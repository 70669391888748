import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { setCurrentPlanet } from 'logic/action/planet.action'
import { FlexCont, MainContainer } from 'shared/styles/styled'
import { MobileHeaderCont, MobileHeaderTitleCont, ConnectBtn, MobCollapseIcon, GreenMobCollapseIcon, WarningCont } from './style'
import { lendingPathArr } from 'service/dataConstant'
import CustomModal from 'shared/custom-modal'
import { MobileSideNav } from '../side-nav'
import WalletMLogout from '../wallet-modal/WalletMLogout'
import { connectWallet } from 'modules/wallet-provider/WalletProvider'
import CloseIcon from 'assets/icons/white-cross.svg'
import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
} from 'wagmi'
import { mainnet, polygon, optimism, bsc, arbitrum } from 'wagmi/chains'
import { useWeb3Modal } from "@web3modal/react";
import { connectEthWallet, disconnectEthWallet } from 'logic/action/wallet.actions'
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect'
import wallet from 'modules/wallet/wallet'
import {ethers} from 'ethers'

const MobileHeader = (props: any) => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const [showSideNav, setShowSideNav] = useState(false)
  const [openLogoutModal, setOpenLogoutModal] = useState(false)
  const [warningMsg, setWarningMsg] = useState(true)
  const [updateWarning, setToggleWarning] = useState(true)
  const [activeTab, setActiveTab] = useState('base')
  const { ethWalletConnected } = selector.ethData
  const { planetIcon, menuIcon } = selector.planet

  const { connector: activeConnector, address, isConnected } = useAccount()
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect()
  const { disconnect } = useDisconnect()
  const [loading, setLoading] = useState(false);
  const { open } = useWeb3Modal();
  const chains = [mainnet, bsc];
  const projectId = '8f395d21e8a7dce987dfe4a6110febc9';

  useEffect(() => {
    const pathUrl: any = props.location.pathname.split('/')
    if (pathUrl[1]) {
      if(pathUrl[1] == "pools_v3"){
        pathUrl[1] = "poolsv3";
      }
      setActiveTab(pathUrl[1])
      if (lendingPathArr.includes(pathUrl[1])) {
        dispatch(setCurrentPlanet('green'))
      }
      else {
        dispatch(setCurrentPlanet('blue'))
      }
    } else {
      dispatch(setCurrentPlanet('blue'))
    }

    if(pathUrl[1] !== "boost" && updateWarning !== false){
      setWarningMsg(true);
    }

    if(pathUrl[1] == "boost" ){
      setWarningMsg(false);
    }
    
  }, [props.location.pathname, dispatch])

  useEffect(() => {
    // console.log('mobile login', isConnected, connectors, address );
    if(address && address !== null){
      // console.log("set wallet address to local storage", address)
      dispatch(connectEthWallet(address));
      localStorage.setItem("walletConnected", JSON.stringify(true));
      let walletType = localStorage.getItem("wagmi.wallet");
      if(walletType != null  ) {
        let type = JSON.parse(walletType) == "walletConnect"? 3 : 1;
        localStorage.setItem("walletType", JSON.stringify(type));
        setProviderInWallet(type)
      } else {
        setProviderInWallet(1);
      }
    }
  },[address])

  const setProviderInWallet = async (wallet_type?: any) => {
    if(wallet_type == 3){
      await wallet.setProvider(wallet_type);
      const address = await wallet.login(wallet_type);
    } else {
      await wallet.setProvider(1);
    }
  }

  const getAddress = (addressObj: any) => {
    let address = addressObj.address;
    // console.log("address", address, isConnected)
    const add1 = address  .substring(0, 2)
    const add2 = address.substring(address.length - 4)
    const finalAdd = `${add1}....${add2}`
    return finalAdd
  }

  const hideWarning = () =>{
    setWarningMsg(false);
  }

  const toggleWarning = () => {
    setToggleWarning(false);
    hideWarning()
  }

  async function onOpen() {
    // console.log("login in mobile header")
    setLoading(true);
    await open();
    setLoading(false);
  }

  return (
    <Fragment>
      <MainContainer warningMsg={warningMsg} className="header"></MainContainer>
      <MobileHeaderCont warningMsg={warningMsg} className='warningContainer'>
        <WarningCont className='warningContainer'>
          <FlexCont className='mobWarning'>
          {activeTab === "poolsv3" || activeTab === "pools" ? 
            <p className="warningText">Old pools except gamma infinity vault have been discontinued. Migrate using&nbsp;<a href="https://app.planet.finance/migration" className="siteLink">Migration Link</a></p>
            :
            <p className="warningText"><p className="warningHeader">PHISHING WARNING:</p>&nbsp;Please make sure you're visiting&nbsp;<a href="https://app.planet.finance" className="siteLink">https://app.planet.finance</a>&nbsp;all other domains are fake.</p>
        }
          </FlexCont>
        </WarningCont>
        <img className='close-icon' src={CloseIcon} alt="" onClick={() => toggleWarning()} />
       </MobileHeaderCont>
      <MobileHeaderCont warningMsg={warningMsg} className="mobHeaderContainer">
        <MobileHeaderTitleCont>
          <img src={planetIcon} onClick={()=>window.open("https://planet.finance","_self")} alt="" />
          <p>Planet</p>
        </MobileHeaderTitleCont>

        <FlexCont>
          {/* {ethWalletConnected ? (
            <ConnectBtn onClick={() => setOpenLogoutModal(true)}>{getAddress(address)}</ConnectBtn>
          ) : (
            <ConnectBtn onClick={() => connectWallet(dispatch)}>Connect</ConnectBtn>
          )} */}
          {isConnected ? (  
            <ConnectBtn onClick={() => setOpenLogoutModal(true)}>{getAddress({address})}</ConnectBtn>
          ) : 
          <ConnectBtn onClick={() => onOpen()}>Connect</ConnectBtn>
            
          }  
          {
            menuIcon.indexOf("green") > -1? 
            <GreenMobCollapseIcon src={menuIcon} alt="" onClick={() => setShowSideNav(true)} /> 
            : 
            <MobCollapseIcon src={menuIcon} alt="" onClick={() => setShowSideNav(true)} />
          }
        </FlexCont>


      </MobileHeaderCont>
      <MobileSideNav showSideNav={showSideNav} setShowSideNav={setShowSideNav} address={address} />
      <CustomModal show={openLogoutModal} toggleModal={setOpenLogoutModal}>
        <WalletMLogout setOpenModal={setOpenLogoutModal} />
      </CustomModal>
    </Fragment>
  )
}

export default withRouter(MobileHeader)
