import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'

export const TokenValInput = styled.input<any>`
  width: 100%;
  background: transparent;
  color: ${colors.white};
  font-size: 16px;
  line-height: 20px;
  font-family: MontserratBold;
  border: none;
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 30px;
  }
  &.tokenInput{
    padding: 30px;
  }
  
`

export const TokenSelectCont = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-left: 20px;
  }
  img.boost-token-icon{
    height: 30px;
    margin-right: 10px;
  }
`

export const FlexCont = styled.div`
  display: flex;
  align-items: center;
  &.clock-setting{
    margin-bottom: 20px;
  }
  &.stableTokensCont{
    display: flex;
  }
`

export const TokenName = styled.h1`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 25px;
  }
  
`

export const SelectCurrTxt = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  width: 132px;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 25px;
    width: 164px;
  }
`

export const PercentageBtnCont = styled.div`
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-gap: 20px;
  align-items: center;
  &.gammaStaked{
    padding: 20px 30px 30px 30px;
  }
`
export const PercentageBox = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  background: #1F1F22 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF26;
  border-radius: 20px;

  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 0px 3px 10px #0000004D;
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 50px;
  }
  p{
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF64;
    font-family:MontserratBold;
    margin:0;
    opacity:1;
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
`