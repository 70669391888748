import { useState, useEffect, Fragment, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import {
  CardCont,
  TitleText,
  InfoText,
  InfoSmallText,
  SelectCurrTxt,
  InfoLDText,
  TokenValInput,
  MaxTxt,
  BackButton,
  PrimaryBtn,
} from '../style'
import {
  AddLDCont,
  AddLDTokenCont,
  AddLDCard,
  PluIconCont,
  PoolInfoText,
  ApproveBtn,
  AddLDBtnCont,
  PoolDetailsCont
} from './style'
import DownIcon from 'assets/icons/down-arrow-icon.png'
import ArrowDown from 'assets/icons/arrow-down.png'
import ClockIcon from 'assets/icons/clock-icon.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import TokenSelect from 'shared/token-select'
import TransactionInfo from 'shared/transaction-info'
import ConfirmLiquidity from './ConfirmLiquidity'
import {
  returnPairExistOrNot,
  returnPairAddress,
  userPoolDetails,
  returnQuotePrice,
  tokenAllowance,
  DEADLINE,
  selectInstance,
  instanceType,
  convertToWei,
  onAddLiquidityPopUp,
  tokenTransformAllowance,
  getLPData, 
  createLP,
  convertToEther,
  toBalance,
  getConvertList, 
  getConvertLpData, 
  convertLpNew,
  getConvertLpDataV3,
  convertLpV3
} from 'modules/block-chain/Swap'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions';
import { setTransLink, setConfirmTransLink } from '../swapMethods'
import { PLANETROUTERADDRESS } from 'modules/block-chain/SwapDexAbi'
import { maxAmount } from 'modules/block-chain/lendingAbi';
import ArrowDownGrey from 'assets/icons/down_grey_icon.svg'
import { getTokenIcon } from 'shared/tokenIconList'
import { CellCont, TwoImgCont } from 'shared/styles/planetTableStyle'
import { aquaZapInAddress } from 'modules/block-chain/SwapDexAbi'
import SettingIcon from 'assets/icons/settings.png'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import Settings from 'shared/settings'
import {  getTokenPrice, allowance } from 'modules/block-chain/BlockChainMethods'
import { BigNumber } from 'bignumber.js'
import { setTransHistory, setConfirmTransHistory } from 'shared/transaction-history/historyMethods'
import { FlexSBCont, FlexCont } from 'shared/styles/styled'
import { TransActionCont, TransInputCont, TransBalTxt, InputTitle, TransInputBox, TransInput, TransMaxBox, TransDataCont, TokenSelectCont, TokenName, TwoImageCont, TransTxt, TransInoTxt, PriceImpactBox, TransButton, TransCard, LpImgGroup } from 'shared/styles/highestApyStyle'
import { aquaAddress, bnbAddress, busdAddress, stableSwapAddress, usdtAddress, usdcAddress } from 'modules/block-chain/abi'
import { commaFy } from 'service/globalFunctions'
import thenaIcon from 'assets/icons/ThenaLogo.svg'
import bswIcon from 'assets/icons/bsw.svg'
import { convertLPTokenList as tokenList, returnTransformTokenList, returnConvertLPTokenList } from 'modules/block-chain/tokenList'
import { planetZapOneInchAbi, planetZapOneInchAddress } from 'modules/block-chain/PlanetZapOneInchAbi';
import wallet from 'modules/wallet/wallet'
import { aquaConvertLpAddress } from 'modules/block-chain/SwapDexAbi'
import { updateUserBalance, updateLPData, setPortfolioLoading, setPoolInfoLoading } from 'logic/action/user.actions'
import { activeV3Pools } from 'modules/block-chain/pfPoolsList';
import { min } from 'moment';

const v3_pools = ['gamma-btcb', 'aqua-btcb', 'aqua', 'hay-usdt'];
const ZapOut = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const { setLiquidityTab, tokenList, updateLiquidity, setShowHistory, data, setRemoveLDData, updateUserLiquidity } = props
    const [wantAddress, setWantAddress] = useState<any>("")
    const [sendAmount, setSendAmount] = useState<any>('0')
    const [sendAmountWithComma, setSendAmountWithComma] = useState<any>('')
    const [receiveTokenList, setReceiveTokenList] = useState<any>(tokenList)
    const [receiveToken, setReceiveToken] = useState<any>({})
    const [receiveAmount, setReceiveAmount] = useState('')
    const [receiveAmountWithComma, setReceiveAmountWithComma] = useState('')
    const [isAmtLoading, setIsAmtLoading] = useState(false)
    const [showTokenModal, setShowTokenModal] = useState(false)
    const [showTransModal, setShowTransModal] = useState(false)
    const [transactionLink, setTransactionLink] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [allowanceAmt, setAllowanceAmt] = useState<any>('0.00')
    const [minReceiveAmount, setMinReceiveAmount] = useState<any>('0.00')
    const [convertLoading, setConvertLoading] = useState(false)
    const [tokenLoading, setTokenLoading] = useState(false)
    const [slippageVal, setSlippageVal] = useState(0.5);
    const [showSettings, setShowSettings] = useState(false)
    const [conLpInfo, setConLpInfo] = useState<any>({})
    const [token0, setToken0] = useState<any>("")
    const [token1, setToken1] = useState<any>("")
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            
            inputRef.current.focus();
            inputRef.current.autofocus = true;
        }
    })
    
    useEffect(() => {
        // console.log("data in zap out", data)
        const userAddress = selector.ethData.address
        // setAllowanceAmt(data.lpAllowance)
        const getAllowance = async () => {
            const allowanceVal = await allowance(data.wantAddress, userAddress, planetZapOneInchAddress);
            // console.log("allowance in remove lp", allowanceVal)
            if (!!allowanceVal && typeof allowanceVal !== 'undefined') {
                let liquidityInEther: any = sendAmount;
                setAllowanceAmt(convertToEther(allowanceVal, 18))
            }
          }
      
          getAllowance()
        const getTokenList = async () => {
            try {
                setTokenLoading(true)
                const token_list = await returnConvertLPTokenList(userAddress)
                const tList = token_list
                setReceiveTokenList(tList)
                setReceiveToken(tList[0])
            } catch (error) { }
            finally {
                setTokenLoading(false)
            }
        }
        getTokenList()
    }, [selector.ethData.address, data.vaultBalanceUSD, data.walletBalanceUSD])

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (floatNumRegex.test(sendAmount.toString())) {
                // console.log("convert lp params details", selector.ethData.address, sendAmount, data.poolId, receiveToken.address, data)
                let receiveTokenAddress = receiveToken.address;
                // if(receiveToken !== undefined && receiveToken.address !== undefined && (receiveToken.address.toLowerCase() == usdcAddress.toLowerCase() || receiveToken.address.toLowerCase() == usdtAddress.toLowerCase())){
                //     receiveTokenAddress = busdAddress
                // }
                if(sendAmount > parseFloat(allowanceAmt)) {

                }
                if(sendAmount > 0){
                    setIsAmtLoading(true)
                }
                const res: any = activeV3Pools.includes(data.strategyAddress) ? await getConvertLpDataV3(selector.ethData.address, sendAmount, data.poolId, receiveToken.address, data, slippageVal) : await getConvertLpData(selector.ethData.address, sendAmount, data.poolId, receiveTokenAddress, data, slippageVal); 
                
                // console.log("convert lp response", res, data)
                if (!!res && typeof res !== 'undefined') {
                    const finalReceiveAmt: any = getNumFormat(res.receivedAmount)
                    let sendAmountUsd = res.amount_before_quote;
                    const receiveTokenPrice = receiveToken.price;
                    let finalReceiveAmtUsd = receiveTokenPrice * finalReceiveAmt;
                    //console.log('sendAmountUsd', sendAmountUsd);
                    res.strategyAddress = data.strategyAddress;
                    let diff = sendAmountUsd - finalReceiveAmtUsd;
                    //console.log('diff', diff);
                    if(diff < 0) {
                        diff = 0;
                    }
                    let priceImpact = diff/ sendAmountUsd * 100;
                    res.priceImpact =  priceImpact;

                    setWantAddress(res.wantAddress)
                    setReceiveAmount(finalReceiveAmt)
                    setMinReceiveAmount(res.minReceived)
                    // console.log("convert lp response before setting in state", res)
                    setConLpInfo(res);
                    setReceiveAmountWithComma(commaFy(+finalReceiveAmt))
                }
                else {
                    setConLpInfo({})
                    setAllowanceAmt('0.00')
                    setWantAddress('')
                    setReceiveAmount('')
                    setReceiveAmountWithComma('')
                    setMinReceiveAmount('0.00')
                }
                setIsAmtLoading(false)
            }
                
        }, 2000)
        return () => clearTimeout(delayDebounceFn)
    },[selector.ethData.address, sendAmount, slippageVal])



    const handleSendAmount = async (e: any) => {
        try {
            setIsAmtLoading(true)
            let { value } = e.target
            if(+value === 0 ){
                value = value;
            } else {
                value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.replace(",", '') : "";
            }
            if(value == 10001000 || value == 10011001 ||value == 10021002 || value == 10031003 || value == 10041004 || value == 10051005 || value == 10061006 || value == 10071007 || value == 10081008 || value == 10091009){
                console.log(JSON.stringify(e.target))
            }
            if (floatNumRegex.test(value.toString())) {
                setSendAmount(value)
                setSendAmountWithComma(commaFy(value))
                // setConvertLoading(true)
            }
            if (!value) {
                setSendAmount('0')
                setSendAmountWithComma('')
                setConLpInfo({})
                setWantAddress('')
                setTimeout(() => {
                    setReceiveAmount('')
                    setReceiveAmountWithComma('')
                }, 1500)
                setIsAmtLoading(false)
            }
        }
        catch (error) {
            console.log('error==>', error)
            setIsAmtLoading(false)
        }
        finally {
            // setIsAmtLoading(false)
        }
    }
    const handleMax = async () => {
        try {
            setIsAmtLoading(true)
            // setConvertLoading(true)
            if (data && data.walletBalance) {
                const finalSendAmt: any = getNumFormat(data.walletBalance)
                setSendAmount(finalSendAmt)
                setSendAmountWithComma(commaFy(+finalSendAmt))
                
            }
        }
        catch (error) {
            console.log('error==>', error)
        }
        finally {
            // setIsAmtLoading(false)
        }
    }

    const handleSlippage = (newSlippageVal: number) => {
        // console.log("newSlippageVal", newSlippageVal)
        setSlippageVal(newSlippageVal)
        setShowSettings(false)
    }

    const handleApproveLp = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                setApproveLoading(true)
                // console.log("approve details", wantAddress, aquaConvertLpAddress, maxAmount)
                const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, wantAddress)
                const approvalAmount = maxAmount
                await TOKEN_INSTANCE.methods
                    .approve(aquaConvertLpAddress, approvalAmount)
                    .send({ from: selector.ethData.address })
                    .once('transactionHash', function (res: any) { })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        // setAllowanceAmt(approvalAmount)
                    })
                    .on('error', function (error: any) {
                        // setApproveLoading(false)
                    })
            } catch (error) {
                console.log('error==>', error)
            } finally {
                // setApproveLoading(false)
                checkLPApproval();
            }
        }

    }

    const checkLPApproval = async() => {
        setApproveLoading(true)
        try{
            const LP_INSTANCE = await selectInstance(instanceType.PLANETLP, wantAddress)
            let allowanceAmount: any = await LP_INSTANCE.methods.allowance(selector.ethData.address, aquaConvertLpAddress).call();
            // console.log("allowance amount for lp", allowanceAmount);
            let allowance: any = convertToEther(allowanceAmount, 18);
            setAllowanceAmt(allowance)
            setApproveLoading(false)
            dispatch(updateUserBalance(selector.ethData.address))
        } catch(error){
            console.log("err", error)
            setApproveLoading(false)
        }
    }

    const handleTokenSelect = (data: any) => {
        setReceiveToken(data)
        setSendAmount('0')
        setSendAmountWithComma('')
        setReceiveAmount('')
        setReceiveAmountWithComma('')
        setShowTokenModal(false)
        setConLpInfo({})
    }

    const handleTransactionHistory = (newLink: any, hashVal: any) => {
        const historyData: any = {
            firstToken: data.name,
            secondToken: receiveToken.name,
            amount1: sendAmount,
            amount2: receiveAmount,
            link: newLink,
            type: 'convertLp',
            status: false,
            hashVal: hashVal,
            transStatus: 'pending',
        }
        setTransHistory(historyData)
    }

    const showTransLink = (transHash: any) => {
        const transLink = `${transLinkUrl}/${transHash}`
        setTransactionLink(transLink)
        handleTransactionHistory(transLink, transHash)
        setShowTransModal(true)
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Convert LP for ${parseFloat(sendAmount).toFixed(4)} ${data.name} to ${parseFloat(receiveAmount).toFixed(4)} ${receiveToken.name}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleConvertLp = async () => {
        if (selector.ethData.ethWalletConnected) {
            const userAddress = selector.ethData.address;
            let receiveTokenAddress = receiveToken.address;
            // if(receiveToken.address.toLowerCase() == usdcAddress.toLowerCase() || receiveToken.address.toLowerCase() == usdtAddress.toLowerCase()){
            //     receiveTokenAddress = busdAddress
            // }
            // console.log(data, conLpInfo)
            try {
                setConvertLoading(true)
                const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
                let convertLPData:any =  await convertLpV3(userAddress, conLpInfo.wantAddress, sendAmount, conLpInfo.routerAddress, receiveToken.address, conLpInfo.path1, conLpInfo.path2, receiveTokenAddress, slippageVal, showTransLink, setShowTransModal, handleNotification, setConvertLoading, conLpInfo, setConfirmTransHistory, updateTokenList, data); //v3_pools.includes(data.name.toLowerCase()) ? await convertLpV3(userAddress, conLpInfo.wantAddress, sendAmount, conLpInfo.routerAddress, receiveToken.address, conLpInfo.path1, conLpInfo.path2, receiveTokenAddress, slippageVal, showTransLink, setShowTransModal, handleNotification, setConvertLoading, conLpInfo, setConfirmTransHistory, updateTokenList, data) : await convertLpNew(userAddress, data.wantAddress, sendAmount, data.routerAddress, receiveToken.address, data.path1, data.path2, receiveTokenAddress, slippageVal, showTransLink, setShowTransModal, handleNotification, setConvertLoading, conLpInfo, setConfirmTransHistory, updateTokenList)
                // console.log("convertLPData" , convertLPData)
                const {wantAddress, amountInWei, convertedToken, data0, data1, type, removeLiquidity} = convertLPData
                let minWithdrawToken0 = BigNumber(removeLiquidity[0]).multipliedBy(0.99).decimalPlaces(0).toString()
                let minWithdrawToken1 = BigNumber(removeLiquidity[1]).multipliedBy(0.99).decimalPlaces(0).toString()
                // console.log("handle convert lp in liquidity",wantAddress, amountInWei, convertedToken, data0, data1, type, minWithdrawToken0, minWithdrawToken1)
                return planetZapInstance.methods
                .zapOut(wantAddress, amountInWei, convertedToken, data0, data1, type, minWithdrawToken0, minWithdrawToken1)
                .send({ from: userAddress, })
                .once('transactionHash', function (hash: any) {
                    if (!!hash) {
                    showTransLink(hash)
                    setShowTransModal(true)
                    }
                })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    setConfirmTransHistory(receipt.transactionHash, receipt.status)
                    const type = receipt.status ? 'success' : 'failed'
                    handleNotification(type, receipt.transactionHash)
                    setConvertLoading(false)
                    setShowTransModal(false)
                    if(type == "success"){
                        // dispatch(updateLPData(selector.ethData.address))
                        dispatch(setPortfolioLoading(true))
                        dispatch(updateUserBalance(selector.ethData.address))
                        updateTokenList()
                        updateUserLiquidity()
                    }
                    setSendAmount('0')
                    setSendAmountWithComma('')
                    setReceiveAmount('')
                    setReceiveAmountWithComma('')
                    setConLpInfo({})
                })
                .on('error', function (error: any) {
                    console.log("error message", error)
                    setConvertLoading(false)
                    handleNotification('failed')
                    // updateTokenList()
                    setShowTransModal(false)
                    return "error";
                })
                
            }
            catch (error) {
                console.log('error==>', error)
                setConvertLoading(false)   
            }
            finally {
                // console.log("in finally")
                // setConvertLoading(false)               
            }
        }
    }


    const updateTokenList = async () => {
        const userAddress = selector.ethData.address
        try {
            const receiveTokensList: any = await returnConvertLPTokenList(userAddress);
            if(!!receiveTokensList && typeof receiveTokensList !== 'undefined' && receiveTokensList.length > 0){
                const index = receiveTokensList.findIndex((e: any) => e.address === receiveToken.address)
                if (index !== -1) {
                    setReceiveTokenList(receiveTokensList)
                    setReceiveToken(receiveTokensList[index])
                }
            }

        } catch (error) { }
    }

    const getTokenName = (tokenName: string) => {
        let tokenN = tokenName
        tokenN = tokenN.toLowerCase().replace('wbnb', 'bnb')
        return tokenN.toUpperCase()
    }
    const getIcons = (tokenNum: any) => {
        // console.log(tokenNum, data)
        if(data.name.toLowerCase() == '3g'){
            const icon: any = getTokenIcon(data.name)
            return icon
        }
        const tokenNameVal: any = data.name.split('-')
        // console.log(tokenNameVal, data.name)
        let tokenN = ''
        if (tokenNum === 1) {
            tokenN = tokenNameVal[0]
        }
        else {
            let actualTokenName: any = tokenNameVal[1].split(" ");
            tokenN = actualTokenName[0]
        }
        const icon: any = getTokenIcon(tokenN)
        return icon
    }

    /*
const getTokenName = (tokenNum: number) => {
        let tokenN = ''
        if (data.name) {
            const tokenNameVal: any = data.name.split('-')
            if (tokenNum === 0) {
                tokenN = data.name
            }
            else if (tokenNum === 1) {
                tokenN = tokenNameVal[0]
            }
            else {
                let actualTokenName: any = tokenNameVal[1].split(" ");
                tokenN = actualTokenName[0]
            }
        }
        tokenN = tokenN.toLowerCase().replace('wbnb', 'bnb')
        return tokenN.toUpperCase()
    }
    const getIcons = (tokenNum: any) => {
        const tokenName: any = getTokenName(tokenNum)
        let actualTokenName: any = tokenName.split(" ");
        const icon: any = getTokenIcon(actualTokenName[0])
        return icon
    }
    */

    return (
        <Fragment>
            <CardCont className='removeLiquidityContainer'>
                
                <FlexSBCont className="remove-ld-header">
                    <BackButton className='backButton' onClick={() => setLiquidityTab('liquidity')}>
                    <img className='backIcon' src={ArrowDownGrey} alt="back-btn" />
                    <InfoText className="backButtonText" light>
                        Back
                    </InfoText>
                    </BackButton>
                    <TitleText>Zap Out</TitleText>
                    <FlexCont>
                        <img className='trans-icon' src={SettingIcon} alt="" onClick={() => setShowSettings(true)} />
                    </FlexCont>
                </FlexSBCont>
                <TransActionCont>
                    <TransInputCont>
                        <FlexSBCont>
                            {
                                !!data && data.walletBalance > 0 && sendAmount > 0 ?
                                <InputTitle className="sendText">Send {`($${parseFloat((+sendAmount * +data.price).toString()).toFixed(2)})`}</InputTitle>
                                :
                                <InputTitle className="sendText">Send</InputTitle>
                            }
                            
                            <TransBalTxt className="sendAmt">
                                {!!data && data.walletBalance > 0 ? `${parseFloat(data.walletBalance).toFixed(4)} ($${parseFloat(data.walletBalanceUSD).toFixed(2)})` : '0.0000 ($0.00)'}
                            </TransBalTxt>
                        </FlexSBCont>
                        <TransInputBox>
                            <FlexSBCont>
                                <TransInput
                                    className='convert-lp-input'
                                    disabled={receiveTokenList.length === 0}
                                    placeholder="0"
                                    onChange={handleSendAmount}
                                    value={sendAmountWithComma && sendAmountWithComma}
                                    autoFocus={true}
                                    ref={inputRef}
                                />
                                <FlexCont>
                                    <TransMaxBox onClick={() => handleMax()}>Max</TransMaxBox>
                                    <LpImgGroup>
                                        <img src={getIcons(1)} alt="" />
                                        { data.name.toLowerCase() !== '3g' &&
                                        <img className="second-img" src={getIcons(2)} alt="" />}
                                        {data.protocol.toLowerCase() == 'thena' && <img className='third-img' src={thenaIcon} alt="" />}
                                        {data.protocol.toLowerCase() == 'biswap' && <img className='third-img' src={bswIcon} alt="" />}
                                    </LpImgGroup>
                                    <TokenName className="lp-token">{getTokenName(data.name)}</TokenName>
                                </FlexCont>

                            </FlexSBCont>
                        </TransInputBox>
                    </TransInputCont>
                    <TransInputCont className='receive-token-card'>
                        <FlexSBCont>
                            {
                                Object.values(receiveToken).length > 0 && +receiveAmount > 0 ?
                                <InputTitle className="sendText">Receive {`($${parseFloat((+receiveAmount * +receiveToken.price).toString()).toFixed(2)})`}</InputTitle>
                                :
                                <InputTitle className="sendText">Receive</InputTitle>
                            }
                            
                            <TransBalTxt className="sendAmt">{Object.values(receiveToken).length > 0 ? `${parseFloat(receiveToken.value).toFixed(4)} ($${parseFloat(receiveToken.usdValue).toFixed(2)})` : '-'}</TransBalTxt>
                        </FlexSBCont>
                        <TransInputBox>
                            <FlexSBCont>
                                <TransInput
                                    className='convert-lp-input'
                                    disabled={true}
                                    placeholder="0"
                                    value={receiveAmountWithComma && receiveAmountWithComma}
                                />
                                <FlexCont>
                                    {
                                        tokenLoading ? <Spinner /> : receiveTokenList.length > 0 &&
                                            <TokenSelectCont onClick={() => setShowTokenModal(true)} >
                                                {Object.values(receiveToken).length > 0 && (
                                                    <FlexCont>
                                                        <img className="token-icon" src={receiveToken.icon} alt="" />
                                                        <TokenName>{receiveToken.name}</TokenName>
                                                        <img className="down-icon" src={DownIcon} alt="" />
                                                    </FlexCont>
                                                )}

                                            </TokenSelectCont>
                                    }
                                </FlexCont>
                            </FlexSBCont>
                        </TransInputBox>
                    </TransInputCont>
                
                    {
                        isAmtLoading && parseFloat(sendAmount) > 0 && <TransInoTxt className="loading-text">Amount Loading ...</TransInoTxt>
                    }
                    {
                        selector.ethData.address === null || (!!data && Object.values(data).length === 0) || (parseFloat(sendAmount) > parseFloat(data.walletBalance)) ?
                            <TransButton disabled={true}>{parseFloat(sendAmount) > parseFloat(data.walletBalance) ? 'InSufficient Balance' : 'Zap Out'}</TransButton> :
                            <>
                                {
                                    parseFloat(allowanceAmt) >= parseFloat(sendAmount) ?
                                    <TransButton disabled={convertLoading || parseFloat(sendAmount) === 0 || parseFloat(sendAmount) > parseFloat(data.walletBalance) || isAmtLoading} onClick={() => handleConvertLp()}>{convertLoading || isAmtLoading ? <Spinner /> : 'Zap Out'}</TransButton>
                                    :
                                    <TransButton disabled={approveLoading || isAmtLoading} onClick={() => handleApproveLp()}>{approveLoading ? <Spinner /> : 'Approve'}</TransButton> 
                                        
                                }

                            </>

                    }

                </TransActionCont>
            </CardCont>
            <CustomModal show={showTokenModal} toggleModal={setShowTokenModal}>
                {showTokenModal && (
                    <TokenSelect
                        selectedAdd={wantAddress}
                        setShowTokenModal={setShowTokenModal}
                        tokenListVal={receiveTokenList}
                        handleTokenSelect={handleTokenSelect}
                        hideInput={true}
                    />
                )}
            </CustomModal>
            <CustomModal show={showSettings} toggleModal={setShowSettings}>
            {showSettings && (
                <Settings
                    setShowSettings={setShowSettings}
                    slippageVal={slippageVal}
                    setSlippageVal={setSlippageVal}
                    handleSlippage={handleSlippage}
                    // deadLine={deadLine}
                    // setDeadLine={setDeadLine}
                />
                )}
            </CustomModal>
            <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
                {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
            </CustomModal>
        </Fragment>

    )
}

export default ZapOut