import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { Button } from 'shared/button'

export const HeaderCont = styled.div<any>`
  display: none;
  justify-content: space-between;
  align-items: center;
  padding-right:20px;
  background: rgba(0,0,0,0.4);
  border-bottom: 1px solid ${colors.borderColor};
  position: fixed;
  left: 0;
  top: 0;
  width:100%;
  height: 76px;
  box-sizing: border-box;
  z-index: 20;
  backdrop-filter: blur(50px);
  @supports (-moz-appearance:none) {
    background: ${colors.themeBackground};
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    display: flex;
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    padding-right: 40px;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    padding-right: 120px;
  }
  @media (min-width: 1050px) {
    &.headerContainer{
      zoom:0.85 !important;
    }
  }
  @media (min-width: 1350px) {
    &.headerContainer{
      zoom:0.9 !important;
    }
  }
  @media (min-width: 1550px) {
    &.headerContainer{
      zoom:0.95 !important;
    }
  }
  @media (min-width: 1750px) {
    &.headerContainer{
      zoom:1 !important;
    }
  }

  &.headerContainer{
    top: ${(props: any) => props.warningMsg ? '75px' : '0'};
  }

  &.warningContainer{
    top: 0;
    display: ${(props: any) => props.warningMsg ? '' : 'none'};
  }

  &.warningContainer > img.close-icon{
    height: 20px;
  }
  
`
export const WarningCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 100%;
`

export const HeaderPathCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PathCont = styled.div`
  position: relative;
  display: flex;
  margin-right: 30px;
  &.poolsv3{
    &:hover{
      span{ 
        opacity: 1;
      }
    }
  }
  &.poolsV3 > span{
    width: 4px;
    height: 4px;
    background: ${colors.white};
    opacity: 0.4;
    border-radius: 2px;
    margin: 1px 0;
  }
`
export const ActivePath = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: ${colors.lightBlue};
`
export const ActiveLendingPath = styled(ActivePath)`
  background: ${colors.lightGreen};
`
export const Path = styled.p<any>`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  opacity: ${(props: any) => props.activeTab ? 1 : 0.4};
  margin: 0;
  cursor: pointer;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  &:hover{
    opacity: 1;
  }
`
export const PriceWalletBox = styled.div`
  display: flex;
  align-items: center;
`
export const PriceBox = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  margin-right: 30px;
  img{
    width: 30px;
    height: 30px;
    margin-right:10px;
  }
  p{
    font-size: 14px;
    line-height: 17px;
    color: ${colors.white};
    font-family: MontserratBold;
    margin:0;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
`

export const HeaderTitleCont = styled.div`
  display: flex;
  align-items: center;
  padding-left: 27.14px;
  margin-right: 41px;
  img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      height: 35px;
      width: 35px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      height: 40px;
      width: 40px;
    }
  }
  p {
    font-size: 18px;
    line-height: 20px;
    color: ${colors.white};
    font-family: MontserratBold;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      font-size: 20px;
      line-height: 24px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      font-size: 24px;
      line-height: 29px;
    }
  }
`
export const DotContent = styled.div`
  display: none;
  left: 0;
  float: left;
`

export const PoolContent = styled.div`
  display: none;
  left: 0;
  float: left;
`

export const PoolContWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items:center;
  &:hover ${PoolContent} {
    display: block
  }
`

export const DotContWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items:center;
  &:hover ${DotContent} {
    display: block
  }
`
export const DotCont = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid ${colors.borderColor};
  border-radius: 10px;
  width: 28px;
  height: 34px;
  &:hover{
    border: 1px solid ${colors.white};
    span{ 
      opacity: 1;
    }
  }
  span{
    width: 4px;
    height: 4px;
    background: ${colors.white};
    opacity: 0.4;
    border-radius: 2px;
    margin: 1px 0;
  }
`
export const ConnectWalletBtn = styled(Button) <any>`
  height: 50px;
  font-size: 12px;
  padding: 14px 30px;
  border: 0;
  box-sizing: border-box;
  color: ${(props: any) => props.currPlanet === 'green' ? colors.lightGreen : colors.lightBlue};
  background: ${(props: any) => props.currPlanet === 'green' ?
    `transparent linear-gradient(90deg, rgba(85,255,118, 0.2) 0%, rgba(39,172,64, 0.2) 100%)  0% 0% no-repeat padding-box` :
    `transparent linear-gradient(270deg, rgba(48,55,218, 0.2) 0%, rgba(85,183,255, 0.2) 100%) 0% 0% no-repeat padding-box`
  };
  &:disabled {
    background: ${(props: any) => props.currPlanet === 'green' ?
    `transparent linear-gradient(90deg, rgba(85,255,118, 0.2) 0%, rgba(39,172,64, 0.2) 100%)  0% 0% no-repeat padding-box` :
    `transparent linear-gradient(270deg, rgba(48,55,218, 0.2) 0%, rgba(85,183,255, 0.2) 100%) 0% 0% no-repeat padding-box`
  };
    cursor: initial;
    opacity: 0.6;
  }
  &:hover {
    background: ${(props: any) => props.currPlanet === 'green' ?
    `transparent linear-gradient(90deg, rgba(85,255,118, 0.4) 0%, rgba(39,172,64, 0.26) 100%)  0% 0% no-repeat padding-box` :
    `transparent linear-gradient(270deg,rgba(48,55,218,0.4) 0%,rgba(85,183,255,0.26) 100%) 0% 0% no-repeat padding-box`
  };
  }

  @media (min-width: 912px) {
    font-size: 18px;
  }

`

/* ======================== Header SubLink ============================= */

export const SubLinkCont = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.borderColor};
  border-radius: 10px;
  background: ${colors.themeBackground};
  padding: 2.3vw 2.3vw 0.8vw 2.3vw;
  box-sizing: border-box;
  z-index: 100;
  a{
    text-decoration: none;
  }
  min-width: 38vw;

  &.pool-sublinks{
    min-width: 13vw;
    padding: 2.3vw 2.3vw 0vw 2.3vw;
    top: 25px;
  }
`
export const SubLinkRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 35px;
  a{
    text-decoration: none;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
`

export const HeadText = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  opacity: 1;
  margin: 0 0 0px 0;
  cursor: pointer;
  @media (max-width: ${screenSizes.mediaL}px) {
    font-size: 11px;
    line-height: 13px;
  }
  @media (max-width: ${screenSizes.mediaXLL}px) {
    font-size: 12px;
    line-height: 14px;
  }
  @media (max-width: ${screenSizes.mediaXXL}px) {
    font-size: 14px;
    line-height: 17px;
  }
  &.menu-link{
    text-align: left;
    width: max-content;
  }
`

export const HeadDescription = styled.p`
  font-size: 14px ;
  line-height: 18px;
  margin: 6px 0 0 0;
  text-align: left;
  font-weight: 600;
  font-family: MontserratSemiBold;
  font-style: normal;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.4;
  @media (max-width: ${screenSizes.mediaL}px) {
    font-size: 10px;
    line-height: 12px;
  }
  @media (max-width: ${screenSizes.mediaXLL}px) {
    font-size: 10px;
    line-height: 13.5px;
  }
  @media (max-width: ${screenSizes.mediaXXL}px) {
    font-size: 12px;
    line-height: 15px;
  }
`

export const SubLink = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  &:hover{
    p {
      opacity: 1;
    }
  }
  div {
    
    img {
      width: 1.2vw;
      height: 1.21vw;
      margin-right: 0.78vw;
    }
  }
`

// display: flex;
// flex-direction: column;
// justify-content: center;
// export const SubLinkDescription = styled.p`

// `

/* =============== Mobile Header ================== */

export const MobileHeaderCont = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px 20px 20px;
  background: rgba(0,0,0,0.4);
  border-bottom: 1px solid ${colors.borderColor};
  position: fixed;
  width: 100%;
  height: 70px;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 10;
  backdrop-filter: blur(50px);
  @supports (-moz-appearance:none) {
    background: ${colors.themeBackground};
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    display: none;
  }
  &.mobHeaderContainer{
    top: ${(props: any) => props.warningMsg ? '70px' : '0'};
  }
  &.warningContainer{
    display: ${(props: any) => props.warningMsg ? '' : 'none'};
  }
`
export const MobileHeaderTitleCont = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 31.41px;
    height: 30px;
    margin-right:7px;
  }
  p {
    font-size: 20px;
    line-height: 24px;
    color: ${colors.white};
    font-family: MontserratBold;
    margin: 0;
  }
`
export const MobCollapseIcon = styled.img`
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 0px;
`

export const GreenMobCollapseIcon = styled.img`
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 0px;
`

export const ConnectBtn = styled(Button)`
  background: ${colors.themeBackground};
  font-size: 15px;
  line-height: 19px;
  max-width: 110px;
  padding: 8px 20px;
  text-align: center;
`
