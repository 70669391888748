import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchInfVaultData, fetchPoolData, updateUserBalance } from 'logic/action/user.actions'
import { updateUserLendingData } from 'logic/action/lending.action'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexCont, FlexSBCont, PlanetBtn, PlanetRedBtn, PlanetBrownBtn } from 'shared/styles/styled'
import { GTokenWrapper, GTokenWeb, GTokenMob, GTokenCont, GTokenCard, GCardHeader, GActionCont, GTokenImgCont, GTokenTxt, GTokenValTxt, GCardText, GCardDivider, TextBox, GreenTxtBox, StakeTxtImgCont, StakeTxtImgBox, GStakeIconBox, GAquaStakeCont, GTokenInfoCont, StopUnStakingBox, GCardNumTxt, GLoadingText1, GLoadingText2, GLoadingText3, GLoadingText4 } from './style'
import { GTokenMCard, GMCardBText, MGBtnGroup, OpenCloseBox, CompIconBox, CompIcon } from './style'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import AquaSyncIcon from 'assets/icons/aqua-sync.svg'
import GammaSyncIcon from 'assets/icons/gamma-sync.svg'
import StakeIcon from 'assets/icons/blue-plus.svg'
import UnStakeIcon from 'assets/icons/red-minus.svg'
import InstantIcon from 'assets/icons/instant.svg'
import DownIcon from 'assets/icons/down.svg'
import FinishedUnStakingIcon from 'assets/icons/finished-unstaking.png'
import CancelUnStakingIcon from 'assets/icons/cancel-unstaking.png'
import GStakeArrowIcon from 'assets/icons/stake-arrows.png'
import MGArrowStakeIcon from 'assets/icons/m-stake-arrow.svg'
import { commaFy, checkZeroVal, convertToKAndM } from 'service/globalFunctions'
import { transLinkUrl } from 'service/global-constant'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import Stake from './Stake'
import UnStake from './UnStake'
import InstantUnStake from './InstantUnStake'
import UnStakingTime from './UnStakingTime'
import UnStakingTimeAqua from './UnStakingTimeAqua'
import GammaBalance from './GammaBalance'
import GammaBalanceUsd from './GammaBalanceUsd'
import GammaBalanceMobile from './GammaBalanceMobile'
import { transfer_rewards_in_gamma_infinity_vault_old, stopUnstakeProcess, getUserUnstakingTimeInInfinityVault, unstake_after_min_time, getOldInfinityVaultData } from 'modules/block-chain/BlockChainMethods'
import axios from 'axios'
import {getBurnApy} from 'modules/block-chain-green/LendingBase'
interface apyDataArray {
    [key: string]: Number
}
var tokenApy: apyDataArray = {}


const PlanetGToken = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [showStake, setShowStake] = useState(false)
    const [showUnStake, setShowUnStake] = useState(false)
    const [showInstUnStake, setShowInstUnStake] = useState(false)
    const [showGAquaDetails, setShowGAquaDetails] = useState(false)
    const [showGGammaDetails, setShowGGammaDetails] = useState(false)
    const [token, setToken] = useState('gAQUA')
    const [stakeLoading, setStakeLoading] = useState(false)

    const [aquaCountDownDate, setAquaCountDownDate] = useState('')
    const [gammaCountDownDate, setGammaCountDownDate] = useState('')
    const [aquaStopUnStaking, setAquaStopUnStaking] = useState(false)
    const [gammaStopUnStaking, setGammaStopUnStaking] = useState(false)
    const [finishAquaUnStaking, setFinishAquaUnStaking] = useState(false)
    const [finishGammaUnStaking, setFinishGammaUnStaking] = useState(false)
    
    let { infVaultLoading, gammaInfData, aquaInfData, poolData } = selector.user
    useEffect( () => {
        const address = selector.ethData.address;
        let isMounted = true;
        // console.log(" infinity stake details for AQUA", aquaInfData)
        if(aquaInfData.infinity_vault_address === undefined){
            // console.log("inside dispatch in old vaults")
            dispatch(fetchInfVaultData(selector.ethData.address))
        }
        // console.log(" infinity stake details for GAMMA", gammaInfData)
        const getAquaUnStakingDate = async () => {
            if (!!aquaInfData && Object.values(aquaInfData).length > 0 && isMounted === true) {
                // console.log("inside if in planetGToken", aquaInfData)
                try {
                    const aquaUnStakeDate: any = await getUserUnstakingTimeInInfinityVault(address, aquaInfData.infinity_vault_address, aquaInfData.infinityAbi)
                    if (!!aquaUnStakeDate && typeof aquaUnStakeDate !== 'undefined' && isMounted === true) {
                        setAquaCountDownDate(aquaUnStakeDate)
                    }
                }
                catch (error) {
                    setAquaCountDownDate('')
                }

            }
        }
        const getGammaUnStakingDate = async () => {
            if (!!gammaInfData && Object.values(gammaInfData).length > 0) {
                try {
                    const gammaUnStakeDate: any = await getUserUnstakingTimeInInfinityVault(address, gammaInfData.infinity_vault_address, gammaInfData.infinityAbi)
                    if (!!gammaUnStakeDate && typeof gammaUnStakeDate !== 'undefined' && isMounted === true) {
                        setGammaCountDownDate(gammaUnStakeDate)
                    }
                }
                catch (error) {
                    // console.log(' unstakeerror ');
                    // console.log(error);
                    setGammaCountDownDate('')
                }
            }
        }

        const setInfData = async(userAddress : any) => {
            try{
                let inf_data: any = await getOldInfinityVaultData(userAddress)
                // console.log("inf data", inf_data)
                gammaInfData = inf_data[0];
                aquaInfData = inf_data[1];
                return true;
            } catch(error){
                console.log(error)
            }
        }

        if (selector.ethData.address) {
            getAquaUnStakingDate()
            getGammaUnStakingDate()
            if(((gammaInfData.userAddress == undefined && gammaInfData.tvl !== undefined) || (aquaInfData.userAddress == undefined && aquaInfData.tvl !== undefined)) && infVaultLoading == false){
                setInfData(selector.ethData.address)
            }
        }
        // console.log("gammaInfData in useEffect", gammaInfData)
        // console.log("aquaInfData in useEffect", aquaInfData)
        return () => {
            isMounted = false;
        };
    }, [selector.ethData.address, aquaInfData, gammaInfData])

    const handleShowStake = (token: string) => {
        // console.log("token details", token)
        setToken(token)
        setShowStake(true)
    }
    const handleShowUnStake = (token: string) => {
        setToken(token)
        setShowUnStake(true)
    }
    const handleShowInstUnStake = (token: string) => {
        setToken(token)
        setShowInstUnStake(true)
    }
    const getSign = (value: any) => {
        if (!!value && parseFloat(value) > 0) {
            return '+'
        }
        else {
            return ""
        }
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `Amount ${aquaInfData.pending_gamma} staked` : `Failed to stake`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }
    const handleUserGAquaStake = async () => {
        if (selector.ethData.ethWalletConnected) {
            const userAddress = selector.ethData.address
            try {
                setStakeLoading(true)
                const res: any = await transfer_rewards_in_gamma_infinity_vault_old(userAddress)
                handleNotification('success', res.transactionHash)
                // dispatch(fetchInfVaultData(userAddress, true))
                dispatch(updateUserBalance(userAddress))
                dispatch(updateUserLendingData(selector.ethData.address))
            }
            catch (error) {
                console.log("error==>", error)
                handleNotification('failed')
            }
            finally {
                setStakeLoading(false)
            }
        }
    }

    const stopUnStakeNotification = (type: string, token: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `Stop ${token} Unstaking successfully` : `Failed to stop ${token} Unstaking`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleStopUnStake = async (tokenName: string) => {
        if (selector.ethData.ethWalletConnected) {
            const userAddress = selector.ethData.address
            try {

                let res: any = ''

                if (tokenName === 'AQUA') {
                    setAquaStopUnStaking(true)
                    setAquaCountDownDate('')
                    aquaInfData.unstaking_bal = 0
                    res = await stopUnstakeProcess(userAddress, aquaInfData.infinity_vault_address, aquaInfData.infinityAbi)
                }
                else {
                    setGammaStopUnStaking(true)
                    setGammaCountDownDate('')
                    gammaInfData.unstaking_bal = 0
                    res = await stopUnstakeProcess(userAddress, gammaInfData.infinity_vault_address, gammaInfData.infinityAbi)
                }
                stopUnStakeNotification('success', tokenName, res.transactionHash)
                // dispatch(fetchInfVaultData(userAddress, true))
                dispatch(updateUserBalance(userAddress))
                dispatch(updateUserLendingData(selector.ethData.address))
                
            }
            catch (error) {
                console.log("error==>", error)
                stopUnStakeNotification('failed', tokenName)
            }
            finally {
                setAquaStopUnStaking(false)
                setGammaStopUnStaking(false)
            }
        }
    }

    const getIsTimerOn = (endTime: any) => {
        let isTimerOn: boolean = false
        if (!!endTime) {
            let now = new Date()
            const difference: any = endTime.getTime() - now.getTime()
            if (parseFloat(difference) > 0) {
                isTimerOn = true
            }
        }
        return isTimerOn
    }

    const finishUnStakeNotification = (type: string, token: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `Finish ${token} Unstaking successfully` : `Failed to finish ${token} Unstaking`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleFinishUnStake = async (tokenName: string) => {
        if (selector.ethData.ethWalletConnected) {
            const userAddress = selector.ethData.address
            try {

                let res: any = ''
                if (tokenName === 'AQUA') {
                    setFinishAquaUnStaking(true)
                    res = await unstake_after_min_time(userAddress, aquaInfData.infinity_vault_address, aquaInfData.infinityAbi)
                }
                else {
                    setFinishGammaUnStaking(true)
                    res = await unstake_after_min_time(userAddress, gammaInfData.infinity_vault_address, gammaInfData.infinityAbi)
                }
                finishUnStakeNotification('success', tokenName, res.transactionHash)
                // dispatch(fetchInfVaultData(userAddress, true))
                dispatch(updateUserBalance(userAddress))
                dispatch(updateUserLendingData(selector.ethData.address))
            }
            catch (error) {
                console.log("error==>", error)
                finishUnStakeNotification('failed', tokenName)
            }
            finally {
                setFinishAquaUnStaking(false)
                setFinishGammaUnStaking(false)
            }
        }
    }

    return (
        <GTokenWrapper>
            <GTokenWeb>
                <GTokenCont>
                    <GTokenCard>
                        <GCardHeader>
                            <FlexCont>
                                <GTokenImgCont>
                                    <img src={AQUAIcon} alt="" />
                                    <img className="sync-img" src={AquaSyncIcon} alt="" />
                                </GTokenImgCont>
                                <div>
                                    <GTokenTxt>AQUA ♾️</GTokenTxt>
                                    {infVaultLoading ? <GLoadingText1 /> :
                                        <GTokenValTxt>${`${!!aquaInfData && aquaInfData.tvl ? commaFy(convertToKAndM(parseFloat(aquaInfData.tvl).toFixed(2))) : '0.00'} TVL`}</GTokenValTxt>
                                    }
                                </div>
                            </FlexCont>
                            <GActionCont>
                                <img src={StakeIcon} className='cancelUnstake' alt="" onClick={() => !checkZeroVal(aquaInfData.unstaking_bal) ? handleStopUnStake('AQUA') : ""} />
                                <img src={UnStakeIcon} alt="" onClick={() => handleShowUnStake('gAQUA')} />
                                <img src={InstantIcon} alt="" onClick={() => handleShowInstUnStake('gAQUA')} />
                            </GActionCont>
                        </GCardHeader>
                        <FlexSBCont>
                            <FlexCont>
                                {infVaultLoading ? <GreenTxtBox><GLoadingText2 /></GreenTxtBox> :
                                    <GreenTxtBox>{`${getSign(aquaInfData.apy)}${!!aquaInfData && aquaInfData.apy ? parseFloat(aquaInfData.apy).toFixed(2) + "% APY": "   "}`}</GreenTxtBox>
                                }
                                <TextBox>5X Voting Power</TextBox>
                            </FlexCont>
                            {
                                !checkZeroVal(aquaInfData.pending_gamma) &&
                                <GAquaStakeCont>
                                    <img src={GAMMAIcon} alt="" />
                                    <p>{!!aquaInfData && aquaInfData.pending_gamma ? commaFy(parseFloat(aquaInfData.pending_gamma).toFixed(4)) : '0.0000'}</p>
                                    <StakeTxtImgCont onClick={() => handleUserGAquaStake()}>
                                        {stakeLoading ? <Spinner /> :
                                            <StakeTxtImgBox>
                                                <p>Stake</p>
                                                <img src={GStakeArrowIcon} alt="" />
                                            </StakeTxtImgBox>
                                        }
                                    </StakeTxtImgCont>
                                </GAquaStakeCont>
                            }

                        </FlexSBCont>
                        <GCardDivider />
                        <GTokenInfoCont>
                            <div>
                                <GCardText>AQUA Balance</GCardText>
                                {infVaultLoading ? <GLoadingText3 /> :
                                    <GCardNumTxt isValZero={checkZeroVal(aquaInfData.deposited_bal)}>{!!aquaInfData && aquaInfData.deposited_bal ? commaFy(parseFloat(aquaInfData.deposited_bal).toFixed(4)) : '0.0000'}</GCardNumTxt>
                                }
                            </div>
                            <div>
                                <GCardText>USD Value</GCardText>
                                {infVaultLoading ? <GLoadingText3 /> :
                                    <GCardNumTxt isValZero={checkZeroVal(aquaInfData.deposited_bal_usd)}>${!!aquaInfData && aquaInfData.deposited_bal_usd ? commaFy(parseFloat(aquaInfData.deposited_bal_usd).toFixed(2)) : '0.00'}</GCardNumTxt>
                                }
                            </div>
                            <div>
                                <GCardText>AQUA Unstaking</GCardText>
                                {infVaultLoading ? <GLoadingText3 /> :
                                    <FlexCont className="unStaking-box">
                                        <GCardNumTxt isValZero={checkZeroVal(aquaInfData.unstaking_bal)}>{!!aquaInfData && aquaInfData.unstaking_bal ? commaFy(parseFloat(aquaInfData.unstaking_bal).toFixed(4)) : '0.0000'}</GCardNumTxt>
                                        {
                                            !checkZeroVal(aquaInfData.unstaking_bal) &&
                                            <StopUnStakingBox>
                                                {getIsTimerOn(aquaCountDownDate) ?
                                                    <>
                                                        {aquaStopUnStaking ? <Spinner /> :
                                                            <img src={CancelUnStakingIcon} alt="" onClick={() => handleStopUnStake('AQUA')} />
                                                        }
                                                    </> :
                                                    <>
                                                        {finishAquaUnStaking ? <Spinner /> :
                                                            <img src={FinishedUnStakingIcon} alt="" onClick={() => handleFinishUnStake('AQUA')} />
                                                        }
                                                    </>
                                                }

                                            </StopUnStakingBox>
                                        }

                                    </FlexCont>
                                }
                            </div>
                            <div>
                                <GCardText>Unstaking Time</GCardText>
                                {infVaultLoading ? <GLoadingText3 /> :
                                    <UnStakingTimeAqua compareDate={aquaCountDownDate} setAquaCountDownDate={setAquaCountDownDate} />
                                }
                            </div>
                        </GTokenInfoCont>

                    </GTokenCard>
                    <GTokenCard>
                        <GCardHeader>
                            <FlexCont>
                                <GTokenImgCont>
                                    <img src={GAMMAIcon} alt="" />
                                    <img className="sync-img" src={GammaSyncIcon} alt="" />
                                </GTokenImgCont>
                                <div>
                                    <GTokenTxt>GAMMA ♾️</GTokenTxt>
                                    {infVaultLoading ? <GLoadingText1 /> :
                                        <GTokenValTxt>${`${!!gammaInfData && gammaInfData.tvl ? commaFy(convertToKAndM(parseFloat(gammaInfData.tvl).toFixed(2))) : '0.00'} TVL`}</GTokenValTxt>
                                    }
                                </div>
                            </FlexCont>
                            <GActionCont>
                                <img src={StakeIcon} className='cancelUnstake' alt="" onClick={() => !checkZeroVal(gammaInfData.unstaking_bal) ? handleStopUnStake('GAMMA') : ""} />
                                <img src={UnStakeIcon} alt="" onClick={() => handleShowUnStake('gGAMMA')} />
                                <img src={InstantIcon} alt="" onClick={() => handleShowInstUnStake('gGAMMA')} />
                            </GActionCont>
                        </GCardHeader>
                        <FlexCont>
                            {infVaultLoading ? <GreenTxtBox><GLoadingText2 /></GreenTxtBox> :
                                <GreenTxtBox>{`${getSign(gammaInfData.apy)}${!!gammaInfData && gammaInfData.apy ? parseFloat(gammaInfData.apy).toFixed(2) + "% APY": "   "}`}</GreenTxtBox>
                            }
                            <GreenTxtBox>Auto-compound</GreenTxtBox>
                        </FlexCont>
                        <GCardDivider />
                        <GTokenInfoCont>
                            <GammaBalance infVaultLoading={infVaultLoading} gammaInfData={gammaInfData} />
                            <GammaBalanceUsd infVaultLoading={infVaultLoading} gammaInfData={gammaInfData} />
                            {/* <div>
                                <GCardText>USD Value</GCardText>
                                {infVaultLoading ? <GLoadingText3 /> :
                                    <GCardNumTxt isValZero={checkZeroVal(gammaInfData.deposited_bal_usd)}>${!!gammaInfData && gammaInfData.deposited_bal_usd ? commaFy(convertToKAndM(gammaInfData.deposited_bal_usd)) : '0.00'}</GCardNumTxt>
                                }
                            </div> */}
                            <div>
                                <GCardText>GAMMA Unstaking</GCardText>
                                {infVaultLoading ? <GLoadingText3 /> :
                                    <FlexCont className="unStaking-box">
                                        <GCardNumTxt isValZero={checkZeroVal(gammaInfData.unstaking_bal)}>{!!gammaInfData && gammaInfData.unstaking_bal ? commaFy(parseFloat(gammaInfData.unstaking_bal).toFixed(4)) : '0.0000'}</GCardNumTxt>
                                        {
                                            !checkZeroVal(gammaInfData.unstaking_bal) &&
                                            <StopUnStakingBox>
                                                {getIsTimerOn(gammaCountDownDate) ?
                                                    <>
                                                        {gammaStopUnStaking ? <Spinner /> :
                                                            <img src={CancelUnStakingIcon} alt="" onClick={() => handleStopUnStake('GAMMA')} />
                                                        }
                                                    </> :
                                                    <>
                                                        {finishGammaUnStaking ? <Spinner /> :
                                                            <img src={FinishedUnStakingIcon} alt="" onClick={() => handleFinishUnStake('GAMMA')} />
                                                        }
                                                    </>
                                                }

                                            </StopUnStakingBox>
                                        }
                                    </FlexCont>
                                }
                            </div>
                            <div>
                                <GCardText>Unstaking Time</GCardText>
                                {infVaultLoading ? <GLoadingText3 /> :
                                    <UnStakingTime compareDate={gammaCountDownDate} setGammaCountDownDate={setGammaCountDownDate} />
                                }
                            </div>
                        </GTokenInfoCont>
                    </GTokenCard>
                </GTokenCont>
            </GTokenWeb>
            <GTokenMob>
                <GTokenCont>
                    <GTokenMCard>
                        <GCardHeader>
                            <FlexCont>
                                <img className='mGToken-icon' src={AQUAIcon} alt="" />
                                <div>
                                    <GTokenTxt>AQUA ♾️</GTokenTxt>
                                    {infVaultLoading ? <GLoadingText1 /> :
                                        <GTokenValTxt>${`${!!aquaInfData && aquaInfData.tvl ? commaFy(convertToKAndM(parseFloat(aquaInfData.tvl).toFixed(2))) : '0.00'} TVL`}</GTokenValTxt>
                                    }
                                </div>
                            </FlexCont>
                        </GCardHeader>
                        <div>
                            {infVaultLoading ? <GLoadingText4 /> :
                                <GMCardBText isValZero={checkZeroVal(aquaInfData.deposited_bal)}>{!!aquaInfData && aquaInfData.deposited_bal ? commaFy(parseFloat(aquaInfData.deposited_bal).toFixed(4)) : '0.0000'}</GMCardBText>
                            }
                            <GCardText>Balance</GCardText>
                        </div>
                        <div className="mTop-space">
                            {infVaultLoading ? <GLoadingText4 /> :
                                <GMCardBText className='green-text'>{`${getSign(aquaInfData.apy)}${!!aquaInfData && aquaInfData.apy? parseFloat(aquaInfData.apy).toFixed(2) + "%" : "   "}`}</GMCardBText>
                            }
                            {aquaInfData !== undefined && aquaInfData.apy !== undefined ? <GCardText>APY</GCardText> : ""}
                        </div>
                        <div className="mTop-space">
                            <GMCardBText className='blue-text'>5X</GMCardBText>
                            <GCardText>Voting Power</GCardText>
                        </div>
                        <PlanetBtn className="unstakeGToken" isActive={infVaultLoading || !checkZeroVal(aquaInfData.unstaking_bal) ? true : false } disabled={infVaultLoading || checkZeroVal(aquaInfData.unstaking_bal)} onClick={() => handleStopUnStake('AQUA')}>Cancel Stake</PlanetBtn>
                        {showGAquaDetails &&
                            <>
                                <MGBtnGroup>
                                    <PlanetRedBtn onClick={() => handleShowUnStake('gAQUA')} >Unstake</PlanetRedBtn>
                                    <PlanetBrownBtn onClick={() => handleShowInstUnStake('gAQUA')} >
                                        <img className='mInst-icon' src={InstantIcon} alt="" />
                                    </PlanetBrownBtn>
                                </MGBtnGroup>
                                {
                                    !checkZeroVal(aquaInfData.pending_gamma) &&
                                    <>
                                        <FlexSBCont>
                                            <div>
                                                <FlexCont>
                                                    <img className="m-gamma-icon" src={GAMMAIcon} alt="" />
                                                    <GMCardBText>{!!aquaInfData && aquaInfData.pending_gamma ? commaFy(parseFloat(aquaInfData.pending_gamma).toFixed(4)) : '0.0000'}</GMCardBText>
                                                </FlexCont>
                                                <GCardText>Earnings</GCardText>
                                            </div>
                                            { }
                                            <GStakeIconBox>
                                                {stakeLoading ? <Spinner /> :
                                                    <img src={MGArrowStakeIcon} alt="" onClick={() => handleUserGAquaStake()} />
                                                }
                                            </GStakeIconBox>
                                        </FlexSBCont>
                                        <GCardDivider />
                                    </>
                                }

                                <FlexSBCont>
                                    <div>
                                        {infVaultLoading ? <GLoadingText4 /> :
                                            <GMCardBText isValZero={checkZeroVal(aquaInfData.unstaking_bal)}>{!!aquaInfData && aquaInfData.unstaking_bal ? commaFy(parseFloat(aquaInfData.unstaking_bal).toFixed(4)) : '0.0000'}</GMCardBText>
                                        }
                                        <GCardText>AQUA Unstaking</GCardText>
                                    </div>

                                    {
                                        !checkZeroVal(aquaInfData.unstaking_bal) &&
                                        <StopUnStakingBox>
                                            {getIsTimerOn(aquaCountDownDate) ?
                                                <>
                                                    {aquaStopUnStaking ? <Spinner /> :
                                                        <img src={CancelUnStakingIcon} alt="" onClick={() => handleStopUnStake('AQUA')} />
                                                    }
                                                </> :
                                                <>
                                                    {finishAquaUnStaking ? <Spinner /> :
                                                        <img src={FinishedUnStakingIcon} alt="" onClick={() => handleFinishUnStake('AQUA')} />
                                                    }
                                                </>
                                            }

                                        </StopUnStakingBox>
                                    }
                                </FlexSBCont>

                                <div className="mTop-space">
                                    {infVaultLoading ? <GLoadingText4 /> :
                                        <UnStakingTimeAqua compareDate={aquaCountDownDate} setAquaCountDownDate={setAquaCountDownDate} />
                                    }
                                    <GCardText>Unstaking Time</GCardText>
                                </div>
                            </>
                        }
                        {/* <GCardDivider /> */}
                        <OpenCloseBox isActive={showGAquaDetails} onClick={() => setShowGAquaDetails(!showGAquaDetails)}>
                            <p>{showGAquaDetails ? 'Close Details' : 'See Details'}</p>
                            <img src={DownIcon} alt="" />
                        </OpenCloseBox>
                    </GTokenMCard>
                    <GTokenMCard>
                        <GCardHeader>
                            <FlexCont>
                                <img className='mGToken-icon' src={GAMMAIcon} alt="" />
                                <div>
                                    <GTokenTxt>GAMMA ♾️</GTokenTxt>
                                    {infVaultLoading ? <GLoadingText1 /> :
                                        <GTokenValTxt>${`${!!gammaInfData && gammaInfData.tvl ? commaFy(convertToKAndM(parseFloat(gammaInfData.tvl).toFixed(2))) : '0.00'} TVL`}</GTokenValTxt>
                                    }
                                </div>
                            </FlexCont>
                        </GCardHeader>
                        {/* <div>
                            {infVaultLoading ? <GLoadingText4 /> :
                                <GMCardBText isValZero={checkZeroVal(gammaInfData.deposited_bal)}>
                                    {!!gammaInfData && gammaInfData.deposited_bal ? commaFy(parseFloat(gammaInfData.deposited_bal).toFixed(4)) : '0.0000'}
                                </GMCardBText>
                            }
                            <GCardText>Balance</GCardText>
                        </div> */}
                        <GammaBalanceMobile infVaultLoading={infVaultLoading} gammaInfData={gammaInfData} />
                        <div className="mTop-space">
                            {infVaultLoading ? <GLoadingText4 /> :
                                <GMCardBText className='green-text'>{`${getSign(gammaInfData.apy)}${!!gammaInfData && gammaInfData.apy ? parseFloat(gammaInfData.apy).toFixed(2) +"%": "   "}`}</GMCardBText>
                            }
                            {gammaInfData !== undefined && gammaInfData.apy !== undefined ? <GCardText>APY</GCardText> : ""}
                        </div>
                        <div className="mTop-space">
                            <CompIconBox>
                                <CompIcon />
                                <CompIcon className="second-icon" />
                            </CompIconBox>
                            <GCardText>Compounding</GCardText>
                        </div>
                        <PlanetBtn className="unstakeGToken" isActive={infVaultLoading || !checkZeroVal(gammaInfData.unstaking_bal) ? true : false } disabled={infVaultLoading || checkZeroVal(gammaInfData.unstaking_bal)} onClick={() => handleStopUnStake('GAMMA')}>Cancel Stake</PlanetBtn>
                        {showGGammaDetails &&
                            <>
                                <MGBtnGroup>
                                    <PlanetRedBtn onClick={() => handleShowUnStake('gGAMMA')} >Unstake</PlanetRedBtn>
                                    <PlanetBrownBtn onClick={() => handleShowInstUnStake('gGAMMA')} >
                                        <img className='mInst-icon' src={InstantIcon} alt="" />
                                    </PlanetBrownBtn>
                                </MGBtnGroup>
                                <FlexSBCont>
                                    <div>
                                        {infVaultLoading ? <GLoadingText4 /> :
                                            <GMCardBText isValZero={checkZeroVal(gammaInfData.unstaking_bal)}>{!!gammaInfData && gammaInfData.unstaking_bal ? commaFy(parseFloat(gammaInfData.unstaking_bal).toFixed(4)) : '0.0000'}</GMCardBText>
                                        }
                                        <GCardText>Gamma Unstaking</GCardText>
                                    </div>
                                    {
                                        !checkZeroVal(gammaInfData.unstaking_bal) &&
                                        <StopUnStakingBox>
                                            {getIsTimerOn(gammaCountDownDate) ?
                                                <>
                                                    {gammaStopUnStaking ? <Spinner /> :
                                                        <img src={CancelUnStakingIcon} alt="" onClick={() => handleStopUnStake('GAMMA')} />
                                                    }
                                                </> :
                                                <>
                                                    {finishGammaUnStaking ? <Spinner /> :
                                                        <img src={FinishedUnStakingIcon} alt="" onClick={() => handleFinishUnStake('GAMMA')} />
                                                    }
                                                </>
                                            }

                                        </StopUnStakingBox>
                                    }

                                </FlexSBCont>

                                <div className="mTop-space">
                                    {infVaultLoading ? <GLoadingText4 /> :
                                        <UnStakingTime compareDate={gammaCountDownDate} setGammaCountDownDate={setGammaCountDownDate} />
                                    }
                                    <GCardText>Unstaking Time</GCardText>
                                </div>
                            </>
                        }
                        {/* <GCardDivider /> */}
                        <OpenCloseBox isActive={showGGammaDetails} onClick={() => setShowGGammaDetails(!showGGammaDetails)}>
                            <p>{showGGammaDetails ? 'Close Details' : 'See Details'}</p>
                            <img src={DownIcon} alt="" />
                        </OpenCloseBox>
                    </GTokenMCard>
                </GTokenCont>
            </GTokenMob>
            <CustomModal show={showStake} toggleModal={setShowStake}>
                {showStake && <Stake token={token} setShowStake={setShowStake} data={token === 'gAQUA' ? aquaInfData : gammaInfData} />}
            </CustomModal>
            <CustomModal show={showUnStake} toggleModal={setShowUnStake}>
                {showUnStake && <UnStake token={token} setShowUnStake={setShowUnStake} data={token === 'gAQUA' ? aquaInfData : gammaInfData} />}
            </CustomModal>
            <CustomModal show={showInstUnStake} toggleModal={setShowInstUnStake}>
                {showInstUnStake && <InstantUnStake token={token} setShowInstUnStake={setShowInstUnStake} data={token === 'gAQUA' ? aquaInfData : gammaInfData} />}
            </CustomModal>
        </GTokenWrapper >
    );
};

export default PlanetGToken;
