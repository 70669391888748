export enum Paths {
  root = '/',
  vaults = '/vaults',
  pools = '/pools',
  swap = '/swap',
  swapplanetzap = '/swapplanetzap',
  liquidity = '/liquidity',
  planetToken = '/planet-token',
  lending = '/lending',
  liquidations = '/liquidations',
  markets = '/markets',
  metrics = '/metrics',
  lending_v1 = '/lending_v1',
  markets_v1 = '/markets_v1',
  vaults_v1 =  '/vaults_v1',
  pools_v1 =  '/pools_v1',
  compensation = '/compensation',
  // boost = '/boost',
  liquidStaking = '/liquidstaking',
  vesting = '/vesting',
  bounty = '/bounty',
  migration = '/migration',
  pools_v3 = '/pools_v3'
}