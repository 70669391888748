import { useState, useContext } from 'react'
import { FarmPoolContext } from 'context/FarmPoolContext'
import { FlexSBCont } from 'shared/styles/styled'
import { ExpandedCell, ExpendCellTitle, TableInfoRows, TableInfoBox, TableInfoTxt, CellDownIcon } from 'shared/styles/planetTableStyle'
import DownIcon from 'assets/icons/down.svg'
import { commaFy } from 'service/globalFunctions'
import InfoTooltip from 'shared/info-tool-tip'
import { useSelector } from 'react-redux'
import { allFusionPools, gammaFarmAdddress, gammaFarmAdddressOld } from 'modules/block-chain/abi'


const PoolInfo = (props: any) => {
    const [showVaultInfo, setShowVaultInfo] = useState(false)
    const [showFeeInfo, setShowFeeInfo] = useState(false)
    const [showAPYCal, setShowAPyCal] = useState(false)
    const farmPoolData:any = useContext(FarmPoolContext);
    const selector = useSelector((state: any) => state)
    const { data } = props

    const _renderVaultInfo = () => {
        // console.log('_renderVaultInfo data', data);
        let allocPoint = data.poolAllocPoint;
        let totalAllocPoint = farmPoolData.totalAllocPoint;
        let gammaPerBlock = (farmPoolData.gammaPerBlock) / 1e18;
        // console.log('allocPoint, totalAllocPoint, gammaPerBlock', allocPoint, totalAllocPoint, gammaPerBlock)
        let gammaPerDay = (gammaPerBlock * 28800 * (allocPoint / totalAllocPoint)).toFixed(0);

        return (
            <TableInfoBox>
                <FlexSBCont>
                    <TableInfoTxt light>LP Token Price</TableInfoTxt>
                    <TableInfoTxt>${commaFy(parseFloat(data.price).toFixed(2))}</TableInfoTxt>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Farm Name</TableInfoTxt>
                    <TableInfoTxt>{data.farmName}</TableInfoTxt>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Farm Contract</TableInfoTxt>
                    <a href={data.farmContract} target="_blank" rel="noreferrer">
                        <TableInfoTxt>View</TableInfoTxt>
                    </a>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Vault Contract</TableInfoTxt>
                    {}
                    <a href={data.vaultContract} target="_blank" rel="noreferrer">
                        <TableInfoTxt>View</TableInfoTxt>
                    </a>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Gamma Per Day</TableInfoTxt>
                    <TableInfoTxt>{gammaPerDay}</TableInfoTxt>
                </FlexSBCont>
                {/* <FlexSBCont>
                    <TableInfoTxt light>Vault Multiplier</TableInfoTxt>
                    <TableInfoTxt>{data.vaultMultiplier}X</TableInfoTxt>
                </FlexSBCont> */}
                {
                    (data.poolId >= 2 && data.farmContractAddress != undefined && data.farmContractAddress == gammaFarmAdddress && selector.ethData.address !== undefined && data.gammaBoostPercent != undefined)? (
                        <FlexSBCont>
                            <TableInfoTxt light>Boost Percentage</TableInfoTxt>
                            <TableInfoTxt>{data.gammaBoostPercent}%</TableInfoTxt>
                        </FlexSBCont>
                    )
                    : 
                    ""
                }
                {   
                    (data.poolId >= 2 && data.farmContractAddress != undefined && data.farmContractAddress == gammaFarmAdddress)?
                    (
                        <FlexSBCont>
                            <TableInfoTxt light>Average Boost APR</TableInfoTxt>
                            <TableInfoTxt>{data.averageGammaBoostApr && (!isFinite(data.averageGammaBoostApr) || data.averageGammaBoostApr > 10000) ? "> 10,000" : data.averageGammaBoostApr ? commaFy(data.averageGammaBoostApr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                        </FlexSBCont>
                    )     
                    :
                    ""
                }
                
            </TableInfoBox>
        )
    }

    const _renderFeeInfo = () => {
        if(data.poolId >= 2 || ( data.farmContractAddress != undefined && data.farmContractAddress.toLowerCase() == gammaFarmAdddressOld.toLowerCase())) {
            return (
                <TableInfoBox>
                    <FlexSBCont >
                        <TableInfoTxt light>Deposit Fee</TableInfoTxt>
                        <TableInfoTxt>{data.depositFees}%</TableInfoTxt>
                    </FlexSBCont>
                    <FlexSBCont>
                        <TableInfoTxt light>Withdraw Fee</TableInfoTxt>
                        <TableInfoTxt>{data.withdrawalFees}%</TableInfoTxt>
                    </FlexSBCont>
                    {data.farmName === "PCS" ?
                    <FlexSBCont>
                        <TableInfoTxt light>Performance Fee</TableInfoTxt>
                        <TableInfoTxt>{data.burnFees}%</TableInfoTxt>
                    </FlexSBCont>
                    :""
                    }
                    {+data.performanceFee > 0 ?
                    <FlexSBCont>
                        <TableInfoTxt className="aggregationText" light>Performance Fee <InfoTooltip infoId='aggregationFee' left={5} /></TableInfoTxt>
                        <TableInfoTxt>{data.performanceFee}%</TableInfoTxt>
                    </FlexSBCont>
                    :""
                    }
                </TableInfoBox>
            )
        }
        else {
            return (
                <TableInfoBox>
                    <FlexSBCont >
                        <TableInfoTxt light>Deposit Fee</TableInfoTxt>
                        <TableInfoTxt>{data.depositFees}%</TableInfoTxt>
                    </FlexSBCont>
                    <FlexSBCont>
                        <TableInfoTxt light>Unstake Fee</TableInfoTxt>
                        <TableInfoTxt>{data.withdrawalFees}%</TableInfoTxt>
                    </FlexSBCont>
                    <FlexSBCont>
                        <TableInfoTxt light>Instant Unstake Fee</TableInfoTxt>
                        <TableInfoTxt>{data.instantWithdrawFee}%</TableInfoTxt>
                    </FlexSBCont>
                </TableInfoBox>
            )
        }
        
    }

    const _renderAPYCalculation = () => {
        let oldFarm = gammaFarmAdddressOld; 
        // console.log('gammaFarmAdddressOld', oldFarm);
        if(data.farmContractAddress != undefined && data.farmContractAddress.toLowerCase() == oldFarm.toLowerCase()) {
            return <TableInfoBox>
                <FlexSBCont>
                    <TableInfoTxt light>Trade Fee APR</TableInfoTxt>
                    <TableInfoTxt>{parseFloat(data.tradeFeeApr).toFixed(2)}%</TableInfoTxt>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>GAMMA APR</TableInfoTxt>
                    <TableInfoTxt>{"0.00"}%</TableInfoTxt>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Boost APR</TableInfoTxt>
                    <TableInfoTxt>{"0.00"}%</TableInfoTxt>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Total APR</TableInfoTxt>
                    <TableInfoTxt>{data.tradeFeeApr && (!isFinite(data.tradeFeeApr) || data.tradeFeeApr > 10000) ? "> 10,000" : data.tradeFeeApr ? commaFy(data.tradeFeeApr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                </FlexSBCont>
            </TableInfoBox>
        }
        else {
            if(data.poolId >= 2) {
                return (
                    <TableInfoBox>
                        {!allFusionPools.includes(data.strategyAddress.toLowerCase()) && 
                        <FlexSBCont>
                            <TableInfoTxt light>Trade Fee APR</TableInfoTxt>
                            <TableInfoTxt>{parseFloat(data.tradeFeeApr).toFixed(2)}%</TableInfoTxt>
                        </FlexSBCont>
                        }
                        <FlexSBCont>
                            <TableInfoTxt light>GAMMA APR</TableInfoTxt>
                            <TableInfoTxt>{data.gammaApr && (!isFinite(data.gammaApr) || data.gammaApr > 10000) ? "> 10,000" : data.gammaApr ? commaFy(data.gammaApr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                        </FlexSBCont>
                        <FlexSBCont>
                            <TableInfoTxt light>Boost APR</TableInfoTxt>
                            <TableInfoTxt>{data.boostAPR && (!isFinite(data.boostAPR) || data.boostAPR > 10000) ? "> 10,000" : data.boostAPR ? commaFy(data.boostAPR.toFixed(2)) : "0.00"}%</TableInfoTxt>
                        </FlexSBCont>
                        {(data.isBoostedInNewFarm && (data.protocol.toLowerCase() == "thena" || data.protocol.toLowerCase() == "biswap" || data.protocol.toLowerCase() == "cake")) &&
                            <FlexSBCont>
                                <TableInfoTxt light>Parent Farm APR</TableInfoTxt>
                                <TableInfoTxt>{(data.parentFarmApr).toFixed(2)}%</TableInfoTxt>
                            </FlexSBCont>
                        }
                        <FlexSBCont>
                            <TableInfoTxt light>Total APR</TableInfoTxt>
                            <TableInfoTxt>{data.totalApr && (!isFinite(data.totalApr) || data.totalApr > 10000) ? "> 10,000" : data.totalApr ? commaFy(data.totalApr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                        </FlexSBCont>
                    </TableInfoBox>
                )
            }
            else {
                let apr = parseFloat(data.apr) ;
                let gammaSupplyApr = parseFloat(data.gammaSupplyApr) ;
                let totalApr = parseFloat(data.totalApr) ;
                return (
                    <TableInfoBox>
                        <FlexSBCont>
                            <TableInfoTxt light>Unstake Fee APR</TableInfoTxt>
                            <TableInfoTxt>{(parseFloat(data.widthdrawApr) * 100 ).toFixed(2)}%</TableInfoTxt>
                        </FlexSBCont>
                        <FlexSBCont>
                            <TableInfoTxt light>Farm GAMMA APR</TableInfoTxt>
                            <TableInfoTxt>{apr && (!isFinite(apr) || apr > 10000) ? "> 10,000" : apr ? commaFy(apr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                        </FlexSBCont>
                        {data.poolId == 1 &&
                            <FlexSBCont>
                                <TableInfoTxt light>BURN APR</TableInfoTxt>
                                <TableInfoTxt>{data.burnApy && (!isFinite(data.burnApy) || data.burnApy > 10000) ? "> 10,000" : data.burnApy ? commaFy(data.burnApy.toFixed(2)) : "0.00"}%</TableInfoTxt>
                            </FlexSBCont>
                        }
                        <FlexSBCont>
                            <TableInfoTxt light>Green Planet APR</TableInfoTxt>
                            <TableInfoTxt>{gammaSupplyApr && (!isFinite(gammaSupplyApr) || gammaSupplyApr > 10000) ? "> 10,000" : gammaSupplyApr ? commaFy(gammaSupplyApr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                        </FlexSBCont>
                        <FlexSBCont>
                            <TableInfoTxt light>Total APR</TableInfoTxt>
                            <TableInfoTxt>{(totalApr).toFixed(2)}%</TableInfoTxt>
                        </FlexSBCont>
                    </TableInfoBox>
                )
            }
        }
        
    }

    return (
        <TableInfoRows>
            <ExpandedCell className="item1">
                <div className="on-web">
                    <ExpendCellTitle>Pool Details</ExpendCellTitle>
                    {_renderVaultInfo()}
                </div>
                <div className="on-mob">
                    <FlexSBCont onClick={() => setShowVaultInfo(!showVaultInfo)}>
                        <ExpendCellTitle>Vault Details</ExpendCellTitle>
                        <CellDownIcon activeStatus={showVaultInfo}><img src={DownIcon} alt="" /></CellDownIcon>
                    </FlexSBCont>
                    {showVaultInfo && _renderVaultInfo()}
                </div>
            </ExpandedCell>
            <ExpandedCell className="item2">
                <div className="on-web">
                    <ExpendCellTitle>Fees</ExpendCellTitle>
                    {_renderFeeInfo()}
                </div>
                <div className="on-mob">
                    <FlexSBCont onClick={() => setShowFeeInfo(!showFeeInfo)}>
                        <ExpendCellTitle>Fees</ExpendCellTitle>
                        <CellDownIcon activeStatus={showFeeInfo}><img src={DownIcon} alt="" /></CellDownIcon>
                    </FlexSBCont>
                    {showFeeInfo && _renderFeeInfo()}
                </div>
            </ExpandedCell>
            <ExpandedCell className="item3">
                <div className="on-web">
                    <ExpendCellTitle>APR Calculations</ExpendCellTitle>
                    {_renderAPYCalculation()}
                </div>
                <div className="on-mob">
                    <FlexSBCont onClick={() => setShowAPyCal(!showAPYCal)}>
                        <ExpendCellTitle>APR Calculations</ExpendCellTitle>
                        <CellDownIcon activeStatus={showAPYCal}><img src={DownIcon} alt="" /></CellDownIcon>
                    </FlexSBCont>
                    {showAPYCal && _renderAPYCalculation()}
                </div>
            </ExpandedCell>
        </TableInfoRows>
    )
}
export default PoolInfo
