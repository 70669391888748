import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserLendingData } from 'logic/action/lending.action'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont } from 'shared/styles/styled'
import { HAModalCont, HAMDataCont, HAModalTitle, TokenInfoBox, HATokenIcon, TokenValTxt, ModalAPYTxt, TvlTxt, SwitchCont, DepositSwitch, WithdrawSwitch, ModalCard, ModalCardTitle, DataInfoCard, InfoHeaderCont, DataInfoTitle, DataInfoCont, DataInfoTxt, LoadingText3, LoadingText4 } from 'shared/styles/highestApyStyle'
import { ModalInputCont, ModalInput, MaxBox, MaxBoxZero, UserBalTxt } from 'shared/styles/highestApyStyle'
import { CardBtnGroup, CardBtn, TransformBtn, CardRedBtn, CardGreenBtn } from 'shared/styles/highestApyStyle'
import CloseIcon from 'assets/icons/white-cross.svg'
import DownIcon from 'assets/icons/down.svg'
import { getTokenIcon } from 'shared/tokenIconList'
import { floatNumRegex, } from 'shared/helpers/regexConstants'
import { getTokenName, commaFy, convertToKAndM, checkZeroVal, getNumFormat } from 'service/globalFunctions'
import BoostVaultAction from './BoostVaultAction'
import { transLinkUrl, fraxTranLink, maticTransLink, dotTransLink, solTransLink, adaTransLink, lunaTransLink, austTransLink, avaxTransLink, atomTransLink, xrpTransLink, linkTransUrl, ftmTransLink, whustTransferLink } from 'service/global-constant'
import Spinner from 'shared/spinner'
import { isAllowanceApproved } from 'modules/block-chain/BlockChainMethods'
import { gBnbAddress, convertToWei, instType, selectInstance, MAX_INT } from 'modules/block-chain/lendingAbi'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { getUnderlyingDecimal } from 'modules/block-chain/LendingBase'
import { anchor_apy } from 'modules/block-chain-green/lendingAbi'

const VaultDetailsModal = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('deposit')
    const [depositAmount, setDepositAmount] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [depositLoading, setDepositLoading] = useState(false)
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [isAllowance, setIsAllowance] = useState(false)
    const [showVaultInfo, setShowVaultInfo] = useState(false)
    const [showFeeInfo, setShowFeeInfo] = useState(false)
    const [showAPYCal, setShowAPyCal] = useState(false)
    const { portfolioLoading, poolData } = selector.user
    const { toggleVaultModal, data, setModalData, currentTab } = props

    useEffect(() => {
        const dataArr = poolData.newVaults;
        if (!!dataArr && Object.values(dataArr).length > 0) {
            let newApyArr: any = []
            if (currentTab === 'finished') {
                if (data.isBoostPool) {
                    newApyArr = dataArr.finished.filter((e: any) => e.wantAddress.toLowerCase() === data.wantAddress.toLowerCase())
                }
                else {
                    newApyArr = dataArr.finished.filter((e: any) => e.address.toLowerCase() === data.address.toLowerCase())
                }
            }
            else {
                if (data.isBoostPool) {
                    newApyArr = dataArr.active.filter((e: any) => e.strategyAddress.toLowerCase() === data.strategyAddress.toLowerCase())
                }
                else {
                    newApyArr = dataArr.active.filter((e: any) => e.address.toLowerCase() === data.address.toLowerCase())
                }
            }
            if(newApyArr[0] !== undefined) {
                // console.log("inside if")
                setModalData(newApyArr[0])
            } else {
                // console.log("inside else")
                setModalData(data)
            }
            
            // console.log("vault details modal data", data, newApyArr, dataArr)
        }
        
    }, [poolData, data, setModalData, currentTab])


    useEffect(() => {
        const userAddress = selector.ethData.address
        const getIsAllowance = async () => {
            
            if (data.name === 'BNB' || data.name === 'WBNB') {
                setIsAllowance(true)
            }
            else {
                const wantAddress = data.address? data.address :  data.wantAddress;

                // console.log(wantAddress)
                if(typeof wantAddress == undefined)
                {
                    setIsAllowance(false)
                    return 1;
                }
                let farmAddress = ""

                try {
                    const isAllow = await isAllowanceApproved(userAddress, farmAddress, wantAddress)
                    setIsAllowance(isAllow)
                }
                catch (error) {
                    console.log("error=>", error)
                }
            }
        }
        if (!!data && !data.isBoostPool) {
            getIsAllowance()
        }
        // console.log("inside 2nd useeffectvault details modal", data)
    }, [selector.ethData.address, data])

    const handleDepositAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setDepositAmount(value)
        }
        if (!value) {
            setDepositAmount('')
        }
    }
    const handleWithdrawAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setWithdrawAmount(value)
        }
        if (!value) {
            setWithdrawAmount('')
        }
    }


    const handleNotification = (type: string, actionType: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const amt = actionType === 'Deposit' ? depositAmount : withdrawAmount
        const dataVal: any = {
            type: type,
            message: `${actionType}  ${parseFloat(amt).toFixed(4)} ${data.name}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleDepositAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(depositAmount) > 0) {
            try {
                setDepositLoading(true)
                const amountInEth = depositAmount
                const userAddress = selector.ethData.address
                const gTokenAddress = data.address
                if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase()) {
                    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
                    await inst.methods.mint().send({
                        from: userAddress,
                        value: convertToWei(amountInEth, 1e18)
                    })
                        .once('confirmation', function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            handleNotification(type, 'Deposit', receipt.transactionHash)
                            setDepositLoading(false)
                            dispatch(updateUserLendingData(selector.ethData.address))
                            dispatch(updateUserBalance(selector.ethData.address))
                        })
                        .on('error', function (error: any) {
                            handleNotification('failed', 'Deposit')
                            setDepositLoading(false)
                        })
                }
                else {
                    const inst: any = await selectInstance(instType.gToken, gTokenAddress)
                    const decimal = Math.pow(10, await getUnderlyingDecimal(gTokenAddress));
                    await inst.methods.mint(convertToWei(amountInEth, decimal)).send({
                        from: userAddress,
                    })
                        .once('confirmation', function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            handleNotification(type, 'Deposit', receipt.transactionHash)
                            setDepositLoading(false)
                            dispatch(updateUserLendingData(selector.ethData.address))
                            dispatch(updateUserBalance(selector.ethData.address))
                        })
                        .on('error', function (error: any) {
                            handleNotification('failed', 'Deposit')
                            setDepositLoading(false)
                        })
                }
            }
            catch (error) {
                console.log("error=>", error)
                setDepositLoading(false)
            }
            finally {
                setDepositLoading(false)
            }

        }
    }
    const handleWithdrawAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(withdrawAmount) > 0) {
            try {
                setWithdrawLoading(true)
                const amountInEth = withdrawAmount
                const userAddress = selector.ethData.address
                const gTokenAddress = data.address
                const inst: any = await selectInstance(instType.gToken, gTokenAddress)
                const decimal = Math.pow(10, await getUnderlyingDecimal(gTokenAddress));
                await inst.methods.redeemUnderlying(convertToWei(amountInEth, decimal)).send({
                    from: userAddress,
                })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        handleNotification(type, 'Withdraw', receipt.transactionHash)
                        setWithdrawLoading(false)
                        dispatch(updateUserLendingData(selector.ethData.address))
                        dispatch(updateUserBalance(selector.ethData.address))
                    })
                    .on('error', function (error: any) {
                        handleNotification('failed', 'Withdraw')
                        setWithdrawLoading(false)
                    })
            }
            catch (error) {
                console.log("error=>", error)
                setWithdrawLoading(false)
            }
            finally {
                setWithdrawLoading(false)
            }
        }
    }

    const approveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} approved` : `Failed to approve  ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }
    const handleApprove = async () => {
        try {
            setApproveLoading(true)
            const underlyingAddress = data.underlyingAddress
            const gTokenAddress = data.address
            const userAddress = selector.ethData.address
            const TOKEN_INSTANCE = await selectInstance(instType.PANCAKELP, underlyingAddress)
            await TOKEN_INSTANCE.methods
                .approve(gTokenAddress, MAX_INT)
                .send({ from: userAddress })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    approveNotification(type, receipt.transactionHash)
                    setApproveLoading(false)
                    setIsAllowance(true)
                })
                .on('error', function (error: any) {
                    approveNotification('failed')
                    setApproveLoading(false)
                })
        }
        catch (error) {
            console.log("error==>", error)
            setApproveLoading(false)
        }
        finally {
            setApproveLoading(false)
        }
    }

    const handleBuyToken = () => {
        history.push(`${Paths.swap}`, { tokenAdd: data.underlyingAddress.toLowerCase() })
    }

    const renderTokenTransform = () => {
        if (data.name === 'FRAX') {
            return <a href={fraxTranLink} target='_blank' rel="noreferrer"><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'MATIC') {
            return <a href={maticTransLink} target='_blank' rel="noreferrer"><TransformBtn >{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'DOT') {
            return <a href={dotTransLink} target='_blank' rel="noreferrer"><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'SOL') {
            return <a href={solTransLink} target='_blank' rel="noreferrer"><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'ADA') {
            return <a href={adaTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'LUNA') {
            return <a href={lunaTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'aUST') {
            return <a href={austTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'AVAX') {
            return <a href={avaxTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'ATOM') {
            return <a href={atomTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'XRP') {
            return <a href={xrpTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'LINK') {
            return <a href={linkTransUrl} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'FTM') {
            return <a href={ftmTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'whUST') {
            return <a href={whustTransferLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else {
            return <TransformBtn onClick={() => handleBuyToken()}>{`Buy  ${getTokenName(data.name)}`}</TransformBtn>
        }
    }


    return (
        <HAModalCont>
            <FlexSBCont>
                <HAModalTitle>{getTokenName(data.name)}{data.isBoostPool ? data.strategyAddress === "0x0709e1356F765a6F88632B539E209a5d6864B765" ? "(PCS) OLD":" (PCS)" : ''}</HAModalTitle>
                <img className='close-icon' src={CloseIcon} alt="" onClick={() => toggleVaultModal()} />
            </FlexSBCont>
            <HAMDataCont>
                <TokenInfoBox>
                    <HATokenIcon src={getTokenIcon(data.name.replace(' (OLD)', ''))} />
                    {portfolioLoading ? <LoadingText4 /> :
                        <TokenValTxt isValZero={checkZeroVal(data.vaultBalanceUSD)}>{`$${!!data && data.vaultBalanceUSD ? commaFy(parseFloat(data.vaultBalanceUSD).toFixed(2)) : '0.00'}`}</TokenValTxt>
                    }
                    <ModalAPYTxt>{commaFy(convertToKAndM(data.totalApy))}% APY</ModalAPYTxt>
                    <TvlTxt>{`$${!!data.tvl ? commaFy(convertToKAndM(data.tvl)) : '0.00'} TVL`}</TvlTxt>
                </TokenInfoBox>
                <SwitchCont className={!data.isBoostPool ? 'without-boost' : ''}>
                    <DepositSwitch isActive={activeTab === 'deposit'} onClick={() => setActiveTab('deposit')}>Deposit</DepositSwitch>
                    <WithdrawSwitch isActive={activeTab === 'withdraw'} onClick={() => setActiveTab('withdraw')}>Withdraw</WithdrawSwitch>
                </SwitchCont>
                {data.isBoostPool ?
                    <BoostVaultAction activeTab={activeTab} data={data} /> :
                    <>
                        {activeTab === 'deposit' ?
                            <ModalCard>
                                <FlexSBCont>
                                    <ModalCardTitle>In Wallet</ModalCardTitle>
                                    {portfolioLoading ? <LoadingText3 className='gamma-reward' /> :
                                        <UserBalTxt>{`${commaFy(parseFloat(data.walletBalance).toFixed(4))} ($${commaFy(parseFloat(data.walletBalanceUSD).toFixed(2))})`}</UserBalTxt>
                                    }
                                </FlexSBCont>
                                <ModalInputCont>
                                    <ModalInput placeholder="0" onChange={handleDepositAmountChanges} value={depositAmount && depositAmount} />
                                    {data.walletBalanceUSD > 0.01 ?
                                        <MaxBox onClick={() => setDepositAmount(getNumFormat(data.walletBalance))}>MAX</MaxBox>
                                        :
                                        <MaxBoxZero onClick={() => setDepositAmount(getNumFormat(data.walletBalance))}>MAX</MaxBoxZero>
                                    }
                                </ModalInputCont>
                                <CardBtnGroup>
                                    {renderTokenTransform()}
                                    {
                                        isAllowance ?
                                            <CardBtn onClick={() => handleDepositAmount()} disabled={depositLoading}>{depositLoading ? <Spinner /> : 'Deposit'}</CardBtn> :
                                            <CardGreenBtn onClick={() => handleApprove()} disabled={approveLoading}>{approveLoading ? <Spinner /> : 'Approve'}</CardGreenBtn>
                                    }
                                </CardBtnGroup>
                            </ModalCard> :
                            <ModalCard>
                                <FlexSBCont>
                                    <ModalCardTitle>In Vault</ModalCardTitle>
                                    {portfolioLoading ? <LoadingText3 className='gamma-reward' /> :
                                        <UserBalTxt>{`${commaFy(parseFloat(data.vaultBalance).toFixed(4))} ($${commaFy(parseFloat(data.vaultBalanceUSD).toFixed(2))})`}</UserBalTxt>
                                    }
                                </FlexSBCont>
                                <ModalInputCont>
                                    <ModalInput placeholder="0" onChange={handleWithdrawAmountChanges} value={withdrawAmount && withdrawAmount} />
                                    {data.vaultBalanceUSD > 0.01 ?
                                        <MaxBox onClick={() => setWithdrawAmount(getNumFormat(data.vaultBalance))}>MAX</MaxBox>
                                        :
                                        <MaxBoxZero onClick={() => setWithdrawAmount(getNumFormat(data.vaultBalance))}>MAX</MaxBoxZero>
                                    }
                                </ModalInputCont>
                                <CardRedBtn onClick={() => handleWithdrawAmount()} disabled={withdrawLoading}>
                                    {withdrawLoading ? <Spinner /> : 'Withdraw'}
                                </CardRedBtn>
                            </ModalCard>
                        }
                    </>
                }
                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowAPyCal(!showAPYCal)} isActive={showAPYCal}>
                        <DataInfoTitle>APY Calculations</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showAPYCal &&
                        <DataInfoCont>
                            {data.name === 'aUST' &&
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>ANCHOR APY</DataInfoTxt>
                                <DataInfoTxt>{anchor_apy}%</DataInfoTxt>
                            </FlexSBCont>
                            }
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>GAMMA APY</DataInfoTxt>
                                <DataInfoTxt>{parseFloat(data.gammaApy).toFixed(2)}%</DataInfoTxt>
                            </FlexSBCont>
                            {data.isBoostPool ?
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Parent Farm APY</DataInfoTxt>
                                    <DataInfoTxt>{parseFloat(data.parentFarmApy).toFixed(2)}%</DataInfoTxt>
                                </FlexSBCont> :
                                <>
                                    {data.name === 'AQUA' ?
                                        <FlexSBCont className='bottom-space'>
                                            <DataInfoTxt light>Burn APY</DataInfoTxt>
                                            <DataInfoTxt>{parseFloat(data.burnApy).toFixed(2)}%</DataInfoTxt>
                                        </FlexSBCont> :
                                        <>
                                            {data.name !== 'GAMMA' &&
                                                <FlexSBCont className='bottom-space'>
                                                    <DataInfoTxt light>Supply APY</DataInfoTxt>
                                                    <DataInfoTxt>{parseFloat(data.supplyApy).toFixed(2)}%</DataInfoTxt>
                                                </FlexSBCont>}
                                        </>
                                    }
                                </>
                            }
                            <FlexSBCont>
                                <DataInfoTxt light>Total APY</DataInfoTxt>
                                <DataInfoTxt>{parseFloat(data.totalApy).toFixed(2)}%</DataInfoTxt>
                            </FlexSBCont>
                        </DataInfoCont>
                    }
                </DataInfoCard>

                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowVaultInfo(!showVaultInfo)} isActive={showVaultInfo}>
                        <DataInfoTitle>Vault Details</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showVaultInfo &&
                        <DataInfoCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>{`${data.name} Price`}</DataInfoTxt>
                                <DataInfoTxt>${commaFy(parseFloat(data.price).toFixed(2))}</DataInfoTxt>
                            </FlexSBCont>
                            {data.protocol === "Planet" ?
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Origin</DataInfoTxt>
                                    <DataInfoTxt>Green Planet</DataInfoTxt>
                                </FlexSBCont>
                                :
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Origin</DataInfoTxt>
                                    <DataInfoTxt>PCS</DataInfoTxt>
                                </FlexSBCont>
                            }
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Contract</DataInfoTxt>
                                <a href={data.farmContract} target="_blank" rel="noreferrer">
                                    <DataInfoTxt>View</DataInfoTxt>
                                </a>
                            </FlexSBCont>
                            {data.protocol === "PCS" ?
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Vault Contract</DataInfoTxt>
                                    <a href={data.vaultContract} target="_blank" rel="noreferrer">
                                        <DataInfoTxt>View</DataInfoTxt>
                                    </a>
                                </FlexSBCont>
                                :
                                ""
                            }
                            {data.protocol === "PCS" ?
                                <FlexSBCont>
                                    <DataInfoTxt light>Vault Multiplier</DataInfoTxt>
                                    <DataInfoTxt>{data.vaultMultiplier}X</DataInfoTxt>
                                </FlexSBCont>
                                :
                                ""
                            }
                        </DataInfoCont>
                    }
                </DataInfoCard>

                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowFeeInfo(!showFeeInfo)} isActive={showFeeInfo}>
                        <DataInfoTitle>Fees</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showFeeInfo &&
                        <DataInfoCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Deposit Fee</DataInfoTxt>
                                <DataInfoTxt>{data.depositFee}%</DataInfoTxt>
                            </FlexSBCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Withdraw Fee</DataInfoTxt>
                                <DataInfoTxt>{data.withdrawalFees}%</DataInfoTxt>
                            </FlexSBCont>
                            {data.protocol === "PCS" ?
                                <FlexSBCont>
                                    <DataInfoTxt light>Performance Fee</DataInfoTxt>
                                    <DataInfoTxt>{data.burnFees}%</DataInfoTxt>
                                </FlexSBCont>
                                :
                                ""
                            }
                        </DataInfoCont>
                    }
                </DataInfoCard>
            </HAMDataCont>
        </HAModalCont>
    );
};

export default VaultDetailsModal