import { LENDING_PLATFORM_LOADING,  LENDING_PLATFORM_LOADING_NEW, USER_ASSET_LOADING, MARKET_ASSET_LOADING, BORROW_LIMIT_LOADING, ADD_NULL_LOADING, UPDATE_LENDING_PLATFORM_DATA, UPDATE_USER_ASSET_DATA, UPDATE_ASSET_MARKET_DATA, UPDATE_BORROW_LIMIT, UPDATE_USER_OLD_ASSET_DATA, UPDATE_NEW_LENDING_PLATFORM_DATA, BORROW_LIMIT_LOADING_NEW, UPDATE_BORROW_LIMIT_NEW, USER_ASSET_LOADING_NEW } from './action.config'
import { getTotalPlatformData, getUserSuppliedAndBorrowedAssetData, getBorrowLimitData } from 'modules/block-chain-green/LendingBase'
import { fetchUserPortfolio } from './user.actions'
import { getTotalPlatformData as getTotalPlatformData_v1, getUserSuppliedAndBorrowedAssetData as getUserSuppliedAndBorrowedAssetData_v1, getBorrowLimitData as getBorrowLimitData_v1 } from 'modules/block-chain-green_v1/LendingBase'

/* ============================= Lending Platform ================================== */

export const setAddNullLoading = (status: boolean) => {
    return {
        type: ADD_NULL_LOADING,
        payload: status
    }
}

export const setLendingPlatformLoading = (status: boolean) => {
    return {
        type: LENDING_PLATFORM_LOADING,
        payload: status
    }
}

export const setLendingPlatformLoadingNew = (status: boolean) => {
    return {
        type: LENDING_PLATFORM_LOADING_NEW,
        payload: status
    }
}

export const updateLendingPlatformData = (data: any) => {
    return {
        type: UPDATE_LENDING_PLATFORM_DATA,
        payload: data
    }
}

export const updateNewLendingPlatformData = (data: any) => {
    return {
        type: UPDATE_NEW_LENDING_PLATFORM_DATA,
        payload: data
    }
}

export const fetchLendingPlatformData = (address: any, isUpdate?: boolean) => async (dispatch: any) => {
    try {
        if (!!address) {
            dispatch(setLendingPlatformLoadingNew(true))
        }
        else {
            dispatch(setAddNullLoading(true))
        }
        const res = await getTotalPlatformData(address)
        // console.log("fetchLendingPlatformData", res)
        if (!!res && res !== 'undefined') {
            dispatch(updateNewLendingPlatformData(res))
        }
    }
    catch (error) {
        console.log(error)
    }
    finally {
        if (!!address) {
            dispatch(setLendingPlatformLoadingNew(false))
        }
        else {
            dispatch(setAddNullLoading(false))
        }
    }
}

export const fetchLendingPlatformData_v1 = (address: any, isUpdate?: boolean) => async (dispatch: any) => {
    try {
        if (!!address) {
            dispatch(setLendingPlatformLoading(true))
        }
        else {
            dispatch(setAddNullLoading(true))
        }
        // console.log("fetchLendingPlatformData", res)
        const res_v1 = await getTotalPlatformData_v1(address)
        if (!!res_v1 && res_v1 !== 'undefined') {
            dispatch(updateLendingPlatformData(res_v1))
        }
    }
    catch (error) {
        console.log(error)
    }
    finally {
        if (!!address) {
            dispatch(setLendingPlatformLoading(false))
        }
        else {
            dispatch(setAddNullLoading(false))
        }
    }
}

export const updateLendingPlatform = (address: any) => async (dispatch: any) => {
    try {
        const res = await getTotalPlatformData_v1(address)
        if (!!res && res !== 'undefined') {
            dispatch(updateLendingPlatformData(res))
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const updateNewLendingPlatform = (address: any) => async (dispatch: any) => {
    try {
        const res = await getTotalPlatformData(address)
        if (!!res && res !== 'undefined') {
            dispatch(updateNewLendingPlatformData(res))
        }
    }
    catch (error) {
        console.log(error)
    }
}

/* ========================== USER ASSET DATA =========================== */

export const setUserAssetLoading = (status: boolean) => {
    return {
        type: USER_ASSET_LOADING,
        payload: status
    }
}

export const setUserAssetLoadingNew = (status: boolean) => {
    return {
        type: USER_ASSET_LOADING_NEW,
        payload: status
    }
}

export const updateUserAssetData = (data: any) => {
    return {
        type: UPDATE_USER_ASSET_DATA,
        payload: data
    }
}

export const updateUserOldAssetData = (data: any) => {
    return {
        type: UPDATE_USER_OLD_ASSET_DATA,
        payload: data
    }
}

export const fetchUserAssetData = (address: any) => async (dispatch: any) => {
    try {
        if (!!address) {
            dispatch(setUserAssetLoadingNew(true))
        }
        const res = await getUserSuppliedAndBorrowedAssetData(address)
        if (!!res) {
            const data = {
                suppliedAssets: res.suppliedAssets,
                borrowedAssets: res.borrowedAssets,
                marketData: res.data,
                gammaObj: res.gammaObj,
                levelBorrowApyArray: res.levelBorrowApyArray,
                total_lending_tvl: res.total_lending_tvl
            }
            // 
            dispatch(updateUserAssetData(data))
        }
        
    }
    catch (error) {
        console.log(error)
        dispatch(setUserAssetLoadingNew(false))
    }
    finally {
        if (!!address) {
            dispatch(setUserAssetLoadingNew(false))
        }
    }
}

export const fetchUserAssetData_v1 = (address: any) => async (dispatch: any) => {
    try {
        if (!!address) {
            dispatch(setUserAssetLoading(true))
        }
        const res_v1 = await getUserSuppliedAndBorrowedAssetData_v1(address)

        if (!!res_v1) {
            const data = {
                suppliedAssets: res_v1.suppliedAssets,
                borrowedAssets: res_v1.borrowedAssets,
                marketData: res_v1.data,
                gammaObj: res_v1.gammaObj,
                levelBorrowApyArray: res_v1.levelBorrowApyArray
            }
            dispatch(updateUserOldAssetData(data))
        }
    }
    catch (error) {
        console.log(error)
    }
    finally {
        if (!!address) {
            dispatch(setUserAssetLoading(false))
        }
    }
}

export const updateUserAsset = (address: any) => async (dispatch: any) => {
    try {
        const res = await getUserSuppliedAndBorrowedAssetData_v1(address)
        if (!!res) {
            const data = {
                suppliedAssets: res.suppliedAssets,
                borrowedAssets: res.borrowedAssets,
                marketData: res.data,
                gammaObj: res.gammaObj,
                levelBorrowApyArray: res.levelBorrowApyArray
            }
            dispatch(updateUserOldAssetData(data))
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const updateUserAssetNew = (address: any) => async (dispatch: any) => {
    try {
        const res = await getUserSuppliedAndBorrowedAssetData(address)
        if (!!res) {
            const data = {
                suppliedAssets: res.suppliedAssets,
                borrowedAssets: res.borrowedAssets,
                marketData: res.data,
                gammaObj: res.gammaObj,
                levelBorrowApyArray: res.levelBorrowApyArray,
                total_lending_tvl: res.total_lending_tvl
            }
            dispatch(updateUserAssetData(data))
        }
    }
    catch (error) {
        console.log(error)
    }
}


/* ======================== MARKET ASSET DATA =========================== */

export const setMarketLoading = (status: boolean) => {
    return {
        type: MARKET_ASSET_LOADING,
        payload: status
    }
}

export const updateAssetMarketData = (data: any) => {
    return {
        type: UPDATE_ASSET_MARKET_DATA,
        payload: data
    }
}

/* ===================================== BORROW LIMIT ==================================== */

export const setBorrowLimitLoading = (status: boolean) => {
    return {
        type: BORROW_LIMIT_LOADING,
        payload: status
    }
}

export const updateBorrowLimit = (data: any) => {
    return {
        type: UPDATE_BORROW_LIMIT,
        payload: data
    }
}

export const setBorrowLimitLoadingNew = (status: boolean) => {
    return {
        type: BORROW_LIMIT_LOADING_NEW,
        payload: status
    }
}

export const updateBorrowLimitNew = (data: any) => {
    return {
        type: UPDATE_BORROW_LIMIT_NEW,
        payload: data
    }
}

export const fetchBorrowLimit = (address: any) => async (dispatch: any) => {
    try {
        if (!!address) {
            dispatch(setBorrowLimitLoading(true))
        }
        const res_v1 = await getBorrowLimitData_v1(address)
        if (!!res_v1) {
            dispatch(updateBorrowLimit(res_v1))
        }
    }
    catch (error) {
        console.log(error)
    }
    finally {
        if (!!address) {
            dispatch(setBorrowLimitLoading(false))
        }
    }
}

export const fetchBorrowLimitNew = (address: any) => async (dispatch: any) => {
    try {
        if (!!address) {
            dispatch(setBorrowLimitLoadingNew(true))
        }
        const res = await getBorrowLimitData(address)
        // console.log("fetchBorrowLimitNew",res)
        if (!!res) {
            dispatch(updateBorrowLimitNew(res))
        }
    }
    catch (error) {
        console.log(error)
    }
    finally {
        if (!!address) {
            dispatch(setBorrowLimitLoadingNew(false))
        }
    }
}

export const updateBorrowLimitData = (address: any) => async (dispatch: any) => {
    try {
        const res_v1 = await getBorrowLimitData_v1(address)
        if (!!res_v1) {
            dispatch(updateBorrowLimit(res_v1))
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const updateBorrowLimitDataNew = (address: any) => async (dispatch: any) => {
    try {
        const res = await getBorrowLimitData(address)
        if (!!res) {
            dispatch(updateBorrowLimitNew(res))
        }
    }
    catch (error) {
        console.log(error)
    }
}

/* ==================================   Update All Lending =============================================== */

export const updateUserLendingData = (address: any) => async (dispatch: any) => {
    dispatch(updateUserAssetNew(address))
    dispatch(updateNewLendingPlatform(address))
    dispatch(updateBorrowLimitDataNew(address))
    dispatch(fetchUserPortfolio(address, true))
    // dispatch(updateUserAsset(address))
    // dispatch(updateLendingPlatform(address))
    // dispatch(updateBorrowLimitData(address))
}

export const silentUpdateUserLendingData = (address: any) => async (dispatch: any) => {
    dispatch(updateUserAssetNew(address))
    dispatch(updateNewLendingPlatform(address))
    dispatch(updateBorrowLimitDataNew(address))
    dispatch(fetchUserPortfolio(address, true))
    // dispatch(updateLendingPlatform(address))
    // dispatch(updateUserAsset(address))
    // dispatch(updateBorrowLimitData(address))
}