
let v;

let adjList;

let res = [];

let MAX_HOPS = 5;

const obj = {
    
    0:{address:"0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991"},
    1:{address:"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"},
    2:{address:"0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"},
    3:{address:"0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"},
    4:{address:"0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"},
    5:{address:"0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"},
    6:{address:"0x2170Ed0880ac9A755fd29B2688956BD959F933F8"},
    7:{address:"0x23396cF899Ca06c4472205fC903bDB4de249D6fC"},
    8:{address:"0x55d398326f99059fF775485246999027B3197955"},
    9:{address:"0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3"},
   10:{address:"0x14016E85a25aeb13065688cAFB43044C2ef86784"},
   11:{address:"0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15"},
    
}

// A directed graph using
// adjacency list representation
function Graph(vertices)
{
	// initialise vertex count
		v = vertices;

		// initialise adjacency list
		initAdjList();
}

// utility method to initialise
	// adjacency list
function initAdjList()
{
	adjList = new Array(v);

		for (let i = 0; i < v; i++) {
			adjList[i] = [];
		}
}

// add edge from u to v
function addEdge(u,v)
{
	// Add v to u's list.
		adjList[u].push(v);
}

// Prints all paths from
	// 's' to 'd'
function printAllPaths(s,d)
{
	let isVisited = new Array(v);
	for(let i=0;i<v;i++)
		isVisited[i]=false;
		let pathList = [];

		// add source to path[]
		pathList.push(s);

		// Call recursive utility
		printAllPathsUtil(s, d, isVisited, pathList,s,d);
}

// A recursive function to print
	// all paths from 'u' to 'd'.
	// isVisited[] keeps track of
	// vertices in current path.
	// localPathList<> stores actual
	// vertices in the current path
function printAllPathsUtil(u,d,isVisited,localPathList,source,destination)
{
	if (u == (d)) {
            let temp = [];
            localPathList.forEach(element => {
                temp.push(obj[element].address)
				// final.push(temp)
            });
            //const st = `${obj[source].address}_${obj[destination].address}:`.toString()
            //console.log(st)
			//console.log(typeof(temp))
			if(temp.length <= MAX_HOPS)
			res.push(temp)
			//console.log(temp);
            //console.log(",")
			// if match found then no need to
			// traverse more till depth
			return;
		}

		// Mark the current node
		isVisited[u] = true;

		// Recur for all the vertices
		// adjacent to current vertex
		for (let i=0;i< adjList[u].length;i++) {
			if (!isVisited[adjList[u][i]]) {
				// store current node
				// in path[]
				localPathList.push(adjList[u][i]);
				printAllPathsUtil(adjList[u][i], d,
				isVisited, localPathList,source,destination);

				// remove current node
				// in path[]
				localPathList.splice(localPathList.indexOf
				(adjList[u][i]),1);
			}
		}

		// Mark the current node
		isVisited[u] = false;
}

// Driver program
// Create a sample graph
Graph(12);

addEdge(0, 1);
addEdge(0, 2);
addEdge(0, 3);

addEdge(1, 0);
addEdge(1, 5);
addEdge(1, 3);
addEdge(1, 6);
addEdge(1, 8);
addEdge(1, 11);

addEdge(2, 0);
addEdge(2, 6);

addEdge(3, 0);
addEdge(3, 4);
addEdge(3, 1);
addEdge(3, 5);
addEdge(3, 8);
addEdge(3, 9);
addEdge(3, 10);

addEdge(4, 7);
addEdge(4, 3);
addEdge(4, 8);
addEdge(4, 6);

addEdge(5, 1);
addEdge(5, 6);
addEdge(5, 3);

addEdge(6, 5);
addEdge(6, 1);
addEdge(6, 4);
addEdge(6, 2);

addEdge(7, 4);

addEdge(8, 3);
addEdge(8, 4);
addEdge(8, 1);

addEdge(9, 3);

addEdge(10, 3);
addEdge(11,1);


export function print(token0Sno,token1Sno){
    printAllPaths(token0Sno,token1Sno)
	var data = res;
	res = []
	return data
}


