import { useState, Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserLendingData } from 'logic/action/lending.action'
import { updateUserBalance, silentUpdateOnInfStake, updateVaultsData } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import {
  CardCont,
  FlexSBCont,
  FlexCont,
  TitleText,
  InfoText,
  InfoSmallText,
  SelectCurrTxt,
  InfoLDText,
  TokenValInput,
  MaxTxt,
  TokenName,
  BackButton,
  PrimaryBtn,
} from '../style'
import {
  SwapContainer,
  SwapHeaderContAlt,
  SwapStatusTextAlt
} from './../../style'
import {
  CreateLpCont,
  AddLDTokenCont,
  AddLDCard,
  PluIconCont,
  TokenSelectCont,
  PoolDetailsCont,
  PoolInfoText,
  ApproveBtn,
  CreateLpBtnCont,
  ApproveBtnGroup,
  DonutChart,
  DonutHole
} from './style'
import DownIcon from 'assets/icons/down-arrow-icon.png'
import ArrowDown from 'assets/icons/arrow-down.png'
import CrossIcon from 'assets/icons/plus.svg'
import ClockIcon from 'assets/icons/clock-icon.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import TokenSelect from 'shared/token-select'
import TransactionInfo from 'shared/transaction-info'
import ConfirmLiquidity from './ConfirmLiquidity'
import ConfirmRemoveLiquidity from './ConfirmRemoveLiquidity'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions';
import { setTransLink, setConfirmTransLink } from '../swapMethods'
import { PLANETROUTERADDRESS } from 'modules/block-chain/SwapDexAbi'
import { convertToEther } from 'modules/block-chain/BlockChainMethods'
import { maxAmount } from 'modules/block-chain/lendingAbi';
import ArrowDownGrey from 'assets/icons/down_grey_icon.svg'
import history from 'modules/app/components/history'
import AddStableSwapLiquidity from './AddStableSwapLiquidity';
import RemoveLiquidity from './RemoveLiquidityNew';
import { commaFy, convertToKAndM, checkZeroVal, getTokenName } from 'service/globalFunctions'
import {SwapConstCard, SwapConstTxt, PriceImpactTxt} from '../token-swap/style'
import {stableSwapAddress} from 'modules/block-chain/abi'
import {
  returnPairExistOrNot,
  returnPairAddress,
  userPoolDetails,
  returnQuotePrice,
  tokenAllowance,
  DEADLINE,
  selectInstance,
  instanceType,
  convertToWei,
  onAddLiquidityPopUp,
  getAllStableSwapPools,
  addLiquidityToPool,
  removeLiquidityFromPool,
  removeLiquidityFromPoolOneCoin,
  withdrawTokenFromStableLP,
  _totalSupply
} from 'modules/block-chain/Swap'
import BUSDIcon from 'assets/icons/BUSD.png'
import USDCIcon from 'assets/icons/USDC.png'
import USDTIcon from 'assets/icons/USDT.png'
import wallet from 'modules/wallet/wallet'

var stableTokenArray: any = []

const CreateLpPair = (props: any) => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const { setLiquidityTab, tokenList, updateLiquidity, setShowHistory } = props
  const [firstAmount, setFirstAmount] = useState('')
  const [secondAmount, setSecondAmount] = useState('')
  const [firstToken, setFirstToken] = useState<any>(!!tokenList && tokenList[0])
  const [secondToken, setSecondToken] = useState<any>({})
  const [existTokenAdd, setExistTokeAdd] = useState('')
  const [tokenType, setTokenType] = useState('')
  const [showTokenModal, setShowTokenModal] = useState(false)
  const [showTransModal, setShowTransModal] = useState(false)
  const [transactionLink, setTransactionLink] = useState('')
  const [currTokenList, setCurrTokenList] = useState<any>(tokenList)
  const [isPairExist, setIsPairExist] = useState(false)
  const [pairAddress, setPairAddress] = useState('')
  const [poolDetails, setPoolDetails] = useState<any>({})
  const [firstAllowance, setFirstAllowance] = useState<any>('0.00')
  const [secondAllowance, setSecondAllowance] = useState<any>('0.00')
  const [showCnfModal, setShowCnfModal] = useState(false)
  const [confirmLdData, setConfirmLdData] = useState<any>({})
  const [confirmRemoveLdData, setConfirmRemoveLdData] = useState<any>({})
  const [isAmtInSuf, setIsAmtInSuf] = useState(false)
  const [inSufMsg, setInSufMsg] = useState('')
  const [approve1Loading, setApprove1Loading] = useState(false)
  const [approve2Loading, setApprove2Loading] = useState(false)
  const [createLPLoading, setCreateLpLoading] = useState(false)
  const [approveLoading, setApproveLoading] = useState(false)
  const [depositLoading, setDepositLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('add')
  const [confirmSwapBtn, setConfirmSwapBtn] = useState(false)
  const [confirmRemoveBtn, setConfirmRemoveBtn] = useState(false)
  const [removeAmountVal, setRemoveAmountVal] = useState('0')
  const [removeTokenIndex, setRemoveTokenIndex] = useState(3)
  const [removeLiquidityToken, setRemoveLiquidityToken] = useState(stableSwapAddress)
  const [stableSwapArray, setStableSwapArray] = useState<any>([])
  const [tokenArray, setTokenArray] = useState<any>([])
  const [stableSwapObject, setStableSwapObject] = useState<any>({});
  const [insufficientBalanceCheck, setInsufficientBalanceCheck] = useState<any>(false)
  const [showRemoveCnfModal, setShowRemoveCnfModal] = useState(false)
  const [removeLoading, setRemoveLoading] = useState(false)
  const [resetInput, ResetInputData] = useState(false);
  const bnbSno = 1

  useEffect(() => {
    if(!!stableSwapArray && stableSwapArray.length == 0){

      let tokenArray = [
        {
          "name": "BUSD", 
          "tvl": "1510000", 
          "is_approved": false, 
          "token":"0xe9e7cea3dedca5984780bafc599bd69add087d56", 
          "address": "0xe983cF03314830d9b4d5e87594bB50215438e19a",
          "icon": BUSDIcon
        }, 
        {
          "name": "USDT", 
          "tvl": "1820000", 
          "is_approved": false, 
          "token": "0x55d398326f99059ff775485246999027b3197955", 
          "address": "0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8",
          "icon": USDTIcon
        }, 
        {
          "name": "USDC", 
          "tvl": "1640000", 
          "is_approved": false, 
          "token": "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d", 
          "address": "0xb56aEa60744ed5AeA8F1639dD1f574b102618d46",
          "icon": USDCIcon
        }
      ];
      setTokenArray(tokenArray);
      fetchSwapArray();
    }
  }, [selector.ethData.address, tokenList])

  const fetchSwapArray = async () => {
    let array: any = [];
    let tokenArray: any = [];
    try {
      array = selector.ethData.address !== undefined ? await getAllStableSwapPools(selector.ethData.address) : await getAllStableSwapPools();
      // console.log("stable swap data", array);
      setStableSwapArray(array[0].assets);
      setStableSwapObject(array[0])
      // console.log(array);
      // console.log("token list", tokenList)
      if(tokenList.length > 0){
        tokenList.forEach((token: any) =>{
          if(array[0].assets.includes(token.name.toLowerCase())){
            for(let i=0; i < array[0].assetDetails.length; i++){
              if(array[0].assetDetails[i].name.toLowerCase() == token.name.toLowerCase()){
                token.is_approved = array[0].assetDetails[i].is_approved;
                token.tvl = array[0].assetDetails[i].tvl;
              }
            }
            token.inputValue = 0;
            token.inputValueWithPrecision = 0;
            // token.is_approved = token.name.toLowerCase() == 'busd' ? array[0].assetDetails[0].is_approved : token.name.toLowerCase() == 'usdt' ? array[0].assetDetails[1].is_approved : array[0].assetDetails[2].is_approved;
            // tokenArray.push(token);
            if(token.name.toLowerCase() == 'busd'){
              tokenArray[0] = token;
            }
            if(token.name.toLowerCase() == 'usdc'){
              tokenArray[1] = token;
            }
            if(token.name.toLowerCase() == 'usdt'){
              tokenArray[2] = token;
            }
          }
        })
        // console.log(tokenArray)
        setTokenArray(tokenArray);
        setConfirmLdData(tokenArray)
        setConfirmRemoveLdData(tokenArray);
        ResetInputData(false)
        stableTokenArray = tokenArray;
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const getFirstAllowance = async () => {
    try {
      if (firstToken.name !== 'BNB') {
        const firstAllowanceVal: any = await tokenAllowance(firstToken.address, selector.ethData.address)
        if (!!firstAllowanceVal && typeof firstAllowanceVal !== 'undefined') {
          setFirstAllowance(firstAllowanceVal)
        }
      }
    } catch (error) {
      setFirstAllowance('')
    }
  }
  const getSecondAllowance = async () => {
    try {
      if (secondToken.name !== 'BNB') {
        const secondAllowanceVal: any = await tokenAllowance(secondToken.address, selector.ethData.address)
        if (!!secondAllowanceVal && typeof secondAllowanceVal !== 'undefined') {
          setSecondAllowance(secondAllowanceVal)
        }
      }
    } catch (error) {
      setSecondAllowance('')
    }
  }
  const checkInSufficient = (value: any, type: any) => {
    const firstDAmt = firstToken.name === 'BNB' ? 0.01 : 0.0
    const secondDAmt = secondToken.name === 'BNB' ? 0.01 : 0.0
    if (type === 'first') {
      if (parseFloat(value) > parseFloat(firstToken.value) - firstDAmt) {
        setInSufMsg(`Insufficient ${firstToken.name} Balance`)
        setIsAmtInSuf(true)
      } else if (parseFloat(secondAmount) > parseFloat(secondToken.value) - secondDAmt) {
        setInSufMsg(`Insufficient ${secondToken.name} Balance`)
        setIsAmtInSuf(true)
      } else {
        setInSufMsg('')
        setIsAmtInSuf(false)
      }
    }
    if (type === 'second') {
      const decreaseAmt = secondToken.name === 'BNB' ? 0.01 : 0.0
      if (parseFloat(value) > parseFloat(secondToken.value) - decreaseAmt) {
        setInSufMsg(`Insufficient ${secondToken.name} Balance`)
        setIsAmtInSuf(true)
      } else if (parseFloat(firstAmount) > parseFloat(firstToken.value) - secondDAmt) {
        setInSufMsg(`Insufficient ${firstToken.name} Balance`)
        setIsAmtInSuf(true)
      } else {
        setInSufMsg('')
        setIsAmtInSuf(false)
      }
    }
  }

  const handleFirstAmount = async (e: any) => {
    const { value } = e.target
    if (floatNumRegex.test(value.toString())) {
      setFirstAmount(value)
      checkInSufficient(value, 'first')
      getFirstAllowance()
    }
    if (!value) {
      setFirstAmount('')
      checkInSufficient('0.00', 'first')
    }
  }
  const handleSecondAmount = async (e: any) => {
    const { value } = e.target
    if (floatNumRegex.test(value.toString())) {
      setSecondAmount(value)
      checkInSufficient(value, 'second')
      getSecondAllowance()
    }
    if (!value) {
      setSecondAmount('')
      checkInSufficient('0.00', 'second')
    }
  }

  const getExistTokenAdd = (type: string) => {
    if (type === 'first' && Object.values(secondToken).length > 0) {
      setExistTokeAdd(secondToken.address)
    }
    if (type === 'second' && Object.values(firstToken).length > 0) {
      setExistTokeAdd(firstToken.address)
    }
  }

  const handleTokenModal = (type: string) => {
    setTokenType(type)
    // getTokenList(type)
    getExistTokenAdd(type)
    setShowTokenModal(true)
  }


  const getTokenList = (type: string) => {
    let newTokenList: any = [...tokenList]
    if (type === 'first' && Object.values(secondToken).length > 0) {
      newTokenList = tokenList.filter((e: any) => e.sno !== secondToken.sno)
    }
    if (type === 'second' && Object.values(firstToken).length > 0) {
      newTokenList = tokenList.filter((e: any) => e.sno !== firstToken.sno)
    }
    setCurrTokenList([...newTokenList])
  }

  const getPairExistVal = async (token1: any, token2: any) => {
    try {
      const pairExist: any = await returnPairExistOrNot(token1.address, token2.address)
      setIsPairExist(pairExist)
      if (pairExist) {
        const pairAddressVal = await returnPairAddress(token1.address, token2.address)
        setPairAddress(pairAddressVal)
        // const pollDataVal: any = await userPoolDetails(token1.address, token2.address, pairAddressVal, selector.ethData.address)
        // setPoolDetails(pollDataVal)
      }
    } catch (error) {
      setIsPairExist(false)
      setPairAddress('')
    }
  }

  const handleTokenSelect = (selectedTokenData: any) => {
    if (selectedTokenData.address === existTokenAdd) {
      setFirstToken(secondToken)
      setSecondToken(firstToken)
      getPairExistVal(secondToken, firstToken)
    }
    else {
      if (tokenType === 'first') {
        setFirstToken(selectedTokenData)
        setFirstAmount('')
        if (Object.values(secondToken).length > 0) {
          getPairExistVal(selectedTokenData, secondToken)
        }
      }
      if (tokenType === 'second') {
        setSecondToken(selectedTokenData)
        setSecondAmount('')
        if (Object.values(firstToken).length > 0) {
          getPairExistVal(firstToken, selectedTokenData)
        }
      }
    }
    setExistTokeAdd('')
    setFirstAmount('')
    setSecondAmount('')
    handleToggleModal()
  }

  const handleToggleModal = () => {
    setTokenType('')
    setShowTokenModal(false)
  }

  const getApproveStatus = () => {
    const firstAmt = parseFloat(firstAmount)
    const secondAmt = parseFloat(secondAmount)
    const firstAl = parseFloat(firstAllowance)
    const secondAl = parseFloat(secondAllowance)
    if ((firstAmt > firstAl && firstToken.name !== 'BNB') || (secondAmt > secondAl && secondToken.name !== 'BNB')) {
      return true
    } else {
      return false
    }
  }

  const handleAddLdModal = async () => {
    try {
      //const res = await onAddLiquidityPopUp(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address)
      setCreateLpLoading(true)
      // if (!!res && typeof res !== 'undefined') {
      const data: any = {
        firstToken: firstToken,
        secondToken: secondToken,
        firstAmount: firstAmount,
        secondAmount: secondAmount,
        receivedTokens: Math.sqrt(parseFloat(firstAmount) * parseFloat(secondAmount)) - convertToEther(1000),
        poolShare: 100,
      }
      setConfirmLdData(data)
      setShowCnfModal(true)
    } catch (error) {
      console.log('error----', error)
      setConfirmLdData({})
      setShowCnfModal(false)
    } finally {
      setCreateLpLoading(false)
    }
  }

  const handleApprove = async (type: any) => {
    const address = type === 'first' ? firstToken.address : secondToken.address
    try {
      type === 'first' ? setApprove1Loading(true) : setApprove2Loading(true)
      const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, address)
      const approvalAmount = maxAmount
      await TOKEN_INSTANCE.methods
        .approve(PLANETROUTERADDRESS, approvalAmount)
        .send({ from: selector.ethData.address })
        .once('transactionHash', function (res: any) {
          if (!!res && typeof res) {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            setShowTransModal(true)
          }
        })
        .on('confirmation', function (confNumber: any, receipt: any) {
          if (receipt && typeof receipt !== 'undefined') {
            setApprove1Loading(false)
            setApprove2Loading(false)
            type === 'first' ? getFirstAllowance() : getSecondAllowance()
            updateLiquidity()
          }
        })
        .on('error', function (error: any) {
          setTransactionLink('')

          setApprove1Loading(false)
          setApprove2Loading(false)
        })
    } catch (error) {
    } finally {
      setApprove1Loading(false)
      setApprove2Loading(false)
    }
  }

  const handleTransactionLink = (newLink: any, hashVal: any) => {
    const data: any = {
      firstToken: firstToken.name,
      secondToken: secondToken.name,
      amount1: firstAmount,
      amount2: secondAmount,
      link: newLink,
      type: 'addedLiquidity',
      status: false,
      hashVal: hashVal,
      transStatus: 'pending',
    }
    setTransLink(data)
  }

  const removeNotification = (type: string, hash?: any) => {
    const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
    const msg = type === 'success' ? `Liquidity removed from ${stableSwapObject.poolName} stable swap` : `Failed to remove liquidity from ${stableSwapObject.poolName} stable swap`
    const dataVal: any = {
        type: type,
        message: msg,
        link: linkUrl
    }
    dispatch(notificationClose())
    dispatch(notificationOpen(dataVal))
  }

  const depositNotification = (type: string, hash?: any) => {
    const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
    const msg = type === 'success' ? `Liquidity added to ${stableSwapObject.poolName} stable swap` : `Failed to add liquidity to ${stableSwapObject.poolName} stable swap`
    const dataVal: any = {
        type: type,
        message: msg,
        link: linkUrl
    }
    dispatch(notificationClose())
    dispatch(notificationOpen(dataVal))
  }

  const handleNotification = (type: string, hash?: any) => {
    const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
    const data: any = {
      type: type,
      message: `Added liquidity for ${parseFloat(firstAmount).toFixed(4)} ${firstToken.name} and ${parseFloat(secondAmount).toFixed(4)} ${secondToken.name}`,
      link: linkUrl
    }
    dispatch(notificationClose())
    dispatch(notificationOpen(data))
  }

  const handleDepositInStableSwap = async () => {

    // console.log("all ok till here !", confirmLdData);
    let slippageFactor = 0;
    if (selector.ethData.ethWalletConnected) {
      try{
        setDepositLoading(true);
        let depositLiquidityData: any = await addLiquidityToPool(stableSwapAddress, [(confirmLdData[0].inputValueWithPrecision), (confirmLdData[1].inputValueWithPrecision), (confirmLdData[2].inputValueWithPrecision)], [18, 18, 18], 0, selector.ethData.address);
        // console.log(depositLiquidityData)
        const stable_pool_inst: any = new wallet.web3.eth.Contract(depositLiquidityData.stableswap_main_abi_nG, depositLiquidityData.poolAddress);
        await stable_pool_inst.methods.add_liquidity(depositLiquidityData.preciseLiquidityList, depositLiquidityData.totalStables * slippageFactor, selector.ethData.address).send({from: selector.ethData.address})
        .once('transactionHash', function (res: any) {
          if (!!res && typeof res) {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            setShowTransModal(true)
           
          }
        })
        .once('confirmation',function (confNumber: any, receipt: any){
          const type = receipt.status ? 'success' : 'failed'
          depositNotification(type, receipt.transactionHash)
          setDepositLoading(false);
          fetchSwapArray()
          dispatch(silentUpdateOnInfStake(selector.ethData.address))
          dispatch(updateUserLendingData(selector.ethData.address))
          ResetInputData(true);
          
        })
        .once('error', function(error: any){
          depositNotification("failed")
          setDepositLoading(false);
          // fetchSwapArray()
        })
      } catch(error){
        console.log("error=>",error)
      } finally {
        setDepositLoading(false)
        setShowCnfModal(false)
        updateLiquidity()

      }
    }
  }


  const handleCreatePool = async () => {
    try {
      setCreateLpLoading(true)
      if (firstToken.sno === bnbSno)
        await _createPool(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 0)
      else await _createPool(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 1)
    } catch (error) {
      console.log('error==>', error)
    } finally {
      setCreateLpLoading(false)
      setConfirmLdData({})
      setFirstAmount('')
      setSecondAmount('')
      updateLiquidity()
    }
  }

  const _createPool = async (tokenA: any, tokenB: any, amountADesiredInEther: any, amountBDesiredInEther: any, to: any, flag: any) => {
    /*
     * type : true =>  addLiquidityEth
     * type : false =>  addLiquidity
     * flag = 0 for bnb
     * flag = 1 for others
     */
    let type: boolean
    let amountAMinInEther: any = 0.008 * amountADesiredInEther
    let amountBMinInEther: any = 0.008 * amountBDesiredInEther
    amountAMinInEther = amountAMinInEther.toString()
    amountBMinInEther = amountBMinInEther.toString()

    const pair = await returnPairExistOrNot(tokenA, tokenB)
    if (!pair) {
      amountAMinInEther = '0'
      amountBMinInEther = '0'
    }
    if (flag === 0) type = true
    else type = false

    let deadline = Math.floor(Date.now() / 1000) + DEADLINE
    let txArray: any

    const ROUTER_INSTANCE = await selectInstance(instanceType.PLANETROUTER)
    if (type) {
      if (firstToken.sno === bnbSno) {
        txArray = [
          tokenB,
          convertToWei(amountBDesiredInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountADesiredInEther),
        ]
      } else {
        txArray = [
          tokenA,
          convertToWei(amountADesiredInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountBDesiredInEther),
        ]
      }

      await ROUTER_INSTANCE.methods
        .addLiquidityETH(txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({
          from: to,
          value: txArray[4],
        })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setCreateLpLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setCreateLpLoading(false)
        })
    } else {
      txArray = [
        convertToWei(amountADesiredInEther),
        convertToWei(amountBDesiredInEther),
        convertToWei(amountAMinInEther),
        convertToWei(amountBMinInEther),
      ]

      await ROUTER_INSTANCE.methods
        .addLiquidity(tokenA, tokenB, txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({ from: to })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setCreateLpLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setCreateLpLoading(false)
        })
    }
  }

  const handleMaxAmount = async () => {
    let amount: any
    if (!isPairExist) {
      if (firstToken.sno === bnbSno) {
        amount = firstToken.value
        amount -= 0.01
      } else amount = firstToken.value
      amount = getNumFormat(amount)
      setFirstAmount(amount)
    }
  }
  const handleSecondMaxAmount = async () => {
    let amount: any
    if (!isPairExist) {
      if (secondToken.sno === bnbSno) {
        amount = secondToken.value
        amount -= 0.01
      } else amount = secondToken.value
      amount = getNumFormat(amount)
      setSecondAmount(amount)
    }
  }

  const handleTabClick = (tabName: string) => {
    // history.push(`${Paths.root}${tabName}`)
    setActiveTab(tabName)
  }

  const showConfirmModal = async () => {
    // console.log(stableSwapObject.poolAddress, +removeAmountVal, removeLiquidityToken, removeTokenIndex, confirmRemoveLdData, tokenArray)
    const array: any = tokenArray;
    let withdrawAmount = removeLiquidityToken.toLowerCase() != stableSwapObject.poolAddress.toLowerCase() ? await withdrawTokenFromStableLP(stableSwapObject.poolAddress, removeTokenIndex, convertToWei(+removeAmountVal)) : 0;
    let withdrawAmountNormalised = convertToEther(withdrawAmount);
    let totalSupply = 0;
    if(removeLiquidityToken.toLowerCase() == stableSwapObject.poolAddress.toLowerCase()){
      let poolLpAddress = '0xc9440deb2a607a6f6a744a9d142b16ed824a1a3b';
      const lpInstance = await selectInstance(instanceType.PLANETLP, poolLpAddress)
      totalSupply = await _totalSupply(lpInstance);
      totalSupply = convertToEther(totalSupply)
      // console.log(totalSupply, confirmRemoveLdData)
    }
    confirmRemoveLdData.map((tokenObj: any, index: any) => {
      if(removeLiquidityToken.toLowerCase() == stableSwapObject.poolAddress.toLowerCase()){
        // console.log(+removeAmountVal, +tokenObj.tvl, +totalSupply, +removeAmountVal * +tokenObj.tvl/+totalSupply)
        confirmRemoveLdData[index].inputValue = +removeAmountVal * +tokenObj.tvl/+totalSupply;
        confirmRemoveLdData[index].inputValueWithPrecision = +removeAmountVal * +tokenObj.tvl/+totalSupply;
      } else {
        if(index == removeTokenIndex){  
          confirmRemoveLdData[removeTokenIndex].inputValue = withdrawAmountNormalised;
          confirmRemoveLdData[removeTokenIndex].inputValueWithPrecision = withdrawAmountNormalised;
        } else {
          confirmRemoveLdData[index].inputValue = 0;
          confirmRemoveLdData[index].inputValueWithPrecision = 0;
        }
      }
    })
    confirmRemoveLdData.minimum_received = +removeAmountVal;
    // console.log("token array value", confirmRemoveLdData, withdrawAmount, withdrawAmountNormalised);
    setConfirmRemoveLdData(confirmRemoveLdData)
    setShowRemoveCnfModal(true);
  }

  const removeStableLiquidityTokens = async() => {
    // console.log(stableSwapObject.poolAddress, +removeAmountVal, removeLiquidityToken, removeTokenIndex)
    if(removeLiquidityToken.toLowerCase() == stableSwapObject.poolAddress.toLowerCase()){
      if (selector.ethData.ethWalletConnected && parseFloat(removeAmountVal) > 0) {
        try{
          setRemoveLoading(true);
          let removeLiquidityData: any = await removeLiquidityFromPool(stableSwapObject.poolAddress, removeAmountVal, [0,0,0], [18, 18,18], selector.ethData.address);
          
          //await removeLiquidityData.stable_pool_inst.methods.remove_liquidity(BigInt(+removeAmountVal*10**removeLiquidityData.lpTokenDecimals), removeLiquidityData.preciseLiquidityList).send({from: selector.ethData.address})
          let revisedValue: any = convertToWei(removeAmountVal);
          revisedValue = BigInt(revisedValue);
          revisedValue = revisedValue.toString();
          // console.log( "remove liquidity combined tokens", removeLiquidityData, stableSwapObject.userLPTokens, revisedValue)
          await removeLiquidityData.stable_pool_inst.methods.remove_liquidity(revisedValue, removeLiquidityData.preciseLiquidityList).send({from: selector.ethData.address})
          .once('transactionHash', function (res: any) {
            if (!!res && typeof res) {
              const transLink = `${transLinkUrl}/${res}`
              setTransactionLink(transLink)
              setShowTransModal(true)
             
            }
          })
          .once('confirmation',function (confNumber: any, receipt: any){
            const type = receipt.status ? 'success' : 'failed'
            removeNotification(type, receipt.transactionHash)
            setRemoveLoading(false);
            fetchSwapArray()
            dispatch(silentUpdateOnInfStake(selector.ethData.address))
            dispatch(updateUserLendingData(selector.ethData.address))
            ResetInputData(true);
            setRemoveTokenIndex(3)
            setRemoveAmountVal('0')
          })
          .on('error', function(error: any){
            removeNotification("failed")
            setRemoveLoading(false);
            // fetchSwapArray()
          })

        } catch(error){
          console.log("error=>",error)
        } finally {
          setRemoveLoading(false)
          updateLiquidity()
        }
      }
      
    } else {
      if (selector.ethData.ethWalletConnected && parseFloat(removeAmountVal) > 0) {
        try{
          setRemoveLoading(true);
          let removeLiquidityData: any = await removeLiquidityFromPoolOneCoin(stableSwapObject.poolAddress, +removeAmountVal, 0, removeTokenIndex, 18, selector.ethData.address )
          //await removeLiquidityData.stable_pool_inst.methods.remove_liquidity_one_coin(BigInt(+removeAmountVal*10**removeLiquidityData.lpTokenDecimals), removeTokenIndex, 0).send({from: selector.ethData.address})
          let revisedValue: any = convertToWei(removeAmountVal);
          revisedValue = BigInt(revisedValue);
          revisedValue = revisedValue.toString();
          await removeLiquidityData.stable_pool_inst.methods.remove_liquidity_one_coin(revisedValue, removeTokenIndex, 0).send({from: selector.ethData.address})
          .once('transactionHash', function (res: any) {
            if (!!res && typeof res) {
              const transLink = `${transLinkUrl}/${res}`
              setTransactionLink(transLink)
              setShowTransModal(true)
             
            }
          })
          .once('confirmation',function (confNumber: any, receipt: any){
            const type = receipt.status ? 'success' : 'failed'
            removeNotification(type, receipt.transactionHash)
            setRemoveLoading(false);
            fetchSwapArray()
            dispatch(silentUpdateOnInfStake(selector.ethData.address))
            dispatch(updateUserLendingData(selector.ethData.address))
            ResetInputData(true);
            setRemoveTokenIndex(3)
            setRemoveAmountVal('0')
          })
          .on('error', function(error: any){
            removeNotification("failed")
            setRemoveLoading(false);
            updateLiquidity()
            // fetchSwapArray()
          })

        } catch(error){
          console.log("error=>",error)
        } finally {
          setRemoveLoading(false)
        }
      }
    }
  }

  const swapTokenBreakdown = () => {
    // console.log(stableSwapObject);
    if(stableSwapObject !== undefined && stableSwapObject.assetDetails !== undefined && stableSwapObject.assetDetails.length > 0){
      return stableSwapObject.assetDetails.map((token: any, index: any) => {
        // console.log(token.tvl)
        return (
          <FlexSBCont className="swap-const-data">
            <SwapConstTxt light>{token.name}</SwapConstTxt>
            <PriceImpactTxt className="swapTokenPrice">{convertToKAndM((token.assetTVL).slice(0, (token.assetTVL.indexOf("."))+3))}({parseFloat(((+token.assetTVL*100)/+stableSwapObject.tvl).toString()).toFixed(2)}%)</PriceImpactTxt>
          </FlexSBCont>
        )
      })
    }
    
  }

  return (
    <Fragment>
      <CardCont className="border">
        <CreateLpCont>
          <FlexSBCont className="stablePoolHeader">
            <BackButton className='backButton stablePoolHeader' onClick={() => setLiquidityTab('liquidity')}>
                <img className='backIcon' src={ArrowDownGrey} alt="back-btn" />
                <InfoText className="backButtonText" light>
                   Back
                </InfoText>
            </BackButton>
            {/* <TitleText className='addLiquidity'>Add Mixed Liquidity</TitleText> */}
            {/* <img className=" clock-icon" src={ClockIcon} alt="" onClick={() => setShowHistory(true)} /> */}
            <SwapContainer className='add'>
              <SwapHeaderContAlt className='addStable'>
                <SwapStatusTextAlt className='stableTabText' activeStatus={activeTab === 'add'} onClick={() => handleTabClick('add')} >
                  Add
                </SwapStatusTextAlt>
                <SwapStatusTextAlt className='stableTabText' activeStatus={activeTab === 'remove'} onClick={() => handleTabClick('remove')}>
                  Remove
                </SwapStatusTextAlt>
              </SwapHeaderContAlt>
              
            </SwapContainer>
          </FlexSBCont>
          {activeTab === 'add' && <AddStableSwapLiquidity tokenList={tokenList} setConfirmSwapBtn={setConfirmSwapBtn} confirmSwapCheck={confirmSwapBtn} setShowCnfModal={setShowCnfModal} showCnfModal={showCnfModal} setConfirmLdData={setConfirmLdData} tokenArray={tokenArray} setTokenArray={setTokenArray} stableSwapObject={stableSwapObject} setStableSwapObject={setStableSwapObject} depositLoading={depositLoading} resetInput={resetInput} />}
          {activeTab === 'remove' && <RemoveLiquidity tokenList={tokenList} setConfirmLdData={setConfirmLdData} stableSwapObject={stableSwapObject} tokenArray={tokenArray} setConfirmRemoveBtn={setConfirmRemoveBtn} confirmRemoveBtn={confirmRemoveBtn} setRemoveAmountVal={setRemoveAmountVal} setRemoveLiquidityToken={setRemoveLiquidityToken} setRemoveTokenIndex={setRemoveTokenIndex} removeTokenIndex={removeTokenIndex} setInsufficientBalanceCheck={setInsufficientBalanceCheck} setRemoveLoading={setRemoveLoading} removeLoading={removeLoading} resetInput={resetInput} setShowCnfModal={setShowCnfModal} />}
          {/* <InfoText className="create-lp" light>
            Add tokens to a liquidity pool
          </InfoText> */}
          
          
          {!isPairExist && Object.values(secondToken).length > 0 && (
            <>
              {parseFloat(firstAmount) > 0 && parseFloat(secondAmount) > 0 && (
                <PoolDetailsCont>
                  <InfoSmallText>Pool Details</InfoSmallText>
                  <FlexSBCont className="pool-info">
                    <PoolInfoText>{`${firstToken.name} Per ${secondToken.name}`}</PoolInfoText>
                    <InfoSmallText>
                      {!!poolDetails && firstAmount && secondAmount
                        ? (parseFloat(firstAmount) / parseFloat(secondAmount)).toFixed(4)
                        : parseFloat(poolDetails.price1).toFixed(4)}
                    </InfoSmallText>
                  </FlexSBCont>
                  <FlexSBCont className="pool-info">
                    <PoolInfoText>{`${secondToken.name} Per ${firstToken.name}`}</PoolInfoText>
                    <InfoSmallText>
                      {!!poolDetails && firstAmount && secondAmount
                        ? (parseFloat(secondAmount) / parseFloat(firstAmount)).toFixed(4)
                        : parseFloat(poolDetails.price2).toFixed(4)}
                    </InfoSmallText>
                  </FlexSBCont>
                  <FlexSBCont className="pool-info">
                    <PoolInfoText>Share Of Pool</PoolInfoText>
                    <InfoSmallText>100%</InfoSmallText>
                  </FlexSBCont>
                </PoolDetailsCont>
              )}
            </>
          )}
          <CreateLpBtnCont className="add-stable-liquidity" marginStatus={parseFloat(firstAmount) > 0 && parseFloat(secondAmount) > 0}>
            {depositLoading ? 
            <PrimaryBtn disabled>
              <Spinner/>
            </PrimaryBtn>
            :
            <>
            {!confirmSwapBtn && activeTab == "add" ? <PrimaryBtn disabled>{isAmtInSuf ? inSufMsg : 'Add Liquidity'}</PrimaryBtn> 
            : 
            ""
            }
            </>
            }
            { removeLoading ? 
            <PrimaryBtn disabled>
              <Spinner/>
            </PrimaryBtn>
            :
            <>
            {
              activeTab == "remove" && insufficientBalanceCheck == false ? <PrimaryBtn disabled={!confirmRemoveBtn ||  +removeAmountVal <= 0} onClick={showConfirmModal}>{isAmtInSuf ? inSufMsg : 'Remove Liquidity'}</PrimaryBtn>
              :
              activeTab == "remove" && insufficientBalanceCheck == true ?
              <PrimaryBtn disabled>{'Insufficient Liquidity'}</PrimaryBtn>
              :
              ""
            }
            </>
            }
            
          </CreateLpBtnCont>
        </CreateLpCont>
      </CardCont>
      {activeTab == "add" && stableSwapArray.length > 0 ? 
      <CardCont className="stableSwapDetails">
        <InfoText className="totalStableLiquidity" light>
          Total StableSwap Liquidity
        </InfoText>
        <InfoText className="stableSwapTVL" light>
          ${convertToKAndM(stableSwapObject.tvl)}
        </InfoText>
        <FlexSBCont className="donut_chart">
          <DonutChart tokenTVL={stableSwapObject.assetDetails} totalTVL={stableSwapObject.tvl}>
             <DonutHole></DonutHole>
          </DonutChart>
        </FlexSBCont>
        <SwapConstCard className="stable-swap-info">
          {swapTokenBreakdown()}
          {/* <FlexSBCont className="swap-const-data">
            <SwapConstTxt light>24h Trade Volume</SwapConstTxt>
            <PriceImpactTxt className="swapTokenPrice">${commaFy(stableSwapObject.oneDayTradeVolume)}</PriceImpactTxt>
          </FlexSBCont> */}
        </SwapConstCard>
      </CardCont>
      :
      ""
      }
      <CustomModal show={showTokenModal} toggleModal={handleToggleModal}>
        {showTokenModal && (
          <TokenSelect
            setShowTokenModal={setShowTokenModal}
            tokenListVal={currTokenList}
            handleTokenSelect={handleTokenSelect}
            existTokenAdd={existTokenAdd}
          />
        )}
      </CustomModal>
      <CustomModal show={showCnfModal} toggleModal={setShowCnfModal}>
        {showCnfModal && (
          <ConfirmLiquidity setShowCnfModal={setShowCnfModal} stableSwap={true} confirmLdData={confirmLdData} handleAddLiquidity={handleDepositInStableSwap} depositLoading={depositLoading} stableSwapObject={stableSwapObject} />
        )}
      </CustomModal>
      <CustomModal show={showRemoveCnfModal} toggleModal={setShowRemoveCnfModal}>
        {showRemoveCnfModal && (
          <ConfirmRemoveLiquidity setShowRemoveCnfModal={setShowRemoveCnfModal} stableSwap={true} confirmRemoveLdData={confirmRemoveLdData} handleRemoveLiquidity={removeStableLiquidityTokens} depositLoading={depositLoading} stableSwapObject={stableSwapObject} />
        )}
      </CustomModal>
      <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
        {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
      </CustomModal>
    </Fragment>
  )
}

export default CreateLpPair
