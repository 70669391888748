import {
  PLANETFACTORYABI,
  PLANETFACTORYADDRESS,
  PLANETLPABI,
  PLANETROUTERABI,
  PLANETROUTERADDRESS,
  zeroAddress,
  aquaZapInAddress,
  aquaZapInAbi,
  pcsRouterAddress,
  belt4Abi,
  convertLpAbi,
  aquaConvertLpAddress,
  wbnbAddress,
  oldZapAbi,
  oldZapAddress
} from './SwapDexAbi'
import {
  planetZapOneInchAddress,
  planetZapOneInchAbi,
  referralAddress,
  referralFees,
  protocols,
  thena_router_address,
  thena_router_abi,
  hypervisor_abi,
  planetZapOneInchAbiOld,
  planetZapOneInchAddressOld
} from './PlanetZapOneInchAbi'
import { aquaAddress, aquaFarmAbi, aquaFarmAddress, bnbAddress, busdAddress, gammaFarmAdddress, pancakFactoryAddress, gammaFarmAbi, priceOracleAbi, stableSwapAddress, usdcAddress, usdtAddress, gammaFarmAbiOld, pancakeLPabi, biswapRouterAddress, biswapRouterAbi, activeFusionPools, gammaFarmV3Abi, migrateToV3Address} from './abi'
import { BigNumber } from 'bignumber.js'
import { LpAddressList, planetAmmTradeList, returnSwapTokenList, returnTokenPriceMulticall } from './tokenList'
import {stableSwapPoolInfoList} from './pfTokenList'
import { signERC2612Permit } from 'eth-permit'
import { formatUnits } from '@ethersproject/units'
import { allowance, aquaPairPoolId, balanceOf, poolInfo, poolInfoNew, token1, token2, tokenList, totalSupply, getTokenPrice, instType, getAllowanceForStablePoolsMulticall, handleDeposit } from './BlockChainMethods'
import wallet from 'modules/wallet/wallet'
import { print } from './Routing'
import { decimals } from './gBNB'
import { newPFApiBaseUrl, planetFinanceApiBaseUrl } from "service/global-constant";
import axios from 'axios'
import {stableswap_main_address, stableswap_main_abi_nG, ERC20_abi, multicall_abi, multicall_address} from './abi'
import { MAX_INT, priceOracleAddress, gBnbAbi, maxAmount} from './lendingAbi'
import { transferGuardianPaused } from 'modules/block-chain-green_v1/gammatroller'
import { updateUserBalance, updateLPData, setPortfolioLoading } from 'logic/action/user.actions'
import { activeV3FusionPools, activeV3Pools } from './pfPoolsList'

export enum instanceType {
  'PLANETROUTER' = 'PLANETROUTER',
  'PCSROUTER' = 'PCSROUTER',
  'PLANETFACTORY' = 'PLANETFACTORY',
  'PLANETLP' = 'PLANETLP',
  'AQUAZAPINABI' = 'AQUAZAPINABI',
  'BELT4' = 'BELT4',
  'CONVERTLP' = 'CONVERTLP',
  'PCSFACTORY' = 'PCSFACTORY'
}

const chainId = 56;
const apiBaseUrl = 'https://pfapi.planet.finance/v1';//'https://api-planetfinance.1inch.io/v5.0/' + chainId;
//const apiBaseUrl = 'https://api.1inch.io/v5.0/' + chainId;
const apiRequestUrl:any = (methodName:any,  dst: any, src: any, amount: any, fee?:any) => {
  return fee == undefined ? apiBaseUrl + methodName + '/' + src + '/' + dst + '/' + amount : apiBaseUrl + methodName + '/' + src + '/' + dst + '/' + amount + '/' + fee;
}

const apiRequestUrlMigrate:any = (methodName:any,  dst: any, src: any, amount: any, fee:any, fromAddress: any) => {
  return apiBaseUrl + methodName + '/' + src + '/' + dst + '/' + amount + '/' + fee + '/' + fromAddress;
}

export const permitResult = async (pairAddress: any, userAddress: any, allowanceAmountInWei: any) => {
  console.log(allowanceAmountInWei)
  let res: any = await signERC2612Permit(
    //@ts-ignore
    await wallet.web3.currentProvider,
    pairAddress, // address of the token which have this permit function
    userAddress, // user address who will sign this message
    PLANETROUTERADDRESS, // address to whom we want to grant permit
    allowanceAmountInWei.toString() // balance amount (how much amount we want permit )
  ).catch((err: any) => {
    console.log(err)
  })
  return res
}

export const SLIPPAGE_TOLERANCE = 0.005 //0.5%

export const LIQUIDITY_PROVIDER_FEE = 0.0025 //0.25%

export const DEADLINE = 1200 //20 MINUTES

export const selectInstance = async (type: any, contractAddress?: any): Promise<any> => {
  switch (type) {
    case 'PLANETROUTER':
      return new wallet.web3.eth.Contract(PLANETROUTERABI, PLANETROUTERADDRESS)
    case 'PCSROUTER':
      return new wallet.web3.eth.Contract(PLANETROUTERABI, pcsRouterAddress)
    case 'BELT4':
      return new wallet.web3.eth.Contract(belt4Abi, "0xaea4f7dcd172997947809ce6f12018a6d5c1e8b6")
    case 'PLANETFACTORY':
      return new wallet.web3.eth.Contract(PLANETFACTORYABI, PLANETFACTORYADDRESS)
    case 'PCSFACTORY':
      return new wallet.web3.eth.Contract(PLANETFACTORYABI, pancakFactoryAddress)
    case 'PLANETLP':
      return new wallet.web3.eth.Contract(PLANETLPABI, contractAddress)
    case 'AQUAFARM':
      return new wallet.web3.eth.Contract(aquaFarmAbi, contractAddress)
    case 'AQUAZAPINABI':
      return new wallet.web3.eth.Contract(aquaZapInAbi, aquaZapInAddress)
    case 'CONVERTLP':
      return new wallet.web3.eth.Contract(convertLpAbi, aquaConvertLpAddress)
    case 'GAMMAFARM':
      return new wallet.web3.eth.Contract(gammaFarmAbi, contractAddress)
    case 'priceOracle':
      return new wallet.web3.eth.Contract(priceOracleAbi, contractAddress)
    default:
      return null
  }
}

export const convertToWei = (data: any) => {
  //decimals = Math.pow(10,decimals) // unnecessary fix
  data = noExponents(data)
  let x = new BigNumber(data)
  x = x.multipliedBy(1e18) //decimals = 1e18,1e8 etc.
  return x.toFixed(0,3)
}

export const convertToWeiInternal = (data: any, decimals: any) => {
  data = noExponents(data)
  let x = new BigNumber(data)
  x = x.multipliedBy(decimals) //decimals = 1e18,1e8 etc.
  return x.toFixed(0,3)
}

export const convertToEther: any = (data: any, decimals: number): any => {
  data = noExponents(data)
  return noExponents(formatUnits(data.toString(), decimals.toString())) //decimals = 18,8
}

export const convertToEtherRevised: any = (data: any, decimals: number): any => {
  data = noExponents(data)
  BigNumber.config({ROUNDING_MODE: BigNumber.ROUND_DOWN, DECIMAL_PLACES: 40,})
  const num = new BigNumber(data);
  const deno = new BigNumber(decimals);
  return num.dividedBy(deno).toString(); //1e18, 1e8
}

const noExponents = function (num: any) {
  var data = String(num).split(/[eE]/);
  if (data.length === 1) return data[0];

  var z = '', sign = num < 0 ? '-' : '',
    str = data[0].replace('.', ''),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
}

const getFinalBalance = (val: any) => {
  const newVal = new BigNumber(val).toString()
  return newVal
}

export const toBalance = (totalAmount: any) => {
  let x = new BigNumber(totalAmount)
  return x.div(1e18).toNumber()
}

export const returnPairAddress = async (tokenA: any, tokenB: any, protocol?: any) => {
  try {
    
    if(protocol !== undefined && protocol == 'thena'){
      const FACTORY_INSTANCE = new wallet.web3.eth.Contract(thena_router_abi, thena_router_address) 
      const pairAddress = await FACTORY_INSTANCE.methods.pairFor(tokenA, tokenB, false).call()
      // console.log("pair address if", tokenA, tokenB, pairAddress, protocol)
      return pairAddress !== zeroAddress ? pairAddress : zeroAddress
    } else {
      const FACTORY_INSTANCE = new wallet.web3.eth.Contract(PLANETFACTORYABI, PLANETFACTORYADDRESS) 
      const pairAddress = await FACTORY_INSTANCE.methods.getPair(tokenA, tokenB).call()
      // console.log("pair address else", tokenA, tokenB, pairAddress, protocol)
      return pairAddress !== zeroAddress ? pairAddress : zeroAddress
    }
  } catch (err) {
    console.log(err)
    return zeroAddress
  }
}

export const returnPcsPairAddress = async (tokenA: any, tokenB: any) => {
  try {
    const FACTORY_INSTANCE = await selectInstance(instanceType.PCSFACTORY)
    const pairAddress = await FACTORY_INSTANCE.methods.getPair(tokenA, tokenB).call()
    return pairAddress !== zeroAddress ? pairAddress : zeroAddress
  } catch (err) {
    console.log(err)
    return zeroAddress
  }
}

export const returnPairExistOrNot = async (tokenA: any, tokenB: any, protocol?: any): Promise<any> => {
  try {
    // console.log("protocol", protocol)
    const pairAddress = await returnPairAddress(tokenA, tokenB, protocol)
    return pairAddress !== zeroAddress ? true : false
  } catch (err) {
    console.log(err)
    return false
  }
}

export const returnOptimalPath = async (tokenA: string, tokenB: string, amountInEther: any) => {
  if (tokenA === tokenB) {
    return {
      path: [],
      amountOut: convertToWeiInternal(amountInEther,Math.pow(10,await decimals(tokenA)))
    }
  }
  let pathArr: any = [];

  const isPairExist = await returnPairExistOrNot(tokenA, tokenB)

  if (isPairExist) {
    pathArr.push([tokenA, tokenB])
  }

  if (tokenA !== bnbAddress && tokenB !== bnbAddress) {
    pathArr.push([tokenA, bnbAddress, tokenB])
  }
  else if (tokenA !== busdAddress && tokenB !== busdAddress) {
    pathArr.push([tokenA, busdAddress, tokenB])
  }
  const ROUTER_INSTANCE = await selectInstance(instanceType.PCSROUTER, pcsRouterAddress);
  let amount: any = [];
  const data = async () => {
    for (let i = 0; i < pathArr.length; i++) {
      const element = pathArr[i];
      const amountOut = await ROUTER_INSTANCE.methods.getAmountsOut(convertToWeiInternal(amountInEther,
        Math.pow(10,await decimals(element[0]))), element).call();
      amount.push(amountOut)
    }
    return amount;
  }
  amount = await data();

  let amount1;
  let amount2;
  if (amount.length === 2) {
    amount1 = parseFloat(convertToEther(amount[0][amount[0].length - 1],await decimals(pathArr[1])))
    amount2 = parseFloat(convertToEther(amount[1][amount[1].length - 1],await decimals(pathArr[2])))
  }
  else {
    amount1 = parseFloat(convertToEther(amount[0][amount[0].length - 1],await decimals(pathArr[1])))
    amount2 = 0
  }

  if (amount1 > amount2) {
    return {
      path: pathArr[0],
      amountOut: amount[0][amount[0].length - 1]
    }
  }
  else {
    return {
      path: pathArr[1],
      amountOut: amount[1][amount[1].length - 1]
    }
  }
}

const tokenWithSno: any = {

  "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991": 0,
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": 1,
  "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82": 2,
  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56": 3,
  "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d": 4,
  "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c": 5,
  "0x2170Ed0880ac9A755fd29B2688956BD959F933F8": 6,
  "0x23396cF899Ca06c4472205fC903bDB4de249D6fC": 7,
  "0x55d398326f99059fF775485246999027B3197955": 8,
  "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3": 9,
  "0x14016E85a25aeb13065688cAFB43044C2ef86784": 10,
  "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15": 11,
}

const swapTokenOptimalPathTemp: any = {
  // AQUA to other
  "0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991": {
    // to bnb
    "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c" : [
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
    ],
    // AQUA > BNB > GAMMA
    "0xb3cb6d2f8f2fde203a022201c81a96c167607f15" : [
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15"
    ],
    // AQUA > BNB > BTCB
    "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c" : [
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
    ],
    // AQUA > BNB > ETH
    "0x2170ed0880ac9a755fd29b2688956bd959f933f8" : [
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8"
    ],
    // AQUA > BNB > BUSD
    "0xe9e7cea3dedca5984780bafc599bd69add087d56" : [
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56"
    ],
    // AQUA > BNB > BUSD > USDC
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d" : [
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
    ],
    // AQUA > BNB > BUSD > USDT
    "0x55d398326f99059fF775485246999027B3197955" : [
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x55d398326f99059fF775485246999027B3197955"
    ],
    // AQUA > BNB > BUSD > DAI
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3" : [
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3"
    ],
  },
  // GAMMA to other
  "0xb3cb6d2f8f2fde203a022201c81a96c167607f15": {
    // to bnb
    "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c" : [
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
    ],
    // GAMMA > BNB > AQUA
    "0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991" : [
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991"
    ],
    // GAMMA > BNB > BTCB
    "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c" : [
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
    ],
    // GAMMA > BNB > ETH
    "0x2170ed0880ac9a755fd29b2688956bd959f933f8" : [
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8"
    ],
    // GAMMA > BNB > BUSD
    "0xe9e7cea3dedca5984780bafc599bd69add087d56" : [
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56"
    ],
    // GAMMA > BNB > BUSD > USDC
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d" : [
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
    ],
    // GAMMA > BNB > BUSD > USDT
    "0x55d398326f99059fF775485246999027B3197955" : [
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x55d398326f99059fF775485246999027B3197955"
    ],
    // GAMMA > BNB > BUSD > DAI
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3" : [
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3"
    ],
  },
  // BNB to other
  "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c": {
    // BNB > AQUA
    "0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991" : [
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991"
    ],
    // BNB > GAMMA
    "0xb3cb6d2f8f2fde203a022201c81a96c167607f15" : [
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15"
    ],
    // BNB > BTCB
    "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c" : [
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    ],
    // BNB > ETH
    "0x2170ed0880ac9a755fd29b2688956bd959f933f8" : [
      "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8"
    ],
    // BNB > BUSD
    "0xe9e7cea3dedca5984780bafc599bd69add087d56" : [
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56"
    ],
    // BNB > BUSD > USDC
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d" : [
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
    ],
    // BNB > BUSD > USDT
    "0x55d398326f99059fF775485246999027B3197955" : [
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x55d398326f99059fF775485246999027B3197955"
    ],
    // BNB > BUSD > DAI
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3" : [
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3"
    ],
  },
  // BTCB to other
  "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c": {
    // BTCB > BNB > AQUA
    "0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991" : [
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991"
    ],
    // BTCB > BNB > GAMMA
    "0xb3cb6d2f8f2fde203a022201c81a96c167607f15" : [
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15"
    ],
    // BTCB > BNB
    "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c" : [
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    ],
    // BTCB > BNB > ETH
    "0x2170ed0880ac9a755fd29b2688956bd959f933f8" : [
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8"
    ],
    // BTCB > USDT > BUSD
    "0xe9e7cea3dedca5984780bafc599bd69add087d56" : [
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      // "0x55d398326f99059fF775485246999027B3197955",//usdt
      "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",//bnb
      "0xe9e7cea3dedca5984780bafc599bd69add087d56"
    ],
    // BTCB > USDT > USDC
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d" : [
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "0x55d398326f99059fF775485246999027B3197955",
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
    ],
    // BTCB > USDT
    "0x55d398326f99059fF775485246999027B3197955" : [
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "0x55d398326f99059fF775485246999027B3197955"
    ],
    // BTCB > USDT > DAI
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3" : [
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "0x55d398326f99059fF775485246999027B3197955",
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3"
    ],
  },
  // ETH to other
  "0x2170ed0880ac9a755fd29b2688956bd959f933f8": {
    // ETH > BNB > AQUA
    "0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991" : [
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991"
    ],
    // ETH > BNB > GAMMA
    "0xb3cb6d2f8f2fde203a022201c81a96c167607f15" : [
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15"
    ],
    // ETH > BNB
    "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c" : [
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    ],
    // ETH > BNB > BTCB
    "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c" : [
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
    ],
    // ETH > BNB > BUSD
    "0xe9e7cea3dedca5984780bafc599bd69add087d56" : [
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56"
    ],
    // ETH > BNB > BUSD > USDC
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d" : [
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
    ],
    // ETH > BNB > BUSD > USDT
    "0x55d398326f99059fF775485246999027B3197955" : [
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x55d398326f99059fF775485246999027B3197955"
    ],
    // ETH > BNB > BUSD > DAI
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3" : [
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3"
    ],
  },
  // BUSD to other
  "0xe9e7cea3dedca5984780bafc599bd69add087d56": {
    // BUSD > BNB > AQUA
    "0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991" : [
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991"
    ],
    // BUSD > BNB > GAMMA
    "0xb3cb6d2f8f2fde203a022201c81a96c167607f15" : [
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15"
    ],
    // BUSD > BNB
    "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c" : [
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    ],
    // BUSD > USDT > BTCB
    "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c" : [
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      // "0x55d398326f99059fF775485246999027B3197955",//usdt
      "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",//bnb
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
    ],
    // BUSD > BNB > ETH
    "0x2170ed0880ac9a755fd29b2688956bd959f933f8" : [
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8"
    ],
    // BUSD > USDC
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d" : [
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
    ],
    // BUSD > USDT
    "0x55d398326f99059fF775485246999027B3197955" : [
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x55d398326f99059fF775485246999027B3197955"
    ],
    // BUSD > DAI
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3" : [
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3"
    ],
  },
  // USDC to other
  "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d": {
    // USDC > BUSD > BNB > AQUA 
    "0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991" : [
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991"
    ],
    // USDC > BUSD > BNB > GAMMA
    "0xb3cb6d2f8f2fde203a022201c81a96c167607f15" : [
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15"
    ],
    // USDC > BUSD > BNB
    "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c" : [
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    ],
    // USDC > USDT > BTCB
    "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c" : [
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      "0x55d398326f99059fF775485246999027B3197955",
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
    ],
    // USDC > BUSD > BNB > ETH
    "0x2170ed0880ac9a755fd29b2688956bd959f933f8" : [
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8"
    ],
    // USDC > BUSD 
    "0xe9e7cea3dedca5984780bafc599bd69add087d56" : [
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56"
    ],
    // USDC > USDT
    "0x55d398326f99059fF775485246999027B3197955" : [
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      "0x55d398326f99059fF775485246999027B3197955"
    ],
    // USDC > DAI
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3" : [
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3"
    ],
  },
  // USDT to other
  "0x55d398326f99059fF775485246999027B3197955": {
    // USDT > BUSD > BNB > AQUA
    "0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991" : [
      "0x55d398326f99059fF775485246999027B3197955",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991"
    ],
    // USDT > BUSD > BNB > GAMMA
    "0xb3cb6d2f8f2fde203a022201c81a96c167607f15" : [
      "0x55d398326f99059fF775485246999027B3197955",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15"
    ],
    // USDT > BUSD > BNB
    "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c" : [
      "0x55d398326f99059fF775485246999027B3197955",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    ],
    // USDT > BTCB
    "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c" : [
      "0x55d398326f99059fF775485246999027B3197955",
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
    ],
    // USDT > BTCB > BNB > ETH
    "0x2170ed0880ac9a755fd29b2688956bd959f933f8" : [
      "0x55d398326f99059fF775485246999027B3197955",
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8"
    ],
    // USDT > BUSD
    "0xe9e7cea3dedca5984780bafc599bd69add087d56" : [
      "0x55d398326f99059fF775485246999027B3197955",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56"
    ],
    // USDT > USDC
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d" : [
      "0x55d398326f99059fF775485246999027B3197955",
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
    ],
    // USDT > DAI
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3" : [
      "0x55d398326f99059fF775485246999027B3197955",
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3"
    ],
  },
  // DAI to other
  "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3": {
    // DAI > BUSD > BNB > AQUA
    "0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991" : [
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991"
    ],
    // DAI > BUSD > BNB > GAMMA
    "0xb3cb6d2f8f2fde203a022201c81a96c167607f15" : [
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15"
    ],
    // DAI > BUSD > BNB
    "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c" : [
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    ],
    // DAI > USDT > BTCB
    "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c" : [
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      "0x55d398326f99059fF775485246999027B3197955",
      "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
    ],
    // DAI > BUSD > BNB > ETH
    "0x2170ed0880ac9a755fd29b2688956bd959f933f8" : [
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8"
    ],
    // DAI > BUSD 
    "0xe9e7cea3dedca5984780bafc599bd69add087d56" : [
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56"
    ],
    // DAI > USDC
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d" : [
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
    ],
    // DAI > USDT
    "0x55d398326f99059fF775485246999027B3197955" : [
      "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      "0x55d398326f99059fF775485246999027B3197955"
    ],
  },

}

export const getPathForSwap = (from: string, to: string) => {
  // return ["0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15"];
  // console.log(" from", from, "to", to)
  if(from !== undefined && to !== undefined){
    return swapTokenOptimalPathTemp[from.toLocaleLowerCase()][to.toLocaleLowerCase()];
  } 
}

export const returnPlanetOptimalPathNew = async (tokenA: string, tokenB: string, amountInEther: any) => {
  let a = Object.keys(swapTokenOptimalPathTemp)
  // console.log("optimal path tokens", a, tokenA, tokenB)
  let stableTokenArray = [busdAddress.toLowerCase(), usdcAddress.toLowerCase(), usdtAddress.toLowerCase()];
  if(stableTokenArray.includes(tokenA.toLowerCase()) && stableTokenArray.includes(tokenB.toLowerCase())){
    // console.log("stable path check",[tokenA, tokenB], amountInEther)
    return {
      path: [tokenA, tokenB],
      amountOut: (amountInEther)
    };
  }
  if(tokenA !== undefined && tokenB !== undefined && !(a.includes(tokenA.toLowerCase()) && a.includes(tokenB.toLowerCase())))
  {
    // console.log("in undefined state")
    return undefined;
  }
  // console.log(" swap path check before", tokenA, tokenB)
  let p = tokenA !== undefined && tokenB !== undefined ? getPathForSwap(tokenA, tokenB) : [];
  // console.log(" swap path check", p, tokenA, tokenB)
  if(p == undefined) {
    return undefined;
  }
  
  let pathArr = [p];
  
  // console.log("pathArr", pathArr)
  let impacts: any = [];
  const data = async () => {
    try {
      for (let i = 0; i < pathArr.length; i++) {
        const element = pathArr[i];
        const impact = await returnPlanetPairImpacts(element, amountInEther)
        impacts.push(impact)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  await data();
  // console.log("impacts", impacts)

  //1) combine the arrays:

  let list = [];

  for (let j = 0; j < impacts.length; j++)
    list.push({ 'impact': impacts[j], 'path': pathArr[j] });

  //2) sort:
  list.sort(function (a, b) {
    return ((a.impact < b.impact) ? -1 : ((a.impact == b.impact) ? 0 : 1));
  });
  // console.log("list", list)
  //3) separate them back out:
  for (let k = 0; k < impacts.length; k++) {
    impacts[k] = list[k].impact;
    pathArr[k] = list[k].path;
  }

  // console.log('pathArr OOO', pathArr);
  
  const index = (impacts.length === 0) ? -1 : 0
  
  if (index !== -1) {
    const ROUTER_INSTANCE = await selectInstance(instanceType.PLANETROUTER, PLANETROUTERADDRESS);
    const amountOutArr = (await ROUTER_INSTANCE.methods.getAmountsOut(convertToWeiInternal(amountInEther,
      Math.pow(10,await decimals(pathArr[index][0]))), pathArr[index]).call());
    const amountOut = (amountOutArr[amountOutArr.length - 1])

    // console.log(
    //   {
    //     path: pathArr[index],
    //     amountOut: (amountOut)
    //   }
    // );
    return {
      path: pathArr[index],
      amountOut: (amountOut)
    }
  }
  else {
    return {
      path: [],
      amountOut: 0
    }
  }
}

export const returnPlanetOptimalPath = async (tokenA: string, tokenB: string, amountInEther: any) => {
  let res = await returnPlanetOptimalPathNew(tokenA, tokenB, amountInEther);
  // console.log("optimal path response", tokenA, tokenB, res)
  if(res != undefined)
  {
    return res;
  }

  if (tokenA === tokenB) {
    //console.log("tokenA === tokenB");
    return {
      path: [tokenA],
      amountOut: convertToWeiInternal(amountInEther,Math.pow(10, await decimals(tokenA)))
    }
  }

  let pathArr: any = [];

  if (pathArr.length === 0) {
    const token0Sno: number = tokenWithSno[tokenA]
    const token1Sno: number = tokenWithSno[tokenB]

    if (token0Sno === 0) { //AQUA
      if (token1Sno === 1) //BNB
        pathArr = [[tokenA, tokenB]];
      else if (token1Sno === 11) //GAMMA
        pathArr = [[tokenA, bnbAddress, tokenB]];
      else
        pathArr = print(token0Sno, token1Sno)
    }
    else if (token0Sno === 1) { //BNB
      if (token1Sno === 0) //AQUA
        pathArr = [[tokenA, tokenB]];
      else if (token1Sno === 11) //GAMMA
        pathArr = [[tokenA, tokenB]];
      else
        pathArr = print(token0Sno, token1Sno)
    }
    else if (token0Sno === 11) {//GAMMA
      if (token1Sno === 0) //AQUA
        pathArr = [[tokenA, bnbAddress, tokenB]];
      else if (token1Sno === 1) //BNB
        pathArr = [[tokenA, tokenB]];
      else
        pathArr = print(token0Sno, token1Sno)
    }
    else
      pathArr = print(token0Sno, token1Sno)

  }

  //pathArr = getPathForSwap(tokenA, tokenB)
  // console.log("pathArr", pathArr)
  let impacts: any = [];
  const data = async () => {
    try {
      for (let i = 0; i < pathArr.length; i++) {
        const element = pathArr[i];
        const impact = await returnPlanetPairImpacts(element, amountInEther)
        impacts.push(impact)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  await data();
  // console.log("impacts", impacts)

  //1) combine the arrays:

  let list = [];

  for (let j = 0; j < impacts.length; j++)
    list.push({ 'impact': impacts[j], 'path': pathArr[j] });

  //2) sort:
  list.sort(function (a, b) {
    return ((a.impact < b.impact) ? -1 : ((a.impact == b.impact) ? 0 : 1));
  });
  // console.log("list", list)
  //3) separate them back out:
  for (let k = 0; k < impacts.length; k++) {
    impacts[k] = list[k].impact;
    pathArr[k] = list[k].path;
  }

  // console.log('pathArr OOO', pathArr);
  
  const index = (impacts.length === 0) ? -1 : 0
  
  if (index !== -1) {
    const ROUTER_INSTANCE = await selectInstance(instanceType.PLANETROUTER, PLANETROUTERADDRESS);
    const amountOutArr = (await ROUTER_INSTANCE.methods.getAmountsOut(convertToWeiInternal(amountInEther,
      Math.pow(10,await decimals(pathArr[index][0]))), pathArr[index]).call());
    const amountOut = (amountOutArr[amountOutArr.length - 1])

    // console.log(
    //   {
    //     path: pathArr[index],
    //     amountOut: (amountOut)
    //   }
    // );
    return {
      path: pathArr[index],
      amountOut: (amountOut)
    }
  }
  else {
    return {
      path: [],
      amountOut: 0
    }
  }
}


export const returnAmountOfToken = async (amountInEth: any, tokenA: any, tokenB: any, filledToken: boolean) => {
  amountInEth = amountInEth.toString()
  // console.log('filledToken', filledToken, tokenA, tokenB)
  if (!!amountInEth && parseFloat(amountInEth) > 0) {
    try {

      let priceOfToken: any;
      let finalBalance: any;
      const tokenPath = (await returnPlanetOptimalPath(tokenA, tokenB, 2)).path
      // console.log('tokenPath returnAmountOfToken', tokenPath );
      const ROUTER_INSTANCE = await selectInstance(instanceType.PLANETROUTER)
      if (!filledToken) {
        priceOfToken = await ROUTER_INSTANCE.methods.getAmountsIn(convertToWeiInternal(amountInEth,
          Math.pow(10,await decimals(tokenB))), tokenPath).call()
        //console.log('priceOfToken returnAmountOfToken', priceOfToken );
        return priceOfToken;
      } else {
        priceOfToken = await ROUTER_INSTANCE.methods.getAmountsOut(convertToWeiInternal(amountInEth,
          Math.pow(10,await decimals(tokenA))), tokenPath).call()
        return priceOfToken;
      }
    } catch (err) {
      console.log(err)
    }
  }
  else {
    return '0'
  }
}

export const tokenBalance = async (tokenAddress: any, userAddress: any, flag: any): Promise<any> => {
  /*
  * flag = 0 for bnb currency
  * flag = 1 for other currency 
  */
  try {
    if (userAddress === null) {
      return 0
    } else if (flag === 0) {
      const balance = await wallet.web3.eth.getBalance(userAddress)
      let x = new BigNumber(balance)
      let balanceVal: any = x.div(1e18).toString()
      const finalBalance = getFinalBalance(balanceVal)
      return finalBalance
    } else {
      const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, tokenAddress)
      const balance = await TOKEN_INSTANCE.methods.balanceOf(userAddress).call()
      if (balance === 0) {
        return 0
      }
      let x = new BigNumber(balance)
      let balanceVal: any = x.div(1e18).toString()
      const finalBalance = getFinalBalance(balanceVal)
      return finalBalance
    }
  } catch (err) {
    console.log(err)
    return 0;
  }
}

export const returnTokenNameAndPrice = async (tokenAddress: any, userAddress: any) => {
  try {
    const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, tokenAddress)
    if (TOKEN_INSTANCE) {
      const name = await TOKEN_INSTANCE.methods.symbol().call()
      let balance: any
      balance = await TOKEN_INSTANCE.methods.balanceOf(userAddress).call()
      // if (tokenAddress !== wbnbAddress) 
      // else {
      //   balance = await (await web3()).eth.getBalance(userAddress)
      // }
      return {
        name: name,
        balance: convertToEther(balance,await decimals(tokenAddress)),
        address: tokenAddress,
      }
    }
  } catch (err) {
    console.log(err)
  }
}

export const tokenAllowancePlanetZapOneInch = async (tokenAddress: any, userAddress: any) => {
  try {
    const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, tokenAddress)
    const allowance = await TOKEN_INSTANCE.methods.allowance(userAddress, planetZapOneInchAddress).call()
    return convertToEther(allowance,await decimals(tokenAddress))
  } catch (err) {
    console.log(err)
  }
}

export const tokenAllowanceOneInch = async (tokenAddress: any, userAddress: any) => {
  try {
    const oneInchRouter = "0x1111111254EEB25477B68fb85Ed929f73A960582";
    const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, tokenAddress)
    const allowance = await TOKEN_INSTANCE.methods.allowance(userAddress, oneInchRouter).call()
    return convertToEther(allowance,await decimals(tokenAddress))
  } catch (err) {
    console.log(err)
  }
}

export const tokenAllowance: any = async (tokenAddress: any, userAddress: any, pool?: any, wantAddress? : any) => {
  try {
    let routerAddress: any = pool !== undefined && pool == 'thena' ? thena_router_address : pool !== undefined && pool == 'fusion' ? wantAddress : pool !== undefined && pool == 'biswap' ? biswapRouterAddress : PLANETROUTERADDRESS;
    // console.log("router",routerAddress, pool, tokenAddress)
    const TOKEN_INSTANCE: any = await selectInstance(instanceType.PLANETLP, tokenAddress)
    const allowance = await TOKEN_INSTANCE.methods.allowance(userAddress, routerAddress).call();
    // console.log(allowance)
    return convertToEther(allowance, 18)
  } catch (err) {
    console.log(err)
  }
}

export const stableSwapTokenAllowance = async (tokenAddress: any, userAddress: any, poolAddress: any) => {
  try {
    const tokenInstance: any = new wallet.web3.eth.Contract(gBnbAbi, tokenAddress)
    const allowance = await tokenInstance.methods.allowance(userAddress, poolAddress).call()
    return convertToEther(allowance,await decimals(tokenAddress))
  } catch (err) {
    console.log(err)
  }
}

export const onSelectingBothTokensOnSwap = async (tokenA: any, tokenB: any) => {
  try {
    const path: any = (await returnPlanetOptimalPath(tokenA, tokenB, 2)).path;
    // console.log("path inside onSelectingBothTokensOnSwap", path, tokenA, tokenB)
    let path2: any = []
    if (!!path && path.length > 0) {
      for (let i = path.length - 1; i >= 0; i--) {
        path2.push(path[i]);
      }
    }
    return {
      path1: path,
      path2: path2,
      tokenAPrice: await getTokenPrice(tokenA),
      tokenBPrice: await getTokenPrice(tokenB)
    }
  } catch (err) {
    console.log(err)
  }
}

export const returnTokenReserves = async (tokenA: any, tokenB: any) => {
  try {
    const pairAddress = await returnPairAddress(tokenA, tokenB)
    const proceed = pairAddress !== zeroAddress ? true : false
    if (proceed) {
      const LP_INSTANCE = await selectInstance(instanceType.PLANETLP, pairAddress)
      const getReserves = await LP_INSTANCE.methods.getReserves().call()
      const token0 = await LP_INSTANCE.methods.token0().call()
      const token1 = await LP_INSTANCE.methods.token1().call()
      if (tokenA === token0 && tokenB === token1)
        return {
          0: convertToEther(getReserves[0],await decimals(token0)),
          1: convertToEther(getReserves[1],await decimals(token1)),
        }
      else
        return {
          0: convertToEther(getReserves[1],await decimals(token1)),
          1: convertToEther(getReserves[0],await decimals(token0)),
        }
    } else
      return {
        0: 0,
        1: 0,
      }
  } catch (error) { }
}

export const returnPcsTokenReserves = async (tokenA: any, tokenB: any) => {
  try {
    const pairAddress = await returnPcsPairAddress(tokenA, tokenB)
    const proceed = pairAddress !== zeroAddress ? true : false
    if (proceed) {
      const LP_INSTANCE = await selectInstance(instanceType.PLANETLP, pairAddress)
      const getReserves = await LP_INSTANCE.methods.getReserves().call()
      const token0 = await LP_INSTANCE.methods.token0().call()
      const token1 = await LP_INSTANCE.methods.token1().call()
      if (tokenA === token0 && tokenB === token1)
        return {
          0: convertToEther(getReserves[0],await decimals(token0)),
          1: convertToEther(getReserves[1],await decimals(token1)),
        }
      else
        return {
          0: convertToEther(getReserves[1],await decimals(token1)),
          1: convertToEther(getReserves[0],await decimals(token0)),
        }
    } else
      return {
        0: 0,
        1: 0,
      }
  } catch (error) { }
}

const userLpBalance = async (lpInstance: any, userAddress: any) => {
  try{
    const userBalance = await lpInstance.methods.balanceOf(userAddress).call()
    return userBalance
  } catch(error) {
    console.log("error", error);
    return 0;
  }
}

const userTokenBalance = async (tokenAddress: any, userAddress: any) => {
  const lpInstance = await selectInstance(instanceType.PLANETLP, tokenAddress)
  const userBalance = await lpInstance.methods.balanceOf(userAddress).call()
  return userBalance
}

export const _totalSupply = async (lpInstance: any) => {
  const totalSupp = await lpInstance.methods.totalSupply().call()
  return totalSupp
}

export const returnUserPoolLiquidity = async (userAddress: any, poolLpAddress: any) => {
  const lpInstance = await selectInstance(instanceType.PLANETLP, poolLpAddress)

  const token0 = await lpInstance.methods.token0().call()
  const token1 = await lpInstance.methods.token1().call()
  const totalSupp: any = await _totalSupply(lpInstance)
  const bal: any = await userLpBalance(lpInstance, userAddress)

  let contractLiquidityBalance: any = '0';//await userLpBalance(lpInstance, poolLpAddress)
  contractLiquidityBalance = parseFloat(contractLiquidityBalance) + parseFloat(bal)
  contractLiquidityBalance = contractLiquidityBalance.toString()
  const contractToken0balance: any = await userTokenBalance(token0, userAddress)
  const contractToken1balance: any = await userTokenBalance(token1, userAddress)

  let poolToken0: any = (contractLiquidityBalance * contractToken0balance) / totalSupp
  let poolToken1: any = (contractLiquidityBalance * contractToken1balance) / totalSupp
  poolToken0 = poolToken0.toFixed(0).toString()
  poolToken1 = poolToken1.toFixed(0).toString()

  const poolShare = (bal / totalSupp) * 100
  if (bal !== 0|| bal !== 0)
    return {
      token0: token0,
      token1: token1,
      pooledToken0: convertToEther(poolToken0,await decimals(token0)),
      pooledToken1: convertToEther(poolToken1,await decimals(token1)),
      yourPoolToken: convertToEther(bal,18),
      poolShare: poolShare,
    }
  else
    return {
      token0: zeroAddress,
      token1: zeroAddress,
      pooledToken0: '0',
      pooledToken1: '0',
      yourPoolToken: '0',
      poolShare: '0',
    }
}

export const returnUserPoolLiquidityMulticall = async(userAddress: any, poolLpAddressArray: any) => {
  // console.log("poolLpAddressArray", poolLpAddressArray)
  let targets: any = [];
  let callDatas: any = [];
  let results:any = [];
  let ouput_format : any = [];
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);

  let token0Array : any = [];
  let token1Array : any = [];
  let totalLPSupplyArray : any = [];
  let lpBalanceArray : any = [];
  let token0BalanceInPoolArray : any = [];
  let token1BalanceInPoolArray : any = [];
  let poolTokenBalanceArray: any = [];
  let fusion_pool_want_array = ['0xd3c480ec7a47596ff8d63396227d1f7dc728a7f0', '0x10bf6e7b28b1cffb1c047d7f815953931e5ee947', '0x3ec1ffd5dc29190588608ae9fd4f93750e84cda2', '0x754fd74e22255780a58f125300008781d8318e3a', '0x2ecbd508c00bbc8aa0cdc9100bf3956fcabe7677', '0x88e1ce1e3e0171c83150844bb2f0bd2266802da2', '0xdf0b9b59e92a2554dedb6f6f4af6918d79dd54c4'];
  try{
    const lpInstance = new wallet.web3.eth.Contract(pancakeLPabi, "0x754Fd74e22255780a58F125300008781D8318e3A");
    poolLpAddressArray.forEach( async (pool: any) => {
      let wantAddress = pool.address.toLowerCase() == aquaAddress.toLowerCase() ? "0x43d427dF6957e180497248CaDc30cE7aa716FE52" : pool.address;
      targets.push(wantAddress);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(lpInstance.methods.token0()._method,[]));
      callDatas.push(data);
      ouput_format.push(lpInstance.methods.token0()._method.outputs)
    })

    poolLpAddressArray.forEach( async (pool: any) => {
      let wantAddress = pool.address.toLowerCase() == aquaAddress.toLowerCase() ? "0x43d427dF6957e180497248CaDc30cE7aa716FE52" : pool.address;
      targets.push(wantAddress);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(lpInstance.methods.token1()._method,[]));
      callDatas.push(data);
      ouput_format.push(lpInstance.methods.token1()._method.outputs)
    })

    poolLpAddressArray.forEach( async (pool: any) => {
      // const lpInstance = new wallet.web3.eth.Contract(PLANETLPABI, pool.address);
      targets.push(pool.address);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(lpInstance.methods.totalSupply()._method,[]));
      callDatas.push(data);
      ouput_format.push(lpInstance.methods.totalSupply()._method.outputs)
    })

    poolLpAddressArray.forEach( async (pool: any) => {
      const lp_instance = new wallet.web3.eth.Contract(PLANETLPABI, pool.address);
      targets.push(pool.address);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(lp_instance.methods.balanceOf(userAddress)._method,[userAddress]));
      callDatas.push(data);
      ouput_format.push(lp_instance.methods.balanceOf(userAddress)._method.outputs)
    })
    
    
    // console.log("targets", targets, callDatas)
    const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());
    // console.log("agg data", aggregated_data)
    const do_split = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }  

    for(let i = 0 ; i < aggregated_data[1].length ; i++){
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i],aggregated_data[1][i]))
    }
    const split_arr = (await do_split(results, poolLpAddressArray.length));
    // console.log("agg data", split_arr, poolLpAddressArray)
    token0Array = split_arr[0];
    token1Array = split_arr[1];
    totalLPSupplyArray = split_arr[2];
    lpBalanceArray = split_arr[3];
    // poolTokenBalanceArray = split_arr[4];
    let targets_1: any = [];
    let callDatas_1: any = [];
    let results_1: any = [];
    let ouput_format_1 : any = [];
    let user_lp_balance_array: any = [];
    let lpTokenArray: any = []
    poolLpAddressArray.forEach((pool: any) => {
      if(parseFloat(pool.lpBalance) > 0){
        lpTokenArray.push(pool);
      }
    });
    // console.log(lpTokenArray)
    lpTokenArray.forEach( async (pool: any) => {
      if(pool.address.toLowerCase() != aquaAddress.toLowerCase()){
        const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
        targets_1.push(planetZapOneInchAddress);
        
        if(fusion_pool_want_array.includes(pool.address.toLowerCase())){
          const data: any = (wallet.web3.eth.abi.encodeFunctionCall(planetZapInstance.methods.quoteRemoveLiquidityGammaUniproxy(pool.address, pool.token0Address, pool.lpBalance)._method,[pool.address, pool.token0Address, pool.lpBalance]));
          callDatas_1.push(data);
          ouput_format_1.push(planetZapInstance.methods.quoteRemoveLiquidityGammaUniproxy(pool.address, pool.token0Address, pool.lpBalance)._method.outputs)
        } else {
          const data: any = (wallet.web3.eth.abi.encodeFunctionCall(planetZapInstance.methods.quoteRemoveLiquidity(pool.address, pool.token0Address, pool.token1Address, pool.lpBalance)._method,[pool.address, pool.token0Address, pool.token1Address, pool.lpBalance]));
          callDatas_1.push(data);
          ouput_format_1.push(planetZapInstance.methods.quoteRemoveLiquidity(pool.address, pool.token0Address, pool.token1Address, pool.lpBalance)._method.outputs)
        }
      }
    })
    
    const aggregated_data_1 = (await multicall_inst.methods.aggregate(targets_1,callDatas_1).call());
    // console.log("agg data", aggregated_data_1)
    const do_split_1 = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split_1(array, n)) : [];
    }  

    for(let i = 0 ; i < aggregated_data_1[1].length ; i++){
      results_1.push(wallet.web3.eth.abi.decodeParameters(ouput_format_1[i],aggregated_data_1[1][i]))
    }
    const split_arr_1 = (await do_split_1(results_1, lpTokenArray.length));
    // token0BalanceInPoolArray = split_arr_1[0];
    // token1BalanceInPoolArray = split_arr_1[1];
    user_lp_balance_array = split_arr_1[0];
    lpTokenArray.forEach((pool: any, index:any) => {
      if(pool.address.toLowerCase() != aquaAddress.toLowerCase()){
        pool.amountA = user_lp_balance_array[index].amountA;
        pool.amountB = user_lp_balance_array[index].amountB;
        user_lp_balance_array[pool.address.toLowerCase()] = pool;
      }
    });
    // console.log("lp balance array",user_lp_balance_array,)
    let lpDataArray: any = [];
    for(let i: any = 0; i < poolLpAddressArray.length; i++){
      const token0: any = poolLpAddressArray[i].address.toLowerCase() == aquaAddress.toLowerCase() ? aquaAddress : token0Array[i][0];
      const token1: any = poolLpAddressArray[i].address.toLowerCase() == aquaAddress.toLowerCase() ? aquaAddress : token1Array[i][0];
      const totalSupp: any = convertToEther(totalLPSupplyArray[i][0], 18);
      const bal: any = convertToEther(lpBalanceArray[i][0], 18);
      if(poolLpAddressArray[i].address.toLowerCase() == aquaAddress.toLowerCase() ){
        user_lp_balance_array[aquaAddress.toLowerCase()] = bal;
      }
      
      const poolShare = (bal / totalSupp) * 100
      let lpData: any;
      // let contractToken0balance = convertToEther(token0BalanceInPoolArray[i][0], 18);
      // let contractToken1balance = convertToEther(token1BalanceInPoolArray[i][0], 18);
      if (bal !== 0|| bal !== 0){

        // console.log(poolLpAddressArray[i])
        let poolToken0: any = user_lp_balance_array[poolLpAddressArray[i].address.toLowerCase()] !== undefined && poolLpAddressArray[i].address.toLowerCase() == aquaAddress.toLowerCase() ? user_lp_balance_array[aquaAddress.toLowerCase()] : user_lp_balance_array[poolLpAddressArray[i].address.toLowerCase()] !== undefined ? convertToEther(user_lp_balance_array[poolLpAddressArray[i].address.toLowerCase()].amountA, 18) : 0;//(bal * contractToken0balance) / totalSupp
        let poolToken1: any = user_lp_balance_array[poolLpAddressArray[i].address.toLowerCase()] !== undefined && poolLpAddressArray[i].address.toLowerCase() == aquaAddress.toLowerCase() ? 0 : user_lp_balance_array[poolLpAddressArray[i].address.toLowerCase()] !== undefined ? convertToEther(user_lp_balance_array[poolLpAddressArray[i].address.toLowerCase()].amountB, 18) : 0;//(bal * contractToken1balance) / totalSupp
        // poolToken0 = poolToken0.toFixed(3).toString()
        // poolToken1 = poolToken1.toFixed(3).toString()
        lpData = {
          token0: token0,
          token1: token1,
          pooledToken0: poolToken0, //poolTokenBalanceArray[i].amountA > 0 ? convertToEther(poolTokenBalanceArray[i].amountA, 18) : 0,
          pooledToken1: poolToken1, //poolTokenBalanceArray[i].amountB > 0 ? convertToEther(poolTokenBalanceArray[i].amountB, 18) : 0,
          yourPoolToken: bal,
          poolShare: poolShare,
        }
      } else {
        lpData = {
          token0: zeroAddress,
          token1: zeroAddress,
          pooledToken0: '0',
          pooledToken1: '0',
          yourPoolToken: '0',
          poolShare: '0',
        }
      }
      lpDataArray.push(lpData)
    }
    // console.log(lpDataArray)
    return lpDataArray;
  } catch (error) {
    console.log("error", error)
    return []
  }
}

export const fetch_lp_price = async () => {
  let targets_: any = [];
  let callDatas_: any = [];
  let results_: any = [];
  let ouput_format_: any = [];
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  let wantAddressArray: any = ["0x1d6F3e6e0C98BAbA086fC2a9d12d79841280DB8a"];
  let reserves_arr: any = [];
  let total_supply_arr: any = [];
  let token1_arr: any = [];
  let token0_arr: any = [];
  let token_price_array = await returnTokenPriceMulticall();

  //reserves
  wantAddressArray.forEach((wantAddress: any) => {
    targets_.push(wantAddress)
    const lp_instance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
    const data = (wallet.web3.eth.abi.encodeFunctionCall(lp_instance.methods.getReserves()._method, []));
    callDatas_.push(data);
    ouput_format_.push(lp_instance.methods.getReserves()._method.outputs)
  })

  //total supply
  wantAddressArray.forEach((wantAddress: any) => {
    const lp_instance: any = new wallet.web3.eth.Contract(pancakeLPabi,wantAddress)
    targets_.push(wantAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(lp_instance.methods.totalSupply()._method, []));
    callDatas_.push(data);
    ouput_format_.push(lp_instance.methods.totalSupply()._method.outputs)
  })

  //token 0 
  wantAddressArray.forEach((wantAddress: any) => {
    const lp_instance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
    targets_.push(wantAddress)
    const data = (wallet.web3.eth.abi.encodeFunctionCall(lp_instance.methods.token0()._method, []));
    callDatas_.push(data);
    ouput_format_.push(lp_instance.methods.token0()._method.outputs)
  })

  //token 1 
  wantAddressArray.forEach((wantAddress: any) => {
    const lp_instance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
    //let wantAddress = pool_data['want'].toLowerCase() == cakeTokenAddress.toLowerCase() && farmAddress.toLowerCase() == aquaFarmAddress.toLowerCase() ? "0xFc7AC742C051Ca19B9eA5725280eAAB5b3073534" : pool_data['want'].toLowerCase() == cakeTokenAddress.toLowerCase() && farmAddress.toLowerCase() == gammaFarmAdddress.toLowerCase() ? "0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b" : pool_data['want'];
    targets_.push(wantAddress)
    const data = (wallet.web3.eth.abi.encodeFunctionCall(lp_instance.methods.token1()._method, []));
    callDatas_.push(data);
    ouput_format_.push(lp_instance.methods.token1()._method.outputs)
  })

  const aggregated_data_ = await multicall_inst.methods.aggregate(targets_, callDatas_).call()
  const do_split_ = async (array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split_(array, n)) : []
  }

  for (let i = 0; i < aggregated_data_[1].length; i++) {
    results_.push(wallet.web3.eth.abi.decodeParameters(ouput_format_[i], aggregated_data_[1][i]))
  }
  // console.log("results", results_)
  const split_arr_ = await do_split_(results_, wantAddressArray.length)
  reserves_arr = split_arr_[0];
  total_supply_arr = split_arr_[1];
  token0_arr = split_arr_[2];
  token1_arr = split_arr_[3];

  let lp_price_arr: any = [];
  for(let i = 0; i < wantAddressArray.length; i++){
    let token0price: any = token_price_array[token0_arr[i][0].toLowerCase()];
    let token1price: any = token_price_array[token1_arr[i][0].toLowerCase()];
    let reserve0 = reserves_arr[i]['_reserve0'] ? parseFloat(reserves_arr[i]['_reserve0']) : 0;
    let reserve1 = reserves_arr[i]['_reserve1'] ? parseFloat(reserves_arr[i]['_reserve1']) : 0;
    let assetPrice: any = (reserve0 * token0price + reserve1 * token1price) / total_supply_arr[i][0];
    // console.log("lp price", assetPrice);
    lp_price_arr[wantAddressArray[i].toLowerCase()] = assetPrice
  }
  
  return lp_price_arr;
}

export const returnUserLiquidity = async (userAddress: any) => {
  let res: any = []
  // let userLiquidityArray: any = []
  let lpPairArray: any = []
  let poolData = [];
  try {
    poolData = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
  } catch (error) {
    try {
      poolData = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
    } catch (error) {
      console.log('error in fetching poolDataNew', error);
    }
  }
  const list = await LpAddressList(userAddress)
  // console.log("LpAddressList", list)
  const userLiquidityArray: any = await returnUserPoolLiquidityMulticall(userAddress, list)
  // console.log("userLiquidityArray", userLiquidityArray)
  list.forEach((lpPair: any) => {
    //let userLiquidity = returnUserPoolLiquidity(userAddress, lpPair.address)
    //userLiquidityArray.push(userLiquidity)
    lpPairArray.push(lpPair)
  })
  // console.log("userLiquidityArray", userLiquidityArray)
  // userLiquidityArray = await Promise.all(userLiquidityArray)
  let lp_price_array = await fetch_lp_price();
  for (let i = 0; i < list.length; i++) {
    if (parseFloat(userLiquidityArray[i].yourPoolToken) !== 0) {
      // console.log("pool liquidity", userLiquidityArray[i])
      let price: any = poolData.pools.active.find((lpToken: any) => {
        return lpToken.wantAddress.toLowerCase() == lpPairArray[i].address.toLowerCase() ? lpToken.price : 0
      });
      if(price == undefined || price == 0){
        price = poolData.pools.finished.find((lpToken: any) => {
          return lpToken.wantAddress.toLowerCase() == lpPairArray[i].address.toLowerCase() ? lpToken.price : 0
        });
      }
      if(price == undefined || price == 0){
        price = poolData.newPools.active.find((lpToken: any) => {
          return lpToken.wantAddress.toLowerCase() == lpPairArray[i].address.toLowerCase() ? lpToken.price : 0
        });
      }
      if(price == undefined || price == 0){
        price = poolData.newPools.finished.find((lpToken: any) => {
          return lpToken.wantAddress.toLowerCase() == lpPairArray[i].address.toLowerCase() ? lpToken.price : 0
        });
      }

      if(price == undefined || price == 0){
        price = poolData.v3Pools.active.find((lpToken: any) => {
          return lpToken.wantAddress.toLowerCase() == lpPairArray[i].address.toLowerCase() ? lpToken.price : 0
        });
      }

      if(price == undefined || price == 0){
        price = lp_price_array[lpPairArray[i].address.toLowerCase()] ? {price: lp_price_array[lpPairArray[i].address.toLowerCase()]} : 0;
      }
      const lpPrice = price !== undefined ? price.price : 0;
      // console.log(parseFloat(userLiquidityArray[i].yourPoolToken) * (price.price) > 0.01, userLiquidityArray[i].yourPoolToken, price.price)
      if(parseFloat(userLiquidityArray[i].yourPoolToken) * lpPrice > 0.01){
        res.push({
          token0: userLiquidityArray[i].token0,
          token1: userLiquidityArray[i].token1,
          pooledToken0: userLiquidityArray[i].pooledToken0,
          pooledToken1: userLiquidityArray[i].pooledToken1,
          yourPoolToken: userLiquidityArray[i].yourPoolToken,
          poolShare: userLiquidityArray[i].poolShare,
          address: lpPairArray[i].address,
          price: lpPrice,
          ...lpPairArray[i],
        })
      }     
    }
  }
  // console.log("res", res)
  return res
}

export const returnQuotePrice = async (amountInEther: any, flag: boolean, pairAddress: any, tokenA: any, pool?: any,poolType?: any) => {
  amountInEther = amountInEther.toString()
  /*
   * flag = true if token1 amount is given
   * flag = false if token2 amount is given
   */
  if (parseFloat(amountInEther)) {
    const lpInstance = await selectInstance(instanceType.PLANETLP, pairAddress)
    const token0 = await lpInstance.methods.token0().call()
    const token1 = await lpInstance.methods.token1().call()
    let ROUTER_INSTANCE = pool !== undefined && pool == 'thena' ? new wallet.web3.eth.Contract(thena_router_abi, thena_router_address) : pool !== undefined && pool == 'biswap' ? new wallet.web3.eth.Contract(biswapRouterAbi , biswapRouterAddress) : await selectInstance(instanceType.PLANETROUTER);
    // console.log(token0, token1, poolType, pool)
    let getReserves: any;
    if(pool !== undefined && pool !== 'fusion' || pool == undefined){
      getReserves = pool !== undefined && pool == 'thena' && poolType !== undefined ? await ROUTER_INSTANCE.methods.getReserves(token0, token1, poolType).call(): await lpInstance.methods.getReserves().call();
    }
    // console.log("getReserves", getReserves, amountInEther)
    ROUTER_INSTANCE = await selectInstance(instanceType.PLANETROUTER)
    let quotePrice: any

    let planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress)
    if(pool !== undefined && pool != 'planet' && pool != 'biswap' && poolType !== undefined && poolType == true){
      
      let stableRatio: any = await planetZapInstance.methods.quoteStableAddLiquidityRatio(pairAddress, thena_router_address).call();
      // console.log("stable ratio", stableRatio)
      let tokenVal : any;
      let tokenVal1 : any;
      let otherRatio: any = BigNumber("1e18").minus(BigNumber(stableRatio)).toFixed(0,3).toString()
      if(tokenA === token0){
        // console.log("in stable if", tokenA, token0, token1)
        tokenVal = BigNumber(convertToWei(amountInEther)).multipliedBy(BigNumber(stableRatio)).dividedBy(BigNumber(otherRatio)).toFixed(0,3).toString() 
      } else {
        // console.log("in stable else", tokenA, token0, token1)
        tokenVal = BigNumber(convertToWei(amountInEther)).multipliedBy(BigNumber(otherRatio)).dividedBy(BigNumber(stableRatio)).toFixed(0,3).toString()
      }
      // console.log(convertToEther(tokenVal, 18), tokenVal)
      return convertToEther(tokenVal, 18);
    }
    if(pool !== undefined && pool == 'fusion'){
      let gammaUniproxyRouter  = '0xf75c017e3b023a593505e281b565ed35cc120efa'//"0x6b3d98406779ddca311e6c43553773207b506fa6";
      //LPAddress = "0xD3C480EC7a47596fF8D63396227d1F7dC728A7f0";
      let unStableRange: any = await planetZapInstance.methods.getSecondTokenDepositAmount( pairAddress, tokenA == token0 ? token0 : token1, convertToWei(amountInEther) , gammaUniproxyRouter).call();
      let _otherTokenAmountMin = BigNumber(unStableRange[0]);
      let _otherTokenAmountMax = BigNumber(unStableRange[1]);
      let average:any = _otherTokenAmountMin.plus(_otherTokenAmountMax).dividedBy(BigNumber("2")).toFixed(0,3);
      // let tokenBAverage = BigNumber(convertToEther(average,18));
      // console.log("avg token b",  convertToEther(average, 18), unStableRange, pairAddress, tokenA == token0 ? token0 : token1, convertToWei(amountInEther) , gammaUniproxyRouter)
      return convertToEther(average, 18);
    }

    if (flag) {
      if (tokenA === token0) quotePrice = await ROUTER_INSTANCE.methods.quote(convertToWeiInternal(amountInEther,
        Math.pow(10,await decimals(token0))), getReserves[0], getReserves[1]).call()
      else quotePrice = await ROUTER_INSTANCE.methods.quote(convertToWeiInternal(amountInEther,
        Math.pow(10,await decimals(token0))), getReserves[1], getReserves[0]).call()
      if (tokenA === token0) 
      return convertToEther(quotePrice,await decimals(token1))
      else
      return convertToEther(quotePrice,await decimals(token0))
    } else {
      if (tokenA === token0) quotePrice = await ROUTER_INSTANCE.methods.quote(convertToWeiInternal(amountInEther,
        Math.pow(10,await decimals(token1))), getReserves[1], getReserves[0]).call()
      else quotePrice = await ROUTER_INSTANCE.methods.quote(convertToWeiInternal(amountInEther,
        Math.pow(10,await decimals(token1))), getReserves[0], getReserves[1]).call()
        if (tokenA === token0) 
        return convertToEther(quotePrice,await decimals(token0))
        else
        return convertToEther(quotePrice,await decimals(token1))
    }
  }
  return 0
}

export const userPoolDetails = async (tokenA: any, tokenB: any, pairAddress: any, userAddress: any) => {
  const lpInstance = await selectInstance(instanceType.PLANETLP, pairAddress)
  let amount1: any = returnQuotePrice(1, true, pairAddress, tokenA) //amount of tokenB per tokenA
  let amount2: any = returnQuotePrice(1, false, pairAddress, tokenA) //amount of tokenA per tokenB
  const bal: any = userLpBalance(lpInstance, userAddress)
  const totalSupp: any = _totalSupply(lpInstance)
  let promiseArray: any = [amount2, amount1, bal, totalSupp]
  promiseArray = await Promise.all(promiseArray)
  const poolShare = (promiseArray[2] / promiseArray[3]) * 100
  return {
    price1: promiseArray[0],
    price2: promiseArray[1],
    poolShare: poolShare,
  }
}

export const userPoolDetailsNew = async (tokenA: any, tokenB: any, pairAddress: any, userAddress: any) => {
  const lpInstance = await selectInstance(instanceType.PLANETLP, pairAddress)
  // let amount1: any = returnQuotePrice(1, true, pairAddress, tokenA) //amount of tokenB per tokenA
  // let amount2: any = returnQuotePrice(1, false, pairAddress, tokenA) //amount of tokenA per tokenB
  // const bal: any = userLpBalance(lpInstance, userAddress)
  // const totalSupp: any = _totalSupply(lpInstance)
  // let promiseArray: any = [amount2, amount1, bal, totalSupp]
  // promiseArray = await Promise.all(promiseArray)
  // const poolShare = (promiseArray[2] / promiseArray[3]) * 100
  // return {
  //   price1: promiseArray[0],
  //   price2: promiseArray[1],
  //   poolShare: poolShare,
  // }
  try{
    
    let amount1: any = await getQuote(tokenA, tokenB, 1e18) //amount of tokenB per tokenA
    let delayres = await delay(2000);
    let amount2: any = await getQuote(tokenB, tokenA, 1e18) //amount of tokenA per tokenB
    // console.log("token A comparison", amount1)
    return {
        price1: convertToEther(amount1.toTokenAmount, 18),
        price2: convertToEther(amount2.toTokenAmount, 18),
        poolShare: 0,
      }
  } catch(error){
    console.log(error);
    return {
      price1: 0,
      price2: 0,
      poolShare: 0,
    }
  }
}

export const onAddLiquidityPopUp = async (tokenA: any, tokenB: any, amount0InEth: any, amount1InEth: any, userAddress: any) => {
  const amount0: any = convertToWeiInternal(amount0InEth, Math.pow(10,await decimals(tokenA)))
  const amount1: any = convertToWeiInternal(amount1InEth,  Math.pow(10,await decimals(tokenB)))
  const poolLpAddress: any = await returnPairAddress(tokenA, tokenB)
  const lpInstance = await selectInstance(instanceType.PLANETLP, poolLpAddress)
  const token0 = await lpInstance.methods.token0().call()
  const getReserves = await lpInstance.methods.getReserves().call()

  let _reserve0: any = 0
  let _reserve1: any = 0
  if (tokenA === token0) {
    _reserve0 = parseFloat(getReserves[0])
    _reserve1 = parseFloat(getReserves[1])
  } else {
    _reserve0 = parseFloat(getReserves[1])
    _reserve1 = parseFloat(getReserves[0])
  }

  const totalSupp: any = parseFloat(await lpInstance.methods.totalSupply().call())
  const a: any = (amount0 * totalSupp) / _reserve0
  const b: any = (amount1 * totalSupp) / _reserve1
  const liquidity: any = convertToEther(Math.min(a, b).toFixed(0),18)
  // const bal: any = await userLpBalance(lpInstance, userAddress)
  const share: any = parseFloat(liquidity) / parseFloat((convertToEther(totalSupp,18)) + parseFloat(liquidity))
  return {
    receivedTokens: liquidity,
    poolShare: share * 100,
  }
}

export const onAddLiquidityPopUpNew = async (tokenA: any, tokenB: any, amount0InEth: any, amount1InEth: any, userAddress: any, wantAddress: any, poolType? : any) => {
  // console.log(tokenA, tokenB, wantAddress)
  try{
    const amount0: any = convertToWeiInternal(amount0InEth, Math.pow(10,await decimals(tokenA)))
    const amount1: any = convertToWeiInternal(amount1InEth,  Math.pow(10,await decimals(tokenB)))
    //const poolLpAddress: any = await returnPairAddress(tokenA, tokenB)
    const lpInstance = poolType !== undefined && poolType == 'fusion' ? new wallet.web3.eth.Contract(hypervisor_abi, wantAddress) : new wallet.web3.eth.Contract(PLANETLPABI, wantAddress)
    const token0 = await lpInstance.methods.token0().call()
    let getReserves : any;
    if(poolType !== undefined && poolType == 'fusion'){
      getReserves = await lpInstance.methods.getTotalAmounts().call();
      // console.log("get fusion reserves", getReserves)
      getReserves = [getReserves.total0, getReserves.total1]
    } else {
      getReserves = await lpInstance.methods.getReserves().call()
    }

    let _reserve0: any = 0
    let _reserve1: any = 0
    if (tokenA === token0) {
      _reserve0 = parseFloat(getReserves[0])
      _reserve1 = parseFloat(getReserves[1])
    } else {
      _reserve0 = parseFloat(getReserves[1])
      _reserve1 = parseFloat(getReserves[0])
    }

    const totalSupp: any = parseFloat(await lpInstance.methods.totalSupply().call())
    const a: any = (amount0 * totalSupp) / _reserve0
    const b: any = (amount1 * totalSupp) / _reserve1
    const liquidity: any = convertToEther(Math.min(a, b).toFixed(0),18)
    // const bal: any = await userLpBalance(lpInstance, userAddress)
    const share: any = parseFloat(liquidity) / parseFloat((convertToEther(totalSupp,18)) + parseFloat(liquidity))
    return {
      receivedTokens: liquidity,
      poolShare: share * 100,
    }
  } catch (error){
    console.log(error)
    return {
      receivedTokens: 0,
      poolShare: 0 
    }
  }
}


export const retLpDetOnRmLd = async (lpPairAddress: any) => {
  try {
    const FACTORY_INSTANCE = await selectInstance(instanceType.PLANETFACTORY);
    // const iconList:any[] = [BUSDIcon,USDTIcon,USDCIcon,BNBIcon,AQUAIcon,DAIIcon]
    const pairArrayLength = parseFloat(await FACTORY_INSTANCE.methods.allPairsLength().call())
    let lpPresent: boolean = false;
    let presentLpPair: any = [];

    for (let i = 0; i < pairArrayLength; i++)
      presentLpPair.push(FACTORY_INSTANCE.methods.allPairs(i).call())

    presentLpPair = await Promise.all(presentLpPair);
    lpPresent = presentLpPair.includes(lpPairAddress);

    if (lpPresent) {
      const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, lpPairAddress)
      const token0 = await TOKEN_INSTANCE.methods.token0().call();
      const token1 = await TOKEN_INSTANCE.methods.token1().call();
      const TOKEN0_INSTANCE = await selectInstance(instanceType.PLANETLP, token0);
      const TOKEN1_INSTANCE = await selectInstance(instanceType.PLANETLP, token1);
      const name0 = await TOKEN0_INSTANCE.methods.symbol().call();
      const name1 = await TOKEN1_INSTANCE.methods.symbol().call();
      return {
        name: `${name0}/${name1}`,
        address: lpPairAddress,
      }
    }
  } catch (err) {
    console.log(err)
  }
}



export const returnTradeRoute = async () => {

  /*******************************CONTRACT's INSTANCE***********************************************/

  const ROUTER_INSTANCE = await selectInstance(instanceType.PLANETROUTER);
  const FACTORY_INSTANCE = await selectInstance(instanceType.PLANETFACTORY);

  /*************************************************************************************************/

  // const pathArray = [];
  const allPairsLength = parseInt(await FACTORY_INSTANCE.methods.allPairsLength().call());
  let pairsArr = new Array(allPairsLength);

  for (let index = 0; index < allPairsLength; index++) {
    const pairAddress = await FACTORY_INSTANCE.methods.allPairs(index).call();
    const pairInstance = await selectInstance(instanceType.PLANETLP, pairAddress);
    let pairArr = new Array(2);
    pairArr[0] = (pairInstance.methods.token0().call());
    pairArr[1] = (pairInstance.methods.token1().call());
    pairsArr[index] = pairArr
  }
  let res: any = [];
  pairsArr.map(async (e) => {
    res.push(await Promise.all(e))
  })
  // console.log(res)
}


/* ===============================  GET LP ======================================= */

export const tokenTransformAllowance = async (tokenAddress: any, userAddress: any, transformAddress? : string) => {
  try {
    const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, tokenAddress)
    transformAddress = transformAddress ? transformAddress : aquaZapInAddress;
    const allowance = await TOKEN_INSTANCE.methods.allowance(userAddress, transformAddress).call()
    return convertToEther(allowance,await decimals(tokenAddress))
  } catch (err) {
    console.log(err)
  }
}

const returnImpacts = async (path: string[], amount1: any): Promise<number> => {

  let imapctArr: any = []
  const ROUTER_INSTANCE: any = await selectInstance(instanceType.PLANETROUTER);
  const amounts = await ROUTER_INSTANCE.methods.getAmountsOut(convertToWeiInternal(amount1,
    Math.pow(10,await decimals(path[0]))), path).call();
  for (let i = 0; i < path.length - 1; i++) {
    
    const tokenA = path[i];
    const tokenB = path[i + 1];
    const getReserves: any = await returnTokenReserves(tokenA, tokenB)
    const constantProduct: any = getReserves['0'] * getReserves[1]
    const marketPrice: any = getReserves['0'] / getReserves['1']

    const interMediateAmount = convertToEther(amounts[i],await decimals(path[i]))

    const tokenA_ReservesAfterSwap: any = parseFloat(getReserves['0']) + parseFloat(interMediateAmount)
    const tokenB_ReservesAfterSwap: any = parseFloat(constantProduct) / parseFloat(tokenA_ReservesAfterSwap)
    const tokenB_Received: any = getReserves['1'] - tokenB_ReservesAfterSwap
    const pricePaidPerTokenB: any = parseFloat(interMediateAmount) / tokenB_Received
    const impact: any = ((pricePaidPerTokenB - marketPrice) / marketPrice) * 100
    imapctArr.push(impact);
  }
  return Math.max(...imapctArr)
}

export const getPcsLPData = async (amount: any, assetAddress: any, id: any, slippage: any, poolData: any) => {
  let farmAddress;
  if (poolData.isNewFarm) {
    farmAddress = gammaFarmAdddress
  } else {
    farmAddress = aquaFarmAddress
  }

  const aquafarmInstance: any = await selectInstance('AQUAFARM', poolData.farmContractAddress)
  let poolDetails = await aquafarmInstance.methods.poolInfo(id).call()
  const LPAddress = poolDetails['want']
  const lp = await selectInstance(instanceType.PLANETLP, LPAddress)
  const token0 = await lp.methods.token0().call()
  const token1 = await lp.methods.token1().call()
  let half: any = (parseFloat(amount) / 2).toFixed(18) // half amount in ether

  const path1Json = await returnOptimalPath(assetAddress, token0, half)
  const path1 = path1Json.path
  const amountsOut0: any = convertToEther(path1Json.amountOut,await decimals(path1[path1.length-1])) // in wei to ether
  const path2Json = await returnOptimalPath(assetAddress, token1, half)
  const path2 = path2Json.path
  const amountsOut1: any = convertToEther(path2Json.amountOut,await decimals(path2[path2.length-1])) //in wei to ether
  const totalSupply = parseFloat(convertToEther(await lp.methods.totalSupply().call(),18))

  const getReserves = await lp.methods.getReserves().call()

  /******************************************************************************************/

  let impact1 = 0
  let impact2 = 0
  let impactAbove3Percent = false;
  half = parseFloat(half)


  /*
   * After Swap
   */
  if (token0 !== assetAddress && path1.length > 0) {

    impact1 = await returnPcsPairImpacts(path1, half)
  }

  if (token1 !== assetAddress && path2.length > 0) {


    impact2 = await returnPcsPairImpacts(path2, half)

  }


  if (impact1 > 3 || impact2 > 3) {
    impactAbove3Percent = true
  }

  /******************************************************************************************/

  let _reserve0: any = 0
  let _reserve1: any = 0

  _reserve0 = parseFloat(convertToEther(parseFloat(getReserves[0]),await decimals(token0)))
  _reserve1 = parseFloat(convertToEther(parseFloat(getReserves[1]),await decimals(token1)))
  const a: any = (parseFloat(amountsOut0) * totalSupply) / _reserve0
  const b: any = (parseFloat(amountsOut1) * totalSupply) / _reserve1
  const liquidity = Math.min(a, b)

  const minLiquidity = liquidity * (1 - slippage / 100)
  const share = liquidity / (totalSupply + liquidity)

  return { share, liquidity, minLiquidity, amountsOut0, amountsOut1, path1, path2, impactAbove3Percent }
}

export const getLPDataOriginal = async (amount: any, assetAddress: any, id: any, slippage: any, poolData: any) => {
  let farmAddress;
  if (poolData.isNewFarm) {
    farmAddress = gammaFarmAdddress
  } else {
    farmAddress = aquaFarmAddress
  }

  if(assetAddress.toLowerCase() == usdcAddress.toLowerCase() || assetAddress.toLowerCase() == usdtAddress.toLowerCase()){
    //export const getMinimumSwapOutToken = async(poolAddress: string, swapFromIndex: any, swapToIndex: any, swapAmount: any, precisionList: number[]) => {
      let from_token_index = assetAddress.toLowerCase() == usdcAddress.toLowerCase() ? 1 : 2;
      let amountInWei = convertToWei(amount)
      // console.log(stableSwapAddress, from_token_index, 0, amount, "stable token value")
      amount = await getMinimumSwapOutToken(stableSwapAddress, from_token_index, 0, amountInWei, [18, 18, 18])
      amount = convertToEther(amount,18)
      assetAddress = busdAddress;
  }
  // const poolDetails = await poolInfoNew(farmAddress, data.poolId)
  // const tokenInstance: any = await selectInstance(instType.GAMMAFARM, farmAddress)
  // const data = await tokenInstance.methods.poolInfo(poolId).call()
  try{
    
    const farmInstance: any = new wallet.web3.eth.Contract(gammaFarmAbiOld, poolData.farmContractAddress)
    let poolDetails = await farmInstance.methods.poolInfo(id).call()
    const LPAddress = poolDetails['want']
    
    const lp = await selectInstance(instanceType.PLANETLP, LPAddress)
    const token0 = await lp.methods.token0().call()
    const token1 = await lp.methods.token1().call()
    let half: any = (parseFloat(amount) / 2).toFixed(18) // half amount in ether

    const path1Json = await returnPlanetOptimalPath(assetAddress, token0, half)
    const path1 = path1Json.path
    const amountsOut0: any = convertToEther(path1Json.amountOut, await decimals(path1[path1.length-1])) // in wei to ether
    const path2Json = await returnPlanetOptimalPath(assetAddress, token1, half)
    const path2 = path2Json.path
    const amountsOut1: any = convertToEther(path2Json.amountOut,await decimals(path2[path2.length-1])) //in wei to ether
    const totalSupply = parseFloat(convertToEther(await lp.methods.totalSupply().call(),18))

    const getReserves = await lp.methods.getReserves().call()
    
    /******************************************************************************************/

    let impact1 = 0
    let impact2 = 0
    let impactAbove3Percent = false;
    half = parseFloat(half)

    if (token0 !== assetAddress && path1.length > 0) {
      impact1 = await returnImpacts(path1, half)
    }

    if (token1 !== assetAddress && path2.length > 0) {
      impact2 = await returnImpacts(path2, half)
    }

    if (impact1 > 3 || impact2 > 3) {
      impactAbove3Percent = true
    }

    /******************************************************************************************/

    let _reserve0: any = 0
    let _reserve1: any = 0

    _reserve0 = parseFloat(convertToEther(parseFloat(getReserves[0]),await decimals(token0)))
    _reserve1 = parseFloat(convertToEther(parseFloat(getReserves[1]),await decimals(token1)))
    const a: any = (parseFloat(amountsOut0) * totalSupply) / _reserve0
    const b: any = (parseFloat(amountsOut1) * totalSupply) / _reserve1
    const liquidity = Math.min(a, b)

    const minLiquidity = liquidity * (1 - slippage / 100)
    const share = liquidity / (totalSupply + liquidity)

    return { share, liquidity, minLiquidity, amountsOut0, amountsOut1, path1, path2, impactAbove3Percent }
  } catch( error){
    console.log(error)
  }
}

const getProtocols = async() => {
  function nonPmm(protocol:any) {
    //console.log('protocol', protocol);
    return !protocol.includes('PMM');
  }
  let protocolsAr:any = protocols;
  // let protocols:any = await fetch(apiRequestUrl('/liquidity-sources'))
  //       .then(res => res.json())
  //       .then(res => res);
  //console.log('protocols', protocols);
  protocolsAr = protocolsAr.protocols.map(function(a:any) {return a.id;});
  return protocolsAr.filter(nonPmm) ;
}

export const getQuote = async (toTokenAddress:any, fromTokenAddress:any, amount:any) => {
  let fee = referralFees;
  try {
    let protocols = await getProtocols();
    //console.log('protocols', protocols);
    protocols = protocols.toString();
    if(amount > 0 && toTokenAddress != undefined && fromTokenAddress != undefined) {
      let url = apiRequestUrl('/swapQuote', toTokenAddress, fromTokenAddress, amount)
      // console.log('url', url);
      let delayres = await delay(2000);
      return fetch(url)
      .then(res => res.json())
      .then((res: any) => { 
        // console.log("res", res)
        res.fromToken = fromTokenAddress;
        res.toToken = toTokenAddress;
        res.fromTokenAmount = amount;
        res.toTokenAmount = res.toAmount;
        return res;
      });
    }
  } catch(error){
    console.log(error);
    let protocols = await getProtocols();
    //console.log('protocols', protocols);
    protocols = protocols.toString();
    if(amount > 0 && toTokenAddress != undefined && fromTokenAddress != undefined) {
      let url = apiRequestUrl('/swapQuote', toTokenAddress, fromTokenAddress, amount)
      // console.log('url', url);
      let delayres = await delay(2000);
      return fetch(apiRequestUrl('/swapQuote', toTokenAddress, fromTokenAddress, amount))
      .then(res => res.json())
      .then((res: any) => { 
        // console.log("res", res)
        res.fromToken = fromTokenAddress;
        res.toToken = toTokenAddress;
        res.fromTokenAmount = amount;
        res.toTokenAmount = res.toAmount;
        return res;
      });
    }
  }
  let protocols = await getProtocols();
  //console.log('protocols', protocols);
  protocols = protocols.toString();

  
}

export const buildTxForSwap = async (inputToken: any, receiveToken: any, minSendAmountInWei: any, slippageVal: any, userAddress: any) => {
  try {
    //remove decimal from amount
    let sendAmount: any = noExponents(minSendAmountInWei).split(".");
    let protocols = await getProtocols();
    protocols = protocols.toString();

    const swapParams = {
      fromTokenAddress: inputToken, // 1INCH
      toTokenAddress: receiveToken, // DAI
      amount: sendAmount[0],
      fromAddress: planetZapOneInchAddress,
      slippage: slippageVal,
      disableEstimate: true,
      allowPartialFill: false,
      referrerAddress: referralAddress,
      fee: referralFees,
      protocols : protocols
    };

    // console.log('swapParams', swapParams);
    const url = apiRequestUrl('/swapData', swapParams.toTokenAddress, swapParams.fromTokenAddress, swapParams.amount, swapParams.slippage);
    return fetch(url).then(res => res.json()).then(res => res.tx);
  } catch (error) {
    console.log(error)
    //remove decimal from amount
    let sendAmount: any = noExponents(minSendAmountInWei).split(".");
    let protocols = await getProtocols();
    protocols = protocols.toString();

    const swapParams = {
      fromTokenAddress: inputToken, // 1INCH
      toTokenAddress: receiveToken, // DAI
      amount: sendAmount[0],
      fromAddress: planetZapOneInchAddress,
      slippage: slippageVal,
      disableEstimate: true,
      allowPartialFill: false,
      referrerAddress: referralAddress,
      fee: referralFees,
      protocols : protocols
    };

    let delayres = await delay(2000);
    const url = apiRequestUrl('/swapData', swapParams.toTokenAddress, swapParams.fromTokenAddress, swapParams.amount, swapParams.slippage);
    return fetch(url).then(res => res.json()).then(res => res.tx);
  }
}

export const buildTxForSwapInMigrate = async (inputToken: any, receiveToken: any, minSendAmountInWei: any, slippageVal: any, userAddress: any, fromAddress: any) => {
  try {
    //remove decimal from amount
    let sendAmount: any = noExponents(minSendAmountInWei).split(".");
    let protocols = await getProtocols();
    protocols = protocols.toString();

    const swapParams = {
      fromTokenAddress: inputToken, // 1INCH
      toTokenAddress: receiveToken, // DAI
      amount: sendAmount[0],
      fromAddress: fromAddress,
      slippage: slippageVal,
      disableEstimate: true,
      allowPartialFill: false,
      referrerAddress: referralAddress,
      fee: referralFees,
      protocols : protocols
    };

    const url = apiRequestUrlMigrate('/swapDataNew', swapParams.toTokenAddress, swapParams.fromTokenAddress, swapParams.amount, swapParams.slippage, swapParams.fromAddress);
    return fetch(url).then(res => res.json()).then(res => res.tx);
  } catch(error) {
    console.log("error", error)
    //remove decimal from amount
    let sendAmount: any = noExponents(minSendAmountInWei).split(".");
    let protocols = await getProtocols();
    protocols = protocols.toString();

    const swapParams = {
      fromTokenAddress: inputToken, // 1INCH
      toTokenAddress: receiveToken, // DAI
      amount: sendAmount[0],
      fromAddress: fromAddress,
      slippage: slippageVal,
      disableEstimate: true,
      allowPartialFill: false,
      referrerAddress: referralAddress,
      fee: referralFees,
      protocols : protocols
    };

    let delayres = await delay(2000);
    const url = apiRequestUrlMigrate('/swapDataNew', swapParams.toTokenAddress, swapParams.fromTokenAddress, swapParams.amount, swapParams.slippage, swapParams.fromAddress);
    return fetch(url).then(res => res.json()).then(res => res.tx);
  }
  
}

const buildTxForSwapForConvertLP = async (inputToken: any, receiveToken: any, minSendAmountInWei: any, slippageVal: any, userAddress: any) => {

  const swapParams = {
    fromTokenAddress: inputToken, // 1INCH  
    toTokenAddress: receiveToken, // DAI
    amount: minSendAmountInWei,
    fromAddress: userAddress,
    slippage: slippageVal,
    disableEstimate: true,
    allowPartialFill: false,
    referrerAddress: referralAddress,
    fee: referralFees
  };

  //console.log('swapParams', swapParams);
  const url = apiRequestUrl('/swapData', swapParams.toTokenAddress, swapParams.fromTokenAddress, swapParams.amount, swapParams.slippage);

  return fetch(url).then(res => res.json()).then(res => res.tx);
}

const delay = (delayInms: any) => {
  return new Promise(resolve => setTimeout(resolve, delayInms));
};

export const getLPData = async (amount: any, assetAddress: any, id: any, slippage: any, poolData: any) => {
  let farmAddress;
  if (poolData.isNewFarm) {
    farmAddress = gammaFarmAdddress
  } else {
    farmAddress = aquaFarmAddress
  }
  // const poolDetails = await poolInfoNew(farmAddress, data.poolId)
  // const tokenInstance: any = await selectInstance(instType.GAMMAFARM, farmAddress)
  // const data = await tokenInstance.methods.poolInfo(poolId).call()
  const farmInstance: any = await selectInstance('GAMMAFARM', farmAddress)
  let poolDetails = await farmInstance.methods.poolInfo(id).call()
  let LPAddress = poolDetails['want']
  const lp = await selectInstance(instanceType.PLANETLP, LPAddress)
  const token0 = await lp.methods.token0().call()
  const token1 = await lp.methods.token1().call()
  const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);

  let half: any = BigNumber(amount).dividedBy(2)
  let half_2 = half

  const token0Price: any = await getTokenPrice(token0);
  const token1Price: any = await getTokenPrice(token1);
  const tokenSuppliedPrice: any = await getTokenPrice(assetAddress);

  if(activeFusionPools.includes(poolDetails['strat'].toLowerCase())) {
    // console.log("inside fusion condition", token1Price, token0Price, tokenSuppliedPrice)
    let totalAmountSuppliedUsd = BigNumber(amount).multipliedBy(BigNumber(tokenSuppliedPrice));
    let tokenASuppliedAmount:any = BigNumber(totalAmountSuppliedUsd).dividedBy(BigNumber(token0Price));
    tokenASuppliedAmount = convertToWei(tokenASuppliedAmount.toString());

    let tokenASuppliedAmount_half:any = BigNumber(tokenASuppliedAmount).dividedBy(BigNumber("2")).toFixed(0,3);
    let gammaUniproxyRouter  = '0xf75c017e3b023a593505e281b565ed35cc120efa'//"0x6b3d98406779ddca311e6c43553773207b506fa6";
    //LPAddress = "0xD3C480EC7a47596fF8D63396227d1F7dC728A7f0";
    let unStableRange: any = await planetZapInstance.methods.getSecondTokenDepositAmount( LPAddress, token0, tokenASuppliedAmount_half , gammaUniproxyRouter).call();
    let _otherTokenAmountMin = BigNumber(unStableRange[0]);
    let _otherTokenAmountMax = BigNumber(unStableRange[1]);
   

    let average:any = _otherTokenAmountMin.plus(_otherTokenAmountMax).dividedBy(BigNumber("2")).toFixed(0,3);

    let tokenBAverage = BigNumber(convertToEther(average,18));
    let tokenBAverageUsd = tokenBAverage.multipliedBy(BigNumber(token1Price));
    let tokenASuppliedUsd = BigNumber(convertToEther(tokenASuppliedAmount_half, 18)).multipliedBy(BigNumber(token0Price));

    let tokenSuppliedUsd:any = BigNumber(amount).multipliedBy(BigNumber(tokenSuppliedPrice));
    let newTokenAvalueUsd = BigNumber(tokenASuppliedUsd.multipliedBy(tokenSuppliedUsd).dividedBy(tokenASuppliedUsd.plus(tokenBAverageUsd)))

    let ratio = newTokenAvalueUsd.dividedBy(totalAmountSuppliedUsd);

    half = BigNumber(amount).multipliedBy(BigNumber(ratio))
    half_2 = BigNumber(amount).multipliedBy(BigNumber("1").minus(ratio))

    //half_2 = half_2.minus(half.plus(half_2).minus(BigNumber(amount)))    
  }
    
  let stable_LP_Array: any = [ '0xb32ae069db02f614e303aceaf5ad963d7de12194', '0x208adae61fd7587314c2f7f1566bd137e0dd0126' ];
  //let fetchLiquidity: any = await planetZapInstance.methods.quoteAddLiquidity(LPAddress, token0, token1, quote0.toTokenAmount, quote1.toTokenAmount).call();
  //call quote stables add liquidity ratio
  let tokenVal0: any = convertToWei(half).toString();
  let tokenVal1: any = convertToWei(half_2).toString();

  if(stable_LP_Array.includes(poolDetails['strat'].toLowerCase())){
    let routerAddress: any = PLANETROUTERADDRESS;    
    if(stable_LP_Array.includes(poolDetails['strat'].toLowerCase())){
      routerAddress = "0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109";
      let stableRatio: any = await planetZapInstance.methods.quoteStableAddLiquidityRatio(LPAddress, routerAddress).call();
      tokenVal1 = BigNumber(amount).multipliedBy(BigNumber(stableRatio)).toFixed(0,3).toString()
      tokenVal0 = BigNumber(amount).multipliedBy(BigNumber("1e18").minus(BigNumber(stableRatio))).toFixed(0,3).toString()
    }  
  }
  let quote0: any = {};
  let quote1: any = {};
  if(assetAddress.toLowerCase() == token0.toLowerCase()){
    quote1 = await getQuote(token1, assetAddress, tokenVal1)
    quote0.toTokenAmount = tokenVal0;
  } else if(assetAddress.toLowerCase() == token1.toLowerCase()){
    quote0 = await getQuote(token0, assetAddress, tokenVal0)
    quote1.toTokenAmount = tokenVal1;
  } else {
    quote0 = await getQuote(token0, assetAddress, tokenVal0)
    let delayres = await delay(2000);
    quote1 = await getQuote(token1, assetAddress, tokenVal1)
  }
    // total supply usd / price of LP ==> liquidity amount
  let fetchLiquidity: any = {};
  if(activeFusionPools.includes(poolDetails['strat'].toLowerCase())) {
    try{
      fetchLiquidity.liquidity = ((quote0.toTokenAmount * token0Price + quote1.toTokenAmount * token1Price)/ poolData.price).toFixed(0);
      fetchLiquidity.amountA = quote0.toTokenAmount;
      fetchLiquidity.amountB = quote1.toTokenAmount;
    } catch (error){
      console.log(error)
    }
  }
  else {
    
    try{
      fetchLiquidity = await planetZapInstance.methods.quoteAddLiquidity(LPAddress, token0, token1, quote0.toTokenAmount, quote1.toTokenAmount).call();
      
    } catch (error){
      console.log(error)
    }
  }
  let impactAbove3Percent = false;
  const liquidity = convertToEther(fetchLiquidity.liquidity, 18);
  const minLiquidity = liquidity * (1 - slippage / 100)
  let amountsOut0 = convertToEther(fetchLiquidity.amountA, 18);
  let amountsOut1 = convertToEther(fetchLiquidity.amountB, 18);

  return { liquidity, minLiquidity, amountsOut0, amountsOut1, impactAbove3Percent, tokenVal0, tokenVal1, token0, token1 }
}

export const getLPDataV3 = async (amount: any, assetAddress: any, id: any, slippage: any, poolData: any) => {
  let farmAddress = poolData.farmContractAddress;
  // if (poolData.isNewFarm) {
  //   farmAddress = gammaFarmAdddress
  // } else {
  //   farmAddress = aquaFarmAddress
  // }
  // const poolDetails = await poolInfoNew(farmAddress, data.poolId)
  // const tokenInstance: any = await selectInstance(instType.GAMMAFARM, farmAddress)
  // const data = await tokenInstance.methods.poolInfo(poolId).call()
  const farmInstance: any = new wallet.web3.eth.Contract(gammaFarmV3Abi, farmAddress)
  // let poolDetails = await farmInstance.methods.poolInfo(id).call()
  let LPAddress = poolData.wantAddress;
  const lp = await selectInstance(instanceType.PLANETLP, LPAddress)
  const token0 = poolData.token0;
  const token1 = poolData.token1;
  // console.log(token0, poolData.token0, token1, poolData.token1)
  const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);

  let half: any = BigNumber(amount).dividedBy(2)
  let half_2 = half
  let tokenPriceArray: any = await returnTokenPriceMulticall();
  // console.log(tokenPriceArray, token0, token1)
  const token0Price: any = tokenPriceArray[token0.toLowerCase()]//await getTokenPrice(token0);
  const token1Price: any = tokenPriceArray[token1.toLowerCase()];//await getTokenPrice(token1);
  // console.log("token prices", token0Price, token1Price)
  const tokenSuppliedPrice: any = await getTokenPrice(assetAddress);

  if(activeV3FusionPools.includes(poolData.strategyAddress.toLowerCase())) {
    // console.log("inside fusion condition", token1Price, token0Price, tokenSuppliedPrice)
    let totalAmountSuppliedUsd = BigNumber(amount).multipliedBy(BigNumber(tokenSuppliedPrice));
    let tokenASuppliedAmount:any = BigNumber(totalAmountSuppliedUsd).dividedBy(BigNumber(token0Price));
    tokenASuppliedAmount = convertToWei(tokenASuppliedAmount.toString());

    let tokenASuppliedAmount_half:any = BigNumber(tokenASuppliedAmount).dividedBy(BigNumber("2")).toFixed(0,3);
    let gammaUniproxyRouter  = '0xf75c017e3b023a593505e281b565ed35cc120efa'//"0x6b3d98406779ddca311e6c43553773207b506fa6";
    //LPAddress = "0xD3C480EC7a47596fF8D63396227d1F7dC728A7f0";
    // console.log("second token deposit amt", LPAddress, token0, tokenASuppliedAmount_half , gammaUniproxyRouter)
    let unStableRange: any = await planetZapInstance.methods.getSecondTokenDepositAmount( LPAddress, token0, tokenASuppliedAmount_half , gammaUniproxyRouter).call();
    let _otherTokenAmountMin = BigNumber(unStableRange[0]);
    let _otherTokenAmountMax = BigNumber(unStableRange[1]);
   

    let average:any = _otherTokenAmountMin.plus(_otherTokenAmountMax).dividedBy(BigNumber("2")).toFixed(0,3);

    let tokenBAverage = BigNumber(convertToEther(average,18));
    let tokenBAverageUsd = tokenBAverage.multipliedBy(BigNumber(token1Price));
    let tokenASuppliedUsd = BigNumber(convertToEther(tokenASuppliedAmount_half, 18)).multipliedBy(BigNumber(token0Price));

    let tokenSuppliedUsd:any = BigNumber(amount).multipliedBy(BigNumber(tokenSuppliedPrice));
    let newTokenAvalueUsd = BigNumber(tokenASuppliedUsd.multipliedBy(tokenSuppliedUsd).dividedBy(tokenASuppliedUsd.plus(tokenBAverageUsd)))

    let ratio = newTokenAvalueUsd.dividedBy(totalAmountSuppliedUsd);

    half = BigNumber(amount).multipliedBy(BigNumber(ratio))
    half_2 = BigNumber(amount).multipliedBy(BigNumber("1").minus(ratio))

    //half_2 = half_2.minus(half.plus(half_2).minus(BigNumber(amount)))    
  }
    
  let stable_LP_Array: any = [ '0xb32ae069db02f614e303aceaf5ad963d7de12194', '0x208adae61fd7587314c2f7f1566bd137e0dd0126' ];
  //let fetchLiquidity: any = await planetZapInstance.methods.quoteAddLiquidity(LPAddress, token0, token1, quote0.toTokenAmount, quote1.toTokenAmount).call();
  //call quote stables add liquidity ratio
  let tokenVal0: any = convertToWei(half).toString();
  let tokenVal1: any = convertToWei(half_2).toString();

  if(stable_LP_Array.includes(poolData.strategyAddress.toLowerCase())){
    let routerAddress: any = PLANETROUTERADDRESS;    
    if(stable_LP_Array.includes(poolData.strategyAddress.toLowerCase())){
      routerAddress = "0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109";
      let stableRatio: any = await planetZapInstance.methods.quoteStableAddLiquidityRatio(LPAddress, routerAddress).call();
      tokenVal1 = BigNumber(amount).multipliedBy(BigNumber(stableRatio)).toFixed(0,3).toString()
      tokenVal0 = BigNumber(amount).multipliedBy(BigNumber("1e18").minus(BigNumber(stableRatio))).toFixed(0,3).toString()
    }  
  }
  let quote0: any = {};
  let quote1: any = {};
  if(assetAddress.toLowerCase() == token0.toLowerCase()){
    quote1 = await getQuote(token1, assetAddress, tokenVal1)
    quote0.toTokenAmount = tokenVal0;
  } else if(assetAddress.toLowerCase() == token1.toLowerCase()){
    quote0 = await getQuote(token0, assetAddress, tokenVal0)
    quote1.toTokenAmount = tokenVal1;
  } else {
    quote0 = await getQuote(token0, assetAddress, tokenVal0)
    let delayres = await delay(2000);
    // console.log("quote 1 params in else",token1, assetAddress, tokenVal1)
    quote1 = await getQuote(token1, assetAddress, tokenVal1)
  }
    // total supply usd / price of LP ==> liquidity amount
  let fetchLiquidity: any = {};
  if(activeV3FusionPools.includes(poolData.strategyAddress.toLowerCase())) {
    try{
      fetchLiquidity.liquidity = ((quote0.toTokenAmount * token0Price + quote1.toTokenAmount * token1Price)/ poolData.price).toFixed(0);
      fetchLiquidity.amountA = quote0.toTokenAmount;
      fetchLiquidity.amountB = quote1.toTokenAmount;
    } catch (error){
      console.log(error)
    }
  }
  else {
    
    try{
      // console.log(LPAddress, token0, token1, quote0.toTokenAmount, quote1.toTokenAmount)
      if(poolData.name.toLowerCase() == "aqua") {
        fetchLiquidity = {
          liquidity: BigNumber(quote0.toTokenAmount).plus(quote1.toTokenAmount).toString(),
          amountA: BigNumber(quote0.toTokenAmount).plus(quote1.toTokenAmount).toString(),
          amountB: BigNumber(quote0.toTokenAmount).plus(quote1.toTokenAmount).toString()
        }
      } else {
        fetchLiquidity = await planetZapInstance.methods.quoteAddLiquidity(LPAddress, token0, token1, quote0.toTokenAmount, quote1.toTokenAmount).call();
      }
    } catch (error){
      console.log(error)
    }
  }
  let impactAbove3Percent = false;
  // console.log("liquidity data", fetchLiquidity)
  const liquidity = convertToEther(fetchLiquidity.liquidity, 18);
  const minLiquidity = liquidity * (1 - slippage / 100)
  let amountsOut0 = convertToEther(fetchLiquidity.amountA, 18);
  let amountsOut1 = convertToEther(fetchLiquidity.amountB, 18);

  return { liquidity, minLiquidity, amountsOut0, amountsOut1, impactAbove3Percent, tokenVal0, tokenVal1, token0, token1 }
}



export const getLPDataForStables = async (amount: any, assetAddress: any, id: any, slippage: any, poolData: any) => {
  let farmAddress;
  if (poolData.isNewFarm) {
    farmAddress = gammaFarmAdddress
  } else {
    farmAddress = aquaFarmAddress
  }
  let busd_address = "0xe9e7cea3dedca5984780bafc599bd69add087d56";
  let tokenAmount: any = (parseFloat(amount)).toFixed(18)
  if(assetAddress.toLowerCase() !== "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d".toLowerCase() && assetAddress.toLowerCase() !== "0x55d398326f99059ff775485246999027b3197955".toLowerCase() && assetAddress.toLowerCase() !== "0xe9e7cea3dedca5984780bafc599bd69add087d56".toLowerCase()){
    let swapPath = await returnPlanetOptimalPath(assetAddress, busdAddress, tokenAmount)
    let impactAbove3Percent = false;
    const path1 = swapPath.path;
    let impact1 = 0
    if (busdAddress !== assetAddress && path1.length > 0) {
      impact1 = await returnImpacts(path1, tokenAmount)
    }
    if (impact1 > 3) {
      impactAbove3Percent = true
    }
    // const ROUTER_INSTANCE: any = await selectInstance(instanceType.PLANETROUTER);
    // const amountOut = await ROUTER_INSTANCE.methods.getAmountsOut(convertToWeiInternal(tokenAmount,Math.pow(10,18)), path1).call();
    //calc token amount
    let amountsOut0 = convertToEther(swapPath.amountOut, 18);
    let stableLPTokens = await estimateTokensOnDeposit(stableSwapAddress, [swapPath.amountOut, 0, 0])
    const liquidity = (stableLPTokens);

    const minLiquidity = liquidity * (1 - slippage / 100)
    let share = 0
    return { share, liquidity, minLiquidity, path1, impactAbove3Percent, amountsOut0 }
  } else {
    let impactAbove3Percent = false;
    const path1 = [assetAddress];
    let impact1 = 0
    if (busdAddress !== assetAddress && path1.length > 0) {
    impact1 = 0 //await returnImpacts(path1, tokenAmount)
    }
    if (impact1 > 3) {
      impactAbove3Percent = true
    }
    let amountInWei = convertToWei(amount)
    if(assetAddress.toLowerCase() !== busdAddress.toLowerCase()){
      let from_token_index = assetAddress.toLowerCase() == usdcAddress.toLowerCase() ? 1 : 2;
      
      // console.log(stableSwapAddress, from_token_index, 0, amount, "stable token value")
      amount = await getMinimumSwapOutToken(stableSwapAddress, from_token_index, 0, amountInWei, [18, 18, 18])
      let amountsOut0 = convertToEther(amount, 18);
      let stableLPTokens = await estimateTokensOnDeposit(stableSwapAddress, [amount, 0, 0])
      const liquidity = (stableLPTokens);

      const minLiquidity = liquidity * (1 - slippage / 100)
      let share = 0
      return { share, liquidity, minLiquidity, path1, impactAbove3Percent, amountsOut0 }
    } else {
      let amountsOut0 = amount;
      let stableLPTokens = await estimateTokensOnDeposit(stableSwapAddress, [amountInWei, 0, 0])
      const liquidity = (stableLPTokens);
  
      const minLiquidity = liquidity * (1 - slippage / 100)
      let share = 0
      return { share, liquidity, minLiquidity, path1, impactAbove3Percent, amountsOut0 }
    }
  }
  

}

const zapIn = async (assetAdd: any, address: any, amount: any, lpAddress: any, token0Path: any, token1Path: any, minLiquidity: any, userAddress: any, showTransLink: any) => {
  const SwampContract = new wallet.web3.eth.Contract(oldZapAbi, oldZapAddress)//await selectInstance(instanceType.AQUAZAPINABI)
  if (assetAdd === bnbAddress) {
    try {
      return SwampContract.methods
        .zapIn(address, amount, lpAddress, token0Path, token1Path, minLiquidity, PLANETROUTERADDRESS)
        .send({ from: userAddress, value: amount, })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
    } catch (error) {
      return error
    }
  } else {
    try {
      return SwampContract.methods
        .zapIn(address, amount, lpAddress, token0Path, token1Path, minLiquidity, PLANETROUTERADDRESS)
        .send({ from: userAddress, })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
    } catch (error) {
      return error
    }
  }
}

const zapInRevised = async (assetAdd: any, amount: any, token0Path: any, token1Path: any, slippageVal: any, minLiquidity: any, lpAddress: any, userAddress: any, showTransLink: any, stableSwapZap?: boolean, stableSwapPoolData?: any[]) => {
  // console.log("zap in revised", assetAdd, amount, token0Path, token1Path, slippageVal, minLiquidity, lpAddress, userAddress)
  const SwampContract = new wallet.web3.eth.Contract(oldZapAbi, oldZapAddress)
  if(stableSwapZap !== undefined) {
    try {
      let stablePoolToken = stableSwapPoolData !== undefined && stableSwapPoolData[0] == 1 ? usdcAddress: usdtAddress;
      // console.log("zap method for stable swap data", assetAdd, stablePoolToken, amount, stableSwapPoolData, token0Path, token1Path, slippageVal, minLiquidity, PLANETROUTERADDRESS)
      
      return SwampContract.methods
      .zapInFrom3GStables(assetAdd, stablePoolToken, amount, stableSwapPoolData, token0Path, token1Path, slippageVal, minLiquidity, PLANETROUTERADDRESS)
      .send({ from: userAddress, value: 0 })
      .once('transactionHash', function (hash: any) {
        if (!!hash) {
          showTransLink(hash)
        }
      })
    } catch (error) {
      console.log(error)
      return error
    }
  }
  if (assetAdd === bnbAddress) {
    try {
      let amountInEth = convertToEther(amount, 18);
      // console.log("zap method data", assetAdd, amount, token0Path, token1Path, slippageVal, minLiquidity, PLANETROUTERADDRESS)
      return SwampContract.methods
        .zapIn(zeroAddress, amount, token0Path, token1Path, slippageVal, minLiquidity, PLANETROUTERADDRESS)
        .send({ from: userAddress, value: amount, })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
    } catch (error) {
      return error
    }
  } else {
    try {
      // console.log("zap method data in else", assetAdd, amount, token0Path, token1Path, slippageVal, minLiquidity, PLANETROUTERADDRESS)
      return SwampContract.methods
        .zapIn(assetAdd, amount, token0Path, token1Path, slippageVal, minLiquidity, PLANETROUTERADDRESS)
        .send({ from: userAddress, })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
    } catch (error) {
      return error
    }
  }
}

/*
@param fromTokenAddress: Aqua address, incase we are converting Aqua tokens
@param amountIn: How much aqua we want to transform, with precision
@param pathToBusd: Aqua to busd conversion path
@param routerAddress: Router address
@param stablePoolAddress: StablePoolAddress
@param busdIndex: Index of busd in the stablePool
@param nCoins: number of coins in the pool, 3 if 3g, 4 if 4g
@param userAddress: user calling transform
*/
export const zapInto3GStables = async (fromTokenAddress: string, amountIn: string, pathToBusd: string[],
  routerAddress: string, stablePoolAddress: string, busdIndex: number, nCoins: number, userAddress: string) => {
  try {
    const zapContract: any = new wallet.web3.eth.Contract(oldZapAbi, oldZapAddress)
    await zapContract.methods.zapInto3GStables(fromTokenAddress, amountIn, pathToBusd, routerAddress, stablePoolAddress, busdIndex, nCoins).send({
      from: userAddress
    })
  }
  catch (error){
    console.log(error)
  }
}

export const createStableLP = async (amount: any, id: any, assetAdd: any, minLiquidity: any, userAddress: any, showTransLink: any, path1: any, path2: any, poolData: any, slippageVal: any) => {

  let farmAddress;
  if (poolData.isNewFarm) {
    farmAddress = gammaFarmAdddress
  } else {
    farmAddress = aquaFarmAddress
  }
  // console.log("createLP", farmAddress, amount, assetAdd, minLiquidity)
  const aquafarmInstance: any = new wallet.web3.eth.Contract(gammaFarmAbiOld, poolData.farmContractAddress)
  let poolDetails = await aquafarmInstance.methods.poolInfo(id).call()
  const LPAddress = poolDetails['want']
  // const contract = await selectInstance(instanceType.PLANETLP, LPAddress)
  // const token0 = await contract.methods.token0().call()
  // const token1 = await contract.methods.token1().call()
  slippageVal = (1 - slippageVal/100) * 1000;
  let amountInWei = convertToWei(amount);
  let minLiquidityInWei = convertToWei(minLiquidity)
  if(assetAdd.toLowerCase() !== usdcAddress.toLowerCase() && assetAdd.toLowerCase() !== usdtAddress.toLowerCase() && assetAdd.toLowerCase() !== busdAddress.toLowerCase()){ 
    try {
      let sendAmount = assetAdd.toLowerCase() == bnbAddress.toLowerCase() ? amountInWei : 0;
      assetAdd = assetAdd.toLowerCase() == bnbAddress.toLowerCase() ? zeroAddress : assetAdd;
      const SwapContract = new wallet.web3.eth.Contract(oldZapAbi, oldZapAddress)//await selectInstance(instanceType.AQUAZAPINABI)
      // console.log("zap method for stable swap data", assetAdd, amountInWei, path1, slippageVal, PLANETROUTERADDRESS, stableSwapAddress, 0, 3, minLiquidityInWei, userAddress)
      return SwapContract.methods
        .zapInto3GStables(assetAdd, amountInWei, path1, slippageVal, PLANETROUTERADDRESS, stableSwapAddress, 0, 3, minLiquidityInWei)
        .send({ from: userAddress, value: sendAmount })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
    } catch (error) {
      console.log(error)
      return error
    }
  } else {
    try {
      // console.log("zap method for stable swap data", assetAdd, amountInWei, path1, slippageVal, PLANETROUTERADDRESS, stableSwapAddress, 0, 3, minLiquidityInWei, userAddress)
      let liquidityAmountArray = assetAdd.toLowerCase() == busdAddress.toLowerCase() ? [amount, 0, 0] : assetAdd.toLowerCase() !== usdcAddress.toLowerCase() ? [ 0, amount, 0] : [0, 0, amount];
      let fetchLiquidityDetails: any = await addLiquidityToPool(stableSwapAddress, liquidityAmountArray, [18,18,18], 0, userAddress);
      // console.log("add liquidity details",fetchLiquidityDetails);
      let slippageFactor = 0;
      const stable_pool_inst: any = new wallet.web3.eth.Contract(stableswap_main_abi_nG, stableSwapAddress);
      return stable_pool_inst.methods.add_liquidity(fetchLiquidityDetails.preciseLiquidityList, fetchLiquidityDetails.totalStables * slippageFactor, userAddress)
      .send({from: userAddress})
      .once('transactionHash', function (hash: any) {
        if (!!hash) {
          showTransLink(hash)
        }
      })
    } catch(error) {
      console.log(error);
      return error;
    }    
  }
}

export const createLPNew = async (amount: any, id: any, assetAdd: any, transInfo: any, userAddress: any, showTransLink: any, poolData: any, slippageVal: any, updateTokenList: any, dispatch: any, handleNotification: any, setConfirmTransHistory: any, setCreateLpLoading: any) => {
  let LPAddress = poolData['wantAddress'];

  const contract = await selectInstance(instanceType.PLANETLP, LPAddress)
  const token0 = await contract.methods.token0().call()
  const token1 = await contract.methods.token1().call()

  const balanceBeforeTransaction = await balanceOf(LPAddress, userAddress);
  // console.log('balanceBeforeTransaction', balanceBeforeTransaction );
  let callData0: any = {data:"0x"};
  let callData1: any = {data:"0x"};

  if(assetAdd.toLowerCase() == token0.toLowerCase() || assetAdd.toLowerCase() == token1.toLowerCase()){
    if(assetAdd.toLowerCase() !== token0.toLowerCase()) {
      callData0 = await buildTxForSwap(assetAdd, token0, transInfo.sendAmount0, slippageVal, userAddress)
    }
    else {
      callData1 = await buildTxForSwap(assetAdd, token1, transInfo.sendAmount1, slippageVal, userAddress)
    }
  } else {
    callData0 = await buildTxForSwap(assetAdd, token0, transInfo.sendAmount0, slippageVal, userAddress);
    let delayres = await delay(2000);
    callData1 = await buildTxForSwap(assetAdd, token1, transInfo.sendAmount1, slippageVal, userAddress);
  }

  const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
  
  let thena_LP_Array: any = ['0x5cdfb4cd95f733fd679343bbc39d12dff907251b', '0xcf32907c7ee99dccbd8feec2c26fad077f799890', '0x7b5a46d584477eb00fc88a3265724e50e7cbcb32', '0x06f5f260be2c541f134b5548e5e62952d7315363', '0x10a4a5e00769d0101bf6ba8ff10b4d68f4ba070b','0x590540c90f42eec56ec33009ce954c91fd3079b0'];
  let biswap_LP_Array: any = [
    "0xaf2773d0eeff2bcf67045bfe8f750dc143633279"
  ];
  let stable_LP_Array: any = [ '0xb32ae069db02f614e303aceaf5ad963d7de12194', '0x208adae61fd7587314c2f7f1566bd137e0dd0126' ];

  let poolType: any = 0;
  let routerAddress: any = PLANETROUTERADDRESS;

  if(thena_LP_Array.includes(poolData['strategyAddress'].toLowerCase())){
    poolType = 2;
    routerAddress = '0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109';
  }

  if(stable_LP_Array.includes(poolData['strategyAddress'].toLowerCase())){
    poolType = 1;
    routerAddress = '0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109';
  } 

  if(activeFusionPools.includes(poolData['strategyAddress'].toLowerCase())){
    poolType = 3;
    routerAddress = '0xf75c017e3b023a593505e281b565ed35cc120efa';//'0x6b3d98406779ddca311e6c43553773207b506fa6';
  }

  if(biswap_LP_Array.includes(poolData['strategyAddress'].toLowerCase())){
    routerAddress = '0x3a6d8ca21d1cf76f653a67577fa0d27453350dd8';
  }
  
  if (assetAdd.toLowerCase() === bnbAddress.toLowerCase()) {
    // console.log(callData0.data, callData1.data, poolType, routerAddress, LPAddress)
    try {
      return new Promise((resolve, reject) => {
        planetZapInstance.methods
        .zapInBNB(callData0.data, callData1.data, poolType, routerAddress, LPAddress, true, poolData["poolId"], 0)
        .send({ from: userAddress, value: convertToWei(amount) })
          .on('confirmation', (confNumber: any, receipt: any) => {
            if (confNumber === 5) {
              showTransLink(receipt.transactionHash)
              setConfirmTransHistory(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setCreateLpLoading(false)
              resolve(receipt.status ? 'success' : 'failed') 
            }
          })
          .on('error', (error:any) => {
            console.log(error)
            setCreateLpLoading(false)
            handleNotification('failed')
            reject("failed")
          })
      })
    } catch (error) {
      console.log("error", error)
      return "failed"
    }
  } else {
    try {
      return new Promise((resolve, reject) => { 
        planetZapInstance.methods
        .zapIn(assetAdd, convertToWei(amount), callData0.data, callData1.data, poolType, routerAddress, LPAddress, true, poolData["poolId"], 0)
        .send({ from: userAddress })
        .once('confirmation', async function (confNumber: any, receipt: any) {
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          showTransLink(receipt.transactionHash)
          setConfirmTransHistory(receipt.transactionHash, receipt.status)
          setCreateLpLoading(false)
          resolve(receipt.status ? 'success' : 'failed') 
        })
        .on('error', function (error: any) { 
          console.log("error message", error)
          setCreateLpLoading(false)
          handleNotification('failed')
          reject("failed")
        })
      })
    } catch (error) {
      console.log("error", error)
      return "failed"
    }
  }
}

export const createLP = async (amount: any, id: any, assetAdd: any, transInfo: any, userAddress: any, showTransLink: any, poolData: any, slippageVal: any, updateTokenList: any, dispatch: any, handleNotification: any, setConfirmTransHistory: any, setCreateLpLoading: any) => {
  //console.log('poolData', poolData);
  // console.log("createLP", amount, assetAdd, transInfo, slippageVal, poolData)
  let LPAddress = poolData['wantAddress']
  const contract = await selectInstance(instanceType.PLANETLP, LPAddress)
  const token0 = await contract.methods.token0().call()
  const token1 = await contract.methods.token1().call()

  const balanceBeforeTransaction = await balanceOf(LPAddress, userAddress);
  // console.log('balanceBeforeTransaction', balanceBeforeTransaction );
  let callData0: any = {data:"0x"};
  let callData1: any = {data:"0x"};
  if(assetAdd.toLowerCase() == token0.toLowerCase() || assetAdd.toLowerCase() == token1.toLowerCase()){
    if(assetAdd.toLowerCase() !== token0.toLowerCase()) {
      callData0 = await buildTxForSwap(assetAdd, token0, transInfo.sendAmount0, slippageVal, userAddress)
    }
    else {
      callData1 = await buildTxForSwap(assetAdd, token1, transInfo.sendAmount1, slippageVal, userAddress)
    }
  } else {
    callData0 = await buildTxForSwap(assetAdd, token0, transInfo.sendAmount0, slippageVal, userAddress);
    let delayres = await delay(3000);
    callData1 = await buildTxForSwap(assetAdd, token1, transInfo.sendAmount1, slippageVal, userAddress);
  }
  // console.log("call data val", callData0, callData1)

  const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
  
  let thena_LP_Array: any = ['0x5cdfb4cd95f733fd679343bbc39d12dff907251b', '0xcf32907c7ee99dccbd8feec2c26fad077f799890', '0x7b5a46d584477eb00fc88a3265724e50e7cbcb32', '0x06f5f260be2c541f134b5548e5e62952d7315363', '0x10a4a5e00769d0101bf6ba8ff10b4d68f4ba070b','0x590540c90f42eec56ec33009ce954c91fd3079b0'];
  let stable_LP_Array: any = [ '0xb32ae069db02f614e303aceaf5ad963d7de12194', '0x208adae61fd7587314c2f7f1566bd137e0dd0126' ];

  let poolType: any = 0;
  let routerAddress: any = PLANETROUTERADDRESS;

  if(thena_LP_Array.includes(poolData['strategyAddress'].toLowerCase())){
    poolType = 2;
    routerAddress = '0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109';
  }

  if(stable_LP_Array.includes(poolData['strategyAddress'].toLowerCase())){
    poolType = 1;
    routerAddress = '0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109';
  } 

  if(activeFusionPools.includes(poolData['strategyAddress'].toLowerCase())){
    poolType = 3;
    routerAddress = '0xf75c017e3b023a593505e281b565ed35cc120efa'//'0x6b3d98406779ddca311e6c43553773207b506fa6';
  } 
  //console.log('assetAdd -23', assetAdd )
  if (assetAdd.toLowerCase() === bnbAddress.toLowerCase()) {
    // console.log("send token is bnb")      
    try {
      //console.log("zap method data bnb", amount, callData0, callData1, poolType, routerAddress, LPAddress)
      return planetZapInstance.methods
        .zapInBNB(callData0.data, callData1.data, poolType, routerAddress, LPAddress)
        .send({ from: userAddress, value: convertToWei(amount) })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
        .once('confirmation', async function (confNumber: any, receipt: any) {
          setConfirmTransHistory(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setCreateLpLoading(false)
          /*if(type == "success"){

            const balanceAfterTransaction = await balanceOf(LPAddress, userAddress);
            console.log('balanceAfterTransaction', balanceAfterTransaction );
            //updateTokenList()
            // dispatch(updateLPData(userAddress))
            //dispatch(setPortfolioLoading(true))
            //dispatch(updateUserBalance(userAddress))

            const newLpTokens = balanceAfterTransaction - balanceBeforeTransaction;
            // const allowanceAmount = await allowance(LPAddress, userAddress, gammaFarmAdddress);
            // console.log('allowanceAmount', allowanceAmount );
            // if(allowanceAmount < newLpTokens) {
            //   // get allowance
            //   console.log(poolData.poolId, convertToEther(newLpTokens, 18), userAddress, handleNotification, poolData);
            //   let res:any = await lpTokenApprove(LPAddress, gammaFarmAdddress, userAddress, newLpTokens )

            //   if(res == "Fail") {
            //     setCreateLpLoading(false)
            //     //handleNotification('failed')
            //     return "error";
            //   }
            // }

            // await handleDeposit(poolData.poolId, convertToEther(newLpTokens, 18), userAddress, handleNotification, poolData)
            // stake in farm LP

            updateTokenList()
            // dispatch(updateLPData(userAddress))
            dispatch(setPortfolioLoading(true))
            dispatch(updateUserBalance(userAddress))
          }*/
        })
        .on('error', function (error: any) {
            console.log("error message", error)
            setCreateLpLoading(false)
            handleNotification('failed')
            return "error";
        })
    } catch (error) {
      console.log("error", error)
      return error
    }
  } else {
    // console.log("need to write the function !!")
    try {
      //console.log("zap method data", assetAdd, convertToWei(amount), callData0.data, callData1.data, poolType, routerAddress, LPAddress)
      return planetZapInstance.methods
        .zapIn(assetAdd, convertToWei(amount), callData0.data, callData1.data, poolType, routerAddress, LPAddress)
        .send({ from: userAddress })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
        .once('confirmation', async function (confNumber: any, receipt: any) {
          setConfirmTransHistory(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setCreateLpLoading(false)
          /*if(type == "success"){

            const balanceAfterTransaction = await balanceOf(LPAddress, userAddress);
            console.log('balanceAfterTransaction', balanceAfterTransaction );
            //updateTokenList()
            // dispatch(updateLPData(userAddress))
            //dispatch(setPortfolioLoading(true))
            //dispatch(updateUserBalance(userAddress))
            const newLpTokens = balanceAfterTransaction - balanceBeforeTransaction;
            const allowanceAmount = await allowance(LPAddress, userAddress, gammaFarmAdddress);
            console.log('allowanceAmount', allowanceAmount );
            if(allowanceAmount < newLpTokens) {
              // get allowance
              console.log(poolData.poolId, convertToEther(newLpTokens, 18), userAddress, handleNotification, poolData);
              let res:any = await lpTokenApprove(LPAddress, gammaFarmAdddress, userAddress, newLpTokens )

              if(res == "Fail") {
                setCreateLpLoading(false)
                //handleNotification('failed')
                return "error";
              }
            }
            
            await handleDeposit(poolData.poolId, convertToEther(newLpTokens, 18), userAddress, handleNotification, poolData)
            // stake in farm LP

            updateTokenList()
            // dispatch(updateLPData(userAddress))
            dispatch(setPortfolioLoading(true))
            dispatch(updateUserBalance(userAddress))
          }*/
        })
        .on('error', function (error: any) {
            console.log("error message", error)
            setCreateLpLoading(false)
            handleNotification('failed')
            return "error";
        })
    } catch (error) {
      console.log("error", error)
      return error
    }
  }
}

export const createLPV3 = async (amount: any, id: any, assetAdd: any, transInfo: any, userAddress: any, showTransLink: any, poolData: any, slippageVal: any, updateTokenList: any, dispatch: any, handleNotification: any, setConfirmTransHistory: any, setCreateLpLoading: any, lockIndex: any, zapInStake: any) => {
  // console.log('poolData', poolData);
  // console.log("createLP", amount, assetAdd, transInfo, slippageVal, poolData)
  let LPAddress = poolData.wantAddress;
  // const contract = await selectInstance(instanceType.PLANETLP, LPAddress)
  const token0 = poolData.token0;
  const token1 = poolData.token1;
  const balanceBeforeTransaction = await balanceOf(LPAddress, userAddress);
  // console.log('balanceBeforeTransaction', balanceBeforeTransaction );
  let callData0: any = {data:"0x"};
  let callData1: any = {data:"0x"};
  if(assetAdd.toLowerCase() == token0.toLowerCase() || assetAdd.toLowerCase() == token1.toLowerCase()){
    
    if(assetAdd.toLowerCase() == aquaAddress.toLowerCase() && poolData.name.toLowerCase() == "aqua"){
      
    }
    else if(assetAdd.toLowerCase() !== token0.toLowerCase()) {
      callData0 = await buildTxForSwapInMigrate(assetAdd, token0, transInfo.sendAmount0, slippageVal, userAddress, aquaConvertLpAddress)
    }
    else {
      callData1 = await buildTxForSwapInMigrate(assetAdd, token1, transInfo.sendAmount1, slippageVal, userAddress, aquaConvertLpAddress)
    }
  } else if(assetAdd.toLowerCase() !== aquaAddress.toLowerCase() && poolData.name.toLowerCase() == "aqua"){
    callData0 = await buildTxForSwapInMigrate(assetAdd, aquaAddress, transInfo.sendAmount0, slippageVal, userAddress, aquaConvertLpAddress)
    // console.log("callData0", callData0)
  } 
  else {
    callData0 = await buildTxForSwapInMigrate(assetAdd, token0, transInfo.sendAmount0, slippageVal, userAddress, aquaConvertLpAddress);
    let delayres = await delay(3000);
    callData1 = await buildTxForSwapInMigrate(assetAdd, token1, transInfo.sendAmount1, slippageVal, userAddress, aquaConvertLpAddress);
  }
  // console.log("call data val", callData0, callData1)

  const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
  
  let thena_LP_Array: any = ['0x5cdfb4cd95f733fd679343bbc39d12dff907251b', '0xcf32907c7ee99dccbd8feec2c26fad077f799890', '0x7b5a46d584477eb00fc88a3265724e50e7cbcb32', '0x06f5f260be2c541f134b5548e5e62952d7315363', '0x10a4a5e00769d0101bf6ba8ff10b4d68f4ba070b','0x590540c90f42eec56ec33009ce954c91fd3079b0'];
  let stable_LP_Array: any = [ '0xb32ae069db02f614e303aceaf5ad963d7de12194', '0x208adae61fd7587314c2f7f1566bd137e0dd0126' ];

  let poolType: any = 0;
  let routerAddress: any = PLANETROUTERADDRESS;

  if(thena_LP_Array.includes(poolData['strategyAddress'].toLowerCase())){
    poolType = 2;
    routerAddress = '0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109';
  }

  if(stable_LP_Array.includes(poolData['strategyAddress'].toLowerCase())){
    poolType = 1;
    routerAddress = '0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109';
  } 

  if(activeV3FusionPools.includes(poolData['strategyAddress'].toLowerCase())){
    poolType = 3;
    routerAddress = '0xf75c017e3b023a593505e281b565ed35cc120efa'//'0x6b3d98406779ddca311e6c43553773207b506fa6';
    lockIndex = 0
  } 
  //console.log('assetAdd -23', assetAdd )
  let time_lock_index = poolData.name.toLowerCase() == "gamma-btcb" ? 0 : lockIndex;
  if (assetAdd.toLowerCase() === bnbAddress.toLowerCase()) {
    // console.log("send token is bnb")      
    try {

      /** 
    @notice Zaps BNB into any LP Pair (including aggregated pairs) on Planet via One Inch Router
    @param _token0 One Inch calldata for swapping BNB to token0 of the LP Pair
    @param _token1 One Inch calldata for swapping BNB to token1 of the LP Pair
    @param _type LP Pair type, whether uniswapV2, solidly volatile or solidly stable
    @param _router Rourter where "Add Liquidity" is to be called, to create LP Pair
    @param _pair Address of the output LP Pair token
    
    function zapInBNB (bytes calldata _token0, bytes calldata _token1, WantType _type, address _router, address _pair, bool _stake, uint256 pid, uint256 lockTimeIndex)
    */

    
      // console.log("zap method data bnb", callData0.data, callData1.data, poolType, routerAddress, LPAddress, zapInStake, poolData.poolId, time_lock_index, amount)
      return planetZapInstance.methods
        .zapInBNB(callData0.data, callData1.data, poolType, routerAddress, LPAddress, zapInStake, poolData.poolId, time_lock_index)
        .send({ from: userAddress, value: amount })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
        .once('confirmation', async function (confNumber: any, receipt: any) {
          setConfirmTransHistory(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash, poolData, transInfo.liquidity, convertToEther(amount, 18))
          setCreateLpLoading(false)
          
        })
        .on('error', function (error: any) {
            console.log("error message", error)
            setCreateLpLoading(false)
            handleNotification('failed',"",poolData, transInfo.liquidity, convertToEther(amount, 18))
            return "error";
        })
    } catch (error) {
      console.log("error", error)
      return error
    }
  } else {
    // console.log("need to write the function !!")
    try {
      //console.log("zap method data", assetAdd, convertToWei(amount), callData0.data, callData1.data, poolType, routerAddress, LPAddress)
    //  console.log(assetAdd, convertToWei(amount), callData0.data, callData1.data, poolType, routerAddress, LPAddress, zapInStake, poolData.poolId, time_lock_index)
      return planetZapInstance.methods
        .zapIn(assetAdd, amount, callData0.data, callData1.data, poolType, routerAddress, LPAddress, zapInStake, poolData.poolId, time_lock_index)
        .send({ from: userAddress })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
        .once('confirmation', async function (confNumber: any, receipt: any) {
          setConfirmTransHistory(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash, poolData, transInfo.liquidity, convertToEther(amount, 18))
          setCreateLpLoading(false)
          return true;
        })
        .on('error', function (error: any) {
            console.log("error message", error)
            setCreateLpLoading(false)
            handleNotification('failed',"",poolData, transInfo.liquidity, convertToEther(amount, 18))
            return "error";
        })
    } catch (error) {
      console.log("error", error)
      return error
    }
  }
}

export const lpTokenApproveString = async (tokenAddress: string, spender: string, userAddress: string, allowanceAmount?: string ) => {

  let amt:any = MAX_INT;
  if(allowanceAmount) {
    amt = allowanceAmount+'';
  }
  try {

    const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, tokenAddress)
    return new Promise((resolve, reject) => {
      tokenInstance.methods
      .approve(spender, amt)
      .send({ from: userAddress })
        .on('confirmation', (confNumber: any, receipt: any) => {
          if (confNumber === 5) {
            resolve("success") 
          }
        })
        .on('error', (error:any) => {
          console.log(error)
          reject("Fail")
        })
    })
  } catch (error) {
    console.log('lpTokenApprove error', error);
    return "Fail"
  }
}

export const lpTokenApprove = async (tokenAddress: string, spender: string, userAddress: string, allowanceAmount?: number ) => {

  let amt:any = MAX_INT;
  if(allowanceAmount) {
    amt = allowanceAmount+'';
  }
  try {

    const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, tokenAddress)
    return new Promise((resolve, reject) => {
      tokenInstance.methods
      .approve(spender, amt)
      .send({ from: userAddress })
        .on('confirmation', (confNumber: any, receipt: any) => {
          if (confNumber === 5) {
            resolve("success") 
          }
        })
        .on('error', (error:any) => {
          console.log(error)
          reject("Fail")
        })
    })
  } catch (error) {
    console.log('lpTokenApprove error', error);
    return "Fail"
  }
}

export const createLPOriginal = async (amount: any, id: any, assetAdd: any, minLiquidity: any, userAddress: any, showTransLink: any, path1: any, path2: any, poolData: any, slippageVal: any) => {

  let farmAddress;
  if (poolData.isNewFarm) {
    farmAddress = gammaFarmAdddress
  } else {
    farmAddress = aquaFarmAddress
  }
  // console.log("createLP", farmAddress, amount, assetAdd, minLiquidity)
  const aquafarmInstance: any = new wallet.web3.eth.Contract(gammaFarmAbiOld, poolData.farmContractAddress)
  
  let poolDetails = await aquafarmInstance.methods.poolInfo(id).call()
  const LPAddress = poolDetails['want']
  const contract = await selectInstance(instanceType.PLANETLP, LPAddress)
  const token0 = await contract.methods.token0().call()
  const token1 = await contract.methods.token1().call()
  slippageVal = (1 - slippageVal/100) * 1000;
  if(assetAdd.toLowerCase() == usdcAddress.toLowerCase() || assetAdd.toLowerCase() == "0x55d398326f99059ff775485246999027b3197955".toLowerCase()){
    //export const getMinimumSwapOutToken = async(poolAddress: string, swapFromIndex: any, swapToIndex: any, swapAmount: any, precisionList: number[]) => {
      let from_token_index = assetAdd.toLowerCase() == "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d".toLowerCase() ? 1 : 2;
      // amount = await getMinimumSwapOutToken(stableSwapAddress, from_token_index, 0, amount, [])
      let amountInWei = convertToWei(amount)
      let min_swap_amount = await getMinimumSwapOutToken(stableSwapAddress, from_token_index, 0, amountInWei, [18, 18, 18])
      let stableSwapPoolData = [from_token_index, 0, min_swap_amount]
      assetAdd = stableSwapAddress;//"0xe9e7cea3dedca5984780bafc599bd69add087d56";
      return await zapInRevised(
        assetAdd,
        convertToWeiInternal(`${amount}`,1e18),
        path1,
        path2,
        slippageVal,
        `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
        PLANETROUTERADDRESS,
        userAddress,
        showTransLink,
        true,
        stableSwapPoolData
      )
  }
  if (assetAdd === bnbAddress) {
    // console.log("send token is bnb")
    if (token0 === bnbAddress || token1 === bnbAddress) {
      // console.log("one of the LP token is bnb")
      if (token1 === bnbAddress) {
        // console.log("token one is bnb")
        return await zapInRevised(
          assetAdd,
          convertToWeiInternal(`${amount}`,1e18),
          path1,
          path2,
          slippageVal,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          PLANETROUTERADDRESS,
          userAddress,
          showTransLink
        )
      } else {
        // console.log("token zero is bnb");
        return await zapInRevised(
          assetAdd,
          convertToWeiInternal(`${amount}`,1e18),
          path1,
          path2,
          slippageVal,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          PLANETROUTERADDRESS,
          userAddress,
          showTransLink
        )
      }
    } else {
      if (token0 === aquaAddress) {
        return await zapInRevised(
          assetAdd,
          convertToWeiInternal(`${amount}`,1e18),
          path1,
          path2,
          slippageVal,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          PLANETROUTERADDRESS,
          userAddress,
          showTransLink
        )
      }
      else {
        return await zapInRevised(
          assetAdd,
          convertToWeiInternal(`${amount}`,1e18),
          path1,
          path2,
          slippageVal,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          PLANETROUTERADDRESS,
          userAddress,
          showTransLink
        )
      }

    }
  } else {
    if (token0 === assetAdd || token1 === assetAdd) {
      if (token1 === assetAdd) {
        if (token0 === bnbAddress) {
          return await zapInRevised(
            assetAdd,
            convertToWeiInternal(`${amount}`,1e18),
            path1,
            path2,
            slippageVal,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            PLANETROUTERADDRESS,
            userAddress,
            showTransLink
          )
        } else {
          return await zapInRevised(
            assetAdd,
            convertToWeiInternal(`${amount}`,1e18),
            path1,
            path2,
            slippageVal,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            PLANETROUTERADDRESS,
            userAddress,
            showTransLink
          )
        }
      } else {
        if (token1 === bnbAddress) {
          return await zapInRevised(
            assetAdd,
            convertToWeiInternal(`${amount}`,1e18),
            path1,
            path2,
            slippageVal,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            PLANETROUTERADDRESS,
            userAddress,
            showTransLink
          )
        }
        else {
          return await zapInRevised(
            assetAdd,
            convertToWeiInternal(`${amount}`,1e18),
            path1,
            path2,
            slippageVal,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            PLANETROUTERADDRESS,
            userAddress,
            showTransLink
          )
        }

      }
    } else {
      if (token0 === bnbAddress) {
        return await zapInRevised(
          assetAdd,
          convertToWeiInternal(`${amount}`,1e18),
          path1,
          path2,
          slippageVal,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          PLANETROUTERADDRESS,
          userAddress,
          showTransLink
        )
      } else if (token1 === bnbAddress) {
        return await zapInRevised(
          assetAdd,
          convertToWeiInternal(`${amount}`,1e18),
          path1,
          path2,
          slippageVal,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          PLANETROUTERADDRESS,
          userAddress,
          showTransLink
        )
      } else {
        if (token0 === aquaAddress) {
          return await zapInRevised(
            assetAdd,
            convertToWeiInternal(`${amount}`,1e18),
            path1,
            path2,
            slippageVal,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            PLANETROUTERADDRESS,
            userAddress,
            showTransLink
          )
        }
        else {
          return await zapInRevised(
            assetAdd,
            convertToWeiInternal(`${amount}`,1e18),
            path1,
            path2,
            slippageVal,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            PLANETROUTERADDRESS,
            userAddress,
            showTransLink
          )
        }

      }
    }
  }
}

export const createLPOld = async (amount: any, id: any, assetAdd: any, minLiquidity: any, userAddress: any, showTransLink: any, path1: any, path2: any, poolData: any) => {

  let farmAddress;
  if (poolData.isNewFarm) {
    farmAddress = gammaFarmAdddress
  } else {
    farmAddress = aquaFarmAddress
  }
  const aquafarmInstance: any = await selectInstance('GAMMAFARM', farmAddress)
  let poolDetails = await aquafarmInstance.methods.poolInfo(id).call()
  const LPAddress = poolDetails['want']
  const contract = await selectInstance(instanceType.PLANETLP, LPAddress)
  const token0 = await contract.methods.token0().call()
  const token1 = await contract.methods.token1().call()
  if (assetAdd === bnbAddress) {
    if (token0 === bnbAddress || token1 === bnbAddress) {
      if (token1 === bnbAddress) {
        return await zapIn(
          assetAdd,
          zeroAddress,
          convertToWeiInternal(`${amount}`,1e18),
          LPAddress,
          path1,
          path2,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          userAddress,
          showTransLink
        )
      } else {
        return await zapIn(
          assetAdd,
          zeroAddress,
          convertToWeiInternal(`${amount}`,1e18),
          LPAddress,
          path1,
          path2,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          userAddress,
          showTransLink
        )
      }
    } else {
      if (token0 === aquaAddress) {
        return await zapIn(
          assetAdd,
          zeroAddress,
          convertToWeiInternal(`${amount}`,1e18),
          LPAddress,
          path1,
          path2,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          userAddress,
          showTransLink
        )
      }
      else {
        return await zapIn(
          assetAdd,
          zeroAddress,
          convertToWeiInternal(`${amount}`,1e18),
          LPAddress,
          path1,
          path2,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          userAddress,
          showTransLink
        )
      }

    }
  } else {
    if (token0 === assetAdd || token1 === assetAdd) {
      if (token1 === assetAdd) {
        if (token0 === bnbAddress) {
          return await zapIn(
            assetAdd,
            assetAdd,
            convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(token1))),
            LPAddress,
            path1,
            path2,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            userAddress,
            showTransLink
          )
        } else {
          return await zapIn(
            assetAdd,
            assetAdd,
            convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(token1))),
            LPAddress,
            path1,
            path2,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            userAddress,
            showTransLink
          )
        }
      } else {
        if (token1 === bnbAddress) {
          return await zapIn(
            assetAdd,
            assetAdd,
            convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(token0))),
            LPAddress,
            path1,
            path2,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            userAddress,
            showTransLink
          )
        }
        else {
          return await zapIn(
            assetAdd,
            assetAdd,
            convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(token0))),
            LPAddress,
            path1,
            path2,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            userAddress,
            showTransLink
          )
        }

      }
    } else {
      if (token0 === bnbAddress) {
        return await zapIn(
          assetAdd,
          assetAdd,
          convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(assetAdd))),
          LPAddress,
          path1,
          path2,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          userAddress,
          showTransLink
        )
      } else if (token1 === bnbAddress) {
        return await zapIn(
          assetAdd,
          assetAdd,
          convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(assetAdd))),
          LPAddress,
          path1,
          path2,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          userAddress,
          showTransLink
        )
      } else {
        if (token0 === aquaAddress) {
          return await zapIn(
            assetAdd,
            assetAdd,
            convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(assetAdd))),
            LPAddress,
            path1,
            path2,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            userAddress,
            showTransLink
          )
        }
        else {
          return await zapIn(
            assetAdd,
            assetAdd,
            convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(assetAdd))),
            LPAddress,
            path1,
            path2,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            userAddress,
            showTransLink
          )
        }

      }
    }
  }
}


const pcsZapIn = async (assetAdd: any, address: any, amount: any, lpAddress: any, token0Path: any, token1Path: any, minLiquidity: any, userAddress: any, showTransLink: any) => {
  const SwampContract = new wallet.web3.eth.Contract(oldZapAbi, oldZapAddress)//await selectInstance(instanceType.AQUAZAPINABI)

  if (assetAdd === bnbAddress) {
    try {
      return SwampContract.methods
        .zapIn(address, amount, lpAddress, token0Path, token1Path, minLiquidity, pcsRouterAddress)
        .send({ from: userAddress, value: amount, })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
    } catch (error) {
      return error
    }
  } else {
    try {
      return SwampContract.methods
        .zapIn(address, amount, lpAddress, token0Path, token1Path, minLiquidity, pcsRouterAddress)
        .send({ from: userAddress, })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
    } catch (error) {
      return error
    }
  }
}

export const createPcsLP = async (amount: any, id: any, assetAdd: any, minLiquidity: any, userAddress: any, showTransLink: any, path1: any, path2: any, poolData: any) => {

  let farmAddress
  if (poolData.isNewFarm) {
    farmAddress = gammaFarmAdddress
  } else {
    farmAddress = aquaFarmAddress
  }

  const aquafarmInstance: any = new wallet.web3.eth.Contract(gammaFarmAbiOld, poolData.farmContractAddress)
  let poolDetails = await aquafarmInstance.methods.poolInfo(id).call()
  const LPAddress = poolDetails['want']
  const contract = await selectInstance(instanceType.PLANETLP, LPAddress)
  const token0 = await contract.methods.token0().call()
  const token1 = await contract.methods.token1().call()
  if (assetAdd === bnbAddress) {
    if (token0 === bnbAddress || token1 === bnbAddress) {
      if (token1 === bnbAddress) {
        return await pcsZapIn(
          assetAdd,
          zeroAddress,
          convertToWeiInternal(`${amount}`,1e18),
          LPAddress,
          path1,
          path2,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          userAddress,
          showTransLink
        )
      } else {
        return await pcsZapIn(
          assetAdd,
          zeroAddress,
          convertToWeiInternal(`${amount}`,1e18),
          LPAddress,
          path1,
          path2,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          userAddress,
          showTransLink
        )
      }
    } else {
      return await pcsZapIn(
        assetAdd,
        zeroAddress,
        convertToWeiInternal(`${amount}`,1e18),
        LPAddress,
        path1,
        path2,
        `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
        userAddress,
        showTransLink
      )
    }
  } else {
    if (token0 === assetAdd || token1 === assetAdd) {
      if (token1 === assetAdd) {
        if (token0 === bnbAddress) {
          return await pcsZapIn(
            assetAdd,
            assetAdd,
            convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(token1))),
            LPAddress,
            path1,
            path2,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            userAddress,
            showTransLink
          )
        } else {
          return await pcsZapIn(
            assetAdd,
            assetAdd,
            convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(token1))),
            LPAddress,
            path1,
            path2,
            `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
            userAddress,
            showTransLink
          )
        }
      } else {
        return await pcsZapIn(
          assetAdd,
          assetAdd,
          convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(token0))),
          LPAddress,
          path1,
          path2,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          userAddress,
          showTransLink
        )
      }
    } else {
      if (token0 === bnbAddress) {
        return await pcsZapIn(
          assetAdd,
          assetAdd,
          convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(assetAdd))),
          LPAddress,
          path1,
          path2,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          userAddress,
          showTransLink
        )
      } else if (token1 === bnbAddress) {
        return await pcsZapIn(
          assetAdd,
          assetAdd,
          convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(assetAdd))),
          LPAddress,
          path1,
          path2,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          userAddress,
          showTransLink
        )
      } else {
        return await pcsZapIn(
          assetAdd,
          assetAdd,
          convertToWeiInternal(`${amount}`,Math.pow(10,await decimals(assetAdd))),
          LPAddress,
          path1,
          path2,
          `${convertToWeiInternal(minLiquidity.toFixed(18),1e18)}`,
          userAddress,
          showTransLink
        )
      }
    }
  }
}

/* ============================= Zap In Belt =========================== */

export const get4BeltLPData = async (amount: any, assetAdd: any, slippage: any) => {
  amount = amount.toString()
  const router: any = await selectInstance(instanceType.PCSROUTER)
  const beltInstance: any = await selectInstance(instanceType.BELT4)
  let amountsOut0: any = '0'
  let returnLiquidity: any = ""
  if (assetAdd === busdAddress) {
    amountsOut0 = convertToWeiInternal(amount,1e18)
    returnLiquidity = convertToEther(await beltInstance.methods.calc_token_amount([amountsOut0, 0, 0, 0], true).call(),18)
  }
  else {
    amountsOut0 = await router.methods.getAmountsOut(convertToWeiInternal(amount,1e18), [assetAdd, busdAddress,]).call()
    returnLiquidity = convertToEther(await beltInstance.methods.calc_token_amount([amountsOut0[1], 0, 0, 0], true).call(),18)
  }

  const amountIn = (returnLiquidity - (0.01224038251890815166348665098449 * returnLiquidity))
  return {
    liquidity: amountIn,
    minLiquidity: (1 - slippage / 100) * amountIn
  }

}

export const create4BeltLP = async (amount: any, id: any, assetAdd: any, minLiquidity: any, userAddress: any, showTransLink: any) => {
  const SwampContract = new wallet.web3.eth.Contract(oldZapAbi, oldZapAddress)//await selectInstance(instanceType.AQUAZAPINABI)
  const aquafarmInstance: any = await selectInstance('AQUAFARM', aquaFarmAddress)
  let poolDetails = await aquafarmInstance.methods.poolInfo(id).call()
  const LPAddress = poolDetails['want']

  if (assetAdd === bnbAddress) {
    const token0Path = [bnbAddress, busdAddress]
    try {
      return SwampContract.methods
        .zapIn4Belt(zeroAddress, convertToWeiInternal(amount.toString(),1e18), LPAddress, token0Path, convertToWeiInternal(minLiquidity.toString(),1e18), pcsRouterAddress)
        .send({ from: userAddress, value: convertToWeiInternal(amount.toString(),1e18), })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
    } catch (error) {
      return error
    }
  }
  else if (assetAdd === busdAddress) {
    const token0Path: any = []
    try {
      return SwampContract.methods
        .zapIn4Belt(assetAdd, convertToWeiInternal(amount.toString(),1e18), LPAddress, token0Path, convertToWeiInternal(minLiquidity.toString(),1e18), pcsRouterAddress)
        .send({ from: userAddress, })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
    } catch (error) {
      return error
    }
  }
  else {
    const token0Path: any = [assetAdd, busdAddress]
    try {
      return SwampContract.methods
        .zapIn4Belt(assetAdd, convertToWeiInternal(amount.toString(),Math.pow(10,await decimals(assetAdd))), LPAddress, token0Path, convertToWeiInternal(minLiquidity.toString(),1e18), pcsRouterAddress)
        .send({ from: userAddress, })
        .once('transactionHash', function (hash: any) {
          if (!!hash) {
            showTransLink(hash)
          }
        })
    } catch (error) {
      return error
    }
  }
}

export const getConvertList = async (userAddress: string, poolId: number, poolData: any) => {
  let farmAddress;
  if (poolData.isNewFarm) {
    farmAddress = gammaFarmAdddress
  } else {
    farmAddress = aquaFarmAddress
  }

  //const poolIdExist = Object.keys(tokenList).includes(poolId.toString());

  const poolDetails = await poolInfoNew(farmAddress, poolId.toString());
  const wantAddress = poolDetails['want']
  const token0Address = poolData.isStablePool ? busdAddress : await token1(wantAddress);
  const token1Address = poolData.isStablePool ? usdcAddress: await token2(wantAddress);

  const token0Instance = await selectInstance(instanceType.PLANETLP, token0Address)
  const token1Instance = await selectInstance(instanceType.PLANETLP, token1Address)
  let token0Name = await token0Instance.methods.symbol().call()
  let token1Name = await token1Instance.methods.symbol().call()
  token0Name = token0Name.toUpperCase()
  token1Name = token1Name.toUpperCase()

  let token1PairExist = true
  let token2PairExist = true;

  if (farmAddress === gammaFarmAdddress || aquaPairPoolId.includes(poolId)) {
    // for planet router pools
    token1PairExist = await returnPairExistOrNot(token0Address, wbnbAddress);
    token2PairExist = await returnPairExistOrNot(token1Address, wbnbAddress);
  }

  let userToken0Bal = 0;
  let userToken1Bal = 0;
  let busdBalance = 0;
  let userBnbBal = 0;

  if (userAddress) {

    userToken0Bal = convertToEther(await balanceOf(token0Address, userAddress),await decimals(token0Address))
    userToken1Bal = convertToEther(await balanceOf(token1Address, userAddress),await decimals(token1Address))
    busdBalance = convertToEther(await balanceOf(busdAddress, userAddress),18);
    userBnbBal = await tokenBalance(wbnbAddress, userAddress, 0);

  }

  if (token0Name !== "WBNB" && token1Name !== "WBNB") {
    let arr = [
      { tokenName: token0Name, bal: userToken0Bal, tokenAddress: token0Address },
      { tokenName: token1Name, bal: userToken1Bal, tokenAddress: token1Address },
      { tokenName: "BNB", bal: userBnbBal, tokenAddress: zeroAddress },
    ]

    if (token0Name !== "BUSD" && token1Name !== "BUSD") {
      arr.push({ tokenName: "BUSD", bal: busdBalance, tokenAddress: busdAddress })
    }
    return arr
  }
  else {
    if (token0Name === "WBNB") {
      let arr = [
        { tokenName: token1Name, bal: userToken1Bal, tokenAddress: token1Address },
        { tokenName: "BNB", bal: userBnbBal, tokenAddress: zeroAddress },
      ]
      if (token0Name !== "BUSD" && token1Name !== "BUSD") {
        arr.push({ tokenName: "BUSD", bal: busdBalance, tokenAddress: busdAddress })
      }
      return arr
    }
    else {
      let arr = [
        { tokenName: token0Name, bal: userToken0Bal, tokenAddress: token0Address },
        { tokenName: "BNB", bal: userBnbBal, tokenAddress: zeroAddress }
      ]
      if (token0Name !== "BUSD" && token1Name !== "BUSD") {
        arr.push({ tokenName: "BUSD", bal: busdBalance, tokenAddress: busdAddress })
      }
      return arr
    }
  }
}

const returnPcsPairImpacts = async (path: string[], amount1: any): Promise<number> => {

  let imapctArr: any = []
  const ROUTER_INSTANCE: any = await selectInstance(instanceType.PCSROUTER);
  const amounts = await ROUTER_INSTANCE.methods.getAmountsOut(convertToWeiInternal(amount1,Math.pow(10,await decimals(path[0]))), path).call();
  for (let i = 0; i < path.length - 1; i++) {
    const tokenA = path[i];
    const tokenB = path[i + 1];
    const getReserves: any = await returnPcsTokenReserves(tokenA, tokenB)
    const constantProduct: any = getReserves['0'] * getReserves[1]
    const marketPrice: any = getReserves['0'] / getReserves['1']

    const interMediateAmount = convertToEther(amounts[i],await decimals(path[i]))

    const tokenA_ReservesAfterSwap: any = parseFloat(getReserves['0']) + parseFloat(interMediateAmount)
    const tokenB_ReservesAfterSwap: any = parseFloat(constantProduct) / parseFloat(tokenA_ReservesAfterSwap)
    const tokenB_Received: any = getReserves['1'] - tokenB_ReservesAfterSwap
    const pricePaidPerTokenB: any = parseFloat(interMediateAmount) / tokenB_Received
    const impact: any = ((pricePaidPerTokenB - marketPrice) / marketPrice) * 100
    imapctArr.push(impact);
  }
  return Math.max(...imapctArr)
}

const returnPlanetPairImpacts = async (path: string[], amount1: any): Promise<number> => {

  let imapctArr: any = []
  const ROUTER_INSTANCE: any = await selectInstance(instanceType.PLANETROUTER);
  // console.log("swap amounts check", amount1, path)
  const amounts = await ROUTER_INSTANCE.methods.getAmountsOut(convertToWeiInternal(amount1,Math.pow(10,await decimals(path[0]))), path).call();
  // console.log("amount in price impact", amounts, path, amount1)
  for (let i = 0; i < path.length - 1; i++) {
    const tokenA = path[i];
    const tokenB = path[i + 1];
    const getReserves: any = await returnTokenReserves(tokenA, tokenB)
    const constantProduct: any = getReserves['0'] * getReserves[1]
    const marketPrice: any = getReserves['0'] / getReserves['1']

    const interMediateAmount = convertToEther(amounts[i],await decimals(path[i]))

    const tokenA_ReservesAfterSwap: any = parseFloat(getReserves['0']) + parseFloat(interMediateAmount)
    const tokenB_ReservesAfterSwap: any = parseFloat(constantProduct) / parseFloat(tokenA_ReservesAfterSwap)
    const tokenB_Received: any = getReserves['1'] - tokenB_ReservesAfterSwap
    const pricePaidPerTokenB: any = parseFloat(interMediateAmount) / tokenB_Received
    const impact: any = ((pricePaidPerTokenB - marketPrice) / marketPrice) * 100
    imapctArr.push(impact);
  }
  return Math.max(...imapctArr)
}

export const getConvertStableLpData = async (userAddress: string, wantAmountInEth: string, poolId: number, convertedToken: string, poolData: any, slippageVal: any) => {
  let userWantBalance: any = 0
  let receivedToken: any = 0
  let currentAllowance: any = 0
  let routerAddress: string = PLANETROUTERADDRESS;
  let path1: any = [];
  let path2: any = [];
  let optimalPathObject: any = {};
  slippageVal = (1 - slippageVal/100)
  if (userAddress) {
    userWantBalance = convertToEther(await balanceOf(poolData.wantAddress, userAddress),await decimals(poolData.wantAddress));
    currentAllowance = convertToEther(await allowance(poolData.wantAddress, userAddress, aquaConvertLpAddress),await decimals(poolData.wantAddress))
    // console.log("user want balance", userWantBalance, currentAllowance, poolData.wantAddress)
  }
  const wantTotalSupply = convertToEther(await totalSupply(poolData.wantAddress),18);
  if(convertedToken.toLowerCase() !== busdAddress.toLowerCase() && convertedToken.toLowerCase() !== usdtAddress.toLowerCase() && convertedToken.toLowerCase() !== usdcAddress.toLowerCase() ){
    optimalPathObject = (await returnPlanetOptimalPath(busdAddress, convertedToken, wantAmountInEth));
    // console.log("path for converting stable lp to non stable token",optimalPathObject, convertedToken);
    path1 = optimalPathObject.path;    
  } else {
    let withdraw_from_token = convertedToken.toLowerCase() == busdAddress.toLowerCase() ? 0 : convertedToken.toLowerCase() == usdcAddress.toLowerCase() ? 1 : 2;
    let withdrawTokenAmount = await withdrawTokenFromStableLP(stableSwapAddress, withdraw_from_token, convertToWei(wantAmountInEth));
    optimalPathObject.amountOut = withdrawTokenAmount;
  }

  return {
    receivedToken: convertToEther(optimalPathObject.amountOut, 18),
    minReceived: convertToEther(optimalPathObject.amountOut, 18) * slippageVal,
    priceImpact: 0,
    path1: path1,
    path2: [],
    wantAddress: poolData.wantAddress,
    routerAddress: PLANETROUTERADDRESS,
    userWantBalance: 0,
    allowance: currentAllowance
  }
} 

export const getConvertLpDataOriginal = async (userAddress: string, wantAmountInEth: string, poolId: number, convertedToken: string, poolData: any, slippageVal?: any) => {
  // console.log("inside get convert lp data", wantAmountInEth, poolId, convertedToken, poolData)
  if (convertedToken === zeroAddress) {
    convertedToken = wbnbAddress
  }
  let farmAddress;
  if (poolData.isNewFarm) {
    farmAddress = gammaFarmAdddress
  } else {
    farmAddress = aquaFarmAddress
  }

  const poolDetails = await poolInfo(poolData.farmContractAddress, poolId.toString());
  const wantAddress = poolDetails['want']
  // console.log("pool details", poolDetails, poolId)
  const token0Address = await token1(wantAddress);
  const token1Address = await token2(wantAddress);
  let userWantBalance: any = 0
  let receivedToken: any = 0
  let currentAllowance: any = 0
  let routerAddress: string = zeroAddress;
  let path1: any = [];
  let path2: any = [];

  if (token0Address !== zeroAddress && token1Address !== zeroAddress) {

    if (poolData.isBoostPool) {
      routerAddress = pcsRouterAddress;
    }
    else if (gammaFarmAdddress === farmAddress || aquaPairPoolId.includes(poolId)) {
      routerAddress = PLANETROUTERADDRESS;
    }
    else {
      routerAddress = pcsRouterAddress;
    }

    if (routerAddress === pcsRouterAddress) {
      if (token0Address !== convertedToken) {

        if (token1Address !== convertedToken)
          path1 = [[token0Address, convertedToken], [token0Address, token1Address, convertedToken]];
        else
          path1 = [[token0Address, convertedToken]]

      }
      else {
        path1 = []
      }
    }
    else {
      if (token0Address !== convertedToken) {
        path1 = (await returnPlanetOptimalPath(token0Address, convertedToken, wantAmountInEth)).path
        path1 = [path1]
      }
      else {
        path1 = []
      }
    }

    if (routerAddress === pcsRouterAddress) {
      if (token1Address !== convertedToken) {

        if (token0Address !== convertedToken)
          path2 = [[token1Address, convertedToken], [token1Address, token0Address, convertedToken]];
        else
          path2 = [[token1Address, convertedToken]]

      }
      else {
        path2 = []
      }
    }
    else {
      if (token1Address !== convertedToken) {
        path2 = (await returnPlanetOptimalPath(token1Address, convertedToken, wantAmountInEth)).path
        path2 = [path2]
      }
      else {
        path2 = []
      }
    }

    if (userAddress) {
      userWantBalance = convertToEther(await balanceOf(wantAddress, userAddress),await decimals(wantAddress));
      currentAllowance = convertToEther(await allowance(wantAddress, userAddress, aquaConvertLpAddress),await decimals(wantAddress))
      // console.log("user want balance", userWantBalance, currentAllowance, wantAddress)
    }

    const wantAddToken0Bal = convertToEther(await balanceOf(token0Address, wantAddress),await decimals(token0Address));
    const wantAddToken1Bal = convertToEther(await balanceOf(token1Address, wantAddress),await decimals(token1Address));
    const wantTotalSupply = convertToEther(await totalSupply(wantAddress),18);
    // console.log("token want balance", wantAddToken0Bal, wantAddToken1Bal, wantTotalSupply)
    const token1SwapAmt = parseFloat(wantAmountInEth) * (wantAddToken0Bal / wantTotalSupply);
    const token2SwapAmt = parseFloat(wantAmountInEth) * (wantAddToken1Bal / wantTotalSupply);

    let impact1 = 0
    let impact2 = 0

    if (path1.length > 0) {

      if (routerAddress === PLANETROUTERADDRESS) {
        let impacts: any = [];
        for (let i = 0; i < path1.length; i++) {
          if (path1[i].length !== 0)
            impacts.push(await returnPlanetPairImpacts(path1[i], token1SwapAmt))
        }
        impact1 = (Math.min(...impacts))
        path1 = path1[impacts.findIndex((e: any) => e === impact1)]
      }
      else {
        let impacts: any = []
        for (let i = 0; i < path1.length; i++) {
          if (path1[i].length !== 0)
            impacts.push(await returnPcsPairImpacts(path1[i], token1SwapAmt))
        }

        impact1 = Math.min(...impacts)
        path1 = path1[impacts.findIndex((e: any) => e === impact1)]
      }

    }

    if (path2.length > 0) {

      if (routerAddress === PLANETROUTERADDRESS) {
        let impacts: any = []
        for (let i = 0; i < path2.length; i++) {
          if (path2[i].length !== 0)
            impacts.push(await returnPlanetPairImpacts(path2[i], token2SwapAmt))
        }
        impact2 = Math.min(...impacts)
        path2 = path2[impacts.findIndex((e: any) => e === impact2)]
      }
      else {
        let impacts: any = []
        for (let i = 0; i < path2.length; i++) {
          if (path2[i].length !== 0)
            impacts.push(await returnPcsPairImpacts(path2[i], token2SwapAmt))
        }
        impact2 = Math.min(...impacts)
        path2 = path2[impacts.findIndex((e: any) => e === impact2)]
      }

    }
    try{
      if(path1.length == 0 ){
        path1 = [convertedToken]
      }
      if(path2.length == 0 ){
        path2= [convertedToken]
      }
      const CONVERT_LP_INSTANCE = new wallet.web3.eth.Contract(oldZapAbi, oldZapAddress);//await selectInstance(instanceType.CONVERTLP);
      // console.log("wantAmountInEth", wantAmountInEth, wantAddress, convertToWeiInternal(wantAmountInEth,Math.pow(10,await decimals(wantAddress))), routerAddress, path1, path2)
      if (parseFloat(wantAmountInEth) > 0)
      receivedToken = await CONVERT_LP_INSTANCE.methods.convertLpOutput(wantAddress, convertToWeiInternal(wantAmountInEth,Math.pow(10,await decimals(wantAddress))), routerAddress, path1, path2).call()
      const d = (path1.length > 0) ? await decimals(path1[path1.length - 1]) : await decimals(path2[path2.length - 1]) 
      slippageVal = slippageVal ? slippageVal : 0.5;
      
      return {
        receivedToken: convertToEther(receivedToken,d),
        minReceived: convertToEther(receivedToken,d) * ( 1 - slippageVal/100),
        priceImpact: (impact1 > impact2) ? impact1 : impact2,
        path1: path1,
        path2: path2,
        wantAddress: wantAddress,
        routerAddress: routerAddress,
        userWantBalance: userWantBalance,
        allowance: currentAllowance
      }
    }catch(error){
      console.log(wantAmountInEth, error)
    }
    
    
  }
  else {
    return {
      receivedToken: 0,
      minReceived: 0,
      priceImpact: 0,
      path1: [],
      path2: [],
      wantAddress: zeroAddress,
      routerAddress: zeroAddress,
      userWantBalance: 0,
      allowance: currentAllowance
    }
  }
}

export const getConvertLpData = async (userAddress: string, wantAmountInEth: string, poolId: number, convertedToken: string, poolData: any, slippageVal?: any) => {
  if (convertedToken === zeroAddress) {
    convertedToken = wbnbAddress
  }
  let farmAddress;
  if (poolData.isNewFarm) {
    farmAddress = gammaFarmAdddress
  } else {
    farmAddress = aquaFarmAddress
  }

  // const poolDetails = await poolInfoNew(poolData.farmContractAddress, poolId.toString());
  const wantAddress = poolData.wantAddress;//poolDetails['want']
  // console.log("pool details", poolDetails, poolId)
  const token0Address = await token1(wantAddress);
  const token1Address = await token2(wantAddress);
  let userWantBalance: any = 0
  let receivedAmount: any = 0
  let minReceived: any = 0
  let currentAllowance: any = 0
  let routerAddress: string = zeroAddress;
  let path1: any = [];
  let path2: any = [];
  if(token0Address !== undefined && token1Address !== undefined && wantAmountInEth && +wantAmountInEth > 0) {
    const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
  
    // console.log("remove liquiity quote params", wantAddress, token0Address, token1Address, convertToWei(wantAmountInEth))
    let quoteRemoveLiquity = activeFusionPools.includes(poolData.strategyAddress.toLowerCase()) ? await planetZapInstance.methods.quoteRemoveLiquidityGammaUniproxy(wantAddress, token0Address, convertToWei(wantAmountInEth)).call() : await planetZapInstance.methods.quoteRemoveLiquidity(wantAddress, token0Address, token1Address, convertToWei(wantAmountInEth)).call();
    // console.log("quoteRemoveLiquity", quoteRemoveLiquity, token1Address, token0Address, convertedToken)
    //call get quote on the receive token
    let quote0: any = {};
    let quote1: any = {};
    if(token0Address.toLowerCase() == convertedToken.toLowerCase()){
      quote0.toTokenAmount =  quoteRemoveLiquity.amountA
      quote1 = await getQuote(convertedToken, token1Address, quoteRemoveLiquity.amountB)
    } else if(token1Address.toLowerCase() == convertedToken.toLowerCase()){
      quote0 = await getQuote(convertedToken, token0Address, quoteRemoveLiquity.amountA)
      quote1.toTokenAmount = quoteRemoveLiquity.amountB
    } else {
      quote0 = await getQuote(convertedToken, token0Address, quoteRemoveLiquity.amountA)
      let delayres = await delay(2500);
      quote1 = await getQuote(convertedToken, token1Address, quoteRemoveLiquity.amountB)
    }
    
    let token0Price = await getTokenPrice(token0Address);
    let token1Price = await getTokenPrice(token1Address);
    let amount_before_quote = convertToEther(quoteRemoveLiquity.amountA, 18) * token0Price + convertToEther(quoteRemoveLiquity.amountB, 18) * token1Price;
    
    // if (userAddress) {
    //   userWantBalance = convertToEther(await balanceOf(wantAddress, userAddress),await decimals(wantAddress));
    //   currentAllowance = convertToEther(await allowance(wantAddress, userAddress, aquaConvertLpAddress),await decimals(wantAddress))
    //   console.log("user want balance", userWantBalance, currentAllowance, wantAddress)
    // }
    // console.log("quote values in remove liquidity", quote0, quote1)
    receivedAmount = convertToEther(BigNumber(quote0.toTokenAmount).plus(quote1.toTokenAmount).toString(), 18);
    minReceived = ( 1 - slippageVal/100) * receivedAmount;
    routerAddress = PLANETROUTERADDRESS;
    let lpObject = {
      receivedAmount,
      minReceived,
      priceImpact: 0,
      path1: [],
      path2: [],
      wantAddress,
      routerAddress,
      userWantBalance,
      allowance: currentAllowance,
      token0Address,
      token1Address,
      poolData,
      quote0,
      quote1,
      amount_before_quote
    }
    // console.log("return data", lpObject)
    return lpObject;
  } else {
    return {
      receivedAmount: 0,
      minReceived: 0,
      priceImpact: 0,
      path1: [],
      path2: [],
      wantAddress: zeroAddress,
      routerAddress: zeroAddress,
      userWantBalance: 0,
      allowance: currentAllowance,
      token0Address: zeroAddress,
      token1Address: zeroAddress,
      poolDetails: {}
    };
  }
}

export const getConvertLpDataV3 = async (userAddress: string, wantAmountInEth: string, poolId: number, convertedToken: string, poolData: any, slippageVal?: any) => {
  // console.log("inside get convert lp data", wantAmountInEth, poolId, convertedToken, poolData)
  if (convertedToken === zeroAddress) {
    convertedToken = wbnbAddress
  }
  let farmAddress;
  if (poolData.isNewFarm) {
    farmAddress = gammaFarmAdddress
  } else {
    farmAddress = aquaFarmAddress
  }

  // const poolDetails = await poolInfoNew(poolData.farmContractAddress, poolId.toString());
  const wantAddress = poolData.wantAddress;//poolDetails['want']
  const strategyAddress = poolData.strategyAddress;
  // console.log("pool details", poolDetails, poolId)
  const token0Address = poolData.token0;//await token1(wantAddress);
  const token1Address = poolData.token1;//await token2(wantAddress);
  let userWantBalance: any = 0
  let receivedAmount: any = 0
  let minReceived: any = 0
  let currentAllowance: any = 0
  let routerAddress: string = zeroAddress;
  let path1: any = [];
  let path2: any = [];
  if(token0Address !== undefined && token1Address !== undefined && wantAmountInEth && +wantAmountInEth > 0) {
    const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
  
    // console.log("remove liquiity quote params", wantAddress, token0Address, token1Address, convertToWei(wantAmountInEth))
    let quoteRemoveLiquity = activeV3FusionPools.includes(poolData.strategyAddress.toLowerCase()) ? await planetZapInstance.methods.quoteRemoveLiquidityGammaUniproxy(wantAddress, token0Address, convertToWei(wantAmountInEth)).call() : await planetZapInstance.methods.quoteRemoveLiquidity(wantAddress, token0Address, token1Address, convertToWei(wantAmountInEth)).call();
    // console.log("quoteRemoveLiquity", quoteRemoveLiquity, token1Address, token0Address, convertedToken)
    //call get quote on the receive token
    let quote0: any = {};
    let quote1: any = {};
    if(token0Address.toLowerCase() == convertedToken.toLowerCase()){
      quote0.toTokenAmount = quoteRemoveLiquity.amountA;
      quote1 = await getQuote(convertedToken, token1Address, quoteRemoveLiquity.amountB);
    } else if(token1Address.toLowerCase() == convertedToken.toLowerCase()){
      quote0 = await getQuote(convertedToken, token0Address, quoteRemoveLiquity.amountA)
      quote1.toTokenAmount = quoteRemoveLiquity.amountB;
    } else {
      quote0 = await getQuote(convertedToken, token0Address, quoteRemoveLiquity.amountA)
      console.log(quote0, convertedToken, token0Address, quoteRemoveLiquity.amountA)
      let delayres = await delay(2000);
      quote1 = await getQuote(convertedToken, token1Address, quoteRemoveLiquity.amountB)
      console.log(quote1, convertedToken, token1Address, quoteRemoveLiquity.amountB)
    }
    
    let token0Price = await getTokenPrice(token0Address);
    let token1Price = await getTokenPrice(token1Address);
    let amount_before_quote = convertToEther(quoteRemoveLiquity.amountA, 18) * token0Price + convertToEther(quoteRemoveLiquity.amountB, 18) * token1Price;
    
    // if (userAddress) {
    //   userWantBalance = convertToEther(await balanceOf(wantAddress, userAddress),await decimals(wantAddress));
    //   currentAllowance = convertToEther(await allowance(wantAddress, userAddress, aquaConvertLpAddress),await decimals(wantAddress))
    //   console.log("user want balance", userWantBalance, currentAllowance, wantAddress)
    // }
    // console.log("quote values in remove liquidity", quote0, quote1)
    receivedAmount = convertToEther(BigInt(quote0.toTokenAmount) + BigInt(quote1.toTokenAmount), 18);
    minReceived = ( 1 - slippageVal/100) * receivedAmount;
    routerAddress = PLANETROUTERADDRESS;
    let lpObject = {
      receivedAmount,
      minReceived,
      priceImpact: 0,
      path1: [],
      path2: [],
      wantAddress,
      routerAddress,
      userWantBalance,
      allowance: currentAllowance,
      token0Address,
      token1Address,
      poolData,
      quote0,
      quote1,
      amount_before_quote,
      strategyAddress,
      convertedToken
    }
    console.log("return data", lpObject)
    return lpObject;
  } else {
    return {
      receivedAmount: 0,
      minReceived: 0,
      priceImpact: 0,
      path1: [],
      path2: [],
      wantAddress: zeroAddress,
      routerAddress: zeroAddress,
      userWantBalance: 0,
      allowance: currentAllowance,
      token0Address: zeroAddress,
      token1Address: zeroAddress,
      poolDetails: {},
      strategyAddress,
      convertedToken
    };
  }
}

export const fetchConvertLpForAqua = async (userAddress: string, wantAmountInEth: string, poolId: number, convertedToken: string, poolData: any, slippageVal?: any) => {

  let routerAddress = PLANETROUTERADDRESS;
  let userWantBalance: any = 0
  let receivedAmount: any = 0
  let minReceived: any = 0
  let currentAllowance: any = 0
  if(convertedToken.toLowerCase() == aquaAddress.toLowerCase()){
    let lpObject = {
      receivedAmount: wantAmountInEth,
      minReceived: parseFloat(wantAmountInEth) * (1 - slippageVal/100),
      priceImpact: 0,
      path1: [],
      path2: [],
      wantAddress: poolData.wantAddress,
      routerAddress,
      userWantBalance,
      allowance: currentAllowance,
      token0: aquaAddress,
      token1: aquaAddress,
      poolData,
      quote: wantAmountInEth,
      quote1: wantAmountInEth,
      amount_before_quote: wantAmountInEth,
      convertedToken
    }
    return lpObject;
  } else {
    let swapQuote = await getQuote(convertedToken, aquaAddress, convertToWei(wantAmountInEth))
    let lpObject = {
      receivedAmount: convertToEther(swapQuote.toAmount,18),
      minReceived: parseFloat(convertToEther(swapQuote.toAmount,18)) * (1 - slippageVal/100),
      priceImpact: 0,
      path1: [],
      path2: [],
      wantAddress: poolData.wantAddress,
      routerAddress,
      userWantBalance,
      allowance: currentAllowance,
      token0: aquaAddress,
      token1: aquaAddress,
      poolData,
      quote: wantAmountInEth,
      quote1: wantAmountInEth,
      amount_before_quote: wantAmountInEth,
      convertedToken
    }
    return lpObject;
  }
}

export const convertLpNew = async (userAddress: string, wantAddress: string, wantAmountInEth: string, routerAddress: string, convertedToken: string, path1: any, path2: any, receiveTokenAddress: string, slippageVal: any, showTransLink: any, setShowTransModal: any, handleNotification: any, setConvertLoading: any, convertLPObj: any, setConfirmTransHistory: any, updateTokenList: any) => {
  const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
  let thena_LP_Array: any = ['0x5cdfb4cd95f733fd679343bbc39d12dff907251b', '0xcf32907c7ee99dccbd8feec2c26fad077f799890', '0x7b5a46d584477eb00fc88a3265724e50e7cbcb32', '0x06f5f260be2c541f134b5548e5e62952d7315363', '0x10a4a5e00769d0101bf6ba8ff10b4d68f4ba070b','0x590540c90f42eec56ec33009ce954c91fd3079b0'];
  let stable_LP_Array: any = [ '0xb32ae069db02f614e303aceaf5ad963d7de12194', '0x208adae61fd7587314c2f7f1566bd137e0dd0126' ];
  let type: any = 0;
  if(stable_LP_Array.includes(convertLPObj.poolData.strategyAddress.toLowerCase())){
    type = 1
  }
  if(thena_LP_Array.includes(convertLPObj.poolData.strategyAddress.toLowerCase())){
    type = 2
  }
  if(activeFusionPools.includes(convertLPObj.poolData.strategyAddress.toLowerCase())){
    type = 3
  }
  // console.log("slippage", slippageVal, convertLPObj.token0Address, convertedToken, convertLPObj.quote0.fromTokenAmount, slippageVal, userAddress)
  // console.log("slippage", slippageVal, convertLPObj.token1Address, convertedToken, convertLPObj.quote1.fromTokenAmount, slippageVal, userAddress)
  let swapToken0: any = {};
  let swapToken1: any = {};
  try {
    if(convertLPObj.token0Address.toLowerCase() == convertedToken.toLowerCase()){
      swapToken0 = {data: "0x"} 
      let fromTokenAmount = BigInt(Math.floor(convertLPObj.quote1.fromTokenAmount * 0.999));
      swapToken1 = await buildTxForSwapInMigrate(convertLPObj.token1Address, convertedToken, fromTokenAmount, slippageVal, userAddress, planetZapOneInchAddress)
    } else if(convertLPObj.token1Address.toLowerCase() == convertedToken.toLowerCase()){
      let fromTokenAmount = BigInt(Math.floor(convertLPObj.quote0.fromTokenAmount * 0.999));
      swapToken0 = await buildTxForSwapInMigrate(convertLPObj.token0Address, convertedToken, fromTokenAmount, slippageVal, userAddress, planetZapOneInchAddress)
      swapToken1 = {data: "0x"}
    } else {
      // console.log("convertLPObj", convertLPObj)
      let fromTokenAmount0 = BigInt(Math.floor(convertLPObj.quote0.fromTokenAmount * 0.999))
      let fromTokenAmount1 = BigInt(Math.floor(convertLPObj.quote1.fromTokenAmount * 0.999))
      swapToken0 = await buildTxForSwapInMigrate(convertLPObj.token0Address, convertedToken, fromTokenAmount0, slippageVal, userAddress, planetZapOneInchAddress)
      let delayres = await delay(2000);
      swapToken1 = await buildTxForSwapInMigrate(convertLPObj.token1Address, convertedToken, fromTokenAmount1, slippageVal, userAddress, planetZapOneInchAddress)
    }
    
    // console.log("zap out params", wantAddress, convertToWei(wantAmountInEth), convertedToken, swapToken0.data, swapToken1.data, type)
    let amountInWei = convertToWei(wantAmountInEth)
    // console.log("removeLiquidity", wantAddress, convertLPObj.token0Address, convertLPObj.token1Address, amountInWei)
    let removeLiquidity:any = await planetZapInstance.methods.quoteRemoveLiquidity(wantAddress, convertLPObj.token0Address, convertLPObj.token1Address, amountInWei).call();
    return {wantAddress, amountInWei, convertedToken, data0: swapToken0.data, data1: swapToken1.data, type, removeLiquidity};
    
  } catch(err) {
    console.log("error",err)
    setShowTransModal(false)
    setConvertLoading(false)
    // console.log("in catch")
    return {};
  }
  
}

export const convertLpV3 = async (userAddress: string, wantAddress: string, wantAmountInEth: string, routerAddress: string, convertedToken: string, path1: any, path2: any, receiveTokenAddress: string, slippageVal: any, showTransLink: any, setShowTransModal: any, handleNotification: any, setConvertLoading: any, convertLPObj: any, setConfirmTransHistory: any, updateTokenList: any, lPObject: any) => {
  const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
  let thena_LP_Array: any = ['0x5cdfb4cd95f733fd679343bbc39d12dff907251b', '0xcf32907c7ee99dccbd8feec2c26fad077f799890', '0x7b5a46d584477eb00fc88a3265724e50e7cbcb32', '0x06f5f260be2c541f134b5548e5e62952d7315363', '0x10a4a5e00769d0101bf6ba8ff10b4d68f4ba070b','0x590540c90f42eec56ec33009ce954c91fd3079b0'];
  let stable_LP_Array: any = [ '0xb32ae069db02f614e303aceaf5ad963d7de12194', '0x208adae61fd7587314c2f7f1566bd137e0dd0126' ];
  let type: any = 0;
  if(stable_LP_Array.includes(lPObject.strategyAddress.toLowerCase())){
    type = 1
  }
  if(thena_LP_Array.includes(lPObject.strategyAddress.toLowerCase())){
    type = 2
  }
  if(activeFusionPools.includes(lPObject.strategyAddress.toLowerCase())){
    type = 3
  }

  if(activeV3FusionPools.includes(lPObject.strategyAddress.toLowerCase())){
    type = 3
  }
  // console.log("slippage", slippageVal, convertLPObj.token0Address, convertedToken, convertLPObj.quote0.fromTokenAmount, slippageVal, userAddress)
  // console.log("slippage", slippageVal, convertLPObj.token1Address, convertedToken, convertLPObj.quote1.fromTokenAmount, slippageVal, userAddress)
  BigNumber.set({
    EXPONENTIAL_AT: [-10, 50],
  })
  let swapToken0: any = {};
  let swapToken1: any = {};
  try {
    if(convertLPObj.token0Address.toLowerCase() == convertedToken.toLowerCase()){
      swapToken0 = {data: "0x"} 
      let fromTokenAmount = BigNumber(convertLPObj.quote1.fromTokenAmount).multipliedBy(0.999).toFixed(0).toString();//BigInt(Math.floor(convertLPObj.quote1.fromTokenAmount * 0.999));
      swapToken1 = await buildTxForSwapInMigrate(convertLPObj.token1Address, convertedToken, fromTokenAmount, slippageVal, userAddress, aquaConvertLpAddress)
    } else if(convertLPObj.token1Address.toLowerCase() == convertedToken.toLowerCase()){
      let fromTokenAmount = BigNumber(convertLPObj.quote0.fromTokenAmount).multipliedBy(0.999).toFixed(0).toString();//BigInt(Math.floor(convertLPObj.quote0.fromTokenAmount * 0.999));
      swapToken0 = await buildTxForSwapInMigrate(convertLPObj.token0Address, convertedToken, fromTokenAmount, slippageVal, userAddress, aquaConvertLpAddress)
      swapToken1 = {data: "0x"}
    } else {
      // console.log("convertLPObj", convertLPObj)
      let fromTokenAmount0 = BigNumber(convertLPObj.quote0.fromTokenAmount).multipliedBy(0.999).toFixed(0).toString();//BigInt(Math.floor(convertLPObj.quote0.fromTokenAmount * 0.999))
      let fromTokenAmount1 = BigNumber(convertLPObj.quote1.fromTokenAmount).multipliedBy(0.999).toFixed(0).toString();//BigInt(Math.floor(convertLPObj.quote1.fromTokenAmount * 0.999))
      swapToken0 = await buildTxForSwapInMigrate(convertLPObj.token0Address, convertedToken, fromTokenAmount0, slippageVal, userAddress, aquaConvertLpAddress)
      let delayres = await delay(2000);
      swapToken1 = await buildTxForSwapInMigrate(convertLPObj.token1Address, convertedToken, fromTokenAmount1, slippageVal, userAddress, aquaConvertLpAddress)
    }
    
    if(lPObject.token0 == undefined){
      lPObject.token0 = await token1(lPObject.wantAddress);
    }
    if(lPObject.token1 == undefined){
      lPObject.token1 = await token2(lPObject.wantAddress);
    }
  
    // console.log("zap out params", wantAddress, convertToWei(wantAmountInEth), convertedToken, swapToken0.data, swapToken1.data, type)
    let fusion_pool_want_array: any = ['0xd3c480ec7a47596ff8d63396227d1f7dc728a7f0', '0x10bf6e7b28b1cffb1c047d7f815953931e5ee947', '0x3ec1ffd5dc29190588608ae9fd4f93750e84cda2', '0x754fd74e22255780a58f125300008781d8318e3a', '0x2ecbd508c00bbc8aa0cdc9100bf3956fcabe7677', '0x88e1ce1e3e0171c83150844bb2f0bd2266802da2', '0xdf0b9b59e92a2554dedb6f6f4af6918d79dd54c4'];
    let amountInWei = convertToWei(wantAmountInEth)
    // console.log(lPObject.wantAddress, lPObject.token0, lPObject.token1, amountInWei, lPObject)
    let removeLiquidity:any = activeV3FusionPools.includes(lPObject.strategyAddress.toLowerCase()) ? await planetZapInstance.methods.quoteRemoveLiquidityGammaUniproxy(lPObject.wantAddress, lPObject.token0, amountInWei).call() : fusion_pool_want_array.includes(lPObject.wantAddress.toLowerCase()) ? await planetZapInstance.methods.quoteRemoveLiquidityGammaUniproxy(lPObject.wantAddress, lPObject.token0, amountInWei).call() : await planetZapInstance.methods.quoteRemoveLiquidity(lPObject.wantAddress, lPObject.token0, lPObject.token1, amountInWei).call();
    // console.log("remove liquidity in swap", removeLiquidity)
    return {wantAddress, amountInWei, convertedToken, data0: swapToken0.data, data1: swapToken1.data, type, removeLiquidity};
    
  } catch(err) {
    console.log("error",err)
    setShowTransModal(false)
    setConvertLoading(false)
    // console.log("in catch")
    return {};
  }
  
}

export const convertLp = async (userAddress: string, wantAddress: string, wantAmountInEth: string, routerAddress: string, convertedToken: string, path1: any, path2: any) => {

  try {
    const CONVERT_LP_INSTANCE = new wallet.web3.eth.Contract(oldZapAbi, oldZapAddress)//await selectInstance(instanceType.CONVERTLP);
    return CONVERT_LP_INSTANCE.methods
      .convertLiquidityToken(wantAddress, convertToWeiInternal(wantAmountInEth,Math.pow(10,await decimals(wantAddress))), routerAddress, convertedToken, path1, path2)
      .send({ from: userAddress, })
      .once('transactionHash', function (hash: any) {
        if (!!hash) {
          // showTransLink(hash)
        }
      })
  } catch (error) {
    return error
  }

}


export const calculateUserLPToken = async(poolAddress: string, userAddress: string) => {

  try{
    var stablePool = new wallet.web3.eth.Contract(stableswap_main_abi_nG, poolAddress);
    var lptokenAddress = await stablePool.methods.token().call();
    var lptokenContract = new wallet.web3.eth.Contract(ERC20_abi,lptokenAddress)
    let LPTokenBalance =  await lptokenContract.methods.balanceOf(userAddress).call()
    let decimals = (await lptokenContract.methods.decimals().call())
    LPTokenBalance = convertToEther(LPTokenBalance, decimals)
     return LPTokenBalance;
  }
  catch(err){
    console.log(err)
  }
}

export const getAllStableSwapPools = async (userAddress?: any) => {

  // console.log("inside swap.ts: ", userAddress);
  var poolData = []
  var poolName;
  var stablePool;
  //let stableswap_main_address: any = [];
  try{
    // if(userAddress){

      let gTokenList = []
    
      for(var poolIndex = 0; poolIndex < stableSwapPoolInfoList.length; poolIndex++){

        for(var index in stableSwapPoolInfoList[poolIndex].assetsInfo){
          gTokenList.push( stableSwapPoolInfoList[poolIndex].assetsInfo[index].gTokenAddress);
        }
        let allowances = userAddress ? await getAllowanceForStablePoolsMulticall(userAddress, stableSwapPoolInfoList[poolIndex].assetsInfo, stableswap_main_address[0]["poolAddress"]) : [];
    
        stablePool = new wallet.web3.eth.Contract(stableswap_main_abi_nG, stableSwapPoolInfoList[poolIndex]["poolAddress"]);
  
        poolName = stableSwapPoolInfoList[poolIndex]["poolName"];
        const n_coins =  stableSwapPoolInfoList[poolIndex].assetsInfo.length;
  
        const assetNames = []
        const assetAddress = []
        var assetDetails = []
        var userLPTokens: any = "0";
        if(userAddress){
          userLPTokens = await calculateUserLPToken(stableSwapPoolInfoList[poolIndex]["poolAddress"], userAddress);
        }
        for(var i=0; i<n_coins; i++){
          var coinAddress =  stableSwapPoolInfoList[poolIndex].assetsInfo[i].tokenAddress
          let coin = new wallet.web3.eth.Contract(ERC20_abi, coinAddress);
        
          var assetName = stableSwapPoolInfoList[poolIndex].assetsInfo[i].name;
          
          let assetTVL = await stablePool.methods.balances(i).call()
         
          assetNames.push(assetName.toLowerCase())
          assetAddress.push(coinAddress)
          // console.log("allowance array data", allowances[coinAddress.toLowerCase()])
          // console.log("max int data", (MAX_INT))
          const oracleInstance: any = await selectInstance(instType.priceOracle, priceOracleAddress)
          if(allowances[coinAddress.toLowerCase()] != undefined){
            
            let is_approved = +allowances[coinAddress.toLowerCase()] > 0 ? true : false;
            assetDetails.push({
                'name': assetName, 
                'tvl': await coin.methods.balanceOf(stableSwapPoolInfoList[poolIndex]["poolAddress"]).call()*(await oracleInstance.methods.getUnderlyingPrice(gTokenList[i]).call())/10**(18+18), 
                "is_approved": is_approved, 
                'allowance': convertToEther(+allowances[coinAddress.toLowerCase()], 18), 
                'token': coinAddress, 
                'address': "0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8",
                "assetTVL": (assetTVL/1e18).toString()
              })
          } else {
            assetDetails.push({
              'name': assetName, 
              'tvl': await coin.methods.balanceOf(stableSwapPoolInfoList[poolIndex]["poolAddress"]).call()*(await oracleInstance.methods.getUnderlyingPrice(gTokenList[i]).call())/10**(18+18), 
              "is_approved": false, 
              'allowance': "0", 
              'token': coinAddress, 
              'address': "0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8",
              "assetTVL": (assetTVL/1e18).toString()
            })
          }
          
        }
        poolData.push({
          'poolName': poolName, 
          'poolAddress': stableswap_main_address[poolIndex]["poolAddress"], 
          'assets': assetNames, // remove
          'assetAddress': assetAddress, 
          'tvl': await calculateStablePoolTVL(stableSwapPoolInfoList[poolIndex]["poolAddress"], assetAddress, gTokenList),
          'precisionList': ["10e18","10e18", "10e18"], 
          'poolLPToken': stableSwapPoolInfoList[poolIndex]["lpTokenName"],
          'lpTokenAddress': stableSwapPoolInfoList[poolIndex]["lpTokenAddress"],
          'assetPrice': await getAssetPrice(stablePool),//stablePool.methods.get_virtual_price().call()/10**18, //(await lptokenContract.methods.decimals().call()),
          'assetDetails': assetDetails,
          'userLPTokens': userLPTokens,
          'oneDayTradeVolume': "3104827.52"
      }) 
      
  
      }
    
    return poolData;
  }

  catch(err){
    console.log(err)
  }

}

export const getAssetPrice = async(stable_pool_inst: any) => {
  try{
    let assetPrice = await stable_pool_inst.methods.get_virtual_price().call();
    return assetPrice/10**18;
  }catch(error){
    console.log("error in asset price", error);
    return 0;
  }
}

export const calculateTokenPrecisions = async(tokenList: string[]) => {
  var precisionList = []
  try{
    for(var token of tokenList){
      const tokenContract = new wallet.web3.eth.Contract(ERC20_abi,token);
      precisionList.push(await tokenContract.methods.decimals().call())
    }
    return precisionList
  }
  catch(err){
    console.log(err)
  }

}

export const estimateTokensOnDeposit = async(poolAddress: string, amount: any[]) => {
  try {
    const stable_pool_inst = new wallet.web3.eth.Contract(stableswap_main_abi_nG, poolAddress)
    let token_amount = await stable_pool_inst.methods.calc_token_amount(amount, true).call();
    token_amount = convertToEther(token_amount, 18);
    return token_amount;
  }catch(error){
    console.log(error);
    return 0;
  }
}

export const calculateStablePoolTVL = async(poolAddress: string, assetsAddress: string[], gTokenAddresses: string[]) => {

  var tvl = 0
  let callDatas = []
  let priceCallDatas = []
  let outputFormat = []
  let priceOutputFormat = []
  let targets = []
  let priceTargets = []
  let results:any  = []

  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  let decimals = 18

  try{
    for(var assetIndex in assetsAddress){
      
      const token = new wallet.web3.eth.Contract(ERC20_abi, assetsAddress[assetIndex]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(token.methods.balanceOf(poolAddress)._method, [poolAddress])
      callDatas.push(data)
      outputFormat.push(token.methods.balanceOf(poolAddress)._method.outputs)
      targets.push(assetsAddress[assetIndex])

      const oracleInstance: any = await selectInstance(instType.priceOracle, priceOracleAddress)
      priceTargets.push(priceOracleAddress)
      const priceData = wallet.web3.eth.abi.encodeFunctionCall(oracleInstance.methods.getUnderlyingPrice(gTokenAddresses[assetIndex])._method, [gTokenAddresses[assetIndex]])
      priceCallDatas.push(priceData)
      priceOutputFormat.push(oracleInstance.methods.getUnderlyingPrice(gTokenAddresses[assetIndex])._method.outputs)
    }

    const aggregatedPriceData = await multicall_inst.methods.aggregate(priceTargets, priceCallDatas).call()
  
    for (let i = 0; i < aggregatedPriceData[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(priceOutputFormat[i], aggregatedPriceData[1][i])[0])
    }

    const aggregatedData = await multicall_inst.methods.aggregate(targets, callDatas).call()

    for(let i = 0 ; i < aggregatedData[1].length ; i++){
      tvl = ((wallet.web3.eth.abi.decodeParameters(outputFormat[i], aggregatedData[1][i])[0]*results[i]))/10**(decimals*2) + tvl
    }    
      return tvl;
  }
  catch(err){
    console.log(err)
  }

}

export const addLiquidityToPool = async(poolAddress: string, liquidityList: any[], precisionList: number[], slippageFactor: any, userAddress: string) => {

  var preciseLiquidityList: any[]
  preciseLiquidityList = []
  try{
    for(var index in liquidityList){
      let revisedValue = convertToWei(liquidityList[index])
      preciseLiquidityList.push(BigInt(revisedValue))
    }
    var totalStables = liquidityList.reduce(function(a, b){ return +a + +b; }); 
    return {totalStables, preciseLiquidityList, stableswap_main_abi_nG, poolAddress}
  }

  catch(err){
    console.log(err)
  }

}

export const removeLiquidityFromPool = async(poolAddress: string, lpTokenCount: any, minimumStableCoin: number[], precisionList: any, userAddress: string) => {

  try{
    var preciseLiquidityList: any[]
    preciseLiquidityList = []
    for(var index in minimumStableCoin){
      let revisedValue = convertToWei(minimumStableCoin[index])
      preciseLiquidityList.push(BigInt(revisedValue))
    }
    const stable_pool_inst = new wallet.web3.eth.Contract(stableswap_main_abi_nG, poolAddress);
    const lpTokenAddress = await stable_pool_inst.methods.token().call();
    const lptokenContract = new wallet.web3.eth.Contract(ERC20_abi, lpTokenAddress);
    const lpTokenDecimals = await lptokenContract.methods.decimals().call();

    return {stable_pool_inst, lpTokenDecimals, preciseLiquidityList}

}
  catch(err){
    console.log("errorr: ", err)
  }

}

export const removeLiquidityFromPoolOneCoin = async(poolAddress: string, tokenCount: number, minimumStableCoin: number, coinIndex: any, precisionOfStable: number, userAddress: string) => {
  try{

    const stable_pool_inst = new wallet.web3.eth.Contract(stableswap_main_abi_nG, poolAddress);
    const lpTokenAddress = await stable_pool_inst.methods.token().call();
    const lptokenContract = new wallet.web3.eth.Contract(ERC20_abi, lpTokenAddress);
    const lpTokenDecimals = await lptokenContract.methods.decimals().call();

    return {stable_pool_inst, lpTokenDecimals}
    }

    catch(err){
      console.log(err)
    }
}

export const swapStableToken = async(poolAddress: string, swapFromIndex: number, swapToIndex: number, swapAmount: number, minSwapOutAmount: number, tokenPrecision: number, userAddress: string) => {

  try{
    var stablePool = new wallet.web3.eth.Contract(stableswap_main_abi_nG, poolAddress);
    var swapFromTokenContractAddress = await stablePool.methods.coins(swapFromIndex).call();
    var swapFromTokenContract = new wallet.web3.eth.Contract(ERC20_abi, swapFromTokenContractAddress);
    let allowance = await swapFromTokenContract.methods.allowance(userAddress, poolAddress).call();
    if(allowance < BigInt(swapAmount*10**tokenPrecision)){
      await swapFromTokenContract.methods.approve(poolAddress, MAX_INT).send({from: userAddress})
    }

    var swapOutAmountreceived = await stablePool.methods.exchange(swapFromIndex, swapToIndex, BigInt(swapAmount*10**tokenPrecision), BigInt(minSwapOutAmount*10**tokenPrecision), userAddress).send({from: userAddress});
  }
  catch(err){
    console.log(err)
  }

}

export const getMinimumSwapOutToken = async(poolAddress: string, swapFromIndex: any, swapToIndex: any, swapAmount: any, precisionList: number[]) => {
  try{
    // console.log(swapFromIndex, swapToIndex, swapAmount)
    var stablePool = new wallet.web3.eth.Contract(stableswap_main_abi_nG, poolAddress);
    return await stablePool.methods.get_dy(swapFromIndex, swapToIndex, swapAmount).call();
  }
  catch(err){
    console.log("error"+err)
    return 0
  }
}

export const withdrawTokenFromStableLP = async(poolAddress: string, withdrawFromIndex: any, withdrawAmount: any) => {
  try{
    var stablePool = new wallet.web3.eth.Contract(stableswap_main_abi_nG, poolAddress);
    return await stablePool.methods.calc_withdraw_one_coin(withdrawAmount, withdrawFromIndex).call();
  }
  catch(err){
    console.log(err)
    return 0
  }
}
