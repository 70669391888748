import { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { updateUserAsset, updateUserAssetNew } from 'logic/action/lending.action'
import { ModalCont, ActivateHeader, FlexSBCont, FlexCont, ActivateAssetCont, InfoText, DataInfoCont, ValueText, ActionBtn, InfoSmallText, SliderContainer, ValueTextWhite } from './style'
import CrossIcon from 'assets/icons/white-cross.svg'
import GammaIcon from 'assets/icons/gamma-icon.png'
import AQUABurnedIcon from 'assets/icons/burned-icon.svg'
import TotalApyIcon from 'assets/icons/total-apy.png'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import TransactionInfo from 'shared/transaction-info'
import { transLinkUrl } from 'service/global-constant'
import { checkNegative, getNumSign, convertNumFormat, commaFy } from 'service/globalFunctions'
import { instType, selectInstance, MAX_INT } from 'modules/block-chain-green/lendingAbi'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import AnchorIcon from 'assets/icons/anchor_logo.png'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import AssetSupplyRevised from './AssetSupplyRevised'
import { gBNBxAddress } from 'modules/block-chain/lendingAbi';
import BNBxIcon from 'assets/icons/BNBx.png'


const ActivateAsset = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [activate, setActivateLoading] = useState(false)
    const [maxBorrow, setMaxBorrow] = useState<any>(0)
    const [borrowLimit, setBorrowLimit] = useState<any>(0)
    const [transactionLink, setTransactionLink] = useState('')
    const [showTransModal, setShowTransModal] = useState(false)
    const [showVaultDetails, setShowVaultDetails] = useState(false)
    const [modalData, setModalData] = useState<any>({})
    const { setShowEnableAsset, setShowAssetSupply, data, setIsAllowance } = props

    useEffect(() => {
        if (!!data && Object.values(data).length > 0) {
            console.log("data inside activate asset", data)
            setBorrowLimit(data.borrowLimitUsedCurr)
            setMaxBorrow(data.borrowLimitInUsd)
        }
        setModalData(data)
    }, [data])

    const handleEnableNotification = (type: string, hash?: any,) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} activated` : `Failed to activate  ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const toggleVaultDetailsModal = () => {
        setModalData({})
        setShowVaultDetails(false)
    }

    const handleEnable = async () => {
        try {
            setActivateLoading(true)
            const underlyingAddress = data.underlyingAddress
            const gTokenAddress = data.address
            const userAddress = selector.ethData.address
            const TOKEN_INSTANCE = await selectInstance(instType.PANCAKELP, underlyingAddress)
            await TOKEN_INSTANCE.methods
                .approve(gTokenAddress, MAX_INT)
                .send({ from: userAddress })
                .once('transactionHash', function (res: any) {
                    if (res && typeof res !== 'undefined') {
                        const transLink = `${transLinkUrl}/${res}`
                        setTransactionLink(transLink)
                        setShowTransModal(true)
                    }
                })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    handleEnableNotification(type, receipt.transactionHash)
                    setActivateLoading(false)
                    setShowEnableAsset(false)
                    setIsAllowance(true)
                    setShowAssetSupply(true)
                    dispatch(updateUserAssetNew(selector.ethData.address))
                })
                .on('error', function (error: any) {
                    handleEnableNotification('failed')
                    setShowTransModal(false)
                    setActivateLoading(false)
                    setShowEnableAsset(false)
                    
                })
        }
        catch (error) {
            console.log("error==>", error)
            setActivateLoading(false)
        }
        finally {
            setShowTransModal(false)
            setActivateLoading(false)
        }
    }
    return (
        <Fragment>
            <ModalCont>
                <ActivateHeader>
                    <img className="close-icon" src={CrossIcon} alt="" onClick={() => setShowEnableAsset(false)} />
                    <img className="token-icon" src={data.icon} alt="" />
                    <h1>Activate {data.name}</h1>
                    <p>To start lending</p>
                </ActivateHeader>
                <ActivateAssetCont>
                    <DataInfoCont>
                    {/*!!data && (data.anchorApy !== 0) &&
                        <FlexSBCont className="info-part" >
                            <FlexCont>
                                <img className="apy-img" src={AnchorIcon} alt="" />
                                <InfoText>Anchor APY</InfoText>
                            </FlexCont>
                            <ValueTextWhite isNegative={checkNegative(data.anchorApy)}>{`${checkNegative(data.anchorApy) ? '' : getNumSign(data.anchorApy)}${convertNumFormat(data.anchorApy)}%`}</ValueTextWhite>
                        </FlexSBCont>
                    */}
                        {!!data && (data.name !== 'AQUA' && data.name !== 'GAMMA') &&
                            <FlexSBCont className="info-part">
                                <FlexCont>
                                    <img className="apy-img" src={data.icon} alt="" />
                                    <InfoText>Supply APR</InfoText>
                                </FlexCont>
                                <ValueText isNegative={checkNegative(data.supplyApr)}>{`${checkNegative(data.supplyApr) ? '' : getNumSign(data.supplyApr)}${convertNumFormat(data.supplyApr)}%`}</ValueText>
                            </FlexSBCont>
                        }
                        <FlexSBCont className="info-part" >
                            <FlexCont>
                                <img className="apy-img" src={GammaIcon} alt="" />
                                <InfoText>GAMMA APR</InfoText>
                            </FlexCont>
                            <ValueText isNegative={checkNegative(data.supplyDistributionApr)}>{data.supplyDistributionApr && (!isFinite(data.supplyDistributionApr) || data.supplyDistributionApr > 10000) ? "> 10,000" : data.supplyDistributionApr ? commaFy(data.supplyDistributionApr.toFixed(2)) : "0.00"}%</ValueText>
                        </FlexSBCont>
                        <FlexSBCont className="info-part" >
                            <FlexCont>
                                <img className="apy-img" src={GammaIcon} alt="" />
                                <InfoText>Boost APR</InfoText>
                            </FlexCont>
                            <ValueText isNegative={checkNegative(data.boostApr)}>{data.boostApr && (!isFinite(data.boostApr) || data.boostApr > 10000) ? "> 10,000" : data.boostApr ? commaFy(data.boostApr.toFixed(2)) : "0.00"}%</ValueText>
                        </FlexSBCont>
                        {data.address.toLowerCase() == gBNBxAddress.toLowerCase() &&
                        <FlexSBCont className="info-part" >
                            <FlexCont>
                                <img className="apy-img" src={BNBxIcon} alt="" />
                                <InfoText>Stake APR</InfoText>
                            </FlexCont>
                            <ValueText isNegative={checkNegative(data.stakeApr)}>{data.stakeApr && (!isFinite(data.stakeApr) || data.stakeApr > 10000) ? "> 10,000" : data.stakeApr ? commaFy(data.stakeApr.toFixed(2)) : "0.00"}%</ValueText>
                        </FlexSBCont>}
                        {/* <FlexSBCont className="info-part" >
                            <FlexCont>
                                <img className="apy-img" src={GammaIcon} alt="" />
                                <InfoText>Boost APY</InfoText>
                            </FlexCont>
                            <ValueText isNegative={checkNegative(data.avgBoostApy)}>{data.avgBoostApy && (!isFinite(data.avgBoostApy) || data.avgBoostApy > 10000) ? "> 10,000" : data.avgBoostApy ? commaFy(data.avgBoostApy.toFixed(2)) : "0.00"}%</ValueText>
                        </FlexSBCont> */}
                        {!!data && (data.name === 'AQUA' || data.name === 'AQUA (OLD)') &&
                            <FlexSBCont className="info-part" >
                                <FlexCont>
                                    <img className="apy-img" src={AQUABurnedIcon} alt="" />
                                    <InfoText>Burn APR</InfoText>
                                </FlexCont>
                                <ValueText isNegative={checkNegative(data.burnApr)}>{`${checkNegative(data.burnApr) ? '' : getNumSign(data.burnApr)}${convertNumFormat(data.burnApr)}%`}</ValueText>
                            </FlexSBCont>
                        }
                        <FlexSBCont className="info-part">
                            <FlexCont>
                                <img className="apy-img" src={TotalApyIcon} alt="" />
                                <InfoText>Total APY</InfoText>
                            </FlexCont>
                            <ValueText isNegative={checkNegative(data.totalSupplyApy)}>{data.totalSupplyApy && (!isFinite(data.totalSupplyApy) || data.totalSupplyApy > 10000) ? "> 10,000" : data.totalSupplyApy && isFinite(data.totalSupplyApy) && data.totalSupplyApy < 10000 ? commaFy(data.totalSupplyApy.toFixed(2)): "0.00"}%</ValueText>
                        </FlexSBCont>
                        {/* <FlexSBCont>
                            <InfoText>Borrow Limit Used</InfoText>
                            <InfoText>{parseFloat(borrowLimit).toFixed(2)}%</InfoText>
                        </FlexSBCont>
                        <SliderContainer>
                            <Slider min={0} max={100} value={borrowLimit} tooltip={false} />
                        </SliderContainer>
                        <FlexSBCont className="max-borrow">
                            <InfoSmallText>Max Borrow</InfoSmallText>
                            <InfoSmallText>${parseFloat(maxBorrow).toFixed(2)}</InfoSmallText>
                        </FlexSBCont>
                        <FlexSBCont className="info-part">
                            <InfoSmallText>Wallet Balance</InfoSmallText>
                            <InfoSmallText>{`${parseFloat(data.walletBal).toFixed(4)} ${data.name}`}</InfoSmallText>
                        </FlexSBCont> */}
                        <ActionBtn onClick={() => handleEnable()} disabled={activate || !selector.ethData.address}>
                            {activate ? <Spinner /> : "Activate"}
                        </ActionBtn>
                    </DataInfoCont>
                </ActivateAssetCont>
            </ModalCont>
            <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
                {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
            </CustomModal>
            {<CustomActionModal show={showVaultDetails} toggleModal={toggleVaultDetailsModal}>
                {showVaultDetails && (
                    <AssetSupplyRevised
                        data={modalData}
                        setShowAssetSupply={setShowAssetSupply}
                        toggleVaultModal={toggleVaultDetailsModal} setModalData={setModalData} currentTab={"all"}
                    />
                )}
            </CustomActionModal>}
        </Fragment>
    );
};

export default ActivateAsset;