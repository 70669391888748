import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import { MainContainer, FlexCont, CardButton, CardButton2, FlexSBCont } from 'shared/styles/styled'
import { TitleText, TitleInfo, CardText, DataTitle } from 'shared/styles/globalText'
import { DataCont, TableSearchCont, TableProtoCalCont, LoadingText4, TableCont } from 'shared/styles/planetTableStyle'
import { TextBox, InfoText, InfoTitle, ClaimBtn } from './style';
import {getCompensationDataForUser, convertToEther, gConvertToEther} from './../../block-chain-green/BlockChainMethods';
import {compensation_address_poor, compensation_address_rich, COMPENSATION_ABI} from './../../block-chain-green/abi';
import wallet from 'modules/wallet/wallet'
import { transLinkUrl } from 'service/global-constant'
import { LoadingText3 } from 'shared/styles/highestApyStyle'
import { updateUserLendingData, silentUpdateUserLendingData } from 'logic/action/lending.action'
import { updateUserBalance } from 'logic/action/user.actions'
import Spinner from 'shared/spinner'
import { poor_users_comp_list } from './poor_users';
import { rich_users_comp_list } from './rich_users';

const Overview = () => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const { ethWalletConnected, address } = selector.ethData;
    const [claimLoading, setClaimLoading] = useState(false)
    const [userCompData, setUserCompData] = useState<any>({})
    const [transactionLink, setTransactionLink] = useState('')
    const [loading, setLoading] = useState(true);
    const [userType, setUserType] = useState("rich")
    const [showTransModal, setShowTransModal] = useState(false)
    // const {  } = selector.user

    useEffect(()=> {
        // console.log("user address", selector)
        async function fetchCompensationFunction() {
            // selector.ethData.address = "0xd253fb22DEc385c3e5A5845cAc93C8e4582c26E5";
            let userCompensationData: any = {
                remainingAmount: 0,
                unclaimedAmount: 0,
                userTotalCompensationAmount: 0
            };
            let userAddress_in_lowercase = selector.ethData.address.toLowerCase();
            if(poor_users_comp_list.includes(userAddress_in_lowercase)){
                userCompensationData = await getCompensationDataForUser(selector.ethData.address, "poor")
                setUserType("poor")
            }
            
            if(rich_users_comp_list.includes(userAddress_in_lowercase)){
                userCompensationData = await getCompensationDataForUser(selector.ethData.address, "rich")
                setUserType("rich")
            }
            // console.log("user comp details", userCompensationData)
            if(userCompensationData !== undefined && userCompensationData.userTotalCompensationAmount !== undefined){
                userCompensationData.userTotalCompensationAmount = convertToEther(userCompensationData.userTotalCompensationAmount)
                userCompensationData.remainingAmount = convertToEther(userCompensationData.remainingAmount)
                userCompensationData.unclaimedAmount = convertToEther(userCompensationData.unclaimedAmount)
                userCompensationData.receivedSoFar = userCompensationData.userTotalCompensationAmount - userCompensationData.remainingAmount;
            }
            setUserCompData(userCompensationData)
            setLoading(false);
        }
        fetchCompensationFunction()
    },[selector.ethData.address]);

    const compensateUser = async () => {
        setClaimLoading(true)
        let compensation_address = userType == "rich" ? compensation_address_rich : compensation_address_poor;
        const compensationInstance: any = new wallet.web3.eth.Contract(COMPENSATION_ABI, compensation_address);
        try{
            await compensationInstance.methods.compensateMe()
            .send({
                from: selector.ethData.address
            }).once("transationHash", function (res: any){
                if(res && typeof res !== 'undefined') {
                    const transLink = `${transLinkUrl}/${res}`;
                    setTransactionLink(transLink)
                    handleTransactionLink(transLink, res)
                    // setShowTransModal(true)
                }
            })
            .once("confirmation", function (confNumber: any, receipt: any){
                const type = receipt.status ? 'success' : 'failed'
                handleNotification(type, 'Claim', receipt.transactionHash)
                setClaimLoading(false)
                dispatch(silentUpdateUserLendingData(selector.ethData.address))
            })
            .on("error", function (error: any){
                handleNotification("failed", 'Claim')
                setClaimLoading(false)
            })
        } catch(error){
            console.log("error", error);
            setClaimLoading(false)
        }
        finally {
            setLoading(true);
            UpdateCompensationData();
        }
    }

    const UpdateCompensationData = async () => {
        let userCompensationData: any = await getCompensationDataForUser(selector.ethData.address, userType)
        // console.log("user comp details", userCompensationData)
        if(userCompensationData !== undefined && userCompensationData.userTotalCompensationAmount !== undefined){
            userCompensationData.userTotalCompensationAmount = convertToEther(userCompensationData.userTotalCompensationAmount)
            userCompensationData.remainingAmount = convertToEther(userCompensationData.remainingAmount)
            userCompensationData.unclaimedAmount = convertToEther(userCompensationData.unclaimedAmount)
            userCompensationData.receivedSoFar = userCompensationData.userTotalCompensationAmount - userCompensationData.remainingAmount - userCompensationData.unclaimedAmount;
        }
        setUserCompData(userCompensationData)
        setLoading(false);
    }

    const handleTransactionLink = (newLink: any, hashVal: any) => {
        const data: any = {
          link: newLink,
          type: 'compensateMe',
          status: false,
          hashVal: hashVal,
          transStatus: 'pending',
        }
        // /setTransLink(data)
    }

    const handleNotification = (type: string, actionType: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const data: any = {
          type: type,
          message: type !== 'failed' ? `Compensated user successfully till current round!` : 'Failed to compensate user!',
          link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(data))
    }
    
    return (
        <FlexSBCont className="overview left">
            <TextBox>Overview</TextBox>
            <div className='overview_info'>
                <div className='overview_container'>
                    <InfoTitle>Personal Deficit</InfoTitle>
                    { loading || userCompData == undefined || userCompData.userTotalCompensationAmount == undefined ? <LoadingText3 className='compLoad' /> : <InfoText> {"$" + (+userCompData.userTotalCompensationAmount).toFixed(3)}</InfoText>}
                </div>
                <div className='overview_container'>
                    <InfoTitle>Received so far</InfoTitle>
                    { loading || userCompData == undefined || userCompData.receivedSoFar == undefined ? <LoadingText3 className='compLoad' /> : <InfoText> {"$" + (+userCompData.receivedSoFar).toFixed(3)}</InfoText>}
                </div>
                <div className='overview_container'>
                    <InfoTitle>Claimable</InfoTitle>
                    { loading || userCompData == undefined || userCompData.unclaimedAmount == undefined ? <LoadingText3 className='compLoad' /> : <InfoText> {"$" + (+userCompData.unclaimedAmount).toFixed(3)}</InfoText>}
                </div>
            </div>
            <ClaimBtn disabled={+userCompData.unclaimedAmount <= 0} onClick={() => compensateUser()}>{claimLoading ? <Spinner />:<TextBox className='claimBtnText'>${userCompData !== undefined && userCompData.unclaimedAmount !== undefined ? (+userCompData.unclaimedAmount).toFixed(3) : "0.000"}</TextBox>}</ClaimBtn>
        </FlexSBCont>
    );
};

export default Overview