import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MainContainer, FlexCont, FlexSBCont, ToolTipText, ToolTipIcon, } from 'shared/styles/styled'
import { LiquidationCont, Title, CardCont, Card, CardTitle, CardText, CardSmText, AccInfo, TransLink, ActionCard, ActionCardTxt, TokenValInput, MaxTxt, LiquidateBtn, TableWrapper, TableCont, TableRow, HealthText, TokenText, TokenSelectCont, LoadingText1 } from './style'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import LinkIcon from 'assets/icons/transactionlink.png'
import ArrowDown from 'assets/icons/arrow-down.png'
import QuestionIcon from 'assets/icons/question-icon.svg'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import ComponentLoader from 'shared/component-loader'
import TokenSelect from 'shared/token-select'
import ReactTooltip from "react-tooltip";
import { transAddLinkUrl, transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions'
import { getLiquidatedAccounts, getLiquidityDataOfUser, loadMoreLiquidatedAccountsRevised } from 'modules/block-chain-green/LendingBase'
import { liquidateBorrow } from 'modules/block-chain-green/gBNB'
import { commaFy } from 'service/globalFunctions'

const Liquidation = () => {
    const selector = useSelector((state: any) => state)
    const [dataLoading, setDataLoading] = useState(false)
    const [liquidationData, setLiquidationData] = useState<any>([])
    const [liquidateAmt, setLiquidateAmt] = useState('')
    const [selectedData, setSelectedData] = useState<any>({})
    const [repayToken, setRepayToken] = useState<any>({})
    const [liquidatorToken, setLiquidatorToken] = useState<any>({})
    const [seizeToken, setSeizeToken] = useState<any>({})
    const [showRepayModal, setShowRepayModal] = useState(false)
    const [showSeizeModal, setShowSeizeModal] = useState(false)
    const [showLiquidatorAssetsModal, setShowLiquidatorAssetsModal] = useState(false)
    //userAddress, pageno, unhealthyAccounts, pageSize, liquidatedArr
    const [pageNo, setPageNo] = useState(0)
    const [unhealthyAccounts, setUnhealthyAccounts] = useState<any>([])
    const [pageSize, setPageSize] = useState(50)
    const [loadMore, setLoadMore] = useState(false)
    const [repayList, setRepayList] = useState<any>([])
    const [seizeList, setSeizeList] = useState<any>([])
    const [suppliedAssetsOfLiquidator, setSuppliedAssetsOfLiquidator] = useState([])
    const [lDataLoading, setLDataLoading] = useState(false)
    const [deficit_in_PF, set_deficit_in_PF] = useState(0)
    const [deficit_users_array, set_deficit_users_array]= useState<any>([])
    const [liquidateLoading, setLiquidateLoading] = useState(false)
    const [assetDiff, setAssetDiff] = useState(0)
    const [total_borrowed, setTotalBorrowed] = useState(0)
    const [total_supplied, setTotalSupplied] = useState(0)
    const [closeFactor, setCloseFactor] = useState(0)
    const [borrowBalanceArray, setBorrowBalanceArray] = useState([])
    const [balanceOfGTokenArr, setBalanceOfGTokenArr] = useState([])
    const [tokenPriceArr, setTokenPriceArr] = useState([])
    const [liquidIncentiveData, setLiquidIncentiveData] = useState([])
    const [liquidatorBalance, setLiquidatorBalance] = useState([])
    const [repayTokenCheck, setRepayTokenCheck] = useState(false)
    const [transactionLink, setTransactionLink] = useState('')
    const [showTransModal, setShowTransModal] = useState(false)
    const [accountUnhealthy, setAccountUnhealthy] = useState(false)

    useEffect(() => {
        ReactTooltip.rebuild();
    })

    useEffect(() => {
        let isMounted = true;
        const userAddress = selector.ethData.address
        const getLiquidationData = async () => {
            try {
                setDataLoading(true)
                const res = await getLiquidatedAccounts(userAddress)
                if (!!res && typeof res !== 'undefined' && isMounted === true) {
                    // console.log("res.liquidatedAccounts", res.liquidatedAccounts)
                    let finalArr: any = [];
                    if(!!res.liquidatedAccounts && res.liquidatedAccounts.length > 0){
                        // finalArr = res.liquidatedAccounts.sort((a: any, b: any) => (parseFloat(b.res.total_supplied) > parseFloat(a.res.total_supplied)) ? 1 : (parseFloat(a.res.total_supplied) > parseFloat(b.res.total_supplied) ? -1 : 0))
                        finalArr = res.liquidatedAccounts.sort((a: any, b: any) => (parseFloat(b.res.health) < parseFloat(a.res.health)) ? 1 : (parseFloat(a.res.health) < parseFloat(b.res.health) ? -1 : 0))
                    } else {
                        // console.log("no accounts found !", res.loadMore);
                        // res.newPageNo = 0;
                        // res.pageSize = 0;
                        // res.unhealthyAccounts = [];
                        // res.loadMore = false;
                        // res.deficit_in_PF = 0;
                        // res.deficit_users_array = [];
                        // res.assetDiff = 0;
                        // res.suppliedAssetsOfLiquidator = 0;
                    }
                    setLiquidationData(finalArr)
                    setPageNo(res.newPageNo)
                    setPageSize(res.pageSize)
                    setUnhealthyAccounts(res.unhealthyAccounts)
                    setLoadMore(res.loadMore)
                    set_deficit_in_PF(res.deficit_in_PF)
                    set_deficit_users_array(res.deficit_users_array)
                    setAssetDiff(res.assetDiff)
                    setSuppliedAssetsOfLiquidator(res.suppliedAssetsOfLiquidator)
                }
            }
            catch (error) {
                setLiquidationData([])
            }
            finally {
                if(isMounted === true){
                    setDataLoading(false)
                }
            }
        }
        getLiquidationData()
        return () => {
            isMounted = false;
        };
    }, [selector.ethData.address])

    const loadMoreLiquidatedAccounts = async () => {
        // console.log("inside loadmore")
        const userAddress = selector.ethData.address;
        const getLiquidationData = async () => {
            try {
                // setDataLoading(true)
                setLoadMore(false)
                const res = await loadMoreLiquidatedAccountsRevised(userAddress, pageNo, unhealthyAccounts, pageSize, liquidationData, deficit_in_PF, deficit_users_array, assetDiff)
                if (!!res && typeof res !== 'undefined') {
                    let finalArr: any = [];
                    if(!!res.updatedAccountArr && res.updatedAccountArr.length > 0){
                        finalArr = res.updatedAccountArr.sort((a: any, b: any) => (parseFloat(b.res.total_supplied) > parseFloat(a.res.total_supplied)) ? 1 : (parseFloat(a.res.total_supplied) > parseFloat(b.res.total_supplied) ? -1 : 0))
                    }
                    setLiquidationData(finalArr)
                    setPageNo(res.newPageNo)
                    setPageSize(res.pageSize)
                    setUnhealthyAccounts(res.unhealthyAccounts)
                    setLoadMore(res.loadMore)
                    set_deficit_in_PF(res.deficit_in_PF)
                    set_deficit_users_array(res.deficit_users_array)
                    setAssetDiff(res.assetDiff)
                }
            }
            catch (error) {
                setLiquidationData([])
            }
            finally {
                setDataLoading(false)
            }
        }
        getLiquidationData()
    }   

    const handleLiquidateAmount = async (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            console.log("value in handleLiquidateAmount", !value, liquidateAmt, liquidateLoading,!(Object.values(selectedData).length > 0), !repayTokenCheck )
            //!liquidateAmt || liquidateLoading || !(Object.values(selectedData).length > 0) || !repayTokenCheck
            setLiquidateAmt(value)
        }
        if (!value) {
            setLiquidateAmt('')
        }
    }

    const handleMax = () => {
        // console.log("handle max",liquidatorToken, seizeToken, selectedData, repayToken )
        // selectedData.total_supplied
        let tokenVal = +selectedData.total_supplied;
        console.log(selectedData.total_supplied, liquidatorToken.value, liquidatorToken.usdValue)
        let availableTokens = +seizeToken.availableTokens;
        let availableTokensUSD = +seizeToken.availableTokensUsd;
        let selectedRowMaxRepayValue = selectedData.maxRepayAmount
        if(selectedData.seizeAssetSymbol !== seizeToken.name || selectedData.repayAssetSymbol !== repayToken.name){
            selectedRowMaxRepayValue = selectedData.liquidIncentiveData * repayToken.usdValue /seizeToken.price;
            // console.log("selectedRowMaxRepayValue", selectedRowMaxRepayValue, selectedData.liquidIncentiveData, repayToken.usdValue , seizeToken.price, selectedData.seizeAssetSymbol, seizeToken.name)
        }
        // console.log("tokenVal", tokenVal, selectedData.seizeAssetSymbol, seizeToken.name)
        let liquidatorTokenValue = liquidatorToken.value;
        if(liquidatorToken.usdValue > seizeToken.usdValue ){
            liquidatorTokenValue = seizeToken.usdValue * 0.995 / (liquidatorToken.price * selectedData.liquidIncentiveData);
            // console.log("liquidatorTokenValue", liquidatorTokenValue, seizeToken.usdValue, liquidatorToken.price)
        }
        let minRecoverableToken = Math.min(liquidatorTokenValue, +availableTokens, selectedRowMaxRepayValue)
        minRecoverableToken = +minRecoverableToken.toFixed(6);
        // console.log("minRecoverableToken",minRecoverableToken, liquidatorTokenValue, +selectedData.liquidIncentiveData, +availableTokens, selectedRowMaxRepayValue)
        if(seizeToken.value * selectedData.closeFactor >= minRecoverableToken){
            let liquidatorTokenVal: any =  minRecoverableToken ;
            let maxAmt: any = liquidatorTokenVal;
            // console.log("liquidatorTokenVal",liquidatorTokenVal)
            maxAmt = getNumFormat(maxAmt)
            setLiquidateAmt(maxAmt)
        } else{
            // console.log("seize Token",selectedRowMaxRepayValue, liquidatorTokenValue, availableTokens)
            let maxAmt: any =  Math.min(selectedRowMaxRepayValue, liquidatorTokenValue, availableTokens);
            maxAmt = maxAmt.toFixed(6);
            maxAmt = getNumFormat(maxAmt)
            setLiquidateAmt(maxAmt)
        }
    }
    const handleRowClick = (itemData: any) => {
        // console.log("liquidation item data", itemData)
        setLiquidateAmt('')
        if (selectedData.address === itemData.res.address) {
            setSelectedData({})
            setRepayToken({})
            setRepayToken({})
            setSeizeToken({})
            setRepayList([])
            setSeizeList([])
            setSuppliedAssetsOfLiquidator([])
            setLiquidatorToken({})
            setRepayTokenCheck(false)
        }
        else {
            if(itemData.res.health < 1){
                setAccountUnhealthy(true)
            } else {
                setAccountUnhealthy(false)
            }
            
            // let revisedRepayAmount = itemData.res.borrowBalanceArray[itemData.res.seizeAsset] * itemData.res.tokenPriceArr[itemData.res.seizeAsset] / itemData.res.tokenPriceArr[itemData.res.market];
            itemData.res.maxAmountSeized = itemData.res.maxAmountSeized;
            itemData.res.maxSeizedInDollar = itemData.res.maxAmountSeized * itemData.res.tokenPriceArr[itemData.res.seizeAsset];
            // itemData.res.maxRepayAmount = revisedRepayAmount;
            // itemData.res.maxRepayInDollar = revisedRepayAmount * itemData.res.tokenPriceArr[itemData.res.market];
            itemData.res.total_borrowed = itemData.res.total_borrowed > 0 ? itemData.res.total_borrowed : itemData.res.total_supplied;
            console.log(itemData.assetsToSeizeList[itemData.seize_index], itemData.seize_index)
            setSelectedData(itemData.res)
            let repayTokenObject: any = {};
            if(itemData.assetsToRepayList.length > 1){
                repayTokenObject = itemData.assetsToRepayList.find((element: any) =>{
                    
                    if(itemData.res.market === element.address){
                        return element;
                    }
                });
                setRepayToken(repayTokenObject)
            } else {
                repayTokenObject = itemData.assetsToRepayList[0];
                setRepayToken(itemData.assetsToRepayList[0])
            }
            if(itemData.assetsToSeizeList.length > 1){
                let seizeTokenObject = itemData.assetsToSeizeList.find((element: any) =>{
                    if(itemData.res.seizeAsset === element.address){
                        return element;
                    }
                });
                setSeizeToken(seizeTokenObject)
            } else {
                setSeizeToken(itemData.assetsToSeizeList[0])
            }
            // setRepayToken(itemData.assetsToRepayList[itemData.borrow_index])
            // setSeizeToken(itemData.assetsToSeizeList[itemData.seize_index])
            setRepayList(itemData.assetsToRepayList)
            setSeizeList(itemData.assetsToSeizeList)
            setSuppliedAssetsOfLiquidator(itemData.suppliedAssetsOfLiquidator)
            if(itemData.suppliedAssetsOfLiquidator.length > 0){

                let liquidatorTokenObject = itemData.suppliedAssetsOfLiquidator.find((element: any) =>{
                    if(repayTokenObject.address.toLowerCase() === element.address.toLowerCase()){
                        return element;
                    }
                });
                // console.log("liquidatorTokenObject", liquidatorTokenObject)
                if(liquidatorTokenObject === undefined){
                    setLiquidatorToken(itemData.suppliedAssetsOfLiquidator[0]);
                    setRepayTokenCheck(false)
                } else {
                    setLiquidatorToken(liquidatorTokenObject)
                    setRepayTokenCheck(true)
                }
            }
        }
        // console.log(repayTokenCheck)
    }

    const getLiquidityData = async (repayTokenAdd: string, seizeTokenAdd: string) => {
        const userAddress = selector.ethData.address
        try {
            //liquidator: string, borrower: string, market: string, seizeMarket: string, borrowBalanceArray?: any, closeFactorVal?: any, balanceOfGTokenArr?: any, tokenPriceArr?: any, liquidIncentiveData?: any, liquidatorBalance?: any, total_borrowed ?: number, total_supplied ?: number
            setLDataLoading(true)
            // console.log("inside getLiquidityData",userAddress, selectedData.address, repayTokenAdd, seizeTokenAdd, selectedData)
            const resData = await getLiquidityDataOfUser(userAddress, selectedData.address, repayTokenAdd, seizeTokenAdd, selectedData.borrowBalanceArray, selectedData.closeFactor, selectedData.balanceOfGTokenArr, selectedData.tokenPriceArr, selectedData.liquidIncentiveData, selectedData.liquidatorBalance, selectedData.total_borrowed, selectedData.total_supplied, selectedData.userSupplyBalance)
            // console.log("inside getLiquidityData response", resData)
            if (resData !== null && !!resData && typeof resData !== 'undefined') {
                let revisedRepayAmount = resData.userSupplyBalance[resData.seizeAsset] * resData.tokenPriceArr[resData.seizeAsset] / resData.tokenPriceArr[resData.market];
                resData.maxAmountSeized = resData.userSupplyBalance[resData.seizeAsset];
                resData.maxSeizedInDollar = resData.userSupplyBalance[resData.seizeAsset] * resData.tokenPriceArr[resData.seizeAsset];
                resData.maxRepayAmount = revisedRepayAmount / resData.liquidIncentiveData;
                resData.maxRepayInDollar = revisedRepayAmount * resData.tokenPriceArr[resData.market];
                setSelectedData(resData)
            }
        }
        catch (error) { }
        finally {
            setLDataLoading(false)
        }
    }

    const handleRepaySelect = (repayItem: any) => {
        // console.log("inside handleRepaySelect", repayItem, suppliedAssetsOfLiquidator)
        setLiquidateAmt('')
        setRepayToken(repayItem)
        setShowRepayModal(false)
        getLiquidityData(repayItem.address, seizeToken.address)
        if(suppliedAssetsOfLiquidator !== undefined && suppliedAssetsOfLiquidator.length > 0){
            
            let liquidatorTokenObject = suppliedAssetsOfLiquidator.find((element: any) =>{
                if(repayItem.address.toLowerCase() === element.address.toLowerCase()){
                    return element;
                }
            });
            // console.log("liquidatorTokenObject", liquidatorTokenObject)
            if(liquidatorTokenObject === undefined){
                setLiquidatorToken(suppliedAssetsOfLiquidator[0]);
                setRepayTokenCheck(false)
            } else {
                setLiquidatorToken(liquidatorTokenObject)
                setRepayTokenCheck(true)
            }
        }
        
    }

    const handleLiquidatorAssetSelect = (repayItem: any) => {
        setLiquidateAmt('')
        // console.log("repay item details", repayItem, repayToken)
        //setRepayToken(repayItem)
        setLiquidatorToken(repayItem)
        setShowRepayModal(false)
        // getLiquidityData(repayItem.address, seizeToken.address)
        setShowLiquidatorAssetsModal(false)
        if(repayItem.address !== repayToken.address){
            setRepayTokenCheck(false)
        } else {
            setRepayTokenCheck(true)
        }
    }

    const handleSeizeSelect = (seizeItem: any) => {
        // console.log("inside handleSeizeSelect", seizeItem)
        setLiquidateAmt('')
        setSeizeToken(seizeItem)
        setShowSeizeModal(false)
        getLiquidityData(repayToken.address, seizeItem.address)
    }

    const getFormatAddress = (address: string) => {
        const add1 = address.substring(0, 2)
        const add2 = address.substring(address.length - 3)
        const finalAdd = `${add1}....${add2}`
        return finalAdd
    }

    const handleLiquidate = async () => {
        const userAddress = selector.ethData.address
        try {
            // console.log("inside handleLiquidate", repayToken.address, userAddress, selectedData.address, seizeToken.address, liquidateAmt)
            setLiquidateLoading(true)
            const res = await liquidateBorrow(repayToken.address, userAddress, selectedData.address, seizeToken.address, liquidateAmt)
            if (!!res && typeof res !== 'undefined' && Object.values(res).length > 0) {
                setLiquidateAmt('')
                setSelectedData({})
                setRepayToken({})
                setSeizeToken({})
                updateData()
            }
        }
        catch (error) { }
        finally {
            setLiquidateLoading(false)
        }
    }

    const handleLiquidateRevised = async () => {
        // const userAddress = selector.ethData.address
        // try {
        //     console.log("inside handleLiquidate", repayToken.address, userAddress, selectedData.address, seizeToken.address, liquidateAmt)
        //     setLiquidateLoading(true)
        //     await liquidateBorrow(repayToken.address, userAddress, selectedData.address, seizeToken.address, liquidateAmt)
        //     .then( (res: any) =>{
        //         console.log(res);
        //         if (!!res && typeof res !== 'undefined' && Object.values(res).length > 0) {
        //             const transLink = `${transLinkUrl}/${res}`
        //             setTransactionLink(transLink)
        //             setShowTransModal(true)
        //             setLiquidateAmt('')
        //             setSelectedData({})
        //             setRepayToken({})
        //             setSeizeToken({})
        //             updateData()
        //         }
        //     })
        //     .then((confNumber: any, receipt: any) => {
        //         const type = receipt.status ? 'success' : 'failed'
        //         handleNotification(type, 'Supplied', receipt.transactionHash)
        //         setSuppLoading(false)
        //         dispatch(updateUserLendingData(selector.ethData.address))
        //     })
        //     .on('error', function (error: any) {
        //         handleNotification('failed', 'Supplied')
        //         setShowTransModal(false)
        //         setSuppLoading(false)
        //     })
            
        // }
        // catch (error) { 
        //     console.log(error)
        // }
        // finally {
        //     setLiquidateLoading(false)
        // }
    }

    const updateData = async () => {
        try {
            const userAddress = selector.ethData.address
            const res = await getLiquidatedAccounts(userAddress)
            if (!!res && typeof res !== 'undefined') {
                setLiquidationData(res.liquidatedAccounts)
                setPageNo(res.newPageNo)
                setPageSize(res.pageSize)
                setUnhealthyAccounts(res.unhealthyAccounts)
                setLoadMore(res.loadMore)
            }
        }
        catch (error) { }
    }

    

    const _renderTableRow = () => {
        return liquidationData.map((item: any, index: number) => {

            return (
                <TableRow key={index} className="table-row liquidatedRows" onClick={() => handleRowClick(item)} selected={item.res.address === selectedData.address}>
                    <HealthText isOverOne={!!item && parseFloat(item.res.health) >= 1}>{parseFloat(item.res.health).toFixed(2)}</HealthText>
                    <CardText>{getFormatAddress(item.res.address)}</CardText>
                    {!!item.res &&
                        <>
                            <FlexCont>
                                <img src={item.res.repayAssetIcon} alt="" />
                                <TokenText>{item.res.repayAssetSymbol}</TokenText>
                            </FlexCont>
                            <CardText>{`${commaFy(parseFloat(item.res.maxRepayAmount).toFixed(4))} ${item.res.repayAssetSymbol}`}</CardText>
                            <FlexCont>
                                <img src={item.res.seizeAssetIcon} alt="" />
                                <TokenText>{item.res.seizeAssetSymbol}</TokenText>
                            </FlexCont>
                            <CardText>{`${commaFy(parseFloat(item.res.maxAmountSeized).toFixed(4))} ${item.res.seizeAssetSymbol}`}</CardText>
                            {/* <CardText>${`${commaFy(parseFloat(item.res.total_supplied).toFixed(2))} `}</CardText>
                            <CardText>${item.res.total_borrowed > 0 ? `${commaFy(parseFloat(item.res.total_borrowed).toFixed(2))}`: `${commaFy(parseFloat(item.res.total_supplied).toFixed(2))}`}</CardText> */}
                        </>
                    }

                </TableRow>
            )
        })
    }
    return (
        <MainContainer>
            <LiquidationCont>
                <Title>Liquidations</Title>
                <CardCont>
                    <Card>
                        <CardTitle>Account</CardTitle>
                        <AccInfo>
                            {
                                Object.values(selectedData).length > 0 ?
                                    <FlexSBCont>
                                        <CardText>{selectedData.address}</CardText>
                                        <TransLink href={`${transAddLinkUrl}${selectedData.address}`} target='_blank'>
                                            <img src={LinkIcon} alt="" />
                                        </TransLink>
                                    </FlexSBCont> :
                                    <CardText>Select an account to liquidate</CardText>
                            }

                        </AccInfo>
                        <FlexSBCont>
                            <FlexCont>
                                <CardText light>Account Health</CardText>
                                <ToolTipIcon src={QuestionIcon} alt="" data-tip data-for="accountHealth" left={10} />
                            </FlexCont>
                            {
                                Object.values(selectedData).length > 0 ?
                                    <HealthText >{parseFloat(selectedData.health).toFixed(2)}</HealthText> :
                                    <CardText>0.00</CardText>
                            }

                        </FlexSBCont>
                        <FlexSBCont className="info-gap">
                            <FlexCont>
                                <CardText light>Assets To Repay</CardText>
                                <ToolTipIcon src={QuestionIcon} alt="" data-tip data-for="assetToRepay" left={10} />
                            </FlexCont>

                            {Object.values(selectedData).length > 0 ?
                                <TokenSelectCont onClick={() => setShowRepayModal(true)}>
                                    <img className="token-icon" src={repayToken.icon} alt="" />
                                    <TokenText className="token-name">{repayToken.name}</TokenText>
                                    <img className="down-icon" src={ArrowDown} alt="" />
                                </TokenSelectCont> :
                                <CardText>Select account</CardText>
                            }
                        </FlexSBCont>
                        <FlexSBCont className="info-gap">
                            <FlexCont>
                                <CardText light>Asset To Seize</CardText>
                                <ToolTipIcon src={QuestionIcon} alt="" data-tip data-for="assetToSeize" left={10} />
                            </FlexCont>

                            {Object.values(selectedData).length > 0 ?
                                <TokenSelectCont onClick={() => setShowSeizeModal(true)}>
                                    <img className="token-icon" src={seizeToken.icon} alt="" />
                                    <TokenText className="token-name">{seizeToken.name}</TokenText>
                                    <img className="down-icon" src={ArrowDown} alt="" />
                                </TokenSelectCont> :
                                <CardText>Select account</CardText>
                            }
                        </FlexSBCont>
                        <FlexSBCont className="info-gap">
                            <FlexCont>
                                <CardText light>Max Repay Amount</CardText>
                                <ToolTipIcon src={QuestionIcon} alt="" data-tip data-for="maxRepayAmount" left={10} />
                            </FlexCont>
                            {lDataLoading ? <LoadingText1 /> :
                                <CardText>{Object.values(selectedData).length > 0 ? `${commaFy(parseFloat(selectedData.maxRepayAmount).toFixed(4))} ${selectedData.repayAssetSymbol}` : 0.0000}</CardText>
                            }
                        </FlexSBCont>
                        <FlexSBCont className="info-gap">
                            <FlexCont>
                                <CardText light>Max Seize Amount</CardText>
                                <ToolTipIcon src={QuestionIcon} alt="" data-tip data-for="maxSeizeAmount" left={10} />
                            </FlexCont>

                            {lDataLoading ? <LoadingText1 /> :
                                <CardText>{Object.values(selectedData).length > 0 ? `${commaFy(parseFloat(selectedData.maxAmountSeized).toFixed(4))} ${selectedData.seizeAssetSymbol}` : 0.0000}</CardText>
                            }

                        </FlexSBCont>
                        <FlexSBCont className="info-gap">
                            <FlexCont>
                                <CardText light>Total recoverable Amount</CardText>
                                <ToolTipIcon src={QuestionIcon} alt="" data-tip data-for="maxSeizeAmount" left={10} />
                            </FlexCont>

                            {lDataLoading ? <LoadingText1 /> :
                                <CardText>${Object.values(selectedData).length > 0 ? `${commaFy(parseFloat(selectedData.total_supplied).toFixed(2))}` : 0.00}</CardText>
                            }

                        </FlexSBCont>
                        <FlexSBCont className="info-gap">
                            <FlexCont>
                                <CardText light>Total borrowed Amount</CardText>
                                <ToolTipIcon src={QuestionIcon} alt="" data-tip data-for="maxSeizeAmount" left={10} />
                            </FlexCont>

                            {lDataLoading ? <LoadingText1 /> :
                                <CardText>${Object.values(selectedData).length > 0 && selectedData.total_borrowed > 0 ? `${commaFy(parseFloat(selectedData.total_borrowed).toFixed(2))}` : 0.00}</CardText>
                            }

                        </FlexSBCont>
                    </Card>
                    <Card>
                        <CardTitle>Liquidate</CardTitle>
                        <AccInfo>
                            <CardText>Amount To Repay</CardText>
                        </AccInfo>
                        <ActionCard>
                            <ActionCardTxt>Send to liquidate {`($${Object.values(liquidatorToken).length > 0 ? (parseFloat((+liquidatorToken.price * +liquidateAmt).toString()).toFixed(2)) : '0'})`}</ActionCardTxt>
                            <FlexSBCont className="liquidate-amt">
                                <TokenValInput
                                    disabled={!(Object.values(selectedData).length > 0)}
                                    placeholder="0.00"
                                    onChange={handleLiquidateAmount}
                                    value={liquidateAmt && liquidateAmt}
                                />
                                {Object.values(selectedData).length > 0 && Object.values(liquidatorToken).length > 0 ?
                                    <FlexCont>
                                        <MaxTxt onClick={() => handleMax()}>MAX</MaxTxt>
                                        <TokenSelectCont onClick={() => setShowLiquidatorAssetsModal(true)}>
                                            <img className="token-icon" src={liquidatorToken.icon} alt="" />
                                            <TokenText className="token-name">{liquidatorToken.name}</TokenText>
                                            <img className="down-icon" src={ArrowDown} alt="" />
                                        </TokenSelectCont>
                                    </FlexCont>
                                    :
                                    <CardText light>Select Account</CardText>
                                }
                                {/*Object.values(selectedData).length > 0 && Object.values(repayToken).length > 0 ?
                                    <FlexCont>
                                        <MaxTxt onClick={() => handleMax()}>MAX</MaxTxt>
                                        <TokenSelectCont onClick={() => setShowRepayModal(true)}>
                                            <img className="token-icon" src={repayToken.icon} alt="" />
                                            <TokenText className="token-name">{repayToken.name}</TokenText>
                                            <img className="down-icon" src={ArrowDown} alt="" />
                                        </TokenSelectCont>
                                    </FlexCont>
                                    :
                                    <CardText light>Select Account</CardText>
                            */}

                            </FlexSBCont>
                        </ActionCard>
                        <FlexSBCont className="wallet-info">
                            <CardSmText light>Wallet Balance</CardSmText>
                            <CardSmText>{Object.values(liquidatorToken).length > 0 ? parseFloat(liquidatorToken.value).toFixed(4) + "( $"+ parseFloat(liquidatorToken.usdValue).toFixed(2) + ")": '0.00'}</CardSmText>
                        </FlexSBCont>
                        <LiquidateBtn disabled={!liquidateAmt || liquidateLoading || !(Object.values(selectedData).length > 0) || !repayTokenCheck || !accountUnhealthy || parseFloat(liquidatorToken.usdValue) < 0.01} onClick={() => handleLiquidate()}>{liquidateLoading ? <Spinner /> : 'Liquidate'}</LiquidateBtn>
                    </Card>
                </CardCont>
                {
                    dataLoading || liquidationData.length > 0 ?
                
                <TableWrapper>
                    <TableCont>
                        <TableRow className="liquidatedAccounts">
                            <CardText light>Health</CardText>
                            <CardText light>Account</CardText>
                            <CardText light>Asset To Repay</CardText>
                            <CardText light>Max Repay Amount</CardText>
                            <CardText light>Asset To Seize</CardText>
                            <CardText light>Max Seize Amount</CardText>
                            {/* <CardText light>Recoverable</CardText>
                            <CardText light>Deficit</CardText> */}
                        </TableRow>
                        {dataLoading ? <ComponentLoader /> :
                            <>
                                {!!liquidationData && liquidationData.length > 0 && _renderTableRow()}
                            </>
                        }

                    </TableCont>
                </TableWrapper>
                :
                ""
                }
            </LiquidationCont>
            {loadMore &&
                <LiquidateBtn accountsArray={liquidationData.length} disabled={!loadMore} onClick={() => loadMoreLiquidatedAccounts()}>{'Load More'}</LiquidateBtn>
            }
            
            <ReactTooltip className="planet-tool-tip" id="accountHealth" place="top" effect="solid">
                <ToolTipText>When account health drops below 1.00, the account can be liquidated by repaying their debt beyond the borrow limit.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="assetToRepay" place="top" effect="solid">
                <ToolTipText>These are the borrowers assets that need to be repaid to ensure the borrower remains within the collateral ratio limits.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="assetToSeize" place="top" effect="solid">
                <ToolTipText>These are the borrowers supplied assets that can be seized by a liquidator for repaying the outstanding debt.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="maxRepayAmount" place="top" effect="solid">
                <ToolTipText>The maximum amount of assets required to repay outstanding debt.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="maxSeizeAmount" place="top" effect="solid">
                <ToolTipText>Maximum amount of assets seizable for repaying the outstanding debt.</ToolTipText>
            </ReactTooltip>

            <CustomModal show={showRepayModal} toggleModal={setShowRepayModal}>
                {showRepayModal && (
                    <TokenSelect
                        selectedAdd={repayToken.address}
                        setShowTokenModal={setShowRepayModal}
                        tokenListVal={repayList}
                        handleTokenSelect={handleRepaySelect}
                        hideInput={true}
                    />
                )}
            </CustomModal>
            <CustomModal show={showLiquidatorAssetsModal} toggleModal={setShowLiquidatorAssetsModal}>
                {showLiquidatorAssetsModal && suppliedAssetsOfLiquidator !== undefined && suppliedAssetsOfLiquidator.length > 0 && (
                    <TokenSelect
                        selectedAdd={repayToken.address}
                        setShowTokenModal={setShowLiquidatorAssetsModal}
                        tokenListVal={suppliedAssetsOfLiquidator}
                        handleTokenSelect={handleLiquidatorAssetSelect}
                        hideInput={true}
                    />
                )}
            </CustomModal>
            <CustomModal show={showSeizeModal} toggleModal={setShowSeizeModal}>
                {showSeizeModal && (
                    <TokenSelect
                        selectedAdd={seizeToken.address}
                        setShowTokenModal={setShowSeizeModal}
                        tokenListVal={seizeList}
                        handleTokenSelect={handleSeizeSelect}
                        hideInput={true}
                    />
                )}
            </CustomModal>
        </MainContainer>
    );
};

export default Liquidation;