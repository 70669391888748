import { useState, useEffect, Fragment } from 'react'
import { Table, TableHeader, TableBody, MobTableBody, TableRowCont, TableRow, CellCont, HeadText, HeadTextCont, HeadTextData, CellText, CellSmText, ArrowIconCont, MobArrowIconCont, VaultActionRow, PoolActionRow, LoadingText1 } from 'shared/styles/planetTableStyle'
import DownIcon from 'assets/icons/down.svg'
import SortIcon from 'assets/icons/white-arrow-up.svg'
import DefaultSortIcon from 'assets/icons/up-down-icon.svg'
import InfoTooltip from 'shared/info-tool-tip'
import { commaFy, convertToKAndM, checkZeroVal, getTokenName } from 'service/globalFunctions';
import { getTokenIcon } from 'shared/tokenIconList'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import EmptyData from 'shared/empty-data'
import TableLoading from 'shared/table-loading'
import VaultTransaction from './VaultTransaction'
import BoostVaultTransaction from './BoostVaultTransaction'
import VaultInfo from './VaultInfo'
import VaultDetailsModal from './vault-modal/VaultDetailsModal'

const VaultsTable = (props: any) => {
    const [sortByTh, setSortByTh] = useState('tvl')
    const [sortDescending, setSortDescending] = useState(true)
    const [expandedRows, setExpandedRows] = useState<any>([])

    /* ===================== mob state ======================= */
    const [showVaultDetails, setShowVaultDetails] = useState(false)
    const [modalData, setModalData] = useState<any>({})

    const { activeTab, poolLoading, data, portfolioLoading, protocol, isBorderDisable, isUserVault, vaults } = props
    // console.log('data inside vault details page ---', data);

    useEffect(() => {
        setExpandedRows([])
        if(isUserVault === true)
            setSortByTh('vault')
    }, [activeTab])

    const handleRowClick = (rowId: any) => {
        const currentExpandedRows = expandedRows
        const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId)
        const newExpandedRows = isRowCurrentlyExpanded
            ? currentExpandedRows.filter((id: any) => id !== rowId)
            : currentExpandedRows.concat(rowId)

        setExpandedRows(newExpandedRows)
    }

    const _renderTableRow = (rowData: any) => {
        return rowData.map((rowItem: any, index: number) => {
            // console.log("_renderTableRow", rowItem)
            if(rowItem.walletBalInUsd !== undefined){
                rowItem.walletBalanceUSD = rowItem.walletBalInUsd;
            }
            if(rowItem.currentlySupplyingUsd !== undefined){
                rowItem.vaultBalanceUSD = rowItem.currentlySupplyingUsd 
            }
            const clickCallback = () => handleRowClick(rowItem.address)
            // console.log("row item", rowItem)
            return (
                <TableRowCont key={index}>
                    <TableRow onClick={clickCallback}>
                        <CellCont>
                            <img src={getTokenIcon(rowItem.name.replace(' (OLD)', ''))} alt="" />
                        </CellCont>
                        <CellCont>
                            <CellText>{`${getTokenName(rowItem.name)} ${rowItem.isBoostPool ?  rowItem.strategyAddress === "0x0709e1356F765a6F88632B539E209a5d6864B765" ? "(PCS) OLD":'(PCS)' : ''}`}</CellText>
                            <CellSmText>{`$${!!rowItem.tvl ? commaFy(convertToKAndM(rowItem.tvl)) : '0.00'} TVL`}</CellSmText>
                        </CellCont>
                        <CellCont>
                            <CellText>{commaFy(convertToKAndM(rowItem.totalApy))}%</CellText>
                        </CellCont>
                        <CellCont>
                            {portfolioLoading ? <LoadingText1 /> :
                                <CellText isValZero={checkZeroVal(rowItem.walletBalanceUSD)}>${commaFy(parseFloat(rowItem.walletBalanceUSD).toFixed(2))}</CellText>
                            }
                        </CellCont>
                        <CellCont>
                            {portfolioLoading ? <LoadingText1 /> :
                                <CellText isValZero={checkZeroVal(rowItem.vaultBalanceUSD)}>${commaFy(parseFloat(rowItem.vaultBalanceUSD).toFixed(2))}</CellText>
                            }
                        </CellCont>
                        {/* <CellCont>
                            {rowItem.isBoostPool &&
                                <>
                                    {portfolioLoading ? <LoadingText1 /> :
                                        <CellText className={!checkZeroVal(rowItem.totalRewardsUSD) ? "reward-text" : ''} isValZero={checkZeroVal(rowItem.totalRewardsUSD)}>${commaFy(parseFloat(rowItem.totalRewardsUSD).toFixed(2))}</CellText>
                                    }
                                </>
                            }
                        </CellCont> */}
                        <CellCont>
                            <ArrowIconCont isActive={expandedRows.includes(rowItem.address)}>
                                <img src={DownIcon} alt="" />
                            </ArrowIconCont>
                        </CellCont>
                    </TableRow>
                    {expandedRows.includes(rowItem.address) &&
                        <Fragment>
                            {rowItem.isBoostPool ?
                                <PoolActionRow>
                                    <BoostVaultTransaction data={rowItem} />
                                </PoolActionRow> :
                                <VaultActionRow>
                                    <VaultTransaction data={rowItem} />
                                </VaultActionRow>
                            }
                            <VaultInfo data={rowItem} />
                        </Fragment>
                    }

                </TableRowCont>
            )
        })
    }

    const handleShowVaultDetails = (modalDataVal: any) => {
        // console.log("handleShowVaultDetails", modalDataVal)
        setModalData(modalDataVal)
        setShowVaultDetails(true)
    }

    const toggleVaultDetailsModal = () => {
        setModalData({})
        setShowVaultDetails(false)
    }

    const _renderMobTableRow = (rowData: any) => {
        return rowData.map((rowItem: any, index: number) => {
            if(rowItem.walletBalInUsd !== undefined){
                rowItem.walletBalanceUSD = rowItem.walletBalInUsd;
            }
            if(rowItem.currentlySupplyingUsd !== undefined){
                rowItem.vaultBalanceUSD = rowItem.currentlySupplyingUsd 
            }
            return (
                <TableRowCont key={index} onClick={() => handleShowVaultDetails(rowItem)}>
                    <TableRow>
                        <CellCont>
                            <img src={getTokenIcon(rowItem.name.replace(' (OLD)', ''))} alt="" />
                        </CellCont>
                        <CellCont>
                            <CellText>{`${getTokenName(rowItem.name)} ${rowItem.isBoostPool ?  rowItem.strategyAddress === "0x0709e1356F765a6F88632B539E209a5d6864B765" ? "(PCS) OLD":'(PCS)' : ''}`}</CellText>
                            {/* <CellText>{`${getTokenName(rowItem.name)} ${rowItem.isBoostPool ? '(PCS)' : ''}`}</CellText> */}
                        </CellCont>
                        <CellCont>
                            <CellText>{commaFy(convertToKAndM(rowItem.totalApy))}%</CellText>
                        </CellCont>
                        {vaults === "all" ?
                        <CellCont>
                            {portfolioLoading ? <LoadingText1 /> :
                                <CellText isValZero={checkZeroVal(rowItem.walletBalanceUSD)}>${commaFy(parseFloat(rowItem.walletBalanceUSD).toFixed(2))}</CellText>
                            }
                        </CellCont>
                        :
                        <CellCont>
                            {portfolioLoading ? <LoadingText1 /> :
                                <CellText isValZero={checkZeroVal(rowItem.vaultBalanceUSD)}>${commaFy(parseFloat(rowItem.vaultBalanceUSD).toFixed(2))}</CellText>
                            }
                        </CellCont>
                        }
                        <CellCont>
                            <MobArrowIconCont>
                                <img src={DownIcon} alt="" />
                            </MobArrowIconCont>
                        </CellCont>
                    </TableRow>
                </TableRowCont>
            )
        })
    }

    const getRowData = () => {
        // console.log('rowData -',  data);
        let rowData: any = []
        if (!!data && Object.values(data).length > 0) {
            if (isUserVault) {
                rowData = data
            }
            else {
                // console.log("inside else of getRowData", data)
                if (activeTab === 'all') {
                    rowData = data.active
                }
                if (activeTab === 'boosted') {
                    rowData = data.active.filter((e: any) => e.pooltype === 'boosted')
                }
                if (activeTab === 'stablecoins') {
                    rowData = data.active.filter((e: any) => e.pooltype === 'stable')
                }
                if (activeTab === 'cryptos') {
                    rowData = data.active.filter((e: any) => e.pooltype === 'crypto')
                }
                if (activeTab === 'finished') {
                    rowData = data.finished
                    // console.log(" finished row data", rowData)
                }
                // rowData = rowData.filter((e: any) => !(parseFloat(e.vaultBalanceUSD) > 0))
                // console.log("after filter row data", rowData)
            }
            if (!!rowData && rowData.length > 0) {
                if (!!protocol) {
                    rowData = rowData.filter((e: any) => e.protocol === protocol)
                }
                if (sortByTh === 'tvl') {
                    rowData = rowData.sort((a: any, b: any) => (parseFloat(b.tvl) > parseFloat(a.tvl)) ? 1 : (parseFloat(a.tvl) > parseFloat(b.tvl) ? -1 : 0))
                }
                if (sortByTh === 'apy') {
                    rowData = rowData.sort((a: any, b: any) => (parseFloat(b.totalApy) > parseFloat(a.totalApy)) ? 1 : (parseFloat(a.totalApy) > parseFloat(b.totalApy) ? -1 : 0))
                }
                if (sortByTh === 'wallet') {
                    rowData = rowData.sort((a: any, b: any) => (parseFloat(b.walletBalanceUSD) > parseFloat(a.walletBalanceUSD)) ? 1 : (parseFloat(a.walletBalanceUSD) > parseFloat(b.walletBalanceUSD) ? -1 : 0))
                }
                if (sortByTh === 'vault') {
                    rowData = rowData.sort((a: any, b: any) => (parseFloat(b.vaultBalanceUSD) > parseFloat(a.vaultBalanceUSD)) ? 1 : (parseFloat(a.vaultBalanceUSD) > parseFloat(b.vaultBalanceUSD) ? -1 : 0))
                }
                if (sortByTh === 'rewards') {
                    rowData = rowData.sort((a: any, b: any) => (parseFloat(b.totalRewardsUSD) > parseFloat(a.totalRewardsUSD)) ? 1 : (parseFloat(a.totalRewardsUSD) > parseFloat(b.totalRewardsUSD) ? -1 : 0))
                }
                if (!sortDescending) {
                    rowData = rowData.reverse()
                }
            }
        }

        // console.log('rowData',  rowData);
        return rowData
    }

    const renderTableData = () => {
        const rowData = getRowData()
        // console.log("renderTableData row data", rowData)
        if (rowData !== undefined && rowData.length > 0) {
            return (
                <Fragment>
                    <TableBody>
                        {_renderTableRow(rowData)}
                    </TableBody>
                    <MobTableBody>
                        {_renderMobTableRow(rowData)}
                    </MobTableBody>
                </Fragment>

            )
        }
        else {
            return <EmptyData />
        }
    }

    const handleTabClick = (tabSelect: string) => {
        if (tabSelect === sortByTh) {
            setSortByTh(tabSelect)
            setSortDescending(!sortDescending)
        }
        else {
            setSortByTh(tabSelect)
            setSortDescending(true)
        }
    }

    return (
        <Table isBorderDisable={isBorderDisable}>
            <TableHeader>
                <HeadTextCont />
                <HeadTextCont>
                    <HeadTextData onClick={() => handleTabClick('tvl')} isDown={sortByTh === 'tvl' && sortDescending} >
                        <HeadText>TVL</HeadText>
                        <img src={sortByTh === 'tvl' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                <HeadTextCont>
                    <HeadTextData onClick={() => handleTabClick('apy')} isDown={sortByTh === 'apy' && sortDescending}>
                        <HeadText>APY</HeadText>
                        <img src={sortByTh === 'apy' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                <HeadTextCont className='show-web'>
                    <HeadTextData onClick={() => handleTabClick('wallet')} isDown={sortByTh === 'wallet' && sortDescending}>
                        <HeadText>In Wallet</HeadText>
                        <img src={sortByTh === 'wallet' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                <HeadTextCont className='show-web'>
                    <HeadTextData onClick={() => handleTabClick('vault')} isDown={sortByTh === 'vault' && sortDescending}>
                        <HeadText>In Vault</HeadText>
                        <img src={sortByTh === 'vault' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                {vaults === "all" ?
                <HeadTextCont className='show-mob'>
                    <HeadTextData onClick={() => handleTabClick('wallet')} isDown={sortByTh === 'wallet' && sortDescending}>
                        <HeadText>In Wallet</HeadText>
                        <img src={sortByTh === 'wallet' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                :
                <HeadTextCont className='show-mob'>
                    <HeadTextData onClick={() => handleTabClick('vault')} isDown={sortByTh === 'vault' && sortDescending}>
                        <HeadText>Deposit</HeadText>
                        <img src={sortByTh === 'vault' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                }
                {/* <HeadTextCont className='show-web'>
                    <HeadTextData onClick={() => handleTabClick('rewards')} isDown={sortByTh === 'rewards' && sortDescending}>
                        <HeadText>Rewards</HeadText>
                        <img src={sortByTh === 'rewards' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                    <InfoTooltip infoId='vaultRewards' left={5} />
                </HeadTextCont>
                <HeadTextCont /> */}
            </TableHeader>
            {poolLoading ? <TableLoading /> : renderTableData()}

            <CustomActionModal show={showVaultDetails} toggleModal={toggleVaultDetailsModal}>
                {showVaultDetails && <VaultDetailsModal toggleVaultModal={toggleVaultDetailsModal} data={modalData} setModalData={setModalData} currentTab={activeTab} />}
            </CustomActionModal>
        </Table>

    );
};

export default VaultsTable;