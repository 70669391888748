import { gammatrollerAddress, deprecatedMarkets, discountLevelAddress, gammaAbi, gBnbAbi, instType, intRateModelAbi, selectInstance, convertToEther, gammatrollerAddressOld, gammatrollerAbi, gammatrollerAbiOld } from './lendingAbi'
import { zeroAddress } from './SwapDexAbi'
import wallet from 'modules/wallet/wallet'
import { aquaAddress, gamma_reservoir, multicall_abi, multicall_address } from './abi'


/************************************************Read Only Functions************************************************************/

export const _borrowGuardianPaused = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods._borrowGuardianPaused().call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const _mintGuardianPaused = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods._mintGuardianPaused().call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const accountAssets = async (address: string, amount: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.accountAssets(address, amount).call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const admin = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.admin().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const allMarkets = async (amount: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.allMarkets(amount).call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}
export const borrowCapGuardian = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.borrowCapGuardian().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const borrowCaps = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.borrowCaps(address).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}
export const borrowGuardianPaused = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.borrowGuardianPaused(address).call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const borrowGuardianPausedMulticall = async (addressArray: any, gammatrollerAdd: string) => {
    const assetsLen = Object.keys(addressArray).length
    const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
    let targets: any = []
    let callDatas: any = []
    let results: any = []
    let ouput_format: any = []
    let borrowGuardianPausedArr: any = [];
    if (assetsLen === 0) {
        return borrowGuardianPausedArr;
    }
    try {
        // const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        
        for (let i = 0; i < assetsLen; i++) {
            const inst : any = addressArray[i].address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || addressArray[i].address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? await selectInstance(instType.gammatrollerOld, gammatrollerAddressOld) : await selectInstance(instType.gammatroller, gammatrollerAddress)
            let gammatrollerAddressVal =  addressArray[i].address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || addressArray[i].address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? gammatrollerAddressOld : gammatrollerAddress
            targets.push(gammatrollerAddressVal)
            const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.borrowGuardianPaused(addressArray[i].address)._method, [addressArray[i].address])
            callDatas.push(data)
            ouput_format.push(inst.methods.borrowGuardianPaused(addressArray[i].address)._method.outputs)
        }
        const aggregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()

        const do_split = async (array: any, n: any): Promise<any> => {
            return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
        }

        for (let i = 0; i < aggregated_data[1].length; i++) {
            results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
        }

        const split_arr = await do_split(results, assetsLen)
        const blocksPerDay = 28800 // 13.15 seconds per block
        const daysPerYear = 365
        if (split_arr[0].length > 0) {
            for (let i = 0; i < assetsLen; i++) {
                borrowGuardianPausedArr[addressArray[i].address] = split_arr[0][i][0];
            }
        }
        // console.log("borrowGuardianPausedArr", borrowGuardianPausedArr)
        return borrowGuardianPausedArr;
    } catch (e) {
        console.log(e)
        return false
    }
}

export const checkMembership = async (account: string, gToken: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.checkMembership(account, gToken).call()
        return data
    } catch (e) {
        console.log(e)
        return false

    }
}

export const closeFactorMantissa = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.closeFactorMantissa().call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaAccrued = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaAccrued(address).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaBorrowState = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaBorrowState(address).call()
        return data
    } catch (e) {
        console.log(e)
        return {
            index: 0,
            block: 0
        }
    }
}

export const gammaBorrowerIndex = async (add1: string, add2: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaBorrowerIndex(add1, add2).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaContributorSpeeds = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaContributorSpeeds(address).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaInitialIndex = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaInitialIndex().call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaRate = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaRate().call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaSpeeds = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaSpeeds(address).call()
        return data
    } catch (e) {
        console.log(address, gammatrollerAdd, e)
        return 0
    }
}

export const gammaSpeedsMulticall = async (addressArray: any, gammatrollerAdd: string) => {
    const assetsLen = Object.keys(addressArray).length
    const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
    let targets: any = []
    let callDatas: any = []
    let results: any = []
    let ouput_format: any = []
    let gammaSpeedsArr: any = [];
    if (assetsLen === 0) {
        return gammaSpeedsArr;
    }
    try {
        // const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        for (let i = 0; i < assetsLen; i++) {
            const inst : any = addressArray[i].address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || addressArray[i].address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? await selectInstance(instType.gammatrollerOld, gammatrollerAddressOld) : await selectInstance(instType.gammatroller, gammatrollerAddress)
            let gammatrollerAddressVal =  addressArray[i].address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || addressArray[i].address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? gammatrollerAddressOld : gammatrollerAddress
            targets.push(gammatrollerAddressVal)
            const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.gammaSpeeds(addressArray[i].address)._method, [addressArray[i].address])
            callDatas.push(data)
            ouput_format.push(inst.methods.gammaSpeeds(addressArray[i].address)._method.outputs)
        }
        const aggregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()

        const do_split = async (array: any, n: any): Promise<any> => {
            return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
        }

        for (let i = 0; i < aggregated_data[1].length; i++) {
            results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
        }

        const split_arr = await do_split(results, assetsLen)
        const blocksPerDay = 28800 // 13.15 seconds per block
        if (split_arr[0].length > 0) {
            for (let i = 0; i < assetsLen; i++) {
                gammaSpeedsArr[addressArray[i].address] = parseFloat(convertToEther((split_arr[0][i][0] * blocksPerDay), 18)) 
            }
        }
        // console.log('gammaSpeedsArr', gammaSpeedsArr)
        return gammaSpeedsArr;
    } catch (error) {
        console.log('error', error)
    }
}

export const gammaSupplierIndex = async (add1: string, add2: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaSupplierIndex(add1, add2).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaSupplyState = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaSupplyState(address).call()
        return data
    } catch (e) {
        console.log(e)
        return {
            index: 0,
            block: 0
        }
    }
}
export const gammatrollerImplementation = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammatrollerImplementation().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const getAccountLiquidity = async (account: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.getAccountLiquidity(account).call()
        return data
    } catch (e) {
        console.log(e)
        return {
            0: 0,
            1: 0,
            2: 0
        }

    }
}

export const deprecatedMarket = async (gTokenAddress: string) => {
    try {
        const inst: any = await selectInstance(instType.DL, discountLevelAddress)
        const data = await inst.methods.deprecatedMarket(gTokenAddress).call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const getAllMarkets = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.getAllMarkets().call()

        let res = [];
        for (let i = 0; i < data.length; i++) {
            if (!deprecatedMarkets.includes(data[i])) {
                res.push(data[i])
            }
        }

        return res
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const getAssetsIn = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.getAssetsIn(address).call()

        // let res = [];
        // for (let i = 0; i < data.length; i++) {
        //     const check = await isDeprecated(data[i], gammatrollerAddress)
        //     if (!check) {
        //         res.push(data[i])
        //     }
        // }
        return data
    } catch (e) {
        console.log(e)
        return []
    }
}

export const getAssetsInMulticall = async (address: string, gammatrollerAdd: string) => {
    if(address === undefined|| address === null ){
        return [];
    } 
    try {
        let assets_data: any = []
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        if(!!address){
            assets_data = await inst.methods.getAssetsIn(address).call()
        }
        // console.log("assets_data", assets_data, gammatrollerAdd);
        if (assets_data.length === 0) {
            assets_data = await inst.methods.getAssetsIn(address).call();
        }
        const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
        // const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress);
        let targets: any = [];
        let callDatas: any = [];
        let results: any = [];
        let ouput_format: any = [];
        let isDeprecatedArray: any = [];
        if (assets_data.length === 0) {
            return isDeprecatedArray;
        }
        try {
            // const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
            for (let i = 0; i < assets_data.length; i++) {
                const inst : any = assets_data[i].toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || assets_data[i].toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? await selectInstance(instType.gammatrollerOld, gammatrollerAddressOld) : await selectInstance(instType.gammatroller, gammatrollerAddress)
                let gammatrollerAddressVal =  assets_data[i].toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || assets_data[i].toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? gammatrollerAddressOld : gammatrollerAddress
                targets.push(gammatrollerAddressVal)
                const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.isDeprecated(assets_data[i])._method, [assets_data[i]]));
                callDatas.push(data);
                ouput_format.push(inst.methods.isDeprecated(assets_data[i])._method.outputs)
            }
            const aggregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());

            const do_split = async (array: any, n: any): Promise<any> => {
                return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
            }

            for (let i = 0; i < aggregated_data[1].length; i++) {
                results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
            }

            const split_arr = (await do_split(results, assets_data.length));
            if (split_arr[0].length > 0) {
                for (let i = 0; i < assets_data.length; i++) {
                    if (split_arr[0][i][0] === false)
                        isDeprecatedArray.push({ address: assets_data[i] });
                }
            }
            // console.log("isDeprecatedArray", isDeprecatedArray);
            return isDeprecatedArray;
        } catch (error) {
            console.log("error", error)
        }

    } catch (e) {
        console.log(e)
        return []
    }
}

export const getBlockNumber = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.getBlockNumber().call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}
export const getCompAddress = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.getCompAddress().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const getHypotheticalAccountLiquidity = async (account: string, gTokenModify: string, redeemTokens: string, borrowAmount: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.getHypotheticalAccountLiquidity(account, gTokenModify, redeemTokens, borrowAmount).call()
        return data
    } catch (e) {
        console.log(e)
        return {
            0: 0,
            1: 0,
            2: 0
        }
    }
}

export const isGammatroller = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.isGammatroller().call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}
export const isDeprecated = async (gToken: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.isDeprecated(gToken).call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const lastContributorBlock = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.lastContributorBlock(address).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const liquidateCalculateSeizeTokens = async (gTokenBorrowed: string, gTokenCollateral: string, actualRepayAmount: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.liquidateCalculateSeizeTokens(gTokenBorrowed, gTokenCollateral, actualRepayAmount).call()
        return data
    } catch (e) {
        console.log(e)
        return {
            0: 0,
            1: 0
        }
    }
}

export const liquidationIncentiveMantissa = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.liquidationIncentiveMantissa().call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const markets = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.markets(address).call()
        return data
    } catch (e) {
        console.log(e)
        return {
            isListed: false,
            collateralFactorMantissa: 0,
            isComped: false
        }
    }
}

export const marketsMulticall = async (addressArray: any, assetLength: any, gammatrollerAdd: string, assetIn: any) => {
    let res = [];
    const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
    let targets: any = [];
    let callDatas: any = [];
    let results: any = [];
    let ouput_format: any = [];
    let marketArray: any = {};
    // console.log("address in marketsMulticall",assetIn)
    
    try {
        // const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        // const data = await inst.methods.markets(address).call()
        // return data
        // const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        for (let i = 0; i < assetLength; i++) {
            // console.log("address in marketsMulticall",addressArray[i].address)
            const inst : any = addressArray[i].address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || addressArray[i].address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? await selectInstance(instType.gammatrollerOld, gammatrollerAddressOld) : await selectInstance(instType.gammatroller, gammatrollerAddress)
            let gammatrollerAddressVal =  addressArray[i].address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || addressArray[i].address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? gammatrollerAddressOld : gammatrollerAddress
            targets.push(gammatrollerAddressVal)
            // targets.push(gammatrollerAdd);
            const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.markets(addressArray[i].address)._method, [addressArray[i].address]));
            callDatas.push(data);
            ouput_format.push(inst.methods.markets(addressArray[i].address)._method.outputs)
        }
        const aggregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());

        const do_split = async (array: any, n: any): Promise<any> => {
            return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
        }

        for (let i = 0; i < aggregated_data[1].length; i++) {
            results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
        }

        const split_arr = (await do_split(results, assetLength));
        // console.log("marketsMulticall split_arr ",split_arr[0])
        if (split_arr[0].length > 0) {
            for (let i = 0; i < assetLength; i++) {
                if (split_arr[0][i] !== undefined) {
                    let collateralFactor = split_arr[0][i][1] ? convertToEther(split_arr[0][i][1], 18) * 100 : 0;
                    let index = assetIn.some((e: any) => e.address === addressArray[i].address)
                    // console.log("index", addressArray[i].address, index, split_arr[0][i][1])
                    marketArray[addressArray[i].address] = {
                        isListed: split_arr[0][i][0],
                        collateralFactor: collateralFactor,//split_arr[0][i][1],
                        isComped: split_arr[0][i][1],
                        index: index,
                        isAvailForCollateral: collateralFactor && index ? true : false
                    }
                } else {

                    marketArray[addressArray[i].address] = {
                        isListed: false,
                        collateralFactor: 0,
                        isComped: false,
                        index: false,
                        isAvailForCollateral: false
                    }
                }
            }
        }
        // console.log("marketArray",marketArray)
        return marketArray;
    } catch (e) {
        console.log(e)
        return {
            isListed: false,
            collateralFactorMantissa: 0,
            isComped: false
        }
    }
}

export const marketsMulticallRevised = async (addressArray: any, assetLength: any, gammatrollerAdd: string, assetIn?: any) => {
    let res = [];
    const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
    let targets: any = [];
    let callDatas: any = [];
    let results: any = [];
    let ouput_format: any = [];
    let marketArray: any = {};
    // console.log("address in marketsMulticall",addressArray)
    try {
        // const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        // const data = await inst.methods.markets(address).call()
        // return data
        // const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        for (let i = 0; i < assetLength; i++) {
            // console.log("address in marketsMulticall",addressArray[i].address, gammatrollerAdd, assetLength)
            const inst : any = addressArray[i].address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || addressArray[i].address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? await selectInstance(instType.gammatrollerOld, gammatrollerAddressOld) : await selectInstance(instType.gammatroller, gammatrollerAddress)
            let gammatrollerAddressVal =  addressArray[i].address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || addressArray[i].address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? gammatrollerAddressOld : gammatrollerAddress
            targets.push(gammatrollerAddressVal)
            const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.markets(addressArray[i].address)._method, [addressArray[i].address]));
            callDatas.push(data);
            ouput_format.push(inst.methods.markets(addressArray[i].address)._method.outputs)
        }
        const aggregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());
        // console.log("aggregated_data inside marketsMulticallRevised", aggregated_data)
        const do_split = async (array: any, n: any): Promise<any> => {
            return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
        }

        for (let i = 0; i < aggregated_data[1].length; i++) {
            results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
        }

        const split_arr = (await do_split(results, assetLength));
        // console.log("marketsMulticallRevised split_arr",split_arr[0])
        if (split_arr[0].length > 0) {
            for (let i = 0; i < assetLength; i++) {
                if (split_arr[0][i] !== undefined) {
                    // let collateralFactor = split_arr[0][i][1] ? convertToEther(split_arr[0][i][1], 18) * 100: 0;
                    // let index = assetIn.some((e: any) => e.address === addressArray[i].address)
                    marketArray[addressArray[i].address] = split_arr[0][i];
                    marketArray[addressArray[i].address.toLowerCase()] = split_arr[0][i];
                } else {

                    marketArray[addressArray[i].address] = {
                        isListed: false,
                        collateralFactorMantissa: '0',
                        isComped: false
                    }
                    marketArray[addressArray[i].address.toLowerCase()] = {
                        isListed: false,
                        collateralFactorMantissa: '0',
                        isComped: false
                    }
                }
            }
        }
        return marketArray;
    } catch (e) {
        console.log(addressArray, e)
        return {
            isListed: false,
            collateralFactorMantissa: 0,
            isComped: false
        }
    }
}

export const maxAssets = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.maxAssets().call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const mintGuardianPaused = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.mintGuardianPaused(address).call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const oracle = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.oracle().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const pauseGuardian = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.pauseGuardian().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}
export const pendingAdmin = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.pendingAdmin().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const pendingGammatrollerImplementation = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.pendingGammatrollerImplementation().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const seizeGuardianPaused = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.seizeGuardianPaused().call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const transferGuardianPaused = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.transferGuardianPaused().call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}



/***************************************************************************************************************/

export const enterMarkets = async (gTokenAddress: string, userAddress: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAddress)
        const data = await inst.methods.enterMarkets([gTokenAddress]).send({
            from: userAddress,
        })
        return data;
    } catch (e) {
        console.log(e)
        return {};
    }
}

export const exitMarkets = async (gTokenAddress: string, userAddress: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAddress)
        const data = await inst.methods.exitMarket(gTokenAddress).send({
            from: userAddress,
        })
        return data;
    } catch (e) {
        console.log(e)
        return {};
    }
}

export const claimGamma = async (userAddress: string, userAssetData: any) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAddress)

        let holders: any = userAddress;
        let supplyGTokens: any = [];
        let borrowGTokens: any = [];
        let suppliers = false;
        let borrowers = false;

        // const assetsIn = await getAssetsIn(userAddress, gammatrollerAddress)

        if (userAssetData.suppliedAssets.length > 0) {

            suppliers = true;

            userAssetData.suppliedAssets.forEach((element: any) => {
                if(element.address.toLowerCase() !== "0xb7eD4A5AF620B52022fb26035C565277035d4FD7".toLowerCase() && element.address.toLowerCase() !== "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442".toLowerCase()){
                    supplyGTokens.push(element.address.toLowerCase())
                }
            });

        }

        if (userAssetData.borrowedAssets.length > 0) {
            borrowers = true;
            userAssetData.borrowedAssets.forEach((element: any) => {
                borrowGTokens.push(element.address.toLowerCase())
            });
        }

        // gTokens = [...new Set(gTokens)]
        // console.log("Claim gamma details for lending", holders, supplyGTokens, borrowGTokens, borrowers, suppliers)
        let data: any;
        if(userAssetData.suppliedAssets.length > 0){
            data = await inst.methods.claimGamma(holders, supplyGTokens, borrowGTokens, borrowers, suppliers).send({
                from: userAddress,
            })
        } else {
            data= await inst.methods.claimGamma(userAddress).send({
                from: userAddress,
            })
        }
         
        return data;
    } catch (e) {
        console.log(e)
        return {};
    }
}

/***************************************************************************************************************/