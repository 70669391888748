import { convertToEther, convertToWei, gBnbAddress, instType, selectInstance, gammatrollerAddress, priceOracleAddress, gGamma } from './lendingAbi'
import { zeroAddress } from './SwapDexAbi'
import { bnbAddress, multicall_abi, multicall_address } from './abi'
import { pfTokenList, pfTokenListRevised } from './pfTokenList'
import { tokenApprove } from './LendingBase'
import wallet from 'modules/wallet/wallet'
import { getBorrowRate } from './interestRateModel'
import { getUnderlyingDecimal } from 'modules/block-chain-green/LendingBase'
import { anchorApy } from 'modules/block-chain/abi'

/************************************************Read Only Functions************************************************************/

export const accrualBlockNumber = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.accrualBlockNumber().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const admin = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.admin().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const allowance = async (owner: string, spender: string, gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.allowance(owner, spender).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const allowanceMulticall = async (owner: string, gTokenAddressArray: any, arrLen: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let allowanceArray: any = [];
  if (arrLen === 0 || !owner) {
    return allowanceArray;
  }
  try {
    for (let i = 0; i < arrLen; i++) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].token)
      targets.push(gTokenAddressArray[i].token)
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.allowance(owner, gTokenAddressArray[i].address)._method, [
        owner, gTokenAddressArray[i].address
      ])
      callDatas.push(data)
      ouput_format.push(inst.methods.allowance(owner, gTokenAddressArray[i].address)._method.outputs)
    }
    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()
    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = await do_split(results, arrLen)
    if (split_arr[0].length > 0) {
      for (let i = 0; i < arrLen; i++) {
        allowanceArray[gTokenAddressArray[i].address] = split_arr[0][i][0]
      }
    }
    return allowanceArray;
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const balanceOfGtoken = async (owner: string, gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.balanceOf(owner).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const balanceOfGtokenMulticall = async (owner: string, gTokenAddressArray: any, arrayLength: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let balanceOfGtokenArray: any = [];
  if (arrayLength === 0 || !owner) {
    return balanceOfGtokenArray;
  }
  try {
    for (let i = 0; i < arrayLength; i++) {
      // console.log("gTokenAddressArray", i ,  gTokenAddressArray[i].address)
      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].token)
      targets.push(gTokenAddressArray[i].token)
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.balanceOf(owner)._method, [
        owner
      ])
      callDatas.push(data)
      ouput_format.push(inst.methods.balanceOf(owner)._method.outputs)
    }
    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()
    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = await do_split(results, arrayLength)

    if (split_arr[0].length > 0) {
      for (let i = 0; i < arrayLength; i++) {
        balanceOfGtokenArray[gTokenAddressArray[i].address] = split_arr[0][i][0]
      }
    }
    return balanceOfGtokenArray;
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const borrowBalanceStored = async (account: string, gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.borrowBalanceStored(account).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const borrowIndex = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.borrowIndex().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const borrowRatePerBlock = async (gTokenAddress: string, blockNumber?: number) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = blockNumber === undefined ? await inst.methods.borrowRatePerBlock().call() :
      await inst.methods.borrowRatePerBlock().call(undefined, blockNumber)
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const borrowRatePerBlockMulticall = async (gTokenAddressArray: any, blockNumber?: number) => {
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let borrowRatePerBlockArr: any = [];
  const assetsLen = Object.keys(gTokenAddressArray).length
  if (assetsLen === 0) {
    return borrowRatePerBlockArr;
  }
  try {

    for (let i = 0; i < assetsLen; i++) {
      // console.log("gTokenAddressArray", i ,  gTokenAddressArray[i].address)
      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].address)
      targets.push(gTokenAddressArray[i].address)
      if (blockNumber === undefined) {
        const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.borrowRatePerBlock()._method, [])
        callDatas.push(data)
        ouput_format.push(inst.methods.borrowRatePerBlock()._method.outputs)
      } else {
        const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.borrowRatePerBlock(undefined, blockNumber)._method, [undefined, blockNumber])
        callDatas.push(data)
        ouput_format.push(inst.methods.borrowRatePerBlock(undefined, blockNumber)._method.outputs)
      }

    }
    const aggregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()
    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = await do_split(results, assetsLen)
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365
    // console.log("results", results)
    // console.log("split_arr", split_arr)
    // console.log("gTokenAddressArray", gTokenAddressArray[0].address)
    if (split_arr[0].length > 0) {
      for (let i = 0; i < assetsLen; i++) {
        const _borrowRatePerBlock = convertToEther(split_arr[0][i][0], 18)
        borrowRatePerBlockArr[gTokenAddressArray[i].address] = (Math.pow(_borrowRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100
      }
    }
    // console.log("borrowRatePerBlockArr", borrowRatePerBlockArr)
    return borrowRatePerBlockArr;
  } catch (error) {
    console.log('error', error)
  }

}

export const borrowRatePerBlockMulticallAlt = async (gTokenAddressArray: any, blockNumber?: number) => {
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let borrowRatePerBlockArr: any = [];
  if (gTokenAddressArray.length === 0) {
    return borrowRatePerBlockArr;
  }
  try {

    for (let i = 0; i < gTokenAddressArray.length; i++) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].address)
      targets.push(gTokenAddressArray[i].address)
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.borrowRatePerBlock()._method, [])
      callDatas.push(data)
      ouput_format.push(inst.methods.borrowRatePerBlock()._method.outputs)
    }
    const aggregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = await do_split(results, gTokenAddressArray.length)
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365
    if (split_arr[0].length > 0) {
      for (let i = 0; i < gTokenAddressArray.length; i++) {
        borrowRatePerBlockArr[gTokenAddressArray[i].address] = convertToEther(split_arr[0][i][0], 18)
      }
    }
    return borrowRatePerBlockArr;
  } catch (error) {
    console.log('error', error)
  }

}

export const gammatroller = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.gammatroller().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const decimals = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.decimals().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const decimalsMulticall = async (gTokenAddressArray: any, arrLen: any) => {
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress);
  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format: any = [];
  let decimalArray: any = [];
  // console.log("address array ",addressArr)
  if (arrLen === 0) {
    return [];
  }
  try {
    for (let i = 0; i < arrLen; i++) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].token)
      targets.push(gTokenAddressArray[i].token);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.decimals()._method, []));
      callDatas.push(data);
      ouput_format.push(inst.methods.decimals()._method.outputs)
    }
    const aggregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    //const decimal = parseFloat(await getUnderlyingDecimal(gTokenAddress)) + 10;

    const split_arr = (await do_split(results, arrLen));
    if (split_arr[0].length > 0) {
      for (let i = 0; i < arrLen; i++) {
        decimalArray[gTokenAddressArray[i].address] = split_arr[0][i][0];

      }
    }
    // console.log("decimalArray data", decimalArray);
    return decimalArray;
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const exchangeRateStored = async (gTokenAddress: string, blockNumber?: number) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = blockNumber === undefined ? await inst.methods.exchangeRateStored().call() :
      await inst.methods.exchangeRateStored().call(undefined, blockNumber)
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const exchangeRateStoredMulticall = async (gTokenAddressArray: any, arrLen: any, blockNumber?: number) => {
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress);
  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format: any = [];
  let gTokenExchangeRateArray: any = [];
  blockNumber = undefined;
  // console.log("address array ",addressArr)
  if (arrLen === 0) {
    return [];
  }
  try {
    for (let i = 0; i < arrLen; i++) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].address)
      targets.push(gTokenAddressArray[i].address);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.exchangeRateStored()._method, []));
      callDatas.push(data);
      ouput_format.push(inst.methods.exchangeRateStored()._method.outputs)
    }
    const aggregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    //const decimal = parseFloat(await getUnderlyingDecimal(gTokenAddress)) + 10;

    const split_arr = (await do_split(results, arrLen));
    if (split_arr[0].length > 0) {
      for (let i = 0; i < arrLen; i++) {
        let exchangerate_decimals = 28;
        // for wormhole assets, exchange rate is different. it is 2e14. So decimals will be 14 + 2(for percent). For normal assets exchange rate is 2e26. So exchange decimals would be 28
        if (gTokenAddressArray[i].address === "0x88FD42E447d39C3259b53623f2536bd855e47C48" || gTokenAddressArray[i].address === "0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4" || gTokenAddressArray[i].address ===  "0xAF7602Fb6A83f04886032467B487101B80ebccC0") {
          exchangerate_decimals = 16;
        }
        gTokenExchangeRateArray[gTokenAddressArray[i].address] = parseFloat(convertToEther(split_arr[0][i][0], exchangerate_decimals));

      }
    }
    // console.log("gTokenExchangeRateArray data", gTokenExchangeRateArray);
    return gTokenExchangeRateArray;
  } catch (error) {
    console.log("error", error)
  }

}

export const exchangeRateStoredMulticallAlt = async (gTokenAddressArray: any, arrLen: any, decimalArr: any) => {
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress);
  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format: any = [];
  let gTokenExchangeRateArray: any = [];
  let blockNumber = undefined;
  // console.log("address array ",addressArr)
  if (arrLen === 0) {
    return [];
  }
  try {
    for (let i = 0; i < arrLen; i++) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].address)
      targets.push(gTokenAddressArray[i].address);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.exchangeRateStored()._method, []));
      callDatas.push(data);
      ouput_format.push(inst.methods.exchangeRateStored()._method.outputs)
    }
    const aggregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = (await do_split(results, arrLen));
    if (split_arr[0].length > 0) {
      for (let i = 0; i < arrLen; i++) {
        gTokenExchangeRateArray[gTokenAddressArray[i].address] = parseFloat(convertToEther(split_arr[0][i][0], decimalArr[gTokenAddressArray[i].address]));

      }
    }
    // console.log("gTokenExchangeRateArray data", gTokenExchangeRateArray);
    return gTokenExchangeRateArray;
  } catch (error) {
    console.log("error", error)
  }

}


export const latestParamsOnAccrueInterest = async (gTokenAddress: string) => {
  try {
    const currentBlockNumber = await wallet.web3.eth.getBlockNumber()
    const accrualBlockNumberPrior = await accrualBlockNumber(gTokenAddress)
    const cashPrior = await getCash(gTokenAddress);
    const borrowsPrior = await totalBorrows(gTokenAddress);
    const reservesPrior = await totalReserves(gTokenAddress);
    const borrowIndexPrior = await borrowIndex(gTokenAddress);
    const interestAdd = await interestRateModel(gTokenAddress);
    const borrowRateMantissa = await getBorrowRate(cashPrior, borrowsPrior, reservesPrior, interestAdd);

    const blockDelta = currentBlockNumber - accrualBlockNumberPrior;
    const simpleInterestFactor = borrowRateMantissa * blockDelta;
    const interestAccumulated = convertToEther(simpleInterestFactor * borrowsPrior, 18);

    const totalBorrowsNew = borrowsPrior + interestAccumulated
    const reserveFactor = await reserveFactorMantissa(gTokenAddress)
    const totalReservesNew = convertToEther(reserveFactor * interestAccumulated, 18) + reservesPrior
    const borrowIndexNew = convertToEther(simpleInterestFactor * borrowIndexPrior, 18) + borrowIndexPrior
    const cashPlusBorrowsMinusReserves = cashPrior + totalBorrowsNew - totalReservesNew
    const _totalSupply = await totalSupply(gTokenAddress);
    const exchangeRate = parseFloat(convertToWei(cashPlusBorrowsMinusReserves, 1e18)) / _totalSupply

    return {
      totalBorrows: totalBorrowsNew,
      totalReserves: totalReservesNew,
      borrowIndex: borrowIndexNew,
      exchangeRateStored: exchangeRate
    }
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getAccountSnapshot = async (account: string, gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.getAccountSnapshot(account).call()
    return data
  } catch (e) {
    return {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
    }
  }
}

export const getCash = async (gTokenAddress: string, blockNumber?: number) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = blockNumber === undefined ? await inst.methods.getCash().call() :
      await inst.methods.getCash(undefined, blockNumber).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getCashMulticall = async (gTokenAddressArray: any, arrLen: number, blockNumber?: number) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let cashArray: any = [];
  if (arrLen === 0) {
    return cashArray;
  }
  try {
    for (let i = 0; i < arrLen; i++) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].address)
      targets.push(gTokenAddressArray[i].address)
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.getCash()._method, [])
      callDatas.push(data)
      ouput_format.push(inst.methods.getCash()._method.outputs)
    }
    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()
    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = await do_split(results, arrLen)
    if (split_arr[0].length > 0) {
      for (let i = 0; i < arrLen; i++) {
        cashArray[gTokenAddressArray[i].address] = split_arr[0][i][0]
      }
    }
    return cashArray;
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const interestRateModel = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.interestRateModel().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const isCToken = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.isCToken().call()
    return data
  } catch (e) {
    console.log(e)
    return false
  }
}

export const name = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.name().call()
    return data
  } catch (e) {
    console.log(e)
    return null
  }
}

export const pendingAdmin = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.pendingAdmin().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const protocolSeizeShareMantissa = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.protocolSeizeShareMantissa().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const reserveFactorMantissa = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.reserveFactorMantissa().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const supplyRatePerBlock = async (gTokenAddress: string, blockNumber?: number) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const supp = await totalSupply(gTokenAddress)
    let data = 0

    if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase() && parseFloat(supp) === 0) {
      data = 0
    }
    else {
      data = blockNumber === undefined ? await inst.methods.supplyRatePerBlock().call() :
        await inst.methods.supplyRatePerBlock().call(undefined, blockNumber)
    }

    return data
  } catch (e) {
    console.log("error =>", gTokenAddress, blockNumber)
    return 0
  }
}

export const supplyRatePerBlockMulticall = async (supplyArray: any, gTokenAddressArray: any, assetLen: number, blockNumber?: number) => {

  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress);
  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format: any = [];
  let supplyRatePerBlock: any = [];
  if (assetLen === 0) {
    return supplyRatePerBlock;
  }
  try {

    for (let i = 0; i < assetLen; i++) {

      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].address)
      targets.push(gTokenAddressArray[i].address);
      if (blockNumber === undefined) {
        const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.supplyRatePerBlock()._method, []));
        callDatas.push(data);
        ouput_format.push(inst.methods.supplyRatePerBlock()._method.outputs)
      } else {
        const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.supplyRatePerBlock(undefined, blockNumber)._method, [undefined, blockNumber]));
        callDatas.push(data);
        ouput_format.push(inst.methods.supplyRatePerBlock(undefined, blockNumber)._method.outputs)
      }

    }

    const aggregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = (await do_split(results, assetLen));
    if (split_arr[0].length > 0) {
      const blocksPerDay = 28800 // 13.15 seconds per block
      const daysPerYear = 365;
      
      for (let i = 0; i < assetLen; i++) {
        let anchor_apy = gTokenAddressArray[i].address.toLowerCase() === "0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4".toLowerCase() ? anchorApy : 0;
        if (gTokenAddressArray[i].address.toLowerCase() === gBnbAddress.toLowerCase() && parseFloat(supplyArray[gTokenAddressArray[i].address]) === 0) {
          supplyRatePerBlock[gTokenAddressArray[i].address] = 0 + anchor_apy
        } else {
          const _supplyRatePerBlock = convertToEther(split_arr[0][i][0], 18);
          
          supplyRatePerBlock[gTokenAddressArray[i].address] = (Math.pow(_supplyRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100 + anchor_apy;
        }
      }
    }

    return supplyRatePerBlock;
  } catch (error) {
    console.log("error in supplyRatePerBlock multicall", error);
    return 0;
  }
}

export const supplyRatePerBlockMulticallRev = async (supplyArray: any, gTokenAddressArray: any, assetLen: number, blockNumber?: number) => {

  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress);
  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format: any = [];
  let supplyRatePerBlock: any = [];
  if (assetLen === 0) {
    return supplyRatePerBlock;
  }
  try {

    for (let i = 0; i < assetLen; i++) {

      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].address)
      targets.push(gTokenAddressArray[i].address);
      if (blockNumber === undefined) {
        const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.supplyRatePerBlock()._method, []));
        callDatas.push(data);
        ouput_format.push(inst.methods.supplyRatePerBlock()._method.outputs)
      } else {
        const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.supplyRatePerBlock(undefined, blockNumber)._method, [undefined, blockNumber]));
        callDatas.push(data);
        ouput_format.push(inst.methods.supplyRatePerBlock(undefined, blockNumber)._method.outputs)
      }

    }

    const aggregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = (await do_split(results, assetLen));
    if (split_arr[0].length > 0) {
      const blocksPerDay = 28800 // 13.15 seconds per block
      const daysPerYear = 365;
      for (let i = 0; i < assetLen; i++) {
        if (gTokenAddressArray[i].address.toLowerCase() === gBnbAddress.toLowerCase() && parseFloat(supplyArray[gTokenAddressArray[i].address]) === 0) {
          supplyRatePerBlock[gTokenAddressArray[i].address] = 0
        } else {
          // const _supplyRatePerBlock = convertToEther(split_arr[0][i][0], 18);
          supplyRatePerBlock[gTokenAddressArray[i].address] = convertToEther(split_arr[0][i][0], 18);//(Math.pow(_supplyRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100;
        }
      }
    }

    return supplyRatePerBlock;
  } catch (error) {
    console.log("error in supplyRatePerBlock multicall", error);
    return 0;
  }
}

export const symbol = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.symbol().call()
    return data
  } catch (e) {
    console.log(e)
    return null
  }
}

export const totalBorrows = async (gTokenAddress: string, blockNumber?: number) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = blockNumber === undefined ? await inst.methods.totalBorrows().call() :
      await inst.methods.totalBorrows().call(undefined, blockNumber)
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const totalBorrowsMulticall = async (gTokenAddressArray: any, blockNumber?: number) => {

  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let totalBorrowsArr: any = []
  blockNumber = undefined
  const assetsLen = Object.keys(gTokenAddressArray).length
  if (assetsLen === 0) {
    return totalBorrowsArr
  }
  try {

    for (let i = 0; i < assetsLen; i++) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].address)
      targets.push(gTokenAddressArray[i].address)
      if(blockNumber !== undefined){
        const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.totalBorrows(undefined, blockNumber)._method, [undefined, blockNumber])
        callDatas.push(data)
        ouput_format.push(inst.methods.totalBorrows(undefined, blockNumber)._method.outputs)
      } else {
        const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.totalBorrows()._method, [])
        callDatas.push(data)
        ouput_format.push(inst.methods.totalBorrows()._method.outputs)
      }
      
    }
    const aggregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = await do_split(results, assetsLen)
    if (split_arr[0].length > 0) {
      for (let i = 0; i < assetsLen; i++) {
        totalBorrowsArr[gTokenAddressArray[i].address] = split_arr[0][i][0];
      }
    }
    // console.log('totalBorrowsArr', totalBorrowsArr)
    return totalBorrowsArr;
  } catch (error) {
    console.log('error', error)
  }
}

export const totalReserves = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.totalReserves().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const totalSupply = async (gTokenAddress: string, blockNumber?: number) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = blockNumber === undefined ? await inst.methods.totalSupply().call() :
      await inst.methods.totalSupply().call(undefined, blockNumber)
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const totalSupplyMulticall = async (gTokenAddressArray: any, tokenLength: number, etherBase?: number, blockNumber?: number) => {

  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress);
  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format: any = [];
  let totalSupplyArray: any = [];
  blockNumber = undefined;
  // console.log("address array ",addressArr)
  if (tokenLength === 0) {
    return totalSupplyArray;
  }
  try {
    for (let i = 0; i < tokenLength; i++) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].address)
      targets.push(gTokenAddressArray[i].address);
      if (blockNumber !== undefined) {
        const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.totalSupply(undefined, blockNumber)._method, [undefined, blockNumber]));
        callDatas.push(data);
        ouput_format.push(inst.methods.totalSupply(undefined, blockNumber)._method.outputs)
      } else {
        const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.totalSupply()._method, []));
        callDatas.push(data);
        ouput_format.push(inst.methods.totalSupply()._method.outputs)
      }


    }
    const aggregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = (await do_split(results, tokenLength));
    if (split_arr[0].length > 0) {
      for (let i = 0; i < tokenLength; i++) {
        totalSupplyArray[gTokenAddressArray[i].address] = split_arr[0][i][0];
      }
    }
    // console.log("totalSupplyArray",totalSupplyArray)
    return totalSupplyArray;

  } catch (error) {
    console.log("error", error)
  }
}



export const underlying = async (gTokenAddress: string) => {
  try {
    if (gTokenAddress.toLowerCase() === (pfTokenList[0].address).toLowerCase() || gTokenAddress.toLowerCase() === "0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29".toLowerCase()) {
      return bnbAddress
    }
    else {
      const inst: any = await selectInstance(instType.gToken, gTokenAddress)
      const data = await inst.methods.underlying().call()
      return data
    }
  } catch (e) {
    console.log("error in", gTokenAddress, e)
    return zeroAddress
  }
}

export const underlyingMulticall = async (gTokenAddressArray: any) => {
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let underlyingArray: any = [];
  const assetsLen = Object.keys(pfTokenList).length
  if (assetsLen === 0) {
    return underlyingArray;
  }
  // console.log("assetsLen", assetsLen)
  try {
    for (let i = 0; i < assetsLen; i++) {
      if (i !== 0) {
        const inst: any = await selectInstance(instType.gToken, gTokenAddressArray[i].address)
        targets.push(gTokenAddressArray[i].address)
        const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.underlying()._method, [])
        callDatas.push(data)
        ouput_format.push(inst.methods.underlying()._method.outputs)
      }

    }

    const aggregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()
    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }
    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = await do_split(results, assetsLen - 1)
    // console.log("split_arr", split_arr[0])
    if (split_arr[0].length > 0) {
      for (let i = 0; i < assetsLen - 1; i++) {
        // if(gTokenAddressArray[i].address.toLowerCase() === (pfTokenList[0].address).toLowerCase()){
        //   underlyingArray[gTokenAddressArray[i].address] = bnbAddress;
        // } else {
        //   underlyingArray[gTokenAddressArray[i].address] = split_arr[0][i][0]
        // }
        // console.log("underlyingArray", i,  split_arr[0][i])
        underlyingArray[gTokenAddressArray[i + 1].address] = split_arr[0][i][0]
      }
      underlyingArray['0x24664791B015659fcb71aB2c9C0d56996462082F'] = bnbAddress;
    }
    // console.log("underlyingArray", underlyingArray)
    return underlyingArray;
  } catch (error) {
    console.log(error)
    return underlyingArray;
  }
}

export const getUnderlyingDecimalMulticall = async (gTokenAddressArray: any, underlyingDecimalArray: any, totalBorrowsArray: any, userDiscountLevel: any, supplyRatePerBlockArray: any, _borrowRatePerBlockArr: any, userAddress?: string) => {

  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let underlyingDecimalArr: any = [];
  const assetsLen = Object.keys(pfTokenList).length
  if (assetsLen === 0) {
    return underlyingDecimalArr;
  }

  try {
    for (let i = 0; i < assetsLen; i++) {
      // console.log("underlyingDecimalArray data",gTokenAddressArray[i].address,  underlyingDecimalArray[gTokenAddressArray[i].address] )
      const inst: any = await selectInstance(instType.gBNB, underlyingDecimalArray[gTokenAddressArray[i].address])
      targets.push(underlyingDecimalArray[gTokenAddressArray[i].address])
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.decimals()._method, [])
      callDatas.push(data)
      ouput_format.push(inst.methods.decimals()._method.outputs)

    }
    const aggregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = await do_split(results, assetsLen)
    if (split_arr[0].length > 0) {
      const levelData = userDiscountLevel;
      for (let i = 0; i < assetsLen; i++) {
        let borrowApy = _borrowRatePerBlockArr[gTokenAddressArray[i].address];
        const totalBorrows_ = convertToEther(totalBorrowsArray[gTokenAddressArray[i].address], split_arr[0][i][0])
        if (userAddress !== null && userAddress !== '' && gTokenAddressArray[i].address.toLowerCase() !== gGamma.toLowerCase() && totalBorrows_ > 0) {
          const discount = levelData.discount / 2
          const diff = _borrowRatePerBlockArr[gTokenAddressArray[i].address] - supplyRatePerBlockArray[gTokenAddressArray[i].address];
          borrowApy -= (discount * diff) / 100;
          //await differenceApy(gTokenAddress)
        }
        underlyingDecimalArr[gTokenAddressArray[i].address] = borrowApy * -1;
      }
    }
    // console.log("underlyingDecimalArray", underlyingDecimalArr)
    return underlyingDecimalArr;
  } catch (error) {
    console.log(error)
  }
}

export const getUnderlyingDecimalMulticallAlt = async (gTokenAddressArray: any, underlyingDecimalArray?: any) => {

  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let underlyingDecimalArr: any = [];
  const assetsLen = Object.keys(pfTokenList).length
  if (assetsLen === 0) {
    return underlyingDecimalArr;
  }

  try {
    for (let i = 0; i < assetsLen; i++) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].token)
      targets.push(gTokenAddressArray[i].token)
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.decimals()._method, [])
      callDatas.push(data)
      ouput_format.push(inst.methods.decimals()._method.outputs)

    }
    const aggregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = await do_split(results, assetsLen)
    if (split_arr[0].length > 0) {
      for (let i = 0; i < assetsLen; i++) {
        underlyingDecimalArr[gTokenAddressArray[i].address] = split_arr[0][i][0];
      }
    }
    // console.log("underlyingDecimalArray", underlyingDecimalArr)
    return underlyingDecimalArr;
  } catch (error) {
    console.log(error)
  }
}

/********************************************************************************************************************************/

export const mint = async (gTokenAddress: string, userAddress: string, amountInEth: string) => {
  try {
    if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase()) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
      const data = await inst.methods.mint().send({
        from: userAddress,
        value: convertToWei(amountInEth, 1e18)
      })
      return data;
    }
    else {
      const inst: any = await selectInstance(instType.gToken, gTokenAddress)
      const data = await inst.methods.mint(convertToWei(amountInEth, 1e18)).send({
        from: userAddress,
      })
      return data;
    }
  } catch (e) {
    console.log(e)
    return {};
  }
}

export const redeemUnderlying = async (gTokenAddress: string, userAddress: string, amountInEth: string) => {
  try {
    const inst: any = await selectInstance(instType.gToken, gTokenAddress)
    const data = await inst.methods.redeemUnderlying(convertToWei(amountInEth, 1e18)).send({
      from: userAddress,
    })
    return data;
  } catch (e) {
    console.log(e)
    return {};
  }
}

export const borrow = async (gTokenAddress: string, userAddress: string, amountInEth: string) => {
  try {
    const inst: any = await selectInstance(instType.gToken, gTokenAddress)
    const data = await inst.methods.borrow(convertToWei(amountInEth, 1e18)).send({
      from: userAddress,
    })
    return data;
  } catch (e) {
    console.log(e)
    return {};
  }
}

export const repayBorrow = async (gTokenAddress: string, userAddress: string, amountInEth: string) => {
  try {
    if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase()) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
      const data = await inst.methods.repayBorrow().send({
        from: userAddress,
        value: convertToWei(amountInEth, 1e18)
      })
      return data;
    }
    else {
      const inst: any = await selectInstance(instType.gToken, gTokenAddress)
      const data = await inst.methods.repayBorrow(convertToWei(amountInEth, 1e18)).send({
        from: userAddress,
      })
      return data
    }
  } catch (e) {
    console.log(e)
    return {};
  }
}

export const liquidateBorrow = async (gTokenAddress: string,
  liquidator: string,
  borrower: string,
  gTokenCollateral: string,
  repayAmountInEth: string) => {
  try {
    if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase()) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
      const data = await inst.methods.liquidateBorrow(borrower, gTokenCollateral).send({
        from: liquidator,
        value: convertToWei(repayAmountInEth, 1e18)
      })
      return data;
    }
    else {
      const inst: any = await selectInstance(instType.gToken, gTokenAddress)
      const _token = await underlying(gTokenAddress)
      const tokenInstance = await selectInstance(instType.gToken, _token)
      const allowanceVal = convertToEther(await tokenInstance.methods.allowance(liquidator, gTokenAddress).call(), 18)
     
      // console.log("decimals in gTokenAddress", pfTokenListRevised[gTokenAddress], gTokenAddress)
      let decimals = Math.pow(10, pfTokenListRevised[gTokenAddress].decimals)
      // repayAmountInEth = convertToWei(repayAmountInEth, decimals)
      // console.log("tokenApprove in else ",_token, gTokenAddress, liquidator, repayAmountInEth, allowanceVal)
      if (parseFloat(repayAmountInEth) > parseFloat(allowanceVal)) {
        await tokenApprove(_token, gTokenAddress, liquidator)
      }
      /*const data = await inst.methods.liquidateBorrow(borrower, convertToWei(repayAmountInEth, await getUnderlyingDecimal(gTokenAddress)), gTokenCollateral).send({
        from: liquidator,
      })
      */
     let decimalsVal = await getUnderlyingDecimal(gTokenAddress);
    //  console.log("before transaction", decimalsVal)
      const data = await inst.methods.liquidateBorrow(borrower, convertToWei(repayAmountInEth, decimals), gTokenCollateral).send({
        from: liquidator
      })
      // console.log("liquidateBorrow in else",data)
      return data

    }
  } catch (e) {
    console.log(e)
    return {};
  }
}



export function findLargest3(arr: number[]) {
  var scoreByPattern = arr
  var maxIndex = new Array();
  var maxPoints = new Array();
  var i;
  maxPoints[0] = 0;
  maxPoints[1] = 0;
  maxPoints[2] = 0;

  for (i = 0; i < scoreByPattern.length; i++) {
    if (scoreByPattern[i] > maxPoints[0]) {
      maxPoints[0] = scoreByPattern[i];
      maxIndex[0] = i;
    }
  }

  for (i = 0; i < scoreByPattern.length; i++) {
    if (scoreByPattern[i] > maxPoints[1] && scoreByPattern[i] < maxPoints[0]) {
      maxPoints[1] = scoreByPattern[i];
      maxIndex[1] = i;
    }
  }

  for (i = 0; i < scoreByPattern.length; i++) {
    if (scoreByPattern[i] > maxPoints[2] && scoreByPattern[i] < maxPoints[1]) {
      maxPoints[2] = scoreByPattern[i];
      maxIndex[2] = i;
    }
  }
  return maxIndex;
  //alert(maxIndex);
}



