import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserLendingData, updateUserAssetNew } from 'logic/action/lending.action'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont } from 'shared/styles/styled'
import { HAModalCont, HAMDataCont, HAModalTitle, TokenInfoBox, HATokenIcon, TokenValTxt, ModalAPYTxt, TvlTxt, SwitchCont, DepositSwitch, WithdrawSwitch, ModalCard, ModalCardTitle, DataInfoCard, InfoHeaderCont, DataInfoTitle, DataInfoCont, DataInfoTxt, LoadingText3, LoadingText4, RepaySwitch } from 'shared/styles/highestApyStyle'
import { ModalInputCont, ModalInput, MaxBox, MaxBoxZero, UserBalTxt } from 'shared/styles/highestApyStyle'
import { CardBtnGroup, CardBtn, TransformBtn, CardRedBtn, CardGreenBtn, RepayCardBtn } from 'shared/styles/highestApyStyle'
import CloseIcon from 'assets/icons/white-cross.svg'
import DownIcon from 'assets/icons/down.svg'
import { getTokenIcon } from 'shared/tokenIconList'
import { floatNumRegex, } from 'shared/helpers/regexConstants'
import { getTokenName, commaFy, convertToKAndM, checkZeroVal, getNumFormat } from 'service/globalFunctions'
import { transLinkUrl, fraxTranLink, maticTransLink, dotTransLink, solTransLink, adaTransLink, lunaTransLink, austTransLink, avaxTransLink, atomTransLink, xrpTransLink, linkTransUrl, ftmTransLink, whustTransferLink } from 'service/global-constant'
import Spinner from 'shared/spinner'
import { isAllowanceApproved } from 'modules/block-chain/BlockChainMethods'
import { getBorrowLimitParamsOnChange, getParamsWhenBorrowLimitIsChanged, checkErrors} from 'modules/block-chain-green/LendingBase';
import { gBnbAddress, gBNBxAddress, convertToWei, instType, selectInstance, MAX_INT } from 'modules/block-chain/lendingAbi'
import { maxAmount, maximillionAddress, noExponents, anchor_apy } from 'modules/block-chain-green/lendingAbi'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { getUnderlyingDecimal } from 'modules/block-chain/LendingBase'
import BoostVaultAction from '../../vaults/components/vault-modal/BoostVaultAction'
import {
    ModalCont,
    ModalHeader,
    CloseModalImg,
    InputCont,
    InputField,
    MaxTxt,
    InputValText,
    FlexCont,
    DataInputCont,
    InfoText,
    DataSwitchCont,
    SwitchText,
    ValueText,
    ActionBtn,
    ActionBtn2,
    InfoSmallText,
    SelectAssetWrapper,
    SelectAssetCont,
    SelectAssetText,
    VLDivider,
    SliderContainer,
    ErrorText,
    ValueTextWhite,
    ApyText,
    SliderContainerNew,
    TableSwitchCont
} from './style'
import { ToolTipIcon, ToolTipText, ToolTipDataCont} from 'shared/styles/styled'
import ReactTooltip from "react-tooltip";
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css' 
import {
    CardBoldText, 
    CardText,
    VerticalCardDivider,
} from "../style";
import { checkNegative } from 'service/globalFunctions'
// import QuestionIcon from 'assets/icons/question-icon.svg'
import QuestionIcon from 'assets/icons/question_icon.png'
import { inputNumRegex } from 'shared/helpers/regexConstants'
import ToolTipModal2 from 'shared/tooltip-modal/Tooltip2'
import { enterMarkets, exitMarkets } from 'modules/block-chain-green/gammatroller'

const AssetBorrowRevised = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('borrow')
    const [suppAmt, setSuppAmt] = useState('')
    const [suppVal, setSuppVal] = useState('')
    const [depositAmount, setDepositAmount] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [depositLoading, setDepositLoading] = useState(false)
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [isAllowance, setIsAllowance] = useState(false)
    const [showVaultInfo, setShowVaultInfo] = useState(false)
    const [showFeeInfo, setShowFeeInfo] = useState(false)
    const [showAPYCal, setShowAPyCal] = useState(false)
    const [borrowLimit, setBorrowLimit] = useState<any>(0)
    const [maxBorrow, setMaxBorrow] = useState<any>(0)
    const [assetType, setAssetType] = useState('token')
    const [switchStatus, setSwitchStatus] = useState('borrow')
    const [isError, setIsError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [errorLiquidationText, setErrorLiquidationText] = useState('')
    const [maxSelected, setMAxSelected] = useState(false)
    const [borrowLoading, setBorrowLoading] = useState(false)
    const [repayLoading, setRepayLoading] = useState(false)
    const [enableLoading, setEnableLoading] = useState(false)
    const [availToBorrow, setAvailToBorrow] = useState<any>(0)
    const [transactionLink, setTransactionLink] = useState('')
    const [showTransModal, setShowTransModal] = useState(false)
    const { portfolioLoading, poolData } = selector.user
    const { gPlatformDataNew, userAssetData } = selector.lending
    const { toggleVaultModal, data, setModalData, currentTab } = props
    const [showToolTip, setShowTooltipModal] = useState(false)
    const [toolTipTitle, setToolTipTitle] = useState('')
    const [toolTipMessage, setToolTipMessage] = useState('')
    const [maxRepayAmount, setMaxRepayAmount] = useState(0);
    const gPlatformData = gPlatformDataNew
    let bnbxData: any = {};
    useEffect(() => {
        const dataArr = poolData.newVaults;
        // console.log("vaults data", dataArr)
        let vault_details = dataArr.active.filter((e: any) => e.address === data.address)
        // console.log("vault_details", vault_details)
        let market_details = userAssetData.marketData.filter((e: any) => e.address === data.address)
        // console.log("market_details", market_details)
        let revised_data = data;
        data.price = data.price !== undefined ? data.price : data.underlyingPrice;
        data.walletBalance = data.walletBalance !== undefined && data.walletBalance > 0 ? data.walletBalance : data.walletBal;
        data.walletBalanceUSD = data.walletBalanceUSD !== undefined && data.walletBalanceUSD > 0 ? data.walletBalanceUSD : data.walletBalInUsd;
        // console.log("data props", data)
        if(vault_details.length > 0){
            let borrowTokenObject = Object.assign( vault_details[0], revised_data);
            borrowTokenObject = Object.assign( borrowTokenObject, market_details[0]);
            // console.log("borrowTokenObject", borrowTokenObject)
            setModalData(vault_details[0])
            setBorrowLimit(borrowTokenObject.borrowLimitUsedCurr)
            setMaxBorrow(borrowTokenObject.borrowLimitInUsd)
        } else {
            if(data.borrowLimitUsedCurr !== undefined){
                setBorrowLimit(data.borrowLimitUsedCurr)
            }
        }
        setAvailToBorrow(gPlatformData.remainingBorrowBalUsd)

        let finalAmountVal: any = 0;
        let finalAmt: any = data.currentlyBorrowing;
        if (parseFloat(finalAmt) >= parseFloat(data.walletBalance)) {
            finalAmt = finalAmt//data.walletBal
        }
        if(data.address.toLowerCase() === gBnbAddress.toLowerCase()) {
            finalAmountVal = data.walletBalance > finalAmt + 0.01 ? getNumFormat(finalAmt) : getNumFormat(data.walletBalance - 0.01);
        } else {
            finalAmountVal = parseFloat(finalAmt) >= parseFloat(data.walletBalance) ? getNumFormat(data.walletBalance) :getNumFormat(finalAmt)
        }
        // console.log("max repay amount", finalAmountVal,finalAmt, data)
        setMaxRepayAmount(finalAmountVal)

    }, [poolData, data, setModalData, currentTab, userAssetData])


    useEffect(() => {
        const userAddress = selector.ethData.address
        const getIsAllowance = async () => {
            
            if (data.name === 'BNB' || data.name === 'WBNB') {
                setIsAllowance(true)
            }
            else {
                const wantAddress = data.address? data.address :  data.wantAddress;

                if(typeof wantAddress == undefined)
                {
                    setIsAllowance(false)
                    return 1;
                }
                let farmAddress = ""

                try {
                    let isAllow = await isAllowanceApproved(userAddress, farmAddress, wantAddress)
                    if(+data.gTokenAllowance == +MAX_INT || +data.allowance > 0){
                        isAllow = true;
                    }
                    // console.log("allowance value", isAllow, data)
                    setIsAllowance(isAllow)
                }
                catch (error) {
                    console.log("error=>", error)
                }
            }
        }
        if (!!data && !data.isBoostPool) {
            getIsAllowance()
        }

    }, [selector.ethData.address, data])

    const handleDepositAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setDepositAmount(value)
        }
        if (!value) {
            setDepositAmount('')
        }
    }
    const handleWithdrawAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setWithdrawAmount(value)
        }
        if (!value) {
            setWithdrawAmount('')
        }
    }

    const handleNotification = (type: string, actionType: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const price = data.price !== undefined ? +data.price : +data.underlyingPrice;
        const amt: any = (+suppAmt).toString();//actionType === 'Deposit' ? depositAmount : withdrawAmount
        const dataVal: any = {
            type: type,
            message: `${actionType}  ${parseFloat(amt).toFixed(4)} ${data.name}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleDepositAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(depositAmount) > 0) {
            try {
                setDepositLoading(true)
                const amountInEth = depositAmount
                const userAddress = selector.ethData.address
                const gTokenAddress = data.address
                if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase()) {
                    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
                    await inst.methods.mint().send({
                        from: userAddress,
                        value: convertToWei(amountInEth, 1e18)
                    })
                        .once('confirmation', function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            handleNotification(type, 'Deposit', receipt.transactionHash)
                            setDepositLoading(false)
                            dispatch(updateUserLendingData(selector.ethData.address))
                        })
                        .on('error', function (error: any) {
                            handleNotification('failed', 'Deposit')
                            setDepositLoading(false)
                        })
                }
                else {
                    const inst: any = await selectInstance(instType.gToken, gTokenAddress)
                    const decimal = Math.pow(10, await getUnderlyingDecimal(gTokenAddress));
                    await inst.methods.mint(convertToWei(amountInEth, decimal)).send({
                        from: userAddress,
                    })
                        .once('confirmation', function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            handleNotification(type, 'Deposit', receipt.transactionHash)
                            setDepositLoading(false)
                            dispatch(updateUserLendingData(selector.ethData.address))
                        })
                        .on('error', function (error: any) {
                            handleNotification('failed', 'Deposit')
                            setDepositLoading(false)
                        })
                }
            }
            catch (error) {
                console.log("error=>", error)
                setDepositLoading(false)
            }
            finally {
                setDepositLoading(false)
            }

        }
    }
    const handleWithdrawAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(withdrawAmount) > 0) {
            try {
                setWithdrawLoading(true)
                const amountInEth = withdrawAmount
                const userAddress = selector.ethData.address
                const gTokenAddress = data.address
                const inst: any = await selectInstance(instType.gToken, gTokenAddress)
                const decimal = Math.pow(10, await getUnderlyingDecimal(gTokenAddress));
                await inst.methods.redeemUnderlying(convertToWei(amountInEth, decimal)).send({
                    from: userAddress,
                })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        handleNotification(type, 'Withdraw', receipt.transactionHash)
                        setWithdrawLoading(false)
                        dispatch(updateUserLendingData(selector.ethData.address))
                    })
                    .on('error', function (error: any) {
                        handleNotification('failed', 'Withdraw')
                        setWithdrawLoading(false)
                    })
            }
            catch (error) {
                console.log("error=>", error)
                setWithdrawLoading(false)
            }
            finally {
                setWithdrawLoading(false)
            }
        }
    }

    const approveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} approved` : `Failed to approve  ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }
    const handleApprove = async () => {
        try {
            setApproveLoading(true)
            const underlyingAddress = data.underlyingAddress
            const gTokenAddress = data.address
            const userAddress = selector.ethData.address
            const TOKEN_INSTANCE = await selectInstance(instType.PANCAKELP, underlyingAddress)
            await TOKEN_INSTANCE.methods
                .approve(gTokenAddress, MAX_INT)
                .send({ from: userAddress })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    approveNotification(type, receipt.transactionHash)
                    setApproveLoading(false)
                    setIsAllowance(true)
                })
                .on('error', function (error: any) {
                    approveNotification('failed')
                    setApproveLoading(false)
                })
        }
        catch (error) {
            console.log("error==>", error)
            setApproveLoading(false)
        }
        finally {
            setApproveLoading(false)
        }
    }

    const getSuppVal = (val: any) => {
        let finalVal: any = 0
        if (!!val) {
            if (assetType === 'token') {
                finalVal = parseFloat(val) * parseFloat(data.underlyingPrice)
            }
            else {
                if (parseFloat(data.underlyingPrice) > 0) {
                    finalVal = parseFloat(val) / parseFloat(data.underlyingPrice)
                }
            }
        }
        setSuppVal(finalVal)
    }

    const handleInputError = async (inputAmount: any) => {
        const userAddress = selector.ethData.address
        const type = switchStatus === 'borrow' ? 3 : 4
        if (assetType === 'usd') {
            inputAmount = parseFloat(inputAmount) / parseFloat(data.underlyingPrice)
        } else {
            inputAmount = parseFloat(inputAmount)
        }

        try {
            const errorRes: any = await checkErrors(data, userAddress, type, inputAmount)
            // console.log("input error check", errorRes, inputAmount, type, data)
            if (!!errorRes && typeof errorRes !== 'undefined') {
                if (!errorRes.proceed) {
                    setIsError(true)
                    // setErrorText(errorRes.reason)
                    setErrorLiquidationText(errorRes.reason);
                }
                else {
                    setIsError(false)
                    setErrorText("")
                    setErrorLiquidationText("");
                }
            }
        }
        catch (error) { }
    }

    const handleBuyToken = () => {
        history.push(`${Paths.swap}`, { tokenAdd: data.underlyingAddress.toLowerCase() })
    }

    const renderTokenTransform = () => {
        if (data.name === 'FRAX') {
            return <a href={fraxTranLink} target='_blank' rel="noreferrer"><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'MATIC') {
            return <a href={maticTransLink} target='_blank' rel="noreferrer"><TransformBtn >{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'DOT') {
            return <a href={dotTransLink} target='_blank' rel="noreferrer"><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'SOL') {
            return <a href={solTransLink} target='_blank' rel="noreferrer"><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'ADA') {
            return <a href={adaTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'LUNA') {
            return <a href={lunaTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'aUST') {
            return <a href={austTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'AVAX') {
            return <a href={avaxTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'ATOM') {
            return <a href={atomTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'XRP') {
            return <a href={xrpTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'LINK') {
            return <a href={linkTransUrl} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'FTM') {
            return <a href={ftmTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'whUST') {
            return <a href={whustTransferLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else {
            return <TransformBtn onClick={() => handleBuyToken()}>{`Buy  ${getTokenName(data.name)}`}</TransformBtn>
        }
    }

    const getInfoNumFormat = (val: any, borrowLimit?: boolean) => {
        const newVal: any = parseFloat(val).toFixed(2).toString().split('.')
        if(borrowLimit === true)
            return <span>${commaFy(newVal[0])}.<span>{newVal[1]}</span></span>;
        else
            return <CardBoldText className='borrow-popup-mob'>${commaFy(newVal[0])}.<span>{newVal[1]}</span></CardBoldText>;
    }

    const handleBorrowLimitChange = async (val: any) => {
        try {
            if (!!data) {
                const type = switchStatus === 'borrow' ? 3 : 4
                if (type === 3) {
                    const currVal = val >= data.borrowLimitUsedCurr ? 0.90 * val : data.borrowLimitUsedCurr
                    setBorrowLimit(currVal)
                    const percentage = currVal / 100
                    const userAddress = selector.ethData.address
                    const res: any = await getParamsWhenBorrowLimitIsChanged(data.isUsingAsCollateral, data.address, type, gPlatformData.borrowLimitInUsd, gPlatformData.userTotalBorrowBal, percentage, userAddress)
                    if(percentage > 0.751 && percentage < 0.9){
                        setIsError(false)
                        if(errorLiquidationText == ""){
                            setErrorLiquidationText("Beware of Liquidation Risk");
                        }
                    } 
                    else if(percentage > 0.9 && percentage < 1.0001){
                        setIsError(true)
                        if(errorLiquidationText == ""){
                            setErrorLiquidationText("");
                        }
                    } 
                    else if(percentage > 1){
                        setIsError(true)
                        if(errorLiquidationText == ""){
                            setErrorLiquidationText("Borrow Limit Exceeded");
                        }
                    }
                    else {
                        setIsError(false)
                        setErrorLiquidationText("");
                    }
                    if (!!res && typeof res !== 'undefined') {
                        res.amountGivenInUsd = res.amountGivenInUsd > 0.001 ? res.amountGivenInUsd : 0.00;
                        const finalAmountVal = getNumFormat(res.amountGivenInUsd/data.underlyingPrice); //getNumFormat(res.amountGivenInEth)
                        setSuppAmt(finalAmountVal)
                        getSuppVal(finalAmountVal)
                        await handleInputError(finalAmountVal)
                    }
                }
                if (type === 4) {
                    const currVal = val <= data.borrowLimitUsedCurr ? val : data.borrowLimitUsedCurr
                    setBorrowLimit(currVal)
                    const percentage = currVal / 100
                    const userAddress = selector.ethData.address
                    const res: any = await getParamsWhenBorrowLimitIsChanged(data.isUsingAsCollateral, data.address, type, gPlatformData.borrowLimitInUsd, gPlatformData.userTotalBorrowBal, percentage, userAddress)
                    if (!!res && typeof res !== 'undefined') {
                        res.amountGivenInUsd = res.amountGivenInUsd > 0.001 ? res.amountGivenInUsd : 0.00;
                        setMaxBorrow(res.borrowLimit)
                        const finalAmountVal = getNumFormat(res.amountGivenInUsd)
                        setSuppAmt(finalAmountVal)
                        getSuppVal(finalAmountVal)
                        await handleInputError(finalAmountVal)
                    }
                }
            }

        }
        catch (err) {

        }
    }

    const handleMax = async () => {
        // console.log("user obj in max function", data)
        if (switchStatus === 'borrow') {
            let maxBorrow: any = 0
            let availToBorrow = gPlatformData.remainingBorrowBalUsd < data.availableUsd ?
                gPlatformData.remainingBorrowBalUsd : data.availableUsd

            availToBorrow = parseFloat(gPlatformData.borrowLimitInUsd) * 0.9 - parseFloat(gPlatformData.userTotalBorrowBal)
            // console.log("availToBorrow",availToBorrow, gPlatformData, data);
            if (!!gPlatformData && availToBorrow) {
                maxBorrow = parseFloat(availToBorrow) / parseFloat(data.underlyingPrice)
            }

            let finalAmt: any = maxBorrow
            if (assetType === 'usd') {
                finalAmt = (+data.borrowLimitInUsd *(1 - +data.borrowLimitUsedCurr * 0.01)) * 0.75
            } else {
                finalAmt = data.borrowLimitUsedCurr < 90 ? (+data.borrowLimitInUsd *(0.9 - +data.borrowLimitUsedCurr * 0.01)) / parseFloat(data.underlyingPrice) : 0
            }
            // let finalAmt: any = (+data.borrowLimitInUsd *(1 - +data.borrowLimitUsedCurr * 0.01)) * 0.75
            // finalAmt = finalAmt > +data.borrowLimitInUsd ? noExponents(data.borrowLimitInUsd) : noExponents(finalAmt)
            finalAmt = finalAmt > +data.availableUsd ? noExponents(data.availableUsd/data.underlyingPrice) : noExponents(finalAmt)
            const finalAmountVal = finalAmt > availToBorrow ? getNumFormat(availToBorrow) : getNumFormat(finalAmt)
            // console.log("finalAmountVal in borrow", finalAmountVal, data.borrowLimitInUsd * 0.75, +finalAmountVal * +data.underlyingPrice , +data.currentlyBorrowingUsd, data)
            if(data.borrowLimitInUsd * 0.75 < (finalAmountVal * data.underlyingPrice + data.borrowLimitUsedCurr * data.borrowLimitInUsd * 0.01)){
                setErrorLiquidationText("Beware of liquidation risk!")
            } else {
                setErrorLiquidationText("");
            }
            setSuppAmt(finalAmountVal)
            getSuppVal(finalAmountVal)
            handleBorrowLimit(finalAmountVal)
            setIsError(false)
            await handleInputError(finalAmountVal)
        }
        else {
            // console.log("repay data",data)
            const val = data.currentlyBorrowing
            let finalAmt: any = val //Math.pow(2, 256) - 1; //
            if (parseFloat(finalAmt) >= parseFloat(data.walletBalance)) {
                finalAmt = finalAmt//data.walletBal
            }
            else {
                setMAxSelected(true)
            }
            if (assetType === 'usd') {
                finalAmt = parseFloat(finalAmt) * parseFloat(data.underlyingPrice)
            } else {
                finalAmt = parseFloat(finalAmt);
            }
            finalAmt = noExponents(finalAmt)
            let finalAmountVal: any = 0;
            if(data.address.toLowerCase() === gBnbAddress.toLowerCase()) {
                finalAmountVal = data.walletBalance > finalAmt + 0.01 ? getNumFormat(finalAmt) : getNumFormat(data.walletBalance - 0.01);
            } else {
                finalAmountVal = parseFloat(finalAmt) >= parseFloat(data.walletBalance) ? getNumFormat(data.walletBalance) :getNumFormat(finalAmt)
            }

            // const finalAmountVal = finalAmt > data.walletBalance ? getNumFormat(data.walletBalance) :getNumFormat(finalAmt)
            // console.log("finalAmountVal", finalAmountVal, data.walletBalanceUSD)
            if(+data.allowance >= +finalAmountVal || data.name === 'BNB' || data.name === 'WBNB'){
                setIsAllowance(true)
            } else {
                setIsAllowance(false)
            }
            setSuppAmt(finalAmountVal)
            getSuppVal(finalAmountVal)
            handleBorrowLimit(finalAmountVal)
            await handleInputError(finalAmountVal)
        }
    }

    const handleMaxOriginal = async () => {
        if (switchStatus === 'borrow') {
            let maxBorrow: any = 0

            let availToBorrow = gPlatformData.remainingBorrowBalUsd < data.availableUsd ?
                gPlatformData.remainingBorrowBalUsd : data.availableUsd

            availToBorrow = parseFloat(gPlatformData.borrowLimitInUsd) * 0.9 - parseFloat(gPlatformData.userTotalBorrowBal)

            if (!!gPlatformData && availToBorrow) {
                maxBorrow = parseFloat(availToBorrow) / parseFloat(data.underlyingPrice)
            }

            let finalAmt: any = maxBorrow
            if (assetType === 'usd') {
                finalAmt = parseFloat(finalAmt) * parseFloat(data.underlyingPrice)
            } else {
                finalAmt = parseFloat(finalAmt)
            }
            finalAmt = noExponents(finalAmt)
            const finalAmountVal = getNumFormat(finalAmt)
            setSuppAmt(finalAmountVal)
            getSuppVal(finalAmountVal)
            handleBorrowLimit(finalAmountVal)
            await handleInputError(finalAmountVal)
        }
        else {

            const val = data.currentlyBorrowing
            let finalAmt: any = val
            if (parseFloat(finalAmt) >= parseFloat(data.walletBal)) {
                finalAmt = data.walletBal
            }
            else {
                setMAxSelected(true)
            }
            if (assetType === 'usd') {
                finalAmt = parseFloat(finalAmt) * parseFloat(data.underlyingPrice)
            } else {
                finalAmt = parseFloat(finalAmt)
            }
            finalAmt = noExponents(finalAmt)
            const finalAmountVal = getNumFormat(finalAmt)
            setSuppAmt(finalAmountVal)
            getSuppVal(finalAmountVal)
            handleBorrowLimit(finalAmountVal)
            await handleInputError(finalAmountVal)
        }
    }

    const getFinalAmount = (amountValue: any) => {
        let finalAmount: any = !!amountValue ? amountValue : 0
        if (assetType === 'usd') {
            if (parseFloat(data.underlyingPrice) > 0) {
                finalAmount = parseFloat(finalAmount) / parseFloat(data.underlyingPrice)
            }
            else {
                finalAmount = 0
            }
        } else {
            finalAmount = parseFloat(finalAmount) 
        }
        return finalAmount
    }

    const handleBorrowLimit = async (amount: any) => {
        try {
            const amountVal: any = getFinalAmount(amount)
            // console.log("final amount", amountVal)
            const type = switchStatus === 'borrow' ? 3 : 4
            // console.log("handleBorrowLimit details",data, gPlatformData, data.isUsingAsCollateral, data.address, type, parseFloat(amountVal), gPlatformData.borrowLimitInUsd, gPlatformData.userTotalBorrowBal,)
            const res: any = await getBorrowLimitParamsOnChange(data.isUsingAsCollateral, data.address, type, parseFloat(amountVal), gPlatformData.borrowLimitInUsd, gPlatformData.userTotalBorrowBal,)
            // console.log("res inside handleBorrowLimit", res)
            if (!!res && typeof res !== 'undefined') {
                setBorrowLimit(res.usedBorrowLimitInPercentage)
                setMaxBorrow(res.borrowLimit)
            }
        }
        catch (error) { }
    }

    const handleSwitchStatus = (switchVal: string) => {
        setSuppAmt('')
        setSwitchStatus(switchVal)
        setActiveTab(switchVal)
        setBorrowLimit(data.borrowLimitUsedCurr)
        setMaxBorrow(data.borrowLimitInUsd)
        setAvailToBorrow(gPlatformData.remainingBorrowBalUsd)
        setIsError(false)
        setErrorLiquidationText("")
    }

    const handleSuppAmount = async (e: any) => {
        const { value } = e.target
        const dataVal = value.replace(/\$|,|/g, "");
        const numVal = dataVal.toString()
        // console.log("input value", numVal)
        setMAxSelected(false)
        if(numVal == ""){
            setErrorLiquidationText("");
            setIsError(false)
        }
        if (switchStatus === 'borrow'){
            
            let availToBorrow = gPlatformData.remainingBorrowBalUsd < data.availableUsd ?
                gPlatformData.remainingBorrowBalUsd : data.availableUsd

            availToBorrow = parseFloat(gPlatformData.borrowLimitInUsd) * 0.75 - parseFloat(gPlatformData.userTotalBorrowBal)
            let maxAvailableToBorrow = parseFloat(gPlatformData.borrowLimitInUsd) * 0.9 - parseFloat(gPlatformData.userTotalBorrowBal)
            let totalBorrowLimit = parseFloat(gPlatformData.borrowLimitInUsd) - parseFloat(gPlatformData.userTotalBorrowBal)
            // console.log("input value for borrow", numVal, availToBorrow, maxAvailableToBorrow, totalBorrowLimit)
            setErrorLiquidationText("");
            setIsError(false)
            if(!!gPlatformData && +availToBorrow && (+numVal*data.underlyingPrice + 0.000001)> +availToBorrow){
                // console.log("borrowing more than 75%")
                setIsError(false)
                if(errorLiquidationText == ""){
                    setErrorLiquidationText("Beware of Liquidation Risk");
                }
                if(!!gPlatformData && +maxAvailableToBorrow && +numVal *data.underlyingPrice > +maxAvailableToBorrow){
                    setIsError(true)
                    if(!!gPlatformData && +totalBorrowLimit && +numVal *data.underlyingPrice > +totalBorrowLimit){
                        setErrorLiquidationText("Borrow Limit Exceeded");
                    } else {
                        setErrorLiquidationText("Beware of Liquidation Risk");
                    }
                } else {
                    setErrorLiquidationText("Beware of Liquidation Risk");
                    setIsError(false)
                }
                
            }
        } else{
            // console.log("borrowing less than 75%")
            setErrorLiquidationText("");
            setIsError(false)
        }
        /*
            setIsError(true)
            setErrorText(errorRes.reason)
            if (switchStatus === 'borrow') {
            let maxBorrow: any = 0

            let availToBorrow = gPlatformData.remainingBorrowBalUsd < data.availableUsd ?
                gPlatformData.remainingBorrowBalUsd : data.availableUsd

            availToBorrow = parseFloat(gPlatformData.borrowLimitInUsd) * 0.7 - parseFloat(gPlatformData.userTotalBorrowBal)

            if (!!gPlatformData && availToBorrow) {
                maxBorrow = parseFloat(availToBorrow) / parseFloat(data.underlyingPrice)
            }

            let finalAmt: any = maxBorrow
            if (assetType === 'usd') {
                finalAmt = parseFloat(finalAmt) * parseFloat(data.underlyingPrice)
            }
            finalAmt = noExponents(finalAmt)
            const finalAmountVal = getNumFormat(finalAmt)
            setSuppAmt(finalAmountVal)
            getSuppVal(finalAmountVal)
            handleBorrowLimit(finalAmountVal)
            await handleInputError(finalAmountVal)
        }
        */
        if (inputNumRegex.test(numVal.toString())) {
            setSuppAmt(numVal)
            getSuppVal(numVal)
            handleBorrowLimit(numVal)
            await handleInputError(numVal)
            if(+data.allowance >= +numVal || data.name === 'BNB' || data.name === 'WBNB'){
                setIsAllowance(true)
            } else {
                setIsAllowance(false)
            }
        }
        if (!numVal) {
            setSuppAmt('')
            setSuppVal('')
            setIsError(false)
            setBorrowLimit(data.borrowLimitUsedCurr)
            setMaxBorrow(data.borrowLimitInUsd)
            setAvailToBorrow(gPlatformData.remainingBorrowBalUsd)
        }

        if(errorText !== "") {
            setErrorLiquidationText("");
        }
    }

    const handleRepay = async () => {
        try {
            setRepayLoading(true)
            let amountInEth = getFinalAmount(suppAmt)
            const gTokenAddress = data.address
            const userAddress = selector.ethData.address
            // console.log("amount in eth", amountInEth, data, maxSelected)
            if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase()) {
                if (maxSelected) {
                    // console.log("amount in eth", amountInEth, data)
                    //borrow balance + $2/1bnb
                    amountInEth = (+data.borrowBalance + 2/+data.price)
                    const inst: any = await selectInstance(instType.maxiMillion, maximillionAddress)
                    await inst.methods.repayBehalfExplicit(userAddress, gBnbAddress).send({
                        from: userAddress,
                        value: convertToWei(amountInEth * 1.00, 1e18)
                    })
                        .once('transactionHash', function (res: any) {
                            if (res && typeof res !== 'undefined') {
                                const transLink = `${transLinkUrl}/${res}`
                                setTransactionLink(transLink)
                                setShowTransModal(true)
                            }
                        })
                        .once('confirmation', function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            handleNotification(type, 'Repay', receipt.transactionHash)
                            setRepayLoading(false)
                            dispatch(updateUserLendingData(selector.ethData.address))
                            dispatch(updateUserBalance(selector.ethData.address))
                        })
                        .on('error', function (error: any) {
                            handleNotification('failed', 'Repay')
                            setShowTransModal(false)
                            setRepayLoading(false)
                        })
                }
                else {
                    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
                    await inst.methods.repayBorrow().send({
                        from: userAddress,
                        value: convertToWei(amountInEth, 1e18)
                    })
                        .once('transactionHash', function (res: any) {
                            if (res && typeof res !== 'undefined') {
                                const transLink = `${transLinkUrl}/${res}`
                                setTransactionLink(transLink)
                                setShowTransModal(true)
                            }
                        })
                        .once('confirmation', function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            handleNotification(type, 'Repay', receipt.transactionHash)
                            setRepayLoading(false)
                            dispatch(updateUserLendingData(selector.ethData.address))
                            dispatch(updateUserBalance(selector.ethData.address))
                        })
                        .on('error', function (error: any) {
                            handleNotification('failed', 'Repay')
                            setShowTransModal(false)
                            setRepayLoading(false)
                        })
                }
            }
            else {

                const inst: any = await selectInstance(instType.gToken, gTokenAddress)
                let amount: any = 0

                if (maxSelected) {
                    amount = "115792089237316195423570985008687907853269984665640564039457584007913129639935"//Math.pow(2, 256) - 1;//maxAmount;
                    // amount = amount.toString()
                    // console.log("max amount", amount)
                }
                else {
                    const decimal = Math.pow(10, await getUnderlyingDecimal(gTokenAddress));
                    amount = convertToWei(amountInEth, decimal);
                }

                // console.log("repay amount", amount)
                await inst.methods.repayBorrow(amount).send({
                    from: userAddress,
                })
                    .once('transactionHash', function (res: any) {
                        if (res && typeof res !== 'undefined') {
                            const transLink = `${transLinkUrl}/${res}`
                            setTransactionLink(transLink)
                            setShowTransModal(true)
                        }
                    })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        handleNotification(type, 'Repay', receipt.transactionHash)
                        setRepayLoading(false)
                        dispatch(updateUserLendingData(selector.ethData.address))
                        dispatch(updateUserBalance(selector.ethData.address))
                    })
                    .on('error', function (error: any) {
                        handleNotification('failed', 'Repay')
                        setShowTransModal(false)
                        setRepayLoading(false)
                    })
            }

        }
        catch (error) {
            console.log("error==>", error)
            setRepayLoading(false)
        }
        finally {
            setSuppAmt('')
            setSuppVal('')
            setShowTransModal(false)
            setRepayLoading(false)
            setMAxSelected(false)
            dispatch(updateUserBalance(selector.ethData.address))
        }
    }

    const handleBorrow = async () => {
        try {
            setBorrowLoading(true)
            const amountInEth = getFinalAmount(suppAmt)
            const gTokenAddress = data.address
            const userAddress = selector.ethData.address
            const inst: any = await selectInstance(instType.gToken, gTokenAddress)
            const decimal = Math.pow(10, await getUnderlyingDecimal(gTokenAddress));
            await inst.methods.borrow(convertToWei(amountInEth, decimal)).send({
                from: userAddress,
            })
                .once('transactionHash', function (res: any) {
                    if (res && typeof res !== 'undefined') {
                        const transLink = `${transLinkUrl}/${res}`
                        setTransactionLink(transLink)
                        setShowTransModal(true)
                    }
                })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    handleNotification(type, 'Borrowed', receipt.transactionHash)
                    setBorrowLoading(false)
                    dispatch(updateUserLendingData(selector.ethData.address))
                    dispatch(updateUserBalance(selector.ethData.address))
                })
                .on('error', function (error: any) {
                    handleNotification('failed', 'Borrowed')
                    setShowTransModal(false)
                    setBorrowLoading(false)
                })
        }
        catch (error) {
            console.log("error==>", error)
            setBorrowLoading(false)
        }
        finally {
            setSuppAmt('')
            setSuppVal('')
            setShowTransModal(false)
            setBorrowLoading(false)
            dispatch(updateUserBalance(selector.ethData.address))
        }
    }

    const handleEnableNotification = (type: string, hash?: any,) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} enabled` : `Failed to enable ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleEnable = async () => {
        try {
            setEnableLoading(true)
            const underlyingAddress = data.underlyingAddress
            const gTokenAddress = data.address
            const userAddress = selector.ethData.address
            const TOKEN_INSTANCE = await selectInstance(instType.PANCAKELP, underlyingAddress)
            await TOKEN_INSTANCE.methods
                .approve(gTokenAddress, MAX_INT)
                .send({ from: userAddress })
                .once('transactionHash', function (res: any) {
                    if (res && typeof res !== 'undefined') {
                        // console.log(res)
                        const transLink = `${transLinkUrl}/${res}`
                        setTransactionLink(transLink)
                        setShowTransModal(true)
                    }
                })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    handleEnableNotification(type, receipt.transactionHash)
                    setEnableLoading(false)
                    setIsAllowance(true)
                    dispatch(updateUserAssetNew(selector.ethData.address))
                })
                .on('error', function (error: any) {
                    handleEnableNotification('failed')
                    setShowTransModal(false)
                    setEnableLoading(false)
                })
        }
        catch (error) {
            console.log("error==>", error)
            setEnableLoading(false)
        }
        finally {
            setShowTransModal(false)
            setEnableLoading(false)
        }
    }

    const showToolTipModal = async(title: any, message: any) =>{
        // console.log("inside showToolTip function")
        //hide tool tip inside pop up
        setShowTooltipModal(!showToolTip);
        setToolTipTitle(title);
        setToolTipMessage(message)
    }

    return (
        <HAModalCont>
            <FlexSBCont>
                <HAModalTitle>{getTokenName(data.name)}{data.isBoostPool ? data.strategyAddress === "0x0709e1356F765a6F88632B539E209a5d6864B765" ? "(PCS) OLD":" (PCS)" : ''}</HAModalTitle>
                <img className='close-icon' src={CloseIcon} alt="" onClick={() => toggleVaultModal()} />
            </FlexSBCont>
            <HAMDataCont>
                <TokenInfoBox>
                    <HATokenIcon src={data.name !== undefined ? getTokenIcon(data.name.replace(' (OLD)', '')): ""} />
                    {
                        getInfoNumFormat(!!data && data.currentlyBorrowingUsd ? data.currentlyBorrowingUsd : 0)
                    }
    {/*<TokenValTxt isValZero={checkZeroVal(data.borrowBalance)}>{`$${!!data && data.borrowBalance ? commaFy(parseFloat(data.vaultBalanceUSD).toFixed(2)) : '0.00'}`}</TokenValTxt>*/}
                    
                    <ModalAPYTxt className='borrowedTxt'>Borrowed</ModalAPYTxt>
                    <div className='mobCapsuleCont'>
                    <ApyText className='apyCapsule mobPopup' isNegative={checkNegative(data.totalBorrowApy)}>{!!data.totalBorrowApy ? commaFy(convertToKAndM(data.totalBorrowApy)) : '0.00'}% APY</ApyText>
                    <ApyText className='apyCapsule mobPopupTVL' isNegative={checkNegative(data.tvl)}>${!!data.tvl ? commaFy(convertToKAndM(data.tvl)) : '0.00'} TVL</ApyText>
                    {/* {data.userBoostApy && (!isFinite(data.userBoostApy) || data.userBoostApy > 10000) ? "> 10,000" : data.userBoostApy ? commaFy(data.userBoostApy.toFixed(2)) : "0.00"} totalBorrowApy*/}
                    </div>
                    {/* <ModalAPYTxt>{commaFy(convertToKAndM(data.totalApy))}% APY</ModalAPYTxt>
                    <TvlTxt>{`$${!!data.tvl ? commaFy(convertToKAndM(data.tvl)) : '0.00'} TVL`}</TvlTxt> */}
                </TokenInfoBox>
                <SwitchCont className={!data.isBoostPool ? 'without-boost' : ''}>
                    <DepositSwitch isActive={activeTab === 'borrow'} switchStatus={switchStatus === 'borrow'} onClick={() => handleSwitchStatus('borrow')}>Borrow</DepositSwitch>
                    <RepaySwitch isActive={activeTab === 'repay'}  switchStatus={switchStatus === 'repay'} onClick={() => handleSwitchStatus('repay')}>Repay</RepaySwitch>
                </SwitchCont>
                
                    <>
                        {activeTab === 'borrow' ?
                            <ModalCard>
                                <FlexSBCont>
                                    <ModalCardTitle>Borrow Limit</ModalCardTitle>
                                    
                                    <UserBalTxt>{`${commaFy(parseFloat((+data.borrowLimitInUsd/+data.price).toString()).toFixed(4))} ($${commaFy(parseFloat(data.borrowLimitInUsd).toFixed(2))})`}</UserBalTxt>
                                    
                                </FlexSBCont>
                                <ModalInputCont>
                                    <ModalInput placeholder="0" onChange={handleSuppAmount} value={suppAmt && `${assetType === 'token' ? `${suppAmt}` : suppAmt}`} />
                                    
                                    <MaxBox onClick={() => handleMax()}>MAX</MaxBox>
                                        {/* :
                                        <MaxBoxZero onClick={() => setDepositAmount(getNumFormat(data.walletBalance))}>MAX</MaxBoxZero>
                                    } */}
                                </ModalInputCont>
                                    {/* {renderTokenTransform()} */}
                                    {/* {
                                        isAllowance ?
                                            <CardBtn onClick={() => handleDepositAmount()} disabled={depositLoading}>{depositLoading ? <Spinner /> : 'Borrow'}</CardBtn> :
                                            <CardGreenBtn onClick={() => handleApprove()} disabled={approveLoading}>{approveLoading ? <Spinner /> : 'Approve'}</CardGreenBtn>
                                    } */}
                                    {!!errorLiquidationText && <ErrorText>{errorLiquidationText}</ErrorText>}
                                    <CardBtn onClick={() => handleBorrow()} disabled={data.borrowPaused || borrowLoading || !suppAmt || parseFloat(suppAmt) <= 0 || !selector.ethData.address || isError}>
                                        {borrowLoading ? <Spinner /> : 'Borrow'}
                                    </CardBtn>
                            </ModalCard> :
                            <ModalCard>
                                <FlexSBCont>
                                    <ModalCardTitle>In Wallet</ModalCardTitle>
                                    {portfolioLoading ? <LoadingText3 className='gamma-reward' /> :
                                        <UserBalTxt>{`${commaFy(parseFloat((+data.walletBalance).toString()).toFixed(4))} ($${commaFy(parseFloat(data.walletBalanceUSD).toFixed(2))})`}</UserBalTxt>
                                    }
                                </FlexSBCont>
                                <ModalInputCont>
                                    <ModalInput placeholder="0" onChange={handleSuppAmount} value={suppAmt && `${assetType === 'token' ? `${suppAmt}` : suppAmt}`} />
                                    
                                        <MaxBox onClick={() => handleMax()}>MAX</MaxBox>
                                       
                                    
                                </ModalInputCont>
                                {!!errorLiquidationText && <ErrorText>{errorLiquidationText}</ErrorText>}
                                {isAllowance || +maxRepayAmount > +data.walletBalance ?
                                <RepayCardBtn onClick={() => handleRepay()} disabled={repayLoading || !suppAmt || parseFloat(suppAmt) <= 0 || !selector.ethData.address || isError || +suppAmt > +data.walletBalance}>
                                    {repayLoading ? <Spinner /> : 'Repay'}
                                </RepayCardBtn >:
                                <RepayCardBtn  onClick={() => handleEnable()} disabled={enableLoading || !suppAmt || parseFloat(suppAmt) <= 0 || !selector.ethData.address}>
                                    {enableLoading ? <Spinner /> : 'Approve'}
                                </RepayCardBtn>}
                            </ModalCard>
                        }
                    </>
                
                <DataInfoCard className='popUpSlider'>
                    <FlexSBCont>
                        
                        { borrowLimit ? <CardBoldText className="borrowPercent">{parseFloat(borrowLimit).toFixed(2)}%</CardBoldText> : <CardText>0.00%</CardText>}
                        <div className='borrowLimit'><CardText className="finalLimit">100%</CardText></div>
                    </FlexSBCont>
                    <SliderContainerNew borrowLimit={borrowLimit} className={+borrowLimit > 75 && +borrowLimit < 90 ? 'SliderContainer OrangeLimit': +borrowLimit > 90 ? 'SliderContainer RedLimit' : 'SliderContainer'}>
                        {/* <CardText className="borrowLimitPopup">70%</CardText>
                        <VerticalCardDivider className="borrowLimitPopup"></VerticalCardDivider> */}
                        <Slider step={0.01} min={0} max={100} value={borrowLimit} tooltip={false} onChange={handleBorrowLimitChange} />
                    </SliderContainerNew>
                    <FlexSBCont>
                        <FlexCont>
                            <CardText className="borrowUsage">Borrow Usage</CardText>
                        </FlexCont>
                        <FlexCont className="limitPopUp" onClick={() => showToolTipModal("Borrow Limit", "The maximum dollar amount you can borrow against your collateral. If this were to hit $0.00 you would be subject to liquidation with a penalty up to 10% of your collateral.")} >
                            <CardText>Limit: ${commaFy((data.borrowLimitInUsd).toFixed(2))}</CardText>
                            <ToolTipIcon className="tooltip-mob" src={QuestionIcon} alt="" data-for="mAvailableCreditInfo" left={5} />
                        </FlexCont>
                    </FlexSBCont>
                </DataInfoCard>
                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowAPyCal(!showAPYCal)} isActive={showAPYCal}>
                        <DataInfoTitle>APR Calculations</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showAPYCal &&
                        <DataInfoCont>
                            
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>GAMMA APR</DataInfoTxt>
                                <DataInfoTxt>{parseFloat(data.borrowDistributionApr).toFixed(2)}%</DataInfoTxt>
                            </FlexSBCont>
                            {data.isBoostPool ?
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Parent Farm APY</DataInfoTxt>
                                    <DataInfoTxt>{parseFloat(data.parentFarmApy).toFixed(2)}%</DataInfoTxt>
                                </FlexSBCont> :
                                <>
                                    {data.name === 'AQUA' ?
                                        <FlexSBCont className='bottom-space'>
                                            <DataInfoTxt light>Burn APY</DataInfoTxt>
                                            <DataInfoTxt>{parseFloat(data.burnApy).toFixed(2)}%</DataInfoTxt>
                                        </FlexSBCont> :
                                        <>
                                            {data.name !== 'GAMMA' &&
                                                <FlexSBCont className='bottom-space'>
                                                    <DataInfoTxt light>Borrow APR</DataInfoTxt>
                                                    <DataInfoTxt>{parseFloat(data.borrowApr).toFixed(2)}%</DataInfoTxt>
                                                </FlexSBCont>}
                                        </>
                                    }
                                </>
                            }
                            <FlexSBCont>
                                <DataInfoTxt light>Total APR</DataInfoTxt>
                                <DataInfoTxt>{parseFloat(data.totalBorrowApr).toFixed(2)}%</DataInfoTxt>
                            </FlexSBCont>
                        </DataInfoCont>
                    }
                </DataInfoCard>

                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowVaultInfo(!showVaultInfo)} isActive={showVaultInfo}>
                        <DataInfoTitle>Vault Details</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showVaultInfo &&
                        <DataInfoCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>{`${data.name} Price`}</DataInfoTxt>
                                <DataInfoTxt>${commaFy(parseFloat(data.price).toFixed(2))}</DataInfoTxt>
                            </FlexSBCont>
                            {data.protocol === "Planet" ?
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Origin</DataInfoTxt>
                                    <DataInfoTxt>Green Planet</DataInfoTxt>
                                </FlexSBCont>
                                :
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Origin</DataInfoTxt>
                                    <DataInfoTxt>PCS</DataInfoTxt>
                                </FlexSBCont>
                            }
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Contract</DataInfoTxt>
                                <a href={data.farmContract} target="_blank" rel="noreferrer">
                                    <DataInfoTxt>View</DataInfoTxt>
                                </a>
                            </FlexSBCont>
                            {data.protocol === "PCS" ?
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Vault Contract</DataInfoTxt>
                                    <a href={data.vaultContract} target="_blank" rel="noreferrer">
                                        <DataInfoTxt>View</DataInfoTxt>
                                    </a>
                                </FlexSBCont>
                                :
                                ""
                            }
                            {data.protocol === "PCS" ?
                                <FlexSBCont>
                                    <DataInfoTxt light>Vault Multiplier</DataInfoTxt>
                                    <DataInfoTxt>{data.vaultMultiplier}X</DataInfoTxt>
                                </FlexSBCont>
                                :
                                ""
                            }
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Boost Percentage</DataInfoTxt>
                                <DataInfoTxt>{data.gammBoostPercentage ? commaFy(parseFloat(data.gammBoostPercentage).toFixed(2)): 0}%</DataInfoTxt>
                            </FlexSBCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Average Boost APY</DataInfoTxt>
                                <DataInfoTxt>{data.avgBoostApy ? commaFy(parseFloat(data.avgBoostApy).toFixed(2)): 0}%</DataInfoTxt>
                            </FlexSBCont>
                        </DataInfoCont>
                    }
                </DataInfoCard>

                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowFeeInfo(!showFeeInfo)} isActive={showFeeInfo}>
                        <DataInfoTitle>Fees</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showFeeInfo &&
                        <DataInfoCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Deposit Fee</DataInfoTxt>
                                <DataInfoTxt>{data.depositFee}%</DataInfoTxt>
                            </FlexSBCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Withdraw Fee</DataInfoTxt>
                                <DataInfoTxt>{data.withdrawalFees}%</DataInfoTxt>
                            </FlexSBCont>
                            {data.protocol === "PCS" ?
                                <FlexSBCont>
                                    <DataInfoTxt light>Performance Fee</DataInfoTxt>
                                    <DataInfoTxt>{data.burnFees}%</DataInfoTxt>
                                </FlexSBCont>
                                :
                                ""
                            }
                        </DataInfoCont>
                    }
                </DataInfoCard>
            </HAMDataCont>
            <ToolTipModal2 
                showToolTip={showToolTip}
                toggleToolTipModal={setShowTooltipModal}
                title={toolTipTitle}
                message={toolTipMessage} 
            />
            <ReactTooltip className="planet-tool-tip" id="mGammaBalanceInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>The total GAMMA you have in your wallet, supplied to markets and in liquidity pools.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mGammaEarnedInfo" place="right" effect="solid" multiline={true}>
                <ToolTipText>This is your uncollected GAMMA earned from supplying & borrowing assets. You can press collect to claim your GAMMA.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mTotalMarketSizeInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>The total value of all assets supplied.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mSupplyBalanceInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>This is the total value of assets you’ve supplied to the market.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mBorrowBalanceInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>This is the total value of assets you’ve borrowed from the market.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mNetApyInfo" place="right" effect="solid" multiline={true}>
                <ToolTipText>Your total estimated annualized interest rate, of all assets supplied and borrowed at the current market rates.
                    (Estimated Annualized Earnings/Supply Balance) * 100</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mBorrowLimitInfo" place="right" effect="solid" multiline={true}>
                <ToolTipText>The maximum value as a % that you can borrow against your collateral. If this were to hit 100% you
                    would be subject to liquidation with a penalty up to 10% of your collateral.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mAvailableCreditInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>The maximum dollar amount you can borrow against your collateral. If this were to hit $0.00 you
                    would be subject to liquidation with a penalty up to 10% of your collateral.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mCurrentLevelInfo" place="right" effect="solid" multiline={true} >
                <ToolTipDataCont>
                    <ToolTipText>There are 3 discount levels that can be reached by supplying GAMMA.</ToolTipText>
                    <ul>
                        <li>Level 1: 5% off protocol fees</li>
                        <li>Level 2: 20% off protocol fees</li>
                        <li>Level 3: 50% off protocol fees</li>
                    </ul>
                </ToolTipDataCont>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mStackRatioInfo" place="right" effect="solid" multiline={true} >
                <ToolTipDataCont>
                    <ToolTipText>This refers to the USD value of your GAMMA supplied, as a percentage, relative to the USD value of
                        assets you’re supplying. This is how your Discount Level is calculated.</ToolTipText>
                    <ul>
                        <li>Level 1: (1-4.99%)</li>
                        <li>Level 2: (5-9.99%)</li>
                        <li>Level 3: (Greater than 10%)</li>
                    </ul>
                </ToolTipDataCont>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mDailyEarning" place="top" effect="solid" multiline={true}>
                <ToolTipText>This is an estimated amount that you will earn over the next 24 hours at the current numbers.</ToolTipText>
            </ReactTooltip>
        </HAModalCont>
    );
};

export default AssetBorrowRevised