import { useState, useEffect } from 'react'
import { FlexCont, FlexSBCont, LoadingText2 } from 'shared/styles/styled'
import { CardText, CardLText, CardBText } from '../style'
import { TokenStatsCont, BackBtnCont, BackText, StatsInfoCont, StatsInfo, RateText, AssetCard, SwitchCont, SwitchBox, SwitchText, AssetCardCont, AssetSliderCont, AssetDetails, InfoDivider, GraphCont, GraphChartCont } from './style'
import GammaIcon from 'assets/icons/gamma-icon.png'
import { marketDataApi } from 'service/global-constant'
import { commaFy, convertNumFormat } from 'service/globalFunctions'
import { colors } from 'shared/styles/theme'
import PlanetGraph from './PlanetGraph'
// import { getTokenIcon } from 'shared/tokenIconList'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import axios from 'axios'
import { getIndividualMarketData } from 'modules/block-chain-green/LendingBase'

const AssetStats = (props: any) => {
    const [activeSwitch, setActiveSwitch] = useState('supply')
    const [dataLoading, setDataLoading] = useState(false)
    const [data, setData] = useState<any>({})
    const [graphData, setGraphData] = useState<any>()
    const [supplyData, setSupplyData] = useState<any>([])
    const [borrowData, setBorrowData] = useState<any>([])
    const { setShowIndAsset, tokenData, individualMarketDataArr } = props

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const getGraphData = async () => {
            try {
                const res = await axios.get(`${marketDataApi}/${tokenData.address}`)
                if (!!res && typeof res !== 'undefined' && res.data.length > 0) {
                    const suppData: any = []
                    const borData: any = []
                    const finalData = res.data.reverse()
                    for (const item of finalData) {
                        const sup = {
                            date: item.date,
                            supplyApy: item.total_supply_apy.supplyApy ? (parseFloat(item.total_supply_apy.supplyApy) / 1000) + 1 : (parseFloat(item.total_supply_apy) / 1000) + 1,
                            totalSupply: item.total_supply_usd
                        }
                        const bor = {
                            date: item.date,
                            borrowApy: (parseFloat(item.total_borrow_apy) / 1000) + 1,
                            totalBorrow: item.total_borrows_usd
                        }
                        suppData.push(sup)
                        borData.push(bor)
                    }
                    setSupplyData(suppData)
                    setBorrowData(borData)
                    setGraphData(res.data)
                }
            }
            catch (err) { }
        }
        getGraphData()

    }, [tokenData.address])

    useEffect(() => {
        const getIndAssetData = async () => {
            try {
                setDataLoading(true)
                const res = individualMarketDataArr[tokenData.address.toLowerCase()]//await getIndividualMarketData(tokenData.address)
                if (!!res && typeof res !== 'undefined') {
                    setData(res)
                }
                setData(res)
            }
            catch (error) { }
            finally {
                setDataLoading(false)
            }
        }
        getIndAssetData()
    }, [tokenData])

    const getPercentageVal = (val: any) => {
        const value = parseFloat(val)
        let percentageVal = "0.00%"
        if (value > 0) {
            percentageVal = `+${value.toFixed(2)}%`
        }
        if (value < 0) {
            percentageVal = `${value.toFixed(2)}%`
        }
        return percentageVal

    }

    return (
        <TokenStatsCont>
            <BackBtnCont>
                <BackText onClick={() => setShowIndAsset(false)}>{`< Back to markets`}</BackText>
            </BackBtnCont>
            <StatsInfoCont>
                <FlexCont>
                    <img className="asset-icon" src={tokenData.icon} alt="" />
                    <CardBText>{tokenData.name}</CardBText>
                </FlexCont>
                <StatsInfo>
                    <div>
                        <CardLText>Net Rate</CardLText>
                        {dataLoading ? <LoadingText2 className="loading-text" width={100} /> :
                            <>
                                {activeSwitch === 'supply' ?
                                    <RateText className="info-space" isNegative={parseFloat(data.netSupplyRate) < 0}>{!!data && data.netSupplyRate ? getPercentageVal(data.netSupplyRate) : '0.00%'}</RateText> :
                                    <RateText className="info-space" isNegative={parseFloat(data.netBorrowRate) < 0}>{!!data && data.netBorrowRate ? getPercentageVal(data.netBorrowRate) : '0.00%'}</RateText>
                                }
                            </>
                        }


                    </div>
                    <div>
                        <FlexCont>
                            <img className=" info-icon" src={tokenData.icon} alt="" />
                            <CardLText>{activeSwitch === "supply" ? 'Supply APY' : 'Borrow APY'}</CardLText>
                        </FlexCont>
                        {dataLoading ? <LoadingText2 className="loading-text" width={100} /> :
                            <>
                                {activeSwitch === 'supply' ?
                                    <CardBText className="info-space">{!!data && data.supplyApy ? parseFloat(data.supplyApy).toFixed(2) : '0.00'}%</CardBText> :
                                    <CardBText className="info-space">{!!data && data.borrowApy ? parseFloat(data.borrowApy).toFixed(2) : '0.00'}%</CardBText>
                                }
                            </>
                        }


                    </div>
                    <div>
                        <FlexCont>
                            <img className=" info-icon" src={GammaIcon} alt="" />
                            <CardLText>GAMMA APY</CardLText>
                        </FlexCont>
                        {dataLoading ? <LoadingText2 className="loading-text" width={100} /> :
                            <>
                                {activeSwitch === 'supply' ?
                                    <CardBText className="info-space">{!!data && data.supplyDistApy ? parseFloat(data.supplyDistApy).toFixed(2) : '0.00'}%</CardBText> :
                                    <CardBText className="info-space">{!!data && data.borrowDistApy ? parseFloat(data.borrowDistApy).toFixed(2) : '0.00'}%</CardBText>
                                }
                            </>
                        }

                    </div>
                    <div>
                        <CardLText>{activeSwitch === 'supply' ? 'Total Supply' : 'Total Borrow'}</CardLText>
                        {dataLoading ? <LoadingText2 className="loading-text" width={100} /> :
                            <>
                                {activeSwitch === 'supply' ?
                                    <CardBText className="info-space">${!!data && data.totalSupplyInUSd ? commaFy(convertNumFormat(data.totalSupplyInUSd)) : '0.00'}</CardBText> :
                                    <CardBText className="info-space">${!!data && data.totalBorrowInUSd ? commaFy(convertNumFormat(data.totalBorrowInUSd)) : '0.00'}</CardBText>
                                }
                            </>
                        }
                    </div>
                </StatsInfo>
            </StatsInfoCont>
            <GraphCont>
                <SwitchCont>
                    <SwitchBox isActive={activeSwitch === 'supply'} onClick={() => setActiveSwitch('supply')}>
                        <SwitchText isActive={activeSwitch === 'supply'}>Supply</SwitchText>
                    </SwitchBox>
                    <SwitchBox isActive={activeSwitch === 'borrow'} onClick={() => setActiveSwitch('borrow')}>
                        <SwitchText isActive={activeSwitch === 'borrow'}>Borrow</SwitchText>
                    </SwitchBox>
                    <SwitchBox className="on-web" />
                </SwitchCont>
                <GraphChartCont>
                    {!!graphData && graphData.length > 0 &&
                        <PlanetGraph activeSwitch={activeSwitch} data={activeSwitch === 'supply' ? supplyData : borrowData} hoverColor={activeSwitch === "supply" ? colors.lightGreen : colors.green} />
                    }

                </GraphChartCont>

            </GraphCont>
            <AssetCardCont>
                <AssetCard>
                    <CardBText>Interest Rate Model</CardBText>
                    <FlexSBCont className="rate-info">
                        <CardLText>Utilization Rate</CardLText>
                        <CardText>{!!data && data.util ? parseFloat(data.util).toFixed(2) : '0.00'}%</CardText>
                    </FlexSBCont>
                    <AssetSliderCont>
                        <Slider min={0} max={100} value={!!data && data.util ? parseFloat(data.util) : 0} tooltip={false} />
                    </AssetSliderCont>
                </AssetCard>
                <AssetCard>
                    <CardBText>Market Details</CardBText>
                    <AssetDetails>
                        <FlexSBCont>
                            <CardLText>Price</CardLText>
                            {dataLoading ? <LoadingText2 width={20} /> :
                                <CardText>${!!data && data.underlyingPrice ? commaFy(parseFloat(data.underlyingPrice).toFixed(2)) : '0.00'}</CardText>
                            }

                        </FlexSBCont>
                        <InfoDivider />
                        <FlexSBCont>
                            <CardLText>Market Liquidity</CardLText>
                            {dataLoading ? <LoadingText2 width={20} /> :
                                <CardText>{`${!!data && data.marketLiquidity ? commaFy(parseFloat(data.marketLiquidity).toFixed(2)) : '0.00'} ${data.underlyingSymbol}`}</CardText>
                            }

                        </FlexSBCont>
                        <InfoDivider />
                        {/* <FlexSBCont>
                            <CardLText># of Suppliers</CardLText>
                            {dataLoading ? <LoadingText2 width={20} /> :
                                <CardText>{!!data && data.noOfSuppliers ? commaFy(data.noOfSuppliers) : 0}</CardText>
                            }

                        </FlexSBCont>
                        <InfoDivider />
                        <FlexSBCont>
                            <CardLText># of Borrowers</CardLText>
                            {dataLoading ? <LoadingText2 width={20} /> :
                                <CardText>{!!data && data.noOfBorrowers ? commaFy(data.noOfBorrowers) : 0}</CardText>
                            }

                        </FlexSBCont> */}
                        {/* <InfoDivider /> */}
                        {/* <FlexSBCont>
                            <CardLText>Daily Interest</CardLText>
                            {dataLoading ? <LoadingText2 width={20} /> :
                                <CardText>${!!data && data.dailyInterestPerDay ? commaFy(parseFloat(data.dailyInterestPerDay).toFixed(2)) : "0.00"}</CardText>
                            }

                        </FlexSBCont> */}
                        {/* <InfoDivider /> */}
                        <FlexSBCont>
                            <CardLText>Available</CardLText>
                            {dataLoading ? <LoadingText2 width={20} /> :
                                <CardText>{`${!!data && data.availableToBorrow ? commaFy(parseFloat(data.availableToBorrow).toFixed(2)) : '0.00'} ${data.underlyingSymbol}`}</CardText>
                            }

                        </FlexSBCont>
                        <InfoDivider />
                        <FlexSBCont>
                            <CardLText>Collateral Factor</CardLText>
                            {dataLoading ? <LoadingText2 width={20} /> :
                                <CardText>{!!data && data.collateralFactor ? parseFloat(data.collateralFactor) * 100 : 0}%</CardText>
                            }

                        </FlexSBCont>
                        <InfoDivider />
                        <FlexSBCont>
                            <CardLText>{`${!!data && data.gTokenSymbol ? data.gTokenSymbol : ''} Minted`}</CardLText>
                            {dataLoading ? <LoadingText2 width={20} /> :
                                <CardText>{`${!!data && data.marketTokenMinted ? commaFy(parseFloat(data.marketTokenMinted).toFixed(2)) : '0.00'} ${data.gTokenSymbol}`}</CardText>
                            }
                        </FlexSBCont>
                        <InfoDivider />
                        <FlexSBCont>
                            <CardLText>Exchange Rate</CardLText>
                            {dataLoading ? <LoadingText2 width={20} /> :
                                <CardText>{`1 ${data.underlyingSymbol} = ${!!data && data.exchangeRate ? commaFy(parseFloat(data.exchangeRate).toFixed(2)) : '0.00'} ${data.gTokenSymbol}`}   </CardText>
                            }
                        </FlexSBCont>
                    </AssetDetails>
                </AssetCard>
            </AssetCardCont>
        </TokenStatsCont>
    );
};

export default AssetStats;