import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserLendingData } from 'logic/action/lending.action'
import { updateUserBalance, silentUpdateOnInfStake } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont } from 'shared/styles/styled'
import { HAModalCont, HAMDataCont, HAModalTitle, TokenInfoBox, HATokenIcon, TokenValTxt, ModalAPYTxt, TvlTxt, SwitchCont, DepositSwitch, WithdrawSwitch, ModalCard, ModalCardTitle, DataInfoCard, InfoHeaderCont, DataInfoTitle, DataInfoCont, DataInfoTxt, LoadingText3, LoadingText4 } from 'shared/styles/highestApyStyle'
import { CellCont, TwoImgCont } from 'shared/styles/planetTableStyle'
import { ModalInputCont, ModalInput, MaxBox, MaxBoxZero, UserBalTxt } from 'shared/styles/highestApyStyle'
import { CardBtnGroup, CardBtn, TransformBtn, CardRedBtn, CardGreenBtn } from 'shared/styles/highestApyStyle'
import CloseIcon from 'assets/icons/white-cross.svg'
import DownIcon from 'assets/icons/down.svg'
import { getTokenIcon } from 'shared/tokenIconList'
import { floatNumRegex, } from 'shared/helpers/regexConstants'
import { getTokenName, commaFy, convertToKAndM, checkZeroVal, getNumFormat } from 'service/globalFunctions'
// import BoostVaultAction from './BoostVaultAction'
import { transLinkUrl, fraxTranLink, maticTransLink, dotTransLink, solTransLink, adaTransLink, lunaTransLink, austTransLink, avaxTransLink, atomTransLink, xrpTransLink, linkTransUrl, ftmTransLink, whustTransferLink } from 'service/global-constant'
import Spinner from 'shared/spinner'
import { isAllowanceApproved, boostCalculatorForMarkets, boostCalculatorForLPs } from 'modules/block-chain/BlockChainMethods'
import { bluePfTokenListWithoutAquaGamma } from 'modules/block-chain/pfTokenList'
import { gBnbAddress, convertToWei, instType, selectInstance, MAX_INT } from 'modules/block-chain/lendingAbi'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { getUnderlyingDecimal } from 'modules/block-chain/LendingBase'
import { anchor_apy } from 'modules/block-chain-green/lendingAbi'
import { TokenValInput, TokenSelectCont, FlexCont, TokenName, SelectCurrTxt, PercentageBox, PercentageBtnCont} from './styles'
import { swapTokenList, tokenListWithoutAquaGamma } from 'modules/block-chain/tokenList'
import ArrowDown from 'assets/icons/arrow-down.png'
import gammaIcon from 'assets/icons/gamma-icon.png'
import EqualsIcon from 'assets/icons/equal-icon.png'
import CustomModal from 'shared/custom-modal'
import TokenSelect from 'shared/token-select'
import CAKEIcon from 'assets/icons/CAKE.svg'
import stablePoolIcon from 'assets/icons/stableswap_nobg.png'

const BoostCalculationModal = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('deposit')
    const [depositAmount, setDepositAmount] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [depositLoading, setDepositLoading] = useState(false)
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [isAllowance, setIsAllowance] = useState(false)
    const [showVaultInfo, setShowVaultInfo] = useState(false)
    const [showFeeInfo, setShowFeeInfo] = useState(false)
    const [showAPYCal, setShowAPyCal] = useState(false)
    const [depositLP, setdepositLP] = useState({})
    const [depositLPValue, setDepositLPValue] = useState(0)
    const [depositLPValueWithComma, setDepositLPValueWithComma] = useState('')
    const [depositGamma, setDepositGamma] = useState(0)
    const [depositGammaWithComma, setDepositGammaWithComma] = useState('')
    const [showTokenModal, setShowTokenModal] = useState(false)
    const [currTokenList, setCurrTokenList] = useState<any>([])
    const [sendToken, setSendToken] = useState<any>([])
    const [existTokenAdd, setExistTokeAdd] = useState('')
    const [selectedPool, setSelectedPool] = useState<any>({})
    const [boostPercentForToken, setBoostPercentForToken] = useState(0)
    const [boostValForToken, setBoostValForToken] = useState(0.00)
    const [boostLPArray, setBoostLPArray] = useState<any>([])
    const { portfolioLoading, poolData } = selector.user
    const { togglePoolModal, data, setModalData } = props
    const { infVaultLoadingNew, gammaInfDataNew, aquaInfDataNew, gammaInfVaultLoading, aquaInfVaultLoading, userPortfolio } = selector.user

    const gammaInfObj = {
        poolId: gammaInfDataNew.pid,
        value: gammaInfDataNew.deposited_bal,
        usdValue: gammaInfDataNew.deposited_bal_usd,
        farmContractAddress: gammaInfDataNew.farmAddress,
        name: "Gamma Infinity",
        strategyAddress: gammaInfDataNew.strategyAddress
    };

    const aquaInfObj = {
        poolId: aquaInfDataNew.pid,
        value: aquaInfDataNew.deposited_bal,
        usdValue: aquaInfDataNew.deposited_bal_usd,
        farmContractAddress: aquaInfDataNew.farmAddress,
        name: "Aqua Infinity",
        strategyAddress: aquaInfDataNew.strategyAddress
    };

    useEffect(() => {
        //console.log("pool data", poolData, data, userPortfolio)
        const dataArr = poolData.newVaults;
        poolData.newPools.active.map((pool: any, index: any) => {
            poolData.newPools.active[index].value = poolData.newPools.active[index].vaultBalance;
            poolData.newPools.active[index].usdValue = poolData.newPools.active[index].vaultBalanceUSD;
            const tokenName = poolData.newPools.active[index].name.split('-');
            let icon1: any;
            let icon2: any;
            if (tokenName.length === 1) {
                icon1 = getTokenIcon(poolData.newPools.active[index].name)
            }
            else if (tokenName.length === 2) {
                icon1 = getTokenIcon(tokenName[0])
                icon2 = getTokenIcon(tokenName[1])
            }
            poolData.newPools.active[index].icon1 = icon1;
            poolData.newPools.active[index].icon2 = icon2;
            if(poolData.newPools.active[index].strategyAddress.toLowerCase() === data.strategyAddress.toLowerCase()){
                setSelectedPool(poolData.newPools.active[index])
            }
        })
        poolData.newPools.active.push(gammaInfObj)
        poolData.newPools.active.push(aquaInfObj)
        setCurrTokenList(poolData.newPools.active)
        setSendToken(data)
        
    }, [poolData, data, setModalData])

    useEffect(() => {
        if(+depositLPValue > 0 && +depositGamma > 0 && infVaultLoadingNew === false && Object.keys(boostLPArray).length > 0){
            boostCalulatorForPools()
        }

    }, [depositLPValue, depositGamma, infVaultLoadingNew, boostLPArray])

    useEffect(() => {
        const boostLPData = async() => {
            let boostPercentageData = await boostCalculatorForLPs(selector.ethData.address)
            setBoostLPArray(boostPercentageData)
            //console.log("booost lp data", boostPercentageData)
        }
        if(!infVaultLoadingNew){
            boostLPData()
        }
       
    }, [infVaultLoadingNew])

    const renderTokenIcons = (tokenIcons: string, isBoostPool: boolean) => {
        const tokenName = tokenIcons.split('-')
        if (tokenName.length === 1) {
            const icon = getTokenIcon(tokenIcons)
            return <img src={icon} alt="" />
        }
        else if (tokenName.length === 2) {
            const icon1 = getTokenIcon(tokenName[0])
            const icon2 = getTokenIcon(tokenName[1])
            return (
                <TwoImgCont>
                    <img src={icon1} alt="" />
                    <img className="second-img" src={icon2} alt="" />
                    {isBoostPool && <img className='third-img' src={CAKEIcon} alt="" />}
                </TwoImgCont>
            )
        }
        else if (tokenName.length === 3) {
            const icon1 = getTokenIcon(tokenName[0])
            const icon2 = getTokenIcon(tokenName[1])
            const icon3 = getTokenIcon(tokenName[2])
            return (
                <TwoImgCont>
                    <img src={icon1} alt="" />
                    <img className="second-img" src={icon2} alt="" />
                    <img className='third-icon' src={icon3} alt="" />
                </TwoImgCont>
            )
        }
    }

    const boostCalulatorForPools = async() => {
        const new_markets_without_gammaAqua: any = Object.values(bluePfTokenListWithoutAquaGamma);
        
        //console.log("boostPercentage value",boostLPArray, sendToken, gammaInfDataNew, selectedPool);
        let selectedLPData = boostLPArray[sendToken.farmContractAddress+"_"+sendToken.poolId] 
        //console.log(selectedLPData)
        let addedPooltokens = depositLPValue/selectedLPData.price * 1e18;
        let entranceFeeFactor = 0;// fetch from contract
        let sharesAdded = addedPooltokens - (addedPooltokens * entranceFeeFactor)/10000;
        let existingLPShares = selector.ethData.address ? selectedPool.vaultBalance * 1e18 : 0;
        let netPoolShares = sharesAdded + existingLPShares; //
        //console.log("lp shares", sharesAdded, existingLPShares)
        let depositGammaVal = depositGamma/selectedLPData.gammaPrice * 1e18;
        let iGammaAdded = (depositGammaVal - (entranceFeeFactor * depositGammaVal)/1000) * 1e36 / (selectedLPData.iTokenExchangeRate * selectedLPData.gTokenExchangeRateForGamma) ;
        //console.log("iGamma added", iGammaAdded)
        let iGammaBalance = selector.ethData.address ? gammaInfDataNew.iTokenBalance : 0;//from contract
        let iGammaToBeUnstaked = selector.ethData.address ? gammaInfDataNew.unstaking_bal * selectedLPData.iTokenExchangeRate : 0; // from contract
        let netIGamma = iGammaBalance - iGammaToBeUnstaked + iGammaAdded;
        //console.log("igamma data", netIGamma, iGammaBalance,  iGammaToBeUnstaked, iGammaAdded)
        let userFactor = Math.sqrt(netPoolShares * netIGamma );
        let totalFactor = +selectedLPData.totalFactor + +userFactor;
        let wantLockedTotal = selectedLPData.wantLockedTotal + sharesAdded;//
        let sharesTotal = selectedLPData.sharesTotal + sharesAdded;
        //console.log("values check", "user shares", netPoolShares, "gamma tokens desposited",depositGammaVal, "IGamma",netIGamma,"user factor", userFactor, "totalFactor",totalFactor, "wantLockedTotal", wantLockedTotal, "total Shares", sharesTotal,"factor ratio", userFactor/totalFactor)
        let userBoostAPR = (100 * selectedLPData.gammaBoostReward * selectedLPData.gammaPrice * 1e18 * (userFactor/totalFactor)) / (netPoolShares * wantLockedTotal * selectedLPData.price/ sharesTotal)
        let boostApy = (Math.pow((1 + (userBoostAPR)/36500), 365) - 1) * 100;
        //console.log("apr",userBoostAPR, "apy", boostApy )
        let roiOnBoost: any = +depositLPValue + (+userBoostAPR * +depositLPValue/100);
        setBoostPercentForToken(+boostApy.toFixed(2))
        setBoostValForToken(roiOnBoost.toFixed(2))
        /*
        let gammaBoostPercentage = poolInfoArr[i].gammaRewardBoostPercentage ? +poolInfoArr[i].gammaRewardBoostPercentage/10000 : 0;

        let gammaBoostReward = (gammaBoostPercentage * blocksPerYear * farmV2DripRate/ 1e18 * poolInfoArr[i].allocPoint)/totalAllocPoint;

        userBoostAPR = +sharesTotal > 0 && +wantLockedTotal > 0 && +userInfoArr[i].shares > 0 ? (100 * gammaBoostReward * +gammaPrice * 1e18 * (userInfoArr[i].factor/poolInfoArr[i].totalFactor))/(userInfoArr[i].shares * wantLockedTotal * lpTokenPriceArr[pidArr[i]] / sharesTotal) : 0;

        */
    }

    const handleDepositAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setDepositAmount(value)
        }
        if (!value) {
            setDepositAmount('')
        }
    }
    const handleTokenValue: any = (e: any) => {
        let { value } = e.target
        if(+value === 0 ){
            value = value;
        } else {
            value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.split(",").join("") : "";
        }
        if (floatNumRegex.test(value.toString())) {
            setDepositLPValue(value)
            setDepositLPValueWithComma(commaFy(value))
        }
        if (!value) {
            setDepositLPValue(0)
            setDepositLPValueWithComma('')
        }
    }

    const handleGammaDeposit: any = (e: any) => {
        let { value } = e.target
        if(+value === 0 ){
            value = value;
        } else {
            value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.split(",").join("") : "";
        }
        if (floatNumRegex.test(value.toString())) {
            setDepositGamma(value)
            setDepositGammaWithComma(commaFy(value))
        }
        if (!value) {
            setDepositGamma(0)
            setDepositGammaWithComma('')
        }
    }

    const handleTokenModal = (type: string) => {
        // setTokenType(type)
        // getTokenList(type)
        // getExistTokenAdd(type)
        setShowTokenModal(true)
        // setSendAmountCheck(0)
    }

    const handleToggleModal = () => {
        // setTokenType('')
        setShowTokenModal(false)
    }

    
    const handleTokenSelect = (selectedTokenData: any) => {
        //console.log("selected token", selectedTokenData)
        setSendToken(selectedTokenData)
        handleToggleModal()
    // // console.log(selectedTokenData, existTokenAdd)
    // if (selectedTokenData.address === existTokenAdd) {
    //   setSendToken(receiveToken)
    //   setReceiveToken(sendToken)
    //   getSwapConstantData(receiveToken, sendToken)
    // }
    // else {
    //   if (tokenType === 'send') {
    //     setSendToken(selectedTokenData)
    //     if (Object.values(receiveToken).length > 0) {
    //       getSwapConstantData(selectedTokenData, receiveToken)
    //     } else {
    //       // console.log("set swap const data inside handle token select else 1")
    //       setSwapConsData({})
    //     }
    //   }
    //   if (tokenType === 'receive') {
    //     setReceiveToken(selectedTokenData)
    //     if (Object.values(sendToken).length > 0) {
    //       getSwapConstantData(sendToken, selectedTokenData)
    //     } else {
    //       // console.log("set swap const data inside handle token select else 2")
    //       setSwapConsData({})
    //     }
    //   }
    //   setIntermediateTokenArray([])
    // }
    
    // setExistTokeAdd('')
    // setSendAmount('')
    // setSendAmountWithoutComma(0)
    // setReceiveAmountWithoutComma(0)
    // setReceiveAmount('')
    // handleToggleModal()  
    // handleMinReceive(0)  
    // console.log("handle token select function", sendAmountWithoutComma)
  }

  const updateGammaVal = async(gamma: any) => {
    setDepositGamma(gamma);
    setDepositGammaWithComma(commaFy(gamma))
  }
    

    return (
        <HAModalCont className='boostCalCont'>
            <FlexSBCont className='boostHeading'>
                <HAModalTitle>Boost Calculator</HAModalTitle>
                <img className='close-icon' src={CloseIcon} alt="" onClick={() => togglePoolModal()} />
            </FlexSBCont>
            <HAMDataCont className='boostCalDataCont'>
                <DataInfoTxt className='boostInputTitle'>
                    Deposit Amount
                </DataInfoTxt>
                <FlexSBCont className="tokenInputCont">
                    <TokenValInput
                        disabled={false}
                        placeholder="$0"
                        onChange={handleTokenValue}
                        value={depositLPValueWithComma && depositLPValueWithComma}
                        autoFocus={true}
                    />
                    <FlexCont>
                    
                    <TokenSelectCont onClick={() => handleTokenModal('send')}>
                        {Object.values(sendToken).length > 0 ? (
                        <>
                            <CellCont className="boostPoolImg">{renderTokenIcons(sendToken.name, sendToken.isBoostPool)}</CellCont>
                            <TokenName>{sendToken.name}</TokenName>
                        </>
                        ) : (
                        <SelectCurrTxt>Select Currency</SelectCurrTxt>
                        )}
                        <img className="arrow-down" src={ArrowDown} alt="" />
                    </TokenSelectCont>
                    </FlexCont>
                </FlexSBCont>
                <DataInfoTxt className='gammaText'>
                    Gamma staked
                </DataInfoTxt>
                <FlexSBCont className="tokenInputCont">
                    <TokenValInput
                        disabled={false}
                        placeholder="$0"
                        onChange={handleGammaDeposit}
                        value={depositGammaWithComma && depositGammaWithComma}
                        
                    />
                    <TokenSelectCont>
                        <>
                            <img className="boost-token-icon" src={gammaIcon} alt="" />
                            <TokenName>GAMMA</TokenName>
                        </>
                        
                    </TokenSelectCont>
                </FlexSBCont>
                <PercentageBtnCont className='gammaStaked'>
                    <PercentageBox onClick={() => updateGammaVal(100)}><p>$100</p></PercentageBox>
                    <PercentageBox onClick={() => updateGammaVal(1000)}><p>$1k</p></PercentageBox>
                    <PercentageBox onClick={() => updateGammaVal(5000)}><p>$5k</p></PercentageBox>
                    <PercentageBox onClick={() => updateGammaVal(10000)}><p>$10k</p></PercentageBox>
                </PercentageBtnCont>
                <FlexSBCont className="equalsIcon">
                    <img className="boost-token-icon" src={EqualsIcon} alt="" />
                </FlexSBCont>
                <FlexSBCont className="boostOutput">
                    <DataInfoTxt className='roiText'>
                        Annual ROI (Based on current rates)
                    </DataInfoTxt>
                    <DataInfoTxt className='roiValue'>
                        ${boostValForToken}
                    </DataInfoTxt>
                    <DataInfoTxt className='boostApy'>
                        {boostPercentForToken}% APY 
                    </DataInfoTxt>
                    
                </FlexSBCont>
            </HAMDataCont>
            <CustomModal show={showTokenModal} toggleModal={handleToggleModal}>
                {showTokenModal && (
                    <TokenSelect
                        selectedAdd={sendToken.address}
                        setShowTokenModal={setShowTokenModal}
                        tokenListVal={currTokenList}
                        handleTokenSelect={handleTokenSelect}
                        existTokenAdd={existTokenAdd}
                        crossIconColor={'white'}
                    />
                )}
            </CustomModal>
        </HAModalCont>
        
    );
};

export default BoostCalculationModal