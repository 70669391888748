// import { useDispatch } from 'react-redux'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentPlanet } from 'logic/action/planet.action'
import { SubLinkCont, SubLink, SubLinkRow, HeadText, HeadDescription } from './style'
import { analyticsLink, aquaVotingLink, docsLink } from 'service/global-constant'
import AnalyticsIcon from 'assets/icons/analytics.svg'
import governanceIcon from 'assets/icons/governance.svg'
import learnMoreIcon from 'assets/icons/learnMore.svg'
import marketsIcon from 'assets/icons/markets.svg'
import metricsIcon from 'assets/icons/metrics.svg'
import liquidationIcon from 'assets/icons/liquidations.png'
import history from 'modules/app/components/history'
// import compensation fom 'modules/'
import { Paths } from 'modules/app/components/routes/types'
import { fetchPoolData, fetchUserPortfolio, fetchPlatformData, fetchInfVaultData, fetchInfVaultDataNew, fetchPoolDataNew } from 'logic/action/user.actions'
import { fetchLendingPlatformData, fetchUserAssetData, fetchBorrowLimit, fetchLendingPlatformData_v1, fetchUserAssetData_v1 } from 'logic/action/lending.action'

const HeaderSubLinks = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: any) => state)
    const { poolLoading, poolData, portfolioLoading, userPortfolio } = selector.user
    const { infVaultLoading, gammaInfData, aquaInfData } = selector.user
    const { gPlatformData, userAssetData, userOldAssetData, borrowLimit, gPlatformLoadingNew, borrowLimitLoadingNew, addNullLoading } = selector.lending

    const handlePathLink = (path: string) => {
        if (path === 'markets') {
            history.push(Paths.markets)
            dispatch(setCurrentPlanet('green'))
        }
        if (path === 'metrics') {
            history.push(Paths.metrics)
            dispatch(setCurrentPlanet('green'))
        }
        if (path === 'liquidations') {
            history.push(Paths.liquidations)
            dispatch(setCurrentPlanet('green'))
        }
        if (path === 'lending_v1') {
            if(gPlatformData.availableCredit === undefined){
                dispatch(fetchLendingPlatformData_v1(selector.ethData.address))
                dispatch(fetchUserAssetData_v1(selector.ethData.address))
                dispatch(fetchBorrowLimit(selector.ethData.address))
            }
            history.push(Paths.lending_v1)
            dispatch(setCurrentPlanet('green'))
        }
        if (path === 'markets_v1') {
            history.push(Paths.markets_v1)
            dispatch(setCurrentPlanet('green'))
        }
        if (path === 'vaults_v1') {
            // console.log(gammaInfData)
            if(gammaInfData.tvl === undefined){
                dispatch(fetchInfVaultData(selector.ethData.address))
            }
            history.push(Paths.vaults_v1)
            dispatch(setCurrentPlanet('blue'))
        }
        if (path === 'pools_v1') {
            history.push(Paths.pools_v1)
            dispatch(setCurrentPlanet('blue'))
        }
        if (path === 'compensation') {
            history.push(Paths.compensation)
            dispatch(setCurrentPlanet('blue'))
        }
    }

    return (
        <SubLinkCont>
            <SubLinkRow>
                <a href={analyticsLink} target='_blank' rel="noreferrer">
                    <SubLink>
                        <div>
                            <img src={AnalyticsIcon} alt="" />
                        </div>
                        <div>
                            <HeadText className='menu-link'>
                                Analytics
                            </HeadText>
                            <HeadDescription>
                                In-Depth Liquidity Data
                            </HeadDescription>
                        </div>
                    </SubLink>
                </a>

                <SubLink onClick={() => handlePathLink('markets')}>
                    <div>
                        <img src={marketsIcon} alt="" />
                    </div>
                    <div>
                        <HeadText className='menu-link'>
                            Markets
                        </HeadText>
                        <HeadDescription>
                            Insightful Lending Data
                        </HeadDescription>
                    </div>
                </SubLink>

            </SubLinkRow>
            <SubLinkRow>
                {/* <SubLink onClick={() => handlePathLink('metrics')}>
                    <div>
                        <img src={metricsIcon} alt="" />
                    </div>
                    <div>
                        <HeadText className='menu-link'>
                            Metrics
                        </HeadText>
                        <HeadDescription>
                            AQUA &amp; GAMMA
                        </HeadDescription>
                    </div>
                </SubLink> */}

                    <SubLink onClick={() => handlePathLink('liquidations')}>
                        <div>
                            <img src={liquidationIcon} alt="" />
                        </div>
                        <div>
                            <HeadText>
                                Liquidations
                            </HeadText>
                            <HeadDescription>
                                Protect green planet & earn
                            </HeadDescription>
                        </div>
                    </SubLink>

                <a href={aquaVotingLink} target='_blank' rel="noreferrer">
                    <SubLink>
                        <div>
                            <img src={governanceIcon} alt="" />
                        </div>
                        <div>
                            <HeadText className='menu-link'>
                                Governance
                            </HeadText>
                            <HeadDescription>
                                Shape The Future Of Planet
                            </HeadDescription>
                        </div>
                    </SubLink>
                </a>
            </SubLinkRow>
            <SubLinkRow>
                <a href={docsLink} target='_blank' rel="noreferrer">
                    <SubLink>
                        <div>
                            <img src={learnMoreIcon} alt="" />
                        </div>
                        <div>
                            <HeadText className='menu-link'>
                                Learn More
                            </HeadText>
                            <HeadDescription>
                                Acquire DeFi Mastery
                            </HeadDescription>
                        </div>
                    </SubLink>
                </a>
                {/* { <a href={docsLink} target='_blank' rel="noreferrer">  */}
                    
                {/* </a>  */}
            </SubLinkRow>
            {/* <SubLinkRow>
                <SubLink onClick={() => handlePathLink('lending_v1')}>
                    <div>
                        <img src={liquidationIcon} alt="" />
                    </div>
                    <div>
                        <HeadText>
                            Lending V1
                        </HeadText>
                        <HeadDescription>
                            Old lending market
                        </HeadDescription>
                    </div>
                </SubLink>
                <SubLink onClick={() => handlePathLink('markets_v1')}>
                    <div>
                        <img src={marketsIcon} alt="" />
                    </div>
                    <div>
                        <HeadText className='menu-link'>
                            Markets V1
                        </HeadText>
                        <HeadDescription>
                            Insights On Old Lending Data
                        </HeadDescription>
                    </div>
                </SubLink>
            </SubLinkRow> */}
            {/* <SubLinkRow>
                <SubLink onClick={() => handlePathLink('vaults_v1')}>
                    <div>
                        <img src={liquidationIcon} alt="" />
                    </div>
                    <div>
                        <HeadText>
                            Vaults V1
                        </HeadText>
                        <HeadDescription>
                            Old infinity vaults
                        </HeadDescription>
                    </div>
                </SubLink>
                <SubLink onClick={() => handlePathLink('compensation')}>
                    <div>
                        <img src={liquidationIcon} alt="" />
                    </div>
                    <div>
                        <HeadText>
                            Compensation
                        </HeadText>
                        <HeadDescription>
                            Terra compensation
                        </HeadDescription>
                    </div>
                </SubLink>
            </SubLinkRow> */}
            {/* <SubLinkRow>
                <SubLink onClick={() => handlePathLink('pools_v1')}>
                    <div>
                        <img src={marketsIcon} alt="" />
                    </div>
                    <div>
                        <HeadText className='menu-link'>
                            Pools V1
                        </HeadText>
                        <HeadDescription>
                            Old Pools Data
                        </HeadDescription>
                    </div>
                </SubLink> 
                
            </SubLinkRow> */}
            {/* <SubLink >Learn</SubLink> */}
        </SubLinkCont>
    );
};

export default HeaderSubLinks;