import BUSDIcon from 'assets/icons/BUSD.png'
import USDTIcon from 'assets/icons/USDT.svg'
import USTIcon from 'assets/icons/UST.png'
import USDCIcon from 'assets/icons/USDC.svg'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import BNBIcon from 'assets/icons/BNB.png'
import DAIIcon from 'assets/icons/DAI.svg'
import CAKEIcon from 'assets/icons/CAKE.svg'
import BTCBIcon from 'assets/icons/BTCB.svg'
import ETHIcon from 'assets/icons/ETH.svg'
import TUSDIcon from 'assets/icons/TUSD.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import LUNAIcon from 'assets/icons/LUNA.png'
import AVAXIcon from 'assets/icons/AVAX.svg'
import XRPIcon from 'assets/icons/XRP.svg'
import FRAXIcon from 'assets/icons/FRAX.svg'
import ADAIcon from 'assets/icons/ADA.svg'
import BNBxIcon from 'assets/icons/BNBx.png'
import HayIcon from 'assets/icons/hay_icon.png'
import ankrBNB from 'assets/icons/ankrBNB.png'
import { convertToEtherRevised, instanceType, returnPairExistOrNot, selectInstance, tokenBalance } from './Swap'
import { token1, token2, getTokenPrice } from './BlockChainMethods'
import { getTokenIcon } from 'shared/tokenIconList'
import { getGammaPrice, getBNBxPrice } from './LendingBase'
import {BTCBAddress, aquaAddress, bnbAddress, bnbxAddress, busdAddress, multicall_abi, multicall_address, pancakFactoryAddress, pancakeFactoryAbi, pancakeLPabi} from './abi'
import wallet from 'modules/wallet/wallet'
import { analyticsLink } from 'service/global-constant'
import {
  PLANETFACTORYABI,
  PLANETFACTORYADDRESS,
  PLANETLPABI,
  PLANETROUTERABI,
  PLANETROUTERADDRESS,
  zeroAddress,
  aquaZapInAddress,
  aquaZapInAbi,
  pcsRouterAddress,
  belt4Abi,
  convertLpAbi,
  aquaConvertLpAddress,
  wbnbAddress
} from './SwapDexAbi'
import { BigNumber } from 'bignumber.js'
import {pfTokenListLowercase} from '../block-chain/pfTokenList'
import { AQUA_BNBLPAddress, BUSD_BNBLPAddress, GAMMA_BNBLPAddress, convertToEther, gBnbAbi, gammaAddress } from './lendingAbi'
const cakeTokenAddress = '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'

const bnbSno = 1;
export const tokenList = [
  {
    sno: 0,
    name: 'AQUA',
    tokenOrigin: 'AQUA',
    icon: AQUAIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
    pairWith: [1, 2, 3, 11],
    gTokenAddress: '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5'
  },
  {
    sno: 1,
    name: 'BNB',
    tokenOrigin: 'Binance Coin',
    icon: BNBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    pairWith: [0, 5, 3, 6, 8],
    gTokenAddress: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29'
  },
  {
    sno: 2,
    name: 'CAKE',
    tokenOrigin: 'PancakeSwap',
    icon: CAKEIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    pairWith: [0, 6],
    gTokenAddress: '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79'
  },
  {
    sno: 3,
    name: 'BUSD',
    tokenOrigin: 'Binance USD',
    icon: BUSDIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    pairWith: [0, 4, 7, 1, 5, 8, 9, 10],
    gTokenAddress: '0xe983cF03314830d9b4d5e87594bB50215438e19a'
  },
  {
    sno: 4,
    name: 'USDC',
    tokenOrigin: 'USD Coin',
    icon: USDCIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    pairWith: [7, 3, 8, 6],
    gTokenAddress: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46'
  },
  {
    sno: 5,
    name: 'BTCB',
    tokenOrigin: 'Bitcoin',
    icon: BTCBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    pairWith: [1, 6, 3],
    gTokenAddress: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050'
  },
  {
    sno: 6,
    name: 'ETH',
    tokenOrigin: 'Ethereum',
    icon: ETHIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    pairWith: [5, 1, 4, 2],
    gTokenAddress:'0x666Fa13501ce90c6f8c078313bd72f1f95197c5d'
  },
  // {
  //   sno: 7,
  //   name: 'UST',
  //   tokenOrigin: 'Terra USD',
  //   icon: USTIcon,
  //   value: '0.00',
  //   usdValue: '0.00',
  //   address: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  //   pairWith: [4, 3],
  // },
  {
    sno: 7,
    name: 'USDT',
    tokenOrigin: 'Tether USD',
    icon: USDTIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x55d398326f99059fF775485246999027B3197955',
    pairWith: [3, 4, 1],
    gTokenAddress: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8'
  },
  {
    sno: 8,
    name: 'DAI',
    tokenOrigin: 'Maker DAO',
    icon: DAIIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    pairWith: [3],
    gTokenAddress: '0x9C53267A58A54Df78daD8DB48a118222998c71B4'
  },
  
  {
    sno: 9,
    name: 'GAMMA',
    tokenOrigin: 'GAMMA',
    icon: GAMMAIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15',
    pairWith: [0],
    gTokenAddress: '0xCD221e1504442671671D3330CB8E916a5EDc3FC7'
  },
  {
    sno: 10,
    name: 'BNBx',
    tokenOrigin: 'Stader',
    icon: BNBxIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
    gTokenAddress: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428',
  },
]

export const transformTokenList = [
  {
    sno: 0,
    name: 'AQUA',
    tokenOrigin: 'AQUA',
    icon: AQUAIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
    price: 0,
    gTokenAddress: '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5'
  },
  {
    sno: 1,
    name: 'BNB',
    tokenOrigin: 'Binance Coin',
    icon: BNBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    price: 0,
    gTokenAddress: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29'
  },
  {
    sno: 2,
    name: 'BUSD',
    tokenOrigin: 'Binance USD',
    icon: BUSDIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    price: 0,
    gTokenAddress: '0xe983cF03314830d9b4d5e87594bB50215438e19a'
  },
  {
    sno: 3,
    name: 'BTCB',
    tokenOrigin: 'Bitcoin',
    icon: BTCBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    price: 0,
    gTokenAddress: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050'
  },
  {
    sno: 4,
    name: 'ETH',
    tokenOrigin: 'Ethereum',
    icon: ETHIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    price: 0,
    gTokenAddress:'0x666Fa13501ce90c6f8c078313bd72f1f95197c5d'
  },
  {
    sno: 5,
    name: 'GAMMA',
    tokenOrigin: 'GAMMA',
    icon: GAMMAIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15',
    price: 0,
    gTokenAddress: '0xCD221e1504442671671D3330CB8E916a5EDc3FC7'
  },
  {
    sno: 6,
    name: 'USDT',
    tokenOrigin: 'Tether USD',
    icon: USDTIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x55d398326f99059fF775485246999027B3197955',
    price: 0,
    gTokenAddress: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8'
  },
  {
    sno: 7,
    name: 'USDC',
    tokenOrigin: 'USD Coin',
    icon: USDCIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    price: 0,
    gTokenAddress: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46'
  },
  {
    sno: 8,
    name: 'FRAX',
    marketSymbol: 'FRAX',
    tokenOrigin: 'FRAX Finance',
    icon: FRAXIcon,
    value: '0.00',
    usdValue: '0.00',
    price: 0,
    gTokenAddress: '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
    address: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
    
  },
  {
    sno: 9,
    gTokenAddress: '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
    name: 'DAI',
    marketSymbol: 'DAI',
    tokenOrigin: 'Maker DAO',
    icon: DAIIcon,
    value: '0.00',
    usdValue: '0.00',
    price: 0,
    address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  },
  {
    sno: 10,
    gTokenAddress: '0xc019728cccdc45cdA6a051802878c00e563C1E78',
    name: 'AVAX',
    marketSymbol: 'AVAX',
    icon: AVAXIcon,
    value: '0.00',
    usdValue: '0.00',
    price: 0,
    address: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
  },
  {
    sno: 11,
    name: 'CAKE',
    marketSymbol: 'CAKE',
    icon: CAKEIcon,
    value: '0.00',
    usdValue: '0.00',
    price: 0,
    address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    gTokenAddress: '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
  },
  {
    sno: 12,
    name: 'ADA',
    marketSymbol: 'ADA',
    icon: ADAIcon,
    tokenOrigin: 'Cardano',
    value: '0.00',
    usdValue: '0.00',
    price: 0,
    address: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    gTokenAddress: '0x407e49480643E338ae36612b220f0607e90429Dc',
  },
  {
    sno: 13,
    name: 'XRP',
    marketSymbol: 'XRP',
    icon: XRPIcon,
    tokenOrigin: 'XRP',
    value: '0.00',
    usdValue: '0.00',
    price: 0,
    address: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    gTokenAddress: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
  },
  {
    sno: 14,
    name: 'BNBx',
    tokenOrigin: 'Stader',
    icon: BNBxIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
    gTokenAddress: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428',
  },
  {
    sno: 15,
    name: 'HAY',
    tokenOrigin: 'Helio',
    icon: HayIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
    gTokenAddress: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
  },
  {
    sno: 16,
    name: 'ankrBNB',
    tokenOrigin: 'ankr',
    icon: ankrBNB,
    value: '0.00',
    usdValue: '0.00',
    address: '0x52f24a5e03aee338da5fd9df68d2b6fae1178827',
    gTokenAddress: '0x52f24a5e03aee338da5fd9df68d2b6fae1178827',
  },
]

export const convertLPTokenList = [
  {
    sno: 0,
    name: 'AQUA',
    tokenOrigin: 'AQUA',
    icon: AQUAIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
    price: 0,
    gTokenAddress: '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5'
  },
  {
    sno: 1,
    name: 'BNB',
    tokenOrigin: 'Binance Coin',
    icon: BNBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    price: 0,
    gTokenAddress: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29'
  },
  {
    sno: 2,
    name: 'BUSD',
    tokenOrigin: 'Binance USD',
    icon: BUSDIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    price: 0,
    gTokenAddress: '0xe983cF03314830d9b4d5e87594bB50215438e19a'
  },
  {
    sno: 3,
    name: 'BTCB',
    tokenOrigin: 'Bitcoin',
    icon: BTCBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    price: 0,
    gTokenAddress: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050'
  },
  {
    sno: 4,
    name: 'ETH',
    tokenOrigin: 'Ethereum',
    icon: ETHIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    price: 0,
    gTokenAddress:'0x666Fa13501ce90c6f8c078313bd72f1f95197c5d'
  },
  {
    sno: 5,
    name: 'GAMMA',
    tokenOrigin: 'GAMMA',
    icon: GAMMAIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15',
    price: 0,
    gTokenAddress: '0xCD221e1504442671671D3330CB8E916a5EDc3FC7'
  },
  {
    sno: 6,
    name: 'USDT',
    tokenOrigin: 'Tether USD',
    icon: USDTIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x55d398326f99059fF775485246999027B3197955',
    price: 0,
    gTokenAddress: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8'
  },
  {
    sno: 7,
    name: 'USDC',
    tokenOrigin: 'USD Coin',
    icon: USDCIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    price: 0,
    gTokenAddress: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46'
  },
  {
    sno: 8,
    name: 'FRAX',
    marketSymbol: 'FRAX',
    tokenOrigin: 'FRAX Finance',
    icon: FRAXIcon,
    value: '0.00',
    usdValue: '0.00',
    price: 0,
    gTokenAddress: '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
    address: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
    
  },
  {
    sno: 9,
    gTokenAddress: '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
    name: 'DAI',
    marketSymbol: 'DAI',
    tokenOrigin: 'Maker DAO',
    icon: DAIIcon,
    value: '0.00',
    usdValue: '0.00',
    price: 0,
    address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  },
  {
    sno: 10,
    gTokenAddress: '0xc019728cccdc45cdA6a051802878c00e563C1E78',
    name: 'AVAX',
    marketSymbol: 'AVAX',
    icon: AVAXIcon,
    value: '0.00',
    usdValue: '0.00',
    price: 0,
    address: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
  },
  {
    sno: 11,
    name: 'CAKE',
    marketSymbol: 'CAKE',
    icon: CAKEIcon,
    value: '0.00',
    usdValue: '0.00',
    price: 0,
    address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    gTokenAddress: '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
  },
  {
    sno: 12,
    name: 'ADA',
    marketSymbol: 'ADA',
    icon: ADAIcon,
    tokenOrigin: 'Cardano',
    value: '0.00',
    usdValue: '0.00',
    price: 0,
    address: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    gTokenAddress: '0x407e49480643E338ae36612b220f0607e90429Dc',
  },
  {
    sno: 13,
    name: 'XRP',
    marketSymbol: 'XRP',
    icon: XRPIcon,
    tokenOrigin: 'XRP',
    value: '0.00',
    usdValue: '0.00',
    price: 0,
    address: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    gTokenAddress: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
  },
  {
    sno: 14,
    name: 'BNBx',
    tokenOrigin: 'Stader',
    icon: BNBxIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
    gTokenAddress: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428',
  },
  {
    sno: 15,
    name: 'HAY',
    tokenOrigin: 'Helio',
    icon: HayIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
    gTokenAddress: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
  },
]

export const swapTokenList = [
  {
    sno: 0,
    name: 'AQUA',
    tokenOrigin: 'AQUA',
    icon: AQUAIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
    pairWith: [1, 2],//[1, 2, 3, 11],
    gTokenAddress: '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5'
  },
  {
    sno: 1,
    name: 'BNB',
    tokenOrigin: 'Binance Coin',
    icon: BNBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    pairWith: [0, 5, 3],//[0, 5, 3, 6, 8],
    gTokenAddress: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29'
  },
  {
    sno: 2,
    name: 'BUSD',
    tokenOrigin: 'Binance USD',
    icon: BUSDIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    pairWith: [0, 4, 7, 1, 5, 8, 9, 10],
    gTokenAddress: '0xe983cF03314830d9b4d5e87594bB50215438e19a'
  },
  {
    sno: 3,
    name: 'BTCB',
    tokenOrigin: 'Bitcoin',
    icon: BTCBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    pairWith: [1, 6, 3],
    gTokenAddress: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050'
  },
  {
    sno: 4,
    name: 'ETH',
    tokenOrigin: 'Ethereum',
    icon: ETHIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    pairWith: [5, 1, 4, 2],
    gTokenAddress:'0x666Fa13501ce90c6f8c078313bd72f1f95197c5d'
  },
  {
    sno: 5,
    name: 'GAMMA',
    tokenOrigin: 'GAMMA',
    icon: GAMMAIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15',
    pairWith: [0],
    gTokenAddress: '0xCD221e1504442671671D3330CB8E916a5EDc3FC7'
  },
  {
    sno: 6,
    name: 'USDT',
    tokenOrigin: 'Tether USD',
    icon: USDTIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x55d398326f99059fF775485246999027B3197955',
    pairWith: [3, 4, 1],
    gTokenAddress: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8'
  },
  {
    sno: 7,
    name: 'USDC',
    tokenOrigin: 'USD Coin',
    icon: USDCIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    pairWith: [7, 3, 8, 6],
    gTokenAddress: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46'
  },
  {
    sno: 8,
    name: 'BNBx',
    tokenOrigin: 'Stader',
    icon: BNBxIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
    gTokenAddress: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428',
  },
]

export const tokenSwapList = [
  {
    sno: 0,
    name: 'AQUA',
    tokenOrigin: 'AQUA',
    icon: AQUAIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
    pairWith: [1, 2],//[1, 2, 3, 11],
    gTokenAddress: '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5',
    price: 0
  },
  {
    sno: 1,
    name: 'BNB',
    tokenOrigin: 'Binance Coin',
    icon: BNBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    pairWith: [0, 5, 3],//[0, 5, 3, 6, 8],
    gTokenAddress: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29',
    price: 0
  },
  {
    sno: 2,
    name: 'GAMMA',
    tokenOrigin: 'GAMMA',
    icon: GAMMAIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15',
    pairWith: [0],
    gTokenAddress: '0xCD221e1504442671671D3330CB8E916a5EDc3FC7',
    price: 0
  },
  {
    sno: 3,
    name: 'BUSD',
    tokenOrigin: 'Binance USD',
    icon: BUSDIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    pairWith: [0, 4, 7, 1, 5, 8, 9, 10],
    gTokenAddress: '0xe983cF03314830d9b4d5e87594bB50215438e19a',
    price: 0
  },
  {
    sno: 4,
    name: 'BTCB',
    tokenOrigin: 'Bitcoin',
    icon: BTCBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    pairWith: [1, 6, 3],
    gTokenAddress: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050',
    price: 0
  },
  {
    sno: 5,
    name: 'ETH',
    tokenOrigin: 'Ethereum',
    icon: ETHIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    pairWith: [5, 1, 4, 2],
    gTokenAddress:'0x666Fa13501ce90c6f8c078313bd72f1f95197c5d',
    price: 0
  },
  {
    sno: 6,
    name: 'USDT',
    tokenOrigin: 'Tether USD',
    icon: USDTIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x55d398326f99059fF775485246999027B3197955',
    pairWith: [3, 4, 1],
    gTokenAddress: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8',
    price: 0
  },
  {
    sno: 7,
    name: 'USDC',
    tokenOrigin: 'USD Coin',
    icon: USDCIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    gTokenAddress: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46',
    price: 0
  },
  {
    sno: 8,
    name: 'FRAX',
    marketSymbol: 'FRAX',
    tokenOrigin: 'FRAX Finance',
    icon: FRAXIcon,
    value: '0.00',
    usdValue: '0.00',
    gTokenAddress: '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
    address: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
    price: 0
  },
  {
    sno: 9,
    gTokenAddress: '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
    name: 'DAI',
    marketSymbol: 'DAI',
    tokenOrigin: 'Maker DAO',
    icon: DAIIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    price: 0
  },
  {
    sno: 10,
    gTokenAddress: '0xc019728cccdc45cdA6a051802878c00e563C1E78',
    name: 'AVAX',
    marketSymbol: 'AVAX',
    icon: AVAXIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
    price: 0
  },
  {
    sno: 11,
    name: 'CAKE',
    marketSymbol: 'CAKE',
    icon: CAKEIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    gTokenAddress: '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
    price: 0
  },
  {
    sno: 12,
    name: 'ADA',
    marketSymbol: 'ADA',
    icon: ADAIcon,
    tokenOrigin: 'Cardano',
    value: '0.00',
    usdValue: '0.00',
    address: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    gTokenAddress: '0x407e49480643E338ae36612b220f0607e90429Dc',
    price: 0
  },
  {
    sno: 13,
    name: 'XRP',
    marketSymbol: 'XRP',
    icon: XRPIcon,
    tokenOrigin: 'XRP',
    value: '0.00',
    usdValue: '0.00',
    address: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    gTokenAddress: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
    price: 0
  },
  {
    sno: 14,
    name: 'BNBx',
    tokenOrigin: 'Stader',
    icon: BNBxIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
    gTokenAddress: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428',
    price: 0
  },
  {
    sno: 15,
    name: 'HAY',
    tokenOrigin: 'Helio',
    icon: HayIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
    gTokenAddress: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
    price: 0
  },
]

export const tokenListWithoutAquaGamma = [
  
  {
    sno: 0,
    name: 'BNB',
    tokenOrigin: 'Binance Coin',
    icon: BNBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    pairWith: [0, 5, 3],//[0, 5, 3, 6, 8],
    gTokenAddress: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29'
  },
  {
    sno: 1,
    name: 'BUSD',
    tokenOrigin: 'Binance USD',
    icon: BUSDIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    pairWith: [0, 4, 7, 1, 5, 8, 9, 10],
    gTokenAddress: '0xe983cF03314830d9b4d5e87594bB50215438e19a'
  },
  {
    sno: 2,
    name: 'BTCB',
    tokenOrigin: 'Bitcoin',
    icon: BTCBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    pairWith: [1, 6, 3],
    gTokenAddress: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050'
  },
  {
    sno: 3,
    name: 'ETH',
    tokenOrigin: 'Ethereum',
    icon: ETHIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    pairWith: [5, 1, 4, 2],
    gTokenAddress:'0x666Fa13501ce90c6f8c078313bd72f1f95197c5d'
  },
  {
    sno: 4,
    name: 'USDT',
    tokenOrigin: 'Tether USD',
    icon: USDTIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x55d398326f99059fF775485246999027B3197955',
    pairWith: [3, 4, 1],
    gTokenAddress: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8'
  },
  {
    sno: 5,
    name: 'USDC',
    tokenOrigin: 'USD Coin',
    icon: USDCIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    gTokenAddress: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46'
  },
  {
    sno: 6,
    name: 'FRAX',
    marketSymbol: 'FRAX',
    tokenOrigin: 'FRAX Finance',
    icon: FRAXIcon,
    value: '0.00',
    usdValue: '0.00',
    gTokenAddress: '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
    address: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
    
  },
  {
    sno: 7,
    gTokenAddress: '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
    name: 'DAI',
    marketSymbol: 'DAI',
    tokenOrigin: 'Maker DAO',
    icon: DAIIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  },
  {
    sno: 8,
    gTokenAddress: '0xc019728cccdc45cdA6a051802878c00e563C1E78',
    name: 'AVAX',
    marketSymbol: 'AVAX',
    icon: AVAXIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
  },
  {
    sno: 9,
    name: 'CAKE',
    marketSymbol: 'CAKE',
    icon: CAKEIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    gTokenAddress: '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
  },
  {
    sno: 10,
    name: 'ADA',
    marketSymbol: 'ADA',
    icon: ADAIcon,
    tokenOrigin: 'Cardano',
    value: '0.00',
    usdValue: '0.00',
    address: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    gTokenAddress: '0x407e49480643E338ae36612b220f0607e90429Dc',
  },
  {
    sno: 11,
    name: 'XRP',
    marketSymbol: 'XRP',
    icon: XRPIcon,
    tokenOrigin: 'XRP',
    value: '0.00',
    usdValue: '0.00',
    token: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    gTokenAddress: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
  },
  {
    sno: 12,
    name: 'BNBx',
    tokenOrigin: 'Stader',
    icon: BNBxIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
    gTokenAddress: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428',
  },
]

export const LpAddressList: any = async (userAddress ?: any) => {
  const newLPAddressArray: any = await LpAddressListRevised(userAddress);
  //console.log("newLPAddressArray", newLPAddressArray)
  return newLPAddressArray;
  let res = [];
  const factoryInstance = await selectInstance(instanceType.PLANETFACTORY);
  const totalPairs = parseFloat(await factoryInstance.methods.allPairsLength().call());
  for (let i = 0; i < totalPairs; i++) {
    const pairAddress = await factoryInstance.methods.allPairs(i).call();
    const token0Address = await token1(pairAddress);
    const token1Address = await token2(pairAddress);
    const token1Instance: any = await selectInstance(instanceType.PLANETLP, token0Address);
    const token2Instance: any = await selectInstance(instanceType.PLANETLP, token1Address);
    let token0Symbol = await token1Instance.methods.symbol().call()
    let token1Symbol = await token2Instance.methods.symbol().call()

    token0Symbol = (token0Symbol === "WBNB") ? "BNB" : token0Symbol
    token1Symbol = (token1Symbol === "WBNB") ? "BNB" : token1Symbol

    res.push({
      sno: i,
      name: `${token0Symbol}/${token1Symbol}`,
      icon1: getTokenIcon(token0Symbol),
      icon2: getTokenIcon(token1Symbol),
      address: pairAddress
    })
    // console.log("response from LP address", res)
    if (i === totalPairs - 1)
      return res;
  }
}

export const LpAddressListRevised: any = async (userAddress?: any) => {

  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  let res = [];
  let targets: any = [];
  let callDatas: any = [];
  let results:any = [];
  let ouput_format : any = [];

  let pairAddressArray: any = [];
  const factoryInstance = new wallet.web3.eth.Contract(PLANETFACTORYABI, PLANETFACTORYADDRESS)
  const totalPairs = parseFloat(await factoryInstance.methods.allPairsLength().call());

  for (let i = 0; i < totalPairs; i++) {
    try{
      targets.push(PLANETFACTORYADDRESS);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(factoryInstance.methods.allPairs(i)._method,[i]));
      callDatas.push(data);
      ouput_format.push(factoryInstance.methods.allPairs(i)._method.outputs)
    } catch(error: any){
      console.log(i, error);
      return "";
    }
  }

  const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());

  const do_split = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }  

  for(let i = 0 ; i < aggregated_data[1].length ; i++){
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
  }
  const split_arr = (await do_split(results, totalPairs));
  pairAddressArray = split_arr[0];
  pairAddressArray.push({0:"0x93B32a8dfE10e9196403dd111974E325219aec24"})//hay-busd thena
  pairAddressArray.push({0:"0xD3C480EC7a47596fF8D63396227d1F7dC728A7f0"}) //btcb-bnb thena fusion
  pairAddressArray.push({0:"0x19420fa1D0E350b5d46a8D3B24Ff2421744eB54E"})//bnbx-usdt
  pairAddressArray.push({0: "0x10bf6e7B28b1cfFb1c047D7F815953931e5Ee947"})//eth-bnb
  pairAddressArray.push({0: "0x3ec1FFd5dc29190588608Ae9Fd4f93750e84CDA2"})//usdt-bnb fusion
  pairAddressArray.push({0: "0x2ecBD508c00Bbc8aA0cdc9100bf3956fCabE7677"}) //bnbx - bnb
  pairAddressArray.push({0: "0x754Fd74e22255780a58F125300008781D8318e3A"})//ankr-bnb
  pairAddressArray.push({0: "0xE0Aa23541960BdAF33Ac9601a28123b385554E59"})//hay-usdt
  pairAddressArray.push({0: "0x88e1cE1E3E0171c83150844bB2f0bD2266802dA2"}) //bnbx-usdt
  pairAddressArray.push({0: "0xDf0B9b59E92A2554dEdB6F6F4AF6918d79DD54c4"}) //hay-usdt
  pairAddressArray.push({0: "0x1820E39d1bE8Bf90fe45B097503613E557F5DFDF"}) //eth-bnb
  pairAddressArray.push({0: "0x4c4f18da91063F1c34a9396B2d97e95b163F2b0c"}) //btcb-bnb
  pairAddressArray.push({0: "0x89fD5DcF5D7C9FEbA8c8106A5Abc09325fC2a39e"}) //bnb-busd
  // pairAddressArray.push({0: "0xC9440dEb2a607A6f6a744a9d142b16eD824A1A3b"}) //3g
  pairAddressArray.push({0: "0xD328D129E46f9B978416599913102e8CD64593F3"}) //btcb-bnb fusion
  pairAddressArray.push({0: "0x483653bcf3a10d9a1c334ce16a19471a614f4385"}) //bnb-busd fusion
  pairAddressArray.push({0: "0x1d168C5b5DEa1c6dA0E9FD9bf4B7607e4e9D8EeC"}) //bnb-eth fusion
  pairAddressArray.push({0: "0x618f9Eb0E1a698409621f4F487B563529f003643"}) //usdt-usdc fusion
  pairAddressArray.push({0: "0x6BE6A437A1172e6C220246eCB3A92a45AF9f0Cbc"}) //usdt-bnb fusion
  pairAddressArray.push({0: "0x6c83E45fE3Be4A9c12BB28cB5BA4cD210455fb55"}) //bnbx-bnb fusion
  // pairAddressArray.push({0: "0x19420fa1D0E350b5d46a8D3B24Ff2421744eB54E"}) //bnbx-usdt fusion
  // pairAddressArray.push({0: "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991"}) //aqua pool
  // console.log("pairAddressArray", pairAddressArray)

  let token0Array: any = [];
  let token1Array: any = [];
  let lpTokenBalanceArray: any = [];
  let targets_1: any = [];
  let callDatas_1: any = [];
  let results_1 :any = [];
  let ouput_format_1 : any = [];

  let dummyPairAddress: string = "0xFc7AC742C051Ca19B9eA5725280eAAB5b3073534"; 
  const tokenInstance = new wallet.web3.eth.Contract(pancakeLPabi, dummyPairAddress)
  pairAddressArray.forEach(async(pairAddress: any, index: any) => {
    try{
      let wantAddress = pairAddress[0].toLowerCase() == aquaAddress.toLowerCase() ? "0x43d427dF6957e180497248CaDc30cE7aa716FE52" : pairAddress[0];
      targets_1.push(wantAddress);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.token0()._method,[]));
      callDatas_1.push(data);
      ouput_format_1.push(tokenInstance.methods.token0()._method.outputs)
    } catch(error: any){
      console.log(pairAddress, error)
    }
  })
  pairAddressArray.forEach(async(pairAddress: any, index: any) => {
    try{
      let wantAddress = pairAddress[0].toLowerCase() == aquaAddress.toLowerCase() ? "0x43d427dF6957e180497248CaDc30cE7aa716FE52" : pairAddress[0];
      targets_1.push(wantAddress);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.token1()._method,[]));
      callDatas_1.push(data);
      ouput_format_1.push(tokenInstance.methods.token1()._method.outputs)
    } catch(error: any){
      console.log(pairAddress, error)
    }
  })
  if(userAddress !== undefined) {
    // console.log("in here", userAddress)
    pairAddressArray.forEach( async (pairAddress: any) => {
      const lp_instance = new wallet.web3.eth.Contract(PLANETLPABI, pairAddress[0]);
      targets_1.push(pairAddress[0]);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(lp_instance.methods.balanceOf(userAddress)._method,[userAddress]));
      callDatas_1.push(data);
      ouput_format_1.push(lp_instance.methods.balanceOf(userAddress)._method.outputs)
    })
  }
  
  // console.log(targets_1,callDatas_1)
  const aggregated_data_1 = (await multicall_inst.methods.aggregate(targets_1,callDatas_1).call());

  const do_split_1 = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split_1(array, n)) : [];
  }  

  for(let i = 0 ; i < aggregated_data_1[1].length ; i++){
    results_1.push(wallet.web3.eth.abi.decodeParameters(ouput_format_1[i], aggregated_data_1[1][i]))
  }
  const split_arr_1 = (await do_split(results_1, pairAddressArray.length));
  token0Array = split_arr_1[0]
  token1Array = split_arr_1[1]
  lpTokenBalanceArray = split_arr_1[2] !== undefined ? split_arr_1[2] : [];
  // console.log("split_arr",split_arr_1)
  // console.log("token one array",token1Array)
  for(let i = 0 ; i < pairAddressArray.length; i++){
    
    let token0Address: string = token0Array[i][0].toLowerCase();
    let token1Address: string = token1Array[i][0].toLowerCase();
    if(pairAddressArray[i][0].toLowerCase() == cakeTokenAddress.toLowerCase()){
      token0Address = zeroAddress;
      token1Address = zeroAddress;
    }
    // console.log("inside for loop", token0Address, token1Address)
    let token0Symbol = pairAddressArray[i][0].toLowerCase() == aquaAddress.toLowerCase() ? "AQUA" :  pfTokenListLowercase[token0Address] ? pfTokenListLowercase[token0Address].name : "TOK1";
    let token1Symbol = pairAddressArray[i][0].toLowerCase() == aquaAddress.toLowerCase() ? '' : pfTokenListLowercase[token1Address] ? pfTokenListLowercase[token1Address].name : "TOK2";
    res.push({
      sno: i,
      name: pairAddressArray[i][0] == "0xDf0B9b59E92A2554dEdB6F6F4AF6918d79DD54c4" ? `${token0Symbol}/${token1Symbol}  (Fusion)` : pairAddressArray[i][0].toLowerCase() == aquaAddress.toLowerCase() ? "AQUA" : `${token0Symbol}/${token1Symbol}`,
      icon1: token0Symbol !== "TOK1" ? getTokenIcon(token0Symbol) : "",
      icon2: token1Symbol !== "TOK2" ? getTokenIcon(token1Symbol) : "",
      address: pairAddressArray[i][0],
      token0Address: pairAddressArray[i][0].toLowerCase() == aquaAddress.toLowerCase() ? aquaAddress : token0Address,
      token1Address: token1Address,
      lpBalance: lpTokenBalanceArray[i][0]
    })
    // console.log(i, res);
  }
  
  return res;


}

export const belt4TokenList = [
  {
    sno: 1,
    name: 'BNB',
    tokenOrigin: 'Binance Coin',
    icon: BNBIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    pairWith: [0],
  },
  {
    sno: 2,
    name: 'BUSD',
    tokenOrigin: 'Binance USD',
    icon: BUSDIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    pairWith: [0],
  },
  {
    sno: 3,
    name: 'USDT',
    tokenOrigin: 'Tether',
    icon: USDTIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x55d398326f99059fF775485246999027B3197955',
    pairWith: [0],
  },
  {
    sno: 4,
    name: 'USDC',
    tokenOrigin: 'USD Coin',
    icon: USDCIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    pairWith: [0],
  },
  {
    sno: 5,
    name: 'DAI',
    tokenOrigin: 'MakerDAO',
    icon: DAIIcon,
    value: '0.00',
    usdValue: '0.00',
    address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    pairWith: [0],
  },
]


export const returnTokenList = async (userAddress: any) => {
  let results = userAddress ? await returnTokenListMulticall(userAddress) : swapTokenList;
  return results;
}

export const returnTokenListForAddLiquidity = async (userAddress: any) => {
  let results = userAddress ? await returnTokenListMulticall(userAddress) : swapTokenList;
  const tokenList: any = [];
  let addLiquidityTokenArray = ["0xb3cb6d2f8f2fde203a022201c81a96c167607f15", "0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991", "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c", "0x55d398326f99059ff775485246999027b3197955", "0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5", "0x2170ed0880ac9a755fd29b2688956bd959f933f8", "0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275"]
  results.map((token: any) => {

    if(addLiquidityTokenArray.includes(token.address.toLowerCase())){
      tokenList.push(token)
    }
    
  })
  return tokenList;
}

export const returnTokenListMulticall = async (userAddress: any) => {
  try {
    const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
    const lpInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, BUSD_BNBLPAddress);
    const factory_inst: any = new wallet.web3.eth.Contract(pancakeFactoryAbi, pancakFactoryAddress);
    const token_inst: any = new wallet.web3.eth.Contract(gBnbAbi, gammaAddress);
    
    let res = [];
    let targets: any = [];
    let callDatas: any = [];
    let results:any = [];
    let ouput_format : any = [];

    let tokenBalanceArray: any = [];
    let tokenPriceArray: any = [];
    let lpReserves: any = [];
    let token0Array: any = [];

    // balance
    tokenSwapList.forEach(async (token: any) => {
      // console.log(userAddress)
      let tokenAddress = token.address.toLowerCase() == bnbAddress ? gammaAddress : token.address;
      targets.push(tokenAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.balanceOf(userAddress)._method, [userAddress])
      callDatas.push(data)
      ouput_format.push(token_inst.methods.balanceOf(userAddress)._method.outputs)
    })

    const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());

    const do_split = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }  

    for(let i = 0 ; i < aggregated_data[1].length ; i++){
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = (await do_split(results, tokenSwapList.length));
    // console.log("new split data", split_arr, tokenSwapList)
    tokenBalanceArray = split_arr[0];
    // lpReserves = split_arr[1];
    // token0Array = split_arr[2];
    let bnbBalance = await wallet.web3.eth.getBalance(userAddress);
    let token_price_array: any = await returnTokenPriceMulticall();//[];
    let tokenDetailsArray: any = [];
    for(let i = 0; i < tokenSwapList.length; i++){
      let tokenAddress = tokenSwapList[i].address.toLowerCase();
      let token_balance = tokenAddress !== bnbAddress.toLowerCase() ? tokenBalanceArray[i][0] : bnbBalance;
      // tokenBalanceArray[tokenAddress] = convertToEtherRevised(token_balance, 1e18)
      token_balance = convertToEtherRevised(token_balance, 1e18)
      // console.log("token balance", token_balance, tokenSwapList[i].name, i)
      
      let tokenDetails: any = tokenSwapList[i]
      tokenDetails.value = token_balance;//tokenBalanceArray[tokenAddress] ? (tokenBalanceArray[tokenAddress]) : 0;
      tokenDetails.price = token_price_array[tokenAddress] ? token_price_array[tokenAddress] : 0;
      
      tokenDetails.usdValue = token_balance !== undefined && tokenDetails.price !== undefined ? (parseFloat(token_balance) * parseFloat(tokenDetails.price)): '0.00';
      tokenDetails.maxBalanceInWei = token_balance;
      tokenDetailsArray.push(tokenDetails);
    }
   
    let tokenArr: any = tokenDetailsArray.sort((a: any, b: any) => (parseFloat(b.usdValue) > parseFloat(a.usdValue)) ? 1 : (parseFloat(a.usdValue) > parseFloat(b.usdValue) ? -1 : 0))
    // console.log(tokenArr)
    return tokenArr
  } catch(error) {
    console.log(error);
    return swapTokenList;
  }
  
}

export const returnTokenPriceMulticall = async () => {
  try {
    const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
    const lpInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, BUSD_BNBLPAddress);
    const factory_inst: any = new wallet.web3.eth.Contract(pancakeFactoryAbi, pancakFactoryAddress);
    // if(tokenSwapList[0].sno !== 1){
    //   let firstElement = tokenSwapList[0];
    //   tokenSwapList.shift();
    //   tokenSwapList.push(firstElement);
    // }
    

    let res = [];
    let targets: any = [];
    let callDatas: any = [];
    let results:any = [];
    let ouput_format : any = [];

    let targets_: any = [];
    let callDatas_: any = [];
    let results_:any = [];
    let ouput_format_ : any = [];

    let lp_pair_array: any = [];

    //fetch lp pair
    tokenSwapList.forEach(async(token) => {
      const token1 = bnbAddress;
      const token2 = token.address.toLowerCase() == bnbAddress.toLowerCase() || token.address.toLowerCase() == gammaAddress.toLowerCase() || token.address.toLowerCase() == aquaAddress.toLowerCase() || token.address.toLowerCase() == bnbxAddress.toLowerCase() ? busdAddress : token.address;
      targets_.push(pancakFactoryAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(factory_inst.methods.getPair(token1, token2)._method, [token1, token2])
      callDatas_.push(data)
      ouput_format_.push(factory_inst.methods.getPair(token1, token2)._method.outputs)
      
    })

    const aggregated_data_ = (await multicall_inst.methods.aggregate(targets_,callDatas_).call());

    const do_split_ = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split_(array, n)) : [];
    }  

    for(let i = 0 ; i < aggregated_data_[1].length ; i++){
      results_.push(wallet.web3.eth.abi.decodeParameters(ouput_format_[i], aggregated_data_[1][i]))
    }
    const split_arr_ = (await do_split_(results_, tokenSwapList.length));
    // console.log("get pair array",split_arr_)
    lp_pair_array = split_arr_[0];
    let lp_pair_address_array : any = [];
    
    for(let i = 0 ; i < tokenSwapList.length ; i++){
      lp_pair_address_array[tokenSwapList[i].address.toLowerCase()] = tokenSwapList[i].address.toLowerCase() == bnbAddress.toLowerCase() ? BUSD_BNBLPAddress : tokenSwapList[i].address.toLowerCase() == gammaAddress.toLowerCase() ? GAMMA_BNBLPAddress : tokenSwapList[i].address.toLowerCase() == aquaAddress.toLowerCase() ? AQUA_BNBLPAddress : tokenSwapList[i].address.toLowerCase() == bnbxAddress.toLowerCase() ? "0x6c83E45fE3Be4A9c12BB28cB5BA4cD210455fb55" : lp_pair_array[i][0];
    }
    // console.log("LP pair array", lp_pair_address_array)

    let tokenPriceArray: any = [];
    let lpReserves: any = [];
    let token0Array: any = [];

    //reserves
    tokenSwapList.forEach(async (token: any) => {
      let lpAddress = lp_pair_address_array[token.address.toLowerCase()];
      targets.push(lpAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(lpInstance.methods.getReserves()._method, [])
      callDatas.push(data)
      ouput_format.push(lpInstance.methods.getReserves()._method.outputs)
    })

    // token0
    tokenSwapList.forEach(async (token: any) => {
      // const data = await tokenInstance.methods.token0().call()
      let lpAddress = lp_pair_address_array[token.address.toLowerCase() == bnbAddress.toLowerCase || token.address.toLowerCase() == gammaAddress.toLowerCase() || token.address.toLowerCase() == aquaAddress.toLowerCase() ? busdAddress.toLowerCase() : token.address.toLowerCase()];
      targets.push(lpAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(lpInstance.methods.token0()._method, [])
      callDatas.push(data)
      ouput_format.push(lpInstance.methods.token0()._method.outputs)
    })

    const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());

    const do_split = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }  

    for(let i = 0 ; i < aggregated_data[1].length ; i++){
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = (await do_split(results, tokenSwapList.length));
    // console.log("new split data", split_arr)
    lpReserves = split_arr[0];
    token0Array = split_arr[1];

    let token_price_array: any = [];
    let tokenDetailsArray: any = [];
    for(let i = 0; i < tokenSwapList.length; i++){
      let tokenAddress = tokenSwapList[i].address.toLowerCase();
      if(tokenAddress == bnbAddress.toLowerCase()){
        const getBNBReserve = lpReserves[i]._reserve0
        const getUSDReserve = lpReserves[i]._reserve1
        token_price_array[tokenAddress] = getUSDReserve / getBNBReserve
      }

      if(tokenAddress == gammaAddress.toLowerCase()) {
        const reserve0 = lpReserves[i]._reserve0;
        const reserve1 = lpReserves[i]._reserve1;
        const bnbPerGAMMA = reserve1 / reserve0
        token_price_array[tokenAddress] = bnbPerGAMMA * token_price_array[bnbAddress.toLowerCase()]
      }

      if(tokenAddress == aquaAddress.toLowerCase()) {
        const reserve0 = lpReserves[i]._reserve0;
        const reserve1 = lpReserves[i]._reserve1;
        const bnbPerAQUA = reserve1 / reserve0
        token_price_array[tokenAddress] = bnbPerAQUA * token_price_array[bnbAddress.toLowerCase()]
      }

      if(tokenAddress == bnbxAddress.toLowerCase()) {
        const reserve0 = lpReserves[i]._reserve0;
        const reserve1 = lpReserves[i]._reserve1;
        const bnbPerBNBx = reserve1 / reserve0
        token_price_array[tokenAddress] = bnbPerBNBx * token_price_array[bnbAddress.toLowerCase()]
      }

      if(tokenAddress !== bnbAddress.toLowerCase() && tokenAddress !== aquaAddress.toLowerCase() && tokenAddress !== gammaAddress.toLowerCase() && tokenAddress !== bnbxAddress.toLowerCase()){
        let getReserve0: any, getReserve1: any
        // console.log(token0Array)
        if (tokenAddress.toLowerCase() === token0Array[i][0].toLowerCase()) {
          getReserve0 = lpReserves[i]._reserve0;
          getReserve1 = lpReserves[i]._reserve1;
        } else {
          getReserve0 = lpReserves[i]._reserve1;
          getReserve1 = lpReserves[i]._reserve0;
        }
        const reservesRatio = getReserve0 / getReserve1
        token_price_array[tokenAddress] = token_price_array[bnbAddress.toLowerCase()] / reservesRatio
      }
    }
    // console.log("token price array", token_price_array)
    return token_price_array;
  } catch(error) {
    console.log(error);
    return [];
  }
  
}

export const returnTransformTokenList = async (userAddress: any) => {
  //console.log(await retTotSuppAndUserBal())
  let results = userAddress ? await returnTokenListMulticall(userAddress) : swapTokenList;
  return results;
  let result: any = []
  let tokenData: any = []
  transformTokenList.forEach(async (element) => {
    let tokenStatus: any
    if (element.sno === bnbSno) tokenStatus = await tokenBalance(element.address, userAddress, 0)
    else tokenStatus = await tokenBalance(element.address, userAddress, 1)
    let tokenPriceUsd: any = 0
    if (element.name === 'GAMMA') {
      tokenPriceUsd = await getGammaPrice()
    }
    else {
      tokenPriceUsd = await getTokenPrice(element.address)
    }
    // console.log("token details in transform", element.name, tokenStatus, tokenPriceUsd, element.address, userAddress )
    const usdVal: any = !!tokenStatus && !!tokenPriceUsd ? parseFloat(tokenStatus) * parseFloat(tokenPriceUsd) : '0.00'
    element.value = tokenStatus
    element.usdValue = usdVal
    element.price = tokenPriceUsd;
    tokenData.push(element)
  })
  result = tokenData
  return result
}

export const returnConvertLPTokenList = async (userAddress: any) => {
  let results = userAddress ? await returnTokenListMulticall(userAddress) : swapTokenList;
  return results;
  //console.log(await retTotSuppAndUserBal())
  let result: any = []
  let tokenData: any = []
  convertLPTokenList.forEach(async (element) => {
    let tokenStatus: any
    if (element.sno === bnbSno) tokenStatus = await tokenBalance(element.address, userAddress, 0)
    else tokenStatus = await tokenBalance(element.address, userAddress, 1)
    let tokenPriceUsd: any = 0
    if (element.name === 'GAMMA') {
      tokenPriceUsd = await getGammaPrice()
    }
    else {
      tokenPriceUsd = await getTokenPrice(element.address)
    }
    // console.log("token details in transform", element.name, tokenStatus, tokenPriceUsd, element.address, userAddress )
    const usdVal: any = !!tokenStatus && !!tokenPriceUsd ? parseFloat(tokenStatus) * parseFloat(tokenPriceUsd) : '0.00'
    element.value = tokenStatus
    element.usdValue = usdVal
    element.price = tokenPriceUsd;
    tokenData.push(element)
  })
  result = tokenData
  return result
}

export const returnSwapTokenList = async (userAddress: any) => {
  let results = userAddress ? await returnTokenListMulticall(userAddress) : swapTokenList;
  return results;
  //console.log(await retTotSuppAndUserBal())
  let result: any = []
  let tokenData: any = []
  swapTokenList.forEach(async (element: any) => {
    let tokenStatus: any;
    if (element.sno === bnbSno) tokenStatus = await tokenBalance(element.address, userAddress, 0)
    else tokenStatus = await tokenBalance(element.address, userAddress, 1)
    // console.log("element in returnSwapTokenList", element, tokenStatus)
    let tokenPriceUsd: any = 0
    if (element.name === 'GAMMA') {
      tokenPriceUsd = await getGammaPrice()
    }
    else {
      tokenPriceUsd = await getTokenPrice(element.address)
    }
    const usdVal: any = !!tokenStatus && !!tokenPriceUsd ? parseFloat(tokenStatus) * parseFloat(tokenPriceUsd) : '0.00'
    element.value = tokenStatus
    element.usdValue = usdVal
    tokenData.push(element)
  })
  result = tokenData
  return result
}

export const returnTokenListOnCreateLp = async (userAddress: any) => {
  let result: any = []
  let tokenData: any = []
  tokenList.forEach(async (element) => {
    let tokenStatus: any
    if (element.sno === bnbSno) tokenStatus = await tokenBalance(element.address, userAddress, 0)
    else tokenStatus = await tokenBalance(element.address, userAddress, 1)
    let tokenPriceUsd: any = 0
    if (element.name === 'GAMMA') {
      tokenPriceUsd = await getGammaPrice()
    }
    else {
      tokenPriceUsd = await getTokenPrice(element.address)
    }
    const usdVal: any = !!tokenStatus && !!tokenPriceUsd ? parseFloat(tokenStatus) * parseFloat(tokenPriceUsd) : '0.00'
    element.value = tokenStatus
    element.usdValue = usdVal
    tokenData.push(element)
  })
  result = tokenData
  return result
}
export const returnTokenListOnSwapAndAddLiquidity = async (selectedTokenAddress: any, userAddress: any) => {
  let result: any = []
  let tokenData: any = []
  tokenList.forEach(async (element) => {
    if (selectedTokenAddress !== element.address) {
      const pairExist = await returnPairExistOrNot(selectedTokenAddress, element.address)
      if (pairExist) {
        let tokenStatus: any
        if (element.sno === bnbSno) tokenStatus = await tokenBalance(element.address, userAddress, 0)
        else tokenStatus = await tokenBalance(element.address, userAddress, 1)
        let tokenPriceUsd: any = 0
        if (element.name === 'GAMMA') {
          tokenPriceUsd = await getGammaPrice()
        }
        else {
          tokenPriceUsd = await getTokenPrice(element.address)
        }
        const usdVal: any = !!tokenStatus && !!tokenPriceUsd ? parseFloat(tokenStatus) * parseFloat(tokenPriceUsd) : '0.00'
        element.value = tokenStatus
        element.usdValue = usdVal
        tokenData.push(element)
      }
    }
  })
  result = tokenData
  return result
}

export const returnBelt4TokenList = async (userAddress: any) => {
  //console.log(await retTotSuppAndUserBal())
  let result: any = []
  let tokenData: any = []
  belt4TokenList.forEach(async (element) => {
    let tokenStatus: any
    if (element.sno === bnbSno) tokenStatus = await tokenBalance(element.address, userAddress, 0)
    else tokenStatus = await tokenBalance(element.address, userAddress, 1)
    let tokenPriceUsd: any = 0
    if (element.name === 'GAMMA') {
      tokenPriceUsd = await getGammaPrice()
    }
    else {
      tokenPriceUsd = await getTokenPrice(element.address)
    }
    const usdVal: any = !!tokenStatus && !!tokenPriceUsd ? parseFloat(tokenStatus) * parseFloat(tokenPriceUsd) : '0.00'
    element.value = tokenStatus
    element.usdValue = usdVal
    tokenData.push(element)
  })
  result = tokenData
  return result
}


// export const tokenList = [
//   {
//     sno: 0,
//     name: 'AQUA',
//     icon: AQUAIcon,
//     value: '0.00',
//     address: '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
//     pairWith: [1],
//   },
//   {
//     sno: 1,
//     name: 'BNB',
//     icon: BNBIcon,
//     value: '0.00',
//     address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
//     pairWith: [0,2],
//   },
//   {
//     sno: 2,
//     name: 'ACAC',
//     icon: AQUAIcon,
//     value: '0.00',
//     address: '0x82c41B3B276f4dbDc4fb8d067601B4c3A4B336ED',
//     pairWith : [1,3]
//   },
//   {
//     sno: 3,
//     name: 'CAKE',
//     icon: CAKEIcon,
//     value: '0.00',
//     address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
//     pairWith: [2],
//   },
// ]

// export const LpAddressList: any = [
// {
//   sno: 0,
//   name: 'AQUA/BNB',
//   icon1: AQUAIcon,
//   icon2: BNBIcon,
//   address: '0x03028D2F8B275695A1c6AFB69A4765e3666e36d9',
// },
// {
//   sno: 1,
//   name: 'BNB/ACAC',
//   icon1: BNBIcon,
//   icon2: AQUAIcon,
//   address: '0x35c8469600230766A11B645A9Ffb7a3E2B4FcA96',
// },
// {
//   sno: 2,
//   name: 'ACAC/CAKE',
//   icon1: AQUAIcon,
//   icon2: CAKEIcon,
//   address: '0x85AA3445f6aA905B81ebFBa94F107a5CCe7619aB',
// },
// ]


export const planetAmmTradeList = {
  "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c":
    [
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    ]
  ,
  "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991_0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82":
    [
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    ]
  ,
  "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991_0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":
    [
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    ]
  ,
  "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991_0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d":
    [
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
    ]
  ,
  "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991_0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c":
    [
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'
    ]
  ,
  "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991_0x2170Ed0880ac9A755fd29B2688956BD959F933F8":
    [
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
    ]
  ,
  "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991_0x23396cF899Ca06c4472205fC903bDB4de249D6fC":
    [
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC'
    ]
  ,
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991":
    [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991'
    ]
  ,
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82":
    [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    ]
  ,
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":
    [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    ]
  ,
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d":
    [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
    ]
  ,
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c":
    [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'
    ]
  ,
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0x2170Ed0880ac9A755fd29B2688956BD959F933F8":
    [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
    ]
  ,
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0x23396cF899Ca06c4472205fC903bDB4de249D6fC":
    [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC'
    ]
  ,
  "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82_0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991":
    [
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991'
    ]
  ,
  "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c":
    [
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    ]
  ,
  "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82_0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":
    [
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    ]
  ,
  "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82_0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d":
    [
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
    ]
  ,
  "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82_0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c":
    [
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'
    ]
  ,
  "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82_0x2170Ed0880ac9A755fd29B2688956BD959F933F8":
    [
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
    ]
  ,
  "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82_0x23396cF899Ca06c4472205fC903bDB4de249D6fC":
    [
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC'
    ]
  ,
  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56_0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991":
    [
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991'
    ]
  ,
  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c":
    [
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    ]
  ,
  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56_0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82":
    [
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    ]
  ,
  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56_0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d":
    [
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
    ]
  ,
  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56_0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c":
    [
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'
    ]
  ,
  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56_0x2170Ed0880ac9A755fd29B2688956BD959F933F8":
    [
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
    ]
  ,
  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56_0x23396cF899Ca06c4472205fC903bDB4de249D6fC":
    [
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC'
    ]
  ,
  "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d_0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991":
    [
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991'
    ]
  ,
  "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c":
    [
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    ]
  ,
  "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d_0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82":
    [
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    ]
  ,
  "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d_0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":
    [
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    ]
  ,
  "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d_0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c":
    [
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'
    ]
  ,
  "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d_0x2170Ed0880ac9A755fd29B2688956BD959F933F8":
    [
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
    ]
  ,
  "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d_0x23396cF899Ca06c4472205fC903bDB4de249D6fC":
    [
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC'
    ]
  ,
  "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c_0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991":
    [
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991'
    ]
  ,
  "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c":
    [
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    ]
  ,
  "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c_0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82":
    [
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    ]
  ,
  "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c_0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":
    [
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    ]
  ,
  "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c_0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d":
    [
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
    ]
  ,
  "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c_0x2170Ed0880ac9A755fd29B2688956BD959F933F8":
    [
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
    ]
  ,
  "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c_0x23396cF899Ca06c4472205fC903bDB4de249D6fC":
    [
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC'
    ]
  ,
  "0x2170Ed0880ac9A755fd29B2688956BD959F933F8_0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991":
    [
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991'
    ]
  ,
  "0x2170Ed0880ac9A755fd29B2688956BD959F933F8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c":
    [
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    ]
  ,
  "0x2170Ed0880ac9A755fd29B2688956BD959F933F8_0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82":
    [
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    ]
  ,
  "0x2170Ed0880ac9A755fd29B2688956BD959F933F8_0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":
    [
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    ]
  ,
  "0x2170Ed0880ac9A755fd29B2688956BD959F933F8_0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d":
    [
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
    ]
  ,
  "0x2170Ed0880ac9A755fd29B2688956BD959F933F8_0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c":
    [
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'
    ]
  ,
  "0x2170Ed0880ac9A755fd29B2688956BD959F933F8_0x23396cF899Ca06c4472205fC903bDB4de249D6fC":
    [
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC'
    ]
  ,
  "0x23396cF899Ca06c4472205fC903bDB4de249D6fC_0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991":
    [
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991'
    ]
  ,
  "0x23396cF899Ca06c4472205fC903bDB4de249D6fC_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c":
    [
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    ]
  ,
  "0x23396cF899Ca06c4472205fC903bDB4de249D6fC_0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82":
    [
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    ]
  ,
  "0x23396cF899Ca06c4472205fC903bDB4de249D6fC_0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":
    [
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    ]
  ,
  "0x23396cF899Ca06c4472205fC903bDB4de249D6fC_0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d":
    [
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
    ]
  ,
  "0x23396cF899Ca06c4472205fC903bDB4de249D6fC_0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c":
    [
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'
    ]
  ,
  "0x23396cF899Ca06c4472205fC903bDB4de249D6fC_0x2170Ed0880ac9A755fd29B2688956BD959F933F8":
    [
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
    ]
}
