import { useState, useContext } from 'react'
import { FarmPoolContext } from 'context/FarmPoolContext'
import { FlexSBCont } from 'shared/styles/styled'
import { ExpandedCell, ExpendCellTitle, TableInfoRows, TableInfoBox, TableInfoTxt, CellDownIcon } from 'shared/styles/planetTableStyle'
import DownIcon from 'assets/icons/down.svg'
import { commaFy } from 'service/globalFunctions'
import InfoTooltip from 'shared/info-tool-tip'
import { useSelector } from 'react-redux'
import { allFusionPools, gammaFarmAdddress, gammaFarmAdddressOld } from 'modules/block-chain/abi'


const PoolInfo = (props: any) => {
    const [showVaultInfo, setShowVaultInfo] = useState(false)
    const [showFeeInfo, setShowFeeInfo] = useState(false)
    const [showAPYCal, setShowAPyCal] = useState(false)
    const farmPoolData:any = useContext(FarmPoolContext);
    const selector = useSelector((state: any) => state)
    const { data } = props
    const { poolLoading, poolData, portfolioLoading, userPortfolio, aquaInfDataNew } = selector.user

    const _renderVaultInfo = () => {
        let allocPoint = data.poolAllocPoint;
        let totalAllocPoint = farmPoolData.totalAllocPoint;
        let gammaPerBlock = (farmPoolData.gammaPerBlock) / 1e18;
        let gammaPerDay = (gammaPerBlock * 28800 * (allocPoint / totalAllocPoint)).toFixed(0);

        return (
            <TableInfoBox>
                <FlexSBCont>
                    <TableInfoTxt light>{data.name} Price</TableInfoTxt>
                    <TableInfoTxt>${commaFy(parseFloat(data.price).toFixed(2))}</TableInfoTxt>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Farm Contract</TableInfoTxt>
                    <a href={data.farmContract} target="_blank" rel="noreferrer">
                        <TableInfoTxt>View</TableInfoTxt>
                    </a>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Vault Contract</TableInfoTxt>
                    {}
                    <a href={data.vaultContract} target="_blank" rel="noreferrer">
                        <TableInfoTxt>View</TableInfoTxt>
                    </a>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Vault Multiplier</TableInfoTxt>
                    <TableInfoTxt>{data.vaultMultiplier}X</TableInfoTxt>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Vault Type</TableInfoTxt>
                    <TableInfoTxt>Locked</TableInfoTxt>
                </FlexSBCont>
                
            </TableInfoBox>
        )
    }

    const _renderFeeInfo = () => {
        if(data.poolId >= 2 || ( data.farmContractAddress != undefined && data.farmContractAddress.toLowerCase() == gammaFarmAdddressOld.toLowerCase())) {
            return (
                <TableInfoBox>
                    <FlexSBCont >
                        <TableInfoTxt light>Deposit Fee</TableInfoTxt>
                        <TableInfoTxt>{data.depositFees}%</TableInfoTxt>
                    </FlexSBCont>
                    <FlexSBCont>
                        <TableInfoTxt light>Withdraw Fee</TableInfoTxt>
                        <TableInfoTxt>{data.withdrawalFees}%</TableInfoTxt>
                    </FlexSBCont>
                    {/* {data.farmName === "PCS" ?
                    <FlexSBCont>
                        <TableInfoTxt light>Performance Fee</TableInfoTxt>
                        <TableInfoTxt>{data.burnFees}%</TableInfoTxt>
                    </FlexSBCont>
                    :""
                    */}
                    {+data.performanceFee > 0 ?
                    <FlexSBCont>
                        <TableInfoTxt className="aggregationText" light>Performance Fee <InfoTooltip infoId='aggregationFee' left={5} /></TableInfoTxt>
                        <TableInfoTxt>{data.performanceFee}%</TableInfoTxt>
                    </FlexSBCont>
                    :""
                    }
                </TableInfoBox>
            )
        }
        else {
            return (
                <TableInfoBox>
                    <FlexSBCont >
                        <TableInfoTxt light>Deposit Fee</TableInfoTxt>
                        <TableInfoTxt>{data.depositFees}%</TableInfoTxt>
                    </FlexSBCont>
                    <FlexSBCont>
                        <TableInfoTxt light>Unstake Fee</TableInfoTxt>
                        <TableInfoTxt>{data.withdrawalFees}%</TableInfoTxt>
                    </FlexSBCont>
                    {+data.performanceFee > 0 ?
                    <FlexSBCont>
                        <TableInfoTxt className="aggregationText" light>Performance Fee <InfoTooltip infoId='aggregationFee' left={5} /></TableInfoTxt>
                        <TableInfoTxt>{data.performanceFee}%</TableInfoTxt>
                    </FlexSBCont>
                    :""
                    }
                    {/* <FlexSBCont>
                        <TableInfoTxt light>Instant Unstake Fee</TableInfoTxt>
                        <TableInfoTxt>{data.instantWithdrawFee}%</TableInfoTxt>
                    </FlexSBCont> */}
                </TableInfoBox>
            )
        }
        
    }

    const _renderAPYCalculation = () => {

        return (
            <TableInfoBox>
                <FlexSBCont>
                    <TableInfoTxt light>Trade Fee APR (Auto Compounding)</TableInfoTxt>
                    <TableInfoTxt>{(parseFloat(data.tradeFeeApr) * 100 ).toFixed(2)}%</TableInfoTxt>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Farm GAMMA APR</TableInfoTxt>
                    <TableInfoTxt>{data.gammaApr && (!isFinite(data.gammaApr) || data.gammaApr > 10000) ? "> 10,000" : data.gammaApr ? commaFy(data.gammaApr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                </FlexSBCont>
                {data.parentFarmApr > 0 &&
                <FlexSBCont>
                    <TableInfoTxt light>Parent Farm APR</TableInfoTxt>
                    <TableInfoTxt>{(data.parentFarmApr).toFixed(2)}%</TableInfoTxt>
                </FlexSBCont>
                }
                <FlexSBCont>
                    <TableInfoTxt light>Total APR</TableInfoTxt>
                    <TableInfoTxt>{data.totalApr && (!isFinite(data.totalApr) || data.totalApr > 10000) ? "> 10,000" : data.totalApr ? commaFy(data.totalApr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                </FlexSBCont>
            </TableInfoBox>
        )
            
    }
     
    

    return (
        <TableInfoRows>
            <ExpandedCell className="item1">
                <div className="on-web">
                    <ExpendCellTitle>Pool Details</ExpendCellTitle>
                    {_renderVaultInfo()}
                </div>
                <div className="on-mob">
                    <FlexSBCont onClick={() => setShowVaultInfo(!showVaultInfo)}>
                        <ExpendCellTitle>Vault Details</ExpendCellTitle>
                        <CellDownIcon activeStatus={showVaultInfo}><img src={DownIcon} alt="" /></CellDownIcon>
                    </FlexSBCont>
                    {showVaultInfo && _renderVaultInfo()}
                </div>
            </ExpandedCell>
            <ExpandedCell className="item2">
                <div className="on-web">
                    <ExpendCellTitle>Fees</ExpendCellTitle>
                    {_renderFeeInfo()}
                </div>
                <div className="on-mob">
                    <FlexSBCont onClick={() => setShowFeeInfo(!showFeeInfo)}>
                        <ExpendCellTitle>Fees</ExpendCellTitle>
                        <CellDownIcon activeStatus={showFeeInfo}><img src={DownIcon} alt="" /></CellDownIcon>
                    </FlexSBCont>
                    {showFeeInfo && _renderFeeInfo()}
                </div>
            </ExpandedCell>
            <ExpandedCell className="item3">
                <div className="on-web">
                    <ExpendCellTitle>APR Calculations</ExpendCellTitle>
                    {_renderAPYCalculation()}
                </div>
                <div className="on-mob">
                    <FlexSBCont onClick={() => setShowAPyCal(!showAPYCal)}>
                        <ExpendCellTitle>APR Calculations</ExpendCellTitle>
                        <CellDownIcon activeStatus={showAPYCal}><img src={DownIcon} alt="" /></CellDownIcon>
                    </FlexSBCont>
                    {showAPYCal && _renderAPYCalculation()}
                </div>
            </ExpandedCell>
        </TableInfoRows>
    )
}
export default PoolInfo
