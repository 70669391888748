import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { PlanetCard } from 'shared/styles/styled'

export const VaultsContainer = styled.div`
  position: relative;
  .title-info{
      margin-top: 10px;
      @media (min-width: ${screenSizes.mediaM}px) {
        margin-top: 20px;
      }
  }
`

export const MobileDiv = styled.span`
  display: none;
  @media (max-width: ${screenSizes.mediaM}px) {
    display: block;
  }
`;

export const WebDiv = styled.span`
  display: none;
  @media (min-width: 801px) {
    display: block;
  }
`;

export const VaultsHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: ${screenSizes.mediaM}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`

export const GammaEarnedBox = styled(PlanetCard)`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  height: auto;
  margin-top: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
      height: 80px;
      margin-top: 0;
    }
  img{
      width: 44px;
      height: 44px;
      margin-right: 10px;
      @media (min-width: ${screenSizes.mediaM}px) {
          width: 30px;
          height: 30px;
        }
    }
  button{
      width: 118px;
      height: 48px;
      font-size: 16px;
      color: ${colors.lightGreen};
      margin-left: 0;
      border-radius: 24px;
      @media (min-width: ${screenSizes.mediaM}px) {
          width: 90px;
          height: 39px;
          font-size: 15px;
          border-radius: 20px;
          margin-left: 15px;
        }
    }
`
export const EarnValText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 5px 0 0 0;
`
export const EarnValTextUSD = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 5px 0 0 0;  
  &:hover{
    opacity: 0;
  }
`

export const VaultsTabCont = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
  }
`

export const TabWrapper = styled.div`
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0 -16px;
`
export const TabContainer = styled.div`
   display: flex;
   align-items: center;
   min-width: 650px;
   box-sizing: border-box;
   padding-left: 16px;
   flex-wrap: no-wrap;
   @media (min-width: ${screenSizes.mediaM}px) {
     min-width: auto;
     margin-right: 0;
     flex-wrap: wrap;
  }
`
export const TabBox = styled.div<any>`
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 12px;
   padding: 6px 18px;
   background: ${(props: any) => props.isActive ? 'transparent linear-gradient(270deg, rgba(48,55,218, 0.2) 0%, rgba(85,183,255, 0.2) 100%) 0% 0% no-repeat padding-box' : 'rgba(0, 0, 0, 0.4)'};
   box-shadow: 0px 3px 10px #00000033;
   border: ${(props: any) => props.isActive ? 'none' : `1px solid ${colors.borderColor}`};
   border-radius: 100px;
   cursor: pointer;
   height: 31px;
   box-sizing: border-box;
   @media (min-width: ${screenSizes.mediaM}px) {
      margin-right: 10px;
      padding: 10px 30px;
      font-size: 15px;
      height: 43px;
    } 
`
export const TabText = styled.p<any>`
  font-size: 12px;
  line-height: 15px;
  color: ${(props: any) => props.isActive ? colors.lightBlue : colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: ${(props: any) => props.isActive ? 1 : 0.4};
   &:hover{
       opacity: 1;
   }
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 19px;
    line-height: 23px;
  } 
`