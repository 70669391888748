import React, { useState, useEffect, Fragment, useRef, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { updateUserBalance, updateLPData } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont, FlexCont } from 'shared/styles/styled'
import { TransActionCont, TransInputCont, TransBalTxt, InputTitle, TransInputBox, TransInput, TransMaxBox, TokenSelectCont, TokenName, LpImgGroup, TransInoTxt, TransButton } from 'shared/styles/highestApyStyle'
import DownIcon from 'assets/icons/down.svg'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import { getTokenIcon } from 'shared/tokenIconList'
import TokenSelect from 'shared/token-select'
import TransactionInfo from 'shared/transaction-info'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions'
import { setTransHistory, setConfirmTransHistory } from 'shared/transaction-history/historyMethods'
import { getConvertList, getConvertLpDataOriginal, selectInstance, instanceType, convertToWei, getMinimumSwapOutToken, getConvertStableLpData, withdrawTokenFromStableLP, removeLiquidityFromPool, removeLiquidityFromPoolOneCoin } from 'modules/block-chain/Swap'
import { getTokenPrice } from 'modules/block-chain/BlockChainMethods'
import { aquaConvertLpAddress, oldZapAbi, oldZapAddress } from 'modules/block-chain/SwapDexAbi'
import { maxAmount } from 'modules/block-chain/lendingAbi'
import { transformTokenList as tokenList, returnTokenList, returnTransformTokenList, returnConvertLPTokenList } from 'modules/block-chain/tokenList'
import { stakedWantTokens } from 'modules/block-chain-green/BlockChainMethods';
import { commaFy } from 'service/globalFunctions'
import { aquaAddress, bnbAddress, busdAddress, stableSwapAddress, usdtAddress, usdcAddress } from 'modules/block-chain/abi'
import { gammaAddress } from 'modules/block-chain/lendingAbi'
import wallet from 'modules/wallet/wallet'

const ConvertLpAction = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [wantAddress, setWantAddress] = useState<any>("")
    const [sendAmount, setSendAmount] = useState<any>('')
    const [sendAmountWithComma, setSendAmountWithComma] = useState<any>('')
    const [receiveTokenList, setReceiveTokenList] = useState<any>(tokenList)
    const [receiveToken, setReceiveToken] = useState<any>({})
    const [receiveAmount, setReceiveAmount] = useState('')
    const [receiveAmountWithComma, setReceiveAmountWithComma] = useState('')
    const [isAmtLoading, setIsAmtLoading] = useState(false)
    const [showTokenModal, setShowTokenModal] = useState(false)
    const [showTransModal, setShowTransModal] = useState(false)
    const [transactionLink, setTransactionLink] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [allowanceAmt, setAllowanceAmt] = useState<any>('0.00')
    const [minReceiveAmount, setMinReceiveAmount] = useState<any>('0.00')
    const [convertLoading, setConvertLoading] = useState(false)
    const [tokenLoading, setTokenLoading] = useState(false)
    const { data, conLpInfo, setConLpInfo, slippageVal } = props
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            
            inputRef.current.focus();
            inputRef.current.autofocus = true;
        }
    })
    
    useEffect(() => {
        const userAddress = selector.ethData.address
        const getTokenList = async () => {
            try {
                setTokenLoading(true)
                // const receiveTokens: any = await getConvertList(userAddress, data.poolId, data)
                // const tokenArr: any = []
                // if (!!receiveTokens && typeof receiveTokens !== 'undefined' && receiveTokens.length > 0) {
                //     for (const item of receiveTokens) {
                //         const tokenPriceUsd: any = await getTokenPrice(item.tokenAddress)
                //         const usdVal: any = !!tokenPriceUsd ? parseFloat(item.bal) * parseFloat(tokenPriceUsd) : '0.00'
                //         const data = {
                //             name: item.tokenName,
                //             icon: getTokenIcon(item.tokenName),
                //             value: item.bal,
                //             address: item.tokenAddress,
                //             usdValue: usdVal
                //         }
                //         tokenArr.push(data)
                //     }
                //     setReceiveTokenList(tokenList) //setReceiveTokenList([...tokenArr])
                //     setReceiveToken(tokenList[0])
                // }  
                // if(data.isStablePool){
                //     setReceiveTokenList(tokenList) //setReceiveTokenList([...tokenArr])
                //     setReceiveToken(tokenList[0])
                // }

                const token_list = await returnTransformTokenList(userAddress)//returnTokenList
                if (data.isBoostPool) {
                    const tList = token_list.filter((e: any) => e.address.toLowerCase() !== aquaAddress.toLowerCase() && e.address.toLowerCase() !== gammaAddress.toLowerCase())
                    // console.log("selected from token", tList)
                    // setSendToken(tList[0])
                    setReceiveTokenList(tList)
                    setReceiveToken(tList[0])
                }
                else {
                    const tList = token_list
                    // console.log("selected from token in else", tList)
                    // setSendToken(tList[0])
                    setReceiveTokenList(tList)
                    setReceiveToken(tList[0])
                }

            } catch (error) { }
            finally {
                setTokenLoading(false)
            }
        }
        getTokenList()
    }, [selector.ethData.address, data])

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (floatNumRegex.test(sendAmount.toString())) {
                // console.log("convert lp params details", selector.ethData.address, sendAmount, data.poolId, receiveToken.address, data)
                let receiveTokenAddress = receiveToken.address;
                if(receiveToken !== undefined && receiveToken.address !== undefined && (receiveToken.address.toLowerCase() == usdcAddress.toLowerCase() || receiveToken.address.toLowerCase() == usdtAddress.toLowerCase())){
                    receiveTokenAddress = busdAddress
                }
                const res: any = data.isStablePool ? await getConvertStableLpData(selector.ethData.address, sendAmount, data.poolId, receiveTokenAddress, data, slippageVal) : await getConvertLpDataOriginal(selector.ethData.address, sendAmount, data.poolId, receiveTokenAddress, data, slippageVal)
                
                // console.log("convert lp response", res)
                if (!!res && typeof res !== 'undefined') {
                    setConLpInfo(res)
                    setAllowanceAmt(res.allowance)
                    setWantAddress(res.wantAddress)
                    const finalReceiveAmt: any = getNumFormat(res.receivedToken)
                    setReceiveAmount(finalReceiveAmt)
                    setReceiveAmountWithComma(commaFy(+finalReceiveAmt))
                    setMinReceiveAmount(res.minReceived)
                }
                else {
                    setConLpInfo({})
                    setAllowanceAmt('0.00')
                    setWantAddress('')
                    setReceiveAmount('')
                    setReceiveAmountWithComma('')
                    setMinReceiveAmount('0.00')
                }
            }
                
        }, 2000)
        return () => clearTimeout(delayDebounceFn)
    },[selector.ethData.address, sendAmount])

    const handleSendAmount = async (e: any) => {
        const userAddress = selector.ethData.address
        try {
            setIsAmtLoading(true)
            let { value } = e.target
            if(+value === 0 ){
                value = value;
            } else {
                value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.split(",").join("") : "";
            }
            if (floatNumRegex.test(value.toString())) {
                setSendAmount(value)
                setSendAmountWithComma(commaFy(value))
                
            }
            if (!value) {
                setSendAmount('')
                setSendAmountWithComma('')
                setConLpInfo({})
                setWantAddress('')
                setTimeout(() => {
                    setReceiveAmount('')
                    setReceiveAmountWithComma('')
                }, 1500)
            }
        }
        catch (error) {
            console.log('error==>', error)
        }
        finally {
            setIsAmtLoading(false)
        }
    }
    const handleMax = async () => {
        const userAddress = selector.ethData.address
        try {
            setIsAmtLoading(true)
            if (data && data.walletBalance) {
                const finalSendAmt: any = getNumFormat(data.walletBalance)
                setSendAmount(finalSendAmt)
                setSendAmountWithComma(commaFy(+finalSendAmt))
                let receiveTokenAddress = receiveToken.address;
                if(receiveToken.address.toLowerCase() == usdcAddress.toLowerCase() || receiveToken.address.toLowerCase() == usdtAddress.toLowerCase()){
                    receiveTokenAddress = busdAddress
                }
                // const res: any = data.isStablePool ? await getConvertStableLpData(selector.ethData.address, sendAmount, data.poolId, receiveTokenAddress, data, slippageVal) : await getConvertLpData(selector.ethData.address, sendAmount, data.poolId, receiveTokenAddress, data, slippageVal)
                // // const res: any = await getConvertLpData(userAddress, data.walletBalance, data.poolId, receiveToken.address, data, slippageVal)
                // if (!!res && typeof res !== 'undefined') {
                //     setConLpInfo(res)
                //     setAllowanceAmt(res.allowance)
                //     setWantAddress(res.wantAddress)
                //     const finalReceiveAmt: any = getNumFormat(res.receivedToken)
                //     setReceiveAmount(finalReceiveAmt)
                //     setReceiveAmountWithComma(commaFy(+finalReceiveAmt))
                //     setMinReceiveAmount(res.minReceived)
                // }
                // else {
                //     setConLpInfo({})
                //     setAllowanceAmt('0.00')
                //     setWantAddress('')
                //     setReceiveAmount('')
                //     setReceiveAmountWithComma('')
                //     setMinReceiveAmount('0.00')
                // }
            }
        }
        catch (error) {
            console.log('error==>', error)
        }
        finally {
            setIsAmtLoading(false)
        }
    }

    const handleApproveLp = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                setApproveLoading(true)
                // console.log("approve details", wantAddress, aquaConvertLpAddress, maxAmount)
                const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, wantAddress)
                const approvalAmount = maxAmount
                await TOKEN_INSTANCE.methods
                    .approve(aquaConvertLpAddress, approvalAmount)
                    .send({ from: selector.ethData.address })
                    .once('transactionHash', function (res: any) { })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        setAllowanceAmt(approvalAmount)
                    })
                    .on('error', function (error: any) {
                        setApproveLoading(false)
                    })
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setApproveLoading(false)
            }
        }

    }

    const handleTokenSelect = (data: any) => {
        setReceiveToken(data)
        setSendAmount('')
        setSendAmountWithComma('')
        setReceiveAmount('')
        setReceiveAmountWithComma('')
        setShowTokenModal(false)
        setConLpInfo({})
    }

    const handleTransactionHistory = (newLink: any, hashVal: any) => {
        const historyData: any = {
            firstToken: data.name,
            secondToken: receiveToken.name,
            amount1: sendAmount,
            amount2: receiveAmount,
            link: newLink,
            type: 'convertLp',
            status: false,
            hashVal: hashVal,
            transStatus: 'pending',
        }
        setTransHistory(historyData)
    }

    const showTransLink = (transHash: any) => {
        const transLink = `${transLinkUrl}/${transHash}`
        setTransactionLink(transLink)
        handleTransactionHistory(transLink, transHash)
        setShowTransModal(true)
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Convert LP for ${parseFloat(sendAmount).toFixed(4)} ${data.name} to ${parseFloat(receiveAmount).toFixed(4)} ${receiveToken.name}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleConvertLp = async () => {
        if (selector.ethData.ethWalletConnected) {
            const userAddress = selector.ethData.address;
            let receiveTokenAddress = receiveToken.address;
            if(receiveToken.address.toLowerCase() == usdcAddress.toLowerCase() || receiveToken.address.toLowerCase() == usdtAddress.toLowerCase()){
                receiveTokenAddress = busdAddress
            }
            try {
                setConvertLoading(true)
                await convertLp(userAddress, conLpInfo.wantAddress, sendAmount, conLpInfo.routerAddress, receiveToken.address, conLpInfo.path1, conLpInfo.path2, receiveTokenAddress)
                dispatch(updateLPData(selector.ethData.address))
                dispatch(updateUserBalance(selector.ethData.address))
            }
            catch (error) {
                console.log('error==>', error)
            }
            finally {
                setConvertLoading(false)
                setSendAmount('')
                setSendAmountWithComma('')
                setReceiveAmount('')
                setReceiveAmountWithComma('')
                setConLpInfo({})
            }
        }
    }

    const convertLp = async (userAddress: string, wantAddress: string, wantAmountInEth: string, routerAddress: string, convertedToken: string, path1: any, path2: any, receiveTokenAddress: string) => {
        let slippageValue: any = (1 - slippageVal/100) * 1000;
        let minTokens: any = +wantAmountInEth * slippageValue/1000;

        // console.log(" convert liquidity token details",convertedToken, wantAddress, convertToWei(wantAmountInEth), routerAddress, path1, path2, slippageVal, convertToWei(minTokens))
        const CONVERT_LP_INSTANCE = new wallet.web3.eth.Contract(oldZapAbi, oldZapAddress)//await selectInstance(instanceType.CONVERTLP, true);
        try {
            if(data.isStablePool && convertedToken.toLowerCase() !== usdcAddress.toLowerCase() && convertedToken.toLowerCase() !== usdtAddress.toLowerCase() && convertedToken.toLowerCase() !== busdAddress.toLowerCase()){
                // console.log("convert from stable pool to a non stable token", minReceiveAmount);

                let minWithdrawTokens: any = await withdrawTokenFromStableLP(stableSwapAddress, 0, convertToWei(minTokens));
                const ROUTER_INSTANCE: any = await selectInstance(instanceType.PLANETROUTER);
                // console.log("get amounts out detail: ",minWithdrawTokens ,path1)
                minTokens = await ROUTER_INSTANCE.methods.getAmountsOut((minWithdrawTokens), path1).call();

                // console.log("convert liquidity from stables", stableSwapAddress, convertToWei(wantAmountInEth), 0, minWithdrawTokens, routerAddress, path1, slippageValue, (minTokens))
                let stableLPToken = "0xC9440dEb2a607A6f6a744a9d142b16eD824A1A3b";
                return CONVERT_LP_INSTANCE.methods
                .convertLiquidityFromStables(stableLPToken, stableSwapAddress, convertToWei(wantAmountInEth), 0, minWithdrawTokens, routerAddress, path1, slippageValue, minTokens[minTokens.length -1])
                .send({ from: userAddress, })
                .once('transactionHash', function (hash: any) {
                    if (!!hash) {
                        showTransLink(hash)
                        setShowTransModal(true)
                    }
                })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    setConfirmTransHistory(receipt.transactionHash, receipt.status)
                    const type = receipt.status ? 'success' : 'failed'
                    handleNotification(type, receipt.transactionHash)
                    setConvertLoading(false)
                    setShowTransModal(false)
                })
                .on('error', function (error: any) {
                    setConvertLoading(false)
                    handleNotification('failed')
                    updateTokenList()
                    setShowTransModal(false)
                })
            } else if (data.isStablePool && (convertedToken.toLowerCase() == usdcAddress.toLowerCase() || convertedToken.toLowerCase() == usdtAddress.toLowerCase() || convertedToken.toLowerCase() == busdAddress.toLowerCase())) {
                let removeAmountVal = wantAmountInEth;
                // console.log("withdraw from the stable pool", removeAmountVal)
                let removeTokenIndex = convertedToken.toLowerCase() == busdAddress.toLowerCase() ? 0 : convertedToken.toLowerCase() == usdcAddress.toLowerCase() ? 1 : 2;
                let removeLiquidityData: any = await removeLiquidityFromPoolOneCoin(stableSwapAddress, +removeAmountVal, 0, removeTokenIndex, 18, userAddress);
                let revisedValue: any = convertToWei(removeAmountVal);
                revisedValue = BigInt(revisedValue);
                revisedValue = revisedValue.toString();
                let minWithdrawTokens: any = await withdrawTokenFromStableLP(stableSwapAddress, removeTokenIndex, convertToWei(minTokens));
                // console.log( "remove liquidity combined tokens", removeLiquidityData, revisedValue)
                return removeLiquidityData.stable_pool_inst.methods
                .remove_liquidity_one_coin(revisedValue, removeTokenIndex, minWithdrawTokens)
                .send({from: selector.ethData.address})
                .once('transactionHash', function (hash: any) {
                    if (!!hash) {
                        showTransLink(hash)
                        setShowTransModal(true)
                    }
                })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    setConfirmTransHistory(receipt.transactionHash, receipt.status)
                    const type = receipt.status ? 'success' : 'failed'
                    handleNotification(type, receipt.transactionHash)
                    setConvertLoading(false)
                    setShowTransModal(false)
                })
                .on('error', function (error: any) {
                    setConvertLoading(false)
                    handleNotification('failed')
                    updateTokenList()
                    setShowTransModal(false)
                })
            }
            let to_token_index = convertedToken.toLowerCase() == usdcAddress.toLowerCase() ? 1 : 2;
            let min_swap_amount = await getMinimumSwapOutToken(stableSwapAddress, 0, to_token_index, convertToWei(wantAmountInEth), [18, 18, 18])
            // console.log("minTokenAmount", min_swap_amount, convertedToken, receiveTokenAddress)
            let stableSwapPoolData = [0, to_token_index, min_swap_amount];
            
            if(receiveToken.address.toLowerCase() == usdcAddress.toLowerCase() || receiveToken.address.toLowerCase() == usdtAddress.toLowerCase()){
                // console.log("convert lp from non stable pool to stable token", convertedToken)
                return CONVERT_LP_INSTANCE.methods
                .convertLiquidityToStables(wantAddress, convertToWei(wantAmountInEth), routerAddress, path1, path2, slippageValue, convertToWei(minTokens), stableSwapAddress, stableSwapPoolData)
                .send({ from: userAddress, })
                .once('transactionHash', function (hash: any) {
                    if (!!hash) {
                        showTransLink(hash)
                        setShowTransModal(true)
                    }
                })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    setConfirmTransHistory(receipt.transactionHash, receipt.status)
                    const type = receipt.status ? 'success' : 'failed'
                    handleNotification(type, receipt.transactionHash)
                    setConvertLoading(false)
                    setShowTransModal(false)
                })
                .on('error', function (error: any) {
                    setConvertLoading(false)
                    handleNotification('failed')
                    updateTokenList()
                    setShowTransModal(false)
                })
            } else {
                // console.log("in else", wantAddress, convertToWei(wantAmountInEth), routerAddress, path1, path2, slippageValue, convertToWei(minTokens))
                let minWantAmountInEth: any = +wantAmountInEth * slippageValue/1000;
                let min_lp_tokens = await CONVERT_LP_INSTANCE.methods.convertLpOutput(wantAddress, convertToWei(minWantAmountInEth), routerAddress, path1, path2).call();
                // console.log("min lp tokens", min_lp_tokens)
                return CONVERT_LP_INSTANCE.methods
                .convertLiquidityToken(wantAddress, convertToWei(wantAmountInEth), routerAddress, path1, path2, slippageValue, min_lp_tokens)
                .send({ from: userAddress, })
                .once('transactionHash', function (hash: any) {
                    if (!!hash) {
                        showTransLink(hash)
                        setShowTransModal(true)
                    }
                })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    setConfirmTransHistory(receipt.transactionHash, receipt.status)
                    const type = receipt.status ? 'success' : 'failed'
                    handleNotification(type, receipt.transactionHash)
                    setConvertLoading(false)
                    setShowTransModal(false)
                })
                .on('error', function (error: any) {
                    setConvertLoading(false)
                    handleNotification('failed')
                    updateTokenList()
                    setShowTransModal(false)
                })
            }

        } catch (error) {
            console.log('error==>', error)
            setShowTransModal(false)
            setConvertLoading(false)
        }

    }

    const updateTokenList = async () => {
        const userAddress = selector.ethData.address
        try {
            const receiveTokensList: any = await returnConvertLPTokenList(userAddress);
            if(!!receiveTokensList && typeof receiveTokensList !== 'undefined' && receiveTokensList.length > 0){
                const index = receiveTokensList.findIndex((e: any) => e.address === receiveToken.address)
                if (index !== -1) {
                    setReceiveTokenList(receiveTokensList)
                    setReceiveToken(receiveTokensList[index])
                }
            }

        } catch (error) { }
    }

    const getTokenName = (tokenName: string) => {
        let tokenN = tokenName
        tokenN = tokenN.toLowerCase().replace('wbnb', 'bnb')
        return tokenN.toUpperCase()
    }
    const getIcons = (tokenNum: any) => {
        if(data.name.toLowerCase() == '3g'){
            const icon: any = getTokenIcon(data.name)
            return icon
        }
        const tokenNameVal: any = data.name.split('-')
        let tokenN = ''
        if (tokenNum === 1) {
            tokenN = tokenNameVal[0]
        }
        else {
            tokenN = tokenNameVal[1]
        }
        const icon: any = getTokenIcon(tokenN)
        return icon
    }

    return (
        <Fragment>
            <TransActionCont>
                <TransInputCont>
                    <FlexSBCont>
                        {
                            !!data && data.walletBalance > 0 && sendAmount > 0 ?
                            <InputTitle className="sendText">Send {`($${parseFloat((+sendAmount * +data.price).toString()).toFixed(2)})`}</InputTitle>
                            :
                            <InputTitle className="sendText">Send</InputTitle>
                        }
                        
                        <TransBalTxt className="sendAmt">
                            {!!data && data.walletBalance > 0 ? `${parseFloat(data.walletBalance).toFixed(4)} ($${parseFloat(data.walletBalanceUSD).toFixed(2)})` : '0.0000 ($0.00)'}
                        </TransBalTxt>
                    </FlexSBCont>
                    <TransInputBox>
                        <FlexSBCont>
                            <TransInput
                                className='convert-lp-input'
                                disabled={receiveTokenList.length === 0}
                                placeholder="0"
                                onChange={handleSendAmount}
                                value={sendAmountWithComma && sendAmountWithComma}
                                autoFocus={true}
                                ref={inputRef}
                            />
                            <FlexCont>
                                <TransMaxBox onClick={() => handleMax()}>Max</TransMaxBox>
                                <LpImgGroup>
                                    <img src={getIcons(1)} alt="" />
                                    { data.name.toLowerCase() !== '3g' &&
                                    <img className="second-img" src={getIcons(2)} alt="" />}
                                </LpImgGroup>
                                <TokenName className="lp-token">{getTokenName(data.name)}</TokenName>
                            </FlexCont>

                        </FlexSBCont>
                    </TransInputBox>
                </TransInputCont>
                <TransInputCont className='receive-token-card'>
                    <FlexSBCont>
                        {
                            Object.values(receiveToken).length > 0 && +receiveAmount > 0 ?
                            <InputTitle className="sendText">Receive {`($${parseFloat((+receiveAmount * +receiveToken.price).toString()).toFixed(2)})`}</InputTitle>
                            :
                            <InputTitle className="sendText">Receive</InputTitle>
                        }
                        
                        <TransBalTxt className="sendAmt">{Object.values(receiveToken).length > 0 ? `${parseFloat(receiveToken.value).toFixed(4)} ($${parseFloat(receiveToken.usdValue).toFixed(2)})` : '-'}</TransBalTxt>
                    </FlexSBCont>
                    <TransInputBox>
                        <FlexSBCont>
                            <TransInput
                                className='convert-lp-input'
                                disabled={true}
                                placeholder="0"
                                value={receiveAmountWithComma && receiveAmountWithComma}
                            />
                            <FlexCont>
                                {
                                    tokenLoading ? <Spinner /> : receiveTokenList.length > 0 &&
                                        <TokenSelectCont onClick={() => setShowTokenModal(true)} >
                                            {Object.values(receiveToken).length > 0 && (
                                                <FlexCont>
                                                    <img className="token-icon" src={receiveToken.icon} alt="" />
                                                    <TokenName>{receiveToken.name}</TokenName>
                                                    <img className="down-icon" src={DownIcon} alt="" />
                                                </FlexCont>
                                            )}

                                        </TokenSelectCont>
                                }
                            </FlexCont>
                        </FlexSBCont>
                    </TransInputBox>
                </TransInputCont>
                {
                    isAmtLoading && parseFloat(sendAmount) > 0 && <TransInoTxt className="loading-text">Amount Loading ...</TransInoTxt>
                }
                {
                    selector.ethData.address === null || (!!conLpInfo && Object.values(conLpInfo).length === 0) || (parseFloat(sendAmount) > parseFloat(data.walletBalance)) ?
                        <TransButton disabled={true}>{parseFloat(sendAmount) > parseFloat(data.walletBalance) ? 'InSufficient Balance' : 'Convert'}</TransButton> :
                        <>
                            {
                                parseFloat(sendAmount) > parseFloat(allowanceAmt) ?
                                    <TransButton disabled={approveLoading} onClick={() => handleApproveLp()}>{approveLoading ? <Spinner /> : 'Approve'}</TransButton> :
                                    <TransButton disabled={convertLoading || parseFloat(sendAmount) === 0 || parseFloat(sendAmount) > parseFloat(data.walletBalance) || isAmtLoading} onClick={() => handleConvertLp()}>{convertLoading ? <Spinner /> : 'Convert'}</TransButton>
                            }

                        </>

                }

            </TransActionCont>
            <CustomModal show={showTokenModal} toggleModal={setShowTokenModal}>
                {showTokenModal && (
                    <TokenSelect
                        selectedAdd={wantAddress}
                        setShowTokenModal={setShowTokenModal}
                        tokenListVal={receiveTokenList}
                        handleTokenSelect={handleTokenSelect}
                        hideInput={true}
                    />
                )}
            </CustomModal>
            <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
                {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
            </CustomModal>
        </Fragment>

    )
}

export default ConvertLpAction