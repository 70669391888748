import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TableCont, TableCard, ActiveTableRow, TableText, TableSmText, ApyText, ActiveTokenCont, TokenName, MarketBtn } from './style'
import CustomModal from 'shared/custom-modal'
import AssetBorrow from './AssetBorrow'
import AssetBorrowRevised from './AssetBorrowRevised'
import { commaFy, convertNumFormat, checkNegative } from 'service/globalFunctions'
import { getApyOnLevelSelectAtBorrow } from 'modules/block-chain-green/LendingBase'
import DownIcon from 'assets/icons/down.svg'
import SortIcon from 'assets/icons/white-arrow-up.svg'
import DefaultSortIcon from 'assets/icons/up-down-icon.svg'
import TableLoading from './TableLoading'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import MarketIcon from 'assets/icons/markets.svg'
import { Table, TableHeader, TableBody, MobTableBody, TableRowCont, TableRow, CellCont, HeadTextCont, HeadTextData, HeadText, CellText, CellSmText, TwoImgCont, ArrowIconCont, MobArrowIconCont, LoadingText1 } from 'shared/styles/planetTableStyle'
import { userDiscountLevel } from 'service/dataConstant'
import Select from 'shared/select'
import RightIcon from 'assets/icons/right_arrow.svg'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import { gammaAddress } from 'modules/block-chain/lendingAbi'


const BorrowedAsset = (props: any) => {
    const selector = useSelector((state: any) => state)
    const [showAssetBorrow, setShowAssetBorrow] = useState(false)
    const [modalData, setModalData] = useState<any>({})
    const [apyData, setApyData] = useState<any>([])
    const [sortByTh, setSortByTh] = useState('borrowed')
    const [sortDescending, setSortDescending] = useState(true)
    const [isAllowance, setIsAllowance] = useState(false)
    const [showAssetSupply, setShowAssetSupply] = useState(false)
    const [discountLevel, setDiscountLevel] = useState(0)
    const [showVaultDetails, setShowVaultDetails] = useState(false)
    const [displayWebModal, setDisplayWebModal] = useState(false)
    const { userAssetLoading, gPlatformData, userAssetData } = selector.lending
    const { ethWalletConnected, address } = selector.ethData
    const { data, currLevel } = props

    useEffect(() => {
        // console.log("borrow apy in userAssetData", userAssetData, gPlatformData.currentLevel, data);
        const getApyData = async () => {
            let apyDataArr: any = []
            for (let i = 0; i < data.length; i++) {
                const gTokenAddress = data[i].address.toLowerCase()
                // console.log("borrow apy for level", userAssetData.levelBorrowApyArray[0][gTokenAddress])
                // getApyOnLevelSelectAtBorrow(gTokenAddress, currLevel)
                let res: any = [];
                if(userAssetData !== undefined && userAssetData.levelBorrowApyArray !== undefined && userAssetData.levelBorrowApyArray.length > 0){
                    if(currLevel == 0){
                        res = userAssetData.levelBorrowApyArray[0][gTokenAddress];
                    } else if(currLevel == 1){
                        res = userAssetData.levelBorrowApyArray[1][gTokenAddress];
                    } else if(currLevel == 2){
                        res = userAssetData.levelBorrowApyArray[2][gTokenAddress];
                    } else {
                        res = userAssetData.levelBorrowApyArray[3][gTokenAddress];
                    }
                    // console.log(res)
                    apyDataArr.push(res)
                }
                
            }
            //apyDataArr = await Promise.all(apyDataArr)
            // console.log("apyDataArr in getRevisedBorrowApy inside useeffect", apyDataArr, currLevel)
            setApyData([...apyDataArr])
        }
        if (!!data && data.length > 0) {
            getApyData()
        }
        if(discountLevel === 0 || (+discountLevel === 0 && +currLevel !== +discountLevel)){
            setDiscountLevel(currLevel)
        }
        if(ethWalletConnected === false || (gPlatformData !== undefined && gPlatformData.suppliedAmountWithoutCollateral !== undefined && gPlatformData.suppliedAmountWithoutCollateral == 0 )){
            setSortByTh('borrow_available')
        } else {
            setSortByTh('borrowed')
        }
    }, [data, currLevel, ethWalletConnected, gPlatformData, userAssetData])

    const handleLevelSelect = async (discountLevelOption: number, revisedApyCall?: boolean) => {
        setDiscountLevel(discountLevelOption)
        if(revisedApyCall !== false){
            await getRevisedBorrowApy(+discountLevelOption);
        }
        
    }

    const getRevisedBorrowApy = async (discountLevel: any) => {
        let apyDataArr: any = []
        for (let i = 0; i < data.length; i++) {
            const gTokenAddress = data[i].address.toLowerCase();
            // const res = getApyOnLevelSelectAtBorrow(gTokenAddress, discountLevel)
            let res: any = [];
            if(userAssetData !== undefined && userAssetData.levelBorrowApyArray !== undefined && userAssetData.levelBorrowApyArray.length > 0){
                if(discountLevel == 0){
                    res = userAssetData.levelBorrowApyArray[0][gTokenAddress];
                } else if(discountLevel == 1){
                    res = userAssetData.levelBorrowApyArray[1][gTokenAddress];
                } else if(discountLevel == 2){
                    res = userAssetData.levelBorrowApyArray[2][gTokenAddress];
                } else {
                    res = userAssetData.levelBorrowApyArray[3][gTokenAddress];
                }
                // console.log(res)
                apyDataArr.push(res)
            }
        }
        // apyDataArr = await Promise.all(apyDataArr)
        // console.log("apyDataArr in getRevisedBorrowApy", apyDataArr, discountLevel)
        setApyData([...apyDataArr])
        
    }

    const getApyValues = (addVal: any) => {
        let finalApyVal: any = {
            borrowApy: 0,
            borrowApy24hr: 0,
        }
       
        const apyValData = apyData.filter((e: any) => e != null && e.address.toLowerCase() === addVal.toLowerCase())
        finalApyVal = apyValData[0]
        return finalApyVal
    }

    const sortByAvailableUsd = (dataList: any) => {
        if (!!dataList && dataList.length > 0) {
            const finalArr = data.sort((a: any, b: any) => (parseFloat(b.availableUsd) > parseFloat(a.availableUsd)) ? 1 : (parseFloat(a.availableUsd) > parseFloat(b.availableUsd) ? -1 : 0))
            return finalArr
        }
        else {
            return dataList
        }
    }

    const sortByMarketSize = (dataList: any) => {
        if (!!dataList && dataList.length > 0) {
            const finalArr = data.sort((a: any, b: any) => (parseFloat(b.totalMarketSizeUsd) > parseFloat(a.totalMarketSizeUsd)) ? 1 : (parseFloat(a.totalMarketSizeUsd) > parseFloat(b.totalMarketSizeUsd) ? -1 : 0))
            return finalArr
        }
        else {
            return dataList
        }
    }

    const sortByApy = (dataList: any) => {
        if (!!dataList && dataList.length > 0) {
            const finalArr = data.sort((a: any, b: any) => (parseFloat(b.totalSupplyApy) > parseFloat(a.totalSupplyApy)) ? 1 : (parseFloat(a.totalSupplyApy) > parseFloat(b.totalSupplyApy) ? -1 : 0))
            return finalArr
        }
        else {
            return dataList
        }
    }

    const handleTabClick = (tabSelect: string) => {
        if (tabSelect === sortByTh) {
            setSortByTh(tabSelect)
            setSortDescending(!sortDescending)
        }
        else {
            setSortByTh(tabSelect)
            setSortDescending(true)
        }
    }

    const handlePathLink = (path: string) => {
        if (path === 'markets') {
            history.push(Paths.markets)
        }
        if (path === 'metrics') {
            history.push(Paths.metrics)
        }
        if (path === 'liquidations') {
            history.push(Paths.liquidations)
        }
    }

    const sortByBorrowAmount = (dataList: any) => {
        if (!!dataList && dataList.length > 0) {
            const finalArr = data.sort((a: any, b: any) => (parseFloat(b.currentlyBorrowingUsd) > parseFloat(a.currentlyBorrowingUsd)) ? 1 : (parseFloat(a.currentlyBorrowingUsd) > parseFloat(b.currentlyBorrowingUsd) ? -1 : 0))
            return finalArr
        }
        else {
            return dataList
        }
    }

    const sortByBorrowApy = (dataList: any) => {
        if (!!dataList && dataList.length > 0) {
            const finalArr = data.sort((a: any, b: any) => (parseFloat(b.totalBorrowApy) > parseFloat(a.totalBorrowApy)) ? 1 : (parseFloat(a.totalBorrowApy) > parseFloat(b.totalBorrowApy) ? -1 : 0))
            return finalArr
        }
        else {
            return dataList
        }
    }

    const sortBySupplyAvailableToBorrow = (dataList: any) => {
        if (!!dataList && dataList.length > 0) {
            const finalArr = data.sort((a: any, b: any) => (parseFloat(b.availableUsd) > parseFloat(a.availableUsd)) ? 1 : (parseFloat(a.availableUsd) > parseFloat(b.availableUsd) ? -1 : 0))
            return finalArr
        }
        else {
            return dataList
        }
    }

    const sortBySupplyAvailable = (dataList: any) => {
        if (!!dataList && dataList.length > 0) {
            const finalArr = data.sort((a: any, b: any) => (parseFloat((+b.totalMarketSizeUsd - +b.availableUsd).toString()) > parseFloat((+a.totalMarketSizeUsd - +a.availableUsd).toString())) ? 1 : (parseFloat((+a.totalMarketSizeUsd - +a.availableUsd).toString()) > parseFloat((+b.totalMarketSizeUsd - +b.availableUsd).toString()) ? -1 : 0))
            return finalArr
        }
        else {
            return dataList
        }
    }

    const toggleVaultDetailsModal = () => {
        setModalData({})
        setShowVaultDetails(false)
    }

    const handleSupplyModal = (itemData: any) => {
        // console.log("itemdata in handleSupplyModal web", itemData)
        setIsAllowance(itemData.underlyingTokenAllowance)
        setModalData(itemData)
        setShowAssetBorrow(true)
        // setDisplayWebModal(true)
    }


    const handleRowClick = (itemData: any) => {
        setModalData(itemData)
        setShowAssetBorrow(true)
        setShowVaultDetails(true)
        // setDisplayWebModal(false)
    }

    const _renderDataRow = () => {
        // const orderedData = sortByAvailableUsd(data)
        let orderedData = sortByBorrowAmount(data)
        if(sortByTh === "borrowed"){
            orderedData = sortByBorrowAmount(data)
        } else if(sortByTh === "apy"){
            const orderedData = sortByBorrowApy(data)
        } else if(sortByTh === "available"){
            const orderedData = sortBySupplyAvailableToBorrow(data)
        } else if(sortByTh === "borrow_available"){
            const orderedData = sortBySupplyAvailable(data)
        } else{
            orderedData = sortByBorrowAmount(data)
        }
        if (!sortDescending) {
            orderedData = orderedData.reverse()
        }
        return orderedData.map((item: any, index: number) => {
            const apyVal: any = getApyValues(item.address)
            if(item.address.toLowerCase() !== '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() && item.address.toLowerCase() !== '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() && item.address.toLowerCase() !== "0xCD221e1504442671671D3330CB8E916a5EDc3FC7".toLowerCase() && item.address.toLowerCase() !== "0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5".toLowerCase() && item.address.toLowerCase() !== gammaAddress.toLowerCase()){
                return (
                    <ActiveTableRow key={index} className="table-data borrow-data-row" onClick={() => handleRowClick(item)}>
                        <ActiveTokenCont>
                            <img src={item.icon} alt="" />
                            <div className="align-left">
                                <TokenName>{item.name}</TokenName>
                                {item.totalMarketSizeUsd - item.availableUsd > 0 ?
                                <TableSmText className="market-size">${commaFy(convertNumFormat(item.totalMarketSizeUsd - item.availableUsd))}</TableSmText>
                                :
                                <TableSmText className="market-size">$0.00</TableSmText>
                                }

                            </div>
                        </ActiveTokenCont>
                        <div>
                            {item.currentlyBorrowingUsd > 0.0054 &&
                                <TableText className='suppliedToken'>{commaFy(parseFloat(item.currentlyBorrowing).toFixed(4))}</TableText>
                            }
                            {item.currentlyBorrowingUsd <= 0.0054 &&
                            <TableText className='dataInGrey suppliedToken'>{commaFy(convertNumFormat(item.currentlyBorrowing, 4))}</TableText>
                            }
                            <TableSmText className='suppliedUsd'>${commaFy(parseFloat(item.currentlyBorrowingUsd).toFixed(2))}</TableSmText>
                        </div>
                        <div>
                            <ApyText className='apyCapsule' isNegative={!!apyVal && checkNegative(apyVal.borrowApy)}>{!!apyVal && apyVal.borrowApy ? commaFy(convertNumFormat(apyVal.borrowApy)) : '0.00'}%</ApyText>
                            {/* <TableSmText >{!!apyVal && apyVal.borrowApy24hr ? commaFy(convertNumFormat(apyVal.borrowApy24hr)) : '0.00'}%</TableSmText> */}
                        </div>
                        <div>
                            <TableText className='suppliedToken' light={parseFloat(item.availableUsd) <= 0}>${commaFy(convertNumFormat(item.availableUsd))}</TableText>
                            <TableSmText >{`${commaFy(convertNumFormat(item.available))} ${item.name}`}</TableSmText>
                        </div>
                    </ActiveTableRow>
                )
            }
            
        })
    }

    const _renderDataRowMob = () => {
        // const orderedData = sortByAvailableUsd(data)
        let orderedData = sortByBorrowAmount(data)
        if(sortByTh === "borrowed"){
            orderedData = sortByBorrowAmount(data)
        } else if(sortByTh === "apy"){
            const orderedData = sortByBorrowApy(data)
        } else if(sortByTh === "available"){
            const orderedData = sortBySupplyAvailableToBorrow(data)
        } else if(sortByTh === "borrow_available"){
            const orderedData = sortBySupplyAvailable(data)
        } else{
            orderedData = sortByBorrowAmount(data)
        }
        if (!sortDescending) {
            orderedData = orderedData.reverse()
        }
        return orderedData.map((item: any, index: number) => {
            const apyVal: any = getApyValues(item.address)
            if(item.address !== '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442' && item.address !== '0xb7eD4A5AF620B52022fb26035C565277035d4FD7' && item.address.toLowerCase() !== "0xCD221e1504442671671D3330CB8E916a5EDc3FC7".toLowerCase() && item.address.toLowerCase() !== "0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5".toLowerCase()){
                return (
                    <TableRowCont key={index} onClick={() => handleRowClick(item)}>
                        <TableRow className="supplyTableRowMob" onClick={() => handleRowClick(item)}>
                            <CellCont className='supplyTableRowMob'>
                            <img src={item.icon} alt="" className='supplyTableMarketIcon' />
                            <div className="align-left supplyTableMarketText">
                                <TokenName>{item.name}</TokenName>
                            </div>
                            </CellCont>
                            <CellCont>
                                <div>
                                    <CellText className='apyTextWhite' isNegative={!!apyVal && checkNegative(apyVal.borrowApy)}>{!!apyVal && apyVal.borrowApy ? commaFy(convertNumFormat(apyVal.borrowApy)) : '0.00'}%</CellText>
                                    {/* <TableSmText >{!!apyVal && apyVal.borrowApy24hr ? commaFy(convertNumFormat(apyVal.borrowApy24hr)) : '0.00'}%</TableSmText> */}
                                </div>
                            </CellCont>
                            <CellCont>
                                <div>
                                    {item.currentlyBorrowingUsd > 0.005 &&
                                        <TableText className='suppliedAmountText'>${commaFy(convertNumFormat(item.currentlyBorrowingUsd, 2))}</TableText>
                                    }
                                    {item.currentlyBorrowingUsd <= 0.0054 &&
                                    <TableText className='dataInGrey suppliedAmountText'>${commaFy(convertNumFormat(item.currentlyBorrowingUsd, 2))}</TableText>
                                    }
                                </div>
                            </CellCont>
                        
                            <CellCont>
                                <img className='downGreyIcon' src={RightIcon} alt="" />
                            </CellCont>
                        </TableRow>
                    </TableRowCont>
                )
            }
        })
    }

    return (
        
        <TableCont>
            <TableCard className='supplyTable'>
                <div className='supplyMarketDiv'>
                    <h2 className="borrowMarket">Borrow</h2>
                    {/* <div className='LevelBtn'>
                    <Select options={userDiscountLevel} handleSelect={handleLevelSelect} iconColor={"grey"} defaultValue={currLevel} value={discountLevel} />
                    </div> */}
                    
                </div>
                
                <ActiveTableRow className="borrow-header-row">
                    {/* <TableText className="lendingSupply" light>Borrow Assets</TableText> */}
                    <HeadTextCont className='supplyHeader earn_assets'>
                        <HeadTextData className='lendingSupply' onClick={() => handleTabClick('borrow_available')} isDown={sortByTh === 'borrow_available' && sortDescending}>
                            <HeadText className='lendingSupply'>Total Borrowed</HeadText>
                            <img className='lendingSupply' src={sortByTh === 'borrow_available' ? SortIcon : DefaultSortIcon} alt=""/>
                        </HeadTextData>
                    </HeadTextCont>
                    <HeadTextCont className='supplyHeader'>
                        <HeadTextData className='lendingSupply' onClick={() => handleTabClick('borrowed')} isDown={sortByTh === 'borrowed' && sortDescending}>
                            <HeadText className='lendingSupply'>Debt</HeadText>
                            <img className='lendingSupply' src={sortByTh === 'borrowed' ? SortIcon : DefaultSortIcon} alt=""/>
                        </HeadTextData>
                    </HeadTextCont>
                    <HeadTextCont className='supplyHeader'>
                        <HeadTextData className='lendingSupply' onClick={() => handleTabClick('apy')} isDown={sortByTh === 'apy' && sortDescending}>
                            <HeadText className='lendingSupply'>APY</HeadText>
                            <img className='lendingSupply' src={sortByTh === 'apy' ? SortIcon : DefaultSortIcon} alt=""/>
                        </HeadTextData>
                    </HeadTextCont>
                    <HeadTextCont className='supplyHeader'>
                        <HeadTextData className='lendingSupply' onClick={() => handleTabClick('available')} isDown={sortByTh === 'available' && sortDescending}>
                            <HeadText className='lendingSupply'>Available</HeadText>
                            <img className='lendingSupply' src={sortByTh === 'available' ? SortIcon : DefaultSortIcon} alt=""/>
                        </HeadTextData>
                    </HeadTextCont>
                    {/* <TableText light>Amount</TableText>
                    <TableText light>APY</TableText>
                    <TableText light>Available</TableText> */}
                </ActiveTableRow>
                {
                    userAssetLoading ? <TableLoading /> :
                        !!data && data.length > 0 ? _renderDataRow() : <TableLoading />
                }
            </TableCard>
            <Table className='show-mob'>
            <TableHeader>
                <HeadTextCont />
                <HeadTextCont>
                    <HeadTextData onClick={() => handleTabClick('borrow_available')} isDown={sortByTh === 'borrow_available' && sortDescending} >
                        <HeadText>TVL</HeadText>
                        <img src={sortByTh === 'borrow_available' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                <HeadTextCont>
                    <HeadTextData onClick={() => handleTabClick('apy')} isDown={sortByTh === 'apy' && sortDescending}>
                        <HeadText>APY</HeadText>
                        <img src={sortByTh === 'apy' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                
                
                <HeadTextCont className='show-mob'>
                    <HeadTextData onClick={() => handleTabClick('borrowed')} isDown={sortByTh === 'borrowed' && sortDescending}>
                        <HeadText>Borrowed</HeadText>
                        <img src={sortByTh === 'borrowed' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                <HeadTextCont />
            </TableHeader>
            {userAssetLoading ? <TableLoading /> : _renderDataRowMob()}
            </Table>
            
            {<CustomActionModal show={showVaultDetails} toggleModal={toggleVaultDetailsModal}>
                {showVaultDetails && (
                    <AssetBorrowRevised
                        data={modalData}
                        setShowAssetBorrow={setShowAssetBorrow}
                        toggleVaultModal={toggleVaultDetailsModal} setModalData={setModalData} currentTab={"all"}
                    />
                )}
            </CustomActionModal>}
           
        </TableCont>

    );
};

export default BorrowedAsset;
