import { POOL_LOADING, UPDATE_POOL_INFO, UPDATE_POOL_DATA, PORTFOLIO_LOADING, UPDATE_USER_PORTFOLIO, PLATFORM_LOADING, UPDATE_PLATFORM_DATA, INFINITY_VAULT_LOADING, UPDATE_GAMMA_INF_VAULT_DATA, UPDATE_AQUA_INF_VAULT_DATA, UPDATE_GAMMA_INF_VAULT_DATA_NEW, UPDATE_AQUA_INF_VAULT_DATA_NEW, POOL_LOADING_NEW, UPDATE_POOL_INFO_NEW, UPDATE_POOL_DATA_NEW, GAMMA_INFINITY_VAULT_LOADING, INFINITY_VAULT_LOADING_NEW, AQUA_INFINITY_VAULT_LOADING, UPDATE_ONLY_POOL_DATA, UPDATE_ONLY_VAULTS_DATA, UPDATE_POOL_INFO_V3, UPDATE_POOL_DATA_V3 } from 'logic/action/action.config'
import {revisedPFMarketTokenList, revisedPFNewMarketTokenList} from '../../modules/block-chain/pfTokenList'
import {activeV3Pools, newPoolList} from '../../modules/block-chain/pfPoolsList'
const initialState = {
  authLoading: false,
  poolLoading: true,
  poolLoadingNew: true,
  poolData: {
    pools: {},
    vaults: {},
    newVaults: {},
    newPools: {},
    v3Pools: {}
  },
  portfolioLoading: false,
  userPortfolio: {
    balanceInVaults: 0,
    currentApy: 0,
    currentApr: 0,
    dailyEarning: 0,
    pendingEarnings: 0,
    userAquaBalance: 0,
    userAquaBalanceUSD: 0,
    userGammaBalance: 0,
    userGammaBalanceUSD: 0,
    pendingGammaAllPools: 0,
    greenPendingGAMMA: 0,
    greenPendingUsd: 0,
    userLevel: { level: 0, discount: 0 },
    suppliedList: [],
    userLPArray: [],
    userMarketsArray: [],
    aquaPrice: 0,
    gammaPrice: 0,
    newLpArrayData: [],
    other_pool_balance_in_v3: 0,
    user_gamma_btcb_balance_in_v3: 0
  },
  platformLoading: false,
  platformData: {},
  infVaultLoading: false,
  infVaultLoadingNew: false,
  gammaInfVaultLoading: false,
  aquaInfVaultLoading: false,
  gammaInfData: {},
  aquaInfData: {},
  gammaInfDataNew: {},
  aquaInfDataNew: {},
  migration_arr: []
}

let aquaObject: any = {};
let gammaObject: any = {}

const getTotalRewards = (firstReward: any, secondReward: any) => {
  let finalAmount: any = 0
  const firstAmt = !!firstReward && !isNaN(parseFloat(firstReward)) ? firstReward : 0
  const secondAmt = !!secondReward && !isNaN(parseFloat(secondReward)) ? secondReward : 0
  finalAmount = parseFloat(firstAmt) + parseFloat(secondAmt)
  return finalAmount
}

const updatePools = (addressVal: string, userPortfolio: any, pools: string) => {
  let userPoolData: any = {
    vaultBalance: 0,
    vaultBalanceUSD: 0,
    walletBalance: 0,
    walletBalanceUSD: 0,
    uranusRewards: 0,
    uranusRewardsUSD: 0,
    userRewards: 0,
    userRewardsUSD: 0,
    allowance: '0',
    isAllowanceApproved: false,
    totalRewardsUSD: 0
  }
  if(pools === "v2"){
    if (userPortfolio.newLpArrayData.length > 0) {
      const data = userPortfolio.newLpArrayData.filter((e: any) => e.wantAddress_farmAddress.toLowerCase() === addressVal.toLowerCase())
      if (data.length > 0) {
        const dataVal: any = data[0]
        userPoolData = {
          vaultBalance: !!dataVal.LPTokens ? dataVal.LPTokens : 0,
          vaultBalanceUSD: !!dataVal.LPTokenUSD ? dataVal.LPTokenUSD : 0,
          walletBalance: !!dataVal.walletBalance ? dataVal.walletBalance : 0,
          walletBalanceUSD: !!dataVal.walletBalanceUSD ? dataVal.walletBalanceUSD : 0,
          uranusRewards: !!dataVal.uranusRewards ? dataVal.uranusRewards : 0,
          uranusRewardsUSD: !!dataVal.uranusRewardsUSD ? dataVal.uranusRewardsUSD : 0,
          userRewards: !!dataVal.userRewards ? dataVal.userRewards : 0,
          userRewardsUSD: !!dataVal.userRewardsUSD ? dataVal.userRewardsUSD : 0,
          allowance: !!dataVal.allowance ? dataVal.allowance : '0',
          isAllowanceApproved: !!dataVal.isAllowanceApproved ? true : false,
          totalRewardsUSD: 0,//getTotalRewards(dataVal.uranusRewardsUSD, dataVal.userRewardsUSD)
          userBoostApy: !!dataVal.userBoostApy ? dataVal.userBoostApy : 0,
          averageBoostApy: !!dataVal.averageBoostApy ? dataVal.averageBoostApy : 0,
          gammaBoostPercent: !!dataVal.gammaBoostPercent ? dataVal.gammaBoostPercent : 0,
          lpAllowance: !!dataVal.lpAllowance ? dataVal.lpAllowance : 0,
          avgBoostAPR: !!dataVal.avgBoostAPR ? dataVal.avgBoostAPR : 0,
          userBoostAPR: !!dataVal.userBoostAPR ? dataVal.userBoostAPR : 0,
        }
      }
    }

  } else if(pools === "v3"){
    if (userPortfolio.V3_LPArray.length > 0) {
      const data = userPortfolio.V3_LPArray.filter((e: any) => e.wantAddress_farmAddress.toLowerCase() === addressVal.toLowerCase())
      if (data.length > 0) {
        const dataVal: any = data[0]
        userPoolData = {
          vaultBalance: !!dataVal.LPTokens ? dataVal.LPTokens : 0,
          vaultBalanceUSD: !!dataVal.LPTokenUSD ? dataVal.LPTokenUSD : 0,
          walletBalance: !!dataVal.walletBalance ? dataVal.walletBalance : 0,
          walletBalanceUSD: !!dataVal.walletBalanceUSD ? dataVal.walletBalanceUSD : 0,
          uranusRewards: !!dataVal.uranusRewards ? dataVal.uranusRewards : 0,
          uranusRewardsUSD: !!dataVal.uranusRewardsUSD ? dataVal.uranusRewardsUSD : 0,
          userRewards: !!dataVal.userRewards ? dataVal.userRewards : 0,
          userRewardsUSD: !!dataVal.userRewardsUSD ? dataVal.userRewardsUSD : 0,
          allowance: !!dataVal.allowance ? dataVal.allowance : '0',
          isAllowanceApproved: !!dataVal.isAllowanceApproved ? true : false,
          totalRewardsUSD: 0,//getTotalRewards(dataVal.uranusRewardsUSD, dataVal.userRewardsUSD)
          userBoostApy: !!dataVal.userBoostApy ? dataVal.userBoostApy : 0,
          averageBoostApy: !!dataVal.averageBoostApy ? dataVal.averageBoostApy : 0,
          gammaBoostPercent: !!dataVal.gammaBoostPercent ? dataVal.gammaBoostPercent : 0,
          lpAllowance: !!dataVal.lpAllowance ? dataVal.lpAllowance : 0,
          avgBoostAPR: !!dataVal.avgBoostAPR ? dataVal.avgBoostAPR : 0,
          userBoostAPR: !!dataVal.userBoostAPR ? dataVal.userBoostAPR : 0,
          withdrawalBalance: !!dataVal.withdrawalBalance ? dataVal.withdrawalBalance : 0,
          withdrawalBalanceUSD: !!dataVal.withdrawalBalanceUSD ? dataVal.withdrawalBalanceUSD : 0,
          poolsLockArray: !!dataVal.poolsLockArray ? dataVal.poolsLockArray : [],
          token0: !!dataVal.token0 ? dataVal.token0 : "",
          token1: !!dataVal.token1 ? dataVal.token1 : "",
          gammaAPR: !!dataVal.gammaAPR ? dataVal.gammaAPR : 0,
          gammaAPY: !!dataVal.gammaAPY ? dataVal.gammaAPY : 0,
          tradeFeeAPR: !!dataVal.tradeFeeAPR ? dataVal.tradeFeeAPR : 0,
          tradeFeeAPY: !!dataVal.tradeFeeAPY ? dataVal.tradeFeeAPY : 0,
          totalAPR: !!dataVal.totalAPR ? dataVal.totalAPR : 0,
          totalAPY: !!dataVal.totalAPY ? dataVal.totalAPY : 0,
          farmV3DripRate : !!dataVal.farmV3DripRate ? dataVal.farmV3DripRate : 0,
          allocPoint: !!dataVal.allocPoint ? dataVal.allocPoint : 0,
          totalAllocPoint: !!dataVal.totalAllocPoint ? dataVal.totalAllocPoint : 0,
          gammaPrice: !!dataVal.gammaPrice ? dataVal.gammaPrice : 0,
          wantLockedTotal: !!dataVal.wantLockedTotal ? dataVal.wantLockedTotal : 0,
          sharesTotal: !!dataVal.sharesTotal ? dataVal.sharesTotal : 0,
          totalFactor: !!dataVal.totalFactor ? dataVal.totalFactor : 0,
          userFactor: !!dataVal.userFactor ? dataVal.userFactor : 0,
          userEarningShares: !!dataVal.userEarningShares ? dataVal.userEarningShares : 0,
          userShares: !!dataVal.userShares ? dataVal.userShares : 0,
          lockPoolInfo: !!dataVal.lockPoolInfo ? dataVal.lockPoolInfo : [],
        }
      }
    }
  } else {
    if (userPortfolio.userLPArray.length > 0) {
      const data = userPortfolio.userLPArray.filter((e: any) => e.wantAddress_farmAddress.toLowerCase() === addressVal.toLowerCase())
      if (data.length > 0) {
        const dataVal: any = data[0]
        userPoolData = {
          vaultBalance: !!dataVal.LPTokens ? dataVal.LPTokens : 0,
          vaultBalanceUSD: !!dataVal.LPTokenUSD ? dataVal.LPTokenUSD : 0,
          walletBalance: !!dataVal.walletBalance ? dataVal.walletBalance : 0,
          walletBalanceUSD: !!dataVal.walletBalanceUSD ? dataVal.walletBalanceUSD : 0,
          uranusRewards: !!dataVal.uranusRewards ? dataVal.uranusRewards : 0,
          uranusRewardsUSD: !!dataVal.uranusRewardsUSD ? dataVal.uranusRewardsUSD : 0,
          userRewards: !!dataVal.userRewards ? dataVal.userRewards : 0,
          userRewardsUSD: !!dataVal.userRewardsUSD ? dataVal.userRewardsUSD : 0,
          allowance: !!dataVal.allowance ? dataVal.allowance : '0',
          isAllowanceApproved: !!dataVal.isAllowanceApproved ? true : false,
          totalRewardsUSD: getTotalRewards(dataVal.uranusRewardsUSD, dataVal.userRewardsUSD)
        }
      }
    }
  }

  
  
  return userPoolData
}

const updateVaults = (address: string, userPortfolio: any, isBoostPool?: boolean) => {
  let userVaultData: any = {
    vaultBalance: 0,
    vaultBalanceUSD: 0,
    walletBalance: 0,
    walletBalanceUSD: 0,
    uranusRewards: 0,
    uranusRewardsUSD: 0,
    userRewards: 0,
    userRewardsUSD: 0,
    allowance: '0',
    isAllowanceApproved: false,
    totalRewardsUSD: 0,
    set: false,
    withdrew: false,
    amountWithdrawn: 0

  }
  if (userPortfolio.userMarketsArray.length > 0) {
    let data: any;
    if(isBoostPool){
      data = userPortfolio.userMarketsArray.filter((e: any) => e.strategyAddress.toLowerCase() === address.toLowerCase())
    } else{
      data = userPortfolio.userMarketsArray.filter((e: any) => e.marketAddress.toLowerCase() === address.toLowerCase())
    }
    
    if (data.length > 0) {
      const dataVal: any = data[0]
      
      userVaultData = {
        vaultBalance: !!dataVal.vaultBalance ? dataVal.vaultBalance : 0,
        vaultBalanceUSD: !!dataVal.vaultBalanceUSD ? dataVal.vaultBalanceUSD : 0,
        walletBalance: !!dataVal.walletBalance ? dataVal.walletBalance : 0,
        walletBalanceUSD: !!dataVal.walletBalanceUSD ? dataVal.walletBalanceUSD : 0,
        uranusRewards: !!dataVal.uranusRewards ? dataVal.uranusRewards : 0,
        uranusRewardsUSD: !!dataVal.uranusRewardsUSD ? dataVal.uranusRewardsUSD : 0,
        userRewards: !!dataVal.userRewards ? dataVal.userRewards : 0,
        userRewardsUSD: !!dataVal.userRewardsUSD ? dataVal.userRewardsUSD : 0,
        allowance: !!dataVal.allowance ? dataVal.allowance : '0',
        isAllowanceApproved: !!dataVal.isAllowanceApproved ? true : false,
        totalRewardsUSD: getTotalRewards(dataVal.uranusRewardsUSD, dataVal.userRewardsUSD),
        set: true,
        withdrew: !!dataVal.withdrew ? dataVal.withdrew: false,
        amountWithdrawn: !!dataVal.amountWithdrawn ? dataVal.amountWithdrawn : 0,
        userBoostApy:  !!dataVal.userBoostApy ? dataVal.userBoostApy : 0,
        averageBoostApy: !!dataVal.avgBoostApy ? dataVal.avgBoostApy : 0,
        gammaBoostPercentage: !!dataVal.gammaBoostPercentage ? dataVal.gammaBoostPercentage : 0,
        avgBoostAPR: !!dataVal.averageBoostAPR ? dataVal.averageBoostAPR : 0,
        userBoostAPR: !!dataVal.userBoostAPR ? dataVal.userBoostAPR : 0,
        borrowBalanceUSD: !!dataVal.borrowBalanceUSD ? dataVal.borrowBalanceUSD : 0,
        gamma_borrow_apr: !!dataVal.gamma_borrow_apr ? dataVal.gamma_borrow_apr : 0,
        borrow_apr: !!dataVal.borrow_apr ? dataVal.borrow_apr : 0
      }
    }
  }
  return userVaultData
}

const getUpdatedPoolData = (poolData: any, userPortfolio: any) => {
  let pools = poolData.pools
  let vaults = poolData.vaults
  let newVaults = poolData.newVaults
  let newPools = poolData.newPools
  let v3Pools = poolData.v3Pools
  let newVaultsAquaGama: any = [];
  let old_vaults = [];
  let newVaultsAddresses:any=[];
  for(let item of newVaults.active){
    newVaultsAddresses.push(item.address); 
  }

  if (!!pools && Object.values(pools).length > 0) {
    const active: any = []
    const finished: any = []
    if (pools.active.length > 0) {
      for (const item of pools.active) {
        
        const userVal: any = updatePools(item.wantaddress_farmaddress, userPortfolio, "v1")
        const data = { ...item, ...userVal }
        active.push(data)
      }
    }
    if (pools.finished.length > 0) {
      for (const item of pools.finished) {
        const userVal: any = updatePools(item.wantaddress_farmaddress, userPortfolio, "v1")  
        const data = { ...item, ...userVal }
        if((data.strategyAddress.toLowerCase() == "0xA2a9E46AF5E32C6d4667AEf6D42d83102D7d1fbc".toLowerCase() || data.strategyAddress.toLowerCase() == "0x59ea5143cA27cA0005A17B0dc460A7D0f5163A7e".toLowerCase() || data.strategyAddress.toLowerCase() == "0xb5157c3aD103eD0318a5BA07AAe7caF7857E0cBA".toLowerCase()) && !data.name.includes("OLD") ){
          data.name = data.name + " (OLD)"
        }
        data.poolStatus = "finished";
        finished.push(data)
      }
    }
    pools = { active, finished }
  }

  if (!!newPools && Object.values(newPools).length > 0) {
    const active: any = []
    const finished: any = []
    const to_be_migrated: any = [];
    //const poolIdsToBeMigrated = [2, 3, 16, 17, 18, 19, 23];
    const poolIdsToBeMigrated = [2, 3, 16, 17, 18, 19, 23];
    if (newPools.active.length > 0) {
      for (const item of newPools.active) {

        const userVal: any = updatePools(item.wantaddress_farmaddress, userPortfolio, "v2")
        const data = { ...item, ...userVal }
        if(data.wantAddress.toLowerCase() == "0xC9440dEb2a607A6f6a744a9d142b16eD824A1A3b".toLowerCase()){
          data.isStablePool = true;
        }
        data.averageBoostApy = data.averageGammaBoostApy && +data.averageGammaBoostApy < 10000 ? +data.averageGammaBoostApy : +data.averageBoostApy && +data.averageBoostApy < 10000 ? +data.averageBoostApy : data.gammaApy ? data.gammaApy : 0;
        data.userBoostApy = data.userBoostApy && data.userBoostApy > 0 && data.vaultBalanceUSD > 1 ? data.userBoostApy : data.averageBoostApy;
        data.averageGammaBoostApr = data.averageGammaBoostApr && +data.averageGammaBoostApr < 10000 ? data.averageGammaBoostApr : data.gammaApr;
        data.boostAPR = data.userBoostAPR && data.vaultBalanceUSD > 1 ? data.userBoostAPR: data.averageGammaBoostApr;
        data.gammaApr = data.gammaApr === undefined || data.gammaApy == 0 ? 0 : data.gammaApr;
        data.totalApy_without_averageGammaBoostApy = data.totalApy_without_averageGammaBoostApy > 0 ? data.totalApy_without_averageGammaBoostApy : data.gammaApy;
        data.tradeFeeAr = data.tradeFeeApr ? +data.tradeFeeApr : 0;
        data.tradeFeeApy = data.tradeFeeApy ? +data.tradeFeeApy : 0;
        data.avgGammaBoostApyUpper = data.avgGammaBoostApyUpper ? +data.avgGammaBoostApyUpper + data.totalApy_without_averageGammaBoostApy : data.totalApy_without_averageGammaBoostApy
        data.avgGammaBoostApyLower = data.avgGammaBoostApyLower ? +data.avgGammaBoostApyLower + data.totalApy_without_averageGammaBoostApy : data.totalApy_without_averageGammaBoostApy;
        data.apyFromParentFarm = data.protocol.toLowerCase() == "thena" && data.apyFromParentFarm ? +data.apyFromParentFarm : data.protocol.toLowerCase() == "thena" && (data.apyFromParentFarm || data.apyFromParentFarm == 0) ? 0 : 0;
        data.parentFarmApr = data.parentFarmApr ? data.parentFarmApr : 0;
        data.gammaApy = data.gammaApy ? +data.gammaApy : 0;
        data.totalApr = data.boostAPR + data.gammaApr + data.tradeFeeApr + data.parentFarmApr;
        data.totalApy = 100 * (Math.pow(1 + (data.totalApr/36500), 365) - 1); //data.gammaApy + data.apyFromParentFarm + data.userBoostApy + data.tradeFeeApy;
        data.gammaBoostPercent = data.gammaBoostPercent ? data.gammaBoostPercent : 50;
        data.poolStatus = "active";
        // if(data.strategyAddress.toLowerCase() == "0x06f5F260bE2C541F134b5548E5e62952D7315363".toLowerCase()){
        //   data.poolStatus = "finished";
        // }
        if(newPoolList.includes(data.strategyAddress)){
          active.push(data)
        }
        if(poolIdsToBeMigrated.indexOf(data.poolId) > -1){
          to_be_migrated.push(data)
        }
        
      }
    }
    if (newPools.finished.length > 0) {
      for (const item of newPools.finished) {
        const userVal: any = updatePools(item.wantaddress_farmaddress, userPortfolio, item.farmContractAddress.toLowerCase() == "0x9EBce8B8d535247b2a0dfC0494Bc8aeEd7640cF9".toLowerCase() ? "v2": "v1")
        const data = { ...item, ...userVal }
        if(data.wantAddress.toLowerCase() == "0xC9440dEb2a607A6f6a744a9d142b16eD824A1A3b".toLowerCase()){
          data.isStablePool = true;
        }
        if((data.strategyAddress.toLowerCase() == "0xA2a9E46AF5E32C6d4667AEf6D42d83102D7d1fbc".toLowerCase() || data.strategyAddress.toLowerCase() == "0x59ea5143cA27cA0005A17B0dc460A7D0f5163A7e".toLowerCase() || data.strategyAddress.toLowerCase() == "0xb5157c3aD103eD0318a5BA07AAe7caF7857E0cBA".toLowerCase()) && !data.name.includes("OLD") ){
          data.name = data.name + " (OLD)"
        }
        data.parentFarmApr = data.parentFarmApr ? data.parentFarmApr : 0;
        data.poolStatus = "finished";
        finished.push(data)

        if(poolIdsToBeMigrated.indexOf(data.poolId) > -1){
          to_be_migrated.push(data)
        }
      }
    }
    newPools = { active, finished, to_be_migrated }
  }

  if (!!v3Pools && Object.values(v3Pools).length > 0) {
    const active: any = []
    const finished: any = []
    const to_be_migrated: any = [];
    if (v3Pools.active.length > 0) {
      for (const item of v3Pools.active) {

        const userVal: any = updatePools(item.wantaddress_farmaddress, userPortfolio, "v3")
        const data = { ...item, ...userVal }
        if(data.wantAddress.toLowerCase() == "0xC9440dEb2a607A6f6a744a9d142b16eD824A1A3b".toLowerCase()){
          data.isStablePool = true;
        }
        data.gammaApr = data.gammaAPR !== undefined ? data.gammaAPR : data.gammaApr ? data.gammaApr : 0;
        data.gammaApy = data.gammaAPY !== undefined ? +data.gammaAPY : data.gammaApy ? data.gammaApy : 0;
        data.tradeFeeApr = data.tradeFeeAPR ? +data.tradeFeeAPR : data.tradeFeeApr ? data.tradeFeeApr : 0;
        data.tradeFeeApy = data.tradeFeeAPY ? +data.tradeFeeAPY : data.tradeFeeApy ? data.tradeFeeApy : 0;
        data.parentFarmApr = data.parentFarmApr && data.parentFarmApr > 0 ? data.parentFarmApr : 0;
        data.totalApr = data.gammaApr + data.tradeFeeApr + data.parentFarmApr;
        data.totalApy = 100 * (Math.pow(1 + (data.totalApr/36500), 365) - 1);//data.totalAPY; //data.gammaApy + data.apyFromParentFarm + data.userBoostApy + data.tradeFeeApy;
        data.poolStatus = "active";
        // if(data.strategyAddress.toLowerCase() == "0x06f5F260bE2C541F134b5548E5e62952D7315363".toLowerCase()){
        //   data.poolStatus = "finished";
        // }
        if(activeV3Pools.includes(data.strategyAddress)){
          active.push(data)
        }
      }
    }
    
    v3Pools = { active }
  }
  
  if (!!vaults && Object.values(vaults).length > 0) {
    const active: any = []
    const finished: any = []
    let aqua: any = {}
    let gamma: any = {}
    let oldGtokenVaults = [];
    //  && item.strategyAddress !== "0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9"
    if (vaults.active.length > 0) {
      for (const item of vaults.active) {
        const userVal: any = updateVaults(item.isBoostPool ? item.strategyAddress : item.address, userPortfolio, item.isBoostPool ? true : false)
        const data = { ...item, ...userVal }
        if(userVal.set || (item.isBoostPool && item.strategyAddress.toLowerCase() !== "0x2336F606250851c8D58088Fb6097420aCc78c142".toLowerCase()) || (userPortfolio.userMarketsArray.length === 0 && (item.address.toLowerCase() === "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442".toLowerCase() || item.address.toLowerCase() === "0xb7eD4A5AF620B52022fb26035C565277035d4FD7".toLowerCase()))){
          if(item.isBoostPool){
            data.address = data.strategyAddress;
          }
          active.push(data)
        }
        
        // if(item.address === "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442" && !newVaultsAddresses.includes("0x0c6dd143F4b86567d6c21E8ccfD0300f00896442")){
        //   gamma = data;
        //   // newVaultsAquaGama.push(data);
        // }
        // if(item.address === "0xb7eD4A5AF620B52022fb26035C565277035d4FD7" && !newVaultsAddresses.includes("0xb7eD4A5AF620B52022fb26035C565277035d4FD7")){
        //   aqua = data;
        //   // newVaultsAquaGama.push(data);
        // }
      }
    }
    if (vaults.finished.length > 0) {
      for (const item of vaults.finished) {
        const userVal: any = updateVaults(item.isBoostPool ? item.strategyAddress : item.address, userPortfolio, item.isBoostPool ? true : false)
        const data = { ...item, ...userVal }
        
        if(item.isBoostPool){
          data.address = data.strategyAddress;
        }
         
        finished.push(data)
      }
    }
    let pfVaults = {"active":newVaultsAquaGama};
    aquaObject = aqua;
    gammaObject = gamma;
    vaults = { active, finished, aqua, gamma, pfVaults }
  }

  if (!!newVaults && Object.values(newVaults).length > 0) {
    const act: any = []
    let aqua: any = aquaObject;//{}
    let gamma: any = gammaObject//{}
    //  && item.strategyAddress !== "0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9"
    if (newVaults.active.length > 0) {
      for (const item of newVaults.active) {
        if(item.address.toLowerCase() !== "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442".toLowerCase() && item.address.toLowerCase() !== "0xb7eD4A5AF620B52022fb26035C565277035d4FD7".toLowerCase()){
          const userVal: any = updateVaults(item.isBoostPool ? item.strategyAddress : item.address, userPortfolio, item.isBoostPool ? true : false)
          let data = { ...item, ...userVal };
          data.userBoostApy = data.userBoostApy && data.userBoostApy > 0 && data.vaultBalanceUSD > 1 ? data.userBoostApy : data.avgBoostApy;
          data.userBoostApr = data.userBoostAPR && data.userBoostAPR > 0 && data.vaultBalanceUSD > 1 ? data.userBoostAPR : data.averageBoostAPR;
          data.totalApr = data.userBoostApr + data.gammaApr + data.supplyApr;
          data.totalApy = data.userBoostApy && data.userBoostApy > 0 && data.totalApy_without_avgBoostApy ? data.userBoostApy + data.totalApy_without_avgBoostApy : data.totalApy; 
          if(userVal.set || (item.isBoostPool && item.strategyAddress.toLowerCase() !== "0x2336F606250851c8D58088Fb6097420aCc78c142".toLowerCase()) || (userPortfolio.userMarketsArray.length === 0 && revisedPFNewMarketTokenList.includes(item.address))){
            if(item.isBoostPool){
              data.address = data.strategyAddress;
            }
            act.push(data)
          }
        }
      }
    }
    

    if (vaults.active.length > 0) {
      for (const item of vaults.active) {
        if(item.address === "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442"){
          let data = updateVaults( "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442", userPortfolio, false)
          let gammaObj = {...item, ...data};
          old_vaults.push(gammaObj);
        }

        if(item.address === "0xb7eD4A5AF620B52022fb26035C565277035d4FD7"){
          let data = updateVaults( "0xb7eD4A5AF620B52022fb26035C565277035d4FD7", userPortfolio, false)
          let aquaObj = {...item, ...data};
          old_vaults.push(aquaObj);
        }
      }
    } else {
      if(vaults.gamma.address !== undefined && vaults.gamma.address === "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442"){
        let data = updateVaults( "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442", userPortfolio, false)
        let gammaObj = {...vaults.gamma, ...data};
        old_vaults.push(gammaObj);
      } 

      if(vaults.aqua.address !== undefined && vaults.aqua.address === "0xb7eD4A5AF620B52022fb26035C565277035d4FD7"){
        let data = updateVaults( "0xb7eD4A5AF620B52022fb26035C565277035d4FD7", userPortfolio, false)
        let aquaObj = {...vaults.aqua, ...data};
        old_vaults.push(aquaObj);
      }
    }
    let pfVaults = {"active":newVaultsAquaGama};
    let a = newVaultsAquaGama.concat(act);
    newVaults = { active: a, aqua, gamma, pfVaults, old_vaults}
  }
  const newPoolData = {
    pools: pools,
    vaults: vaults,
    newVaults: newVaults,
    newPools: newPools,
    v3Pools: v3Pools
  }
  return newPoolData
}

const fetchUpdatedPoolData = async (lpDetails: any) => {
  let newPools = lpDetails.data.newPools;
  let pools = lpDetails.data.pools;
  lpDetails.newLpArrayData = lpDetails.newPoolData;
  lpDetails.userLPArray = lpDetails.oldPoolData;
  if (!!newPools && Object.values(newPools).length > 0) {
    const active: any = []
    const finished: any = []
    if (newPools.active.length > 0) {
      for (const item of newPools.active) {

        const userVal: any = updatePools(item.wantaddress_farmaddress, lpDetails, "v2")
        const data = { ...item, ...userVal }
        // data.userBoostApy = data.userBoostApy && data.userBoostApy > 0 ? data.userBoostApy: data.averageGammaBoostApy;
        data.averageBoostApy = data.averageGammaBoostApy && +data.averageGammaBoostApy < 10000 ? +data.averageGammaBoostApy : +data.averageBoostApy && +data.averageBoostApy < 10000 ? +data.averageBoostApy : data.gammaApy ? data.gammaApy : 0;
        data.userBoostApy = data.userBoostApy && data.userBoostApy > 0 && data.vaultBalanceUSD > 1 ? data.userBoostApy : data.averageBoostApy;
        data.averageGammaBoostApr = data.averageGammaBoostApr && +data.averageGammaBoostApr < 10000 ? data.averageGammaBoostApr : data.gammaApr;
        data.boostAPR = data.userBoostAPR && data.vaultBalanceUSD > 1 ? data.userBoostAPR: data.averageGammaBoostApr;
        data.gammaApr = data.gammaApr === undefined || data.gammaApy == 0 ? 0 : data.gammaApr;
        data.totalApy_without_averageGammaBoostApy = data.totalApy_without_averageGammaBoostApy > 0 ? data.totalApy_without_averageGammaBoostApy : data.gammaApy;
        data.tradeFeeAr = data.tradeFeeApr ? +data.tradeFeeApr : 0;
        data.tradeFeeApy = data.tradeFeeApy ? +data.tradeFeeApy : 0;
        data.avgGammaBoostApyUpper = data.avgGammaBoostApyUpper ? +data.avgGammaBoostApyUpper + data.totalApy_without_averageGammaBoostApy : data.totalApy_without_averageGammaBoostApy
        data.avgGammaBoostApyLower = data.avgGammaBoostApyLower ? +data.avgGammaBoostApyLower + data.totalApy_without_averageGammaBoostApy : data.totalApy_without_averageGammaBoostApy;
        data.apyFromParentFarm = data.protocol.toLowerCase() == "thena" && data.apyFromParentFarm ? +data.apyFromParentFarm : data.protocol.toLowerCase() == "thena" && (data.apyFromParentFarm || data.apyFromParentFarm == 0) ? 0 : 0;
        data.parentFarmApr = data.parentFarmApr ? data.parentFarmApr : 0;
        data.gammaApy = data.gammaApy ? +data.gammaApy : 0;
        data.totalApr = data.boostAPR + data.gammaApr + data.tradeFeeApr + data.parentFarmApr;
        data.totalApy = 100 * (Math.pow(1 + (data.totalApr/36500), 365) - 1); //data.gammaApy + data.apyFromParentFarm + data.userBoostApy + data.tradeFeeApy;
        data.gammaBoostPercent = data.gammaBoostPercent ? data.gammaBoostPercent : 50;
        data.poolStatus = "active";
        if(newPoolList.includes(data.strategyAddress)){
          active.push(data)
        }
      }
    }
    if (pools.finished.length > 0) {
      for (const item of pools.finished) {
        const userVal: any = updatePools(item.wantaddress_farmaddress, lpDetails, "v1")
        const data = { ...item, ...userVal }
        data.poolStatus = "finished";
        finished.push(data)
      }
    }
    newPools = { active, finished }
  }
  const newPoolData = {
    newPools: newPools
  }
  return newPoolData
}

export const fetchUpdatedVaultsData = async(vault_details: any) => {
  let newVaults = vault_details.data.newVaults;
  vault_details.userMarketsArray = vault_details.vaults_data;
  const act: any = []
  let aqua: any = aquaObject;//{}
  let gamma: any = gammaObject//{}
  let old_vaults: any = [];
  let newVaultsAquaGama: any = [];
  //  && item.strategyAddress !== "0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9"
  if (newVaults.active.length > 0) {
    for (const item of newVaults.active) {
      if(item.address.toLowerCase() !== "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442".toLowerCase() && item.address.toLowerCase() !== "0xb7eD4A5AF620B52022fb26035C565277035d4FD7".toLowerCase()){
        const userVal: any = updateVaults(item.isBoostPool ? item.strategyAddress : item.address, vault_details, item.isBoostPool ? true : false)
        let data = { ...item, ...userVal };
        
        data.userBoostApy = data.userBoostApy && data.userBoostApy > 0 && data.vaultBalanceUSD > 1 ? data.userBoostApy : data.averageBoostAPY;
        data.totalApy = data.userBoostApy && data.userBoostApy > 0 && data.totalApy_without_avgBoostApy ? data.userBoostApy + data.totalApy_without_avgBoostApy : data.totalApy; 
        if(userVal.set || (item.isBoostPool && item.strategyAddress.toLowerCase() !== "0x2336F606250851c8D58088Fb6097420aCc78c142".toLowerCase()) || (vault_details.userMarketsArray.length === 0 && revisedPFNewMarketTokenList.includes(item.address))){
          if(item.isBoostPool){
            data.address = data.strategyAddress;
          }
          act.push(data)
        }
      }
    }
  }
    

  if (vault_details.data.vaults.active.length > 0) {
    for (const item of vault_details.data.vaults.active) {
      if(item.address === "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442"){
        let data = updateVaults( "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442", vault_details, false)
        let gammaObj = {...item, ...data};
        old_vaults.push(gammaObj);
      }

      if(item.address === "0xb7eD4A5AF620B52022fb26035C565277035d4FD7"){
        let data = updateVaults( "0xb7eD4A5AF620B52022fb26035C565277035d4FD7", vault_details, false)
        let aquaObj = {...item, ...data};
        old_vaults.push(aquaObj);
      }
    }
  }
  let pfVaults = {"active":newVaultsAquaGama};
  let a = newVaultsAquaGama.concat(act);
  newVaults = { active: a, aqua, gamma, pfVaults, old_vaults};
  const newPoolData = {
    newVaults: newVaults,
  }
  return newPoolData
}

export const userReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {

    case POOL_LOADING:
      return {
        ...state,
        poolLoading: payload,
      }
    case UPDATE_POOL_INFO:
      return {
        ...state,
        poolData: payload,
      }

    case UPDATE_POOL_DATA:
      return {
        ...state,
        poolData: getUpdatedPoolData(state.poolData, payload),
      }

    case POOL_LOADING_NEW:
      return {
        ...state,
        poolLoadingNew: payload,
      }
    case UPDATE_POOL_INFO_NEW:
      return {
        ...state,
        poolDataNew: payload,
      }

    case UPDATE_POOL_INFO_V3:
      return {
        ...state,
        poolDataNew: payload,
      }

    case UPDATE_POOL_DATA_NEW:
      return {
        ...state,
        poolDataNew: getUpdatedPoolData(state.poolData, payload),
      }

    case UPDATE_POOL_DATA_V3:
      return {
        ...state,
        poolDataNew: getUpdatedPoolData(state.poolData, payload),
      }

    case UPDATE_ONLY_POOL_DATA:
      return {
        ...state,
        poolDataNew: fetchUpdatedPoolData(payload),
      }

    case UPDATE_ONLY_VAULTS_DATA: 
      return {
        ...state,
        poolDataNew: fetchUpdatedVaultsData(payload)
      }

    case PORTFOLIO_LOADING:
      return {
        ...state,
        portfolioLoading: payload,
      }
    case UPDATE_USER_PORTFOLIO:
      return {
        ...state,
        userPortfolio: payload,
      }

    case PLATFORM_LOADING:
      return {
        ...state,
        platformLoading: payload,
      }
    case UPDATE_PLATFORM_DATA:
      return {
        ...state,
        platformData: payload,
      }

    case INFINITY_VAULT_LOADING:
      return {
        ...state,
        infVaultLoading: payload,
      }
    
    case GAMMA_INFINITY_VAULT_LOADING:
      return {
        ...state,
        gammaInfVaultLoading: payload,
      }

    case INFINITY_VAULT_LOADING_NEW:
      return {
        ...state,
        infVaultLoadingNew: payload,
      }
    
    case AQUA_INFINITY_VAULT_LOADING:
      return {
        ...state,
        aquaInfVaultLoading: payload,
      }

    case UPDATE_GAMMA_INF_VAULT_DATA:
      return {
        ...state,
        gammaInfData: payload,
      }
    case UPDATE_AQUA_INF_VAULT_DATA:
      return {
        ...state,
        aquaInfData: payload,
      }
    case UPDATE_GAMMA_INF_VAULT_DATA_NEW:
      return {
        ...state,
        gammaInfDataNew: payload,
      }
    case UPDATE_AQUA_INF_VAULT_DATA_NEW:
      return {
        ...state,
        aquaInfDataNew: payload,
      }

    default:
      return state
  }
}
