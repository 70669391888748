import { useState, useEffect, Fragment, ChangeEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import {
  CardCont,
  FlexSBCont,
  FlexCont,
  TitleText,
  InfoText,
  InfoSmallText,
  SelectCurrTxt,
  InfoLDText,
  TokenValInput,
  MaxTxt,
  MaxTxtZero,
  TokenName,
  BackButton,
  PrimaryBtn,
} from '../style'
import {
  AddLDCont,
  AddLDTokenCont,
  AddLDCard,
  PluIconCont,
  TokenSelectCont,
  PoolDetailsCont,
  PoolInfoText,
  ApproveBtn,
  AddLDBtnCont,
} from './style'
import DownIcon from 'assets/icons/down-arrow-icon.png'
import ArrowDown from 'assets/icons/arrow-down.png'
import CrossIcon from 'assets/icons/plus.svg'
import ClockIcon from 'assets/icons/clock-icon.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import TokenSelect from 'shared/token-select'
import TransactionInfo from 'shared/transaction-info'
import ConfirmLiquidity from './ConfirmLiquidity'
import {
  returnPairExistOrNot,
  returnPairAddress,
  userPoolDetails,
  returnQuotePrice,
  tokenAllowance,
  DEADLINE,
  selectInstance,
  instanceType,
  convertToWei,
  onAddLiquidityPopUp,
  getAllStableSwapPools,
  estimateTokensOnDeposit,
} from 'modules/block-chain/Swap'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions';
import { setTransLink, setConfirmTransLink } from '../swapMethods'
import { PLANETROUTERADDRESS } from 'modules/block-chain/SwapDexAbi'
import { maxAmount } from 'modules/block-chain/lendingAbi';
import ArrowDownGrey from 'assets/icons/down_grey_icon.svg'
import { commaFy, convertToKAndM } from 'service/globalFunctions'
import BUSDIcon from 'assets/icons/BUSD.png'
import USDCIcon from 'assets/icons/USDC.png'
import USDTIcon from 'assets/icons/USDT.png'
import {SwapConstCard, SwapConstTxt, PriceImpactTxt} from '../token-swap/style'
import stabkeMakrketImage from 'assets/icons/stable.png'
import stableSwapIcon from 'assets/icons/stable-swap-icon.png'
import {stableSwapAddress} from 'modules/block-chain/abi'
import { LoadingText1, LoadingText3 } from 'shared/styles/planetTableStyle'

var onApproveErrorCheck: any = false;

const AddStableSwapLiquidity = (props: any) => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const { setLiquidityTab, tokenList, updateLiquidity, setShowHistory } = props
  var {setConfirmSwapBtn, confirmSwapCheck, setShowCnfModal, showCnfModal, setConfirmLdData, tokenArray, setTokenArray, stableSwapObject, depositLoading, resetInput, setStableSwapObject} = props;
  const [firstAmount, setFirstAmount] = useState('')
  const [secondAmount, setSecondAmount] = useState('')
  const [isAmtLoading, setIsAmtLoading] = useState(false)
  const [firstToken, setFirstToken] = useState<any>(!!tokenList && tokenList.length > 0 ? tokenList[0] : {})
  const [secondToken, setSecondToken] = useState<any>(!!tokenList && tokenList.length > 0 ? tokenList[1] : {})
  const [existTokenAdd, setExistTokeAdd] = useState('')
  const [tokenType, setTokenType] = useState('')
  const [showTokenModal, setShowTokenModal] = useState(false)
  const [showTransModal, setShowTransModal] = useState(false)
  const [transactionLink, setTransactionLink] = useState('')
  const [isPairExist, setIsPairExist] = useState(false)
  const [pairAddress, setPairAddress] = useState('')
  const [poolDetails, setPoolDetails] = useState<any>({})
  const [firstAllowance, setFirstAllowance] = useState<any>('0')
  const [secondAllowance, setSecondAllowance] = useState<any>('0')
  const [addLdLoading, setAddLdLoading] = useState(false)
  const [approve1Loading, setApprove1Loading] = useState(false)
  const [approve2Loading, setApprove2Loading] = useState(false)
  const [sendAmountCheck, setSendAmountCheck] = useState(0)
  const [stableSwapArray, setStableSwapArray] = useState<any>([])
  const [selectedIndex, setSelectedIndex] = useState<any>('0')
  const [maxButton, setMaxButton] = useState<any>(false)
  const [inputValue, setInputValue] = useState<any>('0')
  const [firstTokenValue, setFirstTokenValue] = useState<any>('')
  const [secondTokenValue, setSecondTokenValue] = useState<any>('')
  const [thirdTokenValue, setThirdTokenValue] = useState<any>('')
  const [firstTokenDisplayValue, setFirstDisplayTokenValue] = useState<any>('')
  const [secondTokenDisplayValue, setSecondTokenDisplayValue] = useState<any>('')
  const [thirdTokenDisplayValue, setThirdTokenDisplayValue] = useState<any>('')
  const [confirmStableSwapBtn, setConfirmStableSwapBtn] = useState<any>(false)
  const [approveTokenName, setApproveTokenName] = useState<any>('')
  const [approveLoading, setApproveLoading] = useState<any>(false)
  const [insufficientBalanceCheck, setInsufficientBalanceCheck] = useState<any>(false)
  const [onApproveError, setOnApproveError] = useState<any>(0)
  const [minReceivedAmount, setMinReceivedAmount] = useState<any>('');
  const [addSSDataLoading, setAddSSDataLoading] = useState(false)
  const bnbSno = 1

  

  useEffect(() => {
    const getPairExistVal = async () => {
      let pairExist: any = false;
      try {
        pairExist = await returnPairExistOrNot(tokenList[0].address, tokenList[1].address)
        setIsPairExist(pairExist)
      }
      catch (error) {
        setIsPairExist(false)
        setPairAddress('')
      }

      try {
        if (pairExist) {
          const pairAddressVal = await returnPairAddress(tokenList[0].address, tokenList[1].address)
          setPairAddress(pairAddressVal)
          if((selector.ethData && selector.ethData.address))
          {
            const pollDataVal: any = await userPoolDetails(tokenList[0].address, tokenList[1].address, pairAddressVal, selector.ethData.address)
            setPoolDetails(pollDataVal)
          }
          
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (!!tokenList && tokenList.length > 0) {
      // getPairExistVal()
    }

    
    
    if(+firstTokenValue > 0 || +secondTokenValue > 0 || +thirdTokenValue > 0){
      // setShowCnfModal(true)
      // confirmSwapBtn = true;
      let depositAmount: any = +firstTokenValue + +secondTokenValue + +thirdTokenValue;
      // depositAmount = convertToWei(depositAmount.toString())
      // calcMinTokens(depositAmount);
      setConfirmSwapBtn(true);
      setConfirmStableSwapBtn(true)
    }

    if(!showCnfModal && confirmSwapCheck){
      setConfirmStableSwapBtn(true)
    }
    
    if(stableSwapObject.assetPrice == undefined){
      setAddSSDataLoading(true)
      
    } else {
      setAddSSDataLoading(false)
    }

    if(resetInput){
      setFirstTokenValue('')
      setFirstDisplayTokenValue('')
      setSecondTokenValue('')
      setSecondTokenDisplayValue('')
      setThirdTokenValue('')
      setThirdTokenDisplayValue('')
    }

  }, [selector.ethData.address, tokenList, firstTokenValue, secondTokenValue, thirdTokenValue, showCnfModal, confirmSwapCheck, tokenArray, stableSwapObject, resetInput])

  const approvalCheck = async(token: any) => {

  }

  const calcMinTokens = async (depositAmount: any) => {
    try{
      let min_received_amount: any = await estimateTokensOnDeposit(stableSwapObject.poolAddress, depositAmount)
      
      stableSwapObject.minimum_received = commaFy(parseFloat(min_received_amount.toString()).toFixed(2));
      setStableSwapObject(stableSwapObject)
      setMinReceivedAmount(min_received_amount)
    } catch(err){
      console.log(err)
    }
  }

  const getAllowance = async () => {
    try {
      if (firstToken.sno !== bnbSno && parseFloat(firstAllowance) === 0) {
        const firstAllowanceVal: any = await tokenAllowance(firstToken.address, selector.ethData.address)
        if (!!firstAllowanceVal && typeof firstAllowanceVal !== 'undefined') {
          setFirstAllowance(firstAllowanceVal)
        }
      }
      if (secondToken.sno !== bnbSno && parseFloat(secondAllowance) === 0) {
        const secondAllowanceVal: any = await tokenAllowance(secondToken.address, selector.ethData.address)
        if (!!secondAllowanceVal && typeof secondAllowanceVal !== 'undefined') {
          setSecondAllowance(secondAllowanceVal)
        }
      }
    } catch (error) { }
  }

  const getAmountOfToken = async (amount: any, flag: boolean, pairAddress: string, firstTokenAdd: string) => {
    let finalAmt: any = ''
    try {
      setIsAmtLoading(true)
      const tokenAmount: any = await returnQuotePrice(amount, flag, pairAddress, firstTokenAdd)
      finalAmt = tokenAmount
    }
    catch (error) {
      console.log("error=>", error)
    }
    finally {
      setIsAmtLoading(false)
      finalAmt = getNumFormat(finalAmt)
      return finalAmt
    }
  }

  const handleFirstTokenChange = async (e: any) => {
    setMaxButton(false);
    let value: any = e.target.value;//e.nativeEvent.data;//e.target
    if(value == "" && firstTokenValue.length == 1){
      value = "";
      setFirstTokenValue(value)
      setFirstDisplayTokenValue(value);
    }
    
    if(+value === 0 ){
      value = value;
    } else {
      value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.split(",").join("") : "";
      
      if(+value > +tokenArray[0].value || +tokenArray[1].inputValueWithPrecision > +tokenArray[1].value || +tokenArray[2].inputValueWithPrecision > +tokenArray[2].value){
        setInsufficientBalanceCheck(true)
      } else {
        setInsufficientBalanceCheck(false)
      }
    }
    
    let firstVal = value !='' || +value > 0 ? convertToWei(value): 0;
    let secondVal = secondTokenValue != '' || +secondTokenValue > 0 ?convertToWei(secondTokenValue): 0;
    let thirdVal = thirdTokenValue !='' || +thirdTokenValue > 0 ?convertToWei(thirdTokenValue): 0;
    let depositAmount = [firstVal, secondVal, thirdVal]
    await calcMinTokens(depositAmount);
    if (floatNumRegex.test(value.toString())) {
      let inputVal = value !== "" ? value : "0.00";
      tokenArray[0].inputValue = inputVal;
      tokenArray[0].inputValueWithPrecision = inputVal;
      setTokenArray(tokenArray);
      setFirstTokenValue(value)
      setFirstDisplayTokenValue(commaFy(value))
    }
    
    
  }

  const handleSecondTokenChange = async (e: any) => {
    setMaxButton(false);
    let value: any = e.target.value;//e.nativeEvent.data;//e.target
    if(value == "" && secondTokenValue.length == 1){
      value = "";
      setSecondTokenValue(value)
      setSecondTokenDisplayValue(value)
    }
    if(+value === 0 ){
      value = value;
    } else {
      value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.split(",").join("") : "";
      if(+value > +tokenArray[1].value || +tokenArray[0].inputValueWithPrecision > +tokenArray[0].value || +tokenArray[2].inputValueWithPrecision > +tokenArray[2].value){
        setInsufficientBalanceCheck(true)
      } else {
        setInsufficientBalanceCheck(false)
      }
    }
    
    let firstVal = firstTokenValue != '' || +firstTokenValue > 0 ?convertToWei(firstTokenValue): 0;
    let secondVal = value !='' || +value > 0 ? convertToWei(value): 0;
    let thirdVal = thirdTokenValue !='' || +thirdTokenValue > 0 ?convertToWei(thirdTokenValue): 0;
    let depositAmount = [firstVal, secondVal, thirdVal]
    await calcMinTokens(depositAmount);
    if (floatNumRegex.test(value.toString())) {
      let inputVal = value !== "" ? value : "0.00";
      tokenArray[1].inputValue = inputVal;
      tokenArray[1].inputValueWithPrecision = inputVal;
      setTokenArray(tokenArray);
      setSecondTokenValue(value)
      setSecondTokenDisplayValue(commaFy(value))
      
    }
    
  }

  const handleThirdTokenChange = async (e: any) => {
    setMaxButton(false);
    let value: any = e.target.value;//e.nativeEvent.data;//e.target
    if(value == "" && thirdTokenValue.length == 1){
      value = "";
      setThirdTokenValue(value)
      setThirdTokenDisplayValue(value)
    }
   
    if(+value === 0 ){
      value = value;
    } else {
      value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.split(",").join("") : "";
      if(+value > +tokenArray[2].value || +tokenArray[1].inputValueWithPrecision > +tokenArray[1].value || +tokenArray[0].inputValueWithPrecision > +tokenArray[0].value){
        setInsufficientBalanceCheck(true)
      } else {
        setInsufficientBalanceCheck(false)
      }
      
    }
    let firstVal = firstTokenValue != '' || +firstTokenValue > 0 ?convertToWei(firstTokenValue): 0;
    let secondVal = secondTokenValue != '' || +secondTokenValue > 0 ?convertToWei(secondTokenValue): 0;
    let thirdVal = value !='' || +value > 0 ? convertToWei(value): 0;
    let depositAmount = [firstVal, secondVal, thirdVal]
    await calcMinTokens(depositAmount);
    if (floatNumRegex.test(value.toString())) {
      let inputVal = value !== "" ? value : "0.00";
      tokenArray[2].inputValue = inputVal;
      tokenArray[2].inputValueWithPrecision = inputVal;
      setTokenArray(tokenArray);
      setThirdTokenValue(value)
      setThirdTokenDisplayValue(commaFy(value))
      
    }
    
  }

  const getExistTokenAdd = (type: string) => {
    if (type === 'first' && Object.values(secondToken).length > 0) {
      setExistTokeAdd(secondToken.address)
    }
    if (type === 'second' && Object.values(firstToken).length > 0) {
      setExistTokeAdd(firstToken.address)
    }
  }
  const handleTokenModal = (type: string) => {
    setTokenType(type)
    // getTokenList(type)
    getExistTokenAdd(type)
    setShowTokenModal(true)
  }

  const getPairExistVal = async (token1: any, token2: any) => {
    let pairExist: any = false;
    try {
      pairExist = await returnPairExistOrNot(token1.address, token2.address)
      setIsPairExist(pairExist)
    }
    catch (error) {
      setIsPairExist(false)
      setPairAddress('')
    }
    try {
      if (pairExist) {
        const pairAddressVal = await returnPairAddress(token1.address, token2.address)
        setPairAddress(pairAddressVal)
        if((selector.ethData && selector.ethData.address))
        {
          const pollDataVal: any = await userPoolDetails(token1.address, token2.address, pairAddressVal, selector.ethData.address)
          setPoolDetails(pollDataVal)
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleTokenSelect = (selectedTokenData: any) => {
    if (selectedTokenData.address === existTokenAdd) {
      setFirstToken(secondToken)
      setSecondToken(firstToken)
      getPairExistVal(secondToken, firstToken)
      setSendAmountCheck(secondToken.value)
    }
    else {
      if (tokenType === 'first') {
        setFirstToken(selectedTokenData)
        setSendAmountCheck(selectedTokenData.value)
        if (Object.values(secondToken).length > 0) {
          getPairExistVal(selectedTokenData, secondToken)
        }
      }
      if (tokenType === 'second') {
        setSecondToken(selectedTokenData)
        setSendAmountCheck(selectedTokenData.value)
        if (Object.values(firstToken).length > 0) {
          getPairExistVal(firstToken, selectedTokenData)
        }
      }
    }
    setExistTokeAdd('')
    setFirstAmount('')
    setSecondAmount('')
    setFirstAllowance('0')
    setSecondAllowance('0')
    handleToggleModal()
  }

  const handleToggleModal = () => {
    setTokenType('')
    setShowTokenModal(false)
  }

  const getAddLiquidityStatus = () => {
    const firstAmt = parseFloat(firstAmount)
    const secondAmt = parseFloat(secondAmount)
    const firstAl = parseFloat(firstAllowance)
    const secondAl = parseFloat(secondAllowance)

    console.log(firstAmount);
    console.log(secondAmount);
    console.log(firstAllowance);
    console.log(secondAllowance);

    console.log('firstAmt', firstAmt);
    console.log('secondAmt', secondAmt);
    console.log('firstAl', firstAl);
    console.log('secondAl', secondAl);

    console.log(secondToken);
    console.log(firstToken);

    if (
      (firstAmt < firstAl && secondToken.name === 'BNB') ||
      (secondAmt < secondAl && firstToken.name === 'BNB') ||
      (firstAmt < firstAl && secondAmt < secondAl)
    ) {
      return true
    } else {
      return false
    }
  }

  const handleAddLdModal = async () => {
    try {
      setAddLdLoading(true)
      const res = await onAddLiquidityPopUp(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address)
      if (!!res && typeof res !== 'undefined') {
        const data: any = {
          firstToken: firstToken,
          secondToken: secondToken,
          firstAmount: firstAmount,
          secondAmount: secondAmount,
          receivedTokens: res.receivedTokens,
          poolShare: res.poolShare,
        }
        setConfirmLdData(data)
        setShowCnfModal(true)
      }
    } catch (error) {
      //console.log('error----', error)
      setConfirmLdData({})
      setShowCnfModal(false)
    } finally {
      setAddLdLoading(false)
    }
  }

  const approveNotification = (type: string,  data: any, hash?: any) => {
    const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
    const msg = type === 'success' ? `${data.name} approved` : `Failed to approve  ${data.name}`
    const dataVal: any = {
        type: type,
        message: msg,
        link: linkUrl
    }
    dispatch(notificationClose())
    dispatch(notificationOpen(dataVal))
}

  const handleApprove = async (underlyingAddress: any, index: any) => {
    setApproveLoading(true);
    try {
      // type === 'first' ? setApprove1Loading(true) : setApprove2Loading(true)
      const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, underlyingAddress)
      const approvalAmount = maxAmount
      await TOKEN_INSTANCE.methods
        .approve(stableSwapAddress, approvalAmount)//PLANETROUTERADDRESS
        .send({ from: selector.ethData.address })
        .once('transactionHash', function (res: any) {
          if (!!res && typeof res) {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            setShowTransModal(true)
           
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          const type = receipt.status ? 'success' : 'failed';
          approveNotification(type, tokenArray[index], receipt.transactionHash)
          if (receipt && typeof receipt !== 'undefined') {
            // console.log(approveTokenName)
            setApproveTokenName('');
            // setApprove1Loading(false)
            //setApprove2Loading(false)
            // updateLiquidity()
            tokenArray[index].is_approved = true;
            setTokenArray(tokenArray);
          }
        })
        .once('error', function (error: any) {
          approveNotification('failed', tokenArray[index])
          setTransactionLink('')
          // console.log("on transaction cancel or error")
          setOnApproveError(1);
          onApproveErrorCheck = true;
          // console.log("set On approve state",onApproveError, onApproveErrorCheck)
          setApprove1Loading(false)
          setApprove2Loading(false)
          setShowTransModal(false)
        })
    } catch (error) {
    } finally {
      setApprove1Loading(false)
      setApprove2Loading(false)
      // getAllowance()
      setShowTransModal(false)
      setApproveLoading(false);
      
    }
    // console.log("on approve check", onApproveErrorCheck)
    return new Promise(resolve => {
      setTimeout(() => { resolve(onApproveErrorCheck);}, 10);
    })
    return onApproveErrorCheck;
  }

  const handleTransactionLink = (newLink: any, hashVal: any) => {
    const data: any = {
      firstToken: firstToken.name,
      secondToken: secondToken.name,
      amount1: firstAmount,
      amount2: secondAmount,
      link: newLink,
      type: 'addedLiquidity',
      status: false,
      hashVal: hashVal,
      transStatus: 'pending',
    }
    setTransLink(data)
  }

  const handleNotification = (type: string, hash?: any) => {
    const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
    const data: any = {
      type: type,
      message: `Added liquidity for ${parseFloat(firstAmount).toFixed(4)} ${firstToken.name} and ${parseFloat(secondAmount).toFixed(4)} ${secondToken.name}`,
      link: linkUrl
    }
    dispatch(notificationClose())
    dispatch(notificationOpen(data))
  }

  const handleAddLiquidity = async () => {
    try {
      setAddLdLoading(true);
      if (firstToken.sno === bnbSno || secondToken.sno === bnbSno) {

        await _addLiquidity(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 0)
      }

      else {
        await _addLiquidity(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 1)
      }
    } catch (error) {
      console.log('error==>', error)
    } finally {
      setAddLdLoading(false)
      setConfirmLdData({})
      setFirstAmount('')
      setSecondAmount('')
      updateLiquidity()
    }
  }

  const _addLiquidity = async (tokenA: any, tokenB: any, amountADesiredInEther: any, amountBDesiredInEther: any, to: any, flag: any) => {
    // console.log('in _addLiquidity');
    /*
     * type : true =>  addLiquidityEth
     * type : false =>  addLiquidity
     * flag = 0 for bnb
     * flag = 1 for others
     */
    let type: boolean
    let amountAMinInEther: any = 0.008 * amountADesiredInEther
    let amountBMinInEther: any = 0.008 * amountBDesiredInEther
    amountAMinInEther = amountAMinInEther.toString()
    amountBMinInEther = amountBMinInEther.toString()

    const pair = await returnPairExistOrNot(tokenA, tokenB)
    if (!pair) {
      amountAMinInEther = '0'
      amountBMinInEther = '0'
    }
    if (flag === 0) type = true
    else type = false

    let deadline = Math.floor(Date.now() / 1000) + DEADLINE
    let txArray: any

    const ROUTER_INSTANCE = await selectInstance(instanceType.PLANETROUTER)
    if (type) {
      if (firstToken.sno === bnbSno) {
        txArray = [
          tokenB,
          convertToWei(amountBDesiredInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountADesiredInEther),
        ]
      } else {
        txArray = [
          tokenA,
          convertToWei(amountADesiredInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountBDesiredInEther),
        ]
      }
      console.log('in true')
      console.log("txArray", txArray);
      console.log(to)
      await ROUTER_INSTANCE.methods
        .addLiquidityETH(txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({
          from: to,
          value: txArray[4],
        })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setAddLdLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setAddLdLoading(false)
        })
    } else {
      console.log('amountADesiredInEther');
      console.log(amountADesiredInEther);
      console.log(amountBDesiredInEther);
      console.log(amountAMinInEther);
      console.log(amountBMinInEther);
      txArray = [
        convertToWei(amountADesiredInEther),
        convertToWei(amountBDesiredInEther),
        convertToWei(amountAMinInEther),
        convertToWei(amountBMinInEther),
      ]

      console.log('in false')
      console.log("txArray", txArray);
      console.log(to)

      await ROUTER_INSTANCE.methods
        .addLiquidity(tokenA, tokenB, txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({ from: to })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setAddLdLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setAddLdLoading(false)
          setShowTransModal(false)
        })
    }
  }

  const handleMaxAmount = async () => {
    if (isPairExist) {
      let amount: any;
      if(parseFloat(firstToken.usdValue) > 0.01 && parseFloat(secondToken.usdValue) > 0.01){
        if (firstToken.sno === bnbSno && parseFloat(secondToken.value) !== 0 ) {
          amount = firstToken.value
          amount -= 0.01
        } else amount = firstToken.value
        amount = getNumFormat(amount)
        setFirstAmount(amount)
        setSendAmountCheck(amount)
        const secondAmtVal: any = await getAmountOfToken(amount, true, pairAddress, firstToken.address)
        setSecondAmount(secondAmtVal)
        getAllowance()
      }
      
    }
  }
  const handleSecondMaxAmount = async () => {
    if (isPairExist) {
      let amount: any
      if (secondToken.sno === bnbSno && parseFloat(firstToken.value) !== 0) {
        amount = secondToken.value
        amount -= 0.01
      } else amount = secondToken.value
      amount = getNumFormat(amount)
      setSecondAmount(amount)
      const firstAmtVal: any = await getAmountOfToken(amount, false, pairAddress, firstToken.address)
      setFirstAmount(firstAmtVal)
    }
  }

  const updateMaxAmount = (token: any, index: any, tokenArray: any) => {
    // console.log(tokenArray[index])
    if(tokenArray.length > 0){
      setMaxButton(true);
      tokenArray[index].inputValue = (+tokenArray[index].value).toFixed(2);
      tokenArray[index].inputValueWithPrecision = tokenArray[index].value;
      setTokenArray(tokenArray);
      if(index == 0){
        setFirstTokenValue((+tokenArray[index].value).toFixed(2));
        setFirstDisplayTokenValue(commaFy((+tokenArray[index].value).toFixed(2)));
      } else if(index == 1){
        setSecondTokenValue((+tokenArray[index].value).toFixed(2));
        setSecondTokenDisplayValue(commaFy((+tokenArray[index].value).toFixed(2)));
      } else {
        setThirdTokenValue((+tokenArray[index].value).toFixed(2));
        setThirdTokenDisplayValue(commaFy((+tokenArray[index].value).toFixed(2)));
      }
    }
  }

  const handleConfirm = async() => {
    // approvalCheck()
    // console.log(tokenArray);
    let depositAmount: any = 0;
    for(let i = 0; i < tokenArray.length; i++){
      if(firstTokenValue > 0 && tokenArray[0].allowance < firstTokenValue){
        //approve busd
        setApproveTokenName('BUSD')
        let approve_busd = await handleApprove(tokenArray[0].address, 0);
        if(approve_busd !== undefined && approve_busd){
          break;
        }
      }
      
      if(secondTokenValue > 0 && tokenArray[1].allowance < secondTokenValue){
        //approve usdc
        setApproveTokenName('USDC')
        let approve_usdc = await handleApprove(tokenArray[1].address, 1);
        if(approve_usdc !== undefined && approve_usdc){
          break;
        }
      }
      if(thirdTokenValue > 0 && tokenArray[2].allowance < thirdTokenValue){
        //approve usdt
        setApproveTokenName('USDT')
        let approve_usdt = await handleApprove(tokenArray[2].address, 2);
        if(approve_usdt !== undefined && approve_usdt){
          break;
        }
      }
      tokenArray[i].inputValue = tokenArray[i].name.toLowerCase() == "busd" ? firstTokenValue : tokenArray[i].name.toLowerCase() == "usdc" ? secondTokenValue : thirdTokenValue;
      
      // console.log(onApproveErrorCheck, i)
      if(onApproveErrorCheck){
        break;
      }
    }
    depositAmount = +firstTokenValue + +secondTokenValue + +thirdTokenValue;
    depositAmount = convertToWei(depositAmount.toString())
    let min_received_amount: any = 0;
    try{
      let firstVal = convertToWei(firstTokenValue);
      let secondVal = convertToWei(secondTokenValue);
      let thirdVal = convertToWei(thirdTokenValue);
      min_received_amount = await estimateTokensOnDeposit(stableSwapObject.poolAddress, [firstVal, secondVal, thirdVal])
    } catch(err){
      console.log(err)
    }
    
    stableSwapObject.minimum_received = commaFy(parseFloat(min_received_amount.toString()).toFixed(2));
    setStableSwapObject(stableSwapObject)
    if(!onApproveErrorCheck){
      tokenArray.receivedTokens = stableSwapObject.minimum_received;
      setConfirmLdData(tokenArray)
      setConfirmStableSwapBtn(false);
      setShowCnfModal(true)
      setConfirmSwapBtn(true)
    } else {
      setApproveTokenName('')
      setShowCnfModal(false)
      setConfirmSwapBtn(false)
      onApproveErrorCheck = false;
    }
    
  }

  const fetchTokenArrayData = () => {
    return tokenArray.map((item: any, index: number) => {
      return (
        <AddLDCard className='tokenCard'>
          <FlexSBCont>
            <FlexSBCont className='swapTokens'>
              <div className='tokenIcon'>
                <img className="stable-token-icon" src={item.icon} alt="" />
                <div className="tokenName">
                  <TokenName className='stableTokenName'>{item.name}</TokenName>
                  {addSSDataLoading || isNaN(item.value) ? <LoadingText1 /> :
                  <div>
                    <TokenName className='tokenBalance web'>Balance: {commaFy(parseFloat(item.value).toFixed(2))}</TokenName>
                    <TokenName className='tokenBalance mob'>Bal: {commaFy(parseFloat(item.value).toFixed(2))}</TokenName>
                  </div>
                  }
                </div>
                
              </div>
            </FlexSBCont>
            <FlexSBCont className="token-input stableTokensContainer">
              <TokenValInput
                className='stableTokenInput'
                placeholder="0"
                disabled={!(Object.values(firstToken).length > 0 )}
                onChange={(index == 0 ? handleFirstTokenChange : index == 1 ? handleSecondTokenChange : handleThirdTokenChange)}
                value={index == 0 ? firstTokenDisplayValue : index == 1 ? secondTokenDisplayValue : thirdTokenDisplayValue}
                autoFocus={index == 0 ? true : false}
              />
              
              {/* handleInputChange  handleAmountChange(e, index, tokenArray)*/}
              <MaxTxt  onClick={() => updateMaxAmount(item, index, tokenArray)}>Max</MaxTxt>
              
            </FlexSBCont>
          </FlexSBCont>
        </AddLDCard>
      )
    })
  }

  return (
    <Fragment>
      <CardCont className='mixedLiquidityContainer stableTokensContainer'>
        <AddLDCont className='AddLiquidityContainer'>
          {/* <FlexSBCont>
            <BackButton className='backButton' onClick={() => setLiquidityTab('liquidity')}>
              <img className='backIcon' src={ArrowDownGrey} alt="back-btn" />
              <InfoText className="backButtonText" light>
                Back
              </InfoText>
            </BackButton>
            <TitleText className='addLiquidity'>Add Liquidity</TitleText>
            <img className=" clock-icon" src={ClockIcon} alt="" onClick={() => setShowHistory(true)} />
          </FlexSBCont> */}
          {/* <InfoText className="add-token-ld" light>
            Add tokens to a liquidity pool
          </InfoText> */}
          <AddLDTokenCont className='tokensContainer'>
            {fetchTokenArrayData()}
            
          </AddLDTokenCont>
          <SwapConstCard className="stable-swap-info">
            <FlexSBCont className="swap-const-data">
              <SwapConstTxt light>Minimum Received</SwapConstTxt>
              <FlexSBCont>
                <img className="stable-icon" src={stableSwapIcon} alt="" />
                {minReceivedAmount && <PriceImpactTxt className="swapTokenPrice">{parseFloat(minReceivedAmount).toFixed(2)}
                </PriceImpactTxt>}
              </FlexSBCont>
            </FlexSBCont>
            <FlexSBCont className="swap-const-data">
              <SwapConstTxt light>{stableSwapObject.poolLPToken} Price</SwapConstTxt>
              {addSSDataLoading || isNaN(stableSwapObject.assetPrice) ? <LoadingText3 /> :
              <PriceImpactTxt className="swapTokenPrice">${parseFloat(stableSwapObject.assetPrice).toFixed(4)}</PriceImpactTxt>
              }
            </FlexSBCont>
            {(approveLoading && depositLoading != true) && !insufficientBalanceCheck ? <PrimaryBtn className="confirmStableSwapBtn" disabled><Spinner /> </PrimaryBtn>
            :
            <>
              {confirmStableSwapBtn && !insufficientBalanceCheck && !approveLoading && depositLoading != true? 
              <PrimaryBtn className="confirmStableSwapBtn" onClick={() => handleConfirm()} disabled={firstTokenValue == 0 && secondTokenValue == 0 && thirdTokenValue == 0}>{approveTokenName !== '' ? 'Approve ' + approveTokenName : approveTokenName !== '' && approveLoading == true ? "Approving " + approveTokenName : 'Confirm'}</PrimaryBtn> 
              :
              confirmStableSwapBtn && insufficientBalanceCheck && !approveLoading ?
              <PrimaryBtn className="confirmStableSwapBtn" disabled>
                {"Insufficient Balance"}
              </PrimaryBtn>
              :
              ""
              }
            </>
            }
        </SwapConstCard>
          
        </AddLDCont>
      </CardCont>
      <CustomModal show={showTokenModal} toggleModal={handleToggleModal}>
        {showTokenModal && (
          <TokenSelect
            selectedAdd={tokenType === 'first' ? secondToken.address : firstToken.address}
            setShowTokenModal={setShowTokenModal}
            tokenListVal={tokenList}
            handleTokenSelect={handleTokenSelect}
            existTokenAdd={existTokenAdd}
            crossIconColor={'white'}
          />
        )}
      </CustomModal>
      {/* <CustomModal show={showCnfModal} toggleModal={setShowCnfModal}>
        {showCnfModal && !confirmStableSwapBtn && (
          <ConfirmLiquidity setShowCnfModal={setShowCnfModal} confirmLdData={tokenArray} handleAddLiquidity={handleAddLiquidity} stableSwap={true} crossIconColor={"white"} />
        )}
      </CustomModal> */}
      <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
        {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} crossIconColor={"white"} />}
      </CustomModal>
    </Fragment>
  )
}

export default AddStableSwapLiquidity
