import { useState, useEffect, Fragment } from 'react'
import { withRouter } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentPlanet } from 'logic/action/planet.action'
import { FlexCont, MainContainer } from 'shared/styles/styled'
//import { MainContainer, FlexCont, FlexSBCont, ToolTipDataCont, ToolTipText, ToolTipIcon, LoadingText1, LoadingText2, LoadingText3 } from 'shared/styles/styled'
import { HeaderCont, HeaderPathCont, PathCont, Path, ActivePath, ActiveLendingPath, HeaderTitleCont, DotContWrapper, DotCont, DotContent, PriceWalletBox, PriceBox, ConnectWalletBtn, WarningCont, PoolContWrapper, PoolContent } from './style'
import GammaIcon from 'assets/icons/gamma-icon.png'
import AquaIcon from 'assets/icons/blue-icon.svg'
import HeaderSubLinks from './HeaderSubLinks'
import PoolSubLinks from './PoolsSubLinks'
import CustomModal from 'shared/custom-modal'
import WalletLogout from '../wallet-modal/WalletLogout'
import WalletList from '../wallet-modal/WalletList'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { commaFy } from 'service/globalFunctions'
import { lendingPathArr } from 'service/dataConstant'
import CloseIcon from 'assets/icons/white-cross.svg'
import {
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
} from 'wagmi'
import { mainnet, polygon, optimism, bsc, arbitrum } from 'wagmi/chains'
import { useWeb3Modal } from "@web3modal/react";
import { connectEthWallet, disconnectEthWallet } from 'logic/action/wallet.actions'
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import wallet from 'modules/wallet/wallet'
import {ethers} from 'ethers'

let windowWidth: any = window.innerWidth;

const Header = (props: any) => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('base')
  const [openLogoutModal, setOpenLogoutModal] = useState(false)
  const [openWalletList, setOpenWalletList] = useState(false)
  const [warningMsg, setWarningMsg] = useState(true)
  // const { ethWalletConnected, address } = selector.ethData
  const { platformData } = selector.user
  const { planetIcon, currentPlanet } = selector.planet
  const [platform_data, setPlatformData] = useState({
    aqua_price: 0,
    gamma_price: 0
  })

  const { connector: activeConnector, address, isConnected } = useAccount()
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect()
  const { disconnect } = useDisconnect()
  const [loading, setLoading] = useState(false);
  const { chain, chains } = useNetwork()
  const { open } = useWeb3Modal();
  const [providerClient, setProviderClient] = useState(undefined);
  // const chains = [arbitrum, mainnet, polygon, bsc];
  const projectId = '8f395d21e8a7dce987dfe4a6110febc9';
  
  useEffect(() => {
    
    // console.log('platformData', platformData, isConnected, connectors, address, activeConnector );
    setPlatformData(platformData);
    if(address && address !== null){
      dispatch(connectEthWallet(address));
      localStorage.setItem("walletConnected", JSON.stringify(true));
      let walletType = localStorage.getItem("wagmi.wallet");
      if(walletType != null  ) {
        let type = JSON.parse(walletType) == "walletConnect"? 3 : 1;
        localStorage.setItem("walletType", JSON.stringify(type));
        setProviderInWallet(type)
      } else {
        setProviderInWallet(1)
      }
    }
  },[platformData, address])

  // const web3Modal = new Web3Modal({
  //   providerOptions // required
  // });

  const setProviderInWallet = async (wallet_type?: any) => {
    if(wallet_type == 3){
      await wallet.setProvider(wallet_type);
      const address = await wallet.login(wallet_type);
    } else {
      await wallet.setProvider(1);
    }
  }

  useEffect(() => {
    const pathUrl: any = props.location.pathname.split('/')
    if (pathUrl[1]) {
      if(pathUrl[1] == "pools_v3"){
        pathUrl[1] = "poolsv3";
      }
      setActiveTab(pathUrl[1])
      if (lendingPathArr.includes(pathUrl[1])) {
        dispatch(setCurrentPlanet('green'))
      }
      else {
        dispatch(setCurrentPlanet('blue'))
      }
    } else {
      setActiveTab('base')
      dispatch(setCurrentPlanet('blue'))
    }
  }, [props.location.pathname, dispatch])

  const getAddress = (addressObj: any) => {
    let address = addressObj.address;
    // console.log("address", address, isConnected, chain, chains)
    const add1 = address.substring(0, 2)
    const add2 = address.substring(address.length - 4)
    const finalAdd = `${add1}....${add2}`
    return finalAdd
  }

  // async function onInitializeProviderClient() {
  //   const client: any = await EthereumProvider.init({
  //     projectId: projectId,
  //     showQrModal: true,
  //     qrModalOptions: { themeMode: "light" },
  //     chains: [1],
  //     methods: ["eth_sendTransaction", "personal_sign"],
  //     events: ["chainChanged", "accountsChanged"],
  //     metadata: {
  //       name: "My Dapp",
  //       description: "My Dapp description",
  //       url: "https://my-dapp.com",
  //       icons: ["https://my-dapp.com/logo.png"],
  //     },
  //   });
  //   setProviderClient(client);
  // }

  // useEffect(() => {
  //   onInitializeProviderClient();
  // }, []);

  const handlePathClick = (item: string) => {
    setActiveTab(item)
    if (item === 'base') {
      history.push(`${Paths.root}`)
    }
    if (item === 'vaults') {
      history.push(`${Paths.vaults}`)
    }
    if (item === 'poolsv3') {
      history.push(`${Paths.pools_v3}`)
    }
    if (item === 'swap') {
      history.push(`${Paths.swap}`)
    }
    if (item === 'lending') {
      history.push(`${Paths.lending}`)
    }
    // if (item === 'boost') {
    //   history.push(`${Paths.boost}`)
    // }
    if (item === 'liquidstaking') {
      history.push(`${Paths.liquidStaking}`)
    }
  }

  const hideWarning = () =>{
    setWarningMsg(false);
  }

  async function onOpen() {
    setLoading(true);
    await open();
    setLoading(false);
  }

  return (
    <Fragment>
      <MainContainer warningMsg={warningMsg} className="header"></MainContainer>
      {
       <HeaderCont warningMsg={warningMsg} className="warningContainer">
        <WarningCont className='warningContainer'>
          <FlexCont className='webWarning'>
            {activeTab === "poolsv3" || activeTab === "pools" ? 
            <p className="warningText"><p className="warningHeader">PHISHING WARNING:</p>&nbsp;Please make sure you're visiting&nbsp;<a href="https://app.planet.finance" className="siteLink">https://app.planet.finance</a>&nbsp;all other domains are fake. Old pools except gamma infinity vault have been discontinued. Migrate using&nbsp;<a href="https://app.planet.finance/migration" className="siteLink">Migration Link</a></p>
            :
            <p className="warningText"><p className="warningHeader">PHISHING WARNING:</p>&nbsp;Please make sure you're visiting&nbsp;<a href="https://app.planet.finance" className="siteLink">https://app.planet.finance</a>&nbsp;all other domains are fake.</p>
            }
          </FlexCont>
        </WarningCont>
        <img className='close-icon' src={CloseIcon} alt="" onClick={() => hideWarning()} />
      </HeaderCont>}
      <HeaderCont warningMsg={warningMsg} className="headerContainer">
        <HeaderPathCont>
          <HeaderTitleCont className="first-item">
            <img src={planetIcon} alt="" onClick={()=>window.open("https://planet.finance","_self")}/>
            <p>Planet</p>
          </HeaderTitleCont>
          <FlexCont style={{alignItems: "center"}}>
          {/* <PathCont>
              <Path activeTab={activeTab === 'boost'} onClick={() => handlePathClick('boost')}>Boost</Path>
              {activeTab === 'boost' && <ActiveLendingPath />}
            </PathCont> */}
            <PathCont>
              <Path activeTab={activeTab === 'base'} onClick={() => handlePathClick('base')}>Base</Path>
              {activeTab === 'base' && <ActivePath />}
            </PathCont>
            {/* <PathCont>
              <Path activeTab={activeTab === 'vaults'} onClick={() => handlePathClick('vaults')}>Vaults</Path>
              {activeTab === 'vaults' && <ActivePath />}
            </PathCont> */}
            <PoolContWrapper>
              <PathCont className='poolsV3'>
                <Path activeTab={activeTab === 'poolsv3'} onClick={() => handlePathClick('poolsv3')}>Pools V3</Path>
                {activeTab === 'poolsv3' && <ActivePath />}
                <PoolContent>
                    <PoolSubLinks />
                </PoolContent>
              </PathCont>
            </PoolContWrapper>
            
            <PathCont>
              <Path activeTab={activeTab === 'lending'} onClick={() => handlePathClick('lending')}>Lending</Path>
              {activeTab === 'lending' && <ActiveLendingPath />}
            </PathCont>

            <PathCont>
              <Path activeTab={activeTab === 'liquidstaking'} onClick={() => handlePathClick('liquidstaking')}>Liquid Staking</Path>
              {activeTab === 'liquidstaking' && <ActivePath />}
            </PathCont>
            
            <PathCont>
              <Path activeTab={activeTab === 'swap' || activeTab === 'liquidity'} onClick={() => handlePathClick('swap')}>Swap</Path>
              {(activeTab === 'swap' || activeTab === 'liquidity') && <ActivePath />}
            </PathCont>
            
            <PathCont>
              <Path onClick={()=> window.open("https://perp.planet.finance", "_blank")}>Perpetuals</Path>
              {activeTab === 'perpetuals' && <ActiveLendingPath />}
            </PathCont>

            <DotContWrapper>
              <DotCont>
                <span></span>
                <span></span>
                <span></span>
                <DotContent>
                  <HeaderSubLinks />
                </DotContent>
              </DotCont>
            </DotContWrapper>
          </FlexCont>
        </HeaderPathCont>
        <PriceWalletBox>
          <PriceBox >
            <img src={AquaIcon} alt="" />
            <p>${!!platform_data && platform_data.aqua_price ? commaFy(platform_data.aqua_price.toFixed(2)) : '0.00'}</p>
          </PriceBox>
          <PriceBox >
            <img src={GammaIcon} alt="" />
            <p>${!!platform_data && platform_data.gamma_price ? commaFy(platform_data.gamma_price.toFixed(3)) : '0.00'}</p>
          </PriceBox>
          {/* <Web3Button /> */}
          {/* {ethWalletConnected ? (
            <ConnectWalletBtn currPlanet={currentPlanet} onClick={() => setOpenLogoutModal(true)}>{getAddress(address)}</ConnectWalletBtn>
          ) : (
            <ConnectWalletBtn currPlanet={currentPlanet} onClick={() => setOpenWalletList(true)}>Connect Wallet</ConnectWalletBtn>
          )} */}
          {isConnected ? (  
            <ConnectWalletBtn currPlanet={currentPlanet} onClick={() => setOpenLogoutModal(true)}>{getAddress({address})}</ConnectWalletBtn>
          ) : 
          <ConnectWalletBtn currPlanet={currentPlanet} onClick={() => onOpen()}>Connect Wallet</ConnectWalletBtn>
            
          }    
        </PriceWalletBox>
      </HeaderCont>
      <CustomModal show={openLogoutModal} toggleModal={setOpenLogoutModal}>
        <WalletLogout setOpenModal={setOpenLogoutModal} />
      </CustomModal>
      <CustomModal show={openWalletList} toggleModal={setOpenWalletList}>
        <WalletList setOpenModal={setOpenWalletList} />
      </CustomModal>
    </Fragment>

  )
}

export default withRouter(Header)