import { convertToEther, convertToWei, gBnbAddress, instType, selectInstance } from './lendingAbi'
import { zeroAddress } from './SwapDexAbi'
import { bnbAddress } from './abi'
import { pfTokenList } from './pfTokenList'
import { getUnderlyingDecimal, tokenApprove } from './LendingBase'


/************************************************Read Only Functions************************************************************/

export const accrualBlockNumber = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.accrualBlockNumber().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const admin = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.admin().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const allowance = async (owner: string, spender: string, gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.allowance(owner, spender).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const balanceOfGtoken = async (owner: string, gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.balanceOf(owner).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const borrowBalanceStored = async (account: string, gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.borrowBalanceStored(account).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const borrowIndex = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.borrowIndex().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const borrowRatePerBlock = async (gTokenAddress: string,blockNumber?:number) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = blockNumber === undefined ? await inst.methods.borrowRatePerBlock().call() :
    await inst.methods.borrowRatePerBlock().call(undefined,blockNumber)
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const gammatroller = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.gammatroller().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const decimals = async (gTokenAddress: string) => {
  if(gTokenAddress == undefined) {
    return 0;
  }
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.decimals().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const exchangeRateStored = async (gTokenAddress: string,blockNumber?:number) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = blockNumber === undefined ? await inst.methods.exchangeRateStored().call() :
    await inst.methods.exchangeRateStored().call(undefined,blockNumber)
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}


export const getAccountSnapshot = async (account: string, gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.getAccountSnapshot(account).call()
    return data
  } catch (e) {
    return {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
    }
  }
}

export const getCash = async (gTokenAddress: string,blockNumber?:number) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = blockNumber === undefined ? await inst.methods.getCash().call() :
    await inst.methods.getCash(undefined,blockNumber).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const interestRateModel = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.interestRateModel().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const isCToken = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.isCToken().call()
    return data
  } catch (e) {
    console.log(e)
    return false
  }
}

export const name = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.name().call()
    return data
  } catch (e) {
    console.log(e)
    return null
  }
}

export const pendingAdmin = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.pendingAdmin().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const protocolSeizeShareMantissa = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.protocolSeizeShareMantissa().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const reserveFactorMantissa = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.reserveFactorMantissa().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const supplyRatePerBlock = async (gTokenAddress: string,blockNumber?:number) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const supp = await totalSupply(gTokenAddress)
    let data = 0
    
    if(gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase() && parseFloat(supp) === 0){
      data = 0
    }
    else{
      data = blockNumber === undefined ? await inst.methods.supplyRatePerBlock().call() :
      await inst.methods.supplyRatePerBlock().call(undefined,blockNumber)
    }

    return data
  } catch (e) {
    console.log("error =>",gTokenAddress,blockNumber)
    return 0
  }
}

export const symbol = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.symbol().call()
    return data
  } catch (e) {
    console.log(e)
    return null
  }
}

export const totalBorrows = async (gTokenAddress: string,blockNumber?:number) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = blockNumber === undefined ? await inst.methods.totalBorrows().call() :
    await inst.methods.totalBorrows().call(undefined,blockNumber) 
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const totalReserves = async (gTokenAddress: string) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = await inst.methods.totalReserves().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const totalSupply = async (gTokenAddress: string,blockNumber?:number) => {
  try {
    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
    const data = blockNumber === undefined ? await inst.methods.totalSupply().call() :
    await inst.methods.totalSupply().call(undefined,blockNumber)
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}





export const underlying = async (gTokenAddress: string) => {
  try {
    if (gTokenAddress.toLowerCase() === (pfTokenList[0].address).toLowerCase()) {
      return bnbAddress
    }
    else {
      const inst: any = await selectInstance(instType.gToken, gTokenAddress)
      const data = await inst.methods.underlying().call()
      return data
    }
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

/********************************************************************************************************************************/

export const mint = async (gTokenAddress: string, userAddress: string, amountInEth: string) => {
  try {
    if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase()) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
      const data = await inst.methods.mint().send({
        from: userAddress,
        value: convertToWei(amountInEth, 1e18)
      })
      return data;
    }
    else {
      const inst: any = await selectInstance(instType.gToken, gTokenAddress)
      const decimal = Math.pow(10,await getUnderlyingDecimal(gTokenAddress));
      const data = await inst.methods.mint(convertToWei(amountInEth, decimal)).send({
        from: userAddress,
      })
      return data;
    }
  } catch (e) {
    console.log(e)
    return {};
  }
}

export const redeemUnderlying = async (gTokenAddress: string, userAddress: string, amountInEth: string) => {
  try {
    const inst: any = await selectInstance(instType.gToken, gTokenAddress)
    const data = await inst.methods.redeemUnderlying(convertToWei(amountInEth, 1e18)).send({
      from: userAddress,
    })
    return data;
  } catch (e) {
    console.log(e)
    return {};
  }
}

export const borrow = async (gTokenAddress: string, userAddress: string, amountInEth: string) => {
  try {
    const inst: any = await selectInstance(instType.gToken, gTokenAddress)
    const decimal = Math.pow(10,await getUnderlyingDecimal(gTokenAddress));
    const data = await inst.methods.borrow(convertToWei(amountInEth, decimal)).send({
      from: userAddress,
    })
    return data;
  } catch (e) {
    console.log(e)
    return {};
  }
}

export const repayBorrow = async (gTokenAddress: string, userAddress: string, amountInEth: string) => {
  try {
    if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase()) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
      const data = await inst.methods.repayBorrow().send({
        from: userAddress,
        value: convertToWei(amountInEth, 1e18)
      })
      return data;
    }
    else {
      const inst: any = await selectInstance(instType.gToken, gTokenAddress)
      const decimal = Math.pow(10,await getUnderlyingDecimal(gTokenAddress));
      const data = await inst.methods.repayBorrow(convertToWei(amountInEth, decimal)).send({
        from: userAddress,
      })
      return data
    }
  } catch (e) {
    console.log(e)
    return {};
  }
}

export const liquidateBorrow = async (gTokenAddress: string,
  liquidator: string,
  borrower: string,
  gTokenCollateral: string,
  repayAmountInEth: string) => {
  try {
    if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase()) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
      const data = await inst.methods.liquidateBorrow(borrower, gTokenCollateral).send({
        from: liquidator,
        value: convertToWei(repayAmountInEth, 1e18)
      })
      return data;
    }
    else {
      const inst: any = await selectInstance(instType.gToken, gTokenAddress)
      const _token = await underlying(gTokenAddress)
      const tokenInstance = await selectInstance(instType.gToken, _token)
      const allowanceVal = convertToEther(await tokenInstance.methods.allowance(liquidator, gTokenAddress).call(), 18)
      if (parseFloat(repayAmountInEth) > parseFloat(allowanceVal)) {
        await tokenApprove(_token, gTokenAddress, liquidator)
      }
      const decimal = Math.pow(10,await getUnderlyingDecimal(gTokenAddress));
      const data = await inst.methods.liquidateBorrow(borrower, convertToWei(repayAmountInEth, decimal), gTokenCollateral).send({
        from: liquidator,
      })
      return data

    }
  } catch (e) {
    console.log(e)
    return {};
  }
}

export function findLargest3(arr : number[]) {
  var scoreByPattern = arr
  var maxIndex = new Array();
  var maxPoints = new Array();
  var i;
  maxPoints[0] = 0;
  maxPoints[1] = 0;
  maxPoints[2] = 0; 
  
  for (i = 0; i < scoreByPattern.length; i++) {
    if (scoreByPattern[i] > maxPoints[0]) {
      maxPoints[0] = scoreByPattern[i];
      maxIndex[0] = i;
    }
  }

  for (i = 0; i < scoreByPattern.length; i++) {
    if (scoreByPattern[i] > maxPoints[1] && scoreByPattern[i] < maxPoints[0]) {
      maxPoints[1] = scoreByPattern[i];
      maxIndex[1] = i;
    }
  }

  for (i = 0; i < scoreByPattern.length; i++) {
    if (scoreByPattern[i] > maxPoints[2] && scoreByPattern[i] < maxPoints[1]) {
      maxPoints[2] = scoreByPattern[i];
      maxIndex[2] = i;
    }
  }
  return maxIndex;
  //alert(maxIndex);
}



