import { LENDING_PLATFORM_LOADING, USER_ASSET_LOADING, BORROW_LIMIT_LOADING, ADD_NULL_LOADING, UPDATE_LENDING_PLATFORM_DATA, UPDATE_USER_ASSET_DATA, UPDATE_BORROW_LIMIT, UPDATE_USER_OLD_ASSET_DATA, UPDATE_NEW_LENDING_PLATFORM_DATA, BORROW_LIMIT_LOADING_NEW, UPDATE_BORROW_LIMIT_NEW, LENDING_PLATFORM_LOADING_NEW, USER_ASSET_LOADING_NEW } from '../action/action.config'

const initialState = {
    addNullLoading: false,
    gPlatformLoading: false,
    gPlatformLoadingNew: false,
    gPlatformData: {},
    gPlatformDataNew: {},
    userAssetLoading: false,
    userAssetLoadingNew: false,
    userAssetData: {
        suppliedAssets: [],
        borrowedAssets: [],
        marketData: [],
        gammaObj:{},
        levelBorrowApyArray:[],
        total_lending_tvl: 0
    },
    userOldAssetData: {
        suppliedAssets: [],
        borrowedAssets: [],
        marketData: [],
        gammaObj:{},
        levelBorrowApyArray:[],
    },
    borrowLimitLoading: false,
    borrowLimitLoadingNew: false,
    borrowLimit: {},
    borrowLimitNew: {}

}

export const lendingReducer = (state = initialState, action: any) => {
    const { type, payload } = action
    switch (type) {

        case ADD_NULL_LOADING:
            return {
                ...state,
                addNullLoading: payload,
            }
        case LENDING_PLATFORM_LOADING:
            return {
                ...state,
                gPlatformLoading: payload,
            }
        case LENDING_PLATFORM_LOADING_NEW:
            return {
                ...state,
                gPlatformLoadingNew: payload,
            }
        case UPDATE_LENDING_PLATFORM_DATA:
            return {
                ...state,
                gPlatformData: payload,
            }

        case UPDATE_NEW_LENDING_PLATFORM_DATA:
            return {
                ...state,
                gPlatformDataNew: payload,
            }

        case USER_ASSET_LOADING_NEW:
            return {
                ...state,
                userAssetLoadingNew: payload,
            }
        case USER_ASSET_LOADING:
            return {
                ...state,
                userAssetLoading: payload,
            }
        case UPDATE_USER_ASSET_DATA:
            return {
                ...state,
                userAssetData: payload,
            }
        case UPDATE_USER_OLD_ASSET_DATA:
            return {
                ...state,
                userOldAssetData: payload,
            }

        case BORROW_LIMIT_LOADING:
            return {
                ...state,
                borrowLimitLoading: payload,
            }

        case UPDATE_BORROW_LIMIT:
            return {
                ...state,
                borrowLimit: payload,
            }

        case BORROW_LIMIT_LOADING_NEW:
            return {
                ...state,
                borrowLimitLoadingNew: payload,
            }

        case UPDATE_BORROW_LIMIT_NEW:
            return {
                ...state,
                borrowLimitNew: payload,
            }
        default:
            return state
    }
}
