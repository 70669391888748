import styled from "styled-components";
import { colors, screenSizes } from 'shared/styles/theme'
import { PlanetCard } from "shared/styles/styled";

export const PriceChartCont = styled.div`
  zoom: 110%;
`

/* ======================================= Metrics History ================================ */

export const HistoryCont = styled.div`
  margin-top: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-top: 50px;
  }
  .hash-web{
    display: none;
    @media (min-width: ${screenSizes.mediaL}px) {
      display: block;
    }
  }
  .hash-mobile{
    display: block;
    @media (min-width: ${screenSizes.mediaL}px) {
      display: none;
    }
  }
  .web-cell{
    display: none;
    @media (min-width: ${screenSizes.mediaM}px) {
      display: block;
    }
  }
  .mobile-cell{
    display: block;
    @media (min-width: ${screenSizes.mediaM}px) {
      display: none;
    }
  }
`
export const HistoryTitle = styled.h1`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 24px;
    line-height: 29px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 30px;
    line-height: 37px;
  }

`
export const HistorySwitchCont = styled(PlanetCard) <any>`
  width: 177px;
  height: 30px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  border: 1px solid ${colors.borderColor};
  padding: 2px 3px;
`
export const SwitchText = styled.div<any>`
  background: ${(props: any) => props.isActive ? 'rgba(85,255,118, 0.2)' : 'transparent'};
  border-radius: 20px;
  border:0;
  cursor: ${(props: any) => props.isActive ? "initial" : "pointer"};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props: any) => props.isActive ? 1 : 0.4};
  p{
    font-size: 14px;
    font-family: MontserratSemiBold;
    color: ${(props: any) => props.isActive ? colors.lightGreen : colors.white};
    margin: 0;
    text-align: center;
    opacity: 1;
  }
`
export const TableWrapper = styled(PlanetCard)`
  overflow-x: auto;
  margin-top: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-top: 30px;
  }
`
export const HistoryTable = styled.div`
  box-sizing: border-box;
  padding: 18px 0 0 0;
  border-radius: 0;
  .table-header{
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 15px;
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-bottom: 0;
      
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 0;
  }
`

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1.2fr repeat(2,1fr);
  grid-gap: 10px;
  padding: 0 20px;
  border-bottom: none;
  text-align: left;
  align-items: center;
  margin-bottom: 21px;
  &:last-child{
    border-bottom: 0;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-template-columns: 1fr 1fr 1fr 50px;
    border-bottom: 1px solid ${colors.borderColor};
    padding: 30px;
    margin-bottom: 0;
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    grid-template-columns: 1fr 3fr 1fr 50px;
  }
`

export const TableText = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.white};
  font-family: MontserratMedium;
  margin: 0;
  opacity: 0.4;
  @media (min-width: ${screenSizes.mediaL}px) {
    font-size: 12px;
    line-height: 15px;
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    font-size: 20px;
    line-height: 25px;
  }

`
export const HeadText = styled(TableText)`
  opacity: 0.4;
`
export const CellText = styled(TableText) <any>`
  font-family: MontserratBold;
  opacity: ${(props: any) => props.light ? 0.4 : 1};
`
export const AmountText = styled.p <any>`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaL}px) {
    font-size: 12px;
    line-height: 15px;
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    font-size: 20px;
    line-height: 25px;
  }
`
export const TransLink = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 14px;
    height: 16px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 16px;
      height: 18px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 18px;
      height: 20px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      width: 21.52px;
      height: 24px;
    }
  }

`

export const EmptyText = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  text-align: center;
  margin: 50px 0;
`

/* ================================== rechart custom tooltip ======================= */

export const ToolTipCont = styled.div`
  padding: 10px;
  background: #1E2442;
  border-radius: 10px;
`

export const ToolTipLabel = styled.h1`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
`
export const ToolTipLabelTime = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.white};
  font-family: MontserratMedium;
  margin: 0;
`
export const ToolTipDataTxt = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 5px 0 0;
`

export const CustomTooltip = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    .tooltip-body {
        width: 100%;
        position: relative;
        background: transparent;
        border: 1px solid ${colors.borderColor};
        text-align: center;
        padding: 1px 3px;
        border-radius: 2px;

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            width: 0;
            height: 0;
            border: 10px solid transparent;
            margin-left: -10px;
            bottom: 0;
            border-top-color: ${colors.borderColor};
            border-bottom: 0;
            margin-bottom: -10px;
        }

        p {
            font-size: 0.75em;
            color: ${colors.white};
            margin: 0;
            align-self: center;
        }
    }
`