import { useState } from 'react'
import { ToolTipCont, ToolTipLabel, ToolTipDataTxt } from './style'
import { ComposedChart, Line, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { convertToKAndM } from 'service/globalFunctions'
import { colors } from 'shared/styles/theme';
import moment from 'moment'


const getName = (activeSwitch: string, name: string) => {
    if (activeSwitch === 'supply') {
        if (name === 'supplyApy') {
            return 'SUPPLY APY'
        }
        if (name === 'totalSupply') {
            return 'TOTAL SUPPLY'
        }
    }
    else {
        if (name === 'borrowApy') {
            return 'BORROW APY'
        }
        if (name === 'totalBorrow') {
            return 'TOTAL BORROW'
        }
    }
}
const getValue = (name: string, value: any) => {
    if (name === 'supplyApy' || name === 'borrowApy') {
        const newVal: any = parseFloat(value) - 1
        const finalVal = parseFloat(newVal) * 1000
        return `${finalVal.toFixed(2)}%`
    }
    if (name === 'totalSupply' || name === 'totalBorrow') {
        return convertToKAndM(value)
    }
}
const CustomTooltip = ({ active, payload, label, activeSwitch }: any) => {
    if (active) {
        return (
            <ToolTipCont>
                <ToolTipLabel>{labelFormatter(label)}</ToolTipLabel>
                {payload && payload.length > 0 && (
                    payload.map((p: any) => (
                        <ToolTipDataTxt key={`${p.name}-${p.stroke}`} className="label medium">{`${getName(activeSwitch, p.name)} : ${getValue(p.name, p.value)}`}</ToolTipDataTxt>
                    )))}
            </ToolTipCont>
        );
    }

    return null;
};

const labelFormatter = (tickItem: any) => {
    return moment(tickItem).format('MMM Do YYYY');
}

const PlanetGraph = (props: any) => {
    const { activeSwitch, data, hoverColor } = props
    const [focusBar, setFocusBar] = useState(null);
    return (
        <ResponsiveContainer width="99.5%" height={250}>
            <ComposedChart data={data} barCategoryGap={0.5} onMouseMove={(state: any) => {
                if (state.isTooltipActive) {
                    setFocusBar(state.activeTooltipIndex);
                } else {
                    setFocusBar(null);
                }
            }}>
                <defs>
                    <filter id="shadow" height="200%">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="7" result="blur" />
                        <feOffset in="blur" dx="0" dy="7" result="offsetBlur" />
                        <feFlood floodColor={hoverColor} floodOpacity="0.5" result="offsetColor" />
                        <feComposite
                            in="offsetColor"
                            in2="offsetBlur"
                            operator="in"
                            result="offsetBlur"
                        />
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
                <XAxis hide={true} dataKey='date' />
                <YAxis yAxisId="left" hide={true} domain={[0, (dataMax: any) => (dataMax * 3)]} />
                <YAxis yAxisId="right" orientation='right' hide={true} />
                <Tooltip content={<CustomTooltip activeSwitch={activeSwitch} />} />

                <Line yAxisId="right" type="monotone" dataKey={activeSwitch === "supply" ? 'supplyApy' : 'borrowApy'} filter="url(#shadow)" fill={colors.lightGreen} stroke={hoverColor} dot={false} strokeWidth={3} />
                <Bar yAxisId="left" dataKey={activeSwitch === "supply" ? 'totalSupply' : 'totalBorrow'} layout="vertical" minPointSize={5}  >
                    {data.map((entry: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={focusBar === index ? hoverColor : '#163418'} display="planet finance" />
                    ))}
                </Bar>

            </ComposedChart>
        </ResponsiveContainer>
    )
}
export default PlanetGraph
