import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserLendingData } from 'logic/action/lending.action'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont } from 'shared/styles/styled'
import { HAModalCont, HAMDataCont, HAModalTitle, TokenInfoBox, HATokenIcon, TokenValTxt, ModalAPYTxt, TvlTxt, SwitchCont, DepositSwitch, WithdrawSwitch, ModalCard, ModalCardTitle, DataInfoCard, InfoHeaderCont, DataInfoTitle, DataInfoCont, DataInfoTxt, LoadingText3, LoadingText4, RepaySwitch, SupplySwitch } from 'shared/styles/highestApyStyle'
import { ModalInputCont, ModalInput, MaxBox, MaxBoxZero, UserBalTxt } from 'shared/styles/highestApyStyle'
// import QuestionIcon from 'assets/icons/question-icon.svg'
import QuestionIcon from 'assets/icons/question_icon.png'
import { ToolTipIcon, ToolTipText, ToolTipDataCont} from 'shared/styles/styled'
import { CardBtnGroup, CardBtn, TransformBtn, CardRedBtn, CardGreenBtn, RepayCardBtn } from 'shared/styles/highestApyStyle'
import CloseIcon from 'assets/icons/white-cross.svg'
import DownIcon from 'assets/icons/down.svg'
import { getTokenIcon } from 'shared/tokenIconList'
import { floatNumRegex, } from 'shared/helpers/regexConstants'
import { getTokenName, commaFy, convertToKAndM, checkZeroVal, getNumFormat } from 'service/globalFunctions'
import { transLinkUrl, fraxTranLink, maticTransLink, dotTransLink, solTransLink, adaTransLink, lunaTransLink, austTransLink, avaxTransLink, atomTransLink, xrpTransLink, linkTransUrl, ftmTransLink, whustTransferLink } from 'service/global-constant'
import Spinner from 'shared/spinner'
import { isAllowanceApproved } from 'modules/block-chain/BlockChainMethods'
import { getBorrowLimitParamsOnChange, getParamsWhenBorrowLimitIsChanged, checkErrors} from 'modules/block-chain-green/LendingBase';
import { gBnbAddress, convertToWei, instType, selectInstance, MAX_INT, noExponents, anchor_apy } from 'modules/block-chain-green/lendingAbi'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { getUnderlyingDecimal } from 'modules/block-chain/LendingBase'
import BoostVaultAction from '../../vaults/components/vault-modal/BoostVaultAction'
import {
    ModalCont,
    ModalHeader,
    CloseModalImg,
    InputCont,
    InputField,
    MaxTxt,
    InputValText,
    FlexCont,
    DataInputCont,
    InfoText,
    DataSwitchCont,
    SwitchText,
    ValueText,
    ActionBtn,
    ActionBtn2,
    InfoSmallText,
    SelectAssetWrapper,
    SelectAssetCont,
    SelectAssetText,
    VLDivider,
    SliderContainer,
    SliderContainerNew,
    ErrorText,
    ValueTextWhite,
    ApyText,
    TableSwitchCont
} from './style'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css' 
import {
    CardBoldText, 
    CardText,
    VerticalCardDivider,
} from "../style";
import { checkNegative } from 'service/globalFunctions'
import ReactTooltip from "react-tooltip";
import { enterMarkets, exitMarkets } from 'modules/block-chain-green/gammatroller'
import ToggleSwitch from 'shared/toggle-switch'
import { inputNumRegex } from 'shared/helpers/regexConstants'
import ToolTipModal2 from 'shared/tooltip-modal/Tooltip2'
import CustomModal from 'shared/custom-modal'
import { gBNBxAddress } from 'modules/block-chain/lendingAbi'

const AssetSupplyRevised = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('supply')
    const [suppAmt, setSuppAmt] = useState('')
    const [suppVal, setSuppVal] = useState('')
    const [depositAmount, setDepositAmount] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [depositLoading, setDepositLoading] = useState(false)
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [isAllowance, setIsAllowance] = useState(false)
    const [showVaultInfo, setShowVaultInfo] = useState(false)
    const [showFeeInfo, setShowFeeInfo] = useState(false)
    const [showAPYCal, setShowAPyCal] = useState(false)
    const [borrowLimit, setBorrowLimit] = useState<any>(0)
    const [maxBorrow, setMaxBorrow] = useState<any>(0)
    const [assetType, setAssetType] = useState('token')
    const [switchStatus, setSwitchStatus] = useState('supply')
    const [suppLoading, setSuppLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [activateLoading, setActivateLoading] = useState(false)
    const [transactionLink, setTransactionLink] = useState('')
    const [showTransModal, setShowTransModal] = useState(false)
    const [showToolTip, setShowTooltipModal] = useState(false)
    const [toolTipTitle, setToolTipTitle] = useState('')
    const [toolTipMessage, setToolTipMessage] = useState('')
    const [errorLiquidationText, setErrorLiquidationText] = useState('')
    const [withdrawMax, setWithdrawMax] = useState(false)
    const { portfolioLoading, poolData } = selector.user
    const { gPlatformDataNew, userAssetData } = selector.lending
    const { toggleVaultModal, data, setModalData, currentTab } = props
    const gPlatformData = gPlatformDataNew
    useEffect(() => {
        const dataArr = poolData.newVaults; // poolData.newVaults
        // console.log(dataArr, poolData)
        let vault_details = dataArr.active.filter((e: any) => e.address === data.address)
        let market_details = userAssetData.marketData.filter((e: any) => e.address === data.address)
        // console.log("vault_details", vault_details)
        // console.log("market_details", market_details)
        let revised_data = data;
        // console.log("data props", data)
        if(vault_details.length > 0){
            let supplyTokenObject = Object.assign( vault_details[0], revised_data);
            supplyTokenObject = Object.assign( supplyTokenObject, market_details[0]);
            setModalData(supplyTokenObject)
            // console.log("supplyTokenObject",supplyTokenObject);
        } else {
            if(data.borrowLimitUsedCurr !== undefined){
                setBorrowLimit(data.borrowLimitUsedCurr)
            }
        }

        ReactTooltip.rebuild();

        if (!!data && Object.values(data).length > 0) {
            if(data.borrowLimitUsedCurr !== undefined){
                setBorrowLimit(+data.borrowLimitUsedCurr)
            }
            setMaxBorrow(data.borrowLimitInUsd)
            if (data.name === 'AQUA (OLD)' || data.name === 'GAMMA (OLD)') {
                setSwitchStatus('withdraw')
            }
        }
        
    }, [poolData, data, setModalData, currentTab, userAssetData])


    useEffect(() => {
        const userAddress = selector.ethData.address
        const getIsAllowance = async () => {
            // console.log("data", data)
            if (data.name === 'BNB' || data.name === 'WBNB') {
                // console.log("in here", data.name)
                setIsAllowance(true)
            }
            else {
                // console.log("in else")
                const wantAddress = data.address? data.address :  data.wantAddress;

                if(typeof wantAddress == undefined)
                {
                    setIsAllowance(false)
                    return 1;
                }
                let farmAddress = ""

                try {
                    let isAllow = await isAllowanceApproved(userAddress, farmAddress, wantAddress)
                    
                    // const tokenInstance: any = await selectInstance(instType.PANCAKELP, wantAddress)
                    // const data = await tokenInstance.methods.allowance(userAddress, wantAddress).call()

                    if(+data.gTokenAllowance == +MAX_INT || +data.allowance > 0){
                        isAllow = true;//false means user has to activate
                    }
                    // console.log(isAllow, data)
                    setIsAllowance(isAllow)
                }
                catch (error) {
                    console.log("error=>", error)
                }
            }
        }
        if (!!data && !data.isBoostPool) {
            getIsAllowance()
        }

    }, [selector.ethData.address, data])

    const handleDepositAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setDepositAmount(value)
        }
        if (!value) {
            setDepositAmount('')
        }
    }
    const handleWithdrawAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setWithdrawAmount(value)
        }
        if (!value) {
            setWithdrawAmount('')
        }
    }


    const handleNotification = (type: string, actionType: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        //actionType === 'Deposit' ? depositAmount : withdrawAmount
        // const amtInToken = (suppAmt/data.price).toString();
        const price = data.price !== undefined ? +data.price : +data.underlyingPrice;
        const amt: any = (+suppAmt/price).toString();
        const dataVal: any = {
            type: type,
            message: `${actionType}  ${parseFloat(amt).toFixed(4)} ${data.name}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleDepositAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(depositAmount) > 0) {
            try {
                setDepositLoading(true)
                const amountInEth = depositAmount
                const userAddress = selector.ethData.address
                const gTokenAddress = data.address
                if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase()) {
                    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
                    await inst.methods.mint().send({
                        from: userAddress,
                        value: convertToWei(amountInEth, 1e18)
                    })
                        .once('confirmation', function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            handleNotification(type, 'Deposit', receipt.transactionHash)
                            setDepositLoading(false)
                            dispatch(updateUserLendingData(selector.ethData.address))
                        })
                        .on('error', function (error: any) {
                            handleNotification('failed', 'Deposit')
                            setDepositLoading(false)
                        })
                }
                else {
                    const inst: any = await selectInstance(instType.gToken, gTokenAddress)
                    const decimal = Math.pow(10, await getUnderlyingDecimal(gTokenAddress));
                    await inst.methods.mint(convertToWei(amountInEth, decimal)).send({
                        from: userAddress,
                    })
                        .once('confirmation', function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            handleNotification(type, 'Deposit', receipt.transactionHash)
                            setDepositLoading(false)
                            dispatch(updateUserLendingData(selector.ethData.address))
                        })
                        .on('error', function (error: any) {
                            handleNotification('failed', 'Deposit')
                            setDepositLoading(false)
                        })
                }
            }
            catch (error) {
                console.log("error=>", error)
                setDepositLoading(false)
            }
            finally {
                setDepositLoading(false)
            }

        }
    }
    const handleWithdrawAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(withdrawAmount) > 0) {
            try {
                setWithdrawLoading(true)
                const amountInEth = withdrawAmount
                const userAddress = selector.ethData.address
                const gTokenAddress = data.address
                const inst: any = await selectInstance(instType.gToken, gTokenAddress)
                const decimal = Math.pow(10, await getUnderlyingDecimal(gTokenAddress));
                await inst.methods.redeemUnderlying(convertToWei(amountInEth, decimal)).send({
                    from: userAddress,
                })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        handleNotification(type, 'Withdraw', receipt.transactionHash)
                        setWithdrawLoading(false)
                        dispatch(updateUserLendingData(selector.ethData.address))
                    })
                    .on('error', function (error: any) {
                        handleNotification('failed', 'Withdraw')
                        setWithdrawLoading(false)
                    })
            }
            catch (error) {
                console.log("error=>", error)
                setWithdrawLoading(false)
            }
            finally {
                setWithdrawLoading(false)
            }
        }
    }

    const approveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} approved` : `Failed to approve  ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }
    const handleApprove = async () => {
        try {
            setApproveLoading(true)
            const underlyingAddress = data.underlyingAddress
            const gTokenAddress = data.address
            const userAddress = selector.ethData.address
            const TOKEN_INSTANCE = await selectInstance(instType.PANCAKELP, underlyingAddress)
            await TOKEN_INSTANCE.methods
                .approve(gTokenAddress, MAX_INT)
                .send({ from: userAddress })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    approveNotification(type, receipt.transactionHash)
                    setApproveLoading(false)
                    setIsAllowance(true)
                })
                .on('error', function (error: any) {
                    approveNotification('failed')
                    setApproveLoading(false)
                })
        }
        catch (error) {
            console.log("error==>", error)
            setApproveLoading(false)
        }
        finally {
            setApproveLoading(false)
        }
    }

    const getSuppVal = (val: any) => {
        let finalVal: any = 0
        if (!!val) {
            if (assetType === 'token') {
                finalVal = parseFloat(val) * parseFloat(data.underlyingPrice)
            }
            else {
                if (parseFloat(data.underlyingPrice) > 0) {
                    finalVal = parseFloat(val) / parseFloat(data.underlyingPrice)
                }
            }
        }
        setSuppVal(finalVal)
    }

    const handleInputError = async (inputAmount: any) => {
        // console.log("inout amount", inputAmount)
        const userAddress = selector.ethData.address
        const type = switchStatus === 'supply' ? 1 : 2

        if (assetType === 'usd') {
            inputAmount = parseFloat(inputAmount) / parseFloat(data.underlyingPrice)
        } else {
            inputAmount = parseFloat(inputAmount);
        }

        try {
            const errorRes: any = await checkErrors(data, userAddress, type, inputAmount)
            // console.log("supply input error", inputAmount, errorRes);
            if (!!errorRes && typeof errorRes !== 'undefined') {
                if (!errorRes.proceed) {
                    // setIsError(true);
                    setIsError(true);
                    setErrorText("");
                    setErrorLiquidationText(errorRes.reason);
                    // setTimeout(() => {
                    //     setIsError(true);
                    //     setErrorText("");
                    //     setErrorLiquidationText(errorRes.reason);
                    //   }, 500);
                    
                }
                else {
                    // console.log("in else")
                    setIsError(false)
                    setErrorText("")
                    setErrorLiquidationText("");
                }
            }
        }
        catch (error) { }
    }

    const handleBuyToken = () => {
        history.push(`${Paths.swap}`, { tokenAdd: data.underlyingAddress.toLowerCase() })
    }

    const handleCollateral = async (itemData: any) => {
        //setCollateralData(itemData)
        //setShowCollateral(true)

        let collRes: any = {}
        try {
            if (itemData.isUsingAsCollateral) {
                collRes = await exitMarkets(itemData.address, selector.ethData.address)
            }
            else {
                collRes = await enterMarkets(itemData.address, selector.ethData.address)
            }
            if (!!collRes && collRes.status) {
                dispatch(updateUserLendingData(selector.ethData.address))
            }
        }
        catch (error) { }
        finally {
            // setShowCollateral(false)
            dispatch(updateUserLendingData(selector.ethData.address))
        }
    }

    const showToolTipModal = async(title: any, message: any) =>{
        //hiding tooltip pop up
        setShowTooltipModal(!showToolTip);
        setToolTipTitle(title);
        setToolTipMessage(message);
        let width = document.body.clientWidth;
        // if(width < 800)
        // {   
        //     window.scrollTo(0,0);
        //     console.log(width);
        //     window.scrollTo({ top: 0, behavior: 'smooth' });
        //     document.getElementsByTagName('html')[0].scrollIntoView({ behavior: "smooth" });
        //}
    }

    const renderTokenTransform = () => {
        if (data.name === 'FRAX') {
            return <a href={fraxTranLink} target='_blank' rel="noreferrer"><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'MATIC') {
            return <a href={maticTransLink} target='_blank' rel="noreferrer"><TransformBtn >{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'DOT') {
            return <a href={dotTransLink} target='_blank' rel="noreferrer"><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'SOL') {
            return <a href={solTransLink} target='_blank' rel="noreferrer"><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'ADA') {
            return <a href={adaTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'LUNA') {
            return <a href={lunaTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'aUST') {
            return <a href={austTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'AVAX') {
            return <a href={avaxTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'ATOM') {
            return <a href={atomTransLink} target='_blank' rel="noreferrer" ><TransformBtn>{`Buy  ${getTokenName(data.name)}`}</TransformBtn></a>
        }
        else if (data.name === 'XRP') {
            return <a href={xrpTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'LINK') {
            return <a href={linkTransUrl} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'FTM') {
            return <a href={ftmTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'whUST') {
            return <a href={whustTransferLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else {
            return <TransformBtn onClick={() => handleBuyToken()}>{`Buy  ${getTokenName(data.name)}`}</TransformBtn>
        }
    }

    const getInfoNumFormat = (val: any, borrowLimit?: boolean) => {
        const newVal: any = parseFloat(val).toFixed(2).toString().split('.')
        if(borrowLimit === true)
            return <span>${commaFy(newVal[0])}.<span>{newVal[1]}</span></span>;
        else
            return <CardBoldText className='borrow-popup-mob'>${commaFy(newVal[0])}.<span>{newVal[1]}</span></CardBoldText>;
    }

    const handleBorrowLimit = async (amount: any) => {
        const userAddress = selector.ethData.address
        try {
            const amountVal: any = getFinalAmount(amount)
            const type = switchStatus === 'supply' ? 1 : 2
            const res: any = await getBorrowLimitParamsOnChange(data.isUsingAsCollateral, data.address, type, parseFloat(amountVal), gPlatformData.borrowLimitInUsd, gPlatformData.userTotalBorrowBal,)
            // console.log(" borrow limit handling", res, data.isUsingAsCollateral, data.address, type, parseFloat(amountVal), gPlatformData.borrowLimitInUsd, gPlatformData.userTotalBorrowBal, data)
            if (!!res && typeof res !== 'undefined') {
                setBorrowLimit(res.usedBorrowLimitInPercentage)
                setMaxBorrow(res.borrowLimit)
                let percentage: any = +res.usedBorrowLimitInPercentage/100;
                if(percentage > 0.751 && percentage <= 0.95 && switchStatus !== "supply"){
                    // console.log("75-90")
                    setIsError(false)
                    if(errorLiquidationText == ""){
                        setErrorLiquidationText("Beware of Liquidation Risk");
                    }
                } 
                else if(percentage > 0.95 && percentage < 1.0001 && switchStatus !== "supply"){
                    // console.log("90-100")
                    setIsError(true)
                    if(errorLiquidationText == ""){
                        setErrorLiquidationText("Beware of Liquidation Risk");
                    }
                } 
                else if(percentage > 1 && switchStatus !== "supply"){
                    console.log("above 100")
                    setIsError(true)
                    if(errorLiquidationText == ""){
                        setErrorLiquidationText("Cannot withdraw more than max permissible limit");
                    }
                }
                else {
                    if(switchStatus == "supply"){
                        const errorRes: any = await checkErrors(data, userAddress, type, amountVal)
                        // console.log("supply error")
                        if (!!errorRes && typeof errorRes !== 'undefined') {
                            if (!errorRes.proceed) {
                                setIsError(true);
                                setErrorText("");
                                setErrorLiquidationText(errorRes.reason);
                            }
                            else {
                                setIsError(false)
                                setErrorText("")
                                setErrorLiquidationText("");
                            }
                        }
                    } else {
                        // console.log("no error")
                        setIsError(false)
                        setErrorLiquidationText("");
                    }
                    
                }
            }
        }
        catch (error) { }
    }

    const handleSuppAmount = async (e: any) => {
        const { value } = e.target
        const dataVal = value.replace(/\$|,|/g, "");
        const numVal = dataVal.toString()
        // console.log("handleSuppAmount", data)
        if (inputNumRegex.test(numVal.toString())) {
            if(+data.allowance >= +numVal || data.name === 'BNB' || data.name === 'WBNB'){
                setIsAllowance(true)
            } else {
                setIsAllowance(false)
            }
            setSuppAmt(numVal)
            getSuppVal(numVal)
            handleBorrowLimit(numVal)
            await handleInputError(numVal)
        }
        if (!numVal) {
            setSuppAmt('')
            setSuppVal('')
            setIsError(false)
            setBorrowLimit(data.borrowLimitUsedCurr)
            setMaxBorrow(data.borrowLimitInUsd)
            setErrorText("")
            setErrorLiquidationText("");
        }
        if (switchStatus !== 'supply') {
            setWithdrawMax(false)
        }
        
    }

    const getFinalAmount = (amountValue: any) => {
        let finalAmount: any = !!amountValue ? amountValue : 0
        if (assetType === 'usd') {
            if (parseFloat(data.underlyingPrice) > 0) {
                finalAmount = parseFloat(finalAmount) / parseFloat(data.underlyingPrice)
            }
            else {
                finalAmount = 0
            }
        } else {
            finalAmount = parseFloat(finalAmount)
        }
        
        return finalAmount
    }

    const handleBorrowLimitChange = async (val: any) => {
        try {
            if (!!data && data.borrowLimitUsedCurr && data.isUsingAsCollateral) {
                const type = switchStatus === 'supply' ? 1 : 2
                if (type === 1 && val >= data.supplyLowestBorrowLimitUsed) {
                    const currVal = val <= data.borrowLimitUsedCurr ? val : data.borrowLimitUsedCurr;
                    setBorrowLimit(currVal)
                    const percentage = currVal / 100
                    const userAddress = selector.ethData.address
                    const res: any = await getParamsWhenBorrowLimitIsChanged(data.isUsingAsCollateral, data.address, type, gPlatformData.borrowLimitInUsd, gPlatformData.userTotalBorrowBal, percentage, userAddress)
                    if (!!res && typeof res !== 'undefined') {
                        setMaxBorrow(res.borrowLimit)
                        res.amountGivenInUsd = res.amountGivenInUsd > 0.001 ? res.amountGivenInUsd : 0.00;
                        const finalAmountVal = getNumFormat(res.amountGivenInUsd)
                        setSuppAmt(finalAmountVal)
                        getSuppVal(finalAmountVal)
                        await handleInputError(finalAmountVal)
                    }
                }
                if (type === 2) {
                    console.log(val)
                    const currVal = val >= data.borrowLimitUsedCurr ? val : data.borrowLimitUsedCurr
                    setBorrowLimit(currVal)
                    const percentage = currVal / 100
                    const userAddress = selector.ethData.address
                    const res: any = await getParamsWhenBorrowLimitIsChanged(data.isUsingAsCollateral, data.address, type, gPlatformData.borrowLimitInUsd, gPlatformData.userTotalBorrowBal, percentage, userAddress)
                    console.log("getParamsWhenBorrowLimitIsChanged", res)
                    if(percentage > 0.7501 && percentage <= 0.95){
                        setIsError(false)
                        setErrorLiquidationText("Beware of Liquidation Risk");
                    } else if(percentage > 0.95 && percentage < 1.0001){
                        setIsError(true)
                        setErrorLiquidationText("Beware of Liquidation Risk");
                    }else if(percentage > 1){
                        setIsError(true)
                        if(errorLiquidationText == ""){
                            setErrorLiquidationText("Withdraw Limit Exceeded");
                        }
                    } 
                    else {
                        setIsError(false)
                        setErrorLiquidationText("");
                    }
                    if (!!res && typeof res !== 'undefined') {
                        console.log("borrow limit", res.borrowLimit)
                        setMaxBorrow(res.borrowLimit)
                        res.amountGivenInUsd = res.amountGivenInUsd > 0.001 ? res.amountGivenInUsd : 0.00;
                        const finalAmountVal = getNumFormat(res.amountGivenInUsd)
                        const finalAmountInTokens = getNumFormat(res.amountGivenInEth)
                        console.log("finalAmountInTokens", finalAmountInTokens)
                        setSuppAmt(finalAmountInTokens)
                        getSuppVal(finalAmountInTokens)
                        await handleInputError(finalAmountInTokens)
                    }
                }
            }

        }
        catch (err) {

        }
    }

    const handleSwitchStatus = (switchVal: string) => {
        setSuppAmt('')
        setSwitchStatus(switchVal)
        setActiveTab(switchVal)
        setBorrowLimit(data.borrowLimitUsedCurr)
        setMaxBorrow(data.borrowLimitInUsd)
        setIsError(false)
        setErrorLiquidationText("")

    }

    const handleMax = async () => {
        if (switchStatus === 'supply') {
            // console.log("user supply details in max function",data)
            const val = data.name === 'BNB' ? parseFloat(data.walletBal) - 0.01 > 0 ? parseFloat(data.walletBal) - 0.01 : 0 : data.walletBal;
            let finalAmt: any = val;
            
            if (assetType === 'usd') {
                finalAmt = parseFloat(finalAmt) * parseFloat(data.underlyingPrice)
            } else {
                finalAmt = data.name === 'BNB' ? parseFloat(data.walletBal) - 0.01 > 0 ? parseFloat(data.walletBal) - 0.01 : 0 : data.walletBal;
                finalAmt = parseFloat(finalAmt)
            }
            // console.log("user supply details in max function", finalAmt, assetType)
            finalAmt = noExponents(finalAmt)
            const finalAmountVal = getNumFormat(finalAmt)
            if(+data.allowance >= +finalAmountVal || data.name === 'BNB' || data.name === 'WBNB'){
                setIsAllowance(true)
            } else {
                setIsAllowance(false)
            }
            setSuppAmt(finalAmountVal)
            getSuppVal(finalAmountVal)
            handleBorrowLimit(finalAmountVal)
            await handleInputError(finalAmountVal)
        }
        else {
            setWithdrawMax(true)
            let maxAmount = data.currentlySupplyingUsd - ((data.borrowLimitInUsd * data.borrowLimitUsedCurr / data.collateralFactor)/95)//0.9
            
            let finalAmt: any = data.maxWithdraw;
            if (assetType === 'usd') {
                finalAmt = parseFloat(finalAmt) * parseFloat(data.underlyingPrice)
            }
            let withdrawRatio = (data.borrowLimitInUsd * data.borrowLimitUsedCurr * 0.01)/(data.borrowLimitInUsd - (finalAmt * data.collateralFactor)) ;
            // console.log("data inside handlemax", data, withdrawRatio, finalAmt, maxAmount)
            if(data.isAvailForCollateral && withdrawRatio > 0.75){
                finalAmt = maxAmount > finalAmt ? noExponents(finalAmt) : noExponents(maxAmount);
                setErrorLiquidationText("Beware of Liquidation Risk");
            } else {
                setErrorLiquidationText("");
                finalAmt = noExponents(finalAmt);
            }
            const finalAmountVal: any = getNumFormat(finalAmt)
            setSuppAmt(finalAmountVal)
            getSuppVal(finalAmountVal)
            handleBorrowLimit(finalAmountVal)
            setIsError(false)
            await handleInputError(finalAmountVal)
        }
    }

    const handleMaxOriginal = async () => {
        if (switchStatus === 'supply') {
            const val = data.name === 'BNB' ? parseFloat(data.walletBal) - 0.001 > 0 ? parseFloat(data.walletBal) - 0.001 : 0 : data.walletBal
            let finalAmt: any = val
            if (assetType === 'usd') {
                finalAmt = parseFloat(finalAmt) * parseFloat(data.underlyingPrice)
            }
            finalAmt = noExponents(finalAmt)
            const finalAmountVal = getNumFormat(finalAmt)
            setSuppAmt(finalAmountVal)
            getSuppVal(finalAmountVal)
            handleBorrowLimit(finalAmountVal)
            await handleInputError(finalAmountVal)
        }
        else {
            let finalAmt: any = data.maxWithdraw
            if (assetType === 'usd') {
                finalAmt = parseFloat(finalAmt) * parseFloat(data.underlyingPrice)
            }
            finalAmt = noExponents(finalAmt)
            const finalAmountVal = getNumFormat(finalAmt)
            setSuppAmt(finalAmountVal)
            getSuppVal(finalAmountVal)
            handleBorrowLimit(finalAmountVal)
            await handleInputError(finalAmountVal)
        }
    }

    const handleWithdraw = async () => {
        try {
            setWithdrawLoading(true)
            const amountInEth = getFinalAmount(suppAmt)
            const userAddress = selector.ethData.address
            const gTokenAddress = data.address
            
            const inst: any = await selectInstance(instType.gToken, gTokenAddress)
            const decimal = Math.pow(10, await getUnderlyingDecimal(gTokenAddress));
            // console.log("handleWithdraw", amountInEth, decimal, userAddress, gTokenAddress)
            if(withdrawMax && data.gTokenBalance !== undefined){
                await inst.methods.redeem(data.gTokenBalance).send({
                    from: userAddress,
                })
                .once('transactionHash', function (res: any) {
                    if (res && typeof res !== 'undefined') {
                        const transLink = `${transLinkUrl}/${res}`
                        setTransactionLink(transLink)
                        setShowTransModal(true)
                    }
                })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    handleNotification(type, 'Withdraw', receipt.transactionHash)
                    setWithdrawLoading(false)
                    dispatch(updateUserLendingData(selector.ethData.address))
                    dispatch(updateUserBalance(selector.ethData.address))
                })
                .on('error', function (error: any) {
                    handleNotification('failed', 'Withdraw')
                    setShowTransModal(false)
                    setWithdrawLoading(false)
                    
                })
            } else {
                await inst.methods.redeemUnderlying(convertToWei(amountInEth, decimal)).send({
                    from: userAddress,
                })
                .once('transactionHash', function (res: any) {
                    if (res && typeof res !== 'undefined') {
                        const transLink = `${transLinkUrl}/${res}`
                        setTransactionLink(transLink)
                        setShowTransModal(true)
                    }
                })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    handleNotification(type, 'Withdraw', receipt.transactionHash)
                    setWithdrawLoading(false)
                    dispatch(updateUserLendingData(selector.ethData.address))
                    dispatch(updateUserBalance(selector.ethData.address))
                })
                .on('error', function (error: any) {
                    handleNotification('failed', 'Withdraw')
                    setShowTransModal(false)
                    setWithdrawLoading(false)
                    
                })
            }
            
        }
        catch (error) {
            console.log("error=>", error)
            setWithdrawLoading(false)
        }
        finally {
            setSuppAmt('')
            setSuppVal('')
            setShowTransModal(false)
            setWithdrawLoading(false)
            setWithdrawMax(false)
            
        }
    }

    const handleEnableNotification = (type: string, hash?: any,) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} activated` : `Failed to activate  ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleEnable = async () => {
        try {
            setActivateLoading(true)
            const underlyingAddress = data.underlyingAddress
            const gTokenAddress = data.address
            const userAddress = selector.ethData.address
            const TOKEN_INSTANCE = await selectInstance(instType.PANCAKELP, underlyingAddress)
            await TOKEN_INSTANCE.methods
                .approve(gTokenAddress, MAX_INT)
                .send({ from: userAddress })
                .once('transactionHash', function (res: any) {
                    if (res && typeof res !== 'undefined') {
                        const transLink = `${transLinkUrl}/${res}`
                        setTransactionLink(transLink)
                        setShowTransModal(true)
                    }
                })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    handleEnableNotification(type, receipt.transactionHash)
                    setActivateLoading(false)
                    dispatch(updateUserLendingData(selector.ethData.address))
                })
                .on('error', function (error: any) {
                    handleEnableNotification('failed')
                    setShowTransModal(false)
                    setActivateLoading(false)
                })
        }
        catch (error) {
            console.log("error==>", error)
            setActivateLoading(false)
        }
        finally {
            setShowTransModal(false)
            setActivateLoading(false)
        }
    }

    const handleSupply = async () => {
        try {
            setSuppLoading(true)
            const amountInEth = getFinalAmount(suppAmt)
            const userAddress = selector.ethData.address
            const gTokenAddress = data.address
            
            // return;
            if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase()) {
                const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
                await inst.methods.mint().send({
                    from: userAddress,
                    value: convertToWei(amountInEth, 1e18)
                })
                    .once('transactionHash', function (res: any) {
                        if (res && typeof res !== 'undefined') {
                            const transLink = `${transLinkUrl}/${res}`
                            setTransactionLink(transLink)
                            setShowTransModal(true)
                        }
                    })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        handleNotification(type, 'Supplied', receipt.transactionHash)
                        setSuppLoading(false)
                        dispatch(updateUserLendingData(selector.ethData.address))
                    })
                    .on('error', function (error: any) {
                        handleNotification('failed', 'Supplied')
                        setShowTransModal(false)
                        setSuppLoading(false)
                    })
            }
            else {
                const inst: any = await selectInstance(instType.gToken, gTokenAddress)
                const decimal = Math.pow(10, await getUnderlyingDecimal(gTokenAddress));
                await inst.methods.mint(convertToWei(amountInEth, decimal)).send({
                    from: userAddress,
                })
                    .once('transactionHash', function (res: any) {
                        if (res && typeof res !== 'undefined') {
                            const transLink = `${transLinkUrl}/${res}`
                            setTransactionLink(transLink)
                            setShowTransModal(true)
                        }
                    })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        handleNotification(type, 'Supplied', receipt.transactionHash)
                        setSuppLoading(false)
                        dispatch(updateUserLendingData(selector.ethData.address))
                        dispatch(updateUserBalance(selector.ethData.address))
                    })
                    .on('error', function (error: any) {
                        handleNotification('failed', 'Supplied')
                        setShowTransModal(false)
                        setSuppLoading(false)
                    })
            }
        }
        catch (error) {
            console.log("error=>", error)
            setSuppLoading(false)
        }
        finally {
            setSuppAmt('')
            setSuppVal('')
            setShowTransModal(false)
            setSuppLoading(false)
        }
    }

    return (
        <HAModalCont className={showToolTip ? "tooltipOverlay": ""} >
            <FlexSBCont>
                <HAModalTitle>{getTokenName(data.name)}{data.isBoostPool ? data.strategyAddress === "0x0709e1356F765a6F88632B539E209a5d6864B765" ? "(PCS) OLD":" (PCS)" : ''}</HAModalTitle>
                <img className='close-icon' src={CloseIcon} alt="" onClick={() => toggleVaultModal()} />
            </FlexSBCont>
            <HAMDataCont>
                <TokenInfoBox>
                    <HATokenIcon src={data.name !== undefined ? getTokenIcon(data.name.replace(' (OLD)', '')): ""} />
                    {
                        getInfoNumFormat(!!data && data.currentlySupplyingUsd ? data.currentlySupplyingUsd : 0)
                    }
    {/*<TokenValTxt isValZero={checkZeroVal(data.borrowBalance)}>{`$${!!data && data.borrowBalance ? commaFy(parseFloat(data.vaultBalanceUSD).toFixed(2)) : '0.00'}`}</TokenValTxt>*/}
                    
                    <ModalAPYTxt className='borrowedTxt'>Supplied</ModalAPYTxt>
                    <div className='mobCapsuleCont'>
                    <ApyText className='apyCapsule mobPopup' isNegative={checkNegative(data.totalSupplyApy)}>{data.totalSupplyApy && (!isFinite(data.totalSupplyApy) || data.totalSupplyApy > 10000) ? "> 10,000" : data.totalSupplyApy ? commaFy(data.totalSupplyApy.toFixed(2)) : "0.00"}% APY</ApyText>
                    <ApyText className='apyCapsule mobPopupTVL' isNegative={checkNegative(data.totalMarketSizeUsd)}>${!!data.totalMarketSizeUsd ? commaFy(convertToKAndM(data.totalMarketSizeUsd)) : '0.00'} TVL</ApyText>
                    </div>
                    {/* <ModalAPYTxt>{commaFy(convertToKAndM(data.totalApy))}% APY</ModalAPYTxt>
                    <TvlTxt>{`$${!!data.tvl ? commaFy(convertToKAndM(data.tvl)) : '0.00'} TVL`}</TvlTxt> */}
                </TokenInfoBox>
                <SwitchCont className={!data.isBoostPool ? 'without-boost' : ''}>
                    <SupplySwitch isActive={activeTab === 'supply'} switchStatus={switchStatus === 'supply'} onClick={() => handleSwitchStatus('supply')}>Supply</SupplySwitch>
                    <WithdrawSwitch isActive={activeTab === 'withdraw'} switchStatus={switchStatus === 'withdraw'} onClick={() => handleSwitchStatus('withdraw')}>Withdraw</WithdrawSwitch>
                </SwitchCont>
                {data.isBoostPool ?
                    <BoostVaultAction activeTab={activeTab} data={data} /> :
                    <>
                        {activeTab === 'supply' ?
                            <ModalCard>
                                <FlexSBCont>
                                    <ModalCardTitle>In Wallet</ModalCardTitle>
                                    
                                    <UserBalTxt>{`${commaFy(parseFloat(data.walletBal).toFixed(4))} ($${commaFy(parseFloat(data.walletBalInUsd).toFixed(2))})`}</UserBalTxt>
                                    
                                </FlexSBCont>
                                <ModalInputCont>
                                    <ModalInput placeholder="0" onChange={handleSuppAmount} value={suppAmt && `${assetType === 'token' ? `${suppAmt}` : suppAmt}`} />
                                    {data.walletBalInUsd > 0.01 ?
                                        <MaxBox onClick={() => handleMax()}>MAX</MaxBox>
                                        :
                                        <MaxBoxZero onClick={() => handleMax()}>MAX</MaxBoxZero>
                                    }
                                </ModalInputCont>
                                {!!errorLiquidationText && <ErrorText>{errorLiquidationText}</ErrorText>}
                                {isError && !!errorText && <ErrorText>{errorText}</ErrorText>}
                                    {/* {renderTokenTransform()} */}
                                    {/* {
                                        isAllowance ?
                                            <CardBtn onClick={() => handleDepositAmount()} disabled={depositLoading}>{depositLoading ? <Spinner /> : 'Borrow'}</CardBtn> :
                                            <CardGreenBtn onClick={() => handleApprove()} disabled={approveLoading}>{approveLoading ? <Spinner /> : 'Approve'}</CardGreenBtn>
                                    } */}
                                    { isAllowance || parseFloat(suppAmt) > data.walletBal ? 
                                    <CardGreenBtn onClick={() => handleSupply()} disabled={suppLoading || !suppAmt || parseFloat(suppAmt) <= 0 || !selector.ethData.address || isError}>
                                        {suppLoading ? <Spinner /> : 'Supply'}
                                    </CardGreenBtn>
                                    :
                                    <CardGreenBtn onClick={() => handleEnable()} disabled={activateLoading || !suppAmt || parseFloat(suppAmt) <= 0 || !selector.ethData.address}>
                                        {activateLoading ? <Spinner /> : 'Approve'}
                                    </CardGreenBtn>
                                    }
                            </ModalCard> :
                            <ModalCard>
                                <FlexSBCont>
                                    <ModalCardTitle>Deposited</ModalCardTitle>
                                    
                                        <UserBalTxt>{`${commaFy(parseFloat(data.currentlySupplying).toFixed(4))} ($${commaFy(parseFloat(data.currentlySupplyingUsd).toFixed(2))})`}</UserBalTxt>
                                    
                                </FlexSBCont>
                                <ModalInputCont>
                                    <ModalInput placeholder="0" onChange={handleSuppAmount} value={suppAmt && `${assetType === 'token' ? `${suppAmt}` : suppAmt}`} />
                                    {data.vaultBalanceUSD > 0.01 ?
                                        <MaxBox onClick={() => handleMax()}>MAX</MaxBox>
                                        :
                                        <MaxBoxZero onClick={() => handleMax()}>MAX</MaxBoxZero>
                                    }
                                </ModalInputCont>
                                {!!errorLiquidationText && <ErrorText>{errorLiquidationText}</ErrorText>}
                                {isError && !!errorText && <ErrorText>{errorText}</ErrorText>}
                                <CardRedBtn onClick={() => handleWithdraw()} disabled={withdrawLoading || !suppAmt || parseFloat(suppAmt) <= 0 || !selector.ethData.address || isError} >
                                    {withdrawLoading ? <Spinner /> : 'Withdraw'}
                                </CardRedBtn>
                            </ModalCard>
                        }
                    </>
                }
                <DataInfoCard className='popUpSlider'>
                    <FlexSBCont>
                        
                        { borrowLimit ? <CardBoldText className="borrowPercent">{parseFloat(borrowLimit).toFixed(2)}%</CardBoldText> : <CardText>0.00%</CardText>}
                        {<div className='borrowLimit'><CardText className="finalLimit">100%</CardText></div>} 
                    </FlexSBCont>
                    <SliderContainerNew borrowLimit={borrowLimit} className={+borrowLimit > 75 && +borrowLimit < 90 ? 'SliderContainer OrangeLimit': +borrowLimit > 90 ? 'SliderContainer RedLimit' : 'SliderContainer'}>
                        {/* <CardText className="borrowLimitPopup">70%</CardText>
                        <VerticalCardDivider className="borrowLimitPopup"></VerticalCardDivider> */}
                        <Slider step={0.01} min={0} max={100} value={borrowLimit} tooltip={false} onChange={handleBorrowLimitChange} />
                    </SliderContainerNew>
                    <FlexSBCont>
                        <FlexCont>
                            <CardText className="borrowUsage">Borrow Usage</CardText>
                        </FlexCont>
                        <FlexCont className="limitPopUp" onClick={() => showToolTipModal("Borrow Limit", "The maximum dollar amount you can borrow against your collateral. If this were to hit $0.00 you would be subject to liquidation with a penalty up to 10% of your collateral.")} >
                        {!!data && data.borrowLimitInUsd !== undefined ?<CardText onClick={() => showToolTipModal("Borrow Limit", "The maximum dollar amount you can borrow against your collateral. If this were to hit $0.00 you would be subject to liquidation with a penalty up to 10% of your collateral.")}>Limit: ${commaFy((data.borrowLimitInUsd).toFixed(2))}</CardText>:
                        <CardText>0.00</CardText>}
                            <ToolTipIcon className="tooltip-mob" src={QuestionIcon} alt="" left={5} onClick={() => showToolTipModal("Borrow Limit", "The maximum dollar amount you can borrow against your collateral. If this were to hit $0.00 you would be subject to liquidation with a penalty up to 10% of your collateral.")} />
                        </FlexCont>
                    </FlexSBCont>
                </DataInfoCard>
                {!!data && ((data.name !== 'AQUA' && data.name !== 'GAMMA') && (data.isAvailForCollateral && +data.currentlySupplyingUsd > 0.01)) ?
                <DataInfoCard className='collateralCard'>
                    <DataInfoTitle className='collateralText'>Collateral</DataInfoTitle>
                    {data.isAvailForCollateral && data.currentlySupplyingUsd > 0.0054 &&
                        <TableSwitchCont className='collateralSwitch' onClick={() => handleCollateral(data)}>
                            <ToggleSwitch id={data.name} data={data} isChecked={data.isUsingAsCollateral} />
                        </TableSwitchCont>
                    }
                </DataInfoCard>
                :""}
                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowAPyCal(!showAPYCal)} isActive={showAPYCal}>
                        <DataInfoTitle>APR Calculations</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showAPYCal &&
                        <DataInfoCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>GAMMA APR</DataInfoTxt>
                                <DataInfoTxt>{data.gammaApr && (!isFinite(data.gammaApr) || data.gammaApr > 10000) ? "> 10,000" : data.gammaApr ? commaFy(data.gammaApr.toFixed(2)) : "0.00"}%</DataInfoTxt>
                            </FlexSBCont>
                            {data.isBoostPool ?
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Parent Farm APR</DataInfoTxt>
                                    <DataInfoTxt>{parseFloat(data.parentFarmApy).toFixed(2)}%</DataInfoTxt>
                                </FlexSBCont> :
                                <>
                                    {data.name === 'AQUA' ?
                                        <FlexSBCont className='bottom-space'>
                                            <DataInfoTxt light>Burn APR</DataInfoTxt>
                                            <DataInfoTxt>{parseFloat(data.burnApr).toFixed(2)}%</DataInfoTxt>
                                        </FlexSBCont> :
                                        <>
                                            {data.name !== 'GAMMA' &&
                                                <FlexSBCont className='bottom-space'>
                                                    <DataInfoTxt light>Supply APR</DataInfoTxt>
                                                    <DataInfoTxt>{parseFloat(data.supplyApr).toFixed(2)}%</DataInfoTxt>
                                                </FlexSBCont>}
                                        </>
                                    }
                                </>
                            }
                            {data.name !== 'AQUA' && data.name !== 'GAMMA' && 
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Boost APR</DataInfoTxt>
                                <DataInfoTxt>{data.boostApr && (!isFinite(data.boostApr) || data.boostApr > 10000) ? "> 10,000" : data.boostApr ? commaFy(data.boostApr.toFixed(2)) : "0.00"}%</DataInfoTxt>
                            </FlexSBCont>
                            }
                            {data.address.toLowerCase() == gBNBxAddress.toLowerCase() && 
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Stake APR</DataInfoTxt>
                                <DataInfoTxt>{data.stakeApr && (!isFinite(data.stakeApr) || data.stakeApr > 10000) ? "> 10,000" : data.stakeApr ? commaFy(data.stakeApr.toFixed(2)) : "0.00"}%</DataInfoTxt>
                            </FlexSBCont>
                            }
                            <FlexSBCont>
                                <DataInfoTxt light>Total APR</DataInfoTxt>
                                <DataInfoTxt>{data.totalSupplyApr && (!isFinite(data.totalSupplyApr) || data.totalSupplyApr > 10000) ? "> 10,000" : data.totalSupplyApr ? commaFy(data.totalSupplyApr.toFixed(2)) : "0.00"}%</DataInfoTxt>
                            </FlexSBCont>
                        </DataInfoCont>
                    }
                </DataInfoCard>

                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowVaultInfo(!showVaultInfo)} isActive={showVaultInfo}>
                        <DataInfoTitle>Vault Details</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showVaultInfo &&
                        <DataInfoCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>{`${data.name} Price`}</DataInfoTxt>
                                <DataInfoTxt>${data.price !== undefined ? commaFy(parseFloat(data.price).toFixed(2)): commaFy(parseFloat(data.underlyingPrice).toFixed(2))}</DataInfoTxt>
                            </FlexSBCont>
                            {data.protocol === "Planet" ?
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Origin</DataInfoTxt>
                                    <DataInfoTxt>Green Planet</DataInfoTxt>
                                </FlexSBCont>
                                :
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Origin</DataInfoTxt>
                                    <DataInfoTxt>PCS</DataInfoTxt>
                                </FlexSBCont>
                            }
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Contract</DataInfoTxt>
                                <a href={data.farmContract} target="_blank" rel="noreferrer">
                                    <DataInfoTxt>View</DataInfoTxt>
                                </a>
                            </FlexSBCont>
                            {data.protocol === "PCS" ?
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Vault Contract</DataInfoTxt>
                                    <a href={data.vaultContract} target="_blank" rel="noreferrer">
                                        <DataInfoTxt>View</DataInfoTxt>
                                    </a>
                                </FlexSBCont>
                                :
                                ""
                            }
                            {data.protocol === "PCS" ?
                                <FlexSBCont>
                                    <DataInfoTxt light>Vault Multiplier</DataInfoTxt>
                                    <DataInfoTxt>{data.vaultMultiplier}X</DataInfoTxt>
                                </FlexSBCont>
                                :
                                ""
                            }
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Boost Percentage</DataInfoTxt>
                                <DataInfoTxt>{data.gammBoostPercentage ? commaFy(parseFloat(data.gammBoostPercentage).toFixed(2)): 0}%</DataInfoTxt>
                            </FlexSBCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Average Boost APR</DataInfoTxt>
                                <DataInfoTxt>{data.averageBoostAPR && (!isFinite(data.averageBoostAPR) || data.averageBoostAPR > 10000) ? "> 10,000" : data.averageBoostAPR ? commaFy(data.averageBoostAPR.toFixed(2)) : "0.00"}%</DataInfoTxt>
                            </FlexSBCont>
                        </DataInfoCont>
                    }
                </DataInfoCard>

                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowFeeInfo(!showFeeInfo)} isActive={showFeeInfo}>
                        <DataInfoTitle>Fees</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showFeeInfo &&
                        <DataInfoCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Deposit Fee</DataInfoTxt>
                                <DataInfoTxt>{data.depositFee}%</DataInfoTxt>
                            </FlexSBCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Withdraw Fee</DataInfoTxt>
                                <DataInfoTxt>{data.withdrawalFees}%</DataInfoTxt>
                            </FlexSBCont>
                            {data.protocol === "PCS" ?
                                <FlexSBCont>
                                    <DataInfoTxt light>Performance Fee</DataInfoTxt>
                                    <DataInfoTxt>{data.burnFees}%</DataInfoTxt>
                                </FlexSBCont>
                                :
                                ""
                            }
                        </DataInfoCont>
                    }
                </DataInfoCard>
            </HAMDataCont>
            <ToolTipModal2
                showToolTip={showToolTip}
                toggleToolTipModal={setShowTooltipModal}
                title={toolTipTitle}
                message={toolTipMessage} 
            />
            <ReactTooltip className="planet-tool-tip" id="mGammaBalanceInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>The total GAMMA you have in your wallet, supplied to markets and in liquidity pools.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mGammaEarnedInfo" place="right" effect="solid" multiline={true}>
                <ToolTipText>This is your uncollected GAMMA earned from supplying & borrowing assets. You can press collect to claim your GAMMA.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mTotalMarketSizeInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>The total value of all assets supplied.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mSupplyBalanceInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>This is the total value of assets you’ve supplied to the market.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mBorrowBalanceInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>This is the total value of assets you’ve borrowed from the market.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mNetApyInfo" place="right" effect="solid" multiline={true}>
                <ToolTipText>Your total estimated annualized interest rate, of all assets supplied and borrowed at the current market rates.
                    (Estimated Annualized Earnings/Supply Balance) * 100</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mBorrowLimitInfo" place="right" effect="solid" multiline={true}>
                <ToolTipText>The maximum value as a % that you can borrow against your collateral. If this were to hit 100% you
                    would be subject to liquidation with a penalty up to 10% of your collateral.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mAvailableCreditInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>The maximum dollar amount you can borrow against your collateral. If this were to hit $0.00 you
                    would be subject to liquidation with a penalty up to 10% of your collateral.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mCurrentLevelInfo" place="right" effect="solid" multiline={true} >
                <ToolTipDataCont>
                    <ToolTipText>There are 3 discount levels that can be reached by supplying GAMMA.</ToolTipText>
                    <ul>
                        <li>Level 1: 5% off protocol fees</li>
                        <li>Level 2: 20% off protocol fees</li>
                        <li>Level 3: 50% off protocol fees</li>
                    </ul>
                </ToolTipDataCont>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mStackRatioInfo" place="right" effect="solid" multiline={true} >
                <ToolTipDataCont>
                    <ToolTipText>This refers to the USD value of your GAMMA supplied, as a percentage, relative to the USD value of
                        assets you’re supplying. This is how your Discount Level is calculated.</ToolTipText>
                    <ul>
                        <li>Level 1: (1-4.99%)</li>
                        <li>Level 2: (5-9.99%)</li>
                        <li>Level 3: (Greater than 10%)</li>
                    </ul>
                </ToolTipDataCont>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mDailyEarning" place="top" effect="solid" multiline={true}>
                <ToolTipText>This is an estimated amount that you will earn over the next 24 hours at the current numbers.</ToolTipText>
            </ReactTooltip>
        </HAModalCont>
    );
};

export default AssetSupplyRevised