import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { updateUserBalance, updateLPData } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont, FlexCont } from 'shared/styles/styled'
import { TransActionCont, TransInputCont, TransBalTxt, InputTitle, TransInputBox, TransInput, TransMaxBox, TransDataCont, TokenSelectCont, TokenName, TwoImageCont, TransTxt, TransInoTxt, PriceImpactBox, TransButton } from 'shared/styles/highestApyStyle'
import DownIcon from 'assets/icons/down.svg'
import { transformTokenList as tokenList, returnTokenList, returnTransformTokenList } from 'modules/block-chain/tokenList'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import { getTokenIcon } from 'shared/tokenIconList'
import TokenSelect from 'shared/token-select'
import TransactionInfo from 'shared/transaction-info'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions'
import { setTransHistory, setConfirmTransHistory } from 'shared/transaction-history/historyMethods'
import { getLPDataOriginal, getPcsLPData, createLPOriginal, createPcsLP, toBalance, tokenTransformAllowance, selectInstance, instanceType, getLPDataForStables, onSelectingBothTokensOnSwap, createStableLP } from 'modules/block-chain/Swap'
import { aquaAddress, bnbAddress, busdAddress, stableSwapAddress, usdtAddress, usdcAddress } from 'modules/block-chain/abi'
import { gammaAddress } from 'modules/block-chain/lendingAbi'
import { aquaZapInAddress } from 'modules/block-chain/SwapDexAbi'
import { maxAmount } from 'modules/block-chain/lendingAbi';
import BUSDIcon from 'assets/icons/BUSD.png'
import USDCIcon from 'assets/icons/USDC.png'
import USDTIcon from 'assets/icons/USDT.png'
import stableSwapIcon from 'assets/icons/stable-swap-icon.png'
import { commaFy } from 'service/globalFunctions'

const TransformAction = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [tokenDataList, setTokenDataList] = useState<any>(tokenList)
    const bnbSno = 1
    const [sendToken, setSendToken] = useState<any>(tokenList[bnbSno])
    const [sendAmount, setSendAmount] = useState('')
    const [sendAmountWithComma, setSendAmountWithComma] = useState('')
    const [isAmtLoading, setIsAmtLoading] = useState(false)
    const [inSufAmt, setInSufAmt] = useState(false)
    const [showTokenModal, setShowTokenModal] = useState(false)
    const [createLpLoading, setCreateLpLoading] = useState(false)
    const [approveLoading, setApproveLoading] = useState(false)
    const [allowanceAmt, setAllowanceAmt] = useState<any>('0.00')
    const [showTransModal, setShowTransModal] = useState(false)
    const [transactionLink, setTransactionLink] = useState('')
    const [lpDetails, setLPDetails] = useState({})
    const [warningMessage, setWarningMessage] = useState(false)
    const { data, transInfo, setTransInfo, slippageVal } = props

    useEffect(() => {
        const userAddress = selector.ethData.address;
        // console.log("pool data in old transform", data)
        const getTokenList = async () => {
            try {
                const token_list = await returnTransformTokenList(userAddress)//returnTokenList
                if (data.isBoostPool) {
                    const tList = token_list.filter((e: any) => e.address.toLowerCase() !== aquaAddress.toLowerCase() && e.address.toLowerCase() !== gammaAddress.toLowerCase())
                    // console.log("selected from token", tList)
                    // setSendToken(tList[0])
                    setTokenDataList(tList)
                }
                else {
                    const tList = token_list
                    // console.log("selected from token in else", tList)
                    // setSendToken(tList[0])
                    setTokenDataList(tList)
                }
                
            } catch (error) { }
        }
        getTokenList()
        
    const delayDebounceFn = setTimeout(async () => {
        getTransInfo(sendAmount)
    }, 2000)
    return () => clearTimeout(delayDebounceFn)
    }, [selector.ethData.address, data, sendAmount])

    useEffect(() => {
        const getAllowance = async () => {
            if (sendToken.address) {
                try {
                    let transformAddress = aquaZapInAddress;
                    let sendTokenAddress = sendToken.address;
                    if(data.isStablePool){
                        sendTokenAddress = (sendToken.address.toLowerCase() == usdcAddress.toLowerCase() || sendToken.address.toLowerCase() == usdtAddress.toLowerCase() || sendToken.address.toLowerCase() == busdAddress.toLowerCase()) ? busdAddress : sendToken.address;
                        transformAddress = (sendToken.address.toLowerCase() == usdcAddress.toLowerCase() || sendToken.address.toLowerCase() == usdtAddress.toLowerCase() || sendToken.address.toLowerCase() == busdAddress.toLowerCase()) ? stableSwapAddress : aquaZapInAddress;
                    }

                    const res = await tokenTransformAllowance(sendTokenAddress, selector.ethData.address, transformAddress)
                    // console.log("token allowance in transform", res, sendToken.name, sendTokenAddress, selector.ethData.address, transformAddress)
                    setAllowanceAmt(res)
                } catch (error) { }
            }
        }
        getAllowance()
    }, [selector.ethData.address, sendToken.address])

    useEffect(() => {
        setLPDetails(data)
    }, [data])

    const getTransInfo = async (amtVal: any) => {
        // console.log("getTransInfo", data, slippageVal);
        try {
            setIsAmtLoading(true)
            if (parseFloat(amtVal) > 0) {
                let info: any = ''
                if (data.isBoostPool) {
                    info = await getPcsLPData(amtVal, sendToken.address, data.poolId, slippageVal, data)
                } else if(data.isStablePool ){
                    info = await getLPDataForStables(amtVal, sendToken.address, data.poolId, slippageVal, data)
                } else {
                    info = await getLPDataOriginal(amtVal, sendToken.address, data.poolId, slippageVal, data)
                }
                
                // console.log("getTransInfo before", info, slippageVal)
                if (!!info && typeof info !== 'undefined') {
                    const transform_data: any = {
                        amountsOut0: typeof info.amountsOut0 === "number" ? toBalance(info.amountsOut0.toString()) : info.amountsOut0,
                        amountsOut1: typeof info.amountsOut1 === "number" ? toBalance(info.amountsOut1.toString()) : info.amountsOut1,
                        liquidity: info.liquidity,
                        minLiquidity: info.minLiquidity,
                        share: info.share,
                        path1: info.path1,
                        path2: info.path2,
                        impactAbove3Percent: info.impactAbove3Percent
                    }
                    setTransInfo({ ...transform_data })
                    // console.log("getTransInfo inside if", transform_data, data)
                    let expectedVal: any = (+sendToken.usdValue / +sendToken.value) * +sendAmount * (1 - +slippageVal/100) * 0.95 / +data.price;
                    // console.log(expectedVal, transform_data.minLiquidity)
                    if(transform_data.minLiquidity < expectedVal){
                        setWarningMessage(true)
                    } else {
                        setWarningMessage(false)
                    }
                }
                else {
                    setTransInfo({})
                }
            }
            else {
                setTransInfo({})
            }
        }
        catch (err) {
            console.log("err=>", err)
        }
        finally {
            setIsAmtLoading(false)
        }
    }

    const handleSendAmount = async (e: any) => {
        let { value } = e.target
        if(+value === 0 ){
            value = value;
        } else {
            value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.split(",").join("") : "";
        }
        if (floatNumRegex.test(value.toString())) {
            setSendAmount(value)
            setSendAmountWithComma(commaFy(value));
            // getTransInfo(value)
            let amt = parseFloat(value)
            amt = sendToken.sno === bnbSno ? amt + 0.01 : amt
            if (parseFloat(sendToken.value) < amt) {
                setInSufAmt(true)
            }
            else {
                setInSufAmt(false)
            }
        }
        if (!value) {
            setSendAmount('')
            setSendAmountWithComma('')
            setInSufAmt(false)
            setTimeout(() => {
                setTransInfo({})
            }, 1500)
        }
    }

    const handleTokenSelect = (data: any) => {
        setSendToken(data)
        setSendAmount('')
        setSendAmountWithComma('')
        setWarningMessage(false)
        setShowTokenModal(false)
        setTransInfo({})
    }
    
    const handleMaxAmount = async () => {
        let amount: any
        if (sendToken.sno === bnbSno && parseFloat(sendToken.value) !== 0) {
            amount = sendToken.value
            amount -= 0.02
        } else amount = sendToken.value
        amount = getNumFormat(amount)
        setSendAmount(amount)
        setSendAmountWithComma(commaFy(+amount))
        getTransInfo(amount)
        setInSufAmt(false)
    }

    const handleTransactionHistory = (newLink: any, hashVal: any) => {
        const historyData: any = {
            firstToken: sendToken.name,
            secondToken: data.name,
            amount1: sendAmount,
            amount2: transInfo.liquidity,
            link: newLink,
            type: 'tokenTransform',
            status: false,
            hashVal: hashVal,
            transStatus: 'pending',
        }
        setTransHistory(historyData)
    }


    const showTransLink = (transHash: any) => {
        const transLink = `${transLinkUrl}/${transHash}`
        setTransactionLink(transLink)
        handleTransactionHistory(transLink, transHash)
        setShowTransModal(true)
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Transform LP for ${parseFloat(sendAmount).toFixed(4)} ${sendToken.name} to ${parseFloat(transInfo.liquidity).toFixed(4)} ${data.name.toUpperCase()}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
        setSendAmount('')
        setSendAmountWithComma('')
    }

    const handleCreateLp = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                setCreateLpLoading(true)
                let res: any = ''
                // if(data.isStablePool || 1) {
                //     res = await createStableLP(sendAmount, data.poolId, sendToken.address, transInfo.minLiquidity, selector.ethData.address, showTransLink, transInfo.path1, transInfo.path2, data, slippageVal)
                // }
                if (data.isBoostPool) {
                    res = await createPcsLP(sendAmount, data.poolId, sendToken.address, transInfo.minLiquidity, selector.ethData.address, showTransLink, transInfo.path1, transInfo.path2, data)
                } else if(data.isStablePool) {
                    // console.log("send token", sendToken)
                    res = await createStableLP(sendAmount, data.poolId, sendToken.address, transInfo.minLiquidity, selector.ethData.address, showTransLink, transInfo.path1, transInfo.path2, data, slippageVal)
                } else {
                    res = await createLPOriginal(sendAmount, data.poolId, sendToken.address, transInfo.minLiquidity, selector.ethData.address, showTransLink, transInfo.path1, transInfo.path2, data, slippageVal)
                }

                if (!!res && res.transactionHash) {
                    setConfirmTransHistory(res.transactionHash, res.status)
                    const type = res.status ? 'success' : 'failed'
                    handleNotification(type, res.transactionHash)
                    setCreateLpLoading(false)
                    updateTokenList()
                    dispatch(updateLPData(selector.ethData.address))
                    dispatch(updateUserBalance(selector.ethData.address))
                }
            }
            catch (error) {
                handleNotification('failed')
            }
            finally {
                setCreateLpLoading(false)
                setShowTransModal(false)
            }
        }
    }

    const handleApproveLp = async () => {
        if (selector.ethData.ethWalletConnected) {
            let approvalContract = data.isStablePool && (sendToken.address.toLowerCase() == usdcAddress.toLowerCase() || sendToken.address.toLowerCase() == usdtAddress.toLowerCase() || sendToken.address.toLowerCase() == busdAddress.toLowerCase()) ? stableSwapAddress : aquaZapInAddress;
            
            try {
                setApproveLoading(true)
                const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, sendToken.address)
                const approvalAmount = maxAmount
                await TOKEN_INSTANCE.methods
                    .approve(approvalContract, approvalAmount)
                    .send({ from: selector.ethData.address })
                    .once('transactionHash', function (res: any) { })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        setAllowanceAmt(approvalAmount)
                    })
                    .on('error', function (error: any) {
                        setApproveLoading(false)
                    })
            } catch (error) {
            } finally {
                setApproveLoading(false)
            }
        }

    }

    const updateTokenList = async () => {
        const userAddress = selector.ethData.address
        try {
            const token_list = await returnTransformTokenList(userAddress)//await returnTokenList(userAddress)
            if (!!token_list && token_list.length > 0) {
                let tList: any = []
                if (data.isNewFarm) {
                    tList = token_list
                }
                else {
                    tList = token_list.filter((e: any) => e.address === bnbAddress || e.address === busdAddress)
                }
                const index = tList.findIndex((e: any) => e.sno === sendToken.sno)
                if (index !== -1) {
                    setSendToken(tList[index])
                    setTokenDataList([...tList])
                }
            }
        } catch (error) { }
    }

    const getTokenName = (tokenNum: number) => {
        let tokenN = ''
        if (data.name) {
            const tokenNameVal: any = data.name.split('-')
            if (tokenNum === 0) {
                tokenN = data.name
            }
            else if (tokenNum === 1) {
                tokenN = tokenNameVal[0]
            }
            else {
                tokenN = tokenNameVal[1]
            }
        }
        tokenN = tokenN.toLowerCase().replace('wbnb', 'bnb')
        return tokenN.toUpperCase()
    }
    const getIcons = (tokenNum: any) => {
        const tokenName: any = getTokenName(tokenNum)
        const icon: any = getTokenIcon(tokenName)
        return icon
    }

    return (
        <Fragment>
            <TransActionCont>
                <TransInputCont>
                    <FlexSBCont>
                        {
                            Object.values(sendToken).length > 0 && +sendAmount > 0 ?
                            <InputTitle className="sendText">Send  {`($${commaFy(parseFloat((+sendAmount * +sendToken.price).toString()).toFixed(2))}) `}</InputTitle>
                            :
                            <InputTitle className="sendText">Send</InputTitle>
                        }
                        
                        <TransBalTxt className="sendAmt">
                            {Object.values(sendToken).length > 0 ? `${parseFloat(sendToken.value).toFixed(4)} ($${parseFloat(sendToken.usdValue).toFixed(2)}) ` : '-'}
                        </TransBalTxt>
                    </FlexSBCont>
                    <TransInputBox>
                        <FlexSBCont>
                            <TransInput
                                disabled={!(Object.values(sendToken).length > 0)}
                                placeholder="0"
                                onChange={handleSendAmount}
                                value={sendAmountWithComma && sendAmountWithComma}
                                autoFocus={true}
                            />
                            <FlexCont>
                                {Object.values(sendToken).length > 0 && <TransMaxBox onClick={() => handleMaxAmount()}>Max</TransMaxBox>}
                                <TokenSelectCont onClick={() => setShowTokenModal(true)}>
                                    {Object.values(sendToken).length > 0 && (
                                        <FlexCont>
                                            <img className="token-icon" src={sendToken.icon} alt="" />
                                            <TokenName>{sendToken.name}</TokenName>
                                            <img className="down-icon" src={DownIcon} alt="" />
                                        </FlexCont>
                                    )}

                                </TokenSelectCont>
                            </FlexCont>

                        </FlexSBCont>
                    </TransInputBox>
                </TransInputCont>
                {data.isStablePool ? 
                <TransDataCont>
                    {
                        isAmtLoading && parseFloat(sendAmount) > 0 && <TransInoTxt className="loading-text">Amount Loading ...</TransInoTxt>
                    }
                    
                    <FlexSBCont >
                        <FlexCont >
                            <img className="icon-data" src={stableSwapIcon} alt="" />
                            <TransTxt>3G LP</TransTxt>
                        </FlexCont>
                        <TransTxt>{!!transInfo && transInfo.liquidity && parseFloat(sendAmount) > 0 ? `${parseFloat(transInfo.liquidity).toFixed(6)} LP ($${(+data.price * +transInfo.liquidity).toFixed(2)})` : 0}</TransTxt>
                    </FlexSBCont>
                </TransDataCont>
                :
                <TransDataCont>
                    {
                        isAmtLoading && parseFloat(sendAmount) > 0 && <TransInoTxt className="loading-text">Amount Loading ...</TransInoTxt>
                    }
                    <FlexSBCont className="token-data">
                        <FlexCont >
                            <img className="icon-data" src={getIcons(1)} alt="" />
                            <TransTxt>{getTokenName(1)}</TransTxt>
                        </FlexCont>
                        <TransTxt>{!!transInfo && transInfo.amountsOut0 && parseFloat(sendAmount) > 0 ? parseFloat(transInfo.amountsOut0).toFixed(6) : 0}</TransTxt>
                    </FlexSBCont>
                    <FlexSBCont className="token-data">
                        <FlexCont >
                            <img className="icon-data" src={getIcons(2)} alt="" />
                            <TransTxt>{getTokenName(2)}</TransTxt>
                        </FlexCont>
                        <TransTxt>{!!transInfo && transInfo.amountsOut1 && parseFloat(sendAmount) > 0 ? parseFloat(transInfo.amountsOut1).toFixed(6) : 0}</TransTxt>
                    </FlexSBCont>
                    <FlexSBCont >
                        <FlexCont >
                            <TwoImageCont>
                                <img src={getIcons(1)} alt="" />
                                <img className="second-img" src={getIcons(2)} alt="" />
                            </TwoImageCont>
                            <TransTxt>{getTokenName(0)}</TransTxt>
                        </FlexCont>
                        <TransTxt>{!!transInfo && transInfo.liquidity && parseFloat(sendAmount) > 0 ? `${parseFloat(transInfo.liquidity).toFixed(6)} LP` : 0}</TransTxt>
                    </FlexSBCont>
                </TransDataCont>
                }
                {
                    transInfo.impactAbove3Percent && !inSufAmt && parseFloat(sendAmount) > 0 &&
                    <PriceImpactBox>
                        <p>Price impact is over 3%, please try a smaller amount</p>
                    </PriceImpactBox>
                }
                {
                   warningMessage && 
                    <PriceImpactBox>
                        <p>High slippage value, please try a smaller amount</p>
                    </PriceImpactBox>
                }
                {
                    sendToken.address === bnbAddress ?
                        <TransButton disabled={!(parseFloat(sendAmount) > 0) || createLpLoading || !selector.ethData.ethWalletConnected || inSufAmt || transInfo.impactAbove3Percent || isAmtLoading} onClick={() => handleCreateLp()}> {createLpLoading ? <Spinner /> : inSufAmt ? 'Insufficient Balance' : 'Create LP'}</TransButton> :
                        <>
                            {
                                parseFloat(allowanceAmt) < parseFloat(sendAmount) ?
                                    <TransButton disabled={!(parseFloat(sendAmount) > 0) || approveLoading || !selector.ethData.ethWalletConnected} onClick={() => handleApproveLp()}> {approveLoading ? <Spinner /> : 'Approve'}</TransButton> :
                                    <TransButton disabled={!(parseFloat(sendAmount) > 0) || createLpLoading || !selector.ethData.ethWalletConnected || inSufAmt || transInfo.impactAbove3Percent || isAmtLoading} onClick={() => handleCreateLp()}> {createLpLoading ? <Spinner /> : inSufAmt ? 'Insufficient Balance' : 'Create LP'}</TransButton>
                            }
                        </>

                }
            </TransActionCont>
            <CustomModal show={showTokenModal} toggleModal={setShowTokenModal}>
                {showTokenModal && (
                    <TokenSelect
                        selectedAdd={sendToken.address}
                        setShowTokenModal={setShowTokenModal}
                        tokenListVal={tokenDataList}
                        handleTokenSelect={handleTokenSelect}
                        hideInput={true}
                    />
                )}
            </CustomModal>
            <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
                {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
            </CustomModal>
        </Fragment>

    )
}

export default TransformAction