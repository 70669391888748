import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { PlanetCard } from 'shared/styles/styled'

export const PoolsContainer = styled.div`
  position: relative;
  .title-info{
      margin-top: 10px;
      @media (min-width: ${screenSizes.mediaM}px) {
        margin-top: 20px;
      }
  }
`
export const PoolsTabCont = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
  }
`
export const TabWrapper = styled.div`
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0 -16px;
`
export const TabContainer = styled.div`
   display: flex;
   align-items: center;
   min-width: 620px;
   box-sizing: border-box;
   padding-left: 16px;
   flex-wrap: no-wrap;
   @media (min-width: ${screenSizes.mediaM}px) {
     min-width: auto;
     margin-right: 0;
     flex-wrap: wrap;
  }
`
export const TabBox = styled.div<any>`
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 12px;
   padding: 6px 18px;
   background: ${(props: any) => props.isActive ? 'transparent linear-gradient(270deg, rgba(48,55,218, 0.2) 0%, rgba(85,183,255, 0.2) 100%) 0% 0% no-repeat padding-box' : 'rgba(0, 0, 0, 0.4)'};
   box-shadow: 0px 3px 10px #00000033;
   border: ${(props: any) => props.isActive ? 'none' : `1px solid ${colors.borderColor}`};
   border-radius: 100px;
   cursor: pointer;
   height: 31px;
   box-sizing: border-box;
   @media (min-width: ${screenSizes.mediaM}px) {
      margin-right: 10px;
      padding: 10px 30px;
      font-size: 15px;
      height: 43px;
    } 
`
export const TabText = styled.p<any>`
  font-size: 12px;
  line-height: 15px;
  color: ${(props: any) => props.isActive ? colors.lightBlue : colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: ${(props: any) => props.isActive ? 1 : 0.4};
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 19px;
    line-height: 23px;
  } 
  &:hover{
    opacity: 1;
}
`

export const GammaEarnedBox = styled(PlanetCard)`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  height: auto;
  margin-top: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
      height: 80px;
      margin-top: 0;
      &.claimGammaEarnings{
        margin-right: 20px;
      }
  }
  img{
      width: 44px;
      height: 44px;
      margin-right: 10px;
      @media (min-width: ${screenSizes.mediaM}px) {
          width: 30px;
          height: 30px;
        }
    }
  button{
      width: 118px;
      height: 48px;
      font-size: 16px;
      color: ${colors.lightGreen};
      margin-left: 0;
      border-radius: 24px;
      @media (min-width: ${screenSizes.mediaM}px) {
          width: 90px;
          height: 39px;
          font-size: 15px;
          border-radius: 20px;
          margin-left: 15px;
        }
    }
`
export const EarnValText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 5px 0 0 0;
`
export const EarnValTextUSD = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 5px 0 0 0;  
  &:hover{
    opacity: 0;
  }
`
export const PoolsHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: ${screenSizes.mediaM}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    div.gammaEarnings{
      float:left;
      display: flex;
    }
  }
`
export const DataInfoBoxNew = styled(PlanetCard)`
  padding: 0px 0px 0px 0px;
  &.gammaEarningsCard{
  }
  &.borrowCard{
    padding: 1.04vw;
    width: 100%;
    margin-top: 1.04vw;
    display: flex;
    @media (max-width: 799px) {
      margin-top: 4.83vw;
      padding: 4.83vw 2.41vw; 
    }
  }
`

export const SliderContainer = styled.div<any>`
.rangeslider{
  margin: 0;
  margin: 10px 0;
  width: 100%;
  max-width: 99.5%;
  box-sizing: border-box;
  
}
&.borrowLimit{
  width:1px;
  height:10px;
  color:red;
}
.rangeslider-horizontal {
  height: 1.25vw;
  border-radius: 1.04vw;
  background: ${colors.themeBackground};
  border:1px solid ${colors.borderColor};
  box-sizing:border-box;
  padding:0;
  cursor:initial;
  width: 100%;
  ul{
    display:none;
  }
  .rangeslider__fill {
    border-radius: 1.04vw;
    //background: linear-gradient(90deg, ${colors.lightGreen} 0%, ${colors.green} 100%) 0% 0% no-repeat padding-box;
    //box-shadow:none;
    background: transparent linear-gradient(90deg, #FF5555 0%, #28AE41 75%, #27AC40 100%) 0% 0% no-repeat padding-box;
  }
  .rangeslider__handle:after {
    display:none;
  }
  .rangeslider__handle {
    display:none;
  }
  @media (max-width: 799px) {
    height: 2.898vw;
  }

  
}
&.greenWebSlider .rangeslider-horizontal{
  background: linear-gradient(to right, #0E2012 75%, #000000 25%) !important;
}

&.SliderContainer .rangeslider-horizontal{
  background: linear-gradient(to right, #0E2012 75%, #000000 25%) !important;
}

&.SliderContainerPool{
  width: 100%;
  margin-top: 1.5625vw;
  @media (max-width: 799px) {
    margin-top: 15px;
  }
}

&.SliderContainerPool .rangeslider-horizontal, &.SliderContainer.pool .rangeslider-horizontal, &.SliderContainerPopup .rangeslider-horizontal {
  background: #000000 !important;
}

&.SliderContainerPopup .rangeslider-horizontal{
  height: 0.625vw;
  width: 100%;
  @media (max-width: 799px) {
    height: 10px;
    width: 50.75vw;
  }
}

&.SliderContainerPopup > .rangeslider-horizontal > .rangeslider__handle{
  height: 10px;
  width: 5px;
  display: block;
}

&.SliderContainerPool .rangeslider-horizontal{
  height: 0.625vw;
  @media (max-width: 799px) {
    height: 10px;
  }
}

&.OrangeLimit .rangeslider__fill{
  //background: linear-gradient(to right, ${colors.lightGreen} 0%, ${colors.green} 75%, #FF8955 15% ) 0% 0% no-repeat padding-box !important;
   background: ${(props: any) => props.borrowLimit ? 'linear-gradient(to right, #FF5555 0%, #28AE41 calc(75% / '+props.borrowLimit+' * 100), #27AC40 calc(100% - ((75 / '+props.borrowLimit+') * 100%)) ) 0% 0% no-repeat padding-box !important' : 'linear-gradient(to right, #FF5555 0%, #28AE41 75%, #27AC40 15% ) 0% 0% no-repeat padding-box !important'};
}

&.RedLimit .rangeslider__fill{
  //background: linear-gradient(to right, ${colors.lightGreen} 0%, ${colors.green} 75%, #FF8955 15%, #FF5C55 10% ) 0% 0% no-repeat padding-box !important;
  background: ${(props: any) => props.borrowLimit ? 'linear-gradient(to right, #FF5555 0%, #28AE41 calc(75% / '+props.borrowLimit+' * 100), #27AC40 calc(15 / '+props.borrowLimit+' * 100%)) 0% 0% no-repeat padding-box !important' : 'linear-gradient(to right, #FF5555 0%, #28AE41 75%, #27AC40 15% ) 0% 0% no-repeat padding-box !important'};
}

 `

 export const SliderText = styled.p<any>`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: ${(props: any) => props.light ? 'MontserratSemiBold' : 'MontserratBold'};
  opacity: ${(props: any) => props.light ? 0.4 : 1};
  margin: 0;
  &.cursor_pointer {
    cursor: pointer;
  }
  span{
    font-size: 12px;
    line-height: 16px;
    color: #6A7273;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
    line-height: 22px;
   
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  
  &.discountLevel{
    height: 74px;
    width: 74px;
    color:  #FFF;
    opacity: 1;
    font-weight: bold;
    text-align: center;
    line-height: 74px;
    font-size: 37px;
  }
  &.borrow_usage{
    font-size: 1.04vw;
    line-height: 1.25vw;
    @media (max-width: 799px) {
      font-size: 2.41vw;
      line-height: 3.14vw;
    }
  }
  &.borrow_value{
    font-size: 1.04vw;
    line-height: 1.25vw;
    @media (max-width: 799px) {
      font-size: 3.38vw;
      line-height: 4.34vw;
    }
  }
`