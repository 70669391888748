import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { NormalBtn } from 'shared/styles/styled'
import { Button } from 'shared/button'
import { PlanetCard } from 'shared/styles/styled'

export const WebBlock = styled.div`
  display:none;
  @media (min-width: ${screenSizes.mediaM}px) {
    display:block;
  }
`
export const MobileBlock = styled.div`
  display:block;
  @media (min-width: ${screenSizes.mediaM}px) {
    display:none;
  }
`
export const HeaderCont = styled.div`
  .gamma-icon{
    margin-right: 20px;
    width: 40px;
    height: 40px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 50px;
      height: 50px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 60px;
      height: 60px;
    }
  }
  .level-icon{
    width: 50px;
    height: 50px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 70px;
      height: 70px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 85px;
      height: 85px;
    }
  }
  .level-info{
    padding-right: 16px;
    margin: 0 30px;
  }
`
export const HeaderTxt = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: 0.4;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    color: #FFF;
    font-size: 14px;
    line-height: 18px;
    opacity: 1;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    color: #FFF;
    font-size: 16px !important;
    line-height: 18px;
    opacity: 1;
    &.gammaEarning{
      color: ${colors.white};
      opacity: 1;
      font-size: 14px !important;
    }
    &.gammaEarningsUSD {
      font-size: 16px!important;
    }
    &.stakeTextGradient{
      font-size: 16px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    &.gammaEarning{
      color: ${colors.white};
      opacity: 1;
      font-size: 13px !important;
    }
    &.gammaEarningsUSD {
      font-size: 14px !important;
    }
    &.stakeTextGradient{
      font-size: 14px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    color: #FFF;
    font-size: 16px !important;
    line-height: 18px;
    opacity: 1;
    &.gammaEarningsUSD {
      font-size: 14px !important;
    }
    &.stakeTextGradient{
      font-size: 14px !important;
    }
  }
  &.gammaEarningsUSD {
    text-align: left;
    opacity: 0.4;
    color: #FFFFFF;
  }
  &.gammaEarningsMobUSD{
    opacity: 1;
    color: #FFFFFF;
  }
  &.stakeTextGradient{
    background: transparent linear-gradient(91deg, #55FF76 0%, #27AC40 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
	  -webkit-text-fill-color: transparent;
    opacity: 1;
  }
  &.gammaEarning{
    color: ${colors.white};
    opacity: 1;
    font-size: 14px;
  }
  &.cursor_pointer {
    cursor: pointer;
  }
  &.stakeTextGradientGrey{
    color: #FFFFFF64 !important;
  }
  &.totalMarketSize{
    line-height: 2.291vw;
    font-size: 2.39vw!important;
    background: transparent linear-gradient(90deg, #55FE76 0%, #27AC40 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: 799px) {
      line-height: 8.937vw;
      font-size: 7.246vw!important;
      opacity: 1 !important;
    }
  }

  &.gammaEarning{
    color: ${colors.white};
    opacity: 1;
    font-size: 0.729vw !important;
    line-height: 0.9375vw;
  }

  &.gammaEarningsUSD {
    font-size: 0.729vw !important;
    line-height: 0.9375vw;
  }
`

export const HeaderNumTxt = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 10px 0;
  span{
    font-size: 14px;
    line-height: 17px;
    color: #736F82;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    &.gammaEarningsMob{
      margin:0;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    &.gammaEarnings{
      font-size: 20px !important;
    }
    span.gammaEarnings{
      text-align: left;
      color: #666666;
      font-size: 0.833vw!important;
    }
    &.DiscountPercent{
      font-size: 20px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    &.gammaEarnings{
      font-size: 22px !important;
    }
    span.gammaEarnings{
      text-align: left;
      color: #666666;
      font-size: 15px;
    }
    &.DiscountPercent{
      font-size: 22px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    &.gammaEarnings{
      font-size: 24px !important;
    }
    span.gammaEarnings{
      text-align: left;
      color: #666666;
      font-size: 16px;
    }
    &.DiscountPercent{
      font-size: 24px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 24px;
    line-height: 29px;
  }
  &.gammaEarnings{
    text-align: left;
    color: #FFFFFF;
    font-size: 12px;
  }
  span.gammaEarnings{
    text-align: left;
    color: #666666;
    font-size: 12px;
  }
  &discountPercent{
    max-width: 150px;
    display: flex;
    width: fit-content;
  }
  &.gammaEarnings span{
    color: #666666;
    font-size: 16px;
  }
  &.gammaEarningsMob{
    text-align: left;
    color: #FFFFFF;
    font-size: 12px;
    opacity: 1;
    font-family: MontserratRegular;
  }
  &.gammaEarningsMob span{
    color: #FFFFFF;
    font-size: 12px;
    opacity: 1;
  }
  &.DiscountPercent{
    font-size: 24px;
  }
  &.DiscountInGrey{
    color: #FFFFFF64;
  }

  &.gammaEarnings{
    margin: 0.52vw 0 !important;
    font-size: 1.25vw !important;;
    line-height: 0.989vw !important;
  }

  span.gammaEarnings{
    text-align: left;
    color: #666666;
    font-size: 0.833vw!important;
  }

  &.gammaText{
    font-size: 1.25vw;
    line-height: 1.51vw;
    margin: 0.408vw 0 0 0.52vw;
  }

  &.gammaSubText{
    font-size: 0.989vw;
    line-height: 1.197vw;
    margin: 0.416vw 0 0 0.52vw;
    opacity: 0.4;
  }

  &.gammaBoostSubText{
    font-size: 0.989vw;
    line-height: 1.197vw;
    margin: 0.208vw 0 0 0.52vw;
    color: #55FF76;
  }
`

export const DiscountText = styled.div<any>`
  padding: 5px 10px;
  font-size: 12px;
  color:${(props: any) => props.isHighLevel ? colors.lightGreen : colors.red};
  font-family: MontserratBold;
  margin-left: 10px;
  background: ${(props: any) => props.isHighLevel ? 'rgba(85,254,118, 0.15)' : 'rgba(255,85,85, 0.15)'};
  border-radius: 10px;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 14px;
    padding: 5px 16px;
    margin-left: 16px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 16px;
    padding: 5px 20px;
    margin-left: 20px;
  }
`
export const HeaderBtn = styled(NormalBtn)`
  width: 132px;
  max-width: 100px;
  height: 40px;
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: MontserratBold;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 16px;
    max-width: 120px;
    height: 45px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 18px;
    max-width: 132px;
    height: 50px;
  }

  &.collectGamma{
    padding: 0.729vw 1.718vw; 
    font-size: 0.9375vw;
    background: transparent linear-gradient(90deg, #55FF7615 0%, #27AC4015 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 2px solid #FFFFFF15;
    border-radius: 25px;
    color: #55FE76;
  }

  &.stakeGamma{
    margin: 0.8854vw 0 0 2.864vw;
    padding: 0.729vw 3.854vw; 
    font-size: 0.989vw;
    background: transparent linear-gradient(270deg, #5EF480 0%, #207C2E 100%) 0% 0% no-repeat padding-box;
    border-radius: 25px;
    opacity: 1;
    color: #FFFFFF;
    width: auto;
    max-width: fit-content;
  }

  &.lendingGammaApyBtn{
    padding: 0.52vw 1.04vw;
    font-size: 0.781vw !important;
    line-height: 0.989vw;
    width: auto;
    height: auto;
    margin-left: 2.44vw;
  }
`

export const HeaderInfoBox = styled(PlanetCard)`
  padding: 20px 16px 15px 16px;
  min-height: 110px;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    padding: 20px 20px 15px 20px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    padding: 20px 30px 15px 30px;
  }
`
export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    margin-right: 20px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    margin-right: 30px;
  }
`
export const DataInfoCont = styled.div`
  display:grid;
  grid-template-columns: minmax(auto, 240px) 1fr;
  align-items: start;
  grid-gap: 20px;
  margin-top: 30px;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    grid-template-columns: minmax(auto, 320px) 1fr;
    grid-gap: 30px;
  }
`
export const DataInfoContNew = styled.div`
  display:flex;
  align-items: start;
  grid-gap: 1.5625vw;
  margin-top: 1.5625vw;
  margin-bottom: 1.5625vw;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    grid-gap: 1.5625vw;
  }
  &.DataInfoContNew{
    display: flex;
    width: 100%;
    grid-gap: 1.5625vw;
  }

  &.lending_stats_card {
    justify-content: space-between;
  }
`
export const CardDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.borderColor};
  margin: 30px 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 20px 0 0 0;
  }
`
export const VerticalCardDivider = styled.div`
  width: 1px;
  height: 4vw;
  background: ${colors.borderColor};
  @media (min-width: ${screenSizes.mediaM}px) {
  }
  &.borrowLimit{
    height: 30px;
    left: 75%;
    float: left;
    top: 22px;
    position: absolute;
    border-right: 3px dashed #FFFFFF26;
    background: transparent;
  }
  &.borrowLimitPopup{
    height: 25px;
    left: 70%;
    float: left;
    top: -30px;
    position: relative;
    border-right: 3px dashed #FFFFFF26;
    background: transparent;
  }
  @media not all and (min-resolution:.001dpcm) { 
    @media {
      &.borrowLimit{
        height: 30px;
        left: 75%;
        float: left;
        top: -25px;
        position: relative;
        border-right: 3px dashed #FFFFFF26;
        background: transparent;
      }
      &.borrowLimitPopup{
        height: 25px;
        left: 70%;
        float: left;
        top: -30px;
        position: relative;
        border-right: 3px dashed #FFFFFF26;
        background: transparent;
      }
    }
  } 
  &.borrowLimitWeb{
    height: 35px;
    margin-left: 75%;
    margin-top: -35px;
    float: left;
    top: 20px;
    border-right: 3px dashed #FFFFFF26;
    background: transparent;
  }
  &.limiterGap{
    width:75%;
    height: 0;
    background: transparent;
    float: left;
  }
`

export const VerticalCardDivider1 = styled.div`
  width: 1px;
  height: 100%;
  position: absolute;
  left: 25%;
  background: ${colors.borderColor};
  @media (min-width: ${screenSizes.mediaM}px) {
  }
`
export const VerticalCardDivider2 = styled.div`
  width: 1px;
  height: 100%;
  position: absolute;
  left: 50%;
  background: ${colors.borderColor};
  @media (min-width: ${screenSizes.mediaM}px) {
  }
`
export const VerticalCardDivider3 = styled.div`
  width: 1px;
  height: 100%;
  position: absolute;
  left: 75%;
  background: ${colors.borderColor};
  @media (min-width: ${screenSizes.mediaM}px) {
  }
`

export const DataInfoBox = styled(PlanetCard)`
  padding: 20px 20px 10px 20px;
  &.GammaEarning {
    width: 50%;
    padding: 1.875vw 1.5625vw;
    position: relative;
  }
  .gamma-icon {
    margin-right: 20px;
    width: 40px;
    height: 40px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 75px;
      height: 75px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 60px;
      height: 60px;
    }
  }
`
export const DataInfoBoxNew = styled(PlanetCard)`
  padding: 0px 0px 0px 0px;
  &.gammaEarningsCard{
  }
  &.borrowCard{
    padding: 1.04vw;
    width: 100%;
  }
`
export const DataInfoBoxPadding = styled.div`
  padding: 30px 30px 30px 30px;
`
export const InfoBoxWrapper = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  position: relative;
  flex-wrap:wrap;
  .info-box{
    margin-right: 10px;
  }
  .info-box:after {
    border-right: 1px solid;
  }
  &.InfoBoxWrapper{
    padding: 1.77vw 1.5625vw;
    display: flex;
  }

  &.gammaEarningsCard.InfoBoxWrapper{
    padding: 1.5625vw 1.5625vw;
    display: flex;
    flex-wrap: nowrap;
  }
  &.totalMarketSizeCard{
    padding: 1.5625vw 0;
  }
`
export const InfoBox = styled.div`
  .net-apy{
    color:#55FE83;
  }
  box-sizing:border-box;
  
`

export const InfoBoxMob = styled.div`
  display: flex;
  &.gammaEarnings{
    width: 50%;
  }
  &.discountLevel {
    width: 50%;
  }
  &.gammaTxCards{
    grid-gap: 3.64vw;
    margin-bottom : 5.55vw;
  }
`

export const InfoBoxNew = styled.div`
  .net-apy{
    color:#55FE83;
  }
  box-sizing:border-box;  
  &.info-box{
    align-items: center;
    margin: 0 0;
  }
  &.spaceInLendingStats{
    margin-left: 3.125vw;
  }
`
export const DiscountPie = styled.div`
min-width:90px;
min-height:90px;
float: left;
svg{
  height: 75px;
  width: 75px;
}
circle {
  fill: transparent;
  stroke: #55FF76;
  opacity: 1;
  stroke-width: 6;
}
.level1{
  stroke-dasharray: 9 2;
  opacity:0.2;
}
.level2{
  stroke-dasharray: 9 2;
  opacity:0.2;
}
.level3{
  stroke-dasharray: 9 2;
  opacity:0.2;
}
.dashed {
  stroke-dasharray: 9 2;
  opacity:0.2;
}
div {
  height: 75px;
  width: 75px;
  color:  #FFF;
  opacity: 0.9;
  font-weight: bold;
  text-align: center;
  line-height: 90;
}
&.discountLevel{
  height: 74px;
  width: 74px;
  color:  #FFF;
  opacity: 1;
  font-weight: bold;
  text-align: center;
  line-height: 74px;
  margin-left: 3px;
}
&.discountPieMob{
  margin-left: 3.14vw;
  min-width: fit-content;
  min-height: fit-content;
}

@media (max-width: 799px) {
  img.gamma-icon{
    height: 15.7vw;
    width: 15.7vw;
  }

  img.stake-gamma-icon{
    height: 10.92vw;
    width: 12.13vw;
    
  }

  &.stake-gamma-icon-cont{
    min-width: 16.26vw;
    min-height: 12.13vw;
    float: left;
  }
}
`

export const MarketSizeTxt = styled.p`
  font-size: 30px;
  line-height: 38px;
  font-family: MontserratBold;
  margin:0;
  background: transparent linear-gradient(90deg, #55FE76 0%, #27AC40 100%) 0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 18px;
    line-height: 21px;
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    font-size: 20px;
    line-height: 23px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 24px;
    line-height: 27px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 35px;
    line-height: 38px;
  }
`
export const InfoNumberTxt = styled.p`
  font-size: 1.822vw;
  line-height: 1.927vw;
  font-family: MontserratBold;
  color: ${colors.white};
  margin:0;
  * span{
    font-size: 1.5625vw;
    line-height: 1.927vw;
    margin: 12px 0 0 0;
    color: #6A7273;

    @media (min-width: 800px) {
      font-size: 1.5625vw;
      line-height: 1.927vw;
    }
  }
  
  &.top-space span{
    color: #666666;
  }
  &.vestingGamma{
    color: #FFFFFF;
    font-size: 1.25vw;
    line-height: 0.989vw;
    @media (max-width: 799px) {
      font-size: 4.83vw;
      line-height: 4.59vw;
    }
  }
  &.vestingGamma > span {
    opacity: 0.4;
    font-size: 0.8333vw;
    @media (max-width: 799px) {
      font-size: 3.864vw;
      line-height: 4.59vw;
    }
  }
`

export const InfoNumberTxtNew = styled.p`
  font-size: 18px;
  line-height: 21px;
  font-family: MontserratBold;
  color: ${colors.white};
  text-align: start;
  margin:0;
  span{
    font-size: 14px;
    line-height: 18px;
    margin: 12px 0 0 0;
    color: #6A7273;

    @media (min-width: ${screenSizes.mediaXL}px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
      font-size: 20px;
      line-height: 24px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      font-size: 30px;
      line-height: 38px;
    }
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    font-size: 20px;
    line-height: 23px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 24px;
    line-height: 27px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 35px;
    line-height: 38px;
  }
`

export const NetApyText = styled(InfoNumberTxtNew) <any>`
  color: ${(props: any) => (props.netApy == undefined || +props.netApy === 0) ? "#FFFFFF64" : (props.isNegative) ? colors.red : colors.lightGreen };
  font-size: 1.822vw;
  line-height: 1.927vw;
  img.tooltip-web{
    height: 14px;
    width: 14px;
  }
`
export const InfoLightText = styled.p`
  &.cursor_pointer {
    cursor: pointer;
  }
  font-size: 14px;
  line-height: 18px;
  font-family:MontserratSemiBold;
  color: ${colors.white};
  margin: 0.52vw 0 0 0;
  opacity:0.2;
  text-align: center;
  @media (min-width: 800px) {
    font-size: 1.04vw;
    line-height: 1.25vw;
  }
  &.collateralText{
    opacity: 0.4;
  }
`
export const SwitchCont = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
`

/* ========================== Slider Style ======================= */

export const SliderBox = styled(PlanetCard)`
  padding: 20px;
  margin: 30px 0;
`
export const SliderText = styled.p<any>`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: ${(props: any) => props.light ? 'MontserratSemiBold' : 'MontserratBold'};
  opacity: ${(props: any) => props.light ? 0.4 : 1};
  margin: 0;
  &.cursor_pointer {
    cursor: pointer;
  }
  span{
    font-size: 12px;
    line-height: 16px;
    color: #6A7273;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
    line-height: 22px;
   
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  
  &.discountLevel{
    height: 74px;
    width: 74px;
    color:  #FFF;
    opacity: 1;
    font-weight: bold;
    text-align: center;
    line-height: 74px;
    font-size: 37px;
  }
  &.borrow_usage{
    font-size: 1.04vw;
    line-height: 1.25vw;
  }
  &.borrow_value{
    font-size: 1.04vw;
    line-height: 1.25vw;
  }
`

export const SliderContainer = styled.div<any>`
.rangeslider{
  margin: 0;
  margin: 10px 0;
  width: 100%;
  max-width: 99.5%;
  box-sizing: border-box;
  zoom: 90%;
  
}
&.borrowLimit{
  width:1px;
  height:10px;
  color:red;
}
.rangeslider-horizontal {
  height: 1.25vw;
  border-radius: 1.04vw;
  background: ${colors.themeBackground};
  border:1px solid ${colors.borderColor};
  box-sizing:border-box;
  padding:0;
  cursor:initial;
  width: 100%;
  ul{
    display:none;
  }
  .rangeslider__fill {
    border-radius: 1.04vw;
    //background: linear-gradient(90deg, ${colors.lightGreen} 0%, ${colors.green} 100%) 0% 0% no-repeat padding-box;
    //box-shadow:none;
    background: transparent linear-gradient(90deg, #55FF76 0%, #27AC40 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #55FF76BF;
  }
  .rangeslider__handle:after {
    display:none;
  }
  .rangeslider__handle {
    display:none;
  }
  @media (max-width: 799px) {
    height: 2.898vw;
  }

  
}
&.greenWebSlider .rangeslider-horizontal{
  background: linear-gradient(to right, #0E2012 75%, #000000 25%) !important;
}

&.SliderContainer .rangeslider-horizontal{
  background: linear-gradient(to right, #0E2012 75%, #000000 25%) !important;
}
&.OrangeLimit .rangeslider__fill{
  //background: linear-gradient(to right, ${colors.lightGreen} 0%, ${colors.green} 75%, #FF8955 15% ) 0% 0% no-repeat padding-box !important;
   background: ${(props: any) => props.borrowLimit ? 'linear-gradient(to right, #55FF76 0%, #27AC40 calc(75% / '+props.borrowLimit+' * 100), #FF8955 calc(100% - ((75 / '+props.borrowLimit+') * 100%)) ) 0% 0% no-repeat padding-box !important' : 'linear-gradient(to right, ${colors.lightGreen} 0%, ${colors.green} 75%, #FF8955 15% ) 0% 0% no-repeat padding-box !important'};
}

&.RedLimit .rangeslider__fill{
  //background: linear-gradient(to right, ${colors.lightGreen} 0%, ${colors.green} 75%, #FF8955 15%, #FF5C55 10% ) 0% 0% no-repeat padding-box !important;
  background: ${(props: any) => props.borrowLimit ? 'linear-gradient(to right, #55FF76 0%, #27AC40 calc(75% / '+props.borrowLimit+' * 100), #FF8955 calc(15 / '+props.borrowLimit+' * 100%), #FF5C55 calc(100% - ((90 / '+props.borrowLimit+') * 100%))) 0% 0% no-repeat padding-box !important' : 'linear-gradient(to right, ${colors.lightGreen} 0%, ${colors.green} 75%, #FF8955 15% ) 0% 0% no-repeat padding-box !important'};
}

 `

/* ======================== Market Table =================== */

export const TableCont = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  align-items: start;
  @media (min-width: ${screenSizes.mediaM}px) {
    /*grid-gap: 16px;*/
    &.supplyBorrow{
      column-gap: 1.5625vw;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    grid-gap: 20px;  
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    grid-gap: 30px;
  }
`
/* ========================== Mobile view ========================= */

export const MobileHomeCont = styled.div`
  .heading-space{
    margin-bottom:20px;
  }
  .info-space {
    margin-bottom:10px;
  }
  .balance-space{
    margin-bottom: 20px;
  }
  .hover-info{
    margin-left: 5px;
  }
  .more-details{
    text-align : center;
  }
`

export const MobileHeader = styled.div`
  .tvl-space{
    margin-top: 10px;
  }
`
export const LevelBox = styled(PlanetCard)`
  border-radius: 14px;
  padding: 8px 14px;
  width: 104px;
  max-width: 104px;
  p{
    font-size: 14px;
    font-family: MontserratBold;
    text-transform: capitalize;
    color: ${colors.white};
    margin: 0;
    text-align: center;
  }
`
export const HeaderBtnGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin: 20px 0 15px 0;
  img {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }
`
export const AquaPriceBox = styled(PlanetCard)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${colors.white};
  font-family: MontserratSemiBold;

`
export const ActionBtn1 = styled(Button)`
  height: 49px;
  background: transparent linear-gradient(269deg, ${colors.green} 0%, ${colors.lightGreen} 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #27AC40;
  border: 0;
  &:disabled {
    background: transparent linear-gradient(269deg, ${colors.green} 0%, ${colors.lightGreen} 100%) 0% 0% no-repeat padding-box;
  }
`
export const ActionBtn2 = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
  background: ${colors.background};
  img {
    width: 34px;
    height: 33px;
    margin-right: 6px;
  }
  &:disabled {
    opacity:1;
  }
`
export const InfoDataCard = styled(PlanetCard)`
  padding: 4.854vw;
  margin-bottom: 3.64vw;
  &.gammaEarnings{
    width: auto;
    padding: 4.854vw 4.854vw 3.864vw 4.854vw;
    margin: 0;
  }
  &.discountLevel{
    width: auto;
    margin-bottom: 0;
    display: flex;
    padding: 3.62vw 4.854vw 4.59vw 4.854vw;
  }
  &.marketTVLMob{
    padding: 0;
    margin-bottom: 4.854vw;
    border: none;
    background: transparent;
    box-shadow: none;
    backdrop-filter: unset;
  }
`
export const DataCard = styled(PlanetCard)`
  padding: 10px 0;
  margin-bottom: 15px;
`
export const MobBalanceCont = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  .netApy{
    color: ${colors.lightGreen};
  }
`
export const CardText = styled.p`
  font-size: 14px;
  line-height: 18px;
  font-family: MontserratSemiBold;
  color: ${colors.white};
  margin:0;
  opacity: 0.4;
  &.discountTextMob{
    font-size: 4.34vw;
    color: #FFFFFF;
    opacity: 1;
  }
  &.discountText{
    font-size: 14px;
    color: #FFFFFF;
    opacity: 1;
    font-family: MontserratBold;
  }
  &.gammaToLevelUp{
    margin-top: 2.41vw;
    margin-bottom:2.41vw;
    font-size: 2.898vw;
    line-height: 3.623vw;
    white-space: nowrap;
    font-family: MontserratRegular !important;
  }
  &.borrowLimit{
    left: 66%;
    float: left;
    top: 23px;
    position: absolute;
  }
  &.borrowLimitPopup{
    left: 65% !important;
    float: left;
    top: -30px;
    position: relative;
  }
  
  @media not all and (min-resolution:.001dpcm) { 
    @media {
      &.borrowLimit{
        left: 66%;
        float: left;
        margin-top: -49px;
        position: relative;
      }
      &.borrowLimitPopup{
        left: 66%;
        float: left;
        top: -27px;
        position: relative;
      }
    }
  } 
  &.borrowLimitWeb{
    margin-left: 72%;
    float: left;
    font-size: 16px;
    margin-top: -27px;
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    &.borrowLimitWeb{
      font-size: 14px !important;
      margin-left: 71% !important;
    }
    &.borrowLimitPopup{
      top: -27px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    &.borrowLimitWeb{
      font-size: 14px !important;
      margin-left: 72% !important;
    }
    &.borrowLimitPopup{
      top: -27px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    &.borrowLimitWeb{
      font-size: 16px !important;
    }
  }
  &.gammaLevel{
    font-size: 12px;
    opacity: 1;
  }
  &.finalLimit{
    margin-left: -35px;
  }
  &.borrowUsage{
    
  }

  @media (max-width: 799px) {
    font-size: 2.898vw;
    line-height: 3.6231vw;

    &.GammaText{
      font-size: 3.38vw;
      line-height: 4.347vw;
      margin-top: 1.69vw;
      color: #FFFFFF;
      opacity:1 !important;
      font-family: MontserratBold;
    }
     
    &.boostText{
      font-size: 2.898vw;
      line-height: 3.623vw;
      margin-top: 0.72vw;
      color: #55FF76;
      opacity:1 !important;
      font-family: MontserratRegular;
    }

    &.gammaTVL{
      font-size: 2.898vw;
      line-height: 3.623vw;
      margin-top: 0.72vw;
      color: #FFFFFF;
      opacity:0.4 !important;
      font-family: MontserratRegular;
    }
  }
`
export const CardNumText = styled.p`
  font-size: 14px;
  line-height: 18px;
  font-family: MontserratBold;
  color: ${colors.white};
  margin:0;
`
export const CardBoldText = styled.p`
  font-size: 20px;
  line-height: 24px;
  font-family: MontserratBold;
  color: ${colors.white};
  @media (max-width: ${screenSizes.mediaS}px) {
    
  }
  margin:0;
  span {
    font-size: 16px;
    line-height: 19px;
    color: #736F82;
  }
  &.borrowPercent{
    font-size: 14px;
    color: #55FF76;
    font-family: MontserratSemiBold;
  }
  &.borrow-popup-mob{
    font-size: 39px;
    color: #FFFFFF;
  }
  &.borrow-popup-mob span{
    font-size: 20px;
    color: #666666;
  }
  &.netApyGrey{
    color: #FFFFFF64;
  }
  &.netApyGreen{
    color: ${colors.lightGreen};
  }
  &.netApyRed{
    color: ${colors.red};
  }
`
export const SwitchCard = styled(PlanetCard) <any>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 15px;
  .supp-switch{
    border-right:${(props: any) => props.activeSwitch === "supply" ? `1px solid ${colors.borderColor}` : "none"};
  }
  .bor-switch{
    border-left: ${(props: any) => props.activeSwitch === "borrow" ? `1px solid ${colors.borderColor}` : "none"};
  }
  &.mobile-switch{
    border-radius: 30px;
    background: #000000 0% 0% no-repeat padding-box;
  }
`
export const SwitchBox = styled.div<any>`
  border-radius: 20px;
  cursor: ${(props: any) => props.activeSwitch ? "initial" : "pointer"};
  padding: 12px 0;
  background: ${(props: any) => props.activeSwitch ? "rgba(85,255,118,0.2)":"#000"};
  p{
    font-size: 16px;
    line-height: 19px;
    font-family: MontserratBold;
    color: ${(props: any) => props.activeSwitch ? '#55FF76': '#FFFFFF66'};
    margin: 0;
    text-align: center;
    opacity: 1;
  }
  &.mobile-switch{
    border-radius: 30px;
    margin:2px;
  }
  &.supplyActive{
    background: rgba(85,255,118,0.2) !important;
  }

  &.borrowActive{
    background: rgba(85, 183, 255, 0.2) !important;
  }

  &.supplyActive p{
    color:'#55FF76' !important;
  }
  &.borrowActive p{
    color: rgba(85, 183, 255, 1) !important;
  }
`
/*  ========================= ToolTip Style ========================= */