// old zap contract
//export const planetZapOneInchAddress = '0xDA12Fe02eC04CcF26fCfC00d71AC2D56dD329078'//'0xa0c96Ae48e62f1606393A6b64bF264D9C0aeDB5C';

//export const planetZapOneInchAddress = '0xA9b2ae23f8C87C7000eC8Fe88c6c09FfD3783168';
export const planetZapOneInchAddress = "0x12f8839D91BBEFf0fF75401a01c98734f70D1591"//"0x108dAEB2036Addd7FD3676354915C973966893A4"//'0x2Ec7954527173fcB9eD2114CA891d5807817B084'//'0x5989E25702443fc00EC583Eb83c1dC54FA7BD188'//'0x189633b0D81f853cb6b7408030ca2245a8AfBE07';

export const referralAddress = "0x19d68cC59Ce99A4fd0DB05bC0Ff4a12403fC2d1a";
export const referralFees = 0.1;

export const planetZapOneInchAbi: any = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_WBNB",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_newFarm",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_minAddLiquidity",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "tokenIn",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      }
    ],
    "name": "Swap",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "TokenReturned",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "tokenIn",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      }
    ],
    "name": "ZapIn",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "tokenOut",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amountOut",
        "type": "uint256"
      }
    ],
    "name": "ZapOut",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenB",
        "type": "address"
      }
    ],
    "name": "getReserves",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "reserveA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "reserveB",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_inputTokenDepositAmount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_router",
        "type": "address"
      }
    ],
    "name": "getSecondTokenDepositAmount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "_otherTokenAmountMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_otherTokenAmountMax",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_amount",
        "type": "uint256"
      }
    ],
    "name": "inCaseTokensGetStuck",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "minAddLiquidity",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "planetFarm",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bool",
        "name": "success",
        "type": "bool"
      },
      {
        "internalType": "bytes",
        "name": "data",
        "type": "bytes"
      },
      {
        "internalType": "string",
        "name": "errorMessage",
        "type": "string"
      }
    ],
    "name": "propagateError",
    "outputs": [],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenB",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_amountADesired",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_amountBDesired",
        "type": "uint256"
      }
    ],
    "name": "quoteAddLiquidity",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenB",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_liquidity",
        "type": "uint256"
      }
    ],
    "name": "quoteRemoveLiquidity",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_hypervisor",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenA",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_liquidity",
        "type": "uint256"
      }
    ],
    "name": "quoteRemoveLiquidityGammaUniproxy",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract ISolidlyPair",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_router",
        "type": "address"
      }
    ],
    "name": "quoteStableAddLiquidityRatio",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "ratio1to0",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_minAddLiquidity",
        "type": "uint256"
      }
    ],
    "name": "setMinAddLiquidity",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_newFarm",
        "type": "address"
      }
    ],
    "name": "setPlanetFarm",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_inputToken",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_tokenInAmount",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "_token0",
        "type": "bytes"
      },
      {
        "internalType": "address",
        "name": "_outputToken",
        "type": "address"
      }
    ],
    "name": "swap",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes",
        "name": "_token0",
        "type": "bytes"
      },
      {
        "internalType": "address",
        "name": "_outputToken",
        "type": "address"
      }
    ],
    "name": "swapFromBNB",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_inputToken",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_tokenInAmount",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "_token0",
        "type": "bytes"
      },
      {
        "internalType": "bytes",
        "name": "_token1",
        "type": "bytes"
      },
      {
        "internalType": "enum PlanetZapOneInch.WantType",
        "name": "_type",
        "type": "uint8"
      },
      {
        "internalType": "address",
        "name": "_router",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "_stake",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "pid",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "lockTimeIndex",
        "type": "uint256"
      }
    ],
    "name": "zapIn",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes",
        "name": "_token0",
        "type": "bytes"
      },
      {
        "internalType": "bytes",
        "name": "_token1",
        "type": "bytes"
      },
      {
        "internalType": "enum PlanetZapOneInch.WantType",
        "name": "_type",
        "type": "uint8"
      },
      {
        "internalType": "address",
        "name": "_router",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "_stake",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "pid",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "lockTimeIndex",
        "type": "uint256"
      }
    ],
    "name": "zapInBNB",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_withdrawAmount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_desiredToken",
        "type": "address"
      },
      {
        "internalType": "bytes",
        "name": "_dataToken0",
        "type": "bytes"
      },
      {
        "internalType": "bytes",
        "name": "_dataToken1",
        "type": "bytes"
      },
      {
        "internalType": "enum PlanetZapOneInch.WantType",
        "name": "_type",
        "type": "uint8"
      },
      {
        "internalType": "uint256",
        "name": "_minWithdrawToken0",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_minWithdrawToken1",
        "type": "uint256"
      }
    ],
    "name": "zapOut",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
];

export const planetZapOneInchAddressOld = "0x2Ec7954527173fcB9eD2114CA891d5807817B084";

export const planetZapOneInchAbiOld = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_oneInchRouter",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_WBNB",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "tokenIn",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      }
    ],
    "name": "Swap",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "TokenReturned",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "tokenIn",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      }
    ],
    "name": "ZapIn",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "tokenOut",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amountOut",
        "type": "uint256"
      }
    ],
    "name": "ZapOut",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "WBNB",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenB",
        "type": "address"
      }
    ],
    "name": "getReserves",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "reserveA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "reserveB",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_inputTokenDepositAmount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_router",
        "type": "address"
      }
    ],
    "name": "getSecondTokenDepositAmount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "_otherTokenAmountMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_otherTokenAmountMax",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_amount",
        "type": "uint256"
      }
    ],
    "name": "inCaseTokensGetStuck",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "minimumAmount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "oneInchRouter",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bool",
        "name": "success",
        "type": "bool"
      },
      {
        "internalType": "bytes",
        "name": "data",
        "type": "bytes"
      },
      {
        "internalType": "string",
        "name": "errorMessage",
        "type": "string"
      }
    ],
    "name": "propagateError",
    "outputs": [],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenB",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_amountADesired",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_amountBDesired",
        "type": "uint256"
      }
    ],
    "name": "quoteAddLiquidity",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenB",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_liquidity",
        "type": "uint256"
      }
    ],
    "name": "quoteRemoveLiquidity",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_hypervisor",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenA",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_liquidity",
        "type": "uint256"
      }
    ],
    "name": "quoteRemoveLiquidityGammaUniproxy",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract ISolidlyPair",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_router",
        "type": "address"
      }
    ],
    "name": "quoteStableAddLiquidityRatio",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "ratio1to0",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_inputToken",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_tokenInAmount",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "_token0",
        "type": "bytes"
      },
      {
        "internalType": "address",
        "name": "_outputToken",
        "type": "address"
      }
    ],
    "name": "swap",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes",
        "name": "_token0",
        "type": "bytes"
      },
      {
        "internalType": "address",
        "name": "_outputToken",
        "type": "address"
      }
    ],
    "name": "swapFromBNB",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_inputToken",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_tokenInAmount",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "_token0",
        "type": "bytes"
      },
      {
        "internalType": "bytes",
        "name": "_token1",
        "type": "bytes"
      },
      {
        "internalType": "enum PlanetZapOneInch.WantType",
        "name": "_type",
        "type": "uint8"
      },
      {
        "internalType": "address",
        "name": "_router",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      }
    ],
    "name": "zapIn",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes",
        "name": "_token0",
        "type": "bytes"
      },
      {
        "internalType": "bytes",
        "name": "_token1",
        "type": "bytes"
      },
      {
        "internalType": "enum PlanetZapOneInch.WantType",
        "name": "_type",
        "type": "uint8"
      },
      {
        "internalType": "address",
        "name": "_router",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      }
    ],
    "name": "zapInBNB",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_pair",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_withdrawAmount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_desiredToken",
        "type": "address"
      },
      {
        "internalType": "bytes",
        "name": "_dataToken0",
        "type": "bytes"
      },
      {
        "internalType": "bytes",
        "name": "_dataToken1",
        "type": "bytes"
      },
      {
        "internalType": "enum PlanetZapOneInch.WantType",
        "name": "_type",
        "type": "uint8"
      }
    ],
    "name": "zapOut",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
];


export const protocols:any = {
  protocols: [
  {
  id: "PANCAKESWAP",
  title: "Pancake Swap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/pancakeswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/pancakeswap_color.png",
  },
  {
  id: "VENUS",
  title: "Venus",
  img: "https://cdn.1inch.io/liquidity-sources-logo/venus.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/venus_color.png",
  },
  {
  id: "JULSWAP",
  title: "JulSwap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/julswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/julswap_color.png",
  },
  {
  id: "BAKERYSWAP",
  title: "Bakery Swap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/bakeryswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/bakeryswap_color.png",
  },
  {
  id: "BSC_ONE_INCH_LP",
  title: "1inch LP v1.1",
  img: "https://cdn.1inch.io/liquidity-sources-logo/1inch.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/1inch_color.png",
  },
  {
  id: "ACRYPTOS",
  title: "Acryptos",
  img: "https://cdn.1inch.io/liquidity-sources-logo/acryptos.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/acryptos_color.png",
  },
  {
  id: "BSC_DODO",
  title: "DODO",
  img: "https://cdn.1inch.io/liquidity-sources-logo/dodo.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/dodo_color.png",
  },
  {
  id: "APESWAP",
  title: "ApeSwap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/apeswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/apeswap_color.png",
  },
  {
  id: "SPARTAN_V2",
  title: "Spartan v2",
  img: "https://cdn.1inch.io/liquidity-sources-logo/spartan.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/spartan_color.png",
  },
  {
  id: "VSWAP",
  title: "Value Liquid vSwap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/valueliquid.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/valueliquid_color.png",
  },
  {
  id: "VPEGSWAP",
  title: "Value Liquid vPegSwap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/valueliquid.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/valueliquid_color.png",
  },
  {
  id: "HYPERSWAP",
  title: "Hyperjump",
  img: "https://cdn.1inch.io/liquidity-sources-logo/hyperjump.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/hyperjump_color.png",
  },
  {
  id: "BSC_DODO_V2",
  title: "DODO v2",
  img: "https://cdn.1inch.io/liquidity-sources-logo/dodo.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/dodo_color.png",
  },
  {
  id: "SWAPSWIPE",
  title: "Swap Swipe",
  img: "https://cdn.1inch.io/liquidity-sources-logo/swapswipe.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/swapswipe_color.png",
  },
  {
  id: "ELLIPSIS_FINANCE",
  title: "Ellipsis Finance",
  img: "https://cdn.1inch.io/liquidity-sources-logo/ellipsisfinance.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/ellipsisfinance_color.png",
  },
  {
  id: "BSC_NERVE",
  title: "Nerve Finance",
  img: "https://cdn.1inch.io/liquidity-sources-logo/nerve.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/nerve_color.png",
  },
  {
  id: "BSC_SMOOTHY_FINANCE",
  title: "Smoothy Finance",
  img: "https://cdn.1inch.io/liquidity-sources-logo/smoothy.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/smoothy_color.png",
  },
  {
  id: "CHEESESWAP",
  title: "Cheese Swap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/cheeseswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/cheeseswap_color.png",
  },
  {
  id: "PANCAKESWAP_V2",
  title: "Pancake Swap V2",
  img: "https://cdn.1inch.io/liquidity-sources-logo/pancakeswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/pancakeswap_color.png",
  },
  {
  id: "MDEX",
  title: "MDEX",
  img: "https://cdn.1inch.io/liquidity-sources-logo/mdex.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/mdex_color.png",
  },
  {
  id: "WARDEN",
  title: "Warden Swap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/warden.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/warden_color.png",
  },
  {
  id: "WAULTSWAP",
  title: "Wault Swap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/waultswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/waultswap_color.png",
  },
  {
  id: "BSC_ONE_INCH_LIMIT_ORDER",
  title: "1inch Limit Order Protocol",
  img: "https://cdn.1inch.io/liquidity-sources-logo/1inch.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/1inch_color.png",
  },
  {
  id: "BSC_ONE_INCH_LIMIT_ORDER_V2",
  title: "1inch Limit Order Protocol V2",
  img: "https://cdn.1inch.io/liquidity-sources-logo/1inch.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/1inch_color.png",
  },
  {
  id: "BSC_ONE_INCH_LIMIT_ORDER_V3",
  title: "1inch Limit Order Protocol V3",
  img: "https://cdn.1inch.io/liquidity-sources-logo/1inch.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/1inch_color.png",
  },
  {
  id: "BSC_PMM3",
  title: "PMM3",
  img: "https://cdn.1inch.io/liquidity-sources-logo/pmm.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/pmm_color.png",
  },
  {
  id: "ACSI_FINANCE",
  title: "Acsi Finance",
  img: "https://cdn.1inch.io/liquidity-sources-logo/acryptos.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/acryptos_color.png",
  },
  {
  id: "GAMBIT_FINANCE",
  title: "Gambit Finance",
  img: "https://cdn.1inch.io/liquidity-sources-logo/gambit.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/gambit_color.png",
  },
  {
  id: "JETSWAP",
  title: "JetSwap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/jetswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/jetswap_color.png",
  },
  {
  id: "BSC_UNIFI",
  title: "Unifi",
  img: "https://cdn.1inch.io/liquidity-sources-logo/unifi.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/unifi_color.png",
  },
  {
  id: "BSC_KYBER_DMM",
  title: "Kyber DMM",
  img: "https://cdn.1inch.io/liquidity-sources-logo/kyber.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/kyber_color.png",
  },
  {
  id: "BSC_BI_SWAP",
  title: "biswap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/biswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/biswap_color.png",
  },
  {
  id: "BSC_DOPPLE",
  title: "Dopple",
  img: "https://cdn.1inch.io/liquidity-sources-logo/dopple.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/dopple_color.png",
  },
  {
  id: "BABYSWAP",
  title: "BabySwap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/babyswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/babyswap_color.png",
  },
  {
  id: "BSC_PMM2MM",
  title: "PMM2",
  img: "https://cdn.1inch.io/liquidity-sources-logo/pmm.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/pmm_color.png",
  },
  {
  id: "WOOFI",
  title: "WOOFi",
  img: "https://cdn.1inch.io/liquidity-sources-logo/woofi.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/woofi_color.png",
  },
  {
  id: "BSC_ELK",
  title: "ELK",
  img: "https://cdn.1inch.io/liquidity-sources-logo/elk.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/elk_color.png",
  },
  {
  id: "BSC_SYNAPSE",
  title: "Synapse",
  img: "https://cdn.1inch.io/liquidity-sources-logo/synapse.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/synapse_color.png",
  },
  {
  id: "BSC_AUTOSHARK",
  title: "Autoshark",
  img: "https://cdn.1inch.io/liquidity-sources-logo/autoshark.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/autoshark_color.png",
  },
  {
  id: "BSC_CAFE_SWAP",
  title: "Cafe Swap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/cafe_swap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/cafe_swap_color.png",
  },
  {
  id: "PLANET_FINANCE",
  title: "PlanetFinance",
  img: "https://cdn.1inch.io/liquidity-sources-logo/planetfinance.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/planetfinance_color.png",
  },
  {
  id: "BSC_ANNEX_FINANCE",
  title: "AnnexFinance",
  img: "https://cdn.1inch.io/liquidity-sources-logo/annex.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/annex_color.png",
  },
  {
  id: "BSC_ANNEX_SWAP",
  title: "AnnexSwap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/annex.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/annex_color.png",
  },
  {
  id: "BSC_RADIOSHACK",
  title: "RadioShack",
  img: "https://cdn.1inch.io/liquidity-sources-logo/radioshack.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/radioshack_color.png",
  },
  {
  id: "BSC_KYBERSWAP_ELASTIC",
  title: "KyberSwap Elastic",
  img: "https://cdn.1inch.io/liquidity-sources-logo/kyber.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/kyber_color.png",
  },
  {
  id: "BSC_FSTSWAP",
  title: "FstSwap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/fstswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/fstswap_color.png",
  },
  {
  id: "BSC_NOMISWAP",
  title: "Nomiswap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/nomiswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/nomiswap_color.png",
  },
  {
  id: "BSC_CONE",
  title: "Cone",
  img: "https://cdn.1inch.io/liquidity-sources-logo/cano.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/cano_color.png",
  },
  {
  id: "BSC_KYBER_DMM_STATIC",
  title: "Kyber DMM Static",
  img: "https://cdn.1inch.io/liquidity-sources-logo/kyber.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/kyber_color.png",
  },
  {
  id: "WOMBATSWAP",
  title: "Wombat",
  img: "https://cdn.1inch.io/liquidity-sources-logo/wombat.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/wombat_color.png",
  },
  {
  id: "BSC_NOMISWAP_STABLE",
  title: "Nomiswap Stable",
  img: "https://cdn.1inch.io/liquidity-sources-logo/nomiswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/nomiswap_color.png",
  },
  {
  id: "BSC_PANCAKESWAP_STABLE",
  title: "Pancake Stable",
  img: "https://cdn.1inch.io/liquidity-sources-logo/pancakeswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/pancakeswap_color.png",
  },
  {
  id: "BSC_BABYDOGE",
  title: "Babydoge",
  img: "https://cdn.1inch.io/liquidity-sources-logo/babydoge.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/babydoge_color.png",
  },
  {
  id: "BSC_THENA",
  title: "THENA",
  img: "https://cdn.1inch.io/liquidity-sources-logo/thena.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/thena_color.png",
  },
  {
  id: "BSC_WOOFI_V2",
  title: "WOOFi V2",
  img: "https://cdn.1inch.io/liquidity-sources-logo/woofi.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/woofi_color.png",
  },
  {
  id: "BSC_KYOTOSWAP",
  title: "Kyotoswap",
  img: "https://cdn.1inch.io/liquidity-sources-logo/kyotoswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/kyotoswap_color.png",
  },
  {
  id: "BSC_TRADERJOE",
  title: "TraderJoe",
  img: "https://cdn.1inch.io/liquidity-sources-logo/traderjoe.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/traderjoe_color.png",
  },
  {
  id: "BSC_TRADERJOE_V2",
  title: "TraderJoe V2",
  img: "https://cdn.1inch.io/liquidity-sources-logo/traderjoe.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/traderjoe_color.png",
  },
  {
  id: "BSC_UNISWAP_V3",
  title: "Uniswap V3",
  img: "https://cdn.1inch.io/liquidity-sources-logo/uniswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/uniswap_color.png",
  },
  {
  id: "BSC_TRIDENT",
  title: "Trident",
  img: "https://cdn.1inch.io/liquidity-sources-logo/sushiswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/sushiswap_color.png",
  },
  {
  id: "BSC_MAVERICK_V1",
  title: "Maverick V1",
  img: "https://cdn.1inch.io/liquidity-sources-logo/maverick.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/maverick_color.png",
  },
  {
  id: "BSC_PANCAKESWAP_V3",
  title: "Pancake Swap V3",
  img: "https://cdn.1inch.io/liquidity-sources-logo/pancakeswap.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/pancakeswap_color.png",
  },
  {
  id: "BSC_THENA_V3",
  title: "THENA V2",
  img: "https://cdn.1inch.io/liquidity-sources-logo/thena.png",
  img_color: "https://cdn.1inch.io/liquidity-sources-logo/thena_color.png",
  },
  ]
}

export const thena_router_address = "0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109"

export const thena_router_abi = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_factory",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_wETH",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "sender",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount0In",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "_tokenIn",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      }
    ],
    "name": "Swap",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "uint256[]",
        "name": "amounts",
        "type": "uint256[]"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "stable",
            "type": "bool"
          }
        ],
        "internalType": "struct RouterV2.route[]",
        "name": "routes",
        "type": "tuple[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "UNSAFE_swapExactTokensForTokens",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "amountADesired",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountBDesired",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountAMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountBMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "addLiquidity",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "amountTokenDesired",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountTokenMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "addLiquidityETH",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountToken",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETH",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      }
    ],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "factory",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "tokenIn",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenOut",
        "type": "address"
      }
    ],
    "name": "getAmountOut",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "stable",
            "type": "bool"
          }
        ],
        "internalType": "struct RouterV2.route[]",
        "name": "routes",
        "type": "tuple[]"
      }
    ],
    "name": "getAmountsOut",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "amounts",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      }
    ],
    "name": "getReserves",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "reserveA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "reserveB",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "pair",
        "type": "address"
      }
    ],
    "name": "isPair",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      }
    ],
    "name": "pairFor",
    "outputs": [
      {
        "internalType": "address",
        "name": "pair",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "amountADesired",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountBDesired",
        "type": "uint256"
      }
    ],
    "name": "quoteAddLiquidity",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      }
    ],
    "name": "quoteRemoveLiquidity",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountAMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountBMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "removeLiquidity",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountTokenMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "removeLiquidityETH",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountToken",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETH",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountTokenMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "removeLiquidityETHSupportingFeeOnTransferTokens",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountToken",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETH",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountTokenMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "approveMax",
        "type": "bool"
      },
      {
        "internalType": "uint8",
        "name": "v",
        "type": "uint8"
      },
      {
        "internalType": "bytes32",
        "name": "r",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "s",
        "type": "bytes32"
      }
    ],
    "name": "removeLiquidityETHWithPermit",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountToken",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETH",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountTokenMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "approveMax",
        "type": "bool"
      },
      {
        "internalType": "uint8",
        "name": "v",
        "type": "uint8"
      },
      {
        "internalType": "bytes32",
        "name": "r",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "s",
        "type": "bytes32"
      }
    ],
    "name": "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountToken",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETH",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountAMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountBMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "approveMax",
        "type": "bool"
      },
      {
        "internalType": "uint8",
        "name": "v",
        "type": "uint8"
      },
      {
        "internalType": "bytes32",
        "name": "r",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "s",
        "type": "bytes32"
      }
    ],
    "name": "removeLiquidityWithPermit",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
      }
    ],
    "name": "sortTokens",
    "outputs": [
      {
        "internalType": "address",
        "name": "token0",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "token1",
        "type": "address"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "stable",
            "type": "bool"
          }
        ],
        "internalType": "struct RouterV2.route[]",
        "name": "routes",
        "type": "tuple[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactETHForTokens",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "amounts",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "stable",
            "type": "bool"
          }
        ],
        "internalType": "struct RouterV2.route[]",
        "name": "routes",
        "type": "tuple[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactETHForTokensSupportingFeeOnTransferTokens",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "stable",
            "type": "bool"
          }
        ],
        "internalType": "struct RouterV2.route[]",
        "name": "routes",
        "type": "tuple[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactTokensForETH",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "amounts",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "stable",
            "type": "bool"
          }
        ],
        "internalType": "struct RouterV2.route[]",
        "name": "routes",
        "type": "tuple[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactTokensForETHSupportingFeeOnTransferTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "stable",
            "type": "bool"
          }
        ],
        "internalType": "struct RouterV2.route[]",
        "name": "routes",
        "type": "tuple[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactTokensForTokens",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "amounts",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "tokenFrom",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenTo",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "stable",
        "type": "bool"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactTokensForTokensSimple",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "amounts",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "stable",
            "type": "bool"
          }
        ],
        "internalType": "struct RouterV2.route[]",
        "name": "routes",
        "type": "tuple[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactTokensForTokensSupportingFeeOnTransferTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "wETH",
    "outputs": [
      {
        "internalType": "contract IWETH",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
]

export const gammaUniproxyRouter = '0xf75c017e3b023a593505e281b565ed35cc120efa';

export const gammaUniproxyRouterABI = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_clearance",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [],
    "name": "clearance",
    "outputs": [
      {
        "internalType": "contract IClearing",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "deposit0",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "deposit1",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "pos",
        "type": "address"
      },
      {
        "internalType": "uint256[4]",
        "name": "minIn",
        "type": "uint256[4]"
      }
    ],
    "name": "deposit",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "shares",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "pos",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_deposit",
        "type": "uint256"
      }
    ],
    "name": "getDepositAmount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountStart",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountEnd",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newClearance",
        "type": "address"
      }
    ],
    "name": "transferClearance",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]

export const hypervisor_abi = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_pool",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_owner",
        "type": "address"
      },
      {
        "internalType": "string",
        "name": "name",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "symbol",
        "type": "string"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "sender",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "shares",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount0",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount1",
        "type": "uint256"
      }
    ],
    "name": "Deposit",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "int24",
        "name": "tick",
        "type": "int24"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "totalAmount0",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "totalAmount1",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "feeAmount0",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "feeAmount1",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "totalSupply",
        "type": "uint256"
      }
    ],
    "name": "Rebalance",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint8",
        "name": "newFee",
        "type": "uint8"
      }
    ],
    "name": "SetFee",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "sender",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "shares",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount0",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount1",
        "type": "uint256"
      }
    ],
    "name": "Withdraw",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint8",
        "name": "fee",
        "type": "uint8"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "fees0",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "fees1",
        "type": "uint256"
      }
    ],
    "name": "ZeroBurn",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "DOMAIN_SEPARATOR",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "PRECISION",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "int24",
        "name": "tickLower",
        "type": "int24"
      },
      {
        "internalType": "int24",
        "name": "tickUpper",
        "type": "int24"
      },
      {
        "internalType": "uint256",
        "name": "amount0",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount1",
        "type": "uint256"
      },
      {
        "internalType": "uint256[2]",
        "name": "inMin",
        "type": "uint256[2]"
      }
    ],
    "name": "addLiquidity",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amount0",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount1",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "data",
        "type": "bytes"
      }
    ],
    "name": "algebraMintCallback",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "baseLower",
    "outputs": [
      {
        "internalType": "int24",
        "name": "",
        "type": "int24"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "baseUpper",
    "outputs": [
      {
        "internalType": "int24",
        "name": "",
        "type": "int24"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256[4]",
        "name": "inMin",
        "type": "uint256[4]"
      }
    ],
    "name": "compound",
    "outputs": [
      {
        "internalType": "uint128",
        "name": "baseToken0Owed",
        "type": "uint128"
      },
      {
        "internalType": "uint128",
        "name": "baseToken1Owed",
        "type": "uint128"
      },
      {
        "internalType": "uint128",
        "name": "limitToken0Owed",
        "type": "uint128"
      },
      {
        "internalType": "uint128",
        "name": "limitToken1Owed",
        "type": "uint128"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "currentTick",
    "outputs": [
      {
        "internalType": "int24",
        "name": "tick",
        "type": "int24"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "decimals",
    "outputs": [
      {
        "internalType": "uint8",
        "name": "",
        "type": "uint8"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "subtractedValue",
        "type": "uint256"
      }
    ],
    "name": "decreaseAllowance",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "deposit0",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "deposit1",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "uint256[4]",
        "name": "inMin",
        "type": "uint256[4]"
      }
    ],
    "name": "deposit",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "shares",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "deposit0Max",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "deposit1Max",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "directDeposit",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "fee",
    "outputs": [
      {
        "internalType": "uint8",
        "name": "",
        "type": "uint8"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "feeRecipient",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getBasePosition",
    "outputs": [
      {
        "internalType": "uint128",
        "name": "liquidity",
        "type": "uint128"
      },
      {
        "internalType": "uint256",
        "name": "amount0",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount1",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getLimitPosition",
    "outputs": [
      {
        "internalType": "uint128",
        "name": "liquidity",
        "type": "uint128"
      },
      {
        "internalType": "uint256",
        "name": "amount0",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount1",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getTotalAmounts",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "total0",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "total1",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "addedValue",
        "type": "uint256"
      }
    ],
    "name": "increaseAllowance",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "limitLower",
    "outputs": [
      {
        "internalType": "int24",
        "name": "",
        "type": "int24"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "limitUpper",
    "outputs": [
      {
        "internalType": "int24",
        "name": "",
        "type": "int24"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "maxTotalSupply",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "name",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "nonces",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      },
      {
        "internalType": "uint8",
        "name": "v",
        "type": "uint8"
      },
      {
        "internalType": "bytes32",
        "name": "r",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "s",
        "type": "bytes32"
      }
    ],
    "name": "permit",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "pool",
    "outputs": [
      {
        "internalType": "contract IAlgebraPool",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "int24",
        "name": "tickLower",
        "type": "int24"
      },
      {
        "internalType": "int24",
        "name": "tickUpper",
        "type": "int24"
      },
      {
        "internalType": "uint128",
        "name": "shares",
        "type": "uint128"
      },
      {
        "internalType": "uint256[2]",
        "name": "amountMin",
        "type": "uint256[2]"
      }
    ],
    "name": "pullLiquidity",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amount0",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount1",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "int24",
        "name": "_baseLower",
        "type": "int24"
      },
      {
        "internalType": "int24",
        "name": "_baseUpper",
        "type": "int24"
      },
      {
        "internalType": "int24",
        "name": "_limitLower",
        "type": "int24"
      },
      {
        "internalType": "int24",
        "name": "_limitUpper",
        "type": "int24"
      },
      {
        "internalType": "address",
        "name": "_feeRecipient",
        "type": "address"
      },
      {
        "internalType": "uint256[4]",
        "name": "inMin",
        "type": "uint256[4]"
      },
      {
        "internalType": "uint256[4]",
        "name": "outMin",
        "type": "uint256[4]"
      }
    ],
    "name": "rebalance",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "removeWhitelisted",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint8",
        "name": "newFee",
        "type": "uint8"
      }
    ],
    "name": "setFee",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_address",
        "type": "address"
      }
    ],
    "name": "setWhitelist",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tickSpacing",
    "outputs": [
      {
        "internalType": "int24",
        "name": "",
        "type": "int24"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "toggleDirectDeposit",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "token0",
    "outputs": [
      {
        "internalType": "contract IERC20",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "token1",
    "outputs": [
      {
        "internalType": "contract IERC20",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "recipient",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "sender",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "recipient",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "transferFrom",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "whitelistedAddress",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "shares",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "uint256[4]",
        "name": "minAmounts",
        "type": "uint256[4]"
      }
    ],
    "name": "withdraw",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amount0",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount1",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]