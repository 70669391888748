import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchInfVaultData } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont, FlexCont, PlanetYellowBtn, MaxBtn } from 'shared/styles/styled';
import { ModalCont, ModalCard, ModalTitle, ModalInputCont, ModalInput, ExchangeRateBox, ModalText, ModalBText, ModalInfoText, UnderstandFeeBox, UnderstandText, UnderstandCheckBox, CrossButton } from './style'
import { colors } from 'shared/styles/theme';
import CloseIcon from 'assets/icons/white-cross.svg'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import InstantSmIcon from 'assets/icons/inst-icon.svg'
import InstantBlackIcon from 'assets/icons/inst-black.svg'
import { transLinkUrl } from 'service/global-constant'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { commaFy, getNumFormat } from 'service/globalFunctions'
import Spinner from 'shared/spinner'
import { unstake_instantly } from 'modules/block-chain/BlockChainMethods'

const InstantUnStake = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [sendAmount, setSendAmount] = useState('')
    const [amtReceive, setAmtReceive] = useState<any>('0')
    const [loading, setLoading] = useState(false)
    const [isUnderstand, setIsUnderstand] = useState(true)
    const { token, setShowInstUnStake, data } = props

    const handleSendAmount = async (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setSendAmount(value)
            getMinReceive(value)
        }
        if (!value) {
            setSendAmount('')
            getMinReceive(0)
        }
    }

    const getMinReceive = (val: any) => {
        if (!!val && parseFloat(val) > 0) {
            const minReceiveVal: any = parseFloat(val) - (parseFloat(val) * 0.05)
            setAmtReceive(parseFloat(minReceiveVal))
        }
        else {
            setAmtReceive('0')
        }
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `Amount ${sendAmount} Unstaked` : `Failed to Unstake`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleInstantUnStake = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(sendAmount) > 0) {
            const userAddress = selector.ethData.address
            try {
                setLoading(true)
                // console.log("sendAmount inside handleInstantUnStake", typeof sendAmount, sendAmount)
                const res: any = await unstake_instantly(userAddress, data.infinity_vault_address, data.infinityAbi, sendAmount)
                handleNotification('success', res.transactionHash)
                dispatch(fetchInfVaultData(userAddress, false))
                setShowInstUnStake(false)
            }
            catch (error) {
                console.log("error==>", error)
                handleNotification('failed')
            }
            finally {
                setLoading(false)
            }
        }
    }

    const getExchangeRate = () => {
        let exchangeVal: any = '0'
        if (!!sendAmount && !!data && data.exchangeRate) {
            if (parseFloat(data.exchangeRate) > 0) {
                exchangeVal = parseFloat(sendAmount) / parseFloat(data.exchangeRate)
            }
        }
        return parseFloat(exchangeVal).toFixed(4)
    }

    const handleMax = () => {
        if (!!data && data.gtoken_user_can_unstake) {
            // console.log("data.gtoken_user_can_unstake", typeof data.gtoken_user_can_unstake, data.gtoken_user_can_unstake)
            const maxAmt: any = data.gtoken_user_can_unstake;//getNumFormat(data.gtoken_user_can_unstake)
            // console.log("type of max amount", typeof maxAmt, maxAmt)
            setSendAmount(maxAmt)
            getMinReceive(data.gtoken_user_can_unstake)
        }
        else {
            setSendAmount('0')
            getMinReceive(0)
        }

    }

    return (
        <ModalCont>
            <ModalCard>
                <FlexSBCont>
                    <ModalTitle>Instant Unstake</ModalTitle>
                    <CrossButton onClick={() => setShowInstUnStake(false)} >+</CrossButton>
                </FlexSBCont>
                <ModalInputCont isActive={!!sendAmount && parseFloat(sendAmount) > 0} colorVal={colors.yellow}>
                    <FlexSBCont className="bottom-space">
                        <ModalText>Amount</ModalText>
                        <ModalText>{`Balance: ${!!data && data.gtoken_staked ? commaFy(parseFloat(data.gtoken_staked).toFixed(4)) : '0.0000'}`}</ModalText>
                    </FlexSBCont>
                    <FlexCont>
                        <ModalInput
                            placeholder="0"
                            onChange={handleSendAmount}
                            value={sendAmount && sendAmount}
                        />
                        <FlexCont>
                            <MaxBtn className="inst-unStake-max" onClick={() => handleMax()}>MAX</MaxBtn>
                            <img src={token === 'gAQUA' ? AQUAIcon : GAMMAIcon} alt="" />
                            <ModalBText>{token === 'gAQUA' ? "gAQUA" : "gGAMMA"}</ModalBText>
                        </FlexCont>
                    </FlexCont>
                </ModalInputCont>
                <ExchangeRateBox>{`= ${getExchangeRate()} ${token === 'gAQUA' ? 'AQUA' : 'GAMMA'}`}</ExchangeRateBox>
                {/* <UnderstandFeeBox>
                    <UnderstandCheckBox isActive={isUnderstand} isCursorShow={!!sendAmount && parseFloat(sendAmount) > 0} onClick={() => !!sendAmount && parseFloat(sendAmount) > 0 && setIsUnderstand(!isUnderstand)}>
                        {isUnderstand && <img src={InstantBlackIcon} alt="" />}
                    </UnderstandCheckBox>
                    <UnderstandText>I understand the fees</UnderstandText>
                </UnderstandFeeBox> */}
                <PlanetYellowBtn className="inst-confirm-btn" disabled={!sendAmount || parseFloat(sendAmount) <= 0 || loading || !isUnderstand} onClick={() => handleInstantUnStake()}>{loading ? <Spinner /> : 'Confirm'}</PlanetYellowBtn>
            </ModalCard>
            <ModalCard className="top-space">
                <FlexSBCont >
                    <ModalInfoText light>Unstaking time</ModalInfoText>
                    <FlexCont>
                        <img className="inst-icon" src={InstantSmIcon} alt="" />
                        <ModalInfoText>Instantly</ModalInfoText>
                    </FlexCont>

                </FlexSBCont>
                <FlexSBCont className="info-space">
                    <ModalInfoText light>Instant Unstake Penalty</ModalInfoText>
                    <ModalInfoText>{!!data && data.infinity_vault_instant_withdraw_fee ? data.infinity_vault_instant_withdraw_fee : 0}%</ModalInfoText>
                </FlexSBCont>
                <FlexSBCont className="info-space">
                    <ModalInfoText light>Amount To Receive</ModalInfoText>
                    <ModalInfoText>{parseFloat(amtReceive).toFixed(4)}</ModalInfoText>
                </FlexSBCont>
            </ModalCard>

        </ModalCont>
    );
};

export default InstantUnStake;