import { useState, useEffect} from 'react'
import { useSelector, useDispatch, } from 'react-redux'
import { FlexSBCont, FlexCont } from 'shared/styles/styled'
import { TransModalCont, TransCard, TransTabTxt, TransTxt, TransInoTxt } from 'shared/styles/highestApyStyle'
import SettingIcon from 'assets/icons/settings.png'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import CustomModal from 'shared/custom-modal'
import Settings from 'shared/settings'
import TransformAction from './TransformAction'
import ConvertLpAction from './ConvertLpAction'
import WithdrawAndZapOut from './WithdrawAndZapOut'


const PoolTCAction = (props: any) => {
    const selector = useSelector((state: any) => state)
    const { setShowTCAModal, transData, type, poolTable, zapTokenAmount, zapTokenObject, setShowPoolDetails, key, gammaBtcbPool, setTrxPopUpOverlay, trxPopUpOverlay} = props
    const [activeTab, setActiveTab] = useState(props.type)
    const [transInfo, setTransInfo] = useState<any>({})
    const [conLpInfo, setConLpInfo] = useState<any>({})
    const [slippageVal, setSlippageVal] = useState(0.5)
    const [deadLine, setDeadLine] = useState(20)
    const [showSettings, setShowSettings] = useState(false)
    const [gammaBtcbTransInfo, setGammaBtcbTransInfo] = useState<any>({})
    const [lock_time, set_lock_time] = useState(1);

    
    const { poolData, poolLoadingNew, portfolioLoading, poolLoading} = selector.user
    const [priceImpact, setPriceImpact] = useState<any>('0.00')
    const [lpData, setLPData] = useState<any>(transData)

    const handleSlippage = (newSlippageVal: number) => {
        setSlippageVal(newSlippageVal)
    }

    const handleTabSelect = (tabName: string) => {
        // console.log("transform data", transData)
        if (tabName !== activeTab) {
            setActiveTab(tabName)
            setSlippageVal(0.5)
            setDeadLine(20)
            setTransInfo({})
            setConLpInfo({})
            setGammaBtcbTransInfo({})
        }
    }

    useEffect(()=>{
        // console.log("transform data", transData, gammaBtcbPool)
        // if(type == 'transform'){
        //     setActiveTab('transform')
        // } else {
        //     setActiveTab('convertLp')
        // }
        
        // console.log("checking loading state of pools", activeTab, transData, props)
        if (poolLoading == false && portfolioLoading == false) {
            // console.log("loading is complete", poolLoading, portfolioLoading)
            const dataArr = poolData.newPools;
            if (!!dataArr && Object.values(dataArr).length > 0) {
                let lpObject = dataArr.active.filter((e: any) => e.strategyAddress.toLowerCase() == transData.strategyAddress.toLowerCase())
                // lpObject[0].walletBalanceUSD = "10";
                // console.log("lp object with new data", lpObject)
                
                setLPData(lpObject[0])
            }
        } else {
            // console.log("still in loading state!")
        }

    }, [poolLoading, portfolioLoading])

    const getHeadingText = () => {
        if(type == 'transform') {
            if(poolTable != undefined && poolTable)
                return "Zap In & Stake" 
            else
                return "Transform"
        }
        else {
            if(poolTable != undefined && poolTable)
                return "Withdraw & Zap Out" 
            else
                return "Convert LP"
        }
    }

    const getDescriptionText = () => {
        if(type == 'transform') {
            if(poolTable != undefined && poolTable)
                return "One-click to convert your crypto to LP Token and Stake" 
            else
                return "One-click to convert your crypto to LP Token"
        }
        else {
            if(poolTable != undefined && poolTable)
                return "One-click convert your staked LP token to crypto" 
            else
                return "One-click convert your LP token"
        }
    }

    return (
        <TransModalCont className='poolTrxModal'>
            <TransCard className='poolTrxCont'>
                <FlexSBCont>
                    <FlexCont>
                        <TransTabTxt className="TransTabTitle" isActive={true} onClick={() => type == 'transform' ? handleTabSelect('transform') : handleTabSelect('convertLp')}>
                            {type == 'transform' ? transData.poolId == 2? "Swap and Stake": "Zap In & Stake" :(poolTable != undefined && poolTable)? "Withdraw & Zap Out" : "Convert LP"}
                        </TransTabTxt>
                        {/* <TransTabTxt isActive={activeTab === 'convertLp'} onClick={() => handleTabSelect('convertLp')}>c</TransTabTxt> */}
                    </FlexCont>
                    <FlexCont>
                        <img className='trans-icon' src={SettingIcon} alt="" onClick={() => setShowSettings(true)} />
                        <img className='trans-icon' src={CrossIcon} alt="" onClick={() => setShowTCAModal(false)} />
                    </FlexCont>
                </FlexSBCont>
                {/* <TransTxt light className="trans-info">
                    {
                        getDescriptionText()
                    }
                </TransTxt> */}
                {activeTab === 'transform' ?
                    <TransformAction data={transData} transInfo={transInfo} setTransInfo={setTransInfo} slippageVal={slippageVal} setPriceImpact={setPriceImpact} key={transData.walletBalanceUSD || transData.vaultBalanceUSD} zapTokenAmount={zapTokenAmount} zapTokenObject={zapTokenObject} setShowPoolDetails={setShowPoolDetails} setShowTCAModal={setShowTCAModal} gammaBtcbPool={gammaBtcbPool} gammaBtcbTransInfo={gammaBtcbTransInfo} setGammaBtcbTransInfo={setGammaBtcbTransInfo} setTrxPopUpOverlay={setTrxPopUpOverlay} trxPopUpOverlay={trxPopUpOverlay} set_lock_time={set_lock_time} lock_time={lock_time} /> :
                    poolTable != undefined && poolTable? 
                        <WithdrawAndZapOut data={transData} conLpInfo={conLpInfo} setConLpInfo={setConLpInfo} slippageVal={slippageVal} key={transData.walletBalanceUSD || transData.vaultBalanceUSD} zapTokenAmount={zapTokenAmount} zapTokenObject={zapTokenObject}  setShowPoolDetails={setShowPoolDetails} setShowTCAModal={setShowTCAModal}/>
                        :
                        <ConvertLpAction data={transData} conLpInfo={conLpInfo} setConLpInfo={setConLpInfo} slippageVal={slippageVal} key={transData.walletBalanceUSD || transData.vaultBalanceUSD} zapTokenAmount={zapTokenAmount} zapTokenObject={zapTokenObject}  setShowPoolDetails={setShowPoolDetails} setShowTCAModal={setShowTCAModal}/>
                    
                }
            </TransCard>
            <TransCard className='info-card-cont'>
                {activeTab === 'transform' && transData.poolId < 3 &&
                    <FlexSBCont className='info-space'>
                        <TransInoTxt className="info-text" light>Lock Time</TransInoTxt>
                        <TransInoTxt className="info-text-val">{transData.name.toLowerCase() == "gamma-btcb" || lock_time == 1 ? "180 days" : lock_time == 0 ? "90 days" : "360 days"}</TransInoTxt>
                    </FlexSBCont>
                }
                {(activeTab === 'transform' || activeTab === 'convertLp') &&
                    <FlexSBCont className='info-space'>
                        <TransInoTxt className="info-text" light>Slippage Tolerance</TransInoTxt>
                        <TransInoTxt className="info-text-val">{slippageVal}%</TransInoTxt>
                    </FlexSBCont>
                }
                {activeTab === 'convertLp' &&
                    <FlexSBCont className='info-space'>
                        <TransInoTxt className="info-text" light>Price Impact</TransInoTxt>
                        <TransInoTxt className="info-text-val">{conLpInfo.priceImpact? parseFloat(conLpInfo.priceImpact).toFixed(2) : 0}%</TransInoTxt>
                    </FlexSBCont>
                }
                {activeTab === 'transform' &&
                    <FlexSBCont className='info-space'>
                        <TransInoTxt className="info-text" light>Price Impact</TransInoTxt>
                        <TransInoTxt className="info-text-val">{transInfo.priceImpact? parseFloat(transInfo.priceImpact).toFixed(2) : 0}%</TransInoTxt>
                    </FlexSBCont>
                }
                {activeTab === 'transform' && 
                    <FlexSBCont className='info-space'>
                        <TransInoTxt className="info-text" light>Minimum Received</TransInoTxt>
                        <TransInoTxt className="info-text-val">{!!transInfo && transInfo.minLiquidity ? `${parseFloat(transInfo.minLiquidity).toFixed(6)} LP` : '0 LP'}</TransInoTxt>
                    </FlexSBCont>
                }
                {activeTab === 'convertLp' && 
                    <FlexSBCont className='info-space'>
                        <TransInoTxt className="info-text" light>Minimum Received</TransInoTxt>
                        <TransInoTxt className="info-text-val">{!!conLpInfo && conLpInfo.minLiquidity ? `${parseFloat(conLpInfo.minLiquidity).toFixed(6)} LP` : '0 LP'}</TransInoTxt>
                    </FlexSBCont>
                }
                {activeTab === 'transform' &&
                    <FlexSBCont className='info-space'>
                        <TransInoTxt className="info-text" light>LP Share</TransInoTxt>
                        <TransInoTxt className="info-text-val">{transInfo.liquidity ? parseFloat(transInfo.liquidity).toFixed(2) : 0}%</TransInoTxt>
                    </FlexSBCont>
                }

                {activeTab === 'transform' && transData.name.toLowerCase() !== "gamma-btcb" && transData.zapInGammaBtcb &&
                    <FlexSBCont className='info-space'>
                        <TransInoTxt className="info-text" light>Minimum Received GAMMA-BTCB</TransInoTxt>
                        <TransInoTxt className="info-text-val">{!!gammaBtcbTransInfo && gammaBtcbTransInfo.minLiquidity != undefined ? `${parseFloat(gammaBtcbTransInfo.minLiquidity).toFixed(6)} LP` : '0 LP'}</TransInoTxt>
                    </FlexSBCont>
                }

                {activeTab === 'transform' && transData.name.toLowerCase() !== "gamma-btcb" && transData.zapInGammaBtcb &&
                    <FlexSBCont className='info-space'>
                        <TransInoTxt className="info-text" light>LP Share of GAMMA-BTCB</TransInoTxt>
                        <TransInoTxt className="info-text-val">{priceImpact? parseFloat(priceImpact).toFixed(2) : 0}%</TransInoTxt>
                    </FlexSBCont>
                }

                {activeTab === 'transform' && transData.name.toLowerCase() !== "gamma-btcb" && transData.zapInGammaBtcb &&
                    <FlexSBCont className='info-space'>
                        <TransInoTxt className="info-text" light>Lock time for GAMMA-BTCB</TransInoTxt>
                        <TransInoTxt className="info-text-val">180 Days</TransInoTxt>
                    </FlexSBCont>
                }

                {/* <FlexSBCont className={activeTab === 'transform' ? 'info-space' : ''}>
                    <TransInoTxt light>Minimum Received</TransInoTxt>
                    {activeTab === 'transform' ?
                        <TransInoTxt>{!!transInfo && transInfo.minLiquidity ? `${parseFloat(transInfo.minLiquidity).toFixed(6)} LP` : '0 LP'}</TransInoTxt> :
                        <TransInoTxt>{!!conLpInfo && conLpInfo.minReceived ? `${parseFloat(conLpInfo.minReceived).toFixed(6)}` : 0}</TransInoTxt>
                    }
                </FlexSBCont> */}
                {/*activeTab === 'transform' &&
                    <FlexSBCont>
                        <TransInoTxt light>LP Share</TransInoTxt>
                        {activeTab === 'transform' ?
                            <TransInoTxt>{!!transInfo && transInfo.share ? `${transInfo.share < 0.01 ? '<0.01' : parseFloat(transInfo.share).toFixed(6)}%` : '0%'}</TransInoTxt> :
                            <TransInoTxt>0</TransInoTxt>
                        }
                    </FlexSBCont>
                */}
            </TransCard>
            <CustomModal show={showSettings} toggleModal={setShowSettings}>
                {showSettings && (
                    <Settings
                        setShowSettings={setShowSettings}
                        slippageVal={slippageVal}
                        setSlippageVal={setSlippageVal}
                        handleSlippage={handleSlippage}
                        // deadLine={deadLine}
                        // setDeadLine={setDeadLine}
                    />
                )}
            </CustomModal>
        </TransModalCont>
    );
};

export default PoolTCAction