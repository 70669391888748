import { useState, useEffect } from 'react'
import { FlexSBCont } from 'shared/styles/styled'
import { ExpandedCell, ExpendCellTitle, TableInfoRows, TableInfoBox, TableInfoTxt, CellDownIcon } from 'shared/styles/planetTableStyle'
import DownIcon from 'assets/icons/down.svg'
import { commaFy } from 'service/globalFunctions'
import InfoTooltip from 'shared/info-tool-tip'
import { FlexCont } from './styles'
import whiteCrossIcon from 'assets/icons/white-cross.svg'
import {TitleText, InfoLDText, TokenValInput} from './styles'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import BTCBIcon from 'assets/icons/BTCB.svg'
import { MainContainer, CardButton2 } from 'shared/styles/styled'
import Spinner from 'shared/spinner';
import { balanceOf, claimGammaRewardsForPools } from 'modules/block-chain/BlockChainMethods';
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions';
import { updateUserLendingData } from 'logic/action/lending.action';
import { transLinkUrl } from 'service/global-constant';
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { BTCBAddress, convertToBoostABI, convertToBoostAddress, gBTCBAddress, gammaFarmV3, gammaFarmV3Abi, v3_strategy_abi, vestingPoolStrategyAddress } from 'modules/block-chain/abi'
import wallet from 'modules/wallet/wallet'
import { convertToEther, convertToWei, gammaAddress, maxAmount } from 'modules/block-chain/lendingAbi'
import { returnTokenPriceMulticall, tokenSwapList } from 'modules/block-chain/tokenList'
import BigNumber from 'bignumber.js'
import { gBnbAbi } from 'modules/block-chain-green_v1/lendingAbi'
import { BoostDetailsCard } from 'modules/liquidStaking/style'
import { faLessThan } from '@fortawesome/free-solid-svg-icons'

const CollectRewards = (props: any) => {
    const selector = useSelector((state: any) => state)
    const [gammaAmountForStake, setGammaAmountForStake] = useState<any>('0')
    const [gammaDecimalsForStake, setGammaDecimalsForStake] = useState<any>('0000')
    const [gammaAmountForClaim, setGammaAmountForClaim] = useState<any>('0')
    const [gammaDecimalsForClaim, setGammaDecimalsForClaim] = useState<any>('0000')
    const [showFeeInfo, setShowFeeInfo] = useState(false)
    const [showAPYCal, setShowAPyCal] = useState(false)
    const [claimAndStakeLoading, setClaimAndStakeLoading] = useState(false)
    const [claimLoading, setClaimLoading] = useState(false)
    const [btcbPercentage, setBtcbPercentage] = useState<any>('0')
    const [collectGammaBtn, setCollectGammaBtn] = useState(0)
    const [tokenDataList, setTokenDataList] = useState<any>(tokenSwapList)
    const [btcbTokensObject, setBtcbTokensObject] = useState<any>({})
    const [transactionLink, setTransactionLink] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const { data, pendingGamma, toggleCollectModal } = props
    const { poolLoading, poolData, portfolioLoading, userPortfolio, gammaInfDataNew, aquaInfDataNew } = selector.user
    const dispatch = useDispatch()

    useEffect(() => {
        let gammaValArrForStake: any = pendingGamma ? (parseFloat(pendingGamma).toFixed(4)).split(".") : [0,0];
        setGammaAmountForStake(gammaValArrForStake[0])
        setGammaDecimalsForStake(gammaValArrForStake[1])
        let gammaValArrForClaim: any = pendingGamma ? ((parseFloat(pendingGamma)).toFixed(4)).split(".") : [0,0];
        setGammaAmountForClaim(gammaValArrForClaim[0])
        setGammaDecimalsForClaim(gammaValArrForClaim[1])
        if(tokenDataList !== undefined && tokenDataList.length > 0)
        var btcbObject = tokenDataList.find((token: any) => 
            token.name.toLowerCase() == "btcb"
        )
        //console.log(btcbObject)
        setBtcbTokensObject(btcbObject)
    })

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Claim ${type}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleBTCBAmount = (e: any) => {
        let { value, min, max } = e.target
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        if (floatNumRegex.test(value.toString())) {
            setBtcbPercentage(value)
            // setDisplaySendAmount(value);
            // getMinReceive(value)
            // setMaxAmtSelected(false)
        }
        if (!value) {
            setBtcbPercentage('0')
            // setDisplaySendAmount('')
            // getMinReceive(0)
            // setMaxAmtSelected(false)
        }
    }

    const claimAllPoolsGammaRewards = async (stakeToPool: any, instantClaim: any, addBtcbPercent: any, claim_type: any) => {
        if (!!selector.ethData.address && !portfolioLoading) {
            try {
                setCollectGammaBtn(claim_type)
                const pendingGammaInWei = convertToWei(pendingGamma, 1e18);
                let btcbAmount: any = 0
                if(addBtcbPercent > 0) {                
                    //calculate btcb amount
                    const convertToBoostInst = new wallet.web3.eth.Contract(convertToBoostABI, convertToBoostAddress)
                    btcbAmount = await convertToBoostInst.methods.getBTCBAmount(pendingGammaInWei, addBtcbPercent).call();
                    let btcbAmountInEther = convertToEther(btcbAmount, 18);

                    const token_inst = new wallet.web3.eth.Contract(gBnbAbi, BTCBAddress)
                    const approvalAmount = await token_inst.methods.allowance(selector.ethData.address, gammaFarmV3).call();
                    const approvalAmountInEther = convertToEther(approvalAmount, 18);

                    
                    let userBtcbBalance = await balanceOf(BTCBAddress, selector.ethData.address);
                    let userBtcbBalanceInEther = userBtcbBalance > 0 ? convertToEther(userBtcbBalance, 18) : 0;

                    // console.log("approval amount in convert to boost for btcb in farm", approvalAmount, btcbAmount, userBtcbBalance);
                    if(parseFloat(userBtcbBalanceInEther) >= parseFloat(btcbAmountInEther) && parseFloat(approvalAmountInEther) < parseFloat(btcbAmountInEther)) {
                        // console.log("in if")
                        const fetchBtcbApproval = await approveBTCB(token_inst, btcbAmount)
                    } else if(parseFloat(userBtcbBalanceInEther) < parseFloat(btcbAmountInEther) && parseFloat(approvalAmountInEther) < parseFloat(userBtcbBalanceInEther)){
                        // console.log("in else")
                        
                        //caclulate new btcb percentage
                        let token_price_arr: any = await returnTokenPriceMulticall();
                        let gammaPrice = token_price_arr[gammaAddress.toLowerCase()];
                        let btcbPrice = token_price_arr[BTCBAddress.toLowerCase()];
                        // let userGammaBalance = await balanceOf(gammaAddress, selector.ethData.address);
                        BigNumber.set({
                            EXPONENTIAL_AT: [-20, 50],
                        })
                        let price_ratio = BigNumber(gammaPrice).dividedBy(btcbPrice).toString();

                        let partA_: any = 10000 * parseFloat(convertToEther(userBtcbBalance, 18));
                        let partB_: any = (parseFloat(convertToEther(pendingGammaInWei, 18)) * parseFloat(price_ratio)) + parseFloat(convertToEther(856913863320892, 18)); 
                        let percentageOfBtcb_ : any = partA_/partB_;
                        // console.log(percentageOfBtcb_, gammaPrice, btcbPrice, pendingGammaInWei, userBtcbBalance, btcbAmount, partA_, partB_)
                        if(percentageOfBtcb_.toFixed(0) > 100){
                            addBtcbPercent = (percentageOfBtcb_/100).toFixed(0);
                            const fetchBtcbApproval = await approveBTCB(token_inst, userBtcbBalance);
                        } else {
                            addBtcbPercent = 0;
                        }
                        // console.log("percentageOfBtcb_", percentageOfBtcb_, addBtcbPercent)
                    } else {
                        addBtcbPercent = 0;
                    }
                }
                
                console.log(stakeToPool, instantClaim, addBtcbPercent*100)
                const farm_inst = new wallet.web3.eth.Contract(gammaFarmV3Abi, gammaFarmV3)
                const res = await farm_inst.methods.claimAllPoolsPendingGamma(stakeToPool, instantClaim, addBtcbPercent*100).send({
                    from: selector.ethData.address,
                })
                if (!!res && typeof res !== 'undefined' && Object.values(res).length > 0) {
                    const type = res.status ? 'success' : 'failed'
                    if (res.status) {
                        handleNotification(type, res.transactionHash)
                        dispatch(updateUserBalance(selector.ethData.address))
                        dispatch(updateUserLendingData(selector.ethData.address))
                    }
                    else {
                        handleNotification('failed')
                    }
                }
                else {
                    handleNotification('failed')
                }
            }
            catch (error) {
                console.log("error=>", error)
                handleNotification('failed')
            }
            finally {
                setCollectGammaBtn(0)
            }
        }
    }

    const approveBTCB = async (token_inst: any, btcbAmount: any) => {
        try {
            const approvalAmount = btcbAmount
            await token_inst.methods
            .approve(convertToBoostAddress, approvalAmount)
            .send({ from: selector.ethData.address })
            .once('transactionHash', function (res: any) {
                //console.log('approve transactionHash');
                if (res && typeof res !== 'undefined') {
                    const transLink = `${transLinkUrl}/${res}`
                    setTransactionLink(transLink)
                    // setShowTransModal(true)
                }
            })
            .once('confirmation', async function (confNumber: any, receipt: any) {
                const type = receipt.status ? 'success' : 'failed'
                // handleNotification(type, receipt.transactionHash)
                //console.log('approve confirmation');
                setApproveLoading(false)
                handleApproveNotification(type, receipt.transactionHash)
                let allowanceAmount: any = await token_inst.methods.allowance(selector.ethData.address, gammaFarmV3).call();
                // console.log("allowance amount in checkTokenApproval", allowanceAmount);
                if(parseFloat(allowanceAmount) >= parseFloat(btcbAmount)){
                    return true;
                    // console.log("create lp response", createlp_res)
                } else {    
                    approveBTCB(token_inst, btcbAmount)
                }
            })
            .on('error', function (error: any) {
                setTransactionLink('')
                // setShowTransModal(false)
                console.log('handleApprove token error' ,error)
                // checkTokenApproval()
                setApproveLoading(false)
            })
        } catch(error){
            console.log(error);
        } 
        
        
    }

    const handleApproveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        let typeMessage = "Failed";
        if(type == "success") {
            typeMessage = "Successful";
        }
        const dataVal: any = {
            type: type,
            message: `Approval for BTCB ${typeMessage}}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleMaxBtcb = () => {
        setBtcbPercentage(50)
    }

    return (
        <FlexSBCont className='collectGammaCont'>
            <FlexSBCont className='titleBox'>
                <TitleText className="collectGammaTitle">Collect GAMMA</TitleText>
                <img className="close-collect-gamma" src={whiteCrossIcon} alt="" onClick={()=> toggleCollectModal()}/>
            </FlexSBCont>
            <FlexSBCont className='claimGammaCont'>
                <FlexSBCont className='claimCont'>
                    <InfoLDText className='collectGammaTitle'>Deposit into the GAMMA-BTCB Vault - 180 day Lock</InfoLDText>
                    <InfoLDText className='collectGammaSubText'>This increases your APY Across Planet’s Pools & gives Rewards</InfoLDText>
                    <FlexSBCont className='gammaIconCont'>
                        <img className="gammaIcon" src={GAMMAIcon} alt="" />
                        <InfoLDText className='gammaVal'>
                            {gammaAmountForStake}.<span>{gammaDecimalsForStake}</span>
                        </InfoLDText>
                        
                    </FlexSBCont>
                    <InfoLDText className="btcbText">Add BTCB from Wallet</InfoLDText>
                    <FlexSBCont className='gammaIconCont'>
                        <img className="gammaIcon" src={BTCBIcon} alt="" />
                        <FlexSBCont className='btcb-input-cont'>
                            <TokenValInput 
                                disabled={false}
                                placeholder="0"
                                onChange={handleBTCBAmount}
                                value={btcbPercentage && `${btcbPercentage}`}
                                autoFocus={true}
                                className="btcb-input"
                                min={0}
                                max={50}
                            />
                            <span className='percentage'>%</span>
                            <CardButton2 className="on-web maxBtnClaimStake" disabled={collectGammaBtn === 2 || selector.ethData.address == undefined || pendingGamma == 0} onClick={() => handleMaxBtcb()}>max</CardButton2>
                        </FlexSBCont>
                    </FlexSBCont>
                    <CardButton2 className="on-web claimNStake" disabled={collectGammaBtn === 2 || selector.ethData.address == undefined || pendingGamma == 0} onClick={() => claimAllPoolsGammaRewards(true, false, btcbPercentage, 2)}>{collectGammaBtn === 2 ? <Spinner /> : 'Collect & Stake'}</CardButton2>
                    <CardButton2 className="on-mob claimNStake" disabled={collectGammaBtn === 2 || selector.ethData.address == undefined || pendingGamma == 0} onClick={() => claimAllPoolsGammaRewards(true, false, btcbPercentage, 2)}>{collectGammaBtn === 2 ? <Spinner /> : 'Collect & Stake'}</CardButton2>
                </FlexSBCont>
                <FlexSBCont className='claimNStakeCont'>
                    <InfoLDText className='collectGammaTitle'>Withdraw Instantly</InfoLDText>
                    <InfoLDText className='collectGammaSubText'>Collect gamma directly to your wallet.</InfoLDText>
                    <FlexSBCont className='gammaIconCont'>
                        <img className="gammaIcon" src={GAMMAIcon} alt="" />
                        <InfoLDText className='gammaVal'>
                        {gammaAmountForClaim}.<span>{gammaDecimalsForClaim}</span>
                        </InfoLDText>
                        
                    </FlexSBCont>
                    <CardButton2 className="on-web collectGamma" disabled={collectGammaBtn === 3 || selector.ethData.address == undefined || pendingGamma == 0} onClick={() => claimAllPoolsGammaRewards(false, true, 0, 3)}>{collectGammaBtn === 3  ? <Spinner /> : 'Collect'}</CardButton2>
                    <CardButton2 className="on-mob collectGamma" disabled={collectGammaBtn === 3  || selector.ethData.address == undefined || pendingGamma == 0} onClick={() => claimAllPoolsGammaRewards(false, true, 0, 3)}>{collectGammaBtn === 3  ? <Spinner /> : 'Collect'}</CardButton2>
                </FlexSBCont>
                
            </FlexSBCont>
            
        </FlexSBCont>
    )
}
export default CollectRewards
