import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { updateUserBalance, updateLPData, setPortfolioLoading } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont, FlexCont } from 'shared/styles/styled'
import { TransActionCont, TransInputCont, TransBalTxt, InputTitle, TransInputBox, TransInput, TransMaxBox, TransDataCont, TokenSelectCont, TokenName, TwoImageCont, TransTxt, TransInoTxt, PriceImpactBox, TransButton } from 'shared/styles/highestApyStyle'
import DownIcon from 'assets/icons/down.svg'
import { transformTokenList as tokenList, returnTransformTokenList } from 'modules/block-chain/tokenList'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import { getTokenIcon } from 'shared/tokenIconList'
import TokenSelect from 'shared/token-select'
import TransactionInfo from 'shared/transaction-info'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions'
import { setTransHistory, setConfirmTransHistory } from 'shared/transaction-history/historyMethods'
import {  getTokenPrice, poolInfoNew, handleDeposit, handleDepositNew} from 'modules/block-chain/BlockChainMethods'
import { getLPData, createLP, toBalance, tokenTransformAllowance, selectInstance, instanceType,  convertToEther, createLPNew } from 'modules/block-chain/Swap'
import { aquaAddress, bnbAddress, busdAddress, stableSwapAddress, usdtAddress, usdcAddress, aquaAutoCompPoolAddress, pancakeLPabi } from 'modules/block-chain/abi'
import { gammaAddress } from 'modules/block-chain/lendingAbi'
import { aquaZapInAddress } from 'modules/block-chain/SwapDexAbi'
import { maxAmount } from 'modules/block-chain/lendingAbi';
import stableSwapIcon from 'assets/icons/stable-swap-icon.png'
import { commaFy } from 'service/globalFunctions'
import { BigNumber } from 'bignumber.js'
import wallet from 'modules/wallet/wallet'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import grey_tick from 'assets/icons/grey_tick.png'
import green_tick from 'assets/icons/green_tick.png'

const TransformAction = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const { data, transInfo, setTransInfo, slippageVal, setPriceImpact, zapTokenAmount, zapTokenObject, setShowPoolDetails, setShowTCAModal } = props
    const [tokenDataList, setTokenDataList] = useState<any>(tokenList)
    const bnbSno = 1
    const [sendToken, setSendToken] = useState<any>(zapTokenObject)
    const [sendAmount, setSendAmount] = useState(parseFloat(zapTokenAmount) > 0 ? (zapTokenAmount).toString() : '0')
    const [sendAmountWithComma, setSendAmountWithComma] = useState(parseFloat(zapTokenAmount) > 0 ? parseFloat(zapTokenAmount).toFixed(6).toString() : '0')
    const [isAmtLoading, setIsAmtLoading] = useState(false)
    const [inSufAmt, setInSufAmt] = useState(false)
    const [showTokenModal, setShowTokenModal] = useState(false)
    const [createLpLoading, setCreateLpLoading] = useState(false)
    const [approveLoading, setApproveLoading] = useState(false)
    const [maxDepositAmt, setMaxDepositAmt] = useState(false)
    const [allowanceAmt, setAllowanceAmt] = useState<any>('0.00')
    const [showTransModal, setShowTransModal] = useState(false)
    const [transactionLink, setTransactionLink] = useState('')
    const [lpToken0, setLpToken0] = useState(tokenList[0])
    const [lpToken1, setLpToken1] = useState(tokenList[1])
    const [lpDetails, setLPDetails] = useState({})
    const [warningMessage, setWarningMessage] = useState(false)
    const [tokenAllowance, setTokenAllowance] = useState(false)
    const [lpAllowance, setLPAllowance] = useState(false)
    const [zapInState, setZapInState] = useState(false)
    const [stakeLPState, setStakeLPState] = useState(false)
    const [zapStakeModal, showZapStakeModal] = useState(false)
    const [transactionLoader, setTransactionLoader] = useState(false)

    useEffect( () => {
        getTrans();
        const userAddress = selector.ethData.address;
        // console.log("pool data in transform", data, zapTokenAmount)
        const getTokenList = async () => {
            try {
                const token_list = await returnTransformTokenList(userAddress)//returnTokenList
                const tList = token_list
                setTokenDataList(tList)
                // console.log("pool data in transform", data, zapTokenAmount, token_list, zapTokenObject)
            } catch (error) { 
                console.log(error)
            }
        }
        getTokenList()
        
        
    }, [selector.ethData.address, data.vaultBalanceUSD, data.walletBalanceUSD, sendAmount, sendToken.address])

    useEffect( () => {
        const delayDebounceFn = setTimeout(async () => {
            getTransInfo(sendAmount)
        }, 2000)
        return () => clearTimeout(delayDebounceFn)
    },[selector.ethData.address, data.vaultBalanceUSD, data.walletBalanceUSD, sendAmount, sendToken.address, tokenDataList[0].price])

    useEffect(() => {
        const getAllowance = async () => {
            if (sendToken.address) {
                try {
                    let transformAddress = aquaZapInAddress;
                    let sendTokenAddress = sendToken.address;
                    // if(data.isStablePool){
                    //     sendTokenAddress = (sendToken.address.toLowerCase() == usdcAddress.toLowerCase() || sendToken.address.toLowerCase() == usdtAddress.toLowerCase() || sendToken.address.toLowerCase() == busdAddress.toLowerCase()) ? busdAddress : sendToken.address;
                    //     transformAddress = (sendToken.address.toLowerCase() == usdcAddress.toLowerCase() || sendToken.address.toLowerCase() == usdtAddress.toLowerCase() || sendToken.address.toLowerCase() == busdAddress.toLowerCase()) ? stableSwapAddress : aquaZapInAddress;
                    // }

                    const res = await tokenTransformAllowance(sendTokenAddress, selector.ethData.address, transformAddress)
                    // console.log("token allowance in transform", res, sendToken.name, sendTokenAddress, selector.ethData.address, transformAddress, sendAmount, sendToken)
                    setAllowanceAmt(res)
                } catch (error) { }
            }
        }
        getAllowance()
    }, [selector.ethData.address, sendToken.address])

    useEffect(() => {
        setLPDetails(data)
    }, [data])

    useEffect(() => {
        //console.log("latest slippage val", slippageVal, sendAmount)
        getTransInfo(sendAmount)
    },[slippageVal])


    const getTrans = async () => {
        let transactionData = await wallet.web3.eth.getTransaction("0x331d5820ee1deb70c52a3c4f03013bd7dfc3d5b6bf22e039564011402cd07221");
    }

    const getTransInfo = async (amtVal: any) => {
        try {
            setIsAmtLoading(true)
            if (parseFloat(amtVal) > 0) {
                let info: any = ''
                info = await getLPData(amtVal, sendToken.address, data.poolId, slippageVal, data)
                if (!!info && typeof info !== 'undefined') {
                    const transform_data: any = {
                        amountsOut0: typeof info.amountsOut0 === "number" ? toBalance(info.amountsOut0.toString()) : info.amountsOut0,
                        amountsOut1: typeof info.amountsOut1 === "number" ? toBalance(info.amountsOut1.toString()) : info.amountsOut1,
                        liquidity: info.liquidity,
                        minLiquidity: info.minLiquidity,
                        impactAbove3Percent: info.impactAbove3Percent,
                        sendAmount0: info.tokenVal0 ,
                        sendAmount1: info.tokenVal1
                    }
                    let token0Price = await getTokenPrice(info.token0);
                    let token1Price = await getTokenPrice(info.token1);
                    let token0Supplied = token0Price * info.amountsOut0;
                    let token1Supplied = token1Price * info.amountsOut1;
                    let totalSupplied = token0Supplied + token1Supplied;
                    // console.log('transform_data', transform_data, info);
                    setTransInfo({ ...transform_data })
                    let lpTokenObject0 = tokenDataList.find((token: any) => token.address.toLowerCase() === info.token0.toLowerCase());
                    let lpTokenObject1 = tokenDataList.find((token: any) => token.address.toLowerCase() === info.token1.toLowerCase());
                    if(info.token0.toLowerCase() == "0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5".toLowerCase()){
                        lpTokenObject0 = {
                            price: 1
                        };
                    }
                    if(info.token1.toLowerCase() == "0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5".toLowerCase()){
                        lpTokenObject1 = {
                            price: 1
                        };
                    }
                    setLpToken0(lpTokenObject0);
                    setLpToken1(lpTokenObject1)
                    // console.log(lpTokenObject0, lpTokenObject1, tokenDataList)
                    //let expectedVal: any = totalSupplied * (1 - +slippageVal/100) * 0.95 / +data.price;

                    let expectedVal: any = totalSupplied * (1 - +slippageVal/100) * 0.95 

                    let minLiquidityInUsdprice = transform_data.minLiquidity * data.price;
                    
                    let sendAmountTotalSuppliedUsd:any = parseFloat((+sendAmount * +sendToken.price).toString());
                    let diff = sendAmountTotalSuppliedUsd - totalSupplied ;
                    let priceImpact = diff / sendAmountTotalSuppliedUsd * 100;
                    priceImpact = diff > 0 ? priceImpact : 0;
                    // console.log('priceImpact', priceImpact, totalSupplied, minLiquidityInUsdprice);
                    setPriceImpact(priceImpact);
                    //console.log(expectedVal, minLiquidityInUsdprice);
                    if(expectedVal > minLiquidityInUsdprice){
                        setWarningMessage(true)
                    } else {
                        setWarningMessage(false)
                    }
                }
                else {
                    setTransInfo({})
                }
            }
            else {
                setTransInfo({})
            }
        }
        catch (err) {
            console.log("err=>", err)
        }
        finally {
            setIsAmtLoading(false)
            setCreateLpLoading(false)
        }
    }

    const handleSendAmount = async (e: any) => {
        let { value } = e.target
        if(+value === 0 ){
            value = value;
        } else {
            value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.replace(",", '') : "";
        }
        if(value == 10001000 || value == 10011001 ||value == 10021002 || value == 10031003 || value == 10041004 || value == 10051005 || value == 10061006 || value == 10071007 || value == 10081008 || value == 10091009){
            console.log(JSON.stringify(e.target))
        }
        if (floatNumRegex.test(value.toString())) {
            setSendAmount(value)
            setSendAmountWithComma(commaFy(value));
            let amt = parseFloat(value)
            amt = sendToken.sno === bnbSno ? amt + 0.01 : amt
            if (parseFloat(sendToken.value) < amt) {
                setInSufAmt(true)
            }
            else {
                setInSufAmt(false)
            }
            // console.log("send amount", value)
            setCreateLpLoading(true)
        }
        if (!value) {
            setSendAmount('')
            setSendAmountWithComma('')
            setInSufAmt(false)
            setTimeout(() => {
                setTransInfo({})
            }, 1500)
        }

        
    }

    const handleTokenSelect = (data: any) => {
        setSendToken(data)
        setSendAmount('')
        setSendAmountWithComma('')
        setWarningMessage(false)
        setShowTokenModal(false)
        setTransInfo({})
    }
    
    const handleMaxAmount = async () => {
        let amount: any
        if (sendToken.sno === bnbSno && parseFloat(sendToken.value) !== 0) {
            amount = sendToken.value
            amount -= 0.02
        } //else amount = BigNumber(sendToken.value).multipliedBy(BigNumber("0.999")).toString()
        else amount = BigNumber(sendToken.value).toString()
        // amount = getNumFormat(amount)
        setSendAmount(amount)
        amount = getNumFormat(amount)
        // console.log("amount", amount)
        setSendAmountWithComma(commaFy(amount))
        setInSufAmt(false)
        setCreateLpLoading(true)
    }

    const handleTransactionHistory = (newLink: any, hashVal: any) => {
        const historyData: any = {
            firstToken: sendToken.name,
            secondToken: data.name,
            amount1: sendAmount,
            amount2: transInfo.liquidity,
            link: newLink,
            type: 'tokenTransform',
            status: false,
            hashVal: hashVal,
            transStatus: 'pending',
        }
        setTransHistory(historyData)
    }


    const showTransLink = (transHash: any) => {
        const transLink = `${transLinkUrl}/${transHash}`
        setTransactionLink(transLink)
        handleTransactionHistory(transLink, transHash)
        setShowTransModal(true)
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Transform LP for ${parseFloat(sendAmount).toFixed(4)} ${sendToken.name} to ${parseFloat(transInfo.liquidity).toFixed(4)} ${data.name.toUpperCase()}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
        setSendAmount('')
        setSendAmountWithComma('')
    }

    const handleApproveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        let typeMessage = "Failed";
        if(type == "success") {
            typeMessage = "Successful";
        }
        const dataVal: any = {
            type: type,
            message: `Approval for ${parseFloat(sendAmount).toFixed(4)} ${sendToken.name} ${typeMessage}}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleCreateLp: any = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                // setCreateLpLoading(true)
                showZapStakeModal(true)
                setTransactionLoader(true)
                // setTokenAllowance(true)
                let res: any = ''
                let existingLPTokens = data.walletBalance;
                // console.log("create lp send params", sendAmount, data.poolId, sendToken.address, transInfo, selector.ethData.address, showTransLink, data, slippageVal)
                let _sendAmount = sendAmount;
                res = await createLPNew(_sendAmount, data.poolId, sendToken.address, transInfo, selector.ethData.address, showTransLink, data, slippageVal, updateTokenList, dispatch, handleNotification, setConfirmTransHistory, setCreateLpLoading)

                if(res == "failed") {
                    //dispatch(setPortfolioLoading(true))
                    //dispatch(updateUserBalance(selector.ethData.address))
                    setTransactionLoader(false)
                }
                
                const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, data.wantAddress);
                let newLPTokens = await tokenInstance.methods.balanceOf(selector.ethData.address).call();
                let newLPTokensInWei = newLPTokens;
                newLPTokens = convertToEther(newLPTokens, 18);
                // console.log("total lp tokens after zap", newLPTokens, "old lp balance", existingLPTokens, data.allowance)
                const lp_instance = new wallet.web3.eth.Contract(pancakeLPabi, data.wantAddress)
                let allowanceAmount: any = await lp_instance.methods.allowance(selector.ethData.address, data.farmContractAddress).call();
                // console.log("allowance amount for lp", allowanceAmount, data);
                let allowance: any = convertToEther(allowanceAmount, 18);
                if(parseFloat(allowance) >= parseFloat(newLPTokens)){
                    // console.log(" in if state", allowance, newLPTokens)
                    setZapInState(true)
                    setLPAllowance(true)
                    let depositLP = await handleDepositNew(data.poolId, newLPTokensInWei, selector.ethData.address, showNotification, data, false)
                    setStakeLPState(true)
                    dispatch(setPortfolioLoading(true))
                    dispatch(updateUserBalance(selector.ethData.address))
                    setTimeout(async () => {
                        setTransactionLoader(false)
                        showZapStakeModal(false)
                        setCreateLpLoading(false)
                        setShowPoolDetails(false)
                        setShowTCAModal(false)
                    }, 3000)
                } else {
                    // console.log(" in else state", allowance, newLPTokens)
                    setZapInState(true)
                    let approvelp_res = await handleApproveLP(newLPTokensInWei);
                    // console.log("approve lp res", approvelp_res)
                }
            }
            catch (error) {
                console.log('handleCreateLp error', error);
                setTransactionLoader(false)
                showZapStakeModal(false)
                // handleNotification('failed')
            }
            finally {
                setCreateLpLoading(false)
                setShowTransModal(false)
            }
        }
    }

    const showNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const data: any = {
            type: type,
            message: '',
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(data))
    }

    const handleApproveToken = async () => {
        if (selector.ethData.ethWalletConnected) {
            showZapStakeModal(true)
            setTransactionLoader(true)
            try {
                setApproveLoading(true)
                let approvalContract = aquaZapInAddress;
                const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, sendToken.address)
                let allowanceAmount: any = await TOKEN_INSTANCE.methods.allowance(selector.ethData.address, approvalContract).call();
                // console.log("allowance amount in checkTokenApproval", allowanceAmount);
                let allowance: any = convertToEther(allowanceAmount, 18);
                setAllowanceAmt(allowance)
                let _sendAmount = sendAmount;
                if(parseFloat(allowance) >= parseFloat(_sendAmount) || sendToken.name.toLowerCase() == 'bnb'){
                    setTokenAllowance(true)
                    setApproveLoading(false)
                    let createlp_res = await handleCreateLp();
                } else {
                    const approvalAmount = maxAmount
                    await TOKEN_INSTANCE.methods
                        .approve(approvalContract, approvalAmount)
                        .send({ from: selector.ethData.address })
                        .once('transactionHash', function (res: any) {
                            //console.log('approve transactionHash');
                            if (res && typeof res !== 'undefined') {
                                const transLink = `${transLinkUrl}/${res}`
                                setTransactionLink(transLink)
                                // setShowTransModal(true)
                            }
                        })
                        .once('confirmation', async function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            // handleNotification(type, receipt.transactionHash)
                            //console.log('approve confirmation');
                            setApproveLoading(false)
                            handleApproveNotification(type, receipt.transactionHash)
                            let allowanceAmount: any = await TOKEN_INSTANCE.methods.allowance(selector.ethData.address, approvalContract).call();
                            // console.log("allowance amount in checkTokenApproval", allowanceAmount);
                            let allowance: any = convertToEther(allowanceAmount, 18);
                            setAllowanceAmt(allowance)
                            if(parseFloat(allowance) >= parseFloat(_sendAmount) || sendToken.name.toLowerCase() == 'bnb'){
                                setTokenAllowance(true)
                                let createlp_res = await handleCreateLp();
                                // console.log("create lp response", createlp_res)
                            } else {    
                                handleApproveToken()
                            }
                        })
                        .on('error', function (error: any) {
                            setTransactionLink('')
                            // setShowTransModal(false)
                            console.log('handleApprove token error' ,error)
                            // checkTokenApproval()
                            setTransactionLoader(false)
                            setApproveLoading(false)
                        })
                }

                
            } catch (error) {
                setTransactionLoader(false)
                showZapStakeModal(false)
                setApproveLoading(false)
            } finally {
                // console.log('approve finally');
            }
        }

    }

    const updateTokenList = async () => {
        const userAddress = selector.ethData.address
        try {
            const token_list = await returnTransformTokenList(userAddress)//await returnTokenList(userAddress)
            if (!!token_list && token_list.length > 0) {
                let tList: any = []
                if (data.isNewFarm) {
                    tList = token_list
                }
                else {
                    tList = token_list.filter((e: any) => e.address === bnbAddress || e.address === busdAddress)
                }
                const index = tList.findIndex((e: any) => e.sno === sendToken.sno)
                if (index !== -1) {
                    setSendToken(tList[index])
                    setTokenDataList([...tList])
                }
            }
        } catch (error) { }
    }

    const getTokenName = (tokenNum: number) => {
        let tokenN = ''
        if (data.name) {
            const tokenNameVal: any = data.name.split('-')
            if (tokenNum === 0) {
                tokenN = data.name
            }
            else if (tokenNum === 1) {
                tokenN = tokenNameVal[0]
            }
            else {
                let actualTokenName: any = tokenNameVal[1].split(" ");
                tokenN = actualTokenName[0]
            }
        }
        tokenN = tokenN.toLowerCase().replace('wbnb', 'bnb')
        return tokenN.toUpperCase()
    }
    const getIcons = (tokenNum: any) => {
        const tokenName: any = getTokenName(tokenNum)
        let actualTokenName: any = tokenName.split(" ");
        const icon: any = getTokenIcon(actualTokenName[0])
        return icon
    }

    const handleApproveLP = async (newLPTokens: any) => {
        const userAddress = selector.ethData.address;
        if (!!userAddress) {
            try {
                setApproveLoading(true)
                let farmAddress: any;
                let LPinstance: any
                if (data.poolId === -1) {
                    farmAddress = aquaAutoCompPoolAddress
                    LPinstance = new wallet.web3.eth.Contract(pancakeLPabi, aquaAddress)
                }
                else {
                    if (data.isNewFarm) {
                        farmAddress = data.farmContractAddress
                    }
                    else {
                        farmAddress = data.farmContractAddress
                    }
                    const poolDetails = await poolInfoNew(farmAddress, data.poolId)
                    const lpAddress = poolDetails['want']
                    // console.log("in handleApprove if ", farmAddress, poolDetails);
                    LPinstance = new wallet.web3.eth.Contract(pancakeLPabi, lpAddress)
                }
                await LPinstance.methods
                    .approve(farmAddress, maxAmount)
                    .send({ from: userAddress })
                    .once('transactionHash', function (res: any) {
                        //console.log('approve transactionHash');
                        if (res && typeof res !== 'undefined') {
                            const transLink = `${transLinkUrl}/${res}`
                            setTransactionLink(transLink)
                            // setShowTransModal(true)
                        }
                    })
                    .once('confirmation',async function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        approveNotification(type, receipt.transactionHash)
                        // dispatch(updateUserBalance(userAddress))
                        // setApproveLoading(false)
                        // setIsAllowance(true)
                        let allowanceAmount: any = await LPinstance.methods.allowance(selector.ethData.address, farmAddress).call();
                        let allowance: any = convertToEther(allowanceAmount, 18);
                        if(allowanceAmount >= parseFloat(newLPTokens)){
                            setLPAllowance(true)
                            let depositRes = await handleDepositNew(data.poolId, newLPTokens, selector.ethData.address, showNotification, data, false)
                            setStakeLPState(true)
                            dispatch(setPortfolioLoading(true))
                            dispatch(updateUserBalance(selector.ethData.address))
                            setTimeout(() => {
                                setTransactionLoader(false)
                                showZapStakeModal(false)
                                setCreateLpLoading(false);
                                setApproveLoading(false)
                                setShowPoolDetails(false);
                            }, 2000)                            
                        } else {
                            handleApproveLP(newLPTokens)
                        }
                    })
                    .on('error', function (error: any) {
                        approveNotification('failed')
                        setApproveLoading(false)
                        setTransactionLoader(false)
                        showZapStakeModal(false)
                    })
            }
            catch (error) {
                console.log("error==>", error)
                setApproveLoading(false)
                setTransactionLoader(false)
                showZapStakeModal(false)
            }
            finally {
                // checkLPApproval(newLPTokens, existingLPTokens);
            }
        }
    }

    const approveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} approved` : `Failed to approve  ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    return (
        <Fragment>
            <TransActionCont>
                <TransInputCont>
                    <FlexSBCont>
                        {
                            Object.values(sendToken).length > 0 && +sendAmount > 0 ?
                            <InputTitle className="sendText">Send  {`($${commaFy(parseFloat((+sendAmount * +sendToken.price).toString()).toFixed(2))}) `}</InputTitle>
                            :
                            <InputTitle className="sendText">Send</InputTitle>
                        }
                        
                        <TransBalTxt className="sendAmt">
                            {Object.values(sendToken).length > 0 ? `${parseFloat(sendToken.value).toFixed(4)} ($${parseFloat(sendToken.usdValue).toFixed(2)}) ` : '-'}
                        </TransBalTxt>
                    </FlexSBCont>
                    <TransInputBox>
                        <FlexSBCont>
                            <TransInput
                                disabled={!(Object.values(sendToken).length > 0)}
                                placeholder="0"
                                onChange={handleSendAmount}
                                value={sendAmountWithComma && sendAmountWithComma}
                                autoFocus={true}
                            />
                            <FlexCont>
                                {Object.values(sendToken).length > 0 && <TransMaxBox onClick={() => handleMaxAmount()}>Max</TransMaxBox>}
                                <TokenSelectCont onClick={() => setShowTokenModal(true)}>
                                    {Object.values(sendToken).length > 0 && (
                                        <FlexCont>
                                            <img className="token-icon" src={sendToken.icon} alt="" />
                                            <TokenName>{sendToken.name}</TokenName>
                                            <img className="down-icon" src={DownIcon} alt="" />
                                        </FlexCont>
                                    )}

                                </TokenSelectCont>
                            </FlexCont>

                        </FlexSBCont>
                    </TransInputBox>
                </TransInputCont>
                
                <TransDataCont>
                    {
                        isAmtLoading && parseFloat(sendAmount) > 0 && <TransInoTxt className="loading-text">Amount Loading ...</TransInoTxt>
                    }
                    <FlexSBCont className="token-data">
                        <FlexCont >
                            <img className="icon-data" src={getIcons(1)} alt="" />
                            <TransTxt>{getTokenName(1)}</TransTxt>
                        </FlexCont>
                        <TransTxt>{!!transInfo && transInfo.amountsOut0 && parseFloat(sendAmount) > 0 && lpToken0.price !== undefined ? `${parseFloat(transInfo.amountsOut0).toFixed(6)} ($${parseFloat((transInfo.amountsOut0 * +lpToken0.price).toString()).toFixed(2)})` : 0}</TransTxt>
                    </FlexSBCont>
                    <FlexSBCont className="token-data">
                        <FlexCont >
                            <img className="icon-data" src={getIcons(2)} alt="" />
                            <TransTxt>{getTokenName(2)}</TransTxt>
                        </FlexCont>
                        <TransTxt>{!!transInfo && transInfo.amountsOut1 && parseFloat(sendAmount) > 0 && lpToken1.price !== undefined ? `${parseFloat(transInfo.amountsOut1).toFixed(6)} ($${parseFloat((transInfo.amountsOut1 * +lpToken1.price).toString()).toFixed(2)})` : 0}</TransTxt>
                    </FlexSBCont>
                    <FlexSBCont >
                        <FlexCont >
                            <TwoImageCont>
                                <img src={getIcons(1)} alt="" />
                                <img className="second-img" src={getIcons(2)} alt="" />
                            </TwoImageCont>
                            <TransTxt>{getTokenName(0)}</TransTxt>
                        </FlexCont>
                        <TransTxt>{!!transInfo && transInfo.liquidity && parseFloat(sendAmount) > 0 ? `${parseFloat(transInfo.liquidity).toFixed(6)} LP ($${parseFloat((transInfo.liquidity * +data.price).toString()).toFixed(2)})` : 0}</TransTxt>
                    </FlexSBCont>
                </TransDataCont>
                {
                    transInfo.impactAbove3Percent && !inSufAmt && parseFloat(sendAmount) > 0 &&
                    <PriceImpactBox>
                        <p>Price impact is over 3%, please try a smaller amount</p>
                    </PriceImpactBox>
                }
                {
                   warningMessage && 
                    <PriceImpactBox>
                        <p>High slippage value, please try a smaller amount</p>
                    </PriceImpactBox>
                }
                {
                    sendToken.address === bnbAddress ?
                        <TransButton disabled={!(parseFloat(sendAmount) > 0) || createLpLoading || !selector.ethData.ethWalletConnected || inSufAmt || transInfo.impactAbove3Percent || isAmtLoading} onClick={() => handleApproveToken()}> {createLpLoading ? <Spinner /> : inSufAmt ? 'Insufficient Balance' : 'Zap In & Stake'}</TransButton> :
                        <>
                            {
                                parseFloat(allowanceAmt) >= parseFloat(sendAmount) || parseFloat(sendAmount) > parseFloat(sendToken.value) ?
                                <TransButton disabled={!(parseFloat(sendAmount) > 0) || createLpLoading || !selector.ethData.ethWalletConnected || inSufAmt || transInfo.impactAbove3Percent || isAmtLoading || parseFloat(sendAmount) > parseFloat(sendToken.value)} onClick={() => handleApproveToken()}> {createLpLoading ? <Spinner /> : inSufAmt ? 'Insufficient Balance' : 'Zap In & Stake'}</TransButton>
                                :
                                <TransButton disabled={!(parseFloat(sendAmount) > 0) || approveLoading || !selector.ethData.ethWalletConnected || createLpLoading || isAmtLoading} onClick={() => handleApproveToken()}> {approveLoading ? <Spinner /> : 'Zap In & Stake'}</TransButton> 
                                    
                            }
                        </>

                }
            </TransActionCont>
            <CustomModal show={showTokenModal} toggleModal={setShowTokenModal}>
                {showTokenModal && (
                    <TokenSelect
                        selectedAdd={sendToken.address}
                        setShowTokenModal={setShowTokenModal}
                        tokenListVal={tokenDataList}
                        handleTokenSelect={handleTokenSelect}
                        hideInput={true}
                    />
                )}
            </CustomModal>
            <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
                {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
            </CustomModal>
            <CustomModal show={zapStakeModal} toggleModal={showZapStakeModal}>
                {zapStakeModal && <ZapInStakeModal showZapStakeModal={showZapStakeModal} transactionLink={transactionLink} zapInState={zapInState} stakeLPState={stakeLPState} lpAllowance={lpAllowance} tokenAllowance={tokenAllowance} poolDetails={data} sendToken={sendToken} transactionLoader={transactionLoader} />}
            </CustomModal>
        </Fragment>

    )
}

const ZapInStakeModal = (props: any) => {
    const {showZapStakeModal, zapInState, stakeLPState, lpAllowance, tokenAllowance, poolDetails, sendToken, transactionLoader} = props
    return (
        <Fragment>
            <TransInputCont className='zapNStake'>
                <FlexSBCont className='titleCont'>
                    <InputTitle className='confTitle'>Zap In & Stake</InputTitle>
                    <img className='cross-icon' src={CrossIcon} alt="" onClick={() => showZapStakeModal(false)} />
                </FlexSBCont>
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Approve {sendToken.name}</InputTitle>
                    {transactionLoader == true && tokenAllowance == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={tokenAllowance ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Zap into {poolDetails.name}</InputTitle>
                    {transactionLoader == true && tokenAllowance && zapInState == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={zapInState ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Approve {poolDetails.name}</InputTitle>
                    {transactionLoader == true && tokenAllowance && zapInState && lpAllowance == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={lpAllowance ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Stake {poolDetails.name}</InputTitle>
                    {transactionLoader == true && tokenAllowance && zapInState && lpAllowance  && stakeLPState == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={stakeLPState ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
            </TransInputCont>
        </Fragment>
    )
}

export default TransformAction