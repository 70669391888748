import { useState, useEffect } from 'react'
import { GCardDateTxt } from './style'
var timerForUnStakeAqua = setInterval(function () {}, 0)
const UnStakingTime = (props: any) => {
    const [isCountdown, setIsCountdown] = useState(false)
    const [timeVal, setTimeVal] = useState('00:00:00')
    const { compareDate, setAquaCountDownDate } = props

    useEffect(() => {
        // const dateVal = new Date();
        // dateVal.setDate(dateVal.getDate() + 7);
        // timeBetweenDates(dateVal)
        if(timerForUnStakeAqua)
        {
            clearInterval(timerForUnStakeAqua);
        }
        if (!!compareDate) {
            const dateVal = new Date(compareDate)
            timeBetweenDates(dateVal)
        }
    }, [compareDate])

    function timeBetweenDates(toDate: any) {
        let dateEntered = toDate;
        let now = new Date();
        let difference: any = dateEntered.getTime() - now.getTime();

        if (parseFloat(difference) > 0) {
            setIsCountdown(true)
            timerForUnStakeAqua = setInterval(function () {
                difference = difference - 1000
                let seconds: any = Math.floor(difference / 1000);
                let minutes: any = Math.floor(seconds / 60);
                let hours: any = Math.floor(minutes / 60);
                let days: any = Math.floor(hours / 24);

                hours %= 24;
                minutes %= 60;
                seconds %= 60;
                let finalTime: any = '00:00:00'

                if (parseFloat(days) > 0) {
                    finalTime = `${days}:${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(-2)}`
                }
                else {
                    finalTime = `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(-2)}`
                }

                setTimeVal(finalTime)
                if (difference <= 0) {
                    clearInterval(timerForUnStakeAqua)
                    setTimeVal('00:00:00')
                    setIsCountdown(false)
                    setAquaCountDownDate('')
                }
            }, 1000);
        }
        else
        {
            setIsCountdown(false)
            setTimeVal('00:00:00')
        }

    }
    return (
        <GCardDateTxt light={!isCountdown}>{timeVal}</GCardDateTxt>
    );
};

export default UnStakingTime;