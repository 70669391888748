import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { updateUserBalance } from 'logic/action/user.actions'
import { updateUserLendingData } from 'logic/action/lending.action'
import { MainContainer, FlexCont, FlexSBCont, ToolTipDataCont, ToolTipText, ToolTipIcon, LoadingText1, LoadingText2, LoadingText3 } from 'shared/styles/styled'
import {
  WebBlock,
  MobileBlock,
  HeaderCont,
  HeaderTxt,
  HeaderNumTxt,
  DiscountText,
  HeaderBtn,
  HeaderInfoBox,
  HeaderInfo,
  DataInfoCont,
  DataInfoBox,
  InfoBoxWrapper,
  MarketSizeTxt,
  InfoBox,
  InfoNumberTxt,
  NetApyText,
  InfoLightText,
  SliderBox,
  SliderText,
  SliderContainer,
  TableCont,
  DataInfoContNew,
  InfoNumberTxtNew,
  CardDivider,
  VerticalCardDivider,
  InfoBoxNew,
  DataInfoBoxNew,
  DataInfoBoxPadding,
  DiscountPie,
  CardText,
} from './style'
import GammaIcon from 'assets/icons/gamma-icon.png'
import LevelIcon from 'assets/icons/level-icon.png'
// import QuestionIcon from 'assets/icons/question-icon.svg'
import QuestionIcon from 'assets/icons/question_icon.png'
import ReactTooltip from "react-tooltip";
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import SuppliedAsset from './components/SuppliedAsset'
import SupplyMarket from './components/SupplyMarket'
import BorrowedAsset from './components/BorrowedAsset'
import BorrowMarket from './components/BorrowMarket'
import Spinner from 'shared/spinner';
import MobLending from './MobLending'
import { claimGamma } from 'modules/block-chain-green/gammatroller'
import { commaFy, convertToKAndM } from 'service/globalFunctions'
import { transLinkUrl } from 'service/global-constant'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import CustomModal from 'shared/custom-modal'
import AssetSupply from './components/AssetSupply'
import Collateral from './components/Collateral'
import ToolTipModal from 'shared/tooltip-modal'
import AssetSupplyRevised from './components/AssetSupplyRevised'
import ActivateAsset from './components/ActivateAsset'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import VaultEarnings from './components/VaultEarnings'
import BoostCalculationModal from './components/BoostCalculationModal'
import PoolDetailsModalForLending from 'modules/pools_v3/components/pool-modal/PoolDetailsModalForLending'
import { tokenList, returnTokenList, swapTokenList, returnSwapTokenList, tokenSwapList } from './../block-chain/tokenList'
import { FarmPoolContext } from 'context/FarmPoolContext'
import SwapInPools from 'modules/pools_v3/components/pool-modal/SwapInPools'
import UnStakeInstantlyPopUp from 'modules/pools_v3/components/pool-modal/UnStakeInstantlyPopUp'

let timerForGreenPendingGAMMA = setInterval(function () { }, 0)
const Lending = () => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const [claimLoading, setClaimLoading] = useState(false)
  const [suppLevel, setSuppLevel] = useState(0)
  const [borLevel, setBorLevel] = useState(0)
  const [isAllowance, setIsAllowance] = useState(false)
  const [modalData, setModalData] = useState<any>({})
  const [showAssetSupply, setShowAssetSupply] = useState(false)
  const [showCollateral, setShowCollateral] = useState(false)
  const [collateralData, setCollateralData] = useState<any>({})
  const [showToolTip, setShowTooltipModal] = useState(false)
  const [toolTipTitle, setToolTipTitle] = useState('')
  const [toolTipMessage, setToolTipMessage] = useState('')
  const [ greenPendingGAMMA, setGreenPendingGAMMA] = useState('')
  const [ greenPendingGAMMAUSD, setGreenPendingGAMMAUSD] = useState('0.00')
  const [showVaultDetails, setShowVaultDetails] = useState(false)
  const [showEnableAsset, setShowEnableAsset] = useState(false)
  const [boostPopup, setBoostPopup] = useState(false)
  const [gammaInfinityVault, setGammaInfinityVault] = useState(false)
  const [showPoolDetails, setShowPoolDetails] = useState(false)
  const [transData, setTransData] = useState<any>()
  const [receiveTokenNumber, setReceiveTokenNumber] = useState('')
  const [lpAction, setLPAction] = useState<any>('transform')
  const [showTCAModal, setShowTCAModal] = useState(false)
  const [tokenSwapAmount, setTokenSwapAmount] = useState('')
  const [sendToken, setSendToken] = useState<any>(tokenList[1])
  const [tokenDataList, setTokenDataList] = useState<any>(tokenSwapList)
  const [showSwapPopUp, setShowSwapPopUp] = useState(false)
  const [unstakeData, setUnstakeData] = useState({})
  const [processUnstake, setProcessUnstake] = useState(() => {})
  const [showUnstakePopUp, setShowUnstakePopUp] = useState(false)
  const [confirmUnstake, setConfirmUnstake] = useState({
      confirm: false,
      poolId: -1,
  })
  const [initialTokenAdd, setInitialTokenAdd] = useState('')
  const [farmPoolData, setFarmPoolData] =  useState({
    totalAllocPoint: 365,
    gammaPerBlock: 1614583333333333200, 
    tokenSwapList: tokenSwapList,
    tokenSwapListWithUserBalances: tokenSwapList
  })
  const { portfolioLoading, userPortfolio, gammaInfDataNew } = selector.user
  const { ethWalletConnected, address } = selector.ethData
  const { gPlatformDataNew, userAssetData , gPlatformLoading, borrowLimitLoading, borrowLimitNew, addNullLoading } = selector.lending
  const gPlatformData: any = gPlatformDataNew;
  const borrowLimit: any = borrowLimitNew;
  useEffect(() => {
    ReactTooltip.rebuild();
  })

  useEffect(() => {
    if(gammaInfDataNew != undefined && Object.keys(gammaInfDataNew).length > 0 && userAssetData.marketData.find((obj: any) => obj.name.toLowerCase() == "gamma infinity") == undefined){
      // console.log(userAssetData)
      // userAssetData.marketData.push(gammaInfDataNew)
      // userAssetData.total_lending_tvl += gammaInfDataNew.tvl;
      setGammaInfinityVault(gammaInfDataNew)
    }
    
  })

  useEffect(() => {
    const updateTokenListWithBalances = async () => {
        const userAddress = selector.ethData.address
        try {
          const res = await returnTokenList(userAddress)
          if (!!res && typeof res !== 'undefined') {
            farmPoolData.tokenSwapListWithUserBalances = res;
          }
        } catch (error) {
          console.log('error===>', error)
        }
    }
    updateTokenListWithBalances()
}, [selector.ethData.address])

  useEffect(() => {
    // console.log(userAssetData)
    if (!selector.ethData.address) {
      setSuppLevel(0)
      setBorLevel(0)
    }
    else {
      if (!!gPlatformData) {
        const currLevel = parseInt(gPlatformData.currentLevel)
        if (!!currLevel && !isNaN(currLevel)) {
          setSuppLevel(currLevel)
          setBorLevel(currLevel)
        }

      }
    }
  }, [selector.ethData.address, gPlatformData])

  const handleBuyGamma = () => {
    history.push(`${Paths.swap}`)
  }

  const handleSupplyModal = (itemData: any) => {
    // console.log("token details", itemData)
    setIsAllowance(itemData.underlyingTokenAllowance)
    setModalData(itemData)
    // setShowAssetSupply(true)
    if (itemData.name.toLowerCase() == "gamma infinity") {
      setModalData(itemData)
      setShowPoolDetails(true)
    } else if (itemData.underlyingTokenAllowance){
      setShowAssetSupply(true)
      setShowVaultDetails(true)
    }
    else {
        setShowEnableAsset(true)
    }
  }

  const handleNotification = (type: string, hash?: any,) => {
    const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
    const dataVal: any = {
      type: type,
      message: `Claim ${type}`,
      link: linkUrl
    }
    dispatch(notificationClose())
    dispatch(notificationOpen(dataVal))
  }

  const handleClaim = async () => {
    if (selector.ethData.ethWalletConnected) {
      try {
        setClaimLoading(true)
        const res = await claimGamma(selector.ethData.address, userAssetData)
        if (!!res && typeof res !== 'undefined' && Object.values(res).length > 0) {
          const type = res.status ? 'success' : 'failed'
          if (res.status) {
            handleNotification(type, res.transactionHash)
            dispatch(updateUserBalance(selector.ethData.address))
            dispatch(updateUserLendingData(selector.ethData.address))
          }
          else {
            handleNotification('failed')
          }
        }
        else {
          handleNotification('failed')
        }
      }
      catch (error) {
        console.log("error=>", error)
        handleNotification('failed')
      }
      finally {
        setClaimLoading(false)
      }
    }
  }

  useEffect(() => {
    if (timerForGreenPendingGAMMA) {
        clearInterval(timerForGreenPendingGAMMA); 
    }
    if(userPortfolio.greenPendingGAMMA)
    {
        startTimerTripping();
    }
    return () => clearInterval(timerForGreenPendingGAMMA);
  }, [userPortfolio])

  const startTimerTripping = () => {
    if(userPortfolio.greenPendingGAMMA)
    {
      userPortfolio.greenPendingGAMMA = userPortfolio.greenPendingGAMMA ? userPortfolio.greenPendingGAMMA : 0;
      setGreenPendingGAMMA(userPortfolio.greenPendingGAMMA)
      setGreenPendingGAMMAUSD((userPortfolio.greenPendingGAMMA * userPortfolio.gammaPrice).toFixed(2).toString())
      clearInterval(timerForGreenPendingGAMMA)
      gPlatformData.userTotalSupplyBal = gPlatformData.userTotalSupplyBal ? gPlatformData.userTotalSupplyBal : 0;
      let apy = gPlatformData.apyWithoutInfinityVaults ? gPlatformData.apyWithoutInfinityVaults : 0;
      let m: any = 365
      let a: any = apy / 100 + 1
      let apr = (Math.pow(a, 1 / m) - 1) * m
      // need to change following code based tripping criteria
      // supply balance * (aprWithoutInfinityVaults) / (365 * 86400)
      let trippingGreenPendingGAMMA:any = apr > 0 ? parseFloat(gPlatformData.userTotalSupplyBal) * (apr) / (365 * 86400) : 0;

      let now = new Date();
      let timeWhenStarted = now.getTime();
      timerForGreenPendingGAMMA = setInterval(function () {
          now = new Date();
          let newGreenPendingGAMMA =  parseFloat(userPortfolio.greenPendingGAMMA) + (( now.getTime() - timeWhenStarted ) * trippingGreenPendingGAMMA / 1000);
          // console.log("newGreenPendingGAMMA in v2", newGreenPendingGAMMA)
          let pendingGammaUsd = newGreenPendingGAMMA * userPortfolio.gammaPrice;
          if(newGreenPendingGAMMA !== undefined){
              setGreenPendingGAMMA(newGreenPendingGAMMA+"")
              setGreenPendingGAMMAUSD(pendingGammaUsd+"")
          }
          
      }, 1000)
    }
  }

  const togglePoolDetailsModal = () => {
    setModalData({})
    setShowPoolDetails(false)
  }

  const handleGetLpToken = (transData: any, type: any, inputAmount: any) => {
    // console.log("handleShowPoolDetails", transData)
    if(transData.poolStatus == "active" || transData.protocol.toLowerCase() == 'thena'){
        // console.log("pool action in pool table ", type, transData, inputAmount)
        setTransData(transData)
        setShowTCAModal(true)
        setLPAction(type)
        setTokenSwapAmount(inputAmount)
    } else {
        history.push(`${Paths.liquidity}`)
    }
   
}

const showSwapPopup = async (data: any, swapAmount: any) => {
  setShowSwapPopUp(true)
  // console.log("pool data", data)
  let tokenNumber : any = data.poolId == 0 ? 2 : 0;
  setReceiveTokenNumber(tokenNumber)
  setTokenSwapAmount(swapAmount)
}

  const getGammaValFormat = (val: any, gammaEarning?: boolean) => {
    let apy = gPlatformData.apyWithoutInfinityVaults;
    let m: any = 365
    let a: any = apy / 100 + 1
    let apr = (Math.pow(a, 1 / m) - 1) * m
    const gammaVal: any = parseFloat(val).toFixed(4).toString().split('.')
    if(gammaVal !== undefined && gammaVal === gammaVal && +gammaVal[0] === +gammaVal[0]) {
      if(gammaEarning === true){
        return <HeaderNumTxt className="gammaEarnings">{commaFy(gammaVal[0])}.<span className="gammaEarnings">{gammaVal[1]}</span></HeaderNumTxt>
      } else {
        return <HeaderNumTxt>{commaFy(gammaVal[0])}.<span>{gammaVal[1]}</span></HeaderNumTxt>
      }
    } else {
      return <HeaderNumTxt>0.<span>0000</span></HeaderNumTxt>
    }
      
  }
  const getInfoNumFormat = (val: any) => {
    const newVal: any = parseFloat(val).toFixed(2).toString().split('.')
    return <InfoNumberTxt className="">${commaFy(newVal[0])}.<span>{newVal[1]}</span></InfoNumberTxt>
  }
  const getAvailableCredit = (val: any) => {
    const newVal: any = parseFloat(val).toFixed(2).toString().split('.')
    // return <SliderText className="top-space">${commaFy(newVal[0])}.<span>{newVal[1]}</span></SliderText>
    return "$"+commaFy(newVal[0]);
  }

  const handleSuppLevel = (levelVal: number) => {
    if (suppLevel !== levelVal) {
      setSuppLevel(levelVal)
    }
  }
  const handleBorLevel = (levelVal: number) => {
    if (borLevel !== levelVal) {
      setBorLevel(levelVal)
    }
  }

  const describeArc = (x: any, y: any, radius: any, startAngle: any, platformData: any) => {
    let endAngle = (+platformData.userGammaSupplied * 360)/(+platformData.minGammaUpperLevel - +platformData.minGammaCurrentLevel);
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", start.x, start.y, 
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");
    return d;       
  }
  const polarToCartesian = (centerX: any, centerY: any, radius:any, angleInDegrees: any) => {
    var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }

  const showToolTipModal = async(title: any, message: any) =>{
    setShowTooltipModal(!showToolTip);
    setToolTipTitle(title);
    setToolTipMessage(message)
  }
  const discountLevelText = () => {
    return (
      <ToolTipDataCont>
        <ToolTipText>There are 3 discount levels that can be reached by supplying GAMMA.</ToolTipText>
        <ul>
          <li>Level 1: 5% off protocol fees</li>
          <li>Level 2: 20% off protocol fees</li>
          <li>Level 3: 50% off protocol fees</li>
        </ul>
      </ToolTipDataCont>
    );
  }

  const toggleVaultDetailsModal = () => {
    setModalData({})
    setShowVaultDetails(false)
  }

  const boostCalculationPopup = async() => {
    console.log("user asset data", userAssetData)
    setModalData(userAssetData.marketData[0])
    setBoostPopup(true)
  }

  const handleShowVaultDetails = (modalDataVal: any) => {
    setModalData(modalDataVal)
    setShowVaultDetails(true)
  }

  const toggleBoostPopupModal = () => {
    setModalData({})
    setBoostPopup(false)
  }

  const toggleSwapPopupModal = () => {
    // setModalData({})
    setShowSwapPopUp(false)
  }

  const closeSwapModal = () => {
    setShowSwapPopUp(false)
  }

  const handleUnstakePopUpToggleModal = () => {
    setShowUnstakePopUp(false)
  } 

  const updateTokenList = async () => {
    const userAddress = selector.ethData.address
    try {
      const res = await returnTokenList(userAddress)
      if (!!res && typeof res !== 'undefined') {
        setTokenDataList(res)
      }
    } catch (error) {
      console.log('error===>', error)
    }
}

  return (
    <FarmPoolContext.Provider value={farmPoolData}>
    <MainContainer className='lending'>
      <WebBlock>

        <DataInfoContNew className='lending_stats_card'>
          <InfoBoxWrapper className="totalMarketSizeCard">
            <InfoBoxNew className="info-box">
              <HeaderTxt className='totalMarketSize '>
                ${userAssetData.total_lending_tvl !== undefined ? commaFy(userAssetData.total_lending_tvl.toFixed(0)) : 0}
              </HeaderTxt>
              <FlexCont className='LendingStats' onClick={() => showToolTipModal("Collateral Value", "Holding 5% of your Planet pools portfolio in GAMMA-BTCB makes you eligible for GAMMA farm rewards. Beyond the minimum 5%, the more you hold the more you earn.")}>
                <InfoLightText className='collateralText cursor_pointer' onClick={() => showToolTipModal("Collateral Value", "This is the total value of all assets you have supplied and have collateral turned on for.")}>TOTAL MARKET SIZE</InfoLightText>
                <ToolTipIcon className="tooltip-web" src={QuestionIcon} alt=""  left={3} top={6} />
              </FlexCont>
            </InfoBoxNew>
          </InfoBoxWrapper>
          <DataInfoBoxNew className="DataInfoBox">
            
            <InfoBoxWrapper className="InfoBoxWrapper">
              <InfoBoxNew className="info-box">
                <InfoNumberTxtNew>
                  {gPlatformLoading ? <LoadingText3 width={80} /> :
                  getInfoNumFormat(!!gPlatformData && gPlatformData.suppliedAmountWithCollateral ? gPlatformData.suppliedAmountWithCollateral : 0)
                  }
                </InfoNumberTxtNew>
                <FlexCont className='LendingStats' onClick={() => showToolTipModal("Collateral Value", "This is the total value of all assets you have supplied and have collateral turned on for.")}>
                  <InfoLightText className='collateralText cursor_pointer' onClick={() => showToolTipModal("Collateral Value", "This is the total value of all assets you have supplied and have collateral turned on for.")}>COLLATERAL</InfoLightText>
                  <ToolTipIcon className="tooltip-web" src={QuestionIcon} alt=""  left={3} top={6} />
                </FlexCont>
              </InfoBoxNew>
              <InfoBoxNew className="info-box spaceInLendingStats">
                <InfoNumberTxtNew>
                  {gPlatformLoading ? <LoadingText3 width={80} /> :
                  getInfoNumFormat(!!gPlatformData && gPlatformData.userTotalBorrowBal ? gPlatformData.userTotalBorrowBal : 0)
                  }
                </InfoNumberTxtNew>
                <FlexCont className='LendingStats spaceInLendingStats' onClick={() => showToolTipModal("Borrow Balance", "This is the total value of assets you’ve borrowed from the market.")}>
                  <InfoLightText className='collateralText cursor_pointer'>BORROW</InfoLightText>
                  <ToolTipIcon className="tooltip-web" src={QuestionIcon} alt="" left={3} top={6} />
                </FlexCont>
              </InfoBoxNew>
              <InfoBoxNew className="info-box spaceInLendingStats">
                {gPlatformLoading ? <LoadingText3 width={80} /> :
                  <InfoNumberTxtNew>{getInfoNumFormat(!!gPlatformData && gPlatformData.dailyEarnings ? gPlatformData.dailyEarnings : 0)}</InfoNumberTxtNew>
                }
                <FlexCont className='LendingStats' onClick={() => showToolTipModal("NET DAILY INTEREST", "An estimate of your true daily earnings. This includes any interest you're earning on Green Planet minus any interest you're accruing on borrowed assets.")} >
                  <InfoLightText className='collateralText cursor_pointer'>DAILY EARNINGS</InfoLightText>
                  <ToolTipIcon className="tooltip-web"  src={QuestionIcon} alt="" left={10} top={6} />
                </FlexCont>
              </InfoBoxNew>
              <InfoBoxNew className="info-box spaceInLendingStats">
                {gPlatformLoading ? <LoadingText3 width={80} /> :
                  <NetApyText netApy={gPlatformData.userNetApyWithGamma} isNegative={parseFloat(gPlatformData.userNetApyWithGamma) < 0}>{ gPlatformData.userNetApyWithGamma && parseFloat(gPlatformData.userNetApyWithGamma) > 0 ? "+"+parseFloat(gPlatformData.userNetApyWithGamma).toFixed(2) : gPlatformData.userNetApyWithGamma && parseFloat(gPlatformData.userNetApyWithGamma) < 0 ? parseFloat(gPlatformData.userNetApyWithGamma).toFixed(2) : "0.00"}% {/*<img className="tooltip-web" src={QuestionIcon} onClick={() => boostCalculationPopup()} alt="" />*/}</NetApyText>
                }
                <FlexCont className='netApyWeb LendingStats' onClick={() => showToolTipModal("Net Apy", "Your estimated total percentage earned on Green Planet. This % factors in all rewards being earned on your collateral minus any interest you're paying on borrowed assets.")}>
                  <InfoLightText className='collateralText cursor_pointer'>NET APY</InfoLightText>
                  <ToolTipIcon className="tooltip-web" src={QuestionIcon} alt="" left={3} top={6} />
                </FlexCont>

              </InfoBoxNew>
              
            </InfoBoxWrapper>
            
          </DataInfoBoxNew>
          <DataInfoBoxNew className="gammaEarningsCard">
            <InfoBoxWrapper className="InfoBoxWrapper gammaEarningsCard">
              <InfoBox>
                  <FlexCont>
                      <HeaderInfo>
                        <FlexCont onClick={() => showToolTipModal("GAMMA Earnings", "This is your uncollected GAMMA earned from supplying and borrowing assets. You can press collect to claim your GAMMA.")} >
                          <HeaderTxt className='gammaEarning cursor_pointer'>Earnings</HeaderTxt>
                          <ToolTipIcon className="tooltip-web" src={QuestionIcon} alt="" left={10} onClick={() => showToolTipModal("GAMMA Earnings", "The total GAMMA you have in your wallet, supplied to markets and in liquidity pools.")} />
                        </FlexCont>
                        {portfolioLoading ? <LoadingText2 top={10} bottom={10} /> :
                          getGammaValFormat(!!userPortfolio && userPortfolio.greenPendingGAMMA && +greenPendingGAMMA > 0 ? greenPendingGAMMA : 0, true)
                        }
                        {portfolioLoading ? 
                          <div>
                              <LoadingText1 />
                          </div>
                          :
                          <HeaderTxt className='gammaEarningsUSD'>
                            ${(!!userPortfolio && userPortfolio.greenPendingUsd ? commaFy(parseFloat(greenPendingGAMMAUSD).toFixed(2)) : '0.00 ')}
                          </HeaderTxt>
                          
                        }
                        
                      </HeaderInfo>
                      
                      
                  </FlexCont>
              </InfoBox>
              <FlexCont>
                <HeaderBtn className="collectGamma" onClick={() => handleClaim()}>Collect</HeaderBtn>
              </FlexCont>
            </InfoBoxWrapper>
          </DataInfoBoxNew>
        </DataInfoContNew>
        <DataInfoContNew className="DataInfoContNew">
          <DataInfoBoxNew className="DataInfoBox borrowCard">
                <FlexSBCont>
                  {borrowLimitLoading ? 
                  <LoadingText1 width={10} /> 
                  : 
                  <SliderText className='borrow_value'>{borrowLimit.borrowLimitUsedCurr ? parseFloat(borrowLimit.borrowLimitUsedCurr).toFixed(2) : "0.00"}%</SliderText>}
                  {borrowLimitLoading ? <LoadingText1 width={10} /> : <FlexCont>
                    <SliderText light className='borrow_value'>100% (MAX)</SliderText>
                  </FlexCont>}
                </FlexSBCont>
                <SliderContainer borrowLimit={borrowLimit.borrowLimitUsedCurr} className={+borrowLimit.borrowLimitUsedCurr > 75 && +borrowLimit.borrowLimitUsedCurr < 90 ? 'SliderContainer OrangeLimit': +borrowLimit.borrowLimitUsedCurr > 90 ? 'SliderContainer RedLimit' : 'SliderContainer'}>
                  <Slider min={0} max={100} value={borrowLimit.borrowLimitUsedCurr ? borrowLimit.borrowLimitUsedCurr : 0} tooltip={false} />
                </SliderContainer>
                <FlexSBCont>
                  <FlexCont>
                    <SliderText light className='cursor_pointer borrow_usage borrowUsageText' onClick={() => showToolTipModal("Borrow Usage", "This is the percentage of your borrow limit used. This can fluctuate as the value of your collateral and borrowed assets change in price.")} >Borrow Limit</SliderText>
                    <ToolTipIcon className="tooltip-web" src={QuestionIcon} alt="" left={10} onClick={() => showToolTipModal("Borrow Usage", "This is the percentage of your borrow limit used. This can fluctuate as the value of your collateral and borrowed assets change in price.")} />
                  </FlexCont>
                  <FlexCont className='borrow-limit'>
                    <SliderText light className='cursor_pointer borrow_usage' onClick={() => showToolTipModal("Borrow Limit", "The maximum value as a % that you can borrow against your collateral. If this were to hit 100% you would be subject to liquidation with a penalty up to 10% of your collateral.")}
                    >Availble Credit: ${!!gPlatformData && gPlatformData.borrowLimitInUsd ? commaFy(parseFloat(gPlatformData.borrowLimitInUsd).toFixed(2)) : 0.00}</SliderText>
                    <ToolTipIcon className="tooltip-web" src={QuestionIcon} alt="" left={10} onClick={() => showToolTipModal("Borrow Limit", "The maximum value as a % that you can borrow against your collateral. If this were to hit 100% you would be subject to liquidation with a penalty up to 10% of your collateral.")} />
                    
                  </FlexCont>
                </FlexSBCont>
          </DataInfoBoxNew>
          {/* <DataInfoBox className='GammaEarning'>
              <InfoBox>
                <FlexCont>
                  <img className="lending-gamma-icon" src={GammaIcon} alt="" />
                  <FlexCont className="flexColumn">
                    <HeaderNumTxt className="gammaText">
                    GAMMA
                    </HeaderNumTxt>
                    <HeaderNumTxt className="gammaSubText">
                    {gammaInfDataNew && gammaInfDataNew.tvl ? "$" + commaFy(convertToKAndM(gammaInfDataNew.tvl.toFixed(0)).toString()): ""} TVL
                    </HeaderNumTxt>
                  </FlexCont>

                  <FlexCont className="flexColumn">
                    <HeaderBtn className="lendingGammaApyBtn"> {gammaInfDataNew && gammaInfDataNew.apy ? commaFy(convertToKAndM(gammaInfDataNew.apy.toFixed(2)).toString()): "0.00"}% APY</HeaderBtn>
                    <HeaderNumTxt className="gammaBoostSubText">
                    Boost Generator
                    </HeaderNumTxt>
                  </FlexCont>
                  
                  <HeaderBtn className="stakeGamma" onClick={() => handleSupplyModal(gammaInfinityVault)}>Stake</HeaderBtn>
                  
                </FlexCont>
                
              </InfoBox>
          </DataInfoBox> */}
        </DataInfoContNew>
       
        <TableCont className='supplyBorrow'>
          <div>
            {/* {userAssetData.marketData.length > 0 &&
              <SuppliedAsset data={userAssetData.marketData} currLevel={suppLevel} />
            } */}
            <SuppliedAsset data={userAssetData.marketData} currLevel={suppLevel} />
            {/* <SupplyMarket data={userAssetData.marketData} currLevel={suppLevel} handleLevelClick={handleSuppLevel} /> */}
          </div>
          <div>
            {/* {userAssetData.borrowedAssets.length > 0 &&
              <BorrowedAsset data={userAssetData.borrowedAssets} currLevel={borLevel} />
            } */}
              <BorrowedAsset data={userAssetData.marketData} currLevel={borLevel} />
            {/* <BorrowMarket data={(userAssetData.marketData).filter((e: any) => e.borrowPaused === false)} currLevel={borLevel} handleLevelClick={handleBorLevel} /> */}
          </div>
          {/* <CustomModal show={showAssetSupply} toggleModal={toggleVaultDetailsModal}>
                {showAssetSupply && (
                  <AssetSupplyRevised
                    data={modalData}
                    setShowAssetSupply={setShowAssetSupply}
                    toggleVaultModal={toggleVaultDetailsModal} setModalData={setModalData} currentTab={"all"}
                  />
                )}
          </CustomModal> */}
          <CustomModal show={showCollateral} toggleModal={setShowCollateral}>
              {showCollateral && (
                  <Collateral
                      setShowCollateral={setShowCollateral}
                      data={collateralData}
                      enableCollateral={!collateralData.isUsingAsCollateral}
                  />
              )}
          </CustomModal>
          {<CustomActionModal show={showVaultDetails} toggleModal={toggleVaultDetailsModal}>
            {showVaultDetails && (
                <AssetSupplyRevised
                    data={modalData}
                    setShowAssetSupply={setShowAssetSupply}
                    toggleVaultModal={toggleVaultDetailsModal} setModalData={setModalData} currentTab={"all"}
                />
            )}
          </CustomActionModal>}
          <CustomModal show={showEnableAsset} toggleModal={setShowEnableAsset}>
            {showEnableAsset && (
                <ActivateAsset
                    data={modalData}
                    setShowEnableAsset={setShowEnableAsset}
                    setShowAssetSupply={setShowAssetSupply}
                    setIsAllowance={setIsAllowance}
                />
            )}
          </CustomModal>
          <CustomActionModal show={boostPopup} toggleModal={toggleBoostPopupModal}>
            {boostPopup && <BoostCalculationModal toggleVaultModal={toggleBoostPopupModal} data={modalData} setModalData={setModalData} />}
          </CustomActionModal>
          <CustomActionModal show={showPoolDetails} toggleModal={togglePoolDetailsModal}>
            {showPoolDetails && 
            <PoolDetailsModalForLending
              togglePoolModal={togglePoolDetailsModal} 
              data={modalData} 
              handleGetLpToken={handleGetLpToken} 
              setModalData={setModalData} 
              setTokenSwapAmount={setTokenSwapAmount}
              currentTab={"all"} 
              sendToken={sendToken}
              tokenDataList={tokenDataList}
              setSendTokenFromParent={setSendToken}
              showSwapPopup={showSwapPopup}
              setUnstakeData={setUnstakeData}
              setShowUnstakePopUp={setShowUnstakePopUp}
              confirmUnstake={confirmUnstake}
              setConfirmUnstake={setConfirmUnstake}
              showUnstakePopUp={showUnstakePopUp}
            />}
          </CustomActionModal>
          <CustomModal show={showUnstakePopUp} toggleModal={handleUnstakePopUpToggleModal}>
              <UnStakeInstantlyPopUp 
                  setShowUnstakePopUp={setShowUnstakePopUp} 
                  showUnstakePopUp={showUnstakePopUp}
                  unstakeData={unstakeData}
                  setConfirmUnstake={setConfirmUnstake}
              />
          </CustomModal>
          <CustomActionModal show={showSwapPopUp} toggleModal={toggleSwapPopupModal}>
              {showSwapPopUp && <SwapInPools tokenList={tokenDataList} updateTokenList={updateTokenList} initialTokenAdd={initialTokenAdd} receiveTokenNumber={receiveTokenNumber} closeSwapModal={closeSwapModal} tokenSwapAmount={tokenSwapAmount} toggleSwapPopupModal={toggleSwapPopupModal} sendTokenObject={sendToken} />}
          </CustomActionModal>
          <ToolTipModal 
            showToolTip={showToolTip}
            toggleToolTipModal={setShowTooltipModal}
            title={toolTipTitle}
            message={toolTipMessage} 
          />
        </TableCont>
      </WebBlock >
      <MobileBlock>
        <MobLending claimLoading={claimLoading} handleClaim={handleClaim} suppLevel={suppLevel} borLevel={borLevel} />
      </MobileBlock>
      <ReactTooltip className="planet-tool-tip" id="gammaBalanceInfo" place="top" effect="solid">
        <ToolTipText>The total GAMMA you have in your wallet, supplied to markets and in liquidity pools.</ToolTipText>
      </ReactTooltip>
      <ReactTooltip className="planet-tool-tip" id="gammaEarnedInfo" place="top" effect="solid">
        <ToolTipText>This is your uncollected GAMMA earned from supplying & borrowing assets. You can press collect to claim your GAMMA.</ToolTipText>
      </ReactTooltip>
      <ReactTooltip className="planet-tool-tip" id="totalMarketSizeInfo" place="top" effect="solid">
        <ToolTipText>The total value of all assets supplied.</ToolTipText>
      </ReactTooltip>
      <ReactTooltip className="planet-tool-tip" id="supplyBalanceInfo" place="top" effect="solid">
        <ToolTipText>This is the total value of assets you’ve supplied to the market.</ToolTipText>
      </ReactTooltip>
      <ReactTooltip className="planet-tool-tip" id="borrowBalanceInfo" place="top" effect="solid">
        <ToolTipText>This is the total value of assets you’ve borrowed from the market.</ToolTipText>
      </ReactTooltip>
      <ReactTooltip className="planet-tool-tip" id="netApyInfo" place="top" effect="solid">
        <ToolTipText>Your total estimated annualized interest rate, of all assets supplied and borrowed at the current market rates. <br />
          (Estimated Annualized Earnings/Supply Balance) * 100</ToolTipText>
      </ReactTooltip>
      <ReactTooltip className="planet-tool-tip" id="borrowLimitInfo" place="top" effect="solid">
        <ToolTipText>The maximum value as a % that you can borrow against your collateral. If this were to hit 100% you
          <br /> would be subject to liquidation with a penalty up to 10% of your collateral.</ToolTipText>
      </ReactTooltip>
      <ReactTooltip className="planet-tool-tip" id="availableCreditInfo" place="left" effect="solid">
        <ToolTipText>The maximum dollar amount you can borrow against your collateral. If this were to hit $0.00 you
          <br />would be subject to liquidation with a penalty up to 10% of your collateral.</ToolTipText>
      </ReactTooltip>
      <ReactTooltip className="planet-tool-tip" id="currentLevelInfo" place="left" effect="solid" multiline={true}>
        <ToolTipDataCont>
          <ToolTipText>There are 3 discount levels that can be reached by supplying GAMMA.</ToolTipText>
          <ul>
            <li>Level 1: 5% off protocol fees</li>
            <li>Level 2: 20% off protocol fees</li>
            <li>Level 3: 50% off protocol fees</li>
          </ul>
        </ToolTipDataCont>
      </ReactTooltip>
      <ReactTooltip className="planet-tool-tip" id="stackRatioInfo" place="left" effect="solid" multiline={true}>
        <ToolTipDataCont>
          <ToolTipText>This refers to the USD value of your GAMMA supplied, as a percentage, relative to the USD value of
            <br />assets excluding GAMMA that you’re supplying. This is how your Discount Level is calculated.</ToolTipText>
          <ul>
            <li>Level 1: (1-4.99%)</li>
            <li>Level 2: (5-9.99%)</li>
            <li>Level 3: (Greater than 10%)</li>
          </ul>
        </ToolTipDataCont>
      </ReactTooltip>
    </MainContainer >
    </FarmPoolContext.Provider>
  )
}

export default Lending
