import { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import { CardCont, FlexSBCont, FlexCont, TitleText, InfoLDText, InfoSmallText, BackButton, InfoText } from '../style'
import { RemoveLDCont, PercentageText, PercentageBtnCont, PercentageBox, RemoveTokenCont, RemoveLDBtn } from './style'
import ClockIcon from 'assets/icons/clock-icon.png'
import DownIcon from 'assets/icons/down-arrow-icon.png'
import RangeSlider from 'shared/range-slider'
import Spinner from 'shared/spinner'
import CustomModal from 'shared/custom-modal'
import TransactionInfo from 'shared/transaction-info'
import { transLinkUrl } from 'service/global-constant'
import { setTransLink, setConfirmTransLink } from '../swapMethods'
import { convertToWei, DEADLINE, instanceType, permitResult, selectInstance, tokenAllowance } from 'modules/block-chain/Swap'
import { wbnbAddress, PLANETROUTERADDRESS } from 'modules/block-chain/SwapDexAbi'
import { maxAmount } from 'modules/block-chain/lendingAbi';
import ArrowDownGrey from 'assets/icons/down_grey_icon.svg'
import {
  AddLDCont,
  AddLDTokenCont,
  AddLDCard,
  PluIconCont,
  TokenSelectCont,
  PoolDetailsCont,
  PoolInfoText,
  ApproveBtn,
  AddLDBtnCont,
} from './style'
import {
  // CardCont,
  // FlexSBCont,
  // FlexCont,
  // TitleText,
  // InfoText,
  // InfoSmallText,
  // SelectCurrTxt,
  // InfoLDText,
  TokenValInput,
  MaxTxt,
  MaxTxtZero,
  TokenName,
  // BackButton,
  PrimaryBtn,
} from '../style'
import BUSDIcon from 'assets/icons/BUSD.png'
import USDCIcon from 'assets/icons/USDC.png'
import USDTIcon from 'assets/icons/USDT.png'
import stabkeMakrketImage from 'assets/icons/stable.png'
import stableSwapIcon from 'assets/icons/stable-swap-icon.png'
import stableSwap3GIcon from 'assets/icons/3g-icon.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { LoadingText1, LoadingText3 } from 'shared/styles/planetTableStyle'
import { commaFy } from 'service/globalFunctions'

const RemoveLiquidity = (props: any) => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const [ldPercentage, setLdPercentage] = useState(0)
  // const [removeLoading, setRemoveLoading] = useState(false)
  const [showTransModal, setShowTransModal] = useState(false)
  const [transactionLink, setTransactionLink] = useState('')
  const [bncWApproval, setBncWApproval] = useState(false)
  const [approveLoading, setApproveLoading] = useState(false)
  const [removeAmount, setRemoveAmount] = useState('');
  const [displayRemoveAmount, setDisplayRemoveAmount] = useState('');
  const { setLiquidityTab, data, updateLiquidity, setShowHistory, stableSwapObject, tokenArray, setConfirmRemoveBtn, setRemoveAmountVal, setRemoveLiquidityToken, setRemoveTokenIndex, removeTokenIndex, setInsufficientBalanceCheck, setRemoveLoading,removeLoading, resetInput } = props
  
  useEffect(() => {
    if(resetInput){
      setRemoveAmount('')
      setDisplayRemoveAmount('');
    }
    
  }, [selector.ethData.address, resetInput])

  const handlePercentage = (val: number) => {
    setLdPercentage(val)
  }

  const handleTransactionLink = (newLink: any, hashVal: any) => {
    const dataVal: any = {
      firstToken: data.name.split('/')[0],
      secondToken: data.name.split('/')[1],
      amount1: ((parseFloat(data.pooledToken0) * ldPercentage) / 100).toFixed(4),
      amount2: ((parseFloat(data.pooledToken1) * ldPercentage) / 100).toFixed(4),
      link: newLink,
      type: 'removeLiquidity',
      status: false,
      hashVal: hashVal,
      transStatus: 'pending',
    }
    setTransLink(dataVal)
  }
  const handleNotification = (type: string, hash?: any) => {
    const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
    const firstToken = data.name.split('/')[0]
    const secondToken = data.name.split('/')[1]
    const amount1 = ((parseFloat(data.pooledToken0) * ldPercentage) / 100).toFixed(4)
    const amount2 = ((parseFloat(data.pooledToken1) * ldPercentage) / 100).toFixed(4)
    const dataVal: any = {
      type: type,
      message: `Remove liquidity for ${amount1} ${firstToken} and ${amount2} ${secondToken}`,
      link: linkUrl
    }
    dispatch(notificationClose())
    dispatch(notificationOpen(dataVal))
  }

  const handleApprove = async () => {
    try {
      setApproveLoading(true)
      const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, data.address)
      const approvalAmount = maxAmount
      await TOKEN_INSTANCE.methods
        .approve(PLANETROUTERADDRESS, approvalAmount)
        .send({ from: selector.ethData.address })
        .once('transactionHash', function (res: any) {
          if (!!res && typeof res) {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          if (receipt && typeof receipt !== 'undefined') {
            setApproveLoading(false)
            setBncWApproval(false)
            //setLdPercentage(0)
            //updateLiquidity()
            // setLiquidityTab('liquidity')
          }
        })
        .on('error', function (error: any) {
          setTransactionLink('')

          setShowTransModal(false)
        })
    } catch (error) {
      setShowTransModal(false)
    } finally {
      setApproveLoading(false)
    }
  }

  const handleRemoveLiquidity = async () => {
    try {
      setRemoveLoading(true)
      await removeLiquidity(data.address, data.token0, data.token1, ldPercentage, selector.ethData.address, data.yourPoolToken)
    } catch (error) {
      console.log('error==>', error)
    } finally {
      setRemoveLoading(false)
    }
  }

  const removeLiquidity = async (pair: any, tokenA: any, tokenB: any, ldPercentage: any, to: any, lpBalance: any) => {
    let percentage = (ldPercentage / 100)
    let liquidityInEther: any;
    if (ldPercentage === 100)
      liquidityInEther = lpBalance
    else
      liquidityInEther = lpBalance * percentage
    const allowance: any = await tokenAllowance(pair, to)
    if (liquidityInEther > allowance) {
      const permit: any = await permitResult(pair, to, convertToWei(liquidityInEther))
      if (permit && permit !== 'undefined') {
        if (tokenA === wbnbAddress || tokenB === wbnbAddress) {
          await _removeLiquidity(tokenA, tokenB, liquidityInEther, to, true, 0, permit)
        } else {
          await _removeLiquidity(tokenA, tokenB, liquidityInEther, to, true, 1, permit)
        }
      }
    } else {
      if (tokenA === wbnbAddress || tokenB === wbnbAddress) {
        await _removeLiquidity(tokenA, tokenB, liquidityInEther, to, false, 0)
      } else {
        await _removeLiquidity(tokenA, tokenB, liquidityInEther, to, false, 1)
      }
    }
  }

  const _removeLiquidity = async (
    tokenA: any,
    tokenB: any,
    liquidityInEther: any,
    to: any,
    needPermit: boolean,
    flag: any,
    permit?: any
  ) => {
    /*
     * needPermit = true when permit need
     * needPermit = false when permit not needed
     * flag = 0 for bnb
     * flag = 1 for others
     */
    let type: boolean
    if (flag === 0) type = true
    else type = false

    let deadline = Math.floor(Date.now() / 1000) + DEADLINE
    let liquidityTokenDesiredInWei: any

    const promisify = (inner: any) =>
      new Promise((resolve, reject) =>
        inner((err: any, res: any) => {
          if (err) {
            reject(err)
          }
          return resolve(res)

        })
      )

    const ROUTER_INSTANCE = await selectInstance(instanceType.PLANETROUTER)
    if (type) {
      let desiredToken: any
      liquidityTokenDesiredInWei = convertToWei(liquidityInEther)
      if (tokenA === wbnbAddress) {
        desiredToken = tokenB
      } else {
        desiredToken = tokenA
      }

      if (!needPermit) {
        await promisify((cb: any) => {
          ROUTER_INSTANCE.methods
            .removeLiquidityETH(desiredToken, liquidityTokenDesiredInWei, 0, 0, to, deadline)
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      } else {
        await promisify((cb: any) => {
          ROUTER_INSTANCE.methods
            .removeLiquidityETHWithPermit(
              desiredToken,
              liquidityTokenDesiredInWei,
              0,
              0,
              to,
              permit.deadline,
              false,
              permit.v,
              permit.r,
              permit.s
            )
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      }
    } else {
      liquidityTokenDesiredInWei = convertToWei(liquidityInEther)
      if (!needPermit) {
        await promisify((cb: any) => {
          ROUTER_INSTANCE.methods
            .removeLiquidity(tokenA, tokenB, liquidityTokenDesiredInWei, 0, 0, to, deadline)
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      } else {
        await promisify((cb: any) => {
          ROUTER_INSTANCE.methods
            .removeLiquidityWithPermit(
              tokenA,
              tokenB,
              liquidityTokenDesiredInWei,
              0,
              0,
              to,
              permit.deadline,
              false,
              permit.v,
              permit.r,
              permit.s
            )
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      }
    }
  }

  const calculateTokenVal = (type: any) => {
    let val: any = '0.00'
    if (parseFloat(parseFloat(data.pooledToken0) > 0 && data.pooledToken1) > 0) {
      if (type === 'first') {
        val = parseFloat(data.pooledToken1) / parseFloat(data.pooledToken0)
      } else {
        val = parseFloat(data.pooledToken0) / parseFloat(data.pooledToken1)
      }
    }
    return parseFloat(val).toFixed(4)
  }

  const handleInputChange = async (e: any) => {
    let value: any = e.target.value;//e.nativeEvent.data;//e.target
    // console.log(value);
    if(value == "" && removeAmount.length == 1){
      // console.log("in here");
      value = "";
      setRemoveAmount(value)
      setRemoveAmountVal(value)
      setDisplayRemoveAmount(value);
    }
    if(+value === 0 ){
      value = value;
    } else {
      value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.split(",").join("") : "";
      if(+value > +stableSwapObject.userLPTokens){
        setInsufficientBalanceCheck(true)
      } else {
        setInsufficientBalanceCheck(false)
      }
    }
    
    if (floatNumRegex.test(value.toString())) {
      setRemoveAmount(value)
      setRemoveAmountVal(value)
      setDisplayRemoveAmount(commaFy(value));
      // value = value !== "" ? value.split(",").join("") : "";
      // console.log((value));
    }
    
  }

  const updateMaxAmount = () => {
    setRemoveAmount(stableSwapObject.userLPTokens)
    setRemoveAmountVal(stableSwapObject.userLPTokens)
    if(stableSwapObject.userLPTokens !== ""){
      let removeVal = parseFloat(stableSwapObject.userLPTokens).toFixed(2);
      setDisplayRemoveAmount(commaFy(removeVal));
    }
  }

  const removeStableLiquidity = (amount: any, token: any, tokenIndex?: any ) => {
    // console.log(amount, token, stableSwapObject)
    if(Object.keys(stableSwapObject).length > 0){
      setConfirmRemoveBtn(true)
      setRemoveAmount(amount);
      setRemoveAmountVal(amount)
      setRemoveLiquidityToken(token)
      setRemoveTokenIndex(tokenIndex);
    }
    
  }

  return (
    <Fragment>
      <CardCont className='removeLiquidityContainer'>
        <RemoveLDCont>
          <AddLDCard className='tokenCard removeStableLiquidity'>
              <FlexSBCont>
                <FlexSBCont className='swapTokens'>
                  <div className='tokenIcon'>
                    <img className="stable-token-icon" src={stableSwapIcon} alt="" />
                    <div className="tokenName">
                      <TokenName className='stableTokenName'>3G</TokenName>
                      {isNaN(stableSwapObject.userLPTokens) ?
                      <LoadingText1 /> :
                      <div>
                        <TokenName className='tokenBalance web'>Balance: {parseFloat(stableSwapObject.userLPTokens).toFixed(2)}</TokenName>
                        <TokenName className='tokenBalance mob'>Bal: {parseFloat(stableSwapObject.userLPTokens).toFixed(2)}</TokenName>
                      </div>
                      }
                    </div>
                  </div>
                </FlexSBCont>
                <FlexSBCont className="token-input stableTokensContainer">
                  <TokenValInput
                    className='stableTokenInput'
                    placeholder="0"
                    // disabled={!(Object.values(firstToken).length > 0)}
                    onChange={handleInputChange}
                    value={displayRemoveAmount && displayRemoveAmount}
                    autoFocus={true}
                  />
                  <MaxTxt  onClick={() => updateMaxAmount()}>Max</MaxTxt>
                  {/* <InfoLDText className='stableTokenBalance'>{`Balance: ${!!firstToken && Object.values(firstToken).length > 0 ? commaFy(parseFloat(firstToken.value).toFixed(4)) : '0'
                    }`}</InfoLDText> */}
                </FlexSBCont>
               
              </FlexSBCont>
              
            </AddLDCard>
            <TokenName className='stableTokenRemoveTxt'>Choose currency to receive in</TokenName>
            <FlexSBCont className='removeSwapTokens'>
              <FlexSBCont className={removeTokenIndex == -1 ? 'removeTokens firstIndexCont' :'removeTokens'} onClick={() => removeStableLiquidity(removeAmount, stableSwapObject.poolAddress, -1)}>
                <div className='threeGToken'>
                  <img className="threeGIcon" src={stableSwap3GIcon} alt="" />
                  {/* <div className="tokenName">
                    <TokenName className='stableTokenName'>3G</TokenName>
                  </div> */}
                </div>
              </FlexSBCont>
              <FlexSBCont className={removeTokenIndex == 0 ? 'middleIndexCont removeTokens' : 'removeTokens'} onClick={() => removeStableLiquidity(removeAmount, tokenArray[0].address, 0)}>
                <div className='threeGToken'>
                  <img className="threeG-icon" src={BUSDIcon} alt="" />
                  <div className="tokenName">
                    <TokenName className={removeTokenIndex == 0 ? 'selectTokenText removeStableTokenName': 'removeStableTokenName'}>BUSD</TokenName>
                  </div>
                </div>
              </FlexSBCont>
              <FlexSBCont className={removeTokenIndex == 1 ? 'middleIndexCont removeTokens' : 'removeTokens'} onClick={() => removeStableLiquidity(removeAmount, tokenArray[1].address, 1)}>
                <div className='threeGToken'>
                  <img className="threeG-icon" src={USDCIcon} alt="" />
                  <div className="tokenName">
                    <TokenName className={removeTokenIndex == 1 ? 'selectTokenText removeStableTokenName': 'removeStableTokenName'}>USDC</TokenName>
                  </div>
                </div>
              </FlexSBCont>
              <FlexSBCont className={removeTokenIndex == 2 ? 'lastIndexCont removeTokensFinal' : 'removeTokensFinal'} onClick={() => removeStableLiquidity(removeAmount, tokenArray[2].address, 2)}>
                <div className='threeGToken'>
                  <img className="threeG-icon" src={USDTIcon} alt="" />
                  <div className="tokenName">
                    <TokenName className={removeTokenIndex == 2 ? 'selectTokenText removeStableTokenName': 'removeStableTokenName'}>USDT</TokenName>
                  </div>
                </div>
              </FlexSBCont>
            </FlexSBCont>
          
        </RemoveLDCont> 
      </CardCont>
      <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
        {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
      </CustomModal>
    </Fragment>
  )
}

export default RemoveLiquidity
