import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CardCont,
  InfoCardCont,
  FlexSBCont,
  FlexCont,
  TitleText,
  InfoText,
  InfoSmallText,
  BtnGroup,
  PrimaryBtn,
  SecondaryBtn,
  TwoImgCont,
} from '../style'
import { LiquidityCont, EmptyLDCont, LDListCont, UserLDList, DownImg, LDInfoCont, RemoveLDBtn, LDInfoText } from './style'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import ClockIcon from 'assets/icons/clock-icon.png'
import DownIcon from 'assets/icons/down-small.png'
import CustomModal from 'shared/custom-modal'
import ComponentLoader from 'shared/component-loader'
import TransHistory from 'shared/transaction-history'
import RemoveLiquidity from './RemoveLiquidity'
import RemoveLiquidityNew from './RemoveLiquidityNew'
import AddLiquidity from './AddLiquidity'
import AddStableSwapLiquidity from './AddStableSwapLiquidity'
import CreateLpPair from './CreateLpPairNew'
import CreateLpPairOld from './CreateLpPair'
import ArrowDownGrey from 'assets/icons/down_grey_icon.svg'
import mixedMakrketImage from 'assets/icons/mixed.png'
import zapInImg from 'assets/icons/zapIn.png'
import stabkeMakrketImage from 'assets/icons/stable.png'
import { SwapContainer, SwapHeaderCont, SwapStatusText } from '../../style'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import {
  returnPairExistOrNot,
  returnPairAddress,
  userPoolDetails,
  returnQuotePrice,
  tokenAllowance,
  DEADLINE,
  selectInstance,
  instanceType,
  convertToWei,
  onAddLiquidityPopUp,
  getAllStableSwapPools
} from 'modules/block-chain/Swap'
import { commaFy, convertToKAndM, checkZeroVal, getTokenName } from 'service/globalFunctions'
import ZapIn from './ZapIn'
import ZapOut from './ZapOut'
import { convertToEther } from 'modules/block-chain/lendingAbi'

const Liquidity = (props: any) => {
  const selector = useSelector((state: any) => state)
  const [expandedRows, setExpandedRows] = useState<any>([])
  const [liquidityTab, setLiquidityTab] = useState('liquidity')
  const [removeLDData, setRemoveLDData] = useState<any>({})
  const [showHistory, setShowHistory] = useState(false)
  const [activeTab, setActiveTab] = useState('liquidity')
  const [stableSwapArray, setStableSwapArray] = useState<any>([])
  const [stableSwapObject, setStableSwapObject] = useState<any>({})
  const [tokenArray, setTokenArray] = useState<any>([])
  const { tokenList, ldLoading, liquidityList, updateTokenList, updateUserLiquidity, updateTokenListForAddLiquidity, tokenListForAddLiquidity } = props
  const { portfolioLoading, userPortfolio, platformLoading, platformData, infVaultLoadingNew, poolData, aquaInfDataNew, gammaInfDataNew, aquaInfVaultLoading, gammaInfVaultLoading, poolLoadingNew,  } = selector.user

  const updateLiquidity = () => {
    updateTokenList()
    updateUserLiquidity()
  }

  

  const handleTabClick = (tabName: string) => {
    history.push(`${Paths.root}${tabName}`)
  }

  const handleRemoveLiquidity = (dataItem: any) => {
    // setRemoveLDData(dataItem)
    let removeLPObject: any = [];
    if(poolData !== undefined && poolData.newPools.active.length > 0){
      removeLPObject = poolData.newPools.active.filter((pool: any)=> pool.wantAddress.toLowerCase() == dataItem.address.toLowerCase())
      removeLPObject[0].walletBalance = dataItem.yourPoolToken;
      removeLPObject[0].walletBalanceUSD = (parseFloat(dataItem.yourPoolToken) * parseFloat(dataItem.price)).toString();
      console.log("pool object", removeLPObject, dataItem, poolData.newPools.active)
      let removeObject = {...dataItem, ...removeLPObject[0] }
      setRemoveLDData(removeObject)
      // console.log("pool object", removeLPObject, dataItem)
    }

    if(removeLPObject.length == 0 && poolData !== undefined && poolData.v3Pools.active.length > 0){
      removeLPObject = poolData.v3Pools.active.filter((pool: any)=> pool.wantAddress.toLowerCase() == dataItem.address.toLowerCase())
      // console.log("pool object", removeLPObject, dataItem)
      let removeObject = {...dataItem, ...removeLPObject[0] }
      let lpBalance = dataItem.lpBalance > 0 ? parseFloat(convertToEther(dataItem.lpBalance, 18)) : 0;

      if(parseFloat(removeObject.walletBalance) == 0 && lpBalance > 0){
        // console.log("in if");
        removeObject.walletBalance = lpBalance;
        removeObject.walletBalanceUSD = lpBalance * removeObject.price;
      } else {
        console.log(" in else", parseFloat(removeObject.walletBalance), lpBalance)
      }
      // console.log("pool object new", removeObject)
      setRemoveLDData(removeObject)
    }

    if(removeLPObject.length == 0 && poolData !== undefined && poolData.newPools.finished.length > 0){
      removeLPObject = poolData.newPools.finished.filter((pool: any)=> pool.wantAddress.toLowerCase() == dataItem.address.toLowerCase())
      console.log("pool object in last if", removeLPObject)
      let removeObject = {...dataItem, ...removeLPObject[0]}
      if(removeObject.address.toLowerCase() == "0x1d6F3e6e0C98BAbA086fC2a9d12d79841280DB8a".toLowerCase()){
        removeObject.wantAddress = "0x1d6F3e6e0C98BAbA086fC2a9d12d79841280DB8a";
        removeObject.farmContractAddress = "0xB87F7016585510505478D1d160BDf76c1f41b53d";
        removeObject.strategyAddress = "0xAED1ff82c16C27D1220d6DB3b611737Db9a440Dd";
        removeObject.walletBalance = removeObject.yourPoolToken;
        removeObject.walletBalanceUSD = (parseFloat(removeObject.yourPoolToken) * parseFloat(removeObject.price)).toString();
        removeObject.vaultBalanceUSD = "0";
      }
      setRemoveLDData(removeObject)
      // console.log("pool object", removeLPObject)
    }
    console.log("pool object", removeLPObject)
    setLiquidityTab('removeLiquidity')
  }

  const convertLP = (dataItem: any) => {
    // console.log(dataItem)
    let removeLPObject: any = []
    if(poolData !== undefined && poolData.newPools.active.length > 0){
    removeLPObject = poolData.newPools.active.filter((pool: any)=> pool.wantAddress.toLowerCase() == dataItem.address.toLowerCase())
      // console.log("pool object", removeLPObject)
      setRemoveLDData(removeLPObject[0])
      // console.log("pool object", removeLPObject)
    }

    if(removeLPObject.length == 0 && poolData !== undefined && poolData.v3Pools.active.length > 0){
      removeLPObject = poolData.v3Pools.active.filter((pool: any)=> pool.wantAddress.toLowerCase() == dataItem.address.toLowerCase())
      // console.log("pool object", removeLPObject)
      let removeObject = {...dataItem, ...removeLPObject[0] }
      let lpBalance = dataItem.lpBalance > 0 ? parseFloat(convertToEther(dataItem.lpBalance, 18)) : 0;
      if(parseFloat(removeObject.walletBalance) == 0 && lpBalance > 0){
        // console.log("in if");
        removeObject.walletBalance = lpBalance;
        removeObject.walletBalanceUSD = lpBalance * removeObject.price;
      } else {
        // console.log(" in else", parseFloat(removeObject.walletBalance), lpBalance)
      }
      setRemoveLDData(removeObject)
      // console.log("pool object", removeLPObject)
    }

    if(removeLPObject.length == 0 && poolData !== undefined && poolData.newPools.finished.length > 0){
      removeLPObject = poolData.newPools.finished.filter((pool: any)=> pool.wantAddress.toLowerCase() == dataItem.address.toLowerCase())
      // console.log("pool object", removeLPObject)
      setRemoveLDData(removeLPObject[0])
      // console.log("pool object", removeLPObject)
    }
    setLiquidityTab('zapOut')
    
  } 

  const handleRowClick = (rowId: any) => {
    const currentExpandedRows = expandedRows
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId)
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id: any) => id !== rowId)
      : currentExpandedRows.concat(rowId)

    setExpandedRows(newExpandedRows)
  }

  const renderLiquidityList = () => {
    return liquidityList.map((item: any, index: any) => {
      // console.log("liquidity details", item)
      const tokenNameData: any = item.name.split('/')
      const clickCallback = () => handleRowClick(item.sno)
      return (
        <UserLDList key={index}>
          <FlexSBCont className="ld-row" key={index} onClick={clickCallback}>
            <FlexCont>
              <TwoImgCont className="two-img">
                <img src={item.icon1} alt="" />
                <img className="img2" src={item.icon2} alt="" />
              </TwoImgCont>
              <div>
                <InfoText>{item.name.replace("/", "-")}</InfoText>
                <InfoText className="lpShare">${commaFy((+item.price * +item.yourPoolToken).toFixed(2))}</InfoText>
              </div>
              
            </FlexCont>
            <DownImg className="down-icon-liquidity" src={expandedRows.includes(item.sno) ? DownIcon : ArrowDownGrey} alt="" iconStatus={expandedRows.includes(item.sno)} />
          </FlexSBCont>
          {expandedRows.includes(item.sno) && (
            <LDInfoCont>
              <FlexSBCont className="ld-info">
                <InfoSmallText className="liquidityInfoText">{`Pooled ${tokenNameData[0]}`}</InfoSmallText>
                <InfoSmallText>{commaFy(parseFloat(item.pooledToken0).toFixed(4))}</InfoSmallText>
              </FlexSBCont>
              <FlexSBCont className="ld-info">
                <InfoSmallText className="liquidityInfoText">{`Pooled ${tokenNameData[1]}`}</InfoSmallText>
                <InfoSmallText>{commaFy(parseFloat(item.pooledToken1).toFixed(4))}</InfoSmallText>
              </FlexSBCont>
              <FlexSBCont className="ld-info">
                <InfoSmallText className="liquidityInfoText">Your LP Tokens</InfoSmallText>
                <InfoSmallText>{commaFy(parseFloat(item.yourPoolToken).toFixed(4))} LP</InfoSmallText>
              </FlexSBCont>
              <FlexSBCont>
                <InfoSmallText className="liquidityInfoText">Your Pool Share</InfoSmallText>
                <InfoSmallText>{commaFy(parseFloat(item.poolShare).toFixed(2))} %</InfoSmallText>
              </FlexSBCont>
              <FlexSBCont>
              <RemoveLDBtn style={{ marginTop: '20px', fontSize: '12px', lineHeight: '15px',backgroundColor: '#8775F733', borderRadius: '9px', color: '#8C71FF', background: '#8775F733', }} onClick={() => handleRemoveLiquidity(item)}>
                Remove Liquidity
              </RemoveLDBtn>
              <RemoveLDBtn style={{ marginTop: '20px', fontSize: '12px', lineHeight: '15px', marginLeft: '10px', borderRadius: '9px', background: '#FF555533', color: '#FF5555' }} onClick={() => convertLP(item)}>
                Zap out
              </RemoveLDBtn>
              </FlexSBCont>
              
            </LDInfoCont>
          )}
        </UserLDList>
      )
    })
  }
  return (
    <Fragment>
      {liquidityTab === 'liquidity' && (
        <LiquidityCont className="liquidityContainer">
          <CardCont>
            <FlexSBCont className="liquidity-header">
              <TitleText className='addLiquidity'>Add Liquidity</TitleText>
              {/* <SwapHeaderCont className='swap'>
                <SwapStatusText className='tabText' activeStatus={activeTab === 'swap'} onClick={() => handleTabClick('swap')} >
                  Swap
                </SwapStatusText>
                <SwapStatusText className='tabText' activeStatus={activeTab === 'liquidity'} onClick={() => handleTabClick('liquidity')}>
                  Liquidity
                </SwapStatusText>
                <SwapStatusText className='tabText' onClick={()=> window.open("https://synapseprotocol.com/", "_blank")}>
                  Bridge
                </SwapStatusText>
              </SwapHeaderCont>*/}
              
              <img className="clock-icon" src={ClockIcon} alt="" onClick={() => setShowHistory(true)} /> 
            </FlexSBCont>
            {/* <CardCont className="mixedLiquidity" onClick={() => setLiquidityTab('AddStableSwapLiquidity')}>
              <div>
                <FlexSBCont className="liquidity-header">
                  <TitleText className='addLiquidity'>StableSwap</TitleText>
                  
                </FlexSBCont>
                <InfoText light className="swap-subtext">Add stablecoins into deep liquidity</InfoText>
              </div>
              <img className="mixed-icon" src={stabkeMakrketImage} alt="" />
            </CardCont> */}
            <CardCont className="mixedLiquidity" onClick={() => setLiquidityTab('zapIn')}>
                <div>
                  <FlexSBCont className="liquidity-header mixedLiquidity">
                    <TitleText className='mixedLiquidity'>Zap In</TitleText>  
                  </FlexSBCont>
                  <InfoText light className="swap-subtext">Add liquidity using a single token</InfoText>
                </div>
                <img className="zapin-icon" src={zapInImg} alt="" />
            </CardCont>
            <CardCont className="mixedLiquidity" onClick={() => setLiquidityTab('addLiquidity')}>
                <div>
                  <FlexSBCont className="liquidity-header mixedLiquidity">
                    <TitleText className='mixedLiquidity'>Add Liquidity</TitleText>  
                  </FlexSBCont>
                  <InfoText light className="swap-subtext">Add deep liquidity for any assets</InfoText>
                </div>
                <img className="mixed-icon" src={mixedMakrketImage} alt="" />
            </CardCont>
           
            {/* <BtnGroup className="add-liquidity-btn">
              <SecondaryBtn onClick={() => setLiquidityTab('createLpPair')}>Create LP Pair</SecondaryBtn>
              <PrimaryBtn onClick={() => setLiquidityTab('addLiquidity')}>Add Liquidity</PrimaryBtn>
            </BtnGroup> */}
          </CardCont>
          <CardCont className="added-ld">
            <FlexSBCont className="liquidity-header">
              <TitleText>My Liquidity</TitleText>
            </FlexSBCont>
            
            {ldLoading ? <ComponentLoader /> :
              <>
                {liquidityList.length > 0 ? (
                  <CardCont className="added-ld">
                    <LDListCont className="lpList">{renderLiquidityList()}</LDListCont>
                  </CardCont>
                  
                ) : (
                  <CardCont className="added-ld">
                    <InfoText light className="NoLiquidity">No liquidity found</InfoText>
                  </CardCont>
                )}
              </>
            }

          </CardCont>
          {/* <InfoCardCont className="added-ld">
            <LDInfoText>
              <img src={AQUAIcon} alt="" />
              By adding liquidity you’ll <span> earn 0.13% </span>of all trades on this pair
              proportional to your share of the pool. Fees are added to the pool, they
              accrue in real time and can be claimed by withdrawing your liquidity.
            </LDInfoText>
          </InfoCardCont> */}
        </LiquidityCont>
      )}
      {liquidityTab === 'removeLiquidity' && Object.values(removeLDData).length > 0 && (
        <RemoveLiquidity setLiquidityTab={setLiquidityTab} data={removeLDData} updateLiquidity={updateLiquidity} setShowHistory={setShowHistory} stableSwapObj={stableSwapObject} tokenList={tokenList} />
      )}
      {liquidityTab === 'AddStableSwapLiquidity' && (
        <CreateLpPair tokenList={tokenList} setLiquidityTab={setLiquidityTab} updateLiquidity={updateLiquidity} setShowHistory={setShowHistory} stableSwapObject={stableSwapObject} />
      )}
      {liquidityTab === 'addLiquidity' && (
        <AddLiquidity tokenListForAddLiquidity={tokenListForAddLiquidity} setLiquidityTab={setLiquidityTab} updateTokenListForAddLiquidity={updateTokenListForAddLiquidity} setShowHistory={setShowHistory} />
      )}
      {liquidityTab === 'createLpPair' && (
        <CreateLpPairOld tokenList={tokenList} setLiquidityTab={setLiquidityTab} updateLiquidity={updateLiquidity} setShowHistory={setShowHistory} />
      )}
      {liquidityTab === 'zapIn' && (
        <ZapIn tokenList={tokenList} setLiquidityTab={setLiquidityTab} updateLiquidity={updateLiquidity} setShowHistory={setShowHistory} />
      )}
      {liquidityTab === 'zapOut' && (
        <ZapOut tokenList={tokenList} setLiquidityTab={setLiquidityTab} updateLiquidity={updateLiquidity} setShowHistory={setShowHistory} data={removeLDData} updateUserLiquidity={updateUserLiquidity} />
      )}
      <CustomModal show={showHistory} toggleModal={setShowHistory}>
        {showHistory && <TransHistory action={"liquidityHistory"} setShowHistory={setShowHistory} crossIconColor={"white"} setRemoveLDData={setRemoveLDData} />}
      </CustomModal>
    </Fragment>
  )
}

export default Liquidity
