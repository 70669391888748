import { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import {
  CardCont,
  TitleText,
  InfoText,
  InfoSmallText,
  SelectCurrTxt,
  InfoLDText,
  TokenValInput,
  MaxTxt,
  BackButton,
  PrimaryBtn,
} from '../style'
import {
  AddLDCont,
  AddLDTokenCont,
  AddLDCard,
  PluIconCont,
  PoolInfoText,
  ApproveBtn,
  AddLDBtnCont,
  PoolDetailsCont
} from './style'
import DownIcon from 'assets/icons/down-arrow-icon.png'
import ArrowDown from 'assets/icons/arrow-down.png'
import ClockIcon from 'assets/icons/clock-icon.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import TokenSelect from 'shared/token-select'
import TransactionInfo from 'shared/transaction-info'
import ConfirmLiquidity from './ConfirmLiquidity'
import {
  returnPairExistOrNot,
  returnPairAddress,
  userPoolDetails,
  returnQuotePrice,
  tokenAllowance,
  DEADLINE,
  selectInstance,
  instanceType,
  convertToWei,
  onAddLiquidityPopUp,
  tokenTransformAllowance,
  getLPData, 
  createLP,
  convertToEther,
  toBalance,
  createLPNew,
  getLPDataV3,
  createLPV3
} from 'modules/block-chain/Swap'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions';
import { setTransLink, setConfirmTransLink } from '../swapMethods'
import { PLANETROUTERADDRESS } from 'modules/block-chain/SwapDexAbi'
import { maxAmount, noExponents } from 'modules/block-chain/lendingAbi';
import ArrowDownGrey from 'assets/icons/down_grey_icon.svg'
import { getTokenIcon } from 'shared/tokenIconList'
import { CellCont, TwoImgCont } from 'shared/styles/planetTableStyle'
import { aquaZapInAddress } from 'modules/block-chain/SwapDexAbi'
import SettingIcon from 'assets/icons/settings.png'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import Settings from 'shared/settings'
import {  getTokenPrice, handleDepositV3 } from 'modules/block-chain/BlockChainMethods'
import { BigNumber } from 'bignumber.js'
import { transformTokenList as tokenList, returnTransformTokenList } from 'modules/block-chain/tokenList'
import { setTransHistory, setConfirmTransHistory } from 'shared/transaction-history/historyMethods'
import { FlexSBCont, FlexCont } from 'shared/styles/styled'
import { TransActionCont, TransInputCont, TransBalTxt, InputTitle, TransInputBox, TransInput, TransMaxBox, TransDataCont, TokenSelectCont, TokenName, TwoImageCont, TransTxt, TransInoTxt, PriceImpactBox, TransButton, TransCard } from 'shared/styles/highestApyStyle'
import { aquaAddress, bnbAddress, busdAddress, stableSwapAddress, usdtAddress, usdcAddress, gammaFarmV3 } from 'modules/block-chain/abi'
import { commaFy, convertToKAndM } from 'service/globalFunctions'
import { SliderContainer } from 'modules/pools_v3/style';
import Slider from 'react-rangeslider';
import RightIcon from 'assets/icons/right_arrow_green.svg'
import { CardText, CardSubText } from 'shared/styles/globalText';
import { UnderstandCheckBox } from 'modules/pools_v3/components/style';
import green_tick from 'assets/icons/green_tick.png'
import grey_tick from 'assets/icons/grey_tick.png'
import { planetZapOneInchAbi, planetZapOneInchAddress, protocols, referralAddress, referralFees } from 'modules/block-chain/PlanetZapOneInchAbi';
import wallet from 'modules/wallet/wallet'
import { updateUserLendingData } from 'logic/action/lending.action';

const multiplier_index_arr = [1, 2, 3];

const ZapIn = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const { setLiquidityTab, tokenList, updateLiquidity, setShowHistory, zapTokenAmount, zapTokenObject, trxPopUpOverlay } = props
    const { portfolioLoading, userPortfolio, platformLoading, platformData, infVaultLoadingNew, poolData, aquaInfDataNew, gammaInfDataNew, aquaInfVaultLoading, gammaInfVaultLoading, poolLoadingNew } = selector.user
    const [sendAmount, setSendAmount] = useState<any>('')
    const [sendAmountInComma, setSendAmountInComma] = useState('')
    const [lpAmount, setLpAmount] = useState('')
    const [isAmtLoading, setIsAmtLoading] = useState(false)
    const [sendToken, setSendToken] = useState<any>(!!tokenList && tokenList.length > 0 ? tokenList[0] : {})
    const [tokenDataList, setTokenDataList] = useState<any>(tokenList)
    const [lptoken, setLpToken] = useState<any>({})
    const [existTokenAdd, setExistTokeAdd] = useState('')
    const [tokenType, setTokenType] = useState('')
    const [showTokenModal, setShowTokenModal] = useState(false)
    const [showLPTokenModal, setShowLPTokenModal] = useState(false)
    const [showTransModal, setShowTransModal] = useState(false)
    const [transactionLink, setTransactionLink] = useState('')
    const [isPairExist, setIsPairExist] = useState(true)
    const [pairAddress, setPairAddress] = useState('')
    const [poolDetails, setPoolDetails] = useState<any>({})
    const [firstAllowance, setFirstAllowance] = useState<any>('0')
    const [secondAllowance, setSecondAllowance] = useState<any>('0')
    const [addLdLoading, setAddLdLoading] = useState(false)
    const [showCnfModal, setShowCnfModal] = useState(false)
    const [confirmLdData, setConformLdData] = useState<any>({})
    const [approve1Loading, setApprove1Loading] = useState(false)
    const [approve2Loading, setApprove2Loading] = useState(false)
    const [allowanceAmt, setAllowanceAmt] = useState<any>('0.00')
    const [lpList, setLPList] = useState<any>([])
    const [slippageVal, setSlippageVal] = useState(0.5);
    const [showSettings, setShowSettings] = useState(false)
    const [transInfo, setTransInfo] = useState<any>({})
    const [lpToken0, setLpToken0] = useState(tokenList[0])
    const [lpToken1, setLpToken1] = useState(tokenList[1])
    const [priceImpact, setPriceImpact] = useState<any>('0.00')
    const [warningMessage, setWarningMessage] = useState(false)
    const [createLpLoading, setCreateLpLoading] = useState(false)
    const [approveLoading, setApproveLoading] = useState(false)
    const [inSufAmt, setInSufAmt] = useState(false)
    const [suppliedOtherPoolsAmount, setSuppliedOtherPoolsAmount] = useState(userPortfolio.other_pool_balance_in_v3)
    const [minGammaBtcbPoolRatio, setGammaBtcbPoolRatio] = useState(userPortfolio.user_gamma_btcb_balance_in_v3)
    const [migration_array, set_migration_array] = useState([])
    const [lock_time, set_lock_time] = useState(1);
    const [IUnderstand, setIUnderstand] = useState(false)
    const [suppliedGammaBtcbPercent, setGammaBTCBPercent] = useState(5.8)
    const [poolApy_0, setPoolApy_0] = useState(0)
    const [poolApy_1, setPoolApy_1] = useState(0)
    const [poolApy_2, setPoolApy_2] = useState(0)
    const [approveFarm, setApproveFarm] = useState(false)
    const [depositOnFarm, setDepositOnFarm] = useState(false)
    const [minEligibileGammaBtcbAmount, setMinEligibileGammaBtcbAmount] = useState('0')
    const [gammaBtcbApy, setGammaBtcbApy] = useState(0)
    const [actualSendAmount, setActualSendAmount] = useState("0")
    const [minGammaBtcbRequired, setMinGammaBtcbRequired] = useState(false)
    const [sendAmountWithComma, setSendAmountWithComma] = useState('0')
    const [data, setPoolObject] = useState<any>({})
    const [zapInState, setZapInState] = useState(false)
    const [stakeLPState, setStakeLPState] = useState(false)
    const [zapStakeModal, showZapStakeModal] = useState(false)
    const [zapInStateGammaBtcb, setZapInStateGammaBtcb] = useState(false)
    const [transactionLoader, setTransactionLoader] = useState(false)
    const [tokenAllowance, setTokenAllowance] = useState(false)
    const [gammaBtcbTransInfo, setGammaBtcbTransInfo] = useState({})
    const [gammaBtcbPool, setGammaBtcbPool] = useState<any>({})
    const [maxSendAmount, setMaxSendAmount] = useState(false)
    const bnbSno = 1
    const chainId = 56;
    const apiBaseUrl = 'https://pfapi.planet.finance/v1';

    useEffect(() => {
        let poolDetails: any = [];
        if(poolData && poolData.v3Pools && poolData.v3Pools.active && poolData.v3Pools.active.length > 0){
            poolData.v3Pools.active.map((pool: any, index: any) => {
              pool.value = pool.vaultBalance;
              pool.usdValue = pool.vaultBalanceUSD;
              const tokenName = pool.name.split('-');
              let icon1: any;
              let icon2: any;
              if (tokenName.length === 1) {
                  icon1 = getTokenIcon(pool.name)
              }
              else if (tokenName.length === 2) {
                  icon1 = getTokenIcon(tokenName[0])
                  icon2 = getTokenIcon(tokenName[1])
              }
              pool.icon1 = icon1;
              pool.icon2 = icon2;
              poolDetails.push(pool);
              
          })
        }
        if(poolData && poolData.v3Pools && poolData.v3Pools.active && poolData.v3Pools.active.length > 0) {
          //console.log("set pool details", poolDetails)
          setLpToken(poolDetails[0])

          let pd = poolDetails.filter((poolDetail:any) => {
            return (poolDetail.pid != 2)
          });
          setLPList(pd)
          setPoolObject(poolDetails[0])
          setGammaBtcbPool(poolDetails[0])
          if(poolDetails[0].name.toLowerCase() !== "gamma-btcb"){
            setIUnderstand(true)
          } else {
            setIUnderstand(false)
          } 
        }
        
    }, [poolData, poolLoadingNew, portfolioLoading, selector.ethData.address])

    // useEffect(() => {
    //   const getAllowance = async () => {
    //     if (sendToken.address) {
    //       try {
    //         let transformAddress = aquaZapInAddress;
    //         let sendTokenAddress = sendToken.address;

    //         const res = await tokenTransformAllowance(sendTokenAddress, selector.ethData.address, transformAddress)
    //         // console.log("token allowance in transform", res, sendToken.name, sendTokenAddress, selector.ethData.address, transformAddress, sendAmount, sendToken)
    //         setAllowanceAmt(res)
    //       } catch (error) { }
    //     }
    //   }
    //   getAllowance()
    // }, [selector.ethData.address, sendToken.address])

    useEffect(() => {
      const getAllowance = async () => {
          if (sendToken.address) {
              try {
                  let transformAddress = aquaZapInAddress;
                  let sendTokenAddress = sendToken.address;
                  const res = await tokenTransformAllowance(sendTokenAddress, selector.ethData.address, transformAddress)
                  
                  setAllowanceAmt(res)
              } catch (error) { }
          }
      }
      getAllowance()
  }, [selector.ethData.address, sendToken.address])

    // useEffect(() => {
    //     //console.log("latest slippage val", slippageVal, sendAmount)
    //     getTransInfo(sendAmount)
    // },[sendAmount, slippageVal])

    useEffect( () => {
      const delayDebounceFn = setTimeout(async () => {
          getTransInfo(sendAmount)
        }, 2000)
        return () => clearTimeout(delayDebounceFn)
    },[selector.ethData.address, data.vaultBalanceUSD, data.walletBalanceUSD, sendAmount, sendToken.address, tokenDataList[0].price, IUnderstand, slippageVal])

    const getTransInfo = async (amtVal: any) => {
      // console.log("inside trans info", amtVal)
      try {
          let lpLiquidityObject : any = {};
          let gammaBtcbLPObject : any = {};
          setIsAmtLoading(true)
          if (parseFloat(amtVal) > 0) {
              let info: any = '';
              // console.log("Pool data", data)
              let gammaBtcbPercentage = 0;
              //const amountVal = (amtVal * (100 - gammaBtcbPercentage))/ 100;
              let x = new BigNumber(amtVal)
              x = x.multipliedBy(100 - gammaBtcbPercentage).dividedBy(100)
              setActualSendAmount((new BigNumber(x)).toString());
              const amountVal = x;
              // console.log("amount in LP", amountVal)
              if(data.name.toLowerCase() == "aqua"){
                // console.log("in if aqua pool",amountVal)
                  info = await getQuote(aquaAddress, sendToken.address, convertToWei(amountVal))
                  // console.log(info)
                  if (!!info && typeof info !== 'undefined') {
                      const transform_data: any = {
                          amountsOut0: typeof info.toAmount === "number" ? toBalance(info.toAmount.toString()) : convertToEther(info.toAmount, 18),
                          // amountsOut1: typeof info.amountsOut1 === "number" ? toBalance(info.amountsOut1.toString()) : info.amountsOut1,
                          liquidity: convertToEther(info.toAmount,18),
                          minLiquidity: convertToEther(info.toAmount, 18),
                          minLiquidityInWei: info.toAmount,
                          impactAbove3Percent: false,//info.impactAbove3Percent,
                          sendAmount0: info.fromTokenAmount,
                          // sendAmount1: info.tokenVal1,
                          zapInGammaBtcb: IUnderstand
                      }
                      lpLiquidityObject = transform_data;
                      let token0Price = await getTokenPrice(data.token0);
                      // let token1Price = await getTokenPrice(info.token1);
                      let token0Supplied = token0Price * info.amountsOut0;
                      // let token1Supplied = token1Price * info.amountsOut1;
                      let totalSupplied = token0Supplied //+ token1Supplied;
                      // console.log('transform_data', transform_data, info);
                      setTransInfo({ ...transform_data })
                      let lpTokenObject0 = tokenDataList.find((token: any) => token.address.toLowerCase() === aquaAddress.toLowerCase());
                      // let lpTokenObject1 = tokenDataList.find((token: any) => token.address.toLowerCase() === info.token1.toLowerCase());
                      setLpToken0(lpTokenObject0);
                      // console.log(lpTokenObject0)
                      //let expectedVal: any = totalSupplied * (1 - +slippageVal/100) * 0.95 / +data.price;
  
                      let expectedVal: any = totalSupplied * (1 - +slippageVal/100) * 0.95 
  
                      let minLiquidityInUsdprice = transform_data.minLiquidity * data.price;
                      
                      let sendAmountTotalSuppliedUsd:any = parseFloat((+sendAmount * +sendToken.price).toString());
                      let diff = sendAmountTotalSuppliedUsd - totalSupplied ;
                      let priceImpact = diff / sendAmountTotalSuppliedUsd * 100;
                      priceImpact = diff > 0 ? priceImpact : 0;
                      // console.log('priceImpact', priceImpact, totalSupplied, minLiquidityInUsdprice);
                      setPriceImpact(priceImpact);
                      //console.log(expectedVal, minLiquidityInUsdprice);
                      if(expectedVal > minLiquidityInUsdprice){
                          setWarningMessage(true)
                      } else {
                          setWarningMessage(false)
                      }
                  }
              } else {
                  // console.log("in else NON aqua pool",amountVal)
                  info = await getLPDataV3(amountVal, sendToken.address, data.poolId, slippageVal, data)
                  // console.log(info)
                  if (!!info && typeof info !== 'undefined') {
                      const transform_data: any = {
                          amountsOut0: typeof info.amountsOut0 === "number" ? toBalance(info.amountsOut0.toString()) : info.amountsOut0,
                          amountsOut1: typeof info.amountsOut1 === "number" ? toBalance(info.amountsOut1.toString()) : info.amountsOut1,
                          liquidity: info.liquidity,
                          minLiquidity: info.minLiquidity,
                          impactAbove3Percent: info.impactAbove3Percent,
                          sendAmount0: info.tokenVal0 ,
                          sendAmount1: info.tokenVal1,
                          zapInGammaBtcb: IUnderstand
                      }
                      lpLiquidityObject = transform_data;
                      let token0Price = await getTokenPrice(info.token0);
                      let token1Price = await getTokenPrice(info.token1);
                      let token0Supplied = token0Price * info.amountsOut0;
                      let token1Supplied = token1Price * info.amountsOut1;
                      let totalSupplied = token0Supplied + token1Supplied;
                      // console.log('transform_data', transform_data, info);
                      setTransInfo({ ...transform_data })
                      let lpTokenObject0 = tokenDataList.find((token: any) => token.address.toLowerCase() === info.token0.toLowerCase());
                      let lpTokenObject1 = tokenDataList.find((token: any) => token.address.toLowerCase() === info.token1.toLowerCase());
                      if(info.token0.toLowerCase() == "0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5".toLowerCase()){
                          lpTokenObject0 = {
                              price: 1
                          };
                      }
                      if(info.token1.toLowerCase() == "0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5".toLowerCase()){
                          lpTokenObject1 = {
                              price: 1
                          };
                      }
                      setLpToken0(lpTokenObject0);
                      setLpToken1(lpTokenObject1)
                      // console.log(lpTokenObject0, lpTokenObject1)
                      //let expectedVal: any = totalSupplied * (1 - +slippageVal/100) * 0.95 / +data.price;
  
                      let expectedVal: any = totalSupplied * (1 - +slippageVal/100) * 0.95 
  
                      let minLiquidityInUsdprice = transform_data.minLiquidity * data.price;
                      
                      let sendAmountTotalSuppliedUsd:any = parseFloat((+sendAmount * +sendToken.price).toString());
                      let diff = sendAmountTotalSuppliedUsd - totalSupplied ;
                      let priceImpact = diff / sendAmountTotalSuppliedUsd * 100;
                      priceImpact = diff > 0 ? priceImpact : 0;
                      // console.log('priceImpact', priceImpact, totalSupplied, minLiquidityInUsdprice);
                      setPriceImpact(priceImpact);
                      //console.log(expectedVal, minLiquidityInUsdprice);
                      if(expectedVal > minLiquidityInUsdprice){
                          setWarningMessage(true)
                      } else {
                          setWarningMessage(false)
                      }
                  }
                  else {
                      setTransInfo({})
                  }
              }
              
          }
          else {
              setTransInfo({})
          }

          // getApy(lpLiquidityObject, gammaBtcbLPObject)
      }
      catch (err) {
          console.log("err=>", err)
      }
      finally {
          setIsAmtLoading(false)
          setCreateLpLoading(false)
      }
  }

  const getProtocols = async() => {
    function nonPmm(protocol:any) {
      //console.log('protocol', protocol);
      return !protocol.includes('PMM');
    }
    let protocolsAr:any = protocols;
    
    protocolsAr = protocolsAr.protocols.map(function(a:any) {return a.id;});
    return protocolsAr.filter(nonPmm) ;
  }

  const apiRequestUrl:any = (methodName:any,  dst: any, src: any, amount: any, fee?:any, fromAddress?: any) => {
    return fee ? apiBaseUrl + methodName + '/' + src + '/' + dst + '/' + amount + '/' + fee + '/' + fromAddress : apiBaseUrl + methodName + '/' + src + '/' + dst + '/' + amount;
}

  const getQuote = async (toTokenAddress:any, fromTokenAddress:any, amount:any) => {
    let fee = referralFees;
    let protocols = await getProtocols();
    //console.log('protocols', protocols);
    protocols = protocols.toString();

    if(amount > 0 && toTokenAddress != undefined && fromTokenAddress != undefined) {
      let url = apiRequestUrl('/swapQuote', toTokenAddress, fromTokenAddress, amount)
      // console.log('url', url);
      return fetch(apiRequestUrl('/swapQuote', toTokenAddress, fromTokenAddress, amount))
          .then(res => res.json())
          .then((res: any) => { 
            res.fromToken = fromTokenAddress;
            res.toToken = toTokenAddress;
            res.fromTokenAmount = amount;
            res.toTokenAmount = res.toAmount;
            return res;
          });
    }
}

    const handleSlippage = (newSlippageVal: number) => {
        // console.log("newSlippageVal", newSlippageVal)
        setSlippageVal(newSlippageVal)
        setShowSettings(false)
    }

    const handleMaxAmount = async () => {
        let amount: any
        if (sendToken.sno === bnbSno && parseFloat(sendToken.value) !== 0) {
            amount = sendToken.value
            amount -= 0.02
        } //else amount = BigNumber(sendToken.value).multipliedBy(BigNumber("0.999")).toString()
        else amount = BigNumber(sendToken.value).toString()
        // amount = getNumFormat(amount)
        setSendAmount(amount)
        setSendAmountInComma(commaFy(parseFloat(amount).toFixed(6)))
        setMaxSendAmount(true)
        amount = getNumFormat(amount)
        // console.log("amount", amount)
        // setSendAmountWithComma(commaFy(amount))
        setInSufAmt(false)
        setCreateLpLoading(true)
    }

    const handleTransactionHistory = (newLink: any, hashVal: any) => {
        const historyData: any = {
            firstToken: sendToken.name,
            secondToken: data.name,
            amount1: sendAmount,
            amount2: transInfo.liquidity,
            link: newLink,
            type: 'tokenTransform',
            status: false,
            hashVal: hashVal,
            transStatus: 'pending',
        }
        // setTransHistory(historyData)
    }

    const showTransLink = (transHash: any) => {
        const transLink = `${transLinkUrl}/${transHash}`
        setTransactionLink(transLink)
        handleTransactionHistory(transLink, transHash)
        setShowTransModal(true)
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Transform LP for ${parseFloat(sendAmount).toFixed(4)} ${sendToken.name} to ${parseFloat(transInfo.liquidity).toFixed(4)} ${data.name.toUpperCase()}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
        setSendAmount('')
        setSendAmountInComma('')
        // setSendAmountWithComma('')
    }

    const handleApproveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        let typeMessage = "Failed";
        if(type == "success") {
            typeMessage = "Successful";
        }
        const dataVal: any = {
            type: type,
            message: `Approval for ${parseFloat(sendAmount).toFixed(4)} ${sendToken.name} ${typeMessage}}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const updateTokenList = async () => {
        const userAddress = selector.ethData.address
        try {
            const token_list =  await returnTransformTokenList(userAddress)//await returnTokenList(userAddress)
            if (!!token_list && token_list.length > 0) {
                let tList: any = []
                if (data.isNewFarm) {
                    tList = token_list
                }
                const index = tList.findIndex((e: any) => e.sno === sendToken.sno)
                if (index !== -1) {
                    setSendToken(tList[index])
                    setTokenDataList([...tList])
                }
            }
        } catch (error) { }
    }

    const getGammaBtcbTokenName = (tokenNum: number) => {
      let tokenN = ''
      if (gammaBtcbPool.name) {
          const tokenNameVal: any = gammaBtcbPool.name.split('-')
          if (tokenNum === 0) {
              tokenN = gammaBtcbPool.name
          }
          else if (tokenNum === 1) {
              tokenN = tokenNameVal[0]
          }
          else {
              let actualTokenName: any = tokenNameVal[1].split(" ");
              tokenN = actualTokenName[0]
          }
      }
      tokenN = tokenN.toLowerCase().replace('wbnb', 'bnb')
      return tokenN.toUpperCase()
    }

    const getTokenName = (tokenNum: number) => {
      let tokenN = '';
      if (data.name) {
          const tokenNameVal: any = data.name.split('-')
          if (tokenNum === 0) {
              tokenN = data.name
          }
          else if (tokenNum === 1) {
              tokenN = tokenNameVal[0]
          }
          else {
              let actualTokenName: any = data.name.toLowerCase() != "aqua" ? tokenNameVal[1].split(" ") : [];
              tokenN = actualTokenName.length > 0 ? actualTokenName[0]: ""
          }
      }
      tokenN = tokenN.toLowerCase().replace('wbnb', 'bnb')
      return tokenN.toUpperCase()
    }

    const getIcons = (tokenNum: any, poolFlag?: boolean) => {
        const tokenName: any = poolFlag ? getGammaBtcbTokenName(tokenNum) : getTokenName(tokenNum)
        let actualTokenName: any = tokenName.split(" ");
        const icon: any = getTokenIcon(actualTokenName[0])
        return icon
    }

    const handleApproveLp = async () => {
        if (selector.ethData.ethWalletConnected) {
            let approvalContract = aquaZapInAddress;
            
            try {
                setApproveLoading(true)
                const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, sendToken.address)
                const approvalAmount = maxAmount
                await TOKEN_INSTANCE.methods
                    .approve(approvalContract, approvalAmount)
                    .send({ from: selector.ethData.address })
                    .once('transactionHash', function (res: any) {
                        //console.log('approve transactionHash');
                        if (res && typeof res !== 'undefined') {
                            const transLink = `${transLinkUrl}/${res}`
                            setTransactionLink(transLink)
                            setShowTransModal(true)
                        }
                    })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        // handleNotification(type, receipt.transactionHash)
                        //console.log('approve confirmation');
                        handleApproveNotification(type, receipt.transactionHash)
                        checkLPApproval()
                    })
                    .on('error', function (error: any) {
                        setTransactionLink('')
                        setShowTransModal(false)
                        console.log('handleApproveLp error' ,error)
                        checkLPApproval()
                    })
            } catch (error) {
            } finally {
                console.log('approve finally');
            }
        }

    }

  const checkLPApproval = async() => {
      setApproveLoading(true)
      try {
          let approvalContract = aquaZapInAddress;

          const LP_TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, sendToken.address)

          let allowanceAmount: any = await LP_TOKEN_INSTANCE.methods.allowance(selector.ethData.address, approvalContract).call();
          // console.log("allowance amount for lp", allowanceAmount);
          let allowance: any = convertToEther(allowanceAmount, 18);
          setAllowanceAmt(allowance)
          setApproveLoading(false)
      } catch (error){
          console.log(error);
          setApproveLoading(false)
      }
      dispatch(updateUserBalance(selector.ethData.address))
  }

  const getAmountOfToken = async (amount: any, flag: boolean, pairAddress: string, sendTokenAdd: string) => {
    let finalAmt: any = ''
    try {
      setIsAmtLoading(true)
      const tokenAmount: any = await returnQuotePrice(amount, flag, pairAddress, sendTokenAdd)
      finalAmt = tokenAmount
    }
    catch (error) {
      console.log("error=>", error)
    }
    finally {
      setIsAmtLoading(false)
      finalAmt = getNumFormat(finalAmt)
      return finalAmt
    }
  }

  const handleSendAmount = async (e: any) => {
    
    let { value } = e.target
    if(+value === 0 ){
        value = value;
      } else {
        value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.replace(",", '') : "";
      }
    if (floatNumRegex.test(value.toString())) {
      setSendAmount(value)
      setMaxSendAmount(false)
      setSendAmountInComma(commaFy((value)))
    //   const secondAmtVal: any = await getAmountOfToken(value, true, pairAddress, sendToken.address)
    //   setLpAmount(secondAmtVal)
    //   getAllowance()
      setCreateLpLoading(true)
    }
    if (!value) {
      setLpAmount('')
      setMaxSendAmount(false)
      setSendAmount('')
      setSendAmountInComma('')
    }
  }
  const handlelpAmount = async (e: any) => {
    const { value } = e.target
    if (floatNumRegex.test(value.toString())) {
      setLpAmount(value)
      setMaxSendAmount(false)
      const firstAmtVal: any = await getAmountOfToken(value, false, pairAddress, sendToken.address)
      setSendAmount(firstAmtVal)
      // getAllowance()
    }
    if (!value) {
      setSendAmount('')
      setLpAmount('')
    }
  }
  const getExistTokenAdd = (type: string) => {
    if (type === 'first' && Object.values(data).length > 0) {
      setExistTokeAdd(data.address)
    }
    if (type === 'second' && Object.values(sendToken).length > 0) {
      setExistTokeAdd(sendToken.address)
    }
  }
  const handleTokenModal = (type: string) => {
    setTokenType(type)
    // getTokenList(type)
    // getExistTokenAdd(type)
    setShowTokenModal(true)
  }

  const handleLPTokenModal = (type: string) => {
    // console.log("in handleLPTokenModal", lpList)
    setTokenType(type)
    // getTokenList(type)
    // getExistTokenAdd(data.strategyAddress)
    setShowLPTokenModal(true)
  }

  const getPairExistVal = async (token1: any, token2: any) => {
    let pairExist: any = false;
    try {
      //pairExist = await returnPairExistOrNot(token1.address, token2.address)
      setIsPairExist(pairExist)
    }
    catch (error) {
      setIsPairExist(false)
      setPairAddress('')
    }
    try {
      if (pairExist) {
        const pairAddressVal = await returnPairAddress(token1.address, token2.address)
        setPairAddress(pairAddressVal)
        if((selector.ethData && selector.ethData.address))
        {
          const pollDataVal: any = await userPoolDetails(token1.address, token2.address, pairAddressVal, selector.ethData.address)
          setPoolDetails(pollDataVal)
        }
      }
    } catch (error) {
      //console.log('error');
      console.log(error);
    }
  }

  const handleTokenSelect = (selectedTokenData: any) => {
    if (selectedTokenData.address === existTokenAdd) {
      setSendToken(data)
    //   getPairExistVal(data, sendToken)
    }
    else {
      if (tokenType === 'first') {
        setSendToken(selectedTokenData)
        if (Object.values(data).length > 0) {
        //   getPairExistVal(selectedTokenData, data)
        }
      }
      if (tokenType === 'second') {
        if (Object.values(sendToken).length > 0) {
        //   getPairExistVal(sendToken, selectedTokenData)
        }
      }
    }
    setExistTokeAdd('')
    setSendAmount('')
    setSendAmountInComma('')
    setLpAmount('')
    setFirstAllowance('0')
    setSecondAllowance('0')
    handleToggleModal()
  }

  const handleLPTokenSelect = (selectedTokenData: any) => {
    // console.log("handleLPTokenSelect", selectedTokenData)
    setLpToken(selectedTokenData)
    setPoolObject(selectedTokenData)
    if (selectedTokenData.address === existTokenAdd) {
      //getPairExistVal(data, sendToken)
    }
    // else {
    //   if (tokenType === 'first') {
    //     setSendToken(selectedTokenData)
    //     if (Object.values(data).length > 0) {
    //       //getPairExistVal(selectedTokenData, data)
    //     }
    //   }
    //   if (tokenType === 'second') {
    //     if (Object.values(sendToken).length > 0) {
    //       //getPairExistVal(sendToken, selectedTokenData)
    //     }
    //   }
    // }
    setExistTokeAdd('')
    setSendAmount('')
    setSendAmountInComma('')
    setLpAmount('')
    setFirstAllowance('0')
    setSecondAllowance('0')
    handleToggleLPModal()
  }

  const handleToggleModal = () => {
    setTokenType('')
    setShowTokenModal(false)
  }

  const handleToggleLPModal = () => {
    setTokenType('')
    setShowLPTokenModal(false)
  }

  const handleTransactionLink = (newLink: any, hashVal: any) => {
    const trx_data: any = {
      sendToken: sendToken.name,
      data: data.name,
      amount1: sendAmount,
      amount2: lpAmount,
      link: newLink,
      type: 'addedLiquidity',
      status: false,
      hashVal: hashVal,
      transStatus: 'pending',
    }
    setTransLink(trx_data)
  }

  const handleNotificationOld = (type: string, hash?: any) => {
    const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
    const notif_data: any = {
      type: type,
      message: `Added liquidity for ${parseFloat(sendAmount).toFixed(4)} ${sendToken.name} and ${parseFloat(lpAmount).toFixed(4)} ${data.name}`,
      link: linkUrl
    }
    dispatch(notificationClose())
    dispatch(notificationOpen(notif_data))
  }

  const handleAddLiquidity = async () => {
    try {
      setAddLdLoading(true);
      // console.log("add liquidity params", sendToken.address, data.address, sendAmount, lpAmount, selector.ethData.address)
      if (sendToken.sno === bnbSno || data.sno === bnbSno) {

        await _addLiquidity(sendToken.address, data.address, sendAmount, lpAmount, selector.ethData.address, 0)
      }

      else {
        await _addLiquidity(sendToken.address, data.address, sendAmount, lpAmount, selector.ethData.address, 1)
      }
    } catch (error) {
      console.log('error==>', error)
    } finally {
      setAddLdLoading(false)
      setConformLdData({})
      setSendAmount('')
      setSendAmountInComma('')
      setLpAmount('')
      updateLiquidity()
    }
  }

  const _addLiquidity = async (tokenA: any, tokenB: any, amountADesiredInEther: any, amountBDesiredInEther: any, to: any, flag: any) => {
    // console.log('in _addLiquidity');
    /*
     * type : true =>  addLiquidityEth
     * type : false =>  addLiquidity
     * flag = 0 for bnb
     * flag = 1 for others
     */
    let type: boolean
    let amountAMinInEther: any = 0.008 * amountADesiredInEther
    let amountBMinInEther: any = 0.008 * amountBDesiredInEther
    amountAMinInEther = amountAMinInEther.toString()
    amountBMinInEther = amountBMinInEther.toString()

    //const pair = await returnPairExistOrNot(tokenA, tokenB)
    
    if (flag === 0) type = true
    else type = false

    let deadline = Math.floor(Date.now() / 1000) + DEADLINE
    let txArray: any

    const ROUTER_INSTANCE = await selectInstance(instanceType.PLANETROUTER)
    if (type) {
      if (sendToken.sno === bnbSno) {
        txArray = [
          tokenB,
          convertToWei(amountBDesiredInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountADesiredInEther),
        ]
      } else {
        txArray = [
          tokenA,
          convertToWei(amountADesiredInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountBDesiredInEther),
        ]
      }
      // console.log('in true')
      // console.log("txArray", txArray);
      // console.log(to)
      // console.log("addLiquidityETH",txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
      await ROUTER_INSTANCE.methods
        .addLiquidityETH(txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({
          from: to,
          value: txArray[4],
        })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setAddLdLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setAddLdLoading(false)
        })
    } else {
      // console.log('amountADesiredInEther');
      // console.log(amountADesiredInEther);
      // console.log(amountBDesiredInEther);
      // console.log(amountAMinInEther);
      // console.log(amountBMinInEther);
      txArray = [
        convertToWei(amountADesiredInEther),
        convertToWei(amountBDesiredInEther),
        convertToWei(amountAMinInEther),
        convertToWei(amountBMinInEther),
      ]

      // console.log('in false')
      // console.log("txArray", txArray);
      // console.log(to)
      // console.log("in else", tokenA, tokenB, txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
      await ROUTER_INSTANCE.methods
        .addLiquidity(tokenA, tokenB, txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({ from: to })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setAddLdLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setAddLdLoading(false)
        })
    }
  }

const renderTokenIcons = (tokenIcons: string, isBoostPool: boolean) => {
    const tokenName = tokenIcons.split('-')
    if (tokenName.length === 1) {
        const icon = getTokenIcon(tokenIcons)
        // console.log("in token length 1", icon, tokenName, tokenIcons);
        return <img className='boost-token-icon' src={icon} alt="" />
    }
    else if (tokenName.length === 2) {
        const icon1 = getTokenIcon(tokenName[0])
        let secondTokenName = tokenName[1].split(" ");
        const icon2 = getTokenIcon(secondTokenName[0])
        return (
            <TwoImgCont>
                <img className='token-icon' src={icon1} alt="" />
                <img className="second-img token-icon zapInImg" src={icon2} alt="" />
            </TwoImgCont>
        )
    }
    else if (tokenName.length === 3) {
        const icon1 = getTokenIcon(tokenName[0])
        const icon2 = getTokenIcon(tokenName[1])
        const icon3 = getTokenIcon(tokenName[2])
        return (
            <TwoImgCont>
                <img src={icon1} alt="" />
                <img className="second-img" src={icon2} alt="" />
                <img className='third-icon' src={icon3} alt="" />
            </TwoImgCont>
        )
    }
}

const setInputAmountOnMeetEligibility = async () => {
  if((Object.values(data).length > 0 && data.name && data.name.toLowerCase() == "gamma-btcb" && suppliedOtherPoolsAmount > 0 && data.price)){
      const minGammaBtcbInUSD = (0.058 * suppliedOtherPoolsAmount) - minGammaBtcbPoolRatio;
      let newGammaBtcbAmountInTokens: any = 0;
      if(minGammaBtcbInUSD > minGammaBtcbPoolRatio && suppliedOtherPoolsAmount > 0 && minGammaBtcbPoolRatio/suppliedOtherPoolsAmount < 0.058){
          newGammaBtcbAmountInTokens = ((minGammaBtcbInUSD - minGammaBtcbPoolRatio)/data.price).toFixed(2);
          setSendAmount(newGammaBtcbAmountInTokens);
          setSendAmountWithComma(commaFy(newGammaBtcbAmountInTokens));
      }
      
  }
}

const setLockTimeForPool = async (time: any) => {
  set_lock_time(time)
}

const getApy = (lpObject: any, gammaBtcbLpObject: any) => {
  // console.log("data", data, gammaBtcbPool, lpObject, gammaBtcbLpObject)
  const lockTimeMultiplier = data.name.toLowerCase() == "gamma-btcb" ? 1 : lock_time == 0 ? 1 : lock_time == 1 ? 2 : 3;
  const blocksPerYear: any = 28800;
  let apr: any = 0;
  let apy: any = 0;
  if(Object.values(gammaBtcbPool).length > 0) {
    if(data.name.toLowerCase() == "gamma-btcb"){
      //((farmDripPerBlock*AllocPointForPool/TotalAllocPoint)*blocksPerYear* NewUserFactor/NewTotalFactor)*(priceOfGamma/priceofGAMMA-BTCB LP)
      //NewUserFactor = [UserEarningShares + (NewUserDepositShares * LockTimeMultiplier)]
      //NewTotalFactor = OldTotalFactor -OldUserFactor + NewUserFactor
      const currentUserFactor: any = data.userFactor;
      const newUserShares: any = +lpObject.liquidity;
      const newUserFactor : any = currentUserFactor + (newUserShares * lockTimeMultiplier);
      const currentTotalFactor: any = data.totalFactor;   
      const newTotalFactor: any =  currentTotalFactor - currentUserFactor + newUserFactor;
      apr = (data.farmV3DripRate * blocksPerYear * data.allocPoint * newUserFactor * data.gammaPrice * 100)/(data.totalAllocPoint * data.price * newTotalFactor * 1e18)
      apr = apr !== undefined && !isNaN(apr) && apr > 10000 ? 10000 : apr !== undefined && !isNaN(apr) ? apr : 0;

      apy = (Math.pow((1 + (apr)/36500), 365) - 1) * 100;
      apy = apy !== undefined && !isNaN(apy) && apy > 10000 ? 10000 : apy !== undefined && !isNaN(apy) ? apy : 0;
  } else {
      //check pool eligibility            
      const currentUserGammaBtcbSharesUSD = userPortfolio.user_gamma_btcb_balance_in_v3;
      const currentUserOtherPoolSharesUSD = userPortfolio.other_pool_balance_in_v3;
      const addedOtherPoolSharesUSD = +lpObject.liquidity ? +lpObject.liquidity * data.price : 0;
      const addedGammaBtcbSharesUSD = +gammaBtcbLpObject.liquidity ? +gammaBtcbLpObject.liquidity * gammaBtcbPool.price : 0;
      
      const condition_check = (currentUserOtherPoolSharesUSD + addedOtherPoolSharesUSD) > 0 ? (currentUserGammaBtcbSharesUSD + addedGammaBtcbSharesUSD)/(currentUserOtherPoolSharesUSD + addedOtherPoolSharesUSD) : 0;
      // console.log("criteria val check", condition_check, currentUserGammaBtcbSharesUSD, addedGammaBtcbSharesUSD, currentUserOtherPoolSharesUSD, addedOtherPoolSharesUSD)
      if(condition_check > 0.05 || currentUserGammaBtcbSharesUSD > 0){
          // console.log("gamma btcb val more than 5%")
          multiplier_index_arr.forEach((index: any) => {
              const currentUserShares: any = data.userEarningShares;
              const newUserShares: any = parseFloat(lpObject.liquidity) * index;
              // console.log("new user shares", index, newUserShares, parseFloat(lpObject.liquidity), index, lpObject)
              const newUserFactor : any = IUnderstand ? ((currentUserShares + newUserShares) * (gammaBtcbPool.userShares + +gammaBtcbLpObject.liquidity))**0.5 : ((currentUserShares + newUserShares) * (gammaBtcbPool.userShares + 0))**0.5;
              // console.log(newUserFactor, currentUserShares, newUserShares, gammaBtcbPool.userShares, gammaBtcbLpObject.liquidity, index)
              const currentTotalFactor: any = data.totalFactor;   
              const newTotalFactor: any =  currentTotalFactor - data.userFactor + newUserFactor;
              apr = (data.farmV3DripRate * blocksPerYear * data.allocPoint * newUserFactor * data.gammaPrice * 100)/(data.totalAllocPoint * data.price * newTotalFactor * 1e18)
              // console.log(apr, data.farmV3DripRate, blocksPerYear, data.allocPoint , newUserFactor , data.gammaPrice , data.totalAllocPoint , data.price , newTotalFactor, index)
              apr = apr !== undefined && !isNaN(apr) && apr > 10000 ? 10000 : apr !== undefined && !isNaN(apr) ? apr : 0;

              apy = (Math.pow((1 + (apr)/36500), 365) - 1) * 100;
              apy = apy !== undefined && !isNaN(apy) && apy > 10000 ? 10000 : apy !== undefined && !isNaN(apy) ? apy : 0;
              if(index == 1) {
                  setPoolApy_0(apy)
              } else if(index == 2){
                  setPoolApy_1(apy)
              } else {
                  setPoolApy_2(apy)
              }
          })

          if(IUnderstand){

              const currentUserFactor: any = gammaBtcbPool.userFactor;
              const newUserShares: any = +gammaBtcbLpObject.liquidity;
              const newUserFactor : any = currentUserFactor + (newUserShares * lockTimeMultiplier);
              const currentTotalFactor: any = data.totalFactor;   
              const newTotalFactor: any =  currentTotalFactor - currentUserFactor + newUserFactor;
              let gamma_btcb_apr = (data.farmV3DripRate * blocksPerYear * data.allocPoint * newUserFactor * data.gammaPrice * 100)/(data.totalAllocPoint * data.price * newTotalFactor * 1e18)
              gamma_btcb_apr = apr !== undefined && !isNaN(gamma_btcb_apr) && gamma_btcb_apr > 10000 ? 10000 : gamma_btcb_apr !== undefined && !isNaN(gamma_btcb_apr) ? gamma_btcb_apr : 0;

              let gamma_btcb_apy = (Math.pow((1 + (gamma_btcb_apr)/36500), 365) - 1) * 100;
              gamma_btcb_apy = gamma_btcb_apy !== undefined && !isNaN(gamma_btcb_apy) && gamma_btcb_apy > 10000 ? 10000 : gamma_btcb_apy !== undefined && !isNaN(gamma_btcb_apy) ? gamma_btcb_apy : 0;
              setGammaBtcbApy(gamma_btcb_apy)
          }
          
      } else {
          // console.log("gamma btcb val less than 5%")
          apr = 0;
          apy = 0;
      }
    }
  }
}

const handleSuppliedGammaBtcbPercentChange = (percent: any) => {
  // console.log("percent val", percent)
  setGammaBTCBPercent(percent)
}

const buildTxForSwap = async () => {
  let inputToken = sendToken.address;
  let protocols = await getProtocols();
  //console.log('protocols', protocols);
  protocols = protocols.toString();
  let sendAmount: any = noExponents(convertToWei(actualSendAmount)).split(".");
  // console.log(" send amount in swap tx", sendAmount)

  const swapParams = {
    fromTokenAddress: inputToken, // 1INCH
    toTokenAddress: data.wantAddress, // DAI
    amount: sendAmount[0],
    fromAddress: planetZapOneInchAddress,
    slippage: slippageVal,
    disableEstimate: true,
    allowPartialFill: false,
    referrerAddress: referralAddress,
    fee: referralFees,
    protocols: protocols
  };

  // console.log('swapParams', swapParams);
  const url = apiRequestUrl('/swapDataNew', swapParams.toTokenAddress, swapParams.fromTokenAddress, swapParams.amount, swapParams.slippage, swapParams.fromAddress);
  // console.log(url)
  return fetch(url).then(res => res.json()).then(res => res.tx);
}

const handleCreateLp: any = async () => {
  // console.log("inside handleCreateLp")
  if (selector.ethData.ethWalletConnected) {
      try {
          // setCreateLpLoading(true)
          showZapStakeModal(true)
          setTransactionLoader(true)
          if(data.name.toLowerCase() == "aqua") {
              // console.log(" in handle 1 inch swap")
              await handle1InchSwap()
          } else {
              let res: any = ''
              let existingLPTokens = data.walletBalance;
              // console.log('data.walletBalance' , tokenList, sendToken)
              // console.log("create lp send params", sendAmount, data.poolId, sendToken.address, transInfo, selector.ethData.address, showTransLink, data, slippageVal)
              let gammaBtcbPercentage = IUnderstand ? suppliedGammaBtcbPercent : 0;
              BigNumber.set({
                EXPONENTIAL_AT: [-10, 50],
              })
              let _sendAmount = convertToWei(sendAmount);
              // let send_Amount = maxSendAmount? sendToken.value : sendAmount;
              // let _sendAmount: any = (send_Amount * (100 - gammaBtcbPercentage))/100;
              
              // let x = new BigNumber(sendToken.maxBalanceInWei)
              // x = x.multipliedBy(100 - gammaBtcbPercentage).dividedBy(100) //decimals = 1e18,1e8 etc.
              // if(maxSendAmount) {
              //   _sendAmount = convertToEther(x, 18);
              // }
              
              /*
              let sendAmountInWei = convertToWei(sendAmount, 1e18)
                    
                    let _sendAmount = BigNumber(sendAmountInWei).multipliedBy(100 - gammaBtcbPercentage).dividedBy(100).toFixed(0).toString()//(sendAmount * (100 - gammaBtcbPercentage))/100;
                    console.log("create lp send amount", _sendAmount)
              */
              // console.log("send amount for create lp", _sendAmount)
              res = await createLPV3(_sendAmount, data.poolId, sendToken.address, transInfo, selector.ethData.address, showTransLink, data, slippageVal, updateTokenList, dispatch, handleNotification, setConfirmTransHistory, setCreateLpLoading, lock_time, false)

              if(res == "failed") {
                  //dispatch(setPortfolioLoading(true))
                  //dispatch(updateUserBalance(selector.ethData.address))
                  setTransactionLoader(false)
              } else {
                  setZapInState(true)
              }
              setTimeout(async () => {
                  setTransactionLoader(false)
                  showZapStakeModal(false)
                  setCreateLpLoading(false)
                  dispatch(updateUserLendingData(selector.ethData.address))
                  dispatch(updateUserBalance(selector.ethData.address))
              }, 3000)
              
          }
      }
      catch (error) {
          console.log('handleCreateLp error', error);
          setTransactionLoader(false)
          showZapStakeModal(false)
          // handleNotification('failed')
      }
      finally {
          setCreateLpLoading(false)
          setShowTransModal(false)
      }
  }
}

const handle1InchSwap = async () => { 
  // setBtnLoading(true);
  // setTransactionLoader(true)
  // setTokenAllowanceFlag(true)
  // setShowTxConfModal(true)
  let swapTransaction = await buildTxForSwap();
  const userAddress = selector.ethData.address.replace('0x', '');
  const _planetZapOneInchAddress = planetZapOneInchAddress.replace('0x', '');
  swapTransaction = swapTransaction.data.replace(userAddress.toLowerCase(), _planetZapOneInchAddress.toLowerCase())
  //console.log('Transaction for swap: ', swapTransaction);
  try{
      if(sendToken.address.toLowerCase() == bnbAddress.toLowerCase()) {
          const swapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
          // console.log(swapTransaction, data.wantAddress, convertToWei(actualSendAmount))
          await swapInstance.methods
          .swapFromBNB(swapTransaction, data.wantAddress)
          .send({
              from: selector.ethData.address,
              value: convertToWei(actualSendAmount),
          }).on('sending', async function (res: any) {
              // setConfirmSwapModal(false);
          }).once('transactionHash', async function (res: any) {
              if (res && typeof res !== 'undefined') {
              const transLink = `${transLinkUrl}/${res}`
              setTransactionLink(transLink)
              handleTransactionLink(transLink, res)
              setShowTransModal(true)
              }
          }).once('confirmation', async function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              // setBtnLoading(false)
              // setSwapTokenFlag(true)
              // console.log("call deposit on v3 farm", data.poolId, transInfo.minLiquidityInWei, userAddress, showNotification, data, false, lock_time)

              //check approval for the farm in aqua
              // await handleApproveFarm()
              //(poolId: any, amount: any, userAddress: any, showNotification: any, poolData: any, maxAmtSelected:boolean = false, time_lock: any) 
              setTimeout(async () => {
                  setTransactionLoader(false)
                  showZapStakeModal(false)
                  setCreateLpLoading(false)
              }, 3000)
              dispatch(updateUserBalance(selector.ethData.address))
              dispatch(updateUserLendingData(selector.ethData.address))
          })
          .on('error', function (error: any) {
              handleNotification('failed')
              setShowTransModal(false)
              // setBtnLoading(false)
              setTransactionLoader(false)
              showZapStakeModal(false)
              // setShowTxConfModal(false)
          })
      }
      else {
          const swapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
          // console.log("handle 1 inch in else",sendToken.address, convertToWei(actualSendAmount), swapTransaction, data.wantAddress)
          await swapInstance.methods
          .swap(sendToken.address, convertToWei(actualSendAmount), swapTransaction, data.wantAddress)
          .send({
          from: selector.ethData.address,
          value: 0
          }).on('sending', async function (res: any) {
          // setConfirmSwapModal(false);
          }).once('transactionHash', async function (res: any) {
          if (res && typeof res !== 'undefined') {
              const transLink = `${transLinkUrl}/${res}`
              setTransactionLink(transLink)
              handleTransactionLink(transLink, res)
              setShowTransModal(true)
          }
          }).once('confirmation', async function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              // setSwapTokenFlag(true)
              
              setTimeout(async () => {
                setTransactionLoader(false)
                showZapStakeModal(false)
                setCreateLpLoading(false)
            }, 3000)
            dispatch(updateUserLendingData(selector.ethData.address))
            dispatch(updateUserBalance(selector.ethData.address))
              //check approval for the farm in aqua


              // await handleApproveFarm()
              // setBtnLoading(false)
             
          })
          .on('error', function (error: any) {
              handleNotification('failed')
              setShowTransModal(false)
              // setBtnLoading(false)
              setTransactionLoader(false)
              showZapStakeModal(false)
              // setShowTxConfModal(false)
          })
      }
    
  } catch (error){
    console.log(error)
    setTransactionLoader(false)
  //   setShowTxConfModal(false)
  } finally{
    setShowTransModal(false)
      //setBtnLoading(false)
    setSendAmount('')
  //   setReceiveAmount('')
  //   setMinReceive('0.00')
    updateTokenList()
  //   setSendAmountCheck(0)
  //   setSendAmountWithoutComma(0)
  //   setReceiveAmountWithoutComma(0)
  //   setSwapWarning(false)
  //   setMinReceive('0.00')
  //   dispatch(silentUpdateOnInfStake(selector.ethData.address))
    dispatch(updateUserLendingData(selector.ethData.address))
    dispatch(updateUserBalance(selector.ethData.address))
  }
  
}

const handleApproveToken = async () => {
  // console.log("inside approve token")
  if (selector.ethData.ethWalletConnected) {
    showZapStakeModal(true)
    setZapInState(false);
    setTokenAllowance(false);
    setTransactionLoader(true)
    try {
      setApproveLoading(true)
      let approvalContract = aquaZapInAddress;
      const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, sendToken.address)
      let allowanceAmount: any = await TOKEN_INSTANCE.methods.allowance(selector.ethData.address, approvalContract).call();
      // console.log("allowance amount in checkTokenApproval", allowanceAmount);
      let allowance: any = convertToEther(allowanceAmount, 18);
      setAllowanceAmt(allowance)
      let _sendAmount = sendAmount;
        if(parseFloat(allowance) >= parseFloat(_sendAmount) || sendToken.name.toLowerCase() == 'bnb'){
            setTokenAllowance(true)
            setApproveLoading(false)
            let createlp_res = handleCreateLp();
        } else {

              
          const approvalAmount = maxAmount
          // console.log('approvalAmount', approvalAmount);
          await TOKEN_INSTANCE.methods
            .approve(approvalContract, approvalAmount)
            .send({ from: selector.ethData.address })
            .once('transactionHash', function (res: any) {
                // console.log('approve transactionHash');
                if (res && typeof res !== 'undefined') {
                    const transLink = `${transLinkUrl}/${res}`
                    setTransactionLink(transLink)
                    // setShowTransModal(true)
                }
            })
            .once('confirmation', async function (confNumber: any, receipt: any) {
                const type = receipt.status ? 'success' : 'failed'
                // handleNotification(type, receipt.transactionHash)
                //console.log('approve confirmation');
                setApproveLoading(false)
                handleApproveNotification(type, receipt.transactionHash)
                let allowanceAmount: any = await TOKEN_INSTANCE.methods.allowance(selector.ethData.address, approvalContract).call();
                // console.log("allowance amount in checkTokenApproval", allowanceAmount);
                let allowance: any = convertToEther(allowanceAmount, 18);
                setAllowanceAmt(allowance)
                if(parseFloat(allowance) >= parseFloat(_sendAmount) || sendToken.name.toLowerCase() == 'bnb'){
                    setTokenAllowance(true)
                    let createlp_res = handleCreateLp();
                    // console.log("create lp response", createlp_res)
                } else {    
                    handleApproveToken()
                }
            })
            .on('error', function (error: any) {
                setTransactionLink('')
                // setShowTransModal(false)
                console.log('handleApprove token error' ,error)
                // checkTokenApproval()
                setTransactionLoader(false)
                setApproveLoading(false)
            })
        }
      } catch (error) {
          setTransactionLoader(false)
          showZapStakeModal(false)
          setApproveLoading(false)
      } finally {
          // console.log('approve finally');
      }
  }

}

const showNotification = (type: string, hash?: any) => {
  const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
  const data: any = {
      type: type,
      message: '',
      link: linkUrl
  }
  dispatch(notificationClose())
  dispatch(notificationOpen(data))
}

const handleApproveFarm = async () => {
  if (selector.ethData.ethWalletConnected) {
      showZapStakeModal(true)
      setTransactionLoader(true)
      try {
          setApproveLoading(true)
          let approvalContract = gammaFarmV3;
          const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, data.wantAddress)
          let allowanceAmount: any = await TOKEN_INSTANCE.methods.allowance(selector.ethData.address, approvalContract).call();
          // console.log("allowance amount in checkTokenApproval", allowanceAmount);
          let allowance: any = convertToEther(allowanceAmount, 18);
          setAllowanceAmt(allowance)
          let _sendAmount = sendAmount;
          if(parseFloat(allowance) >= parseFloat(transInfo.liquidity)){
              setApproveFarm(true)
              setApproveLoading(false)
              await handleDepositV3(data.poolId, transInfo.minLiquidityInWei, selector.ethData.address, showNotification, data, false, lock_time);
              setDepositOnFarm(true)
              setTimeout(async () => {
                  setTransactionLoader(false)
                  showZapStakeModal(false)
                  setCreateLpLoading(false)
              }, 3000)
          } else {
              const approvalAmount = maxAmount
              await TOKEN_INSTANCE.methods
                  .approve(approvalContract, approvalAmount)
                  .send({ from: selector.ethData.address })
                  .once('transactionHash', function (res: any) {
                      //console.log('approve transactionHash');
                      if (res && typeof res !== 'undefined') {
                          const transLink = `${transLinkUrl}/${res}`
                          setTransactionLink(transLink)
                          // setShowTransModal(true)
                      }
                  })
                  .once('confirmation', async function (confNumber: any, receipt: any) {
                      const type = receipt.status ? 'success' : 'failed'
                      // handleNotification(type, receipt.transactionHash)
                      //console.log('approve confirmation');
                      setApproveLoading(false)
                      handleApproveNotification(type, receipt.transactionHash)
                      let allowanceAmount: any = await TOKEN_INSTANCE.methods.allowance(selector.ethData.address, approvalContract).call();
                      // console.log("allowance amount in checkTokenApproval", allowanceAmount);
                      let allowance: any = convertToEther(allowanceAmount, 18);
                      setAllowanceAmt(allowance)
                      if(parseFloat(allowance) >= parseFloat(transInfo.liquidity)){
                          setApproveFarm(true)
                          await handleDepositV3(data.poolId, transInfo.minLiquidityInWei, selector.ethData.address, showNotification, data, false, lock_time);
                          setDepositOnFarm(true)
                          // console.log("create lp response", createlp_res)
                      } else {    
                          handleApproveFarm()
                      }
                  })
                  .on('error', function (error: any) {
                      setTransactionLink('')
                      // setShowTransModal(false)
                      console.log('handleApprove token error' ,error)
                      // checkTokenApproval()
                      setTransactionLoader(false)
                      setApproveLoading(false)
                  })
          }

          
      } catch (error) {
          setTransactionLoader(false)
          showZapStakeModal(false)
          setApproveLoading(false)
      } finally {
          // console.log('approve finally');
      }
  }

}

  
  return (
    <Fragment>
      <CardCont className='zapIn'>
        <AddLDCont>
          <FlexSBCont>
            <BackButton className='backButton' onClick={() => setLiquidityTab('liquidity')}>
              <img className='backIcon' src={ArrowDownGrey} alt="back-btn" />
              <InfoText className="backButtonText" light>
                Back
              </InfoText>
            </BackButton>
            <TitleText>Zap In</TitleText>
            {/* <img className=" clock-icon" src={ClockIcon} alt="" onClick={() => setShowHistory(true)} /> */}
            <FlexCont>
                <img className='trans-icon' src={SettingIcon} alt="" onClick={() => setShowSettings(true)} />
            </FlexCont>
          </FlexSBCont>

          {/* {Object.values(data).length > 0 && data.name && data.name.toLowerCase() == "gamma-btcb" && suppliedOtherPoolsAmount !== undefined && suppliedOtherPoolsAmount > 0 &&
            <FlexSBCont className='gammaBtcbCont'>
                <FlexSBCont style={{flexDirection: "column", justifyContent: "flex-start"}}>
                    <TransBalTxt className="minPoolPercent">5.8%</TransBalTxt>
                    <TransBalTxt className="percentBalance">${minGammaBtcbPoolRatio.toFixed(2)}<span>/${parseFloat(minEligibileGammaBtcbAmount).toFixed(2)}</span></TransBalTxt>
                </FlexSBCont>
                <FlexSBCont className='gammaBtcbReqCont' style={{}}>
                    <TransBalTxt className="minPoolText">Want to unlock GAMMA Rewards on Pools?</TransBalTxt>
                    <TransBalTxt className="minPoolSubText">Meet the 5% deposit requirement to become eligible</TransBalTxt>
                    <SliderContainer borrowLimit={100} className={suppliedGammaBtcbPercent > 75 && suppliedGammaBtcbPercent < 90 ? 'SliderContainerPopup poolsLimit OrangeLimit': suppliedGammaBtcbPercent > 90 ? 'SliderContainerPopup poolsLimit RedLimit' : 'SliderContainerPopup poolsLimit'}>
                            <Slider min={0} max={100} value={minGammaBtcbPoolRatio && minGammaBtcbPoolRatio ? minGammaBtcbPoolRatio : 0} tooltip={false} />
                    </SliderContainer>
                    <FlexSBCont style={{alignSelf: "flex-start"}}>
                        <TransBalTxt className="eligibilityTxt" disabled={createLpLoading && !selector.ethData.ethWalletConnected && inSufAmt && isAmtLoading && !minGammaBtcbRequired} onClick={()=> setInputAmountOnMeetEligibility()}>Meet Eligibility</TransBalTxt>
                        <img className='RightIcon' src={RightIcon} alt="" />
                    </FlexSBCont>
                </FlexSBCont>
            </FlexSBCont>
          } */}
          {/* {Object.values(data).length > 0 && data.name.toLowerCase() != "gamma-btcb" &&
          <FlexSBCont style={{marginBottom: "10px"}}>
              <CardText className='migrationLockTime'>Lock Time:</CardText>
          </FlexSBCont>
          }
          {Object.values(data).length > 0 && data.name.toLowerCase() != "gamma-btcb" &&
          <FlexCont style={{justifyContent: "space-between"}}>
              <FlexSBCont className={lock_time === 0 ? 'migration-pool-cont apyCont green' : 'migration-pool-cont apyCont'} onClick={() => setLockTimeForPool(0)}>
                  <CardText className={lock_time === 0 ?'migrationApyPool green': 'migrationApyPool'}>{commaFy(convertToKAndM(poolApy_0.toFixed(2)))}% Apy</CardText>
                  <CardSubText className="migration-pool-subtext">90 days</CardSubText>
              </FlexSBCont>
              <FlexSBCont className={lock_time === 1 ? 'migration-pool-cont apyCont green' : 'migration-pool-cont apyCont'} onClick={() => setLockTimeForPool(1)}>
                  <CardText className={lock_time === 1 ?'migrationApyPool green': 'migrationApyPool'}>{commaFy(convertToKAndM(poolApy_1.toFixed(2)))}% Apy</CardText>
                  <CardSubText className="migration-pool-subtext">180 days</CardSubText>
              </FlexSBCont>
              <FlexSBCont className={lock_time === 2 ? 'migration-pool-cont apyCont green' : 'migration-pool-cont apyCont'} onClick={() => setLockTimeForPool(2)}>
                  <CardText className={lock_time === 2 ?'migrationApyPool green': 'migrationApyPool'}>{commaFy(convertToKAndM(poolApy_2.toFixed(2)))}% Apy</CardText>
                  <CardSubText className="migration-pool-subtext">360 days</CardSubText>
              </FlexSBCont>
          </FlexCont>
          } */}
          {/* <InfoText className="add-token-ld" light>
            Add tokens to a liquidity pool
          </InfoText> */}
          <AddLDTokenCont>
            <AddLDCard>
              <FlexSBCont>
                <InfoLDText>Send</InfoLDText>
                <InfoLDText>{`Balance: ${!!sendToken && Object.values(sendToken).length > 0 ? parseFloat(sendToken.value).toFixed(4) : '-'
                  }`}</InfoLDText>
              </FlexSBCont>
              <FlexSBCont className="token-input">
                <TransInput
                  placeholder="0"
                  disabled={!(Object.values(sendToken).length > 0 && Object.values(data).length > 0)}
                  onChange={handleSendAmount}
                  value={sendAmountInComma && sendAmountInComma}
                  autoFocus={true}
                />
                <FlexCont>
                  {Object.values(sendToken).length > 0 && <TransMaxBox className='maxBox' onClick={() => handleMaxAmount()}>Max</TransMaxBox>}
                  <FlexCont onClick={() => handleTokenModal('first')}>
                    {Object.values(sendToken).length > 0 ? (
                      <>
                        <img className="token-icon" src={sendToken.icon} alt="" />
                        <TokenName>{sendToken.name}</TokenName>
                      </>
                    ) : (
                      <SelectCurrTxt>Select Currency</SelectCurrTxt>
                    )}
                    <img className="arrow-down" src={ArrowDown} alt="" />
                  </FlexCont>
                </FlexCont>
              </FlexSBCont>
            </AddLDCard>
            
            <AddLDCard className='zapInReceive'>
              <FlexSBCont>
                <InfoLDText>Receive</InfoLDText>
                <InfoLDText>{`Balance: ${!!data && Object.values(data).length > 0 ? parseFloat(data.value).toFixed(4) : '-'
                  }`}</InfoLDText>
              </FlexSBCont>
              <FlexSBCont className="token-input">
                <TransInput
                  placeholder="0"
                  disabled={true}
                  onChange={handlelpAmount}
                  value={!!transInfo && transInfo.liquidity && parseFloat(sendAmount) > 0 ? `${parseFloat(transInfo.liquidity).toFixed(6)}` : 0}
                />
                <FlexCont>
                  <FlexCont onClick={() => handleLPTokenModal('lp')}>
                    {Object.values(data).length > 0 ? (
                      <>
                        <CellCont className="boostPoolImg">{renderTokenIcons(data.name, data.isBoostPool)}</CellCont>
                        
                        <TokenName>{data.name}</TokenName>
                      </>
                    ) : (
                      <SelectCurrTxt>Select Currency</SelectCurrTxt>
                    )}
                    <img className="arrow-down" src={ArrowDown} alt="" />
                  </FlexCont>
                </FlexCont>
              </FlexSBCont>
            </AddLDCard>  
          </AddLDTokenCont>
          {!isPairExist && (Object.values(sendToken).length > 0 && Object.values(data).length > 0) && (
            <InfoSmallText className="warning-text"> Liquidity pair does not exist</InfoSmallText>
          )}
          {isAmtLoading &&
            <PoolDetailsCont>
              <InfoSmallText style={{ textAlign: 'center' }}> Amount loading...</InfoSmallText>
            </PoolDetailsCont>
          }
          
            <TransDataCont>
            {/* {
                isAmtLoading && parseFloat(sendAmount) > 0 && <TransInoTxt className="loading-text">Amount Loading ...</TransInoTxt>
            } */}
            
                <>
                    {/* <FlexSBCont className='info-space'>
                        <TransInoTxt className="info-text-gamma-btcb" light>Zap to GAMMA-BTCB</TransInoTxt>
                        <UnderstandCheckBox className="vesting-checkbox" onClick={() => setIUnderstand(!IUnderstand)}>{IUnderstand && <img src={green_tick} alt="" />}</UnderstandCheckBox>
                    </FlexSBCont> */}
                    {/* { IUnderstand &&
                        <FlexSBCont className='info-space'>
                            <TransInoTxt className="info-text-gamma-btcb" light>{suppliedGammaBtcbPercent}%</TransInoTxt>
                            <TransInoTxt className="info-text-val">{gammaBtcbApy.toFixed(2)}%</TransInoTxt>
                        </FlexSBCont>
                    } */}
                    {/* { IUnderstand &&
                        <FlexSBCont className='info-space'>
                            <TransInoTxt className="info-text" light>GAMMA- BTCB deposit percentage</TransInoTxt>
                            <TransInoTxt className="info-text-val">New APY</TransInoTxt>
                        </FlexSBCont>
                    } */}
                    {/* { IUnderstand &&
                        <SliderContainer borrowLimit={100} className={suppliedGammaBtcbPercent > 75 && suppliedGammaBtcbPercent < 90 ? 'SliderContainerPopup poolsLimit OrangeLimit': suppliedGammaBtcbPercent > 90 ? 'SliderContainerPopup poolsLimit RedLimit' : 'SliderContainerPopup poolsLimit'}>
                            <Slider min={0} max={100} value={suppliedGammaBtcbPercent} step={1} tooltip={false} onChange={handleSuppliedGammaBtcbPercentChange} />
                        </SliderContainer>
                    } */}
                </>
                {Object.values(data).length > 0 && data.name.toLowerCase() != "aqua" &&
                <FlexSBCont className="token-data">
                    <FlexCont >
                        <img className="icon-data" src={getIcons(1)} alt="" />
                        <TransTxt>{getTokenName(1)}</TransTxt>
                    </FlexCont>
                    <TransTxt>{!!transInfo && transInfo.amountsOut0 && parseFloat(sendAmount) > 0 && lpToken0.price !== undefined ? `${parseFloat(transInfo.amountsOut0).toFixed(6)} ($${parseFloat((transInfo.amountsOut0 * +lpToken0.price).toString()).toFixed(2)})` : 0}</TransTxt>
                </FlexSBCont>
              }
                {Object.values(data).length > 0 && data.name.toLowerCase() == "aqua" ?
                <FlexSBCont className="token-data">
                  <FlexCont >
                      <img className="icon-data" src={getIcons(1)} alt="" />
                      <TransTxt>{getTokenName(1)}</TransTxt>
                  </FlexCont>
                  <TransTxt>{!!transInfo && transInfo.amountsOut1 && parseFloat(sendAmount) > 0 && lpToken1.price !== undefined ? `${parseFloat(transInfo.amountsOut1).toFixed(6)} ($${parseFloat((transInfo.amountsOut1 * +lpToken1.price).toString()).toFixed(2)})` : 0}</TransTxt>
              </FlexSBCont>
              :
              <FlexSBCont className="token-data">
                  <FlexCont >
                      <img className="icon-data" src={getIcons(2)} alt="" />
                      <TransTxt>{getTokenName(2)}</TransTxt>
                  </FlexCont>
                  <TransTxt>{!!transInfo && transInfo.amountsOut1 && parseFloat(sendAmount) > 0 && lpToken1.price !== undefined ? `${parseFloat(transInfo.amountsOut1).toFixed(6)} ($${parseFloat((transInfo.amountsOut1 * +lpToken1.price).toString()).toFixed(2)})` : 0}</TransTxt>
              </FlexSBCont>
              }
              {Object.values(data).length > 0 && data.name.toLowerCase() != "aqua" &&
              <FlexSBCont >
                  <FlexCont >
                      <TwoImageCont>
                          <img src={getIcons(1)} alt="" />
                          <img className="second-img" src={getIcons(2)} alt="" />
                      </TwoImageCont>
                      <TransTxt>{getTokenName(0)}</TransTxt>
                  </FlexCont>
                  <TransTxt>{!!transInfo && transInfo.liquidity && parseFloat(sendAmount) > 0 ? `${parseFloat(transInfo.liquidity).toFixed(6)} LP ($${parseFloat((transInfo.liquidity * +data.price).toString()).toFixed(2)})` : 0}</TransTxt>
              </FlexSBCont>
              }
            </TransDataCont>
            {
                transInfo.impactAbove3Percent && !inSufAmt && parseFloat(sendAmount) > 0 &&
                <PriceImpactBox>
                    <p>Price impact is over 3%, please try a smaller amount</p>
                </PriceImpactBox>
            }
            {
                warningMessage && 
                <PriceImpactBox>
                    <p>High slippage value, please try a smaller amount</p>
                </PriceImpactBox>
            }
            {
                    sendToken.address === bnbAddress ?
                        <TransButton className="zapNStake" disabled={!(parseFloat(sendAmount) > 0) || createLpLoading || !selector.ethData.ethWalletConnected || inSufAmt || transInfo.impactAbove3Percent || isAmtLoading} onClick={() => handleApproveToken()}> {createLpLoading ? <Spinner /> : inSufAmt ? 'Insufficient Balance' : 'Zap In'}</TransButton> :
                        <>
                            {
                                parseFloat(allowanceAmt) >= parseFloat(sendAmount) || parseFloat(sendAmount) > parseFloat(sendToken.value) ?
                                <TransButton className="zapNStake" disabled={!(parseFloat(sendAmount) > 0) || createLpLoading || !selector.ethData.ethWalletConnected || inSufAmt || transInfo.impactAbove3Percent || isAmtLoading || parseFloat(sendAmount) > parseFloat(sendToken.value)} onClick={() => handleApproveToken()}> {createLpLoading ? <Spinner /> : inSufAmt ? 'Insufficient Balance' : 'Zap In'}</TransButton>
                                :
                                <TransButton className="zapNStake" disabled={!(parseFloat(sendAmount) > 0) || approveLoading || !selector.ethData.ethWalletConnected || createLpLoading || isAmtLoading} onClick={() => handleApproveToken()}> {approveLoading ? <Spinner /> : 'Zap In'}</TransButton> 
                                    
                            }
                        </>

                }
        </AddLDCont>
        
      </CardCont>
      <TransCard className='zapIn'>
            <FlexSBCont className='info-space'>
                <TransInoTxt light>Slippage Tolerance</TransInoTxt>
                <TransInoTxt>{slippageVal}%</TransInoTxt>
            </FlexSBCont>
            <FlexSBCont className='info-space'>
                <TransInoTxt light>Price Impact</TransInoTxt>
                <TransInoTxt>{priceImpact? parseFloat(priceImpact).toFixed(2) : 0}%</TransInoTxt>
            </FlexSBCont>
            <FlexSBCont className='info-space'>
                <TransInoTxt light>Minimum Received</TransInoTxt>
                <TransInoTxt>{!!transInfo && transInfo.liquidity && parseFloat(sendAmount) > 0 ? parseFloat(transInfo.minLiquidity).toFixed(6) : 0} LP</TransInoTxt>
            </FlexSBCont>
            <FlexSBCont className='info-space'>
                <TransInoTxt light>LP Share</TransInoTxt>
                <TransInoTxt>{priceImpact? parseFloat(priceImpact).toFixed(2) : 0}%</TransInoTxt>
            </FlexSBCont>
        </TransCard>    
      <CustomModal show={showTokenModal} toggleModal={handleToggleModal}>
        {showTokenModal && (
          <TokenSelect
            selectedAdd={sendToken.address}
            setShowTokenModal={setShowTokenModal}
            tokenListVal={tokenList}
            handleTokenSelect={handleTokenSelect}
            existTokenAdd={existTokenAdd}
          />
        )}
      </CustomModal>
      <CustomModal show={showLPTokenModal} toggleModal={handleToggleLPModal}>
        {showLPTokenModal && (
          <TokenSelect
            selectedAdd={data.strategyAddress}
            setShowTokenModal={setShowLPTokenModal}
            tokenListVal={lpList}
            handleTokenSelect={handleLPTokenSelect}
            existTokenAdd={existTokenAdd}
          />
        )}
      </CustomModal>

        <CustomModal show={showSettings} toggleModal={setShowSettings}>
            {showSettings && (
                <Settings
                    setShowSettings={setShowSettings}
                    slippageVal={slippageVal}
                    setSlippageVal={setSlippageVal}
                    handleSlippage={handleSlippage}
                    // deadLine={deadLine}
                    // setDeadLine={setDeadLine}
                />
            )}
        </CustomModal>

      <CustomModal show={showCnfModal} toggleModal={setShowCnfModal}>
        {showCnfModal && (
          <ConfirmLiquidity setShowCnfModal={setShowCnfModal} confirmLdData={confirmLdData} handleAddLiquidity={handleAddLiquidity} />
        )}
      </CustomModal>
      <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
        {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
      </CustomModal>
      <CustomModal show={zapStakeModal} toggleModal={showZapStakeModal}>
        {zapStakeModal && <ZapInStakeModal showZapStakeModal={showZapStakeModal} transactionLink={transactionLink} zapInStateGammaBtcb={zapInStateGammaBtcb} zapInState={zapInState} tokenAllowance={tokenAllowance} poolDetails={data} sendToken={sendToken} transactionLoader={transactionLoader} IUnderstand={IUnderstand} approveFarm={approveFarm} depositOnFarm={depositOnFarm} />}
      </CustomModal>
    </Fragment>
  )
}

const ZapInStakeModal = (props: any) => {
  const {showZapStakeModal, zapInState, zapInStateGammaBtcb, tokenAllowance, poolDetails, sendToken, transactionLoader, IUnderstand, approveFarm, depositOnFarm} = props
  return (
      <Fragment>
          <TransInputCont className='zapNStake'>
              <FlexSBCont className='titleCont'>
                  <InputTitle className='confTitle'>Zap In</InputTitle>
                  <img className='cross-icon' src={CrossIcon} alt="" onClick={() => showZapStakeModal(false)} />
              </FlexSBCont>
              <FlexSBCont className='allowanceCheck'>
                  <InputTitle className='confText'>Approve {sendToken.name}</InputTitle>
                  {transactionLoader == true && tokenAllowance == false ?
                  <Spinner style={"transactionBox"} />
                  :
                  <img className='trans-icon' src={tokenAllowance ? green_tick : grey_tick} alt="" />
                  }
              </FlexSBCont>
              {/* {IUnderstand && 
              <FlexSBCont className='allowanceCheck'>
                  <InputTitle className='confText'>Zap In into GAMMA-BTCB</InputTitle>
                  {transactionLoader == true && tokenAllowance && zapInStateGammaBtcb == false ?
                  <Spinner style={"transactionBox"} />
                  :
                  <img className='trans-icon' src={zapInStateGammaBtcb ? green_tick : grey_tick} alt="" />
                  }
              </FlexSBCont>
              } */}
              
              <FlexSBCont className='allowanceCheck'>
                  <InputTitle className='confText'>{poolDetails.name.toLowerCase() == "aqua" ? `Swap ${sendToken.name} into AQUA` : `Zap In into ${poolDetails.name}`}</InputTitle>
                  {transactionLoader == true && tokenAllowance && zapInState == false ?
                  <Spinner style={"transactionBox"} />
                  :
                  <img className='trans-icon' src={zapInState ? green_tick : grey_tick} alt="" />
                  }
              </FlexSBCont>
          </TransInputCont>
      </Fragment>
  )
}
export default ZapIn