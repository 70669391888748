import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LoadingText4 } from 'shared/styles/planetTableStyle'
import { EarnValText } from './../style'
import { commaFy, checkZeroVal, convertToKAndM } from 'service/globalFunctions'

let timerForGreenPendingGAMMA = setInterval(function () { }, 0)
const VaultEarnings = (props: any) => {
    const selector = useSelector((state: any) => state)
    const { gPlatformData } = selector.lending
    const { userPortfolio, portfolioLoading } = props
    const [ greenPendingGAMMA, setGreenPendingGAMMA] = useState('0')
    useEffect(() => {
        if (timerForGreenPendingGAMMA) {
            clearInterval(timerForGreenPendingGAMMA); 
        }
        if(userPortfolio.greenPendingGAMMA)
        {
            startTimerTripping();
        }
        return () => clearInterval(timerForGreenPendingGAMMA);
    }, [userPortfolio])

    const startTimerTripping = () => {
        //console.log('gPlatformData',gPlatformData)
        if(userPortfolio.greenPendingGAMMA)
        {   
            userPortfolio.greenPendingGAMMA = userPortfolio.greenPendingGAMMA ? userPortfolio.greenPendingGAMMA : 0;
            setGreenPendingGAMMA(userPortfolio.greenPendingGAMMA)
            clearInterval(timerForGreenPendingGAMMA)
            let greenPendingGAMMA: any = userPortfolio.greenPendingGAMMA;

            let apy = gPlatformData.apyWithoutInfinityVaults ? gPlatformData.apyWithoutInfinityVaults : 0;
            let m: any = 365
            let a: any = apy / 100 + 1
            let apr = (Math.pow(a, 1 / m) - 1) * m
            gPlatformData.userTotalSupplyBal = gPlatformData.userTotalSupplyBal ? gPlatformData.userTotalSupplyBal : 0;
            // need to change following code based tripping criteria
            // supply balance * (aprWithoutInfinityVaults) / (365 * 86400)
            let trippingGreenPendingGAMMA:any = parseFloat(gPlatformData.userTotalSupplyBal) * (apr) / (365 * 86400)

            // console.log("trippingGreenPendingGAMMA", trippingGreenPendingGAMMA);
            let now = new Date();
            let timeWhenStarted = now.getTime();
            timerForGreenPendingGAMMA = setInterval(function () {
                now = new Date();
                let newGreenPendingGAMMA =  parseFloat(userPortfolio.greenPendingGAMMA) + (( now.getTime() - timeWhenStarted ) * trippingGreenPendingGAMMA / 1000);
                //console.log("newGreenPendingGAMMA", newGreenPendingGAMMA);
                setGreenPendingGAMMA(newGreenPendingGAMMA+"")
            }, 1000)
        }
    }

    if(portfolioLoading)
    {
        return (
            <LoadingText4 /> 
        )
    }
    else
    {
        return (
            <EarnValText className='inGamma' id="inGamma">
                {`${greenPendingGAMMA ? commaFy(parseFloat(greenPendingGAMMA).toFixed(4)) : '0.0000'} GAMMA`}
            </EarnValText>
        )
    }
};

export default VaultEarnings;