import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance, updateLPData, silentUpdateOnInfStake, fetchUserPortfolio } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexCont, FlexSBCont } from 'shared/styles/styled'
import { PoolActionRow, ExpandedCell, ExpendCellTitle, CellBtnGroup, TableInputCont, TableInput, MaxTxt, MaxTxtZero, TableBtn, WithdrawBtn, StartUnstakeLpBtn , TransformBtn, EarningCont, ClaimTxt, LoadingText3, ClaimLoading1, ClaimLoading2, DepositBtn, ConvertLpBtn, TimerBtn, CancelStakeBtn, FinishedUnStakingBtn,TwoImgCont, CellCont } from 'shared/styles/planetTableStyle'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { commaFy, getNumFormat, checkZeroVal } from 'service/globalFunctions'
import { transLinkUrl, dogeBnbTransLink } from 'service/global-constant'
import Spinner from 'shared/spinner'
import { gammaAddress, maxAmount } from 'modules/block-chain/lendingAbi'
import { selectInstance, instType, poolInfoNew, handleDeposit, handleWithdraw, harvestLpTokens, earnAquaProfits, isAllowanceApproved, convertToEther, deposit_in_infinity_vault_new, getUserUnstakingTimeInInfinityVaultNew, stopUnstakeProcessNew, unstake_after_min_time_new } from 'modules/block-chain/BlockChainMethods'
import { aquaAddress, aquaAutoCompPoolAddress, aquaFarmAddress, gammaFarmAdddress, gammaFarmAbi } from 'modules/block-chain/abi';
import wallet from 'modules/wallet/wallet'
import FinishedUnStakingIcon from 'assets/icons/finished-unstaking.png'
import CancelUnStakingIcon from 'assets/icons/cancel-unstaking.png'
import UnStakingTimeAqua from './UnStakingTimeAqua'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import {TokenSelectCont, TokenName, SelectCurrTxt} from '../components/pool-modal/styles'
import { tokenList, returnTokenList, swapTokenList, returnSwapTokenList, tokenSwapList } from './../../block-chain/tokenList'
import ArrowDown from 'assets/icons/arrow-down.png'
import { GTokenImgCont } from './style'
import AquaSyncIcon from 'assets/icons/aqua-sync.svg'
import GammaSyncIcon from 'assets/icons/gamma-sync.svg'
import aquaIcon from 'assets/icons/blue-icon.svg';
import gammaIcon from 'assets/icons/gamma-icon.png'
import { getTokenIcon } from 'shared/tokenIconList'
import thenaIcon from 'assets/icons/ThenaLogo.svg'
import bswIcon from 'assets/icons/bsw.svg'
import {convertToEtherRevised, tokenTransformAllowance} from 'modules/block-chain/Swap'
import { aquaZapInAddress } from 'modules/block-chain/SwapDexAbi'

const PoolTransaction = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const bnbSno = 1
    const { data, handleGetLpToken, showSwapPopup, handleTokenModal, sendToken, tokenDataList, tokenSelectedRow, setShowUnstakePopUp, unstakeData, setUnstakeData, confirmUnstake, setShowPoolDetails} = props
    const [depositAmount, setDepositAmount] = useState('')
    const [depositAmountWithComma, setDepositAmountWithComma] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [depositLoading, setDepositLoading] = useState(false)
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [instantUnstakeLoading, setInstantUnstakeLoading] = useState(false)
    const [claimLoading, setClaimLoading] = useState(false)
    const [claimBoostLoading, setClaimBoostLoading] = useState(false)
    const [isAllowance, setIsAllowance] = useState(false)
    const [maxWithdraw, setMaxWithdraw] = useState(false)
    const [maxDeposit, setMaxDeposit] = useState(false)
    const [maxTokenAmt, setMaxTokenAmt] = useState('0')
    const [expandedRowToken, setExpandedRowToken] = useState<any>(sendToken !== undefined ? sendToken : data)
    const [receiveToken, setReceiveToken] = useState<any>({})
    const [aquaToken, setAquaToken] = useState<any>({})
    const [gammaToken, setGammaToken] = useState<any>({})
    const [stopUnstakeLoader, setStopUnstakeLoader] = useState(false)
    const [finishUnstakeLoader, setFinishUnstakeLoader] = useState(false)

    // const [tokenDataList, setTokenDataList] = useState<any>(tokenSwapList)
    const { portfolioLoading } = selector.user
    
    const [countDownDate, setCountDownDate] = useState('')
    useEffect(() => {
        let isMounted = true;
        // console.log("pool data in tx page", data, sendToken, expandedRowToken)
        if(expandedRowToken.address.toLowerCase() == data.strategyAddress.toLowerCase()){
            expandedRowToken.value = data.walletBalance;
            expandedRowToken.usdValue = data.walletBalanceUSD;
            setExpandedRowToken(expandedRowToken)
        }
        const userAddress = selector.ethData.address
        const getIsAllowance = async () => {
            const addGroup = data.wantaddress_farmaddress.split('_')
            const wantAddress = data.wantAddress
            let farmAddress = ""
            if (addGroup.length > 1) {
                farmAddress = addGroup[1]
            }
            try {
                const isAllow = await isAllowanceApproved(userAddress, farmAddress, wantAddress)
                
                // console.log('data.allowance', data.allowance);
                // let allowance = data.allowance ? convertToEther(data.allowance) : 0;
                if(parseFloat(data.allowance) > 0){
                    setIsAllowance(true)
                }


                if(!checkZeroVal(data.unstaking_bal)) {
                    const unStakeDate: any = await getUserUnstakingTimeInInfinityVaultNew(userAddress, data.pid)
                    // console.log('unStakeDate',unStakeDate);
                    if (!!unStakeDate && typeof unStakeDate !== 'undefined' && isMounted === true) {
                        // console.log('unStakeDate undefined',unStakeDate);
                        setCountDownDate(unStakeDate)
                    }
                }
            }
            catch (error) {
                console.log("error=>", error)
            }
        }
        if (!!data) {
            getIsAllowance()
        }

    }, [selector.ethData.address, data])


    useEffect(() => {
        // console.log("send token", sendToken)
        // console.log("tokenSelectedRow", tokenSelectedRow)
        // console.log("data.strategyAddress", data.strategyAddress)
        if(tokenSelectedRow.toLowerCase() == data.strategyAddress.toLowerCase()){
            setExpandedRowToken(sendToken)
        }
            // console.log("expandedRowToken in use effect", expandedRowToken)
    },[sendToken])

    useEffect(() => {
        let depositAmount: any;
        if(data.poolId < 2) {
            if(tokenSelectedRow.toLowerCase() == data.strategyAddress.toLowerCase() && sendToken.address.toLowerCase() == data.wantAddress.toLowerCase()){
                depositAmount = convertToEther(data.infinity_token_wallet_bal_with_precision)
            } else {
                depositAmount = sendToken.value;              
            }
            
        } else {
            if(tokenSelectedRow.toLowerCase() == data.strategyAddress.toLowerCase() && data.strategyAddress.toLowerCase() == sendToken.address.toLowerCase()){
                depositAmount = data.walletBalance;
            } else {
                depositAmount = sendToken.value;
                // setMaxTokenAmt(depositAmount)    
            }
            
        }
        if(data.poolStatus == 'active'){
            setMaxTokenAmt(depositAmount)
        } else {
            setMaxTokenAmt(data.walletBalance)
        }
        
    },[sendToken, data])

    useEffect(() => {
        setDepositAmount('0')
        setDepositAmountWithComma('0')
    }, [handleTokenModal])

    useEffect( () => {
        if(confirmUnstake) {
            if(confirmUnstake.confirm && confirmUnstake.poolId == data.poolId) {
                processUnstakeInstantLy();
            }
        }
    }, [confirmUnstake])

    const handleDepositAmountChanges = (e: any) => {
        // console.log("handle input deposit data", data, expandedRowToken)
        let { value } = e.target
        setMaxDeposit(false)
        if(+value === 0 ){
            value = value;
        } else {
            value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.replace(",", '') : "";
        }
        if (floatNumRegex.test(value.toString())) {
            setDepositAmount(value)
            setDepositAmountWithComma(commaFy(value))
            if(parseFloat(data.allowance) >= parseFloat(value)){
                setIsAllowance(true)
            } else {
                setIsAllowance(false)
            }
            if(data.poolStatus == 'active'){
                setMaxTokenAmt(expandedRowToken.value)
            } else {
                setMaxTokenAmt(data.walletBalance)
            }
        }
        
        if (!value) {
            setDepositAmount('')
            setDepositAmountWithComma('')
        }
    }
    const handleWithdrawAmountChanges = (e: any) => {
        const { value } = e.target
        setMaxWithdraw(false)
        if (floatNumRegex.test(value.toString())) {
            setWithdrawAmount(value)
        }
        if (!value) {
            setWithdrawAmount('')
            setMaxWithdraw(false)
        }
    }

    const handleMaxWithdrawAmount = (data: any) => {
        // console.log("withdraw amount", data)
        setWithdrawAmount(data.vaultBalance)
        setMaxWithdraw(true)
    }

    const approveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} approved` : `Failed to approve  ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleApprove = async () => {
        const userAddress = selector.ethData.address;
        if (!!userAddress) {
            try {
                setApproveLoading(true)
                let farmAddress: any;
                let LPinstance: any
                if (data.poolId === -1) {
                    farmAddress = aquaAutoCompPoolAddress
                    LPinstance = await selectInstance(instType.PANCAKELP, aquaAddress, true)
                }
                else {
                    farmAddress = data.farmContractAddress;
                    // if (data.isNewFarm) {
                    //     farmAddress = data.farmContractAddress
                    // }
                    // else {
                    //     farmAddress = data.farmContractAddress
                    // }
                    const poolDetails = await poolInfoNew(farmAddress, data.poolId)
                    const lpAddress = poolDetails['want']
                    LPinstance = await selectInstance(instType.PANCAKELP, lpAddress, true)
                }
                await LPinstance.methods
                    .approve(farmAddress, maxAmount)
                    .send({ from: userAddress })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        approveNotification(type, receipt.transactionHash)
                        dispatch(updateUserBalance(userAddress))
                        // setApproveLoading(false)
                        // setIsAllowance(true)
                    })
                    .on('error', function (error: any) {
                        approveNotification('failed')
                        setApproveLoading(false)
                    })
            }
            catch (error) {
                console.log("error==>", error)
                setApproveLoading(false)
            }
            finally {
                checkLPApproval();
            }
        }
    }

    const checkLPApproval = async() => {
        let farmAddress: any;
        let LPinstance: any;
        let poolDetails: any;
        try {
            if (data.poolId === -1) {
                farmAddress = aquaAutoCompPoolAddress
                LPinstance = await selectInstance(instType.PANCAKELP, aquaAddress, true)
            }
            else {
                if (data.isNewFarm) {
                    farmAddress = data.farmContractAddress
                }
                else {
                    farmAddress = data.farmContractAddress
                }
                poolDetails = await poolInfoNew(farmAddress, data.poolId)
                // console.log("poolDetails", poolDetails, farmAddress, data.poolId)
                const lpAddress = poolDetails['want']
                LPinstance = await selectInstance(instType.PANCAKELP, lpAddress, true)
            }
            let allowanceAmount: any = await LPinstance.methods.allowance(selector.ethData.address, farmAddress).call();
            let allowance: any = convertToEther(allowanceAmount[0]);
            if(parseFloat(allowance) >= parseFloat(depositAmount)){
                setIsAllowance(true)
            } else {
                setIsAllowance(false)
            }
            setApproveLoading(false)
        } catch (error){
            console.log(error);
            setApproveLoading(false)
            setIsAllowance(false)
        }
    }

    const showNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const data: any = {
            type: type,
            message: '',
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(data))
    }

    const handleDepositAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(depositAmount) > 0) {
            try {
                let depositAmt = depositAmount;
                setDepositLoading(true)
                if(maxDeposit && data.poolId > 1) {
                    depositAmt = data.walletBalance;
                }
                if(maxDeposit && data.poolId < 2){
                    depositAmt = data.infinity_token_wallet_bal_with_precision;
                }
                await handleDeposit(data.poolId, depositAmt, selector.ethData.address, showNotification, data, maxDeposit)
                setDepositLoading(false)
                dispatch(updateUserBalance(selector.ethData.address))
                dispatch(fetchUserPortfolio(selector.ethData.address, true))
                setDepositAmount('0')
                setDepositAmountWithComma('0')
                if(data.poolId < 2) {
                    if(depositAmt == data.infinity_token_wallet_bal_with_precision) {
                        setExpandedRowToken(tokenDataList[0]);
                    }
                }
                else {
                    if(depositAmt == data.walletBalance) {
                        setExpandedRowToken(tokenDataList[0]);
                    }
                }
                
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setDepositLoading(false)
            }
        }
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : '';
        const tokenVal: any = data.pid == 1 ? "AQUA" : "GAMMA"; 
        const msg = type === 'success' ? `Amount ${depositAmount} ${tokenVal} staked` : `Failed to stake`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }


    const consolelog = () => {
        // console.log('consolelog');
    }

    const processUnstakeInstantLy = async () => {
        if(selector.ethData.ethWalletConnected && parseFloat(withdrawAmount) > 0) {
            try {
                setInstantUnstakeLoading(true)
                setShowUnstakePopUp(false)
                // console.log('unstakeins', data)
                await handleWithdraw(data.poolId, withdrawAmount, selector.ethData.address, showNotification, data, maxWithdraw, true)
                setInstantUnstakeLoading(false)
                dispatch(updateLPData(selector.ethData.address))
                dispatch(fetchUserPortfolio(selector.ethData.address, true))
                setMaxWithdraw(false)
                setWithdrawAmount('')
                let d = Object.assign({}, data);
                d.value = data.walletBalance;
                d.usdValue = data.walletBalanceUSD;
                d.address = data.strategyAddress;
                d.icon = data.poolId == 0? gammaIcon: aquaIcon;
                d.name = data.poolId < 2? data.name.split(' ')[0]: data.name;
                setExpandedRowToken(d);
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setInstantUnstakeLoading(false)
                
            }
        }
    }

    const handleUnstakeInstantLy = async () => {
        if(setShowUnstakePopUp) {
            if(selector.ethData.ethWalletConnected && parseFloat(withdrawAmount) > 0) {
                let tokenName:any = data.poolId == 0? "GAMMA": "AQUA";
                let withdrawAmt:any =  parseFloat(withdrawAmount) ;
                let withdrawAmtUsd:any =  parseFloat(withdrawAmount) * (data.vaultBalanceUSD/data.vaultBalance);
                let withdrawFee:any = parseFloat(withdrawAmount) * data.instantWithdrawFee/100;
                let receiveAmt:any =parseFloat(withdrawAmount) - parseFloat(withdrawFee);
                let receiveAmtUsd:any = withdrawAmtUsd * (1 - (data.instantWithdrawFee/100));

                let d = {
                    tokenName,
                    withdrawAmt,
                    withdrawAmtUsd,
                    withdrawFee,
                    receiveAmt,
                    receiveAmtUsd,
                    instantWithdrawFeePercent:data.instantWithdrawFee
                }
                // console.log('d', d);
                setUnstakeData(d);
                setShowUnstakePopUp(true);
            }
        }
    }

    const handleWithdrawAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(withdrawAmount) > 0) {
            try {
                setWithdrawLoading(true)
                // console.log(data)
                await handleWithdraw(data.poolId, withdrawAmount, selector.ethData.address, showNotification, data, maxWithdraw, false)
                setWithdrawLoading(false)
                dispatch(updateUserBalance(selector.ethData.address))
                dispatch(fetchUserPortfolio(selector.ethData.address, true))
                setMaxWithdraw(false)
                setWithdrawAmount('')
                let d = Object.assign({}, data);
                d.value = data.walletBalance;
                d.usdValue = data.walletBalanceUSD;
                d.address = data.strategyAddress;
                d.icon = data.poolId == 0? gammaIcon: aquaIcon;
                d.name = data.poolId < 2? data.name.split(' ')[0]: data.name;
                setExpandedRowToken(d);
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setWithdrawLoading(false)
            }
        }
    }
    const handleClaimEarning = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                setClaimLoading(true)
                await harvestLpTokens(data.poolId, selector.ethData.address, showNotification, data)
                setClaimLoading(false)
                dispatch(updateUserBalance(selector.ethData.address))
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setClaimLoading(false)
            }
        }
    }

    const handleClaimBoostEarning = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                setClaimBoostLoading(true)
                await earnAquaProfits(data.poolId, selector.ethData.address, showNotification, data)
                setClaimBoostLoading(false)
                dispatch(updateUserBalance(selector.ethData.address))
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setClaimBoostLoading(false)
            }
        }
    }

    const handleMaxDeposit = (pool_data: any, selectedRowToken: any) => {
        // console.log("handle max deposit data", pool_data, selectedRowToken)
        let depositAmount: any;
        
        if(data.poolStatus == 'finished'){
            setDepositAmount(data.walletBalance);
            setDepositAmountWithComma(commaFy(parseFloat(data.walletBalance).toFixed(4)))
        }
        else {
            if(data.poolId < 2) {
  
                if(tokenSelectedRow.toLowerCase() == data.strategyAddress.toLowerCase() && selectedRowToken.address.toLowerCase() == data.wantAddress.toLowerCase()){   
                    depositAmount = convertToEtherRevised(data.infinity_token_wallet_bal_with_precision, 1e18);
                } else {
                    depositAmount = selectedRowToken.value;
                }
            } else {
                if(tokenSelectedRow.toLowerCase() == data.strategyAddress.toLowerCase() && selectedRowToken.address.toLowerCase() == data.wantAddress.toLowerCase()){
                    depositAmount = data.walletBalance;
                } else {
                    depositAmount = selectedRowToken.value;
                }
            }

            if (sendToken.sno === bnbSno && parseFloat(sendToken.value) !== 0) {
                depositAmount -= 0.02
            }


            setDepositAmount(depositAmount)
            setDepositAmountWithComma(commaFy(parseFloat(depositAmount).toFixed(4)))
        }

        if(data.poolStatus == 'active'){
            setMaxTokenAmt(depositAmount)
        } else {
            setMaxTokenAmt(data.walletBalance)
        }
        setMaxDeposit(true);
        // console.log("allowance Check", data)
        if(parseFloat(data.allowance) >= parseFloat(data.walletBalance)) {
            setIsAllowance(true);
        } else {
            setIsAllowance(false);
        }
    }


    const handleStopUnStake = async () => { 
        try {
            setStopUnstakeLoader(true);
            let res = await stopUnstakeProcessNew(selector.ethData.address, data.poolId);
            setStopUnstakeLoader(false);
            showNotification('success', res.transactionHash)
            dispatch(updateLPData(selector.ethData.address))
            dispatch(fetchUserPortfolio(selector.ethData.address, true))
            
        }
        catch(error) {
            setStopUnstakeLoader(false);
            console.log('instantunstake error', error)
            showNotification('failed') 
        } 
        
    }

    const getIsTimerOn = (endTime: any) => {
        let isTimerOn: boolean = false
        if (!!endTime) {
            let now = new Date()
            const difference: any = endTime.getTime() - now.getTime()
            if (parseFloat(difference) > 0) {
                isTimerOn = true
            }
        }
        return isTimerOn
    }

    const handleFinishUnStake = async () => {
        try {
            setFinishUnstakeLoader(true);
            let res = await unstake_after_min_time_new(selector.ethData.address, data.poolId);
            showNotification('success', res.transactionHash)
            setFinishUnstakeLoader(false);
            dispatch(updateLPData(selector.ethData.address))
            dispatch(fetchUserPortfolio(selector.ethData.address, true))
        } catch(error) {
            console.log('instantunstake error', error)
            setFinishUnstakeLoader(false);
            showNotification('failed') 
        } 
    }
    
    const renderTokenIcons = (tokenIcons: string, isBoostPool: boolean, protocol?: string) => {
        const tokenName = tokenIcons.split('-')
        if (tokenName.length === 1) {
            const icon = getTokenIcon(tokenIcons)
            return <img src={icon} className='poolsTxIcon' alt="" />
        }
        else if (tokenName.length === 2) {
            const icon1 = getTokenIcon(tokenName[0])
            let secondTokenName = tokenName[1].split(" ");
            const icon2 = getTokenIcon(secondTokenName[0])
            return (
                <TwoImgCont className='poolsTxIconCont'>
                    <img src={icon1} className='poolsTxIcon' alt="" />
                    <img className="poolsTxSecondIcon" src={icon2} alt="" />
                    {protocol && protocol.toLowerCase() == 'thena' && <img className='poolsTxThirdIcon' src={thenaIcon} alt="" />}
                    {protocol && protocol.toLowerCase() == 'biswap' && <img className='poolsTxThirdIcon' src={bswIcon} alt="" />}
                </TwoImgCont>
            )
        }
    }

    const checkWithdrawDisabled = () => {
        if(selector.ethData.address == undefined) {
            return true;
        }
        return (data.vaultBalanceUSD > 0 ? 
            (withdrawLoading? true: false) 
            : true) 
    }

    const checkConvertLPDisabled = () => {
        if(selector.ethData.address == undefined) {
            return true;
        }

        return (data.vaultBalanceUSD > 0 ? 
            (instantUnstakeLoading? true: false) 
            : true)
    }

    const checkStartUnstakeDisabled = () => {
        if(selector.ethData.address == undefined || data.poolId == 0) {
            return true;
        }
        
        return data.vaultBalanceUSD > 0 ? 
            (withdrawLoading? true: false) 
            : true
    }

    const checkIsAllowance = () => {
        if(Object.keys(expandedRowToken).length == 0) {
            return isAllowance;
        }
        else {
            if(Object.keys(expandedRowToken).length > 0  && expandedRowToken.address == data.strategyAddress) {
                if(data.poolId < 2) {
                    return (
                        //isAllowance || parseFloat(depositAmount) <= parseFloat(data.infinity_token_wallet_bal_with_precision)
                        isAllowance
                    ) 
                }
                else {
                    return (
                        //isAllowance && parseFloat(depositAmount) <= parseFloat(data.walletBalance) || parseFloat(depositAmount) == 0
                        isAllowance 
                    )
                }
            } 
            else {
                return true;
            }
        }
    }


    return (
        <PoolActionRow className="newPools">
            <ExpandedCell className="item1">
                <FlexSBCont>
                    <ExpendCellTitle light>
                        Amount
                    </ExpendCellTitle>
                    {portfolioLoading ? <LoadingText3 /> :
                    <>
                    {data.poolStatus == 'active' ?
                        <ExpendCellTitle light>
                            Balance: 
                            {data.pid > 1 ?` ${commaFy(parseFloat(data.walletBalance).toFixed(4))} ($${commaFy(parseFloat(data.walletBalanceUSD).toFixed(2))})` : ` ${commaFy(parseFloat(expandedRowToken.value).toFixed(4))} ($${commaFy(parseFloat(expandedRowToken.usdValue).toFixed(2))})`}
                        </ExpendCellTitle>
                        :
                        <ExpendCellTitle light>
                            Balance: 
                            {` ${commaFy(parseFloat(data.walletBalance).toFixed(4))} ($${commaFy(parseFloat(data.walletBalanceUSD).toFixed(2))})`}
                        </ExpendCellTitle>
                    }
                    </>
                    }
                </FlexSBCont>
                <TableInputCont>
                    <TableInput placeholder="0" onChange={handleDepositAmountChanges} value={depositAmountWithComma && depositAmountWithComma} autoFocus={true} disabled={!(Object.values(expandedRowToken).length > 0)} />
                    <FlexCont className='poolsTx'>
                    {data.walletBalanceUSD > 0.01 || expandedRowToken.usdValue > 0.01 ?
                        <MaxTxt className='poolsTx' onClick={() => handleMaxDeposit(data, expandedRowToken)}>Max</MaxTxt>
                    :
                        <MaxTxtZero className='poolsTx' onClick={() => handleMaxDeposit(data, expandedRowToken)}>Max</MaxTxtZero>
                    }
                    { data.poolStatus == "active" ?
                        <>   
                        {
                        data.pid == 0 || data.pid == 1 ?
                            <TokenSelectCont className='poolTxInputSelect' onClick={() => handleTokenModal('send', data.strategyAddress, data)}>
                                <img className="token-icon" src={expandedRowToken.icon} alt="" />
                                <TokenName className='poolsTx'>{expandedRowToken.name}</TokenName>
                                <img className="arrow-down" src={ArrowDown} alt="" />
                            </TokenSelectCont>
                            :
                            <TokenSelectCont className='poolTxInputSelect' onClick={() => handleTokenModal('send', data.strategyAddress, data)}>
                                <CellCont>
                                    {renderTokenIcons(expandedRowToken.name, false)}
                                </CellCont>
                                <TokenName className='poolsTx'>{expandedRowToken.name}</TokenName>
                                <img className="arrow-down" src={ArrowDown} alt="" />
                            </TokenSelectCont>
                        }
                        </>
                        :
                        ""
                    }    
                    </FlexCont>
                </TableInputCont>
                {data.poolStatus == 'active' ?
                    <>
                    {data.poolId < 2 ?
                        <CellBtnGroup>
                            {portfolioLoading ?
                                <TableBtn disabled={true} style={{ opacity: 1 }}>
                                    Stake
                                </TableBtn> 
                                :
                                <>
                                    {
                                        isAllowance ?
                                            <DepositBtn onClick={() => data.poolId == 0 ? "" : handleDepositAmount()} disabled={selector.ethData.address == undefined || depositLoading || parseFloat(depositAmount) > parseFloat(maxTokenAmt) || (expandedRowToken.address == undefined || expandedRowToken.address.toLowerCase() !== data.wantAddress.toLowerCase()) || data.poolId == 0}>
                                                {
                                                    depositLoading ? 
                                                    <Spinner /> : 'Stake'
                                                }
                                            </DepositBtn> :
                                            <TableBtn onClick={() => data.poolId == 0 ? "" : handleApprove()} disabled={approveLoading || selector.ethData.address == undefined || data.poolId == 0}>{approveLoading ? <Spinner /> : 'Approve'}</TableBtn>
                                    }
                                </>
                            }   
                            
                            <TransformBtn onClick={() => data.poolId == 0 ? "" : showSwapPopup(data, depositAmount)} disabled={selector.ethData.address == undefined || depositLoading || expandedRowToken.address == undefined || parseFloat(depositAmount) > parseFloat(maxTokenAmt) || (expandedRowToken.address.toLowerCase() == data.wantAddress.toLowerCase()) || data.poolId == 0}> 
                            Swap & Stake
                            </TransformBtn>
                            
                        </CellBtnGroup>
                        :
                        <CellBtnGroup>
                            {portfolioLoading ?
                                <TableBtn disabled={true} style={{ opacity: 1 }}>
                                    Stake
                                </TableBtn> 
                                :
                                <>
                                    {
                                        checkIsAllowance()?
                                            <DepositBtn onClick={() => handleDepositAmount()} disabled={selector.ethData.address == undefined || depositLoading || parseFloat(depositAmount) > parseFloat(maxTokenAmt) || expandedRowToken.address == undefined || expandedRowToken.address.toLowerCase() !== data.strategyAddress.toLowerCase()}>
                                                {
                                                    depositLoading ? 
                                                    <Spinner /> : 'Stake'
                                                }
                                            </DepositBtn> :
                                            <TableBtn onClick={() => handleApprove()} disabled={selector.ethData.address == undefined || approveLoading || expandedRowToken.address == undefined || expandedRowToken.address.toLowerCase() !== data.strategyAddress.toLowerCase()}>{approveLoading ? <Spinner /> : 'Approve'}</TableBtn>
                                    }
                                </>
                            }   
                            { data.poolStatus == "active" &&
                                <TransformBtn onClick={() => handleGetLpToken(data, 'transform', depositAmount)} disabled={selector.ethData.address == undefined || depositLoading || expandedRowToken.address == undefined || parseFloat(depositAmount) > parseFloat(maxTokenAmt) || (expandedRowToken.address.toLowerCase() == data.strategyAddress.toLowerCase())}> 
                                    {'Zap In & Stake'}
                                </TransformBtn>
                            }
                        </CellBtnGroup>
                    }
                    </>
                :
            
                    <TransformBtn onClick={() => handleGetLpToken(data, 'convertLp', depositAmount)} disabled={selector.ethData.address == undefined || depositLoading  || parseFloat(depositAmount) > parseFloat(maxTokenAmt) || parseFloat(depositAmount) == 0}> 
                        {
                            data.poolStatus == "finished" && data.protocol.toLowerCase() == 'thena' ? 
                            "Zap out" 
                            :
                            "- Liquidity"
                        }
                    </TransformBtn>
                }
            </ExpandedCell>
            <ExpandedCell className="item2">
                <FlexSBCont>
                    <ExpendCellTitle light>
                        Amount
                    </ExpendCellTitle>
                    {portfolioLoading ? 
                        <LoadingText3 /> :
                        <ExpendCellTitle light>
                            In Vault:
                            {` ${commaFy(parseFloat(data.vaultBalance).toFixed(4))}
                            ($${commaFy(parseFloat(data.vaultBalanceUSD).toFixed(2))})`}
                        </ExpendCellTitle>
                    }
                </FlexSBCont>
                <TableInputCont>
                    <TableInput placeholder="0" onChange={handleWithdrawAmountChanges} value={withdrawAmount && withdrawAmount} />
                    <FlexCont className='poolsTx'>
                        {data.vaultBalanceUSD > 0.01 ?
                            <MaxTxt className='poolsTx' onClick={() => handleMaxWithdrawAmount(data)}>Max</MaxTxt>
                        :
                            <MaxTxtZero className='poolsTx' onClick={() => handleMaxWithdrawAmount(data)}>Max</MaxTxtZero>
                        }
                        {data.poolStatus == 'active'?
                            <>
                            {
                                data.pid == 0 ?
                                <TokenSelectCont className='poolTxInputSelect'>
                                    <img className="token-icon" src={gammaIcon} alt="" />
                                    <TokenName className='poolsTx'>{"GAMMA"}</TokenName>
                                </TokenSelectCont>
                                : data.pid == 1 ?
                                <TokenSelectCont className='poolTxInputSelect'>
                                    <img className="token-icon" src={aquaIcon} alt="" />
                                    <TokenName className='poolsTx'>{"AQUA"}</TokenName>
                                </TokenSelectCont>
                                :
                                <TokenSelectCont className='poolTxInputSelect' >
                                    <CellCont>
                                        {renderTokenIcons(data.name, false)}
                                    </CellCont>
                                    <TokenName className='poolsTx'>{data.name}</TokenName>
                                    {/* <img className="arrow-down" src={ArrowDown} alt="" /> */}
                                </TokenSelectCont>
                            }
                            </>
                            :
                            ""
                        }
                    </FlexCont>
                </TableInputCont>
                {data.poolStatus == "active" ?
                    <CellBtnGroup>
                        <>
                            {
                                (data.poolId == 0 || data.poolId == 1) ? 

                                
                                    !checkZeroVal(data.unstaking_bal) ? 
                                    <>
                                        <CellBtnGroup className="timerGroup">
                                            {
                                                getIsTimerOn(countDownDate)  ?
                                                <>
                                                    <TimerBtn>
                                                        <UnStakingTimeAqua compareDate={countDownDate} setAquaCountDownDate={setCountDownDate} />
                                                    </TimerBtn>
                                                

                                                    <CancelStakeBtn onClick={() => handleStopUnStake()}>
                                                    {
                                                        stopUnstakeLoader ? 
                                                            <Spinner/> : 
                                                            <p>
                                                            +
                                                            </p>
                                                    }
                                                    </CancelStakeBtn>
                                                </>
                                                
                                                : 
                                                <>
                                                    <WithdrawBtn onClick={() => handleFinishUnStake()}>
                                                        {finishUnstakeLoader ? <Spinner/>: 'Withdraw Tokens'}
                                                    </WithdrawBtn>
                                                    <CancelStakeBtn onClick={() => handleStopUnStake()}>
                                                    {
                                                        stopUnstakeLoader ? 
                                                            <Spinner/> : 
                                                            <p>
                                                            +
                                                            </p>
                                                    }
                                                    </CancelStakeBtn>
                                                </>
                                            }
                                            
                                            
                                        </CellBtnGroup>
                                        
                                    </> 
                                    : 

                                    <StartUnstakeLpBtn onClick={() => data.poolId == 0 ? "" : handleWithdrawAmount()} disabled={checkStartUnstakeDisabled()}>
                                        {withdrawLoading ? 
                                        <Spinner /> : 'Start Un-Stake Process'
                                        }
                                    </StartUnstakeLpBtn>
                                :
                                <WithdrawBtn 
                                    onClick={() => data.poolId == 0 ? "" :handleWithdrawAmount()} 
                                    disabled={checkWithdrawDisabled()}
                                >
                                    {withdrawLoading ? 
                                        <Spinner /> : 'Withdraw'
                                    }
                                </WithdrawBtn>
                            }
                        </>
                        <ConvertLpBtn 
                            onClick={
                                () => {
                                    if(data.poolId ==0 || data.poolId == 1) {
                                        handleUnstakeInstantLy()
                                    }
                                    else {
                                        handleGetLpToken(data, "convertLp", withdrawAmount)
                                    }
                                }
                            } 
                            
                            disabled={checkConvertLPDisabled()}
                        >
                            {
                                instantUnstakeLoading? 
                                    <Spinner />
                                :
                                data.poolId < 2 ? 
                                'Un-Stake Instantly' : 
                                'Withdraw & Zap Out'
                            }
                        </ConvertLpBtn>
                    </CellBtnGroup>
                :
                    <WithdrawBtn 
                        onClick={() => handleWithdrawAmount()} 
                        disabled={checkWithdrawDisabled()}
                    >
                        {withdrawLoading ? <Spinner /> : 'Withdraw'}
                    </WithdrawBtn>
                }
            </ExpandedCell>
        </PoolActionRow>
    )
}
export default PoolTransaction
