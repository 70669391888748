import { gammatrollerAddress, deprecatedMarkets, discountLevelAddress, gammaAbi, gBnbAbi, instType, intRateModelAbi, selectInstance, gGamma} from './lendingAbi'
import { zeroAddress } from './SwapDexAbi'
import wallet from 'modules/wallet/wallet'
import {multicall_abi, multicall_address } from './abi'
import {convertToEther, gConvertToEther} from './BlockChainMethods'

/************************************************Read Only Functions************************************************************/

export const _borrowGuardianPaused = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods._borrowGuardianPaused().call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const _mintGuardianPaused = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods._mintGuardianPaused().call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const accountAssets = async (address: string, amount: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.accountAssets(address, amount).call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const admin = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.admin().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const allMarkets = async (amount: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.allMarkets(amount).call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}
export const borrowCapGuardian = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.borrowCapGuardian().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const borrowCaps = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.borrowCaps(address).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}
export const borrowGuardianPaused = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.borrowGuardianPaused(address).call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const checkMembership = async (account: string, gToken: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.checkMembership(account, gToken).call()
        return data
    } catch (e) {
        console.log(e)
        return false

    }
}

export const closeFactorMantissa = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.closeFactorMantissa().call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaAccrued = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaAccrued(address).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaBorrowState = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaBorrowState(address).call()
        return data
    } catch (e) {
        console.log(e)
        return {
            index: 0,
            block: 0
        }
    }
}

export const gammaBorrowerIndex = async (add1: string, add2: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaBorrowerIndex(add1, add2).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaContributorSpeeds = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaContributorSpeeds(address).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaInitialIndex = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaInitialIndex().call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaRate = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaRate().call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaSpeeds = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaSpeeds(address).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}
export const gammaSupplierIndex = async (add1: string, add2: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaSupplierIndex(add1, add2).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const gammaSupplyState = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammaSupplyState(address).call()
        return data
    } catch (e) {
        console.log(e)
        return {
            index: 0,
            block: 0
        }
    }
}
export const gammatrollerImplementation = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.gammatrollerImplementation().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const getAccountLiquidity = async (account: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.getAccountLiquidity(account).call()
        return data
    } catch (e) {
        console.log(e)
        return {
            0: 0,
            1: 0,
            2: 0
        }

    }
}

export const deprecatedMarket = async (gTokenAddress:string) => {
    try {
        const inst: any = await selectInstance(instType.DL , discountLevelAddress)
        const data = await inst.methods.deprecatedMarket(gTokenAddress).call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const getAllMarkets = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.getAllMarkets().call()

        let res = [];
        for(let i = 0 ; i < data.length ; i++){
            if(!deprecatedMarkets.includes(data[i])){
                res.push(data[i])
            }
        }

        return res
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const getAssetsIn = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.getAssetsIn(address).call()

        let res = [];
        for(let i = 0 ; i < data.length ; i++){
            const check = await isDeprecated(data[i],gammatrollerAddress)
            if(!check){
                res.push(data[i])
            }
        }
        return res
    } catch (e) {
        console.log(e)
        return []
    }
}

export const getBlockNumber = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.getBlockNumber().call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}
export const getCompAddress = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.getCompAddress().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const getHypotheticalAccountLiquidity = async (account: string, gTokenModify: string, redeemTokens: string, borrowAmount: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.getHypotheticalAccountLiquidity(account, gTokenModify, redeemTokens, borrowAmount).call()
        return data
    } catch (e) {
        console.log(e)
        return {
            0: 0,
            1: 0,
            2: 0
        }
    }
}

export const isGammatroller = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.isGammatroller().call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}
export const isDeprecated = async (gToken: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.isDeprecated(gToken).call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const lastContributorBlock = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.lastContributorBlock(address).call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const liquidateCalculateSeizeTokens = async (gTokenBorrowed: string, gTokenCollateral: string, actualRepayAmount: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.liquidateCalculateSeizeTokens(gTokenBorrowed, gTokenCollateral, actualRepayAmount).call()
        return data
    } catch (e) {
        console.log(e)
        return {
            0: 0,
            1: 0
        }
    }
}

export const liquidationIncentiveMantissa = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.liquidationIncentiveMantissa().call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const markets = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.markets(address).call()
        return data
    } catch (e) {
        console.log(e)
        return {
            isListed: false,
            collateralFactorMantissa: 0,
            isComped: false
        }
    }
}

export const maxAssets = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.maxAssets().call()
        return data
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const mintGuardianPaused = async (address: string, gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.mintGuardianPaused(address).call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const oracle = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.oracle().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const pauseGuardian = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.pauseGuardian().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}
export const pendingAdmin = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.pendingAdmin().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const pendingGammatrollerImplementation = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.pendingGammatrollerImplementation().call()
        return data
    } catch (e) {
        console.log(e)
        return zeroAddress
    }
}

export const seizeGuardianPaused = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.seizeGuardianPaused().call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}

export const transferGuardianPaused = async (gammatrollerAdd: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAdd)
        const data = await inst.methods.transferGuardianPaused().call()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}



/***************************************************************************************************************/

export const enterMarkets = async (gTokenAddress: string, userAddress: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAddress)
        const data = await inst.methods.enterMarkets([gTokenAddress]).send({
            from: userAddress,
        })
        return data;
    } catch (e) {
        console.log(e)
        return {};
    }
}

export const exitMarkets = async (gTokenAddress: string, userAddress: string) => {
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAddress)
        const data = await inst.methods.exitMarket(gTokenAddress).send({
            from: userAddress,
        })
        return data;
    } catch (e) {
        console.log(e)
        return {};
    }
}


const getAllBorrowBalanceMulticall = async (userAddress: any, marketArray?: any) => {
    let multicallArr: any = []
    const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
    
    // console.log("market_data list in getAllSupplyBalanceMulticall",markets_data);
    if (marketArray.length === 0) {
      return 0
    }
    //0x0c6dd143F4b86567d6c21E8ccfD0300f00896442
  
    let targets: any = []
    let callDatas: any = []
    let results: any = []
    let ouput_format: any = []
    const asset_inst = await selectInstance(instType.gToken, gGamma)
    let snapShotArray: any = [];
    try {
      for (let i = 0; i < marketArray.length; i++) {
        targets.push(marketArray[i])
        const data = wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.getAccountSnapshot(userAddress)._method, [userAddress])
        callDatas.push(data)
        ouput_format.push(asset_inst.methods.getAccountSnapshot(userAddress)._method.outputs)
      }
      const agregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()
  
      const do_split = async (array: any, n: any): Promise<any> => {
        return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
      }
  
      for (let i = 0; i < agregated_data[1].length; i++) {
        results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], agregated_data[1][i]))
      }
      const split_arr = await do_split(results, marketArray.length)
      if (split_arr.length > 0 && split_arr[0].length > 0) {
        // console.log("split_arr", split_arr[0])
        for (let i = 0; i < marketArray.length; i++) {
            let marketAddress = marketArray[i].toLowerCase();
            snapShotArray[marketAddress] = split_arr[0][i][2]
          
        }
      }
    //   console.log("supplyBalanceArr", snapShotArray)
      return snapShotArray;
    } catch (error) {
      console.log(error)
    }
  }

export const claimGamma = async (userAddress: string,userAssetData:any) => {
    // console.log("claimGamma user details", userAddress, userAssetData)
    // return;
    try {
        const inst: any = await selectInstance(instType.gammatroller, gammatrollerAddress)

        let holders: any = userAddress;
        let supplyGtokens: any = [];
        let borrowGtokens: any = [];
        let suppliers = false;
        let borrowers = false;

        const assetsIn = await getAssetsIn(userAddress,gammatrollerAddress)
        // console.log("assetsIn", assetsIn);

        if(userAssetData.length > 0){

            suppliers = true;
            
            userAssetData.forEach((element: any) => {
                if(element.toLowerCase() !== "0xb7eD4A5AF620B52022fb26035C565277035d4FD7".toLowerCase() && element.toLowerCase() !== "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442".toLowerCase()){
                    supplyGtokens.push(element.toLowerCase());
                }
            });

        }
        let borrowedArray = await getAllBorrowBalanceMulticall(userAddress, assetsIn)
        if(assetsIn.length > 0){

            borrowers = true;
            
            assetsIn.forEach((element: any) => {
                if(element.toLowerCase() !== "0xb7eD4A5AF620B52022fb26035C565277035d4FD7".toLowerCase() && element.toLowerCase() !== "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442".toLowerCase() && borrowedArray[element.toLowerCase()] > 0){
                    borrowGtokens.push(element.toLowerCase());
                }
            });

        }

        // console.log("claimGamma user details after", holders,gTokens,borrowers,suppliers)
        // return;
        let data: any;
        if(supplyGtokens.length > 0) {
            data = await inst.methods.claimGamma(holders, supplyGtokens, borrowGtokens, borrowers, suppliers).send({
                from: userAddress,
            })
        } else {
            data = await inst.methods.claimGamma(userAddress).send({
                from: userAddress,
            })
        }
        
        return data;
    } catch (e) {
        console.log(e)
        return {};
    }
}

/***************************************************************************************************************/
