import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserLendingData } from 'logic/action/lending.action'
import { updateUserBalance, silentUpdateOnInfStake } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont } from 'shared/styles/styled'
import { HAModalCont, HAMDataCont, HAModalTitle, TokenInfoBox, HATokenIcon, TokenValTxt, ModalAPYTxt, TvlTxt, SwitchCont, DepositSwitch, WithdrawSwitch, ModalCard, ModalCardTitle, DataInfoCard, InfoHeaderCont, DataInfoTitle, DataInfoCont, DataInfoTxt, LoadingText3, LoadingText4 } from 'shared/styles/highestApyStyle'
import { ModalInputCont, ModalInput, MaxBox, MaxBoxZero, UserBalTxt } from 'shared/styles/highestApyStyle'
import { CardBtnGroup, CardBtn, TransformBtn, CardRedBtn, CardGreenBtn } from 'shared/styles/highestApyStyle'
import CloseIcon from 'assets/icons/white-cross.svg'
import DownIcon from 'assets/icons/down.svg'
import { getTokenIcon } from 'shared/tokenIconList'
import { floatNumRegex, } from 'shared/helpers/regexConstants'
import { getTokenName, commaFy, convertToKAndM, checkZeroVal, getNumFormat } from 'service/globalFunctions'
import { transLinkUrl, fraxTranLink, maticTransLink, dotTransLink, solTransLink, adaTransLink, lunaTransLink, austTransLink, avaxTransLink, atomTransLink, xrpTransLink, linkTransUrl, ftmTransLink, whustTransferLink } from 'service/global-constant'
import Spinner from 'shared/spinner'
import { isAllowanceApproved, boostCalculatorForMarkets } from 'modules/block-chain/BlockChainMethods'
import { bluePfTokenListWithoutAquaGamma } from 'modules/block-chain/pfTokenList'
import { gBnbAddress, convertToWei, instType, selectInstance, MAX_INT } from 'modules/block-chain/lendingAbi'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { getUnderlyingDecimal } from 'modules/block-chain/LendingBase'
import { anchor_apy } from 'modules/block-chain-green/lendingAbi'
import { TokenValInput, TokenSelectCont, FlexCont, TokenName, SelectCurrTxt, PercentageBox, PercentageBtnCont} from './styles'
import { swapTokenList, tokenListWithoutAquaGamma } from 'modules/block-chain/tokenList'
import ArrowDown from 'assets/icons/arrow-down.png'
import gammaIcon from 'assets/icons/gamma-icon.png'
import EqualsIcon from 'assets/icons/equal-icon.png'
import CustomModal from 'shared/custom-modal'
import TokenSelect from 'shared/token-select'

const BoostCalculationModal = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [depositAmount, setDepositAmount] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [depositLoading, setDepositLoading] = useState(false)
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [isAllowance, setIsAllowance] = useState(false)
    const [showVaultInfo, setShowVaultInfo] = useState(false)
    const [showFeeInfo, setShowFeeInfo] = useState(false)
    const [showAPYCal, setShowAPyCal] = useState(false)
    const [depositToken, setDepositToken] = useState({})
    const [depositTokenValue, setDepositTokenValue] = useState(0)
    const [depositTokenValueWithComma, setDepositTokenValueWithComma] = useState('')
    const [depositGamma, setDepositGamma] = useState(0)
    const [depositGammaWithComma, setDepositGammaWithComma] = useState('')
    const [showTokenModal, setShowTokenModal] = useState(false)
    const [currTokenList, setCurrTokenList] = useState<any>(tokenListWithoutAquaGamma)
    const [sendToken, setSendToken] = useState<any>(tokenListWithoutAquaGamma[0])
    const [existTokenAdd, setExistTokeAdd] = useState('')
    const [boostPercentForToken, setBoostPrecentForToken] = useState(0.00)
    const [boostValForToken, setBoostValForToken] = useState(0.00)
    const { portfolioLoading, poolData, infVaultLoadingNew, gammaInfDataNew } = selector.user
    const { toggleVaultModal, data, setModalData, currentTab } = props

    useEffect(() => {
        const dataArr = poolData.newVaults;
        if (!!dataArr && Object.values(dataArr).length > 0) {
            let newApyArr: any = []
            if (currentTab === 'finished') {
                if (data.isBoostPool) {
                    newApyArr = dataArr.finished.filter((e: any) => e.wantAddress.toLowerCase() === data.wantAddress.toLowerCase())
                }
                else {
                    newApyArr = dataArr.finished.filter((e: any) => e.address.toLowerCase() === data.address.toLowerCase())
                }
            }
            else {
                if (data.isBoostPool) {
                    newApyArr = dataArr.active.filter((e: any) => e.strategyAddress.toLowerCase() === data.strategyAddress.toLowerCase())
                }
                else {
                    newApyArr = dataArr.active.filter((e: any) => e.address.toLowerCase() === data.address.toLowerCase())
                }
            }
            if(newApyArr[0] !== undefined) {
                // console.log("inside if")
                setModalData(newApyArr[0])
            } else {
                // console.log("inside else")
                setModalData(data)
            }
            
            // console.log("vault details modal data", data, newApyArr, dataArr)
        }
        
    }, [poolData, data, setModalData, currentTab])


    useEffect(() => {
        const userAddress = selector.ethData.address
        const getIsAllowance = async () => {
            
            if (data.name === 'BNB' || data.name === 'WBNB') {
                setIsAllowance(true)
            }
            else {
                const wantAddress = data.address? data.address :  data.wantAddress;

                // console.log(wantAddress)
                if(typeof wantAddress == undefined)
                {
                    setIsAllowance(false)
                    return 1;
                }
                let farmAddress = ""

                try {
                    const isAllow = await isAllowanceApproved(userAddress, farmAddress, wantAddress)
                    setIsAllowance(isAllow)
                }
                catch (error) {
                    console.log("error=>", error)
                }
            }
        }
        if (!!data && !data.isBoostPool) {
            getIsAllowance()
        }
        // console.log("inside 2nd useeffectvault details modal", data)
    }, [selector.ethData.address, data])

    useEffect(() => {
        if(+depositTokenValue > 0 && +depositGamma > 0){
            boostCalulatorForTokens()
        }

    }, [depositTokenValue, depositGamma])

    const boostCalulatorForTokens = async() => {
        const new_markets_without_gammaAqua: any = Object.values(bluePfTokenListWithoutAquaGamma);
        let boostPercentage = await boostCalculatorForMarkets(selector.ethData.address)
        console.log("boostPercentage value",boostPercentage);
        let selectedTokenDetails = boostPercentage[sendToken.gTokenAddress.toLowerCase()];
        console.log("selected token details", selectedTokenDetails)
        let blocksPerYear = 10512000;
        let  gammaBoostPerYear = selectedTokenDetails.gammaSpeeds * blocksPerYear * selectedTokenDetails.gammaBoostPercentage;
        let entranceFeeFactor = 0; //fetch from contract later 
        let iTokenExchangeRate = selectedTokenDetails.iTokenExchangeRate;
        let gTokenExchangeRate = selectedTokenDetails.gTokenExchangeRate;
        let gammaGTokenExchangeRate = selectedTokenDetails.gammaGTokenExchangeRate;
        let depositGammaVal = depositGamma/selectedTokenDetails.gammaPrice * 1e18;
        let iGammaAdded = (depositGammaVal - (entranceFeeFactor * depositGammaVal)/1000) * 1e36 / (iTokenExchangeRate * gammaGTokenExchangeRate) ;
        console.log("deposit gamma", depositGammaVal, "iTokenExchangeRate", iTokenExchangeRate, "gGammaTokenExchangeRate", gammaGTokenExchangeRate, gTokenExchangeRate)
        let iGammaBalance = selector.ethData.address ? gammaInfDataNew.iTokenBalance : 0;//from contract
        let iGammaToBeUnstaked = selector.ethData.address ? gammaInfDataNew.unstaking_bal * iTokenExchangeRate : 0;  // from contract
        let netIGamma = iGammaBalance - iGammaToBeUnstaked + iGammaAdded;
        let depositTokens = depositTokenValue/selectedTokenDetails.tokenPrice * 1e18;
        let gTokensAdded = (1e18 * depositTokens)/gTokenExchangeRate;
        let existingTokens = selector.ethData.address ?  selectedTokenDetails.accountTokens : 0;
        let netGtokens = gTokensAdded + existingTokens; //+selectedTokenDetails.accountTokens + gTokensAdded;
        let newUserFactor = Math.sqrt(netGtokens * netIGamma);
        let newTotalFactor = +selectedTokenDetails.totalFactor + +newUserFactor;
        console.log("boost calc values", "iGammaAdded", iGammaAdded, "netIGamma", netIGamma, "depositTokens", depositTokens, "gTokensAdded", gTokensAdded, "netGtokens", netGtokens, "newUserFactor", newUserFactor, "newTotalFactor", newTotalFactor)
        let boostApr = (gammaBoostPerYear * 1e36 *(newUserFactor/newTotalFactor))/(netGtokens*gTokenExchangeRate);
        boostApr = boostApr * 100 * selectedTokenDetails.gammaPrice / selectedTokenDetails.tokenPrice;
        let boostApy = (Math.pow((1 + (+boostApr)/36500), 365) - 1) * 100;
        console.log("boost apr", boostApr, "boost Apy", boostApy, gammaBoostPerYear);
        let roiOnBoost = +depositTokenValue * (1 + +boostApr/100)
        console.log("roi on boost", roiOnBoost)
        setBoostPrecentForToken(+boostApy.toFixed(2))
        setBoostValForToken(+roiOnBoost.toFixed(2))
        // let newFactor = 
        /*
        let gammaBoostPerYear = (gammaSpeedsArr[i][0]/1e18 * blocksPerYear * gammaBoostPercentageArr[i][0])/10000;
        let userBoostAPR = gammaBoostPerYear*1e36*(userFactor/totalFactorArr[i][0])/(accountTokens*exchangeRateArr[i][0]);
        userBoostAPR = ( 100 * userBoostAPR * +priceArray[gGamma.toLowerCase()])/(+priceArray[gToken]);
        let userBoostApy = (Math.pow((1 + (+userBoostAPR)/36500), 365) - 1) * 100;
        */
    }

    const handleDepositAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setDepositAmount(value)
        }
        if (!value) {
            setDepositAmount('')
        }
    }
    const handleTokenValue: any = (e: any) => {
        let { value } = e.target
        if(+value === 0 ){
            value = value;
        } else {
            value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.split(",").join("") : "";
        }
        if (floatNumRegex.test(value.toString())) {
            setDepositTokenValue(value)
            setDepositTokenValueWithComma(commaFy(value))
        }
        if (!value) {
            setDepositTokenValue(0)
            setDepositTokenValueWithComma('')
        }
    }

    const handleGammaDeposit: any = (e: any) => {
        let { value } = e.target
        if(+value === 0 ){
            value = value;
        } else {
            value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.split(",").join("") : "";
        }
        if (floatNumRegex.test(value.toString())) {
            setDepositGamma(value)
            setDepositGammaWithComma(commaFy(value))
        }
        if (!value) {
            setDepositGamma(0)
            setDepositGammaWithComma('')
        }
    }

    const handleTokenModal = (type: string) => {
        // setTokenType(type)
        // getTokenList(type)
        // getExistTokenAdd(type)
        setShowTokenModal(true)
        // setSendAmountCheck(0)
    }

    const handleToggleModal = () => {
        // setTokenType('')
        setShowTokenModal(false)
    }

    
    const handleTokenSelect = (selectedTokenData: any) => {
        console.log("selected token", selectedTokenData)
        setSendToken(selectedTokenData)
        handleToggleModal()
    // // console.log(selectedTokenData, existTokenAdd)
    // if (selectedTokenData.address === existTokenAdd) {
    //   setSendToken(receiveToken)
    //   setReceiveToken(sendToken)
    //   getSwapConstantData(receiveToken, sendToken)
    // }
    // else {
    //   if (tokenType === 'send') {
    //     setSendToken(selectedTokenData)
    //     if (Object.values(receiveToken).length > 0) {
    //       getSwapConstantData(selectedTokenData, receiveToken)
    //     } else {
    //       // console.log("set swap const data inside handle token select else 1")
    //       setSwapConsData({})
    //     }
    //   }
    //   if (tokenType === 'receive') {
    //     setReceiveToken(selectedTokenData)
    //     if (Object.values(sendToken).length > 0) {
    //       getSwapConstantData(sendToken, selectedTokenData)
    //     } else {
    //       // console.log("set swap const data inside handle token select else 2")
    //       setSwapConsData({})
    //     }
    //   }
    //   setIntermediateTokenArray([])
    // }
    
    // setExistTokeAdd('')
    // setSendAmount('')
    // setSendAmountWithoutComma(0)
    // setReceiveAmountWithoutComma(0)
    // setReceiveAmount('')
    // handleToggleModal()  
    // handleMinReceive(0)  
    // console.log("handle token select function", sendAmountWithoutComma)
  }

  const updateGammaVal = async(gamma: any) => {
    setDepositGamma(gamma);
    setDepositGammaWithComma(commaFy(gamma))
  }
    

    return (
        <HAModalCont className='boostCalCont'>
            <FlexSBCont className='boostHeading'>
                <HAModalTitle>Boost Calculator</HAModalTitle>
                <img className='close-icon' src={CloseIcon} alt="" onClick={() => toggleVaultModal()} />
            </FlexSBCont>
            <HAMDataCont className='boostCalDataCont'>
                <DataInfoTxt className='boostInputTitle'>
                    Deposit Amount
                </DataInfoTxt>
                <FlexSBCont className="tokenInputCont">
                    <TokenValInput
                        disabled={false}
                        placeholder="$0"
                        onChange={handleTokenValue}
                        value={depositTokenValueWithComma && depositTokenValueWithComma}
                        autoFocus={true}
                    />
                    <FlexCont>
                    
                    <TokenSelectCont onClick={() => handleTokenModal('send')}>
                        {Object.values(sendToken).length > 0 ? (
                        <>
                            <img className="boost-token-icon" src={sendToken.icon} alt="" />
                            <TokenName>{sendToken.name}</TokenName>
                        </>
                        ) : (
                        <SelectCurrTxt>Select Currency</SelectCurrTxt>
                        )}
                        <img className="arrow-down" src={ArrowDown} alt="" />
                    </TokenSelectCont>
                    </FlexCont>
                </FlexSBCont>
                <DataInfoTxt className='gammaText'>
                    Gamma staked
                </DataInfoTxt>
                <FlexSBCont className="tokenInputCont">
                    <TokenValInput
                        disabled={false}
                        placeholder="$0"
                        onChange={handleGammaDeposit}
                        value={depositGammaWithComma && depositGammaWithComma}
                        
                    />
                    <TokenSelectCont>
                        <>
                            <img className="boost-token-icon" src={gammaIcon} alt="" />
                            <TokenName>GAMMA</TokenName>
                        </>
                        
                    </TokenSelectCont>
                </FlexSBCont>
                <PercentageBtnCont className='gammaStaked'>
                    <PercentageBox onClick={() => updateGammaVal(100)}><p>$100</p></PercentageBox>
                    <PercentageBox onClick={() => updateGammaVal(1000)}><p>$1k</p></PercentageBox>
                    <PercentageBox onClick={() => updateGammaVal(5000)}><p>$5k</p></PercentageBox>
                    <PercentageBox onClick={() => updateGammaVal(10000)}><p>$10k</p></PercentageBox>
                </PercentageBtnCont>
                <FlexSBCont className="equalsIcon">
                    <img className="boost-token-icon" src={EqualsIcon} alt="" />
                </FlexSBCont>
                <FlexSBCont className="boostOutput">
                    <DataInfoTxt className='roiText'>
                        Annual ROI (Based on current rates)
                    </DataInfoTxt>
                    <DataInfoTxt className='roiValue'>
                        ${boostValForToken}
                    </DataInfoTxt>
                    <DataInfoTxt className='boostApy'>
                        {boostPercentForToken}% APY 
                    </DataInfoTxt>
                    
                </FlexSBCont>
            </HAMDataCont>
            <CustomModal show={showTokenModal} toggleModal={handleToggleModal}>
                {showTokenModal && (
                    <TokenSelect
                        selectedAdd={sendToken.address}
                        setShowTokenModal={setShowTokenModal}
                        tokenListVal={currTokenList}
                        handleTokenSelect={handleTokenSelect}
                        existTokenAdd={existTokenAdd}
                        crossIconColor={'white'}
                    />
                )}
            </CustomModal>
        </HAModalCont>
        
    );
};

export default BoostCalculationModal