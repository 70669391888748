export const apiBaseUrl = 'https://aquaform.dev'
export const planetFinanceApiBaseUrl = "https://api.planet.finance/"
export const newPFApiBaseUrl = "https://pfapi.planet.finance/"
export const aquaVotingLink = "https://vote.planet.finance/"
export const aquaContractLink = 'https://bscscan.com/token/0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991'
export const buyAquaLink = 'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991'
export const githubLink = 'https://github.com/planetfinance?tab=repositories'
export const auditLink = "https://github.com/HalbornSecurity/PublicReports/blob/master/Solidity%20Smart%20Contract%20Audits/Planet_Finance_Green_Smart_Contract_Security_Audit_Report_Halborn_Final.pdf"
export const docsLink = 'https://docs.planet.finance'
export const blogLink = 'https://medium.com/@planetfinanceio'
export const faqLink = "https://planetfinance.io/faq/"
export const MediumLink = 'https://planetfi.medium.com/'
export const telegramLink = 'https://t.me/planet_finance'
export const discordLink = 'https://discord.gg/wkbDJrsAbX'
export const twitterLink = 'https://twitter.com/planet_finance'
export const instLink = "https://www.instagram.com/planetfinance.io"
export const aquaEmailLink = "https://docs.planet.finance/partnerships/how-to-build-with-us"
export const tokenomicsLink = "https://docs.planet.finance/tokenomics/key-details"
export const aquaLink = "https://docs.planet.finance/tokenomics/aqua"
export const gammaLink = "https://docs.planet.finance/tokenomics/gamma"
export const burnAquaLink = "https://bscscan.com/token/0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991?a=0x000000000000000000000000000000000000dEaD"
export const WEB3_CONNECT_PROVIDER = 'WEB3_CONNECT_PROVIDER'
export const redPlanetLink = 'https://red.planetfinance.io/'
export const uranusPlanetLink = 'https://uranus.planetfinance.io/'
export const pinkPlanetLink = ''
export const greenPlanetLink = 'https://green.planetfinance.io/'
export const transLinkUrl = 'https://bscscan.com/tx'
export const transAddLinkUrl = 'https://bscscan.com/address/'
export const marketDataApi = planetFinanceApiBaseUrl+'v1/markets/getmarketstats'
export const claimGamaUrl = planetFinanceApiBaseUrl+'airdrop/'
export const tvlUrl = planetFinanceApiBaseUrl+'v1/tvl'
export const dexGuruLink = "https://dex.guru/token/0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991-bsc"
export const analyticsLink = 'https://analytics.planet.finance/'
export const treasuryApy = planetFinanceApiBaseUrl+'v1/markets/gettreasury'
export const marketLink = 'https://green.planetfinance.io/markets'
export const metricsLink = 'https://green.planetfinance.io/metrics'
export const aquaChainLink = 'https://bscscan.com/address/0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991'
export const gammaChainLink = 'https://bscscan.com/address/0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15'

/* ======================================== Token transform Link =========================================== */

export const fraxTranLink = 'https://app.frax.finance/amm'
export const maticTransLink = 'https://pancakeswap.finance/swap?outputCurrency=0xcc42724c6683b7e57334c4e856f4c9965ed682bd'
export const dotTransLink = 'https://pancakeswap.finance/swap?outputCurrency=0x7083609fce4d1d8dc0c979aab8c869ea2c873402'
export const solTransLink = 'https://pancakeswap.finance/swap?outputCurrency=0x570a5d26f7765ecb712c0924e4de545b89fd43df'
export const adaTransLink = 'https://pancakeswap.finance/swap?outputCurrency=0x3ee2200efb3400fabb9aacf31297cbdd1d435d47'
export const lunaTransLink = 'https://portalbridge.com/#/transfer'
export const dogeBnbTransLink = 'https://pancakeswap.finance/add/BNB/0xbA2aE424d960c26247Dd6c32edC70B295c744C43'
export const austTransLink = 'https://portalbridge.com/#/transfer'
export const avaxTransLink = 'https://exchange.biswap.org/#/swap?outputCurrency=0x1ce0c2827e2ef14d5c4f29a091d735a204794041'
export const atomTransLink = 'https://pancakeswap.finance/swap?outputCurrency=0x0Eb3a705fc54725037CC9e008bDede697f62F335'
export const xrpTransLink = 'https://pancakeswap.finance/swap?outputCurrency=0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE'
export const linkTransUrl = 'https://pancakeswap.finance/swap?outputCurrency=0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd'
export const ftmTransLink = 'https://pancakeswap.finance/swap?outputCurrency=0xAD29AbB318791D579433D831ed122aFeAf29dcfe'
export const whustTransferLink = 'https://portalbridge.com/#/transfer'