import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchInfVaultData, updateUserBalance } from 'logic/action/user.actions'
import { updateUserLendingData } from 'logic/action/lending.action'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont, FlexCont, PlanetBtn, MaxBtn, MaxStakeBtn, FlexContStake } from 'shared/styles/styled';
import { ModalCont, ModalCard, ModalTitle, ModalInputCont, ModalInput, ExchangeRateBox, ModalText, ModalBText, ModalInfoText, CrossButton } from './style'
import { colors } from 'shared/styles/theme';
import CloseIcon from 'assets/icons/white-cross.svg'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { commaFy, getNumFormat } from 'service/globalFunctions'
import { transLinkUrl } from 'service/global-constant'
import Spinner from 'shared/spinner'
import { deposit_in_infinity_vault } from 'modules/block-chain/BlockChainMethods'
import { instType, selectInstance, MAX_INT } from 'modules/block-chain/lendingAbi'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import VaultDetailsModal from './vault-modal/VaultDetailsModal'
import { HAListCont, HATokenWrapper, HATokenCont, HATokenBox, HATokenTxt, HApyTxt } from 'shared/styles/highestApyStyle'

const Stake = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [sendAmount, setSendAmount] = useState('')
    const [loading, setLoading] = useState(false)
    const [approveLoading, setApproveLoading] = useState(false)
    const [isAllowance, setIsAllowance] = useState(false)
    const [showVaultDetails, setShowVaultDetails] = useState(false)
    const [modalData, setModalData] = useState<any>({})
    const { setShowStake, token, data } = props
    const { poolLoading, poolData } = selector.user

    useEffect(() => {
        const getIsAllowance = async () => {
            setIsAllowance(data.isApproved)
        }
        if (!!data) {
            getIsAllowance()
        }
        // console.log("data inside stake", data)
    }, [data])

    const handleSendAmount = async (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            // console.log("send amount val", value)
            setSendAmount(value)
        }
        if (!value) {
            setSendAmount('')
        }
    }

    const handleShowApyModal = (token: any) => {
        let tokenDetails = {};
        // console.log("poolData inside infinity vaults", poolData)
        if(!poolLoading && token === 'gAQUA'){
            tokenDetails = poolData.vaults.active[0];
        }
        if(!poolLoading && token === 'gGAMMA'){
            tokenDetails = poolData.vaults.active[1]
        }
        // console.log("tokenDetails", tokenDetails)
        setModalData(tokenDetails)
        setShowVaultDetails(true)
    }
    // const getSign = (value: any) => {
    //     if (!!value && parseFloat(value) > 0) {
    //         return '+'
    //     }
    //     else {
    //         return ""
    //     }
    // }

    const toggleApyModal = () => {
        setModalData({})
        setShowVaultDetails(false)
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `Amount ${sendAmount} staked` : `Failed to stake`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleStake = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(sendAmount) > 0) {
            const userAddress = selector.ethData.address
            // console.log("handle Stake", sendAmount);
            try {
                setLoading(true)
                const res: any = await deposit_in_infinity_vault(userAddress, data.infinity_vault_address, data.infinityAbi, sendAmount)
                handleNotification('success', res.transactionHash)
                // dispatch(fetchInfVaultData(userAddress, true))
                dispatch(updateUserBalance(userAddress))
                dispatch(updateUserLendingData(selector.ethData.address))
                setShowStake(false)
            }
            catch (error) {
                console.log("error==>", error)
                handleNotification('failed')
            }
            finally {
                setLoading(false)
            }
        }
    }

    const approveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${token} approved` : `Failed to approve  ${token}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }
    const handleApprove = async () => {
        try {
            setApproveLoading(true)
            const userAddress = selector.ethData.address
            const TOKEN_INSTANCE = await selectInstance(instType.PANCAKELP, data.infinity_token_address)
            await TOKEN_INSTANCE.methods
                .approve(data.infinity_vault_address, MAX_INT)
                .send({ from: userAddress })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    approveNotification(type, receipt.transactionHash)
                    setApproveLoading(false)
                    setIsAllowance(true)
                    // dispatch(fetchInfVaultData(userAddress, true))
                    dispatch(updateUserBalance(userAddress))
                    dispatch(updateUserLendingData(selector.ethData.address))
                })
                .on('error', function (error: any) {
                    approveNotification('failed')
                    setApproveLoading(false)
                })
        }
        catch (error) {
            console.log("error==>", error)
            setApproveLoading(false)
        }
        finally {
            setApproveLoading(false)
        }
    }

    const getExchangeRate = () => {
        let exchangeVal: any = '0'
        if (!!sendAmount && !!data && data.exchangeRate) {
            if (parseFloat(data.exchangeRate) > 0) {
                exchangeVal = parseFloat(sendAmount) / parseFloat(data.exchangeRate)
            }
        }
        return parseFloat(exchangeVal).toFixed(4)
    }

    const handleMax = () => {
        if (!!data && data.wallet_bal) {
            const maxAmt: any = data.wallet_bal;//getNumFormat(data.wallet_bal)
            setSendAmount(maxAmt)
        }
        else {
            setSendAmount('0')
        }
    }

    return (
        <>
            <ModalCont>
            <ModalCard>
                <FlexSBCont>
                    <ModalTitle>Stake</ModalTitle>
                    <FlexContStake>
                        <MaxStakeBtn onClick={() => handleShowApyModal(token)}>Get {token === 'gAQUA' ? "gAQUA" : "gGAMMA"}</MaxStakeBtn>
                    </FlexContStake>
                    <CrossButton onClick={() => setShowStake(false)} >+</CrossButton>
                </FlexSBCont>
                <ModalInputCont isActive={!!sendAmount && parseFloat(sendAmount) > 0} colorVal={colors.lightBlue}>
                    <FlexSBCont className="bottom-space">
                        <ModalText>Amount</ModalText>
                        <ModalText>{`Balance: ${!!data && data.wallet_bal ? commaFy(parseFloat(data.wallet_bal).toFixed(4)) : '0.0000'}`}</ModalText>
                    </FlexSBCont>
                    <FlexCont>
                        <ModalInput
                            placeholder="0"
                            onChange={handleSendAmount}
                            value={sendAmount && sendAmount}
                        />
                        <FlexCont>
                            <MaxBtn onClick={() => handleMax()}>MAX</MaxBtn>
                            <img src={token === 'gAQUA' ? AQUAIcon : GAMMAIcon} alt="" />
                            <ModalBText>{token === 'gAQUA' ? "gAQUA" : "gGAMMA"}</ModalBText>
                        </FlexCont>
                    </FlexCont>
                </ModalInputCont>
                <ExchangeRateBox>{`= ${getExchangeRate()} ${token === 'gAQUA' ? 'AQUA' : 'GAMMA'}`}</ExchangeRateBox>
                {isAllowance ?
                    <PlanetBtn disabled={!sendAmount || parseFloat(sendAmount) <= 0 || loading} onClick={() => handleStake()}>{loading ? <Spinner /> : 'Confirm'}</PlanetBtn> :
                    <PlanetBtn disabled={approveLoading} onClick={() => handleApprove()}>{approveLoading ? <Spinner /> : 'Approve'}</PlanetBtn>
                }
            </ModalCard>
            <ModalCard className="top-space">
                <FlexSBCont >
                    <ModalInfoText light>Unstaking time</ModalInfoText>
                    <ModalInfoText>{`${!!data && data.min_Time_To_Withdraw ? data.min_Time_To_Withdraw : '0'} Days`}</ModalInfoText>
                </FlexSBCont>
                <FlexSBCont className="info-space">
                    <ModalInfoText light>Deposit Fee</ModalInfoText>
                    {/* <ModalInfoText className="apy-val">{`${getSign(data.apy)}${!!data && data.apy ? parseFloat(data.apy).toFixed(2) : '0'}% APY`}</ModalInfoText> */}
                    <ModalInfoText>0.1%</ModalInfoText>
                </FlexSBCont>
            </ModalCard>
            </ModalCont>
            
            <>
                <CustomActionModal show={showVaultDetails} toggleModal={toggleApyModal}>
                    {showVaultDetails && <VaultDetailsModal toggleVaultModal={toggleApyModal} data={modalData} setModalData={setModalData} />}
                </CustomActionModal>
            </>
            
            
        </>
    );
};

export default Stake;