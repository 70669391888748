import { useState, useEffect, createContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MainContainer, FlexCont, CardButton2 } from 'shared/styles/styled'
import { TitleText, TitleInfo, DataTitle, CardText } from 'shared/styles/globalText'
import { DataCont, TableSearchCont, TableProtoCalCont, TableCont, LoadingText4 } from 'shared/styles/planetTableStyle'
import { PoolsContainer, PoolsTabCont, TabWrapper, TabContainer, TabBox, TabText, GammaEarnedBox, EarnValText, PoolsHeader} from './style'
import { newPoolTabOptions, protocolOptions, latestPoolTabOptions } from 'service/dataConstant'
import { FarmPoolContext } from 'context/FarmPoolContext'
import Select from 'shared/select'
import HighestApyPools from './components/HighestApyPools'
import PoolTable from './components/PoolTable'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import InfoTooltip from 'shared/info-tool-tip';
import PoolEarnings from './components/PoolEarnings';
import Spinner from 'shared/spinner';
import { commaFy } from 'service/globalFunctions';
import { claimGammaRewardsForPools, totalAllocPoint, GAMMAPerBlockNew } from 'modules/block-chain/BlockChainMethods';
import { updateUserBalance } from 'logic/action/user.actions';
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import { updateUserLendingData } from 'logic/action/lending.action';
import { transLinkUrl } from 'service/global-constant';
// import PlanetGToken from './components/PlanetGToken'
import PoolEarningsClaimAndStake from './components/PoolEarningsClaimAndStake'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import CollectRewards from './components/pool-modal/CollectRewards'
import { returnTokenList, tokenSwapList } from './../block-chain/tokenList'
import SwapInPools from 'modules/pools/components/pool-modal/SwapInPools'

let userInLP: boolean = false;
const Pools = () => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('all')
    const [protocol, setProtocol] = useState('')
    const [claimLoading, setClaimLoading] = useState(false)
    const { poolLoading, poolData, portfolioLoading, userPortfolio, gammaInfDataNew, aquaInfDataNew } = selector.user
    const [claimAndStakeLoading, setClaimAndStakeLoading] = useState(false)
    const [showCollectModal, setShowCollectModal] = useState(false)
    const [farmPoolData, setFarmPoolData] =  useState({
        totalAllocPoint: 365,
        gammaPerBlock: 1614583333333333200, 
        tokenSwapList: tokenSwapList,
        tokenSwapListWithUserBalances: tokenSwapList
    })
    const [poolList, setPoolList] = useState<any>({
        active: [],
        finished: []
    })

    const [userPoolList, setUserPoolList] = useState<any>([])
    const [myPoolTableList, setMyPoolTableList] = useState<any>([])
    const [tokenDataList, setTokenDataList] = useState<any>(tokenSwapList)
    const [showSwapPopUp, setShowSwapPopUp] = useState(false)
    const [receiveTokenNumber, setReceiveTokenNumber] = useState('')
    const [tokenSwapAmount, setTokenSwapAmount] = useState('')
    const [initialTokenAdd, setInitialTokenAdd] = useState('')
    const [sendToken, setSendToken] = useState<any>(tokenSwapList[1])

    useEffect(() => {
        const updateTokenListWithBalances = async () => {
            const userAddress = selector.ethData.address
            try {
              const res = await returnTokenList(userAddress)
              if (!!res && typeof res !== 'undefined') {
                farmPoolData.tokenSwapListWithUserBalances = res;
              }
            } catch (error) {
              console.log('error===>', error)
            }
        }
        updateTokenListWithBalances()
    }, [selector.ethData.address, poolData])

    useEffect(() => {
        if(gammaInfDataNew != undefined && aquaInfDataNew != undefined && poolData != undefined && poolData.newPools != undefined &&  poolData.newPools.active && poolData.newPools.active.length > 0) {
            if(Object.keys(gammaInfDataNew).length > 0 && Object.keys(aquaInfDataNew).length > 0) {
                let pools = JSON.parse(JSON.stringify(poolData.newPools));
                pools.active.unshift(gammaInfDataNew, aquaInfDataNew);

                // console.log('useEffect pools', pools);
                setPoolList(pools)

                let uPoolList = getUserPoolData(pools);

                // console.log('uPoolList', uPoolList);
                setMyPoolTableList(uPoolList)
                setUserPoolList(uPoolList);
            } else {
                let pools = JSON.parse(JSON.stringify(poolData.newPools));
                // console.log('useEffect pools', pools);
                setPoolList(pools)

                let uPoolList = getUserPoolData(pools);

                // console.log('uPoolList', uPoolList);
                setMyPoolTableList(uPoolList)
                setUserPoolList(uPoolList);
            }
        }
    }, [gammaInfDataNew, aquaInfDataNew, poolData])

    useEffect(() => {
        totalAllocPoint().then((tAlloc) => {
            if(farmPoolData.totalAllocPoint != tAlloc) {
                farmPoolData.totalAllocPoint = tAlloc;
                setFarmPoolData(farmPoolData)
            }
        })
        GAMMAPerBlockNew().then((gammaPerBlock) => {
            if(farmPoolData.gammaPerBlock != gammaPerBlock) {
                farmPoolData.gammaPerBlock = gammaPerBlock;
                setFarmPoolData(farmPoolData)
            }
        })
    }, []);

    
    // const [userInLP, setUserInLP] = useState(false)
    const handleProtocolSelect = (protocolOption: string) => {
        // console.log(protocolOption)
        let rowData = userPoolList.slice();
        setProtocol(protocolOption)
        if (!!protocolOption) {
            rowData = rowData.filter((e: any) => e.protocol.toLowerCase() === protocolOption)
        }
        setMyPoolTableList(rowData)
    }

    const getUserPoolData = (pools:any ) => {
        // console.log("userPortfolio details", userPortfolio, poolData)
        let userPoolArr: any = []
        if (!poolLoading && !portfolioLoading) {
            // console.log('getUserPoolData poolList', poolList);
            const dataArr = pools;
            if (!!dataArr && Object.values(dataArr).length > 0) {
                userPoolArr = dataArr.active.filter((e: any) => {
                    // console.log('getUserPoolData >', e.poolId, parseFloat(e.vaultBalanceUSD) > 0)
                    return parseFloat(e.vaultBalanceUSD) > 0
                })
            }
            if(userPoolArr.length > 0){
                userInLP = true;
            }
        }
        // console.log('userPoolArr', userPoolArr);
        return userPoolArr
    }

    const handleGammaCollect = async (stake: any) => {
        setShowCollectModal(true);
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Claim ${type}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const toggleCollectModal = () => {
        setShowCollectModal(false)
    }

    const toggleSwapPopupModal = () => {
        // setModalData({})
        setShowSwapPopUp(false)
    }

    const closeSwapModal = () => {
        setShowSwapPopUp(false)
    }

    const showSwapPopup = async (data: any, swapAmount: any, sendToken?: any) => {
        
        let tokenNumber : any = data.poolId == 0 ? 2 : 0;
        setReceiveTokenNumber(tokenNumber)
        setTokenSwapAmount(swapAmount)
        if(sendToken){
            setSendToken(sendToken)
        }
        setShowSwapPopUp(true)
    }

    const updateTokenList = async () => {
        const userAddress = selector.ethData.address
        try {
          const res = await returnTokenList(userAddress)
          if (!!res && typeof res !== 'undefined') {
            setTokenDataList(res)
          }
        } catch (error) {
          console.log('error===>', error)
        }
    }

    const getPlatfromEarnings = () => {
        if(selector.ethData.address == undefined) {
            return '0.00';
        }
        return `${userPortfolio.pendingGammaAllPools && +userPortfolio.pendingGammaAllPools > 0 ? commaFy((userPortfolio.pendingGammaAllPools * userPortfolio.gammaPrice).toFixed(2)) : '0.00'}`
    }

    return (
        <FarmPoolContext.Provider value={farmPoolData}>
        <MainContainer>
            <PoolsContainer>
                <PoolsHeader className="poolHeader">
                    <div>
                        <TitleText>Pools</TitleText>
                        <TitleInfo className="title-info">Earn Trade Fees & GAMMA!</TitleInfo>
                    </div>
                    <div className="gammaEarnings">   
                        
                        <GammaEarnedBox>
                            <FlexCont id='earnings'>
                                <img src={GAMMAIcon} alt="" />
                                <div>
                                    <div className='on-web'>
                                        <FlexCont >
                                            <CardText>Pool Earnings</CardText>
                                            <InfoTooltip infoId='claimAndStake' left={5} />
                                        </FlexCont>
                                    </div>
                                    <div className='on-mob'>
                                        <FlexCont >
                                            <CardText>Pool Earnings</CardText>
                                            <InfoTooltip infoId='claimAndStake' left={5} />
                                        </FlexCont>
                                    </div>

                                    <PoolEarningsClaimAndStake portfolioLoading={portfolioLoading} userPortfolio={userPortfolio} claim_type={'claim_stake'} />
                                    {portfolioLoading ? 
                                        <LoadingText4 /> :
                                        <EarnValText className="gammaInUSD" id='gammaInUSD'>
                                            ${getPlatfromEarnings()}
                                        </EarnValText>
                                    }
                                </div>
                            </FlexCont>
                            <CardButton2 className="on-web claim_stake" disabled={claimAndStakeLoading} onClick={() => handleGammaCollect(true)}>{claimAndStakeLoading ? <Spinner /> : 'Collect'}</CardButton2>
                            <CardButton2 className="on-mob claim_stake" disabled={claimAndStakeLoading} onClick={() => handleGammaCollect(true)}>{claimAndStakeLoading ? <Spinner /> : 'Collect'}</CardButton2>
                        </GammaEarnedBox>
                    </div>
                    
                </PoolsHeader>
                <HighestApyPools poolsNewData={poolList} showSwapPopup={showSwapPopup} />
                {
                    userPoolList.length > 0 &&
                    <DataCont>
                        {myPoolTableList.length > 0 && <DataTitle className='my-asset'>My Pools</DataTitle>}
                        {userPoolList.length > 0 &&
                        <PoolsTabCont>
                            <TableSearchCont className="pools">
                                <TableProtoCalCont>
                                    <Select options={protocolOptions} handleSelect={handleProtocolSelect} />
                                </TableProtoCalCont>
                            </TableSearchCont>
                        </PoolsTabCont>
                        }
                        {myPoolTableList.length > 0 && 
                            <PoolTable
                                isBorderDisable={true}
                                isUserPool={true}
                                activeTab={activeTab}
                                protocol={protocol}
                                poolLoading={poolLoading}
                                data={myPoolTableList}
                                portfolioLoading={portfolioLoading}
                                userPortfolio={userPortfolio}
                                pools="my_pools"
                            />
                        }
                        
                    </DataCont>
                }            
                <DataCont>
                    <DataTitle>All Pools</DataTitle>
                    <PoolsTabCont>
                        <TabWrapper>
                            <TabContainer>
                                {
                                    latestPoolTabOptions.map((item: any, index: number) => (
                                        <TabBox key={index} onClick={() => activeTab === 'all' || activeTab !== item.value ? setActiveTab(item.value) : setActiveTab('all')} isActive={activeTab === item.value}>
                                            <TabText isActive={activeTab === item.value}>{item.label}</TabText>
                                        </TabBox>
                                    ))
                                }
                            </TabContainer>
                        </TabWrapper>
                        {!userInLP &&
                        <TableSearchCont className=''>
                            <TableProtoCalCont>
                                <Select options={protocolOptions} handleSelect={handleProtocolSelect} />
                            </TableProtoCalCont>
                        </TableSearchCont>
                            }
                    </PoolsTabCont>
                
                    <TableCont>
                        <PoolTable
                            activeTab={activeTab}
                            protocol={protocol}
                            poolLoading={poolLoading}
                            data={poolList}
                            portfolioLoading={portfolioLoading}
                            userPortfolio={userPortfolio}
                            pools="all"
                        />
                    </TableCont>
                
                
                </DataCont>
            </PoolsContainer>
            <CustomActionModal show={showCollectModal} toggleModal={toggleCollectModal}>
                {showCollectModal && <CollectRewards pendingGamma={userPortfolio.pendingGammaAllPools} gammaPrice={userPortfolio.gammaPrice} toggleCollectModal={toggleCollectModal}/>}
            </CustomActionModal>
            <CustomActionModal show={showSwapPopUp} toggleModal={toggleSwapPopupModal}>
                {showSwapPopUp && <SwapInPools tokenList={tokenDataList} updateTokenList={updateTokenList} initialTokenAdd={initialTokenAdd} receiveTokenNumber={receiveTokenNumber} closeSwapModal={closeSwapModal} tokenSwapAmount={tokenSwapAmount} toggleSwapPopupModal={toggleSwapPopupModal} sendTokenObject={sendToken} />}
            </CustomActionModal>
        </MainContainer>
        </FarmPoolContext.Provider>
    );
};

export default Pools
