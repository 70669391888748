import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { connectEthWallet, disconnectEthWallet } from 'logic/action/wallet.actions'
import { Switch } from 'react-router'
import { Router, Route } from 'react-router-dom'
import history from '../history'
import { Paths } from './types'
import { NotFound } from './NotFound'
import { RouteContainer, StyledRoutesWrapper, StyledRoutesContainer, StyledRoutes, SideNavCont } from './style'
import Base from 'modules/base'
import Vaults from 'modules/vaults'
import Pools from 'modules/pools'
import Swap from 'modules/swap'
import SwapPlanetZap from 'modules/swapPlanetZap'
import PlanetToken from 'modules/planet-token'
import Footer from 'modules/app/components/footer'
import { Header, MobileHeader } from 'modules/app/components/header'
import { SideNav } from '../side-nav'

import Lending from 'modules/lending'
import Liquidation from 'modules/liquidations'
import Markets from 'modules/markets'
import Metrics from 'modules/metrics'
import Halborn from '../halborn'
import Lending_v1 from 'modules/lending_v1'
import Markets_v1 from 'modules/markets_v1'
import Vaults_v1 from 'modules/vaults_v1'
import Pools_v1 from 'modules/pools_v1'
import Compensation from 'modules/compensation'
// import Boost from 'modules/boost-calculator'
import { fetchInfVaultDataNew } from 'logic/action/user.actions'
import LiquidStaking from 'modules/liquidStaking'
import Vesting from 'modules/vesting'
import Bounty from 'modules/bounty'
import Migration from 'modules/migration'
import New_Pools from 'modules/pools_v3'

const notFoundRoute: RouteDefinition = {
  path: '*',
  component: NotFound,
  protected: false,
  title: '',
}

export const routes: RouteDefinition[] = [
  {
    path: Paths.root,
    component: Base,
    protected: false,
    redirect: Paths.root,
  },
  // {
  //   path: Paths.boost,
  //   component: Boost,
  //   protected: false,
  //   redirect: Paths.root,
  // },
  // {
  //   path: Paths.vaults,
  //   component: Vaults,
  //   protected: false,
  //   redirect: Paths.root,
  // },
  {
    path: Paths.pools,
    component: Pools,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.swap,
    component: SwapPlanetZap,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.liquidity,
    component: SwapPlanetZap,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.swapplanetzap,
    component: SwapPlanetZap,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.lending,
    component: Lending,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.markets,
    component: Markets,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.metrics,
    component: Metrics,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.liquidations,
    component: Liquidation,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.planetToken,
    component: PlanetToken,
    protected: false,
    redirect: Paths.root,
  },
  // {
  //   path: Paths.lending_v1,
  //   component: Lending_v1,
  //   protected: false,
  //   redirect: Paths.root,
  // },
  // {
  //   path: Paths.markets_v1,
  //   component: Markets_v1,
  //   protected: false,
  //   redirect: Paths.root,
  // },
  {
    path: Paths.vaults_v1,
    component: Vaults_v1,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.liquidStaking,
    component: LiquidStaking,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.compensation,
    component: Compensation,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.vesting,
    component: Vesting,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.migration,
    component: Migration,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.pools_v3,
    component: New_Pools,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.bounty,
    component: Bounty,
    protected: false,
    redirect: Paths.root,
  },
].concat(notFoundRoute as any) // Ensure that notFound is the last route

export interface RouteDefinition {
  path: string
  protected?: boolean
  redirect?: string
  component?: any
  routes?: RouteDefinition[]
  title?: string
  pathType?: number
}

interface Props { }
interface RoutesProps { }
interface StateProps {
  user: any,
  planet: any
}

function getRouteRenderWithAuth(route: RouteDefinition, i: number) {
  return () => <route.component />
}

const Routes: React.FC<Props & RoutesProps> = () => {
  const selector = useSelector((state: StateProps) => state)
  const dispatch = useDispatch()
  const { bgIcon } = selector.planet
  const { authLoading } = selector.user
  const walletType = localStorage.getItem('walletType')

  useEffect(() => {
    if (!!walletType) {
      if (parseInt(walletType) === 1) {
        if (!!window && window.ethereum) {
          setTimeout(async () => {
            window.ethereum.on('accountsChanged', function (accounts: any) {
              // console.log(" on user address change in type1", accounts[0], accounts)
              if (!!accounts && accounts.length > 0) {
                dispatch(connectEthWallet(accounts[0]))
                // dispatch(fetchInfVaultDataNew(accounts[0]))
              } else {
                dispatch(disconnectEthWallet())
              }
            })
          }, 500)
        }
      }
      if (parseInt(walletType) === 2) {
        if (!!window && window.BinanceChain) {
          setTimeout(async () => {
            window.BinanceChain.on('accountsChanged', function (accounts: any) {
              // console.log(" on user address change in type2", accounts[0], accounts)
              if (!!accounts && accounts.length > 0) {
                dispatch(connectEthWallet(accounts[0]))
                // dispatch(fetchInfVaultDataNew(accounts[0]))
              } else {
                dispatch(disconnectEthWallet())
              }
            })
          }, 500)
        }
      }

    }
  }, [dispatch, walletType])

  const getPlanetImage = () => {
    const { bgIcon } = selector.planet;
    return <img className="bg-icon3" src={bgIcon} alt="bg-icon3" />;
  }

  return (
    <Router history={history}>
      <RouteContainer>
        {/* <img className="bg-icon1" src={BgPlanetIcon} alt="bg-icon1" /> */}
        <img className="bg-icon2" src={bgIcon} alt="bg-icon2" />
        {getPlanetImage()}
        <Header />
        <MobileHeader />
        <Halborn />
        <StyledRoutesWrapper>
          <SideNavCont >
            <SideNav />
          </SideNavCont>
          <StyledRoutesContainer>
            <StyledRoutes>
              <Switch>
                {routes.map((route, i) => {
                  if (authLoading) {
                    return <div key={i}>Loading ...</div>
                  } else {
                    const render = getRouteRenderWithAuth(route, i)
                    const rest = { render }
                    return <Route key={i} path={route.path} exact {...rest} />
                  }
                })}
              </Switch>
            </StyledRoutes>
          </StyledRoutesContainer>
        </StyledRoutesWrapper>
        <Footer />
      </RouteContainer>
    </Router>
  )
}

export default Routes
