import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch, connect } from 'react-redux'
import { updateUserBalance, updateLPData, setPortfolioLoading, setPoolInfoLoading } from 'logic/action/user.actions'
import { MainContainer, FlexCont, CardButton, CardButton2, FlexSBCont } from 'shared/styles/styled'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { TitleText, TitleInfo, CardTitle, CardText, CardSubText, AquaVotingPower, CardAquaText } from 'shared/styles/globalText'
import { BaseContainer, BaseHeader, TvlInfoBox, Card, UserInfoCont, OverViewInfo, CardNumText, BalanceWrapper, BalanceCont, BalanceText, LevelBox, MobBalWrapper, MobBalCard, MobBuyAssetCont, MobBalText, LoadingText1, LoadingText2, ConnectWalletBtn, GTokenImgCont, AquaBlueBox } from './style'
import { getTokenPrice, handleWithdrawLp, balanceOf,allowance, getWithdrawFeeFactor, unstake_instantly_new } from 'modules/block-chain/BlockChainMethods'
import { buildTxForSwap, buildTxForSwapInMigrate } from 'modules/block-chain/Swap'
import TransactionInfo from 'shared/transaction-info'
import { transLinkUrl } from 'service/global-constant'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import TvlIcon from 'assets/icons/tvl-icon.svg'
import { commaFy, convertToKAndM } from 'service/globalFunctions'
import TransactionConfirmationLoader from 'shared/transaction-confirmation-loader'
import Projection from './components/Projection'
import PlanetActions from './components/PlanetActions'
import PlanetToken from './components/PlanetToken'
import ValueLocked from './components/ValueLocked'
import CountUp from "react-countup"
import PlanetNews from './components/PlanetNews'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { aquaAddress, bnbAddress, migrateToV3Abi, migrateToV3Address, gammaFarmV3, gammaFarmV3Abi, pancakeLPabi, BTCBAddress } from 'modules/block-chain/abi'
import { PLANETROUTERADDRESS, PLANETROUTERABI, convertLpAbi } from 'modules/block-chain/SwapDexAbi'
import { getConvertList, getConvertLpData, selectInstance, instanceType, convertLpNew, lpTokenApprove, lpTokenApproveString} from 'modules/block-chain/Swap'
import { planetZapOneInchAbi, planetZapOneInchAbiOld, planetZapOneInchAddress, planetZapOneInchAddressOld } from 'modules/block-chain/PlanetZapOneInchAbi';
import GammaBoost from './components/GammaBoost'
import HighestApySection from './components/HighestApySection'
import { NewsWrapper, NewsContainer, NewsDataCard, NewsContent, NewsTitle, NewsInfo } from './components/style'
import CustomModal from 'shared/custom-modal'
import WalletLogout from 'modules/app/components/wallet-modal/WalletLogout'
import WalletList from 'modules/app/components/wallet-modal/WalletList'
import { connectWallet } from 'modules/wallet-provider/WalletProvider'
import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsAvatar,
    useEnsName,
  } from 'wagmi'
import { useWeb3Modal } from "@web3modal/react";
import { connectEthWallet, disconnectEthWallet } from 'logic/action/wallet.actions'
import wallet from 'modules/wallet/wallet'
import { InfoNumberTxt } from 'modules/lending/style'
import { DataInfoTxt, MaxBox, ModalInput, ModalInputCont } from 'shared/styles/highestApyStyle'
import { UnderstandCheckBox } from 'modules/pools/components/style'
import grey_tick from 'assets/icons/grey_tick.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import RightIcon from 'assets/icons/right_arrow_white.svg'
import { getTokenIcon } from 'shared/tokenIconList'
import { Table, TableHeader, TableBody, MobTableBody, TableRowCont, TableRow, CellCont, HeadTextCont, HeadTextData, HeadText, CellText, CellSmText, OneImg, TwoImgCont, ArrowIconCont, MobArrowIconCont } from 'shared/styles/planetTableStyle'
import { gammaAddress } from 'modules/block-chain-green_v1/lendingAbi'
import { BigNumber } from 'bignumber.js'
import { AnyARecord } from 'dns'
import { convertToEther, convertToWei, maxAmount, noExponents } from 'modules/block-chain/lendingAbi'
import { updateUserLendingData } from 'logic/action/lending.action'
import { activeV3Pools } from 'modules/block-chain/pfPoolsList'

const poolIdsToBeMigrated = [2, 3, 16, 17, 18, 19, 23];

const v3PoolIdsForThena = [{"strategyAddress":"0x31636E47EFE076B23dEe7Fd1D89C962DeCCd63e9", "poolId": 3}, {"strategyAddress":"0x3496294F8aB14904Cb5427556ae217CCFd09FCB1", "poolId": 4}, {"strategyAddress":"0x4027a5cdA69dA74674A09Af4AaCc255d0d7a6e6D", "poolId": 5}, {"strategyAddress":"0xFfE80fBdFC324305C61794Ba38fD2f041CB98b19", "poolId": 6}, {"strategyAddress":"0xD0121b07C279498ca4E7c03eBeA7508B5620A4C3", "poolId": 7}]

const Migration = () => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const { poolLoading, poolData, portfolioLoading, userPortfolio, gammaInfDataNew, aquaInfDataNew } = selector.user
    const [highestApyArr, setHighestApyArr] = useState<any>([])
    const { ethWalletConnected } = selector.ethData
    const [openWalletList, setOpenWalletList] = useState(false)
    const [isUnderstand, setIsUnderstand] = useState(false)
    const [sendAmount, setSendAmount] = useState('')
    const [displaySendAmount, setDisplaySendAmount] = useState('')
    const [migration_array, set_migration_array] = useState([])
    const [aquaInfnityVault, setAquaInfnityVault] = useState<any>({})
    const [lock_time, set_lock_time] = useState(1);
    const [platform_data, setPlatformData] = useState({
        aqua_price: 0,
        gamma_price: 0
    })
    const [loading, setLoading] = useState(false);
    const { open } = useWeb3Modal();
    const { connector: activeConnector, address, isConnected } = useAccount()
    const [showTransactionModal, setShowTransactionModal] = useState(false)
    const [transactionFlags, setTransactionFlags] = useState<any>([])
    const [showTransModal, setShowTransModal] = useState(false)
    const [transactionLink, setTransactionLink] = useState('')
    const [slippageVal, setSlippageVal] = useState(0.5)
    const [poolObj, setPoolObject] = useState<any>({})
    const [poolV3Array, setPoolv3Arr] = useState<any>([])

    const thenaPoolIds = [16, 17, 18, 19, 23];
    // const [migrateLoading, setMigrateLoading] = useState<any>({
    //     poolId : -1,
    //     loading: false
    // });


    useEffect(() => {
        if(poolData != undefined && poolData.newPools != undefined && poolData.newPools.to_be_migrated && poolData.newPools.to_be_migrated.length > 0) { //aquaInfDataNew != undefined && **condition to be added for aqua infinity vault
            let pools_arr: any = poolData.newPools.to_be_migrated;

            // console.log('poolData.newPools.to_be_migrated', poolData.newPools.to_be_migrated);
            //let v3Pools: any = poolData.v3Pools;
            set_migration_array(pools_arr);
            
            let flags: any = [];
            flags.push({actionHeading: "Withdraw from V2 Pool", status: 0});
            flags.push({actionHeading: "Approve", status: 0});
            flags.push({actionHeading: "Deposit in V3 Pool", status: 0});
            setTransactionFlags(flags);
            // if(Object.keys(aquaInfDataNew).length > 0) {
            //     // pools_arr.push(aquaInfDataNew);
            //     set_migration_array(pools_arr);
            // } else {
            //     set_migration_array(pools_arr);
            // }
        }

        if(poolData != undefined && poolData.v3Pools != undefined && poolData.v3Pools.active && poolData.v3Pools.active.length > 0) { //aquaInfDataNew != undefined && **condition to be added for aqua infinity vault
            let pools_arr: any = poolData.v3Pools.active;
            //let v3Pools: any = poolData.v3Pools;
            setPoolv3Arr(pools_arr)
            // if(Object.keys(aquaInfDataNew).length > 0) {
            //     // pools_arr.push(aquaInfDataNew);
            //     set_migration_array(pools_arr);
            // } else {
            //     set_migration_array(pools_arr);
            // }
        }
    }, [poolData])

    useEffect(() => {

        if(Object.keys(aquaInfDataNew).length > 0) {
            setAquaInfnityVault(aquaInfDataNew);
        }
    }, [aquaInfDataNew])

    async function onOpen() {
        setLoading(true);
        await open();
        setLoading(false);
    }

    const reloadMigrationOnTrx = async () => {
        if(poolData != undefined && poolData.newPools != undefined && poolData.newPools.to_be_migrated && poolData.newPools.to_be_migrated.length > 0) { //aquaInfDataNew != undefined && **condition to be added for aqua infinity vault
            let pools_arr: any = poolData.newPools.to_be_migrated;
            //let v3Pools: any = poolData.v3Pools;
            set_migration_array(pools_arr);
            
            let flags: any = [];
            flags.push({actionHeading: "Withdraw from V2 Pool", status: 0});
            flags.push({actionHeading: "Approve", status: 0});
            flags.push({actionHeading: "Deposit in V3 Pool", status: 0});
            setTransactionFlags(flags);
            // if(Object.keys(aquaInfDataNew).length > 0) {
            //     // pools_arr.push(aquaInfDataNew);
            //     set_migration_array(pools_arr);
            // } else {
            //     set_migration_array(pools_arr);
            // }
        }
    }

    const handleNotification = (type: string, hash?: any, poolObj?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        let dataVal: any = {};
        if(poolObj !== undefined){
            const msg = type === 'success' ? `Migrate from old  ${parseFloat(poolObj.vaultBalanceUSD).toFixed(4)} ${poolObj.name} to v3 pool ${parseFloat(poolObj.vaultBalanceUSD).toFixed(4)} ${poolObj.new_pool_name}` : `Failed to migrate`
            dataVal= {
                type: type,
                message: `Migrate from old  ${parseFloat(poolObj.vaultBalanceUSD).toFixed(4)} ${poolObj.name} to v3 pool ${parseFloat(poolObj.vaultBalanceUSD).toFixed(4)} ${poolObj.new_pool_name}`,
                link: linkUrl
            }
        } else {
            const msg = type === 'success' ? `Migrate from old  v2 pool to v3 pool` : `Failed to migrate`
            dataVal= {
                type: type,
                message: `Migrate from old  v2 pool to v3 pool`,
                link: linkUrl
            }
        }
        
        
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleAquaNotification = (type: string, hash?: any, poolObj?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `Migrate from old Aqua Pool ${parseFloat(aquaInfnityVault.deposited_bal_usd).toFixed(4)} to new Aqua v3 pool ${parseFloat(aquaInfnityVault.deposited_bal_usd).toFixed(4)}` : `Failed to Unstake`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const toggleTransactionModal = async () => {
        setShowTransactionModal(!showTransactionModal);
    }

    const showTransLink = (transHash: any) => {
        const transLink = `${transLinkUrl}/${transHash}`
        setTransactionLink(transLink)
        setShowTransModal(true)
    }

    const handleAquaMigrate = async () => {
        let aquaBeforeWithdraw: any = 0;
        if (selector.ethData.ethWalletConnected  > 0) {
            //withdraw aqua from old pool
            const userAddress = selector.ethData.address

            let flags: any = transactionFlags;
            flags[0].status = 1;
            flags[0].actionHeading = "Withdraw from V2 Aqua Pool";
            flags[1].status = 0;
            flags[1].actionHeading = "Approve Aqua on V3 Pool";
            flags[2].status = 0;
            flags[2].actionHeading = "Deposit Aqua on V3 Pool";
            setTransactionFlags(() => ([...flags]));
            toggleTransactionModal();
            let res: any = {};
            try{
                aquaBeforeWithdraw = await balanceOf(aquaAddress, userAddress);
                res = await unstake_instantly_new(userAddress, aquaInfnityVault.pid, maxAmount)
                if(res.transactionHash) {
                    showTransLink(res.transactionHash)
                    setShowTransModal(true);
                }

                let flags: any = transactionFlags;
                flags[0].status = 2;
                flags[1].status = 1;
                flags[2].status = 0;
                setTransactionFlags(() => ([...flags]));
                // setShowTransactionModal(false)
                // dispatch(updateUserLendingData(selector.ethData.address))
                // dispatch(updateUserBalance(selector.ethData.address))
                // await reloadMigrationOnTrx();
            }
            catch(error) {
                console.log("error==>", error)
                handleAquaNotification('failed')
                let flags: any = transactionFlags;
                flags[0].status = 0;
                flags[1].status = 0;
                flags[2].status = 0;
                setTransactionFlags(() => ([...flags]));
                setShowTransactionModal(false)
                dispatch(updateUserLendingData(selector.ethData.address))
                dispatch(updateUserBalance(selector.ethData.address))
                return;

            }
            
            //approve aqua on new farm
            let aquaWithdrawn = await balanceOf(aquaAddress, userAddress);
            let aquaToBeMigrated = new BigNumber(aquaWithdrawn).minus(aquaBeforeWithdraw).toString() ;
            //console.log('aquaToBeMigrated', aquaToBeMigrated);
            const allowanceAmount:any = await allowance(aquaAddress, userAddress, gammaFarmV3);
            //console.log('aquaToBeMigrated', allowanceAmount);
            if( (new BigNumber(allowanceAmount)).comparedTo((aquaToBeMigrated)) ) {
                // get allowance
                let res:any = await lpTokenApproveString(aquaAddress, gammaFarmV3, userAddress, aquaToBeMigrated);
  
                if(res == "Fail") {
                    setShowTransactionModal(false)
                    //handleNotification('failed')
                    return "error";
                }
                let flags: any = transactionFlags;
                flags[0].status = 2;
                flags[1].status = 2;
                flags[2].status = 1;
                setTransactionFlags(() => ([...flags]));
            } else {
                let flags: any = transactionFlags;
                flags[0].status = 2;
                flags[1].status = 2;
                flags[2].status = 1;
                setTransactionFlags(() => ([...flags]));
            }

            //deposit aqua on new farm
            const v3FarmContractInstance : any = new wallet.web3.eth.Contract( gammaFarmV3Abi, gammaFarmV3);
            //console.log('aquaToBeMigrated', aquaToBeMigrated);
            v3FarmContractInstance.methods.deposit(
                userAddress,
                aquaToBeMigrated,
                2,
                0,
            ).send({from: userAddress})
            .once('transactionHash', function (hash: any) {
                if (!!hash) {
                    
                    showTransLink(hash)
                    setShowTransModal(true)
                }
            }) 
            .once('confirmation', async function (confNumber: any, receipt: any) {
                let flags = transactionFlags;
                flags[0].status = 2;
                flags[1].status = 2;
                flags[2].status = 2;
                setTransactionFlags(() => ([...flags]));
                setShowTransactionModal(false)
                
                //setConfirmTransHistory(receipt.transactionHash, receipt.status)
                const type = receipt.status ? 'success' : 'failed'
                handleAquaNotification(type, receipt.transactionHash)
                dispatch(setPortfolioLoading(true))
                dispatch(updateUserBalance(selector.ethData.address))
                dispatch(updateUserLendingData(selector.ethData.address))
                await reloadMigrationOnTrx();

            })
            .on('error', function (error: any) {
                console.log("error==>", error)
                setShowTransactionModal(false)
                handleNotification('failed')
            })
        }
    }

    const handleThenaMigrate = async (poolObj: any) => {
        if (selector.ethData.ethWalletConnected  > 0) {
            //withdraw aqua from old pool
            const userAddress = selector.ethData.address

            let flags: any = [
                {actionHeading: "Withdraw from V2 Pool", status: 1},
                {actionHeading: "Approve", status: 0},
                {actionHeading: "Deposit in V3 Pool", status: 0}
            ];
            setTransactionFlags(() => ([...flags]));
            toggleTransactionModal();
            try{
                if(poolObj.vaultBalanceUSD > 0) {
                    let res: any = await handleWithdrawLp(poolObj.poolId, poolObj.vaultBalance, userAddress, handleNotification, poolObj);
                
                    if(res.transactionHash) {
                        showTransLink(res.transactionHash)
                        setShowTransModal(true);
                    }
    
                    let flags: any = transactionFlags;
                    flags[0].status = 2;
                    flags[1].status = 1;
                    flags[2].status = 0;
                    setTransactionFlags(() => ([...flags]));
                    // setShowTransactionModal(false)
                    // dispatch(updateUserLendingData(selector.ethData.address))
                    // dispatch(updateUserBalance(selector.ethData.address))
                    // await reloadMigrationOnTrx();
                } else {
                    let flags: any = transactionFlags;
                    flags[0].status = 2;
                    flags[1].status = 1;
                    flags[2].status = 0;
                    setTransactionFlags(() => ([...flags]));
                }
                
            }
            catch(error) {
                console.log("error==>", error)
                handleNotification('failed')
                let flags: any = transactionFlags;
                flags[0].status = 0;
                flags[1].status = 0;
                flags[2].status = 0;
                setTransactionFlags(() => ([...flags]));
                setShowTransactionModal(false)
                dispatch(updateUserLendingData(selector.ethData.address))
                dispatch(updateUserBalance(selector.ethData.address))
                return;

            }
            
            //approve LP on new farm
            let lpBalanceAfterWithdraw = await balanceOf(poolObj.wantAddress, userAddress);
            poolObj.walletBalance = convertToEther(lpBalanceAfterWithdraw, 18)
            let lpBalanceToBeMigrated = convertToEther(lpBalanceAfterWithdraw, 18) ;

            const allowanceAmount:any = await allowance(poolObj.wantAddress, userAddress, gammaFarmV3);
            // console.log(allowanceAmount, lpBalanceToBeMigrated, BigNumber(allowanceAmount).comparedTo((lpBalanceAfterWithdraw)))
            if(BigNumber(allowanceAmount).comparedTo((lpBalanceAfterWithdraw)) == -1 ) {
                // get allowance
                let res:any = await lpTokenApproveString(poolObj.wantAddress, gammaFarmV3, userAddress, lpBalanceAfterWithdraw);
  
                if(res == "Fail") {
                    setShowTransactionModal(false)
                    //handleNotification('failed')
                    return "error";
                }
                let flags: any = transactionFlags;
                flags[0].status = 2;
                flags[1].status = 2;
                flags[2].status = 1;
                setTransactionFlags(() => ([...flags]));
            } else {
                let flags: any = transactionFlags;
                flags[0].status = 2;
                flags[1].status = 2;
                flags[2].status = 1;
                setTransactionFlags(() => ([...flags]));
            }
            let v3Pool: any = v3PoolIdsForThena.find((pool: any)=> pool.strategyAddress.toLowerCase() == poolObj.strategyAddress.toLowerCase());
            // console.log("v3Pool", v3Pool.poolId)
            //deposit LP on new farm
            // poolId and Lock to be changed
            const v3FarmContractInstance : any = new wallet.web3.eth.Contract( gammaFarmV3Abi, gammaFarmV3);
            // console.log(userAddress, lpBalanceAfterWithdraw,v3Pool.poolId, 0)
            v3FarmContractInstance.methods.deposit(
                userAddress,
                lpBalanceAfterWithdraw,
                v3Pool.poolId, // to be changed to new pool id
                0, // to be changed to new lock time
            ).send({from: userAddress})
            .once('transactionHash', function (hash: any) {
                if (!!hash) {
                    
                    showTransLink(hash)
                    setShowTransModal(true)
                }
            }) 
            .once('confirmation', async function (confNumber: any, receipt: any) {
                let flags = transactionFlags;
                flags[0].status = 2;
                flags[1].status = 2;
                flags[2].status = 2;
                setTransactionFlags(() => ([...flags]));
                setShowTransactionModal(false)
                
                //setConfirmTransHistory(receipt.transactionHash, receipt.status)
                const type = receipt.status ? 'success' : 'failed'
                handleNotification(type, receipt.transactionHash)
                dispatch(setPortfolioLoading(true))
                dispatch(updateUserBalance(selector.ethData.address))
                dispatch(updateUserLendingData(selector.ethData.address))
                await reloadMigrationOnTrx();

            })
            .on('error', function (error: any) {
                console.log("error==>", error)
                setShowTransactionModal(false)
                handleNotification('failed')
            })
        }
    }

    const handleMigrate = async (poolObj: any) => {
        if (selector.ethData.ethWalletConnected  > 0) {
            const btcbAddress = "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c";
            const PLANETROUTERADDRESS = "0xCec274180eD52b041e4359DfC016aAc2642E82e8";
            const slippageValue = 0.5;
            const userAddress = selector.ethData.address
            try {

                // flag.status = 1 // 0 for process not started yet, 1 for loading, 2 for success, 3 for failed
                let flags: any = [
                    {actionHeading: "Withdraw from V2 Pool", status: 1},
                    {actionHeading: "Approve", status: 0},
                    {actionHeading: "Deposit in V3 Pool", status: 0}
                ];

                setTransactionFlags(() => ([...flags]));
                toggleTransactionModal();

                // console.log('poolObj.vaultBalanceUSD', poolObj.vaultBalanceUSD);
                if(poolObj.vaultBalanceUSD > 0.1 && poolObj.vaultBalance > 0) {
                    let res = await handleWithdrawLp(poolObj.poolId, poolObj.vaultBalance, userAddress, handleNotification, poolObj);
                    let lpBalance = await balanceOf(poolObj.wantAddress, userAddress);
                    poolObj.walletBalance = convertToEther(lpBalance, 18)
                    if(res== "success") {
                        //setConvertLPFlag(true);
                        let flags: any = transactionFlags;
                        flags[0].status = 2;
                        flags[1].status = 1;
                        setTransactionFlags(() => ([...flags]));
                        
                        //call allowance check function
                        const checkApproval = await handleApprove(poolObj);
                    }
                   
                } else {
                    //check token approval
                    flags[0].status = 2;
                    flags[1].status = 1;
                    setTransactionFlags(() => ([...flags]));
                    const checkApproval = await handleApprove(poolObj);
                }
                
            }
            catch (error) {
                console.log("error==>", error)
                //handleNotification('failed')
                let flags = transactionFlags;
                flags[0].status = 0;
                flags[1].status = 0;
                flags[2].status = 0;
                setTransactionFlags(() => ([...flags]));
                setShowTransactionModal(false)
                dispatch(setPortfolioLoading(true))
                dispatch(updateUserBalance(selector.ethData.address))
            }
            finally {
                setLoading(false)
            }
        }
    }

    const handleApprove = async (poolObj: any) => {
        try {
            let lpBalance = await balanceOf(poolObj.wantAddress, selector.ethData.address);
            const allowanceAmount:any = await allowance(poolObj.wantAddress, selector.ethData.address, migrateToV3Address);

            if(parseFloat(poolObj.walletBalance) > parseFloat(convertToEther(allowanceAmount, 18))){
                
                let res:any = await lpTokenApprove(poolObj.wantAddress, migrateToV3Address, selector.ethData.address);
              
                if(res == "Fail") {
                    setShowTransactionModal(false)
                    //handleNotification('failed')
                    return "error";
                }
                let flags: any = transactionFlags;
                flags[0].status = 2;
                flags[1].status = 2;
                flags[2].status = 1;
                setTransactionFlags(() => ([...flags]));
                let withdrawLP = await migrateToNewLP(poolObj);
            } else {
                
                let flags: any = transactionFlags;
                flags[0].status = 2;
                flags[1].status = 2;
                flags[2].status = 1;
                setTransactionFlags(() => ([...flags]));
                let withdrawLP = await migrateToNewLP(poolObj);
            }
        } catch(error) {
            console.log("error", error);
        }
    }

    const migrateToNewLP = async (poolObj: any) => {
        try {
            const routerContractInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
            //console.log('parseInt(withDrawnAmount.toString()', poolObj.wantAddress, poolObj.poolId==2? aquaAddress :  gammaAddress, bnbAddress , parseInt(poolObj.walletBalance.toString()), poolObj);
            let resRemoveLiquidity:any = await routerContractInstance.methods.quoteRemoveLiquidity(poolObj.wantAddress, poolObj.poolId == 2 ? aquaAddress : gammaAddress, bnbAddress, convertToWei(poolObj.walletBalance, 1e18)).call();
            //console.log('resRemoveLiquidity', resRemoveLiquidity);
            const migrationContractInstance: any = new wallet.web3.eth.Contract(migrateToV3Abi, migrateToV3Address);
            BigNumber.set({
                EXPONENTIAL_AT: [-10, 50],
            })
            //console.log('migrationContractInstance')
            //let withdrawAmount = parseInt(withDrawnAmount.toString()) * 0.95;
            let minWithdrawToken0 = BigNumber(resRemoveLiquidity[0]).multipliedBy(0.995).decimalPlaces(0).toString()//resRemoveLiquidity[0] * 0.95;
            let minWithdrawToken1 = BigNumber(resRemoveLiquidity[1]).multipliedBy(0.995).decimalPlaces(0).toString()//resRemoveLiquidity[1] * 0.95;
            let testNum = BigNumber("19359965993640149099859").multipliedBy(0.95).decimalPlaces(0).toString();
            //console.log(testNum,  (parseFloat(testNum).toFixed(0)))
            //address _pair, uint256 _withdrawAmount, address _desiredPair, bytes calldata _dataToken1, address _router, uint256 _lockTimeIndex, uint256 minWithdrawToken0, uint256 minWithdrawToken1

            let dataToken1 = await buildTxForSwapInMigrate(bnbAddress, BTCBAddress, minWithdrawToken1, slippageVal, selector.ethData.address, migrateToV3Address);
            let flags = transactionFlags;
            flags[0].status = 2;
            flags[1].status = 2;
            flags[2].status = 1;
            setTransactionFlags(() => ([...flags]));
            if(poolObj.poolId == 2 ) {
                let newWantAddress = "0x70b606c23d6e786be7accaf31c8feceaf846adf3";
                migrationContractInstance.methods.migrateAquaBnbToAquaBtcb(
                    poolObj.wantAddress,
                    convertToWei(poolObj.walletBalance, 1e18),
                    newWantAddress,
                    dataToken1.data,
                    PLANETROUTERADDRESS,
                    lock_time,
                    minWithdrawToken0,
                    minWithdrawToken1
                ).send({from: selector.ethData.address})
                .once('transactionHash', function (hash: any) {
                    if (!!hash) {
                        showTransLink(hash)
                        setShowTransModal(true)
                    }
                }) 
                .once('confirmation', async function (confNumber: any, receipt: any) {
                    flags = transactionFlags;
                    flags[0].status = 2;
                    flags[1].status = 2;
                    flags[2].status = 2;
                    setTransactionFlags(() => ([...flags]));
                    setShowTransactionModal(false);
                    //setConfirmTransHistory(receipt.transactionHash, receipt.status)
                    const type = receipt.status ? 'success' : 'failed'
                    handleNotification(type, receipt.transactionHash)
                    dispatch(setPortfolioLoading(true))
                    dispatch(updateUserBalance(selector.ethData.address))
                    dispatch(updateUserLendingData(selector.ethData.address))
                    await reloadMigrationOnTrx();
                })
                .on('error', function (error: any) {
                    console.log("error==>", error)
                    //handleNotification('failed')
                    setShowTransactionModal(false);
                })
            } else if(poolObj.poolId == 3) {
                
                let newWantAddress = "0x43d427df6957e180497248cadc30ce7aa716fe52";

                migrationContractInstance.methods.migrateGammaBnbToGammaBtcb(
                    poolObj.wantAddress,
                    convertToWei(poolObj.walletBalance, 1e18),
                    newWantAddress,
                    dataToken1.data,
                    PLANETROUTERADDRESS,
                    0,
                    minWithdrawToken0,
                    minWithdrawToken1
                ).send({from: selector.ethData.address})
                .once('transactionHash', function (hash: any) {
                    if (!!hash) {
                        //console.log('hash', hash);
                        showTransLink(hash)
                        setShowTransModal(true)
                    }
                }) 
                .once('confirmation', async function (confNumber: any, receipt: any) {
                    flags = transactionFlags;
                    flags[0].status = 2;
                    flags[1].status = 2;
                    flags[2].status = 2;
                    setTransactionFlags(() => ([...flags]));
                    
                    setShowTransactionModal(false);
                    //setConfirmTransHistory(receipt.transactionHash, receipt.status)
                    const type = receipt.status ? 'success' : 'failed'
                    handleNotification(type, receipt.transactionHash)
                    dispatch(setPortfolioLoading(true))
                    dispatch(updateUserBalance(selector.ethData.address))
                    dispatch(updateUserLendingData(selector.ethData.address))
                    await reloadMigrationOnTrx();
                    
                })
                .on('error', function (error: any) {
                    console.log("error==>", error)
                    //handleNotification('failed')
                    setShowTransactionModal(false);
                })
            }
        } catch(error){
            console.log(error)
        }
        
    }

    const renderTokenIcons = (tokenIcons: string, isBoostPool: boolean, protocol?: string) => {
        const tokenName = tokenIcons.split('-')
        if (tokenName.length === 1) {
            if(tokenName[0] == "GAMMA Infinity") {
                const icon = getTokenIcon("GAMMA");
                return (
                    <GTokenImgCont>
                        <img src={icon} alt="" />
                    </GTokenImgCont>
                )
            }
            else if (tokenName[0] == "AQUA Infinity")  {
                const icon = getTokenIcon("AQUA");
                return (
                    <GTokenImgCont>
                        <img src={icon} alt="" />
                    </GTokenImgCont>
                )
            }
            else {
                const icon = getTokenIcon(tokenIcons)
                return <OneImg className="migrationIcon" src={icon} alt="" />
            }
        }
        else if (tokenName.length === 2) {
            const icon1 = getTokenIcon(tokenName[0])
            let secondTokenName = tokenName[1].split(" ");
            const icon2 = getTokenIcon(secondTokenName[0])
            return (
                <TwoImgCont className="migrationIcons">
                    <img src={icon1} alt="" />
                    <img className="migration-second-img" src={icon2} alt="" />
                </TwoImgCont>
            )
        }
        else if (tokenName.length === 3) {
            const icon1 = getTokenIcon(tokenName[0])
            const icon2 = getTokenIcon(tokenName[1])
            const icon3 = getTokenIcon(tokenName[2])
            return (
                <TwoImgCont>
                    <img src={icon1} alt="" />
                    <img className="second-img" src={icon2} alt="" />
                    <img className='third-icon' src={icon3} alt="" />
                </TwoImgCont>
            )
        }
    }

    const setLockTimeForPool = async (time: any) => {
        set_lock_time(time)
    }

    const migrationData = () => {
        return migration_array.map((poolObject: any, index: number) => {
            if(poolObject.poolId === 2 || poolObject.poolId === 3 ){
                let name_arr = poolObject.name.split("-");
                poolObject.new_pool_name = name_arr[0] + "-BTCB"
            } else {
                // console.log("in else pool object", poolObject)
                poolObject.new_pool_name = poolObject.name;
            }
            poolObject.tvlInV3 = 0;
            poolObject.tvlInV3 = poolV3Array && poolV3Array.length > 0 && poolV3Array.find((pool: any) => { 
                if(pool.name.toLowerCase() == "gamma-btcb" && poolObject.poolId === 3){
                    
                    return pool.tvl
                }

                if(pool.name.toLowerCase() == "aqua-btcb" && poolObject.poolId === 2){
                     
                    return pool.tvl
                }

                if(thenaPoolIds.includes(poolObject.poolId)){
                     
                    return pool.tvl
                }

            })

            //console.log('poolObject.tvlInV3', poolObject.tvlInV3);
            
            if((poolObject.vaultBalanceUSD > 0.01 || poolObject.walletBalanceUSD > 0.01)) {
                return (
                    <FlexSBCont className='migrationBodyCont'>
                    <FlexCont style={{display: "grid", gridTemplateColumns: "9fr 1.04vw 1fr"}}>
                        <FlexSBCont className="migrationTableBody">
                            <FlexCont></FlexCont>
                            <FlexSBCont>{renderTokenIcons(poolObject.name, poolObject.isBoostPool, poolObject.protocol)}</FlexSBCont>
                            <FlexSBCont className='migration-pool-name'>
                                <CardText className='migrationText'>{poolObject.name}</CardText>
                                <CardSubText className="migration-pool-subtext">{commaFy(convertToKAndM(poolObject.tvl))}</CardSubText>
                            </FlexSBCont>
                            <FlexSBCont><img className="arrow-icon" src={RightIcon} alt="" /></FlexSBCont>
                            <FlexSBCont>{renderTokenIcons(poolObject.new_pool_name, poolObject.isBoostPool, poolObject.protocol)}</FlexSBCont>
                            <FlexSBCont className='migration-pool-name'>
                                <CardText className='migrationText'>{poolObject.new_pool_name}</CardText>
                                <CardSubText className="migration-pool-subtext">${commaFy(convertToKAndM((poolObject.tvlInV3? poolObject.tvlInV3.tvl : 0)))}</CardSubText>
                            </FlexSBCont>
                            <FlexSBCont className='migration-pool-name'>
                                <CardText className='migrationText'></CardText>
                            </FlexSBCont>
                            <FlexSBCont className='migration-pool-name'>
                                <CardText className='migrationText'>${poolObject.vaultBalanceUSD !== undefined && poolObject.vaultBalanceUSD > 0 && poolObject.vaultBalanceUSD > poolObject.walletBalanceUSD ? commaFy(convertToKAndM(poolObject.vaultBalanceUSD.toFixed(2))): poolObject.walletBalanceUSD > 0 ? commaFy(convertToKAndM(poolObject.walletBalanceUSD.toFixed(2))) : "0.00"}</CardText>
                            </FlexSBCont>
                            {/* <FlexSBCont className='migration-pool-name'>
                                <CardText className='migrationText'>${poolObject.vaultBalanceUSD !== undefined && poolObject.vaultBalanceUSD > 0 && poolObject.vaultBalanceUSD > poolObject.walletBalanceUSD ? commaFy(convertToKAndM(poolObject.vaultBalanceUSD.toFixed(2))): poolObject.walletBalanceUSD > 0 ? commaFy(convertToKAndM(poolObject.walletBalanceUSD.toFixed(2))) : "0.00"}</CardText>
                            </FlexSBCont> */}
                        </FlexSBCont>
                        <FlexSBCont></FlexSBCont>
                        <FlexSBCont style={{justifyContent: "end"}}>
                            <ConnectWalletBtn className="migrateButton" onClick={()=> thenaPoolIds.indexOf(poolObject.poolId) > -1? handleThenaMigrate(poolObject) :  handleMigrate(poolObject)} >
                                Migrate
                            </ConnectWalletBtn>
                        </FlexSBCont>
                    </FlexCont>
                    
                    { (poolObject.poolId === 2) &&
                        <FlexCont style={{display: "grid", gridTemplateColumns: "9fr 1.04vw 1fr"}}>
                            <FlexSBCont className="migrationApyLockSection">
                                <FlexCont></FlexCont>
                                <FlexCont></FlexCont>
                                <FlexCont></FlexCont>
                                <FlexCont></FlexCont>
                                <FlexCont></FlexCont>
                                <FlexSBCont className='migration-pool-name'>
                                    <CardText className='migrationLockTime'>Lock Time:</CardText>
                                </FlexSBCont>
                                <FlexSBCont className={lock_time === 0 ? 'migration-pool-name apyCont green' : 'migration-pool-name apyCont'} onClick={() => setLockTimeForPool(0)}>
                                    <CardText className={lock_time === 0 ?'migrationApy green': 'migrationApy'}>{'1x'} Apy</CardText>
                                    <CardSubText className="migration-pool-subtext">90 days</CardSubText>
                                </FlexSBCont>
                                <FlexSBCont className={lock_time === 1 ? 'migration-pool-name apyCont green' : 'migration-pool-name apyCont'} onClick={() => setLockTimeForPool(1)}>
                                    <CardText className={lock_time === 1 ?'migrationApy green': 'migrationApy'}>{'2x'} Apy</CardText>
                                    <CardSubText className="migration-pool-subtext">180 days</CardSubText>
                                </FlexSBCont>
                                <FlexSBCont className={lock_time === 2 ? 'migration-pool-name apyCont green' : 'migration-pool-name apyCont'} onClick={() => setLockTimeForPool(2)}>
                                    <CardText className={lock_time === 2 ?'migrationApy green': 'migrationApy'}>{'3x'} Apy</CardText>
                                    <CardSubText className="migration-pool-subtext">360 days</CardSubText>
                                </FlexSBCont>
                            </FlexSBCont>
                            <FlexSBCont></FlexSBCont>
                            <FlexSBCont></FlexSBCont>
                        </FlexCont>
                    }
                    { ( poolObject.poolId === 3) &&
                        <FlexCont style={{display: "grid", gridTemplateColumns: "9fr 1.04vw 1fr"}}>
                            <FlexSBCont className="migrationApyLockSection">
                                <FlexCont></FlexCont>
                                <FlexCont></FlexCont>
                                <FlexCont></FlexCont>
                                <FlexCont></FlexCont>
                                <FlexCont></FlexCont>
                                <FlexSBCont className='migration-pool-name'>
                                    <CardText className='migrationLockTime'>Lock Time:</CardText>
                                </FlexSBCont>
                                <FlexSBCont>
                                </FlexSBCont>
                                <FlexSBCont className={ 'migration-pool-name apyCont green' } >
                                    {/* <CardText className={'migrationApy green'}>{'1x'} Apy</CardText>
                                    <CardSubText className="migration-pool-subtext">180 days</CardSubText> */}
                                    <CardSubText className="migration-pool-subtext">180 days</CardSubText>
                                </FlexSBCont>
                                <FlexSBCont>
                                </FlexSBCont>
                            </FlexSBCont>
                            <FlexSBCont></FlexSBCont>
                            <FlexSBCont></FlexSBCont>
                        </FlexCont>
                    }
                </FlexSBCont>
                )
            }
            else {
             return <></>
            }
        })
    }

    const aquaMigrationData = () => {
        if(aquaInfnityVault.vaultBalance > 0){
            return (
                <FlexSBCont className='migrationBodyCont'>
                    <FlexCont style={{display: "grid", gridTemplateColumns: "9fr 1.04vw 1fr"}}>
                        <FlexSBCont className="migrationTableBody">
                            <FlexCont></FlexCont>
                            <FlexSBCont>
                                {renderTokenIcons('Aqua', false)}
                            </FlexSBCont>
                            <FlexSBCont className='migration-pool-name'>
                                <CardText className='migrationText'>{"Aqua"}</CardText>
                                <CardSubText className="migration-pool-subtext">
                                    ${commaFy(convertToKAndM(aquaInfnityVault.tvl && aquaInfnityVault.tvl))}
                                </CardSubText>
                            </FlexSBCont>
                            <FlexSBCont>
                                <img className="arrow-icon" src={RightIcon} alt="" />
                            </FlexSBCont>
    
                            <FlexSBCont>
                                {renderTokenIcons("Aqua", true)}
                            </FlexSBCont>
                            <FlexSBCont className='migration-pool-name'>
                                <CardText className='migrationText'>{'Aqua'}</CardText>
                                <AquaVotingPower className="shiny-blue">
                                    {'5X Voting Power '}
                                    <CardAquaText className="migration-pool-subtext">
                                        ${commaFy(convertToKAndM(aquaInfnityVault.tvl && aquaInfnityVault.tvl))}
                                    </CardAquaText>
                                </AquaVotingPower>
                            </FlexSBCont>
                            <FlexSBCont className='migration-pool-name'>
                                <CardText className='migrationText'>
                                </CardText>
                            </FlexSBCont>
                            <FlexSBCont className='migration-pool-name'>
                                    <CardText className='migrationText'>${aquaInfnityVault.deposited_bal_usd !== undefined && aquaInfnityVault.deposited_bal_usd > 0 ? commaFy(convertToKAndM(aquaInfnityVault.deposited_bal_usd.toFixed(2))): "0.00"}</CardText>
                            </FlexSBCont>
                            {/* <FlexSBCont className='migration-pool-name'>
                                <CardText className='migrationText'>${aquaInfnityVault.deposited_bal_usd !== undefined && aquaInfnityVault.deposited_bal_usd > 0 ? commaFy(convertToKAndM(aquaInfnityVault.deposited_bal_usd.toFixed(2))): "0.00"}</CardText>
                            </FlexSBCont> */}
                        </FlexSBCont>
                        <FlexSBCont></FlexSBCont>
                        <FlexSBCont style={{justifyContent: "end"}}>
                            <ConnectWalletBtn className="migrateButton" onClick={()=>handleAquaMigrate()} >
                                Migrate
                            </ConnectWalletBtn>
                        </FlexSBCont>
                    </FlexCont>
                    <FlexCont style={{display: "grid", gridTemplateColumns: "9fr 1.04vw 1fr"}}>
                        <FlexSBCont className="migrationApyLockSection">
                            <FlexCont></FlexCont>
                            <FlexCont></FlexCont>
                            <FlexCont></FlexCont>
                            <FlexCont></FlexCont>
                            <FlexCont></FlexCont>
                            <FlexSBCont className='migration-pool-name'>
                                <CardText className='migrationLockTime'>Lock Time:</CardText>
                            </FlexSBCont>
                            <FlexSBCont>
                            </FlexSBCont>
                            <FlexSBCont className={ 'migration-pool-name apyCont green' } >
                                <CardSubText className="migration-pool-subtext">180 days</CardSubText>
                            </FlexSBCont>
                            <FlexSBCont>
                            </FlexSBCont>
                        </FlexSBCont>
                        <FlexSBCont></FlexSBCont>
                        <FlexSBCont></FlexSBCont>
                    </FlexCont>
                </FlexSBCont>
            )
        } else {
            return <></>
        }
    }

    const aquaMigrationDataMob = () => {
        if(aquaInfnityVault.vaultBalance > 0){
            return (
                <FlexSBCont className='migrationBodyCont'>
                    <FlexCont style={{display: "block"}}>
                        <FlexSBCont className='migrationTableBody'>
                            <FlexCont style={{
                                display: "grid", alignItems: "center",
                                gridTemplateColumns: "10% 32% 10% 10% 40%"
                            }}>
                                <FlexSBCont>
                                    {renderTokenIcons('Aqua', false)}
                                </FlexSBCont>
                                <FlexSBCont className='migration-pool-name'>
                                    <CardText className='migrationText'>
                                        {'Aqua'}
                                    </CardText>
                                    <CardSubText className="migration-pool-subtext">
                                        ${commaFy(convertToKAndM(aquaInfnityVault.tvl && aquaInfnityVault.tvl))}
                                    </CardSubText>
                                </FlexSBCont>
                                <FlexSBCont>
                                    <img className="arrow-icon" src={RightIcon} alt="" />
                                </FlexSBCont>
                                <FlexSBCont>
                                    {renderTokenIcons("Aqua", true)}
                                </FlexSBCont>
                                <FlexSBCont className='migration-pool-name'>
                                    <CardText className='migrationText'>
                                        {'Aqua'}
                                    </CardText>
                                    <AquaVotingPower className="shiny-blue">
                                        {'5X Voting Power '}
                                        <CardAquaText className="migration-pool-subtext">
                                            ${commaFy(convertToKAndM(aquaInfnityVault.tvl && aquaInfnityVault.tvl))}
                                        </CardAquaText>
                                    </AquaVotingPower>
                                </FlexSBCont>
                                <FlexCont className="vesting-details-cont">
                                </FlexCont>
                            </FlexCont>

                            <FlexSBCont className='migration-pool-name'>
                                <CardText className='migrationText'>
                                    
                                </CardText>
                            </FlexSBCont>

                            <FlexSBCont className='migration-details' style={{
                                    display: "grid", alignItems: "center",
                                    gridTemplateColumns: "20% 50%"
                                }}>
                                <DataInfoTxt light className="vesting-details-text" style={{
                                    paddingLeft: "10px"
                                }}>In V2:</DataInfoTxt>
                                <DataInfoTxt className="migrate-details-text">
                                    ${aquaInfnityVault.deposited_bal_usd !== undefined && aquaInfnityVault.deposited_bal_usd > 0 ? commaFy(convertToKAndM(aquaInfnityVault.deposited_bal_usd.toFixed(2))): "0.00"}
                                </DataInfoTxt>
                            </FlexSBCont>
                           
                            <FlexSBCont className='migration-details' style={{
                                display: "grid", alignItems: "center",
                                gridTemplateColumns: "50%"
                            }}>
                                <DataInfoTxt light className="migrate-details-text" style={{
                                    paddingLeft: "10px"
                                }}>
                                    Lock Time:
                                </DataInfoTxt>
                                <DataInfoTxt light className="migrate-details-text"></DataInfoTxt>
                            </FlexSBCont>
                            <FlexSBCont className='migration-details-apy-cont' style={{marginLeft: "40%" }}>
                                <FlexCont style={{display: "flex", columnGap: "5px", paddingLeft: "10px"}}>
                                    <FlexSBCont className={'migration-pool-name apyCont green'}>
                                        {/* <CardText className={'migrationApy green'}>1x Apy</CardText>
                                        <CardSubText className="migration-pool-subtext">180 days</CardSubText> */}
                                        <CardText className={'migrationApy green'}>180 days</CardText>
                                    </FlexSBCont>
                                </FlexCont>
                            </FlexSBCont>
                            <FlexSBCont className='migration-details'>
                                <ConnectWalletBtn  style={{ marginLeft: "10px"}} className="migrateButton" onClick={()=>handleAquaMigrate()}>Migrate</ConnectWalletBtn>
                                <DataInfoTxt light className="migrate-details-text"></DataInfoTxt>
                            </FlexSBCont>
                        </FlexSBCont>
                    </FlexCont>
                </FlexSBCont>
            )
        } else {
            return <></>
        }

    }

    const migrationDataMob = () => {
        return migration_array.map((poolObject: any, index: number) => {
            
            if(poolObject.poolId === 2 || poolObject.poolId === 3 ){
                let name_arr = poolObject.name.split("-");
                poolObject.new_pool_name = name_arr[0] + "-BTCB"
            } else {
                poolObject.new_pool_name = poolObject.name;
            }

            poolObject.tvlInV3 = 0;
            poolObject.tvlInV3 = poolV3Array && poolV3Array.length > 0 && poolV3Array.find((pool: any) => { 
                if(pool.name.toLowerCase() == "gamma-btcb" && poolObject.poolId === 3){
                    
                    return pool.tvl
                }

                if(pool.name.toLowerCase() == "aqua-btcb" && poolObject.poolId === 2){
                     
                    return pool.tvl
                }

                if(thenaPoolIds.includes(poolObject.poolId)){
                     
                    return pool.tvl
                }

            })

            if(poolObject.vaultBalance > 0 || poolObject.walletBalanceUSD > 0.01) {

                return (
                    <FlexSBCont className='migrationBodyCont'>
                        <FlexCont style={{display: "block"}}>
                            <FlexSBCont className='migrationTableBody'>
                                <FlexCont style={{
                                    display: "grid", alignItems: "center",
                                    gridTemplateColumns: "10% 32% 10% 10% 40%"
                                    }}>
                                    <FlexSBCont>
                                        {renderTokenIcons(poolObject.name, poolObject.isBoostPool, poolObject.protocol)}
                                    </FlexSBCont>
                                    <FlexSBCont className='migration-pool-name'>
                                        <CardText className='migrationText'>{poolObject.name}</CardText>
                                        <CardSubText className="migration-pool-subtext">{commaFy(convertToKAndM(poolObject.tvl))}</CardSubText>
                                    </FlexSBCont>
                                    <FlexSBCont>
                                        <img className="arrow-icon" src={RightIcon} alt="" />
                                    </FlexSBCont>
                                    <FlexSBCont>
                                        {renderTokenIcons(poolObject.new_pool_name, poolObject.isBoostPool, poolObject.protocol)}
                                    </FlexSBCont>
                                    <FlexSBCont className='migration-pool-name'>
                                        <CardText className='migrationText'>{poolObject.new_pool_name}</CardText>
                                        <CardSubText className="migration-pool-subtext">${commaFy(convertToKAndM((poolObject.tvlInV3? poolObject.tvlInV3.tvl : 0)))}</CardSubText>
                                    </FlexSBCont>
                                    <FlexCont className="vesting-details-cont">
                                    </FlexCont>
                                </FlexCont>
                                <FlexSBCont className='migration-details'>
                                    <DataInfoTxt light className="vesting-details-text"></DataInfoTxt>
                                    <DataInfoTxt className="migrate-details-text"></DataInfoTxt>
                                </FlexSBCont>
                                <FlexSBCont className='migration-details' style={{
                                    display: "grid", alignItems: "center",
                                    gridTemplateColumns: "20% 50%"
                                }}>
                                    <DataInfoTxt light className="vesting-details-text" style={{
                                        paddingLeft: "10px"
                                        }}>
                                            In V2:
                                        </DataInfoTxt>
                                    <DataInfoTxt className="migrate-details-text">${poolObject.vaultBalanceUSD !== undefined && poolObject.vaultBalanceUSD > 0 ? commaFy(convertToKAndM(poolObject.vaultBalanceUSD.toFixed(2))): "0.00"}</DataInfoTxt>
                                </FlexSBCont>
                                { (poolObject.poolId === 2) &&
                                <FlexSBCont className='migration-details'>
                                    <DataInfoTxt light className="migrate-details-text" style={{
                                        paddingLeft: "10px"
                                        }}>
                                        Lock Time:
                                    </DataInfoTxt>
                                    <DataInfoTxt light className="migrate-details-text"></DataInfoTxt>
                                </FlexSBCont>
                                }
                                { (poolObject.poolId === 2) &&
                                <FlexSBCont className='migration-details-apy-cont' style={{marginLeft: "40%" }}>
                                    { ( (poolObject.poolId == 2 ) || (thenaPoolIds.indexOf(poolObject.poolId)) > -1) &&
                                    <FlexCont style={{display: "flex", columnGap: "5px", paddingLeft: "10px"}}>
                                        <FlexSBCont className={lock_time === 0 ? 'migration-pool-name apyCont green' : 'migration-pool-name apyCont'} onClick={() => setLockTimeForPool(0)}>
                                            <CardText className={lock_time === 0 ?'migrationApy green': 'migrationApy'}>{'1x'} Apy</CardText>
                                            <CardSubText className="migration-pool-subtext">90 days</CardSubText>
                                        </FlexSBCont>
                                        <FlexSBCont className={lock_time === 1 ? 'migration-pool-name apyCont green' : 'migration-pool-name apyCont'} onClick={() => setLockTimeForPool(1)}>
                                            <CardText className={lock_time === 1 ?'migrationApy green': 'migrationApy'}>{'2x'} Apy</CardText>
                                            <CardSubText className="migration-pool-subtext">180 days</CardSubText>
                                        </FlexSBCont>
                                        <FlexSBCont className={lock_time === 2 ? 'migration-pool-name apyCont green' : 'migration-pool-name apyCont'} onClick={() => setLockTimeForPool(2)}>
                                            <CardText className={lock_time === 2 ?'migrationApy green': 'migrationApy'}>{'3x'} Apy</CardText>
                                            <CardSubText className="migration-pool-subtext">360 days</CardSubText>
                                        </FlexSBCont>
                                    </FlexCont>
                                    }
                                    { (poolObject.poolId == 3 ) &&
                                        <FlexCont style={{display: "flex", columnGap: "5px", paddingLeft: "10px"}}>
                                            <FlexSBCont className={ 'migration-pool-name apyCont green' }>
                                                <CardText className={'migrationApy green'}>180 days</CardText>
                                            </FlexSBCont>
                                        </FlexCont>
                                        
                                    }
                                </FlexSBCont>
                                }
                                <FlexSBCont className='migration-details'>
                                    <ConnectWalletBtn style={{ marginLeft: "10px"}} className="migrateButton" onClick={()=> thenaPoolIds.indexOf(poolObject.poolId) > -1? handleThenaMigrate(poolObject) :  handleMigrate(poolObject)} >Migrate</ConnectWalletBtn>
                                    <DataInfoTxt light className="migrate-details-text"></DataInfoTxt>
                                </FlexSBCont>

                                
                            </FlexSBCont>
                            
                        </FlexCont>
                    </FlexSBCont>
                )
            }
            else {
                return <></>
            }
        })
    }

    return (
        <MainContainer className='vesting'>
            <BaseContainer>
                <BaseHeader>
                    <div>
                        <TitleText className='vesting'>Migrate</TitleText>
                        <TitleInfo className="sub-title">Migrate to Planet V3</TitleInfo>
                    </div>

                </BaseHeader>
                <CardText className='vestingText'>{ isConnected ? "Your Pools": "Connect Your Wallet To See Your Pools"}</CardText>
                { isConnected ?
                <FlexCont style={{display: "block"}}>
                    <FlexCont style={{display: "grid", gridTemplateColumns: "9fr 1.04vw 1fr"}}>
                        <OverViewInfo className="migrationTableHeader">
                            <CardText></CardText>
                            <CardText></CardText>
                            <CardText></CardText>
                            <CardText></CardText>
                            <CardText></CardText>
                            <CardText className='migration-title'>Token</CardText>
                            <CardText className='migration-title'></CardText>
                            <CardText className='migration-title'>In V2</CardText>
                            {/* <CardText className='migration-title'>Receive in V3</CardText> */}
                        </OverViewInfo>
                        <FlexCont></FlexCont>
                        <FlexCont></FlexCont>
                    </FlexCont>
                </FlexCont>
                :
                <ConnectWalletBtn className="migrateButton" style={{marginTop: "20px"}} onClick={() => onOpen()}>Connect your wallet</ConnectWalletBtn>
                }
                { isConnected &&
                <FlexCont>
                    <OverViewInfo className="migrationTableBody">
                    {aquaMigrationData()}
                    {migrationData()}
                    </OverViewInfo>
                    
                    <OverViewInfo className="migrationTableBodyMob">
                    {aquaMigrationDataMob()}
                    {migrationDataMob()}
                    </OverViewInfo>
                </FlexCont>
                }
                <CustomModal show={openWalletList} toggleModal={setOpenWalletList}>
                    <WalletList setOpenModal={setOpenWalletList} />
                </CustomModal>
            </BaseContainer>
            <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
                {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
            </CustomModal>
            <CustomModal show={showTransactionModal} toggleModal={toggleTransactionModal} >
                { showTransactionModal && transactionFlags && <TransactionConfirmationLoader flags={transactionFlags}  setShowConfModal={setShowTransactionModal}  heading={'Migrate'} />}
            </CustomModal>
        </MainContainer>
    );
};

const mapStateToProps = (state: any) => ({
    portfolioLoading: state.user.portfolioLoading,
    userPortfolio: state.user.userPortfolio,
    platformLoading: state.user.platformLoading,
    platformData: state.user.platformData,
})
export default connect(mapStateToProps)(Migration)