import { useState, useEffect } from 'react'
import { CardSubText } from 'shared/styles/globalText'
import {   GTokenWeb, GTokenMob, GCardText, GCardNumTxt, GLoadingText3 } from './style'
import TvlIcon from 'assets/icons/tvl-icon.svg'
import { commaFy, checkZeroVal, convertToKAndM } from 'service/globalFunctions'
let timerForGammaBalanceUsd = setInterval(function () { }, 0)
const GammaBalanceUsd = (props: any) => {
    const { gammaInfData, infVaultLoading } = props
    const [gammaBalance, setgammaBalance] = useState('')
    const [gammaBalanceUsd, setgammaBalanceUsd ] = useState('')

    useEffect(() => {
        if (timerForGammaBalanceUsd) {
            clearInterval(timerForGammaBalanceUsd); 
        }
        if(gammaInfData.deposited_bal)
        {
            startTimerTripping();
        }
        return () => clearInterval(timerForGammaBalanceUsd);
    }, [gammaInfData])

    const startTimerTripping = () => {
        if(gammaInfData.deposited_bal)
        {
            //console.log(gammaInfData)
            setgammaBalanceUsd(gammaInfData.deposited_bal_usd);
            clearInterval(timerForGammaBalanceUsd);
            // let trippingGammaBalance: any = gammaBalanceData / (84600 * 365);
            // trippingGammaBalance = 1;
            let apy = gammaInfData.apy;
            let m: any = 365
            let a: any = apy / 100 + 1
            let apr = (Math.pow(a, 1 / m) - 1) * m
            let trippingGammaBalanceUsd:any = parseFloat(gammaInfData.deposited_bal_usd) * (apr) / (365 * 86400);
            //console.log('trippingGammaBalanceUsd', trippingGammaBalanceUsd)
            let now = new Date();
            let timeWhenStarted = now.getTime();
            timerForGammaBalanceUsd = setInterval(function () {
                now = new Date();
                let newGammaBalanceUsd = gammaInfData.deposited_bal_usd + (( now.getTime() - timeWhenStarted ) * parseFloat(trippingGammaBalanceUsd) / 1000);
                setgammaBalanceUsd(newGammaBalanceUsd)
            }, 1000)
        }
    }
    return (
        <div>
            <GCardText>USD Value</GCardText>
            {infVaultLoading ? <GLoadingText3 /> :
                <GCardNumTxt isValZero={checkZeroVal(gammaInfData.deposited_bal_usd)}>${!!gammaBalanceUsd && gammaBalanceUsd ? commaFy(parseFloat(gammaBalanceUsd).toFixed(2)) : '0.00'}</GCardNumTxt>
            }
        </div>
    );
};

export default GammaBalanceUsd;