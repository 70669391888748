import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { PlanetCard } from 'shared/styles/styled'
import { CardText, CardBText, SliderCont } from '../style'

export const TokenStatsCont = styled(PlanetCard)`
   border: none;
  .asset-icon{
        width: 30px;
        height: 30px;
        margin-right: 10px;
        @media (min-width: ${screenSizes.mediaXLL}px) {
          width: 40px;
          height: 40px;
          margin-right: 12px;
        }
        @media (min-width: ${screenSizes.mediaXXL}px) {
          width: 50px;
          height: 50px;
          margin-right: 15px;
        }
    }
  .info-icon{
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .rate-info{
      margin: 30px 0 20px 0;
  }
  .loading-text{
    margin-top: 10px;
  }
`
export const BackBtnCont = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 22px;
`
export const BackText = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: 0.4;
  cursor: pointer;
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
`
export const RateText = styled(CardBText) <any>`
color: ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
`
export const StatsInfoCont = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  align-items: center;
  margin-top: 50px;
  padding-right: 10px;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    padding-right: 20px;
  }
`
export const StatsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-wrap:wrap;
  .info-space{
      margin-top: 10px;
  }
`
export const AssetCard = styled.div`
  border: 1px solid ${colors.borderColor};
  border-radius: 20px;
  box-sizing: border-box;
  padding: 30px;
`
export const SwitchCont = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  text-align: center;
  border-bottom: none;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-template-columns: repeat(2,minmax(auto,192px)) 1fr;
    border-bottom: 1px solid ${colors.borderColor};
  }
  .on-web{
    display: none;
    cursor: initial;
    @media (min-width: ${screenSizes.mediaM}px) {
      display: block;
    }
  }
`
export const SwitchBox = styled.div<any>`
  border-bottom: ${(props: any) => props.isActive ? `5px solid ${colors.lightGreen}` : `5px solid ${colors.borderColor}`};
  padding-bottom: 20px;
  cursor: ${(props: any) => props.isActive ? 'initial' : 'pointer'};
  margin-bottom: 0;

  @media (min-width: ${screenSizes.mediaM}px) {
    margin-bottom: ${(props: any) => props.isActive ? '-5px' : '0'};
    padding-bottom: ${(props: any) => props.isActive ? '17px' : '19px'};
    border-bottom: ${(props: any) => props.isActive ? `5px solid ${colors.lightGreen}` : `none`};
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    margin-bottom: ${(props: any) => props.isActive ? '-3px' : '0'};
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    margin-bottom: ${(props: any) => props.isActive ? '-2px' : '0'};
  }
`
export const SwitchText = styled(CardText) <any>`
  color: ${(props: any) => props.isActive ? colors.lightGreen : colors.white};
  opacity: ${(props: any) => props.isActive ? 1 : 0.4};
`
export const AssetCardCont = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin-top: 20px;
  align-items: start;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    margin-top: 40px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    margin-top: 60px;
  }
`
export const AssetSliderCont = styled(SliderCont)`
  margin: 0;
`
export const AssetDetails = styled.div`
  margin-top: 30px;
`

export const InfoDivider = styled.div`
  background: ${colors.borderColor};
  height: 1px;
  width: 100%;
  margin: 20px 0;
`
export const GraphCont = styled.div`
  border: 0;
  margin-top: 45px;
`
export const GraphChartCont = styled.div`
  position: relative;
  padding: 50px 0 20px 0;
  box-sizing: border-box;
  background: ${colors.themeBackground};
  zoom: 110%;
  min-height: 200px;
`
/* ================================== rechart custom tooltip ======================= */

export const ToolTipCont = styled.div`
  padding: 10px;
  background: #1E2442;
  border-radius: 10px;
`

export const ToolTipLabel = styled.h1`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
`
export const ToolTipDataTxt = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 5px 0 0;
`

export const CustomTooltip = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    .tooltip-body {
        width: 100%;
        position: relative;
        background: transparent;
        border: 1px solid ${colors.borderColor};
        text-align: center;
        padding: 1px 3px;
        border-radius: 2px;

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            width: 0;
            height: 0;
            border: 10px solid transparent;
            margin-left: -10px;
            bottom: 0;
            border-top-color: ${colors.borderColor};
            border-bottom: 0;
            margin-bottom: -10px;
        }

        p {
            font-size: 0.75em;
            color: ${colors.white};
            margin: 0;
            align-self: center;
        }
    }
`