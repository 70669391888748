import styled, { keyframes } from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { PlanetCard, NormalCard, PrimaryBtn, shimmer, NormalBtn } from 'shared/styles/styled'
import { Button } from 'shared/button'

export const FlexSBCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const FlexCont = styled.div`
  display: flex;
  align-items: center;
  &.limitPopUp{
    cursor: pointer;
  }
`
export const InfoText = styled.p<any>`
  font-size: 20px;
  line-height: 25px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  opacity: ${(props: any) => props.light ? 0.2 : 1};
  margin:0;
`
export const InfoSmallText = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin:0;
`
export const ErrorText = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: ${colors.red};
  font-family: MontserratSemiBold;
  margin: 0 0 10px 0;
  text-align: center;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 14px;
    line-height: 17px;
    margin: 0 0 16px 0;
  }
`

/* ================= Table Style ================== */

export const TableCont = styled.div`
  .align-left{
    text-align:left;
  }
  .market-size {
    display: block;
    @media (min-width: ${screenSizes.mediaM}px) {
      display: block;
    }
  }
  .hide-cell{
    display: none;
    @media (min-width: ${screenSizes.mediaM}px) {
      display: block;
    }
  }
  .table-data{
    cursor:pointer;
    &:hover{
      //background: #050505;
      background: #141414;
      border-left: 4px solid ${colors.lightGreen};
      box-shadow: 0px 4px 31px rgb(48 140 218 / 30%);
    }
  }
  .header-row{
    border-left: 0;
  }
  h2{
    color: white;
    padding-left: 20px;
  }
`
export const TableCard = styled(PlanetCard)`
  padding: 10px 0 15px 0;
  margin-bottom: 15px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-bottom: 30px;
    padding: 30px 0 15px 0;
  }
  &.supplyTable{
    padding: 0;
    position: relative;
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    &.supplyTable{
      display:none;
    }
  }
  @media (max-width: 1000px) {
    &.supplyTable{
      
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    div.supplyMarketDiv{
      display: flex;
    }
    
    &.supplyMarket{
      float: left;
      font-family: MontserratBold;
    }
    img.marketIcon{
      float: left;
      height: 18px;
      width:18px;
    }
    div.marketBtn{
      right: 1.5625vw;
      align-content: flex-end;
      float: right;
      position: absolute;
      border: 1px solid #FFFFFF26;
      border-radius: 30px;
      padding: 0.625vw 1.04vw;
    }
    div.LevelBtn{
      right: 30px;
      align-content: flex-end;
      float: right;
      position: absolute;
      top: 30px;
      border-radius: 20px;
    }
    div.LevelBtn:hover{
      background: #141414;
      cursor: pointer;
    }
    div.marketBtn:hover{
      background: #141414;
      cursor: pointer;
    }
    h2.supplyMarket{
      font-family: MontserratBold;
      font-size: 1.5625vw;
      line-height: 1.927vw;
      text-shadow: 0 0 1.04vw #55FE76;
      padding: 0;
      margin: 0;
    }
    h2.borrowMarket{
      text-shadow: 0 0 0.78125vw #55FE76;
      font-family: MontserratBold;
      font-size: 1.5625vw;
      line-height: 1.927vw;
      padding: 0;
      margin: 0;
    }
    img.downGreyIcon{
      float:left;
      height: 14px;
      width: 9px;
      margin-left: 10px;
      position: relative;
      top: 50%;
      transform: translateY(25%);
    }
  }
  @media (max-width: ${screenSizes.mediaL}px) {
    div.supplyMarketDiv{
      display:none
    }
  }

  div.supplyMarketDiv{
    padding: 1.5625vw;
  }

`
export const TableTitleCont = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 15px;
  @media (min-width: ${screenSizes.mediaM}px) {
    display: flex;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    grid-template-columns: 1.1fr repeat(3,1fr);
    padding: 0 20px;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    padding: 0 26px;
  }
`
export const TableTitle = styled.h1<any>`
  display: none;
  font-size: 30px;
  line-height: 38px;
  color: ${colors.white};
  font-family: MontserratBold;
  text-shadow: ${(props: any) => props.type === 'supply' ? '0px 0px 20px #55FE76' : '0px 0px 15px #43B0FF'};
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    display: block;
  }
`
export const LevelCont = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.borderColor};
  padding: 2px;
  box-sizing: border-box;
  border-radius: 20px;
  background: ${colors.themeBackground};
`

export const MarketBtn = styled.div`
  @media (min-width: ${screenSizes.mediaM}px) {
    &.showMarketBtn{
      float: left;
      font-size: 0.9375vw;
      line-height: 1.145vw;
      width: auto;
      border-radius: 20px;
      margin-left: 10px;
      background: transparent;
      color: #FFFFFF;
      box-shadow: 0px 0px 10px #00000029;
      font-family: MontserratBold;
    }
   
    &.marketSymbol{
      opacity:0.4;
      color: #FFFFFF;
    }
  }
  &.showMarketBtnMob{
    width: 100%;
    height: 25px;
    color: white;
    margin-left: 40px;
    font-size: 16px;
  }
  img.downGreyIcon{
    float:left;
    height: 14px;
    width: 9px;
    margin-left: 5px;
  }
  &.showMarketBtnMobText{
    font-size: 14px;
    font-family: MontserratBold;
    color: #FFFFFF;
    margin-left: 15px;
  }
`
export const MarketButton = styled.div`
  div.marketBtn{
    right: 30px;
    align-content: flex-end;
    float: right;
    position: absolute;
    top: 20px;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    padding: 10px 15px 10px 15px;
  }
  &.MarketButtonMob{
    
  }
  div.marketButton{
    align-content: flex-end;
    float: right;
    top: 20px;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    padding: 10px 15px 10px 15px;
  }
  img.marketIcon{
    float: left;
  }
  img.downGreyIcon{
    float:left;
    height: 14px;
    width: 9px;
  }
  img.mobileMarketBtn{
    height:100%;
    width:100%;
  }
  img.mobileMarketBtnImg{
    height: 16px;
    width: 16px;
  }
  div.MarketButtonDiv{
    margin-top: 30px;
    display: flex;
    border-radius: 30px;
    padding: 7px 15px 7px 15px;
    border: 1px solid #FFFFFF26;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000033;
  }
  img.RightIcon{
    height: 11px;
    width: 7px;
    margin-left: 15px;
  }
`

export const LevelTxt = styled.div<any>`
  background: ${(props: any) => props.isActive ? 'rgba(85,255,118, 0.2)' : "transparent"};
  font-size: 12px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  color: ${(props: any) => props.isActive ? colors.lightGreen : colors.white};
  font-family: MontserratBold;
  border-radius: 20px;
  cursor: ${(props: any) => props.isActive ? "initial" : "pointer"};
  opacity: ${(props: any) => props.isActive ? 1 : 0.2};
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 14px;
    padding: 8px 24px;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    padding: 10px 30px;
  }
`
export const TableRow = styled.div`
  display:grid;
  grid-template-columns: 1.2fr repeat(2,1fr);
  align-items:center;
  padding: 10px 20px 10px 20px;
  grid-gap: 5px;
  border-left: 4px solid transparent;
  text-align: right;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 15px 10px 16px 10px;
    grid-template-columns: 1.5fr repeat(3,1fr);
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    grid-template-columns: 1.2fr repeat(3,1fr);
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    grid-template-columns: 1.1fr repeat(3,1fr);
    padding: 15px 20px 16px 20px;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    padding: 15px 37px 16px 26px;
  }
`
export const ActiveTableRow = styled.div<any>`
  display:grid;
  border-left: 4px solid transparent;
  text-align: right;
  grid-template-columns: 1.5fr 1fr 1.2fr 1fr;
  align-items: center;
  padding: 20px 20px 10px 16px;
  grid-gap: 5px;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-template-columns: 1fr 1fr 1.3fr 1fr;
    padding: 1.5625vw 0;
    &.borrow-header-row{
      grid-template-columns: 1.3fr 1fr 1.3fr 1fr;
    }
    &.borrow-data-row{
      grid-template-columns: 1.3fr 1fr 1.3fr 1fr;
    }
  }
  @media (min-width:1150px) and (max-width: 1350px) {
    grid-template-columns: 1.1fr 1fr 1fr 1fr !important;
    padding: 15px 30px 16px 30px !important;
    &.borrow-header-row{
      grid-template-columns: 1.5fr 1fr 1fr 1fr !important;
    }
    &.borrow-data-row{
      grid-template-columns: 1.5fr 1fr 1fr 1fr !important;
    }
    &.gammaRow{
      grid-template-columns: 1fr 1.25fr 1.3fr 1fr !important;
    }
    &.supplyTable{
      grid-template-columns: 1.3fr 1fr 1.3fr 1fr !important;
    }
  }
  @media (min-width:1000px) and (max-width: 1149px) {
    grid-template-columns: 1.1fr 1fr 1fr 1fr !important;
    padding: 15px 30px 16px 30px !important;
    &.borrow-header-row{
      grid-template-columns: 1.3fr 1fr 1fr 1fr !important;
    }
    &.borrow-data-row{
      grid-template-columns: 1.3fr 1fr 1fr 1fr !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    &.supplyTable{
      grid-template-columns: 1.4fr 1fr 1.3fr 1fr !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    grid-template-columns:1fr 1fr 1.3fr 1fr;//1.1fr repeat(3,1fr);
    padding: 15px 20px 16px 20px;
    &.borrow-header-row{
      grid-template-columns: 1.2fr 1fr 1.3fr 1.1fr !important;
    }
    &.borrow-data-row{
      grid-template-columns: 1.2fr 1fr 1.3fr 1.1fr !important;
    }
    &.supplyTable{
      grid-template-columns: 1.3fr 1fr 1.3fr 1fr !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    padding: 15px 37px 16px 26px;
    &.supplyTable{
      grid-template-columns: 1.3fr 1fr 1.3fr 1fr !important;
    }
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    grid-template-columns: 1.2fr 1.0fr 1.2fr 1fr;
  }

  &.supplyTable{
    padding: 1.5625vw;
  }

  &.borrow-header-row{
    padding: 1.5625vw 1.5625vw 0 1.5625vw;
  }

  &.header-row.supplyTable{
    padding: 1.5625vw 1.5625vw 0 1.5625vw !important;
  }

  &.borrow-data-row{
    padding: 1.5625vw;
  }
`
export const TableText = styled.p<any>`
  font-size: 11px;
  line-height: 15px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  opacity : ${(props: any) => props.light ? 0.4 : 1};
  margin: 0;
  @media (min-width: ${screenSizes.mediaXS}px) {
    font-size: 12px;
    line-height: 16px;
    &.lendingSupply{
      font-size: 12px;
    line-height: 16px;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    padding-left: 10px;
    &.lendingSupply{
      font-size: 1.04vw;
      line-height: 1.25vw;
    }
    &.collateral{
      text-align: right !important;
      font-size: 16px !important;
    }
    &.suppliedToken{
      line-height: 25px;
      font-family: MontserratBold;
    }
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    &.collateral{
      text-align: right !important;
    }
  }
  @media (min-width: 420px) {
    font-size: 14px;
    line-height: 18px;
    &.lendingSupply{
      font-size: 14px;
      line-height: 18px;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 16px;
    line-height: 20px;
    &.lendingSupply{
      font-size: 16px;
      line-height: 20px;
    }
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 16px;
    line-height: 20px;
    &.lendingSupply{
      font-size: 16px;
      line-height: 20px;
    }
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    font-size: 20px;
    line-height: 25px;
    &.lendingSupply{
      font-size: 20px;
      line-height: 25px;
    }
  }
  &.lendingSupply{
    text-align: left;
  }
  &.dataInGrey{
    opacity: 0.4;
  }
  &.suppliedAmountText{
    font-size: 14px !important;
    font-family: MontserratBold;
  }
`
export const TableSmText = styled.p`
  font-size: 10px;
  line-height: 13px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  opacity : 0.4;
  margin: 3px 0 0 0;
  @media (min-width: ${screenSizes.mediaXS}px) {
    font-size: 12px;
    line-height: 15px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 14px;
    line-height: 17px;
    &.suppliedUsd{
      font-size: 13px;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 15px;
    line-height: 18px;
    &.suppliedUsd{
      font-size: 15px;
    }
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    font-size: 16px;
    line-height: 19px;
    &.suppliedUsd{
      font-size: 16px;
    }
  }
`

export const ApyText = styled(TableText) <any>`
  color: ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
  &.apyCapsule{
    color: ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
    padding: 7px 15px 7px 15px;
    text-align: center;
    border-radius: 30px;
    box-shadow: 0px 0px 10px #00000029;
    background: ${(props: any) => props.isNegative ? 'rgba(255, 85, 85, 0.1) 0% 0% no-repeat padding-box' : 'transparent linear-gradient(90deg, rgba(85, 255, 118,0.1) 0%, rgba(39, 172, 64,0.1) 100%) 0% 0% no-repeat padding-box'};
    font-family: MontserratSemiBold;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    &.apyCapsule{
      color:  ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
      padding: 15px 30px 15px 30px;
      text-align: center;
      border-radius: 30px;
      width: 4vw;
      align-items: flex-end;
      float: right;
      margin: 0px;
      box-shadow: 0px 0px 10px #00000029;
      background: props.isNegative ? 'rgba(255, 85, 85, 0.1) 0% 0% no-repeat padding-box' : 'transparent linear-gradient(90deg, rgba(85, 255, 118,0.1) 0%, rgba(39, 172, 64,0.1) 100%) 0% 0% no-repeat padding-box' };
      font-family: MontserratSemiBold;
    }
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    &.apyCapsule{
      max-width: 67px;
      width: fit-content;
      float: right;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    &.mobPopup{
      max-width: 16vw;
      width: 16vw;
      margin: 0.5vw;
      height: 1.2vw;
      font-size: 14px;
      line-height: 1.2vw;
      color:  ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
      border-radius: 4vw;
    }
    &.mobPopupTVL{
      max-width: 16vw;
      width: 16vw;
      margin: 0.5vw;
      height: 1.2vw;
      font-size: 14px;
      line-height: 1.2vw;
      background: transparent linear-gradient(90deg, #FFFFFF16 0%, #FFFFFF16 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #00000029;
      color: rgba(255,255,255, 1);
      border-radius: 4vw;
    }
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    &.mobPopup{
      max-width: 12vw;
      width: 12vw;
      margin: 0.5vw;
      height: 1.2vw;
      font-size: 14px;
      line-height: 1.2vw;
      color:  ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
      border-radius: 4vw;
    }
    &.mobPopupTVL{
      max-width: 12vw;
      width: 12vw;
      margin: 0.5vw;
      height: 1.2vw;
      font-size: 14px;
      line-height: 1.2vw;
      background: transparent linear-gradient(90deg, #FFFFFF16 0%, #FFFFFF16 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #00000029;
      color: rgba(255,255,255, 1);
      border-radius: 4vw;
    }
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    &.mobPopup{
      max-width: 10vw;
      width: 9.5vw;
      margin: 0.5vw;
      height: 1.2vw;
      font-size: 16px;
      line-height: 1.2vw;
      color:  ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
      border-radius: 4vw;
    }
    &.mobPopupTVL{
      max-width: 10vw;
      width: 9.5vw;
      margin: 0.5vw;
      height: 1.2vw;
      font-size: 16px;
      line-height: 1.2vw;
      background: transparent linear-gradient(90deg, #FFFFFF16 0%, #FFFFFF16 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #00000029;
      color: rgba(255,255,255, 1);
      border-radius: 4vw;
    }
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    &.mobPopup{
      max-width: 10vw;
      width: 8.5vw;
      margin: 0.5vw;
      height: 1.2vw;
      font-size: 16px;
      line-height: 1.2vw;
      color:  ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
      border-radius: 4vw;
    }
    &.mobPopupTVL{
      max-width: 10vw;
      width: 8.5vw;
      margin: 0.5vw;
      height: 1.2vw;
      font-size: 16px;
      line-height: 1.2vw;
      background: transparent linear-gradient(90deg, #FFFFFF16 0%, #FFFFFF16 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #00000029;
      color: rgba(255,255,255, 1);
      border-radius: 4vw;
    }
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    &.mobPopup{
      max-width: 10vw;
      width: 6.5vw;
      margin: 0.5vw;
      height: 1.2vw;
      font-size: 16px;
      line-height: 1.2vw;
      color:  ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
      border-radius: 4vw;
    }
    &.mobPopupTVL{
      max-width: 10vw;
      width: 6.5vw;
      margin: 0.5vw;
      height: 1.2vw;
      font-size: 16px;
      line-height: 1.2vw;
      background: transparent linear-gradient(90deg, #FFFFFF16 0%, #FFFFFF16 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #00000029;
      color: rgba(255,255,255, 1);
      border-radius: 4vw;
    }
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    &.mobPopup{
      max-width: 40vw;
      width: 28vw;
      margin: 1vw;
      height: 9vw;
      font-size: 16px;
      line-height: 9vw;
      color:  ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
    }
    &.mobPopupTVL{
      max-width: 40vw;
      width: 28vw;
      margin: 1vw;
      height: 9vw;
      font-size: 16px;
      line-height: 9vw;
      background: transparent linear-gradient(90deg, #FFFFFF16 0%, #FFFFFF16 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #00000029;
      color: rgba(255,255,255, 1);
    }
  }

`
export const TokenCont = styled.div`
 display: flex;
 align-items: center;
 img {
   width: 24px;
   height: 24px;
   margin-right: 10px;
   @media (min-width: ${screenSizes.mediaXS}px) {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
   @media (min-width: ${screenSizes.mediaM}px) {
     width: 40px;
     height: 40px;
   }
   @media (min-width: ${screenSizes.mediaXLL}px) {
    width: 50px;
    height: 50px;
  }
 }
`
export const ActiveTokenCont = styled.div`
 display: flex;
 align-items: center;
 img {
   width: 24px;
   height: 24px;
   margin-right: 10px;
   @media (min-width: ${screenSizes.mediaXS}px) {
    width: 30px;
    height: 30px;
  }
   @media (min-width: ${screenSizes.mediaM}px) {
     width: 40px;
     height: 40px;
     margin-right: 15px;
   }
   @media (min-width: ${screenSizes.mediaXLL}px) {
    width: 50px;
    height: 50px;
  }
 }
`
export const TokenName = styled.h4`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin:0;
  @media (min-width: ${screenSizes.mediaXS}px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 20px;
    line-height: 25px;
  }
`
export const TableSwitchCont = styled.div`
  display:flex;
  justify-content:flex-end;
  &.collateralSwitch{
    margin-right: 20px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    &.collateralSwitch{
      margin-right: 20px;
    }
  }
`
/* ================= Modal Style ================ */

export const ModalCont = styled(NormalCard)`
  box-shadow: 0px 3px 10px #0000003B;
  width: 100%;
  max-width: 414px;
  margin: 0 auto;
  @media (min-width: ${screenSizes.mediaM}px) {
    width: 477px;
    max-width: 477px;
  }
`
export const ModalHeader = styled.div`
  position: relative;
  padding: 25px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.borderColor};
  box-shadow: 0px 3px 10px #0000003B;
  div {
    display: flex;
    align-items: center;
  }
  img{
    width: 30px;
    height: 30px;
    margin-right: 9.5px;
  }
  h1{
    font-size: 20px;
    line-height: 24px;
    color: ${colors.white};
    font-family: MontserratBold;
    margin: 0;
  }
`
export const CloseModalImg = styled.img`
  position: absolute;
  top: 28px;
  right: 30px;
  width:20px !important;
  height:20px !important;
  cursor:pointer;
`
export const DataInputCont = styled.div`
  background:${colors.themeBackground};
`
export const DataSwitchCont = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr;
  border-bottom: 1px solid ${colors.borderColor};
`
export const SwitchText = styled.p<any>`
  font-size: 20px;
  line-height: 25px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  text-align: center;
  cursor: ${(props: any) => props.switchStatus ? 'initial' : 'pointer'};;
  margin:0;
  padding-bottom:22px;
  border-bottom:${(props: any) => props.switchStatus ? `1px solid ${colors.lightGreen}` : 'none'};
  opacity: ${(props: any) => props.switchStatus ? 1 : 0.2};
`
export const DataInfoCont = styled.div`
  padding:20px;
  box-sizing:border-box;
  .info-part{
    margin-bottom:30px;
  }
  .max-borrow{
    margin-bottom:10px;
  }
  .apy-img{
    width:40px;
    height:40px;
    margin-right:18.69px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    padding:30px;
  }
`
export const ValueText = styled.p<any>`
  font-size: 20px;
  color: ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
  font-family: MontserratSemiBold;
  padding:7px 15px;
  background: ${(props: any) => props.isNegative ? 'rgba(255,85,85, 0.2)' : 'rgba(85,255,118, 0.2)'};
  border-radius: 20px;
  margin:0;
`
export const ValueTextWhite = styled.p<any>`
  font-size: 20px;
  color: ${(props: any) => props.isNegative ? colors.white : colors.white};
  font-family: MontserratSemiBold;
  padding:7px 15px;
  background: ${(props: any) => props.isNegative ? 'rgba(255,255,255, 0.2)' : 'rgba(255,255,255, 0.2)'};
  border-radius: 20px;
  margin:0;
`
export const ButtonCont = styled.div`
  margin-top:120px;
  button{
    margin-top:20px;
  }
`
export const ActionBtn = styled(PrimaryBtn)`
  height: 60px;
`
export const ActionBtn2 = styled(Button)`
  height: 60px;
  background: ${colors.red};
  &:disabled {
    background: ${colors.red};
  }
`
export const InputCont = styled.div`
  position: relative;
  padding: 50px 0 16px 0;
  box-sizing: border-box;
`
export const InputField = styled.input`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 90px;
  background: transparent;
  color: ${colors.white};
  font-family: MontserratBold;
  font-size: 32px;
  text-align:center;
  border:none;
  box-sizing: border-box;
  outline: none;
`
export const DollarTxt = styled.span`
  position: absolute;
  top: 90px;
  right: 40px;
  font-size: 60px;
  font-family: MontserratSemiBold;
  color: ${colors.white};
  text-transform: uppercase;
  cursor: pointer;
`
export const MaxTxt = styled.span`
  position: absolute;
  top: 55px;
  right: 40px;
  font-size: 20px;
  line-height: 25px;
  font-family: MontserratSemiBold;
  color: ${colors.white};
  text-transform: uppercase;
  cursor: pointer;
`
export const InputValText = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0 0 12px 0;
  text-align: center;
  opacity: 0.4;
`
export const SelectAssetWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`
export const SelectAssetCont = styled.div`
  height: 30px;
  box-sizing: border-box;
  padding: 6px 0 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border:1px solid ${colors.borderColor};
  border-radius: 20px;
`
export const SelectAssetText = styled.p<any>`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.white};
  font-family: MontserratBold;
  opacity: ${(props: any) => props.activeAsset ? 1 : 0.2};
  margin: 0;
  padding: 0 15px;
  cursor: pointer;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 14px;
    line-height: 18px;
  }
`
export const VLDivider = styled.div`
  width: 2px;
  height: 18px;
  background: ${colors.white};
  opacity: 0.15;
`
/* ================== Activate Asset ============ */

export const ActivateAssetCont = styled.div``

export const ActivateHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px 0 50px 0;
  border-radius: 20px 20px 0 0;
  box-sizing: border-box;
  background:${colors.themeBackground};

  .close-icon{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .token-icon{
    width: 168px;
    height: 168px;
    margin-bottom: 20px;
  }
  h1{
    font-size: 20px;
    line-height: 24px;
    color: ${colors.white};
    font-family: MontserratBold;
    margin: 0;
  }
  p{
    font-size: 16px;
    line-height: 19px;
    color: ${colors.white};
    font-family: MontserratSemiBold;
    margin: 10px 0 0 0;
    opacity: 0.2;
  }
`
/* ========================== Collateral ========================= */

export const CollateralCont = styled.div`
  padding: 70px 28px 60px 27px;
`

export const TokenImgCont = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  img{
    width:50px;
    height:50px;
    margin-right:10px;
  }
  h2{
    font-size: 24px;
    line-height:30px;
    color: ${colors.white};
    font-family: MontserratBold;
    margin:0;
  }
`
export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
export const CollSpinCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin:87px auto 114px auto;
`
export const CollSpinLogo = styled.div<any>`
  width: 148px;
  height: 148px;
  border: 12px solid ${(props: any) => props.collStatus ? colors.lightGreen : colors.red};
  border-bottom: 12px solid ${colors.themeBackground};
  border-radius: 50%;
  animation: ${rotate} 0.8s linear infinite;

`
export const ConfirmText = styled.p`
  font-size: 16px;
  line-height:20px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin:0;
  text-align: center;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 25px;
  }
`


/* ========================== Slider Style ======================= */

export const SliderContainer = styled.div`
 zoom:110%;
.rangeslider{
  margin: 20px 0;
  touch-action: true;
}
.rangeslider-horizontal {
  height: 1.25vw;
  border-radius: 1.04vw;
  // background: ${colors.themeBackground};
  background:linear-gradient(to right, #55FE76 0, #FEBD55 50%, #FF5555 100%);
  // border:1px solid ${colors.borderColor};
  box-sizing:border-box;
  width:100%;
  padding:0;
  cursor:pointer;
  ul{
    display:none;
  }
  .rangeslider__fill {
    border-radius: 20px;
    // background: linear-gradient(90deg, ${colors.lightGreen} 0%, ${colors.green} 100%) 0% 0% no-repeat padding-box;
    // background:linear-gradient(to right, #55FE76 0, #FEBD55 50%, #FF5555 100%);
    background: transparent;
    box-shadow:none;
  }
  .rangeslider__handle:after {
    display:none;
  }
  .rangeslider__handle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: black;
    color: black;
    border: 0;
    cursor: pointer;
    outline: none;
  }

  @media (max-width: 799px) {
    height: 2.898vw;
  }
}
 `

 export const SliderContainerNew = styled.div<any>`
 zoom:110%;
.rangeslider{
  margin: 10px 0;
  touch-action: true;
}
.rangeslider-horizontal {
  height: 1.25vw;
  border-radius: 1.04vw;
  // background: ${colors.themeBackground};
  background:transparent;
  // box-shadow: 0px 0px 10px #55FF76BF;
  border:1px solid ${colors.borderColor};
  box-sizing:border-box;
  width:100%;
  padding:0;
  cursor:pointer;
  ul{
    display:none;
  }
  .rangeslider__fill {
    border-radius: 1.04vw;
    background: transparent linear-gradient(90deg, #55FF76 0%, #27AC40 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #55FF76BF;
    //background: linear-gradient(90deg, ${colors.lightGreen} 0%, ${colors.green} 100%) 0% 0% no-repeat padding-box !important;
    // background:linear-gradient(to right, #55FE76 0, #FEBD55 50%, #FF5555 100%);
    background: transparent;
    //box-shadow:none;
    //background: linear-gradient(to right, #55FF76, #27ac40 70%, #ff8955 90%, #ff5c55 100%);
  }
  .rangeslider__handle:after {
    display:none;
  }
  .rangeslider__handle {
    width: 16px;
    height: 16px;
    border-radius: 1.04vw;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: white;
    border: 1px solid #FFFFFF26;
    cursor: pointer;
    outline: none;
  }

  @media (max-width: 799px) {
    height: 2.898vw;
  }
}
&.SliderContainer .rangeslider-horizontal{
  background: linear-gradient(to right, #0E2012 75%, #000000 25%) !important;
}

&.SliderContainer .rangeslider__fill{
  background: transparent linear-gradient(90deg, #55FF76 0%, #27AC40 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #55FF76BF;
}

&.OrangeLimit .rangeslider__fill{
  //background: linear-gradient(to right, ${colors.lightGreen} 0%, ${colors.green} 75%, #FF8955 15% ) 0% 0% no-repeat padding-box !important;
   background: ${(props: any) => props.borrowLimit ? 'linear-gradient(to right, #55FF76 0%, #27AC40 calc(75% / '+props.borrowLimit+' * 100), #FF8955 calc(100% - ((75 / '+props.borrowLimit+') * 100%)) ) 0% 0% no-repeat padding-box !important' : 'linear-gradient(to right, ${colors.lightGreen} 0%, ${colors.green} 75%, #FF8955 15% ) 0% 0% no-repeat padding-box !important'};
}

&.RedLimit .rangeslider__fill{
  //background: linear-gradient(to right, ${colors.lightGreen} 0%, ${colors.green} 75%, #FF8955 15%, #FF5C55 10% ) 0% 0% no-repeat padding-box !important;
  background: ${(props: any) => props.borrowLimit ? 'linear-gradient(to right, #55FF76 0%, #27AC40 calc(75% / '+props.borrowLimit+' * 100), #FF8955 calc(15 / '+props.borrowLimit+' * 100%), #FF5C55 calc(100% - ((90 / '+props.borrowLimit+') * 100%))) 0% 0% no-repeat padding-box !important' : 'linear-gradient(to right, ${colors.lightGreen} 0%, ${colors.green} 75%, #FF8955 15% ) 0% 0% no-repeat padding-box !important'};
}
 `

/* ================== Table Loading =================== */

export const ShimmerCont = styled.div`
  .animate {
    animation: ${shimmer} 3s;
    animation-iteration-count: infinite;
    background: transparent linear-gradient(270deg, ${colors.green} 0%, ${colors.lightGreen} 100%) 0% 0% ;
    background-size: 1000px 100%;
  }
  .hide-loading{
    display: block;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    .hide-loading{
      display: none;
    }
  }
  .token-simmer{
    width: 100px;
  }
`
export const LoadingTableRow = styled(TableRow)`
  align-items: end;
`
export const ShimmerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`
export const MHideBox = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  @media (min-width: ${screenSizes.mediaM}px) {
    display: flex;
  }
`
export const ShimmerCircle = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  @media (min-width: ${screenSizes.mediaXS}px) {
   width: 30px;
   height: 30px;
   margin-right: 15px;
 }
  @media (min-width: ${screenSizes.mediaM}px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
   width: 50px;
   height: 50px;
 }
`
export const ShimmerLine = styled.div`
  height: 12px;
  border-radius: 10px;
  width: 80%;
  @media (min-width: ${screenSizes.mediaXS}px) {
    height: 14px;
  }
  @media (min-width: ${screenSizes.mediaS}px) {
    height: 16px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 18px;
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height: 24px;
  }
`
export const ShimmerSubLine = styled.div`
  height: 10px;
  border-radius: 10px;
  margin-top: 10px;
  width: 80%;
  @media (min-width: ${screenSizes.mediaXS}px) {
    height: 12px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 14px;
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    height: 16px;
  }
`
