import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { CardBText } from 'shared/styles/globalText'
import { PlanetCard } from 'shared/styles/styled'
import { shimmer } from 'shared/styles/styled'
import { Button } from 'shared/button'

export const LoadingText = styled.div`
  animation: ${shimmer} 3s;
  animation-iteration-count: infinite;
  background: transparent linear-gradient(270deg, #3037da 0%, #55b7ff 100%) 0% 0% ;
  background-size: 1000px 100%;
  border-radius: 10px;
`
export const LoadingText1 = styled(LoadingText)`
  width: 50%;
  height: 16px;
  margin-top: 10px;
  @media (min-width: ${screenSizes.mediaXS}px) {
    height: 20px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 18px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    height: 24px;
  }
`
export const LoadingText2 = styled(LoadingText1)`
  width: 100%;
  height: 16px;
  margin-top: 10px;
  @media (min-width: ${screenSizes.mediaXS}px) {
    height: 20px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 18px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    height: 24px;
  }
`
export const Card = styled(PlanetCard)`
  width: 100%;
  padding: 20px;
  overflow-x: hidden !important;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 30px 20px 24px 20px;
    &.on-mob{
      display: none !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    padding: 30px 24px 24px 24px;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    padding: 30px 30px 24px 30px;
  }
  
  &.overviewCard{
    padding: 1.5625vw;
    height: 14.32vw;
    width: 23.959vw;
    @media (max-width: 799px) {
      padding: 20px;
      height: auto;
      width: 100%;
    }
  }

  &.user-balance-card{
    height: 14.32vw;
    width: 23.958vw;
    padding: 1.5625vw 3.125vw 1.25vw 3.125vw;
    overflow: hidden;
    @media (max-width: 799px) {
      padding: 20px;
    }
  }

  &.projection-card{
    padding: 1.5625vw 1.5625vw 1.25vw 1.5625vw;
    height: 14.32vw;
    width: 23.958vw;
    overflow: hidden;
    @media (max-width: 799px) {
      padding: 20px;
      height: auto;
      width: 100%;
      margin-top: 20px;
    }
  }
`
export const CardNumText = styled(CardBText)`
  margin-top: 10px;
  &.tvl-value{
    font-size: 1.04vw;
    line-height: 1.25vw;
    margin-top: 0.468vw;
    @media (max-width: 799px) {
      font-size: 20px;
      line-height: 24px;
      margin-top: 10px;
    }
  }
  &.overview-values{
    font-size: 1.25vw;
    line-height: 1.51vw;
    margin-top: 0.52vw;
    @media (max-width: 799px) {
      font-size: 18px;
      line-height: 22px;
      margin-top: 10px;
    }
  }
`
export const LevelBox = styled.div`
  padding: 3px 10px;
  margin-left: 7px;
  background: rgba(255,255,255,0.1);
  margin-top: 10px;
  border-radius: 11px;
  p{
    font-size: 12px;
    font-family: MontserratSemiBold;
    color: ${colors.white};
    margin: 0;
  }
`

export const BaseContainer = styled.div`
  position: relative;
  .title-info{
      margin-top: 10px;
      @media (min-width: ${screenSizes.mediaM}px) {
        margin-top: 20px;
      }
  }
  .val-space{
      margin-top: 10px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    &.on-mob{
      margin-top: 10px;
      display: none !important;
    }
  }
`
export const BaseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
export const TvlInfoBox = styled(PlanetCard)`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  width: 280px;
  max-width: 280px;
  height: 120px;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    width: 300px;
    max-width: 300px;
    padding: 20px 20px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    width: 347px;
    max-width: 347px;
    padding: 20px 30px;
  }
  img{
      width: 60px;
      height: 60px;
      margin-right: 20px;
      @media (min-width: ${screenSizes.mediaXLL}px) {
        width: 70px;
        height: 70px;
      }
      @media (min-width: ${screenSizes.mediaXXL}px) {
        width: 80px;
        height: 80px;
      }
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    width: 100%;
    max-width: 100%;
    padding: 20px 20px;
    margin-top: 15px;
  }

  &.base-tvl{
    padding: 1.04vw;
    height: auto;
    width: auto;
    @media(max-width: 799px){
      padding: 20px;
      margin-top: 20px;
    }
  }

  img.tvl-icon{
    height: 2.604vw;
    width: 2.604vw;
    @media(max-width: 799px){
      height: 60px;
      width: 60px;
    }
  }
`
export const UserInfoCont = styled.div`
  display: grid;
  grid-template-columns: repeat(1,1fr);
  grid-gap: 15px;
  margin-top: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 30px;
  }
  &.overview-section{
    margin-top: 2.08vw;
    grid-gap: 1.04vw;
    width: 75vw;
    @media (max-width: 799px) {
      margin-top: 30px;
      grid-gap: 7px;
      width: 100%;
    }
  }
`
export const OverViewInfo = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
  text-align: left;
  overflow-x: hidden!important;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    grid-gap: 10px;
    grid-template-columns: 1.2fr 1fr;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    grid-gap: 20px;
  }
  &.overview-info{
    margin-top: 1.5625vw;
    @media (max-width: 799px) {
      margin-top: 20px;
      grid-gap: 10px;
    }
  }
  &.overview-info > div {
    overflow: hidden;
  }
  &.vestingTableHeader{
    grid-template-columns: 2fr 5fr 3fr 1fr 5fr 4fr 1fr;
    font-size: 0.833vw;
    line-height: 0.989vw;
    @media (max-width: 799px) {
      display: none;
    }
  }
  &.vestingTableHeaderMob{
    grid-template-columns: 1.5fr 1.7fr 1fr 0.7fr 5fr;
    @media (min-width: 800px) {
      display: none;
    }
  }
  &.vestingTableBody{
    grid-template-columns: 7.9fr 4.7fr 10fr 1fr;
    font-size: 0.833vw;
    line-height: 0.989vw;
    @media (max-width: 799px) {
      display: none;
    }
  }
  &.vestingTableBodyMob{
    grid-template-columns: 7.7fr 4.5fr 10fr;
    font-size: 0.833vw;
    line-height: 0.989vw;
    @media (min-width: 800px) {
      display: none;
    }
  }
  &.bountyTableHeader{
    grid-template-columns: 1fr 5fr 2fr;
    font-size: 0.833vw;
    line-height: 0.989vw;
    @media (max-width: 799px) {
      display: none;
    }
  }
  &.bountyTableBody{
    grid-template-columns: 3.2fr 4.7fr 3fr;
    font-size: 0.833vw;
    line-height: 0.989vw;
    @media (max-width: 799px) {
      display: none;
    }
  }
  &.bountyTableHeaderMob{
    grid-template-columns: 7.9fr 4.7fr 10fr 1fr;
    font-size: 0.833vw;
    line-height: 0.989vw;
    @media (min-width: 800px) {
      display: none;
    }
  }
  &.bountyTableBodyMob{
    grid-template-columns: 7.9fr 4.7fr 10fr 1fr;
    font-size: 0.833vw;
    line-height: 0.989vw;
    @media (min-width: 800px) {
      display: none;
    }
  }
`
export const BalanceWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  align-items: center;
  .bal-text {
    font-size: 14px;
    line-height: 18px;
  }
  &.user-token-cont{
    align-items: flex-start;
  }
`
export const BalanceCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img{
    width: 70px;
    height: 70px;
    margin-bottom:10px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
       width: 80px;
       height: 80px;
      }
    @media (min-width: ${screenSizes.mediaXXL}px) {
       width: 100px;
       height: 100px;
      }
  }
  .buy-btn{
    height: 39px !important;
    max-width: 130px;
    border-radius: 20px !important;
    font-size: 15px !important;
    margin-top: 17px;
  }

  img.token-icon {
    height: 5.208vw;
    width: 5.468vw;
    margin-bottom:0px;
  }
`
export const BalanceText = styled(CardBText)`
  margin: 5px 0 0 0;
  span{
    font-size: 16px;
    line-height: 19px;
    opacity: 0.4;
  }
  &.user-token-value{
    font-size: 1.25vw;
    line-height: 1.51vw;
  }
  &.user-token-value > span{
    font-size: 0.833vw;
    line-height: 0.989vw;
    opacity: 1;
    color: #736F82;
  }
`
/*  ================== User Mobile balance =============== */

export const MobBalWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaM}px) {
    display: none;
  }
`
export const MobBalCard = styled(PlanetCard)`
  padding: 20px;
  .total-token{
    margin: 10px 0;
  }
`
export const MobBuyAssetCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  img{
    width: 44px;
    height: 44px;
  }
  button{
    height: 35px;
    max-width: 54px;
    font-size: 12px;
    padding: 10px 15px;
    margin-top: 0 !important;
    box-sizing: border-box;
  }
`
export const MobBalText = styled.p<any>`
  font-size: 18px;
  line-height: 22px;
  color: ${(props: any) => props.tokenType === 'aqua' ? colors.lightBlue : props.tokenType === 'gamma' ? colors.lightGreen : colors.white};
  font-family: MontserratBold;
  margin: 0;
`

export const ConnectWalletBtn = styled(Button) <any>`
  height: 50px;
  font-size: 12px;
  padding: 14px 30px;
  border: 0;
  box-sizing: border-box;
  color: ${(props: any) => props.currPlanet === 'green' ? colors.lightGreen : colors.lightBlue};
  background: ${(props: any) => props.currPlanet === 'green' ?
    `transparent linear-gradient(90deg, rgba(85,255,118, 0.2) 0%, rgba(39,172,64, 0.2) 100%)  0% 0% no-repeat padding-box` :
    `transparent linear-gradient(270deg, rgba(48,55,218, 0.2) 0%, rgba(85,183,255, 0.2) 100%) 0% 0% no-repeat padding-box`
  };
  &:disabled {
    background: ${(props: any) => props.currPlanet === 'green' ?
    `transparent linear-gradient(90deg, rgba(85,255,118, 0.2) 0%, rgba(39,172,64, 0.2) 100%)  0% 0% no-repeat padding-box` :
    `transparent linear-gradient(270deg, rgba(48,55,218, 0.2) 0%, rgba(85,183,255, 0.2) 100%) 0% 0% no-repeat padding-box`
  };
    cursor: initial;
    opacity: 0.6;
  }
  &:hover {
    background: ${(props: any) => props.currPlanet === 'green' ?
    `transparent linear-gradient(90deg, rgba(85,255,118, 0.4) 0%, rgba(39,172,64, 0.26) 100%)  0% 0% no-repeat padding-box` :
    `transparent linear-gradient(270deg,rgba(48,55,218,0.4) 0%,rgba(85,183,255,0.26) 100%) 0% 0% no-repeat padding-box`
  };
  }

  @media (min-width: 912px) {
    font-size: 18px;
  }

  &.base-wallet-connect-web{
    font-size: 1.04vw;
    line-height: 1.25vw;
    padding: 0.989vw 7.135vw;
    background: transparent linear-gradient(270deg, #3037DA 0%, #55B7FF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #3037DA;
    border: 1px solid #FFFFFF26;
    border-radius: 30px;
    opacity: 1;
    margin-top: 2.08vw;
    width: auto;
    color: #FFFFFF;
    display: block;
    height: auto; 
    @media (max-width: 799px) {
      display: none;
    }
  }

  &.base-wallet-connect-mob{
    display: none;
    @media (max-width: 799px) {
      font-size: 18px;
      line-height: 22px;
      padding: 10px 30px;
      margin: 20px auto 10px auto;
      width: -webkit-fill-available;
      background: transparent linear-gradient(270deg, #3037DA 0%, #55B7FF 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 20px #3037DA;
      border: 1px solid #FFFFFF26;
      border-radius: 30px;
      opacity: 1;
      display: block;
      color: #FFFFFF;
    }
  }

  &.claimBountyBtn{
    background: transparent linear-gradient(90deg, #55FF7615 0%, #27AC4015 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 2px solid #FFFFFF26;
    border-radius: 25px;
    font-size: 0.52vw;
    line-height: 0.677vw;
    color: #55FE76;
    padding: 0.416vw 1.197vw;
    height: auto;
    width: auto;
    @media (max-width: 799px) {
      font-size: 2.41vw;
      line-height: 3.14vw;
      padding: 1.932vw 5.55vw;
    }
  }

`