import { useState, useEffect, createContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DataTitle } from 'shared/styles/globalText'
import { HAListCont, HATokenWrapper, HATokenCont, HATokenBox, HAImgCont, HATokenTxt, HApyTxt } from 'shared/styles/highestApyStyle'
import { FarmPoolContext } from 'context/FarmPoolContext'
import CAKEIcon from 'assets/icons/CAKE.svg'
import { getTokenIcon } from 'shared/tokenIconList'
import { getTokenName, commaFy, convertToKAndM } from 'service/globalFunctions'
import TokenBoxLoading from 'shared/token-box-loading'
import CustomModal from 'shared/custom-modal'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import PoolDetailsModal from 'modules/pools_v3/components/pool-modal/PoolDetailsModal'
import PoolTCAction from 'modules/pools_v3/components/pool-modal/PoolTCAction'
import thenaIcon from 'assets/icons/ThenaLogo.svg'
import bswIcon from 'assets/icons/bsw.svg'
import { Paths } from 'modules/app/components/routes/types'
import history from 'modules/app/components/history'
import UnStakeInstantlyPopUp from 'modules/pools_v3/components/pool-modal/UnStakeInstantlyPopUp'
import { selectInstance, instType, poolInfoNew, handleDeposit, handleWithdraw, harvestLpTokens, earnAquaProfits, isAllowanceApproved, convertToEther, deposit_in_infinity_vault_new, getUserUnstakingTimeInInfinityVaultNew, stopUnstakeProcessNew, unstake_after_min_time_new } from 'modules/block-chain/BlockChainMethods'
import { updateUserBalance, updateLPData, silentUpdateOnInfStake, } from 'logic/action/user.actions'
import { fetchUserPortfolio } from 'logic/action/user.actions'
import { CardTitle, CardSubText } from 'shared/styles/globalText'
import { NewsWrapper, NewsContainer, NewsDataCard, NewsContent, NewsTitle, NewsInfo } from './style'
import { returnTokenList, tokenSwapList } from 'modules/block-chain/tokenList'
import { claimGammaRewardsForPools, totalAllocPoint, GAMMAPerBlockNew } from 'modules/block-chain/BlockChainMethods';
import SwapInPools from 'modules/pools_v3/components/pool-modal/SwapInPools'
import AssetSupplyRevised from 'modules/lending/components/AssetSupplyRevised'
import { activeV3Pools } from 'modules/block-chain/pfPoolsList'

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const HighestApySection = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [showPoolDetails, setShowPoolDetails] = useState(false)
    const [modalData, setModalData] = useState<any>({})
    const [showTCAModal, setShowTCAModal] = useState(false)
    const [transData, setTransData] = useState<any>()
    const [lpAction, setLPAction] = useState<any>('transform')
    const [tokenSwapAmount, setTokenSwapAmount] = useState(0)
    const [sendToken, setSendToken] = useState<any>(tokenSwapList[1])
    const [trxPopUpOverlay, setTrxPopUpOverlay] = useState(false);
    const poolsNewData = props.apyArray;
    const gammaBtcbPool = props.gammaBtcbPool;
    const [showUnstakePopUp, setShowUnstakePopUp] = useState(false)
    const [unstakeData, setUnstakeData] = useState({})
    const [processUnstake, setProcessUnstake] = useState(() => {})
    const [instantUnstakeLoading, setInstantUnstakeLoading] = useState(false)
    const [confirmUnstake, setConfirmUnstake] = useState({
        confirm: false,
        poolId: -1,
    })
    const [farmPoolData, setFarmPoolData] =  useState({
        totalAllocPoint: 365,
        gammaPerBlock: 1614583333333333200, 
        tokenSwapList: tokenSwapList,
        tokenSwapListWithUserBalances: tokenSwapList
    })
    const [tokenDataList, setTokenDataList] = useState<any>(tokenSwapList)
    const [showSwapPopUp, setShowSwapPopUp] = useState(false)
    const [receiveTokenNumber, setReceiveTokenNumber] = useState('')
    const [initialTokenAdd, setInitialTokenAdd] = useState('')
    const [showVaultDetails, setShowVaultDetails] = useState(false)
    const [showAssetSupply, setShowAssetSupply] = useState(false)
    const { poolLoading, poolData, portfolioLoading, userPortfolio, gammaInfDataNew, aquaInfDataNew } = selector.user

    useEffect(() => { 
        if(showPoolDetails) {
            let strat_id = modalData.strategyAddress
            let pool = poolsNewData.filter((ele:any) => {
                return ele.strategyAddress == strat_id
            })
            
            setModalData(pool[0])
        }
    }, [poolsNewData])

    useEffect(() => {
        const updateTokenListWithBalances = async () => {
            const userAddress = selector.ethData.address
            try {
              const res = await returnTokenList(userAddress)
              if (!!res && typeof res !== 'undefined') {
                farmPoolData.tokenSwapListWithUserBalances = res;
                setSendToken(res[0])
              }
            } catch (error) {
              console.log('error===>', error)
            }
        }
        updateTokenListWithBalances()
    }, [selector.ethData.address, poolData])
    
    const renderHATokenLoading = () => {
        return arr.map((item: any, index: number) => {
            return <TokenBoxLoading key={index} />
        })
    }

    const handleShowApyModal = (modalDataVal: any) => {
        if(modalDataVal.strategyAddress !== undefined) {
            setModalData(modalDataVal)
            setShowPoolDetails(true)
        } else {
            setModalData(modalDataVal)
            setShowVaultDetails(true)
        }
        
    }

    const toggleApyModal = () => {
        setModalData({})
        setShowPoolDetails(false)
    }
    const handleGetLpToken = (transData: any, type: any, inputAmount: any) => {
        if(transData.poolId < 2) {
            if(selector.ethData.ethWalletConnected && parseFloat(inputAmount) > 0) {
                let tokenName:any = transData.poolId == 0? "GAMMA": "AQUA";
                let withdrawAmt:any =  parseFloat(inputAmount) ;
                let withdrawAmtUsd:any =  parseFloat(inputAmount) * (transData.vaultBalanceUSD/transData.vaultBalance);
                let withdrawFee:any = parseFloat(inputAmount) * 0.05;
                let receiveAmt:any = parseFloat(inputAmount) * 0.95;
                let receiveAmtUsd:any = withdrawAmtUsd * 0.95;

                let d = {
                    tokenName,
                    withdrawAmt,
                    withdrawAmtUsd,
                    withdrawFee,
                    receiveAmt,
                    receiveAmtUsd,
                    instantWithdrawFeePercent: 5
                }
                setUnstakeData(d);
                setShowUnstakePopUp(true);
            }
        } 
        else {
            if(transData.poolStatus == "active"){
                setTransData(transData)
                setShowTCAModal(true)
                setLPAction(type)
                setTokenSwapAmount(inputAmount)
            } else {
                history.push(`${Paths.liquidity}`)
            }
        }
    }

    const getTokenList = () => {
        let tokenList: any = []
        if (!poolLoading) {
            const dataArr = poolsNewData;
            if (!!dataArr && Object.values(dataArr).length > 0) {
                tokenList = dataArr.active.sort((a: any, b: any) => (parseFloat(b.totalApy) > parseFloat(a.totalApy)) ? 1 : (parseFloat(a.totalApy) > parseFloat(b.totalApy) ? -1 : 0))
                tokenList = tokenList.slice(0, 10)
            }
        }
        return tokenList
    }

    const renderTokenIcons = (tokenIcons: string, isBoostPool: boolean, protocol?: string) => {
        const tokenName = tokenIcons.split('-')
        if (tokenName.length === 1) {
            const icon = getTokenIcon(tokenIcons)
            return <HAImgCont className="single-img">
                        <img className="single-img" src={icon} alt="" />
                    </HAImgCont>
        }
        else if (tokenName.length === 2) {
            const icon1 = getTokenIcon(tokenName[0])
            let secondTokenName = tokenName[1].split(" ");
            const icon2 = getTokenIcon(secondTokenName[0])
            return (
                <HAImgCont>
                    <img className="first-img" src={icon1} alt="" />
                    <img className="second-img" src={icon2} alt="" />
                    {protocol && protocol.toLowerCase() == 'cake' && <img  className='third-img' src={CAKEIcon} alt="" />}
                    {protocol && protocol.toLowerCase() == "thena" && <img className='third-img' src={thenaIcon} alt="" />}
                    {protocol && protocol.toLowerCase() == "biswap" && <img className='third-img' src={bswIcon} alt="" />}
                </HAImgCont>
            )
        }
        else if (tokenName.length === 3) {
            const icon1 = getTokenIcon(tokenName[0])
            const icon2 = getTokenIcon(tokenName[1])
            const icon3 = getTokenIcon(tokenName[2])
            return (
                <HAImgCont className='stablePool'>
                    <img className="first-img" src={icon1} alt="" />
                    <img className="second-img" src={icon2} alt="" />
                    <img className='third-icon' src={icon3} alt="" />
                </HAImgCont>
            )
        }
    }

    const renderTokenList = () => {
        // const tokenList = getTokenList()
        return poolsNewData.map((item: any, index: number) => {
            let poolApy: any = item.totalApy;
            poolApy = poolApy && (!isFinite(poolApy) || poolApy > 10000) ? "> 10,000" : poolApy ? poolApy.toFixed(2) : "0.00";
            return (
                <HATokenBox key={index} onClick={() => handleShowApyModal(item)} >
                    {renderTokenIcons(item.name, item.isBoostPool, item.protocol)}
                    <HATokenTxt>{getTokenName(item.name)}</HATokenTxt>
                    <HApyTxt>{poolApy}%</HApyTxt>
                    
                </HATokenBox>
            )
        })
    }
    
    const handleUnstakePopUpToggleModal = () => {
        setShowUnstakePopUp(false)
    }

    const toggleSwapPopupModal = () => {
        // setModalData({})
        setShowSwapPopUp(false)
    }

    const closeSwapModal = () => {
        setShowSwapPopUp(false)
    }

    const showSwapPopup = async (data: any, swapAmount: any, sendToken?: any) => {
        
        let tokenNumber : any = data.poolId == 0 ? 2 : 0;
        setReceiveTokenNumber(tokenNumber)
        setTokenSwapAmount(swapAmount)
        if(sendToken){
            setSendToken(sendToken)
        }
        setShowSwapPopUp(true)
    }

    const updateTokenList = async () => {
        const userAddress = selector.ethData.address
        try {
          const res = await returnTokenList(userAddress)
          if (!!res && typeof res !== 'undefined') {
            setTokenDataList(res)
          }
        } catch (error) {
          console.log('error===>', error)
        }
    }

    const toggleVaultDetailsModal = () => {
        setModalData({})
        setShowVaultDetails(false)
      }

    return (
        <FarmPoolContext.Provider value={farmPoolData}>
            <HAListCont>
                <NewsWrapper className='newsCont'>
                <CardTitle className="cardTitle">Highest Yielding Pools & Markets</CardTitle>
                <HATokenWrapper>
                    <HATokenCont poolSize={poolsNewData && (poolsNewData.length > 0 && poolsNewData.length < 10) ? poolsNewData.length : 10}>
                        {poolLoading ? renderHATokenLoading() : renderTokenList()}
                    </HATokenCont>
                </HATokenWrapper>
                </NewsWrapper>
                <CustomActionModal show={showPoolDetails} toggleModal={toggleApyModal}>
                    {showPoolDetails && <PoolDetailsModal 
                        togglePoolModal={toggleApyModal} 
                        data={modalData} 
                        handleGetLpToken={handleGetLpToken} 
                        setModalData={setModalData} 
                        setTokenSwapAmount={setTokenSwapAmount} 
                        sendToken={sendToken}
                        tokenDataList={tokenDataList}
                        setSendTokenFromParent={setSendToken} 
                        showSwapPopup={showSwapPopup}
                        setUnstakeData={setUnstakeData}
                        setShowUnstakePopUp={setShowUnstakePopUp} 
                        confirmUnstake={confirmUnstake}
                        showUnstakePopUp={showUnstakePopUp}
                        setConfirmUnstake={setConfirmUnstake}
                    />}
                </CustomActionModal>
                <CustomModal show={showTCAModal} toggleModal={setShowTCAModal} zapTrxModal={trxPopUpOverlay}>
                    {showTCAModal && activeV3Pools.includes(transData.strategyAddress) && <PoolTCAction setShowTCAModal={setShowTCAModal} transData={transData} key={transData.walletBalance || transData.vaultBalance} type={lpAction} poolTable={true} zapTokenAmount={tokenSwapAmount} zapTokenObject={sendToken} gammaBtcbPool={gammaBtcbPool} setTrxPopUpOverlay={setTrxPopUpOverlay} trxPopUpOverlay={trxPopUpOverlay} />}
                </CustomModal>
                {/*
                {showTCAModal && activeV3Pools.includes(transData.strategyAddress) && <PoolTCAction setShowTCAModal={setShowTCAModal} transData={transData} key={transData.walletBalance || transData.vaultBalance} type={lpAction} poolTable={true} zapTokenAmount={tokenSwapAmount} zapTokenObject={sendToken} setShowPoolDetails={setShowPoolDetails} gammaBtcbPool={gammabtcbPoolObject} setTrxPopUpOverlay={setTrxPopUpOverlay} trxPopUpOverlay={trxPopUpOverlay} />}
                */}
                <CustomModal show={showUnstakePopUp} toggleModal={handleUnstakePopUpToggleModal}>
                    <UnStakeInstantlyPopUp 
                        setShowUnstakePopUp={setShowUnstakePopUp} 
                        showUnstakePopUp={showUnstakePopUp}
                        unstakeData={unstakeData}
                        setConfirmUnstake={setConfirmUnstake}
                        processUnstake={processUnstake}
                    />
                </CustomModal>
                <CustomActionModal show={showSwapPopUp} toggleModal={toggleSwapPopupModal}>
                {showSwapPopUp && <SwapInPools tokenList={tokenDataList} updateTokenList={updateTokenList} initialTokenAdd={initialTokenAdd} receiveTokenNumber={receiveTokenNumber} closeSwapModal={closeSwapModal} tokenSwapAmount={tokenSwapAmount} toggleSwapPopupModal={toggleSwapPopupModal} sendTokenObject={sendToken} />}
                </CustomActionModal>
                {<CustomActionModal show={showVaultDetails} toggleModal={toggleVaultDetailsModal}>
                    {showVaultDetails && (
                        <AssetSupplyRevised
                            data={modalData}
                            setShowAssetSupply={setShowAssetSupply}
                            toggleVaultModal={toggleVaultDetailsModal} setModalData={setModalData} currentTab={"all"}
                        />
                    )}
                </CustomActionModal>}
            </HAListCont>
        </FarmPoolContext.Provider>
    );
};

export default HighestApySection