import styled from "styled-components";
import { colors, screenSizes } from 'shared/styles/theme'
import { PlanetCard } from "shared/styles/styled";

export const MetricsCont = styled.div`
  margin-top: -5px;
  .on-mobile{
    display: block;
    @media (min-width: ${screenSizes.mediaM}px) {
      display: none;
    }
  }
  .on-web{
    display: none;
    @media (min-width: ${screenSizes.mediaM}px) {
      display: block;
    }
  }
  .loading-space{
    margin-top: 10px;
  }
`
export const MetricsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-bottom: 28px;
  }
`
export const Title = styled.h1`
  font-size: 24px;
  line-height: 29px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 30px;
    line-height: 37px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 40px;
    line-height: 49px;
  }
`
export const SwitchCont = styled(PlanetCard) <any>`
  display: grid;
  grid-template-columns: auto auto;
  width: 170px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 15px;
  .aqua-switch{
    border-right:${(props: any) => props.activeToken === "aqua" ? `1px solid ${colors.borderColor}` : "none"};
  }
  .gamma-switch{
    border-left: ${(props: any) => props.activeToken === "gamma" ? `1px solid ${colors.borderColor}` : "none"};
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    width: 280px;
    height: 50px;
    grid-template-columns: 1fr 1fr;
    border-radius: 20px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    width: 320px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    width: 346px;
    height: 64px;
  }
`
export const SwitchToken = styled.div<any>`
  background: ${(props: any) => props.isActive ? colors.themeBackground : 'transparent'};
  border-radius: 15px;
  cursor: ${(props: any) => props.isActive ? "initial" : "pointer"};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props: any) => props.isActive ? 1 : 0.4};
  img{
    width: 24px;
    height: 24px;
    margin-right: ${(props: any) => props.isActive ? '7px' : '0'};
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-right: 10px;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 30px;
      height: 30px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 40px;
      height: 40px;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    border-radius: 20px;
  }
`
export const SwitchTokenTxt = styled.p<any>`
  font-size: 12px;
  color: ${colors.white};
  font-family: MontserratBold;
  opacity: 1;
  margin: 0;
  text-align: center;
  display: ${(props: any) => props.isActive ? "block" : "none"};
  @media (min-width: ${screenSizes.mediaM}px) {
    display: block;
    font-size: 16px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
  }

`

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1,1fr);
  grid-gap: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-gap: 30px;
    grid-template-columns: 1fr minmax(auto , 280px);
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    grid-template-columns: 1fr minmax(auto , 320px);
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    grid-template-columns: 1fr minmax(auto , 346px);
  }
`
export const CardText = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  opacity: 0.4;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
`

export const CardNumText = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 6px 0 0 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 11px 0 0 0;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 24px;
    line-height: 29px;
  }
`

export const PriceGraph = styled.div`
  margin: 12px 0 20px 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 27.51px 0 42.16px 0;
  }
`
export const PriceBox = styled.div`
  padding: 20px 16px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 30px 30px 0 30px;
  }
`
export const PriceTitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratMedium;
   margin: 0 0 11px 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 0 0 20px 0;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
`
export const PriceText = styled(Title) <any>`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  span{
    font-size: 16px;
    line-height: 20px;
    font-family: MontserratSemiBold;
    margin-left: 10px;
    color: ${(props: any) => props.isNegative ? colors.red : props.type === 'gamma' ? colors.lightGreen : colors.lightBlue};
    @media (min-width: ${screenSizes.mediaXLL}px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    color: ${(props: any) => props.type === 'gamma' ? colors.lightGreen : colors.lightBlue};
    font-size: 24px;
    line-height: 29px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 30px;
    line-height: 37px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 40px;
    line-height: 49px;
  }
`
export const TimeBox = styled.div`
  padding: 0 16px 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 0 30px 30px 0;
  }
`
export const TimeText = styled(CardText) <any>`
  font-family: MontserratBold;
  opacity: ${(props: any) => props.activeTime ? 1 : 0.4};
  margin: 0 0 0 20px;
  cursor: pointer;
`
export const InfoCardWrapper = styled.div`
  overflow-x: auto;
  margin-right: -16px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-right: 0;
  }
`

export const InfoCont = styled.div`
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 10px;
  min-width: 700px;
  padding-right: 16px;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-template-columns: repeat(1,1fr);
    grid-gap: 20px;
    min-width: auto;
    padding-right:0;
  }
`
export const InfoCard = styled(PlanetCard)`
  padding: 17px 10px 17px 25px;
  display: flex;
  align-items: center;
  img{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 50px;
      height: 50px;
      margin-right: 15px;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 65px;
      height: 65px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 80px;
      height: 80px;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 20px 10px 20px 30px;
  }
`
