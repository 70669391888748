
import { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions'
import { updateUserLendingData } from 'logic/action/lending.action'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont } from 'shared/styles/styled'
import { ModalCard, ModalCardTitle, ModalCardTxt, EarnCont, LoadingText1, LoadingText2, LoadingText3 } from 'shared/styles/highestApyStyle'
import { ModalInputCont, ModalInput, MaxBox, MaxBoxZero, UserBalTxt } from 'shared/styles/highestApyStyle'
import { CardBtnGroup, CardGreenBtn, CardBtn, TransformBtn, CardRedBtn } from 'shared/styles/highestApyStyle'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { getTokenName, commaFy, getNumFormat } from 'service/globalFunctions'
import { transLinkUrl } from 'service/global-constant'
import Spinner from 'shared/spinner'
import { maxAmount } from 'modules/block-chain/lendingAbi'
import { selectInstance, instType, poolInfo, handleDeposit, handleWithdraw, harvestLpTokens, earnAquaProfits, isAllowanceApproved } from 'modules/block-chain/BlockChainMethods'
import { aquaAddress, aquaAutoCompPoolAddress, aquaFarmAddress, gammaFarmAdddress } from 'modules/block-chain/abi';
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'

const BoostVaultAction = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [depositAmount, setDepositAmount] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [depositLoading, setDepositLoading] = useState(false)
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [claimLoading, setClaimLoading] = useState(false)
    const [claimBoostLoading, setClaimBoostLoading] = useState(false)
    const [isAllowance, setIsAllowance] = useState(false)
    const { portfolioLoading } = selector.user
    const { activeTab, data } = props

    useEffect(() => {
        const userAddress = selector.ethData.address
        const getIsAllowance = async () => {
            const wantAddress = data.address
            let farmAddress = ""
            try {
                const isAllow = await isAllowanceApproved(userAddress, farmAddress, wantAddress)
                setIsAllowance(isAllow)
            }
            catch (error) {
                console.log("error=>", error)
            }
        }
        if (!!data) {
            getIsAllowance()
        }

        if(data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()){
            // setDepositAmount(getNumFormat(data.walletBalance))
            setWithdrawAmount(getNumFormat(data.vaultBalance))
        }
    }, [selector.ethData.address, data])

    const handleDepositAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setDepositAmount(value)
        }
        if (!value) {
            setDepositAmount('')
        }
    }
    const handleWithdrawAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setWithdrawAmount(value)
        }
        if (!value) {
            setWithdrawAmount('')
        }
    }

    const approveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} approved` : `Failed to approve  ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleApprove = async () => {
        const userAddress = selector.ethData.address
        if (!!userAddress) {
            try {
                setApproveLoading(true)
                let farmAddress: any;
                let LPinstance: any
                if (data.poolId === -1) {
                    farmAddress = aquaAutoCompPoolAddress
                    LPinstance = await selectInstance(instType.PANCAKELP, aquaAddress, true)
                }
                else {
                    if (data.isNewFarm) {
                        farmAddress = gammaFarmAdddress
                    }
                    else {
                        farmAddress = aquaFarmAddress
                    }
                    const poolDetails = await poolInfo(farmAddress, data.poolId)
                    const lpAddress = poolDetails['want']
                    LPinstance = await selectInstance(instType.PANCAKELP, lpAddress, true)
                }
                await LPinstance.methods
                    .approve(farmAddress, maxAmount)
                    .send({ from: userAddress })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        approveNotification(type, receipt.transactionHash)
                        setApproveLoading(false)
                        setIsAllowance(true)
                    })
                    .on('error', function (error: any) {
                        approveNotification('failed')
                        setApproveLoading(false)
                    })
            }
            catch (error) {
                console.log("error==>", error)
                setApproveLoading(false)
            }
            finally {
                setApproveLoading(false)
            }
        }
    }

    const showNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const data: any = {
            type: type,
            message: '',
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(data))
    }

    const handleDepositAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(depositAmount) > 0 && data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()) {
            try {
                setDepositLoading(true)
                await handleDeposit(data.poolId, depositAmount, selector.ethData.address, showNotification, data)
                setDepositLoading(false)
                dispatch(updateUserLendingData(selector.ethData.address))
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setDepositLoading(false)
            }
        }
    }
    const handleWithdrawAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(withdrawAmount) > 0) {
            try {
                setWithdrawLoading(true)
                await handleWithdraw(data.poolId, withdrawAmount, selector.ethData.address, showNotification, data)
                setWithdrawLoading(false)
                dispatch(updateUserLendingData(selector.ethData.address))
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setWithdrawLoading(false)
            }
        }
    }
    const handleClaimEarning = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                setClaimLoading(true)
                await harvestLpTokens(data.poolId, selector.ethData.address, showNotification, data)
                setClaimLoading(false)
                dispatch(updateUserBalance(selector.ethData.address))
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setClaimLoading(false)
            }
        }
    }

    const handleClaimBoostEarning = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                setClaimBoostLoading(true)
                await earnAquaProfits(data.poolId, selector.ethData.address, showNotification, data)
                setClaimBoostLoading(false)
                dispatch(updateUserBalance(selector.ethData.address))
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setClaimBoostLoading(false)
            }
        }
    }

    const handleBuyToken = () => {
        if(data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()){
            history.push(`${Paths.swap}`, { tokenAdd: data.wantAddress })
        }
    }

    return (
        <Fragment>
            <ModalCard className='earning-card'>
                <ModalCardTitle>Total Earnings</ModalCardTitle>
                <EarnCont>
                    <div>
                        {portfolioLoading ? <LoadingText1 className='gamma-reward' /> :
                            <ModalCardTxt className='gamma-reward'>{`${parseFloat(data.userRewards).toFixed(4)} GAMMA`}</ModalCardTxt>
                        }
                        {portfolioLoading ? <LoadingText2 /> :
                            <ModalCardTxt>${parseFloat(data.userRewardsUSD).toFixed(4)}</ModalCardTxt>
                        }
                        <CardGreenBtn onClick={() => handleClaimEarning()} disabled={claimLoading}>
                            {claimLoading ? <Spinner /> : 'Collect'}
                        </CardGreenBtn>
                    </div>
                    <div>
                        {portfolioLoading ? <LoadingText1 className='gamma-reward' /> :
                            <ModalCardTxt className='gamma-reward'>{`${parseFloat(data.uranusRewards).toFixed(4)} GAMMA`}</ModalCardTxt>
                        }
                        {portfolioLoading ? <LoadingText2 /> :
                            <ModalCardTxt>${parseFloat(data.uranusRewardsUSD).toFixed(4)}</ModalCardTxt>
                        }
                        <CardGreenBtn onClick={() => handleClaimBoostEarning()} disabled={claimBoostLoading}>
                            {/* {claimBoostLoading ? <Spinner /> : 'Claim Boost'} */}
                            {claimBoostLoading ? <Spinner /> : data.strategyAddress.toLowerCase() == "0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9".toLowerCase() ?'Stake Boost': 'Claim Boost'}
                        </CardGreenBtn>
                    </div>
                </EarnCont>
            </ModalCard>
            {activeTab === 'deposit' ?
                <ModalCard>
                    <FlexSBCont>
                        <ModalCardTitle>In Wallet</ModalCardTitle>
                        {portfolioLoading ? <LoadingText3 className='gamma-reward' /> :
                            <UserBalTxt>{`${commaFy(parseFloat(data.walletBalance).toFixed(4))} ($${commaFy(parseFloat(data.walletBalanceUSD).toFixed(2))})`}</UserBalTxt>
                        }
                    </FlexSBCont>
                    <ModalInputCont>
                        <ModalInput disabled={data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()} placeholder="0" onChange={handleDepositAmountChanges} value={depositAmount && depositAmount} />
                        {data.walletBalanceUSD > 0.01 && data.address.toLowerCase() !== '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()?
                        <MaxBox onClick={() => setDepositAmount(getNumFormat(data.walletBalance))}>MAX</MaxBox>
                        :
                        <MaxBoxZero className={data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase() ? 'disabled':''} onClick={() => data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase() ?setDepositAmount(getNumFormat(data.walletBalance)): ""}>MAX</MaxBoxZero>
                        }
                    </ModalInputCont>
                    <CardBtnGroup>
                        <TransformBtn disabled={data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()} onClick={() => handleBuyToken()}>{`Buy  ${getTokenName(data.name)}`}</TransformBtn>
                        {isAllowance ?
                            <CardBtn onClick={() => handleDepositAmount()} disabled={depositLoading || data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()}>{depositLoading ? <Spinner /> : 'Deposit'}</CardBtn> :
                            <CardBtn onClick={() => handleApprove()} disabled={approveLoading || data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()}>{approveLoading ? <Spinner /> : 'Approve'}</CardBtn>
                        }
                    </CardBtnGroup>
                </ModalCard> :
                <ModalCard>
                    <FlexSBCont>
                        <ModalCardTitle>In Vault</ModalCardTitle>
                        {portfolioLoading ? <LoadingText3 className='gamma-reward' /> :
                            <UserBalTxt>{`${commaFy(parseFloat(data.vaultBalance).toFixed(4))} ($${commaFy(parseFloat(data.vaultBalanceUSD).toFixed(2))})`}</UserBalTxt>
                        }
                    </FlexSBCont>
                    <ModalInputCont>
                        <ModalInput disabled={data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()} placeholder="0" onChange={handleWithdrawAmountChanges} value={withdrawAmount && withdrawAmount} />
                        {data.vaultBalanceUSD > 0.01 && data.address.toLowerCase() !== '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase() ?
                        <MaxBox onClick={() => setWithdrawAmount(data.vaultBalance)}>MAX</MaxBox>
                        :
                        <MaxBoxZero onClick={() => data.address.toLowerCase() !== '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase() ? setWithdrawAmount(data.vaultBalance): ""}>MAX</MaxBoxZero>
                        }
                    </ModalInputCont>
                    <CardRedBtn onClick={() => handleWithdrawAmount()} disabled={withdrawLoading || data.withdrew === true}>
                        {withdrawLoading ? <Spinner /> : 'Withdraw'}
                    </CardRedBtn>
                </ModalCard>
            }
        </Fragment>
    );
};

export default BoostVaultAction