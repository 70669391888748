import { CardButton } from 'shared/styles/styled'
import { CardTitle, CardSubText } from 'shared/styles/globalText'
import { NewsWrapper, NewsContainer, NewsDataCard, NewsContent, NewsTitle, NewsInfo } from './style'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import fusion_pool_img from 'assets/images/fusion_pool_article.png'
import stader_partner from 'assets/images/stader_partner.png'
import bnbx_image from 'assets/images/bnbx_image.png'

const PlanetNews = () => {
    return (
        <NewsWrapper className='newsCont'>
            <CardTitle className="cardTitle">News & Updates</CardTitle>
            <NewsContainer className='newsCont'>
                <NewsDataCard>
                    <NewsContent>
                        <img className="news_img" onClick={() => window.open("https://planetfi.medium.com/concentrated-liquidity-pools-on-planet-c69518bb9be7", "_blank")} src={fusion_pool_img} alt="" />
                        <NewsTitle>Concentrated Liquidity Pools on Planet</NewsTitle>
                        <NewsInfo>We’re excited to announce that aggregated Fusion pools are now live on Planet, offering our users a simplified way to earn high…</NewsInfo>
                        <NewsInfo className='read-more' onClick={() => window.open("https://planetfi.medium.com/concentrated-liquidity-pools-on-planet-c69518bb9be7", "_blank")}>Read Full Article &gt;</NewsInfo>
                        
                    </NewsContent>
                </NewsDataCard>
                <NewsDataCard>
                    <NewsContent>
                        <img className="news_img" onClick={() => window.open("https://planetfi.medium.com/planet-expands-partnership-with-stader-labs-introducing-bnbx-to-green-planet-e4ab6f6f3dfc", "_blank")} src={bnbx_image} alt="" />
                        <NewsTitle>Planet Expands Partnership with Stader...</NewsTitle>
                        <NewsInfo>Planet is thrilled to announce the addition of BNBx to Green Planet. As you may know, we have recently collaborated with</NewsInfo>
                        <NewsInfo className='read-more' onClick={() => window.open("https://planetfi.medium.com/planet-expands-partnership-with-stader-labs-introducing-bnbx-to-green-planet-e4ab6f6f3dfc", "_blank")}>Read Full Article &gt;</NewsInfo>
                        
                    </NewsContent>
                </NewsDataCard>
                <NewsDataCard>
                    <NewsContent>
                        <img className="news_img" onClick={() => window.open("https://planetfi.medium.com/planet-partners-with-stader-labs-to-launch-bnbx-bnb-aggregated-pool-10b6ee94fb54", "_blank")} src={stader_partner} alt="" />
                        <NewsTitle>Concentrated Liquidity Pools on Planet</NewsTitle>
                        <NewsInfo>We’re excited to announce the launch of our very own BNBx-BNB Aggregated Thena Pool on Blue Planet! Stader Labs’ liquid...</NewsInfo>
                        <NewsInfo className='read-more' onClick={() => window.open("https://planetfi.medium.com/planet-partners-with-stader-labs-to-launch-bnbx-bnb-aggregated-pool-10b6ee94fb54", "_blank")}>Read Full Article &gt;</NewsInfo>
                        
                    </NewsContent>
                </NewsDataCard>
            </NewsContainer>
        </NewsWrapper>
    );
};

export default PlanetNews;