import axios from 'axios'
import {
  bnbAddress,
  BUSD_BNBLPAddress,
  gammatrollerAddress,
  discountLevelAddress,
  enterMarketTopic0,
  exitMarketTopic0,
  gammaAddress,
  gBnbAddress,
  gGamma,
  instType,
  level1Discount,
  level2Discount,
  level3Discount,
  selectInstance,
  approvalAmount,
  MAX_INT,
  convertToEther,
  noExponents,
  convertToWei,
  liquidationChecker,
  AQUA_GAMMALPAddress,
  AQUA_BNBLPAddress,
  gammaFarmAdddress,
  deadAddress,
  apiKey,
  swapLogsTopic0,
  oldgAqua,
  gAqua,
  GAMMA_BNBLPAddress,
  priceOracleAddress,
  gamma_symbol_hash,
  price_updated_hash,
  oldgGamma,
  firstTimeGammaDrippedBlock,
  gTokenAbi,
  priceOracleAbi,
  gammatrollerAbi,
  gamma_infinity_vault_abi,
  gamma_infinity_vault_address,
  aqua_infinity_vault_abi,
  aqua_infinity_vault_address,
} from './lendingAbi'
import { zeroAddress } from './SwapDexAbi'
import {
  balanceOfGtoken,
  borrowBalanceStored,
  exchangeRateStored,
  getAccountSnapshot,
  totalBorrows,
  underlying,
  allowance,
  supplyRatePerBlock,
  borrowRatePerBlock,
  borrowIndex,
  totalSupply,
  getCash,
  symbol,
  findLargest3,
  interestRateModel,
  totalReserves,
  gammatroller,
  decimals,
  exchangeRateStoredMulticall,
  totalSupplyMulticall,
  supplyRatePerBlockMulticall,
  borrowRatePerBlockMulticall,
  totalBorrowsMulticall,
  underlyingMulticall,
  getUnderlyingDecimalMulticall,
  getUnderlyingDecimalMulticallAlt,
  balanceOfGtokenMulticall,
  decimalsMulticall,
  allowanceMulticall,
  getCashMulticall,
  borrowRatePerBlockMulticallAlt,
  supplyRatePerBlockMulticallRev,
  latestParamsOnAccrueInterest
} from './gBNB'
import {
  accountAssets,
  borrowCaps,
  borrowGuardianPaused,
  closeFactorMantissa,
  gammaAccrued,
  gammaBorrowerIndex,
  gammaBorrowState,
  gammaInitialIndex,
  gammaSpeeds,
  gammaSupplierIndex,
  gammaSupplyState,
  getAccountLiquidity,
  getAllMarkets,
  getAssetsIn,
  getBlockNumber,
  getHypotheticalAccountLiquidity,
  liquidateCalculateSeizeTokens,
  liquidationIncentiveMantissa,
  markets,
  getAssetsInMulticall,
  marketsMulticall,
  marketsMulticallRevised,
  gammaSpeedsMulticall,
  borrowGuardianPausedMulticall
} from './gammatroller'
import planetGlobalObject  from 'global/GlobalVar'
import { marketTokenList, pfTokenList, pfTokenListRevised } from './pfTokenList'
import { getUnderlyingPrice } from './priceOracle'
import wallet from 'modules/wallet/wallet'
import { getTokenIcon } from 'shared/tokenIconList'
import { utilizationRate } from './interestRateModel'
import { balanceOf, stakedWantTokens, getTokenYield, stakedWantTokensMulticall, balanceOfMulticall } from './BlockChainMethods'
import { aquaAddress, gamma_reservoir, multicall_abi, multicall_address } from './abi'
import { constants } from 'os'
import { formatUnits } from '@ethersproject/units'
import { AnyMxRecord } from 'dns'
import { getInfinityVaultApy, getInfinityVaultBalanceNew } from '../block-chain/BlockChainMethods'
import { newPFApiBaseUrl, planetFinanceApiBaseUrl } from "service/global-constant";
import { anchor_apy, gBnbAbi } from 'modules/block-chain-green/lendingAbi'
import { getSupplyApyFromAPI, getGammaSupplyApyFromAPI, getAllPoolsInfo, getBorrowLimitParamsOnChangeRevised} from 'modules/block-chain-green/LendingBase'
const importAll = (r:any) => r.keys().map(r);

let totalBorrowedAmountValue: any = "";
let totalSupplyBalance: any = {};
let totalMarketLiquidity: any = [];
let borrowAndUsedLimitData: any = {};
let totalPlatformData: any = {};
export const getMarketLiquidity = async (gTokenAddress: string) => {
  try {
    if (gTokenAddress === gBnbAddress) {
      const bal = await wallet.web3.eth.getBalance(gTokenAddress)
      return bal
    } else {
      const underlyingToken = await underlying(gTokenAddress)
      const bal = await balanceOfGtoken(gTokenAddress, underlyingToken)
      return bal
    }
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getUnderlyingDecimal = async (gTokenAddress: string) => {
  const decimal = await decimals(await underlying(gTokenAddress));
  return decimal;
}

export const differenceApy = async (gTokenAddress: string) => {
  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365
    const _borrowRatePerBlock = convertToEther(await borrowRatePerBlock(gTokenAddress), 18)
    const _supplyRatePerBlock = convertToEther(await supplyRatePerBlock(gTokenAddress), 18)
    const x = (Math.pow(_borrowRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100
    const y = (Math.pow(_supplyRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100
    let Apy = x - y
    return Apy
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getBorrowApy = async (gTokenAddress: string, userAddress: string, blockNumber?: number) => {
  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365

    const _borrowRatePerBlock = convertToEther(await borrowRatePerBlock(gTokenAddress, blockNumber), 18)
    let borrowApy = (Math.pow(_borrowRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100

    const totalBorrows_ = convertToEther(await totalBorrows(gTokenAddress, blockNumber), await getUnderlyingDecimal(gTokenAddress))
    // parseFloat(convertToEther(await totalBorrows(gTokenAddress), await getUnderlyingDecimal(gTokenAddress)))

    if (userAddress !== null && userAddress !== '' && gTokenAddress.toLowerCase() !== gGamma.toLowerCase() && totalBorrows_ > 0) {
      const levelData = await getDiscountLevel(userAddress)
      const discount = levelData.discount / 2
      const diff = await differenceApy(gTokenAddress)
      borrowApy -= (discount * diff) / 100
    }
    return borrowApy * -1
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getBorrowApyMulticall = async (gTokenAddressArray: any, userAddress: string, supplyRatePerBlockArray: any, blockNumber?: number) => {
  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365
    const assetsLen = Object.keys(pfTokenList).length
    const levelData = await getDiscountLevel(userAddress)
    const _borrowRatePerBlockArr = await borrowRatePerBlockMulticall(gTokenAddressArray, blockNumber);// convertToEther(await borrowRatePerBlock(gTokenAddressArray, blockNumber), 18)
    // let borrowApy = (Math.pow(_borrowRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100
    const totalBorrows_ = await totalBorrowsMulticall(gTokenAddressArray, blockNumber);
    // const underlying_ = await underlyingMulticall(gTokenAddressArray);
    // getUnderlyingDecimalMulticall
    // const getBorrowApy_ = await getUnderlyingDecimalMulticall(gTokenAddressArray, underlying_, totalBorrows_, levelData, supplyRatePerBlockArray, _borrowRatePerBlockArr, userAddress);
    // console.log("getBorrowApy_ original", getBorrowApy_);
    let getBorrowApy_: any = [];
    for (let i = 0; i < assetsLen; i++) {
      let borrowApy = _borrowRatePerBlockArr[gTokenAddressArray[i].address];
      const totalBorrows = convertToEther(totalBorrows_[gTokenAddressArray[i].address], gTokenAddressArray[i].decimals)
      if (userAddress !== null && userAddress !== '' && gTokenAddressArray[i].address.toLowerCase() !== gGamma.toLowerCase() && totalBorrows > 0) {
        const discount = levelData.discount / 2
        const diff = _borrowRatePerBlockArr[gTokenAddressArray[i].address] - supplyRatePerBlockArray[gTokenAddressArray[i].address];
        borrowApy -= (discount * diff) / 100;
        //await differenceApy(gTokenAddress)
      }
      getBorrowApy_[gTokenAddressArray[i].address] = borrowApy * -1;
    }
  
    return getBorrowApy_
    // return true;
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getSupplyApy = async (gTokenAddress: string, userAddress: string, blockNumber?: number) => {
  userAddress = ''
  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365

    const _supplyRatePerBlock = convertToEther(await supplyRatePerBlock(gTokenAddress, blockNumber), 18)
    let supplyApy = (Math.pow(_supplyRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100

    //const totalBorrows_ = convertToEther(await totalBorrows(gTokenAddress, blockNumber), 18)

    // if (userAddress !== null && userAddress !== '' && gTokenAddress !== pfTokenList[1].address && totalBorrows_ > 0) {
    //   const levelData = await getDiscountLevel(userAddress)
    //   const discount = levelData.discount / 2
    //   const diff = await differenceApy(gTokenAddress)
    //   supplyApy += (discount * diff) / 100
    // }
    return supplyApy
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getSupplyApyMulticall = async (gTokenAddress: string, userAddress: string, blockNumber?: number) => {
  userAddress = ''
  const assetsLen = Object.keys(pfTokenList).length
  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365
    // totalSupplyMulticall
    const totalSupply = await totalSupplyMulticall(pfTokenList, assetsLen, 0, blockNumber);
    const _supplyRatePerBlock_new = await supplyRatePerBlockMulticall(totalSupply, pfTokenList, assetsLen, blockNumber)
    // console.log("_supplyRatePerBlock_new", _supplyRatePerBlock_new)
    return _supplyRatePerBlock_new;

  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getGammaBorrowApy = async (gTokenAddress: string) => {
  try {
    const blocksPerDay = 28800 // 3 seconds per block
    const daysPerYear = 365
    const gammaPrice = await getTokenPrice(gGamma)
    const gammaPerDay = parseFloat(convertToEther(await gammaSpeeds(gTokenAddress, gammatrollerAddress), 18)) * blocksPerDay
    const _totalBorrows = parseFloat(convertToEther(await totalBorrows(gTokenAddress), await getUnderlyingDecimal(gTokenAddress)))
    const assetPrice = await getTokenPrice(gTokenAddress)
    const borrowApy: any = _totalBorrows !== 0 ? 100 * (Math.pow(1 + (gammaPrice * gammaPerDay) / (_totalBorrows * assetPrice), daysPerYear) - 1) : 0
    //console.log("net borrow apy",gTokenAddress,borrowApy)
    return borrowApy
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getGammaBorrowApyMulticall = async (gTokenAddressArray: any, gammaPrice: any, gammaPerDay: any, assetPriceArray: any, decimalArr_: any) => {
  try {
    const blocksPerDay = 28800 // 3 seconds per block
    const daysPerYear = 365
    const totalBorrows = await totalBorrowsMulticall(gTokenAddressArray);
    // console.log("totalBorrows", totalBorrows)
    let borrowApyArray: any = [];
    for (var key in gTokenAddressArray) {
      let keyId = gTokenAddressArray[key].address;
      const _totalBorrows = parseFloat(convertToEther(totalBorrows[keyId], decimalArr_[keyId]));
      // console.log("gtokenAddress", keyId)
      borrowApyArray[keyId] = _totalBorrows !== 0 ? 100 * (Math.pow(1 + (gammaPrice * gammaPerDay[keyId]) / (_totalBorrows * assetPriceArray[keyId]), daysPerYear) - 1) : 0
    }
    //console.log("net borrow apy",gTokenAddress,borrowApy)
    return borrowApyArray
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getGammaSupplyApy = async (gTokenAddress: string) => {
  try {
    const blocksPerDay = 28800 // 3 seconds per block
    const daysPerYear = 365
    const gammaPrice = await getTokenPrice(gGamma)
    const gammaPerDay = parseFloat(convertToEther(await gammaSpeeds(gTokenAddress, gammatrollerAddress), 18)) * blocksPerDay
    const _totalSupply = parseFloat(convertToEther(await totalSupply(gTokenAddress), 8))
    const decimal = parseFloat(await getUnderlyingDecimal(gTokenAddress)) + 10;
    const exchangerate = parseFloat(convertToEther(await exchangeRateStored(gTokenAddress), decimal))
    const totalSupply_ = _totalSupply * exchangerate
    const assetPrice = await getTokenPrice(gTokenAddress)
    const supplyApy: any = totalSupply_ !== 0 ? 100 * (Math.pow(1 + (gammaPrice * gammaPerDay) / (totalSupply_ * assetPrice), daysPerYear) - 1) : 0
    //console.log("net supply apy",gTokenAddress,supplyApy)
    return supplyApy
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getGammaSupplyApyMulticall = async (gTokenAddressArray: any, gammaPrice: any, gammaPerDay: any, assetPriceArray: any, decimalArray: any) => {
  try {
    const blocksPerDay = 28800 // 3 seconds per block
    const daysPerYear = 365
    const assetsLen = Object.keys(gTokenAddressArray).length;
    const totalSupplyArray = await totalSupplyMulticall(gTokenAddressArray, assetsLen, 8);
    // exchangeRateStoredMulticall
    const exchangerate = await exchangeRateStoredMulticall(gTokenAddressArray, assetsLen, decimalArray); // optimise
    // const assetPriceArray = await getTotalUnderlyingPriceMulticall(gTokenAddressArray)
    let supplyApyArray: any = [];
    for (var key in gTokenAddressArray) {
      let keyId = gTokenAddressArray[key].address;
      let totalSupply_ = parseFloat(convertToEther(totalSupplyArray[keyId], 8)) * exchangerate[keyId];
      supplyApyArray[keyId] = totalSupply_ !== 0 ? 100 * (Math.pow(1 + (gammaPrice * gammaPerDay[keyId]) / (totalSupply_ * assetPriceArray[keyId]), daysPerYear) - 1) : 0
    }
    // console.log("getGammaSupplyApyMulticall", supplyApyArray)
    return supplyApyArray;
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getNetApy = async (userAddress: string, tokenPriceArray?: any, totalSupplyArray?: any, borrowBalanceArray?: any, totalSupplyBalance?: any, totalBorrowBalance?: any): Promise<any> => {
  try {
    const assetsLen = Object.keys(pfTokenList).length
    let apyWithoutGamma = 0
    let apyWithGamma = 0
    const gammaPrice = await getTokenPrice(gGamma);
    const gammaPerDay: any = await gammaSpeedsMulticall(pfTokenList, gammatrollerAddress);
    const decimalArray = await getUnderlyingDecimalMulticallAlt(pfTokenList);
    const assetPriceArray = tokenPriceArray ? tokenPriceArray : await getTotalUnderlyingPriceMulticall(pfTokenList)
    // const totalSupply = await totalSupplyMulticall(pfTokenList, assetsLen, 0, blockNumber);
    const supplyApyArray: any = await getSupplyApyMulticall(pfTokenList, userAddress)
    // console.log("supplyApyArray", supplyApyArray)
    const borrowApyArray: any = await getBorrowApyMulticall(pfTokenList, userAddress, supplyApyArray)
    const getGammaSupplyApyArray: any = await getGammaSupplyApyMulticall(pfTokenList, gammaPrice, gammaPerDay, assetPriceArray, decimalArray);
    const getGammaBorrowApyArray: any = await getGammaBorrowApyMulticall(pfTokenList, gammaPrice, gammaPerDay, assetPriceArray, decimalArray)
    
    for (let i = 0; i < assetsLen; i++) {
      const asset = pfTokenList[i].address
      const price = tokenPriceArray[asset] ? tokenPriceArray[asset] : 0;// await getTokenPrice(asset)

      const supplyBal = totalSupplyArray[asset] ? totalSupplyArray[asset] : 0;//getSupplyBalance(asset, userAddress)

      const borrowBal = borrowBalanceArray[asset] ? borrowBalanceArray[asset] : 0;//getBorrowBalance(asset, userAddress)
      // console.log("original borrowBal", borrowBal)
      // console.log("multical borrowBal", borrowBalanceArray[asset] , asset)
      const supplyApy = supplyApyArray[asset] ? supplyApyArray[asset] : 0; //supplyApyArray[asset];//getSupplyApy(asset, userAddress)
      const borrowApy = borrowApyArray[asset] ? borrowApyArray[asset] : 0; //getBorrowApy(asset, userAddress)
      const gammaSupplyApy = getGammaSupplyApyArray[asset] && isFinite(getGammaSupplyApyArray[asset]) ? getGammaSupplyApyArray[asset] : 0;//getGammaSupplyApy(asset)

      const gammaBorrowApy = getGammaBorrowApyArray[asset] && isFinite(getGammaBorrowApyArray[asset]) ? getGammaBorrowApyArray[asset] : 0;//getGammaBorrowApy(asset)



      const promArr: any = [supplyBal, borrowBal, supplyApy, borrowApy, gammaSupplyApy, gammaBorrowApy];//Promise.all([supplyBal, borrowBal, supplyApy, borrowApy, gammaSupplyApy, gammaBorrowApy])
      // console.log("asset details", asset, supplyBal, borrowBal, supplyApy, borrowApy, gammaSupplyApy, gammaBorrowApy)
      apyWithoutGamma +=
        (parseFloat(promArr[0]) * price * parseFloat(promArr[2])) / 100 - (-1 * parseFloat(promArr[1]) * price * parseFloat(promArr[3])) / 100


      let netSupplyApy =
        (parseFloat(promArr[0]) * parseFloat(price) * parseFloat(promArr[2])) / 100 +
        (parseFloat(promArr[0]) * parseFloat(price) * parseFloat(promArr[4])) / 100 +
        (parseFloat(promArr[1]) * parseFloat(price) * parseFloat(promArr[5])) / 100
        // console.log("netSupplyApy", asset, netSupplyApy)
      netSupplyApy = !Number.isNaN(netSupplyApy) ? netSupplyApy : 0;

      let netBorrowApy =
        (-1 * parseFloat(promArr[1]) * parseFloat(price) * parseFloat(promArr[3])) / 100
        // console.log("netBorrowApy", netBorrowApy)
      netBorrowApy = !Number.isNaN(netBorrowApy) ? netBorrowApy : 0;
      const val = (netSupplyApy - netBorrowApy);
      //if(asset.toLowerCase() === gBnbAddress.toLowerCase()){
      //console.log(asset,"net apy",
      // (parseFloat(promArr[0]) * parseFloat(price) * parseFloat(promArr[2])) / 100 +
      // (parseFloat(promArr[0]) * parseFloat(price) * parseFloat(promArr[4])) / 100 +
      // (parseFloat(promArr[1]) * parseFloat(price) * parseFloat(promArr[5])) / 100,
      // (-1 * parseFloat(promArr[1]) * parseFloat(price) * parseFloat(promArr[3])) / 100
      //val
      //)
      //}
      if (!isNaN(val)) {
        apyWithGamma += val
      }
      if(asset === "0x5e11eC8Ec1a57A93E5ddc8AD950cea60EAd9014b"){
        // console.log(asset,"net apy with Gamma", asset, supplyBal, borrowBal, supplyApy, borrowApy, gammaSupplyApy, gammaBorrowApy, apyWithGamma, apyWithoutGamma, netSupplyApy, netBorrowApy, val)
      }
      // console.log(asset,"net apy with Gamma", apyWithGamma, asset)
      // console.log(asset,"net apy without Gamma", apyWithoutGamma, asset)

      if (i === assetsLen - 1) {
        if (apyWithGamma > 0 || apyWithoutGamma > 0) {
          const bal: any = totalSupplyBalance !== undefined ? totalSupplyBalance : (await getTotalSupplyBalance(userAddress)).total;
          return {
            apyWithoutGamma: bal > 0 ? 100 * (apyWithoutGamma / bal) : 0,
            apyWithGamma: bal > 0 ? 100 * (apyWithGamma / bal) : 0,
          }
        } else if (apyWithGamma < 0 || apyWithoutGamma < 0) {
          const bal: any = totalBorrowBalance !== undefined ? totalBorrowBalance : await getTotalBorrowBalance(userAddress);
          return {
            apyWithoutGamma: bal > 0 ? 100 * (apyWithoutGamma / bal) : 0,
            apyWithGamma: bal > 0 ? 100 * (apyWithGamma / bal) : 0,
          }
        } else {
          return {
            apyWithoutGamma: 0,
            apyWithGamma: 0,
          }
        }
      }
    }
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getSupplyBalance = async (gTokenAddress: string, userAddress: string) => {
  try {
    const data = await getAccountSnapshot(userAddress, gTokenAddress)
    const result = convertToEther(data[1], await getUnderlyingDecimal(gTokenAddress)) * convertToEther(data[3], 18)
    // console.log("data in getSupplyBalance", data)
    // console.log("result in getSupplyBalance", result)
    return result
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getBorrowBalance = async (gTokenAddress: string, userAddress: string) => {
  try {
    const data = await borrowBalanceStored(userAddress, gTokenAddress)
    const result = convertToEther(data, await getUnderlyingDecimal(gTokenAddress))
    return result
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getBorrowBalanceMulticall = async (gTokenAddressArray: any, userAddress: string, underlyingDecimal?: any) => {
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress);
  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format: any = [];
  let borrowBalanceArray: any = [];
  if (gTokenAddressArray.length === 0) {
    return borrowBalanceArray;
  }
  try {

    for (let i = 0; i < gTokenAddressArray.length; i++) {
      const inst: any = await selectInstance(instType.gBNB, gTokenAddressArray[i].address)
      targets.push(gTokenAddressArray[i].address);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.borrowBalanceStored(userAddress)._method, [userAddress]));
      callDatas.push(data);
      ouput_format.push(inst.methods.borrowBalanceStored(userAddress)._method.outputs)
    }
    const aggregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = (await do_split(results, gTokenAddressArray.length));
    if (split_arr[0].length > 0) {
      for (let i = 0; i < gTokenAddressArray.length; i++) {
        let decimal = pfTokenListRevised[gTokenAddressArray[i].address] ? pfTokenListRevised[gTokenAddressArray[i].address].decimals : 0;
        // console.log("decimal inside borrowBalanceArray", gTokenAddressArray[i].address,decimal)
        if(gTokenAddressArray[i].address === "0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4" || gTokenAddressArray[i].address === "0x88FD42E447d39C3259b53623f2536bd855e47C48")
          decimal = 6;
        if (split_arr[0][i][0] !== 0) {
          borrowBalanceArray[gTokenAddressArray[i].address] = convertToEther(split_arr[0][i][0], decimal);
        } else {
          borrowBalanceArray[gTokenAddressArray[i].address] = 0;
        }
      }
    }
    // console.log("borrowBalanceArray", borrowBalanceArray)
    return borrowBalanceArray;
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getCurrentBalance = async (gTokenAddress: string, userAddress: string) => {
  try {
    const bal = convertToEther(await balanceOfGtoken(userAddress, gTokenAddress), await getUnderlyingDecimal(gTokenAddress))
    const exchangeRate = convertToEther(await exchangeRateStored(gTokenAddress), 18)
    const result = bal * exchangeRate
    return result
  } catch (e) {
    console.log(e)
    return 0
  }
}

const getReserves = async (tokenAddress: string, blockNumber?: number) => {
  try {
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data =
      blockNumber === undefined
        ? await tokenInstance.methods.getReserves().call()
        : await tokenInstance.methods.getReserves().call(undefined, blockNumber)
    return data
  } catch (e) {
    console.log(e)
    return {
      0: 0,
      1: 0,
      2: 0,
      _reserve0: 0,
      _reserve1: 0,
      _blockTimestampLast: 0,
    }
  }
}

export const getBnbPrice = async (blockNumber?: number) => {
  const reserves = await getReserves(BUSD_BNBLPAddress, blockNumber)
  const getBNBReserve = reserves._reserve0
  const getUSDReserve = reserves._reserve1
  const bnbPrice = getUSDReserve / getBNBReserve
  return bnbPrice
}

const getPair = async (factoryAddress: string, token1: string, token2: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.FACTORY, factoryAddress)
    const data = await tokenInstance.methods.getPair(token1, token2).call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}
export const token1 = async (tokenAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.token0().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const getTokenPrice = async (tokenAddress: string, blockNumber?: number) => {
  const decimal = 36 - (await getUnderlyingDecimal(tokenAddress));
  const priceInBnb = convertToEther(await getUnderlyingPrice(tokenAddress, blockNumber), decimal)
  return priceInBnb

}

export const getGTokenPrice = async (tokenAddress: string, blockNumber?: number) => {
  const underlyingPrice = await getTokenPrice(tokenAddress, blockNumber)
  const exchangeRate = await exchangeRateStored(tokenAddress, blockNumber)
  const decimal = parseFloat(await getUnderlyingDecimal(tokenAddress)) + 10;
  const oneGTokenInUnderlying = convertToEther(exchangeRate, decimal)
  return parseFloat(oneGTokenInUnderlying) * underlyingPrice
}

export const getGTokenPriceRevised = async (tokenAddressArray: any, listLength: number, totalUnderlyingPriceArray: any, decimalArr: any, exchangeRateArray: any, blockNumber?: number) => {
  let gTokenPriceArray: any = [];
  for (var key in tokenAddressArray){
    const underlyingPrice = totalUnderlyingPriceArray[tokenAddressArray[key].address];//await getTokenPrice(tokenAddress, blockNumber)
    const exchangeRate = exchangeRateArray[tokenAddressArray[key].address];//await exchangeRateStored(tokenAddress, blockNumber)
    const decimal = decimalArr[tokenAddressArray[key].address];//parseFloat(await getUnderlyingDecimal(tokenAddress)) + 10;
    const oneGTokenInUnderlying = exchangeRate;//convertToEther(exchangeRate, decimal)
    gTokenPriceArray[tokenAddressArray[key].address] = parseFloat(oneGTokenInUnderlying) * underlyingPrice
  }
  return gTokenPriceArray;  
}

const getAllSupplyBalanceMulticall = async (userAddress: string, underlyingDecimalArray?: any) => {
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const assetsLen = Object.keys(pfTokenList).length
  if (assetsLen === 0) {
    return 0;
  }

  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format: any = [];
  // const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
  // const data = await inst.methods.getAccountSnapshot(account).call()
  let supplyBalanceArr: any = [];
  try {
    for (let i = 0; i < assetsLen; i++) {
      const inst: any = await selectInstance(instType.gBNB, pfTokenList[i].address)
      targets.push(pfTokenList[i].address);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.getAccountSnapshot(userAddress)._method, [userAddress]));
      callDatas.push(data);
      ouput_format.push(inst.methods.getAccountSnapshot(userAddress)._method.outputs)
    }
    const agregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }

    for (let i = 0; i < agregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], agregated_data[1][i]))
    }
    const split_arr = (await do_split(results, assetsLen));
    if (split_arr[0].length > 0) {

      for (let i = 0; i < assetsLen; i++) {
        if (+split_arr[0][i][1] !== 0 && +split_arr[0][i][3] !== 0 && pfTokenList[i].decimals !== undefined) {

          supplyBalanceArr[pfTokenList[i].address] = convertToEther(split_arr[0][i][1], pfTokenList[i].decimals) * convertToEther(split_arr[0][i][3], 18);
        } else {
          supplyBalanceArr[pfTokenList[i].address] = 0;
        }
      }
    }
    // console.log("supplyBalanceArr", supplyBalanceArr)
    return supplyBalanceArr;
  } catch (error) {
    console.log(error)
  }
}

export const convertToEther_ = (data: any, decimals: number): any => {
  data = noExponents(data)
  return noExponents(formatUnits(data.toString(), decimals)) //decimals = 18,8
}

const getTotalUnderlyingPriceMulticall = async (addressArr: any, blockNumber?: number) => {
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format: any = [];
  let underLyingPriceDataArray: any = [];
  const assetsLen = Object.keys(addressArr).length
  blockNumber = undefined;
  if (assetsLen === 0) {
    return 0;
  }

  try {
    const inst: any = await selectInstance(instType.priceOracle, priceOracleAddress)
    for (let i = 0; i < assetsLen; i++) {
      const element = addressArr[i].address;
      targets.push(priceOracleAddress);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.getUnderlyingPrice(element)._method, [element]));
      callDatas.push(data);
      ouput_format.push(inst.methods.getUnderlyingPrice(element)._method.outputs)
    }
    const aggregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());
    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = (await do_split(results, assetsLen));
    if (split_arr[0].length > 0) {
      for (let i = 0; i < assetsLen; i++) {
        const element = addressArr[i].address;
        // console.log("decimal Rev ",element,+pfTokenListRevised[element].decimals)
        const underlyingDecimal = pfTokenListRevised[element] ? +pfTokenListRevised[element].decimals : 0;
        const decimal = 36 - underlyingDecimal;//(await getUnderlyingDecimal(element));
        underLyingPriceDataArray[element] = parseFloat(convertToEther(split_arr[0][i][0], decimal));
        // console.log("underLyingPriceDataArray",element,  underLyingPriceDataArray[element] )
      }
    }
    // console.log("underLyingPriceDataArray", underLyingPriceDataArray)
    return underLyingPriceDataArray;
  } catch (error) {
    console.log("error", error)
  }
}

export const getTotalSupplyBalance = async (userAddress: string, priceArray?: any, supplyArray?: any, decimalArray?: any) => {
  // if (totalSupplyBalance.total !== undefined) {
  //   return totalSupplyBalance;
  // }
  try {
    const assetsLen = Object.keys(pfTokenList).length
    let res = 0
    let suppliedAmountWithCollateral = 0
    let suppliedAmountWithoutCollateral = 0
    let out: any = {}

    priceArray = priceArray ? priceArray : await getTotalUnderlyingPriceMulticall(pfTokenList);
    supplyArray = supplyArray ? supplyArray : await getAllSupplyBalanceMulticall(userAddress, decimalArray);
    let isGivenAsCollateralMulticallData = await isGivenAsCollateralMulticall(pfTokenList, assetsLen, userAddress)
    for (let i = 0; i < assetsLen; i++) {
      const element = pfTokenList[i].address;
      const price = priceArray[element] ? priceArray[element] : 0;//await getTokenPrice(element)
      const suppliedBal = supplyArray[element] ? supplyArray[element] * price : 0;//(await getSupplyBalance(element, userAddress)) * price
      res += suppliedBal
      // if (await isGivenAsCollateral(element, userAddress)) {
      //   suppliedAmountWithCollateral += suppliedBal
      // }
      if (isGivenAsCollateralMulticallData[element].isAvailForCollateral) {
        suppliedAmountWithCollateral += suppliedBal
      }
      suppliedAmountWithoutCollateral += suppliedBal
      out[element] = suppliedBal
      if (i === assetsLen - 1) {
        out['suppliedAmountWithoutCollateral'] = suppliedAmountWithoutCollateral
        out['suppliedAmountWithCollateral'] = suppliedAmountWithCollateral
        out['total'] = res
        totalSupplyBalance = out
        return out;
      }
    }
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getTotalSupplyBalanceIncludingCollateral = async (userAddress: string, totalUnderlyingPriceArray?: any, decimalArray?: any) => {
  try {
    const assetsIn = await getAssetsIn(userAddress, gammatrollerAddress)
    // const market = await marketsMulticall(pfTokenList, assetLength, gammatrollerAddress, assetsIn)
    totalUnderlyingPriceArray = totalUnderlyingPriceArray ? totalUnderlyingPriceArray : await getTotalUnderlyingPriceMulticall(pfTokenList);
    const assetsLen = Object.keys(pfTokenList).length;
    let marketData = await isGivenAsCollateralMulticall(pfTokenList, assetsLen, userAddress)
    if (decimalArray === undefined) {
      decimalArray = await getUnderlyingDecimalMulticallAlt(pfTokenList);
    }

    let supplyArray: any = await getAllSupplyBalanceMulticall(userAddress, decimalArray);
    let res = 0
    for (let i = 0; i < assetsIn.length; i++) {
      const element = assetsIn[i]
      // const market = await markets(element, gammatrollerAddress)
      // const collateralFactor = convertToEther(market.collateralFactorMantissa, 18)
      // const price = await getTokenPrice(element)
      // const suppliedBal = (await getSupplyBalance(element, userAddress)) * price
      // res += (collateralFactor / 100) * suppliedBal
      const collateralFactor = marketData[element] ? marketData[element].collateralFactor / 100 : 0;
      const price = totalUnderlyingPriceArray[element] ? totalUnderlyingPriceArray[element] : 0;
      const suppliedBal = supplyArray[element] ? supplyArray[element] * price : 0;
      res += (collateralFactor / 100) * suppliedBal
      if (i === assetsIn.length - 1) {
        return res * 100
      }
    }
    return 0
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getTotalBorrowBalance = async (userAddress: string, totalUnderlyingPriceArray?: any, borrowBalance?: any, multicalAssetsArray?: any, underLyingDecimal?: any) => {
  // if(totalBorrowedAmountValue !== ""){
  //   // console.log("totalBorrowedAmountValue in if", totalBorrowedAmountValue)
  //   return totalBorrowedAmountValue
  // }
  // userAddress = "0xF85b2d2EF26bfF5C7BB86CA8880ce6bFa4519D92";//"0x26Fe38f35C47579324bDEBc93D3b87eE0356fEC6"; 
  try {
    let assetsIn: any;
    let multicalAssetsArr: any;
    let priceArray: any;
    let borrowBalanceArray: any;
    if (underLyingDecimal === undefined) {
      underLyingDecimal = await getUnderlyingDecimalMulticallAlt(pfTokenList);
    }

    multicalAssetsArr = multicalAssetsArray ? multicalAssetsArray : await getAssetsInMulticall(userAddress, gammatrollerAddress);
    priceArray = totalUnderlyingPriceArray ? totalUnderlyingPriceArray : await getTotalUnderlyingPriceMulticall(multicalAssetsArr);
    borrowBalanceArray = borrowBalance ? borrowBalance : await getBorrowBalanceMulticall(multicalAssetsArr, userAddress, underLyingDecimal);
    // console.log("priceArray", priceArray)
    // console.log("multicalAssetsArr", multicalAssetsArr)
    let totalBorrowedAmount = 0
    totalBorrowedAmountValue = 0;
    for (let i = 0; i < multicalAssetsArr.length; i++) {
      const element = multicalAssetsArr[i].address
      const price = priceArray[element] ? priceArray[element] : 0;//await getTokenPrice(element)
      totalBorrowedAmount += borrowBalanceArray[element] ? borrowBalanceArray[element] * price : 0; //(await getBorrowBalance(element, userAddress)) * price
      // console.log("borrowBalance Amount ", element, borrowBalanceArray[element] * price)
      if (i === multicalAssetsArr.length - 1) {
        totalBorrowedAmountValue = totalBorrowedAmount;

        return totalBorrowedAmount
      }
    }
    return 0
  } catch (e) {
    console.log(e)
    return 0
  }
}

enum Type {
  supply = 1,
  withdraw = 2,
  borrow = 3,
  repay = 4,
}

export const getBorrowLimitParamsOnChange = async (
  isUsingAsCollateral: boolean,
  gTokenAddress: string,
  type: number,
  amountGivenInEth: number,
  userCurrentBorrowLimitInUsd: number,
  userTotalBorrowBal: number
) => {
  switch (type) {
    case Type.supply: {
      if (isUsingAsCollateral) {
        let newBorrowLimit = 0
        newBorrowLimit =
          userCurrentBorrowLimitInUsd +
          amountGivenInEth *
          (await getTokenPrice(gTokenAddress)) *
          parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))

        return {
          borrowLimit: newBorrowLimit,
          usedBorrowLimit: newBorrowLimit > 0 ? userTotalBorrowBal / newBorrowLimit : 0,
          usedBorrowLimitInPercentage: newBorrowLimit > 0 ? (userTotalBorrowBal / newBorrowLimit) * 100 : 0,
        }
      } else {
        return {
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: userTotalBorrowBal / userCurrentBorrowLimitInUsd,
          usedBorrowLimitInPercentage: (userTotalBorrowBal / userCurrentBorrowLimitInUsd) * 100,
        }
      }
    }

    case Type.withdraw: {
      if (isUsingAsCollateral) {
        let newBorrowLimit = 0

        let newDiff =
          amountGivenInEth *
          (await getTokenPrice(gTokenAddress)) *
          parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))

        newBorrowLimit = newDiff < userCurrentBorrowLimitInUsd ? userCurrentBorrowLimitInUsd - newDiff : 0

        return {
          borrowLimit: newBorrowLimit,
          usedBorrowLimit: newBorrowLimit > 0 ? userTotalBorrowBal / newBorrowLimit : 0,
          usedBorrowLimitInPercentage: newBorrowLimit > 0 ? (userTotalBorrowBal / newBorrowLimit) * 100 : 0,
        }
      } else {
        return {
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: userTotalBorrowBal / userCurrentBorrowLimitInUsd,
          usedBorrowLimitInPercentage: (userTotalBorrowBal / userCurrentBorrowLimitInUsd) * 100,
        }
      }
    }

    case Type.borrow: {
      if (userCurrentBorrowLimitInUsd > 0) {
        let newTotalBorrowBalance = userTotalBorrowBal + amountGivenInEth * (await getTokenPrice(gTokenAddress))

        newTotalBorrowBalance = newTotalBorrowBalance >= userCurrentBorrowLimitInUsd ? userCurrentBorrowLimitInUsd : newTotalBorrowBalance

        return {
          usedBorrowLimit: newTotalBorrowBalance > 0 ? (newTotalBorrowBalance / userCurrentBorrowLimitInUsd) : 0,
          usedBorrowLimitInPercentage: newTotalBorrowBalance > 0 ? (newTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }
      } else {
        return {
          usedBorrowLimit: 0,
          usedBorrowLimitInPercentage: 0,
        }
      }
    }

    case Type.repay: {
      if (userCurrentBorrowLimitInUsd > 0) {
        let newTotalBorrowBalance = userTotalBorrowBal - amountGivenInEth * (await getTokenPrice(gTokenAddress))

        newTotalBorrowBalance = newTotalBorrowBalance <= 0 ? 0 : newTotalBorrowBalance

        return {
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: newTotalBorrowBalance > 0 ? newTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: newTotalBorrowBalance > 0 ? (newTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }
      } else {
        return {
          usedBorrowLimit: 0,
          usedBorrowLimitInPercentage: 0,
        }
      }
    }

    default:
      return null
  }
}

export const getParamsWhenBorrowLimitIsChanged = async (
  isUsingAsCollateral: boolean,
  gTokenAddress: string,
  type: number,
  userCurrentBorrowLimitInUsd: number,
  userTotalBorrowBalance: number,
  usedBorrowLimit: number,
  userAddress: string
) => {
  switch (type) {
    case Type.supply: {
      usedBorrowLimit = usedBorrowLimit > 0 ? usedBorrowLimit : 0.0001

      if (isUsingAsCollateral) {
        let newBorrowLimit = 0

        newBorrowLimit = userTotalBorrowBalance / usedBorrowLimit

        let amountGivenInEth =
          ((newBorrowLimit - userCurrentBorrowLimitInUsd) / (await getTokenPrice(gTokenAddress))) *
          parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))

        return {
          amountGivenInEth: amountGivenInEth,
          amountGivenInUsd: amountGivenInEth * (await getTokenPrice(gTokenAddress)),
          borrowLimit: newBorrowLimit,
          usedBorrowLimit: newBorrowLimit > 0 ? userTotalBorrowBalance / newBorrowLimit : 0,
          usedBorrowLimitInPercentage: newBorrowLimit > 0 ? (userTotalBorrowBalance / newBorrowLimit) * 100 : 0,
        }
      } else {
        return {
          amountGivenInEth: 0,
          amountGivenInUsd: 0,
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: userCurrentBorrowLimitInUsd > 0 ? userTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: userCurrentBorrowLimitInUsd > 0 ? (userTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }
      }
    }

    case Type.withdraw: {
      if (isUsingAsCollateral) {
        let newTotalBorrowLimit = 0

        usedBorrowLimit = usedBorrowLimit >= 1 ? 1 : usedBorrowLimit

        let newDiff = userCurrentBorrowLimitInUsd - userTotalBorrowBalance / usedBorrowLimit

        newTotalBorrowLimit = userCurrentBorrowLimitInUsd - newDiff

        let amountGivenInEth =
          (newDiff / (await getTokenPrice(gTokenAddress))) *
          parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))

        return {
          amountGivenInEth: amountGivenInEth,
          amountGivenInUsd: amountGivenInEth * (await getTokenPrice(gTokenAddress)),
          borrowLimit: newTotalBorrowLimit,
          usedBorrowLimit: newTotalBorrowLimit > 0 ? userTotalBorrowBalance / newTotalBorrowLimit : 0,
          usedBorrowLimitInPercentage: newTotalBorrowLimit > 0 ? (userTotalBorrowBalance / newTotalBorrowLimit) * 100 : 0,
        }
      } else {
        return {
          amountGivenInEth: 0,
          amountGivenInUsd: 0,
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: userCurrentBorrowLimitInUsd > 0 ? userTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: userCurrentBorrowLimitInUsd > 0 ? (userTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }
      }
    }

    case Type.borrow: {
      let amountGivenInEth = usedBorrowLimit * userCurrentBorrowLimitInUsd - userTotalBorrowBalance
      amountGivenInEth = amountGivenInEth <= 0 ? 0 : amountGivenInEth
      amountGivenInEth = amountGivenInEth / (await getTokenPrice(gTokenAddress))

      if (userCurrentBorrowLimitInUsd > 0) {
        let newTotalBorrowBalance = userTotalBorrowBalance + amountGivenInEth

        newTotalBorrowBalance = newTotalBorrowBalance >= userCurrentBorrowLimitInUsd ? userCurrentBorrowLimitInUsd : newTotalBorrowBalance

        return {
          amountGivenInEth: amountGivenInEth,
          amountGivenInUsd: amountGivenInEth * (await getTokenPrice(gTokenAddress)),
          usedBorrowLimit: userCurrentBorrowLimitInUsd > 0 ? newTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: userCurrentBorrowLimitInUsd > 0 ? (newTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }
      } else {
        return {
          amountGivenInEth: 0,
          amountGivenInUsd: 0,
          usedBorrowLimit: userCurrentBorrowLimitInUsd > 0 ? userTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: userCurrentBorrowLimitInUsd > 0 ? (userTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }
      }
    }

    case Type.repay: {
      if (userCurrentBorrowLimitInUsd > 0) {
        let amountGivenInEth = userTotalBorrowBalance - usedBorrowLimit * userCurrentBorrowLimitInUsd
        amountGivenInEth = amountGivenInEth / (await getTokenPrice(gTokenAddress))

        let newTotalBorrowBalance = userTotalBorrowBalance - amountGivenInEth

        newTotalBorrowBalance = newTotalBorrowBalance <= 0 ? 0 : newTotalBorrowBalance

        return {
          amountGivenInEth: amountGivenInEth,
          amountGivenInUsd: amountGivenInEth * (await getTokenPrice(gTokenAddress)),
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: userCurrentBorrowLimitInUsd > 0 ? newTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: userCurrentBorrowLimitInUsd > 0 ? (newTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }
      } else {
        return {
          amountGivenInEth: 0,
          amountGivenInUsd: 0,
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: userCurrentBorrowLimitInUsd > 0 ? userTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: userCurrentBorrowLimitInUsd > 0 ? (userTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }
      }
    }

    default:
      return null
  }
}

export const getBorrowAndUsedLimit = async (userAddress: string, borrowBalance?: number, totalUnderlyingPriceArray?: any, borrowBalanceArray?: any, decimalArray?: any) => {
  
  try {
    totalUnderlyingPriceArray = totalUnderlyingPriceArray ? totalUnderlyingPriceArray : await getTotalUnderlyingPriceMulticall(pfTokenList);
    decimalArray = decimalArray ? decimalArray : await getUnderlyingDecimalMulticallAlt(pfTokenList);
    const suppliedWithCollateral: any = await getTotalSupplyBalanceIncludingCollateral(userAddress, totalUnderlyingPriceArray, decimalArray)
    const totalBorrowedAmount: any = borrowBalance !== undefined ? borrowBalance : await getTotalBorrowBalance(userAddress); //!== undefined ? totalBorrowedAmountValue : await getTotalBorrowBalance(userAddress);
    // console.log("borrowBalance in borrowAndUsedLimitData if not already set ", totalBorrowedAmount);
    borrowAndUsedLimitData = {
      totalBorrowed: totalBorrowedAmount,
      borrowLimit: suppliedWithCollateral,
      usedBorrowLimit: suppliedWithCollateral > 0 ? totalBorrowedAmount / suppliedWithCollateral : 0,
      usedBorrowLimitInPercentage: suppliedWithCollateral > 0 ? (totalBorrowedAmount / suppliedWithCollateral) * 100 : 0,
    }
    // console.log("borrowAndUsedLimitData", borrowAndUsedLimitData)
    return borrowAndUsedLimitData;
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const isGivenAsCollateral = async (gTokenAddress: string, userAddress: string) => {
  const assetIn = await getAssetsInMulticall(userAddress, gammatrollerAddress);//await getAssetsIn(userAddress, gammatrollerAddress);
  //const index: any = assetIn.includes(gTokenAddress)
  let index = assetIn.some((e: any) => e.address === gTokenAddress);

  const market = await markets(gTokenAddress, gammatrollerAddress)
  const collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
  const isAvailForCollateral = collateralFactor > 0 ? true : false
  return isAvailForCollateral && index ? true : false
}

export const getPendingRewardsUsingMulticall = async (userAddress: any) => {

  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);

  const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress);

  const asset_inst = await selectInstance(instType.gToken, gGamma);

  //Supply side arrays
  let gammaSupplyStateArr: any = []
  let gammaSpeedsArr: any = []
  let getBlockNumberArr: any = []
  let totalSupplyArr: any = []
  let gammaSupplierIndexArr: any = []
  let gammaInitialIndexArr: any = []
  let balanceOfGtokenArr: any = []

  //Borrow side arrays
  let gammaBorrowStateArr: any = []
  let borrowIndexArr: any = []
  let totalBorrowsArr: any = []
  let gammaBorrowerIndexArr: any = []
  let borrowBalanceStoredArr: any = []

  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format: any = [];

  const assetsIn: any = await getAllMarkets(gammatrollerAddress)

  assetsIn.forEach(async (asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSupplyState(asset)._method, [asset]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaSupplyState(asset)._method.outputs)
  })

  assetsIn.forEach(async (asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSpeeds(asset)._method, [asset]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaSpeeds(asset)._method.outputs)
  })

  assetsIn.forEach(async (asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.getBlockNumber()._method, []));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.getBlockNumber()._method.outputs)
  })

  assetsIn.forEach(async (asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.totalSupply()._method, []));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.totalSupply()._method.outputs)
  })

  assetsIn.forEach(async (asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSupplierIndex(asset, userAddress)._method, [asset, userAddress]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaSupplierIndex(asset, userAddress)._method.outputs)
  })

  assetsIn.forEach(async (asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaInitialIndex()._method, []));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaInitialIndex()._method.outputs)
  })

  assetsIn.forEach(async (asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.balanceOf(userAddress)._method, [userAddress]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.balanceOf(userAddress)._method.outputs)
  })

  assetsIn.forEach(async (asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaBorrowState(asset)._method, [asset]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaBorrowState(asset)._method.outputs)
  })

  assetsIn.forEach(async (asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.borrowIndex()._method, []));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.borrowIndex()._method.outputs)
  })

  assetsIn.forEach(async (asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.totalBorrows()._method, []));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.totalBorrows()._method.outputs)
  })

  assetsIn.forEach(async (asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaBorrowerIndex(asset, userAddress)._method, [asset, userAddress]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaBorrowerIndex(asset, userAddress)._method.outputs)
  })

  assetsIn.forEach(async (asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.borrowBalanceStored(userAddress)._method, [userAddress]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.borrowBalanceStored(userAddress)._method.outputs)
  })

  const agregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());

  const do_split = async (array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }

  for (let i = 0; i < agregated_data[1].length; i++) {
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], agregated_data[1][i]))
  }

  const split_arr = (await do_split(results, assetsIn.length));

  gammaSupplyStateArr = split_arr[0];  // index,block
  gammaSpeedsArr = split_arr[1];
  getBlockNumberArr = split_arr[2];
  totalSupplyArr = split_arr[3]
  gammaSupplierIndexArr = split_arr[4]
  gammaInitialIndexArr = split_arr[5]
  balanceOfGtokenArr = split_arr[6]
  gammaBorrowStateArr = split_arr[7] //index,block
  borrowIndexArr = split_arr[8]
  totalBorrowsArr = split_arr[9]
  gammaBorrowerIndexArr = split_arr[10]
  borrowBalanceStoredArr = split_arr[11]


  let _userGammaAccrued = parseFloat(await gammaAccrued(userAddress, gammatrollerAddress))
  try {
    const getCompAccrueBySupply = async (i: any, _userGammaAccrued: any): Promise<number> => {

      let currentSupplyState = gammaSupplyStateArr[i]


      const supplySpeed = parseFloat(gammaSpeedsArr[i][0])
      const currentBlockNumberDiff = parseFloat(getBlockNumberArr[i][0]) - parseFloat(currentSupplyState.block)
      let newGammaSupplyIndex = 0

      if (currentBlockNumberDiff > 0 && supplySpeed > 0) {
        const supplyAmount = parseFloat(totalSupplyArr[i][0])
        let _gammaAccrued = currentBlockNumberDiff * supplySpeed //borrowspeed = gamma per block
        newGammaSupplyIndex = parseFloat(currentSupplyState.index) + (supplyAmount > 0 ? (_gammaAccrued / supplyAmount) * 1e36 : 0)
      }

      let currentSupplierIndex = parseFloat(gammaSupplierIndexArr[i][0])

      if (currentSupplierIndex === 0 && newGammaSupplyIndex > 0) {
        currentSupplierIndex = parseFloat(gammaInitialIndexArr[i][0])
      }

      let deltaIndex = (parseFloat(convertToEther(newGammaSupplyIndex, 36)) - parseFloat(convertToEther(currentSupplierIndex, 36))) * 1e36
      deltaIndex = deltaIndex < 0 ? 0 : deltaIndex
      const userSupplyBalance = parseFloat(balanceOfGtokenArr[i][0])
      const supplierDelta = userSupplyBalance * deltaIndex
      return parseFloat(_userGammaAccrued) + parseFloat(convertToEther(supplierDelta, 36))
    }

    const getCompAccrueByBorrow = async (i: any, _userGammaAccrued: any): Promise<number> => {

      let currentBorrowState = gammaBorrowStateArr[i]
      const borrowSpeed = parseFloat(gammaSpeedsArr[i][0])
      const currentBlockNumberDiff = parseFloat(getBlockNumberArr[i][0]) - parseFloat(currentBorrowState.block)

      if (currentBlockNumberDiff > 0 && borrowSpeed > 0) {
        const marketBorrowIndex = parseFloat(borrowIndexArr[i][0])
        const borrowAmount = parseFloat(totalBorrowsArr[i][0]) / marketBorrowIndex

        let _gammaAccrued: any = convertToEther(currentBlockNumberDiff * borrowSpeed, 18) //borrowspeed = gamma per block
        let newGammaBorrowIndex =
          parseFloat(currentBorrowState.index) + (borrowAmount > 0 ? (parseFloat(_gammaAccrued) / borrowAmount) * 1e36 : 0)

        const currentborrowerIndex = parseFloat(gammaBorrowerIndexArr[i][0])

        if (currentborrowerIndex > 0) {
          let deltaIndex =
            (parseFloat(convertToEther(newGammaBorrowIndex, 36)) - parseFloat(convertToEther(currentborrowerIndex, 36))) * 1e36
          deltaIndex = deltaIndex < 0 ? 0 : deltaIndex
          const userBorrowBalance = parseFloat(borrowBalanceStoredArr[i][0])
          const borrowerAmount = userBorrowBalance / marketBorrowIndex
          const borrowerDelta = borrowerAmount * deltaIndex

          return parseFloat(_userGammaAccrued) + parseFloat(convertToEther(borrowerDelta, 18))
        }
        return _userGammaAccrued
      }
      return _userGammaAccrued
    }

    for (let i = 0; i < assetsIn.length; i++) {
      //update gamma borrow index

      _userGammaAccrued = await getCompAccrueBySupply(i, _userGammaAccrued)
      _userGammaAccrued = await getCompAccrueByBorrow(i, _userGammaAccrued)
    }

    return noExponents(convertToEther(_userGammaAccrued.toFixed(0), 18))
  } catch (e) {
    console.log(e)
  }
}

export const isGivenAsCollateralMulticall = async (pfTokenList: any, assetLength: any, userAddress: string) => {
  const assetIn = await getAssetsInMulticall(userAddress, gammatrollerAddress);//await getAssetsIn(userAddress, gammatrollerAddress);
  const market = await marketsMulticall(pfTokenList, assetLength, gammatrollerAddress, assetIn)
  return market;
}

export const getPendingRewards = async (userAddress: string) => {

  const assetsIn: any = await getAllMarkets(gammatrollerAddress)

  let batch1 = new wallet.web3.BatchRequest();
  let batch2 = new wallet.web3.BatchRequest();
  let batch3 = new wallet.web3.BatchRequest();
  let batch4 = new wallet.web3.BatchRequest();
  let batch5 = new wallet.web3.BatchRequest();
  let batch6 = new wallet.web3.BatchRequest();
  let batch7 = new wallet.web3.BatchRequest();
  let batch8 = new wallet.web3.BatchRequest();
  let batch9 = new wallet.web3.BatchRequest();
  let batch10 = new wallet.web3.BatchRequest();
  let batch11 = new wallet.web3.BatchRequest();
  let batch12 = new wallet.web3.BatchRequest();
  const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress);

  //Supply side arrays
  let gammaSupplyStateArr: any = []
  let gammaSpeedsArr: any = []
  let getBlockNumberArr: any = []
  let totalSupplyArr: any = []
  let gammaSupplierIndexArr: any = []
  let gammaInitialIndexArr: any = []
  let balanceOfGtokenArr: any = []

  //Borrow side arrays
  let gammaBorrowStateArr: any = []
  let borrowIndexArr: any = []
  let totalBorrowsArr: any = []
  let gammaBorrowerIndexArr: any = []
  let borrowBalanceStoredArr: any = []

  let counter1 = 0;
  let counter2 = 0;
  let counter3 = 0;
  let counter4 = 0;
  let counter5 = 0;
  let counter6 = 0;
  let counter7 = 0;
  let counter8 = 0;
  let counter9 = 0;
  let counter10 = 0;
  let counter11 = 0;
  let counter12 = 0;

  let total = assetsIn.length

  await new Promise(function (resolve: any, reject) {
    assetsIn.forEach(async (asset: any) => {
      const asset_inst = new wallet.web3.eth.Contract(gTokenAbi, asset);
      batch1.add(
        gammatroller_inst.methods.gammaSupplyState(asset).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter1++;
          gammaSupplyStateArr.push(data);
          if (counter1 === total)
            resolve();
        })
      )
      batch2.add(
        gammatroller_inst.methods.gammaSpeeds(asset).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter2++;
          gammaSpeedsArr.push(data);
          if (counter2 === total)
            resolve();
        })
      )
      batch3.add(
        gammatroller_inst.methods.getBlockNumber().call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter3++;
          getBlockNumberArr.push(data);
          if (counter3 === total)
            resolve();
        })
      )
      batch4.add(
        asset_inst.methods.totalSupply().call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter4++;
          totalSupplyArr.push(data);
          if (counter4 === total)
            resolve();
        })
      )
      batch5.add(
        gammatroller_inst.methods.gammaSupplierIndex(asset, userAddress).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter5++;
          gammaSupplierIndexArr.push(data);
          if (counter5 === total)
            resolve();
        })
      )
      batch6.add(
        gammatroller_inst.methods.gammaInitialIndex().call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter6++;
          gammaInitialIndexArr.push(data);
          if (counter6 === total)
            resolve();
        })
      )
      batch7.add(
        asset_inst.methods.balanceOf(userAddress).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter7++;
          balanceOfGtokenArr.push(data);
          if (counter7 === total)
            resolve();
        })
      )
      batch8.add(
        gammatroller_inst.methods.gammaBorrowState(asset).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter8++;
          gammaBorrowStateArr.push(data);
          if (counter8 === total)
            resolve();
        })
      )
      batch9.add(
        asset_inst.methods.borrowIndex().call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter9++;
          borrowIndexArr.push(data);
          if (counter9 === total)
            resolve();
        })
      )
      batch10.add(
        asset_inst.methods.totalBorrows().call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter10++;
          totalBorrowsArr.push(data);
          if (counter10 === total)
            resolve();
        })
      )
      batch11.add(
        gammatroller_inst.methods.gammaBorrowerIndex(asset, userAddress).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter11++;
          gammaBorrowerIndexArr.push(data);
          if (counter11 === total)
            resolve();
        })
      )
      batch12.add(
        asset_inst.methods.borrowBalanceStored(userAddress).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter12++;
          borrowBalanceStoredArr.push(data);
          if (counter12 === total)
            resolve();
        })
      )
    });

    batch1.execute()
    batch2.execute()
    batch3.execute()
    batch4.execute()
    batch5.execute()
    batch6.execute()
    batch7.execute()
    batch8.execute()
    batch9.execute()
    batch10.execute()
    batch11.execute()
    batch12.execute()

  });

  //console.log("gammaSupplyStateArr-->",gammaSupplyStateArr);
  // "gammaSpeedsArr-->",gammaSpeedsArr,
  // "getBlockNumberArr-->",getBlockNumberArr,
  // "totalSupplyArr-->",totalSupplyArr,
  // "gammaSupplierIndexArr-->",gammaSupplierIndexArr,
  // "gammaInitialIndexArr-->",gammaInitialIndexArr,
  // "balanceOfGtokenArr-->",balanceOfGtokenArr,
  // "gammaBorrowStateArr-->",gammaBorrowStateArr,
  // "borrowIndexArr-->",borrowIndexArr,
  // "totalBorrowsArr-->",totalBorrowsArr,
  // "gammaBorrowerIndexArr-->",gammaBorrowerIndexArr,
  // "borrowBalanceStoredArr-->",borrowBalanceStoredArr)


  let _userGammaAccrued = parseFloat(await gammaAccrued(userAddress, gammatrollerAddress))
  try {
    const getCompAccrueBySupply = async (i: any, _userGammaAccrued: any): Promise<number> => {
      const gTokenAddress = assetsIn[i]

      let currentSupplyState = gammaSupplyStateArr[i]
      const supplySpeed = parseFloat(gammaSpeedsArr[i])
      const currentBlockNumberDiff = parseFloat(getBlockNumberArr[i]) - parseFloat(currentSupplyState.block)
      let newGammaSupplyIndex = 0

      if (currentBlockNumberDiff > 0 && supplySpeed > 0) {
        const supplyAmount = parseFloat(totalSupplyArr[i])
        let _gammaAccrued = currentBlockNumberDiff * supplySpeed //borrowspeed = gamma per block
        newGammaSupplyIndex = parseFloat(currentSupplyState.index) + (supplyAmount > 0 ? (_gammaAccrued / supplyAmount) * 1e36 : 0)
      }

      let currentSupplierIndex = parseFloat(gammaSupplierIndexArr[i])

      if (currentSupplierIndex === 0 && newGammaSupplyIndex > 0) {
        currentSupplierIndex = parseFloat(gammaInitialIndexArr[i])
      }

      let deltaIndex = (parseFloat(convertToEther(newGammaSupplyIndex, 36)) - parseFloat(convertToEther(currentSupplierIndex, 36))) * 1e36
      deltaIndex = deltaIndex < 0 ? 0 : deltaIndex
      const userSupplyBalance = parseFloat(balanceOfGtokenArr[i])
      const supplierDelta = userSupplyBalance * deltaIndex
      return parseFloat(_userGammaAccrued) + parseFloat(convertToEther(supplierDelta, 36))
    }

    const getCompAccrueByBorrow = async (i: any, _userGammaAccrued: any): Promise<number> => {
      const gTokenAddress = assetsIn[i]
      let currentBorrowState = gammaBorrowStateArr[i]
      const borrowSpeed = parseFloat(gammaSpeedsArr[i])
      const currentBlockNumberDiff = parseFloat(getBlockNumberArr[i]) - parseFloat(currentBorrowState.block)

      if (currentBlockNumberDiff > 0 && borrowSpeed > 0) {
        const marketBorrowIndex = parseFloat(borrowIndexArr[i])
        const borrowAmount = parseFloat(totalBorrowsArr[i]) / marketBorrowIndex

        let _gammaAccrued: any = convertToEther(currentBlockNumberDiff * borrowSpeed, 18) //borrowspeed = gamma per block
        let newGammaBorrowIndex =
          parseFloat(currentBorrowState.index) + (borrowAmount > 0 ? (parseFloat(_gammaAccrued) / borrowAmount) * 1e36 : 0)

        const currentborrowerIndex = parseFloat(gammaBorrowerIndexArr[i])

        if (currentborrowerIndex > 0) {
          let deltaIndex =
            (parseFloat(convertToEther(newGammaBorrowIndex, 36)) - parseFloat(convertToEther(currentborrowerIndex, 36))) * 1e36
          deltaIndex = deltaIndex < 0 ? 0 : deltaIndex
          const userBorrowBalance = parseFloat(borrowBalanceStoredArr[i])
          const borrowerAmount = userBorrowBalance / marketBorrowIndex
          const borrowerDelta = borrowerAmount * deltaIndex

          return parseFloat(_userGammaAccrued) + parseFloat(convertToEther(borrowerDelta, 18))
        }
        return _userGammaAccrued
      }
      return _userGammaAccrued
    }

    for (let i = 0; i < assetsIn.length; i++) {
      //update gamma borrow index

      _userGammaAccrued = await getCompAccrueBySupply(i, _userGammaAccrued)
      _userGammaAccrued = await getCompAccrueByBorrow(i, _userGammaAccrued)
    }

    return noExponents(convertToEther(_userGammaAccrued.toFixed(0), 18))
  } catch (e) {
    console.log(e)
  }
}

export const getMarketLiquidityDataforMarkets = async (blockNumber?: number, totalUnderlyingPriceArray? : any, getGTokenPriceArray? : any) => {
  const list = pfTokenList
  const listLength = Object.keys(list).length
  let decimalArr: any = [];
  const tokenArr: any = Object.values(pfTokenList);
  for (var key in tokenArr){
    decimalArr[tokenArr[key].address] = tokenArr[key].decimals
  }
  totalUnderlyingPriceArray = getGTokenPriceArray ? getGTokenPriceArray : await getTotalUnderlyingPriceMulticall(pfTokenList);
  if(getGTokenPriceArray === undefined){  
    const exchangeRateArray = await exchangeRateStoredMulticall(pfTokenList, listLength)
    getGTokenPriceArray = await getGTokenPriceRevised(pfTokenList, listLength, totalUnderlyingPriceArray, decimalArr, exchangeRateArray)
  }
  
  const totalSupplyArray = await totalSupplyMulticall(pfTokenList, listLength, 8, blockNumber);
  try {
    let data: any = []
    for (let i = 0; i < listLength; i++) {
      const getData = async (i: any) => {
        const gTokenAddress = list[i].address
        const price = getGTokenPriceArray[gTokenAddress]; //await getGTokenPrice(gTokenAddress, blockNumber);
        // const decimal: any = await decimals(gTokenAddress);
        const underlyingBal = convertToEther(totalSupplyArray[gTokenAddress], 8);//convertToEther(await totalSupply(gTokenAddress, blockNumber), 8)
        return price * underlyingBal
      }
      data.push(getData(i))
    }
    data = await Promise.all(data);
    return data
  } catch (e) {
    console.log(e)
    return [0]
  }
}
export const getMarketLiquidityData = async (priceArray?: any, blockNumber?: number) => {
  // if (totalMarketLiquidity.length > 0) {
  //   console.log("totalMarketLiquidity", totalMarketLiquidity)
  //   return totalMarketLiquidity;
  // }
  const list = pfTokenList
  const listLength = Object.keys(list).length
  if (priceArray !== undefined && (typeof priceArray === 'string' || priceArray.length === 0)) {
    priceArray = await getTotalUnderlyingPriceMulticall(pfTokenList, blockNumber);
  }
  const exchangeRateArray = await exchangeRateStoredMulticall(pfTokenList, listLength);
  const totalSupplyArray = await totalSupplyMulticall(pfTokenList, listLength, 8, blockNumber);
  try {
    let data: any = [];
    let data_new: any = [];
    for (let i = 0; i < listLength; i++) {
      const gTokenAddress = list[i].address;
      let price = 0;
      if (priceArray[gTokenAddress] && exchangeRateArray[gTokenAddress]) {
        price = priceArray[gTokenAddress] * exchangeRateArray[gTokenAddress];
      }
      const underlyingBal = totalSupplyArray[gTokenAddress] ? totalSupplyArray[gTokenAddress] : 0;
      let total = price * underlyingBal
      data_new.push(total);
    }
    return data_new;
  } catch (e) {
    console.log(e)
    return [0]
  }
}

export const getTotalBorrowsOfAllMarkets = async (blockNumber?: number, totalUnderlyingPriceArray? : any) => {
  const list = pfTokenList
  const listLength = Object.keys(list).length
  totalUnderlyingPriceArray = totalUnderlyingPriceArray ? totalUnderlyingPriceArray : await getTotalUnderlyingPriceMulticall(pfTokenList);
  let totalBorrowArray = await totalBorrowsMulticall(pfTokenList, blockNumber)
  try {
    let data: any = []
    for (let i = 0; i < listLength; i++) {
      const getData = async (i: any) => {
        const gTokenAddress = list[i].address
        const price = totalUnderlyingPriceArray[gTokenAddress];//await getTokenPrice(gTokenAddress, blockNumber)
        // const decimals = await getUnderlyingDecimal(gTokenAddress);
        const underlyingBal = convertToEther(totalBorrowArray[gTokenAddress],list[i].decimals);//convertToEther(await totalBorrows(gTokenAddress, blockNumber), decimals)
        return price * underlyingBal
      }
      data.push(getData(i))
    }
    data = await Promise.all(data)
    return data
  } catch (e) {
    console.log(e)
    return [0]
  }
}

export const getMarketData = async (userAddress: string) => {
  let initial_timestamp = new Date().getTime();
  const currentBlock = await wallet.web3.eth.getBlockNumber()
  const currentBlockYesterday = currentBlock
  const currentBlockDayBeforeYesterday = currentBlock
  /*
  multicall functions
  totalSupplyMulticall
  */
  const list = pfTokenList
  const listLength = Object.keys(list).length
  let decimalArr: any = [];
  const tokenArr: any = Object.values(pfTokenList);
  for (var key in tokenArr){
    decimalArr[tokenArr[key].address] = tokenArr[key].decimals
  }
  let totalUnderlyingPriceArray = await getTotalUnderlyingPriceMulticall(pfTokenList);
  const exchangeRateArray = await exchangeRateStoredMulticall(pfTokenList, listLength)
  const getGTokenPriceArray = await getGTokenPriceRevised(pfTokenList, listLength, totalUnderlyingPriceArray, decimalArr, exchangeRateArray)
  const prevToPrevSuppData = await getMarketLiquidityDataforMarkets(currentBlockDayBeforeYesterday, totalUnderlyingPriceArray, getGTokenPriceArray)
  const prevSuppData = await getMarketLiquidityDataforMarkets(currentBlockYesterday, totalUnderlyingPriceArray, getGTokenPriceArray)
  const currSuppData = await getMarketLiquidityDataforMarkets(currentBlock, totalUnderlyingPriceArray, getGTokenPriceArray)
  const supplyApyArray: any = await getSupplyApyMulticall(pfTokenList, userAddress);
  const borrowApyArray: any = await getBorrowApyMulticall(pfTokenList, userAddress, supplyApyArray)
  const prevToPrevTotalSuppMarketSize = prevToPrevSuppData.reduce((a: any, b: any) => a + b, 0)
  const prevTotalSuppMarketSize = prevSuppData.reduce((a: any, b: any) => a + b, 0)
  const currTotalSuppMarketSize = currSuppData.reduce((a: any, b: any) => a + b, 0)

  const prevToPrevBorrowData = await getTotalBorrowsOfAllMarkets(currentBlockDayBeforeYesterday, totalUnderlyingPriceArray)
  const prevBorrowData = await getTotalBorrowsOfAllMarkets(currentBlockYesterday, totalUnderlyingPriceArray)
  const currBorrowData = await getTotalBorrowsOfAllMarkets(currentBlock, totalUnderlyingPriceArray)
  let gammaShareArray = await gammaSpeedsMulticall(pfTokenList, gammatrollerAddress);
  const prevToPrevTotalBorrowMarketSize = prevToPrevBorrowData.reduce((a: any, b: any) => a + b, 0)
  const prevTotalBorrowMarketSize = prevBorrowData.reduce((a: any, b: any) => a + b, 0)
  const currTotalBorrowMarketSize = currBorrowData.reduce((a: any, b: any) => a + b, 0)
  const prevTwentyFourHrSuppVolume =
    prevTotalSuppMarketSize - prevToPrevTotalSuppMarketSize > 0 ? prevTotalSuppMarketSize - prevToPrevTotalSuppMarketSize : 0
  const currTwentyFourHrSuppVolume =
    currTotalSuppMarketSize - prevTotalSuppMarketSize > 0 ? currTotalSuppMarketSize - prevTotalSuppMarketSize : 0

  const prevTwentyFourHrBorrowVolume =
    prevTotalBorrowMarketSize - prevToPrevTotalBorrowMarketSize > 0 ? prevTotalBorrowMarketSize - prevToPrevTotalBorrowMarketSize : 0
  const currTwentyFourHrBorrowVolume =
    currTotalBorrowMarketSize - prevTotalBorrowMarketSize > 0 ? currTotalBorrowMarketSize - prevTotalBorrowMarketSize : 0
  const top3SuppMarket = async () => {
    let list = marketTokenList
    let res = []
    const indexes = findLargest3(currSuppData)

    for (let i = 0; i < indexes.length; i++) {
      const obj1 = {
        address: list[indexes[i]].address,
        name: list[indexes[i]].name,
        marketSymbol: list[indexes[i]].marketSymbol,
        icon: list[indexes[i]].icon,
        tokenOrigin: list[indexes[i]].tokenOrigin,
        percentage: (currSuppData[indexes[i]] / currTotalSuppMarketSize) * 100,
      }
      res.push(obj1)
    }
    return res
  }

  const top3BorrowMarket = async () => {
    let list = marketTokenList
    let res = []

    const indexes = findLargest3(currBorrowData)

    for (let i = 0; i < indexes.length; i++) {
      const obj1 = {
        address: list[indexes[i]].address,
        name: list[indexes[i]].name,
        marketSymbol: list[indexes[i]].marketSymbol,
        icon: list[indexes[i]].icon,
        tokenOrigin: list[indexes[i]].tokenOrigin,
        percentage: (currBorrowData[indexes[i]] / currTotalBorrowMarketSize) * 100,
      }
      res.push(obj1)
    }
    return res
  }
  var greenPlanetGamma = 0
  const allMarketsData = async (userAddress: string) => {
    let list: any = marketTokenList
    
    const marketListData: any = []
    const eachMarketData = (index: number) => {
      const market = list[index].address
      greenPlanetGamma += market === gGamma || market === gAqua  ? gammaShareArray[market] : gammaShareArray[market] * 2;
      // const prevSuppApy = userAddress
      //   ? await getSupplyApy(market, userAddress, currentBlockYesterday)
      //   : await getSupplyApy(market, '', currentBlockYesterday)
      const prevSuppApy = supplyApyArray[market]
      // const currSuppApy = userAddress ? await getSupplyApy(market, userAddress, currentBlock) : await getSupplyApy(market, '', currentBlock)
      const currSuppApy = supplyApyArray[market];
      // const prevBorrowApy = userAddress
      //   ? await getBorrowApy(market, userAddress, currentBlockYesterday)
      //   : await getBorrowApy(market, '', currentBlockYesterday)
      const prevBorrowApy = borrowApyArray[market];
     
      // const currBorrowApy = userAddress
      //   ? await getBorrowApy(market, userAddress, currentBlock)
      //   : await getBorrowApy(market, '', currentBlock)
      
      const currBorrowApy = borrowApyArray[market];
      const marketObj = {
        address: market,
        totalSupply: currSuppData[index],
        totalSupplyChange: ((currSuppData[index] - prevSuppData[index]) / prevSuppData[index]) * 100,
        totalBorrow: currBorrowData[index],
        totalBorrowChange: ((currBorrowData[index] - prevBorrowData[index]) / prevBorrowData[index]) * 100,
        supplyApy: currSuppApy,
        supplyApyChange: ((currSuppApy - prevSuppApy) / prevSuppApy) * 100,
        borrowApy: currBorrowApy,
        borrowApyChange: ((currBorrowApy - prevBorrowApy) / prevBorrowApy) * 100,
        name: marketTokenList[index].name,
        icon: marketTokenList[index].icon,
        tokenOrigin: marketTokenList[index].tokenOrigin,
        gammaPerDay: market === gGamma || market === gAqua  ? gammaShareArray[market] : gammaShareArray[market] * 2
      }
      return marketObj
    }
    Object.values(list).forEach((element: any) => {
      const market = element.sno
      const data = eachMarketData(market)
      marketListData.push(data)
    })
    const result = marketListData;//await Promise.all(marketListData)
    let final_timestamp = new Date().getTime();
    // console.log("Green planet Gamma", greenPlanetGamma)
    // console.log("loading time for markets", final_timestamp - initial_timestamp, result)
    return result
  }
  
  return {
    supplyMarketSize: currTotalSuppMarketSize,
    changeInSupplyMarketSize: ((currTotalSuppMarketSize - prevTotalSuppMarketSize) / prevTotalSuppMarketSize) * 100,

    twentyFourHrSuppVolume: currTwentyFourHrSuppVolume < prevTwentyFourHrSuppVolume ? 0 : currTwentyFourHrSuppVolume,
    twentyFourHrSuppVolumeChange:
      ((currTwentyFourHrSuppVolume < prevTwentyFourHrSuppVolume ? 0 : currTwentyFourHrSuppVolume - prevTwentyFourHrSuppVolume) /
        (prevTwentyFourHrSuppVolume > 0 ? prevTwentyFourHrSuppVolume : 1)) *
      100,

    borrowMarketSize: currTotalBorrowMarketSize,
    changeInBorrowMarketSize: ((currTotalBorrowMarketSize - prevTotalBorrowMarketSize) / prevTotalBorrowMarketSize) * 100,

    twentyFourHrBorrowVolume: currTwentyFourHrBorrowVolume < prevTwentyFourHrBorrowVolume ? 0 : currTwentyFourHrBorrowVolume,
    twentyFourHrBorrowVolumeChange:
      ((currTwentyFourHrBorrowVolume < prevTwentyFourHrBorrowVolume ? 0 : currTwentyFourHrBorrowVolume - prevTwentyFourHrBorrowVolume) /
        (prevTwentyFourHrBorrowVolume > 0 ? prevTwentyFourHrBorrowVolume : 1)) *
      100,

    top3SupplyMarkets: await top3SuppMarket(),
    top3BorrowMarkets: await top3BorrowMarket(),
    allMarkets: await allMarketsData(userAddress),
  }
  
}

export const getMarketDataOriginal = async (userAddress: string) => {
  const currentBlock = await wallet.web3.eth.getBlockNumber()
  const currentBlockYesterday = currentBlock
  const currentBlockDayBeforeYesterday = currentBlock

  const prevToPrevSuppData = await getMarketLiquidityDataforMarkets(currentBlockDayBeforeYesterday)
  const prevSuppData = await getMarketLiquidityDataforMarkets(currentBlockYesterday)
  const currSuppData = await getMarketLiquidityDataforMarkets(currentBlock)

  const prevToPrevTotalSuppMarketSize = prevToPrevSuppData.reduce((a: any, b: any) => a + b, 0)
  const prevTotalSuppMarketSize = prevSuppData.reduce((a: any, b: any) => a + b, 0)
  const currTotalSuppMarketSize = currSuppData.reduce((a: any, b: any) => a + b, 0)

  const prevToPrevBorrowData = await getTotalBorrowsOfAllMarkets(currentBlockDayBeforeYesterday)
  const prevBorrowData = await getTotalBorrowsOfAllMarkets(currentBlockYesterday)
  const currBorrowData = await getTotalBorrowsOfAllMarkets(currentBlock)

  const prevToPrevTotalBorrowMarketSize = prevToPrevBorrowData.reduce((a: any, b: any) => a + b, 0)
  const prevTotalBorrowMarketSize = prevBorrowData.reduce((a: any, b: any) => a + b, 0)
  const currTotalBorrowMarketSize = currBorrowData.reduce((a: any, b: any) => a + b, 0)
  const prevTwentyFourHrSuppVolume =
    prevTotalSuppMarketSize - prevToPrevTotalSuppMarketSize > 0 ? prevTotalSuppMarketSize - prevToPrevTotalSuppMarketSize : 0
  const currTwentyFourHrSuppVolume =
    currTotalSuppMarketSize - prevTotalSuppMarketSize > 0 ? currTotalSuppMarketSize - prevTotalSuppMarketSize : 0

  const prevTwentyFourHrBorrowVolume =
    prevTotalBorrowMarketSize - prevToPrevTotalBorrowMarketSize > 0 ? prevTotalBorrowMarketSize - prevToPrevTotalBorrowMarketSize : 0
  const currTwentyFourHrBorrowVolume =
    currTotalBorrowMarketSize - prevTotalBorrowMarketSize > 0 ? currTotalBorrowMarketSize - prevTotalBorrowMarketSize : 0
  const top3SuppMarket = async () => {
    let list = marketTokenList
    let res = []
    const indexes = findLargest3(currSuppData)

    for (let i = 0; i < indexes.length; i++) {
      const obj1 = {
        address: list[indexes[i]].address,
        name: list[indexes[i]].name,
        marketSymbol: list[indexes[i]].marketSymbol,
        icon: list[indexes[i]].icon,
        tokenOrigin: list[indexes[i]].tokenOrigin,
        percentage: (currSuppData[indexes[i]] / currTotalSuppMarketSize) * 100,
      }
      res.push(obj1)
    }
    return res
  }

  const top3BorrowMarket = async () => {
    let list = marketTokenList
    let res = []

    const indexes = findLargest3(currBorrowData)

    for (let i = 0; i < indexes.length; i++) {
      const obj1 = {
        address: list[indexes[i]].address,
        name: list[indexes[i]].name,
        marketSymbol: list[indexes[i]].marketSymbol,
        icon: list[indexes[i]].icon,
        tokenOrigin: list[indexes[i]].tokenOrigin,
        percentage: (currBorrowData[indexes[i]] / currTotalBorrowMarketSize) * 100,
      }
      res.push(obj1)
    }
    return res
  }

  const allMarketsData = async (userAddress: string) => {
    let list: any = marketTokenList
    const marketListData: any = []
    const eachMarketData = async (index: number) => {
      const market = list[index].address

      const prevSuppApy = userAddress
        ? await getSupplyApy(market, userAddress, currentBlockYesterday)
        : await getSupplyApy(market, '', currentBlockYesterday)

      const currSuppApy = userAddress ? await getSupplyApy(market, userAddress, currentBlock) : await getSupplyApy(market, '', currentBlock)

      const prevBorrowApy = userAddress
        ? await getBorrowApy(market, userAddress, currentBlockYesterday)
        : await getBorrowApy(market, '', currentBlockYesterday)

      const currBorrowApy = userAddress
        ? await getBorrowApy(market, userAddress, currentBlock)
        : await getBorrowApy(market, '', currentBlock)

      const marketObj = {
        address: market,
        totalSupply: currSuppData[index],
        totalSupplyChange: ((currSuppData[index] - prevSuppData[index]) / prevSuppData[index]) * 100,
        totalBorrow: currBorrowData[index],
        totalBorrowChange: ((currBorrowData[index] - prevBorrowData[index]) / prevBorrowData[index]) * 100,
        supplyApy: currSuppApy,
        supplyApyChange: ((currSuppApy - prevSuppApy) / prevSuppApy) * 100,
        borrowApy: currBorrowApy,
        borrowApyChange: ((currBorrowApy - prevBorrowApy) / prevBorrowApy) * 100,
        name: marketTokenList[index].name,
        icon: marketTokenList[index].icon,
        tokenOrigin: marketTokenList[index].tokenOrigin,
      }
      return marketObj
    }
    Object.values(list).forEach((element: any) => {
      const market = element.sno
      const data = eachMarketData(market)
      marketListData.push(data)
    })
    const result = await Promise.all(marketListData)
    return result
  }
  return {
    supplyMarketSize: currTotalSuppMarketSize,
    changeInSupplyMarketSize: ((currTotalSuppMarketSize - prevTotalSuppMarketSize) / prevTotalSuppMarketSize) * 100,

    twentyFourHrSuppVolume: currTwentyFourHrSuppVolume < prevTwentyFourHrSuppVolume ? 0 : currTwentyFourHrSuppVolume,
    twentyFourHrSuppVolumeChange:
      ((currTwentyFourHrSuppVolume < prevTwentyFourHrSuppVolume ? 0 : currTwentyFourHrSuppVolume - prevTwentyFourHrSuppVolume) /
        (prevTwentyFourHrSuppVolume > 0 ? prevTwentyFourHrSuppVolume : 1)) *
      100,

    borrowMarketSize: currTotalBorrowMarketSize,
    changeInBorrowMarketSize: ((currTotalBorrowMarketSize - prevTotalBorrowMarketSize) / prevTotalBorrowMarketSize) * 100,

    twentyFourHrBorrowVolume: currTwentyFourHrBorrowVolume < prevTwentyFourHrBorrowVolume ? 0 : currTwentyFourHrBorrowVolume,
    twentyFourHrBorrowVolumeChange:
      ((currTwentyFourHrBorrowVolume < prevTwentyFourHrBorrowVolume ? 0 : currTwentyFourHrBorrowVolume - prevTwentyFourHrBorrowVolume) /
        (prevTwentyFourHrBorrowVolume > 0 ? prevTwentyFourHrBorrowVolume : 1)) *
      100,

    top3SupplyMarkets: await top3SuppMarket(),
    top3BorrowMarkets: await top3BorrowMarket(),
    allMarkets: await allMarketsData(userAddress),
  }
}

export const getBurnApy = async (resp? : any) => {
  let burnApy: any = '6.24';
  let burnApr: any = '6.24';
  if(resp == "all"){
    return {burnApy, burnApr};
  }
  else {
    return burnApy;
  }
}

export const getBurnApr = async () => {
  return '6.24';
}

export const getTreasuryApy = async () => {
  let apyVal: any = 0
  try {
    const res = await axios.get(planetFinanceApiBaseUrl+'v1/markets/gettreasury')
    if (!!res && res.data && res.data.length > 0) {
      const lastIndex: number = res.data.length - 1
      const firstData = res.data[0]
      const lastData = res.data[lastIndex]
      const key1 = `${Object.keys(firstData)}`
      const key2 = `${Object.keys(lastData)}`
      const firstVal = firstData[key1]
      const lastVal = lastData[key2]
      const diffVal: any = parseFloat(firstVal) - parseFloat(lastVal)
      let increasedVal: any = 0
      if (parseFloat(diffVal) > 0) {
        increasedVal = parseFloat(diffVal)
        const aquaTotalSupply = parseFloat(await totalSupply(aquaAddress))
        const deadAquaBal = await balanceOf(aquaAddress, '0x000000000000000000000000000000000000dEaD')
        const marketCap: any = (convertToEther(aquaTotalSupply, 18) - convertToEther(deadAquaBal, 18)) * (await getAquaPrice())
        const aprVal = ((parseFloat(increasedVal) / parseFloat(marketCap)) * 52) * 100
        const apyData: any = await getTokenYield(aprVal, 365)
        apyVal = !isNaN(parseFloat(apyData)) ? apyData : 0
      }
    }
  }
  catch (error) {
    console.log("error===>", error)
  }
  finally {
    return apyVal
  }
}

export const getUserSuppliedAndBorrowedAssetDataRevised = async (userAddress: any) => {
  const timestamp = new Date().getTime();
    const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
    let totalUnderlyingPriceArray: any = [];
    let exchangeRateArray: any = [];
    let totalSupplyArray: any = [];
    let isDeprecatedArray: any = [];
    let marketsCollateralArray: any = [];
    let gammaSpeedArray: any = [];
    let borrowRatePerBlockArray: any = [];
    let totalBorrowArray: any = [];
    let supplyRatePerBlockArray: any = [];
    let supplyBalanceArray: any = [];
    let borrowBalanceArray: any = [];
    let gTokenBalanceInUnderlyingTokenArray: any = [];
    let gTokenBalanceArray: any = [];
    let allowanceArray: any = [];
    let guardianPausedArray: any = [];
    let cashArray: any = [];
   
    let targets: any = [];
    let callDatas: any = [];
    let results:any = [];
    let ouput_format : any = [];
    const market_array: any = Object.values(pfTokenList);
    let levelData = userAddress !== null ? await getDiscountLevel(userAddress) : { level: 0, discount: 0, };
    const gammatroller_inst: any = new wallet.web3.eth.Contract(gammatrollerAbi, gammatrollerAddress);
    let assets_data: any = [];
    if(!!userAddress){
        assets_data = await gammatroller_inst.methods.getAssetsIn(userAddress).call()
    }
    // console.log(assets_data);
    //token price
    const price_oracle_inst: any = new wallet.web3.eth.Contract(priceOracleAbi, priceOracleAddress);
    const inst: any = new wallet.web3.eth.Contract(gBnbAbi, gGamma)
    market_array.forEach(async(token: any) => {
        const element = token.address;
        targets.push(priceOracleAddress);
        const data = (wallet.web3.eth.abi.encodeFunctionCall(price_oracle_inst.methods.getUnderlyingPrice(element)._method, [element]));
        callDatas.push(data);
        ouput_format.push(price_oracle_inst.methods.getUnderlyingPrice(element)._method.outputs)
    })

    //exchange rate
    market_array.forEach(async(token: any) => {
      // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address)
      targets.push(token.address);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.exchangeRateStored()._method, []));
      callDatas.push(data);
      ouput_format.push(inst.methods.exchangeRateStored()._method.outputs)
    })

    // total supply
    market_array.forEach(async(token: any) => {
      // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address)
      targets.push(token.address);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.totalSupply()._method, []));
      callDatas.push(data);
      ouput_format.push(inst.methods.totalSupply()._method.outputs)
      
    })

    // //is deprecated
    // market_array.forEach(async(token: any) => {  
    //     targets.push(gammatrollerAddress)
    //     const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.isDeprecated(token.address)._method, [token.address]));
    //     callDatas.push(data);
    //     ouput_format.push(gammatroller_inst.methods.isDeprecated(token.address)._method.outputs)
    // })

    //markets collateral
    market_array.forEach(async(token: any) => {
      targets.push(gammatrollerAddress)
      const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.markets(token.address)._method, [token.address]));
      callDatas.push(data);
      ouput_format.push(gammatroller_inst.methods.markets(token.address)._method.outputs)
    })

    //gamma speed
    market_array.forEach(async(token: any) => {
      targets.push(gammatrollerAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSpeeds(token.address)._method, [token.address])
      callDatas.push(data)
      ouput_format.push(gammatroller_inst.methods.gammaSpeeds(token.address)._method.outputs)
    })

    //borrow rate per block
    market_array.forEach(async (token: any) => {
      // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address)
      targets.push(token.address)
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.borrowRatePerBlock()._method, [])
      callDatas.push(data)
      ouput_format.push(inst.methods.borrowRatePerBlock()._method.outputs)
    })

    // total borrows
    market_array.forEach(async(token: any) => {
      // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address)
      targets.push(token.address)
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.totalBorrows()._method, [])
      callDatas.push(data)
      ouput_format.push(inst.methods.totalBorrows()._method.outputs)
      
    })

    //supply rate per block
    market_array.forEach(async( token: any) => {
      // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address);
      targets.push(token.address);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.supplyRatePerBlock()._method, []));
      callDatas.push(data);
      ouput_format.push(inst.methods.supplyRatePerBlock()._method.outputs)
    })

    //borrow guardian pause
    market_array.forEach(async(token: any) => {
      targets.push(gammatrollerAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.borrowGuardianPaused(token.address)._method, [token.address])
      callDatas.push(data)
      ouput_format.push(gammatroller_inst.methods.borrowGuardianPaused(token.address)._method.outputs)
    })

    //cash
    market_array.forEach(async(token: any) => {
      targets.push(token.address)
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.getCash()._method, [])
      callDatas.push(data)
      ouput_format.push(inst.methods.getCash()._method.outputs)
    })
  
    if(userAddress){
      // console.log("user wallet address", userAddress)
        //supply balance
        market_array.forEach(async(token:any) => {
          
          // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address)
          targets.push(token.address);
          const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.getAccountSnapshot(userAddress)._method, [userAddress]));
          callDatas.push(data);
          ouput_format.push(inst.methods.getAccountSnapshot(userAddress)._method.outputs)
        
      })

      //borrow balance
      market_array.forEach(async(token: any) => {
          // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address)
          targets.push(token.address);
          const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.borrowBalanceStored(userAddress)._method, [userAddress]));
          callDatas.push(data);
          ouput_format.push(inst.methods.borrowBalanceStored(userAddress)._method.outputs)
        
      })

      //underlying token balance
      market_array.forEach(async(token: any) => {
        targets.push(token.token);
        const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.balanceOf(userAddress)._method, [
          userAddress
        ]);
        callDatas.push(data);
        ouput_format.push(inst.methods.balanceOf(userAddress)._method.outputs);
      })

      //gtoken balance
      market_array.forEach(async(token: any) => {
        targets.push(token.address);
        const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.balanceOf(userAddress)._method, [
          userAddress
        ]);
        callDatas.push(data);
        ouput_format.push(inst.methods.balanceOf(userAddress)._method.outputs);
      })

      // allowance
      market_array.forEach(async(token: any) => {
        targets.push(token.token)
        const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.allowance(userAddress, token.address)._method, [
          userAddress, token.address
        ])
        callDatas.push(data)
        ouput_format.push(inst.methods.allowance(userAddress, token.address)._method.outputs)
      })
    }
    
    // console.log(" targets & calldata array", targets, callDatas, targets.length, callDatas.length)
    const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());
    // console.log(" aggregated Data ", aggregated_data)
    const do_split = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }  
  
    for(let i = 0 ; i < aggregated_data[1].length ; i++){
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = (await do_split(results, market_array.length));
    // console.log("split arr data", split_arr)
    totalUnderlyingPriceArray = split_arr[0];
    exchangeRateArray = split_arr[1];
    totalSupplyArray = split_arr[2];
    // isDeprecatedArray = split_arr[3];
    marketsCollateralArray = split_arr[3];
    gammaSpeedArray = split_arr[4];
    borrowRatePerBlockArray = split_arr[5];
    totalBorrowArray = split_arr[6];
    supplyRatePerBlockArray = split_arr[7];
    guardianPausedArray = split_arr[8];
    cashArray = split_arr[9];
    if(userAddress){
      supplyBalanceArray = split_arr[10];
      borrowBalanceArray = split_arr[11]; 
      gTokenBalanceInUnderlyingTokenArray = split_arr[12];
      gTokenBalanceArray = split_arr[13];
      allowanceArray = split_arr[14];
    }
    
    const multicall_fetch_timestamp = new Date().getTime();
    // console.log("actual multicall time taken in revised", multicall_fetch_timestamp - timestamp )
    const getGammaSupplyApyArray: any = await getGammaSupplyApyFromAPI();
    const supplyApyArray: any = await getSupplyApyFromAPI();
    let burnApy = await getBurnApy("all");
    // let gammaBoostArr = await gammaBoostPercentForMarkets(market_array)
    let allPoolData = await getAllPoolsInfo();
    allPoolData = allPoolData.newVaults.active;
    let aquaBurnApy = burnApy.burnApy;
    let aquaBurnApr = burnApy.burnApr;
    let gammaPrice = await getGammaPrice();
    const api_fetch_timestamp = new Date().getTime();
    // console.log("api time taken in revised", api_fetch_timestamp - multicall_fetch_timestamp )
    // const new_markets_without_gammaAqua: any = Object.values(bluePfTokenListWithoutAquaGamma);
    const forloop_fetch_timestamp = new Date().getTime();
    // console.log("for loop time taken", forloop_fetch_timestamp - api_fetch_timestamp )
    
    const borrowApyArray: any = [];
    const borrowGammaApyArray: any = [];
    const borrowBalanceArr: any = [];
    let supplyBalanceArr: any = [];
    let suppliedAmountWithCollateral = 0;
    let suppliedAmountWithoutCollateral = 0;
    let totalSuppliedBalance = 0;
    let userGammaSupplied = 0;
    let userSuppliedAqua = 0;
    let diffToUpperLevel: any = 0;
    let upperLevel: any = 0;
    let totalBorrowedAmount = 0;
    let suppliedWithCollateral = 0;
    let getMarketLiquidityBalData = 0;
    let tokenPriceArray: any = [];
    let balanceOfUnderlyingTokenArray: any = [];
    let balanceOfGtokenArray: any = [];
    let marketsArray: any = [];
    let borrowGuardianPausedArray: any = [];
    let GTokenPriceArray: any = [];
    let apyOnLevelSelectAtBorrowZero: any = []; 
    let apyOnLevelSelectAtBorrowOne: any = []; 
    let apyOnLevelSelectAtBorrowTwo: any = [];
    let apyOnLevelSelectAtBorrowThree: any = [];
    let blocksPerDay = 28800;
    let daysPerYear = 365;

    const borrowApyDataOnLevel = (borrowApy: any, discount: any, gammaBorrowApy: any, tokenAddress: any, totalBorrows: any) => {
      if(tokenAddress.toLowerCase() !== gAqua.toLowerCase() && tokenAddress !== gGamma.toLowerCase() && totalBorrows > 0){
        const diff = borrowApy - supplyApyArray[tokenAddress];
        borrowApy -= (discount * diff) / 100;
        // console.log("supply apy data", supplyApyArray[tokenAddress], levelData, diff, borrowApy, gammaSpeedArray[i][0])
      }

      const finalBorrowApy = gammaBorrowApy - borrowApy ;
      let aprPerYear = 0
      let borrowApy24hr = 0
      if (finalBorrowApy > 0) {
        aprPerYear = (Math.pow((finalBorrowApy / 100 + 1), (1 / 365)) - 1) * 365 * 100
        borrowApy24hr = (aprPerYear / 365)
      } else {
        aprPerYear = (Math.pow((finalBorrowApy * -1 / 100 + 1), (1 / 365)) - 1) * 365 * 100
        borrowApy24hr = (aprPerYear / 365) * -1
      }
      let obj = {
        withoutRewardApy: borrowApy ? borrowApy : 0,
        borrowApy: finalBorrowApy ? finalBorrowApy : 0,
        borrowApy24hr: finalBorrowApy/365, //borrowApy24hr ? borrowApy24hr : 0,
        address: tokenAddress,
      }
      return obj;
    }
    
    for (let i = 0; i < market_array.length; i++) {
      const tokenAddress: any = market_array[i].address.toLowerCase();
      const gTokenDecimals: any = market_array[i] ? +market_array[i].decimals : 0;
      const decimal = 36 - gTokenDecimals;
      const tokenPrice = parseFloat(convertToEther(totalUnderlyingPriceArray[i][0], decimal));
      tokenPriceArray[tokenAddress.toLowerCase()] = tokenPrice;
      let tokenSupplyBalance = 0;
      // let is_deprecated = isDeprecatedArray[i][0] == false ? false : true; // {}
      // console.log("is deprecated", isDeprecatedArray[i][0]);
      let marketCollateralDetails: any = {};
      totalSupplyArray[tokenAddress] = totalSupplyArray[i][0];
      if (marketsCollateralArray[i] !== undefined) {
        let collateralFactor = marketsCollateralArray[i][1] ? convertToEther(marketsCollateralArray[i][1], 18) * 100 : 0;
        // console.log("index", addressArray[i].address, index, split_arr[0][i][1])
        let index = assets_data.some((e: any) => e.toLowerCase() === tokenAddress.toLowerCase())
        marketCollateralDetails = {
          isListed: marketsCollateralArray[i][0],
          collateralFactor: collateralFactor,//split_arr[0][i][1],
          isComped: marketsCollateralArray[i][1],
          collateralFactorMantissa: marketsCollateralArray[i][1],
          index: index,
          isAvailForCollateral: collateralFactor && index ? true : false
        }
      } else {
          marketCollateralDetails = {
              isListed: false,
              collateralFactor: 0,
              isComped: false,
              index: false,
              isAvailForCollateral: false,
              collateralFactorMantissa: 0
        }
      }
      if(userAddress){
        allowanceArray[tokenAddress] = allowanceArray[i][0];
        if (+supplyBalanceArray[i][1] !== 0 && +supplyBalanceArray[i][3] !== 0 && gTokenDecimals !== undefined) {
          tokenSupplyBalance = convertToEther(supplyBalanceArray[i][1], gTokenDecimals) * convertToEther(supplyBalanceArray[i][3], 18);
        } 
        const tokenBalance = tokenPrice * tokenSupplyBalance;//exchangeRate * totalSupplyArray[i];
        
        getMarketLiquidityBalData += tokenBalance;
        if(tokenAddress.toLowerCase() === gGamma.toLowerCase()){
          // result.gammaPrice = tokenPrice;
          userGammaSupplied = tokenSupplyBalance;
        }

        if(tokenAddress.toLowerCase() === gAqua.toLowerCase()){
          // result.aquaPrice = tokenPrice;
          userSuppliedAqua = tokenSupplyBalance;
        }
        const suppliedBal = tokenSupplyBalance * tokenPrice;
        totalSuppliedBalance += suppliedBal;
        
        if(marketCollateralDetails.isAvailForCollateral){
          suppliedAmountWithCollateral += suppliedBal
        }
        suppliedAmountWithoutCollateral += suppliedBal;
        let borrowBalance = convertToEther(borrowBalanceArray[i][0], gTokenDecimals);
        borrowBalanceArray[tokenAddress] = borrowBalance;
        // console.log( "borrow balance", market_array[i].name, borrowBalance)
        totalBorrowedAmount += borrowBalance * tokenPrice;
        suppliedWithCollateral += marketCollateralDetails.collateralFactor * tokenBalance / 100;
        borrowBalanceArr[tokenAddress.toLowerCase()] = borrowBalance * tokenPrice;
        supplyBalanceArr[tokenAddress.toLowerCase()] = tokenSupplyBalance;
        balanceOfUnderlyingTokenArray[tokenAddress] = gTokenBalanceInUnderlyingTokenArray[i]['0'];
        balanceOfUnderlyingTokenArray[tokenAddress] = gTokenBalanceInUnderlyingTokenArray[i]['0']
        balanceOfGtokenArray[tokenAddress] = gTokenBalanceArray[i][0];
        
      }
      let exchangerate_decimals = 28;
      let exchangeRate = parseFloat(convertToEther(exchangeRateArray[i][0], exchangerate_decimals));;
      
      marketsArray[tokenAddress.toLowerCase()] = marketCollateralDetails;
      
      cashArray[tokenAddress.toLowerCase()] = cashArray[i][0];
      let borrowApy = convertToEther(borrowRatePerBlockArray[i][0], 18) * blocksPerDay * daysPerYear * 100;
      let totalBorrows = convertToEther(totalBorrowArray[i][0], gTokenDecimals);
      let gammaPerDay = convertToEther(gammaSpeedArray[i][0], 18) * blocksPerDay;
      let gammaBorrowApy = totalBorrows !== 0 ? 100 * (Math.pow(1 + (gammaPrice * gammaPerDay) / (totalBorrows * tokenPrice), daysPerYear) - 1) : 0;
      apyOnLevelSelectAtBorrowZero[tokenAddress] = borrowApyDataOnLevel(borrowApy, 0, gammaBorrowApy, tokenAddress, totalBorrows);
      apyOnLevelSelectAtBorrowOne[tokenAddress] = borrowApyDataOnLevel(borrowApy, 10, gammaBorrowApy, tokenAddress, totalBorrows);
      apyOnLevelSelectAtBorrowTwo[tokenAddress] = borrowApyDataOnLevel(borrowApy, 20, gammaBorrowApy, tokenAddress, totalBorrows);
      apyOnLevelSelectAtBorrowThree[tokenAddress] = borrowApyDataOnLevel(borrowApy, 50, gammaBorrowApy, tokenAddress, totalBorrows);
      // console.log("borrow apy data before",  borrowApy)
      if(tokenAddress.toLowerCase() !== gAqua.toLowerCase() && tokenAddress !== gGamma.toLowerCase() && totalBorrows > 0){
        const discount = levelData.discount;
        const diff = borrowApy - supplyApyArray[tokenAddress];
        borrowApy -= (discount * diff) / 100;
        // console.log("supply apy data", supplyApyArray[tokenAddress], levelData, diff, borrowApy, gammaSpeedArray[i][0])
      }
      borrowGammaApyArray[tokenAddress.toLowerCase()] = gammaBorrowApy;

      const finalBorrowApy = gammaBorrowApy - borrowApy ;
      let aprPerYear = 0
      let borrowApy24hr = 0
      if (finalBorrowApy > 0) {
        aprPerYear = (Math.pow((finalBorrowApy / 100 + 1), (1 / 365)) - 1) * 365 * 100
        borrowApy24hr = (aprPerYear / 365)
      } else {
        aprPerYear = (Math.pow((finalBorrowApy * -1 / 100 + 1), (1 / 365)) - 1) * 365 * 100
        borrowApy24hr = (aprPerYear / 365) * -1
      }
      let obj = {
        withoutRewardApy: borrowApy ? borrowApy : 0,
        borrowApy: finalBorrowApy ? finalBorrowApy : 0,
        borrowApy24hr: finalBorrowApy/365, //borrowApy24hr ? borrowApy24hr : 0,
        address: tokenAddress,
      }
      borrowApyArray[tokenAddress.toLowerCase()] = obj;
      borrowGuardianPausedArray[tokenAddress] = guardianPausedArray[i][0];
      GTokenPriceArray[tokenAddress] = exchangeRate * tokenPrice;
     
    }
    
   
    const levelBorrowApyArray = [apyOnLevelSelectAtBorrowZero, apyOnLevelSelectAtBorrowOne, apyOnLevelSelectAtBorrowTwo, apyOnLevelSelectAtBorrowThree];
    let assetsData: any = [];

    // let boostApyArray = userAddress !== null ? await getUserAndAverageBoostForMarketsAlt(userAddress, new_markets_without_gammaAqua, tokenPriceArray) : [];
    const boostMulticall_fetch_timestamp = new Date().getTime();
    // console.log("boost multicall time taken", boostMulticall_fetch_timestamp - forloop_fetch_timestamp, cashArray )
    let borrowLimit: any = 0;
    let borrowAndUsedLimitData = {
      totalBorrowed: totalBorrowedAmount,
      borrowLimit: suppliedWithCollateral,
      usedBorrowLimit: suppliedWithCollateral > 0 ? totalBorrowedAmount / suppliedWithCollateral : 0,
      usedBorrowLimitInPercentage: suppliedWithCollateral > 0 ? (totalBorrowedAmount / suppliedWithCollateral) * 100 : 0,
    };
    // console.log("borrow balance data",borrowBalanceArr, borrowAndUsedLimitData)
    let gammaObj: any = {};
    for(let i = 0; i < market_array.length; i++){
      let obj = market_array[i];
        const gTokenAddress = obj.address.toLowerCase();
        let vaultData = allPoolData.filter((e: any) => e.address.toLowerCase() === gTokenAddress.toLowerCase())
        // console.log("average boost apy", obj.name, vaultData.avgBoostApy );
        const underlyingToken = obj.token;
        obj.underlyingPrice = tokenPriceArray[gTokenAddress];
        const market = marketsArray[gTokenAddress];
        const burnApy = obj.name === 'AQUA' || obj.name === 'AQUA (OLD)' ? aquaBurnApy : '0'
        const burnApr = obj.name === 'AQUA' || obj.name === 'AQUA (OLD)' ? aquaBurnApr : '0'
        let supplyApy = supplyApyArray[gTokenAddress];
        let borrowGuardianVal = borrowGuardianPausedArray[gTokenAddress];
        let gammaSupplyApy = getGammaSupplyApyArray[gTokenAddress];
        let gammaBorrowApy = borrowGammaApyArray[gTokenAddress];
        let totalSupplyData = totalSupplyArray[gTokenAddress];
        let gTokenPriceVal = GTokenPriceArray[gTokenAddress];
        let underlyingDecimal = +obj.decimals;
        let getCashVal = cashArray[gTokenAddress];
        // console.log("cash reserves available", obj.name, getCashVal)
        let borrowApyObject = borrowApyArray[gTokenAddress];
        let borrowApyVal = borrowApyObject.withoutRewardApy * -1;
        if(userAddress){
          obj.walletBal =
          obj.name !== "BNB"
            ? convertToEther(balanceOfUnderlyingTokenArray[gTokenAddress], underlyingDecimal)
            : convertToEther(await wallet.web3.eth.getBalance(userAddress), 18) 
          
          obj.walletBalInUsd = obj.walletBal * obj.underlyingPrice;
          obj.walletBalInUsd = obj.walletBal * obj.underlyingPrice;
          let index = assets_data.length > 0 ? assets_data.some((e: any) => e.toLowerCase() === gTokenAddress) : false;
          const collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
          const isAvailForCollateral = collateralFactor > 0 ? true : false;
          // console.log(" collateral details", obj.name, collateralFactor, isAvailForCollateral, index, assets_data)
          obj.isUsingAsCollateral = isAvailForCollateral && index ? true : false
          let allowanceVal = allowanceArray[gTokenAddress];
          obj.underlyingTokenAllowance =
            i !== 0
              ? parseFloat(allowanceVal) >= parseFloat(approvalAmount)
                ? true
                : false
              : true;
          obj.gTokenBalance = balanceOfGtokenArray[gTokenAddress] !== undefined ? balanceOfGtokenArray[gTokenAddress] : 0;
          obj.borrowPaused = borrowGuardianVal;
          obj.gTokenAllowance = convertToEther(allowanceVal, 8)
          obj.borrowBalance = borrowBalanceArray[gTokenAddress] ? borrowBalanceArray[gTokenAddress] : 0.0;
          obj.borrowLimitInUsd = borrowAndUsedLimitData.borrowLimit
          obj.borrowLimitUsedCurr = borrowAndUsedLimitData.usedBorrowLimitInPercentage
          obj.currentlySupplying = noExponents(supplyBalanceArr[gTokenAddress]);
          obj.currentlySupplyingUsd = parseFloat(obj.currentlySupplying) * parseFloat(obj.underlyingPrice)
          obj.currentlyBorrowing = borrowBalanceArray[gTokenAddress] ? noExponents(borrowBalanceArray[gTokenAddress]) : 0;
          obj.currentlyBorrowingUsd = parseFloat(obj.currentlyBorrowing) * parseFloat(obj.underlyingPrice)
          obj.isBorrowing = obj.currentlyBorrowingUsd >= 0.01 ? true : false
          obj.isSupplying = obj.currentlySupplyingUsd >= 0.01 ? true : false
          obj.supplyApy = supplyApy ; 
          obj.borrowApy = borrowApyVal;
          obj.supplyApy24hr = parseFloat(obj.supplyApy) / 365
          obj.borrowApy24hr = parseFloat(obj.borrowApy) / 365
          obj.supplyDistributionApy = gammaSupplyApy;//await getGammaSupplyApy(gTokenAddress)
          obj.borrowDistributionApy = gammaBorrowApy;//await getGammaBorrowApy(gTokenAddress)
          obj.burnApy = burnApy
          obj.anchorApy = obj.address === "0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4" ? anchor_apy : 0;
          
          obj.avgBoostApy = 0;//vaultData && vaultData[0] && vaultData[0].avgBoostApy !== undefined ? vaultData[0].avgBoostApy : boostApyArray[gTokenAddress.toLowerCase()] && boostApyArray[gTokenAddress.toLowerCase()].avgBoostApy ? boostApyArray[gTokenAddress.toLowerCase()].avgBoostApy : 0;
          obj.userBoostApy = 0;//boostApyArray[gTokenAddress.toLowerCase()] && boostApyArray[gTokenAddress.toLowerCase()].userBoostApy > 0 && +obj.currentlySupplyingUsd > 1 ? boostApyArray[gTokenAddress.toLowerCase()].userBoostApy : obj.avgBoostApy ;
          // obj.gammBoostPercentage = gammaBoostArr[gTokenAddress.toLowerCase()] ? gammaBoostArr[gTokenAddress.toLowerCase()] : 0;
          let supplyBoost = 0;//obj.userBoostApy > 0 ? obj.userBoostApy : obj.avgBoostApy;
          obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy) + parseFloat(burnApy) + supplyBoost;
  
          obj.totalBorrowApy = obj.borrowApy + obj.borrowDistributionApy;
          obj.collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
          obj.isAvailForCollateral = obj.collateralFactor > 0 ? true : false
          obj.underlyingPrice = tokenPriceArray[gTokenAddress];//await getTokenPrice(gTokenAddress)
          obj.underlyingAddress = underlyingToken
          obj.totalMarketSize =
            convertToEther(totalSupplyData, 8) * ((gTokenPriceVal) / parseFloat(obj.underlyingPrice))
          obj.totalMarketSizeUsd = (gTokenPriceVal) * parseFloat(convertToEther(totalSupplyData, 8))
          obj.available = parseFloat(convertToEther(getCashVal, underlyingDecimal))
          obj.availableUsd = obj.available * parseFloat(obj.underlyingPrice)


          const walletBal = i !== 0 ? obj.walletBal : obj.walletBal - 0.01

          const supplyBorrowLimitData = await getBorrowLimitParamsOnChangeRevised(
            obj.isUsingAsCollateral,
            gTokenAddress,
            1,
            walletBal,
            obj.borrowLimitInUsd,
            totalBorrowedAmount,
            obj.underlyingPrice
          )

          if (obj.isUsingAsCollateral) {
            const collateralGiven =
              obj.currentlySupplyingUsd *
              parseFloat(convertToEther(market['collateralFactorMantissa'], 18))

            if (borrowAndUsedLimitData.borrowLimit - collateralGiven >= borrowAndUsedLimitData.totalBorrowed) {
              obj.maxWithdraw = obj.currentlySupplying
            } else {
              obj.maxWithdraw =
                (borrowAndUsedLimitData.borrowLimit - borrowAndUsedLimitData.totalBorrowed) /
                obj.underlyingPrice /
                parseFloat(convertToEther(market['collateralFactorMantissa'], 18))
            }
          } else {
            obj.maxWithdraw = obj.currentlySupplying
          }

          obj.maxWithdraw = obj.maxWithdraw >= obj.available ? obj.available : obj.maxWithdraw

          obj.supplyLowestBorrowLimitUsed = obj.isUsingAsCollateral ? supplyBorrowLimitData?.usedBorrowLimitInPercentage : 0

          
          if(gTokenAddress.toLowerCase() == gGamma.toLowerCase()){
            gammaObj = obj;
          } 
        } else {
          obj.walletBal = 0
          obj.walletBalInUsd = 0
          obj.isUsingAsCollateral = false
          obj.underlyingTokenAllowance = 0
          obj.borrowPaused = borrowGuardianVal;//await borrowGuardianPaused(gTokenAddress, gammatrollerAddress)
          obj.gTokenBalance = 0;
          obj.gTokenAllowance = 0
          obj.borrowBalance = 0
          obj.borrowLimitInUsd = 0
          obj.borrowLimitUsedCurr = 0
          obj.currentlySupplying = 0
          obj.currentlySupplyingUsd = 0
          obj.currentlyBorrowing = 0
          obj.currentlyBorrowingUsd = 0
          obj.isBorrowing = obj.currentlyBorrowing > 0 ? true : false
          obj.isSupplying = obj.currentlySupplying > 0 ? true : false
          obj.supplyApy = obj.address === "0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4" ? supplyApy - anchor_apy : supplyApy;//await getSupplyApy(gTokenAddress, userAddress)
          obj.borrowApy = borrowApyVal;//((await getApyOnLevelSelectAtBorrow(gTokenAddress, level.level)).withoutRewardApy) * -1
          obj.supplyApy24hr = parseFloat(obj.supplyApy) / 365
          obj.borrowApy24hr = parseFloat(obj.borrowApy) / 365
          obj.supplyDistributionApy = gammaSupplyApy;//await getGammaSupplyApy(gTokenAddress)
          obj.borrowDistributionApy = gammaBorrowApy;//await getGammaBorrowApy(gTokenAddress)
          obj.burnApy = burnApy
          obj.anchorApy = 0;
          const aprPerYear = (Math.pow(((parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy)) / 100 + 1), (1 / 365)) - 1) * 365 * 100
          const totalApr = aprPerYear + parseFloat(burnApr)
          // obj.gammBoostPercentage = gammaBoostArr[gTokenAddress.toLowerCase()] ? gammaBoostArr[gTokenAddress.toLowerCase()] : 0;
          
          obj.avgBoostApy = 0; //vaultData && vaultData[0] && vaultData[0].avgBoostApy !== undefined ? vaultData[0].avgBoostApy : boostApyArray[gTokenAddress.toLowerCase()] && boostApyArray[gTokenAddress.toLowerCase()].avgBoostApy ? boostApyArray[gTokenAddress.toLowerCase()].avgBoostApy : 0;
          obj.userBoostApy = 0;//obj.avgBoostApy;
          
          obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy) + parseFloat(burnApy) + +obj.avgBoostApy;
          obj.totalBorrowApy = obj.borrowApy + obj.borrowDistributionApy
          obj.collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
          obj.isAvailForCollateral = obj.collateralFactor > 0 ? true : false
          obj.underlyingPrice = tokenPriceArray[gTokenAddress];//await getTokenPrice(gTokenAddress)
          obj.underlyingAddress = underlyingToken
          obj.totalMarketSize =
            convertToEther(totalSupplyData, 8) * ((gTokenPriceVal) / parseFloat(obj.underlyingPrice))
          obj.totalMarketSizeUsd = (gTokenPriceVal) * parseFloat(convertToEther(totalSupplyData, 8))
          obj.available = parseFloat(convertToEther(getCashVal, underlyingDecimal))
          obj.availableUsd = obj.available * parseFloat(obj.underlyingPrice)
          if(gTokenAddress.toLowerCase() == gGamma.toLowerCase()){
            gammaObj = obj;
          }
        }
        assetsData.push(obj);
    }

    // assetsData = await Promise.all(assetsData)
    // console.log(" data in v2", data)
    const suppliedAssets: any = assetsData.filter((e: any) => e.isSupplying)
    const borrowedAssets: any = assetsData.filter((e: any) => e.isBorrowing)
    // console.log("supplied and borrowed assets list",userAddress, data, suppliedAssets, borrowedAssets, { data, suppliedAssets, borrowedAssets })
    let timestamp2 = new Date().getTime();
    // console.log("time taken to load user supplied & borrowed assets data", timestamp2 - timestamp)
    // let latestParamsOnAccrueInterestData = await latestParamsOnAccrueInterest("0x820cDE110D5f17b9F985FBb80f10D4f7158F130f");
    // console.log("asset data", assetsData)
    let data = assetsData
    return { data, suppliedAssets, borrowedAssets, gammaObj, levelBorrowApyArray }
}

export const getUserSuppliedAndBorrowedAssetData = async (userAddress: string) => {
  // console.log("inside getUserSuppliedAndBorrowedAssetData");
  // userAddress = "0xbBdA5018Bd032D97744e14F72676AB77b0525E39";//'0xf485073e84121fa19e620e7f8cba8ab11d4a6808';//"";
  const userSuppliedAndBorrowedAssetDetails = await getUserSuppliedAndBorrowedAssetDataRevised(userAddress);
  return userSuppliedAndBorrowedAssetDetails;
  const list = pfTokenList
  const listLength = Object.keys(list).length
  let timestamp = new Date().getTime();
  let data: any = []
  const gammaPrice = await getTokenPrice(gGamma);
  const blocksPerDay = 28800 // 3 seconds per block
    const daysPerYear = 365
  const borrowData: any = userAddress ? await getBorrowAndUsedLimit(userAddress) : 0
  // console.log("borrowData", borrowData);
  const burnApyVal = await getBurnApy()
  const burnAprVal = await getBurnApr()
  const level = await getDiscountLevel(userAddress)
  // const treasuryApyVal = await getTreasuryApy()
  /** multicall common functions */
  const tokenArr: any = Object.values(pfTokenList);
  let decimalArr: any = [];
  for (var key in tokenArr){
    decimalArr[tokenArr[key].address] = tokenArr[key].decimals
  }
  let totalUnderlyingPriceArray = await getTotalUnderlyingPriceMulticall(pfTokenList);
  let userSupplyBalance: any;
  let multicalAssetsArr: any;
  let borrowBalanceArray: any;
  userSupplyBalance = userAddress !== null ? await getAllSupplyBalanceMulticall(userAddress) : [];
  multicalAssetsArr = userAddress !== null ? await getAssetsInMulticall(userAddress, gammatrollerAddress) : [];
  // console.log("multicalAssetsArr",multicalAssetsArr)
  borrowBalanceArray = userAddress !== null ? await getBorrowBalanceMulticall(multicalAssetsArr, userAddress) : [];
  // console.log("borrowBalanceArray",borrowBalanceArray)
  // const getMarketLiquidityBalData = await getMarketLiquidityData(totalUnderlyingPriceArray)//multicall
  // const assetsIn = await getAssetsIn(userAddress, gammatrollerAddress)
  const marketsArray = await marketsMulticallRevised(pfTokenList, listLength, gammatrollerAddress, multicalAssetsArr)
  const balanceOfGtokenArray = await balanceOfGtokenMulticall(userAddress, pfTokenList, listLength)
  const allowanceArray = await allowanceMulticall(userAddress, pfTokenList, listLength);
  //const totalSupply = await totalSupplyMulticall(pfTokenList, assetsLen, 0, blockNumber);
  const supplyApyArray: any = await getSupplyApyMulticall(pfTokenList, userAddress)
  // const borrowApyArray: any = await getBorrowApyMulticall(pfTokenList, userAddress, supplyApyArray)
  const gammaPerDay: any = await gammaSpeedsMulticall(pfTokenList, gammatrollerAddress);
  const getGammaSupplyApyArray: any = await getGammaSupplyApyMulticall(pfTokenList, gammaPrice, gammaPerDay, totalUnderlyingPriceArray, decimalArr);
  const getGammaBorrowApyArray: any = await getGammaBorrowApyMulticall(pfTokenList, gammaPrice, gammaPerDay, totalUnderlyingPriceArray, decimalArr)
  const totalBorrowBalance = await getTotalBorrowBalance(userAddress)
  //  totalSupplyMulticall = async (gTokenAddressArray: any, tokenLength: number, etherBase: number, blockNumber?:number )
  const totalSupplyArray = await totalSupplyMulticall(pfTokenList, listLength);
  const exchangeRateArray = await exchangeRateStoredMulticall(pfTokenList, listLength)
  const getGTokenPriceArray = await getGTokenPriceRevised(pfTokenList, listLength, totalUnderlyingPriceArray, decimalArr, exchangeRateArray)
  const borrowGuardianPausedArray = await borrowGuardianPausedMulticall(pfTokenList, gammatrollerAddress)
  const cashArray = await getCashMulticall(pfTokenList, listLength)
  const apyOnLevelSelectAtBorrow = await getApyOnLevelSelectAtBorrowRevised(pfTokenList, level.level, getGammaBorrowApyArray, totalSupplyArray)
  const apyOnLevelSelectAtBorrowZero = level.level === 0 ? apyOnLevelSelectAtBorrow : await getApyOnLevelSelectAtBorrowRevised(pfTokenList, 0, getGammaBorrowApyArray, totalSupplyArray)
  const apyOnLevelSelectAtBorrowOne = level.level === 1 ? apyOnLevelSelectAtBorrow : await getApyOnLevelSelectAtBorrowRevised(pfTokenList, 1, getGammaBorrowApyArray, totalSupplyArray)
  const apyOnLevelSelectAtBorrowTwo = level.level === 0 ? apyOnLevelSelectAtBorrow : await getApyOnLevelSelectAtBorrowRevised(pfTokenList, 2, getGammaBorrowApyArray, totalSupplyArray)
  const apyOnLevelSelectAtBorrowThree = level.level === 3 ? apyOnLevelSelectAtBorrow : await getApyOnLevelSelectAtBorrowRevised(pfTokenList, 3, getGammaBorrowApyArray, totalSupplyArray)
  // console.log("totalSupplyData", totalSupplyArray); 
  /** End of common functions */
  const levelBorrowApyArray = [apyOnLevelSelectAtBorrowZero, apyOnLevelSelectAtBorrowOne, apyOnLevelSelectAtBorrowTwo, apyOnLevelSelectAtBorrowThree];
  // console.log("levelArray in lending base", levelBorrowApyArray)
  let gammaObj: any = {};
  for (let i = 0; i < listLength; i++) {
    const getUserData = async (i: any) => {
      try {
        let obj = list[i]
        const gTokenAddress = obj.address
        // console.log("gTokenAddress", gTokenAddress, borrowBalanceArray[gTokenAddress])
        const underlyingToken = obj.token;//await underlying(gTokenAddress)
        obj.underlyingPrice = totalUnderlyingPriceArray[gTokenAddress];//await getTokenPrice(gTokenAddress)
        const market = marketsArray[gTokenAddress]//await markets(gTokenAddress, gammatrollerAddress)
        
        const burnApy = obj.name === 'AQUA' || obj.name === 'AQUA (OLD)' ? burnApyVal : '0'
        const burnApr = obj.name === 'AQUA' || obj.name === 'AQUA (OLD)' ? burnAprVal : '0'
        // const treasuryApy = obj.name === 'AQUA' ? treasuryApyVal : '0'
        let supplyApy = supplyApyArray[gTokenAddress];// ? supplyApyArray[gTokenAddress] : 0; //await getSupplyApy(gTokenAddress, userAddress)
        let borrowGuardianVal = borrowGuardianPausedArray[gTokenAddress]; //? borrowGuardianPausedArray[gTokenAddress] : 0;//await borrowGuardianPaused(gTokenAddress, gammatrollerAddress)        
        let gammaSupplyApy = getGammaSupplyApyArray[gTokenAddress];// ? getGammaSupplyApyArray[gTokenAddress] : 0;//await getGammaSupplyApy(gTokenAddress)
        let gammaBorrowApy = getGammaBorrowApyArray[gTokenAddress];//await getGammaBorrowApy(gTokenAddress)
        let totalSupplyData = totalSupplyArray[gTokenAddress];//await totalSupply(gTokenAddress)
        let gTokenPriceVal = getGTokenPriceArray[gTokenAddress];//await getGTokenPrice(gTokenAddress)
        let underlyingDecimal = decimalArr[gTokenAddress];//await getUnderlyingDecimal(gTokenAddress);
        let getCashVal = cashArray[gTokenAddress];//await getCash(gTokenAddress);
        let borrowApyObject = apyOnLevelSelectAtBorrow[gTokenAddress];//await getApyOnLevelSelectAtBorrow(gTokenAddress, level.level)
        let borrowApyVal = borrowApyObject.withoutRewardApy * -1;//((await getApyOnLevelSelectAtBorrow(gTokenAddress, level.level)).withoutRewardApy) * -1;
        // console.log("totalSupplyData", gTokenAddress, totalSupplyData); 
        // console.log("gTokenPriceVal rev", getGTokenPriceArray[gTokenAddress]);
        if (userAddress != null && userAddress !== '') {
          obj.walletBal =
          i !== 0
            ? convertToEther(balanceOfGtokenArray[gTokenAddress], decimalArr[gTokenAddress])
            : convertToEther(await wallet.web3.eth.getBalance(userAddress), 18) 
          
          obj.walletBalInUsd = obj.walletBal * obj.underlyingPrice
          let index = multicalAssetsArr.some((e: any) => e.address === gTokenAddress);
          const collateralFactor = convertToEther(market['collateralFactorMantissa'], 18) * 100
          const isAvailForCollateral = collateralFactor > 0 ? true : false
          obj.isUsingAsCollateral = isAvailForCollateral && index ? true : false
          let allowanceVal = allowanceArray[gTokenAddress];//await allowance(userAddress, gTokenAddress, underlyingToken);
          obj.underlyingTokenAllowance =
            i !== 0
              ? parseFloat(allowanceVal) >= parseFloat(approvalAmount)
                ? true
                : false
              : true;
          
          obj.borrowPaused = borrowGuardianVal;//await borrowGuardianPaused(gTokenAddress, gammatrollerAddress)
          obj.gTokenAllowance = convertToEther(allowanceVal, 8)
          obj.borrowBalance = borrowBalanceArray[gTokenAddress] ? borrowBalanceArray[gTokenAddress] : 0.0;//await getBorrowBalance(gTokenAddress, userAddress)
          obj.borrowLimitInUsd = borrowData.borrowLimit
          obj.borrowLimitUsedCurr = borrowData.usedBorrowLimitInPercentage
          obj.currentlySupplying = noExponents(userSupplyBalance[gTokenAddress]);//noExponents(await getSupplyBalance(gTokenAddress, userAddress))
          obj.currentlySupplyingUsd = parseFloat(obj.currentlySupplying) * parseFloat(obj.underlyingPrice)
          obj.currentlyBorrowing = borrowBalanceArray[gTokenAddress] ? noExponents(borrowBalanceArray[gTokenAddress]) : 0;//noExponents(await getBorrowBalance(gTokenAddress, userAddress))
          obj.currentlyBorrowingUsd = parseFloat(obj.currentlyBorrowing) * parseFloat(obj.underlyingPrice)
          obj.isBorrowing = obj.currentlyBorrowingUsd >= 0.01 ? true : false
          obj.isSupplying = obj.currentlySupplyingUsd >= 0.01 ? true : false
          obj.supplyApy = obj.address === "0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4" ? supplyApy - anchor_apy : supplyApy; //supplyApy;//await getSupplyApy(gTokenAddress, userAddress)
          obj.borrowApy = borrowApyVal;//((await getApyOnLevelSelectAtBorrow(gTokenAddress, level.level)).withoutRewardApy) * -1
          obj.supplyApy24hr = parseFloat(obj.supplyApy) / 365
          obj.borrowApy24hr = parseFloat(obj.borrowApy) / 365
          obj.supplyDistributionApy = gammaSupplyApy;//await getGammaSupplyApy(gTokenAddress)
          obj.borrowDistributionApy = gammaBorrowApy;//await getGammaBorrowApy(gTokenAddress)
          obj.burnApy = burnApy
          obj.anchorApy = obj.address === "0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4" ? anchor_apy : 0;
          // const aprPerYear = (Math.pow(((parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy))/100 + 1),(1/365)) - 1)*365*100
          // const totalApr = aprPerYear+parseFloat(burnApr)
          // if(obj.name === 'AQUA' || obj.name === 'AQUA (OLD)'){
          //   obj.totalSupplyApy = (Math.pow((1+(totalApr/100)/365),365)-1)*100
          // }
          // else{
          //   obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy)
          // }
          // obj.treasuryApy = treasuryApy
          // obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy) + parseFloat(burnApy) + parseFloat(treasuryApy)
          obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy) + parseFloat(burnApy) + obj.anchorApy
          obj.totalBorrowApy = obj.borrowApy + obj.borrowDistributionApy
          obj.collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
          obj.isAvailForCollateral = obj.collateralFactor > 0 ? true : false
          obj.underlyingPrice = totalUnderlyingPriceArray[gTokenAddress];//await getTokenPrice(gTokenAddress)
          obj.underlyingAddress = underlyingToken
          obj.totalMarketSize =
            convertToEther(totalSupplyData, 8) * ((gTokenPriceVal) / parseFloat(obj.underlyingPrice))
          obj.totalMarketSizeUsd = (gTokenPriceVal) * parseFloat(convertToEther(totalSupplyData, 8))
          obj.available = parseFloat(convertToEther(getCashVal, underlyingDecimal))
          obj.availableUsd = obj.available * parseFloat(obj.underlyingPrice)

          /***********************************************************************************/

          const walletBal = i !== 0 ? obj.walletBal : obj.walletBal - 0.01

          const supplyBorrowLimitData = await getBorrowLimitParamsOnChange(
            obj.isUsingAsCollateral,
            gTokenAddress,
            1,
            walletBal,
            obj.borrowLimitInUsd,
            totalBorrowBalance
          )

          if (obj.isUsingAsCollateral) {
            const collateralGiven =
              obj.currentlySupplyingUsd *
              parseFloat(convertToEther(market['collateralFactorMantissa'], 18))

            if (borrowData.borrowLimit - collateralGiven >= borrowData.totalBorrowed) {
              obj.maxWithdraw = obj.currentlySupplying
            } else {
              obj.maxWithdraw =
                (borrowData.borrowLimit - borrowData.totalBorrowed) /
                obj.underlyingPrice /
                parseFloat(convertToEther(market['collateralFactorMantissa'], 18))
            }
          } else {
            obj.maxWithdraw = obj.currentlySupplying
          }

          obj.maxWithdraw = obj.maxWithdraw >= obj.available ? obj.available : obj.maxWithdraw

          obj.supplyLowestBorrowLimitUsed = obj.isUsingAsCollateral ? supplyBorrowLimitData?.usedBorrowLimitInPercentage : 0

          /***********************************************************************************/
          if(gTokenAddress.toLowerCase() == "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442".toLowerCase()){
            gammaObj = obj;
          }
          return obj
        } else {
          obj.walletBal = 0
          obj.walletBalInUsd = 0
          obj.isUsingAsCollateral = false
          obj.underlyingTokenAllowance = 0
          obj.borrowPaused = borrowGuardianVal;//await borrowGuardianPaused(gTokenAddress, gammatrollerAddress)
          obj.gTokenAllowance = 0
          obj.borrowBalance = 0
          obj.borrowLimitInUsd = 0
          obj.borrowLimitUsedCurr = 0
          obj.currentlySupplying = 0
          obj.currentlySupplyingUsd = 0
          obj.currentlyBorrowing = 0
          obj.currentlyBorrowingUsd = 0
          obj.isBorrowing = obj.currentlyBorrowing > 0 ? true : false
          obj.isSupplying = obj.currentlySupplying > 0 ? true : false
          obj.supplyApy = obj.address === "0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4" ? supplyApy - anchor_apy : supplyApy;//await getSupplyApy(gTokenAddress, userAddress)
          obj.borrowApy = borrowApyVal;//((await getApyOnLevelSelectAtBorrow(gTokenAddress, level.level)).withoutRewardApy) * -1
          obj.supplyApy24hr = parseFloat(obj.supplyApy) / 365
          obj.borrowApy24hr = parseFloat(obj.borrowApy) / 365
          obj.supplyDistributionApy = gammaSupplyApy;//await getGammaSupplyApy(gTokenAddress)
          obj.borrowDistributionApy = gammaBorrowApy;//await getGammaBorrowApy(gTokenAddress)
          obj.burnApy = burnApy
          obj.anchorApy = obj.address === "0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4" ? anchor_apy : 0;
          const aprPerYear = (Math.pow(((parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy)) / 100 + 1), (1 / 365)) - 1) * 365 * 100
          const totalApr = aprPerYear + parseFloat(burnApr)
          // if (obj.name === 'AQUA' || obj.name === 'AQUA (OLD)') {
          //   obj.totalSupplyApy = (Math.pow((1 + (totalApr / 100) / 365), 365) - 1) * 100
          // }
          // else {
          //   obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy)
          // }
          // obj.treasuryApy = treasuryApy
          // obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy) + parseFloat(burnApy) + parseFloat(treasuryApy)
          obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy) + parseFloat(burnApy) + obj.anchorApy;
          obj.totalBorrowApy = obj.borrowApy + obj.borrowDistributionApy
          obj.collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
          obj.isAvailForCollateral = obj.collateralFactor > 0 ? true : false
          obj.underlyingPrice = totalUnderlyingPriceArray[gTokenAddress];//await getTokenPrice(gTokenAddress)
          obj.underlyingAddress = underlyingToken
          obj.totalMarketSize =
            convertToEther(totalSupplyData, 8) * ((gTokenPriceVal) / parseFloat(obj.underlyingPrice))
          obj.totalMarketSizeUsd = (gTokenPriceVal) * parseFloat(convertToEther(totalSupplyData, 8))
          obj.available = parseFloat(convertToEther(getCashVal, underlyingDecimal))
          obj.availableUsd = obj.available * parseFloat(obj.underlyingPrice)
          if(gTokenAddress.toLowerCase() == "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442".toLowerCase()){
            gammaObj = obj;
          }
          return obj
        }
      } catch (err) {
        console.log(err)
      }
    }
    if((list[i].address).toLowerCase() === "0xF701A48e5C751A213b7c540F84B64b5A6109962E".toLowerCase() || (list[i].address).toLowerCase() === "0xB3A4ce0654524dCF4B5165cee280EbE69a6E8133".toLowerCase()){
      continue;
    }
    data.push(getUserData(i))
  }
  data = await Promise.all(data)
  const suppliedAssets: any = data.filter((e: any) => e.isSupplying)
  const borrowedAssets: any = data.filter((e: any) => e.isBorrowing)
  // console.log("supplied and borrowed assets list",userAddress, data, suppliedAssets, borrowedAssets, { data, suppliedAssets, borrowedAssets })
  // let timestamp2 = new Date().getTime();
  // console.log("getUserSuppliedAndBorrowedAssetData in v1", data, suppliedAssets, borrowedAssets, gammaObj, levelBorrowApyArray)
  // let latestParamsOnAccrueInterestData = await latestParamsOnAccrueInterest("0x820cDE110D5f17b9F985FBb80f10D4f7158F130f");
  // console.log("latestParamsOnAccrueInterestData",latestParamsOnAccrueInterestData)
  return { data, suppliedAssets, borrowedAssets, gammaObj, levelBorrowApyArray }
}

export const getUserSuppliedAndBorrowedAssetDataOriginal = async (userAddress: string) => {
  //userAddress = "0x7c2068d95630f05aa1778bF236c0BF5FA66E51E9"
  userAddress = "0xD1Ec391627c9E2Fb0c570Da876Bc75dF23c42BEB";//"0xb1c0fEC1004908B2230DD12d761f7c4946C23bA6";
  const list = pfTokenList
  const listLength = Object.keys(list).length

  let data: any = []
  const borrowData: any = userAddress ? await getBorrowAndUsedLimit(userAddress) : 0
  const burnApyVal = await getBurnApy()
  const burnAprVal = await getBurnApr()
  // const treasuryApyVal = await getTreasuryApy()
  /** multicall common functions */
  /** End of common functions */
  const level = await getDiscountLevel(userAddress)
  for (let i = 0; i < listLength; i++) {
    const getUserData = async (i: any) => {
      try {
        let obj = list[i]
        const gTokenAddress = obj.address
        const underlyingToken = await underlying(gTokenAddress)
        obj.underlyingPrice = await getTokenPrice(gTokenAddress)
        const market = await markets(gTokenAddress, gammatrollerAddress)

        const burnApy = obj.name === 'AQUA' || obj.name === 'AQUA (OLD)' ? burnApyVal : '0'
        const burnApr = obj.name === 'AQUA' || obj.name === 'AQUA (OLD)' ? burnAprVal : '0'
        // const treasuryApy = obj.name === 'AQUA' ? treasuryApyVal : '0'

        if (userAddress != null && userAddress !== '') {
          obj.walletBal =
            i !== 0
              ? convertToEther(await balanceOfGtoken(userAddress, underlyingToken), await decimals(underlyingToken))
              : convertToEther(await wallet.web3.eth.getBalance(userAddress), 18)

          obj.walletBalInUsd = obj.walletBal * obj.underlyingPrice
          obj.isUsingAsCollateral = await isGivenAsCollateral(gTokenAddress, userAddress)
          obj.underlyingTokenAllowance =
            i !== 0
              ? parseFloat(await allowance(userAddress, gTokenAddress, underlyingToken)) >= parseFloat(approvalAmount)
                ? true
                : false
              : true
          obj.borrowPaused = await borrowGuardianPaused(gTokenAddress, gammatrollerAddress)
          obj.gTokenAllowance = convertToEther(await allowance(userAddress, gTokenAddress, gTokenAddress), 8)
          obj.borrowBalance = await getBorrowBalance(gTokenAddress, userAddress)
          obj.borrowLimitInUsd = borrowData.borrowLimit
          obj.borrowLimitUsedCurr = borrowData.usedBorrowLimitInPercentage
          obj.currentlySupplying = noExponents(await getSupplyBalance(gTokenAddress, userAddress))
          obj.currentlySupplyingUsd = parseFloat(obj.currentlySupplying) * parseFloat(obj.underlyingPrice)
          obj.currentlyBorrowing = noExponents(await getBorrowBalance(gTokenAddress, userAddress))
          obj.currentlyBorrowingUsd = parseFloat(obj.currentlyBorrowing) * parseFloat(obj.underlyingPrice)
          obj.isBorrowing = obj.currentlyBorrowingUsd >= 0.01 ? true : false
          obj.isSupplying = obj.currentlySupplyingUsd >= 0.01 ? true : false
          obj.supplyApy = await getSupplyApy(gTokenAddress, userAddress)
          obj.borrowApy = ((await getApyOnLevelSelectAtBorrow(gTokenAddress, level.level)).withoutRewardApy) * -1
          obj.supplyApy24hr = parseFloat(obj.supplyApy) / 365
          obj.borrowApy24hr = parseFloat(obj.borrowApy) / 365
          obj.supplyDistributionApy = await getGammaSupplyApy(gTokenAddress)
          obj.borrowDistributionApy = await getGammaBorrowApy(gTokenAddress)
          obj.burnApy = burnApy

          // const aprPerYear = (Math.pow(((parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy))/100 + 1),(1/365)) - 1)*365*100
          // const totalApr = aprPerYear+parseFloat(burnApr)
          // if(obj.name === 'AQUA' || obj.name === 'AQUA (OLD)'){
          //   obj.totalSupplyApy = (Math.pow((1+(totalApr/100)/365),365)-1)*100
          // }
          // else{
          //   obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy)
          // }
          // obj.treasuryApy = treasuryApy
          // obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy) + parseFloat(burnApy) + parseFloat(treasuryApy)
          obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy) + parseFloat(burnApy)
          obj.totalBorrowApy = obj.borrowApy + obj.borrowDistributionApy
          obj.collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
          obj.isAvailForCollateral = obj.collateralFactor > 0 ? true : false
          obj.underlyingPrice = await getTokenPrice(gTokenAddress)
          obj.underlyingAddress = underlyingToken
          obj.totalMarketSize =
            convertToEther(await totalSupply(gTokenAddress), 8) * ((await getGTokenPrice(gTokenAddress)) / parseFloat(obj.underlyingPrice))
          obj.totalMarketSizeUsd = (await getGTokenPrice(gTokenAddress)) * parseFloat(convertToEther(await totalSupply(gTokenAddress), 8))
          obj.available = parseFloat(convertToEther(await getCash(gTokenAddress), await getUnderlyingDecimal(gTokenAddress)))
          obj.availableUsd = obj.available * parseFloat(obj.underlyingPrice)

          /***********************************************************************************/

          const walletBal = i !== 0 ? obj.walletBal : obj.walletBal - 0.01

          const supplyBorrowLimitData = await getBorrowLimitParamsOnChange(
            obj.isUsingAsCollateral,
            gTokenAddress,
            1,
            walletBal,
            obj.borrowLimitInUsd,
            await getTotalBorrowBalance(userAddress)
          )

          if (obj.isUsingAsCollateral) {
            const collateralGiven =
              obj.currentlySupplyingUsd *
              parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))

            if (borrowData.borrowLimit - collateralGiven >= borrowData.totalBorrowed) {
              obj.maxWithdraw = obj.currentlySupplying
            } else {
              obj.maxWithdraw =
                (borrowData.borrowLimit - borrowData.totalBorrowed) /
                obj.underlyingPrice /
                parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))
            }
          } else {
            obj.maxWithdraw = obj.currentlySupplying
          }

          obj.maxWithdraw = obj.maxWithdraw >= obj.available ? obj.available : obj.maxWithdraw

          obj.supplyLowestBorrowLimitUsed = obj.isUsingAsCollateral ? supplyBorrowLimitData?.usedBorrowLimitInPercentage : 0

          /***********************************************************************************/

          return obj
        } else {
          obj.walletBal = 0
          obj.walletBalInUsd = 0
          obj.isUsingAsCollateral = false
          obj.underlyingTokenAllowance = 0
          obj.borrowPaused = await borrowGuardianPaused(gTokenAddress, gammatrollerAddress)
          obj.gTokenAllowance = 0
          obj.borrowBalance = 0
          obj.borrowLimitInUsd = 0
          obj.borrowLimitUsedCurr = 0
          obj.currentlySupplying = 0
          obj.currentlySupplyingUsd = 0
          obj.currentlyBorrowing = 0
          obj.currentlyBorrowingUsd = 0
          obj.isBorrowing = obj.currentlyBorrowing > 0 ? true : false
          obj.isSupplying = obj.currentlySupplying > 0 ? true : false
          obj.supplyApy = await getSupplyApy(gTokenAddress, userAddress)
          obj.borrowApy = ((await getApyOnLevelSelectAtBorrow(gTokenAddress, level.level)).withoutRewardApy) * -1
          obj.supplyApy24hr = parseFloat(obj.supplyApy) / 365
          obj.borrowApy24hr = parseFloat(obj.borrowApy) / 365
          obj.supplyDistributionApy = await getGammaSupplyApy(gTokenAddress)
          obj.borrowDistributionApy = await getGammaBorrowApy(gTokenAddress)
          obj.burnApy = burnApy

          const aprPerYear = (Math.pow(((parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy)) / 100 + 1), (1 / 365)) - 1) * 365 * 100
          const totalApr = aprPerYear + parseFloat(burnApr)
          // if (obj.name === 'AQUA' || obj.name === 'AQUA (OLD)') {
          //   obj.totalSupplyApy = (Math.pow((1 + (totalApr / 100) / 365), 365) - 1) * 100
          // }
          // else {
          //   obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy)
          // }
          // obj.treasuryApy = treasuryApy
          // obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy) + parseFloat(burnApy) + parseFloat(treasuryApy)
          obj.totalSupplyApy = parseFloat(obj.supplyApy) + parseFloat(obj.supplyDistributionApy) + parseFloat(burnApy)
          obj.totalBorrowApy = obj.borrowApy + obj.borrowDistributionApy
          obj.collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
          obj.isAvailForCollateral = obj.collateralFactor > 0 ? true : false
          obj.underlyingPrice = await getTokenPrice(gTokenAddress)
          obj.underlyingAddress = underlyingToken
          obj.totalMarketSize =
            convertToEther(await totalSupply(gTokenAddress), 8) * ((await getGTokenPrice(gTokenAddress)) / parseFloat(obj.underlyingPrice))
          obj.totalMarketSizeUsd = (await getGTokenPrice(gTokenAddress)) * parseFloat(convertToEther(await totalSupply(gTokenAddress), 8))
          obj.available = parseFloat(convertToEther(await getCash(gTokenAddress), await getUnderlyingDecimal(gTokenAddress)))
          obj.availableUsd = obj.available * parseFloat(obj.underlyingPrice)
          return obj
        }
      } catch (err) {
        console.log(err)
      }
    }
    data.push(getUserData(i))
  }
  data = await Promise.all(data)
  const suppliedAssets: any = data.filter((e: any) => e.isSupplying)
  const borrowedAssets: any = data.filter((e: any) => e.isBorrowing)
  console.log("supplied and borrowed assets list",userAddress, data, suppliedAssets, borrowedAssets)
  return { data, suppliedAssets, borrowedAssets }
}

export const getAquaPrice = async () => {
  const lpAddress = AQUA_BNBLPAddress
  const bnbPrice = await getBnbPrice()
  const reserves = await getReserves(lpAddress)
  const reserve0 = reserves['_reserve0']
  const reserve1 = reserves['_reserve1']
  const bnbPerAQUA = reserve1 / reserve0
  const aquafinalPrice = bnbPerAQUA * bnbPrice
  return aquafinalPrice
}

export const getGammaPrice = async () => {
  const lpAddress = GAMMA_BNBLPAddress
  const bnbPrice = await getBnbPrice()
  const reserves = await getReserves(lpAddress)
  const reserve0 = reserves['_reserve0']
  const reserve1 = reserves['_reserve1']
  const bnbPerGAMMA = reserve1 / reserve0
  const gammafinalPrice = bnbPerGAMMA * bnbPrice
  return gammafinalPrice
}

export const getDiscountLevel = async (userAddress: string) => {
  try {

    let level = 0
    let discount = 0
    if (userAddress !== null) {
      const inst = await selectInstance(instType.DL, discountLevelAddress)
      discount = parseFloat(await inst.methods.returnDiscountPercentage(userAddress).call())

      if (discount === level3Discount) {
        level = 3
      } else if (discount === level2Discount) {
        level = 2
      } else if (discount === level1Discount) {
        level = 1
      }
    }

    return {
      level: level,
      discount: discount / 100,
    }
  } catch (err) {
    console.log(err)
    return {
      level: 0,
      discount: 0,
    }
  }
}

export const getDiscountLevelMulticall = async (userAddress: string) => {
  try {

    let level = 0
    let discount = 0
    if (userAddress !== null) {
      const inst = await selectInstance(instType.DL, discountLevelAddress)
      discount = parseFloat(await inst.methods.returnDiscountPercentage(userAddress).call())

      if (discount === level3Discount) {
        level = 3
      } else if (discount === level2Discount) {
        level = 2
      } else if (discount === level1Discount) {
        level = 1
      }
    }

    return {
      level: level,
      discount: discount / 100,
    }
  } catch (err) {
    console.log(err)
    return {
      level: 0,
      discount: 0,
    }
  }
}

export const getBorrowLimitData = async (userAddress: string) => {
  // userAddress = "0x89790b79a234cecd06a85d0b0922f9e021137c7b";//"0xf485073e84121fa19e620e7f8cba8ab11d4a6808";
  let borrowLimit: any = 0
  borrowLimit = userAddress !== null ? await getBorrowAndUsedLimit(userAddress) : 0
  let result: any = {}
  result.availableCredit = borrowLimit.borrowLimit - borrowLimit.totalBorrowed
  result.borrowLimitInUsd = borrowLimit.borrowLimit
  result.borrowLimitUsedCurr = borrowLimit.usedBorrowLimitInPercentage //in percentage
  result.userTotalBorrowBal = userAddress !== null ? await getTotalBorrowBalance(userAddress) : 0
  result.remainingBorrowBalUsd = parseFloat(result.borrowLimitInUsd) - parseFloat(result.userTotalBorrowBal)
  return result
}

export const getGammaAndAquaPrice = async () => {
  let res = {
    aquaPrice: 0,
    gammaPrice: 0
  };
  res.aquaPrice = await getAquaPrice();
  res.gammaPrice = await getGammaPrice();
  return res;
}

export const getPendingData = async (userAddress: string) => {
  let pendingGamma: any = 0
  let gammaPrice: any = 0
  let pendingGammaUsd: any = 0
  try {
    pendingGamma = userAddress !== null ? await getPendingRewardsUsingMulticall(userAddress) : 0
    gammaPrice = await getTokenPrice(gGamma)
    pendingGammaUsd = parseFloat(pendingGamma) * parseFloat(gammaPrice)
  }
  catch (error) {
    console.log("error==>", error)
  }
  finally {
    const finalData = {
      pendingGamma: pendingGamma,
      gammaPrice: gammaPrice,
      pendingGammaUsd: pendingGammaUsd
    }
    return finalData
  }
}

export const getPendingData_V2 = async (userAddress: string) => {
  let pendingGamma: any = 0
  let gammaPrice: any = 0
  let pendingGammaUsd: any = 0
  try {
    pendingGamma = userAddress !== null ? await getPendingRewardsUsingMulticall_V2(userAddress) : 0
    gammaPrice = await getTokenPrice(gGamma)
    pendingGammaUsd = parseFloat(pendingGamma) * parseFloat(gammaPrice)
  }
  catch (error) {
    console.log("error==>", error)
  }
  finally {
    const finalData = {
      pendingGamma: pendingGamma,
      gammaPrice: gammaPrice,
      pendingGammaUsd: pendingGammaUsd
    }
    return finalData
  }
}


export const getTotalPlatformDataMulticall = async (userAddress: any) => {
  const timestamp = new Date().getTime();
    const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
    let result: any = {
      currentLevel: 0,
      discountRate: 0,
      availableCredit: 0,
      userGammaBalance: 0,
      userGammaBalanceInUsd: 0,
      totalMarketSize: 0,
      userTotalSupplyBal: 0,
      userTotalBorrowBal: 0,
      userNetApyWithoutGamma: 0,
      userNetApyWithGamma: 0,
      userStakeRatio: 0,
      gammaPrice: 0,
      aquaPrice: 0,
      pendingGamma: 0,
      dailyEarnings:0,
      gammaToUpgrade: 0
    };
    if(userAddress == undefined) {
      return result;
    }
    let totalUnderlyingPriceArray: any = [];
    let exchangeRateArray: any = [];
    let totalSupplyArray: any = [];
    let isDeprecatedArray: any = [];
    let marketsCollateralArray: any = [];
    let gammaSpeedArray: any = [];
    let borrowRatePerBlockArray: any = [];
    let totalBorrowArray: any = [];
    let supplyRatePerBlockArray: any = [];
    let supplyBalanceArray: any = [];
    let borrowBalanceArray: any = [];
    
   
    let targets: any = [];
    let callDatas: any = [];
    let results:any = [];
    let ouput_format : any = [];
    const market_array: any = Object.values(pfTokenList);
    let levelData = userAddress !== null ? await getDiscountLevel(userAddress) : { level: 0, discount: 0, };
    const gammatroller_inst: any = new wallet.web3.eth.Contract(gammatrollerAbi, gammatrollerAddress);
    let assets_data: any = [];
    if(!!userAddress){
        assets_data = await gammatroller_inst.methods.getAssetsIn(userAddress).call()
    }
    if (assets_data.length === 0) {
        assets_data = await gammatroller_inst.methods.getAssetsIn(userAddress).call();
    }
    
    
    //token price
    const price_oracle_inst: any = new wallet.web3.eth.Contract(priceOracleAbi, priceOracleAddress);
    const inst: any = new wallet.web3.eth.Contract(gBnbAbi, gGamma)
    market_array.forEach(async(token: any) => {
      try{
        const element = token.address;
        targets.push(priceOracleAddress);
        const data = (wallet.web3.eth.abi.encodeFunctionCall(price_oracle_inst.methods.getUnderlyingPrice(element)._method, [element]));
        callDatas.push(data);
        ouput_format.push(price_oracle_inst.methods.getUnderlyingPrice(element)._method.outputs)
      
      } catch(error){
        console.log(token.address, error);
        return 0;
      }
    })

    //exchange rate
    market_array.forEach(async(token: any) => {
      // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address)
      targets.push(token.address);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.exchangeRateStored()._method, []));
      callDatas.push(data);
      ouput_format.push(inst.methods.exchangeRateStored()._method.outputs)
    })

    // total supply
    market_array.forEach(async(token: any) => {
      // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address)
      targets.push(token.address);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.totalSupply()._method, []));
      callDatas.push(data);
      ouput_format.push(inst.methods.totalSupply()._method.outputs)
      
    })

    //is deprecated
    market_array.forEach(async(token: any) => {
      try{
        targets.push(gammatrollerAddress)
        const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.isDeprecated(token.address)._method, [token.address]));
        callDatas.push(data);
        ouput_format.push(gammatroller_inst.methods.isDeprecated(token.address)._method.outputs)
      } catch(error){
        console.log(token.address, error)
        return 0;
      }
    })

    //markets collateral
    market_array.forEach(async(token: any) => {
      targets.push(gammatrollerAddress)
      const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.markets(token.address)._method, [token.address]));
      callDatas.push(data);
      ouput_format.push(gammatroller_inst.methods.markets(token.address)._method.outputs)
    })

    //gamma speed
    market_array.forEach(async(token: any) => {
      targets.push(gammatrollerAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSpeeds(token.address)._method, [token.address])
      callDatas.push(data)
      ouput_format.push(gammatroller_inst.methods.gammaSpeeds(token.address)._method.outputs)
    })

    //borrow rate per block
    market_array.forEach(async (token: any) => {
      // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address)
      targets.push(token.address)
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.borrowRatePerBlock()._method, [])
      callDatas.push(data)
      ouput_format.push(inst.methods.borrowRatePerBlock()._method.outputs)
    })

    // total borrows
    market_array.forEach(async(token: any) => {
      // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address)
      targets.push(token.address)
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.totalBorrows()._method, [])
      callDatas.push(data)
      ouput_format.push(inst.methods.totalBorrows()._method.outputs)
      
    })

    //supply rate per block
    market_array.forEach(async( token: any) => {
      // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address);
      targets.push(token.address);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.supplyRatePerBlock()._method, []));
      callDatas.push(data);
      ouput_format.push(inst.methods.supplyRatePerBlock()._method.outputs)
    })
  
    
    // console.log("user wallet address", userAddress)
    //supply balance
    market_array.forEach(async(token:any) => {
      try{
        // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address)
        targets.push(token.address);
        const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.getAccountSnapshot(userAddress)._method, [userAddress]));
        callDatas.push(data);
        ouput_format.push(inst.methods.getAccountSnapshot(userAddress)._method.outputs)
      }catch(error){
        console.log(token.address, error);
        return 0;
      }
    })

    //borrow balance
    market_array.forEach(async(token: any) => {
      try{
        // const inst: any = new wallet.web3.eth.Contract(gBnbAbi, token.address)
        targets.push(token.address);
        const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.borrowBalanceStored(userAddress)._method, [userAddress]));
        callDatas.push(data);
        ouput_format.push(inst.methods.borrowBalanceStored(userAddress)._method.outputs)
      } catch(error){
        console.log(token.address, error)
        return 0;
      }
    })
    
    
    // console.log(" targets & calldata array", targets, callDatas, targets.length, callDatas.length)
    const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());
    // console.log(" aggregated Data ", aggregated_data)
    const do_split = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }  
  
    for(let i = 0 ; i < aggregated_data[1].length ; i++){
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = (await do_split(results, market_array.length));
    totalUnderlyingPriceArray = split_arr[0];
    exchangeRateArray = split_arr[1];
    totalSupplyArray = split_arr[2];
    isDeprecatedArray = split_arr[3];
    marketsCollateralArray = split_arr[4];
    gammaSpeedArray = split_arr[5];
    borrowRatePerBlockArray = split_arr[6];
    totalBorrowArray = split_arr[7];
    supplyRatePerBlockArray = split_arr[8];
    supplyBalanceArray = split_arr[9];
    borrowBalanceArray = split_arr[10]; 
    const multicall_fetch_timestamp = new Date().getTime();
    // console.log("actual multicall time taken", multicall_fetch_timestamp - timestamp )
    const getGammaSupplyApyArray: any = await getGammaSupplyApyFromAPI();
    const supplyApyArray: any = await getSupplyApyFromAPI();
    let aquaBurnAPY = await getBurnApy();
    result.gammaPrice = parseFloat(convertToEther(totalUnderlyingPriceArray[2][0], 18)); //await getGammaPrice();
    // console.log(" gamma price",result.gammaPrice )
    result.aquaPrice = parseFloat(convertToEther(totalUnderlyingPriceArray[3][0], 18)); //await getAquaPrice();
    let priceArray: any = [];
    priceArray[gGamma.toLowerCase()] = result.gammaPrice;
    priceArray[gAqua.toLowerCase()] = result.aquaPrice;
    const infinityVaultBalance: any = await getInfinityVaultBalanceNew(userAddress, aquaBurnAPY, getGammaSupplyApyArray, priceArray);
    
    const api_fetch_timestamp = new Date().getTime();
    // console.log("api time taken", api_fetch_timestamp - multicall_fetch_timestamp )
    const borrowApyArray: any = [];
    const borrowGammaApyArray: any = [];
    const borrowBalanceArr: any = [];
    let supplyBalanceArr: any = [];
    let suppliedAmountWithCollateral = 0;
    let suppliedAmountWithoutCollateral = 0;
    let totalSuppliedBalance = 0;
    let userGammaSupplied = 0;
    let userSuppliedAqua = 0;
    let diffToUpperLevel: any = 0;
    let upperLevel: any = 0;
    let totalBorrowedAmount = 0;
    let suppliedWithCollateral = 0;
    let getMarketLiquidityBalData = 0;
    let tokenPriceArray: any = []
    let blocksPerDay = 28800;
    let daysPerYear = 365;
    for (let i = 0; i < market_array.length; i++) {
      const tokenAddress: any = market_array[i].address;
    
      const gTokenDecimals: any = market_array[i] ? +market_array[i].decimals : 0;
      const decimal = 36 - gTokenDecimals;
      const tokenPrice = parseFloat(convertToEther(totalUnderlyingPriceArray[i][0], decimal));
      tokenPriceArray[tokenAddress.toLowerCase()] = tokenPrice;
      let tokenSupplyBalance = 0;
      let is_deprecated = isDeprecatedArray[i][0] == false ? false : true; // {}
      // console.log("is deprecated", isDeprecatedArray[i][0]);
      if (+supplyBalanceArray[i][1] !== 0 && +supplyBalanceArray[i][3] !== 0 && gTokenDecimals !== undefined) {
        tokenSupplyBalance = convertToEther(supplyBalanceArray[i][1], gTokenDecimals) * convertToEther(supplyBalanceArray[i][3], 18);
      } 
      
      let exchangerate_decimals = 28;
      let exchangeRate = parseFloat(convertToEther(exchangeRateArray[i][0], exchangerate_decimals));;
      const tokenBalance = tokenPrice * tokenSupplyBalance;//exchangeRate * totalSupplyArray[i];
      getMarketLiquidityBalData += tokenBalance;

      let marketCollateralDetails: any = {};
      if(tokenAddress.toLowerCase() === gGamma.toLowerCase()){
        // result.gammaPrice = tokenPrice;
        userGammaSupplied = tokenSupplyBalance;
      }

      if(tokenAddress.toLowerCase() === gAqua.toLowerCase()){
        // result.aquaPrice = tokenPrice;
        userSuppliedAqua = tokenSupplyBalance;
      }

      if (marketsCollateralArray[i] !== undefined) {
        let collateralFactor = marketsCollateralArray[i][1] ? convertToEther(marketsCollateralArray[i][1], 18) * 100 : 0;
        // console.log("index", addressArray[i].address, index, split_arr[0][i][1])
        let index = assets_data.some((e: any) => e.toLowerCase() === tokenAddress.toLowerCase())
        marketCollateralDetails = {
          isListed: marketsCollateralArray[i][0],
          collateralFactor: collateralFactor,//split_arr[0][i][1],
          isComped: marketsCollateralArray[i][1],
          index: index,
          isAvailForCollateral: collateralFactor && index ? true : false
        }
      } else {
          marketCollateralDetails = {
              isListed: false,
              collateralFactor: 0,
              isComped: false,
              index: false,
              isAvailForCollateral: false
        }
      }
      const suppliedBal = tokenSupplyBalance * tokenPrice;
      totalSuppliedBalance += suppliedBal;
      if(marketCollateralDetails.isAvailForCollateral){
        suppliedAmountWithCollateral += suppliedBal
      }
      suppliedAmountWithoutCollateral += suppliedBal;
      
      let borrowBalance = convertToEther(borrowBalanceArray[i][0], gTokenDecimals);
      totalBorrowedAmount += borrowBalance * tokenPrice;
      suppliedWithCollateral += marketCollateralDetails.collateralFactor * tokenBalance / 100;
      let borrowApy = convertToEther(borrowRatePerBlockArray[i][0], 18) * blocksPerDay * daysPerYear * 100;
      let totalBorrows = convertToEther(totalBorrowArray[i][0], gTokenDecimals);
      // console.log("borrow apy data before",  borrowApy)
      if(tokenAddress.toLowerCase() !== gAqua.toLowerCase() && tokenAddress !== gGamma.toLowerCase() && totalBorrows > 0){
        const discount = levelData.discount;
        const diff = borrowApy - supplyApyArray[tokenAddress];
        borrowApy -= (discount * diff) / 100;
        // console.log("supply apy data", supplyApyArray[tokenAddress], levelData, diff, borrowApy, gammaSpeedArray[i][0])
      }
      
      borrowApy = borrowApy;
      let gammaPerDay = convertToEther(gammaSpeedArray[i][0], 18) * blocksPerDay;
      let gamma_borrow_apy = totalBorrows !== 0 ? 100 * (Math.pow(1 + (result.gammaPrice * gammaPerDay) / (totalBorrows * tokenPrice), daysPerYear) - 1) : 0;
      borrowApyArray[tokenAddress.toLowerCase()] = borrowApy;
      borrowGammaApyArray[tokenAddress.toLowerCase()] = gamma_borrow_apy;
      supplyBalanceArr[tokenAddress.toLowerCase()] = tokenSupplyBalance;
      borrowBalanceArr[tokenAddress.toLowerCase()] = borrowBalance;
      // const discount = levelData.level === 0 ? 0 : levelData.level === 1 ? 5 : levelData.level === 2 ? 20 : 50;
      // let supplyRatePerBlock = supplyRatePerBlockArray[i][0] * blocksPerDay * daysPerYear * 100; 
      
    }

    let netApyVal: any = 0;
    let netAssetBalance = 0
    // console.log("borrow balance", borrowBalanceArray)
    // console.log("gamma borrow apy", getGammaBorrowApyArray)
    // const new_markets_without_gammaAqua: any = Object.values(bluePfTokenListWithoutAquaGamma);
    const forloop_fetch_timestamp = new Date().getTime();
    // console.log("for loop time taken", forloop_fetch_timestamp - api_fetch_timestamp )
    // let boostApyArray = userAddress !== null ? await getUserAndAverageBoostForMarketsAlt(userAddress, new_markets_without_gammaAqua, tokenPriceArray) : [];
    const boostMulticall_fetch_timestamp = new Date().getTime();
    // console.log("boost multicall time taken", boostMulticall_fetch_timestamp - forloop_fetch_timestamp )
    for (let i = 0; i < market_array.length; i++) {
      const asset = pfTokenList[i].address.toLowerCase();
      const price = tokenPriceArray[asset] ? tokenPriceArray[asset] : 0;
      const supplyBal = supplyBalanceArr[asset] ? supplyBalanceArr[asset] : 0;
      const borrowBal = borrowBalanceArr[asset] ? +borrowBalanceArr[asset] : 0;
      const supplyApy = supplyApyArray[asset] ? +supplyApyArray[asset] : 0;
      const borrowApy = borrowApyArray[asset.toLowerCase()] ? borrowApyArray[asset.toLowerCase()] : 0; //getBorrowApy(asset, userAddress)
      const gammaSupplyApy = getGammaSupplyApyArray[asset] && isFinite(getGammaSupplyApyArray[asset]) ? getGammaSupplyApyArray[asset] : 0;//getGammaSupplyApy(asset)

      const gammaBorrowApy = borrowGammaApyArray[asset] && isFinite(borrowGammaApyArray[asset]) ? borrowGammaApyArray[asset] : 0;//getGammaBorrowApy(asset)
      // let avgBoostApy = boostApyArray[asset.toLowerCase()] && boostApyArray[asset.toLowerCase()].averageBoostApy ? boostApyArray[asset.toLowerCase()].averageBoostApy : 0
      let boostApy = 0;//supplyBal * price > 1 && boostApyArray[asset.toLowerCase()] && boostApyArray[asset.toLowerCase()].userBoostApy && isFinite(boostApyArray[asset.toLowerCase()].userBoostApy) ? boostApyArray[asset.toLowerCase()].userBoostApy : avgBoostApy;
      const promArr: any = [supplyBal, borrowBal, supplyApy, borrowApy, gammaSupplyApy, gammaBorrowApy, boostApy];
      // console.log("promArr in multicall", promArr, pfTokenList[i].name)
      // console.log("net apy token supply d÷etails", asset, supplyBal * price, supplyApy + gammaSupplyApy + boostApy)
      // console.log("net apy token borrow/ details", asset, borrowBal * price, borrowApy + gammaBorrowApy)
      let netSupplyApy =
        (parseFloat(promArr[0]) * parseFloat(price) * parseFloat(promArr[2])) / 100 +
        (parseFloat(promArr[0]) * parseFloat(price) * parseFloat(promArr[4])) / 100 +
        // (parseFloat(promArr[1]) * parseFloat(price) * parseFloat(promArr[5])) / 100 +
        (parseFloat(promArr[0]) * parseFloat(price) * parseFloat(promArr[6])) / 100 

      let netBorrowApy = (parseFloat(promArr[1]) * parseFloat(price) * (parseFloat(promArr[5]) - parseFloat(promArr[3]))) / 100 //- (parseFloat(promArr[1]) * parseFloat(price) * parseFloat(promArr[3])) / 100;
      // console.log(" net borrow apy", netBorrowApy, promArr[5], promArr[3], promArr[5] - promArr[3])
      netAssetBalance += (supplyBal - borrowBal) * price;
      netApyVal += netSupplyApy + netBorrowApy;
      
    }
    let totalApy = (netApyVal * 100) / netAssetBalance
    // console.log("total net apy", netApyVal, netAssetBalance, totalApy)
    // return {totalApy, netAssetBalance};
    // console.log("suppliedAmountWithCollateral", suppliedAmountWithCollateral)

    result.suppliedAmountWithCollateral = suppliedAmountWithCollateral;
    result.suppliedAmountWithoutCollateral = suppliedAmountWithoutCollateral;
    let userTotalSuppliedWithoutCollateral = userAddress !== null ? suppliedAmountWithoutCollateral : 0;
    userTotalSuppliedWithoutCollateral +=  (parseFloat(infinityVaultBalance[0].balance) * result.gammaPrice) + (parseFloat(infinityVaultBalance[1].balance) * result.aquaPrice);
    userGammaSupplied += (parseFloat(infinityVaultBalance[0].balance) * result.gammaPrice);

    if (levelData.level === 3) {
      diffToUpperLevel = 0
      upperLevel = 3
    } else if (levelData.level === 2) {
      diffToUpperLevel = (10 * (userTotalSuppliedWithoutCollateral - userGammaSupplied) - userGammaSupplied * 100) / 100
      diffToUpperLevel /= result.gammaPrice
      upperLevel = 3
    } else if (levelData.level === 1) {
      diffToUpperLevel = (5 * (userTotalSuppliedWithoutCollateral - userGammaSupplied) - userGammaSupplied * 100) / 100
      diffToUpperLevel /= result.gammaPrice
      upperLevel = 2
    } else if (userTotalSuppliedWithoutCollateral !== 0) {
      if (userTotalSuppliedWithoutCollateral === userGammaSupplied) {
        levelData.level = 3
        diffToUpperLevel = 0
        upperLevel = 3
      } else {
        if(userGammaSupplied > userTotalSuppliedWithoutCollateral * 0.1) {
          diffToUpperLevel = 0
          levelData.level = 3
          upperLevel = 3
        } 
        diffToUpperLevel = (1 * (userTotalSuppliedWithoutCollateral - userGammaSupplied) - userGammaSupplied * 100) / 100
        diffToUpperLevel /= result.gammaPrice
        upperLevel = 1
      }
    } else {
      diffToUpperLevel = 0
      upperLevel = 1
    }
    // console.log("diffToUpperLevel", diffToUpperLevel, upperLevel)
    let suppliedAssetWithoutCollateral = suppliedAmountWithoutCollateral;
    let minGammaCurrentLevel = 0;
    let minGammaUpperLevel = 0;
    if(levelData.level == 0 && suppliedAssetWithoutCollateral > 0){
      diffToUpperLevel = (suppliedAssetWithoutCollateral * 0.01 - userGammaSupplied)/(result.gammaPrice);
      minGammaCurrentLevel = 0;
      minGammaUpperLevel = (suppliedAssetWithoutCollateral * 0.01 - minGammaCurrentLevel)/(result.gammaPrice);
    } else if(levelData.level == 1 && suppliedAssetWithoutCollateral > 0){
      diffToUpperLevel = (suppliedAssetWithoutCollateral * 0.05 - userGammaSupplied)/(result.gammaPrice);
      minGammaCurrentLevel = suppliedAssetWithoutCollateral * 0.01;
      minGammaUpperLevel = (suppliedAssetWithoutCollateral * 0.05 - minGammaCurrentLevel)/(result.gammaPrice);
    } else if(levelData.level == 2 && suppliedAssetWithoutCollateral > 0){
      diffToUpperLevel = (suppliedAssetWithoutCollateral * 0.1 - userGammaSupplied)/(result.gammaPrice);
      minGammaCurrentLevel = suppliedAssetWithoutCollateral * 0.05;
      minGammaUpperLevel = (suppliedAssetWithoutCollateral * 0.1 - minGammaCurrentLevel)/(result.gammaPrice);
    } else {
      diffToUpperLevel = 0;
      minGammaCurrentLevel = suppliedAssetWithoutCollateral * 0.1;
      minGammaUpperLevel = 0;
    }
    // console.log("user level details", suppliedAssetWithoutCollateral, minGammaCurrentLevel, minGammaUpperLevel, diffToUpperLevel)
    result.gammaToUpgrade = diffToUpperLevel > 0 ? diffToUpperLevel : diffToUpperLevel * -1;
    result.minGammaUpperLevel = minGammaUpperLevel;
    result.minGammaCurrentLevel = minGammaCurrentLevel;
    result.userGammaSupplied = userGammaSupplied;
    result.userTotalBorrowBal = totalBorrowedAmount;
    let borrowLimit: any = 0;
    let borrowAndUsedLimitData = {
      totalBorrowed: totalBorrowedAmount,
      borrowLimit: suppliedWithCollateral,
      usedBorrowLimit: suppliedWithCollateral > 0 ? totalBorrowedAmount / suppliedWithCollateral : 0,
      usedBorrowLimitInPercentage: suppliedWithCollateral > 0 ? (totalBorrowedAmount / suppliedWithCollateral) * 100 : 0,
    };
    borrowLimit = borrowAndUsedLimitData;
    result.availableCredit = borrowLimit.borrowLimit - borrowLimit.totalBorrowed;
    result.borrowLimitInUsd = borrowLimit.borrowLimit
    result.borrowLimitUsedCurr = borrowLimit.usedBorrowLimitInPercentage //in percentage
    result.remainingBorrowBalUsd = parseFloat(result.borrowLimitInUsd) - parseFloat(result.userTotalBorrowBal);
    result.diffToUpperLevel = diffToUpperLevel > 0 ? diffToUpperLevel : diffToUpperLevel * -1;
    result.currentLevel = levelData.level
    result.upperLevel = upperLevel
    result.discountRate = levelData.level == 3 ? 50 : levelData.level == 2 ? 20 : levelData.level == 1 ? 10 : 0;//levelData.discount

    result.totalMarketSize = getMarketLiquidityBalData / 100000000
    result.userTotalSupplyBal = userTotalSuppliedWithoutCollateral; 
    result.userTotalBorrowBal = userAddress !== null ? result.userTotalBorrowBal : 0;
    
    const netApyNew = totalApy;//await getNetApyNew(userAddress, totalUnderlyingPriceArray, userSupplyBalance, borrowBalanceArray, total.total, result.userTotalBorrowBal)
    
    let balanceWithoutInfinityVaults = suppliedAmountWithoutCollateral;//userTotalSuppliedWithoutCollateral - (parseFloat(infinityVaultBalance[gamma_infinity_vault_address]) * result.gammaPrice) - (parseFloat(infinityVaultBalance[aqua_infinity_vault_address]) * result.aquaPrice);
    result.balanceWithoutInfinityVaults = balanceWithoutInfinityVaults;
    
    // console.log("aquaBurnAPY", aquaBurnAPY);
    let offsetApyForAquaBurned = userSuppliedAqua  * result.aquaPrice > 0.01 ? (aquaBurnAPY * userSuppliedAqua * result.aquaPrice)/ (balanceWithoutInfinityVaults) : 0;
    let apyWithAquaBurned: any = totalApy + offsetApyForAquaBurned;
    result.apyWithoutInfinityVaults = apyWithAquaBurned;    
    // console.log("offsetApyForAquaBurned", offsetApyForAquaBurned)
    // console.log("net apy", netApyNew.totalApy)
    result.userNetApyWithGamma = userAddress !== null ? noExponents(apyWithAquaBurned) : 0
    result.userNetApyWithoutGamma = userAddress !== null ? noExponents(totalApy + offsetApyForAquaBurned) : 0;
    const currentApr = (Math.pow(totalApy / 100 + 1, 1 / 365) - 1) * 365;
    result.dailyEarnings = (currentApr * netAssetBalance)/ 365;
    totalPlatformData = result;
    let final_timestamp = new Date().getTime();
    // console.log("compare arrays", final_timestamp - boostMulticall_fetch_timestamp )
    // console.log("Green planet platformdata in v2 Multicall", totalPlatformData)
    return result
    
  
}

/*
const getTotalBalanceOfMarketForEachUser = async (userAddress: string, priceArray: any, deficit_causers_list: any, affected_users_list: any, totalDeficitAmount: number, totalRepayAmount: number) => {
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const assetsLen = Object.keys(pfTokenList).length
  if (assetsLen === 0) {
    return 0;
  }

  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format: any = [];
  let userBalanceDetails: any = [];
  let suppliedTokens: any = 0;
  let borrowedTokens: any = 0;
  let supplyBalanceArr: any = [];
  let suppliedUSD: any = 0;
  let borrowedUSD: any = 0;
  let totalSuppliedUSD: any = 0;
  let totalBorrowedUSD: any = 0;
  let userTokenDetails: any = [];
  try {
    for (let i = 0; i < assetsLen; i++) {
      const inst: any = await selectInstance(instType.gBNB, pfTokenList[i].address)
      targets.push(pfTokenList[i].address);
      const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.getAccountSnapshot(userAddress)._method, [userAddress]));
      callDatas.push(data);
      ouput_format.push(inst.methods.getAccountSnapshot(userAddress)._method.outputs)
    }
    const agregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }

    for (let i = 0; i < agregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], agregated_data[1][i]))
    }
    const split_arr = (await do_split(results, assetsLen));
    if (split_arr[0].length > 0) {

      for (let i = 0; i < assetsLen; i++) {
        if(pfTokenList[i].address !== "0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4" && pfTokenList[i].address !== "0x88FD42E447d39C3259b53623f2536bd855e47C48" && pfTokenList[i].address !== "0xa5ae8459e710F95ca0C93d73F63a66d9996F1ACE"){
          if (+split_arr[0][i][1] !== 0 && +split_arr[0][i][3] !== 0 && pfTokenList[i].decimals !== undefined) {

            suppliedTokens = convertToEther(split_arr[0][i][1], pfTokenList[i].decimals) * convertToEther(split_arr[0][i][3], 18);
          } else {
            suppliedTokens = 0;
          }
        } else {
          suppliedTokens = 0;
        }
        if (+split_arr[0][i][2] !== 0 && +split_arr[0][i][3] !== 0 && pfTokenList[i].decimals !== undefined) {

          borrowedTokens = convertToEther(split_arr[0][i][2], pfTokenList[i].decimals);
        } else {
          borrowedTokens = 0;
        }
        borrowedUSD = borrowedTokens * priceArray[pfTokenList[i].address];
        totalBorrowedUSD += borrowedUSD;
        suppliedUSD = suppliedTokens * priceArray[pfTokenList[i].address];
        totalSuppliedUSD += suppliedUSD;
      }
     
      let recoverable = 0;
      let deficit = 0;
      let maxLiquidatedable = 0;
      if(totalBorrowedUSD >= totalSuppliedUSD){
        //recoverable += totalSuppliedUSD/1.08;
        deficit_causers_list.push({userAddress, totalSuppliedUSD, totalBorrowedUSD, deficitAmount: totalBorrowedUSD - totalSuppliedUSD});
        totalDeficitAmount += totalBorrowedUSD - totalSuppliedUSD;
      } else {
        // maxLiquidatedable = totalSuppliedUSD/1.08;
        affected_users_list.push({userAddress, totalSuppliedUSD, totalBorrowedUSD, totalRepayAmount : totalSuppliedUSD - totalBorrowedUSD});
        // recoverable += Math.min(maxLiquidatedable, totalBorrowedUSD);
        totalRepayAmount += totalSuppliedUSD - totalBorrowedUSD;
      }
      userBalanceDetails = {
        userAddress,
        totalSuppliedUSD,
        totalBorrowedUSD,
        deficitAmount: totalBorrowedUSD > totalSuppliedUSD ? totalBorrowedUSD - totalSuppliedUSD : 0,
        repayAmount: totalSuppliedUSD > totalBorrowedUSD ? totalSuppliedUSD - totalBorrowedUSD : 0
      };
    }
    // console.log("supplyBalanceArr", supplyBalanceArr)
    return [userBalanceDetails, deficit_causers_list, affected_users_list, totalDeficitAmount, totalRepayAmount];
  } catch (error) {
    console.log(error)
  }
}

const getAllUsersFromData = async () => {
  const files = importAll(require.context('./usersData/', false, /\.*$/));
  let usersObject: any = {};
  console.log(files);

  files.forEach(async (file: any) => {
    file.forEach(async (user: any) => {
      usersObject[user.HolderAddress] = '';
    });
  })
  let users = Object.keys(usersObject);
  console.log(users);
  return users;
}

export const getSupplyBalanceAllUsers = async () => {

  let users: any = await getAllUsersFromData();
  let allUsersInMarket: any = users;
  console.log("user list ", users)
  // return ;
  //link market sample set user's supply balance
  let userArray: any = [
    "0x36a14961df0093d2e57d6ae1844a863ea861c6b9",
    "0xdd4Be626bECBD1F055519aD2C58359EDa1e37C89"
  ];
  const underlyingPriceArr = await getTotalUnderlyingPriceMulticall(pfTokenList)
  let deficit_causers_list: any = [];
  let affected_users_list: any = [];
  let totalDeficitAmount = 0;
  let totalRepayAmount = 0;
  let userDetailsArray: any = [];
  let timestamp_init = new Date().getTime();
  // users = ["0xAfEa5b52C980bDA1C5bD43dcb381a1B295213E77"];
  for(let i = 5000; i < users.length; i++)
  {
    let userData: any = await getTotalBalanceOfMarketForEachUser(users[i], underlyingPriceArr, deficit_causers_list, affected_users_list, totalDeficitAmount, totalRepayAmount);
    userDetailsArray.push(userData[0])
    deficit_causers_list = userData[1];
    affected_users_list = userData[2];
    totalDeficitAmount = userData[3];
    totalRepayAmount = userData[4];
    console.log("userData", userData)
  }
  let timestamp_final = new Date().getTime();
  console.log("time taken for array", timestamp_final - timestamp_init);
  // const getUserSupplyArr = await getSupplyBalanceOfMarket(userArray, "0x9B81d1df15fD9C07007d9a9b8fF75D40A5a80D94");
  console.log("deficit_causers_list", JSON.stringify(deficit_causers_list))
  console.log("affected_users_list", JSON.stringify(affected_users_list))
  console.log("totalDeficitAmount", totalDeficitAmount)
  console.log("totalRepayAmount", totalRepayAmount)
  console.log("all market user data stringified ", JSON.stringify(userDetailsArray))
}
*/
export const getTotalPlatformData = async (userAddress: string) => {
  // userAddress = "0x89790b79a234cecd06a85d0b0922f9e021137c7b"; //'0xf485073e84121fa19e620e7f8cba8ab11d4a6808';//"";//"0xfD148e04b6cFB33811540B627d5A592064Bb9b7b";//"0x26Fe38f35C47579324bDEBc93D3b87eE0356fEC6";
  let timestamp = new Date().getTime();
  let newPlatformData = await getTotalPlatformDataMulticall(userAddress);
  // console.log("inside v1", pfTokenList, newPlatformData)
  return newPlatformData;
  try {
    let totalUnderlyingPriceArray = await getTotalUnderlyingPriceMulticall(pfTokenList);
    let userSupplyBalance: any;
    let multicalAssetsArr: any;
    let borrowBalanceArray: any;
    const decimalArray = await getUnderlyingDecimalMulticallAlt(pfTokenList);
    userSupplyBalance = userAddress !== null ? await getAllSupplyBalanceMulticall(userAddress, decimalArray) : [];
    multicalAssetsArr = userAddress !== null ? await getAssetsInMulticall(userAddress, gammatrollerAddress) : [];
    // let priceArray = await getTotalUnderlyingPriceMulticall(multicalAssetsArr);
    borrowBalanceArray = userAddress !== null ? await getBorrowBalanceMulticall(multicalAssetsArr, userAddress, decimalArray) : [];
    // console.log("borrowBalanceArray", borrowBalanceArray)
    // console.log("userSupplyBalance", userSupplyBalance)
    //aqua gamma infinity vault data
    const infinityVaultBalance: any = await getInfinityVaultBalance(userAddress);
    // console.log("infinityVaultBalance", infinityVaultBalance);
    const getMarketLiquidityBalData = await getMarketLiquidityData(totalUnderlyingPriceArray)//multicall
    // let generic_timestamp = new Date().getTime();
    // console.log("time take for generic calls", generic_timestamp - timestamp)
    let diffToUpperLevel: any = 0
    let upperLevel: any = 0
    let levelData =
      userAddress !== null
        ? await getDiscountLevel(userAddress)
        : {
          level: 0,
          discount: 0,
        }
    // console.log("levelData", levelData);
    let result: any = {
      currentLevel: 0,
      discountRate: 0,
      availaibleCredit: 0,
      userGammaBalance: 0,
      userGammaBalanceInUsd: 0,
      totalMarketSize: 0,
      userTotalSupplyBal: 0,
      userTotalBorrowBal: 0,
      userNetApyWithoutGamma: 0,
      userNetApyWithGamma: 0,
      userStakeRatio: 0,
      gammaPrice: 0,
      aquaPrice: 0,
      pendingGamma: 0,
      dailyEarnings:0,
      gammaToUpgrade: 0,
      greenPendingUsd: 0,
      greenPendingGAMMA: 0
    }
    const total = userAddress !== null ? await getTotalSupplyBalance(userAddress, totalUnderlyingPriceArray, userSupplyBalance, decimalArray) : 0
    // console.log("userTotalSuppliedWithoutCollateral before infinity vault aqua & gamma addition",total); 
    result.suppliedAmountWithCollateral = total !==undefined && total.suppliedAmountWithCollateral !== undefined ? total.suppliedAmountWithCollateral: 0;
    result.suppliedAmountWithoutCollateral = total !==undefined && total.suppliedAmountWithoutCollateral !== undefined ? total.suppliedAmountWithoutCollateral: 0;
    result.gammaPrice = await getGammaPrice();
    result.aquaPrice = await getAquaPrice();
    let userGammaSupplied = total[Object.keys(total).filter((e) => e == gGamma)[0]]
    userGammaSupplied = userGammaSupplied === undefined ? 0 : userGammaSupplied;
    let userTotalSuppliedWithoutCollateral = userAddress !== null ? parseFloat(total.suppliedAmountWithoutCollateral) : 0
    //calculate supplywithout collatral along with infinity Gamma
    userTotalSuppliedWithoutCollateral +=  (parseFloat(infinityVaultBalance[gamma_infinity_vault_address]) * result.gammaPrice) + (parseFloat(infinityVaultBalance[aqua_infinity_vault_address]) * result.aquaPrice);
    // console.log("userTotalSuppliedWithoutCollateral after infinity vault aqua & gamma addition",userTotalSuppliedWithoutCollateral); 
    let userGammaBeforeInfinity = userGammaSupplied;
    userGammaSupplied = userGammaSupplied + (parseFloat(infinityVaultBalance[gamma_infinity_vault_address]) * result.gammaPrice);
    // let infinity_timestamp = new Date().getTime();
    // console.log("time take for infinity calls", infinity_timestamp - generic_timestamp)
    /**
     * Number of Gamma in usd user has to supply to reach upper discount level:-
     * x be the number of gamma in usd value
     * stake ratio = x / (total supplied assets of user in usd - aqua supplied in usd - x supplied in usd) 
     * x= stake ratio*(total supplied assets of user in usd - aqua supplied in usd) / (1 + stake ratio) 
     * no. of gamma user has to supply more = x-gamma already supplied in usd
     */
    if (levelData.level === 3) {
      diffToUpperLevel = 0
      upperLevel = 3
    } else if (levelData.level === 2) {
      diffToUpperLevel = (10 * (userTotalSuppliedWithoutCollateral - userGammaSupplied) - userGammaSupplied * 100) / 100
      diffToUpperLevel /= result.gammaPrice
      upperLevel = 3
    } else if (levelData.level === 1) {
      diffToUpperLevel = (5 * (userTotalSuppliedWithoutCollateral - userGammaSupplied) - userGammaSupplied * 100) / 100
      diffToUpperLevel /= result.gammaPrice
      upperLevel = 2
    } else if (userTotalSuppliedWithoutCollateral !== 0) {
      if (userTotalSuppliedWithoutCollateral === userGammaSupplied) {
        levelData.level = 3
        diffToUpperLevel = 0
        upperLevel = 3
      } else {
        diffToUpperLevel = (1 * (userTotalSuppliedWithoutCollateral - userGammaSupplied) - userGammaSupplied * 100) / 100
        diffToUpperLevel /= result.gammaPrice
        upperLevel = 1
      }
    } else {
      diffToUpperLevel = 0
      upperLevel = 1
    }
    let suppliedAssetWithoutCollateral = total !== undefined && total.suppliedAmountWithoutCollateral !== undefined ? parseFloat(total.suppliedAmountWithoutCollateral): 0;
    let minGammaCurrentLevel = 0;
    let minGammaUpperLevel = 0;
    if(levelData.level == 0 &&suppliedAssetWithoutCollateral > 0){
      diffToUpperLevel = (suppliedAssetWithoutCollateral * 0.01 - userGammaSupplied)/(result.gammaPrice);
      minGammaCurrentLevel = 0;
      minGammaUpperLevel = (suppliedAssetWithoutCollateral * 0.01 - minGammaCurrentLevel)/(result.gammaPrice);
    } else if(levelData.level == 1 && suppliedAssetWithoutCollateral > 0){
      diffToUpperLevel = (suppliedAssetWithoutCollateral * 0.05 - userGammaSupplied)/(result.gammaPrice);
      minGammaCurrentLevel = suppliedAssetWithoutCollateral * 0.01;
      minGammaUpperLevel = (suppliedAssetWithoutCollateral * 0.05 - minGammaCurrentLevel)/(result.gammaPrice);
    } else if(levelData.level == 2 && suppliedAssetWithoutCollateral > 0){
      diffToUpperLevel = (suppliedAssetWithoutCollateral * 0.1 - userGammaSupplied)/(result.gammaPrice);
      minGammaCurrentLevel = suppliedAssetWithoutCollateral * 0.05;
      minGammaUpperLevel = (suppliedAssetWithoutCollateral * 0.1 - minGammaCurrentLevel)/(result.gammaPrice);
    } else {
      diffToUpperLevel = 0;
      minGammaCurrentLevel = suppliedAssetWithoutCollateral * 0.1;
      minGammaUpperLevel = 0;
    }
    result.gammaToUpgrade = diffToUpperLevel > 0 ? diffToUpperLevel : diffToUpperLevel * -1;
    result.minGammaUpperLevel = minGammaUpperLevel;
    result.minGammaCurrentLevel = minGammaCurrentLevel;
    result.userGammaSupplied = userGammaSupplied;
    // console.log("diffToUpper Level", diffToUpperLevel, upperLevel, levelData.level, levelData)
    // console.log("Gamma supplied for Level", userGammaSupplied,userTotalSuppliedWithoutCollateral, minGammaUpperLevel, minGammaCurrentLevel)
    result.userTotalBorrowBal = userAddress !== null ? await getTotalBorrowBalance(userAddress) : 0//, totalUnderlyingPriceArray, borrowBalanceArray, multicalAssetsArr
    // console.log("total borrow balance", result.userTotalBorrowBal)
    let borrowLimit: any = 0
    borrowLimit = userAddress !== null ? await getBorrowAndUsedLimit(userAddress, result.userTotalBorrowBal,totalUnderlyingPriceArray, borrowBalanceArray, decimalArray) : 0
    //(userAddress: string, borrowBalance?: number, totalUnderlyingPriceArray?: any, borrowBalanceArray?: any, decimalArray?: any)
    result.availableCredit = borrowLimit.borrowLimit - borrowLimit.totalBorrowed
    result.borrowLimitInUsd = borrowLimit.borrowLimit
    result.borrowLimitUsedCurr = borrowLimit.usedBorrowLimitInPercentage //in percentage

    result.remainingBorrowBalUsd = parseFloat(result.borrowLimitInUsd) - parseFloat(result.userTotalBorrowBal);
    result.diffToUpperLevel = diffToUpperLevel > 0 ? parseFloat(diffToUpperLevel).toFixed(5) : +(diffToUpperLevel) * -1;
    result.currentLevel = levelData.level
    result.upperLevel = upperLevel
    result.discountRate = levelData.discount
    // let userlevel_timestamp = new Date().getTime();
    // console.log("time take for userlevel calls", userlevel_timestamp - infinity_timestamp)
    // userTotalSuppliedWithoutCollateral = userTotalSuppliedWithoutCollateral + (parseFloat(infinityVaultBalance[aqua_infinity_vault_address]) * result.aquaPrice);
    // console.log("userTotalSuppliedWithoutCollateral after adding aqua infinity balance",userTotalSuppliedWithoutCollateral, userGammaSupplied);
    result.userStakeRatio =
      (userTotalSuppliedWithoutCollateral !== 0 ? userGammaSupplied / (userTotalSuppliedWithoutCollateral - userGammaSupplied) : 0) * 100 >= 100
        ? 100
        : (userTotalSuppliedWithoutCollateral !== 0 ? userGammaSupplied / (userTotalSuppliedWithoutCollateral - userGammaSupplied) : 0) * 100

    
    let lpInAquaGammaVault: any = 0
    let gammaInAquaGammaVault: any = 0

    let lpInBnbGammaVault: any = 0
    let gammaInBnbGammaVault: any = 0
    let tokenArray = [{ address: AQUA_GAMMALPAddress, token: userAddress }, { address: GAMMA_BNBLPAddress, token: userAddress }, { address: gammaAddress, token: AQUA_GAMMALPAddress }, { address: gammaAddress, token: GAMMA_BNBLPAddress }];
    let poolIdArray = [0, 17];
    let stakedTokenArray = userAddress !== null ? await stakedWantTokensMulticall(gammaFarmAdddress, poolIdArray, userAddress) : [];
    let totalSupplyArr = userAddress !== null ? await totalSupplyMulticall(tokenArray, 2, 18) : [];
    let balanceOfArr = userAddress !== null ? await balanceOfMulticall(tokenArray, gammaAddress) : [];

    if (userAddress !== null && stakedTokenArray.length > 0) {
      let lpStaked = parseFloat(stakedTokenArray[poolIdArray[0]]) + parseFloat(balanceOfArr[AQUA_GAMMALPAddress + "_" + userAddress]);
      lpInAquaGammaVault = parseFloat(convertToEther(lpStaked, 18));
      lpStaked = parseFloat(stakedTokenArray[17]) + parseFloat(balanceOfArr[GAMMA_BNBLPAddress + "_" + userAddress]);
      // console.log("lpStaked", lpStaked)
      lpInBnbGammaVault = parseFloat(convertToEther(lpStaked, 18));
    }

    if (lpInAquaGammaVault !== 0 && userAddress) {
      let lpTotalSupply = convertToEther(totalSupplyArr[AQUA_GAMMALPAddress], 18);
      let contractGammaBalance = convertToEther(balanceOfArr[gammaAddress + "_" + AQUA_GAMMALPAddress], 18);
      gammaInAquaGammaVault = noExponents((lpInAquaGammaVault / lpTotalSupply) * contractGammaBalance)
    }

    if (lpInBnbGammaVault !== 0 && userAddress) {
      let lpTotalSupply = convertToEther(totalSupplyArr[GAMMA_BNBLPAddress], 18);
      let contractGammaBalance = convertToEther(balanceOfArr[gammaAddress + "_" + GAMMA_BNBLPAddress], 18);
      gammaInBnbGammaVault = noExponents((lpInBnbGammaVault / lpTotalSupply) * contractGammaBalance)
    }

    result.userGammaBalance =
      userAddress !== null
        ? parseFloat(convertToEther(await balanceOfGtoken(userAddress, gammaAddress), 18)) + userGammaSupplied / result.gammaPrice
        + parseFloat(gammaInAquaGammaVault)
        + parseFloat(gammaInBnbGammaVault)
        : 0
    result.userGammaBalanceInUsd = result.userGammaBalance * result.gammaPrice
    let timestampAfterAquaGamma = new Date().getTime();
    // console.log("time taken for AquaGamma cal", timestampAfterAquaGamma - timestampBeforeAquaGamma)
    result.totalMarketSize = getMarketLiquidityBalData.reduce((a: any, b: any) => a + b, 0)
    result.totalMarketSize = result.totalMarketSize / 100000000
    result.userTotalSupplyBal = userTotalSuppliedWithoutCollateral; //total.total //+ (parseFloat(infinityVaultBalance[gamma_infinity_vault_address]) * result.gammaPrice);
    result.userTotalBorrowBal = userAddress !== null ? result.userTotalBorrowBal : 0;//await getTotalBorrowBalance(userAddress) : 0
    const netApy = await getNetApy(userAddress, totalUnderlyingPriceArray, userSupplyBalance, borrowBalanceArray, total.total, result.userTotalBorrowBal);
    // console.log("netApy before aqua burn apy and infinity vault apy", netApy);
    let balanceWithoutInfinityVaults = userTotalSuppliedWithoutCollateral - (parseFloat(infinityVaultBalance[gamma_infinity_vault_address]) * result.gammaPrice) - (parseFloat(infinityVaultBalance[aqua_infinity_vault_address]) * result.aquaPrice);
    // console.log("balanceWithoutInfinityVaults", balanceWithoutInfinityVaults);
    result.balanceWithoutInfinityVaults = balanceWithoutInfinityVaults;
    let aquaBurnAPY = await getBurnApy();
    // console.log("aquaBurnAPY", aquaBurnAPY);
    // console.log("userSupplyBalance", userSupplyBalance);
    let offsetApyForAquaBurned = userSupplyBalance['0xb7eD4A5AF620B52022fb26035C565277035d4FD7'] > 0 ? (aquaBurnAPY * userSupplyBalance['0xb7eD4A5AF620B52022fb26035C565277035d4FD7'] * result.aquaPrice)/ (balanceWithoutInfinityVaults) : 0;
    // console.log("offsetApyForAquaBurned", offsetApyForAquaBurned);
    // const gammaInfApy = await getInfinityVaultApy(gamma_infinity_vault_address, gamma_infinity_vault_abi, gammaPriceData)
    // const aquaInfApy = await getInfinityVaultApy(aqua_infinity_vault_address, aqua_infinity_vault_abi, aquaPriceData)
    const aquaPriceData = await getPriceDataOfGivenInfinityVaultInEth(aqua_infinity_vault_address, aqua_infinity_vault_abi)
    const gammaPriceData = await getPriceDataOfGivenInfinityVaultInEth(gamma_infinity_vault_address, gamma_infinity_vault_abi)
    let gammaInfinityApy = await getInfinityVaultApy(gamma_infinity_vault_address, gamma_infinity_vault_abi, gammaPriceData);
    // console.log("gammaInfinityApy", gammaInfinityApy);
    let gammaApy: any = await fetchTokenApy("0x0c6dd143F4b86567d6c21E8ccfD0300f00896442");
    let aquaApy: any = await fetchTokenApy("0xb7eD4A5AF620B52022fb26035C565277035d4FD7");
    let aquaInfinityApy = await getInfinityVaultApy(aqua_infinity_vault_address, aqua_infinity_vault_abi, aquaPriceData);
    // console.log("aquaInfinityApy", aquaInfinityApy);
    let netGammaApy = +gammaApy + +gammaInfinityApy;
    // console.log("netGammaApy", netGammaApy); 
    let netAquaApy = +aquaBurnAPY + +aquaApy + +aquaInfinityApy;
    // console.log("netAquaApy", netAquaApy);
    let apyWithAquaBurned: any = netApy.apyWithGamma + offsetApyForAquaBurned;
    // console.log("apyWithAquaBurned", apyWithAquaBurned);
    result.apyWithoutInfinityVaults = apyWithAquaBurned;
    let apyWithInfinityVaults: any = 0;
    if(balanceWithoutInfinityVaults !== 0 || parseFloat(infinityVaultBalance[aqua_infinity_vault_address]) !==0 || parseFloat(infinityVaultBalance[gamma_infinity_vault_address]) !== 0){
      apyWithInfinityVaults = ((apyWithAquaBurned * balanceWithoutInfinityVaults) + (netAquaApy * parseFloat(infinityVaultBalance[aqua_infinity_vault_address]) * result.aquaPrice) + (netGammaApy * parseFloat(infinityVaultBalance[gamma_infinity_vault_address]) * result.gammaPrice))/ (balanceWithoutInfinityVaults + (parseFloat(infinityVaultBalance[aqua_infinity_vault_address]) * result.aquaPrice) + (parseFloat(infinityVaultBalance[gamma_infinity_vault_address]) * result.gammaPrice));
    }

    result.userNetApyWithGamma = userAddress !== null ? noExponents(apyWithAquaBurned) : 0
    result.userNetApyWithoutGamma = userAddress !== null ? noExponents(netApy.apyWithoutGamma + offsetApyForAquaBurned) : 0;
    result.dailyEarnings = ((+result.userTotalSupplyBal - +result.userTotalBorrowBal) * +result.userNetApyWithGamma)/(36500) ;
    let pendingGammaRewardsObject = await getPendingData(userAddress);
    result.greenPendingUsd = pendingGammaRewardsObject.pendingGammaUsd;
    result.greenPendingGAMMA = pendingGammaRewardsObject.pendingGamma;
    result.pendingGammaV2 = await getPendingData_V2(userAddress);
    totalPlatformData = result;
    // let final_timestamp = new Date().getTime();
    console.log("Green planet platformdata in v1", totalPlatformData)
    return result
  } catch (err) {
    console.log("ERROR in lending platform data v1",err)
  }
}

export const getPendingRewardsUsingMulticall_V2 = async(userAddress:any) => {
  
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);

  const gammatroller_inst  = await selectInstance(instType.gammatroller,gammatrollerAddress);

  const asset_inst = await selectInstance(instType.gToken,gGamma);

  //Supply side arrays
  let gammaSupplyStateArr: any = []
  let gammaSpeedsArr:any = []
  let getBlockNumberArr: any = []
  let totalSupplyArr:any = []
  let gammaSupplierIndexArr:any = []
  let gammaInitialIndexArr:any = []
  let balanceOfGtokenArr:any = []

  //Borrow side arrays
  let gammaBorrowStateArr: any = []
  let borrowIndexArr:any = []
  let totalBorrowsArr:any = []
  let gammaBorrowerIndexArr:any = []
  let borrowBalanceStoredArr:any = []
  
  let targets: any = [];
  let callDatas: any = [];
  let results:any = [];
  let ouput_format : any = [];

  const assetsIn: any = await getAllMarkets(gammatrollerAddress)

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSupplyState(asset)._method,[asset]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaSupplyState(asset)._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSpeeds(asset)._method,[asset]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaSpeeds(asset)._method.outputs)
  })

  // assetsIn.forEach(async(asset: any) => {
  //   targets.push(gammatrollerAddress);
  //   const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.getBlockNumber()._method,[]));
  //   callDatas.push(data);
  //   ouput_format.push(gammatroller_inst.methods.getBlockNumber()._method.outputs)
  // })

  //  const currentBlock = await wallet.web3.eth.getBlockNumber()
  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.totalSupply()._method,[]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.totalSupply()._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.totalSupply()._method,[]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.totalSupply()._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSupplierIndex(asset,userAddress)._method,[asset,userAddress]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaSupplierIndex(asset,userAddress)._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaInitialIndex()._method,[]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaInitialIndex()._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.balanceOf(userAddress)._method,[userAddress]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.balanceOf(userAddress)._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaBorrowState(asset)._method,[asset]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaBorrowState(asset)._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.borrowIndex()._method,[]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.borrowIndex()._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.totalBorrows()._method,[]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.totalBorrows()._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaBorrowerIndex(asset,userAddress)._method,[asset,userAddress]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaBorrowerIndex(asset,userAddress)._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.borrowBalanceStored(userAddress)._method,[userAddress]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.borrowBalanceStored(userAddress)._method.outputs)
  })

  const agregated_data = (await mutilcall_inst.methods.aggregate(targets,callDatas).call());

  const do_split = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }  

  for(let i = 0 ; i < agregated_data[1].length ; i++){
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i],agregated_data[1][i]))
  }

  const split_arr = (await do_split(results,assetsIn.length));

  gammaSupplyStateArr = split_arr[0];  // index,block
  gammaSpeedsArr = split_arr[1];
  const blockNumber = await wallet.web3.eth.getBlockNumber()//split_arr[2];
  totalSupplyArr = split_arr[3]
  gammaSupplierIndexArr = split_arr[4]
  gammaInitialIndexArr = split_arr[5]
  balanceOfGtokenArr = split_arr[6]   
  gammaBorrowStateArr = split_arr[7] //index,block
  borrowIndexArr = split_arr[8]
  totalBorrowsArr = split_arr[9]
  gammaBorrowerIndexArr = split_arr[10]
  borrowBalanceStoredArr = split_arr[11]


  let _userGammaAccrued = parseFloat(await gammaAccrued(userAddress, gammatrollerAddress))
  try {
    const getCompAccrueBySupply = async (i: any, _userGammaAccrued: any): Promise<number> => {

      let currentSupplyState = gammaSupplyStateArr[i]


      const supplySpeed = parseFloat(gammaSpeedsArr[i][0])
      const currentBlockNumberDiff = parseFloat(blockNumber) - parseFloat(currentSupplyState.block)
      let newGammaSupplyIndex = 0

      if (currentBlockNumberDiff > 0 && supplySpeed > 0) {
        const supplyAmount = parseFloat(totalSupplyArr[i][0])
        let _gammaAccrued = currentBlockNumberDiff * supplySpeed //borrowspeed = gamma per block
        newGammaSupplyIndex = parseFloat(currentSupplyState.index) + (supplyAmount > 0 ? (_gammaAccrued / supplyAmount) * 1e36 : 0)
      }

      let currentSupplierIndex = parseFloat(gammaSupplierIndexArr[i][0])

      if (currentSupplierIndex === 0 && newGammaSupplyIndex > 0) {
        currentSupplierIndex = parseFloat(gammaInitialIndexArr[i][0])
      }

      let deltaIndex = (parseFloat(convertToEther(newGammaSupplyIndex, 36)) - parseFloat(convertToEther(currentSupplierIndex, 36))) * 1e36
      deltaIndex = deltaIndex < 0 ? 0 : deltaIndex
      const userSupplyBalance = parseFloat(balanceOfGtokenArr[i][0])
      const supplierDelta = userSupplyBalance * deltaIndex
      return parseFloat(_userGammaAccrued) + parseFloat(convertToEther(supplierDelta, 36))
    }

    const getCompAccrueByBorrow = async (i: any, _userGammaAccrued: any): Promise<number> => {
  
      let currentBorrowState = gammaBorrowStateArr[i]
      const borrowSpeed = parseFloat(gammaSpeedsArr[i][0])
      const currentBlockNumberDiff = parseFloat(blockNumber) - parseFloat(currentBorrowState.block)

      if (currentBlockNumberDiff > 0 && borrowSpeed > 0) {
        const marketBorrowIndex = parseFloat(borrowIndexArr[i][0])
        const borrowAmount = parseFloat(totalBorrowsArr[i][0]) / marketBorrowIndex

        let _gammaAccrued: any = convertToEther(currentBlockNumberDiff * borrowSpeed, 18) //borrowspeed = gamma per block
        let newGammaBorrowIndex =
          parseFloat(currentBorrowState.index) + (borrowAmount > 0 ? (parseFloat(_gammaAccrued) / borrowAmount) * 1e36 : 0)

        const currentborrowerIndex = parseFloat(gammaBorrowerIndexArr[i][0])

        if (currentborrowerIndex > 0) {
          let deltaIndex =
            (parseFloat(convertToEther(newGammaBorrowIndex, 36)) - parseFloat(convertToEther(currentborrowerIndex, 36))) * 1e36
          deltaIndex = deltaIndex < 0 ? 0 : deltaIndex
          const userBorrowBalance = parseFloat(borrowBalanceStoredArr[i][0])
          const borrowerAmount = userBorrowBalance / marketBorrowIndex
          const borrowerDelta = borrowerAmount * deltaIndex

          return parseFloat(_userGammaAccrued) + parseFloat(convertToEther(borrowerDelta, 18))
        }
        return _userGammaAccrued
      }
      return _userGammaAccrued
    }

    for (let i = 0; i < assetsIn.length; i++) {
      //update gamma borrow index

      _userGammaAccrued = await getCompAccrueBySupply(i, _userGammaAccrued)
      _userGammaAccrued = await getCompAccrueByBorrow(i, _userGammaAccrued)
    }

    return noExponents(convertToEther(_userGammaAccrued.toFixed(0), 18))
  } catch (e) {
    console.log(e)
  }

}

// const getInfinityVaultApy = async (tokenAddress: string, tokenPrice: number, tokenBalance: number, totalBalance: number) => {
//   let tokenApy:any = await fetchTokenApy(tokenAddress);
//   console.log("token Apy", tokenAddress, tokenApy, tokenPrice, totalBalance)
//   let offsetApy: any = tokenBalance > 0 ? (tokenBalance * tokenPrice * tokenApy) / totalBalance : 0;

//   return offsetApy
// }

const fetchTokenApy = async (tokenAddress: string) => {
  let totalApyData : any = {}
  try {
    if(Object.keys(planetGlobalObject.poolGammaSupplyApy).length > 0) {
      totalApyData = planetGlobalObject.poolGammaSupplyApy;
    }
    else {
      totalApyData = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApy')).data;
    }
    
    
    
    for(var key in totalApyData){
      if(key === tokenAddress){
         return parseFloat(totalApyData[key]).toFixed(2);
      }
    }
  } catch (error) {
    try {
      if(Object.keys(planetGlobalObject.poolGammaSupplyApy).length > 0) {
        totalApyData = planetGlobalObject.poolGammaSupplyApy;
      }
      else {
        totalApyData = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApy')).data;
      }
      
      for(var key in totalApyData){
        if(key === tokenAddress){
            // console.log("tokenAddress",parseFloat(totalApyData[key]).toFixed(2))
           return parseFloat(totalApyData[key]).toFixed(2);
        }
      }
    } catch (error) {
      console.log('error in fetching fetchTokenApy', error);
    }
  }
}

const getInfinityVaultBalance = async (userAddress: string) => {
  let totalInfinityVaultBalance: any = [];
  const vaults = [gamma_infinity_vault_address, aqua_infinity_vault_address]
  for (let i = 0; i < vaults.length; i++) {
    let abi
    if (i === 0) abi = gamma_infinity_vault_abi
    else abi = aqua_infinity_vault_abi

    const vault = vaults[i];

    if (vault.length !== 0) {
      const priceData = await getPriceDataOfGivenInfinityVaultInEth(vault, abi)

      const decimal = parseFloat(await getUnderlyingDecimal(priceData.gToken)) + 10;

      let exchangeRate = (await exchangeRateStored(priceData.gToken)) / Math.pow(10, decimal);

      exchangeRate = 1 / exchangeRate;

      const infinity_want_bal = userAddress !== null ? await getUserBalInGtokenInfinityVaultInWei(userAddress, vault, abi) : 0;

      const infinity_unstaking_bal =
        userAddress !== null ? await getUserBalGivenForUnstakinginInfinityVaultInWei(userAddress, vault, abi) : 0

      const infinity_underlying_deposited_bal = (infinity_want_bal * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio)

      const infinity_underlying_unstaking_bal = (infinity_unstaking_bal * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio)

      totalInfinityVaultBalance[vault] = infinity_underlying_deposited_bal + infinity_underlying_unstaking_bal;
    }
  }
  // console.log("totalInfinityVaultBalance", totalInfinityVaultBalance);
  return totalInfinityVaultBalance;
}

export const getPriceDataOfGivenInfinityVaultInEth = async (infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const gToken = await infinity_vault_inst.methods.gToken().call()
  const underlyingPrice = convertToEther(await getUnderlyingPrice(gToken), 18)
  const underlyingToken = await underlying(gToken)
  const decimal = await decimals(underlyingToken)
  const exchangeRate = await exchangeRateStored(gToken)
  const decimalRatio = parseInt(decimal) + 18
  const noOfGtokenInOneToken = Math.pow(10, decimalRatio) / exchangeRate / Math.pow(10, 8)

  return {
    gToken,
    exchangeRate,
    decimalRatio,
    underlyingPrice,
    gTokenPrice: underlyingPrice / noOfGtokenInOneToken,
  }
}

export const getUserBalInGtokenInfinityVaultInWei = async (userAddress: string, infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const gtoken_balance = await infinity_vault_inst.methods.getUserStakingGtokenBal(userAddress).call();//await infinity_vault_inst.methods.getUserGtokenBal(userAddress).call();
  return gtoken_balance
}



export const getUserBalGivenForUnstakinginInfinityVaultInWei = async (userAddress: string, infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const gtoken_unstake_balance = await infinity_vault_inst.methods.getUserGtokenBalGivenForUnstaking(userAddress).call()
  return gtoken_unstake_balance
}

export const tokenApprove = async (underlyingAddress: string, gTokenAddress: string, userAddress: string) => {
  try {
    const TOKEN_INSTANCE = await selectInstance(instType.PANCAKELP, underlyingAddress)
    await TOKEN_INSTANCE.methods
      .approve(gTokenAddress, MAX_INT)
      .send({ from: userAddress })
      .once('transactionHash', function (res: any) { })
      .once('confirmation', function (confNumber: any, receipt: any) { })
      .on('error', function (error: any) { })
  } catch (error) { }
}

const marketData = (topic0: string) => {
  return `https://api.bscscan.com/api?module=logs&action=getLogs&fromBlock=0&toBlock=latest&address=0x0F6Bc276b3b61AAEa65761c92cb01d87A5FCd026&topic0=${topic0}&apikey=W3F3Y9G8FCC76MNRM1WF9UD4JGW61V2CB6`
}

const convertHexData = (data: string) => {
  const amount0In = parseInt(`0x${data.slice(2, 66)}`)
  const amount1In = parseInt(`0x${data.slice(66, 130)}`)
  const amount0Out = parseInt(`0x${data.slice(130, 194)}`)
  const amount1Out = parseInt(`0x${data.slice(194, 262)}`)
  return {
    amount0In: parseFloat(convertToEther(amount0In, 18)),
    amount1In: parseFloat(convertToEther(amount1In, 18)),
    amount0Out: parseFloat(convertToEther(amount0Out, 18)),
    amount1Out: parseFloat(convertToEther(amount1Out, 18))
  }
}

const coingecko_history_data = async (coinTicker: string, startTimeStamp: number, endTimeStamp: number) => {
  const url =
    `https://api.coingecko.com/api/v3/coins/${coinTicker}/market_chart/range?vs_currency=usd&from=${startTimeStamp}&to=${endTimeStamp}`

  const data = (await axios.get(url)).data
  console.log("coingecko_history_data", data)
  console.log("coingecko_history_data", JSON.stringify(data))
  return data.prices
}

export const getGammaBought = async () => {
  const userAddress = gamma_reservoir
  const gammaPrice = await getGammaPrice();

  const gammaInNewGamma = await getSupplyBalance(gGamma, userAddress);
  const gammaInOldGamma = await getSupplyBalance(oldgGamma, userAddress);
  const val = (gammaInNewGamma + gammaInOldGamma)
  const userGammaBalance: any = parseFloat(convertToEther(await balanceOfGtoken(userAddress, gammaAddress), 18)) + (val)
  const infinity_want_bal = await getUserBalInGtokenInfinityVaultInWei(userAddress, gamma_infinity_vault_address, gamma_infinity_vault_abi)
  const priceData = await getPriceDataOfGivenInfinityVaultInEth(gamma_infinity_vault_address, gamma_infinity_vault_abi)
  const infinity_underlying_deposited_bal = (infinity_want_bal * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio)
  
  // console.log("infinityGgammaBalance", infinity_want_bal, priceData, infinity_underlying_deposited_bal_usd)
  const userGammaBalanceUsd = gammaPrice * (userGammaBalance + infinity_underlying_deposited_bal)

  return userGammaBalanceUsd
}
export const getMetricsPageData = async (historicPriceInterval: number) => {

  const currentBlock = await wallet.web3.eth.getBlockNumber()
  let startBlock = currentBlock - 7 * 28800

  //let oracleBlock =  currentBlock - 1 * 28800

  // const aqua_symbol_hash = wallet.web3.utils.keccak256("AQUA");
  // const _gamma_symbol_hash = gamma_symbol_hash;

  // const oracle_logs = (await logs_using_topic0(oracleBlock,priceOracleAddress,price_updated_hash))

  // const aqua_price_update_log = oracle_logs.filter((e: any) => e.topics[1] === aqua_symbol_hash)
  // const gamma_price_update_log = oracle_logs.filter((e: any) => e.topics[1] === _gamma_symbol_hash)

  // aqua_price_update_log.forEach((element: any) => {
  //   const d = new Date(element.timeStamp * 1000)
  //   aqua_history_price.push({
  //     price: (convertToEther(Number(element.data), 6)),
  //     symbol: "AQUA",
  //     date: d,
  //   })
  // });

  // gamma_price_update_log.forEach((element: any) => {
  //   const d = new Date(element.timeStamp * 1000)
  //   gamma_history_price.push({
  //     price: (convertToEther(Number(element.data), 6)),
  //     symbol: "GAMMA",
  //     date: d,
  //   })
  // });

  //AQUA BURN HISTORY
  const transfer_history = await token_transfer_history(aquaAddress, deadAddress, startBlock)
  const gamma_transfer_history = await token_transfer_history(gammaAddress, gamma_reservoir, startBlock)

  const endTimeStamp = (+new Date() / 1000);
  const startTimeStamp = endTimeStamp - historicPriceInterval * 24 * 60 * 60

  const aqua_price_update_log: any = await coingecko_history_data("planet-finance", startTimeStamp, endTimeStamp);
  const gamma_price_update_log: any = await coingecko_history_data("green-planet", startTimeStamp, endTimeStamp);

  const aquaPrice = await getAquaPrice();
  const gammaPrice = await getGammaPrice();

  let aqua_history_price: any = []

  aqua_price_update_log.forEach((element: any) => {
    const d = new Date(element[0])
    aqua_history_price.push({
      price: element[1],
      symbol: "AQUA",
      date: d,
    })
  });

  let gamma_history_price: any = []

  gamma_price_update_log.forEach((element: any) => {
    const d = new Date(element[0])
    gamma_history_price.push({
      price: element[1],
      symbol: "GAMMA",
      date: d,
    })
  });

  let aqua_burn_history: any = [];

  transfer_history.forEach((element: any) => {
    const d = new Date(element.timeStamp * 1000)
    aqua_burn_history.push({
      amount: convertToEther(element.value, 18),
      amountInUsd: parseFloat(convertToEther(element.value, 18)) * aquaPrice,
      symbol: element.tokenSymbol,
      date: d,
      transactionHash: element.hash
    })
  });

  //GAMMA BUY HISTORY
  //startBlock = currentBlock - 1 * 28800

  let gamma_buy_history: any = []

  gamma_transfer_history.forEach((element: any) => {
    const d = new Date(element.timeStamp * 1000)
    gamma_buy_history.push({
      amount: convertToEther(element.value, 18),
      amountInUsd: parseFloat(convertToEther(element.value, 18)) * gammaPrice,
      symbol: element.tokenSymbol,
      date: d,
      transactionHash: element.hash
    })
  });

  const aqua_latest_price = (aqua_history_price[aqua_history_price.length - 1]).price
  const gamma_latest_price = (gamma_history_price[gamma_history_price.length - 1]).price

  const aqua_price_change = ((parseFloat((aqua_history_price[aqua_history_price.length - 1]).price) -
    parseFloat((aqua_history_price[0]).price)) / parseFloat((aqua_history_price[0]).price)) * 100

  const gamma_price_change = ((parseFloat((gamma_history_price[gamma_history_price.length - 1]).price) -
    parseFloat((gamma_history_price[0]).price)) / parseFloat((gamma_history_price[0]).price)) * 100

  const aqua_burned = parseFloat(convertToEther(await balanceOf(aquaAddress, deadAddress), 18))

  const aqua_burn_usd = aqua_burned * aquaPrice

  const aqua_market_cap = (parseFloat(convertToEther(await totalSupply(aquaAddress), 18)) - aqua_burned) * aquaPrice

  const block_diff = currentBlock - firstTimeGammaDrippedBlock
  const underlyingDecimalInPower: any = Math.pow(10, 18);
  // const gammaDrippedPerBlock = parseFloat(convertToWei(100000, 18)) / 28800
  const gammaDrippedPerBlock = parseFloat(convertToWei(100000, underlyingDecimalInPower)) / 28800
  const gamma_dripped = gammaDrippedPerBlock * block_diff;
  const gamma_market_cap = parseFloat(convertToEther(gamma_dripped, 18)) * gammaPrice + 4000000 * gammaPrice

  return {
    aqua_burn_history,
    gamma_buy_history,
    aqua_history_price,
    gamma_history_price,
    aqua_latest_price,
    gamma_latest_price,
    aqua_price_change,
    gamma_price_change,
    aqua_market_cap,
    aqua_burned,
    aqua_burn_usd,
    gamma_market_cap
  }
}

const logs_using_topic0 = async (startBlock: number, contract: string, topic0: string) => {

  const url =
    `https://api.bscscan.com/api?module=logs&action=getLogs&fromBlock=${startBlock}&toBlock=latest&address=${contract}&topic0=${topic0}&apikey=${apiKey}`

  const data = (await axios.get(url)).data['result'];

  return data
}

// const logs_using_topic0_1 = async (startBlock: number, contract: string, topic0: string,topic1:string) => {

//   const url =
//     `https://api.bscscan.com/api?module=logs&action=getLogs&fromBlock=${startBlock}&toBlock=latest&address=${contract}&topic0=${topic0}&topic0_1_opr=andtopic1=${topic1}apikey=${apiKey}`

//   const data = (await axios.get(url)).data['result'];

//   return data
// }

const token_transfer_history = async (tokenContract: string, accountAddress: string, startBlock: number) => {
  const url =
    `https://api.bscscan.com/api?module=account&action=tokentx&contractaddress=${tokenContract}&address=${accountAddress}&page=-1&offset=5&startblock=${startBlock}&endblock=latest&sort=desc&apikey=${apiKey}`

  const data = (await axios.get(url)).data['result']

  return data
}


export const getLiquidityDataOfUser = async (liquidator: string, borrower: string, market: string, seizeMarket: string, borrowBalanceArray?: any, closeFactorVal?: any, balanceOfGTokenArr?: any, tokenPriceArr?: any, liquidIncentiveData?: any, liquidatorBalance?: any, total_borrowed ?: number, total_supplied ?: number, userSupplyBalance?: any) => {
  console.log("inside getLiquidityDataOfUser", liquidator, borrower, market, seizeMarket, borrowBalanceArray, closeFactorVal, balanceOfGTokenArr, tokenPriceArr, liquidIncentiveData, liquidatorBalance, total_borrowed, total_supplied, userSupplyBalance)
  const user = borrower
  const health = await getHealth(user)
  const unhealthy = true;//parseFloat((await getAccountLiquidity(user, gammatrollerAddress))['2']) > 0 ? true : false
  const borrowBal = borrowBalanceArray[market];//await getBorrowBalance(market, user)
  console.log("borrowBal",borrowBal)
  const underlyingDecimal = +pfTokenListRevised[market].underlyingDecimals;//await getUnderlyingDecimal(market);
  const closeFactor = closeFactorVal;//parseFloat(convertToEther(await closeFactorMantissa(gammatrollerAddress), 18))
  console.log("borrow balance inside getLiquidityDataOfUser", borrowBal, borrowBalanceArray, market, borrowBalanceArray[market])
  if (borrowBal > 0) {
    const underlyingToken = pfTokenListRevised[market].token;//await underlying(market)
    const balanceGTokenData = balanceOfGTokenArr[market];//await balanceOfGtoken(liquidator, underlyingToken)
    
    const repayAssetSymbol = underlyingToken === bnbAddress ? 'BNB' : pfTokenListRevised[market].marketSymbol//await symbol(underlyingToken)
    const repayAssetBal: any =
      liquidator !== null
        ? underlyingToken !== bnbAddress
          ? convertToEther(balanceGTokenData, +pfTokenListRevised[market].underlyingDecimals)
          : liquidatorBalance
        : 0
    const repayAssetPrice = tokenPriceArr[market];//await getTokenPrice(market)
    
    let maxRepayAmount = closeFactor * parseFloat(borrowBal)



    const underlyingPrice = tokenPriceArr[seizeMarket]; //await getTokenPrice(seizeMarket)
    const seizeUnderlying = pfTokenListRevised[seizeMarket].token;//await underlying(seizeMarket)
    const seizeSymbol = seizeUnderlying === bnbAddress ? 'BNB' : pfTokenListRevised[seizeMarket].marketSymbol;//await symbol(seizeUnderlying)
    const underlyingDecimalInPower: any = Math.pow(10, underlyingDecimal);
    // let maxSeized = (await liquidateCalculateSeizeTokens(market, seizeMarket, convertToWei(maxRepayAmount, await getUnderlyingDecimal(market)), gammatrollerAddress))['1']
    let maxSeized = (await liquidateCalculateSeizeTokens(market, seizeMarket, convertToWei(maxRepayAmount, underlyingDecimalInPower), gammatrollerAddress))['1']
    console.log("maxSeized 1", maxSeized);
    maxSeized = maxSeized * (await exchangeRateStored(seizeMarket))
    console.log("maxSeized 2", maxSeized);
    maxSeized = convertToEther(maxSeized , 10 + +pfTokenListRevised[seizeMarket].underlyingDecimals + 8)
    console.log("maxSeized 3", maxSeized);
    const liquidIncentive = liquidIncentiveData;//parseFloat(convertToEther(await liquidationIncentiveMantissa(gammatrollerAddress), 18))
    //min of availavle vs max seized tokens
    maxSeized = Math.min(maxSeized, userSupplyBalance[seizeMarket]);
    console.log("maxSeized after min check with supply balance", maxSeized);
    let maxSeizedInDollar = maxSeized * underlyingPrice
    let maxRepayInDollar = maxRepayAmount * repayAssetPrice

    if (maxSeizedInDollar < maxRepayInDollar) {
      maxRepayInDollar = liquidIncentive < 1 ? maxSeizedInDollar / (1 + liquidIncentive) : maxSeizedInDollar / liquidIncentive
      maxRepayAmount = (1 / repayAssetPrice) * maxRepayInDollar
    } else {
      maxSeizedInDollar = liquidIncentive < 1 ? maxRepayInDollar * (1 + liquidIncentive) : maxRepayInDollar * liquidIncentive
      maxSeized = underlyingPrice > 0  ? (1 / underlyingPrice) * maxSeizedInDollar : 0
    }
    console.log("maxSeized 4", maxSeized);
    const result = {
      address: user,
      health: health,
      unhealthy: unhealthy,
      seizeAsset: seizeMarket,
      maxAmountSeized: maxSeized,
      maxSeizedInDollar: maxSeizedInDollar,
      maxRepayAmount: maxRepayAmount,
      maxRepayInDollar: maxRepayInDollar,
      seizeAssetSymbol: seizeSymbol,
      seizeAssetIcon: getTokenIcon(seizeSymbol),
      market: market,
      borrowBalance: borrowBal,
      repayAssetSymbol: repayAssetSymbol,
      repayAssetBal: repayAssetBal,
      repayAssetIcon: getTokenIcon(repayAssetSymbol),
      seizeAssetBal:
        liquidator !== null
          ? underlyingToken !== bnbAddress
            ? convertToEther(balanceGTokenData, +pfTokenListRevised[market].underlyingDecimals)
            : liquidatorBalance
          : 0,
      total_borrowed,
      total_supplied,
      closeFactor,
      borrowBalanceArray, 
      closeFactorVal, 
      balanceOfGTokenArr, 
      tokenPriceArr, 
      liquidIncentiveData, 
      liquidatorBalance, 
      userSupplyBalance
    }

    return result;
  }
  return null
}

export const getLiquidityDataOfUserOriginal = async (liquidator: string, borrower: string, market: string, seizeMarket: string) => {
  const user = borrower
  const health = await getHealth(user)
  const unhealthy = parseFloat((await getAccountLiquidity(user, gammatrollerAddress))['2']) > 0 ? true : false
  const borrowBal = await getBorrowBalance(market, user)

  const closeFactor = parseFloat(convertToEther(await closeFactorMantissa(gammatrollerAddress), 18))

  if (borrowBal > 0) {
    const underlyingToken = await underlying(market)
    const repayAssetSymbol = underlyingToken === bnbAddress ? 'BNB' : await symbol(underlyingToken)
    const repayAssetBal =
      liquidator !== null
        ? underlyingToken !== bnbAddress
          ? convertToEther(await balanceOfGtoken(liquidator, underlyingToken), await decimals(underlyingToken))
          : convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
        : 0
    const repayAssetPrice = await getTokenPrice(market)

    let maxRepayAmount = closeFactor * parseFloat(borrowBal)



    const underlyingPrice = await getTokenPrice(seizeMarket)
    const seizeUnderlying = await underlying(seizeMarket)
    const seizeSymbol = seizeUnderlying === bnbAddress ? 'BNB' : await symbol(seizeUnderlying)
    const underlyingDecimalInPower: any = Math.pow(10, await getUnderlyingDecimal(market));
    // let maxSeized = (await liquidateCalculateSeizeTokens(market, seizeMarket, convertToWei(maxRepayAmount, await getUnderlyingDecimal(market)), gammatrollerAddress))['1']
    let maxSeized = (await liquidateCalculateSeizeTokens(market, seizeMarket, convertToWei(maxRepayAmount, underlyingDecimalInPower), gammatrollerAddress))['1']
    maxSeized = maxSeized * (await exchangeRateStored(seizeMarket))

    maxSeized = convertToEther(maxSeized , 10 + parseInt(await getUnderlyingDecimal(seizeMarket)) + 8)

    const liquidIncentive = parseFloat(convertToEther(await liquidationIncentiveMantissa(gammatrollerAddress), 18))


    let maxSeizedInDollar = maxSeized * underlyingPrice
    let maxRepayInDollar = maxRepayAmount * repayAssetPrice

    if (maxSeizedInDollar < maxRepayInDollar) {
      maxRepayInDollar = liquidIncentive < 1 ? maxSeizedInDollar / (1 + liquidIncentive) : maxSeizedInDollar / liquidIncentive
      maxRepayAmount = (1 / repayAssetPrice) * maxRepayInDollar
    } else {
      maxSeizedInDollar = liquidIncentive < 1 ? maxRepayInDollar * (1 + liquidIncentive) : maxRepayInDollar * liquidIncentive
      maxSeized = (1 / underlyingPrice) * maxSeizedInDollar
    }

    const result = {
      address: user,
      health: health,
      unhealthy: unhealthy,
      seizeAsset: seizeMarket,
      maxAmountSeized: maxSeized,
      maxSeizedInDollar: maxSeizedInDollar,
      maxRepayAmount: maxRepayAmount,
      maxRepayInDollar: maxRepayInDollar,
      seizeAssetSymbol: seizeSymbol,
      seizeAssetIcon: getTokenIcon(seizeSymbol),
      market: market,
      borrowBalance: borrowBal,
      repayAssetSymbol: repayAssetSymbol,
      repayAssetBal: repayAssetBal,
      repayAssetIcon: getTokenIcon(repayAssetSymbol),
      seizeAssetBal:
        liquidator !== null
          ? underlyingToken !== bnbAddress
            ? convertToEther(await balanceOfGtoken(liquidator, underlyingToken), await decimals(underlyingToken))
            : convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
          : 0,
    }

    return result;
  }
  return null
}

const borrowUserMulticall = async (tokenArray: any, assetLen: number) =>{
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let borrowUsersArr: any = [];
  if (assetLen === 0) {
    return borrowUsersArr;
  }
  
  try {
    let discount_inst = await selectInstance(instType.DL, discountLevelAddress)
    for (let i = 0; i < assetLen; i++) {
      targets.push(discountLevelAddress)
      // console.log("token address", tokenArray[i], "index", i)
      const data = wallet.web3.eth.abi.encodeFunctionCall(discount_inst.methods.returnBorrowUserArr(tokenArray[i])._method, [tokenArray[i]])
      callDatas.push(data)
      ouput_format.push(discount_inst.methods.returnBorrowUserArr(tokenArray[i])._method.outputs)
    }
    const aggregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = await do_split(results, assetLen)
    // console.log("split_arr", split_arr[0]);
    if (split_arr[0].length > 0) {
      for (let i = 0; i < assetLen; i++) {
        borrowUsersArr = borrowUsersArr.concat(split_arr[0][i][0])
      }
    }
    // console.log("borrowUsersArr", borrowUsersArr);
    return borrowUsersArr;
  } catch (error) {
    console.log('error', error)
  }
}

const userAccountLiquidityMulticall = async (usersArray: any, assetLen: number) =>{
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let borrowUsersArr: any = [];
  if (assetLen === 0) {
    return borrowUsersArr;
  }
  assetLen = 95
  try {
    let inst = await selectInstance(instType.gammatroller, gammatrollerAddress);
    for (let i = 0; i < assetLen; i++) {
      targets.push(gammatrollerAddress)
      console.log("user Address", i, usersArray[i])
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.getAccountLiquidity(usersArray[i])._method, [usersArray[i]])
      callDatas.push(data)
      ouput_format.push(inst.methods.getAccountLiquidity(usersArray[i])._method.outputs)
    }
    console.log("targets", targets.length, targets);
    console.log("callDatas", callDatas.length, callDatas);
    const aggregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = await do_split(results, assetLen)
    console.log("split_arr", split_arr[0]);
    if (split_arr[0].length > 0) {
      for (let i = 0; i < assetLen; i++) {
        if (parseFloat(split_arr[0][i][2]) > 0){
          borrowUsersArr.push(usersArray[i]);
        }
        //borrowUsersArr = borrowUsersArr.concat(split_arr[0][i][0])
      }
    }
    console.log("borrowUsersArr", borrowUsersArr.length);
    return borrowUsersArr;
  } catch (error) {
    console.log('error', error)
  }
}

export const getLiquidatedAccounts = async (liquidator: string) => {
  let initial_timestamp = new Date().getTime();
  // let user_batch = new wallet.web3.BatchRequest();
  // let discount_inst = await selectInstance(instType.DL, discountLevelAddress)
  // let accounts: any = []
  // let discount_counter = 0;
  let assetsIn: any = await getAllMarkets(gammatrollerAddress)
  let index = assetsIn.indexOf('0xa652E6c8f3e257Ab2Ae366D61a3BF75F55E5EB2E');
  if (index > -1) {
    assetsIn.splice(index, 1); 
  }
  index = assetsIn.indexOf('0xE58aa5826Eb6e0568982018C0dB833aDbff925DF');
  if (index > -1) {
    assetsIn.splice(index, 1); 
  }
  let assetDiff = 0;
  // console.log("revised assets data", assetsIn)
  let markets_total = assetsIn.length
  const borrowUsersArr = await borrowUserMulticall(assetsIn, markets_total);

  let allUsersInMarket: any = borrowUsersArr;//accounts

  let withoutDuplicates = [...new Set(allUsersInMarket)]
  let total = withoutDuplicates.length
  // let liquidatedAccountsFromMulticall = await userAccountLiquidityMulticall(withoutDuplicates, total);
   // console.log("liquidatedAccountsFromMulticall", liquidatedAccountsFromMulticall);
  let batch = new wallet.web3.BatchRequest();
  let inst = await selectInstance(instType.gammatroller, gammatrollerAddress);
  let unhealthyAccounts: any = []
  let counter = 0;
  let deficit_users_array: any = [];
  let  batch_start_timestamp = new Date().getTime();
  await new Promise(function (resolve: any, reject) {
    withoutDuplicates.forEach(account => {
      batch.add(
        inst.methods.getAccountLiquidity(account).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);

          counter++;

          if (+data[2] > 0) {
            unhealthyAccounts.push(account);
          }

          if (counter === total)
            resolve();
        })
      )
    });

    batch.execute()
  });
  let  batch_end_timestamp = new Date().getTime();
  console.log("batch time taken", batch_end_timestamp - batch_start_timestamp)
  let liquidatedAccounts: any = []
  console.log("total unhealthy Accounts size", unhealthyAccounts.length)
  const users = unhealthyAccounts.slice(0,50);//['0x9eBf78d472c498a170712838FEeBf114a42d4b8A','0x988EAAcf600894f12887f43E90446b27186955FC','0x576a63e956E5e315AB7763c0588bD0BcD91564E0','0xAB0929437433cDF25867D3f058aF5ea855ecE9b6'];//unhealthyAccounts.slice(0,50);//['0x988EAAcf600894f12887f43E90446b27186955FC','0x576a63e956E5e315AB7763c0588bD0BcD91564E0'];//unhealthyAccounts.slice(0,50);//liquidatedAccountsFromMulticall //unhealthyAccounts
  let deficit_in_PF: any = 0;
  //const users = ["0xf604c1adaf20875b6a5994394969fee47015e554"]
  const assetsLen = Object.keys(pfTokenList).length
  let  multicall_start_timestamp = new Date().getTime();
  let marketsArray = await marketsMulticallRevised(pfTokenList, assetsLen, gammatrollerAddress)
  const balanceOfGTokenArr = await balanceOfGtokenMulticall(liquidator, pfTokenList, assetsLen);
  const underlyingPriceArr = await getTotalUnderlyingPriceMulticall(pfTokenList)
  const closeFactor = parseFloat(convertToEther(await closeFactorMantissa(gammatrollerAddress), 18))
  const liquidIncentive = parseFloat(convertToEther(await liquidationIncentiveMantissa(gammatrollerAddress), 18))
  const liquidatorBalance = convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
  const cashArray = await getCashMulticall(pfTokenList, assetsLen)
  console.log("cashArray", cashArray)
  let  multicall_end_timestamp = new Date().getTime();
  console.log("underlyiny price array", underlyingPriceArr)
  console.log("balanceOfGTokenArr", balanceOfGTokenArr)
  console.log("liquidatorBalance", liquidatorBalance)
  let suppliedAssetsOfLiquidator = [];
  for (let i = 0; i < assetsLen; i++){
    const asset = pfTokenList[i].address;
    const _underlying = pfTokenListRevised[asset].token;
    const _symbol = _underlying === bnbAddress ? 'BNB' : pfTokenListRevised[asset].marketSymbol;
    const balanceOfGTokenData = _underlying !== null? balanceOfGTokenArr[asset] : 0;
    const underlyingDecimals = +pfTokenListRevised[asset].underlyingDecimals;
    const tokenPrice = underlyingPriceArr[asset];
    if((balanceOfGTokenData > 0 && convertToEther(balanceOfGTokenData, underlyingDecimals) * tokenPrice > 0.01) || liquidator !== null){
      suppliedAssetsOfLiquidator.push({
        name: _symbol,
        icon: getTokenIcon(_symbol),
        value: liquidator !== null
          ? _underlying.toLowerCase() !== bnbAddress.toLowerCase()
            ? convertToEther(balanceOfGTokenData, underlyingDecimals)
            : liquidatorBalance
          : 0,
        address: asset,
        usdValue: (liquidator !== null
          ? _underlying.toLowerCase() !== bnbAddress.toLowerCase()
            ? convertToEther(balanceOfGTokenData, underlyingDecimals)
            : liquidatorBalance
          : 0) * (tokenPrice)
      })
    }
    
  }
  for (let i = 0; i < users.length; i++) {
    const user = users[i]
    let  user_asset_multicall_start_timestamp = new Date().getTime();
    const assetsArray = await getAssetsInMulticall(user, gammatrollerAddress);//const assetsIn = await getAssetsIn(user, gammatrollerAddress);
    const userSupplyBalance = await getAllSupplyBalanceMulticall(user);
    const userBorrowBalance = await getBorrowBalanceMulticall(assetsArray, user);
    let  user_asset_multicall_end_timestamp = new Date().getTime();
    console.log("user asset multicall data", assetsArray, userSupplyBalance, userBorrowBalance)
    let supplyAsset = null;
    let borrowAsset = null;
    let assetsToRepayList = [];
    let assetsToSeizeList = [];
  
    let supplyMax = 0;
    let borrowMax = 0;
    let seize_index = 0;
    let borrow_index = 0;
    let total_supplied = 0;
    let total_borrowed = 0;
    let  user_liquidity_check_start_timestamp = new Date().getTime();
    try{
      for (let i = 0; i < assetsArray.length; i++) {
        
        const asset = assetsArray[i].address;
        if(asset.toLowerCase() === "0xa652E6c8f3e257Ab2Ae366D61a3BF75F55E5EB2E".toLowerCase() || asset.toLowerCase() === "0xE58aa5826Eb6e0568982018C0dB833aDbff925DF".toLowerCase()){
          continue;
        }
        if(marketsArray[asset] == undefined)
        {
          continue;
        }
        const collateralMarket = marketsArray[asset];//await markets(asset, gammatrollerAddress)
        if(collateralMarket.collateralFactorMantissa == undefined)
        {
          continue;
        }
        //console.log("collateralMarket", collateralMarket, asset);
        const collateralFactor = convertToEther(collateralMarket.collateralFactorMantissa, 18) * 100
        const _underlying = pfTokenListRevised[asset].token;//await underlying(asset);
        const _symbol = _underlying === bnbAddress ? 'BNB' : pfTokenListRevised[asset].marketSymbol;//await symbol(_underlying)
        
        const balanceOfGTokenData = _underlying !== null? balanceOfGTokenArr[asset] : 0; //await balanceOfGtoken(liquidator, _underlying): 0;
        const underlyingDecimals = +pfTokenListRevised[asset].underlyingDecimals;//await decimals(_underlying);
        // console.log("underlying decimal check", underlyingDecimals, asset)
        const tokenPrice = underlyingPriceArr[asset];//await getTokenPrice(asset);

        
        
        if (collateralFactor >= 0) {

          const supplyBal = userSupplyBalance[asset];//await getSupplyBalance(asset, user);
          total_supplied += userSupplyBalance[asset] * tokenPrice;
          console.log("Supply Balance", asset, supplyBal, tokenPrice, total_supplied )
          if(supplyBal > supplyMax){

            supplyAsset = asset;
            supplyMax = supplyBal;
            seize_index = assetsToSeizeList.length;

          }
          
          if (supplyBal > 0) {
            let getCashVal = cashArray[asset];
            let availableTokens = parseFloat(convertToEther(getCashVal, underlyingDecimals));
            let availableTokensUsd = +availableTokens * +tokenPrice;
            assetsToSeizeList.push({

              name: _symbol,
              icon: getTokenIcon(_symbol),
              value: supplyBal,
              address: asset,
              usdValue: supplyBal * (tokenPrice),
              availableTokens,
              availableTokensUsd
            })
          }

        }
        const borrowBal = userBorrowBalance[asset];//parseFloat(await getBorrowBalance(asset, user));
        total_borrowed += userBorrowBalance[asset] * tokenPrice;
        console.log("borrow Balance", asset, borrowBal, tokenPrice, total_borrowed )
        
        if(borrowBal > borrowMax){

          borrowAsset = asset;
          borrowMax = borrowBal;
          borrow_index = assetsToRepayList.length;
        }

        if (borrowBal > 0) {
          // assetsToRepayList.push({
          //   name: _symbol,
          //   icon: getTokenIcon(_symbol),
          //   value: liquidator !== null
          //     ? _underlying !== bnbAddress
          //       ? convertToEther(balanceOfGTokenData, underlyingDecimals)
          //       : liquidatorBalance
          //     : 0,
          //   address: asset,
          //   usdValue: (liquidator !== null
          //     ? _underlying !== bnbAddress
          //       ? convertToEther(balanceOfGTokenData, underlyingDecimals)
          //       : liquidatorBalance
          //     : 0) * (tokenPrice)
          // })
          assetsToRepayList.push({
            name: _symbol,
            icon: getTokenIcon(_symbol),
            value: borrowBal,
            address: asset,
            usdValue: borrowBal * (tokenPrice)
          })

        }
      }
    } catch(error){
      console.log(error)
    }
    let  user_liquidity_check_end_timestamp = new Date().getTime();
    console.log("user liquidity check time taken", user_liquidity_check_end_timestamp - user_liquidity_check_start_timestamp)
    let fetch_liquidity_user_start = new Date().getTime();
    
    
    let borrowVal = total_borrowed !== undefined ? total_borrowed : 0;
    let supplyVal = total_supplied !== undefined ? total_supplied : 0;
    // deficit_in_PF += total_borrowed - total_supplied;
      // logic deficit
        // 1. total supplied by user < total borrowed by user
      
        // recoverable += (total supplied by user / 1.08)
        // deficit += total borrowed by user - recoverable

        // 2. if(total_supplied_by_user > total_borrowed_by_user){
        //     maxLiquidatedable_supply_side = (total_supplied_by_user / 1.08);
        //     recoverable = minimum (maxLiquidatedable_supply_side, total_borrowed_by_user);
        //     deficit = total borrowed by user  - recoverable;
        // }
    let recoverable = 0;
    let deficit = 0;
    let maxLiquidatedable = 0;
    console.log("supplyVal",supplyVal )
    console.log("borrowVal",borrowVal )
    if(supplyVal < borrowVal){
      recoverable += supplyVal/1.08;
      deficit += borrowVal - recoverable
    } else {
      maxLiquidatedable = supplyVal/1.08;
      recoverable += Math.min(maxLiquidatedable, borrowVal);
      deficit += borrowVal - recoverable;
    }
    deficit_users_array.push({
      userAddress: user,
      net_balance: total_borrowed - total_supplied,
      recoverable,
      deficit,
      maxLiquidatedable
    });
    assetDiff += supplyVal > borrowVal ? borrowVal : supplyVal;
    deficit_in_PF += total_borrowed - assetDiff;

    // console.log("Asset difference", assetDiff, user)
    // console.log("deficit for single user", user, total_borrowed - total_supplied)

    console.log(liquidator, user, borrowAsset, supplyAsset, userBorrowBalance, closeFactor, balanceOfGTokenArr, underlyingPriceArr, liquidIncentive, liquidatorBalance, total_borrowed, total_supplied, userSupplyBalance)
    const res = borrowAsset !== null && supplyAsset !== null ? await getLiquidityDataOfUser(liquidator, user, borrowAsset, supplyAsset, userBorrowBalance, closeFactor, balanceOfGTokenArr, underlyingPriceArr, liquidIncentive, liquidatorBalance, deficit, recoverable, userSupplyBalance) : null;
    let fetch_liquidity_user_end = new Date().getTime();
    // console.log("user liquidity fetch time taken", fetch_liquidity_user_end - fetch_liquidity_user_start, user)
    if (res !== null) {
      liquidatedAccounts.push({ res, assetsToRepayList, assetsToSeizeList, suppliedAssetsOfLiquidator, seize_index, borrow_index})
    }
  }
  
  console.log(unhealthyAccounts)
  let finalSliceVal = 50;
  let newPageNo = 1;
  let pageSize = 50;
  // console.log("deficit in Green Planet ", deficit_in_PF)
  // console.log("page number",newPageNo)
  let liquidatedArr = []
  let loadMore = unhealthyAccounts.length > 0 ? true: false;
  // console.log("Asset difference for the page", assetDiff)
  let returnObj = {liquidatedAccounts, finalSliceVal, newPageNo, pageSize, unhealthyAccounts, deficit_in_PF, loadMore, deficit_users_array, assetDiff}
  let final_timestamp = new Date().getTime();
  // console.log("liquidations time taken", final_timestamp - initial_timestamp)
  // console.log("liquidations details", returnObj)
  return returnObj;
  return liquidatedAccounts;
}

export const loadMoreAccounts = async (liquidator: string, pageno: number, unhealthyAccounts: any, pageSize: number, liquidatedArr: any, deficit_in_PF: any, deficit_users_array: any, assetDiff: any) => {
  let initial_timestamp = new Date().getTime();
  
  let liquidatedAccounts: any = [];
  let initSliceVal = pageno * pageSize;
  let finalSliceVal = (pageno + 1 ) * pageSize;
  const users = unhealthyAccounts.slice(initSliceVal, finalSliceVal);//liquidatedAccountsFromMulticall //unhealthyAccounts
  console.log("unhealthy Accounts size", unhealthyAccounts.length, initSliceVal, finalSliceVal, users)
  //const users = ["0xf604c1adaf20875b6a5994394969fee47015e554"]
  const assetsLen = Object.keys(pfTokenList).length
  let  multicall_start_timestamp = new Date().getTime();
  let marketsArray = await marketsMulticallRevised(pfTokenList, assetsLen, gammatrollerAddress)
  const balanceOfGTokenArr = await balanceOfGtokenMulticall(liquidator, pfTokenList, assetsLen);
  const underlyingPriceArr = await getTotalUnderlyingPriceMulticall(pfTokenList)
  const closeFactor = parseFloat(convertToEther(await closeFactorMantissa(gammatrollerAddress), 18))
  const liquidIncentive = parseFloat(convertToEther(await liquidationIncentiveMantissa(gammatrollerAddress), 18))
  const liquidatorBalance = convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
  const cashArray = await getCashMulticall(pfTokenList, assetsLen)
  console.log("cashArray", cashArray)
  let suppliedAssetsOfLiquidator = [];
  for (let i = 0; i < assetsLen; i++){
    const asset = pfTokenList[i].address;
    const _underlying = pfTokenListRevised[asset].token;
    const _symbol = _underlying === bnbAddress ? 'BNB' : pfTokenListRevised[asset].marketSymbol;
    const balanceOfGTokenData = _underlying !== null? balanceOfGTokenArr[asset] : 0;
    const underlyingDecimals = +pfTokenListRevised[asset].underlyingDecimals;
    const tokenPrice = underlyingPriceArr[asset];
    if((balanceOfGTokenData > 0 && convertToEther(balanceOfGTokenData, underlyingDecimals) * tokenPrice > 0.01) || liquidator !== null){
      suppliedAssetsOfLiquidator.push({
        name: _symbol,
        icon: getTokenIcon(_symbol),
        value: liquidator !== null
          ? _underlying.toLowerCase() !== bnbAddress.toLowerCase()
            ? convertToEther(balanceOfGTokenData, underlyingDecimals)
            : liquidatorBalance
          : 0,
        address: asset,
        usdValue: (liquidator !== null
          ? _underlying.toLowerCase() !== bnbAddress.toLowerCase()
            ? convertToEther(balanceOfGTokenData, underlyingDecimals)
            : liquidatorBalance
          : 0) * (tokenPrice)
      })
    }
    
  }
  let  multicall_end_timestamp = new Date().getTime();
  underlyingPriceArr['0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4'] = 0;
  underlyingPriceArr['0xa5ae8459e710F95ca0C93d73F63a66d9996F1ACE'] = 0;
  console.log("multicall batch timestamp", multicall_end_timestamp - multicall_start_timestamp)
  for (let i = 0; i < users.length; i++) {
    const user = users[i]
    let  user_asset_multicall_start_timestamp = new Date().getTime();
    const assetsArray = await getAssetsInMulticall(user, gammatrollerAddress);//const assetsIn = await getAssetsIn(user, gammatrollerAddress);
    const userSupplyBalance = await getAllSupplyBalanceMulticall(user);
    const userBorrowBalance = await getBorrowBalanceMulticall(assetsArray, user);
    let  user_asset_multicall_end_timestamp = new Date().getTime();
    console.log("user asset multicall timestamp", user_asset_multicall_end_timestamp - user_asset_multicall_start_timestamp)
    let supplyAsset = null;
    let borrowAsset = null;
    let assetsToRepayList = [];
    let assetsToSeizeList = [];

    let supplyMax = 0;
    let borrowMax = 0;
    let seize_index = 0;
    let borrow_index = 0;
    let total_supplied = 0;
    let total_borrowed = 0;
    let  user_liquidity_check_start_timestamp = new Date().getTime();
    try{
      for (let i = 0; i < assetsArray.length; i++) {
        
        const asset = assetsArray[i].address;
        if(asset.toLowerCase() === "0xa652E6c8f3e257Ab2Ae366D61a3BF75F55E5EB2E".toLowerCase() || asset.toLowerCase() === "0xE58aa5826Eb6e0568982018C0dB833aDbff925DF".toLowerCase()){
          continue;
        }
        if(marketsArray[asset] == undefined)
        {
          continue;
        }
        const collateralMarket = marketsArray[asset];//await markets(asset, gammatrollerAddress)
        if(collateralMarket.collateralFactorMantissa == undefined)
        {
          continue;
        }
        //console.log("collateralMarket", collateralMarket, asset);
        const collateralFactor = convertToEther(collateralMarket.collateralFactorMantissa, 18) * 100
        const _underlying = pfTokenListRevised[asset].token;//await underlying(asset);
        const _symbol = _underlying === bnbAddress ? 'BNB' : pfTokenListRevised[asset].marketSymbol;//await symbol(_underlying)
        
        const balanceOfGTokenData = _underlying !== null? balanceOfGTokenArr[asset] : 0; //await balanceOfGtoken(liquidator, _underlying): 0;
        const underlyingDecimals = +pfTokenListRevised[asset].underlyingDecimals;//await decimals(_underlying);
        // console.log("underlying decimal check", underlyingDecimals, asset)
        const tokenPrice = underlyingPriceArr[asset];//await getTokenPrice(asset);
        
        if (collateralFactor >= 0) {

          const supplyBal = userSupplyBalance[asset];//await getSupplyBalance(asset, user);
          total_supplied += userSupplyBalance[asset] * tokenPrice;
          console.log("Supply Balance", asset, supplyBal, tokenPrice, total_supplied )
          if(supplyBal > supplyMax){

            supplyAsset = asset;
            supplyMax = supplyBal;
            seize_index = assetsToSeizeList.length;

          }
          
          if (supplyBal > 0) {
            let getCashVal = cashArray[asset];
            let availableTokens = parseFloat(convertToEther(getCashVal, underlyingDecimals));
            let availableTokensUsd = +availableTokens * +tokenPrice;
            assetsToSeizeList.push({

              name: _symbol,
              icon: getTokenIcon(_symbol),
              value: supplyBal,
              address: asset,
              usdValue: supplyBal * (tokenPrice),
              availableTokens,
              availableTokensUsd
            })

          }

        }
        const borrowBal = userBorrowBalance[asset];//parseFloat(await getBorrowBalance(asset, user));
        total_borrowed += userBorrowBalance[asset] * tokenPrice;
        console.log("borrow Balance", asset, borrowBal, tokenPrice, total_borrowed )
        if(borrowBal > borrowMax){

          borrowAsset = asset;
          borrowMax = borrowBal;
          borrow_index = assetsToRepayList.length;
        }

        if (borrowBal > 0) {
          // assetsToRepayList.push({
          //   name: _symbol,
          //   icon: getTokenIcon(_symbol),
          //   value: liquidator !== null
          //     ? _underlying !== bnbAddress
          //       ? convertToEther(balanceOfGTokenData, underlyingDecimals)
          //       : liquidatorBalance
          //     : 0,
          //   address: asset,
          //   usdValue: (liquidator !== null
          //     ? _underlying !== bnbAddress
          //       ? convertToEther(balanceOfGTokenData, underlyingDecimals)
          //       : liquidatorBalance
          //     : 0) * (tokenPrice)
          // })
          assetsToRepayList.push({
            name: _symbol,
            icon: getTokenIcon(_symbol),
            value: borrowBal,
            address: asset,
            usdValue: borrowBal * (tokenPrice)
          })
        }
      }
    } catch(error){
      console.log(error)
    }
    let  user_liquidity_check_end_timestamp = new Date().getTime();
    deficit_in_PF += total_borrowed - total_supplied;
    let borrowVal = total_borrowed !== undefined ? total_borrowed : 0;
    let supplyVal = total_supplied !== undefined ? total_supplied : 0;
    let recoverable = 0;
    let deficit = 0;
    let maxLiquidatedable = 0;
    if(supplyVal < borrowVal){
      recoverable += supplyVal/1.08;
      deficit += borrowVal - recoverable
    } else {
      maxLiquidatedable = supplyVal/1.08;
      recoverable += Math.min(maxLiquidatedable, borrowVal);
      deficit += borrowVal - recoverable;
    }
    deficit_users_array.push({
      userAddress: user,
      net_balance: total_borrowed - total_supplied,
      recoverable,
      deficit,
      maxLiquidatedable
    });
    assetDiff += supplyVal > borrowVal ? borrowVal : supplyVal;
    deficit_in_PF += total_borrowed - assetDiff;
    console.log("user liquidity check time taken", user_liquidity_check_end_timestamp - user_liquidity_check_start_timestamp)
    let fetch_liquidity_user_start = new Date().getTime();
    const res = borrowAsset !== null && supplyAsset !== null ? await getLiquidityDataOfUser(liquidator, user, borrowAsset, supplyAsset, userBorrowBalance, closeFactor, balanceOfGTokenArr, underlyingPriceArr, liquidIncentive, liquidatorBalance, deficit, recoverable, userSupplyBalance) : null;
    let fetch_liquidity_user_end = new Date().getTime();
    console.log("user liquidity fetch time taken", fetch_liquidity_user_end - fetch_liquidity_user_start, user)
    if (res !== null) {
      liquidatedAccounts.push({ res, assetsToRepayList, assetsToSeizeList, suppliedAssetsOfLiquidator, seize_index, borrow_index})
    }
  }
  let newPageNo = pageno + 1;
  let loadMore = (unhealthyAccounts.length/newPageNo) > pageSize ? true: false;
  let updatedAccountArr = liquidatedArr.concat(liquidatedAccounts)
  console.log("deficit in Green Planet ", deficit_in_PF)
  console.log("Asset difference for the page", assetDiff)
  console.log("page number",newPageNo)
  let returnObj = {updatedAccountArr, finalSliceVal, newPageNo, pageSize, unhealthyAccounts, deficit_in_PF, loadMore, deficit_users_array, assetDiff}
  console.log(updatedAccountArr, finalSliceVal, pageno+1, pageSize, liquidatedArr, unhealthyAccounts)
  let final_timestamp = new Date().getTime();
  console.log(JSON.stringify(deficit_users_array))
  console.log("liquidations time taken", final_timestamp - initial_timestamp)
  return returnObj;
  //return liquidatedAccounts;
}

export const getLiquidatedAccountsOriginal = async (liquidator: string) => {



  let user_batch = new wallet.web3.BatchRequest();
  let discount_inst = await selectInstance(instType.DL, discountLevelAddress)
  let accounts: any = []
  let discount_counter = 0;
  const assetsIn: any = await getAllMarkets(gammatrollerAddress)
  let markets_total = assetsIn.length

  await new Promise(function (resolve: any, reject) {
    assetsIn.forEach((asset: any) => {
      user_batch.add(
        discount_inst.methods.returnBorrowUserArr(asset).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);

          discount_counter++;

          if (data.length > 0) {
            //console.log(asset,data)
            data.forEach((element: any) => {
              accounts.push(element.toLowerCase())
            });
          }

          if (discount_counter === markets_total)
            resolve();
        })
      )
    });

    user_batch.execute()
  });

  let allUsersInMarket: any = accounts

  let withoutDuplicates = [...new Set(allUsersInMarket)]
  let batch = new wallet.web3.BatchRequest();
  let inst = await selectInstance(instType.gammatroller, gammatrollerAddress);
  let unhealthyAccounts: any = []
  let counter = 0;
  let total = withoutDuplicates.length

  await new Promise(function (resolve: any, reject) {
    withoutDuplicates.forEach(account => {
      batch.add(
        inst.methods.getAccountLiquidity(account).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);

          counter++;

          if (parseFloat(data[2]) > 0) {
            unhealthyAccounts.push(account);
          }

          if (counter === total)
            resolve();
        })
      )
    });

    batch.execute()
  });

  let liquidatedAccounts: any = []
  const users = unhealthyAccounts
  //const users = ["0xf604c1adaf20875b6a5994394969fee47015e554"]

  for (let i = 0; i < users.length; i++) {

    const user = users[i]

    const assetsIn = await getAssetsIn(user, gammatrollerAddress);

    let supplyAsset = null;
    let borrowAsset = null;
    let assetsToRepayList = [];
    let assetsToSeizeList = [];

    let supplyMax = 0;
    let borrowMax = 0;
    let seize_index = 0;
    let borrow_index = 0;

    for (let i = 0; i < assetsIn.length; i++) {
      
      const asset = assetsIn[i];

      const collateralMarket = await markets(asset, gammatrollerAddress)
      const collateralFactor = convertToEther(collateralMarket.collateralFactorMantissa, 18) * 100
      const _underlying = await underlying(asset);
      const _symbol = _underlying === bnbAddress ? 'BNB' : await symbol(_underlying)

      if (collateralFactor > 0) {

        const supplyBal = await getSupplyBalance(asset, user);

        if(supplyBal > supplyMax){

          supplyAsset = asset;
          supplyMax = supplyBal;
          seize_index = assetsToSeizeList.length;

        }

        if (supplyBal > 0) {

          assetsToSeizeList.push({

            name: _symbol,
            icon: getTokenIcon(_symbol),
            value: liquidator !== null
              ? _underlying !== bnbAddress
                ? convertToEther(await balanceOfGtoken(liquidator, _underlying), await decimals(_underlying))
                : convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
              : 0,
            address: asset,
            usdValue: (liquidator !== null
              ? _underlying !== bnbAddress
                ? convertToEther(await balanceOfGtoken(liquidator, _underlying), await decimals(_underlying))
                : convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
              : 0) * (await getTokenPrice(asset))
          })

        }

      }
      const borrowBal = parseFloat(await getBorrowBalance(asset, user));

      if(borrowBal > borrowMax){

        borrowAsset = asset;
        borrowMax = borrowBal;
        borrow_index = assetsToRepayList.length;
      }

      if (borrowBal > 0) {
        assetsToRepayList.push({
          name: _symbol,
          icon: getTokenIcon(_symbol),
          value: liquidator !== null
            ? _underlying !== bnbAddress
              ? convertToEther(await balanceOfGtoken(liquidator, _underlying), await decimals(_underlying))
              : convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
            : 0,
          address: asset,
          usdValue: (liquidator !== null
            ? _underlying !== bnbAddress
              ? convertToEther(await balanceOfGtoken(liquidator, _underlying), await decimals(_underlying))
              : convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
            : 0) * (await getTokenPrice(asset))
        })
      }
    }

    const res = borrowAsset !== null ? await getLiquidityDataOfUser(liquidator, user, borrowAsset, supplyAsset) : null;

    if (res !== null) {
      liquidatedAccounts.push({ res, assetsToRepayList, assetsToSeizeList ,seize_index,borrow_index})
    }
  }

  console.log(liquidatedAccounts)

  return liquidatedAccounts;
}

const getHealth = async (user: string) => {

  const collat = await getTotalSupplyBalanceIncludingCollateral(user);
  const borrow = await getTotalBorrowBalance(user);

  if(borrow != 0){
    return (collat/borrow);
  }

  return Math.max;

}

export const checkErrors = async (data: any, userAddress: any, type: number, inputAmount: string, platFormData?: any) => {
  switch (type) {
    case Type.supply: {
      if (parseFloat(inputAmount) > parseFloat(data.walletBal)) {
        return {
          reason: 'Input Amount Exceed Wallet Balance',
          proceed: false,
        }
      }
      return {
        reason: 'Input Amount Is Under Wallet Balance',
        proceed: true,
      }
    }
    case Type.withdraw: {
      const underlyingDecimalInPower: any = Math.pow(10, await getUnderlyingDecimal(data.address));
      // const redeemTokens: any = (parseFloat(convertToWei(inputAmount, 18 + parseInt(await getUnderlyingDecimal(data.address)))) / (await exchangeRateStored(data.address))).toFixed(0)
      const redeemTokens: any = (parseFloat(convertToWei(inputAmount, 18 + parseInt(underlyingDecimalInPower))) / (await exchangeRateStored(data.address))).toFixed(0)
      const hypoData = await getHypotheticalAccountLiquidity(userAddress, data.address, redeemTokens, '0', gammatrollerAddress)

      if (parseFloat(inputAmount) > parseFloat(data.maxWithdraw)) {
        if (parseFloat(hypoData['2']) > 0) {
          return {
            reason: 'Insufficient Collateral',
            proceed: false,
          }
        }
        else if (inputAmount > data.available) {
          return {
            reason: 'Cannot withdraw more than availaible in market',
            proceed: false,
          }
        } else
          return {
            reason: 'Cannot withdraw more than supplied',
            proceed: false,
          }
      }
      return {
        reason: 'Input Amount Is Under Collateral Value',
        proceed: true,
      }
    }
    case Type.borrow: {
      const underlyingDecimalInPower: any = Math.pow(10, await decimals(await underlying(data.address)));
      // const borrowTokens: any = convertToWei(inputAmount, await decimals(await underlying(data.address)))
      const borrowTokens: any = convertToWei(inputAmount, underlyingDecimalInPower)
      const hypoData = await getHypotheticalAccountLiquidity(userAddress, data.address, '0', borrowTokens, gammatrollerAddress)
      const available: any = data.availableUsd / data.underlyingPrice
      if (parseFloat(inputAmount) > parseFloat(available)) {
        return {
          reason: 'Insufficient Market Liquidity',
          proceed: false,
        }
      }
      else if (parseFloat(inputAmount) >
        (parseFloat(platFormData.borrowLimitInUsd) / data.underlyingPrice - parseFloat(platFormData.userTotalBorrowBal) / data.underlyingPrice)) {
        return {
          reason: 'Borrow Limit Exceed',
          proceed: false,
        }
      }

      else if (parseFloat(hypoData['2']) > 0 || platFormData !== undefined &&
        ((parseFloat(platFormData.userTotalBorrowBal) / data.underlyingPrice) + parseFloat(inputAmount)) >
        (0.9 * parseFloat(platFormData.borrowLimitInUsd) / data.underlyingPrice)) {
        return {
          reason: 'Beware of Liquidation Risk',
          proceed: false,
        }
      }
      return {
        reason: 'Input Amount Is Under Collateral Value',
        proceed: true,
      }
    }
    case Type.repay: {
      if (parseFloat(inputAmount) > parseFloat(data.walletBal)) {
        return {
          reason: 'Input Amount Exceed Wallet Balance',
          proceed: false,
        }
      }
      return {
        reason: 'Input Amount Is Under Wallet Balance',
        proceed: true,
      }
    }

    default:
      return false
  }
}

export const getApyOnLevelSelectAtSupply = async (gTokenAddress: string, level: number) => {
  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365

    const _supplyRatePerBlock = convertToEther(await supplyRatePerBlock(gTokenAddress), 18)
    let supplyApy = (Math.pow(_supplyRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100

    const gammaSupplyApy = await getGammaSupplyApy(gTokenAddress)

    // const totalBorrows_ = convertToEther(await totalBorrows(gTokenAddress), 18)

    // const discount = level === 0 ? 0 : level === 1 ? 2.5 : level === 2 ? 10 : 25

    // if (gTokenAddress !== pfTokenList[1].address && totalBorrows_ > 0) {
    //   const diff = await differenceApy(gTokenAddress)
    //   supplyApy += (discount * diff) / 100
    // }

    let finalSupplyApy = parseFloat(gammaSupplyApy) + supplyApy

    //((1+(0.219)/365)^365)-1 = 0.244749527= 24.47% APY

    //let aprPerYear = (Math.pow((finalSupplyApy/100 + 1),(1/365)) - 1)*365*100

    if (gTokenAddress.toLowerCase() === oldgAqua.toLowerCase() ||
      gTokenAddress.toLowerCase() === gAqua.toLowerCase()) {
      const burnApy = await getBurnApy()
      finalSupplyApy += parseFloat(burnApy)
    }

    const aprPerYear = (Math.pow((finalSupplyApy / 100 + 1), (1 / 365)) - 1) * 365 * 100

    return {
      supplyApy: finalSupplyApy,
      supplyApy24hr: aprPerYear / 365,
      address: gTokenAddress,
    }
  } catch (e) {
    console.log(e)
    return {
      supplyApy: 0,
      supplyApy24hr: 0,
      address: gTokenAddress,
    }
  }
}

export const getApyOnLevelSelectAtBorrow = async (gTokenAddress: string, level: number) => {
  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365

    const _borrowRatePerBlock = convertToEther(await borrowRatePerBlock(gTokenAddress), 18)
    let borrowApy = (Math.pow(_borrowRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100

    const gammaBorrowApy = await getGammaBorrowApy(gTokenAddress)

    const totalBorrows_ = convertToEther(await totalBorrows(gTokenAddress), await getUnderlyingDecimal(gTokenAddress))

    const discount = level === 0 ? 0 : level === 1 ? 5 : level === 2 ? 20 : 50

    if (gTokenAddress.toLowerCase() !== gGamma.toLowerCase() && totalBorrows_ > 0) {
      const diff = await differenceApy(gTokenAddress)
      borrowApy -= (discount * diff) / 100
    }

    const finalBorrowApy = parseFloat(gammaBorrowApy) - borrowApy
    let aprPerYear = 0
    let borrowApy24hr = 0
    if (finalBorrowApy > 0) {
      aprPerYear = (Math.pow((finalBorrowApy / 100 + 1), (1 / 365)) - 1) * 365 * 100
      borrowApy24hr = (aprPerYear / 365)
    } else {
      aprPerYear = (Math.pow((finalBorrowApy * -1 / 100 + 1), (1 / 365)) - 1) * 365 * 100
      borrowApy24hr = (aprPerYear / 365) * -1
    }
   
    return {
      withoutRewardApy: borrowApy,
      borrowApy: finalBorrowApy,
      borrowApy24hr: borrowApy24hr,
      address: gTokenAddress,
    }
  } catch (e) {
    console.log(e)
    return {
      withoutRewardApy: 0,
      borrowApy: 0,
      borrowApy24hr: 0,
      address: gTokenAddress,
    }
  }
}

export const getApyOnLevelSelectAtBorrowRevised = async (gTokenAddressArray: any, level: number, borrowApyArr: any, totalSupplyArray: any) => {
  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365
    const listLength = Object.keys(gTokenAddressArray).length
    const _borrowRatePerBlockArr: any = await borrowRatePerBlockMulticall(gTokenAddressArray);
    const _supplyRatePerBlockArr: any = await supplyRatePerBlockMulticall(totalSupplyArray, gTokenAddressArray, listLength);
    const totalBorrowsArr = await totalBorrowsMulticall(gTokenAddressArray);
    
    const discount = level === 0 ? 0 : level === 1 ? 5 : level === 2 ? 20 : 50

    let apyBorrowLevelArr: any = [];
    for (var key in gTokenAddressArray){
      // decimalArr[gTokenAddressArray[key].address] = gTokenAddressArray[key].decimals
      let borrowApy = _borrowRatePerBlockArr[gTokenAddressArray[key].address];
      const underlyingDecimal = gTokenAddressArray[key].decimals;
      // let borrowApy = (Math.pow(_borrowRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100
      const gammaBorrowApy = borrowApyArr[gTokenAddressArray[key].address];
      const totalBorrows_ = convertToEther(totalBorrowsArr[gTokenAddressArray[key].address], underlyingDecimal);
      if (gTokenAddressArray[key].address.toLowerCase() !== gGamma.toLowerCase() && totalBorrows_ > 0) {
        let anchorApyOffset = gTokenAddressArray[key].address === "0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4" ? anchor_apy : 0;
        const diff = borrowApy - _supplyRatePerBlockArr[gTokenAddressArray[key].address] + anchorApyOffset;
        borrowApy -= (discount * diff) / 100
      }
      const finalBorrowApy = parseFloat(gammaBorrowApy) - borrowApy
      let aprPerYear = 0
      let borrowApy24hr = 0
      if (finalBorrowApy > 0) {
        aprPerYear = (Math.pow((finalBorrowApy / 100 + 1), (1 / 365)) - 1) * 365 * 100
        borrowApy24hr = (aprPerYear / 365)
      } else {
        aprPerYear = (Math.pow((finalBorrowApy * -1 / 100 + 1), (1 / 365)) - 1) * 365 * 100
        borrowApy24hr = (aprPerYear / 365) * -1
      }
      let obj = {
        withoutRewardApy: borrowApy ? borrowApy : 0,
        borrowApy: finalBorrowApy ? finalBorrowApy : 0,
        borrowApy24hr: borrowApy24hr ? borrowApy24hr : 0,
        address: gTokenAddressArray[key].address,
      }
      
      apyBorrowLevelArr[gTokenAddressArray[key].address] = obj;
    }
    
    return apyBorrowLevelArr;
    
  } catch (e) {
    console.log(e)
    return {
      withoutRewardApy: 0,
      borrowApy: 0,
      borrowApy24hr: 0,
      address: gTokenAddressArray[0].address,
    }
  }
}

export const getIndividualMarketData = async (gTokenAddress: string) => {
  const supplyApy = await getSupplyApy(gTokenAddress, '')

  const borrowApy = await getBorrowApy(gTokenAddress, '')

  const supplyDistApy = await getGammaSupplyApy(gTokenAddress)

  const borrowDistApy = await getGammaBorrowApy(gTokenAddress)

  const netSupplyRate: any = supplyApy + supplyDistApy

  const netBorrowRate: any = borrowApy + borrowDistApy

  const gTokenPrice = await getGTokenPrice(gTokenAddress)

  const underlyingPrice = await getTokenPrice(gTokenAddress)

  const underlyingToken = await underlying(gTokenAddress)

  const gTokenSymbol = await symbol(gTokenAddress)

  let underlyingSymbol = await symbol(underlyingToken)

  underlyingSymbol = underlyingSymbol === 'WBNB' ? 'BNB' : underlyingSymbol

  const totalSupply_ = convertToEther(await totalSupply(gTokenAddress), 8)

  const totalSupplyInUSd = totalSupply_ * gTokenPrice

  const borrows_ = await totalBorrows(gTokenAddress)

  const totalBorrows_ = convertToEther(borrows_, await getUnderlyingDecimal(gTokenAddress))

  const totalBorrowInUSd = totalBorrows_ * underlyingPrice

  const cash_ = await getCash(gTokenAddress)

  const marketLiquidity = convertToEther(cash_, await getUnderlyingDecimal(gTokenAddress))

  const borrowCaps_: any = convertToEther(await borrowCaps(gTokenAddress, gammatrollerAddress), await getUnderlyingDecimal(gTokenAddress))
  console.log("borrow details", borrowCaps_, totalBorrows_, marketLiquidity)
  // const availableToBorrow: any = borrowCaps_ > 0 ? borrowCaps_ - totalBorrows_ : marketLiquidity - totalBorrows_
  const availableToBorrow: any = borrowCaps_ > 0 ? borrowCaps_ - totalBorrows_ : marketLiquidity - totalBorrows_ > 0 ? marketLiquidity - totalBorrows_ : totalBorrows_ - marketLiquidity

  const factor: any = await markets(gTokenAddress, gammatrollerAddress)

  const collateralFactor: any = await convertToEther(factor.collateralFactorMantissa, 18)

  const marketTokenMinted: any = totalSupply_

  const rate: any = await exchangeRateStored(gTokenAddress)

  const decimal =  parseFloat(await getUnderlyingDecimal(gTokenAddress)) + 10;
  const underlyingDecimalInPower: any = Math.pow(10, decimal);
  // const exchangeRate: any = convertToWei(1 / rate, decimal)
  const exchangeRate: any = convertToWei(1 / rate, underlyingDecimalInPower)

  const interestRateModelAddress: any = await interestRateModel(gTokenAddress)

  const util: any = noExponents(
    convertToEther(await utilizationRate(cash_, borrows_, await totalReserves(gTokenAddress), interestRateModelAddress), 18) * 100
  )

  const noOfSuppliers: number = 0

  const noOfBorrowers: number = 0

  const dailyInterestPerDay: number = 0

  return {
    supplyApy,
    borrowApy,
    borrowDistApy,
    supplyDistApy,
    netSupplyRate,
    netBorrowRate,
    totalSupplyInUSd,
    totalBorrowInUSd,
    underlyingPrice,
    gTokenPrice,
    marketLiquidity,
    availableToBorrow,
    collateralFactor,
    marketTokenMinted,
    exchangeRate,
    gTokenSymbol,
    underlyingSymbol,
    util,
    noOfSuppliers,
    noOfBorrowers,
    dailyInterestPerDay,
  }
}

const getTotalSuppInUsd = async (gTokenAddress: string, blockNumber?: number) => {
  const price = await getGTokenPrice(gTokenAddress, blockNumber)
  const underlyingBal = convertToEther(await totalSupply(gTokenAddress, blockNumber), 8)
  return price * parseFloat(underlyingBal)
}

const getTotalBorrowInUsd = async (gTokenAddress: string, blockNumber?: number) => {
  const price = await getTokenPrice(gTokenAddress, blockNumber)
  const underlyingBal = convertToEther(await totalBorrows(gTokenAddress, blockNumber), await getUnderlyingDecimal(gTokenAddress))
  return price * parseFloat(underlyingBal)
}

const getGraphData = async (address: string, startBlock: number) => {
  const currentBlock = await wallet.web3.eth.getBlockNumber()
  let res = []
  for (let i = startBlock; i <= currentBlock; i += 28800) {
    const obj = {
      totalSupply: await getTotalSuppInUsd(address, i),
      totalBorrow: await getTotalBorrowInUsd(address, i),
      supplyApy: await getSupplyApy(address, '', i),
      borrowApy: await getBorrowApy(address, '', i),
      blockNumber: i,
      date: new Date((await wallet.web3.eth.getBlock(i)).timestamp * 1000),
    }
    res.push(obj)
  }
  return res
}

export const get_level_three_users = async() => {

  const all_markets : any = await getAllMarkets(gammatrollerAddress);

  const discount_inst = await selectInstance(instType.DL, discountLevelAddress);

  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);

  let targets: any = [];
  let callDatas: any = [];
  let users: any = [];
  let ouput_format: any = [];
  
  all_markets.forEach(async (asset: any) => {
    
    targets.push(discountLevelAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(discount_inst.methods.returnBorrowUserArr(asset)._method, [asset]));
    callDatas.push(data);
    ouput_format.push(discount_inst.methods.returnBorrowUserArr(asset)._method.outputs)

  })

  let agregated_data = (await mutilcall_inst.methods.aggregate(targets, callDatas).call());
  
  for (let i = 0; i < agregated_data[1].length; i++) {

    const _users : any = wallet.web3.eth.abi.decodeParameters(ouput_format[i], agregated_data[1][i])
    
    _users[0].forEach((user:any) => users.push(user));

  }
 
  users = [...new Set(users)];

  let targets1 : any = [];
  let callDatas1 : any = [];
  let output_format_1 : any= [];
  let result : any = [];

  users.forEach(async (user: any) => {
    
    targets1.push(discountLevelAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(discount_inst.methods.returnDiscountPercentage(user)._method, [user]));
    callDatas1.push(data);
    output_format_1.push(discount_inst.methods.returnDiscountPercentage(user)._method.outputs)

  })

  const inc = 29;

  let agregated_data_1 : any = [];

  for (let i = 0; i < targets1.length; ) {

    let j = i + inc;

    if( (i + inc) <= targets1.length ){
      j = i + inc;
    }
    else{
      j = targets1.length;
    }

    try{

      // console.log(i,j);
      const res = (await mutilcall_inst.methods.aggregate(targets1.slice(i,j), callDatas1.slice(i,j)).call());
      
      for(let z  = 0 ; z < res[1].length ; z++){
        agregated_data_1.push(res[1][z]);
      }
    }

    catch(e){
      //console.log(users[i]);
    }

    i = j;

  }

  for (let i = 0; i < agregated_data_1.length ; i++) {
    
    const discount : any = wallet.web3.eth.abi.decodeParameters(output_format_1[i], agregated_data_1[i])

    if (parseInt(discount["discount"]) === level3Discount) {
      result.push(users[i]);
    }

  }

  return result;

}