import React, { useState, useEffect, createContext, forwardRef, useRef, useImperativeHandle } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MainContainer, FlexCont, CardButton2, LoadingText1, FlexSBCont, ToolTipIcon } from 'shared/styles/styled'
import { TitleText, TitleInfo, DataTitle, CardText } from 'shared/styles/globalText'
import { DataCont, TableSearchCont, TableProtoCalCont, TableCont, LoadingText4 } from 'shared/styles/planetTableStyle'
import { PoolsContainer, PoolsTabCont, TabWrapper, TabContainer, TabBox, TabText, GammaEarnedBox, EarnValText, PoolsHeader, DataInfoBoxNew, SliderContainer, SliderText, } from './style'
import { newPoolTabOptions, protocolOptions, latestPoolTabOptions } from 'service/dataConstant'
import { FarmPoolContext } from 'context/FarmPoolContext'
import Select from 'shared/select'
import HighestApyPools from './components/HighestApyPools'
import PoolTable from './components/PoolTable'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import InfoTooltip from 'shared/info-tool-tip';
import PoolEarnings from './components/PoolEarnings';
import Spinner from 'shared/spinner';
import { commaFy } from 'service/globalFunctions';
import { claimGammaRewardsForPools, totalAllocPoint, GAMMAPerBlockNew } from 'modules/block-chain/BlockChainMethods';
import { updateUserBalance } from 'logic/action/user.actions';
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import { updateUserLendingData } from 'logic/action/lending.action';
import { transLinkUrl } from 'service/global-constant';
// import PlanetGToken from './components/PlanetGToken'
import PoolEarningsClaimAndStake from './components/PoolEarningsClaimAndStake'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import CollectRewards from './components/pool-modal/CollectRewards'
import { returnTokenList, tokenSwapList } from '../block-chain/tokenList'
import SwapInPools from 'modules/pools/components/pool-modal/SwapInPools'
import Slider from 'react-rangeslider'
import QuestionIcon from 'assets/icons/question_icon.png'
import TooltipModal from 'shared/tooltip-modal'

let userInLP: boolean = false;
const New_Pools = () => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('all')
    const [protocol, setProtocol] = useState('')
    const [claimLoading, setClaimLoading] = useState(false)
    const { poolLoading, poolData, portfolioLoading, userPortfolio, aquaInfDataNew } = selector.user
    const [claimAndStakeLoading, setClaimAndStakeLoading] = useState(false)
    const [showCollectModal, setShowCollectModal] = useState(false)
    const [farmPoolData, setFarmPoolData] =  useState({
        totalAllocPoint: 365,
        gammaPerBlock: 1614583333333333200, 
        tokenSwapList: tokenSwapList,
        tokenSwapListWithUserBalances: tokenSwapList
    })
    const [poolList, setPoolList] = useState<any>({
        active: [],
        finished: []
    })

    const [userPoolList, setUserPoolList] = useState<any>([])
    const [myPoolTableList, setMyPoolTableList] = useState<any>([])
    const [tokenDataList, setTokenDataList] = useState<any>(tokenSwapList)
    const [showSwapPopUp, setShowSwapPopUp] = useState(false)
    const [receiveTokenNumber, setReceiveTokenNumber] = useState('')
    const [tokenSwapAmount, setTokenSwapAmount] = useState('')
    const [initialTokenAdd, setInitialTokenAdd] = useState('')
    const [suppliedOtherPoolsAmount, setSuppliedOtherPoolsAmount] = useState(userPortfolio.other_pool_balance_in_v3)
    const [suppliedGammaBtcbAmount, setSuppliedGammaBtcbAmount] = useState(userPortfolio.user_gamma_btcb_balance_in_v3)
    const [sendToken, setSendToken] = useState<any>(tokenSwapList[1])
    const [showToolTip, setShowTooltipModal] = useState(false)
    const [toolTipTitle, setToolTipTitle] = useState('')
    const [toolTipMessage, setToolTipMessage] = useState('')
    const [poolRatio, setPoolRatio] = useState(0)
    const poolTableRef: any = useRef();

    useEffect(() => {
        const updateTokenListWithBalances = async () => {
            const userAddress = selector.ethData.address
            try {
              const res = await returnTokenList(userAddress)
              if (!!res && typeof res !== 'undefined') {
                farmPoolData.tokenSwapListWithUserBalances = res;
              }
            } catch (error) {
              console.log('error===>', error)
            }
        }
        updateTokenListWithBalances()
    }, [selector.ethData.address, poolData])

    useEffect(() => {        
        if(poolData != undefined && poolData.v3Pools != undefined &&  poolData.v3Pools.active && poolData.v3Pools.active.length > 0) {            
            let pools = JSON.parse(JSON.stringify(poolData.v3Pools));
            setPoolList(pools)
            let uPoolList = getUserPoolData(pools);
            setMyPoolTableList(uPoolList)
            setUserPoolList(uPoolList);
        }
    }, [poolData])

    useEffect(() => {        
        if(userPortfolio != undefined && userPortfolio.other_pool_balance_in_v3 != undefined &&  userPortfolio.user_gamma_btcb_balance_in_v3 != undefined) {   
            let otherPoolBalance = userPortfolio.other_pool_balance_in_v3 * 0.05;  
            setSuppliedOtherPoolsAmount(otherPoolBalance);
            setSuppliedGammaBtcbAmount(userPortfolio.user_gamma_btcb_balance_in_v3)
        }
    }, [userPortfolio])

    useEffect(() => {
        totalAllocPoint().then((tAlloc) => {
            if(farmPoolData.totalAllocPoint != tAlloc) {
                farmPoolData.totalAllocPoint = tAlloc;
                setFarmPoolData(farmPoolData)
            }
        })
        GAMMAPerBlockNew().then((gammaPerBlock) => {
            if(farmPoolData.gammaPerBlock != gammaPerBlock) {
                farmPoolData.gammaPerBlock = gammaPerBlock;
                setFarmPoolData(farmPoolData)
            }
        })
    }, []);

    useEffect(() => {
        if(userPortfolio != undefined && userPortfolio.user_gamma_btcb_balance_in_v3 !== undefined && userPortfolio.other_pool_balance_in_v3 !== undefined ){
            setPoolRatio(userPortfolio.user_gamma_btcb_balance_in_v3 * 100/ userPortfolio.other_pool_balance_in_v3)
        }
    }, [userPortfolio])

    const showToolTipModal = async(title: any, message: any) =>{
        setShowTooltipModal(!showToolTip);
        setToolTipTitle(title);
        setToolTipMessage(message)
    }

    // const [userInLP, setUserInLP] = useState(false)
    const handleProtocolSelect = (protocolOption: string) => {
        let rowData = userPoolList.slice();
        setProtocol(protocolOption)
        if (!!protocolOption) {
            rowData = rowData.filter((e: any) => e.protocol.toLowerCase() === protocolOption)
        }
        setMyPoolTableList(rowData)
    }

    const getUserPoolData = (pools:any ) => {
        let userPoolArr: any = []
        if (!poolLoading && !portfolioLoading) {
            const dataArr = pools;
            if (!!dataArr && Object.values(dataArr).length > 0) {
                userPoolArr = dataArr.active.filter((e: any) => {
                    return parseFloat(e.vaultBalanceUSD) > 0
                })
            }
            if(userPoolArr.length > 0){
                userInLP = true;
            }
        }
        return userPoolArr
    }

    const handleGammaCollect = async (stake: any) => {
        setShowCollectModal(true);
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Claim ${type}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const toggleCollectModal = () => {
        setShowCollectModal(false)
    }

    const toggleSwapPopupModal = () => {
        // setModalData({})
        setShowSwapPopUp(false)
    }

    const closeSwapModal = () => {
        setShowSwapPopUp(false)
    }

    const showSwapPopup = async (data: any, swapAmount: any, sendToken?: any) => {
        
        let tokenNumber : any = data.poolId == 0 ? 2 : 0;
        setReceiveTokenNumber(tokenNumber)
        setTokenSwapAmount(swapAmount)
        if(sendToken){
            setSendToken(sendToken)
        }
        setShowSwapPopUp(true)
    }

    const updateTokenList = async () => {
        const userAddress = selector.ethData.address
        try {
          const res = await returnTokenList(userAddress)
          if (!!res && typeof res !== 'undefined') {
            setTokenDataList(res)
          }
        } catch (error) {
          console.log('error===>', error)
        }
    }

    const getPlatfromEarnings = () => {
        if(selector.ethData.address == undefined) {
            return '0.00';
        }
        return `${userPortfolio.v3_gammaRewards && +userPortfolio.v3_gammaRewards > 0 ? commaFy((userPortfolio.v3_gammaRewards * userPortfolio.gammaPrice).toFixed(2)) : '0.00'}`
    }

    return (
        <FarmPoolContext.Provider value={farmPoolData}>
        <MainContainer className="newPools">
            <PoolsContainer>
                <PoolsHeader className="poolHeader">
                    <div>
                        <TitleText>Pools</TitleText>
                        <TitleInfo className="title-info">Earn Trade Fees & GAMMA</TitleInfo>
                    </div>
                    <div className="gammaEarnings">   
                        
                        <GammaEarnedBox>
                            <FlexCont id="earnings" className="earningsCont">
                                <img src={GAMMAIcon} alt="" />
                                <div>
                                    <div className='on-mob'>
                                        <FlexCont >
                                            <CardText>Pool Earnings</CardText>
                                            <InfoTooltip infoId='claimAndStake' left={5} />
                                        </FlexCont>
                                    </div>

                                    <PoolEarningsClaimAndStake portfolioLoading={portfolioLoading} userPortfolio={userPortfolio} claim_type={'claim_stake'} />
                                    {portfolioLoading ? 
                                        <LoadingText4 /> :
                                        <EarnValText className="gammaInUSD" id='gammaInUSD'>
                                            ${getPlatfromEarnings()}
                                        </EarnValText>
                                    }
                                </div>
                            </FlexCont>
                            <CardButton2 className="on-web claim_stake" disabled={claimAndStakeLoading || portfolioLoading} onClick={() => handleGammaCollect(true)}>{claimAndStakeLoading || portfolioLoading ? <Spinner /> : 'Collect'}</CardButton2>
                            <CardButton2 className="on-mob claim_stake" disabled={claimAndStakeLoading || portfolioLoading} onClick={() => handleGammaCollect(true)}>{claimAndStakeLoading || portfolioLoading ? <Spinner /> : 'Collect'}</CardButton2>
                        </GammaEarnedBox>
                    </div>
                    
                </PoolsHeader>
                <HighestApyPools poolsNewData={poolList} showSwapPopup={showSwapPopup} gammabtcbPoolObject={poolList.active.find((poolObj: any) => poolObj.name.toLowerCase() == "gamma-btcb")} />
                <DataTitle poolRatio={poolRatio} className='pool-gamma-rewards-text'>{poolRatio > 5 ? "GAMMA Rewards ACTIVE." : "GAMMA Rewards INACTIVE."} <span>{/*poolRatio == 0 ? "" : poolRatio > 0 && poolRatio < 5 ? "You are 100% away from earning at least 95% APR on your $1K deposits in unlocked Pools" : "You are currently earning 137% APR on your $1K deposits in unlocked Pools"*/}</span></DataTitle>

                <FlexCont>
                    <DataInfoBoxNew className="DataInfoBox borrowCard">
                        <FlexCont className="suppliedAmountCard">
                            <FlexSBCont>
                                {suppliedOtherPoolsAmount == undefined || suppliedGammaBtcbAmount === undefined ? 
                                    <LoadingText1 width={10} /> 
                                    : 
                                    <SliderText className='borrow_value'>{"$" + suppliedGammaBtcbAmount.toFixed(2) 
                                        + "/$" + (suppliedOtherPoolsAmount).toFixed(2)}</SliderText>
                                }
                                
                            </FlexSBCont>
                            <SliderContainer borrowLimit={100} className={(+suppliedGammaBtcbAmount*100)/suppliedOtherPoolsAmount > 75 && (+suppliedGammaBtcbAmount*100)/suppliedOtherPoolsAmount < 90 ? 'SliderContainer pool OrangeLimit': (+suppliedGammaBtcbAmount*100)/suppliedOtherPoolsAmount > 90 ? 'SliderContainer pool RedLimit' : 'SliderContainer pool'}>
                                <Slider min={0} max={100} value={suppliedGammaBtcbAmount && suppliedOtherPoolsAmount ? (+suppliedGammaBtcbAmount*100)/suppliedOtherPoolsAmount : 0} tooltip={false} />
                            </SliderContainer>
                            <FlexSBCont>
                                <FlexCont>
                                    <SliderText light className='cursor_pointer borrow_usage borrowUsageText' onClick={() => showToolTipModal("Gamma Rewards","Holding 5% of your Planet pools portfolio in GAMMA-BTCB makes you eligible for GAMMA farm rewards. Beyond the minimum 5%, the more you hold the more you earn.")} >GAMMA-BTCB needed to earn GAMMA rewards</SliderText>
                                    <ToolTipIcon className="tooltip-web" src={QuestionIcon} alt="" left={10} onClick={() => showToolTipModal("Gamma Rewards", "Holding 5% of your Planet pools portfolio in GAMMA-BTCB makes you eligible for GAMMA farm rewards. Beyond the minimum 5%, the more you hold the more you earn.")} />
                                </FlexCont>
                                
                            </FlexSBCont>
                        </FlexCont>
                        
                        <CardButton2 className="zapin-gamma-btcb-btn" onClick={() => poolTableRef.current.handleGetLpTokenNew(poolData.v3Pools.active[0], 'transform', 0)}>Zap Into GAMMA-BTCB</CardButton2>
                    </DataInfoBoxNew>
                    
                </FlexCont>
                
                {
                    userPoolList.length > 0 &&
                    <DataCont>
                        {myPoolTableList.length > 0 && <DataTitle className='my-asset'>My Pools</DataTitle>}
                        {userPoolList.length > 0 &&
                        <PoolsTabCont>
                            <TableSearchCont className="pools">
                                <TableProtoCalCont>
                                    <Select options={protocolOptions} handleSelect={handleProtocolSelect} />
                                </TableProtoCalCont>
                            </TableSearchCont>
                        </PoolsTabCont>
                        }
                        {myPoolTableList.length > 0 && 
                            <PoolTable
                                isBorderDisable={true}
                                isUserPool={true}
                                activeTab={activeTab}
                                protocol={protocol}
                                poolLoading={poolLoading}
                                data={myPoolTableList}
                                portfolioLoading={portfolioLoading}
                                userPortfolio={userPortfolio}
                                pools="my_pools"
                                ref={poolTableRef}
                                gammabtcbPoolObject={poolList.active.find((poolObj: any) => poolObj.name.toLowerCase() == "gamma-btcb")}
                            />
                        }
                        
                    </DataCont>
                }            
                <DataCont>
                    <DataTitle>All Pools</DataTitle>
                    <PoolsTabCont>
                        <TabWrapper>
                            <TabContainer>
                                {
                                    latestPoolTabOptions.map((item: any, index: number) => (
                                        <TabBox key={index} onClick={() => activeTab === 'all' || activeTab !== item.value ? setActiveTab(item.value) : setActiveTab('all')} isActive={activeTab === item.value}>
                                            <TabText isActive={activeTab === item.value}>{item.label}</TabText>
                                        </TabBox>
                                    ))
                                }
                            </TabContainer>
                        </TabWrapper>
                        {!userInLP &&
                        <TableSearchCont className=''>
                            <TableProtoCalCont>
                                <Select options={protocolOptions} handleSelect={handleProtocolSelect} />
                            </TableProtoCalCont>
                        </TableSearchCont>
                            }
                    </PoolsTabCont>
                
                    <TableCont>
                        <PoolTable
                            activeTab={activeTab}
                            protocol={protocol}
                            poolLoading={poolLoading}
                            data={poolList}
                            portfolioLoading={portfolioLoading}
                            userPortfolio={userPortfolio}
                            pools="all"
                            ref={poolTableRef}
                            gammabtcbPoolObject={poolList.active.find((poolObj: any) => poolObj.name.toLowerCase() == "gamma-btcb")}
                        />
                    </TableCont>
                    <TooltipModal 
                        showToolTip={showToolTip}
                        toggleToolTipModal={setShowTooltipModal}
                        title={toolTipTitle}
                        message={toolTipMessage} 
                    />
                
                </DataCont>
            </PoolsContainer>
            <CustomActionModal show={showCollectModal} toggleModal={toggleCollectModal}>
                {showCollectModal && <CollectRewards pendingGamma={userPortfolio.v3_gammaRewards} gammaPrice={userPortfolio.gammaPrice} toggleCollectModal={toggleCollectModal}/>}
            </CustomActionModal>
            <CustomActionModal show={showSwapPopUp} toggleModal={toggleSwapPopupModal}>
                {showSwapPopUp && <SwapInPools tokenList={tokenDataList} updateTokenList={updateTokenList} initialTokenAdd={initialTokenAdd} receiveTokenNumber={receiveTokenNumber} closeSwapModal={closeSwapModal} tokenSwapAmount={tokenSwapAmount} toggleSwapPopupModal={toggleSwapPopupModal} sendTokenObject={sendToken} />}
            </CustomActionModal>
        </MainContainer>
        </FarmPoolContext.Provider>
    );
};

export default New_Pools
