import { useState, useEffect, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { FooterWrapper, FooterSpaceCont, FooterCont, FooterItemCont, TitleImgBox, PlanetTitle, FooterTitle, FooterLink, FooterBgImg, FooterBgImgCont } from './style'
import { aquaEmailLink, auditLink, docsLink, MediumLink, telegramLink, twitterLink, aquaContractLink, githubLink, discordLink, tokenomicsLink, aquaLink, gammaLink, burnAquaLink, claimGamaUrl, transLinkUrl } from 'service/global-constant'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import axios from 'axios';
import { Paths } from 'modules/app/components/routes/types'
import history from 'modules/app/components/history'
import { setCurrentPlanet } from 'logic/action/planet.action'
import { fetchPoolData, fetchUserPortfolio, fetchPlatformData, fetchInfVaultData, fetchInfVaultDataNew, fetchPoolDataNew } from 'logic/action/user.actions'
import { fetchLendingPlatformData, fetchUserAssetData, fetchBorrowLimit, fetchLendingPlatformData_v1, fetchUserAssetData_v1 } from 'logic/action/lending.action'
const Footer = (props: any) => {
    const selector = useSelector((state: any) => state)
    const [claimLoading, setClaimLoading] = useState(false)
    const dispatch = useDispatch()
    const [showAirDrop, setAirDropLink] = useState(false)
    const { currentPlanet, planetIcon } = props
    const { gPlatformData, userAssetData, userOldAssetData, borrowLimit, gPlatformLoadingNew, borrowLimitLoadingNew, addNullLoading } = selector.lending
    const { infVaultLoading, gammaInfData, aquaInfData } = selector.user
    useEffect(() => {
        const pathUrlArr: any = window.location.href.split('/')
        // console.log("path url ", pathUrlArr[pathUrlArr.length - 1])
        if (pathUrlArr[pathUrlArr.length - 1] === "lending") {
            setAirDropLink(true)
        }
      }, [props])
    
    const handleClaimGamma = async () => {
        const userAddress = selector.ethData.address
        if (!!userAddress) {
            try {
                setClaimLoading(true)
                const res = await axios.get(`${claimGamaUrl}${userAddress}`)
                if (!!res && typeof res !== 'undefined') {
        
                    if (res.data.success) {
                        handleNotification('success', "You have successfully claimed GAMMA!!", res.data.data.data.transactionHash)
                    }
                    else {
                        handleNotification('failed', res.data.data)
                    }
        
                }
        
            }
            catch (error) {
                handleNotification('failed', "unknown error please try again !!")
                setClaimLoading(false)
            }
            finally {
                setClaimLoading(false)
            }
        }
    
    }
    const handleNotification = (type: string, msg: string, hash?: any,) => {
        const linkUrl = !!hash ? `${transLinkUrl}${hash}` : ''
        let dataVal: any = ''
        if (type === 'success') {
          dataVal = {
            type: type,
            message: msg,
            link: linkUrl
          }
        }
        else {
          dataVal = {
            type: type,
            message: msg,
            link: ''
          }
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handlePathLink = (path: string) => {
        if (path === 'markets') {
            history.push(Paths.markets)
            dispatch(setCurrentPlanet('green'))
        }
        if (path === 'metrics') {
            history.push(Paths.metrics)
            dispatch(setCurrentPlanet('green'))
        }
        if (path === 'liquidations') {
            history.push(Paths.liquidations)
            dispatch(setCurrentPlanet('green'))
        }
        if (path === 'lending_v1') {
            if(gPlatformData.availableCredit === undefined){
                dispatch(fetchLendingPlatformData_v1(selector.ethData.address))
                dispatch(fetchUserAssetData_v1(selector.ethData.address))
                dispatch(fetchBorrowLimit(selector.ethData.address))
            }
            history.push(Paths.lending_v1)
            dispatch(setCurrentPlanet('green'))
        }
        if (path === 'markets_v1') {
            history.push(Paths.markets_v1)
            dispatch(setCurrentPlanet('green'))
        }
        if (path === 'vaults_v1') {
            // console.log(gammaInfData)
            if(gammaInfData.tvl === undefined){
                dispatch(fetchInfVaultData(selector.ethData.address))
            }
            history.push(Paths.vaults_v1)
            dispatch(setCurrentPlanet('blue'))
        }
        if (path === 'pools_v1') {
            history.push(Paths.pools_v1)
            dispatch(setCurrentPlanet('blue'))
        }
        if (path === 'compensation') {
            history.push(Paths.compensation)
            dispatch(setCurrentPlanet('blue'))
        }
    }

    return (
        <FooterWrapper className='footerContainer'>
            <FooterSpaceCont />
            <FooterCont>
                <FooterBgImgCont>
                    <FooterBgImg src={planetIcon} alt="" />
                </FooterBgImgCont>
                <FooterItemCont className='planetLogo'>
                    <TitleImgBox>
                        <img src={planetIcon} alt="" />
                        <PlanetTitle>Planet</PlanetTitle>
                    </TitleImgBox>
                </FooterItemCont>
                <FooterItemCont>
                    <FooterTitle currPlanet={currentPlanet}>ABOUT</FooterTitle>
                    <FooterLink href={aquaEmailLink} target="_blank">Email Us</FooterLink>
                    <FooterLink href={docsLink} target="_blank">Docs</FooterLink>
                    {/* <FooterLink href={faqLink} target="_blank">FAQ</FooterLink> */}
                </FooterItemCont>
                
                <FooterItemCont>
                    <FooterTitle currPlanet={currentPlanet}>COMMUNITY</FooterTitle>
                    <FooterLink href={MediumLink} target="_blank">Medium</FooterLink>
                    <FooterLink href={telegramLink} target="_blank">Telegram</FooterLink>
                    <FooterLink href={twitterLink} target="_blank">Twitter</FooterLink>
                    <FooterLink href={discordLink} target="_blank">Discord</FooterLink>
                </FooterItemCont>
                <FooterItemCont>
                    <FooterTitle currPlanet={currentPlanet}>PROTOCOL</FooterTitle>
                    <FooterLink href={aquaLink} target="_blank">AQUA</FooterLink>
                    <FooterLink href={gammaLink} target="_blank">GAMMA</FooterLink>
                    <FooterLink href={aquaContractLink} target="_blank">Contract Address</FooterLink>
                    <FooterLink href={burnAquaLink} target="_blank">Burn Address</FooterLink>
                    {/* {selector.ethData.address !== undefined && showAirDrop === true ? 
                    <FooterLink onClick={() => handleClaimGamma()} target="_blank">Claim Airdrop</FooterLink>
                    :
                    ""
                    } */}
                </FooterItemCont>
                <FooterItemCont>
                    <FooterTitle currPlanet={currentPlanet}>LEARN MORE</FooterTitle>
                    <FooterLink href={auditLink} target="_blank">Audit</FooterLink>
                    <FooterLink href={githubLink} target="_blank">Github</FooterLink>
                    <FooterLink href={tokenomicsLink} target="_blank">Tokenomics</FooterLink>
                </FooterItemCont>
                
                <FooterItemCont>
                    <FooterTitle currPlanet={currentPlanet}>ARCHIVE</FooterTitle>
                    <FooterLink onClick={() => handlePathLink('compensation')} >Compensation</FooterLink>
                    {/* <FooterLink onClick={() => handlePathLink('lending_v1')} >Lending V1</FooterLink>
                    <FooterLink onClick={() => handlePathLink('markets_v1')} >Markets V1</FooterLink> */}
                    {/* <FooterLink href={docsLink} target="_blank">Metrics</FooterLink> */}
                    <FooterLink onClick={() => handlePathLink('vaults_v1')}>Vaults V1</FooterLink>
                </FooterItemCont>
            </FooterCont>
            <FooterSpaceCont />
        </FooterWrapper>
    );
};

const mapStateToProps = (state: any) => ({
    currentPlanet: state.planet.currentPlanet,
    planetIcon: state.planet.planetIcon,
})

export default connect(mapStateToProps)(Footer);