import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { PlanetCard } from 'shared/styles/styled'

export const CompContainer = styled.div`
  position: relative;
  .title-info{
      margin-top: 10px;
      @media (min-width: ${screenSizes.mediaM}px) {
        margin-top: 20px;
      }
  }
`
export const MobileDiv = styled.span`
  display: none;
  @media (max-width: ${screenSizes.mediaM}px) {
    display: block;
  }
`;

export const WebDiv = styled.span`
  display: none;
  @media (min-width: 801px) {
    display: block;
  }
  &.leftHalf{
    float:left;
    width: 40%;
  }
  &.rightHalf{
    float:left;
    width: 60%;
    margin-left: 30px;
  }
`;

export const CompHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: ${screenSizes.mediaM}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`

export const LeftContainer = styled.div`
    float:left;
    width: 38%;
    &.mob{
      float:left;
      width: 100%;
    }
    @media (min-width: ${screenSizes.mediaM}px) and (max-width: ${screenSizes.mediaL}px){
      width: 42%;
    }
    @media (min-width: ${screenSizes.mediaL}px) and (max-width: ${screenSizes.mediaXL}px){
      width: 42%;
    }
`

export const RightContainer = styled.div`
    float:left;
    width: 58%;
    margin-left: 30px;
    &.mob{
      width: 100%;
      margin-left: 0px;
    }
    @media (min-width: ${screenSizes.mediaM}px) and (max-width: ${screenSizes.mediaL}px){
      width: 55%;
      margin-left: 20px;
    }
    @media (min-width: ${screenSizes.mediaL}px) and (max-width: ${screenSizes.mediaXL}px){
      width: 55%;
      margin-left: 20px;
    }
`

export const Container = styled.div`
  display: flex;
`