import { useState } from 'react'
import { FlexSBCont, FlexCont } from 'shared/styles/styled'
import { TransModalCont, TransCard, TransTabTxt, TransTxt, TransInoTxt } from 'shared/styles/highestApyStyle'
import SettingIcon from 'assets/icons/settings.png'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import CustomModal from 'shared/custom-modal'
import Settings from 'shared/settings'
import TransformAction from './oldTransformAction'
import ConvertLpAction from './oldConvertLpAction'


const PoolTCAction = (props: any) => {
    const [activeTab, setActiveTab] = useState('transform')
    const [transInfo, setTransInfo] = useState<any>({})
    const [conLpInfo, setConLpInfo] = useState<any>({})
    const [slippageVal, setSlippageVal] = useState(0.5)
    const [deadLine, setDeadLine] = useState(20)
    const [showSettings, setShowSettings] = useState(false)
    const { setShowTCAModal, transData } = props

    const handleSlippage = (newSlippageVal: number) => {
        setSlippageVal(newSlippageVal)
    }

    const handleTabSelect = (tabName: string) => {
        // console.log("transform data", transData)
        if (tabName !== activeTab) {
            setActiveTab(tabName)
            setSlippageVal(0.5)
            setDeadLine(20)
            setTransInfo({})
            setConLpInfo({})
        }
    }

    return (
        <TransModalCont>
            <TransCard>
                <FlexSBCont>
                    <FlexCont>
                        <TransTabTxt isActive={activeTab === 'transform'} onClick={() => handleTabSelect('transform')}>Transform</TransTabTxt>
                        <TransTabTxt isActive={activeTab === 'convertLp'} onClick={() => handleTabSelect('convertLp')}>Convert LP</TransTabTxt>
                    </FlexCont>
                    <FlexCont>
                        <img className='trans-icon' src={SettingIcon} alt="" onClick={() => setShowSettings(true)} />
                        <img className='trans-icon' src={CrossIcon} alt="" onClick={() => setShowTCAModal(false)} />
                    </FlexCont>
                </FlexSBCont>
                <TransTxt light className="trans-info">{activeTab === 'transform' ? 'One-click swap from crypto to LP' : 'One-click convert your LP token'}</TransTxt>
                {activeTab === 'transform' ?
                    <TransformAction data={transData} transInfo={transInfo} setTransInfo={setTransInfo} slippageVal={slippageVal} /> :
                    <ConvertLpAction data={transData} conLpInfo={conLpInfo} setConLpInfo={setConLpInfo} slippageVal={slippageVal} />
                }
            </TransCard>
            <TransCard>
                {activeTab === 'transform' &&
                    <FlexSBCont className='info-space'>
                        <TransInoTxt light>Slippage Tolerance</TransInoTxt>
                        <TransInoTxt>{slippageVal}%</TransInoTxt>
                    </FlexSBCont>
                }
                {activeTab === 'convertLp' &&
                    <FlexSBCont className='info-space'>
                        <TransInoTxt light>Price Impact</TransInoTxt>
                        <TransInoTxt>{!!conLpInfo && conLpInfo.priceImpact ? parseFloat(conLpInfo.priceImpact).toFixed(2) : 0}%</TransInoTxt>
                    </FlexSBCont>
                }
                <FlexSBCont className={activeTab === 'transform' ? 'info-space' : ''}>
                    <TransInoTxt light>Minimum Received</TransInoTxt>
                    {activeTab === 'transform' ?
                        <TransInoTxt>{!!transInfo && transInfo.minLiquidity ? `${parseFloat(transInfo.minLiquidity).toFixed(6)} LP` : '0 LP'}</TransInoTxt> :
                        <TransInoTxt>{!!conLpInfo && conLpInfo.minReceived ? `${parseFloat(conLpInfo.minReceived).toFixed(6)}` : 0}</TransInoTxt>
                    }
                </FlexSBCont>
                {activeTab === 'transform' &&
                    <FlexSBCont>
                        <TransInoTxt light>LP Share</TransInoTxt>
                        {activeTab === 'transform' ?
                            <TransInoTxt>{!!transInfo && transInfo.share ? `${transInfo.share < 0.01 ? '<0.01' : parseFloat(transInfo.share).toFixed(6)}%` : '0%'}</TransInoTxt> :
                            <TransInoTxt>0</TransInoTxt>
                        }
                    </FlexSBCont>
                }
            </TransCard>
            <CustomModal show={showSettings} toggleModal={setShowSettings}>
                {showSettings && (
                    <Settings
                        setShowSettings={setShowSettings}
                        slippageVal={slippageVal}
                        setSlippageVal={setSlippageVal}
                        handleSlippage={handleSlippage}
                        deadLine={deadLine}
                        setDeadLine={setDeadLine}
                    />
                )}
            </CustomModal>
        </TransModalCont>
    );
};

export default PoolTCAction