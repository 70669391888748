import { useState, useEffect } from 'react'
import { CardSubText } from 'shared/styles/globalText'
import {   GTokenWeb, GTokenMob, GCardText, GCardNumTxt, GLoadingText3 } from './style'
import TvlIcon from 'assets/icons/tvl-icon.svg'
import { commaFy, checkZeroVal, convertToKAndM } from 'service/globalFunctions'
let timerForGammaBalance = setInterval(function () { }, 0)
const GammaBalance = (props: any) => {
    const { gammaInfData, infVaultLoading } = props
    const [gammaBalance, setgammaBalance] = useState('')

    useEffect(() => {
        if (timerForGammaBalance) {
            clearInterval(timerForGammaBalance); 
        }
        if(gammaInfData.deposited_bal)
        {
            startTimerTripping();
        }
        return () => clearInterval(timerForGammaBalance);
    }, [gammaInfData, infVaultLoading])

    const startTimerTripping = () => {
        if(gammaInfData.deposited_bal)
        {
            setgammaBalance(gammaInfData.deposited_bal)
            clearInterval(timerForGammaBalance)
            let apy = gammaInfData.apy;
            let m: any = 365
            let a: any = apy / 100 + 1
            let apr = (Math.pow(a, 1 / m) - 1) * m
            let trippingGammaBalance:any = parseFloat(gammaInfData.deposited_bal) * (apr ) / (365 * 86400)

            // console.log('trippingGammaBalance', trippingGammaBalance);
            let now = new Date();
            let timeWhenStarted = now.getTime();
            timerForGammaBalance = setInterval(function () {
                now = new Date();
                let newGammaBalance =  gammaInfData.deposited_bal + (( now.getTime() - timeWhenStarted ) * parseFloat(trippingGammaBalance) / 1000);
                setgammaBalance(newGammaBalance)
            }, 1000)
        }
    }
    return (
        <div>
            <GCardText>GAMMA Balance</GCardText>
            {infVaultLoading ? <GLoadingText3 /> :
                <GCardNumTxt isValZero={checkZeroVal(gammaInfData.deposited_bal)}>
                    {!!gammaBalance && gammaBalance ? commaFy(parseFloat(gammaBalance).toFixed(2)) : '0.00'}

                    {/* <GCardNumTxt isValZero={checkZeroVal(gammaInfData.deposited_bal)}>{!!gammaInfData && gammaInfData.deposited_bal ? commaFy(convertToKAndM(gammaInfData.deposited_bal, true)) : '0.0000'}</GCardNumTxt> */}
                </GCardNumTxt>
            }
        </div>   
    );
};

export default GammaBalance;