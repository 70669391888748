import { CardCont, FlexSBCont, FlexCont, TitleText, PrimaryBtn, TwoImgCont } from '../style'
import { ConfirmLDCont, PoolTokenText, CnfLdInfoCont, CnfLdInfoText } from './style'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import whiteCrossIcon from 'assets/icons/white-cross.svg'
import stableSwap3GIcon from 'assets/icons/3g-icon.png'
import stableSwapIcon from 'assets/icons/stable-swap-icon.png'
import { useEffect } from 'react'
import { Table, TableHeader, TableBody, MobTableBody, TableRowCont, TableRow, CellCont, HeadTextCont, HeadTextData, HeadText, CellText, CellSmText, ArrowIconCont, MobArrowIconCont, LoadingText1 } from 'shared/styles/planetTableStyle'

const ConfirmLiquidity = (props: any) => {
  const { setShowCnfModal, confirmLdData, handleAddLiquidity, crossIconColor, stableSwap, stableSwapObject } = props

  const handleConfirm = () => {
    handleAddLiquidity()
    setShowCnfModal(false)
  }

  const getTokenRate = () => {
    let rate: any = '0.00'
    if (parseFloat(confirmLdData.firstAmount) > 0 && parseFloat(confirmLdData.secondAmount) > 0) {
      rate = parseFloat(confirmLdData.secondAmount) / parseFloat(confirmLdData.firstAmount)
    }
    return parseFloat(rate).toFixed(4)
  }

  const depositedBalanceDetails = () => {
    // console.log(confirmLdData)
    return confirmLdData.map((item: any) => {
      item.inputValue = item.inputValue !== "" ? item.inputValue : "0.00";
      return (
        <TableRow className="confirmLD">
          <CellCont>
            <CnfLdInfoText light>{`${item.name} Deposited`}</CnfLdInfoText>
          </CellCont>
          <CellCont className="confirmLDImg">
            <img src={item.icon} alt="" />
          </CellCont>
          <CellCont className="confirmLDText">
            <CnfLdInfoText>{parseFloat(item.inputValue).toFixed(2)}</CnfLdInfoText>
          </CellCont>
        </TableRow>
        
      )
    })
  }

  {/* <FlexSBCont className="cnf-info-icon">
          <CnfLdInfoText light>{`${item.name} Deposited`}</CnfLdInfoText>
          <FlexCont>
            <img src={item.icon} alt="" />
          </FlexCont>
          <FlexCont>
            
            <CnfLdInfoText>{parseFloat(item.inputValue).toFixed(2)}</CnfLdInfoText>
          </FlexCont>
        </FlexSBCont> */}

  const stablePoolShare = () => {
    let stablePoolShareVal = +stableSwapObject.minimum_received/(+stableSwapObject.minimum_received + +stableSwapObject.tvl);
    return (
      <CnfLdInfoText>{`${stablePoolShareVal ? parseFloat(stablePoolShareVal.toString()).toFixed(4) : '0.0000'}%`}</CnfLdInfoText>
    )
  }

  return (
    <CardCont>
      <ConfirmLDCont>
        <FlexSBCont>
          <TitleText>You’ll Receive</TitleText>
          <img className="close-ld" src={crossIconColor !== undefined ? whiteCrossIcon : CrossIcon} alt="" onClick={() => setShowCnfModal(false)} />
        </FlexSBCont>
        {stableSwap !== undefined ?
        <div>
        <FlexCont className="stableTokensCont">
          <TwoImgCont className="stable-img">
            <img src={stableSwapIcon} alt="" />
            
          </TwoImgCont>
          {/* <TitleText>{parseFloat(confirmLdData.receivedTokens) !== 0 ? parseFloat(confirmLdData.receivedTokens).toFixed(4) : ''}</TitleText> */}
        </FlexCont>
        <PoolTokenText>{`Stable Liquidity Pool tokens`}</PoolTokenText>
        <CnfLdInfoText className="output-info">
          Output is estimated, if the price changes by more than 1% your transaction will revert.
        </CnfLdInfoText>
        <CnfLdInfoCont>
          {depositedBalanceDetails()}
        </CnfLdInfoCont>
        {/* <CnfLdInfoCont>
          <FlexSBCont>
            <CnfLdInfoText light> Share Of Pool</CnfLdInfoText>
            <CnfLdInfoText>{stablePoolShare()}</CnfLdInfoText>
          </FlexSBCont>
        </CnfLdInfoCont> */}
        <CnfLdInfoCont className='receivedTokens'>
          {/* <FlexSBCont className="cnf-info-icon-rx">
            <CnfLdInfoText light>Minimum Received 3G</CnfLdInfoText>
            <FlexCont>
              <img src={stableSwapIcon} alt="" />
            </FlexCont>
            <FlexCont>
              
              <CnfLdInfoText>{stableSwapObject.minimum_received}</CnfLdInfoText>
            </FlexCont>
          </FlexSBCont> */}
          <TableRow className="confirmLD">
            <CellCont><CnfLdInfoText light>Minimum Received 3G</CnfLdInfoText></CellCont>
            <CellCont className="confirmLDImg"> <img src={stableSwapIcon} alt="" /></CellCont>
            <CellCont className="confirmLDText"><CnfLdInfoText>{stableSwapObject.minimum_received}</CnfLdInfoText></CellCont>
          </TableRow>
        </CnfLdInfoCont>
        <PrimaryBtn onClick={() => handleConfirm()}>Confirm</PrimaryBtn>  
        </div>
        :
        <div>
        <FlexCont className="cnf-img-cont">
          <TwoImgCont className="two-img">
            <img src={confirmLdData.firstToken.icon} alt="" />
            <img className="img2" src={confirmLdData.secondToken.icon} alt="" />
          </TwoImgCont>
          <TitleText>{parseFloat(confirmLdData.receivedTokens) !== 0 ? parseFloat(confirmLdData.receivedTokens).toFixed(4) : ''}</TitleText>
        </FlexCont>
        <PoolTokenText>{`${confirmLdData.firstToken.name}/${confirmLdData.secondToken.name} Pool tokens`}</PoolTokenText>
        <CnfLdInfoText className="output-info">
          Output is estimated, if the price changes by more than 1% your transaction will revert.
        </CnfLdInfoText>
        <CnfLdInfoCont>
          <FlexSBCont className="cnf-info-icon">
            <CnfLdInfoText light>{`${confirmLdData.firstToken.name} Deposited`}</CnfLdInfoText>
            <FlexCont>
              <img src={confirmLdData.firstToken.icon} alt="" />
              <CnfLdInfoText>{parseFloat(confirmLdData.firstAmount).toFixed(4)}</CnfLdInfoText>
            </FlexCont>
          </FlexSBCont>
          <FlexSBCont className="cnf-info-icon">
            <CnfLdInfoText light>{`${confirmLdData.secondToken.name} Deposited`}</CnfLdInfoText>
            <FlexCont>
              <img src={confirmLdData.secondToken.icon} alt="" />
              <CnfLdInfoText>{parseFloat(confirmLdData.secondAmount).toFixed(4)}</CnfLdInfoText>
            </FlexCont>
          </FlexSBCont>
          <FlexSBCont className="cnf-info">
            <CnfLdInfoText light> Current Rate</CnfLdInfoText>
            <CnfLdInfoText>{`1 ${confirmLdData.firstToken.name} = ${getTokenRate()} ${confirmLdData.secondToken.name}`}</CnfLdInfoText>
          </FlexSBCont>
            <FlexSBCont>
              <CnfLdInfoText light> Share Of Pool</CnfLdInfoText>
              <CnfLdInfoText>{`${confirmLdData.poolShare ? parseFloat(confirmLdData.poolShare).toFixed(4) : '0.0000'}%`}</CnfLdInfoText>
            </FlexSBCont>
          <PrimaryBtn onClick={() => handleConfirm()}>Confirm</PrimaryBtn>
        </CnfLdInfoCont>
        </div>
        }
        
        
      </ConfirmLDCont>
    </CardCont>
  )
}

export default ConfirmLiquidity
