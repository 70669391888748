import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { CardBText } from 'shared/styles/globalText'
import { PlanetCard } from 'shared/styles/styled'
import { shimmer } from 'shared/styles/styled'
import { Button } from 'shared/button'

export const LoadingText = styled.div`
  animation: ${shimmer} 3s;
  animation-iteration-count: infinite;
  background: transparent linear-gradient(270deg, #3037da 0%, #55b7ff 100%) 0% 0% ;
  background-size: 1000px 100%;
  border-radius: 10px;
`
export const LoadingText1 = styled(LoadingText)`
  width: 50%;
  height: 16px;
  margin-top: 10px;
  @media (min-width: ${screenSizes.mediaXS}px) {
    height: 20px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 18px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    height: 24px;
  }
`
export const LoadingText2 = styled(LoadingText1)`
  width: 100%;
  height: 16px;
  margin-top: 10px;
  &.boostLoading{
    width: 100px;
    margin: auto;
  }
  @media (min-width: ${screenSizes.mediaXS}px) {
    height: 20px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 18px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    height: 24px;
  }
`
export const Card = styled(PlanetCard)`
  width: 100%;
  padding: 20px;
  overflow-x: hidden !important;
  @media (min-width: ${screenSizes.mediaM}px) {
    max-width: fit-content;
    padding: 30px 20px 24px 20px;
    &.on-mob{
      display: none !important;
    }
  }
  &.tokenCard{
    margin-top: 2.083vw;
    max-width: inherit;
    padding: 1.5625vw 2.083vw;
    @media (max-width: 799px) {
        padding: 20px;
        display: block;
    }
  }
`
export const CardNumText = styled(CardBText)`
  margin-top: 10px;
  &.cardValue{
    @media (min-width: ${screenSizes.mediaM}px) {
        font-size: 1.25vw;
        line-height: 1.51vw;
    }
    @media(max-width: 799px){
      margin-top: 0;
    }
  }
  &.cardSubText{
    @media (min-width: ${screenSizes.mediaM}px) {
        font-size: 1.25vw;
        line-height: 1.51vw;
        margin-top: 0.52vw;
    }
  }
`
export const LevelBox = styled.div`
  padding: 3px 10px;
  margin-left: 7px;
  background: rgba(255,255,255,0.1);
  margin-top: 10px;
  border-radius: 11px;
  p{
    font-size: 12px;
    font-family: MontserratSemiBold;
    color: ${colors.white};
    margin: 0;
  }
`

export const BaseContainer = styled.div`
  position: relative;
  .title-info{
      margin-top: 10px;
      @media (min-width: ${screenSizes.mediaM}px) {
        margin-top: 20px;
      }
  }
  .val-space{
      margin-top: 10px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    &.on-mob{
      margin-top: 10px;
      display: none !important;
    }
  }
`
export const BaseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &.liquidStakeHead{
    height: 5.26vw;
    @media (max-width: 799px) {
        height: auto;
    }
  }
`
export const TvlInfoBox = styled(PlanetCard)`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  width: 280px;
  max-width: 280px;
  height: 120px;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    width: 300px;
    max-width: 300px;
    padding: 20px 20px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    width: 347px;
    max-width: 347px;
    padding: 20px 30px;
  }
  img{
      width: 60px;
      height: 60px;
      margin-right: 20px;
      @media (min-width: ${screenSizes.mediaXLL}px) {
        width: 70px;
        height: 70px;
      }
      @media (min-width: ${screenSizes.mediaXXL}px) {
        width: 80px;
        height: 80px;
      }
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    width: 100%;
    max-width: 100%;
    padding: 20px 20px;
    margin-top: 15px;
  }
`
export const UserInfoCont = styled.div`
  display: grid;
  grid-template-columns: repeat(1,1fr);
  grid-gap: 15px;
  margin-top: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 30px;
    &.boostCal{
      grid-template-columns: 1fr 1.437fr;
      grid-gap: 2.083vw;
      margin-top: 2.552;
    }
  }
`
export const OverViewInfo = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
  text-align: left;
  overflow-x: hidden!important;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    grid-gap: 10px;
    grid-template-columns: 1.2fr 1fr;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    grid-gap: 20px;
  }
  &.overviewCont{
    margin:0;
  }
  &.overviewCont > div {
    overflow: hidden;
  }
  &.cardRow{
    margin-top: 1.5625vw;
  }
`
export const BalanceWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  align-items: center;
  .bal-text {
    font-size: 14px;
    line-height: 18px;
    @media (max-width: 799px) {

    }
  }
`
export const BalanceCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img{
    width: 70px;
    height: 70px;
    margin-bottom:10px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
       width: 80px;
       height: 80px;
      }
    @media (min-width: ${screenSizes.mediaXXL}px) {
       width: 100px;
       height: 100px;
      }
  }
  .buy-btn{
    height: 39px !important;
    max-width: 130px;
    border-radius: 20px !important;
    font-size: 15px !important;
    margin-top: 17px;
  }
`
export const BalanceText = styled(CardBText)`
  margin: 5px 0 0 0;
  span{
    font-size: 16px;
    line-height: 19px;
    opacity: 0.4;
  }
`
/*  ================== User Mobile balance =============== */

export const MobBalWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 15px;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaM}px) {
    display: none;
  }
`
export const MobBalCard = styled(PlanetCard)`
  padding: 20px;
  .total-token{
    margin: 10px 0;
  }
`
export const MobBuyAssetCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  img{
    width: 44px;
    height: 44px;
  }
  button{
    height: 35px;
    max-width: 54px;
    font-size: 12px;
    padding: 10px 15px;
    margin-top: 0 !important;
    box-sizing: border-box;
  }
`
export const MobBalText = styled.p<any>`
  font-size: 18px;
  line-height: 22px;
  color: ${(props: any) => props.tokenType === 'aqua' ? colors.lightBlue : props.tokenType === 'gamma' ? colors.lightGreen : colors.white};
  font-family: MontserratBold;
  margin: 0;
`

export const InputBox = styled.div<any>`
  padding: 20px;
  border: ${(props: any) => (+props.sendAmount > 0 ? '2px solid #55B7FF' : '1px solid `${colors.borderColor}`' )};
  background: ${colors.inputBg};
  border-radius: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 24px 30px;
  }
  &.swapBox{
    border: 1px solid #FFFFFF26;
    border: ${(props: any) => (+props.sendAmount > 0 ? '2px solid #55B7FF' : '1px solid `${colors.borderColor}`' )};
  }
  &.inputToken {
    margin-top: 40px;
    @media (min-width: ${screenSizes.mediaM}px) {
      padding: 1.25vw 1.5625vw;
      border: 1px solid #FFFFFF26;
      margin-top: 2.083vw;
    }

    @media (max-width: 799px) {
      padding: 20px 20px;
      border: 1px solid #FFFFFF26;
      margin-top: 20px;
    }
  }
  &.gammaInputToken {
    @media (min-width: ${screenSizes.mediaM}px) {
      padding: 1.25vw 1.5625vw;
      border: 1px solid #FFFFFF26;
    }
    @media (max-width: 799px) {
      padding: 20px 20px;
      border: 1px solid #FFFFFF26;
      margin-top: 0px;
    }
  }
`

export const FlexSBCont: any = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div.tokenIcon{
    display: flex;
  }
  div.tokenIconRev{
    display: inline-flex;
  }
  &.stableTokensContainer{
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    padding: 14px 20px 14px 30px;
    width: 60%;
    background: #080808;
    max-width: 303px;
    @media (max-width: 799px) {
      width: 50%;
      padding: 7px 10px 7px 15px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 50%;
    }
  }
  &.liquidStakeCont{
    margin-top: 2.76vw;
    width: 69.16vw;
    @media (max-width: 799px) {
        width: 100%;
        margin-top: 20px;
        display: block;
    }
  }

  &.liquidStakeLeftCont{
    height: 48.75vw;
    width: 31.197vw;
    display: block;
    @media (max-width: 799px) {
        width: 100%;
        display: block;
        height: auto;
    }
  }

  &.liquidStakeRightCont{
    height: 48.75vw;
    width: 31.09vw;
    display: block;
    @media (max-width: 799px) {
        width: 100%;
        display: block;
        margin-top: 40px;
        height: auto;
    }
  }

  &.liquidStakeRightContWeb{
    height: 48.75vw;
    width: 31.09vw;
    display: block;
    @media (max-width: 799px) {
      display: none;
    }
  }

  &.protocolImgCont{
    margin-top: 2.916vw;
    align-self: baseline;
  }

  img.protocolImg{
    height: 5.208vw;
    width: 5.208vw;
    @media (max-width: 799px) {
        height: 50px;
        width: 50px;
        padding-top: 10px;
    }
  }

  &.protocolCont{
    display: block;
    margin-top: 1.5625vw;
    margin-left: 1.04vw;
    @media (max-width: 799px) {
        margin-top: 10px;
    }
  }
  &.securityInfoCont{
    margin-top: 2.08vw;
    display: block;
    @media (max-width: 799px) {
        margin-top: 20px;
    }
  }
  &.stakingCont{
    display: block;
  }

  &.stake-input-card{
    margin-top: 1.04vw;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000033;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    padding: 1.5625vw;
    display: block;
    @media (max-width: 799px) {
      margin-top: 12px;
      padding: 20px;
    }
  }
  &.staking-info{
    margin-top: 1.04vw;
    @media (max-width: 799px) {
        margin-top: 10px;
    }
  }

  &.inputText{
    margin-top: 0.9895vw;
    @media (max-width: 799px) {
        margin-top: 10px;
    }

  }
  &.staking-details{
    margin-top: 1.5625vw;
    @media (max-width: 799px) {
        margin-top: 20px;
    }
  }
  &.on-web{
    @media (max-width: 799px) {
        display: none;
    }
    @media (min-width: ${screenSizes.mediaM}px) {
        display: flex;
    }
  }
  &.on-mob{
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none;
    }
    @media (max-width: 799px) {
        display: flex;
    }
  }

  &.stakingDetailsMob{
    @media (max-width: 799px) {
        display: block;
        padding: 20px 20px 20px 10px;
        margin-top: 20px;
    }
  }

  &.faqQuestion{
    margin-top: 1.04vw;
    color: #FFFFFF;
    cursor: pointer;
    @media (max-width: 799px) {
        margin-top: 20px;
    }
  }
  &.faqAnswer{
    margin-top: 0.52vw;
    display: ${(props: any) => (props.data == true ? 'flex' : 'none')};
    @media (max-width: 799px) {
        margin-top: 10px;
    }
  }
  img.faq-icon{
    height: 0.57vw;
    width: 0.8854vw;
    @media (max-width: 799px) {
        height: 11px;
        width: 17px;
    }
  }

  &.allowanceCheck{
    padding: 1.5625vw 3.125vw 1.5625vw 1.5625vw;
    border: 1px solid #FFFFFF26;
    @media (max-width: 799px) {
      padding: 20px;
      border: none;
    }
  }

  img.trans-icon{
    height: 37px;
    width: 37px;
  }
  &.titleCont{
    padding: 1.5625vw;
    @media (max-width: 799px) {
      padding: 20px;
      border-bottom: 1px solid #FFFFFF26;
    }
  }
`

export const InfoLDText = styled.p<any>`
  display: flex;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: 0.4;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 24px;
  }
  &.stableTokenBalance{
    white-space: nowrap;
  }
  &.to-stake{
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 0.833vw;
      line-height: 0.9895vw;
    }
  }
  &.to-stake-white{
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 0.833vw;
      line-height: 0.9895vw;
      color: #FFFFFF;
      opacity:1;
    }
  }
  &.protocolText{
    font-size: 1.04vw;
    line-height: 1.25vw;
    color: #FFFFFF;
    opacity: 1;
    font-family: MontserratBold;
    @media (max-width: 799px) {
        font-size: 18px;
        line-height: 22px;
    }

  }
  &.protocolName{
    font-family: MontserratBold;
    font-size: 0.9375vw;
    line-height: 1.146px;
    color: ${(props: any) => (props.data == true ? '#FFFFFF' : '#FFFFFF64')};
    opacity: 1;
    @media (max-width: 799px) {
        font-size: 14px;
        line-height: 18px;
    }
  }

  &.prototcolInfo{
    font-size: 1.04vw;
    line-height: 1.25vw;
    color: #FFFFFF64;
    opacity: 1;
    margin-top: 1.04vw;
    display: block;
    @media (max-width: 799px) {
        font-size: 18px;
        line-height: 22px;
        margin-top: 10px;
    }
  }
  &.prototcolInfo span{
    font-size: 1.04vw;
    line-height: 1.25vw;
    color: #FFFFFF;
    opacity: 1;
    @media (max-width: 799px) {
        font-size: 14px;
        line-height: 18px;
    }
  }

  &.faq{
    color: #FFFFFF !important;
    opacity: 1 !important;
  }

  &.faqQuestion{
    font-size: 0.833vw;
    line-height: 0.9895vw;
    @media (max-width: 799px) {
        font-size: 16px;
        line-height: 19px;
    }
  }

  &.faqMargin{
    @media (max-width: 799px) {
      margin-top: 20px;
    }
  }

`

export const TokenValInput = styled.input<any>`
  width: 100%;
  background: transparent;
  color: ${colors.white};
  font-size: 16px;
  line-height: 20px;
  font-family: MontserratBold;
  border: none;
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 30px;
  }
  &.stableTokenInput{
    width: 70%;
    background: #080808;
    font-size: 20px;
    line-height: 24px;
    @media (max-width: 799px) {
      font-size: 16px;
      line-height: 19px;
      width: 55%s;
    }
  }
  &.receiveInput{
    color: ${colors.white};
    opacity: ${(props: any) => (+props.data > 0 ? 1 : 0.4)};
  }
  &.boost-input{
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 1.25vw;
      line-height: 1.51vw;
    }
  }
`

export const TokenSelectCont = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-left: 20px;
  }
  img.token-icon{
    height: 37px;
    width: 37px;
    margin-right: 13px;
  }
  img.arrow-down {
    margin-left: 10px;
  }
  img.gamma-token-icon{
    height: 37px;
    width: 37px;
    margin-right: 5px;
    @media (min-width: ${screenSizes.mediaM}px) {
      height: 1.927vw;
      width: 1.927vw;
      margin-right: 0.677vw;
    }
  }
  img.boost-token-icon{
    @media (min-width: ${screenSizes.mediaM}px) {
      height: 1.927vw;
      width: 1.927vw;
      margin-right: 0.677vw;
    }
  }
`

export const TokenName = styled.h1`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 25px;
  }
  &.tokenName{
    line-height: 30px;
    @media (max-width:799px) {
      line-height: 24px;
    }
  }
  &.stableTokenName{
    line-height: 19px;
    font-size: 16px;
    @media (max-width:799px) {
      line-height: 16px;
      font-size: 14px;
    }
  }
  
  &.tokenBalance{
    font-size: 10px;
    color: #FFF;
    opacity: 0.4;
    font-family: MontserratSemiBold;
    @media (max-width:799px) {
      font-size: 9px;
      line-height: 11px;
    }
  }
  &.stableTokenRemoveTxt{
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 30px;
    font-family: MontserratSemiBold;
    @media (max-width:799px) {
      margin-top: 20px;
    }
  }
  &.removeStableTokenName{
    font-family: MontserratSemiBold;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.4;
    @media (max-width:799px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  &.selectTokenText{
    opacity: 1;
    font-family: MontserratBold;
  }
  &.web {
    display: block;
    @media (max-width:799px) {
      display: none;
    }
  }
  &.mob {
    display: none;
    @media (max-width:799px) {
      display: block;
    }
  }
  &.boost-token-name{
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 1.04vw;
      line-height: 1.25vw;
    }
  }
  &.stakingTokenName{
    font-size: 1.04vw;
    line-height: 1.25vw;
    @media (max-width:799px) {
        font-size: 14px;
        line-height: 18px;
    }
  }
`

export const SelectCurrTxt = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  width: 132px;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 25px;
    width: 164px;
  }
`

export const BoostDetailsCard = styled.p`
  display: grid;
  grid-template-columns: repeat(1,1fr);
  grid-gap: 40px;
  margin-top: 20px;
  &.boostdetails{
    align: left;
    display: flex;
    @media (max-width: 799px) {
      display: grid;
      grid-gap: 20px;
    }
  }
  &.boost-values{
    grid-gap: 10px;
    margin-top: 0px;
    @media (max-width: 799px) {
      margin: 10px 0 0 0;
    }
  }
  
`
export const UserLDList = styled.div`
  &.boostCal{
    display: flex;
  }
`

export const CardText = styled.p<any>`
 font-size: 14px;
 line-height: 18px;
 color: ${colors.white};
 font-family: MontserratBold;
 opacity: 0.4;
 margin: 0;

 &.cardTitle{
    @media (min-width: ${screenSizes.mediaM}px) {
        font-size: 0.8333vw;
        line-height: 0.989vw;
        font-family: MontserratSemiBold;
    }
    @media (max-width: 799px) {
      margin-top: 10px;
    }
 }
 &.cardSubtext{
    @media (min-width: ${screenSizes.mediaM}px) {
        font-size: 0.8333vw;
        line-height: 0.989vw;
        font-family: MontserratSemiBold;
        margin-top: 0.625vw;
    }
 }

&.stakingText{
    @media (min-width: ${screenSizes.mediaM}px) {
        font-size: 0.9375vw;
        line-height: 1.146vw;
        padding: 0.52vw 1.51vw;
        background: ${(props: any) => (props.data == true ? `#172B3A 0% 0% no-repeat padding-box` : 'none')};
        border-radius: ${(props: any) => (props.data == true ? `100px` : '0px')};
        opacity: 1;
        color: ${(props: any) => (props.data == true ? '#55B7FF' : '#FFFFFF64')};
        cursor: pointer;
    }
    @media (max-width: 799px) {
        font-size: 13px;
        line-height: 16px;
        padding: 5px 15px;
        background: ${(props: any) => (props.data == true ? `#172B3A 0% 0% no-repeat padding-box` : 'none')};
        border-radius: ${(props: any) => (props.data == true ? `100px` : '0px')};
        opacity: 1;
        color: ${(props: any) => (props.data == true ? '#55B7FF' : '#FFFFFF64')}
    }
}
`

export const FlexCont = styled.div<any>`
  display: flex;
  align-items: center;

  &.protocolOption{
    @media (min-width: ${screenSizes.mediaM}px) {
      border: ${(props: any) => (props.data == true ? `1px solid #FFFFFF26` : 'none')};
      border-radius: ${(props: any) => (props.data == true ? `100px` : '0px')};
      opacity: 1;
      margin-top: 1.04vw;
      padding: ${(props: any) => (props.data == true ? `5px 1.5625vw` : '0 10px')};
    }
    @media (max-width: 799px) {
        border: ${(props: any) => (props.data == true ? `1px solid #FFFFFF26` : 'none')};
        border-radius: ${(props: any) => (props.data == true ? `100px` : '0px')};
        opacity: 1;
        margin-top: 10px;
        padding: ${(props: any) => (props.data == true ? `5px 10px` : '0 10px')};
    }
  }

  &.protocolCont{
    @media (min-width: ${screenSizes.mediaM}px) {
      padding-bottom: 1.822vw;
      border-bottom: 1px solid #FFFFFF26;
    }
    @media (max-width: 799px) {
        padding-bottom: 10px;
        border-bottom: 1px solid #FFFFFF26;
      }
  }

  img.protocolImg{

  }

  img.protocolIcon{
    height: 1.667vw;
    width: 1.667vw;
    @media (max-width: 799px) {
        height: 20px;
        width: 20px;
    }
  }

  &.securityInfoCont{
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-top: 2.08vw;
    }
  }

  &.stakingCont{
    display: flex;
    @media (max-width: 799px) {
      margin-top: 30px;
    }
  }

  img.bnb-token-icon{
    height: 1.5625vw;
    width: 1.5625vw;
    margin-right: 0.52vw;
    margin-left: 0.78vw;
    @media (max-width: 799px) {
        height: 20px;
        width: 20px;
        margin-right: 10px;
        margin-left: 10px;    
    }
  }

  &.stakingCard{
    margin-top: 1.51vw;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000033;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    padding: 1.5625vw;
    display: block;
    @media (max-width: 799px) {
        margin-top: 20px;
        padding: 20px;
    }
  }

  &.on-mob{
    display: block;
  }

`

export const TransMaxBox = styled.div`
  font-size: 12px;
  color: ${colors.lightBlue};
  font-family: MontserratBold;
  cursor: pointer;
  padding: 6px 12px;
  background: #55B7FF33;
  border-radius: 12px;
  box-sizing: border-box;
  margin-left: 3px;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 14px;
    padding: 10px 15px;
    margin-left: 10px;
    &.maxBox{
      margin-right: 10px;
    }
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    &.maxBox{
      margin-right: 7px;
    }
    
  }
  &.liquidStakingMax{
    @media (min-width: ${screenSizes.mediaM}px) {
        height: 1.5625vw;
        padding: 0.416vw 0.833vw;
        font-size: 0.572vw;
        line-height: 0.729vw;
        border-radius: 0.572vw;
    }
  }
`

export const TransButton = styled(Button)`
  height: 49px;
  box-sizing: border-box;
  font-size: 15px;
  margin-top: 30px;
  background: transparent linear-gradient(270deg, #3037da 0%, #55b7ff 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  transition: 0.5s;
  text-align: center;
  &:disabled {
    opacity: 0.2;
    background: transparent linear-gradient(270deg, #3037da 0%, #55b7ff 100%) 0% 0% no-repeat padding-box;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 60px;
    font-size: 20px;
    margin-top: 60px;
  }

  &.stakeButton{
    @media (min-width: ${screenSizes.mediaM}px) {
        margin-top: 1.04vw;
        background: transparent linear-gradient(270deg, #3037DA 0%, #55B7FF 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 20px #3037DA;
        border: 1px solid #FFFFFF26;
        border-radius: 30px;
        font-size: 1.04vw;
        line-height: 1.25vw;
        padding: 1.04vw 12.5vw;
        height: 3.125vw;
        cursor: pointer;
    }
  }
  &.withdrawButton{
    @media (min-width: ${screenSizes.mediaM}px) {
        background: transparent linear-gradient(270deg, #3037DA 0%, #55B7FF 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 20px #3037DA;
        border: 1px solid #FFFFFF26;
        border-radius: 30px;
        font-size: 0.677vw;
        line-height: 0.833vw;
        padding: 0.364vw 0.9375vw;
        margin-top: 0px;
        height: auto;
        width: auto;
        cursor: pointer;
    }
  }
  &.stakeButton:disabled {
    opacity: 0.4;
  }
`

export const TransInputCont = styled.div`
  padding: 20px;
  border: 1px solid ${colors.borderColor};
  background: ${colors.inputBg};
  border-radius: 20px;
  box-sizing: border-box;
  &.zapNStake{
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000033;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    width: 31.09vw;
    padding: 1.5625vw;
    @media (max-width: 799px) {
      width: 100%;
      height: auto;
      padding-bottom: 20px;
    }
  }
`

export const InputTitle = styled.h1<any>`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 24px;
  }
  &.sendText{
    opacity: 0.4;
  }
  &.confTitle{
    font-size: 1.5625vw;
    line-height: 1.927vw;
    @media (max-width: 799px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &.confText{
    font-size: 1.04vw;
    line-height: 1.25vw;
    @media (max-width: 799px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`