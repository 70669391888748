import { CardCont, FlexSBCont, FlexCont, TitleText, PrimaryBtn } from '../style'
import { ConfirmSwapCont, SwapTokenCont, SwapText, CnfTokenTxt } from './style'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import DownIcon from 'assets/icons/down-arrow-icon.png'
import { LIQUIDITY_PROVIDER_FEE } from 'modules/block-chain/Swap'
import whiteCrossIcon from 'assets/icons/white-cross.svg'
import { commaFy } from 'service/globalFunctions'

const ConfirmSwap = (props: any) => {
  const { sendToken, receiveToken, minReceive, priceImpact, setConfirmSwapModal, sendAmount, receiveAmount, swapConstData, crossIconColor, sendTokenPrice, ReceiveTokenPrice, handle1InchSwap } = props

  const handleConfirm = () => {
    handle1InchSwap()
  }
  return (
    <CardCont>
      <ConfirmSwapCont>
        <FlexSBCont>
          <TitleText>Confirm Swap</TitleText>
          <img className="close-confirm-swap" src={crossIconColor !== undefined ? whiteCrossIcon : CrossIcon} alt="" onClick={() => setConfirmSwapModal(false)} />
        </FlexSBCont>
        <SwapTokenCont>
          <FlexSBCont>
            <FlexCont>
              <img className="token-icon" src={sendToken.icon} alt="" />
              <CnfTokenTxt>{sendAmount}</CnfTokenTxt>
            </FlexCont>
            <CnfTokenTxt>{sendToken.name}</CnfTokenTxt>
          </FlexSBCont>
          <img className="down-icon" src={DownIcon} alt="" />
          <FlexSBCont>
            <FlexCont>
              <img className="token-icon" src={receiveToken.icon} alt="" />
              <CnfTokenTxt>{receiveAmount}</CnfTokenTxt>
            </FlexCont>
            <CnfTokenTxt>{receiveToken.name}</CnfTokenTxt>
          </FlexSBCont>
        </SwapTokenCont>
        <SwapText className="receive-aqua">
          You’ll receive at least <span>{`${commaFy(parseFloat(minReceive).toFixed(4))} ${receiveToken.name}`}</span> or your swap will not complete.
        </SwapText>
        <FlexSBCont className="confirm-info">
          <SwapText light>Ratio</SwapText>
          <SwapText>{`${commaFy((parseFloat(sendTokenPrice) / parseFloat(ReceiveTokenPrice)).toFixed(4))} ${receiveToken.name}/${sendToken.name}`}</SwapText>
        </FlexSBCont>
        <FlexSBCont className="confirm-info">
          <SwapText light>Minimum Received</SwapText>
          <SwapText>{commaFy(parseFloat(minReceive).toFixed(4))}</SwapText>
        </FlexSBCont>
        <FlexSBCont className="confirm-info">
          <SwapText light>Price Impact</SwapText>
          <SwapText className="price-impact">{`${priceImpact}%`}</SwapText>
        </FlexSBCont>
        <PrimaryBtn onClick={() => handleConfirm()}>Confirm Swap</PrimaryBtn>
      </ConfirmSwapCont>
    </CardCont>
  )
}

export default ConfirmSwap
