import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import { updateUserLendingData } from 'logic/action/lending.action'
import { MainContainer, FlexCont, CardButton, CardButton2 } from 'shared/styles/styled'
import { TitleText, TitleInfo, CardText, DataTitle } from 'shared/styles/globalText'
import { DataCont, TableSearchCont, TableProtoCalCont, LoadingText4, TableCont } from 'shared/styles/planetTableStyle'
import { CompContainer, CompHeader, MobileDiv, WebDiv, LeftContainer, RightContainer, Container } from './style'
import { commaFy } from 'service/globalFunctions'
import Spinner from 'shared/spinner'
import { transLinkUrl } from 'service/global-constant'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import InfoTooltip from 'shared/info-tool-tip'
import Select from 'shared/select'
import Overview from './components/Overview'
import CompExplain from './components/CompExplain';
import LendingFees from './components/LendingFees';

const Compensation = () => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    // const {  } = selector.user

    
    return (
        <MainContainer className="compensation">
            <CompContainer>
                <MobileDiv>
                    <CompHeader>
                        <div>
                            <TitleText>Compensation</TitleText>
                            <TitleInfo className="title-info">Helping planet users affected by the Terra shortfall</TitleInfo>
                        </div>
                        
                    </CompHeader>
                    <LeftContainer className='mob'>
                        <Overview />
                        {/* <CompExplain /> */}
                    </LeftContainer>
                    <RightContainer className='mob'>
                        <CompExplain />
                    </RightContainer>
                </MobileDiv>
                <WebDiv className="web">
                    <CompHeader>
                        <div>
                            <TitleText>Compensation</TitleText>
                            <TitleInfo className="title-info">Helping Planet users affected by the Terra shortfall</TitleInfo>
                        </div>
                        
                    </CompHeader>
                    
                    {/* <div>
                        <LeftContainer>
                            <Overview />
                        </LeftContainer>
                        <RightContainer>
                            <CompExplain />
                        </RightContainer>
                    </div> */}
                    <Container>
                        <Overview />
                        <CompExplain />
                    </Container>
                </WebDiv>
                

            </CompContainer>
        </MainContainer>
    );
};

export default Compensation
