import { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserLendingData } from 'logic/action/lending.action'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont } from 'shared/styles/styled'
import { ExpandedCell, ExpendCellTitle, CellBtnGroup, TableInputCont, TableInput, MaxTxt, MaxTxtZero, TableBtn, WithdrawBtn, TransformBtn, LoadingText3, DepositBtn } from 'shared/styles/planetTableStyle'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { commaFy, getNumFormat } from 'service/globalFunctions'
import { transLinkUrl, fraxTranLink, maticTransLink, dotTransLink, solTransLink, adaTransLink, lunaTransLink, austTransLink, avaxTransLink, atomTransLink, xrpTransLink, linkTransUrl, ftmTransLink, whustTransferLink } from 'service/global-constant'
import Spinner from 'shared/spinner'
import { isAllowanceApproved } from 'modules/block-chain/BlockChainMethods'
import { gBnbAddress, convertToWei, instType, selectInstance, MAX_INT } from 'modules/block-chain/lendingAbi'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { getUnderlyingDecimal } from 'modules/block-chain/LendingBase'

const VaultTransaction = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [depositAmount, setDepositAmount] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [depositLoading, setDepositLoading] = useState(false)
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [isAllowance, setIsAllowance] = useState(false)
    const { portfolioLoading } = selector.user
    const { data } = props

    useEffect(() => {
        const userAddress = selector.ethData.address
        const getIsAllowance = async () => {
            if (data.name === 'BNB' || data.name === 'WBNB') {
                setIsAllowance(true)
            }
            else {
                const wantAddress = data.address
                let farmAddress = ""
                try {
                    const isAllow = await isAllowanceApproved(userAddress, farmAddress, wantAddress)
                    setIsAllowance(isAllow)
                }
                catch (error) {
                    console.log("error=>", error)
                }
            }
        }
        if (!!data) {
            getIsAllowance()
        }

        // console.log("vault data", data)

    }, [selector.ethData.address, data])

    const handleDepositAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setDepositAmount(value)
        }
        if (!value) {
            setDepositAmount('')
        }
    }
    const handleWithdrawAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setWithdrawAmount(value)
        }
        if (!value) {
            setWithdrawAmount('')
        }
    }


    const handleNotification = (type: string, actionType: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const amt = actionType === 'Deposit' ? depositAmount : withdrawAmount
        const dataVal: any = {
            type: type,
            message: `${actionType}  ${parseFloat(amt).toFixed(4)} ${data.name}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleDepositAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(depositAmount) > 0) {
            // console.log("depositAmount", depositAmount)
            try {
                setDepositLoading(true)
                const amountInEth = depositAmount
                const userAddress = selector.ethData.address
                const gTokenAddress = data.address
                if (gTokenAddress.toLowerCase() === gBnbAddress.toLowerCase()) {
                    const inst: any = await selectInstance(instType.gBNB, gTokenAddress)
                    await inst.methods.mint().send({
                        from: userAddress,
                        value: convertToWei(amountInEth, 1e18)
                    })
                        .once('confirmation', function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            handleNotification(type, 'Deposit', receipt.transactionHash)
                            setDepositLoading(false)
                            dispatch(updateUserLendingData(selector.ethData.address))
                            dispatch(updateUserBalance(selector.ethData.address))
                        })
                        .on('error', function (error: any) {
                            handleNotification('failed', 'Deposit')
                            setDepositLoading(false)
                        })
                }
                else {
                    const inst: any = await selectInstance(instType.gToken, gTokenAddress)
                    const decimal = Math.pow(10, await getUnderlyingDecimal(gTokenAddress));
                    await inst.methods.mint(convertToWei(amountInEth, decimal)).send({
                        from: userAddress,
                    })
                        .once('confirmation', function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            handleNotification(type, 'Deposit', receipt.transactionHash)
                            setDepositLoading(false)
                            dispatch(updateUserLendingData(selector.ethData.address))
                            dispatch(updateUserBalance(selector.ethData.address))
                        })
                        .on('error', function (error: any) {
                            handleNotification('failed', 'Deposit')
                            setDepositLoading(false)
                        })
                }
            }
            catch (error) {
                console.log("error=>", error)
                setDepositLoading(false)
            }
            finally {
                setDepositLoading(false)
            }

        }
    }
    const handleWithdrawAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(withdrawAmount) > 0) {
            try {
                setWithdrawLoading(true)
                const amountInEth = withdrawAmount
                const userAddress = selector.ethData.address
                const gTokenAddress = data.address
                const inst: any = await selectInstance(instType.gToken, gTokenAddress)
                const decimal = Math.pow(10, await getUnderlyingDecimal(gTokenAddress));
                await inst.methods.redeemUnderlying(convertToWei(amountInEth, decimal)).send({
                    from: userAddress,
                })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        handleNotification(type, 'Withdraw', receipt.transactionHash)
                        setWithdrawLoading(false)
                        dispatch(updateUserLendingData(selector.ethData.address))
                        dispatch(updateUserBalance(selector.ethData.address))
                    })
                    .on('error', function (error: any) {
                        handleNotification('failed', 'Withdraw')
                        setWithdrawLoading(false)
                    })
            }
            catch (error) {
                console.log("error=>", error)
                setWithdrawLoading(false)
            }
            finally {
                setWithdrawLoading(false)
            }
        }
    }

    const approveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} approved` : `Failed to approve  ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }
    const handleApprove = async () => {
        try {
            setApproveLoading(true)
            const underlyingAddress = data.underlyingAddress
            const gTokenAddress = data.address
            const userAddress = selector.ethData.address
            // console.log(underlyingAddress, gTokenAddress, userAddress)
            const TOKEN_INSTANCE = await selectInstance(instType.PANCAKELP, underlyingAddress)
            await TOKEN_INSTANCE.methods
                .approve(gTokenAddress, MAX_INT)
                .send({ from: userAddress })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    approveNotification(type, receipt.transactionHash)
                    setApproveLoading(false)
                    setIsAllowance(true)
                })
                .on('error', function (error: any) {
                    approveNotification('failed')
                    setApproveLoading(false)
                })
        }
        catch (error) {
            console.log("error==>", error)
            setApproveLoading(false)
        }
        finally {
            setApproveLoading(false)
        }
    }

    const handleTransform = () => {
        history.push(`${Paths.swap}`, { tokenAdd: data.underlyingAddress })
    }

    const renderTokenTransform = () => {
        if (data.name === 'FRAX') {
            return <a href={fraxTranLink} target='_blank' rel="noreferrer"><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'MATIC') {
            return <a href={maticTransLink} target='_blank' rel="noreferrer"><TransformBtn >Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'DOT') {
            return <a href={dotTransLink} target='_blank' rel="noreferrer"><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'SOL') {
            return <a href={solTransLink} target='_blank' rel="noreferrer"><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'ADA') {
            return <a href={adaTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'LUNA') {
            return <a href={lunaTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'aUST') {
            return <a href={austTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'AVAX') {
            return <a href={avaxTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'ATOM') {
            return <a href={atomTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'XRP') {
            return <a href={xrpTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'LINK') {
            return <a href={linkTransUrl} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'FTM') {
            return <a href={ftmTransLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else if (data.name === 'whUST') {
            return <a href={whustTransferLink} target='_blank' rel="noreferrer" ><TransformBtn>Buy {data.name}</TransformBtn></a>
        }
        else {
            return <TransformBtn onClick={() => handleTransform()}>Buy {data.name}</TransformBtn>
        }
    }

    return (
        <Fragment>
            <ExpandedCell className="item1">
                <FlexSBCont>
                    <ExpendCellTitle light>In Wallet</ExpendCellTitle>
                    {portfolioLoading ? <LoadingText3 /> :
                        <ExpendCellTitle>
                            {`${commaFy(parseFloat(data.walletBalance).toFixed(4))} ($${commaFy(parseFloat(data.walletBalanceUSD).toFixed(2))})`}
                        </ExpendCellTitle>
                    }
                </FlexSBCont>
                <TableInputCont>
                    <TableInput placeholder="0" onChange={handleDepositAmountChanges} value={depositAmount && depositAmount} />
                    {data.walletBalanceUSD > 0.01 ?
                        <MaxTxt onClick={() => setDepositAmount(getNumFormat(data.walletBalance))}>Max</MaxTxt>
                        :
                        <MaxTxtZero onClick={() => setDepositAmount(getNumFormat(data.walletBalance))}>Max</MaxTxtZero>
                    }
                </TableInputCont>
                <CellBtnGroup>
                    {renderTokenTransform()}
                    {/* <TransformBtn onClick={() => handleTransform()}>Transform</TransformBtn> */}
                    {portfolioLoading ?
                        <TableBtn disabled={true} style={{ opacity: 1 }}>Deposit</TableBtn> :
                        <>
                            {
                                isAllowance ?
                                    <DepositBtn onClick={() => handleDepositAmount()} disabled={depositLoading}>{depositLoading ? <Spinner /> : 'Deposit'}</DepositBtn> :
                                    <TableBtn onClick={() => handleApprove()} disabled={approveLoading}>{approveLoading ? <Spinner /> : 'Approve'}</TableBtn>
                            }
                        </>
                    }
                </CellBtnGroup>
            </ExpandedCell>
            <ExpandedCell className="item2">
                <FlexSBCont>
                    <ExpendCellTitle light>In Vault</ExpendCellTitle>
                    {portfolioLoading ? <LoadingText3 /> :
                        <ExpendCellTitle>
                            {`${commaFy(parseFloat(data.vaultBalance).toFixed(4))} ($${commaFy(parseFloat(data.vaultBalanceUSD).toFixed(2))})`}
                        </ExpendCellTitle>
                    }
                </FlexSBCont>
                <TableInputCont>
                    <TableInput placeholder="0" onChange={handleWithdrawAmountChanges} value={withdrawAmount && withdrawAmount} />
                    {data.vaultBalanceUSD > 0.01 ?
                        <MaxTxt onClick={() => setWithdrawAmount(getNumFormat(data.vaultBalance))}>Max</MaxTxt>
                        :
                        <MaxTxtZero onClick={() => setWithdrawAmount(getNumFormat(data.vaultBalance))}>Max</MaxTxtZero>
                    }
                </TableInputCont>
                <WithdrawBtn onClick={() => handleWithdrawAmount()} disabled={withdrawLoading}>
                    {withdrawLoading ? <Spinner /> : 'Withdraw'}
                </WithdrawBtn>
            </ExpandedCell>
        </Fragment>
    )
}
export default VaultTransaction
