import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MainContainer, FlexCont, FlexSBCont } from 'shared/styles/styled'
import { MarketCont, Title, CardCont, Card, CardText, CardLText, PercentText, CardBText, CardLine, TopTokenCont, SliderCont, TableWrapper, TableTitle, TableCont, TableRow, TokenText, TableText, TPercentText, TableSmText } from './style'
import { getMarketData, getIndividualMarketDataMulticall } from 'modules/block-chain-green/LendingBase'
import { commaFy, convertNumFormat } from 'service/globalFunctions'
import ComponentLoader from 'shared/component-loader'
import AssetStats from './components/AssetStats'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import GammaIcon from 'assets/icons/gamma-icon.png'


const Markets = () => {
    const selector = useSelector((state: any) => state)
    const [selectedData, setSelectedData] = useState<any>({})
    const [dataLoading, setDataLoading] = useState(false)
    const [showIndAsset, setShowIndAsset] = useState(false)
    const [individualMarketDataArr, setIndividualMarketDataArr] = useState<any>([])
    const [data, setData] = useState<any>({})

    useEffect(() => {
        const getMarketDataList = async () => {
            try {
                setDataLoading(true)
                const res = await getMarketData(selector.ethData.address)
                const ind_market_array = await getIndividualMarketDataMulticall()
                if (!!res && typeof res !== 'undefined') {
                    setData(res)
                }
                if(!!ind_market_array && typeof ind_market_array !== 'undefined'){
                    setIndividualMarketDataArr(ind_market_array)
                }

            }
            catch (err) { }
            finally {
                setDataLoading(false)
            }
        }
        getMarketDataList()
    }, [selector.ethData.address])

    const handleRowClick = (itemData: any) => {
        setSelectedData(itemData)
        setShowIndAsset(true)
    }
    const getPercentageVal = (val: any) => {
        const value = parseFloat(val)
        let percentageVal = "0.00%"
        if (value > 0) {
            percentageVal = `+${value.toFixed(2)}%`
        }
        if (value < 0) {
            percentageVal = `${value.toFixed(2)}%`
        }
        return percentageVal

    }

    const _renderTopToken = (topTokenData: any) => {
        return topTokenData.map((item: any, index: number) => {
            return (
                <div className="top-token" key={index}>
                    <FlexSBCont >
                        <FlexCont>
                            <img className="on-web" src={item.icon} alt="" />
                            <CardText className="token-name">{item.name}</CardText>
                        </FlexCont>
                        <SliderCont className="on-web">
                            <Slider step={0.01} min={0} max={100} value={item.percentage} tooltip={false} />
                        </SliderCont>
                        <PercentText>{parseFloat(item.percentage).toFixed(2)}%</PercentText>
                    </FlexSBCont>
                    <SliderCont className="on-mobile">
                        <Slider step={0.01} min={0} max={100} value={item.percentage} tooltip={false} />
                    </SliderCont>
                </div>
            )
        })

    }
    const sortByMarketSize = (dataList: any) => {
        if (!!dataList && dataList.length > 0) {
            const finalArr = data.allMarkets.sort((a: any, b: any) => (parseFloat(b.totalSupply) > parseFloat(a.totalSupply)) ? 1 : (parseFloat(a.totalSupply) > parseFloat(b.totalSupply) ? -1 : 0))
            return finalArr
        }
        else {
            return dataList
        }
    }

    const _renderTableRow = () => {
        if (!!data && data.allMarkets && data.allMarkets.length > 0) {
            data.allMarkets = sortByMarketSize(data.allMarkets)
            return data.allMarkets.map((item: any, index: number) => {
                return (
                    <TableRow key={index} className="table-row" onClick={() => handleRowClick(item)} >
                        <FlexCont className="first-item">
                            <img src={item.icon} alt="" />
                            <div>
                                <TokenText>{item.name}</TokenText>
                                <TableSmText>{item.tokenOrigin}</TableSmText>
                            </div>
                        </FlexCont>
                        <div>
                            <TableText>${commaFy(convertNumFormat(item.totalSupply))}</TableText>
                            {/* <TPercentText isNegative={parseFloat(item.totalSupplyChange) < 0}>{getPercentageVal(item.totalSupplyChange)}</TPercentText> */}
                        </div>
                        <div>
                            <TableText>{parseFloat(item.supplyApy).toFixed(2)}%</TableText>
                            {/* <TPercentText isNegative={parseFloat(item.supplyApyChange) < 0}>{getPercentageVal(item.supplyApyChange)}</TPercentText> */}
                        </div>
                        <div>
                            <TableText>${commaFy(convertNumFormat(item.totalBorrow))}</TableText>
                            {/* <TPercentText isNegative={parseFloat(item.totalBorrowChange) < 0}>{getPercentageVal(item.totalBorrowChange)}</TPercentText> */}
                        </div>
                        <div>
                            <TableText>{parseFloat(item.borrowApy).toFixed(2)}%</TableText>
                            {/* <TPercentText isNegative={parseFloat(item.borrowApyChange) < 0}>{getPercentageVal(item.borrowApyChange)}</TPercentText> */}
                        </div>

                        <div>
                            <TableText>{parseFloat(item.gammaPerDay).toFixed(2)}</TableText>
                            {/* <TPercentText isNegative={parseFloat(item.borrowApyChange) < 0}>{getPercentageVal(item.borrowApyChange)}</TPercentText> */}
                        </div>

                    </TableRow>
                )
            })
        }
        else {
            return <ComponentLoader />
        }
    }

    return (
        <MainContainer>
            {!showIndAsset ?
                <MarketCont>
                    <Title>Markets</Title>
                    <CardCont>
                        <Card>
                            <FlexSBCont>
                                <CardLText>Supply Market Size</CardLText>
                                {/* <CardLText className="on-web">24H Supply Volume</CardLText> */}
                            </FlexSBCont>
                            <FlexSBCont className="info-gap">
                                <CardBText>${!!data && data.supplyMarketSize ? commaFy(parseFloat(data.supplyMarketSize).toFixed(2)) : '0.00'}</CardBText>
                                {/* <CardBText className="on-web">${!!data && data.twentyFourHrSuppVolume ? commaFy(parseFloat(data.twentyFourHrSuppVolume).toFixed(2)) : '0.00'}</CardBText> */}
                            </FlexSBCont>
                            {/* <FlexSBCont className="info-gap">
                                <PercentText isNegative={parseFloat(data.changeInSupplyMarketSize) < 0}>{!!data && data.changeInSupplyMarketSize ? getPercentageVal(data.changeInSupplyMarketSize) : '0.00%'}</PercentText>
                                <PercentText className="on-web" isNegative={parseFloat(data.twentyFourHrSuppVolumeChange) < 0}>{!!data && data.twentyFourHrSuppVolumeChange ? getPercentageVal(data.twentyFourHrSuppVolumeChange) : '0.00%'}</PercentText>
                            </FlexSBCont> */}
                            <CardLine />
                            <CardLText>Top 3 Markets</CardLText>
                            <TopTokenCont>
                                {dataLoading ? <ComponentLoader /> :
                                    <>
                                        {!!data && data.top3SupplyMarkets && _renderTopToken(data.top3SupplyMarkets)}
                                    </>
                                }

                            </TopTokenCont>
                        </Card>
                        <Card>
                            <FlexSBCont>
                                <CardLText>Borrow Market Size</CardLText>
                                {/* <CardLText className="on-web">24H Borrow Volume</CardLText> */}
                            </FlexSBCont>
                            <FlexSBCont className="info-gap">
                                <CardBText>${!!data && data.borrowMarketSize ? commaFy(parseFloat(data.borrowMarketSize).toFixed(2)) : '0.00'}</CardBText>
                                {/* <CardBText className="on-web">${!!data && data.twentyFourHrBorrowVolume ? commaFy(parseFloat(data.twentyFourHrBorrowVolume).toFixed(2)) : '0.00'}</CardBText> */}
                            </FlexSBCont>
                            {/* <FlexSBCont className="info-gap">
                                <PercentText isNegative={parseFloat(data.changeInBorrowMarketSize) < 0}>{!!data && data.changeInBorrowMarketSize ? getPercentageVal(data.changeInBorrowMarketSize) : '0.00%'}</PercentText>
                                <PercentText className="on-web" isNegative={parseFloat(data.twentyFourHrBorrowVolumeChange) < 0}>{!!data && data.twentyFourHrBorrowVolumeChange ? getPercentageVal(data.twentyFourHrBorrowVolumeChange) : '0.00%'}</PercentText>
                            </FlexSBCont> */}
                            <CardLine />
                            <CardLText>Top 3 Markets</CardLText>
                            <TopTokenCont>
                                {dataLoading ? <ComponentLoader /> :
                                    <>
                                        {!!data && data.top3BorrowMarkets && _renderTopToken(data.top3BorrowMarkets)}
                                    </>
                                }
                            </TopTokenCont>
                        </Card>
                    </CardCont>
                    <TableWrapper>
                        <TableCont>
                            <TableTitle>All Markets</TableTitle>
                            <TableRow>
                                <TableText className="first-item" light>Market</TableText>
                                <TableText light>Total Supply</TableText>
                                <TableText light>Supply APY</TableText>
                                <TableText light>Total Borrow</TableText>
                                <TableText light>Borrow APY</TableText>
                                <TableText light><img src={GammaIcon} className="gamma-icon" alt="" /> Per Day</TableText>
                            </TableRow>
                            {dataLoading ? <ComponentLoader /> : _renderTableRow()}
                        </TableCont>
                    </TableWrapper>
                </MarketCont> :
                <div><AssetStats tokenData={selectedData} setShowIndAsset={setShowIndAsset} individualMarketDataArr={individualMarketDataArr}/></div>
            }
        </MainContainer>
    );
};

export default Markets;
