export const lendingPathArr = ['lending', 'liquidations', 'markets', 'metrics', 'boost']

export const vaultTabOptions = [
    {
        label: 'Boosted',
        value: 'boosted'
    },
    {
        label: 'Stablecoins',
        value: 'stablecoins',
    },
    {
        label: 'Cryptos',
        value: 'cryptos',
    },
    {
        label: 'Finished',
        value: 'finished',
    },
]

export const vaultTabOptionsNew = [
    // {
    //     label: 'Boosted',
    //     value: 'boosted'
    // },
    {
        label: 'Stablecoins',
        value: 'stablecoins',
    },
    {
        label: 'Cryptos',
        value: 'cryptos',
    },
    // {
    //     label: 'Finished',
    //     value: 'finished',
    // },
]

export const latestPoolTabOptions = [
    {
        label: 'Infinity',
        value: 'infinity'
    },
    {
        label: 'Cryptos',
        value: 'cryptos',
    },
    {
        label: 'Stables',
        value: 'stablecoins',
    },
    {
        label: 'Boosted',
        value: 'boosted'
    },
    {
        label: 'Finished',
        value: 'finished',
    },
]

export const poolTabOptions = [
    {
        label: 'Boosted',
        value: 'boosted'
    },
    {
        label: 'Stablecoins',
        value: 'stablecoins',
    },
    {
        label: 'Cryptos',
        value: 'cryptos',
    },
    {
        label: 'Finished',
        value: 'finished',
    },
]

export const newPoolTabOptions = [
    // {
    //     label: 'Boosted',
    //     value: 'boosted'
    // },
    {
        label: 'Stablecoins',
        value: 'stablecoins',
    },
    {
        label: 'Cryptos',
        value: 'cryptos',
    },
    {
        label: 'Finished',
        value: 'finished',
    },
]

export const sortOptions = [
    {
        label: 'TVL',
        value: 'tvl'
    },
    {
        label: 'APY',
        value: 'apy'
    },
    {
        label: 'Balance',
        value: 'balance'
    },
]
export const protocolOptions = [
    {
        label: 'Protocol',
        value: ''
    },
    {
        label: 'Planet',
        value: 'planet'
    },
    {
        label: 'Thena',
        value: 'thena'
    },
    {
        label: 'Biswap',
        value: 'biswap'
    },
]

export const userDiscountLevel = [
    {
        label: 'Level 0',
        value: 0
    },
    {
        label: 'Level 1',
        value: 1
    },
    {
        label: 'Level 2',
        value: 2
    },
    {
        label: 'Level 3',
        value: 3
    }
]