import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch, connect } from 'react-redux'
import { MainContainer, FlexCont, CardButton, CardButton2, FlexSBCont } from 'shared/styles/styled'
import { TitleText, TitleInfo, CardTitle, CardText, CardSubText } from 'shared/styles/globalText'
import { BaseContainer, BaseHeader, TvlInfoBox, Card, UserInfoCont, OverViewInfo, CardNumText, BalanceWrapper, BalanceCont, BalanceText, LevelBox, MobBalWrapper, MobBalCard, MobBuyAssetCont, MobBalText, LoadingText1, LoadingText2, ConnectWalletBtn } from './style'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import TvlIcon from 'assets/icons/tvl-icon.svg'
import { commaFy, convertToKAndM } from 'service/globalFunctions'
import InfoTooltip from 'shared/info-tool-tip'
import { transLinkUrl, dogeBnbTransLink } from 'service/global-constant'
import { updateUserBalance, updateLPData, silentUpdateOnInfStake, fetchUserPortfolio } from 'logic/action/user.actions'
import CountUp from "react-countup"
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { aquaAddress, eligibilityDataProviderAbi, eligibilityDataProviderAddress, gammaFarmV3, gammaFarmV3Abi } from 'modules/block-chain/abi'
import CustomModal from 'shared/custom-modal'
import WalletLogout from 'modules/app/components/wallet-modal/WalletLogout'
import WalletList from 'modules/app/components/wallet-modal/WalletList'
import { connectWallet } from 'modules/wallet-provider/WalletProvider'
import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsAvatar,
    useEnsName,
  } from 'wagmi'
import { useWeb3Modal } from "@web3modal/react";
import { connectEthWallet, disconnectEthWallet } from 'logic/action/wallet.actions'
import wallet from 'modules/wallet/wallet'
import RightIcon from 'assets/icons/right_arrow.svg'
import { InfoNumberTxt } from 'modules/lending/style'
import { DataInfoTxt, MaxBox, ModalInput, ModalInputCont } from 'shared/styles/highestApyStyle'
import { UnderstandCheckBox } from 'modules/pools/components/style'
import grey_tick from 'assets/icons/grey_tick.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { planetFinanceApiBaseUrl } from 'service/global-constant'
import axios from 'axios'
import { fetchBountyGamma } from 'modules/block-chain/BlockChainMethods'
import { notificationOpen, notificationClose } from "logic/action/notification.action"


const Bounty = (props: any) => {
    const { portfolioLoading, userPortfolio, platformLoading, platformData } = props
    const selector = useSelector((state: any) => state)
    const { poolLoading, poolData, gammaInfDataNew, aquaInfDataNew } = selector.user
    const [displaySendAmount, setDisplaySendAmount] = useState('');
    const [userEligible, setUserEligiblity] = useState("Ineligible")
    const [bountyUsers, setBountyUsers] = useState<any>([])
    const [approveLoading, setApproveLoading] = useState(false)
    const dispatch = useDispatch()


    useEffect(() => {
        checkUserEligibility(selector.ethData.address);
        fetchBountyData()

    },[selector.user.address])

    const checkUserEligibility = async (userAddress: string) => {
        try{
            //set eligibility contract abi and address
            const farm_inst: any = new wallet.web3.eth.Contract(eligibilityDataProviderAbi, eligibilityDataProviderAddress);
            const userEligibilityData: any = farm_inst.methods.isEligibleForRewards(userAddress).call();
            // console.log("userEligibilityData", userEligibilityData)
            setUserEligiblity(userEligibilityData.issueBounty)
        } catch(error){
            console.log("error"+ error)
            setUserEligiblity("Ineligible")
            return 0;
        }
        
    }  
    
    const fetchBountyData = async () => {
        try{
            let bountyUsers = (await axios.get('https://pfapi.planet.finance/v1/disqualifiedUsers')).data;
            if(typeof bountyUsers === 'object' ) {
                const bountyUserArray: any = await fetchBountyGamma(bountyUsers);
                setBountyUsers(bountyUserArray);
            } else {
                setBountyUsers([]);
            }
        } catch(error){
            console.log("error", error)
            setBountyUsers([]);
            return 0;
        }
    }

    const getAddress = (address: any) => {
        // let address = addressObj;
        // console.log("address", address, isConnected)
        const add1 = address.substring(0, 2)
        const add2 = address.substring(address.length - 4)
        const finalAdd = `${add1}....${add2}`
        return finalAdd
    }

    const UserBountyDetails = () => {
        return bountyUsers.map((userDetail: any, index: number) => {
            return (
                <>
                    <CardText className='overview-title'>{getAddress(userDetail.id)}</CardText>
                    <FlexCont className="bountyGammaCont">
                        <img className="bounty-gamma-icon" src={GAMMAIcon} alt="" />
                        <CardText className='bountyGammaVal'>{userDetail.huntedGamma}</CardText>
                    </FlexCont>
                    <ConnectWalletBtn className="claimBountyBtn" onClick={claimUserBounty(userDetail)}>Claim Bounty</ConnectWalletBtn>
                </>
            )
        })
    }

    const claimUserBounty = async (userDetails: any) => {
        try{
            const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmV3Abi, gammaFarmV3);
            await farm_inst.methods.claimBounty(userDetails.id)
            .send({ from : selector.ethData.address})
            .once('confirmation', function (confNumber: any, receipt: any){
                const type = receipt.status ? 'success' : 'failed'
                claimNotification(type, receipt.transactionHash, userDetails)
                dispatch(updateUserBalance(selector.ethData.address))
                checkUserEligibility(selector.ethData.address)
                fetchBountyData()
            })
            .on('error', function (error: any) {
                claimNotification('failed')
                setApproveLoading(false)
            })
           
        } catch (error){
            console.log(error)
        }
    }

    const claimNotification = (type: string, hash?: any, userObj?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `Claimed bounty of ${userObj.huntedGamma} Gamma.` : `Failed to collect bounty of ${userObj.huntedGamma} Gamma.`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    return (
        <MainContainer className='vesting'>
            <BaseContainer>
                <BaseHeader>
                    <div>
                        <TitleText className='vesting'>Bounty Hunt</TitleText>
                        <TitleInfo className="sub-title">Bounty Hunt users that have less than 5% of their Planet Portfolio in GAMMA-BTCB</TitleInfo>
                    </div>

                </BaseHeader>

                <FlexCont className="vestingGammaCont">
                    <CardText className='overview-title'>Your Status: <span>{userEligible}</span></CardText>
                </FlexCont>
                <FlexCont className="bountyExp">
                    <CardText className='overview-title'>To bounty hunt, you must be eligible to receive GAMMA Rewards and hold a minimum of 5% GAMMA-BTCB supplied to the GAMMA-BTCB Pool</CardText>
                </FlexCont>
                <FlexCont>
                    <OverViewInfo className="bountyTableHeader">
                        <CardText className='overview-title'>User</CardText>
                        <CardText></CardText>
                        <CardText className='overview-title'>Bounty</CardText>                        
                    </OverViewInfo>
                    <OverViewInfo className="bountyTableHeaderMob">
                        <CardText className='overview-title-mob'>User</CardText>
                        <CardText></CardText>
                        <CardText className='overview-title-mob'>Bounty</CardText>
                    </OverViewInfo>
                </FlexCont>
                <FlexCont>
                    <OverViewInfo className="bountyTableBody">
                        {UserBountyDetails()}
                        {/* <CardText className='overview-title'>{getAddress("0xa1f9faa7B16b5D32f316262A0251d5A366C21237")}</CardText>
                        <FlexCont className="bountyGammaCont">
                            <img className="bounty-gamma-icon" src={GAMMAIcon} alt="" />
                            <CardText className='bountyGammaVal'>1350.0000</CardText>
                        </FlexCont>
                        <ConnectWalletBtn className="claimBountyBtn">Claim Bounty</ConnectWalletBtn>
                        <CardText className='overview-title'>{getAddress("0xa1f9faa7B16b5D32f316262A0251d5A366C21237")}</CardText>
                        <FlexCont className="bountyGammaCont">
                            <img className="bounty-gamma-icon" src={GAMMAIcon} alt="" />
                            <CardText className='bountyGammaVal'>1350.0000</CardText>
                        </FlexCont>
                        <CardText className='overview-title-white'>Dec 31, 2023 12:36 AM (EST)</CardText> */}
                    </OverViewInfo>
                    <OverViewInfo className="bountyTableBodyMob">
                        {UserBountyDetails()}
                        {/* <CardText className='overview-title-mob'>{getAddress("0xa1f9faa7B16b5D32f316262A0251d5A366C21237")}</CardText>
                        <FlexCont className="bountyGammaCont">
                            <img className="bounty-gamma-icon" src={GAMMAIcon} alt="" />
                            <CardText className='bountyGammaVal'>1350.0000</CardText>
                        </FlexCont>
                        <CardText className='overview-title-mob-white'>Dec 30, 2023 12:15 AM (EST)</CardText>
                        <CardText className='overview-title-mob'>{getAddress("0xa1f9faa7B16b5D32f316262A0251d5A366C21237")}</CardText>
                        <FlexCont className="bountyGammaCont">
                            <img className="bounty-gamma-icon" src={GAMMAIcon} alt="" />
                            <CardText className='bountyGammaVal'>1350.0000</CardText>
                        </FlexCont>
                        <CardText className='overview-title-mob-white'>Dec 31, 2023 12:36 AM (EST)</CardText> */}
                    </OverViewInfo>
                </FlexCont>

            </BaseContainer>
        </MainContainer>
    );
};

const mapStateToProps = (state: any) => ({
    portfolioLoading: state.user.portfolioLoading,
    userPortfolio: state.user.userPortfolio,
    platformLoading: state.user.platformLoading,
    platformData: state.user.platformData,
})
export default connect(mapStateToProps)(Bounty)