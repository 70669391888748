import { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { ethers }  from 'ethers'
import Web3 from "web3";
import { updateUserBalance, silentUpdateOnInfStake, updateLPData, fetchUserPortfolio } from 'logic/action/user.actions'
import { updateUserLendingData } from 'logic/action/lending.action'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import {
  CardCont,
  FlexSBCont,
  FlexCont,
  TitleText,
  InfoLDText,
  SelectCurrTxt,
  MaxTxt,
  MaxTxtZero,
  TokenValInput,
  TokenName,
  BtnGroup,
  PrimaryBtn,
  TokenSwapCont, 
  SwapCard, 
  SwapConstCard, 
  TokenSelectCont, 
  SwapDownArrow, 
  SwapConstTxt, 
  PriceImpactTxt, 
  PriceCont,
} from './styles'
import ClockIcon from 'assets/icons/clock-icon.png'
import DownIcon from 'assets/icons/down-arrow-icon.png'
import ArrowDown from 'assets/icons/arrow-down.png'
import SettingIcon from 'assets/icons/settings.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import TokenSelect from 'shared/token-select'
import ConfirmSwap from './ConfirmSwap'
import TransactionInfo from 'shared/transaction-info'
import TransHistory from 'shared/transaction-history'
import Settings from 'shared/settings'
import {
  tokenAllowance,
  tokenAllowanceOneInch,
  tokenAllowancePlanetZapOneInch,
  onSelectingBothTokensOnSwap,
  returnAmountOfToken,
  instanceType,
  selectInstance,
  convertToWei,
  LIQUIDITY_PROVIDER_FEE,
  returnTokenReserves,
  returnPlanetOptimalPath,
  convertToWeiInternal
} from 'modules/block-chain/Swap'
import { decimals } from 'modules/block-chain/gBNB'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions'
import { PLANETROUTERADDRESS } from 'modules/block-chain/SwapDexAbi'
import {
  planetZapOneInchAddress,
  planetZapOneInchAbi,
  referralAddress,
  referralFees,
  protocols
} from 'modules/block-chain/PlanetZapOneInchAbi'
import { convertToEther, gConvertToEther, getTokenPrice, deposit_in_infinity_vault_new } from 'modules/block-chain/BlockChainMethods';
import { gammaAddress, maxAmount, } from 'modules/block-chain/lendingAbi';
import SwapIcon from 'assets/icons/swap_icon.svg'
import SwapIconWhite from 'assets/icons/swap_icon_white.svg'
import ArrowDownGrey from 'assets/icons/down_grey_icon.svg'
import { commaFy } from 'service/globalFunctions'
import ComponentLoader from 'shared/component-loader'
import { createPostfixIncrement } from 'typescript'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { LoadingText1, LoadingText3 } from 'shared/styles/planetTableStyle'
import { swapTokenList, tokenSwapList } from 'modules/block-chain/tokenList'
import { ERC20_abi, multicall_abi, multicall_address, usdcAddress, usdtAddress, bnbAddress} from 'modules/block-chain/abi'
import wallet from 'modules/wallet/wallet'
import { MAX_INT } from 'modules/block-chain-green_v1/lendingAbi';
import whiteCrossIcon from 'assets/icons/white-cross.svg'
import {gammaFarmAdddress, gammaFarmAbi,} from 'modules/block-chain/abi'
import TransactionConfirmation from './TransactionConfirmation';

const SwapInPools = (props: any) => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const { tokenList, updateTokenList, initialTokenAdd, receiveTokenNumber, closeSwapModal, tokenSwapAmount, toggleSwapPopupModal, sendTokenObject } = props
  const bnbSno = 1
  const [sendAmount, setSendAmount] = useState(parseFloat(tokenSwapAmount) > 0 ? parseFloat(tokenSwapAmount).toString() : '0')
  const [sendAmountWithoutComma, setSendAmountWithoutComma] = useState(parseFloat(tokenSwapAmount) > 0 ? parseFloat(tokenSwapAmount).toFixed(6) : 0)
  const [receiveAmount, setReceiveAmount] = useState('')
  const [receiveAmountWithoutComma, setReceiveAmountWithoutComma] = useState(0)
  const [existTokenAdd, setExistTokeAdd] = useState('')
  const [showTokenModal, setShowTokenModal] = useState(false)
  const [sendToken, setSendToken] = useState<any>(sendTokenObject)
  const [receiveToken, setReceiveToken] = useState<any>({})
  const [tokenType, setTokenType] = useState('')
  const [isAmtLoading, setIsAmtLoading] = useState(false)
  const [confirmSwapModal, setConfirmSwapModal] = useState(false)
  const [showTransModal, setShowTransModal] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [currTokenList, setCurrTokenList] = useState<any>(tokenSwapList)
  const [allowanceAmt, setAllowanceAmt] = useState<any>('0.00')
  const [btnStatus, setBtnStatus] = useState('none')
  const [minReceive, setMinReceive] = useState('0.00')
  const [minSendAmountInWei, setMinSendAmountInWei] = useState(0)
  const [transactionLink, setTransactionLink] = useState('')
  const [btnLoading, setBtnLoading] = useState(false)
  const [swapConstData, setSwapConsData] = useState<any>({})
  const [isSendOrReceiveFilled, setIsSendOrReceiveFilled] = useState(true)
  const [priceImpact, setPriceImpact] = useState('0.00')
  const [showSettings, setShowSettings] = useState(false)
  const [slippageVal, setSlippageVal] = useState(0.5)
  const [deadLine, setDeadLine] = useState(20)
  const [pathPriceArr, setpathPriceArr] = useState<any>([])
  const [sendAmountCheck, setSendAmountCheck] = useState(tokenSwapAmount)
  const [intermediateTokenArray, setIntermediateTokenArray] = useState<any>([])  
  const [activeTab, setActiveTab] = useState('swap')
  const [swapWarning, setSwapWarning] = useState(false)
  const [tokenAllowanceFlag, setTokenAllowanceFlag] = useState(false)
  const [swapTokenFlag, setSwapTokenFlag] = useState(false)
  const [showTxConfModal, setShowTxConfModal] = useState(false)
  const [transactionLoader, setTransactionLoader] = useState(false)
  const [stakeTokenFlag, setStakeTokenFlag] = useState(false)

  useEffect(() => {
    let isMounted = true;
    const initialSwapToken = async () => {
      const index1 = tokenList.findIndex((e: any) => e.sno === sendTokenObject.sno)
      const send = tokenList[index1]
      const index2 = tokenList.findIndex((e: any) => e.sno === receiveTokenNumber)
      let receive = tokenList[index2]
      if (!!initialTokenAdd) {
        const netIndex2 = tokenList.findIndex((e: any) => e.address.toLowerCase() === initialTokenAdd.toLowerCase())
        if (netIndex2 >= 0) {
          receive = tokenList[netIndex2]
        }
      }
      setSendToken(send)
      setReceiveToken(receive)
    }
    if (!!tokenList && tokenList.length > 0) {
      initialSwapToken()
    }
    
  }, [tokenList, initialTokenAdd])

  useEffect(() => {
    const setSwapDetails = async () => {
      try {
        const tokenAPrice = await getTokenPrice(sendToken.address);
        const tokenBPrice = await getTokenPrice(receiveToken.address);
        // console.log(" on both token ", data)
        if(tokenAPrice !== undefined && tokenBPrice !== undefined){
          setSwapConsData({
            tokenAPrice,
            tokenBPrice
          })
        }
      }
      catch (error) {
        console.log(error)
      }
    }
    

    if(sendToken !== undefined && sendToken.address !== undefined){
      setSwapDetails()
    }
  },[sendToken.address, receiveToken])

  useEffect(() => { 
    let isMounted = true;
    const getAllowance = async () => {
      if (sendToken.address && selector.ethData.address && isMounted === true) {
        try {
          let res = await tokenAllowancePlanetZapOneInch(sendToken.address, selector.ethData.address)
          // console.log('getAllowance', res, sendToken);
          res = sendToken.address.toLowerCase() === bnbAddress.toLowerCase() ? MAX_INT : res;
          setAllowanceAmt(res)
          getBtnStatusOutside(res);
        } catch (error) {
          console.log(error)
        }
      }
    }
    getAllowance()
    return () => {
      isMounted = false;
    };
  }, [selector.ethData.address, sendToken, receiveToken, sendTokenObject])

  const getAllowance = async () => {
    setBtnLoading(false)
    if (sendToken.address && selector.ethData.address) {
      try {
        let res = await tokenAllowancePlanetZapOneInch(sendToken.address, selector.ethData.address)
        // console.log('getAllowance outside use-effect', res, sendToken);
        res = sendToken.address.toLowerCase() === bnbAddress.toLowerCase() ? MAX_INT : res;
        setAllowanceAmt(res)
        getBtnStatusOutside(res);
      } catch (error) {
        console.log(error)
      }
    }
  }

  const setTransLink = (data: any) => {
    const links: any = localStorage.getItem('transLinks')
    if (!!links) {
      const linksData = JSON.parse(links)
      const finalData = {
        ...data, time: new Date()
      }
      linksData.push(finalData)
      localStorage.setItem('transLinks', JSON.stringify(linksData))
    } else {
      const newLinkData = [{ ...data, time: new Date() }]
      localStorage.setItem('transLinks', JSON.stringify(newLinkData))
    }
  }
  
  const setConfirmTransLink = (transHash: any, status: any) => {
    const links: any = localStorage.getItem('transLinks')
    const linksData = JSON.parse(links)
    const indexNum = linksData.findIndex((e: any) => e.hashVal === transHash)
    if (indexNum >= 0) {
      const newLinksArr = [...linksData]
      newLinksArr[indexNum].transStatus = 'confirm'
      newLinksArr[indexNum].status = status
      localStorage.setItem('transLinks', JSON.stringify([...newLinksArr]))
    }
  }

  const getBtnStatusOutside = (allowanceAmt: any) => {
    let sendAmountVal: any = sendAmountWithoutComma !== undefined && +sendAmountWithoutComma > 0 ? +sendAmountWithoutComma : 0;
    // console.log('getBtnStatus outside', sendAmountVal, sendToken, allowanceAmt);
    if (!sendAmountVal) {
      setBtnStatus('none')
    } else if (parseFloat(sendAmountVal) > parseFloat(sendToken.value)) {
      setBtnStatus('insufficient')
    } else if (parseFloat(sendAmountVal) <= parseFloat(allowanceAmt)) {
      setBtnStatus('swap')
    } else {
      if (sendToken.address.toLowerCase() === bnbAddress.toLowerCase()) {
        // console.log("bnb check ")
        setBtnStatus('swap')
      } else {
        // console.log("approve token")
        setBtnStatus('approve')
      }
    }
  }

  useEffect(() => {
    const getBtnStatus = () => {
      let sendAmountVal: any = sendAmountWithoutComma !== undefined && +sendAmountWithoutComma > 0 ? +sendAmountWithoutComma : 0;
      // console.log('sendToken in use effect', sendAmountVal, sendToken, allowanceAmt);
      if (!sendAmountVal) {
        setBtnStatus('none')
      } else if (parseFloat(sendAmountVal) > parseFloat(sendToken.value)) {
        setBtnStatus('insufficient')
      } else if (parseFloat(sendAmountVal) <= parseFloat(allowanceAmt)) {
        setBtnStatus('swap')
      } else {
        if (sendToken.address.toLowerCase() === bnbAddress.toLowerCase()) {
          // console.log("bnb check ")
          setBtnStatus('swap')
        } else {
          //console.log("approval check ", sendAmountVal, sendToken, allowanceAmt)
          setBtnStatus('approve')
        }
      }
    }
    getBtnStatus()
  }, [allowanceAmt, sendAmount, sendToken.sno, sendToken.value, sendTokenObject])

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {

      if(isSendOrReceiveFilled) {
        setBtnLoading(true)
        let value = sendAmountCheck;
        let v = convertToWei(value)
        const quote:any = await getQuote(receiveToken.address , sendToken.address, v)
        //console.log('quote', quote);

        if(quote != undefined && quote.fromToken) {
          const minReceive = convertToEther(quote.toTokenAmount);
          //console.log('minReceive', minReceive);
          handleMinReceive(minReceive)
          setMinSendAmountInWei(quote.fromTokenAmount);

          var receiveAmountVal = commaFy(parseFloat(minReceive).toFixed(6));
          setReceiveAmountWithoutComma(+minReceive)
          setReceiveAmount(receiveAmountVal);
          if(receiveAmountVal === ""){
            setReceiveAmount(receiveAmountVal);
            setReceiveAmountWithoutComma(0)
          }

          //handleMinReceive(newReceiveAmt)
          handlePriceImpact(value, sendToken.address, receiveToken.address, swapConstData, quote)
        }
        setBtnLoading(false)
      }
      else {
        setBtnLoading(true)

        const value = sendAmountCheck;
        let v = convertToWei(1)
        let quote:any = await getQuote(receiveToken.address , sendToken.address, v)
        //console.log('quote', quote);

        if(quote.fromToken) {
          const minReceive = convertToEther(quote.toTokenAmount);

          let minSend = 1/ parseFloat(minReceive) * value
          let minSendWei: any = minSend * Math.pow(10, quote.fromToken.decimals);

          let quot = await getQuote(receiveToken.address , sendToken.address, parseInt(minSendWei))

          if(quot != undefined && quot.fromToken) {
            const minReceive = convertToEther(quot.toTokenAmount);
            //console.log('minReceive', minReceive);
            handleMinReceive(minReceive)
            setMinSendAmountInWei(quot.fromTokenAmount);

            var receiveAmountVal = commaFy(+parseFloat(minReceive).toFixed(6));
            setReceiveAmountWithoutComma(+minReceive)
            setReceiveAmount(receiveAmountVal);
            
            const minSend = convertToEther(quot.fromTokenAmount);
            
            setSendAmount(commaFy(parseFloat(minSend).toFixed(6)))
            setSendAmountWithoutComma(parseFloat(minSend))
            handlePriceImpact(minSend, sendToken.address, receiveToken.address, swapConstData, quot)
            setBtnLoading(false)
          }
          
        }
      }
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [receiveToken, sendToken, sendAmountCheck, isSendOrReceiveFilled])

  const handleIsSendOrReceiveFilled = async (val: boolean) => {
    setIsSendOrReceiveFilled(val)
  }
  const handleMinReceive = (val: any, swapConstD?: any) => {
    let _swapConstData = swapConstData;
    if(swapConstD != undefined) {
      _swapConstData = swapConstD
    }
    if (+val > 0) {
      const value: any = val - ((slippageVal / 100) * val)
      //console.log('val',value, val);
      setMinReceive(value)
      if( receiveToken && sendToken && +sendAmountWithoutComma > 0 && (+_swapConstData.tokenAPrice * +sendAmountWithoutComma) * 0.95 > (+value * +_swapConstData.tokenBPrice)){
        setSwapWarning(true)
      } else {
        
        setSwapWarning(false)
      }
    }
  }
  const handleSlippage = (newSlippageVal: number) => {
    setSlippageVal(newSlippageVal)
    if (parseFloat(receiveAmount) > 0) {
      const value: any = parseFloat(receiveAmount) - (newSlippageVal / 100) * parseFloat(receiveAmount)
      setMinReceive(value)
    }
  }
  const handleSendAmount = async (e: any) => {    
    let { value } = e.target
    if(+value === 0 ){
      value = value;
    } else {
      value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.replace(",", '') : "";
    }
    
    if(value == 10001000 || value == 10011001 ||value == 10021002 || value == 10031003 || value == 10041004 || value == 10051005 || value == 10061006 || value == 10071007 || value == 10081008 || value == 10091009){
      //console.log(JSON.stringify(e.target))
    }
    if (floatNumRegex.test(value.toString())) {
      if(+value > 0){
        setSendAmountCheck(value)
        setSendAmountWithoutComma(value)
        setSendAmount(commaFy(value))
        handleIsSendOrReceiveFilled(true)
      } else {
        setSendAmount(value)
        setSendAmountWithoutComma(value)
        setSendAmountCheck(0)
        setReceiveAmountWithoutComma(0)
        handleMinReceive(0)
        setReceiveAmount('')
      }      
    } else {
      setSendAmount("0")
      setSendAmountCheck(0)
      setSendAmountWithoutComma(0)
      setReceiveAmountWithoutComma(0)
      handleMinReceive(0)
      setReceiveAmount('')
    }
    if (!value) {
      setSendAmount('')
      setSendAmountWithoutComma(0)
      setSendAmountCheck(0)
      handleMinReceive(0)
      setReceiveAmount('')
      setReceiveAmountWithoutComma(0)
      setSwapWarning(false)
    }
  }

  const handleReceiveAmount = async (e: any) => {
    // console.log(" in handle receive")
    let { value } = e.target
    if(+value === 0 ){
      value = value;
    } else {
      value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.replace(",", '') : "";
    }
    
    if(value == 10001000 || value == 10011001 ||value == 10021002 || value == 10031003 || value == 10041004 || value == 10051005 || value == 10061006 || value == 10071007 || value == 10081008 || value == 10091009){
      //console.log(JSON.stringify(e.target))
    }
    if (floatNumRegex.test(value.toString())) {
      if(+value > 0){
        setReceiveAmount(commaFy(value))
        setReceiveAmountWithoutComma(value)
        handleIsSendOrReceiveFilled(false)
        handleMinReceive(value)
        setSendAmountCheck(value)    
      } else {
        setReceiveAmount(value)
        setSendAmountCheck(0)
        setReceiveAmountWithoutComma(0)
        setSendAmountWithoutComma(0)
        handleMinReceive(0)
        setSendAmount('')
      }
    }
    if (!value) {
      setReceiveAmount('')
      setMinReceive('0.00')
      setPriceImpact('0.00')
      setSendAmountCheck(0)
      setReceiveAmountWithoutComma(0)
      setSendAmountWithoutComma(0)
      setSendAmount('')
      setSwapWarning(false)
    }
  }

  const getExistTokenAdd = (type: string) => {
    if (type === 'send' && Object.values(receiveToken).length > 0) {
      setExistTokeAdd(receiveToken.address)
    }
    if (type === 'receive' && Object.values(sendToken).length > 0) {
      setExistTokeAdd(sendToken.address)
    }
  }
  const handleTokenModal = (type: string) => {
    setTokenType(type)
    // getTokenList(type)
    getExistTokenAdd(type)
    setShowTokenModal(true)
    setSendAmountCheck(0)
  }

  const handleTokenSelect = (selectedTokenData: any) => {
    // console.log(selectedTokenData, existTokenAdd)
    if (selectedTokenData.address === existTokenAdd) {
      setSendToken(receiveToken)
      setReceiveToken(sendToken)
      getSwapConstantData(receiveToken, sendToken)
    }
    else {
      if (tokenType === 'send') {
        setSendToken(selectedTokenData)
        if (Object.values(receiveToken).length > 0) {
          getSwapConstantData(selectedTokenData, receiveToken)
        } else {
          // console.log("set swap const data inside handle token select else 1")
          setSwapConsData({})
        }
      }
      if (tokenType === 'receive') {
        setReceiveToken(selectedTokenData)
        if (Object.values(sendToken).length > 0) {
          getSwapConstantData(sendToken, selectedTokenData)
        } else {
          // console.log("set swap const data inside handle token select else 2")
          setSwapConsData({})
        }
      }
      setIntermediateTokenArray([])
    }
    
    setExistTokeAdd('')
    setSendAmount('')
    setSendAmountWithoutComma(0)
    setReceiveAmountWithoutComma(0)
    setReceiveAmount('')
    handleToggleModal()  
    handleMinReceive(0)  
    setSwapWarning(false)
    setMinReceive('0.00')
    // console.log("handle token select function", sendAmountWithoutComma)
  }

  const getSwapConstantData = async (firstToken: any, secondToken: any) => {
    try {
      const tokenAPrice = await getTokenPrice(firstToken.address)
      const tokenBPrice = await getTokenPrice(secondToken.address)
      setSwapConsData({
        tokenAPrice,
        tokenBPrice
      })
      
    } catch (error) {
      console.log("set swap const data inside get swap const data error condition", error)
      setSwapConsData({})
    }
  }

  const handleToggleModal = () => {
    setTokenType('')
    setShowTokenModal(false)
  }

  const handleSwapToken = async () => {
    setSendToken(receiveToken)
    setReceiveToken(sendToken);
    handleIsSendOrReceiveFilled(true)

    if (Object.values(sendToken).length > 0 && Object.values(receiveToken).length > 0) {
      let tokenAPrice = swapConstData.tokenBPrice;
      let tokenBPrice = swapConstData.tokenAPrice;
      setSwapConsData({
        tokenAPrice,
        tokenBPrice
      });
    }
  }

  const handleMaxAmount = async () => {
    let amount: any
    if (parseFloat(sendToken.usdValue) > 0.01) {
      
      if (sendToken.sno === bnbSno && parseFloat(sendToken.value) !== 0) {
        amount = sendToken.value
        amount -= 0.01
      } else {
        amount = sendToken.value
      }
      setSendAmountCheck(amount)
      amount = getNumFormat(amount)
      setSendAmount(amount)
      setSendAmountWithoutComma(amount)
    }
  }

  const handleApprove = async () => {
    try {
      // minSendAmountInWei - amount for approval
      setBtnLoading(true)
      setTransactionLoader(true)
      setShowTxConfModal(true)
      const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, sendToken.address)
      const approvalAmount = maxAmount
      setConfirmSwapModal(false)
      await TOKEN_INSTANCE.methods
        .approve(planetZapOneInchAddress, minSendAmountInWei)
        .send({ from: selector.ethData.address })
        .once('transactionHash', function (res: any) {
          if (!!res && typeof res) {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            setShowTransModal(true)
          }
        })
        .once('confirmation', async function (confNumber: any, receipt: any) {
          if (receipt && typeof receipt !== 'undefined') {
            setBtnStatus('swap')
            // setBtnLoading(false)
            // updateTokenList()
            let allowanceAmount: any = await TOKEN_INSTANCE.methods.allowance(selector.ethData.address, planetZapOneInchAddress).call();
            // console.log("allowance amount in checkTokenApproval", allowanceAmount);
            let allowance: any = convertToEther(allowanceAmount);
            if(allowance >= sendAmount || sendToken.name.toLowerCase() == 'bnb'){
                setTokenAllowanceFlag(true)
                let swapToken_res = await handle1InchSwap();                
            } else {
                handleApprove()
            }
            
          }
        })
        .on('error', function (error: any) {
          setTransactionLink('')
          setBtnStatus('approve')
          setShowTransModal(false)
          console.log(error)
          setTransactionLoader(false)
          setShowTxConfModal(false)
        })
    } catch (error) {
      console.log(error)
      setTransactionLoader(false)
      setShowTxConfModal(false)
    } finally {
      setBtnLoading(false)
      setSendAmount(sendAmount)
      setSendAmountWithoutComma(+sendAmountWithoutComma)
      setSendToken(sendToken)
      setReceiveToken(receiveToken)
      getAllowance()
      //console.log("token details on approve", sendAmount, sendAmountWithoutComma, sendToken, receiveToken)
    }
  }

  const handleTransactionLink = (newLink: any, hashVal: any) => {
    const data: any = {
      firstToken: sendToken.name,
      secondToken: receiveToken.name,
      amount1: sendAmountWithoutComma,
      amount2: receiveAmountWithoutComma,
      link: newLink,
      type: 'swap',
      status: false,
      hashVal: hashVal,
      transStatus: 'pending',
    }
    setTransLink(data)
  }
  const handleNotification = (type: string, hash?: any) => {
    const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
    const data: any = {
      type: type,
      message: `Swap ${parseFloat(sendAmountWithoutComma.toString()).toFixed(4)} ${sendToken.name} for ${parseFloat(receiveAmountWithoutComma.toString()).toFixed(4)} ${receiveToken.name}`,
      link: linkUrl
    }
    dispatch(notificationClose())
    dispatch(notificationOpen(data))
  }

  const getProtocols = async() => {
    function nonPmm(protocol:any) {
      //console.log('protocol', protocol);
      return !protocol.includes('PMM');
    }
    let protocolsAr:any = protocols;
    // let protocols:any = await fetch(apiRequestUrl('/liquidity-sources'))
    //       .then(res => res.json())
    //       .then(res => res);
    //console.log('protocols', protocols);
    protocolsAr = protocolsAr.protocols.map(function(a:any) {return a.id;});
    return protocolsAr.filter(nonPmm) ;
  }

  const buildTxForSwap = async () => {
    let inputToken = sendToken.address;
    let protocols = await getProtocols();
    //console.log('protocols', protocols);
    protocols = protocols.toString();

    const swapParams = {
      fromTokenAddress: inputToken, // 1INCH
      toTokenAddress: receiveToken.address, // DAI
      amount: minSendAmountInWei,
      fromAddress: planetZapOneInchAddress,
      slippage: slippageVal,
      disableEstimate: true,
      allowPartialFill: false,
      referrerAddress: referralAddress,
      fee: referralFees,
      protocols: protocols
    };

    //console.log('swapParams', swapParams);
    const url = apiRequestUrl('/swapData', swapParams.toTokenAddress, swapParams.fromTokenAddress, swapParams.amount, swapParams.slippage);

    return fetch(url).then(res => res.json()).then(res => res.tx);
  }

  const handle1InchSwap = async () => { 
    setBtnLoading(true);
    setTransactionLoader(true)
    setTokenAllowanceFlag(true)
    setShowTxConfModal(true)
    let swapTransaction = await buildTxForSwap();
    const userAddress = selector.ethData.address.replace('0x', '');
    const _planetZapOneInchAddress = planetZapOneInchAddress.replace('0x', '');
    swapTransaction = swapTransaction.data.replace(userAddress.toLowerCase(), _planetZapOneInchAddress.toLowerCase())
    console.log('Transaction for swap: ', swapTransaction, minSendAmountInWei);
    try{
      if(sendToken.address.toLowerCase() == bnbAddress.toLowerCase()) {
        const swapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
        await swapInstance.methods
          .swapFromBNB(swapTransaction, receiveToken.address)
          .send({
            from: userAddress,
            value: minSendAmountInWei,
          }).on('sending', async function (res: any) {
            setConfirmSwapModal(false);
          }).once('transactionHash', async function (res: any) {
            if (res && typeof res !== 'undefined') {
              const transLink = `${transLinkUrl}/${res}`
              setTransactionLink(transLink)
              handleTransactionLink(transLink, res)
              setShowTransModal(true)
            }
          }).once('confirmation', async function (confNumber: any, receipt: any) {
            setConfirmTransLink(receipt.transactionHash, receipt.status)
            const type = receipt.status ? 'success' : 'failed'
            handleNotification(type, receipt.transactionHash)
            // setBtnLoading(false)
            setSwapTokenFlag(true)
            await handleDepositInInfinityVault();
          })
          .on('error', function (error: any) {
            handleNotification('failed')
            setShowTransModal(false)
            setBtnLoading(false)
            setTransactionLoader(false)
            setShowTxConfModal(false)
          })
      }
      else {
        const swapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
        await swapInstance.methods
          .swap(sendToken.address, minSendAmountInWei, swapTransaction, receiveToken.address)
          .send({
            from: userAddress,
            value: 0
          }).on('sending', async function (res: any) {
            setConfirmSwapModal(false);
          }).once('transactionHash', async function (res: any) {
            if (res && typeof res !== 'undefined') {
              const transLink = `${transLinkUrl}/${res}`
              setTransactionLink(transLink)
              handleTransactionLink(transLink, res)
              setShowTransModal(true)
            }
          }).once('confirmation', async function (confNumber: any, receipt: any) {
            setConfirmTransLink(receipt.transactionHash, receipt.status)
            const type = receipt.status ? 'success' : 'failed'
            handleNotification(type, receipt.transactionHash)
            setSwapTokenFlag(true)
            await handleDepositInInfinityVault();
            // setBtnLoading(false)
            // dispatch(silentUpdateOnInfStake(selector.ethData.address))
            // dispatch(updateUserLendingData(selector.ethData.address))
          })
          .on('error', function (error: any) {
            handleNotification('failed')
            setShowTransModal(false)
            setBtnLoading(false)
            setTransactionLoader(false)
            setShowTxConfModal(false)
          })
      }
      
    } catch (error){
      console.log(error)
      setTransactionLoader(false)
      setShowTxConfModal(false)
    } finally{
      setShowTransModal(false)
        //setBtnLoading(false)
      setSendAmount('')
      setReceiveAmount('')
      setMinReceive('0.00')
      updateTokenList()
      setSendAmountCheck(0)
      setSendAmountWithoutComma(0)
      setReceiveAmountWithoutComma(0)
      setSwapWarning(false)
      setMinReceive('0.00')
      dispatch(silentUpdateOnInfStake(selector.ethData.address))
      dispatch(updateUserLendingData(selector.ethData.address))
    }
    
  }

  const handleDepositInInfinityVault = async () => {
    setBtnLoading(true)
    // console.log("inside stake function")
    if (selector.ethData.ethWalletConnected && parseFloat(sendAmount) > 0) {
        try {
            let pool_id = receiveToken.address.toLowerCase() == gammaAddress.toLowerCase() ? 0 : 1;
            const farm_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
            let staking_amount: any = convertToWei(receiveAmount)//BigInt(amountInEth * Math.pow(10, 18));
            staking_amount = staking_amount.toString();
            let stakingAmountInString: any = staking_amount.split(".");
            await farm_inst.methods
            .deposit(pool_id, stakingAmountInString[0])
            .send({
                from: selector.ethData.address,
            }).on('sending', async function (res: any) {
                setConfirmSwapModal(false);
            }).once('transactionHash', async function (res: any) {
                if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
                }
            }).once('confirmation', async function (confNumber: any, receipt: any) {
                setConfirmTransLink(receipt.transactionHash, receipt.status)
                const type = receipt.status ? 'success' : 'failed'
                handleNotification(type, receipt.transactionHash)
                setStakeTokenFlag(true)
                dispatch(updateLPData(selector.ethData.address))
                dispatch(fetchUserPortfolio(selector.ethData.address, true))
                setShowTxConfModal(false)
                setTimeout(async () => {
                  setTransactionLoader(false)
              }, 2000)
            })
            .on('error', function (error: any) {
                handleNotification('failed')
                setShowTransModal(false)
                setBtnLoading(false)
                setTransactionLoader(false)
                setShowTxConfModal(false)
            })
            setBtnLoading(false)
            toggleSwapPopupModal();
        } catch (error) {
            console.log('error==>', error)
            setTransactionLoader(false)
            setBtnLoading(false)
            setShowTxConfModal(false)
        } finally {
            setBtnLoading(false)
        }
    }
  }   

  const handlePriceImpact = async (amount1: any, tokenA: any, tokenB: any, _swapConstData:any, quote:any) => {
    try {
      if (!!_swapConstData && Object.values(_swapConstData).length > 0) {
        let priceA:any = _swapConstData.tokenAPrice
        let priceB:any = _swapConstData.tokenBPrice
        let v = convertToWei(amount1)
        if(tokenB != undefined && tokenA != undefined && amount1 > 0 ) {
          let fromTokenAmount = quote.fromTokenAmount;
          let toTokenAmount = quote.toTokenAmount;
          let pricePaidPerTokenB: any = fromTokenAmount / toTokenAmount
          let priceRatio = priceB / priceA ;
          const impact: any = (Math.abs(pricePaidPerTokenB - priceRatio) / priceRatio) * 100
          setPriceImpact(parseFloat(impact).toFixed(2))
        } else {
        setPriceImpact('0.00')
        }
      }
      else {
        setPriceImpact('0.00')
      }
    } 
    catch (err) {
      console.log('handle price impact', err)
      setPriceImpact('0.00')
    }
  }

  const sendInput = useRef<HTMLInputElement>(null);
  const receiveInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (sendInput.current) {
      sendInput.current.focus();
      // sendInput.current.autofocus = true;
    }
  },[sendInput.current])

  useEffect(() => {
    if (receiveInput.current) {
      // receiveInput.current.focus();
      // receiveInput.current.autofocus = true;
    }
  },[receiveInput.current])

  const handleTabClick:any = (tabName: string) => {
    history.push(`${Paths.root}${tabName}`)
  }

  const chainId = 56;
  const apiBaseUrl = 'https://pfapi.planet.finance/v1'; //'https://api-planetfinance.1inch.io/v5.0/' + chainId;
  //const apiBaseUrl = 'https://api.1inch.io/v5.0/' + chainId;
  const apiRequestUrl:any = (methodName:any,  dst: any, src: any, amount: any, fee?:any) => {
    return fee == undefined ? apiBaseUrl + methodName + '/' + src + '/' + dst + '/' + amount : apiBaseUrl + methodName + '/' + src + '/' + dst + '/' + amount + '/' + fee;
  }

  const getQuote = async (toTokenAddress:any, fromTokenAddress:any, amount:any) => {
    let fee = referralFees;
    let protocols = await getProtocols();
    //console.log('protocols', protocols);
    protocols = protocols.toString();

    if(amount > 0 && toTokenAddress != undefined && fromTokenAddress != undefined) {
      let url = apiRequestUrl('/swapQuote', toTokenAddress, fromTokenAddress, amount)
      //console.log('url', url);
      return fetch(apiRequestUrl('/swapQuote', toTokenAddress, fromTokenAddress, amount))
          .then(res => res.json())
          .then((res: any) => { 
            res.fromToken = fromTokenAddress;
            res.toToken = toTokenAddress;
            res.fromTokenAmount = amount;
            res.toTokenAmount = res.toAmount;
            return res;
          });
    }
  }

  return (
    <TokenSwapCont className='swap test'>
     
      <CardCont>
        <FlexSBCont>
          <TitleText className="swapText">Swap & Stake</TitleText>
          
          <FlexCont className="clock-setting">
            <img src={SettingIcon} alt="" onClick={() => setShowSettings(true)} />
            <img src={ClockIcon} alt="" onClick={() => setShowHistory(true)} />
            <img className="close-confirm-swap" src={whiteCrossIcon} alt="" onClick={() => closeSwapModal()} />
          </FlexCont>
        </FlexSBCont>
        <SwapCard sendAmount={sendAmountCheck} className="swapBox">
          <FlexSBCont >
            {sendAmountCheck > 0 && !isNaN(swapConstData.tokenAPrice * +sendAmountWithoutComma) ?
            <InfoLDText>Send {`($${Object.values(sendToken).length > 0 ? commaFy(parseFloat((+swapConstData.tokenAPrice * +sendAmountWithoutComma).toString()).toFixed(2)) : '0'})`}</InfoLDText>
            :
            sendAmountCheck > 0 && isNaN(swapConstData.tokenAPrice * +sendAmountWithoutComma) ?
            <InfoLDText>Send <LoadingText1 /></InfoLDText>
            :
            <InfoLDText>Send</InfoLDText>
            }
            <InfoLDText>{`Balance: ${Object.values(sendToken).length > 0 ? commaFy(parseFloat(sendToken.value).toFixed(4)) : '-'}`}</InfoLDText>
          </FlexSBCont>
          <FlexSBCont className="swap-input">
            <TokenValInput
              disabled={!(Object.values(sendToken).length > 0 && Object.values(receiveToken).length > 0)}
              placeholder="0"
              onChange={handleSendAmount}
              value={sendAmount && sendAmount}
              autoFocus={true}
              ref={sendInput}
            />
            <FlexCont>
              {Object.values(sendToken).length > 0 && sendToken.usdValue > 0 ? <MaxTxt onClick={() => handleMaxAmount()}>Max</MaxTxt> : <MaxTxtZero onClick={() => handleMaxAmount()}>Max</MaxTxtZero>}
              <TokenSelectCont onClick={() => handleTokenModal('send')}>
                {Object.values(sendToken).length > 0 ? (
                  <>
                    <img className="token-icon" src={sendToken.icon} alt="" />
                    <TokenName>{sendToken.name}</TokenName>
                  </>
                ) : (
                  <SelectCurrTxt>Select Currency</SelectCurrTxt>
                )}
                <img className="arrow-down" src={ArrowDown} alt="" />
              </TokenSelectCont>
            </FlexCont>
          </FlexSBCont>
        </SwapCard>
        <FlexSBCont className='swapIconBg' ></FlexSBCont>
        {/* <SwapDownArrow className='swapIconBg' onClick={() => handleSwapToken()}>
          <img className='swapIconBg' src={SwapIcon} alt="" onMouseOver={e => e.currentTarget.src = SwapIconWhite} onMouseOut={e => e.currentTarget.src = SwapIcon} onTouchStart={e => e.currentTarget.src = SwapIcon}
          onTouchEnd={e => e.currentTarget.src = SwapIcon} onClickCapture={e => e.currentTarget.src = SwapIcon} />
        </SwapDownArrow> */}
        <SwapCard className="swapBox">

          <FlexSBCont>
            {sendAmountCheck > 0 && !isNaN(+minReceive * +swapConstData.tokenBPrice) ? 
            <InfoLDText>Receive {`${parseFloat(minReceive) > 0 ? "($" +commaFy((+minReceive * +swapConstData.tokenBPrice).toFixed(2)) + ")" : ''
              }`}</InfoLDText>
              :
              sendAmountCheck > 0 && !isNaN(+minReceive * +swapConstData.tokenBPrice) ?
              <InfoLDText>Receive <LoadingText1 /></InfoLDText>
              :
            <InfoLDText>Receive</InfoLDText>
            }
            <InfoLDText>{`Balance: ${Object.values(receiveToken).length > 0 ? commaFy(parseFloat(receiveToken.value).toFixed(4)) : '-'
              }`}</InfoLDText>
          </FlexSBCont>
          <FlexSBCont className="swap-input">
            <TokenValInput
              disabled={!(Object.values(sendToken).length > 0 && Object.values(receiveToken).length > 0)}
              placeholder="0"
              onChange={handleReceiveAmount}
              value={receiveAmount && receiveAmount}
              className="receiveInput"
              data={receiveAmountWithoutComma}
              ref={receiveInput}
              // autoFocus={true}
            />
            <FlexCont>
              <TokenSelectCont>
                <img className="token-icon" src={receiveToken.icon} alt="" />
                <TokenName>{receiveToken.name}</TokenName>
              </TokenSelectCont>
            </FlexCont>
          </FlexSBCont>
        </SwapCard>
        {isAmtLoading &&
          <PriceCont>
            <SwapConstTxt style={{ textAlign: 'center' }}> Amount loading...</SwapConstTxt>
          </PriceCont>
        }
        <PriceCont>
          {!!swapConstData && Object.values(swapConstData).length > 0 && (
            <div>
              {(
                <>
                  <FlexSBCont className="price-space">
                    <SwapConstTxt light>{`${sendToken.name} Price`}</SwapConstTxt>
                    <SwapConstTxt> ${commaFy(parseFloat(swapConstData.tokenAPrice).toFixed(2))}</SwapConstTxt>
                  </FlexSBCont>
                  <FlexSBCont>
                    <SwapConstTxt light>{`${receiveToken.name} Price`}</SwapConstTxt>
                    <SwapConstTxt> ${commaFy(parseFloat(swapConstData.tokenBPrice).toFixed(2))}</SwapConstTxt>
                  </FlexSBCont>
                </>
              )}
            </div>
          )}
        </PriceCont>
        {
        btnStatus === 'approve' && parseFloat(sendAmountWithoutComma.toString()) <= parseFloat(sendToken.value) ? (
          <BtnGroup className="btn-space">
            <PrimaryBtn disabled={btnLoading || parseFloat(sendAmountWithoutComma.toString()) <= 0 || parseFloat(receiveAmountWithoutComma.toString()) <= 0} onClick={() => handleApprove()}>
              {btnLoading ? <Spinner /> : 'Approve'}
            </PrimaryBtn>
            <PrimaryBtn disabled>Swap & Stake</PrimaryBtn>
          </BtnGroup>
        ) : btnStatus === 'swap' && parseFloat(priceImpact) <= 5 && parseFloat(priceImpact) >= 0 && parseFloat(sendAmountWithoutComma.toString()) > 0 ? (
          <PrimaryBtn className="btn-space" disabled={btnLoading || parseFloat(sendAmountWithoutComma.toString()) <= 0 || parseFloat(receiveAmount) <= 0 || isAmtLoading} onClick={() => handle1InchSwap()}>
            {btnLoading ? <Spinner /> : parseFloat(priceImpact) > 3 ? 'Swap & Stake' : 'Swap & Stake'}
          </PrimaryBtn>
        ) : (
          <PrimaryBtn className="btn-space" disabled>Swap & Stake</PrimaryBtn>
        )}
      </CardCont>
      {(
        <SwapConstCard className="swap-const-info">
          <FlexSBCont className="swap-const-data">
            <SwapConstTxt light>Minimum Received</SwapConstTxt>
            <SwapConstTxt>{commaFy(parseFloat(minReceive).toFixed(4))}</SwapConstTxt>
          </FlexSBCont>
         { <FlexSBCont className="swap-const-data">
            <SwapConstTxt light>Price Impact</SwapConstTxt>
            <PriceImpactTxt priceImpactVal={parseFloat(priceImpact)} className="priceImpact">{`${!!priceImpact && parseFloat(priceImpact) > 100 ? '99.99' : parseFloat(priceImpact) < 0 ? '0.00' : priceImpact
              }%`}</PriceImpactTxt>
          </FlexSBCont>}
          { swapWarning  &&
          <FlexSBCont className="swap-const-data">
            <PriceImpactTxt className="highSlippageWarning">High slippage value, please try a smaller amount for swap</PriceImpactTxt>
          </FlexSBCont>
          }
        </SwapConstCard>
      )}
      
      <CustomModal show={showTokenModal} toggleModal={handleToggleModal}>
        {showTokenModal && (
          <TokenSelect
            selectedAdd={tokenType === 'send' ? receiveToken.address : sendToken.address}
            setShowTokenModal={setShowTokenModal}
            tokenListVal={currTokenList}
            handleTokenSelect={handleTokenSelect}
            existTokenAdd={existTokenAdd}
            crossIconColor={'white'}
          />
        )}
      </CustomModal>
      <CustomModal show={confirmSwapModal} toggleModal={setConfirmSwapModal}>
        {confirmSwapModal && (
          <ConfirmSwap
            setConfirmSwapModal={setConfirmSwapModal}
            sendToken={sendToken}
            receiveToken={receiveToken}
            minReceive={minReceive}
            sendAmount={sendAmount}
            receiveAmount={receiveAmount}
            priceImpact={priceImpact}
            swapConstData={swapConstData}
            sendTokenPrice={swapConstData.tokenAPrice}
            ReceiveTokenPrice={swapConstData.tokenBPrice}
            crossIconColor={"white"}
            handle1InchSwap={handle1InchSwap}
          />
        )}
      </CustomModal>
      <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
        {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} crossIconColor={"white"} />}
      </CustomModal>
      <CustomModal show={showSettings} toggleModal={setShowSettings}>
        {showSettings && (
          <Settings
            setShowSettings={setShowSettings}
            slippageVal={slippageVal}
            setSlippageVal={setSlippageVal}
            handleSlippage={handleSlippage}
            // deadLine={deadLine}
            // setDeadLine={setDeadLine}
            crossIconColor={"white"}
          />
        )}
      </CustomModal>
      <CustomModal show={showHistory} toggleModal={setShowHistory}>
        {showHistory && <TransHistory setShowHistory={setShowHistory} crossIconColor={"white"} />}
      </CustomModal>
        <CustomModal show={showTxConfModal} toggleModal={setShowTxConfModal}>
            {showTxConfModal && <TransactionConfirmation flag_1={tokenAllowanceFlag} flag_2={swapTokenFlag} flag_3={stakeTokenFlag} transactionLoader={transactionLoader} action={'swap_stake'} sendToken={sendToken} receiveToken={receiveToken} setShowConfModal={setShowTxConfModal} />}
        </CustomModal>
    </TokenSwapCont>
  )
}

export default SwapInPools
