import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchInfVaultData } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont, FlexCont, PlanetRedBtn, MaxBtn } from 'shared/styles/styled';
import { ModalCont, ModalCard, ModalTitle, ModalInputCont, ModalInput, ExchangeRateBox, ModalText, ModalBText, ModalInfoText, CrossButton } from './style'
import { colors } from 'shared/styles/theme';
import CloseIcon from 'assets/icons/white-cross.svg'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import { transLinkUrl } from 'service/global-constant'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { commaFy, getNumFormat } from 'service/globalFunctions'
import Spinner from 'shared/spinner'
import { normal_unstake } from 'modules/block-chain/BlockChainMethods'


const UnStake = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [sendAmount, setSendAmount] = useState('')
    const [loading, setLoading] = useState(false)
    const { token, setShowUnStake, data } = props

    const handleSendAmount = async (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setSendAmount(value)
        }
        if (!value) {
            setSendAmount('')
        }
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `Amount ${sendAmount} Unstaked` : `Failed to Unstake`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleUnStake = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(sendAmount) > 0) {
            const userAddress = selector.ethData.address
            try {
                setLoading(true)
                const res: any = await normal_unstake(userAddress, data.infinity_vault_address, data.infinityAbi, sendAmount)
                handleNotification('success', res.transactionHash)
                dispatch(fetchInfVaultData(userAddress, false))
                setShowUnStake(false)
            }
            catch (error) {
                console.log("error==>", error)
                handleNotification('failed')
            }
            finally {
                setLoading(false)
            }
        }
    }

    const getExchangeRate = () => {
        let exchangeVal: any = '0'
        if (!!sendAmount && !!data && data.exchangeRate) {
            if (parseFloat(data.exchangeRate) > 0) {
                exchangeVal = parseFloat(sendAmount) / parseFloat(data.exchangeRate)
            }
        }
        return parseFloat(exchangeVal).toFixed(4)
    }

    const handleMax = () => {
        if (!!data && data.gtoken_user_can_unstake) {
            const maxAmt: any = data.gtoken_user_can_unstake;//getNumFormat(data.gtoken_user_can_unstake)
            setSendAmount(maxAmt)
        }
        else {
            setSendAmount('0')
        }
    }

    return (
        <ModalCont>
            <ModalCard>
                <FlexSBCont>
                    <ModalTitle>Unstake</ModalTitle>
                    <CrossButton onClick={() => setShowUnStake(false)} >+</CrossButton>
                </FlexSBCont>
                <ModalInputCont isActive={!!sendAmount && parseFloat(sendAmount) > 0} colorVal={colors.red}>
                    <FlexSBCont className="bottom-space">
                        <ModalText>Amount</ModalText>
                        <ModalText>{`Staked: ${!!data && data.gtoken_staked ? commaFy(parseFloat(data.gtoken_staked).toFixed(4)) : '0.0000'}`}</ModalText>
                    </FlexSBCont>
                    <FlexCont>
                        <ModalInput
                            placeholder="0"
                            onChange={handleSendAmount}
                            value={sendAmount && sendAmount}
                        />
                        <FlexCont>
                            <MaxBtn className="unStake-max" onClick={() => handleMax()}>MAX</MaxBtn>
                            <img src={token === 'gAQUA' ? AQUAIcon : GAMMAIcon} alt="" />
                            <ModalBText>{token === 'gAQUA' ? "gAQUA" : "gGAMMA"}</ModalBText>
                        </FlexCont>
                    </FlexCont>
                </ModalInputCont>
                <ExchangeRateBox>{`= ${getExchangeRate()} ${token === 'gAQUA' ? 'AQUA' : 'GAMMA'}`}</ExchangeRateBox>
                <PlanetRedBtn disabled={!sendAmount || parseFloat(sendAmount) <= 0 || loading} onClick={() => handleUnStake()}>{loading ? <Spinner /> : 'Confirm'}</PlanetRedBtn>
            </ModalCard>
            <ModalCard className="top-space">
                <FlexSBCont >
                    <ModalInfoText light>Unstaking Time</ModalInfoText>
                    <ModalInfoText>{!!data && data.min_Time_To_Withdraw ? data.min_Time_To_Withdraw : 0} Days</ModalInfoText>
                </FlexSBCont>
            </ModalCard>

        </ModalCont>
    );
};

export default UnStake;