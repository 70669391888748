import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { PlanetCard, shimmer } from 'shared/styles/styled'

/*  ========================== Planet G Token ============================== */

export const GLoadingText = styled.div`
  animation: ${shimmer} 3s;
  animation-iteration-count: infinite;
  background: transparent linear-gradient(270deg, #3037da 0%, #55b7ff 100%) 0% 0% ;
  background-size: 1000px 100%;
  border-radius: 10px;
`
export const GLoadingText1 = styled(GLoadingText)`
  height: 12px;
  margin-top: 4px;
  @media (min-width: ${screenSizes.mediaM}px) {
    height:: 16px;
    margin-top: 10px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height:: 19px;
  } 
`
export const GLoadingText2 = styled(GLoadingText)`
  width: 50px;
  height: 14px;
  border-radius: 10px;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height: 17px;
  }
`
export const GLoadingText3 = styled(GLoadingText)`
  width: 80%;
  height: 18px;
  border-radius: 10px;
  margin-top: 10px;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height: 20px;
  }
`
export const GLoadingText4 = styled(GLoadingText)`
  width: 70%;
  height: 18px;
  border-radius: 10px;
  margin-bottom: 3px;
  @media (min-width: 400px) {
    width: 50%;
  }
`


export const GTokenWrapper = styled.div``

export const GTokenWeb = styled.div`
  display: none;
  @media (min-width: ${screenSizes.mediaM}px) {
    display: block;
  }
`
export const GTokenMob = styled.div`
  display: block;
  @media (min-width: ${screenSizes.mediaM}px) {
    display: none;
  }
`

export const GTokenCont = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  margin-top: 15px;
  grid-gap: 10px;
  .right-space{
      margin-right: 10px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-top: 30px;
    grid-gap: 20px;
  }
`
export const GTokenCard = styled(PlanetCard)`
  padding: 20px;
  button{
      font-size: 15px;
      padding: 10px 20px;
      margin-left: 10px;
      @media (min-width: ${screenSizes.mediaXLL}px) {
        font-size: 17px;
        padding: 15px 30px;
    } 
  }
  .inst-unStake{
    cursor: pointer;
  }
  .unStaking-box{
    justify-content: center;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 40px 30px 30px 30px;
  }
`
export const GCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-bottom: 18px;
  }
`
export const GActionCont = styled.div`
  display: flex;
  align-items: center;
  
  img{
    width: 50px;
    height: 50px;
    margin-left: 10px;
    cursor: pointer;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 40px;
      height: 40px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 50px;
      height: 50px;
    }
    &.cancelUnstake{
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`
export const GCardDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.borderColor};
  margin: 15px 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 30px 0;
  }
`
export const GTokenTxt = styled.p`
 font-size: 14px;
 line-height: 18px;
 color: ${colors.white};
 font-family: MontserratBold;
 margin: 0;
 @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 24px;
    line-height: 29px;
  }
`
export const GCardText = styled.p`
 font-size: 12px;
 line-height: 15px;
 color: ${colors.white};
 font-family: MontserratSemiBold;
 opacity: 0.4;
 text-align: center;
 margin: 0;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 16px;
    line-height: 19px;
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    text-align: left;
  }
`
export const GTokenValTxt = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 4px 0 0 0;
  opacity: 0.4;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
    margin: 10px 0 0 0;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 19px;
    line-height: 23px;
  } 
`
export const GTokenImgCont = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-right: 10px;
  width: 60px;
  height: 60px;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    width: 85px;
    height: 85px;
  }
  img{
    width: 50px;
    height: 50px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
        width: 70px;
        height: 70px;
      }
   }
  .sync-img {
    position: absolute;
    bottom: 7px;
    left: 35px;
    width: 20px;
    height: 20px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
        width: 30px;
        height: 30px;
        left: 50px;
        bottom: 10px;
    }
  }
`
export const TextBox = styled.div`
  font-size: 12px;
  font-family: MontserratSemiBold;
  padding: 10px 15px;
  box-shadow: 0px 0px 10px #00000029;
  color: ${colors.lightBlue};
  background: rgba(85,183,255, 0.2);
  margin-right: 10px;
  border-radius: 100px;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 12px;
    padding: 10px 20px;
  } 
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 14px;
    padding: 8px 16px;
  } 
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 15px;
    padding: 10px 20px;
  } 
`
export const GreenTxtBox = styled(TextBox)`
  color: ${colors.lightGreen};
  background: rgba(85,255,118, 0.2);
`
export const GTokenInfoCont = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StopUnStakingBox = styled.div`
  margin: 0 0 0 3px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 10px 0 0 10px;
  }
  img{
    width: 26px;
    height: 26px;
    cursor: pointer;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 22px;
      height: 22px;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 24px;
      height: 24px;
    }  
  }
`
export const GCardNumTxt = styled.p<any>`
  font-size: 18px;
  line-height: 22px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 10px 0 0 0;
  text-align: center;
  opacity: ${(props: any) => props.isValZero ? 0.4 : 1};
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 20px;
    line-height: 24px;
  } 
`
export const GCardDateTxt = styled.p<any>`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0 0 3px 0;
  opacity: ${(props: any) => props.light ? 0.4 : 1};
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 18px;
    line-height: 22px;
    margin: 10px 0 0 0;
    text-align: center;
  } 
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 20px;
    line-height: 24px;
  } 
`
export const GAquaStakeCont = styled.div`
  display: flex;
  align-items: center;
  img{
    width: 24px;
    height: 24px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 30px;
      height: 30px;
    } 
  }
  p{
    font-size: 12px;
    color: ${colors.white};
    font-family: MontserratBold;
    margin: 0 6px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      font-size: 14px;
      margin: 0 8px;
    } 
    @media (min-width: ${screenSizes.mediaXXL}px) {
      font-size: 15px;
      margin: 0 10px;
    } 
  }
`
export const StakeTxtImgCont = styled.div`
  border-radius: 39px;
  box-shadow: 0px 0px 10px #00000029;
  width: 100px;
  padding: 0;
  transition: 0.3s;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    width: 110px;
  }
  &:hover{
    padding: 0 10px 0 0;
  }
`
export const StakeTxtImgBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 10px 20px;
  background: rgba(85,255,118, 0.2);
  box-sizing: border-box;
  border-radius: 39px;
  cursor: pointer;
  p{
    color: ${colors.lightGreen};
    margin: 0 !important;
  }
  img{
    width: 24px;
    height: 14px;
    margin-left: 5px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 15px;
  } 
  &:hover{
    background: rgba(85,255,118, 0.4);
  }
`
export const GStakeIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 35px;
  border-radius: 10px;
  box-sizing: border-box;
  img{
    width: 50px;
    height: 35px;
  }
`

/*  ============================= G token Mob ======================= */

export const GTokenMCard = styled(PlanetCard)`
  padding: 20px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  button{
      font-size: 12px;
      height: 35px;
      border-radius: 10px;
    }
  .mGToken-icon{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    @media (min-width: 380px) {
      width: 44px;
      height: 44px;
    } 
  }
  .mTop-space{
    margin-top: 10px;
  }
  .mBtn-space{
    margin-top: 15px;
  }
  .comp-icon{
    width: 24px;
    height: 18px;
    margin-bottom: 2px;
  }
  .m-gamma-icon{
    width: 16px;
    height: 16px;
    margin: 0 4px 3px 0;
  }
`
export const GMCardBText = styled.p<any>`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0 0 3px 0;
  opacity: ${(props: any) => props.isValZero ? 0.4 : 1};
`
export const CompIconBox = styled.div`
  position: relative;
  width: 22px;
  height: 12px;
  box-sizing: border-box;
  margin-bottom: 11px;
  .second-icon{
    position: absolute;
    left: 12px;
    top: 0;
  }
`
export const CompIcon = styled.div`
  width: 10px;
  height: 10px;
  border: 2px solid ${colors.lightGreen};
  border-radius: 50%;
`
export const MGBtnGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  .mInst-icon{
    width: 35px;
    height: 35px;
  }
`
export const OpenCloseBox = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  opacity: ${(props: any) => props.isActive ? 1 : 0.4};
  p{

    font-size: 12px;
    line-height: 15px;
    color: ${colors.white};
    font-family: MontserratSemiBold;
    margin: 0;
  }
  img{
    width: 9.41px;
    height: 6px;
    margin-left: 5px;
    transform: ${(props: any) => (props.isActive ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: 0.3s;
  }
`
/* ======================================= Vault Modals ========================================== */

export const ModalCont = styled.div`
  width: 100%;
  max-width: 563px;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaM}px) {
    width: 563px;
    max-width: 563px;
  }
  .top-space{
    margin-top: 20px;
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-top: 30px;
    }
  }
  .bottom-space{
   margin-bottom: 20px;
  }
`
export const ModalCard = styled(PlanetCard)`
  background: ${colors.themeBackground};
  border: 1px solid ${colors.borderColor};
  border-radius: 20px;
  box-shadow: 0px 3px 10px #00000033;
  padding: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 30px;
  }
  .close-icon{
    width: 18px;
    height: 18px;
    cursor: pointer;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 20px;
      height: 20px;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 24px;
      height: 24px;
    }
  }
  .info-space{
    margin-top: 10px;
  }
  button{
    border-radius: 20px;
    font-size: 14px;
    height: 40px !important;
    margin-top: 27px;
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 20px;
      height: 60px !important;
    }
  }
  .apy-val{
    color: ${colors.lightGreen};
  }
  .inst-icon{
    width: 11px;
    height: 19px;
    margin-right: 7px;
  }
  .inst-confirm-btn{
    margin-top: 15px;
  }
`
export const ModalInputCont = styled.div<any>`
  background: ${colors.inputBg};
  border: 1px solid ;
  border-color: ${(props: any) => props.isActive && props.colorVal ? props.colorVal : colors.borderColor};
  border-radius: 20px;
  box-shadow: 0px 3px 10px #00000033;
  padding: 20px;
  margin-top: 20px;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 30px;
    margin-top: 30px;
  }
  img{
    width: 20px;
    height: 20px;
    margin: 0 5px 0 10px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 16px;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 30px;
      height: 30px;
      margin: 0 10px 0 20px;
    }
  }
  .inst-unStake-max{
    color: ${colors.yellow};
    background: ${colors.yellowTransBg};
    &:hover {
      background: ${colors.yellowTransHover};
    }
  }
  .unStake-max{
    color: ${colors.red};
    background: ${colors.redTransBg};
    &:hover {
      background: ${colors.redTransHover};
    }
  }
`
export const ModalInput = styled.input`
  width: 100%;
  background: transparent;
  color: ${colors.white};
  font-size: 16px;
  line-height: 20px;
  font-family: MontserratBold;
  border: none;
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0 5px 0 0;
  @media(min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 29px;
    margin: 0 10px 0 0;
  }
`
export const ModalTitle = styled.h1`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media(min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 29px;
  }
  @media(min-width: ${screenSizes.mediaXXL}px) {
    font-size: 30px;
    line-height: 37px;
  }
`
export const ExchangeRateBox = styled.div`
  margin-top: 15px;
  text-align: right;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  opacity: 0.4;
`
export const ModalText = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  @media(min-width: ${screenSizes.mediaM}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media(min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
`
export const ModalBText = styled(ModalText)`
  font-family: MontserratBold;
`
export const ModalInfoText = styled.p<any>`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity:${(props: any) => props.light ? 0.4 : 1};
  @media(min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 19px;
  }
`
export const UnderstandFeeBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: -13px;
`
export const UnderstandText = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: 0.3;
`
export const UnderstandCheckBox = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  background: ${(props: any) => props.isActive ? colors.yellow : colors.inputBg};
  border: 1px solid ${colors.borderColor};
  cursor: ${(props: any) => props.isCursorShow ? 'pointer' : 'initial'};
  img{
    width: 11px;
    height: 19px;
  }
`

export const CrossButton = styled.p`
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  font-size: 35px;
  cursor: pointer;
  color: #FFFFFF;
  margin: 0px;
  margin-top: -3px;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 40px;
    line-height: 34px;
  }
`
