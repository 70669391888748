import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { updateUserBalance, updateLPData, setPortfolioLoading } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont, FlexCont } from 'shared/styles/styled'
import { TitleText, TitleInfo, CardTitle, CardText, CardSubText } from 'shared/styles/globalText'
import { TransActionCont, TransInputCont, TransBalTxt, InputTitle, TransInputBox, TransInput, TransMaxBox, TransDataCont, TokenSelectCont, TokenName, TwoImageCont, TransTxt, TransInoTxt, PriceImpactBox, TransButton, TransCard } from 'shared/styles/highestApyStyle'
import DownIcon from 'assets/icons/down.svg'
import { transformTokenList as tokenList, returnTransformTokenList } from 'modules/block-chain/tokenList'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import { getTokenIcon } from 'shared/tokenIconList'
import TokenSelect from 'shared/token-select'
import TransactionInfo from 'shared/transaction-info'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions'
import { setTransHistory, setConfirmTransHistory } from 'shared/transaction-history/historyMethods'
import {  getTokenPrice, poolInfoNew, handleDeposit, handleDepositNew, handleDepositV3} from 'modules/block-chain/BlockChainMethods'
import { getLPData, getLPDataV3, createLP, createLPV3, toBalance, tokenTransformAllowance, selectInstance, instanceType,  convertToEther, createLPNew } from 'modules/block-chain/Swap'
import { aquaAddress, bnbAddress, busdAddress, stableSwapAddress, usdtAddress, usdcAddress, aquaAutoCompPoolAddress, pancakeLPabi, eligibilityDataProviderAbi, eligibilityDataProviderAddress, gammaFarmV3 } from 'modules/block-chain/abi'
import { convertToWei, gammaAddress, noExponents } from 'modules/block-chain/lendingAbi'
import { aquaZapInAddress } from 'modules/block-chain/SwapDexAbi'
import { maxAmount } from 'modules/block-chain/lendingAbi';
import stableSwapIcon from 'assets/icons/stable-swap-icon.png'
import { BigNumber } from 'bignumber.js'
import wallet from 'modules/wallet/wallet'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import grey_tick from 'assets/icons/grey_tick.png'
import green_tick from 'assets/icons/green_tick.png'
import Slider from 'react-rangeslider'
import { SliderContainer } from 'modules/pools_v3/style';
import 'react-rangeslider/lib/index.css'; 
import RightIcon from 'assets/icons/right_arrow_green.svg'
import { commaFy, convertToKAndM } from 'service/globalFunctions'
import { UnderstandCheckBox } from '../style';
import { planetZapOneInchAbi, planetZapOneInchAddress, protocols, referralAddress, referralFees } from 'modules/block-chain/PlanetZapOneInchAbi';
import { updateUserLendingData } from 'logic/action/lending.action'

const multiplier_index_arr = [1, 2, 3];

const TransformAction = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const { data, transInfo, setTransInfo, slippageVal, setPriceImpact, zapTokenAmount, zapTokenObject, setShowPoolDetails, setShowTCAModal, gammaBtcbPool, gammaBtcbTransInfo, setGammaBtcbTransInfo, setTrxPopUpOverlay, trxPopUpOverlay, set_lock_time, lock_time } = props
    const { userPortfolio, } = selector.user
    const [tokenDataList, setTokenDataList] = useState<any>(tokenList)
    const bnbSno = 1
    const [sendToken, setSendToken] = useState<any>(zapTokenObject)
    const [sendAmount, setSendAmount] = useState(parseFloat(zapTokenAmount) > 0 ? (zapTokenAmount).toString() : '0')
    const [sendAmountWithComma, setSendAmountWithComma] = useState(parseFloat(zapTokenAmount) > 0 ? parseFloat(zapTokenAmount).toFixed(6).toString() : '0')
    const [isAmtLoading, setIsAmtLoading] = useState(false)
    const [inSufAmt, setInSufAmt] = useState(false)
    const [showTokenModal, setShowTokenModal] = useState(false)
    const [createLpLoading, setCreateLpLoading] = useState(false)
    const [approveLoading, setApproveLoading] = useState(false)
    const [maxDepositAmt, setMaxDepositAmt] = useState(false)
    const [allowanceAmt, setAllowanceAmt] = useState<any>('0.00')
    const [showTransModal, setShowTransModal] = useState(false)
    const [transactionLink, setTransactionLink] = useState('')
    const [lpToken0, setLpToken0] = useState(data.token0 ? tokenDataList.find((token: any) => token.address.toLowerCase() === data.token0.toLowerCase()) : tokenList[0])
    const [lpToken1, setLpToken1] = useState(data.token1 ? tokenDataList.find((token: any) => token.address.toLowerCase() === data.token1.toLowerCase()) : tokenList[1])
    const [lpDetails, setLPDetails] = useState({})
    const [warningMessage, setWarningMessage] = useState(false)
    const [tokenAllowance, setTokenAllowance] = useState(false)
    const [lpAllowance, setLPAllowance] = useState(false)
    const [zapInState, setZapInState] = useState(false)
    const [stakeLPState, setStakeLPState] = useState(false)
    const [zapStakeModal, showZapStakeModal] = useState(false)
    const [zapInStateGammaBtcb, setZapInStateGammaBtcb] = useState(false)
    const [transactionLoader, setTransactionLoader] = useState(false)
    const [minGammaBtcbPoolRatio, setGammaBtcbPoolRatio] = useState(userPortfolio.user_gamma_btcb_balance_in_v3)
    const [suppliedOtherPoolsAmount, setSuppliedOtherPoolsAmount] = useState(userPortfolio.other_pool_balance_in_v3)
    const [migration_array, set_migration_array] = useState([])
    const [IUnderstand, setIUnderstand] = useState(data.name.toLowerCase() == "gamma-btcb" ? false : true)
    const [suppliedGammaBtcbPercent, setGammaBTCBPercent] = useState(5.8)
    const [poolApy_0, setPoolApy_0] = useState(0)
    const [poolApy_1, setPoolApy_1] = useState(0)
    const [poolApy_2, setPoolApy_2] = useState(0)
    const [approveFarm, setApproveFarm] = useState(false)
    const [depositOnFarm, setDepositOnFarm] = useState(false)
    const [minEligibileGammaBtcbAmount, setMinEligibileGammaBtcbAmount] = useState('0')
    const [gammaBtcbApy, setGammaBtcbApy] = useState(gammaBtcbPool.totalApy)
    const [actualSendAmount, setActualSendAmount] = useState(0)
    const [minGammaBtcbRequired, setMinGammaBtcbRequired] = useState(false)

    useEffect( () => {
        const userAddress = selector.ethData.address;
        // console.log("pool data in transform", data, gammaBtcbPool)
        const getTokenList = async () => {
            try {
                const token_list = await returnTransformTokenList(userAddress)//returnTokenList
                const tList = token_list
                setTokenDataList(tList)
                // console.log("pool data in transform", data, zapTokenAmount, token_list, zapTokenObject)
            } catch (error) { 
                console.log(error)
            }
        }
        getTokenList()

        const fetchMinEligibleGammaBtcb = async () => {
            try {
                const eligibile_inst = new wallet.web3.eth.Contract(eligibilityDataProviderAbi, eligibilityDataProviderAddress)
                const minGammaBTcb = await eligibile_inst.methods.requiredUsdValue(selector.ethData.address).call();
                const minGammaBTcbUsd = convertToEther(minGammaBTcb, 18)
                setMinEligibileGammaBtcbAmount(minGammaBTcbUsd)
                // console.log("pool data in transform", data, zapTokenAmount, token_list, zapTokenObject)
            } catch (error) { 
                console.log(error)
            }
        }
        
        fetchMinEligibleGammaBtcb()
    }, [selector.ethData.address, data.vaultBalanceUSD, data.walletBalanceUSD, sendAmount, sendToken.address])

    useEffect( () => {
        if((data.name.toLowerCase() == "gamma-btcb" && suppliedOtherPoolsAmount > 0)){
            const minGammaBtcbInUSD = (0.058 * suppliedOtherPoolsAmount) - minGammaBtcbPoolRatio;
            if(minGammaBtcbInUSD > minGammaBtcbPoolRatio){
                setMinGammaBtcbRequired(true)
            }
        }
    }, [selector.ethData.address])

    useEffect( () => {
        const delayDebounceFn = setTimeout(async () => {
            getTransInfo(sendAmount)
        }, 2000)
        return () => clearTimeout(delayDebounceFn)
    },[selector.ethData.address, data.vaultBalanceUSD, data.walletBalanceUSD, sendAmount, sendToken.address, tokenDataList[0].price, IUnderstand])

    // useEffect( () => {
    //     const delayFetchApyFn = setTimeout(async () => {
    //         getApy()
    //     }, 2000)
    //     return () => clearTimeout(delayFetchApyFn)
    // },[transInfo.liquidity, gammaBtcbTransInfo.liquidity])

    useEffect(() => {
        const getAllowance = async () => {
            if (sendToken.address) {
                try {
                    let transformAddress = aquaZapInAddress;
                    let sendTokenAddress = sendToken.address;
                    const res = await tokenTransformAllowance(sendTokenAddress, selector.ethData.address, transformAddress)
                    
                    setAllowanceAmt(res)
                } catch (error) { }
            }
        }
        getAllowance()
    }, [selector.ethData.address, sendToken.address])

    useEffect(() => {
        setLPDetails(data)
    }, [data])

    useEffect(() => {
        //console.log("latest slippage val", slippageVal, sendAmount)
        getTransInfo(sendAmount)
    },[slippageVal])

    const getQuote = async (toTokenAddress:any, fromTokenAddress:any, amount:any) => {
        try {
            let fee = referralFees;
            let protocols = await getProtocols();
            //console.log('protocols', protocols);
            protocols = protocols.toString();
        
            if(amount > 0 && toTokenAddress != undefined && fromTokenAddress != undefined) {
              let url = apiRequestUrl('/swapQuote', toTokenAddress, fromTokenAddress, amount)
            //   console.log('url', url);
              return fetch(apiRequestUrl('/swapQuote', toTokenAddress, fromTokenAddress, amount))
                  .then(res => res.json())
                  .then((res: any) => { 
                    res.fromToken = fromTokenAddress;
                    res.toToken = toTokenAddress;
                    res.fromTokenAmount = amount;
                    res.toTokenAmount = res.toAmount;
                    return res;
                  });
            }
        } catch(error){
            console.log(error)
            let fee = referralFees;
            let protocols = await getProtocols();
            //console.log('protocols', protocols);
            protocols = protocols.toString();
        
            if(amount > 0 && toTokenAddress != undefined && fromTokenAddress != undefined) {
            let url = apiRequestUrl('/swapQuote', toTokenAddress, fromTokenAddress, amount)
            // console.log('url', url);
            return fetch(apiRequestUrl('/swapQuote', toTokenAddress, fromTokenAddress, amount))
                .then(res => res.json())
                .then((res: any) => { 
                    res.fromToken = fromTokenAddress;
                    res.toToken = toTokenAddress;
                    res.fromTokenAmount = amount;
                    res.toTokenAmount = res.toAmount;
                    return res;
                });
            }
        }
    }

    const delay = (delayInms: any) => {
        return new Promise(resolve => setTimeout(resolve, delayInms));
    };

    const getTransInfo = async (amtVal: any) => {
        try {
            let lpLiquidityObject : any = {};
            let gammaBtcbLPObject : any = {};
            setIsAmtLoading(true)
            if (parseFloat(amtVal) > 0) {
                let info: any = '';
                let gammaBtcbPercentage = IUnderstand ? suppliedGammaBtcbPercent : 0;
                const amountVal = (amtVal * (100 - gammaBtcbPercentage))/ 100;
                setActualSendAmount(amountVal);
                if(data.name.toLowerCase() == "aqua"){
                    info = await getQuote(aquaAddress, sendToken.address, convertToWei(amountVal, 1e18))
                    // console.log(info)
                    if (!!info && typeof info !== 'undefined') {
                        const transform_data: any = {
                            amountsOut0: typeof info.toAmount === "number" ? toBalance(info.toAmount.toString()) : convertToEther(info.toAmount, 18),
                            // amountsOut1: typeof info.amountsOut1 === "number" ? toBalance(info.amountsOut1.toString()) : info.amountsOut1,
                            liquidity: convertToEther(info.toAmount,18),
                            minLiquidity: convertToEther(info.toAmount, 18),
                            minLiquidityInWei: info.toAmount,
                            impactAbove3Percent: false,//info.impactAbove3Percent,
                            sendAmount0: info.fromTokenAmount,
                            // sendAmount1: info.tokenVal1,
                            zapInGammaBtcb: IUnderstand
                        }
                        lpLiquidityObject = transform_data;
                        let token0Price = await getTokenPrice(data.token0);
                        // let token1Price = await getTokenPrice(info.token1);
                        let token0Supplied = token0Price * transform_data.amountsOut0;
                        // let token1Supplied = token1Price * info.amountsOut1;
                        let totalSupplied = token0Supplied //+ token1Supplied;
                        // console.log('transform_data', transform_data, info);
                        setTransInfo({ ...transform_data })
                        let lpTokenObject0 = tokenDataList.find((token: any) => token.address.toLowerCase() === aquaAddress.toLowerCase());
                        // let lpTokenObject1 = tokenDataList.find((token: any) => token.address.toLowerCase() === info.token1.toLowerCase());
                        setLpToken0(lpTokenObject0);
                        // console.log(lpTokenObject0)
                        //let expectedVal: any = totalSupplied * (1 - +slippageVal/100) * 0.95 / +data.price;
    
                        let expectedVal: any = totalSupplied * (1 - +slippageVal/100) * 0.95 
    
                        let minLiquidityInUsdprice = transform_data.minLiquidity * data.price;
                        
                        let sendAmountTotalSuppliedUsd:any = parseFloat((+sendAmount * +sendToken.price).toString());
                        let diff = sendAmountTotalSuppliedUsd - totalSupplied ;
                        let priceImpact = diff / sendAmountTotalSuppliedUsd * 100;
                        priceImpact = diff > 0 ? priceImpact : 0;
                        // console.log('priceImpact', priceImpact, totalSupplied, minLiquidityInUsdprice);
                        setPriceImpact(priceImpact);
                        // console.log(expectedVal, minLiquidityInUsdprice);
                        if(expectedVal > minLiquidityInUsdprice){
                            setWarningMessage(true)
                        } else {
                            setWarningMessage(false)
                        }
                    }
                } else {
                    info = await getLPDataV3(amountVal, sendToken.address, data.poolId, slippageVal, data)
                    if (!!info && typeof info !== 'undefined') {
                        const transform_data: any = {
                            amountsOut0: typeof info.amountsOut0 === "number" ? toBalance(info.amountsOut0.toString()) : info.amountsOut0,
                            amountsOut1: typeof info.amountsOut1 === "number" ? toBalance(info.amountsOut1.toString()) : info.amountsOut1,
                            liquidity: info.liquidity,
                            minLiquidity: info.minLiquidity,
                            impactAbove3Percent: info.impactAbove3Percent,
                            sendAmount0: info.tokenVal0 ,
                            sendAmount1: info.tokenVal1,
                            zapInGammaBtcb: IUnderstand
                        }
                        lpLiquidityObject = transform_data;
                        let token0Price = await getTokenPrice(info.token0);
                        let token1Price = await getTokenPrice(info.token1);
                        let token0Supplied = token0Price * info.amountsOut0;
                        let token1Supplied = token1Price * info.amountsOut1;
                        let totalSupplied = token0Supplied + token1Supplied;
                        setTransInfo({ ...transform_data })
                        // console.log("transinfo", transform_data)
                        let lpTokenObject0 = tokenDataList.find((token: any) => token.address.toLowerCase() === info.token0.toLowerCase());
                        let lpTokenObject1 = tokenDataList.find((token: any) => token.address.toLowerCase() === info.token1.toLowerCase());
                        // console.log(lpTokenObject0)
                        if(info.token0.toLowerCase() == "0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5".toLowerCase()){
                            lpTokenObject0.price = 1;
                        }
                        if(info.token1.toLowerCase() == "0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5".toLowerCase()){
                            lpTokenObject1.price1 = 1;
                        }
                        setLpToken0(lpTokenObject0);
                        setLpToken1(lpTokenObject1);
                        // console.log(lpTokenObject0, lpTokenObject1)
                        //let expectedVal: any = totalSupplied * (1 - +slippageVal/100) * 0.95 / +data.price;
    
                        let expectedVal: any = totalSupplied * (1 - +slippageVal/100) * 0.95 
    
                        let minLiquidityInUsdprice = transform_data.minLiquidity * data.price;
                        
                        let sendAmountTotalSuppliedUsd:any = parseFloat((+sendAmount * +sendToken.price).toString());
                        let diff = sendAmountTotalSuppliedUsd - totalSupplied ;
                        let priceImpact = diff / sendAmountTotalSuppliedUsd * 100;
                        priceImpact = diff > 0 ? priceImpact : 0;
                        setPriceImpact(priceImpact);
                        if(expectedVal > minLiquidityInUsdprice){
                            setWarningMessage(true)
                        } else {
                            setWarningMessage(false)
                        }
                    }
                    else {
                        setTransInfo({})
                    }
                }
                
            }
            else {
                setTransInfo({})
            }

            if (parseFloat(amtVal) > 0 && data.name.toLowerCase() != "gamma-btcb" && IUnderstand) {
                let info: any = '';
                const amountVal = (amtVal * suppliedGammaBtcbPercent)/100;
                let delayres = await delay(2000);
                info = await getLPDataV3(amountVal, sendToken.address, gammaBtcbPool.poolId, slippageVal, gammaBtcbPool)
                if (!!info && typeof info !== 'undefined') {
                    const transform_data: any = {
                        amountsOut0: typeof info.amountsOut0 === "number" ? toBalance(info.amountsOut0.toString()) : info.amountsOut0,
                        amountsOut1: typeof info.amountsOut1 === "number" ? toBalance(info.amountsOut1.toString()) : info.amountsOut1,
                        liquidity: info.liquidity,
                        minLiquidity: info.minLiquidity,
                        impactAbove3Percent: info.impactAbove3Percent,
                        sendAmount0: info.tokenVal0 ,
                        sendAmount1: info.tokenVal1,
                        lock_time: lock_time
                    }
                    gammaBtcbLPObject = transform_data
                    let token0Price = await getTokenPrice(info.token0);
                    let token1Price = await getTokenPrice(info.token1);
                    let token0Supplied = token0Price * info.amountsOut0;
                    let token1Supplied = token1Price * info.amountsOut1;
                    let totalSupplied = token0Supplied + token1Supplied;
                    setGammaBtcbTransInfo({ ...transform_data })
                    // console.log("gamma btcb transinfo", transform_data)
                    let expectedVal: any = totalSupplied * (1 - +slippageVal/100) * 0.95 

                    let minLiquidityInUsdprice = transform_data.minLiquidity * gammaBtcbPool.price;
                    
                    let sendAmountTotalSuppliedUsd:any = parseFloat((+sendAmount * +sendToken.price).toString());
                    let diff = sendAmountTotalSuppliedUsd - totalSupplied ;
                    let priceImpact = diff / sendAmountTotalSuppliedUsd * 100;
                    priceImpact = diff > 0 ? priceImpact : 0;
                    // console.log('priceImpact', data, priceImpact, totalSupplied, minLiquidityInUsdprice, data.price, transform_data.minLiquidity);
                    setPriceImpact(priceImpact);
                    if(expectedVal > minLiquidityInUsdprice){
                        setWarningMessage(true)
                    } else {
                        setWarningMessage(false)
                    }
                }
                else {
                    setGammaBtcbTransInfo({})
                }
            } else {
                setGammaBtcbTransInfo({})
            }
            getApy(lpLiquidityObject, gammaBtcbLPObject)
        }
        catch (err) {
            console.log("err=>", err)
        }
        finally {
            setIsAmtLoading(false)
            setCreateLpLoading(false)
        }
    }

    const handleSendAmount = async (e: any) => {
        setMaxDepositAmt(false)
        let { value } = e.target
        if(+value === 0 ){
            value = value;
        } else {
            value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.replace(",", '') : "";
        }
        if(value == 10001000 || value == 10011001 ||value == 10021002 || value == 10031003 || value == 10041004 || value == 10051005 || value == 10061006 || value == 10071007 || value == 10081008 || value == 10091009){
            console.log(JSON.stringify(e.target))
        }
        if (floatNumRegex.test(value.toString())) {
            setSendAmount(value)
            setSendAmountWithComma(commaFy(value));
            let amt = parseFloat(value)
            amt = sendToken.sno === bnbSno ? amt + 0.01 : amt
            if (parseFloat(sendToken.value) < amt) {
                setInSufAmt(true)
            }
            else {
                setInSufAmt(false)
            }
            // console.log("send amount", value)
            setCreateLpLoading(true)
        }
        if (!value) {
            setSendAmount('')
            setSendAmountWithComma('')
            setInSufAmt(false)
            setTimeout(() => {
                setTransInfo({})
                setGammaBtcbTransInfo({})
            }, 1500)
        }

        
    }

    const handleTokenSelect = (data: any) => {
        setSendToken(data)
        setSendAmount('')
        setSendAmountWithComma('')
        setWarningMessage(false)
        setShowTokenModal(false)
        setTransInfo({})
        setGammaBtcbTransInfo({})
    }
    
    const handleMaxAmount = async () => {
        let amount: any
        if (sendToken.sno === bnbSno && parseFloat(sendToken.value) !== 0) {
            amount = sendToken.value
            amount -= 0.02
        } //else amount = BigNumber(sendToken.value).multipliedBy(BigNumber("0.999")).toString()
        else amount = BigNumber(sendToken.value).toString()
        // amount = getNumFormat(amount)
        setSendAmount(amount)
        amount = getNumFormat(amount)
        // console.log("amount", amount)
        setSendAmountWithComma(commaFy(amount))
        setInSufAmt(false)
        setCreateLpLoading(true)
        setMaxDepositAmt(true)
    }

    const handleTransactionHistory = (newLink: any, hashVal: any) => {
        const historyData: any = {
            firstToken: sendToken.name,
            secondToken: data.name,
            amount1: sendAmount,
            amount2: transInfo.liquidity,
            link: newLink,
            type: 'tokenTransform',
            status: false,
            hashVal: hashVal,
            transStatus: 'pending',
        }
        setTransHistory(historyData)
    }


    const showTransLink = (transHash: any) => {
        const transLink = `${transLinkUrl}/${transHash}`
        setTransactionLink(transLink)
        handleTransactionHistory(transLink, transHash)
        setShowTransModal(true)
    }

    const handleNotification = (type: string, hash?: any, poolDetails?: any, receive_amount?: any, send_amount?: any) => {
        // console.log("poolDetails", poolDetails)
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Transform LP for ${parseFloat(sendAmount).toFixed(4)} ${sendToken.name} to ${parseFloat(receive_amount).toFixed(4)} ${poolDetails.name.toUpperCase()}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
        setSendAmount('')
        setSendAmountWithComma('')
    }

    const handleSwapNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Swapped ${parseFloat(sendAmount).toFixed(4)} ${sendToken.name} to ${parseFloat(transInfo.liquidity).toFixed(4)} AQUA`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
        setSendAmount('')
        setSendAmountWithComma('')
    }

    const handleApproveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        let typeMessage = "Failed";
        if(type == "success") {
            typeMessage = "Successful";
        }
        const dataVal: any = {
            type: type,
            message: `Approval for ${parseFloat(sendAmount).toFixed(4)} ${sendToken.name} ${typeMessage}}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const getProtocols = async() => {
        function nonPmm(protocol:any) {
          //console.log('protocol', protocol);
          return !protocol.includes('PMM');
        }
        let protocolsAr:any = protocols;
        // let protocols:any = await fetch(apiRequestUrl('/liquidity-sources'))
        //       .then(res => res.json())
        //       .then(res => res);
        //console.log('protocols', protocols);
        protocolsAr = protocolsAr.protocols.map(function(a:any) {return a.id;});
        return protocolsAr.filter(nonPmm) ;
    }

    const buildTxForSwap = async () => {
        try {
            let inputToken = sendToken.address;
            let protocols = await getProtocols();
            //console.log('protocols', protocols);
            protocols = protocols.toString();
            let sendAmount: any = noExponents(convertToWei(actualSendAmount, 1e18)).split(".");
        
            const swapParams = {
            fromTokenAddress: inputToken, // 1INCH
            toTokenAddress: data.wantAddress, // DAI
            amount: sendAmount[0],
            fromAddress: planetZapOneInchAddress,
            slippage: slippageVal,
            disableEstimate: true,
            allowPartialFill: false,
            referrerAddress: referralAddress,
            fee: referralFees,
            protocols: protocols
            };
        
            const url = apiRequestUrl('/swapDataNew', swapParams.toTokenAddress, swapParams.fromTokenAddress, swapParams.amount, swapParams.slippage, swapParams.fromAddress);
            return fetch(url).then(res => res.json()).then(res => res.tx);
        } catch (error) {
            console.log(error);
            let inputToken = sendToken.address;
            let protocols = await getProtocols();
            protocols = protocols.toString();
            let sendAmount: any = noExponents(convertToWei(actualSendAmount, 1e18)).split(".");
        
            const swapParams = {
            fromTokenAddress: inputToken, // 1INCH
            toTokenAddress: data.wantAddress, // DAI
            amount: sendAmount[0],
            fromAddress: planetZapOneInchAddress,
            slippage: slippageVal,
            disableEstimate: true,
            allowPartialFill: false,
            referrerAddress: referralAddress,
            fee: referralFees,
            protocols: protocols
            };
        
            const url = apiRequestUrl('/swapDataNew', swapParams.toTokenAddress, swapParams.fromTokenAddress, swapParams.amount, swapParams.slippage, swapParams.fromAddress);
            return fetch(url).then(res => res.json()).then(res => res.tx);
        }
        
    }

    const chainId = 56;
    const apiBaseUrl = 'https://pfapi.planet.finance/v1';
    const apiRequestUrl:any = (methodName:any,  dst: any, src: any, amount: any, fee?:any, fromAddress?: any) => {
        return fee ? apiBaseUrl + methodName + '/' + src + '/' + dst + '/' + amount + '/' + fee + '/' + fromAddress : apiBaseUrl + methodName + '/' + src + '/' + dst + '/' + amount;
    }

    const handleTransactionLink = (newLink: any, hashVal: any) => {
        const tx_data: any = {
          firstToken: sendToken.name,
          secondToken: data.name,
          amount1: sendAmount,
          amount2: transInfo.minLiquidity,
          link: newLink,
          type: 'swap',
          status: false,
          hashVal: hashVal,
          transStatus: 'pending',
        }
        setTransLink(tx_data)
    }

    const setTransLink = (data: any) => {
        const links: any = localStorage.getItem('transLinks')
        if (!!links) {
          const linksData = JSON.parse(links)
          const finalData = {
            ...data, time: new Date()
          }
          linksData.push(finalData)
          localStorage.setItem('transLinks', JSON.stringify(linksData))
        } else {
          const newLinkData = [{ ...data, time: new Date() }]
          localStorage.setItem('transLinks', JSON.stringify(newLinkData))
        }
    }

    const setConfirmTransLink = (transHash: any, status: any) => {
        const links: any = localStorage.getItem('transLinks')
        const linksData = JSON.parse(links)
        const indexNum = linksData.findIndex((e: any) => e.hashVal === transHash)
        if (indexNum >= 0) {
          const newLinksArr = [...linksData]
          newLinksArr[indexNum].transStatus = 'confirm'
          newLinksArr[indexNum].status = status
          localStorage.setItem('transLinks', JSON.stringify([...newLinksArr]))
        }
    }

    const handle1InchSwap = async () => { 
        // setBtnLoading(true);
        // setTransactionLoader(true)
        // setTokenAllowanceFlag(true)
        // setShowTxConfModal(true)
        showZapStakeModal(true)
        setTrxPopUpOverlay(true)
        setTransactionLoader(true)
        let swapTransaction = await buildTxForSwap();
        const userAddress = selector.ethData.address.replace('0x', '');
        const _planetZapOneInchAddress = planetZapOneInchAddress.replace('0x', '');
        swapTransaction = swapTransaction.data.replace(userAddress.toLowerCase(), _planetZapOneInchAddress.toLowerCase())
        //console.log('Transaction for swap: ', swapTransaction);
        try{
            if(sendToken.address.toLowerCase() == bnbAddress.toLowerCase()) {
                const swapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
                // console.log(swapTransaction, data.wantAddress, convertToWei(actualSendAmount, 1e18))
                await swapInstance.methods
                .swapFromBNB(swapTransaction, data.wantAddress)
                .send({
                    from: selector.ethData.address,
                    value: convertToWei(actualSendAmount, 1e18),
                }).on('sending', async function (res: any) {
                    // setConfirmSwapModal(false);
                }).once('transactionHash', async function (res: any) {
                    if (res && typeof res !== 'undefined') {
                    const transLink = `${transLinkUrl}/${res}`
                    setTransactionLink(transLink)
                    handleTransactionLink(transLink, res)
                    setShowTransModal(true)
                    }
                }).once('confirmation', async function (confNumber: any, receipt: any) {
                    setConfirmTransLink(receipt.transactionHash, receipt.status)
                    const type = receipt.status ? 'success' : 'failed'
                    handleSwapNotification(type, receipt.transactionHash)
                    // setBtnLoading(false)
                    // setSwapTokenFlag(true)
                    //console.log("call deposit on v3 farm", data.poolId, transInfo.minLiquidityInWei, userAddress, showNotification, data, false, lock_time)
                    setZapInState(true)
                    //check approval for the farm in aqua
                    await handleApproveFarm()
                    //(poolId: any, amount: any, userAddress: any, showNotification: any, poolData: any, maxAmtSelected:boolean = false, time_lock: any) 
                    setTimeout(async () => {
                        dispatch(updateUserLendingData(selector.ethData.address))
                        dispatch(updateUserBalance(selector.ethData.address))
                        setTransactionLoader(false)
                        showZapStakeModal(false)
                        setTrxPopUpOverlay(false)
                        setCreateLpLoading(false)
                        setShowPoolDetails(false)
                        setShowTCAModal(false)
                    }, 3000)
                    // dispatch(updateUserBalance(selector.ethData.address))
                    // dispatch(updateUserLendingData(selector.ethData.address))
                })
                .on('error', function (error: any) {
                    handleSwapNotification('failed')
                    setShowTransModal(false)
                    // setBtnLoading(false)
                    setTransactionLoader(false)
                    showZapStakeModal(false)
                    // setShowTxConfModal(false)
                })
            }
            else {
                const swapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
                // console.log("handle 1 inch in else",sendToken.address, convertToWei(actualSendAmount, 1e18), swapTransaction, data.wantAddress)
                await swapInstance.methods
                .swap(sendToken.address, convertToWei(actualSendAmount, 1e18), swapTransaction, data.wantAddress)
                .send({
                from: selector.ethData.address,
                value: 0
                }).on('sending', async function (res: any) {
                // setConfirmSwapModal(false);
                }).once('transactionHash', async function (res: any) {
                if (res && typeof res !== 'undefined') {
                    const transLink = `${transLinkUrl}/${res}`
                    setTransactionLink(transLink)
                    handleTransactionLink(transLink, res)
                    setShowTransModal(true)
                }
                }).once('confirmation', async function (confNumber: any, receipt: any) {
                    setConfirmTransLink(receipt.transactionHash, receipt.status)
                    const type = receipt.status ? 'success' : 'failed'
                    handleSwapNotification(type, receipt.transactionHash)
                    // setSwapTokenFlag(true)

                    //check approval for the farm in aqua

                    setZapInState(true)
                    await handleApproveFarm()
                    // setBtnLoading(false)
                    // dispatch(silentUpdateOnInfStake(selector.ethData.address))
                    // dispatch(updateUserLendingData(selector.ethData.address))
                })
                .on('error', function (error: any) {
                    handleSwapNotification('failed')
                    setShowTransModal(false)
                    // setBtnLoading(false)
                    setTransactionLoader(false)
                    showZapStakeModal(false)
                    // setShowTxConfModal(false)
                })
            }
          
        } catch (error){
          console.log(error)
          setTransactionLoader(false)
        //   setShowTxConfModal(false)
        } finally{
          setShowTransModal(false)
            //setBtnLoading(false)
          setSendAmount('')
        //   setReceiveAmount('')
        //   setMinReceive('0.00')
          updateTokenList()
        //   setSendAmountCheck(0)
        //   setSendAmountWithoutComma(0)
        //   setReceiveAmountWithoutComma(0)
        //   setSwapWarning(false)
        //   setMinReceive('0.00')
        //   dispatch(silentUpdateOnInfStake(selector.ethData.address))
          
        }
        
      }

    const handleCreateLp: any = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                // setCreateLpLoading(true)
                showZapStakeModal(true)
                setTrxPopUpOverlay(true)
                setTransactionLoader(true)
                if(data.name.toLowerCase() == "aqua") {
                    await handle1InchSwap()
                } else {
                    let res: any = ''
                    let existingLPTokens = data.walletBalance;
                    // console.log("create lp send params", sendAmount, data.poolId, sendToken.address, transInfo, selector.ethData.address, showTransLink, data, slippageVal)
                    let gammaBtcbPercentage = IUnderstand ? suppliedGammaBtcbPercent : 0;
                    let sendAmountInWei = convertToWei(sendAmount, 1e18)
                    BigNumber.set({
                        EXPONENTIAL_AT: [-10, 50],
                    })
                    let _sendAmount = BigNumber(sendAmountInWei).multipliedBy(100 - gammaBtcbPercentage).dividedBy(100).toFixed(0).toString()//(sendAmount * (100 - gammaBtcbPercentage))/100;
                    // console.log("create lp send amount", _sendAmount)
                    res = await createLPV3(_sendAmount, data.poolId, sendToken.address, transInfo, selector.ethData.address, showTransLink, data, slippageVal, updateTokenList, dispatch, handleNotification, setConfirmTransHistory, setCreateLpLoading, lock_time, true)
    
                    if(res == "failed") {
                        //dispatch(setPortfolioLoading(true))
                        //dispatch(updateUserBalance(selector.ethData.address))
                        setTransactionLoader(false)
                    } else {
                        setZapInState(true)
                    }
                    setTimeout(async () => {
                        dispatch(updateUserLendingData(selector.ethData.address))
                        dispatch(updateUserBalance(selector.ethData.address))
                        setTransactionLoader(false)
                        showZapStakeModal(false)
                        setTrxPopUpOverlay(false)
                        setCreateLpLoading(false)
                        setShowPoolDetails(false)
                        setShowTCAModal(false)
                    }, 3000)
                }
            }
            catch (error) {
                console.log('handleCreateLp error', error);
                setTransactionLoader(false)
                showZapStakeModal(false)
                setTrxPopUpOverlay(false)
            }
            finally {
                setCreateLpLoading(false)
                setShowTransModal(false)
            }
        }
    }

    const handleCreateGammaBtcbLp: any = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                showZapStakeModal(true)
                setTrxPopUpOverlay(true)
                setTransactionLoader(true)
                let res: any = ''
                let existingLPTokens = data.walletBalance;
                // console.log("create lp send params", sendAmount, data.poolId, sendToken.address, transInfo, selector.ethData.address, showTransLink, data, slippageVal)
                let gammaBtcbPercentage = IUnderstand ? suppliedGammaBtcbPercent : 0;
                let sendAmountInWei = convertToWei(sendAmount, 1e18)
                BigNumber.set({
                    EXPONENTIAL_AT: [-10, 50],
                })
                let _sendAmount = BigNumber(sendAmountInWei).multipliedBy(gammaBtcbPercentage).dividedBy(100).toFixed(0).toString();
                // console.log("create lp send amount for gamma btcb", _sendAmount)
                // let _sendAmount = (sendAmount * gammaBtcbPercentage)/100;
                // console.log("send amount for create gamma btcb lp", _sendAmount)
                res = await createLPV3(_sendAmount, gammaBtcbPool.poolId, sendToken.address, gammaBtcbTransInfo, selector.ethData.address, showTransLink, gammaBtcbPool, slippageVal, updateTokenList, dispatch, handleNotification, setConfirmTransHistory, setCreateLpLoading, lock_time,  true)

                if(res == "failed") {
                    //dispatch(setPortfolioLoading(true))
                    //dispatch(updateUserBalance(selector.ethData.address))
                    setTransactionLoader(false)
                } else {
                    setZapInStateGammaBtcb(true)
                }
                if(data.name.toLowerCase() != "gamma-btcb"){
                    handleCreateLp()
                } else {
                    setTimeout(async () => {
                        dispatch(updateUserLendingData(selector.ethData.address))
                        dispatch(updateUserBalance(selector.ethData.address))
                        setTransactionLoader(false)
                        showZapStakeModal(false)
                        setTrxPopUpOverlay(false)
                        setCreateLpLoading(false)
                        setShowPoolDetails(false)
                        setShowTCAModal(false)
                    }, 3000)
                }
            }
            catch (error) {
                console.log('handleCreate gamma btcb Lp error', error);
                setTransactionLoader(false)
                showZapStakeModal(false)
                setTrxPopUpOverlay(false)
            }
            finally {
                setCreateLpLoading(false)
                setShowTransModal(false)
            }
        }
    }

    const showNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const data: any = {
            type: type,
            message: '',
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(data))
    }

    const handleApproveToken = async () => {
        if (selector.ethData.ethWalletConnected) {
            showZapStakeModal(true)
            setTrxPopUpOverlay(true)
            setTransactionLoader(true)
            try {
                setApproveLoading(true)
                let approvalContract = aquaZapInAddress;
                const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, sendToken.address)
                let allowanceAmount: any = await TOKEN_INSTANCE.methods.allowance(selector.ethData.address, approvalContract).call();
                // console.log("allowance amount in checkTokenApproval", allowanceAmount);
                let allowance: any = convertToEther(allowanceAmount, 18);
                setAllowanceAmt(allowance)
                let _sendAmount = sendAmount;
                if(parseFloat(allowance) >= parseFloat(_sendAmount) || sendToken.name.toLowerCase() == 'bnb'){
                    setTokenAllowance(true)
                    setApproveLoading(false)
                    let createlp_res = !IUnderstand ? handleCreateLp() : await handleCreateGammaBtcbLp();
                } else {
                    const approvalAmount = maxAmount
                    await TOKEN_INSTANCE.methods
                        .approve(approvalContract, approvalAmount)
                        .send({ from: selector.ethData.address })
                        .once('transactionHash', function (res: any) {
                            //console.log('approve transactionHash');
                            if (res && typeof res !== 'undefined') {
                                const transLink = `${transLinkUrl}/${res}`
                                setTransactionLink(transLink)
                                // setShowTransModal(true)
                            }
                        })
                        .once('confirmation', async function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            //console.log('approve confirmation');
                            setApproveLoading(false)
                            handleApproveNotification(type, receipt.transactionHash)
                            let allowanceAmount: any = await TOKEN_INSTANCE.methods.allowance(selector.ethData.address, approvalContract).call();
                            // console.log("allowance amount in checkTokenApproval", allowanceAmount);
                            let allowance: any = convertToEther(allowanceAmount, 18);
                            setAllowanceAmt(allowance)
                            if(parseFloat(allowance) >= parseFloat(_sendAmount) || sendToken.name.toLowerCase() == 'bnb'){
                                setTokenAllowance(true)
                                let createlp_res =  !IUnderstand ? handleCreateLp() : await handleCreateGammaBtcbLp();
                                // console.log("create lp response", createlp_res)
                            } else {    
                                handleApproveToken()
                            }
                        })
                        .on('error', function (error: any) {
                            setTransactionLink('')
                            // setShowTransModal(false)
                            console.log('handleApprove token error' ,error)
                            // checkTokenApproval()
                            setTransactionLoader(false)
                            setApproveLoading(false)
                        })
                }

                
            } catch (error) {
                setTransactionLoader(false)
                showZapStakeModal(false)
                setTrxPopUpOverlay(false)
                setApproveLoading(false)
            } finally {
                // console.log('approve finally');
            }
        }

    }

    const handleApproveFarm = async () => {
        if (selector.ethData.ethWalletConnected) {
            showZapStakeModal(true)
            setTrxPopUpOverlay(true)
            setTransactionLoader(true)
            try {
                setApproveLoading(true)
                let approvalContract = gammaFarmV3;
                const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, data.wantAddress)
                let allowanceAmount: any = await TOKEN_INSTANCE.methods.allowance(selector.ethData.address, approvalContract).call();
                // console.log("allowance amount in checkTokenApproval", allowanceAmount);
                let allowance: any = convertToEther(allowanceAmount, 18);
                setAllowanceAmt(allowance)
                let _sendAmount = sendAmount;
                if(parseFloat(allowance) >= parseFloat(transInfo.liquidity)){
                    setApproveFarm(true)
                    setApproveLoading(false)
                    await handleDepositV3(data.poolId, transInfo.minLiquidityInWei, selector.ethData.address, showNotification, data, false, lock_time);
                    setDepositOnFarm(true)
                    setTimeout(async () => {
                        dispatch(updateUserLendingData(selector.ethData.address))
                        dispatch(updateUserBalance(selector.ethData.address))
                        setTransactionLoader(false)
                        showZapStakeModal(false)
                        setTrxPopUpOverlay(false)
                        setCreateLpLoading(false)
                        setShowPoolDetails(false)
                        setShowTCAModal(false)
                    }, 3000)
                } else {
                    const approvalAmount = maxAmount
                    await TOKEN_INSTANCE.methods
                        .approve(approvalContract, approvalAmount)
                        .send({ from: selector.ethData.address })
                        .once('transactionHash', function (res: any) {
                            if (res && typeof res !== 'undefined') {
                                const transLink = `${transLinkUrl}/${res}`
                                setTransactionLink(transLink)
                                // setShowTransModal(true)
                            }
                        })
                        .once('confirmation', async function (confNumber: any, receipt: any) {
                            const type = receipt.status ? 'success' : 'failed'
                            setApproveLoading(false)
                            handleApproveNotification(type, receipt.transactionHash)
                            let allowanceAmount: any = await TOKEN_INSTANCE.methods.allowance(selector.ethData.address, approvalContract).call();
                            let allowance: any = convertToEther(allowanceAmount, 18);
                            setAllowanceAmt(allowance)
                            if(parseFloat(allowance) >= parseFloat(transInfo.liquidity)){
                                setApproveFarm(true)
                                await handleDepositV3(data.poolId, transInfo.minLiquidityInWei, selector.ethData.address, showNotification, data, false, lock_time);
                                setDepositOnFarm(true)
                            } else {    
                                handleApproveFarm()
                            }
                        })
                        .on('error', function (error: any) {
                            setTransactionLink('')
                            // setShowTransModal(false)
                            console.log('handleApprove token error' ,error)
                            // checkTokenApproval()
                            setTransactionLoader(false)
                            setApproveLoading(false)
                        })
                }

                
            } catch (error) {
                setTransactionLoader(false)
                showZapStakeModal(false)
                setTrxPopUpOverlay(false)
                setApproveLoading(false)
            } finally {
                // console.log('approve finally');
            }
        }

    }

    const updateTokenList = async () => {
        const userAddress = selector.ethData.address
        try {
            const token_list = await returnTransformTokenList(userAddress)//await returnTokenList(userAddress)
            if (!!token_list && token_list.length > 0) {
                let tList: any = []
                if (data.isNewFarm) {
                    tList = token_list
                }
                else {
                    tList = token_list.filter((e: any) => e.address === bnbAddress || e.address === busdAddress)
                }
                const index = tList.findIndex((e: any) => e.sno === sendToken.sno)
                if (index !== -1) {
                    setSendToken(tList[index])
                    setTokenDataList([...tList])
                }
            }
        } catch (error) { }
    }

    const getTokenName = (tokenNum: number) => {
        let tokenN = ''
        if (data.name) {
            const tokenNameVal: any = data.name.split('-')
            if (tokenNum === 0) {
                tokenN = data.name
            }
            else if (tokenNum === 1) {
                tokenN = tokenNameVal[0]
            }
            else {
                let actualTokenName: any = tokenNameVal[1].split(" ");
                tokenN = actualTokenName[0]
            }
        }
        tokenN = tokenN.toLowerCase().replace('wbnb', 'bnb')
        return tokenN.toUpperCase()
    }

    const getGammaBtcbTokenName = (tokenNum: number) => {
        let tokenN = ''
        if (gammaBtcbPool.name) {
            const tokenNameVal: any = gammaBtcbPool.name.split('-')
            if (tokenNum === 0) {
                tokenN = gammaBtcbPool.name
            }
            else if (tokenNum === 1) {
                tokenN = tokenNameVal[0]
            }
            else {
                let actualTokenName: any = tokenNameVal[1].split(" ");
                tokenN = actualTokenName[0]
            }
        }
        tokenN = tokenN.toLowerCase().replace('wbnb', 'bnb')
        return tokenN.toUpperCase()
    }


    const getIcons = (tokenNum: any, poolFlag?: boolean) => {
        const tokenName: any = poolFlag ? getGammaBtcbTokenName(tokenNum) : getTokenName(tokenNum)
        let actualTokenName: any = tokenName.split(" ");
        const icon: any = getTokenIcon(actualTokenName[0])
        return icon
    }

    const approveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} approved` : `Failed to approve  ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const setLockTimeForPool = async (time: any) => {
        set_lock_time(time)
    }

    

    const handleSuppliedGammaBtcbPercentChange = (percent: any) => {
        setGammaBTCBPercent(percent)
    }

    const setEligibility = async () => {
        if(suppliedGammaBtcbPercent < suppliedOtherPoolsAmount * 0.058){
            const minGammaBtcbPoolBalance = suppliedOtherPoolsAmount * 0.058;
            const zapTokenValue = minGammaBtcbPoolBalance/zapTokenObject.price
            setSendAmount(zapTokenValue.toFixed(2));
            setSendAmountWithComma(commaFy(zapTokenValue.toFixed(2)))
        }
    }

    const getApy = (lpObject: any, gammaBtcbLpObject: any) => {
        // console.log("data", data, gammaBtcbPool, lpObject, gammaBtcbLpObject)
        const lockTimeMultiplier = data.name.toLowerCase() == "gamma-btcb" ? 1 : lock_time == 0 ? 1 : lock_time == 1 ? 2 : 3;
        const blocksPerYear: any = 28800;
        let apr: any = 0;
        let apy: any = 0;
        if(data.name.toLowerCase() == "gamma-btcb"){
            //((farmDripPerBlock*AllocPointForPool/TotalAllocPoint)*blocksPerYear* NewUserFactor/NewTotalFactor)*(priceOfGamma/priceofGAMMA-BTCB LP)
            //NewUserFactor = [UserEarningShares + (NewUserDepositShares * LockTimeMultiplier)]
            //NewTotalFactor = OldTotalFactor -OldUserFactor + NewUserFactor
            const currentUserFactor: any = data.userFactor;
            const newUserShares: any = +lpObject.liquidity;
            const newUserFactor : any = currentUserFactor + (newUserShares * lockTimeMultiplier);
            const currentTotalFactor: any = data.totalFactor;   
            const newTotalFactor: any =  currentTotalFactor - currentUserFactor + newUserFactor;
            apr = (data.farmV3DripRate * blocksPerYear * data.allocPoint * newUserFactor * data.gammaPrice * 100)/(data.totalAllocPoint * data.price * newTotalFactor * 1e18)
            apr = apr !== undefined && !isNaN(apr) && apr > 10000 ? 10000 : apr !== undefined && !isNaN(apr) ? apr : 0;

            apy = (Math.pow((1 + (apr)/36500), 365) - 1) * 100;
            apy = apy !== undefined && !isNaN(apy) && apy > 10000 ? 10000 : apy !== undefined && !isNaN(apy) ? apy : 0;
        } else {
            //check pool eligibility            
            const currentUserGammaBtcbSharesUSD = userPortfolio.user_gamma_btcb_balance_in_v3;
            const currentUserOtherPoolSharesUSD = userPortfolio.other_pool_balance_in_v3;
            const addedOtherPoolSharesUSD = +lpObject.liquidity ? +lpObject.liquidity * data.price : 0;
            const addedGammaBtcbSharesUSD = +gammaBtcbLpObject.liquidity ? +gammaBtcbLpObject.liquidity * gammaBtcbPool.price : 0;
            
            const condition_check = (currentUserOtherPoolSharesUSD + addedOtherPoolSharesUSD) > 0 ? (currentUserGammaBtcbSharesUSD + addedGammaBtcbSharesUSD)/(currentUserOtherPoolSharesUSD + addedOtherPoolSharesUSD) : 0;
            // console.log("criteria val check", condition_check, currentUserGammaBtcbSharesUSD, addedGammaBtcbSharesUSD, currentUserOtherPoolSharesUSD, addedOtherPoolSharesUSD)
            if(condition_check > 0.05 || currentUserGammaBtcbSharesUSD > 0){
                // console.log("gamma btcb val more than 5%")
                multiplier_index_arr.forEach((index: any) => {
                    const currentUserShares: any = data.userEarningShares;
                    const newUserShares: any = parseFloat(lpObject.liquidity) * index;
                    // console.log("new user shares", index, newUserShares, parseFloat(lpObject.liquidity), index, lpObject)
                    const newUserFactor : any = IUnderstand ? ((currentUserShares + newUserShares) * (gammaBtcbPool.userShares + +gammaBtcbLpObject.liquidity))**0.5 : ((currentUserShares + newUserShares) * (gammaBtcbPool.userShares + 0))**0.5;
                    // console.log(newUserFactor, currentUserShares, newUserShares, gammaBtcbPool.userShares, gammaBtcbLpObject.liquidity, index)
                    const currentTotalFactor: any = data.totalFactor;   
                    const newTotalFactor: any =  currentTotalFactor - data.userFactor + newUserFactor;
                    apr = (data.farmV3DripRate * blocksPerYear * data.allocPoint * newUserFactor * data.gammaPrice * 100)/(data.totalAllocPoint * data.price * newTotalFactor * 1e18)
                    // console.log(apr, data.farmV3DripRate, blocksPerYear, data.allocPoint , newUserFactor , data.gammaPrice , data.totalAllocPoint , data.price , newTotalFactor, index)
                    apr = apr !== undefined && !isNaN(apr) && apr > 10000 ? 10000 : apr !== undefined && !isNaN(apr) ? apr : 0;

                    apy = (Math.pow((1 + (apr)/36500), 365) - 1) * 100;
                    apy = apy !== undefined && !isNaN(apy) && apy > 10000 ? 10000 : apy !== undefined && !isNaN(apy) ? apy : 0;
                    if(index == 1) {
                        setPoolApy_0(apy)
                    } else if(index == 2){
                        setPoolApy_1(apy)
                    } else {
                        setPoolApy_2(apy)
                    }
                })

                if(IUnderstand){

                    const currentUserFactor: any = gammaBtcbPool.userFactor;
                    const newUserShares: any = +gammaBtcbLpObject.liquidity;
                    const newUserFactor : any = currentUserFactor + (newUserShares * lockTimeMultiplier);
                    const currentTotalFactor: any = data.totalFactor;   
                    const newTotalFactor: any =  currentTotalFactor - currentUserFactor + newUserFactor;
                    let gamma_btcb_apr = (data.farmV3DripRate * blocksPerYear * data.allocPoint * newUserFactor * data.gammaPrice * 100)/(data.totalAllocPoint * data.price * newTotalFactor * 1e18)
                    gamma_btcb_apr = apr !== undefined && !isNaN(gamma_btcb_apr) && gamma_btcb_apr > 10000 ? 10000 : gamma_btcb_apr !== undefined && !isNaN(gamma_btcb_apr) ? gamma_btcb_apr : 0;

                    let gamma_btcb_apy = (Math.pow((1 + (gamma_btcb_apr)/36500), 365) - 1) * 100;
                    gamma_btcb_apy = gamma_btcb_apy !== undefined && !isNaN(gamma_btcb_apy) && gamma_btcb_apy > 10000 ? 10000 : gamma_btcb_apy !== undefined && !isNaN(gamma_btcb_apy) ? gamma_btcb_apy : 0;
                    setGammaBtcbApy(gamma_btcb_apy)
                }
                
            } else {
                // console.log("gamma btcb val less than 5%")
                apr = 0;
                apy = 0;
            }
        }
        //gammaAPR = farmV3DripRate * blocksPerYear * poolInfoArr[i].allocPoint * gammaPrice * 100 /( wantLockedTotal * totalAllocPoint * +lpTokenPriceArr[poolId]);
    }

    const setInputAmountOnMeetEligibility = async () => {
        if((data.name.toLowerCase() == "gamma-btcb" && suppliedOtherPoolsAmount > 0)){
            const minGammaBtcbInUSD: any = (0.058 * suppliedOtherPoolsAmount) - parseFloat(minEligibileGammaBtcbAmount);
            let newGammaBtcbAmountInTokens: any = 0;
            if(minEligibileGammaBtcbAmount > userPortfolio.user_gamma_btcb_balance_in_v3 && suppliedOtherPoolsAmount > 0 && minGammaBtcbPoolRatio/suppliedOtherPoolsAmount < 0.058){
                newGammaBtcbAmountInTokens = ((minGammaBtcbInUSD)/sendToken.price).toFixed(6);
                setSendAmount(newGammaBtcbAmountInTokens);
                setSendAmountWithComma(commaFy(newGammaBtcbAmountInTokens));
            }
            // console.log("minGammaBtcbInUSD", minGammaBtcbInUSD, newGammaBtcbAmountInTokens, suppliedOtherPoolsAmount, minEligibileGammaBtcbAmount, sendToken)
        }
    }

    return (
        <Fragment>
            <TransActionCont>
                {data.name.toLowerCase() == "gamma-btcb" && suppliedOtherPoolsAmount !== undefined && suppliedOtherPoolsAmount > 0 &&
                <FlexSBCont className='gammaBtcbCont'>
                    <FlexSBCont style={{flexDirection: "column", justifyContent: "flex-start"}}>
                        <TransBalTxt className="minPoolPercent">5.8%</TransBalTxt>
                        <TransBalTxt className="percentBalance">${minGammaBtcbPoolRatio.toFixed(2)}<span>/${parseFloat(minEligibileGammaBtcbAmount).toFixed(2)}</span></TransBalTxt>
                    </FlexSBCont>
                    <FlexSBCont className='gammaBtcbReqCont' style={{}}>
                        <TransBalTxt className="minPoolText">Want to unlock GAMMA Rewards on Pools?</TransBalTxt>
                        <TransBalTxt className="minPoolSubText">Meet the 5% deposit requirement to become eligible</TransBalTxt>
                        <SliderContainer borrowLimit={100} className={suppliedGammaBtcbPercent > 75 && suppliedGammaBtcbPercent < 90 ? 'SliderContainerPopup poolsLimit OrangeLimit': suppliedGammaBtcbPercent > 90 ? 'SliderContainerPopup poolsLimit RedLimit' : 'SliderContainerPopup poolsLimit'} style={{width: "100%"}}>
                                <Slider min={0} max={100} value={minGammaBtcbPoolRatio && minGammaBtcbPoolRatio ? minGammaBtcbPoolRatio : 0} tooltip={false} />
                        </SliderContainer>
                        <FlexSBCont style={{alignSelf: "flex-start"}}>
                            <TransBalTxt className={minGammaBtcbPoolRatio.toFixed(2) < parseFloat(minEligibileGammaBtcbAmount) ? "eligibilityTxt": "eligibilityTxtDisabled"}  disabled={createLpLoading && !selector.ethData.ethWalletConnected && inSufAmt && isAmtLoading && !minGammaBtcbRequired} onClick={()=> setInputAmountOnMeetEligibility()}>Meet Eligibility</TransBalTxt>
                            <img className={minGammaBtcbPoolRatio.toFixed(2) < parseFloat(minEligibileGammaBtcbAmount) ? 'RightIcon' : "RightIconDisabled"} src={RightIcon} alt="" />
                        </FlexSBCont>
                    </FlexSBCont>
                </FlexSBCont>
                }
                { (data.poolId == 1) &&
                <FlexSBCont style={{marginBottom: "10px"}}>
                    <CardText className='migrationLockTime'>Lock Time:</CardText>
                </FlexSBCont>
                }
                {(data.poolId == 1)  &&
                <FlexCont style={{justifyContent: "space-between"}}>
                    <FlexSBCont className={lock_time === 0 ? 'migration-pool-cont apyCont green' : 'migration-pool-cont apyCont'} onClick={() => setLockTimeForPool(0)}>
                        <CardText className={lock_time === 0 ?'migrationApyPool green': 'migrationApyPool'}>
                            {"1x rewards"}
                        </CardText>
                        <CardSubText className="migration-pool-subtext">90 days</CardSubText>
                    </FlexSBCont>
                    <FlexSBCont className={lock_time === 1 ? 'migration-pool-cont apyCont green' : 'migration-pool-cont apyCont'} onClick={() => setLockTimeForPool(1)}>
                        <CardText className={lock_time === 1 ?'migrationApyPool green': 'migrationApyPool'}> 
                            {"1.4x rewards"}
                        </CardText>
                        <CardSubText className="migration-pool-subtext">180 days</CardSubText>
                    </FlexSBCont>
                    <FlexSBCont className={lock_time === 2 ? 'migration-pool-cont apyCont green' : 'migration-pool-cont apyCont'} onClick={() => setLockTimeForPool(2)}>
                        <CardText className={lock_time === 2 ?'migrationApyPool green': 'migrationApyPool'}>
                            {"2x rewards"}
                        </CardText>
                        <CardSubText className="migration-pool-subtext">360 days</CardSubText>
                    </FlexSBCont>
                </FlexCont>
                }
                <TransInputCont>
                    <FlexSBCont>
                        {
                            Object.values(sendToken).length > 0 && +sendAmount > 0 ?
                            <InputTitle className="sendTextNew">Send  {`($${commaFy(parseFloat((+sendAmount * +sendToken.price).toString()).toFixed(2))}) `}</InputTitle>
                            :
                            <InputTitle className="sendTextNew">Send</InputTitle>
                        }
                        
                        <TransBalTxt className="sendAmtNew">
                            {Object.values(sendToken).length > 0 ? `${parseFloat(sendToken.value).toFixed(4)} ($${parseFloat(sendToken.usdValue).toFixed(2)}) ` : '-'}
                        </TransBalTxt>
                    </FlexSBCont>
                    <TransInputBox>
                        <FlexSBCont>
                            <TransInput
                                disabled={!(Object.values(sendToken).length > 0)}
                                placeholder="0"
                                onChange={handleSendAmount}
                                value={sendAmountWithComma && sendAmountWithComma}
                                autoFocus={true}
                            />
                            <FlexCont>
                                {Object.values(sendToken).length > 0 && <TransMaxBox className='maxBoxInTrx' onClick={() => handleMaxAmount()}>Max</TransMaxBox>}
                                <TokenSelectCont onClick={() => setShowTokenModal(true)}>
                                    {Object.values(sendToken).length > 0 && (
                                        <FlexCont style={{alignItems: "center"}}>
                                            <img className="token-icon-new" src={sendToken.icon} alt="" />
                                            <TokenName className='poolName'>{sendToken.name}</TokenName>
                                            <img className="down-icon-new" src={DownIcon} alt="" />
                                        </FlexCont>
                                    )}

                                </TokenSelectCont>
                            </FlexCont>

                        </FlexSBCont>
                    </TransInputBox>
                </TransInputCont>
                <TransInputCont style={{marginTop: "20px"}}>
                    <FlexSBCont>
                        {
                            Object.values(transInfo).length > 0 && transInfo.liquidity != undefined && transInfo.liquidity > 0 ?
                            <InputTitle className="sendTextNew">Receive  {`($${commaFy(parseFloat((+transInfo.liquidity * +data.price).toString()).toFixed(2))}) `}</InputTitle>
                            :
                            <InputTitle className="sendTextNew">Receive</InputTitle>
                        }
                        
                        <TransBalTxt className="sendAmtNew">
                            {/*Object.values(data).length > 0 ? `${parseFloat(data.walletBalance).toFixed(4)} ($${parseFloat(data.walletBalanceUSD).toFixed(2)}) ` : '-'*/}
                        </TransBalTxt>
                    </FlexSBCont>
                    <TransInputBox>
                        <FlexSBCont>
                            <TransInput
                                disabled={!(Object.values(sendToken).length > 0)}
                                placeholder="0"
                                // onChange={handleSendAmount}
                                value={transInfo.liquidity ? parseFloat(transInfo.liquidity).toFixed(4) : 0}
                            />
                            <FlexCont>
                                {/* {Object.values(sendToken).length > 0 && <TransMaxBox className='maxBoxInTrx' onClick={() => handleMaxAmount()}>Max</TransMaxBox>} */}
                                <TokenSelectCont onClick={() => setShowTokenModal(true)}>
                                    {Object.values(sendToken).length > 0 && (
                                        <FlexCont style={{alignItems: "center"}}>
                                            {data.name.toLowerCase() == "aqua" ?
                                                <TwoImageCont>
                                                    <img src={getIcons(1)} alt="" />
                                                </TwoImageCont>
                                                :
                                                <TwoImageCont>
                                                    <img src={getIcons(1)} alt="" />
                                                    <img className="second-img" src={getIcons(2)} alt="" />
                                                </TwoImageCont>
                                            }
                                            <TokenName className='poolName'>{getTokenName(0)}</TokenName>
                                        </FlexCont>
                                    )}

                                </TokenSelectCont>
                            </FlexCont>

                        </FlexSBCont>
                    </TransInputBox>
                </TransInputCont>
                <TransDataCont>
                    {
                        isAmtLoading && parseFloat(sendAmount) > 0 && <TransInoTxt className="loading-text">Amount Loading ...</TransInoTxt>
                    }
                    {data.strategyAddress.toLowerCase() !== "0xadb34c8d63848eaf5760d8d1de776bbae3d044a8" &&
                    <>
                        <FlexSBCont className='info-space'>
                            <TransInoTxt className="info-text-gamma-btcb" light>Zap to GAMMA-BTCB</TransInoTxt>
                            <UnderstandCheckBox className="vesting-checkbox" onClick={() => setIUnderstand(!IUnderstand)}>{IUnderstand && <img src={green_tick} alt="" />}</UnderstandCheckBox>
                        </FlexSBCont>
                        { IUnderstand &&
                            <FlexSBCont className='info-space'>
                                <TransInoTxt className="info-text-gamma-btcb" light>{suppliedGammaBtcbPercent}%</TransInoTxt>
                                <TransInoTxt className="info-text-val">{gammaBtcbApy.toFixed(2)}%</TransInoTxt>
                            </FlexSBCont>
                        }
                        { IUnderstand &&
                            <FlexSBCont className='info-space'>
                                <TransInoTxt className="info-text" light>GAMMA- BTCB deposit percentage</TransInoTxt>
                                <TransInoTxt className="info-text-val">New APY</TransInoTxt>
                            </FlexSBCont>
                        }
                        { IUnderstand &&
                            <>
                            <SliderContainer borrowLimit={100} className={suppliedGammaBtcbPercent > 75 && suppliedGammaBtcbPercent < 90 ? 'SliderContainerPopup poolsLimit OrangeLimit': suppliedGammaBtcbPercent > 90 ? 'SliderContainerPopup poolsLimit RedLimit' : 'SliderContainerPopup poolsLimit'}>
                                <Slider min={0} max={100} value={suppliedGammaBtcbPercent} step={1} tooltip={false} onChange={handleSuppliedGammaBtcbPercentChange} />
                                {/* <input type="range" min="1" max="100" value="50" className="slider" id="myRange" onChange={handleSuppliedGammaBtcbPercentChange} /> */}
                            </SliderContainer>
                            </>
                        }
                    </>
                    }
                    <FlexSBCont className="token-data">
                        <FlexCont >
                            <img className="icon-data" src={getTokenIcon(lpToken0.name)} alt="" />
                            <TransTxt className='poolName'>{lpToken0.name}</TransTxt>
                        </FlexCont>
                        <TransTxt className='poolName'>{!!transInfo && transInfo.amountsOut0 && parseFloat(sendAmount) > 0 && lpToken0.price !== undefined ? `${parseFloat(transInfo.amountsOut0).toFixed(6)} ($${parseFloat((transInfo.amountsOut0 * +lpToken0.price).toString()).toFixed(2)})` : 0}</TransTxt>
                    </FlexSBCont>
                    {data.name.toLowerCase() != "aqua" &&
                        <FlexSBCont className="token-data">
                            <FlexCont >
                                <img className="icon-data" src={getTokenIcon(lpToken1.name)} alt="" />
                                <TransTxt className='poolName'>{lpToken1.name}</TransTxt>
                            </FlexCont>
                            <TransTxt className='poolName'>{!!transInfo && transInfo.amountsOut1 && parseFloat(sendAmount) > 0 && lpToken1.price !== undefined ? `${parseFloat(transInfo.amountsOut1).toFixed(6)} ($${parseFloat((transInfo.amountsOut1 * +lpToken1.price).toString()).toFixed(2)})` : 0}</TransTxt>
                        </FlexSBCont>
                    }
                    {data.name.toLowerCase() != "aqua" &&
                        
                        <FlexSBCont className="token-data">
                            <FlexCont >
                                <TwoImageCont>
                                    <img src={getTokenIcon(lpToken0.name)} alt="" />
                                    <img className="second-img" src={getTokenIcon(lpToken1.name)} alt="" />
                                </TwoImageCont>
                                <TransTxt className='poolName'>{data.name}</TransTxt>
                            </FlexCont>
                            <TransTxt className='poolName'>{!!transInfo && transInfo.liquidity && parseFloat(sendAmount) > 0 ? `${parseFloat(transInfo.liquidity).toFixed(6)} LP ($${parseFloat((transInfo.liquidity * +data.price).toString()).toFixed(2)})` : 0}</TransTxt>
                        </FlexSBCont>
                    }
                    { IUnderstand &&
                        <FlexSBCont >
                            <FlexCont >
                                <TwoImageCont>
                                    <img src={getIcons(1,true)} alt="" />
                                    <img className="second-img" src={getIcons(2, true)} alt="" />
                                </TwoImageCont>
                                <TransTxt className='poolName'>{getGammaBtcbTokenName(0)}</TransTxt>
                            </FlexCont>
                            <TransTxt className='poolName'>{!!gammaBtcbTransInfo && gammaBtcbTransInfo.liquidity && parseFloat(sendAmount) > 0 ? `${parseFloat(gammaBtcbTransInfo.liquidity).toFixed(6)} LP ($${parseFloat((gammaBtcbTransInfo.liquidity * +gammaBtcbPool.price).toString()).toFixed(2)})` : 0}</TransTxt>
                        </FlexSBCont>
                    }
                </TransDataCont>
                {
                    transInfo.impactAbove3Percent && !inSufAmt && parseFloat(sendAmount) > 0 &&
                    <PriceImpactBox>
                        <p>Price impact is over 3%, please try a smaller amount</p>
                    </PriceImpactBox>
                }
                {
                   warningMessage && 
                    <PriceImpactBox>
                        <p>High slippage value, please try a smaller amount</p>
                    </PriceImpactBox>
                }
                {
                    sendToken.address === bnbAddress ?
                        <TransButton className="zapNStake" disabled={!(parseFloat(sendAmount) > 0) || createLpLoading || !selector.ethData.ethWalletConnected || inSufAmt || transInfo.impactAbove3Percent || isAmtLoading} onClick={() => handleApproveToken()}> {createLpLoading|| isAmtLoading ? <Spinner /> : inSufAmt ? 'Insufficient Balance' : data.poolId == 2?'Swap & Stake':'Zap In & Stake'}</TransButton> :
                        <>
                            {
                                parseFloat(allowanceAmt) >= parseFloat(sendAmount) || parseFloat(sendAmount) > parseFloat(sendToken.value) ?
                                <TransButton className="zapNStake" disabled={!(parseFloat(sendAmount) > 0) || createLpLoading || !selector.ethData.ethWalletConnected || inSufAmt || transInfo.impactAbove3Percent || isAmtLoading || parseFloat(sendAmount) > parseFloat(sendToken.value)} onClick={() => handleApproveToken()}> {createLpLoading || isAmtLoading ? <Spinner /> : inSufAmt ? 'Insufficient Balance' : data.poolId == 2?'Swap & Stake':'Zap In & Stake'}</TransButton>
                                :
                                <TransButton className="zapNStake" disabled={!(parseFloat(sendAmount) > 0) || approveLoading || !selector.ethData.ethWalletConnected || createLpLoading || isAmtLoading} onClick={() => handleApproveToken()}> {approveLoading || isAmtLoading ? <Spinner /> : data.poolId == 2?'Swap & Stake':'Zap In & Stake'}</TransButton> 
                                    
                            }
                        </>

                }
            </TransActionCont>
            <CustomModal show={showTokenModal} toggleModal={setShowTokenModal}>
                {showTokenModal && (
                    <TokenSelect
                        selectedAdd={sendToken.address}
                        setShowTokenModal={setShowTokenModal}
                        tokenListVal={tokenDataList}
                        handleTokenSelect={handleTokenSelect}
                        hideInput={true}
                    />
                )}
            </CustomModal>
            <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
                {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
            </CustomModal>
            <CustomModal show={zapStakeModal} toggleModal={showZapStakeModal}>
                {zapStakeModal && <ZapInStakeModal showZapStakeModal={showZapStakeModal} transactionLink={transactionLink} zapInStateGammaBtcb={zapInStateGammaBtcb} zapInState={zapInState} tokenAllowance={tokenAllowance} poolDetails={data} sendToken={sendToken} transactionLoader={transactionLoader} IUnderstand={IUnderstand} approveFarm={approveFarm} depositOnFarm={depositOnFarm} />}
            </CustomModal>
        </Fragment>

    )
}

const ZapInStakeModal = (props: any) => {
    const {showZapStakeModal, zapInState, zapInStateGammaBtcb, tokenAllowance, poolDetails, sendToken, transactionLoader, IUnderstand, approveFarm, depositOnFarm} = props
    return (
        <Fragment>
            <TransInputCont className='zapNStake'>
                <FlexSBCont className='titleCont'>
                    <InputTitle className='confTitle'>{poolDetails.poolId == 2? 'Swap & Stake': 'Zap In & Stake'} </InputTitle>
                    <img className='cross-icon' src={CrossIcon} alt="" onClick={() => showZapStakeModal(false)} />
                </FlexSBCont>
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Approve {sendToken.name}</InputTitle>
                    {transactionLoader == true && tokenAllowance == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={tokenAllowance ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                {IUnderstand && 
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Zap In & Stake into GAMMA-BTCB</InputTitle>
                    {transactionLoader == true && tokenAllowance && zapInStateGammaBtcb == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={zapInStateGammaBtcb ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                }
                
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>{poolDetails.name.toLowerCase() == "aqua" ? `Swap ${sendToken.name} into AQUA` : `Zap In & Stake into ${poolDetails.name}`}</InputTitle>
                    {transactionLoader == true && tokenAllowance && (zapInStateGammaBtcb || !IUnderstand) && zapInState == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={zapInState ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>

                {poolDetails.name.toLowerCase() == "aqua" &&
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Approve Farm V3 for AQUA</InputTitle>
                    {transactionLoader == true && tokenAllowance && (zapInStateGammaBtcb || !IUnderstand) && zapInState && approveFarm == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={approveFarm ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                }
                {poolDetails.name.toLowerCase() == "aqua" &&
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Deposit into {poolDetails.name}</InputTitle>
                    {transactionLoader == true && tokenAllowance && (zapInStateGammaBtcb || !IUnderstand) && zapInState &&  approveFarm == true && depositOnFarm == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={depositOnFarm ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                }
            </TransInputCont>
        </Fragment>
    )
}

export default TransformAction