import styled, { keyframes } from 'styled-components'
import { colors, screenSizes } from './theme'
import { Button } from 'shared/button'

export const MainContainer = styled.div<any>`
  position: relative;
  //margin-top: 70px;
  box-sizing: border-box;
  padding: 30px 16px 0 16px;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 1px 0px 0 0;
    //margin-top: 150px;
    // &.lending {
    //   margin-top: 40px !important;
    // }
    &.header, &.lending {
      margin-top: ${(props: any) => props.warningMsg ? '1px!important' : '0px !important'};
      padding: ${(props: any) => props.warningMsg ? '0px 20px 0 0' : '0 20px 0 20px'};
    }

    &.header {
      margin-top: ${(props: any) => props.warningMsg ? '40px!important' : '0px !important'};
      padding: ${(props: any) => props.warningMsg ? '41px 20px 0 0' : '0 20px 0 20px'};
    }
  }
  @media (max-width: 799px) {
    &.lending {
      // margin-top: 100px !important;
      padding: 4.83vw !important;
    }
    &.header > &.lending {
      margin-top: ${(props: any) => props.warningMsg ? '40px!important' : '0px !important'};
      padding: ${(props: any) => props.warningMsg ? '30px 16px 0 16px' : '0 16px 0 16px'};

    }

    &.vesting {
      margin-top: 9.90vw;
      padding: 0;
    }
    &.newPools{
      padding: 30px 0px 0 0px;
    }

    &.header {
      margin-top: ${(props: any) => props.warningMsg ? '40px!important' : '0px !important'};
      padding: ${(props: any) => props.warningMsg ? '30px 16px 0 16px' : '0 16px 0 16px'};

    }

    &.compensation{
      padding: 30px 16px 0 16px;
    }
  }
  
  .planet-tool-tip{
    border-radius: 10px;
    max-width: 70vw;
    opacity: 1 !important;
  }
  .lending {
    margin-top: 50px !important;
  }
  .on-web{
    display: none;
  }
  .on-mob{
    display: block;
  }
  .show-web{
    display: none;
  }
  .show-mob{
    display: flex;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    .on-web{
      display: block;
    }
    .on-mob{
      display: none;
    }
    .show-web{
      display: flex;
    }
    .show-mob{
      display: none;
    }
    &.liquidStakeCont{
      margin-top: 2.604vw;
      padding: 0;
    }
  }
  .green-text{
    color: ${colors.lightGreen};
  }
  .blue-text{
    color: ${colors.lightBlue};
  }
  .red-text{
    color: ${colors.red};
  }

  &.base{
    width: 75vw;
    margin: 0 auto;
    @media (max-width: 799px) {
      width: 100%;
      margin: 0;
      padding: 40px 0 0 0;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 3),
                    only screen and (min--moz-device-pixel-ratio: 3),
                    only screen and (-o-min-device-pixel-ratio: 3/1),
                    only screen and (min-device-pixel-ratio: 3){
                      padding: 40px 20px 0 20px;
                    }
  }
  
`
export const NormalCard = styled.div`
  background: ${colors.themeBackground};
  box-shadow: 0px 3px 10px #00000033;
  border: 1px solid ${colors.borderColor};
  border-radius: 20px;
  box-sizing: border-box;
`
export const PlanetCard = styled.div`
  background: rgba(0,0,0,0.6);
  box-shadow: 0px 3px 10px #00000033;
  border: 1px solid ${colors.borderColor};
  @media (max-width: ${screenSizes.mediaM}px) {
    border: 1.5px solid ${colors.borderColor};
  }
  border-radius: 20px;
  box-sizing: border-box;
  backdrop-filter: blur(50px);
  @supports (-moz-appearance:none) {
    background: ${colors.themeBackground};
  }
`
export const Separator = styled.div`
  height: 1px;
  width: 100%;
  border: 0;
  background: ${colors.borderColor};
`
export const PlanetBtn = styled(Button)`
  border: 0;
  color: ${colors.white};
  box-sizing: border-box;
  padding: 0;
  background: linear-gradient(270deg, ${colors.blue} 0%,${colors.lightBlue} 100%) no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000033;
  &:disabled {
    background: linear-gradient(270deg, ${colors.blue} 0%,${colors.lightBlue} 100%) no-repeat padding-box;
    cursor: initial;
    opacity: 0.4;
    color: rgba(255,255,255,0.5);
  }
`
export const PlanetBrownBtn = styled(PlanetBtn)`
  margin-left: 5px;
  width: 50px;
  height: 50px;
  background: #332E11 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  &:disabled {
    background: ${colors.grey};
  }
`
export const PlanetRedBtn = styled(PlanetBtn)`
  color: ${colors.red};
  background: ${colors.redTransBg};
  &:disabled {
    background: ${colors.redTransBg};
  }
`
export const PlanetYellowBtn = styled(PlanetBtn)`
  color: ${colors.yellow};
  background: ${colors.yellowTransBg};
  &:disabled {
    background: ${colors.yellowTransBg};
  }
`
export const CardButton = styled(Button)`
  cursor: pointer;
  border: 0;
  color: ${colors.lightBlue};
  box-sizing: border-box;
  padding: 0;
  background: transparent linear-gradient(270deg, rgba(48,55,218, 0.2) 0%, rgba(85,183,255, 0.2) 100%) 0% 0% no-repeat padding-box;
  &:disabled {
    background: transparent linear-gradient(270deg, rgba(48,55,218, 0.2) 0%, rgba(85,183,255, 0.2) 100%) 0% 0% no-repeat padding-box;
    cursor: initial;
    opacity: 0.6;
  }
  &:hover {
    background: transparent linear-gradient(270deg,rgba(48,55,218,0.4) 0%,rgba(85,183,255,0.26) 100%) 0% 0% no-repeat padding-box
  }

  &.calc-boost{
    margin-top: 2.083vw;
    background: transparent linear-gradient(90deg, #5EF480 0%, #207C2E 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #27AC40;
    border: 1px solid #FFFFFF26;
    border-radius: 30px;
    opacity: 1;
    padding: 18px;
    color: #FFFFFF;
    &:disabled {
      opacity: 0.4;
    }
    @media (max-width: 799px) {
      margin-top: 20px;
    }
  }

  &.aqua-buy-btn{
    margin-top: 0.885vw;
    background: transparent linear-gradient(270deg, #3037DA32 0%, #55B7FF32 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #FFFFFF26;
    border-radius: 100px;
    color: #55B7FF;
    font-size: 0.781vw;
    line-height: 0.989vw;
    padding: 0.572vw 2.6vw;
    opacity: 1;
    width: auto;
    &:disabled {
      background: transparent linear-gradient(270deg, #3037DA32 0%, #55B7FF32 100%) 0% 0% no-repeat padding-box;
    }
    &:hover {
      background: transparent linear-gradient(270deg, #3037DA64 0%, #55B7FF64 100%) 0% 0% no-repeat padding-box;
    }
  }

  

`
export const CardButton2 = styled(CardButton)`
  color: ${colors.lightGreen};
  background: transparent linear-gradient(90deg, rgba(85,255,118, 0.4) 0%, rgba(39,172,64, 0.4) 100%)  0% 0% no-repeat padding-box;
  &:disabled {
    background: transparent linear-gradient(90deg, rgba(85,255,118, 0.2) 0%, rgba(39,172,64, 0.2) 100%)  0% 0% no-repeat padding-box;
  }
  &:hover {
    background: transparent linear-gradient(90deg,rgba(85,255,118,0.6) 0%,rgba(39,172,64,0.46) 100%) 0% 0% no-repeat padding-box
  }
  &.claim_stake.on-web{
    width: fit-content;
    padding: 10px
  }
  &.claim_stake.on-mob{
    width: fit-content;
    padding: 12px
  }
  &.collectGammaVesting{
    width: fit-content;
    font-size: clamp(0.9375vw, 12px, 30px);
    line-height:  clamp(1.145vw, 14px, 35px);
    padding: clamp(0.729vw, 12px, 30px) clamp(2.1vw, 12px, 30px); 
    border-radius: clamp(1.302vw, 12px, 40px);
    background: transparent linear-gradient(90deg, #55FF7615 0%, #27AC4015 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    cursor: pointer;
  }
  &.claimNStake{
    margin-top: 0.9375vw;
    font-size: 0.9375vw;
    line-height: 1.145vw;
    padding: 0.729vw 1.72vw;
    width: auto;
    cursor: pointer;
    background: transparent linear-gradient(90deg, #55FF7615 0%, #27AC4015 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 2px solid #FFFFFF15;
    border-radius: 1.302vw;
  }
  @media (max-width: 799px) {
    &.claimNStake{
      margin-top: 10px;
      font-size: 16px;
      line-height: 20px;
      padding: 7px 11px;
      width: auto;
      cursor: pointer;
    }
  }
  &.calculateBoost{
    font-size: 0.78125vw;
    line-height: 0.9895vw;
    padding: 0.5729vw 2.604vw;
    width: auto;
    white-space: nowrap;
    @media (max-width: 799px) {
      font-size: 16px;
      line-height: 19px;
      padding: 10px 20px;
    }
  }

  &.gamma-buy-btn{
    margin-top: 0.885vw;
    background: transparent linear-gradient(270deg, #27AC4032 0%, #55FF7632 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #FFFFFF26;
    border-radius: 100px;
    color: #55FF76;
    font-size: 0.781vw;
    line-height: 0.989vw;
    padding: 0.572vw 2.6vw;
    opacity: 1;
    width: auto;
    &:disabled {
      background: transparent linear-gradient(270deg, #27AC4032 0%, #55FF7632 100%) 0% 0% no-repeat padding-box;
    }
    &:hover {
      background: transparent linear-gradient(270deg, #27AC4064 0%, #55FF7664 100%) 0% 0% no-repeat padding-box;
    }
  }

  &.gammaBuy{
    @media (max-width: 799px) {
      margin:0px;
    }
    margin: 0 auto;
    
  }

  &.collectGamma{
    margin-top: 0.9375vw;
    font-size: 0.9375vw;
    line-height: 1.145vw;
    padding: 0.729vw 1.72vw;
    width: 10.883vw;
    cursor: pointer;
    color: #FF5555;
    background: #FF555515 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 2px solid #FFFFFF15;
    border-radius: 1.302vw;
    @media (max-width: 799px) {
      margin-top: 10px;
      font-size: 16px;
      line-height: 20px;
      padding: 7px 11px;
      width: auto;
      cursor: pointer;
      
    }
  }

  &.maxBtnClaimStake{
    padding: 0.26vw 0.729vw;
    font-size: calc(2px + 0.416vw);;
    margin-left: 5px;
  }

  &.zapin-gamma-btcb-btn{
    background: transparent linear-gradient(90deg, #55FF76 0%, #27AC40 41%, #0E2924 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #0B8E1F;
    border-radius: 30px;
    opacity: 1;
    padding: 0.9375vw 6.25vw;
    width: auto;
    margin: auto;
    color: #FFFFFF;
    font-size: 1.04vw;
    line-height: 1.25vw;
    @media (max-width: 799px) {
      font-size: 2.41vw;
      line-height: 3.14vw!important;
      padding: 2.41vw 4.83vw ;
    }

  }
`
export const CardButton3 = styled(CardButton)`
  color: ${colors.white};
  background: #333333;
  &:disabled {
    background: #333333;
  }
  &:hover {
    background: #666666;
  }
`

/* ================== Flex Container ================ */

export const FlexCont = styled.div<any>`
  display: flex;
  align-items: flex-start;
  
  &#earnings .gammaInUSD{
    display: none;
  }
  &#earnings .inGamma{
    display: block;
  }
  &#earnings:hover .gammaInUSD{
    display: block;
  }
  &#earnings:hover .inGamma{
    display: none;
  }
  &#earnings{
    @media (min-width: 800px) {
      margin: auto !important;
    }
  }

  &.borrow-limit{
    width: auto;
    text-align: center;
  }
  &.netApyWeb{
    display: flex;
    justify-content: center;
  }
  &.LendingStats{
    display: flex;
    justify-content: start;
    align-items: center;
  }
  &.webWarning{
    background: rgba(39, 38, 44, 0.4);
    border-radius: 16px;
    padding: 8px;
    width: 60%;
    height: 65%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  &.webWarning > p.warningText{
    color: white;
    float: left;
    margin: 0;
    display: contents;
    
  }
  &.webWarning > p.warningText > p.warningHeader{
    color: white;
    float: left;
    margin: 0;
    font-weight: bold;
  }
  &.mobWarning > p.warningText > p.warningHeader{
    color: white;
    float: left;
    margin: 0;
    font-weight: bold;
  }
  &.webWarning > p.warningText > a.siteLink{
    color: #55B7FF;
  }

  &.mobWarning > p.warningText > a.siteLink{
    color: #55B7FF;
  }

  @media (max-width: ${screenSizes.mediaM}px) {
    &.mobWarning{
      width: 100%;
      height: 75%;
    }
    &.mobWarning > p.warningText{
      font-size: 13px;
      color: white;
    }
    &.mobWarning > p.warningText > p.warningHeader{
      font-size: 13px;
      color: white;
      float: left;
      margin: 0;
      font-weight: bold;
    }
    &.mobWarning > p.warningText > a.siteLink{
      color: #55B7FF;
      font-size: 13px;
    }
    &.webWarning {
      display: none;
    }
  }

  @media (min-width: ${screenSizes.mediaM}px) {
    &.mobWarning{
      display: none;
    }
    &.poolsTx{
      position: absolute;
      right: 15px;
      top: 4px;
      width: auto;
    }
  }

  img.trans-icon{
    height: 30px;
    width: 30px;
  }

  img.icon-data{
    height: 30px;
    width: 30px;
  }

  &.boostTextCont{
    display: flex;
    flex-direction: column;
  }

  &.boostImgTextCont{
    @media (max-width: 799px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &.textCont{
  }

  &.gammaTextCont{
    width: auto;
  }

  img.inf_boost_icon{
    width: 5.572vw;
    height: 5.572vw;
    margin: 0 auto 0 auto;
    @media (max-width: 799px) {
      width: 100px;
      height: auto;
    }
  }

  img.gamma_icon{
    width: 4.531vw;
    height: 4.531vw;
    display: flex;
    margin: 0 auto 1.04vw auto;
    @media (max-width: 799px) {
      height: 75px;
      width: 75px;
      margin: 20px 10px;
    }
  }

  img.gamma_icon_mob{
    width: 4.531vw;
    height: 4.531vw;
    display: flex;
    margin: 1.04vw auto 1.04vw auto;
    @media (max-width: 799px) {
      height: 75px;
      width: 75px;
      margin: 20px auto 0 auto;
    }
  }

  &.info-icon{
    height: 3.125vw;
    width: 3.125vw;
  }

  img.lending-gamma-icon{
    height: 3.645vw;
    width: 3.645vw;
  }

  &.flexColumn{
    flex-direction: column;  
  }

  &.vestingGammaCont{
    margin-top: 2.656vw;
    display: flex;
    align-items: center;
  }
  img.vesting-gamma-icon{
    height: 2.604vw;
    width: 2.604vw;
    @media (max-width: 799px) {
      height: 12.077vw;
      width: 12.077vw;
    }
  }
  &.vesting-details-cont{
    flex-direction: column;
    @media (max-width: 799px) {
      margin-top: 9.90vw;
      
    }
  }

  &.suppliedAmountCard{
    display: block;
    width: 60%;
    @media (max-width: 799px) {
      width: 53%;      
    }
  }

  img.token-icon-new {
    width: 1.5625vw;
    height: 1.5625vw;
  }

  img.down-icon-new {
    width: 0.8854vw;
    height: 0.573vw;
    margin-left: 5px;
  }

  img.bounty-gamma-icon{
    width: 1.5625vw;
    height: 1.5625vw;
    @media (max-width: 799px) {
      width: 5.314vw;
      height: 5.314vw;
    }
  }
`
export const FlexContStake = styled.div`
  display: flex;
  align-items: right;
  position:absolute;
  right: 40px;
  top: 0px;
`
export const FlexCenterCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const FlexSBCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  div.discountCard{
    display:flex;
    height: auto;
    align-items: center;
  }

  img.RightIconDisabled{
    opacity: 0.4;
    margin-left: 5px;
  }

  &.discountLevel{
    display: grid;
  }
  &.mobileDropdown{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-left: 25%;
    margin-top: 30px;
  }

  &.collectGammaCont{
    width: 100vw;
    height: auto;
    background: #080808 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #F8F8F833;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    display: block;
    margin: 0 auto;
  }

  img.gammaIcon{
    height: 20px;
    width: 20px; 
  }

  &.collectGammaCont{
    width: 100vw;
    height: auto;
    background: #080808 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #F8F8F833;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    display: block;
  }

  &.titleBox{
    height: auto;
    padding: 20px 20px 7px 20px;
    border-bottom: 1px solid #FFFFFF26;
    background: #000000;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  img.close-collect-gamma{
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  &.claimGammaCont{
    padding: 20px 20px 20px 20px;
    background: #000000;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: block;
  }
  &.claimNStakeCont{
    width: auto;
    height: auto;
    padding: 20px;
    display: block;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000033;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
  }
  &.claimCont{
    width: auto;
    height: auto;
    padding: 20px;
    display: block;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000033;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    margin-top: 20px;
  }
  img.gammaIcon{
    height: 20px;
    width: 20px; 
  }
  &.gammaIconCont{
    margin-top: 15px;
    justify-content: flex-start;
  }

  @media (min-width: ${screenSizes.mediaM}px) {
    div.borrowLimit{
      display: flex;
    }
    &.info-space{
      margin-bottom: 10px;
    }
    &.collectGammaCont{
      width: 46vw;
      height: auto;
      background: #080808 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 10px #F8F8F833;
      border: 1px solid #FFFFFF26;
      border-radius: 20px;
      opacity: 1;
      backdrop-filter: blur(50px);
      -webkit-backdrop-filter: blur(50px);
      display: block;
    }

    img.close-collect-gamma{
      height: 2.083vw;
      width: 2.083vw;
      cursor: pointer;
    }

    &.titleBox{
      height: 1.822vw;
      padding: 2.083vw 2.428vw 0.9375vw 1.5625vw;
      border-bottom: 1px solid #FFFFFF26;
      background: #000000;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      width: auto;
    }

    &.claimGammaCont{
      padding: 2.083vw 1.5625vw 2.083vw 1.5625vw;
      background: #000000;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      width: auto;
      align-items: baseline;
    }
    &.claimNStakeCont{
      width: 31vw;
      height: auto;
      padding: 2.083vw 2.083vw 1.354vw 2.083vw;
      display: block;
      background: #000000 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 10px #00000033;
      border: none;
      opacity: 1;
      backdrop-filter: blur(50px);
      -webkit-backdrop-filter: blur(50px);
    }
    &.claimCont{
      width: 31vw;
      height: auto;
      padding: 2.083vw 2.083vw 1.354vw 2.083vw;
      display: block;
      background: #000000 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 10px #00000033;
      border: 1px solid #8AFB8690;
      border-radius: 1.04vw;
      opacity: 1;
      backdrop-filter: blur(50px);
      -webkit-backdrop-filter: blur(50px);
      margin-top: 0px;
    }
    img.gammaIcon{
      height: 2.60vw;
      width: 2.60vw; 
    }
    &.gammaIconCont{
      margin-top: 1.5625vw;
      justify-content: flex-start;
    }
  }

  @media (max-width: 799 px){
    & > div.mob-sidebar-header{
      display: flex;
      align-items: center;
    }
    
  }

  &.overview{
    margin-top: 40px;
    background: #171819 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000033;
    border: 1.5px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    max-width: 600px;
    padding: 30px;
    display: block;
  }
  &.left{
    float:left;
    width: 38%;
    @media (max-width: ${screenSizes.mediaM}px){
      width: -moz-calc(100% - 64px);
      width: -webkit-calc(100% - 64px);
      width: calc(100% - 64px);
      margin-left: 0px;
    }
    @media (min-width: ${screenSizes.mediaM}px) and (max-width: ${screenSizes.mediaL}px){
      width: 42%;
    }
    @media (min-width: ${screenSizes.mediaL}px) and (max-width: ${screenSizes.mediaXL}px){
      width: 42%;
    }
  }
  &.right{
    float:left;
    width: 58%;
    margin-left: 30px;
    @media (max-width: ${screenSizes.mediaM}px){
      width: -moz-calc(100% - 64px);
      width: -webkit-calc(100% - 64px);
      width: calc(100% - 64px);
      margin-left: 0px;
    }
    @media (min-width: ${screenSizes.mediaM}px) and (max-width: ${screenSizes.mediaL}px){
      width: 55%;
      margin-left: 20px;
    }
    @media (min-width: ${screenSizes.mediaL}px) and (max-width: ${screenSizes.mediaXL}px){
      width: 55%;
      margin-left: 20px;
    }
  }
  div.overview_container{
    display: inline-grid;
    width: 33%;
    @media (max-width: 799 px){
      
    }
  }
  div.overview_info{
    margin-top: 30px;
    @media (max-width: 799px) {
      font-size: 14px;
      line-height: 17px;
    }
    @media (min-width: 800px and max-width: 1024px) {
      font-size: 14px;
      line-height: 17px;
    }
    @media (min-width: 1025px and max-width: 1440px) {
      font-size: 14px;
      line-height: 17px;
    }
  }
  &.boostHeading{
    background-color: #1F1F22;
    box-shadow: 0px 3px 10px #00000033;
    padding: 30px;
    border-radius: 20px 20px 0px 0px;
  }
  &.tokenInputCont{
    margin: 15px 30px 0 30px;
    background-color: #1F1F22;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    padding: 30px;
  }
  &.equalsIcon{
    margin: 0px 0 30px 0;
    justify-content: center;
  }
  &.boostOutput{
    display: block;
    padding: 30px 30px 30px 30px;
    background-color:#1F1F22;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    margin: 30px;
  }
  img.token-icon{
    height: 20px;
  }
  &.poolTxInputSelect{
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
  }
  img.cross-icon{
    heigh: 18px;
    width: 18px;
  }
  &.allowanceCheck{
    padding: 1.5625vw 3.125vw 1.5625vw 1.5625vw;
    border: 1px solid #FFFFFF26;
  }
  img.trans-icon{
    height: 1.25vw;
    width: 1.25vw;
    @media (max-width: 799px) {
      height: 4.83vw;
      width: 4.83vw;
    }
  }
  &.titleCont{
    padding: 1.5625vw;
    @media (max-width: 799px) {
      padding: 20px;
      border-bottom: 1px solid #FFFFFF26;
    }
  }

  &.boostCont{
    padding: 1.1458vw 3.125vw 1.1458vw 3.125vw;
    box-shadow: 0px 3px 10px #00000033;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    margin-right: 2.08vw;
    align-items: flex-start;
    width: auto;
    @media (max-width: 799px) {
      display: block;
      padding: 20px;
    }
  }

  &.boostContBase{
    padding: 2.083vw 3.125vw;
    box-shadow: 0px 3px 10px #00000033;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    margin-right: 2.08vw;
    align-items: flex-start;
    width: 29.8vw;
    @media (max-width: 799px) {
      display: block;
      padding: 20px !important;
      width: auto;
    }
  }

  &.boost-in-base{
    @media (max-width: 799px) {
      display: block;
    }
  }

  &.boostContMob{
    padding: 1.1458vw 3.125vw 1.1458vw 3.125vw;
    box-shadow: 0px 3px 10px #00000033;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    margin-right: 2.08vw;
    align-items: flex-start;
    width: auto;
    display: block;
    @media (max-width: 799px) {
      display: block;
      padding: 20px;
    }
  }

  &.boostContGamma{
    padding: 2.083vw 3.125vw !important;
    @media (max-width: 799px) {
      padding: 20px !important;
    }
  }
  &.gamma_inf_mob{
    display: block;
  }
  &.vestingTableHeader{
    
  }

  &.flex-column-cont{
    flex-direction: column;
    align-items: flex-start;
  }

  &.vesting-details{
    width: 23.23vw;
    max-width: 23.23vw;
    margin-bottom: 0.52vw;
    @media (max-width: 799px) {
      width: 100vw;
      max-width: 100vw;
      margin-bottom: 2.415vw;
    }
  }
  &.btcb-input-cont{
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000033;
    border: 1px solid #FFFFFF26;
    border-radius: 0.468px;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    padding: 0.52vw 1.04vw;
    margin-left: 1.25vw;
  }
  span.percentage{
    font-size: 0.833vw;
    font-family: MontserratRegular;
    color: #FFFFFF90;
  }
  &.migration-pool-name{
    flex-direction: column;
  }
  &.migration-pool-cont{
    flex-direction: column;
    margin-left: 10px;
    margin-bottom: 20px;
    @media (max-width: 799px) {
      margin-left: 5px;
      margin-bottom: 10px;
    }
  }
  img.arrow-icon{
    width: 0.9898vw;
    height: 1.5625vw;
    @media (max-width: 799px) {
      width: 8px;
      height: 13px;
      margin: 0 10px 0 15px;
    }
  }

  &.migrationTableBody{
    grid-template-columns: 2.6vw 3.9vw 6fr 1fr 3.9vw 8.5fr 5.5fr 5.5fr 5.5fr;
    display: grid;
    grid-gap: 0.52vw;
    flex-direction: row;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000033;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    padding: 1.30vw 0;
    -webkit-backdrop-filter: blur(50px);
    margin-bottom: 1.5625vw;
    @media (max-width: 799px) {
      display: block;
      background: #000000 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 10px #00000033;
      border: 1px solid #FFFFFF26;
      border-radius: 20px;
      opacity: 1;
      backdrop-filter: blur(50px);
      padding: 4.83vw;
      margin-bottom: 10px;
    }
  }
  

  &.migrationApyLockSection{
    grid-template-columns: 2.6vw 3.9vw 6fr 1fr 3.9vw 8.5fr 5.5fr 5.5fr 5.5fr;
    display: grid;
    flex-direction: row;
    margin-bottom: 1.5625vw;
    @media (max-width: 799px) {
      display: none;
    }
  }

  &.migrationBodyCont {
    display: block;
    @media (max-width: 799px) {
      width: 100vw;
    }
  }

  &.apyCont{
    background: #080808 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    opacity: 1;
    padding: 0.781vw 1.04vw;
    width: fit-content;
    cursor: pointer;
    @media (max-width: 799px) {
      padding: 2.17vw 2.657vw;
      border-radius: 10px;
    }
  }
  &.green{
    border: 1px solid #8AFB86 !important;
  }

  &.migration-details{
    margin-left: 40%;
    width: auto;
    max-width: 100vw;
    margin-bottom: 2.415vw;
    justify-content: space-between;;
    display: flex;
  }

  &.migration-details-apy-cont{
    margin-left: 35%;
    width: auto;
    max-width: 100vw;
    margin-bottom: 2.415vw;
    justify-content: space-between;;
    display: flex;
  }

  &.gammaBtcbReqCont{
    flex-direction: column; 
    margin-left: 1.718vw;
    @media (max-width: 799px) {
      margin-left: 4.83vw;
    }
  }

  &.gammaBtcbCont{
    margin-bottom: 2.08vw;
    @media (max-width: 799px) {
      margin-bottom: 4.83vw;
    }
  }

  &.lockedLPCont{
    grid-template-columns: 3fr 2fr 3fr;
    display: grid;
    opacity: 0.4;
    font-size: 0.833vw;
    line-height: 0.989vw;
    margin-top: 1.5625vw;
    @media (max-width: 799px) {
      grid-template-columns: 2fr 2fr 3fr;
      font-size: 14px;
      line-height: 17px;
      margin-top: 15px;
    }
  }
`

/* =================================== Shimmer ========================== */
export const shimmer = keyframes`
  from {
    background-position: -1000px 0;
  }
  to {
    background-position: 1000px 0;
  }
`
export const AquaPrimaryBtn = styled(Button)`
  background: linear-gradient(to right, ${colors.lightBlue} 0%, ${colors.blue} 100%) no-repeat padding-box;
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to left, ${colors.lightBlue} 0%, ${colors.blue} 100%) no-repeat padding-box;
    opacity: 0;
    border-radius: 20px;
    transition: all 0.5s ease-out;
    z-index: -1;
  }
  &:hover:after {
    opacity: 1;
    z-index: -1;
  }
  &:disabled {
    background: linear-gradient(to left, ${colors.lightBlue} 0%, ${colors.blue} 100%) no-repeat padding-box;
  }
`
export const AquaPriceBox = styled.a`
  background: ${colors.background};
  border: 1px solid ${colors.borderColor};
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0px 3px 5px #00000033;
  text-decoration: none;
  cursor: pointer;
  img{
    width: 34px;
    height: 33px;
    margin-right: 10px;
  }
  p{
    font-size: 14px;
    line-height: 17px;
    color: ${colors.white};
    font-family: MontserratBold;
    margin:0;
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
`

export const MaxBtn = styled.div`
  &:hover{
    background: ${colors.blueTransHover}
  }
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-family: MontserratBold;
  color: ${colors.lightBlue};
  text-transform: uppercase;
  cursor: pointer;
  padding: 8px 16px;
  background: ${colors.blueTransBg};
  border-radius: 11px;
  box-sizing: border-box;
`

export const MaxStakeBtn = styled.div`
  &:hover{
    background: ${colors.blueTransHover}
  }
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-family: MontserratBold;
  color: ${colors.lightBlue};
  cursor: pointer;
  padding: 8px 16px;
  background: ${colors.blueTransBg};
  border-radius: 11px;
  box-sizing: border-box;
`

/* =========================== Tooltip ============================ */

export const ToolTipDataCont = styled.div`
  ul {
    text-align: left;
  }
  li{
    font-size: 14px;
    line-height: 24px;
    color: ${colors.white};
    font-family: MontserratSemiBold;
    margin: 0 0 5px 0;
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
`

export const ToolTipText = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  text-align: center;
  @media (max-width: ${screenSizes.mediaM}px) {
    word-break: break-word;
  }
`
export const ToolTipIcon = styled.img<any>`
  cursor: pointer;
  margin-left: ${(props: any) => (props.left ? `${props.left}px` : '0px')};
  margin-top: ${(props: any) => (props.top ? `${props.top}px` : '0')};
  width: 14px !important;
  height: 14px !important;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-top: ${(props: any) => (props.top ? `${props.top}px` : '0')};
    &.tooltip-web{
      height:14px !important;
      width:14px !important;
    }
  }
  &.tooltip-mob{
    height:10px !important;
    width:10px !important;
  }
  &.borrowLimitIcon{
    left: 61%;
    float: left;
    top: 24px;
    position: absolute;
    margin-left: 5px;
  }
  @media not all and (min-resolution:.001dpcm) { 
    @media {
      &.borrowLimitIcon{
        left: 61%;
        float: left;
        top: -21px;
        position: relative;
        margin-left: 5px;
      }
    }
  } 
  &.maxBorrowLimitIcon{
    float: left;
    position: relative;
    margin-left: 5px;
    margin-top:4px;
  }
  &.borrowLimitWeb{
    margin-left: 73%;
    float: left;
    font-size: 18px;
    margin-top: -25px;
  }
  @media (max-width: 767px) {
    &.maxBorrowLimitIcon{
      margin-top: -13px;
    }
  }
`
export const ToolTipTitleCont = styled.div`
  display: flex;
  align-items: center;
`

/*  ============================ Lending ================================ */

export const ModalDataCont = styled(NormalCard)`
  padding: 20px;
  width: 100%;
  max-width: 382px;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 30px;
    width: 477px;
    max-width: 477px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-family: MontserratBold;
  color: ${colors.lightBlue};
  text-transform: uppercase;
  cursor: pointer;
  padding: 8px 16px;
  background: ${colors.blueTransBg};
  border-radius: 11px;
  box-sizing: border-box;
`
export const NormalBtn = styled(Button)`
  font-size: 18px;
  width: 132px;
  border-radius: 20px;
  margin-left: 30px;
  height: 48px;
  border: 0;
  background: transparent linear-gradient(90deg, rgba(85,255,118, 0.2) 0%, rgba(39,172,64, 0.2) 100%)  0% 0% no-repeat padding-box;
  color: ${colors.lightGreen};
  box-shadow: 0px 0px 10px #00000029;
  &:disabled {
    background: transparent linear-gradient(90deg, rgba(85,255,118, 0.2) 0%, rgba(39,172,64, 0.2) 100%)  0% 0% no-repeat padding-box !important;
    cursor: initial;
    opacity: 1 !important;
  }
  &:hover {
    background: transparent linear-gradient(90deg,rgba(85,255,118,0.4) 0%,rgba(39,172,64,0.26) 100%) 0% 0% no-repeat padding-box;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 50px;
  }
  &.buyGammaMob{
    font-size: 2.912vw;
    line-height: 3.64vw;
    width: auto;
    min-width: auto;
    padding: 2.427vw 3.88vw;
    margin-left:0px;
    font-family: MontserratBold;
    height:auto;
    background: transparent linear-gradient(270deg, #5EF480 0%, #207C2E 100%) 0% 0% no-repeat padding-box;
    border-radius: 6.06vw;
    opacity: 1;
    color: #FFFFFF;
  }
  &.vaultEarnings{
    font-size: 2.898vw;
    width: fit-content;
    min-width: auto;
    padding: 2.415vw 2.898vw !important;
    margin-left:0px;
    font-family: MontserratBold;
    height:auto;
    margin-bottom: 4.83vw;
    background: rgba(85, 255, 118,0.1);
  }

  &.gammaApy{
    font: normal normal 600 2.17vw/2.657vw MontserratRegular;
    color: #55FF76;
    opacity: 1;
    background: #55FF76 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #FFFFFF26;
    border-radius: 100px;
    margin: 1.69vw 0 0 0;
    padding: 1.69vw 3.38vw;
    height: auto;
    width: fit-content;
  }
`
export const PrimaryBtn = styled(Button)`
background: transparent linear-gradient(270deg, ${colors.lightGreen} 0%, ${colors.green} 100%) 0% 0% no-repeat padding-box;
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 20px #27AC40;
  border: 0;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: transparent linear-gradient(90deg, ${colors.lightGreen} 0%, ${colors.green} 100%) 0% 0% no-repeat padding-box;
    opacity: 0;
    border-radius: 20px;
    transition: all 0.5s ease-out;
    z-index: -1;
  }
  &:hover:after {
    opacity: 1;
    z-index: -1;
  }
  &:disabled {
    opacity:0.2;
    background: transparent linear-gradient(90deg, ${colors.lightGreen} 0%, ${colors.green} 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000033;
  }
`
/* ===============================================Shimmer========================================== */

export const LoadingText = styled.div`
  animation: ${shimmer} 3s;
  animation-iteration-count: infinite;
  background: transparent linear-gradient(270deg, ${colors.green} 0%, ${colors.lightGreen} 100%) 0% 0% ;
  background-size: 1000px 100%;
  border-radius: 10px;
`
export const LoadingText1 = styled(LoadingText) <any>`
  width: ${(props: any) => props.width ? `${props.width}%` : '30%'};
  height: 14px;
  margin-top: ${(props: any) => props.top ? `${props.top}px` : 0};
  margin-bottom: ${(props: any) => props.bottom ? `${props.bottom}px` : 0};
  margin-left: ${(props: any) => props.left ? `${props.left}px` : 0};
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height: 16px;
  }
`
export const LoadingText2 = styled(LoadingText) <any>`
  width: ${(props: any) => props.width ? `${props.width}%` : '50%'};
  height: 20px;
  margin-top: ${(props: any) => props.top ? `${props.top}px` : 0};
  margin-bottom: ${(props: any) => props.bottom ? `${props.bottom}px` : 0};
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 16px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height: 18px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    height: 20px;
  }
`
export const LoadingText3 = styled(LoadingText) <any>`
  width: ${(props: any) => props.width ? `${props.width}%` : '50%'};
  height: 24px;
  margin-top: ${(props: any) => props.top ? `${props.top}px` : 0};
  margin-bottom: ${(props: any) => props.bottom ? `${props.bottom}px` : 0};
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 18px;
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    height: 24px;
  }
`