import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { PlanetCard } from 'shared/styles/styled'
import { Button } from 'shared/button'

export const TokenValInput = styled.input<any>`
  width: 100%;
  background: transparent;
  color: ${colors.white};
  font-size: 16px;
  line-height: 20px;
  font-family: MontserratBold;
  border: none;
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 30px;
  }
  &.tokenInput{
    padding: 30px;
  }

  &.btcb-input{
    opacity: 1;
    font-size: 1.25vw;
    font-family: MontserratRegular;
    color: #FFFFFF90;
    width: 2.34vw;
    line-height: 0.989vw;
    @media (max-width: 799px) {
      font-size: 4.34vw;
      margin-botton: 10px;
      width: auto;
    }
  }
  
`

export const SliderInput = styled.input<any>`

`

export const TokenSelectCont = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  gap: 10px;
  img.token-icon{
    height: 30px;
    display: flex;
    justify-content: center;
  }
  &.poolTxInputSelectPoolModal {
    max-width: 300px;

  }
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-left: 20px;
    &.poolTxInputSelect{
      margin-left: 10px;
    }
  }
  img.boost-token-icon{
    height: 30px;
    margin-right: 10px;
  }
  img.token-icon{
    height: 20px;
  }
  
`

export const FlexCont = styled.div`
  display: flex;
  align-items: center;
  &.clock-setting{
    margin-bottom: 20px;
  }
  &.stableTokensCont{
    display: flex;
  }
  img.trans-icon{
    height: 30px;
    width: 30px;
  }
`

export const TokenName = styled.h1`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 25px;
    &.poolsTx{
      font-size: 19px;
      line-height: 24px;
      width: auto;
    }
  }
  
`

export const TokenNameTokenSelect = styled.h1`
  font-size: 15px;
  line-height: 16px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  width: 50px;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 15px;
    line-height: 16px;
    &.poolsTx{
      font-size: 15px;
      line-height: 16px;
      width: auto;
    }
  }
  
`

export const SelectCurrTxt = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  width: 132px;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 25px;
    width: 164px;
  }
`

export const PercentageBtnCont = styled.div`
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-gap: 20px;
  align-items: center;
  &.gammaStaked{
    padding: 20px 30px 30px 30px;
  }
`
export const PercentageBox = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  background: #1F1F22 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF26;
  border-radius: 20px;

  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 0px 3px 10px #0000004D;
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 50px;
  }
  p{
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF64;
    font-family:MontserratBold;
    margin:0;
    opacity:1;
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
`

export const CardCont = styled(PlanetCard)`
  width: 100%;
  padding: 20px;
  background: ${colors.themeBackground};
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 30px;
  }
  .clock-icon {
    width: 23px;
    height: 20px;
    cursor: pointer;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 30px;
      height: 25px;
    }
  }
  .btn-space{
    margin-top: 30px;
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-top: 20px;
    }
  }
  &.mixedLiquidity{
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .mixed-icon{
    height: 90px;
    width: 115px;
    @media (max-width: 799px) {
      height: 55px;
      width: 75px;
      
    }
  }
  .zapin-icon{
    height: 45px;
    width: 115px;
    margin-top: auto;
    margin-bottom: auto;
    @media (max-width: 799px) {
      height: 25px;
      width: 75px;
      
    }
  }
  @media (max-width: 799px){
    &.removeLiquidityContainer{
      padding: 16px;
      padding: 30px 0px 0px 0px;
      border: none;
    }
  }
  //mixedLiquidityContainer
  @media (min-width: 1050px) {
    &.mixedLiquidityContainer{
      zoom:0.8 !important;
      max-width: 620px;
      border: none;
    }
    &.removeLiquidityContainer{
      zoom:0.8 !important;
      max-width: 620px;
      border: none;
      padding: 30px 0px 0px 0px;
    }
  }
  @media (min-width: 1350px) {
    &.mixedLiquidityContainer{
      zoom:0.85 !important;
      max-width: 620px;
      border: none;
    }
    &.removeLiquidityContainer{
      zoom:0.85 !important;
      max-width: 620px;
      border: none;
      padding: 30px 0px 0px 0px;
    }
  }
  @media (min-width: 1550px) {
    &.mixedLiquidityContainer{
      zoom:0.9 !important;
      max-width: 620px;
      border: none;
    }
    &.removeLiquidityContainer{
      zoom:0.9 !important;
      max-width: 620px;
      border: none;
      padding: 30px 0px 0px 0px;
    }
  }
  @media (min-width: 1750px) {
    &.mixedLiquidityContainer{
      zoom:1 !important;
      max-width: 620px;
      border: none;
    }
    &.removeLiquidityContainer{
      zoom:1 !important;
      max-width: 620px;
      border: none;
      padding: 30px 0px 0px 0px;
    }
  }
  &.stableTokensContainer{
    padding: 0px;
    @media (max-width: 799px) {
      
    }
  }
  &.stableSwapDetails{
    margin-top: 30px;
  }
  &.mixedLiquidityContainer.stableTokensContainer{
    @media (max-width: 799px) {
      border: none;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    &.zapIn{

    }
  }
`
export const UnStakeCardCont = styled(PlanetCard) `
  width: fit-content;
  padding: 20px;
  background: ${colors.themeBackground};
`
export const FlexSBCont = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div.tokenIcon{
    display: flex;
  }
  div.tokenIconRev{
    display: inline-flex;
  }
  &.stableTokensContainer{
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    padding: 14px 20px 14px 30px;
    width: 60%;
    background: #080808;
    max-width: 303px;
    @media (max-width: 799px) {
      width: 50%;
      padding: 7px 10px 7px 15px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 50%;
    }
  }

  &.stableTokensContainer.token-input{
    margin-top: 0px;
  }

  img.arrow-down-grey{
    transform: matrix(0, -1, 1, 0, 0, 0);
    width: 16px;
    height: 25px;
  }
  img.intermediate-swap-tokens-icon{
    margin-left: ${(props: any) => (props.arrowCount ? (100/props.arrowCount)+'%' : 0)};
  }
  &.swapInfoCard{
    // margin-left: 15%;
    // margin-right: 15%;
    margin: 0 auto;
    display: contents;
  }
  &.swapInfoCardRev{
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
  }
  &.mixedLiquidity{
    @media (max-width: 799px) {
      margin-bottom: 5px;
    }
  }
  &.swapTokens{
    display: flex ;
    align-items: center;
    margin-top: 0px;
    @media (max-width: 799px) {
      margin-top: 0px;
    }
  }
  &.swapTokenDetails{
    overflow-x: overlay;
    scroll-behavior: auto;
  }
  &.swapTokenDetails::-webkit-scrollbar{
    display: none;
  }
  &.swap-const-data{
    margin-top: 10px;
  }
  &.swap-const-data > img.stable-icon{
    height: 20px;
    width: 20px;
  }
  img.stable-icon{
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
  &.liquidity-header > .swap {
    margin-bottom: 5px;
  }
  img.stable-token-icon{
    height: 36px;
    margin-right: 10px;
  }
  &.removeSwapTokens{
    margin-top: 20px;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    text-align: center;
  }
  img.threeG-icon{
    height: 30px;
    @media (max-width: 799px) {
      height: 22px;
    }
  }
  img.threeGIcon{
    height: 30px;
    margin: auto;
    @media (max-width: 799px) {
      height: 22px;
    }
  }
  &.removeTokens{
    border-right: 1px solid #FFFFFF26;
    padding: 14px 19px;
    width: 25%;
    display: flex;
    cursor: pointer;
    @media (max-width: 799px) {
      padding: 5px 8px;
    }
  }
  &.removeTokensFinal{
    padding: 14px 19px;
    width: 25%;
    display: flex;
    cursor: pointer;
    @media (max-width: 799px) {
      padding: 14px 8px;
    }
  }
  &.removeTokens > div.threeGToken > div.tokenName {
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-left: 5px;
  }
  &.removeTokensFinal > div.threeGToken > div.tokenName {
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-left: 5px;
  }
  &.tokenName {
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-left: 5px;
  }
  &.removeTokens > div.threeGToken {
    display: flex;
    margin: auto;
  }

  &.removeTokensFinal > div.threeGToken {
    display: flex;
    margin: auto;
  }
  &.donut_chart{
    height: 300px;
  }
  @media (max-width: 799px) {
    img.stable-token-icon{
      height: 25px;
      margin-right: 5px;
    }
    &.stableTokensContainer{
      
    }
  }
  &.firstIndexCont{
    background: #2775CA32;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &.lastIndexCont{
    background: #2775CA32;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &.middleIndexCont{
    background: #2775CA32;
  }
  &.stablePoolHeader{
    margin-left: -30px;
    margin-right: -30px;
    border-bottom: 1px solid #FFFFFF26;
    @media (max-width: 799px) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  &.swapIconBg{
    margin-top: 30px;
  }

`

export const TitleText = styled.h1`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 29px;
    &.collectGammaTitle{
      font-size: 1.5625vw;
      line-height: 1.927vw;
    }
  }
  &.mixedLiquidity{
    font-size: 24px;
  }
  &.swapText{
    margin-bottom: 20px;
  }
  @media (max-width: 799px) {
    &.swapText{
      font-size: 20px;
    }
    &.addLiquidity{
      font-size: 20px;
    }
    &.mixedLiquidity{
      font-size: 18px;
    }
  }
`

export const InfoLDText = styled.p`
  display: flex;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: 0.4;
  &.collectGammaTitle{
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    font-family: MontserratBold;
    opacity: 1;
  }
  &.collectGammaSubText{
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF66;
    opacity: 1;
    font-family: MontserratMedium;
    margin-top: 10px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 24px;
    &.collectGammaTitle{
      font-size: 1.041vw;
      line-height: 1.25vw;
      color: #FFFFFF;
      font-family: MontserratBold;
      opacity: 1;
    }
    &.collectGammaSubText{
      font-size: 1.041vw;
      line-height: 1.25vw;
      color: #FFFFFF66;
      opacity: 1;
      font-family: MontserratMedium;
      margin-top: 1.25vw;
    }
    &.gammaVal{
      font-size: 1.041vw;
      line-height: 1.25vw;
      color: #FFFFFF;
      opacity: 1;
      margin-left: 0.989vw;
      align-items: baseline;
    }
    &.gammaVal span{
      font-size: 0.833vw;
      line-height: 0.989vw;
      color: #736F82;
      opacity: 1;
    }
  }
  &.stableTokenBalance{
    white-space: nowrap;
  }
  &.btcbText{
    font-size: calc(2px + 0.625vw);
    line-height: 0.781vw;
    opacity: 0.4;
    font-family: MontserratMedium;
    margin: 1.04vw 0 0.52vw 0;
    @media (max-width: 799px) {
      font-size: calc(10px + 0.625vw);
      margin-top: 10px;
    }
  }
`

export const MaxTxt = styled.span`
  padding: 8px 16px 8px 16px;
  border-radius: 11px;
  background: #55B7FF33 0% 0% no-repeat padding-box;
  font-size: 11px;
  line-height: 14px;
  font-family: MontserratBold;
  color: ${colors.lightBlue};
  text-transform: uppercase;
  cursor: pointer;
  &:hover{
    background: rgba(85, 183, 255, 0.3) 0% 0% no-repeat padding-box;
  }
  // @media (min-width: ${screenSizes.mediaM}px) {
  //   font-size: 20px;
  //   line-height: 25px;
  // }
`
export const MaxTxtZero = styled.span`
  padding: 8px 16px 8px 16px;
  border-radius: 11px;
  background: #FFFFFF33 0% 0% no-repeat padding-box;
  font-size: 11px;
  line-height: 14px;
  font-family: MontserratBold;
  color: ${colors.white};
  text-transform: uppercase;
  cursor: pointer;
  &:hover{
    background: rgba(255, 255, 255, 0.3) 0% 0% no-repeat padding-box;
  }
  // @media (min-width: ${screenSizes.mediaM}px) {
  //   font-size: 20px;
  //   line-height: 25px;
  // }
`

export const BtnGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  box-sizing: border-box;
`
export const PrimaryBtn = styled(Button) <any>`
  height: 49px;
  padding: 15px 0;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 19px;
  background: transparent linear-gradient(270deg, #3037DA 0%, #55B7FF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3037DA;
  opacity: 1;
  transition: 0.5s;
  text-align: center;
  &:disabled {
    opacity: 0.2;
    background: transparent linear-gradient(270deg, #3037da 0%, #55b7ff 100%) 0% 0% no-repeat padding-box;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 60px;
    padding: 18px 0 17px 0;
    font-size: 20px;
    line-height: 25px;
  }
  &.add-stable-liquidity{
    margin-top: 20px;
  }
  &.confirmStableSwapBtn{
    margin-top: 30px;
    @media (max-width: 799px) {
    margin-top: 20px;
    }
  }
`

export const YellowConfirmBtn = styled(Button) <any>`
  height: 49px;
  padding: 15px 0;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 19px;
  background: rgba(255, 229, 85, 0.4)  0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000033;
  opacity: 1;
  transition: 0.5s;
  text-align: center;
  border: 1px solid #FFFFFF26;
  border-radius: 20px;
  color: #FFE555;
  margin-bottom: 40px;
  &:hover {
    background: rgba(255, 229, 85, 0.5)  0% 0% no-repeat padding-box;
  }
  &:disabled {
    background: rgba(255, 229, 85, 0.4)  0% 0% no-repeat padding-box; no-repeat padding-box;
    opacity: 0.2
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 60px;
    padding: 18px 0 17px 0;
    font-size: 20px;
    line-height: 25px;
  }
  &.add-stable-liquidity{
    margin-top: 20px;
  }
  &.confirmStableSwapBtn{
    margin-top: 30px;
    @media (max-width: 799px) {
    margin-top: 20px;
    }
  }
`

export const UnStakeCont = styled.div`
  width: 100%;
  max-width: 1000px;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 30px;
    width: 100%;
    max-width: 1000px;
  }
  .aqua-price {
    margin: 20px 0;
  }

  
  
  .token-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  
  .swap-const-info {
    margin-top: 20px;
  }
  .swap-const-data {
    margin-bottom: 10px;
  }
  
  
`

export const TokenSwapCont = styled.div`
  .clock-setting {
    img {
      width: 23px;
      height: 20px;
      cursor: pointer;
      margin-left: 16px;
      @media (min-width: ${screenSizes.mediaM}px) {
        width: 30px;
        height: 25px;
        margin-left: 20px;
      }
    }
  }
  .swap-input {
    margin-top: 18px;
  }
  .aqua-price {
    margin: 20px 0;
  }
  .swap-info {
    margin: 19px 0 30px 0;
    opacity: 0.4;
  }
  .token-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  .arrow-down {
    width: 14px;
    height: 9px;
    margin-left: 10px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 17px;
      height: 11px;
      margin-left: 15px;
    }
  }
  .swap-const-info {
    margin-top: 20px;
  }
  .swap-const-data {
    margin-bottom: 10px;
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    &.swap{
      width: 100%;
    }
  }
  
  @media (min-width: 1050px) {
    &.swap{
      zoom:0.8 !important;
      max-width: 550px;
    }
  }
  @media (min-width: 1350px) {
    &.swap{
      zoom:0.85 !important;
      max-width: 620px;
    }
  }
  @media (min-width: 1550px) {
    &.swap{
      zoom:0.9 !important;
      max-width: 620px;
    }
  }
  @media (min-width: 1750px) {
    &.swap{
      zoom:0.95 !important;
      max-width: 620px;
    }
  }

  @media (min-width: 2160px) {
    &.swap{
      zoom:1 !important;
      max-width: 620px;
    }
  }
  
`
export const SwapCard = styled.div<any>`
  padding: 20px;
  border: ${(props: any) => (+props.sendAmount > 0 ? '2px solid #55B7FF' : '1px solid `${colors.borderColor}`' )};
  background: ${colors.inputBg};
  border-radius: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 24px 30px;
  }
  &.swapBox{
    border: 1px solid #FFFFFF26;
    border: ${(props: any) => (+props.sendAmount > 0 ? '2px solid #55B7FF' : '1px solid `${colors.borderColor}`' )};
  }
`
export const SwapConstCard = styled(PlanetCard)`
  padding: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 24px 30px;
  }
  &.swapInfo{
    @media (max-width: 799px) {
      overflow-x: visible;
      width: 100vw;
    }
  }
  &.stable-swap-info{
    border: none;
    padding: 0;
    @media (max-width: 799px) {
      padding: 0 0px 20px f0px;
    }
  }
`

export const SwapDownArrow = styled.div`
  width: 40px;
  height: 40px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(85, 183, 255, 0.2);
  border-radius: 100px;
  cursor: pointer;
  @media (min-width: ${screenSizes.mediaM}px) {
    width: 50px;
    height: 50px;
  }
  &.swapIconBg{
    border-radius: 20px;
    background: #000000;
    border: 1px solid #FFFFFF26;
    margin: -5px auto;
    z-index: 10000000;
    position: relative;
  }
  @media (max-width: 799px) {
    &.swapIconBg{
      border-radius: 15px;
      
    }
  }
`

export const SwapConstTxt = styled.p<any>`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: ${(props: any) => (props.light ? 0.4 : 1)};
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
`
export const PriceImpactTxt = styled.p<any>`
  font-size: 12px;
  line-height: 16px;
  color: ${(props: any) => (props.priceImpactVal > 5 ? colors.red : props.priceImpactVal > 3 ? colors.yellow : colors.lightGreen)};
  font-family: MontserratSemiBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 14px;
    line-height: 18px;
  }
  &.priceImpact{
    font-size: 16px;
    line-height: 18px;
  }
  @media (max-width: 799px) {
    &.priceImpact{
      font-size: 13px;
      line-height: 18px;
    }
  }
  &.swapTokenPrice{
    color: #FFF;
    font-size: 16px;
    line-height: 19px;
    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  &.highSlippageWarning{
    color: ${colors.red};
    font-size: 16px;
    line-height: 18px;
    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
`
export const PriceCont = styled.div`
  margin-top: 20px;
  .price-space{
    margin-bottom: 11px;
  }
`

export const ConfirmSwapCont = styled.div`
  width: 100%;
  max-width: 415px;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaM}px) {
    max-width: 382px;
  }
  .down-icon {
    width: 20px;
    height: 24px;
    margin: 20px 0 20px 10px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 20px;
      height: 24px;
      margin: 40px 0 40px 15px;
    }
  }
  .token-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
  }
  .receive-aqua {
    margin-bottom: 30px;
  }
  .confirm-info {
    margin-bottom: 10px;
  }
  .price-impact {
    color: #55fe83;
  }
  .close-confirm-swap {
    cursor: pointer;
  }
  button {
    margin-top: 20px;
  }
`

export const SwapTokenCont = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 20px 0 28px 0;
  }
`
export const SwapText = styled.p<any>`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  opacity: ${(props: any) => (props.light ? 0.4 : 1)};
  margin: 0;
  span {
    color: ${colors.lightBlue};
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
`
export const CnfTokenTxt = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 30px;
  }
`
export const IconRow = styled.div<any>`
  display: flex;
  justify-content: space-between;
`
export const IconWithLabel = styled.label<any>`
  font-family: MONTSERRATSEMIBOLD;
  gap: 10px;
  display: flex;
  float: left;
  img{
    height: 27px;
  }
`

export const IconLabel = styled.span<any>`
  text-align: left;
  font: normal normal 600 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 1.25vw;
  font-family: MONTSERRATSEMIBOLD;
  font-weight: 600;
  @media (max-width: 799px) {
    font-size: 4.43vw;
  }
` 

export const TokenBalanceContainer = styled.div<any>`
  gap: 10px;
  display: flex;
`

export const TokenPriceLabel = styled.label<any>`
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  font-size: 1.25vw;
  align-items: center;
  font-family: MONTSERRATSEMIBOLD;
  @media (max-width: 799px) {
    font-size: 4.43vw;
  }
`

export const CheckBoxRow = styled.div<any>`
  font-family: MONTSERRATSEMIBOLD;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;

`

export const CheckBox = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #080808;
  border: 1px solid #FFFFFF26;
  transition: all 150ms;
  border-radius: 10px;
  &.checked {
    background: #FFE555;
  }
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`

export const CheckBoxLabel = styled.label<any>`
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  opacity: 0.3;
  font-size: 16px;
  line-height: 19px;
  text-align: inherit;
`

export const LeftSection = styled.span<any>`
  display: flex;
  gap: 10px;
`
export const RightSection = styled.span<any>`
  display: flex;
  text-align: end;
`

export const UnStakeConstCard = styled.div<any>`
  padding: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 24px 30px;
  }
  &.swapInfo{
    @media (max-width: 799px) {
      overflow-x: visible;
      width: 100vw;
    }
  }
  &.stable-swap-info{
    border: none;
    padding: 0;
    @media (max-width: 799px) {
      padding: 0 0px 20px f0px;
    }
  }
`

export const UnStakeBottomCont = styled.div<any>` 
  padding-top: 20px;
`

export const TokenContainer = styled.div<any>` 
  width: auto;
  display: flex;
  justify-content: center;
  img {
    height: 30px;
  }
  &.infinityPool {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100px;
  }
`

export const GTokenImgCont = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    width: 85px;
    height: 85px;
  }
  img{
    width: 40px;
    height: 40px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
        width: 70px;
        height: 70px;
      }
   }
  .sync-img {
    position: absolute;
    bottom: 7px;
    left: 35px;
    width: 20px;
    height: 20px;
    @media (max-width: ${screenSizes.mediaS}px) {
      bottom: 5px;
      left: 15px;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
        width: 20px;
        height: 20px;
        left: 20px;
        bottom: 20px;
    }
  }
  img.tokenIcon{
    height: 30px;
    width: 30px;
    @media (max-width: 799px) {
      height: 25px;
      width: 25px;
    }
  }
`

export const TwoImgCont = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 45px;
  height: 30px;
  box-sizing: border-box;
  .second-img {
    position: absolute;
    left: 15px;
  }
  .third-img{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    height: 16px;
  }
  .third-icon{
    position: absolute;
    left: 30px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    width: 60px;
    height: 40px;
    .second-img {
      position: absolute;
      left: 20px;
    }
    .third-icon{
      position: absolute;
      left: 40px;
    }
    img.second-img.boost-token-icon{
      width: 1.5625vw;
      height: 1.5625vw;
      top: 12px !important;
    }
    img.zapInImg{
      top: 5px;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    width: 75px;
    height: 50px;
    .second-img {
      position: absolute;
      left: 25px;
    }
    .third-icon{
      position: absolute;
      left: 50px;
    }
  }
  img.poolsTxSecondIcon{
    height:20px;
    width: 20px;
    position: absolute;
    right: 0px;
  }
  img.poolsTxIcon{
    height:20px;
    width: 20px;
  }
  &.poolsTxIconCont{
    width: 30px;
    height: 20px;
  }
`

export const WithdrawBtnGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  a{
    text-decoration: none;
  }

  &.timerGroup {
    grid-template-columns: 5fr 1.4fr !important;
  }
`

export const StartUnstakeLpBtn = styled(Button)`
  font-size: 12px;
  font-family: MontserratBold;
  background: rgba(140, 113, 255, 0.2) 0% 0% no-repeat padding-box;
  color: rgba(140, 113, 255, 1);
  &:hover {
    background: rgba(140, 113, 255, 0.3) 0% 0% no-repeat padding-box;
  }
  &:disabled {
    background: rgba(140, 113, 255, 0.1) 0% 0% no-repeat padding-box;
  }
`