import { CardCont, FlexSBCont, FlexCont, TitleText, PrimaryBtn, TwoImgCont } from '../style'
import { ConfirmLDCont, PoolTokenText, CnfLdInfoCont, CnfLdInfoText } from './style'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import whiteCrossIcon from 'assets/icons/white-cross.svg'
import stableSwap3GIcon from 'assets/icons/3g-icon.png'

const ConfirmRemoveLiquidity = (props: any) => {
  const { setShowRemoveCnfModal, confirmRemoveLdData, handleRemoveLiquidity, crossIconColor, stableSwap, stableSwapObject } = props

  const handleConfirm = () => {
    handleRemoveLiquidity()
    setShowRemoveCnfModal(false)
  }

  const depositedBalanceDetails = () => {
    // console.log(confirmRemoveLdData)
    return confirmRemoveLdData.map((item: any) => {
      item.inputValue = item.inputValue !== "" ? item.inputValue : "0.00";
      return (
        <FlexSBCont className="cnf-info-icon">
          <CnfLdInfoText light>{`${item.name}`}</CnfLdInfoText>
          <FlexCont>
            <img src={item.icon} alt="" />
            <CnfLdInfoText>{parseFloat(item.inputValue).toFixed(2)}</CnfLdInfoText>
          </FlexCont>
        </FlexSBCont>
      )
    })
  }
  return (
    <CardCont>
      <ConfirmLDCont>
        <FlexSBCont>
          <TitleText>You’ll Receive</TitleText>
          <img className="close-ld" src={crossIconColor !== undefined ? whiteCrossIcon : CrossIcon} alt="" onClick={() => setShowRemoveCnfModal(false)} />
        </FlexSBCont>
        
        <FlexCont className="stableTokensCont">
          <TwoImgCont className="stable-img">
            <img src={stableSwap3GIcon} alt="" />
            
          </TwoImgCont>
          {/* <TitleText>{parseFloat(confirmLdData.receivedTokens) !== 0 ? parseFloat(confirmLdData.receivedTokens).toFixed(4) : ''}</TitleText> */}
        </FlexCont>
        <PoolTokenText>{`Stable Liquidity Pool tokens`}</PoolTokenText>
        <CnfLdInfoText className="output-info">
          Output is estimated, if the price changes by more than 1% your transaction will revert.
        </CnfLdInfoText>
        <CnfLdInfoCont>
          {depositedBalanceDetails()}
        </CnfLdInfoCont>
        <CnfLdInfoCont>
          <FlexSBCont className="cnf-info-icon">
            <CnfLdInfoText light>Minimum Received</CnfLdInfoText>
            <FlexCont>
              <CnfLdInfoText>{stableSwapObject.minimum_received}</CnfLdInfoText>
            </FlexCont>
          </FlexSBCont>
        </CnfLdInfoCont>
        <PrimaryBtn onClick={() => handleConfirm()}>Confirm</PrimaryBtn>  
      </ConfirmLDCont>
    </CardCont>
  )
}

export default ConfirmRemoveLiquidity
