import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { PlanetCard } from 'shared/styles/styled'
import { Button } from 'shared/button'

export const CardCont = styled(PlanetCard)`
  width: 100%;
  padding: 20px;
  background: ${colors.themeBackground};
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 30px;
  }
  .clock-icon {
    width: 23px;
    height: 20px;
    cursor: pointer;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 30px;
      height: 25px;
    }
  }
  .btn-space{
    margin-top: 30px;
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-top: 20px;
    }
  }
  &.mixedLiquidity{
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .mixed-icon{
    height: 90px;
    width: 115px;
    @media (max-width: 799px) {
      height: 55px;
      width: 75px;
      
    }
  }
  .zapin-icon{
    height: 45px;
    width: 115px;
    margin-top: auto;
    margin-bottom: auto;
    @media (max-width: 799px) {
      height: 25px;
      width: 75px;
      
    }
  }
  @media (max-width: 799px){
    &.removeLiquidityContainer{
      padding: 16px;
      padding: 30px 0px 0px 0px;
      border: none;
    }
  }
  //mixedLiquidityContainer
  @media (min-width: 1050px) {
    &.mixedLiquidityContainer{
      zoom:0.8 !important;
      max-width: 620px;
      border: none;
    }
    &.removeLiquidityContainer{
      zoom:0.8 !important;
      max-width: 620px;
      border: none;
      padding: 30px 0px 0px 0px;
    }
  }
  @media (min-width: 1350px) {
    &.mixedLiquidityContainer{
      zoom:0.85 !important;
      max-width: 620px;
      border: none;
    }
    &.removeLiquidityContainer{
      zoom:0.85 !important;
      max-width: 620px;
      border: none;
      padding: 30px 0px 0px 0px;
    }
  }
  @media (min-width: 1550px) {
    &.mixedLiquidityContainer{
      zoom:0.9 !important;
      max-width: 620px;
      border: none;
    }
    &.removeLiquidityContainer{
      zoom:0.9 !important;
      max-width: 620px;
      border: none;
      padding: 30px 0px 0px 0px;
    }
  }
  @media (min-width: 1750px) {
    &.mixedLiquidityContainer{
      zoom:1 !important;
      max-width: 620px;
      border: none;
    }
    &.removeLiquidityContainer{
      zoom:1 !important;
      max-width: 620px;
      border: none;
      padding: 30px 0px 0px 0px;
    }
  }
  &.stableTokensContainer{
    padding: 0px;
    @media (max-width: 799px) {
      
    }
  }
  &.stableSwapDetails{
    margin-top: 30px;
  }
  &.mixedLiquidityContainer.stableTokensContainer{
    @media (max-width: 799px) {
      border: none;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    
  }
  &.zapIn{
    width: 31.1vw;
    @media (max-width: 799px) {
      width: 100%;
    }
  }
`
export const InfoCardCont = styled(PlanetCard)`
  width: 100%;
  padding: 10px;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 10px 20px;
  }
`

export const FlexSBCont = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div.tokenIcon{
    display: flex;
  }
  div.tokenIconRev{
    display: inline-flex;
  }
  &.stableTokensContainer{
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    padding: 14px 20px 14px 30px;
    width: 60%;
    background: #080808;
    max-width: 303px;
    @media (max-width: 799px) {
      width: 50%;
      padding: 7px 10px 7px 15px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 50%;
    }
  }

  &.stableTokensContainer.token-input{
    margin-top: 0px;
  }

  img.arrow-down-grey{
    transform: matrix(0, -1, 1, 0, 0, 0);
    width: 16px;
    height: 25px;
  }
  img.intermediate-swap-tokens-icon{
    margin-left: ${(props: any) => (props.arrowCount ? (100/props.arrowCount)+'%' : 0)};
  }
  &.swapInfoCard{
    // margin-left: 15%;
    // margin-right: 15%;
    margin: 0 auto;
    display: contents;
  }
  &.swapInfoCardRev{
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
  }
  &.mixedLiquidity{
    @media (max-width: 799px) {
      margin-bottom: 5px;
    }
  }
  &.swapTokens{
    display: flex ;
    align-items: center;
    margin-top: 0px;
    @media (max-width: 799px) {
      margin-top: 0px;
    }
  }
  &.swapTokenDetails{
    overflow-x: overlay;
    scroll-behavior: auto;
  }
  &.swapTokenDetails::-webkit-scrollbar{
    display: none;
  }
  &.swap-const-data{
    margin-top: 10px;
  }
  &.swap-const-data > img.stable-icon{
    height: 20px;
    width: 20px;
  }
  img.stable-icon{
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
  &.liquidity-header > .swap {
    margin-bottom: 5px;
  }
  img.stable-token-icon{
    height: 36px;
    margin-right: 10px;
  }
  &.removeSwapTokens{
    margin-top: 20px;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    text-align: center;
  }
  img.threeG-icon{
    height: 30px;
    @media (max-width: 799px) {
      height: 22px;
    }
  }
  img.threeGIcon{
    height: 30px;
    margin: auto;
    @media (max-width: 799px) {
      height: 22px;
    }
  }
  &.removeTokens{
    border-right: 1px solid #FFFFFF26;
    padding: 14px 19px;
    width: 25%;
    display: flex;
    cursor: pointer;
    @media (max-width: 799px) {
      padding: 5px 8px;
    }
  }
  &.removeTokensFinal{
    padding: 14px 19px;
    width: 25%;
    display: flex;
    cursor: pointer;
    @media (max-width: 799px) {
      padding: 14px 8px;
    }
  }
  &.removeTokens > div.threeGToken > div.tokenName {
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-left: 5px;
  }
  &.removeTokensFinal > div.threeGToken > div.tokenName {
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-left: 5px;
  }
  &.tokenName {
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-left: 5px;
  }
  &.removeTokens > div.threeGToken {
    display: flex;
    margin: auto;
  }

  &.removeTokensFinal > div.threeGToken {
    display: flex;
    margin: auto;
  }
  &.donut_chart{
    height: 300px;
  }
  @media (max-width: 799px) {
    img.stable-token-icon{
      height: 25px;
      margin-right: 5px;
    }
    &.stableTokensContainer{
      
    }
  }
  &.firstIndexCont{
    background: #2775CA32;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &.lastIndexCont{
    background: #2775CA32;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &.middleIndexCont{
    background: #2775CA32;
  }
  &.stablePoolHeader{
    margin-left: -30px;
    margin-right: -30px;
    border-bottom: 1px solid #FFFFFF26;
    @media (max-width: 799px) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

`
export const FlexCont = styled.div`
  display: flex;
  align-items: center;
  &.clock-setting{
    margin-bottom: 20px;
  }
  &.stableTokensCont{
    display: flex;
  }
  img.trans-icon{
    height: 30px;
    width: 30px;
  }
`
export const TitleText = styled.h1`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 29px;
  }
  &.mixedLiquidity{
    font-size: 24px;
  }
  &.swapText{
    margin-bottom: 20px;
  }
  @media (max-width: 799px) {
    &.swapText{
      font-size: 20px;
    }
    &.addLiquidity{
      font-size: 20px;
    }
    &.mixedLiquidity{
      font-size: 18px;
    }
  }
`
export const InfoText = styled.p<any>`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: ${(props: any) => (props.light ? 0.4 : 1)};
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 25px;
  }
  &.NoLiquidity{
    text-align: center;
    margin-top: 2.9vw;
    margin-bottom: 2.9vw;
  }
  &.lpShare{
    font-size: 16px;
    color: #FFFFFF64;
  }
  &.backButtonText{
    font-size: 20px;
    margin-left: 15px;
  }
  @media (max-width: 799px) {
    &.liquiditySubText{
      font-size: 12px;
    }
    &.backButtonText{
      font-size: 14px;
      margin-left: 7px;
    }
    &.lpShare{
      font-size: 12px;
    }
  }
  &.totalStableLiquidity{
    font-size: 16px;
  }
  &.stableSwapTVL{
    font-family: MontserratBold;
    font-size: 24px;
    line-height: 29px;
    color: #FFF;
    opacity: 1;
    @media (max-width: 799px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &.swap-subtext{
    margin-top: 15px;
    font-size: 16px;
    line-height: 19px;
  }
`
export const InfoDataTxt = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 30px;
  }
`
export const InfoSmallText = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
  &.liquidityInfoText{
    color: #FFFFFF64;
  }
`
export const InfoLDText = styled.p`
  display: flex;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: 0.4;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 24px;
  }
  &.stableTokenBalance{
    white-space: nowrap;
  }
`
export const BackButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(85, 183, 255, 0.2);
  border-radius: 100px;
  img {
    transform: rotate(90deg);
  }
  cursor: pointer;
  @media (min-width: ${screenSizes.mediaM}px) {
    width: 50px;
    height: 50px;
  }
  img.backIcon{
    height: 20px;
    width: 12px;
    background: transparent;
    margin-left: 30px;
  }
  &.backButton{
    background: transparent;
  }
  @media (max-width: 799px) {
    img.backIcon{
      height: 18px;
      width: 12px;
    }
  }
  &.backButton.stablePoolHeader{
    margin-left: 30px;
    width: 30px;
    height: 30px;
    padding-bottom: 5px;
    @media (max-width: 799px) {
      margin-left: 20px;
    }
  }
`
export const MaxTxt = styled.span`
  padding: 8px 16px 8px 16px;
  border-radius: 11px;
  background: #55B7FF33 0% 0% no-repeat padding-box;
  font-size: 11px;
  line-height: 14px;
  font-family: MontserratBold;
  color: ${colors.lightBlue};
  text-transform: uppercase;
  cursor: pointer;
  &:hover{
    background: rgba(85, 183, 255, 0.3) 0% 0% no-repeat padding-box;
  }
  // @media (min-width: ${screenSizes.mediaM}px) {
  //   font-size: 20px;
  //   line-height: 25px;
  // }
`
export const MaxTxtZero = styled.span`
  padding: 8px 16px 8px 16px;
  border-radius: 11px;
  background: #FFFFFF33 0% 0% no-repeat padding-box;
  font-size: 11px;
  line-height: 14px;
  font-family: MontserratBold;
  color: ${colors.white};
  text-transform: uppercase;
  cursor: pointer;
  &:hover{
    background: rgba(255, 255, 255, 0.3) 0% 0% no-repeat padding-box;
  }
  // @media (min-width: ${screenSizes.mediaM}px) {
  //   font-size: 20px;
  //   line-height: 25px;
  // }
`

export const TokenValInput = styled.input<any>`
  width: 100%;
  background: transparent;
  color: ${colors.white};
  font-size: 16px;
  line-height: 20px;
  font-family: MontserratBold;
  border: none;
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 30px;
  }
  &.stableTokenInput{
    width: 70%;
    background: #080808;
    font-size: 20px;
    line-height: 24px;
    @media (max-width: 799px) {
      font-size: 16px;
      line-height: 19px;
      width: 55%s;
    }
  }
  &.receiveInput{
    color: ${colors.white};
    opacity: ${(props: any) => (+props.data > 0 ? 1 : 0.4)};
  }
`
export const TokenName = styled.h1`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 25px;
  }
  &.tokenName{
    line-height: 30px;
    @media (max-width:799px) {
      line-height: 24px;
    }
  }
  &.stableTokenName{
    line-height: 19px;
    font-size: 16px;
    @media (max-width:799px) {
      line-height: 16px;
      font-size: 14px;
    }
  }
  
  &.tokenBalance{
    font-size: 10px;
    color: #FFF;
    opacity: 0.4;
    font-family: MontserratSemiBold;
    @media (max-width:799px) {
      font-size: 9px;
      line-height: 11px;
    }
  }
  &.stableTokenRemoveTxt{
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 30px;
    font-family: MontserratSemiBold;
    @media (max-width:799px) {
      margin-top: 20px;
    }
  }
  &.removeStableTokenName{
    font-family: MontserratSemiBold;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.4;
    @media (max-width:799px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  &.selectTokenText{
    opacity: 1;
    font-family: MontserratBold;
  }
  &.web {
    display: block;
    @media (max-width:799px) {
      display: none;
    }
  }
  &.mob {
    display: none;
    @media (max-width:799px) {
      display: block;
    }
  }
`
export const BtnGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  box-sizing: border-box;
`
export const PrimaryBtn = styled(Button) <any>`
  height: 49px;
  padding: 15px 0;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 19px;
  background: transparent linear-gradient(270deg, #3037DA 0%, #55B7FF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3037DA;
  opacity: 1;
  transition: 0.5s;
  text-align: center;
  &:disabled {
    opacity: 0.2;
    background: transparent linear-gradient(270deg, #3037da 0%, #55b7ff 100%) 0% 0% no-repeat padding-box;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 60px;
    padding: 18px 0 17px 0;
    font-size: 20px;
    line-height: 25px;
  }
  &.add-stable-liquidity{
    margin-top: 20px;
  }
  &.confirmStableSwapBtn{
    margin-top: 30px;
    @media (max-width: 799px) {
    margin-top: 20px;
    }
  }
`
export const SecondaryBtn = styled(Button) <any>`
  height: 49px;
  padding: 15px 0;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 19px;
  color: ${colors.lightBlue};
  border: 1px solid ${colors.lightBlue};
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 60px;
    padding: 18px 0 17px 0;
    font-size: 20px;
    line-height: 25px;
  }
`
export const NormalBtn = styled(Button) <any>`
  height: 49px;
  padding: 15px 0;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 19px;
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 60px;
    padding: 18px 0 17px 0;
    font-size: 20px;
    line-height: 25px;
  }
`
export const SelectCurrTxt = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  width: 132px;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 25px;
    width: 164px;
  }
`
export const TwoImgCont = styled.div`
  position: relative;
  width: 60px;
  display: flex;
  align-items: center;
  @media (min-width: ${screenSizes.mediaM}px) {
    width: 75px;
  }
  img {
    width: 40px;
    height: 40px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 50px;
      height: 50px;
    }
  }
  .img2 {
    position: absolute;
    top: 0;
    left: 20px;
    @media (min-width: ${screenSizes.mediaM}px) {
      left: 25px;
    }
  }
  &.stable-img > img{
    height: 50px;
    width: auto;
    margin: 10px 0;
  }
`
