const planetGlobalObject:any = {
    getallpoolinfo: {},
    poolsInfoV3: {},
    poolsInfoV2: {},
    poolsInfo: {},
    aquaBurnAPY: {},
    poolGammaSupplyApy: {},
    poolGammaSupplyApr: {},
    poolTotalSupplyApy: {},
    bnbxApy: 0,
    bnbxTVL: 0
}
export default planetGlobalObject