import { ModalCont, ModalHeader, CloseModalImg, CollateralCont, TokenImgCont, ConfirmText, CollSpinCont, CollSpinLogo } from './style'
import CrossIcon from 'assets/icons/white-cross.svg'

const Collateral = (props: any) => {
    const { data, enableCollateral, setShowCollateral } = props
    // console.log("data in collateral",data)
    return (
        <ModalCont>
            <ModalHeader>
                <CloseModalImg src={CrossIcon} alt="" onClick={() => setShowCollateral(false)} />
                <h1>{enableCollateral ? 'Enable Collateral' : 'Disable Collateral'}</h1>
            </ModalHeader>
            <CollateralCont>
                <TokenImgCont>
                    <img src={data.icon} alt="" />
                    <h2>{data.name}</h2>
                </TokenImgCont>
                <CollSpinCont>
                    <CollSpinLogo collStatus={enableCollateral} />
                </CollSpinCont>
                <ConfirmText>{enableCollateral ? "Confirm The Transaction" : 'Each asset used as collateral increases your borrowing limit. Be careful, this can subject the asset to being seized in liquidation.'}</ConfirmText>
            </CollateralCont>
        </ModalCont>
    );
};

export default Collateral;