import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducer'
import thunk from "redux-thunk";
// import { WagmiConfig, createConfig } from "wagmi";
// import { ConnectKitProvider, ConnectKitButton, getDefaultConfig } from "connectkit";


const store = createStore(rootReducer, applyMiddleware(thunk))

export default store
