import { GCardDateTxt } from './style'
import { useCountdown } from './useCountdown';

const UnStakingTime = (props: any) => {
    const { compareDate } = props
    let [days, hours, minutes, seconds] = useCountdown(compareDate);

    days = days || 0;
    hours = hours || 0;
    minutes = minutes || 0;
    seconds = seconds || 0;
    return ( 
        <>  
            {
                days > 0 ? 
                <GCardDateTxt light={(days+hours+minutes+seconds) == 0}>
                    {`${days}:${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(-2)}`}
                </GCardDateTxt>
                :
                <GCardDateTxt light={(days+hours+minutes+seconds) == 0}>
                    {`${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(-2)}`}
                </GCardDateTxt>
            }
        </>
    );
};

export default UnStakingTime;