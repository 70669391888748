import { useState, useEffect, Fragment, useRef, useContext } from 'react'
import { FarmPoolContext } from 'context/FarmPoolContext'
import { Table, TableHeader, TableBody, MobTableBody, TableRowCont, TableRow, CellCont, HeadTextCont, HeadTextData, HeadText, CellText, CellSmText, TwoImgCont, ArrowIconCont, MobArrowIconCont, LoadingText1, TableInfoBox } from 'shared/styles/planetTableStyle'
import { GTokenImgCont } from './style'
import { FlexSBCont, FlexCont, CardButton2 } from 'shared/styles/styled'
import DownIcon from 'assets/icons/down.svg'
import SortIcon from 'assets/icons/white-arrow-up.svg'
import DefaultSortIcon from 'assets/icons/up-down-icon.svg'
import InfoTooltip from 'shared/info-tool-tip'
import CAKEIcon from 'assets/icons/CAKE.svg'
import { commaFy, convertToKAndM, checkZeroVal, getTokenName } from 'service/globalFunctions'
import { getTokenIcon } from 'shared/tokenIconList'
import CustomModal from 'shared/custom-modal'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import EmptyData from 'shared/empty-data'
import TableLoading from 'shared/table-loading'
import PoolTransaction from './PoolTransaction'
import PoolInfo from './PoolInfo'
import PoolDetailsModal from './pool-modal/PoolDetailsModal'
import PoolTCAction from './pool-modal/PoolTCAction'
import OldPoolTCAction from './pool-modal/oldPoolTCAction'
import QuestionIcon from 'assets/icons/question-icon.svg'
import BoostCalculationModal from './pool-modal/BoostCalculatorModal'
import { useSelector, useDispatch } from 'react-redux'
import thenaIcon from 'assets/icons/ThenaLogo.svg'
import bswIcon from 'assets/icons/bsw.svg'
import { newPoolList, activePoolList } from 'modules/block-chain/pfPoolsList'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import AquaSyncIcon from 'assets/icons/aqua-sync.svg'
import GammaSyncIcon from 'assets/icons/gamma-sync.svg'
import SwapInPools from './pool-modal/SwapInPools'
import UnStakeInstantlyPopUp from './pool-modal/UnStakeInstantlyPopUp'
import { tokenList, returnTokenList, swapTokenList, returnSwapTokenList, tokenSwapList } from './../../block-chain/tokenList'
import TokenSelect from 'shared/token-select'
import {aquaAddress, aqua_strategy_address_new, gamma_strategy_address_new} from 'modules/block-chain/abi'
import { gammaAddress } from 'modules/block-chain/lendingAbi'
import inf_boost_icon from 'assets/icons/inf_boost_icon.png'
import gammaIcon from 'assets/icons/gamma-icon.png'

const PoolTable = (props: any) => {
    const [sortByTh, setSortByTh] = useState('tvl')
    const [sortDescending, setSortDescending] = useState(true)
    const [expandedRows, setExpandedRows] = useState<any>([])
    const [showTCAModal, setShowTCAModal] = useState(false)
    const [boostPopup, setBoostPopup] = useState(false)
    const [poolData, setPoolData] = useState<any>([])
    const [poolList, setPoolList] = useState<any>({
        active: [],
        finished: []
    })
    const [transData, setTransData] = useState<any>()
    const [lpAction, setLPAction] = useState<any>('transform')
    /* ===================== mob state ======================= */
    const [showPoolDetails, setShowPoolDetails] = useState(false)
    const [modalData, setModalData] = useState<any>({})
    const [showSwapPopUp, setShowSwapPopUp] = useState(false)
    const [tokenDataList, setTokenDataList] = useState<any>(tokenSwapList)
    const [initialTokenAdd, setInitialTokenAdd] = useState('')
    const [receiveTokenNumber, setReceiveTokenNumber] = useState('')
    const [tokenSwapAmount, setTokenSwapAmount] = useState('')
    const [showTokenModal, setShowTokenModal] = useState(false)
    const [showUnstakePopUp, setShowUnstakePopUp] = useState(false)
    const [unstakeData, setUnstakeData] = useState({})
    const [processUnstake, setProcessUnstake] = useState(() => {})
    const [confirmUnstake, setConfirmUnstake] = useState({
        confirm: false,
        poolId: -1,
    })
    const [currTokenList, setCurrTokenList] = useState<any>(tokenSwapList)
    const [tokenType, setTokenType] = useState('')
    const [existTokenAdd, setExistTokeAdd] = useState('')
    const [sendToken, setSendToken] = useState<any>(tokenList[1])
    const [poolArr, setPoolArr] = useState<any>([])
    const [tokenSelectedRow, setTokenSelectedRow] = useState<any>('');
    const selector = useSelector((state: any) => state)
    // let el = useRef<HTMLElement>(null); 
    const farmPoolData:any = useContext(FarmPoolContext);

    const { activeTab, poolLoading, data, portfolioLoading, protocol, isBorderDisable, isUserPool, pools } = props

    // console.log('pools data farmPoolData', data, pools, farmPoolData);
    useEffect(() => {
        if(isUserPool === true)
            setSortByTh('vault')
        setExpandedRows([])
    }, [activeTab])

    useEffect(() => {
        if(poolLoading == false){
            const pathUrlArr: any = window.location.href.split('/')
            let windowWidth: any = window.innerWidth;
            // console.log(window.innerWidth);
            // console.log("url path arr",pathUrlArr, data, pools)
            if(pathUrlArr[pathUrlArr.length-1].includes("pools")){
                const stratAddress = pathUrlArr[pathUrlArr.length-1].split("#")[1];
                // console.log("pool strat address", stratAddress)
                if(stratAddress !== undefined){                
                    if(windowWidth > 800){
                        let el = document.getElementById(stratAddress);
                        if(el !== null){
                            el.scrollIntoView({ 
                                block: selector.ethData.address !== undefined ? 'nearest': 'start', 
                                behavior: 'smooth',
                                inline: 'nearest'
                            });
                        } 
                        handleRowClick(stratAddress, data)
                    } else {
                        if(data && data.active && data.active.length > 0){
                            let rowToBeExpanded = data.active.filter((rowItem: any, index: number) => {
                                return rowItem.strategyAddress.toLowerCase() == stratAddress.toLowerCase()
                            })
                            handleShowPoolDetails(rowToBeExpanded[0])
                        }
                        
                    }
                }
            }
        }
    }, [poolLoading])


    useEffect(() => {
        const updateTokenList = async () => {
            const userAddress = selector.ethData.address
            try {
              const res = await returnTokenList(userAddress)
              if (!!res && typeof res !== 'undefined') {
                setTokenDataList(res)
              }
            } catch (error) {
              console.log('error===>', error)
            }
        }
        updateTokenList()
    }, [selector.ethData.address])

    const handleRowClick = (rowId: any, poolData: any) => {
        if(!poolLoading && !portfolioLoading){
            const currentExpandedRows = expandedRows
            const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId)
            const newExpandedRows = isRowCurrentlyExpanded
                ? currentExpandedRows.filter((id: any) => id !== rowId)
                : currentExpandedRows.concat(rowId)
    
            setExpandedRows(newExpandedRows)
            let selectedRowToken : any;
            if(rowId.toLowerCase() == aqua_strategy_address_new.toLowerCase()){
                let revisedTokenList = tokenDataList.filter((pool: any) => pool.sno !== 100);
                selectedRowToken = revisedTokenList.filter((token: any) => token.address.toLowerCase() == aquaAddress.toLowerCase());
                selectedRowToken = selectedRowToken.length == 1 && selectedRowToken[0].usdValue > 0.1 ? selectedRowToken[0] : revisedTokenList[0];
                setSendToken(selectedRowToken)
                setTokenSelectedRow(aqua_strategy_address_new)
            }
            if(rowId.toLowerCase() == gamma_strategy_address_new.toLowerCase()){
                let revisedTokenList = tokenDataList.filter((pool: any) => pool.sno !== 100);
                selectedRowToken = revisedTokenList.filter((token: any) => token.address.toLowerCase() == gammaAddress.toLowerCase());
                selectedRowToken = selectedRowToken.length == 1 && selectedRowToken[0].usdValue > 0.1 ? selectedRowToken[0] : revisedTokenList[0];
                setSendToken(selectedRowToken)
                setTokenSelectedRow(gamma_strategy_address_new)
            }
            if(rowId.toLowerCase() !== aqua_strategy_address_new.toLowerCase() && rowId.toLowerCase() !== gamma_strategy_address_new.toLowerCase()){
                
                // console.log("pool data in use effect", poolData)
                let lpTokenObject = {
                    sno: 100,
                    address: poolData.strategyAddress,
                    name: poolData.name,
                    price: poolData.price,
                    value: poolData.walletBalance,
                    usdValue: poolData.walletBalanceUSD
                }
                
                let revisedTokenList = tokenDataList.filter((pool: any) => pool.sno !== 100);
                if (revisedTokenList.some((pool: any) => pool.address.toLowerCase() === poolData.strategyAddress.toLowerCase())) {
                    // console.log("pool found !")
                    
                } else {
                    // console.log("pool NOT found !")
                    revisedTokenList.push(lpTokenObject)
                }
                revisedTokenList.push(lpTokenObject)
                setTokenDataList(revisedTokenList)
                setTokenSelectedRow(rowId)
                if(lpTokenObject.usdValue > 0.1){
                    setSendToken(lpTokenObject)
                } else {
                    setSendToken(revisedTokenList[0])
                }
            }
            // console.log("rowId", rowId, selectedRowToken)
        }
    }

    const renderTokenIcons = (tokenIcons: string, isBoostPool: boolean, protocol?: string) => {
        const tokenName = tokenIcons.split('-')
        if (tokenName.length === 1) {
            if(tokenName[0] == "GAMMA Infinity") {
                const icon = getTokenIcon("GAMMA");
                return (
                    <GTokenImgCont>
                        <img src={icon} alt="" />
                        <img className="sync-img" src={GammaSyncIcon} alt="" />
                    </GTokenImgCont>
                )
            }
            else if (tokenName[0] == "AQUA Infinity")  {
                const icon = getTokenIcon("AQUA");
                return (
                    <GTokenImgCont>
                        <img src={icon} alt="" />
                        <img className="sync-img" src={AquaSyncIcon} alt="" />
                    </GTokenImgCont>
                )
            }
            else {
                const icon = getTokenIcon(tokenIcons)
                return <img src={icon} alt="" />
            }
        }
        else if (tokenName.length === 2) {
            const icon1 = getTokenIcon(tokenName[0])
            let secondTokenName = tokenName[1].split(" ");
            const icon2 = getTokenIcon(secondTokenName[0])
            return (
                <TwoImgCont>
                    <img src={icon1} alt="" />
                    <img className="second-img" src={icon2} alt="" />
                    {protocol && protocol.toLowerCase() == 'cake' && <img className='third-img' src={CAKEIcon} alt="" />}
                    {protocol && protocol.toLowerCase() == 'thena' && <img className='third-img' src={thenaIcon} alt="" />}
                    {protocol && protocol.toLowerCase() == 'biswap' && <img className='third-img' src={bswIcon} alt="" />}
                </TwoImgCont>
            )
        }
        else if (tokenName.length === 3) {
            const icon1 = getTokenIcon(tokenName[0])
            const icon2 = getTokenIcon(tokenName[1])
            const icon3 = getTokenIcon(tokenName[2])
            return (
                <TwoImgCont>
                    <img src={icon1} alt="" />
                    <img className="second-img" src={icon2} alt="" />
                    <img className='third-icon' src={icon3} alt="" />
                </TwoImgCont>
            )
        }
    }

    const handleGetLpToken = (transData: any, type: any, inputAmount: any) => {
        // console.log("handleShowPoolDetails", transData)
        if(transData.poolStatus == "active" || transData.protocol.toLowerCase() == 'thena'){
            // console.log("pool action in pool table ", type, transData, inputAmount)
            setTransData(transData)
            setShowTCAModal(true)
            setLPAction(type)
            setTokenSwapAmount(inputAmount)
        } else {
            history.push(`${Paths.liquidity}`)
        }
       
    }

    const toggleBoostPopupModal = () => {
        setModalData({})
        setBoostPopup(false)
    }

    const _renderTableRow = (rowData: any) => {
        return rowData.map((rowItem: any, index: number) => {
            let poolApy: any = rowItem.totalApy;
            poolApy = poolApy && (!isFinite(poolApy) || poolApy > 10000) ? "> 10,000" : poolApy ? poolApy.toFixed(2) : "0.00";
            rowItem.avgGammaBoostApyLower = rowItem.avgGammaBoostApyLower ? (+rowItem.avgGammaBoostApyLower).toFixed(2) : 0;
            rowItem.avgGammaBoostApyUpper = rowItem.avgGammaBoostApyUpper ? (+rowItem.avgGammaBoostApyUpper).toFixed(2) : 0;
            let rangeApy = "";
            if(rowItem.avgGammaBoostApyUpper > 0){
                if(selector.ethData.address !== undefined && rowItem.vaultBalanceUSD > 0){
                    rangeApy =  "("+ rowItem.avgGammaBoostApyLower +"% - " + rowItem.avgGammaBoostApyUpper +"%)";
                } else {
                    rangeApy = rowItem.avgGammaBoostApyLower +"% - " + rowItem.avgGammaBoostApyUpper +"%";
                }
            }
            const pool_apy = poolApy;
            // rowItem.totalApy = poolApy;
            //set deposit fee for fusion pool as zero //0x4027a5cda69da74674a09af4aacc255d0d7a6e6d','0xffe80fbdfc324305c61794ba38fd2f041cb98b19', '0x3496294f8ab14904cb5427556ae217ccfd09fcb1'
            // if(rowItem.strategyAddress.toLowerCase() === "0xd0121b07c279498ca4e7c03ebea7508b5620a4c3"){
            //     rowItem.depositFees = 0;
            // }
            const clickCallback = () => handleRowClick(rowItem.strategyAddress, rowItem)
            
            if(rowItem.poolStatus == 'active' && rowItem.poolId < 2) {
                // console.log('rowItem.pid', rowItem.poolId, rowItem.name);
                if(rowItem.poolId == 0 ) {
                    return gammaInfinityPoolRow(rowItem);
                }
                else {
                    return aquaInfinityPoolRow(rowItem);
                }
            }
            else {
                return (
                    <TableRowCont key={rowItem.poolId} id={rowItem.strategyAddress} isActive={expandedRows.includes(index)} className={rowItem.isStablePool ? "stablePool" : "newPools"}>
                        <TableRow onClick={clickCallback} className="newPools">
                            <CellCont>
                                {renderTokenIcons(rowItem.name, rowItem.isBoostPool, rowItem.protocol)}
                            </CellCont>
                            <CellCont>
                                <CellText>{getTokenName(rowItem.name, rowItem.pooltype)} {rowItem.isStablePool ? <InfoTooltip infoId='3gStablePool' left={5} /> : ""}</CellText>
                                <CellSmText>{`$${!!rowItem.tvl ? commaFy(convertToKAndM(rowItem.tvl)) : '0.00'} `}</CellSmText>
                            </CellCont>
                            <CellCont>
                                <CellText>{poolApy}%</CellText>
                            </CellCont>
                            <CellCont>
                                {portfolioLoading ? <LoadingText1 /> :
                                    <CellText isValZero={checkZeroVal(rowItem.walletBalanceUSD)}>${commaFy(parseFloat(rowItem.walletBalanceUSD).toFixed(2))}</CellText>
                                }
                            </CellCont>
                            <CellCont>
                                {portfolioLoading ? <LoadingText1 /> :
                                    <CellText isValZero={checkZeroVal(rowItem.vaultBalanceUSD)}>${commaFy(parseFloat(rowItem.vaultBalanceUSD).toFixed(2))}</CellText>
                                }
                            </CellCont>
                            {/* <CellCont>
                                {portfolioLoading ? <LoadingText1 /> :
                                    <CellText className={!checkZeroVal(rowItem.totalRewardsUSD) ? "reward-text" : ''} isValZero={checkZeroVal(rowItem.totalRewardsUSD)}>${commaFy(parseFloat(rowItem.totalRewardsUSD).toFixed(2))}</CellText>
                                }
                            </CellCont> */}
                            <CellCont>
                                <ArrowIconCont isActive={expandedRows.includes(rowItem.strategyAddress)}>
                                    <img src={DownIcon} alt="" />
                                </ArrowIconCont>
                            </CellCont>
                        </TableRow>
                        {expandedRows.includes(rowItem.strategyAddress) &&
                            <Fragment>
                                <PoolTransaction
                                    data={rowItem}
                                    handleGetLpToken={handleGetLpToken}
                                    showSwapPopup={showSwapPopup}
                                    handleTokenModal={handleTokenModal}
                                    sendToken={sendToken}
                                    tokenDataList={tokenDataList}
                                    tokenSelectedRow={tokenSelectedRow}
                                />
                                <PoolInfo data={rowItem} />
                            </Fragment>
                        }
                    </TableRowCont>
                )
            }
        })
    }

    const handleShowPoolDetails = (modalDataVal: any) => {
        if(!poolLoading && !portfolioLoading){
            setModalData(modalDataVal)
            setShowPoolDetails(true)
        }
        
    }

    const togglePoolDetailsModal = () => {
        setModalData({})
        setShowPoolDetails(false)
    }

    const getSign = (value: any) => {
        if (!!value && parseFloat(value) > 0) {
            return '+'
        }
        else {
            return ""
        }
    }

    const gammaInfinityPoolRow = (rowD:any) => {
        const clickCallback = () => handleRowClick(rowD.strategyAddress, rowD)
        let userGammaBalance = ""+0;
        if(farmPoolData != undefined && selector.ethData.address != undefined) {
            let userGammaToken = farmPoolData.tokenSwapListWithUserBalances.find((ele:any) => {
                return (ele.name == "GAMMA")
            })

            if(userGammaToken != undefined) {
                userGammaBalance = "" + userGammaToken.usdValue;
            }
        }
        return (
            <TableRowCont key={rowD.poolId} className={"infinityPool"}>
                <TableRow onClick={clickCallback} className="newPools">
                    <CellCont>
                        {renderTokenIcons("GAMMA Infinity", false, "Planet")}
                    </CellCont>
                    <CellCont>
                        <CellText>{getTokenName("GAMMA", "crypto")}</CellText>
                        <CellSmText>
                            ${`${!!rowD && rowD.tvl ? commaFy(convertToKAndM(parseFloat(rowD.tvl).toFixed(2))) : '0.00'}`}
                            <span className="shiny-green">{'Boost Generator'}</span>
                        </CellSmText>
                    </CellCont>
                    <CellCont>
                        <CellText>
                            {`${getSign(rowD.apy)}${!!rowD && isFinite(rowD.apy) && rowD.apy < 10000 ? parseFloat(rowD.apy).toFixed(2) + "% ": !!rowD && (!isFinite(rowD.apy) || rowD.apy > 10000) ? ">10000% ": "0% "}`}
                        </CellText>
                    </CellCont>
                    <CellCont>
                        {portfolioLoading ? <LoadingText1 /> :
                            <CellText isValZero={checkZeroVal(parseFloat(userGammaBalance).toFixed(2))}>
                                ${commaFy(parseFloat(userGammaBalance).toFixed(2))}
                            </CellText>
                        }
                    </CellCont>
                    <CellCont>
                        {portfolioLoading ? <LoadingText1 /> :
                            <CellText isValZero={checkZeroVal(rowD.deposited_bal_usd)}>
                                ${!!rowD && rowD.deposited_bal_usd ? commaFy(parseFloat(rowD.deposited_bal_usd).toFixed(2)) : '0.00'}
                            </CellText>
                        }
                    </CellCont>
                    {/* <CellCont>
                        {portfolioLoading ? <LoadingText1 /> :
                            <CellText isValZero={checkZeroVal(0.00)}>
                                ${commaFy(parseFloat("0.00").toFixed(2))}
                            </CellText>
                        }
                    </CellCont> */}
                    
                    <CellCont>
                        <ArrowIconCont isActive={expandedRows.includes(rowD.strategyAddress)}>
                            <img src={DownIcon} alt="" />
                        </ArrowIconCont>
                    </CellCont>
                </TableRow>
                {expandedRows.includes(rowD.strategyAddress) &&
                    <Fragment>
                        <PoolTransaction
                            data={rowD}
                            handleGetLpToken={handleGetLpToken}
                            showSwapPopup={showSwapPopup}
                            handleTokenModal={handleTokenModal}
                            sendToken={sendToken}
                            tokenDataList={tokenDataList}
                            tokenSelectedRow={tokenSelectedRow}
                            setShowUnstakePopUp={setShowUnstakePopUp}
                            setUnstakeData={setUnstakeData}
                            confirmUnstake={confirmUnstake}
                        />
                        <PoolInfo data={rowD} />
                        <FlexSBCont>
                            <TableInfoBox style={{width: "50%"}}>
                                <FlexSBCont className='boostCont boostContGamma'>
                                    <FlexCont className='boostTextCont gammaTextCont'>
                                        <HeadText className='boostText margin'>
                                            <span>Don't Have Any GAMMA ?</span>
                                        </HeadText>
                                        <HeadText className='boostText'>
                                        Buy some to stake and <span>increase your APY</span> ACROSS ALL Planets Pools and Markets
                                        </HeadText>
                                    </FlexCont>
                                    <FlexCont className='boostTextCont'>
                                        <img src={gammaIcon} className='gamma_icon' />
                                        <CardButton2 className="calculateBoost" onClick={() => history.push(`${Paths.swap}`)}>Buy GAMMA</CardButton2>
                                    </FlexCont>
                                </FlexSBCont>
                            </TableInfoBox>
                            <TableInfoBox>
                                {/* <FlexSBCont className='boostCont'>
                                    <FlexCont className='boostTextCont textCont'>
                                        <HeadText className='boostText margin'>
                                            <span>Not Sure How Much More You’ll Earn With Boost?</span>
                                        </HeadText>
                                        <HeadText className='boostText'>
                                            Check out our handy <span>Boost Calculator!</span>
                                        </HeadText>
                                    </FlexCont>
                                    <FlexCont className='boostTextCont'>
                                        <img src={inf_boost_icon} className='inf_boost_icon' />
                                        <CardButton2 onClick={() => history.push(`${Paths.boost}`)} className="calculateBoost">Calculate Boost</CardButton2>
                                    </FlexCont>
                                </FlexSBCont> */}
                            </TableInfoBox>
                            {/* <TableInfoBox>
                                <FlexSBCont className='boostCont boostContGamma'>
                                    <FlexCont className='boostTextCont gammaTextCont'>
                                        <HeadText className='boostText margin'>
                                            <span>Don't Have Any GAMMA ?</span>
                                        </HeadText>
                                        <HeadText className='boostText'>
                                        Buy some to stake and <span>increase your APY</span> ACROSS ALL Planets Pools and Markets
                                        </HeadText>
                                    </FlexCont>
                                    <FlexCont className='boostTextCont'>
                                        <img src={gammaIcon} className='gamma_icon' />
                                        <CardButton2 className="calculateBoost" onClick={() => history.push(`${Paths.swap}`)}>Buy GAMMA</CardButton2>
                                    </FlexCont>
                                </FlexSBCont>
                            </TableInfoBox> */}
                        </FlexSBCont>
                        
                        
                    </Fragment>
                }
            </TableRowCont>
        );
    }

    const aquaInfinityPoolRow = (rowD:any) => {
        const clickCallback = () => handleRowClick(rowD.strategyAddress, rowD)
        let userAquaBalance = ""+0;
        if(farmPoolData != undefined && farmPoolData.tokenSwapListWithUserBalances != undefined  && selector.ethData.address != undefined) {
            let userAquaToken = farmPoolData.tokenSwapListWithUserBalances.find((ele:any) => {
                return (ele.name == "AQUA")
            })
            if(userAquaToken != undefined) {
                userAquaBalance = ""+userAquaToken.usdValue;
            }
            
        }
        return (
            <TableRowCont key={rowD.poolId} className={"infinityPool"}>
                <TableRow onClick={clickCallback} className="newPools">
                    <CellCont>
                        {renderTokenIcons("AQUA Infinity", false, "Planet")}
                    </CellCont>
                    <CellCont>
                        <CellText>{getTokenName("AQUA", "crypto")}</CellText>
                        <CellSmText>
                            ${`${!!rowD && rowD.tvl ? commaFy(convertToKAndM(parseFloat(rowD.tvl).toFixed(2))) : '0.00'}`}
                            <span className="shiny-blue">{'5X Voting Power '}</span>
                        </CellSmText>
                    </CellCont>
                    <CellCont>
                        <CellText>
                            {`${getSign(rowD.apy)}${!!rowD && rowD.apy && isFinite(rowD.apy) && rowD.apy < 10000 ? parseFloat(rowD.apy).toFixed(2) + "%": !!rowD && (!isFinite(rowD.apy) || rowD.apy > 10000) ? ">10000%": "0%"}`}
                        </CellText>
                    </CellCont>
                    <CellCont>
                        {portfolioLoading ? <LoadingText1 /> :
                            <CellText isValZero={checkZeroVal(parseFloat(userAquaBalance).toFixed(2))}>
                                ${commaFy(parseFloat(userAquaBalance).toFixed(2))}
                            </CellText>
                        }
                    </CellCont>
                    <CellCont>
                        {portfolioLoading ? <LoadingText1 /> :
                            <CellText isValZero={checkZeroVal(rowD.deposited_bal_usd)}>
                                ${!!rowD && rowD.deposited_bal_usd ? commaFy(parseFloat(rowD.deposited_bal_usd).toFixed(2)) : '0.00'}
                            </CellText>
                        }
                    </CellCont>
                    {/* <CellCont>
                        {portfolioLoading ? <LoadingText1 /> :
                            <CellText isValZero={checkZeroVal(0.00)}>${commaFy(parseFloat("0.00").toFixed(2))}</CellText>
                        }
                    </CellCont> */}
                    
                    <CellCont>
                        <ArrowIconCont isActive={expandedRows.includes(rowD.strategyAddress)}>
                            <img src={DownIcon} alt="" />
                        </ArrowIconCont>
                    </CellCont>
                </TableRow>
                {expandedRows.includes(rowD.strategyAddress) &&
                    <Fragment>
                        <PoolTransaction
                            data={rowD}
                            handleGetLpToken={handleGetLpToken}
                            showSwapPopup={showSwapPopup}
                            handleTokenModal={handleTokenModal}
                            sendToken={sendToken}
                            tokenDataList={tokenDataList}
                            tokenSelectedRow={tokenSelectedRow}
                            setShowUnstakePopUp={setShowUnstakePopUp}
                            setUnstakeData={setUnstakeData}
                            setProcessUnstake={setProcessUnstake}
                            confirmUnstake={confirmUnstake}
                        />
                        <PoolInfo data={rowD} />
                    </Fragment>
                }
            </TableRowCont>
        );
    }

    const _renderMobTableRow = (rowData: any) => {
        // console.log('rowData', rowData);
        return rowData.map((rowItem: any, index: number) => {
            let poolApy: any = (+rowItem.totalApy);
            poolApy = poolApy && (!isFinite(poolApy) || poolApy > 10000) ? "> 10,000" : poolApy ? poolApy.toFixed(2) : "0.00";
            rowItem.avgGammaBoostApyLower = rowItem.avgGammaBoostApyLower ? (+rowItem.avgGammaBoostApyLower).toFixed(2) : 0;
            rowItem.avgGammaBoostApyUpper = rowItem.avgGammaBoostApyUpper ? (+rowItem.avgGammaBoostApyUpper).toFixed(2) : 0;
            let rangeApy = "";
            if(rowItem.avgGammaBoostApyUpper > 0){
                if(selector.ethData.address !== undefined && rowItem.vaultBalanceUSD > 0){
                    rangeApy =  "("+ rowItem.avgGammaBoostApyLower +"% - " + rowItem.avgGammaBoostApyUpper +"%)";
                } else {
                    rangeApy = rowItem.avgGammaBoostApyLower +"% - " + rowItem.avgGammaBoostApyUpper +"%";
                }
            }
            //set deposit fee as zero for fusion pools
            // if(rowItem.strategyAddress.toLowerCase() === "0xd0121b07c279498ca4e7c03ebea7508b5620a4c3"){
            //     rowItem.depositFees = 0;
            // }

            const pool_apy = poolApy;
            const name = rowItem.poolId == 0 || rowItem.poolId == 1 ? rowItem.name.split(' ')[0] : rowItem.name;
            const clickCallback = () => handleRowClick(rowItem.strategyAddress, rowItem)
            const className = rowItem.isStablePool ? "stablePool" : rowItem.poolId > 1? "newPools":  "infinityPool";
            return (
                <TableRowCont key={index} onClick={() => handleShowPoolDetails(rowItem)} className={className}>
                    <TableRow className={className}>
                        <CellCont>
                            {renderTokenIcons(rowItem.name, rowItem.isBoostPool, rowItem.protocol)}
                        </CellCont>
                        <CellCont>
                            <CellText>{getTokenName(name, rowItem.pooltype)} {rowItem.isStablePool ? <InfoTooltip infoId='3gStablePool' left={5} /> : ""}</CellText>
                        </CellCont>
                        <CellCont>
                            <CellText>{poolApy}%</CellText>
                        </CellCont>
                        
                        {pools === 'all' ? 
                            <CellCont>
                                {portfolioLoading ? <LoadingText1 /> :
                                    <CellText isValZero={checkZeroVal(rowItem.walletBalanceUSD)}>${commaFy(parseFloat(rowItem.walletBalanceUSD).toFixed(2))}</CellText>
                                }
                            </CellCont>
                            :
                            <CellCont>
                                {portfolioLoading ? <LoadingText1 /> :
                                    <CellText className={!checkZeroVal(rowItem.vaultBalanceUSD) ? "" : ''} isValZero={checkZeroVal(rowItem.vaultBalanceUSD)}>${commaFy(parseFloat(rowItem.vaultBalanceUSD).toFixed(2))}</CellText>
                                }
                            </CellCont>
                        }
                        <CellCont>
                            <MobArrowIconCont>
                                <img src={DownIcon} alt="" />
                            </MobArrowIconCont>
                        </CellCont>
                    </TableRow>
                </TableRowCont>
            )
            
        })
    }

    const getRowData = () => {
        let rowData: any = []
        if (!!data && Object.values(data).length > 0) {
            if (isUserPool) {
                // console.log(' if poolList poolList',poolList )
                rowData = data
            }
            else {
                // console.log(' poolList poolList',poolList, activeTab  )
                if (activeTab === 'all') {
                    rowData = data
                    rowData = rowData.active.filter((e: any) => !(parseFloat(e.vaultBalanceUSD) > 0))
                }
                if (activeTab === 'boosted') {
                    rowData = data
                    rowData = rowData.active.filter((e: any) => e.isBoostedInNewFarm == true)
                    rowData = rowData.filter((e: any) => !(parseFloat(e.vaultBalanceUSD) > 0))
                }
                if (activeTab === 'stablecoins') {
                    rowData = data
                    rowData = rowData.active.filter((e: any) => e.pooltype === 'stable')
                    rowData = rowData.filter((e: any) => !(parseFloat(e.vaultBalanceUSD) > 0))
                }
                if (activeTab === 'cryptos') {
                    rowData = data
                    rowData = rowData.active.filter((e: any) => e.pooltype === 'crypto')
                    rowData = rowData.filter((e: any) => !(parseFloat(e.vaultBalanceUSD) > 0))
                }
                if (activeTab === 'finished') {
                    rowData = data
                    rowData = rowData.finished 
                    // console.log("finished rows data", rowData)    
                }

                if (activeTab === 'infinity') {
                    rowData = data
                    rowData = rowData.active.filter((e: any) => e.pooltype === 'infinity')
                    // console.log("finished rows data", rowData)    
                }
            }
            if (!!rowData && rowData.length > 0) {
                if (!!protocol) {
                    rowData = rowData.filter((e: any) => e.protocol.toLowerCase() === protocol)
                }
                if (sortByTh === 'tvl') {   
                    rowData = rowData.sort((a: any, b: any) => (parseFloat(b.tvl) > parseFloat(a.tvl)) ? 1 : (parseFloat(a.tvl) > parseFloat(b.tvl) ? -1 : 0))
                }
                if (sortByTh === 'apy') {
                    rowData = rowData.sort((a: any, b: any) => (parseFloat(b.totalApy) > parseFloat(a.totalApy)) ? 1 : (parseFloat(a.totalApy) > parseFloat(b.totalApy) ? -1 : 0))
                }
                if (sortByTh === 'wallet') {
                    rowData = rowData.sort((a: any, b: any) => (parseFloat(b.walletBalanceUSD) > parseFloat(a.walletBalanceUSD)) ? 1 : (parseFloat(a.walletBalanceUSD) > parseFloat(b.walletBalanceUSD) ? -1 : 0))
                }
                if (sortByTh === 'vault') {
                    rowData = rowData.sort((a: any, b: any) => (parseFloat(b.vaultBalanceUSD) > parseFloat(a.vaultBalanceUSD)) ? 1 : (parseFloat(a.vaultBalanceUSD) > parseFloat(b.vaultBalanceUSD) ? -1 : 0))
                }
                // if (sortByTh === 'rewards') {
                //     rowData = rowData.sort((a: any, b: any) => (parseFloat(b.totalRewardsUSD) > parseFloat(a.totalRewardsUSD)) ? 1 : (parseFloat(a.totalRewardsUSD) > parseFloat(b.totalRewardsUSD) ? -1 : 0))
                // }
                if (!sortDescending) {
                    rowData = rowData.reverse()
                }
            }
        }
        return rowData
    }

    const renderTableData = () => {
        const rowData = getRowData()
        //console.log('rowData', rowData);
        if (rowData.length > 0) {
            return (
                <Fragment>
                    <TableBody>
                        {/* {gammaInfinityPoolRow(gammaInfDataNew)}
                        {aquaInfinityPoolRow(aquaInfDataNew)} */}
                        {_renderTableRow(rowData)}
                    </TableBody>
                    <MobTableBody>
                        {/* {gammaInfinityPoolRow(gammaInfDataNew)}
                        {aquaInfinityPoolRow(aquaInfDataNew)} */}
                        {_renderMobTableRow(rowData)}
                    </MobTableBody>
                </Fragment>

            )
        }
        else {
            return <EmptyData />
        }
    }

    const handleTabClick = (tabSelect: string) => {
        if (tabSelect === sortByTh) {
            setSortByTh(tabSelect)
            setSortDescending(!sortDescending)
        }
        else {
            setSortByTh(tabSelect)
            setSortDescending(true)
        }
    }

    const toggleSwapPopupModal = () => {
        // setModalData({})
        setShowSwapPopUp(false)
    }

    const closeSwapModal = () => {
        setShowSwapPopUp(false)
    }

    const updateTokenListData = async () => {
        const userAddress = selector.ethData.address
        try {
          const res = await returnTokenList(userAddress)
          if (!!res && typeof res !== 'undefined') {
            setTokenDataList(res)
          }
        } catch (error) {
          console.log('error===>', error)
        }
    }

    const showSwapPopup = async (data: any, swapAmount: any) => {
        setShowSwapPopUp(true)
        // console.log("pool data", data)
        let tokenNumber : any = data.poolId == 0 ? 2 : 0;
        setReceiveTokenNumber(tokenNumber)
        setTokenSwapAmount(swapAmount)
    }

    const handleToggleModal = () => {
        setTokenType('')
        setShowTokenModal(false)
    }

    const handleTokenSelect = (selectedTokenData: any) => {
        // console.log(selectedTokenData, existTokenAdd)
        setSendToken(selectedTokenData)
        setShowTokenModal(false)
    }

    const handleTokenModal = (type: string, stratAddress: any, poolData: any) => {
        setTokenType(type)
        // getTokenList(type)
        // getExistTokenAdd(type)
        setShowTokenModal(true)
        // setSendAmountCheck(0)
        // console.log("stratAddress", stratAddress)
        setTokenSelectedRow(stratAddress);
        if(stratAddress.toLowerCase() !== aqua_strategy_address_new.toLowerCase() && stratAddress.toLowerCase() !== gamma_strategy_address_new.toLowerCase()){
            let revisedTokenList = tokenDataList.filter((pool: any) => pool.sno !== 100);
            // console.log("pool data in handleTokenModal", poolData, revisedTokenList)
            let lpTokenObject = {
                sno: 100,
                address: poolData.strategyAddress,
                name: poolData.name,
                price: poolData.price,
                value: poolData.walletBalance,
                usdValue: poolData.walletBalanceUSD
            }
            if (revisedTokenList.some((pool: any) => pool.address.toLowerCase() === stratAddress.toLowerCase())) {
                // console.log("pool found !")
                
            } else {
                // console.log("pool NOT found !")
                revisedTokenList.push(lpTokenObject)
            }
            setCurrTokenList(revisedTokenList)
            
        }
        
    } 

    const handleUnstakePopUpToggleModal = () => {
        setShowUnstakePopUp(false)
    }

    return (
        <Table isBorderDisable={isBorderDisable}>
            <TableHeader className='newPools'>
                <HeadTextCont />
                <HeadTextCont>
                    <HeadTextData onClick={() => handleTabClick('tvl')} isDown={sortByTh === 'tvl' && sortDescending} >
                        <HeadText>TVL</HeadText>
                        <img src={sortByTh === 'tvl' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                <HeadTextCont>
                    <HeadTextData onClick={() => handleTabClick('apy')} isDown={sortByTh === 'apy' && sortDescending}>
                        <HeadText>APY</HeadText>
                        <img src={sortByTh === 'apy' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                <HeadTextCont className='show-web'>
                    <HeadTextData onClick={() => handleTabClick('wallet')} isDown={sortByTh === 'wallet' && sortDescending}>
                        <HeadText>In Wallet</HeadText>
                        <img src={sortByTh === 'wallet' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                <HeadTextCont className='show-web'>
                    <HeadTextData onClick={() => handleTabClick('vault')} isDown={sortByTh === 'vault' && sortDescending}>
                        <HeadText>In Pool</HeadText>
                        <img src={sortByTh === 'vault' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                {/* <HeadTextCont className='show-web'>
                    <HeadTextData onClick={() => handleTabClick('rewards')} isDown={sortByTh === 'rewards' && sortDescending}>
                        <HeadText>Rewards</HeadText>
                        <img src={sortByTh === 'rewards' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                    <InfoTooltip infoId='poolRewards' left={5} />
                </HeadTextCont> */}
                {pools == "all" ?
                <HeadTextCont className='show-mob'>
                    <HeadTextData onClick={() => handleTabClick('wallet')} isDown={sortByTh === 'wallet' && sortDescending}>
                        <HeadText>In Wallet</HeadText>
                        <img src={sortByTh === 'wallet' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                    {/* <InfoTooltip infoId='poolRewards' left={5} /> */}
                </HeadTextCont>
                :
                <HeadTextCont className='show-mob'>
                    <HeadTextData onClick={() => handleTabClick('vault')} isDown={sortByTh === 'vault' && sortDescending}>
                        <HeadText>In Pool</HeadText>
                        <img src={sortByTh === 'vault' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                    {/* <InfoTooltip infoId='poolRewards' left={5} /> */}
                </HeadTextCont>
                }
                <HeadTextCont />
            </TableHeader>

            {poolLoading ? <TableLoading /> : renderTableData()}

            <CustomActionModal show={showPoolDetails} toggleModal={togglePoolDetailsModal}>
                {showPoolDetails && 
                <PoolDetailsModal 
                    togglePoolModal={togglePoolDetailsModal} 
                    data={modalData} 
                    handleGetLpToken={handleGetLpToken} 
                    setModalData={setModalData} 
                    setTokenSwapAmount={setTokenSwapAmount}
                    currentTab={activeTab} 
                    sendToken={sendToken}
                    tokenDataList={tokenDataList}
                    setSendTokenFromParent={setSendToken}
                    showSwapPopup={showSwapPopup}
                    setUnstakeData={setUnstakeData}
                    setShowUnstakePopUp={setShowUnstakePopUp}
                    confirmUnstake={confirmUnstake}
                    setConfirmUnstake={setConfirmUnstake}
                    showUnstakePopUp={showUnstakePopUp}
                />}
            </CustomActionModal>
            <CustomActionModal show={boostPopup} toggleModal={toggleBoostPopupModal}>
                {boostPopup && <BoostCalculationModal togglePoolModal={toggleBoostPopupModal} data={modalData} handleGetLpToken={handleGetLpToken} setModalData={setModalData} currentTab={activeTab} poolData={poolData}/>}
            </CustomActionModal>
            <CustomModal show={showTCAModal} toggleModal={setShowTCAModal}>
                {showTCAModal && activePoolList.includes(transData.strategyAddress) && <PoolTCAction setShowTCAModal={setShowTCAModal} transData={transData} key={transData.walletBalance || transData.vaultBalance} type={lpAction} poolTable={true} zapTokenAmount={tokenSwapAmount} zapTokenObject={sendToken} setShowPoolDetails={setShowPoolDetails} />}
                {showTCAModal && !activePoolList.includes(transData.strategyAddress) && <OldPoolTCAction setShowTCAModal={setShowTCAModal} transData={transData} />}
            </CustomModal>
            <CustomActionModal show={showSwapPopUp} toggleModal={toggleSwapPopupModal}>
                {showSwapPopUp && <SwapInPools tokenList={tokenDataList} updateTokenList={updateTokenListData} initialTokenAdd={initialTokenAdd} receiveTokenNumber={receiveTokenNumber} closeSwapModal={closeSwapModal} tokenSwapAmount={tokenSwapAmount} toggleSwapPopupModal={toggleSwapPopupModal} sendTokenObject={sendToken} />}
            </CustomActionModal>
            <CustomModal show={showTokenModal} toggleModal={handleToggleModal}>
            {showTokenModal && (
                <TokenSelect
                    selectedAdd={sendToken.address}
                    setShowTokenModal={setShowTokenModal}
                    tokenListVal={currTokenList}
                    handleTokenSelect={handleTokenSelect}
                    existTokenAdd={existTokenAdd}
                    crossIconColor={'white'}
                />
            )}
            </CustomModal>
            <CustomModal show={showUnstakePopUp} toggleModal={handleUnstakePopUpToggleModal}>
                <UnStakeInstantlyPopUp 
                    setShowUnstakePopUp={setShowUnstakePopUp} 
                    showUnstakePopUp={showUnstakePopUp}
                    unstakeData={unstakeData}
                    setConfirmUnstake={setConfirmUnstake}
                />
            </CustomModal>
        </Table>

    );
};

export default PoolTable;