import { useState, useEffect } from 'react'
import { CardSubText } from 'shared/styles/globalText'
import {   GTokenWeb, GTokenMob, GCardText, GMCardBText, GLoadingText4 } from './style'
import TvlIcon from 'assets/icons/tvl-icon.svg'
import { commaFy, checkZeroVal, convertToKAndM } from 'service/globalFunctions'
let timerForGammaBalanceMobile = setInterval(function () { }, 0)
const GammaBalanceMobile = (props: any) => {
    const { gammaInfData, infVaultLoading } = props
    const [gammaBalance, setgammaBalance] = useState('')
    useEffect(() => {
        if (timerForGammaBalanceMobile) {
            clearInterval(timerForGammaBalanceMobile); 
        }
        if(gammaInfData.deposited_bal)
        {
            startTimerTripping();
        }
        return () => clearInterval(timerForGammaBalanceMobile);
    }, [gammaInfData, infVaultLoading])

    const startTimerTripping = () => {
        if(gammaInfData.deposited_bal)
        {
            setgammaBalance(gammaInfData.deposited_bal)
            clearInterval(timerForGammaBalanceMobile)
            let gammaBalanceData: any = gammaInfData.deposited_bal;
            // let trippingGammaBalance: any = gammaBalanceData / (84600 * 365);
            // trippingGammaBalance = 1;
            let apy = gammaInfData.apy;
            let m: any = 365
            let a: any = apy / 100 + 1
            let apr = (Math.pow(a, 1 / m) - 1) * m
            let trippingGammaBalance = (parseFloat(gammaInfData.deposited_bal) * (apr)) / (365 * 86400)
            //console.log(trippingGammaBalance, 'trippingGammaBalance');

            let now = new Date();
            let timeWhenStarted = now.getTime();
            timerForGammaBalanceMobile = setInterval(function () {
                now = new Date();
                let newGammaBalance =  gammaInfData.deposited_bal + (( now.getTime() - timeWhenStarted ) * trippingGammaBalance / 1000);
                //console.log(newGammaBalance)
                setgammaBalance(newGammaBalance)
            }, 1000)
        }
    }
    return (
        <div>
            {infVaultLoading ? <GLoadingText4 /> :
                <GMCardBText isValZero={checkZeroVal(gammaInfData.deposited_bal)}>{!!gammaInfData && gammaInfData.deposited_bal ? commaFy(parseFloat(gammaInfData.deposited_bal).toFixed(2)) : '0.00'}</GMCardBText>
            }
            <GCardText>Balance</GCardText>
        </div>
    );
};

export default GammaBalanceMobile;