import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'

export const SwapContainer = styled.div`
  width: 100%;
  max-width: 597px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 76px auto 50px auto;
  padding: 16px;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 20px 0 0 0;
    margin: 0px auto 50px auto;
  }

  @media (max-width: 799px) {
    margin: 20px auto 50px auto;
  }

  &.add{
    margin: 10px 30px 0px auto;
    @media (min-width: ${screenSizes.mediaM}px) {
      padding: 0px 0 0 0;
    }
    @media (max-width: 799px) {
      padding: 0px 0 0 0;
      height: 35px;
    }
  }
  
`

export const SwapHeaderCont = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  max-width: fit-content;
  height: 30px;
  /*border: 1px solid ${colors.borderColor};*/
  border-radius: 100px;
  box-sizing: border-box;
  margin-bottom: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-bottom: 20px;
    height: 40px;
  }
  @media (min-width: 1050px) {
    zoom:0.8 !important;
  }
  @media (min-width: 1350px) {
    zoom:1 !important;
  }
  @media (min-width: 1550px) {
    zoom:1 !important;
  }
  @media (min-width: 1750px) {
    zoom:1 !important;
  }
`
export const SwapHeaderContAlt = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  max-width: 420px;
  height: 30px;
  /*border: 1px solid ${colors.borderColor};*/
  border-radius: 100px;
  box-sizing: border-box;
  margin-bottom: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-bottom: 30px;
    /*height: 40px;*/
  }
  @media (min-width: 1050px) {
    zoom:0.8 !important;
  }
  @media (min-width: 1350px) {
    zoom:0.85 !important;
  }
  @media (min-width: 1550px) {
    zoom:0.9 !important;
  }
  @media (min-width: 1750px) {
    zoom:1 !important;
  }
  &.addStable{
    margin-bottom : 0px;
    border-radius : 0px;
    height: 100%;
  }
`

export const SwapStatusText = styled.div<any>`
  padding: ${(props: any) =>
    props.activeStatus ? `5px 15px 5px 15px` : '5px 15px 5px 15px'};
  color: ${(props: any) => (props.activeStatus ? '#55B7FF' : 'rgba(255,255,255,0.4)')};
  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 18px;
  border-radius: 100px;
  max-width: 138px;
  cursor: ${(props: any) => (props.activeStatus ? 'initial' : 'pointer')};
  background: ${(props: any) =>
  props.activeStatus ? `#172B3A 0% 0% no-repeat padding-box` : 'none'};

  @media (min-width: ${screenSizes.mediaM}px) {
    padding: ${(props: any) =>
      props.activeStatus ? `9px 30px 9px 30px` : '9px 30px 9px 30px'};
    margin-right: ${(props: any) =>
      props.activeStatus ? `0px` : '0px'};
    font-size: 24px;
    line-height: 21px;
  }
  &.tabText:hover {
    color: #55B7FF;
  }
`
export const SwapStatusTextAlt = styled.div<any>`
  /*padding: 7px 20px 5px 20px;*/
  color: ${(props: any) => (props.activeStatus ? '#FFFFFF' : 'rgba(255,255,255,0.4)')};
  font-family: MontserratSemiBold;
  font-size: 14px;
  line-height: 18px;
  border-radius: 100px;
  max-width: 138px;
  cursor: ${(props: any) => (props.activeStatus ? 'initial' : 'pointer')};
  background: ${(props: any) =>
    props.activeStatus ? `rgba(85,183,255, 0.2)` : 'none'};

  @media (min-width: ${screenSizes.mediaM}px) {
    /*padding: 7px 30px 9px 30px;*/
    font-size: 19px;
    line-height: 21px;
    &.stableTabText{
      font-size: 24px;
      line-height: 29px;
    }
  }
  &.tabText:hover {
    color: white;
  }
  &.stableTabText{
    margin-right: 20px;
    border-bottom: ${(props: any) => (props.activeStatus ? '3px solid #55B7FF' : 'none' )};
    padding-bottom: ${(props: any) => (props.activeStatus ? '14px': '16px')};
    border-radius: 0px;
    background: transparent;
  }
`