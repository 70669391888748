import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch, connect } from 'react-redux'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { transLinkUrl } from 'service/global-constant'
import Spinner from 'shared/spinner'
import { updateLPData, fetchUserPortfolio } from 'logic/action/user.actions'
import { MainContainer, FlexCont, CardButton, CardButton2, FlexSBCont } from 'shared/styles/styled'
import { TitleText, TitleInfo, CardTitle, CardText, CardSubText } from 'shared/styles/globalText'
import { BaseContainer, BaseHeader, TvlInfoBox, Card, UserInfoCont, OverViewInfo, CardNumText, BalanceWrapper, BalanceCont, BalanceText, LevelBox, MobBalWrapper, MobBalCard, MobBuyAssetCont, MobBalText, LoadingText1, LoadingText2, ConnectWalletBtn } from './style'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import TvlIcon from 'assets/icons/tvl-icon.svg'
import { commaFy, convertToKAndM } from 'service/globalFunctions'
import InfoTooltip from 'shared/info-tool-tip'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import CollectRewards from '../pools_v3/components/pool-modal/CollectRewards'
import Projection from './components/Projection'
import PlanetActions from './components/PlanetActions'
import PlanetToken from './components/PlanetToken'
import ValueLocked from './components/ValueLocked'
import CountUp from "react-countup"
import PlanetNews from './components/PlanetNews'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { aquaAddress, vestingVaultPid } from 'modules/block-chain/abi'
import GammaBoost from './components/GammaBoost'
import HighestApySection from './components/HighestApySection'
import { NewsWrapper, NewsContainer, NewsDataCard, NewsContent, NewsTitle, NewsInfo } from './components/style'
import CustomModal from 'shared/custom-modal'
import WalletLogout from 'modules/app/components/wallet-modal/WalletLogout'
import WalletList from 'modules/app/components/wallet-modal/WalletList'
import { connectWallet } from 'modules/wallet-provider/WalletProvider'
import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsAvatar,
    useEnsName,
  } from 'wagmi'
import { useWeb3Modal } from "@web3modal/react";
import { connectEthWallet, disconnectEthWallet } from 'logic/action/wallet.actions'
import wallet from 'modules/wallet/wallet'
import RightIcon from 'assets/icons/right_arrow.svg'
import { InfoNumberTxt } from 'modules/lending/style'
import { DataInfoTxt, MaxBox, MaxBoxZero, ModalInput, ModalInputCont } from 'shared/styles/highestApyStyle'
import { UnderstandCheckBox } from 'modules/pools/components/style'
import grey_tick from 'assets/icons/grey_tick.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { handleWithdrawVesting, getEarlyVestingPenaltyFactor } from 'modules/block-chain/BlockChainMethods'

const Vesting = (props: any) => {
    const { portfolioLoading, userPortfolio, platformLoading, platformData } = props
    const selector = useSelector((state: any) => state)
    const { ethWalletConnected } = selector.ethData
    const [openWalletList, setOpenWalletList] = useState(false)
    const [isUnderstand, setIsUnderstand] = useState(false)
    const [sendAmount, setSendAmount] = useState('')
    const [displaySendAmount, setDisplaySendAmount] = useState('');
    const [withdrawAmount, setWithdrawAmount] = useState(0);
    const [receiveAmount, setReceiveAmount] = useState(0);
    const [showCollectModal, setShowCollectModal] = useState(false)
    const [vestAmount, setVestAmount] = useState(
        {
            "totalVestBalance": "0",
            "totalVestBalanceUsd": "0",
            "totalVestBalanceWei": 0,
            "maxWithdrawAbleAmount": 0,
            "vestingBalance": 0,
            "unlockedVestingBalance": "0",
            "lockedVestingBalance": "0",
            "vestingBalanceUsd": 0,
            "unlockedVestingBalanceUsd" : 0,
            "unlockedVestingBalanceWei": 0
        }
    )
    const [penalty, setPenalty] = useState(0);
    const [maxAmtSelected , setMaxAmtSelected] = useState(false)
    const [platform_data, setPlatformData] = useState({
        aqua_price: 0,
        gamma_price: 0
    })
    const { connector: activeConnector, address, isConnected } = useAccount()
    const { connect, connectors, error, isLoading, pendingConnector } = useConnect()
    const { disconnect } = useDisconnect()
    const [loading, setLoading] = useState(false);
    const [collectLoading, setCollectLoading] = useState(false);
    const { open } = useWeb3Modal();
    const dispatch = useDispatch()

    useEffect(() => {
        setPlatformData(platformData);
    },[platformData])

    useEffect(() => {
        setPlatformData(platformData);
        if(address && address !== null){
            // console.log("set wallet address to local storage", address)
            dispatch(connectEthWallet(address));
            localStorage.setItem("walletConnected", JSON.stringify(true));
            let walletType = localStorage.getItem("wagmi.wallet");
            if(walletType != null  ) {
                let type = JSON.parse(walletType) == "walletConnect"? 3 : 1;
                localStorage.setItem("walletType", JSON.stringify(type));
                setProviderInWallet(type)
            } else {
                setProviderInWallet(1)
            }
        }
    },[platformData, address])

    const setProviderInWallet = async (wallet_type?: any) => {
        if(wallet_type == 3){
          await wallet.setProvider(wallet_type);
          const address = await wallet.login(wallet_type);
        } else {
            await wallet.setProvider(1);
          }
    }

    useEffect(() => { 
        let totalVestBalance = 0;
        let totalVestBalanceUsd = 0;
        let totalVestBalanceWei = 0;
        let maxWithdrawAbleAmount = 0;
        let currentTimeStamp = Math.floor(Date.now() / 1000);
        let vests = userPortfolio.vestingPositions && userPortfolio.vestingPositions.userLockArray ? userPortfolio.vestingPositions.userLockArray : [];
        
        let vestingBalance = userPortfolio.vestingPositions && userPortfolio.vestingPositions.vestingBalance? userPortfolio.vestingPositions.vestingBalance: 0;
        let unlockedVestingBalance = userPortfolio.vestingPositions && userPortfolio.vestingPositions.unlockedVestingBalance?  userPortfolio.vestingPositions.unlockedVestingBalance: 0;

        let unlockedVestingBalanceUsd = userPortfolio.vestingPositions && userPortfolio.vestingPositions.unlockedVestingBalanceUsd?  userPortfolio.vestingPositions.unlockedVestingBalanceUsd: 0;

        let lockedVestingBalance = userPortfolio.vestingPositions && userPortfolio.vestingPositions.lockedVestingBalance? userPortfolio.vestingPositions.lockedVestingBalance: 0;

        let unlockedVestingBalanceWei = userPortfolio.vestingPositions && userPortfolio.vestingPositions.unlockedVestingBalanceWei? userPortfolio.vestingPositions.unlockedVestingBalanceWei: 0;

        let vestingBalanceUsd = userPortfolio.vestingPositions && userPortfolio.vestingPositions.vestingBalanceUsd? userPortfolio.vestingPositions.vestingBalanceUsd: 0;

        vests.map((vest: any) => {
            totalVestBalanceUsd += parseFloat(vest.lockedAmountUsd);
            totalVestBalance += parseFloat(vest.lockedAmount);
            totalVestBalanceWei += parseFloat(vest.lockedAmountWei);
            maxWithdrawAbleAmount += vest.unlockTimeStamp <= currentTimeStamp? parseFloat(vest.lockedAmount): 0;
        })
        // return {
        //     "totalVestBalance": totalVestBalance.toFixed(2),
        //     "totalVestBalanceUsd": totalVestBalanceUsd.toFixed(2),
        //     "totalVestBalanceWei": totalVestBalanceWei
        // };
        setVestAmount({
            "totalVestBalance": totalVestBalance.toFixed(6),
            "totalVestBalanceUsd": totalVestBalanceUsd.toFixed(2),
            "totalVestBalanceWei": totalVestBalanceWei,
            "maxWithdrawAbleAmount": maxWithdrawAbleAmount,
            "vestingBalance": vestingBalance,
            "unlockedVestingBalance" : parseFloat(unlockedVestingBalance).toFixed(6),
            "lockedVestingBalance": parseFloat(lockedVestingBalance).toFixed(6),
            "vestingBalanceUsd": vestingBalanceUsd,
            "unlockedVestingBalanceUsd" : unlockedVestingBalanceUsd.toFixed(2),
            "unlockedVestingBalanceWei": unlockedVestingBalanceWei
        });


    }, [userPortfolio.vestingPositions])

    const handleGammaCollect = async () => {
        //setShowCollectModal(true);
        
        if (selector.ethData.ethWalletConnected && vestAmount.unlockedVestingBalanceWei > 0) {
            const userAddress = selector.ethData.address
            try {
                setCollectLoading(true);
                await handleWithdrawVesting( vestAmount.unlockedVestingBalance, userAddress, handleNotification, vestAmount,   false, false);
                dispatch(updateLPData(selector.ethData.address))
                dispatch(fetchUserPortfolio(selector.ethData.address, true))
                setCollectLoading(false);
            }
            catch (error) {
                console.log("error==>", error)
                handleNotification('failed')
            }
            finally {
                setCollectLoading(false);
            }
        }
    }

    const toggleCollectModal = () => {
        setShowCollectModal(false)
    }

    const handleVestingAmount = async (e: any) => {
        const { value } = e.target
        let penalyFactor = await getEarlyVestingPenaltyFactor();
        let penalty = penalyFactor/10000 * 100;
        setPenalty(penalty);
        if (floatNumRegex.test(value.toString())) {
            setMaxAmtSelected(false)
            setWithdrawAmount(parseFloat(value));
            setDisplaySendAmount(value.toString());
            setReceiveAmount((parseFloat(value) - (parseFloat(value) * (penalyFactor/10000))));
        }
        if (!value) {
            setMaxAmtSelected(false)
            setWithdrawAmount(parseFloat("0"))
            setDisplaySendAmount("0")
            setReceiveAmount(0);
            setIsUnderstand(false)
        }
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `Amount ${sendAmount} Unstaked` : `Failed to Unstake`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const getExitEarlyText = () => {
        return  loading ? 
            <Spinner /> 
            : 
            'Exit Early';
    }

    const getCollectGammaText = () => {
        return  collectLoading ? 
            <Spinner /> 
            : 
            'Collect';
    }

    const handleExitEarly = async () => {
        if (selector.ethData.ethWalletConnected && withdrawAmount > 0) {
            const userAddress = selector.ethData.address
            try {
                setLoading(true)
                await handleWithdrawVesting( withdrawAmount, userAddress, handleNotification, vestAmount,  maxAmtSelected, true);
                dispatch(updateLPData(selector.ethData.address))
                dispatch(fetchUserPortfolio(selector.ethData.address, true))
                setMaxAmtSelected(false)
                setIsUnderstand(false)
                setWithdrawAmount(0)
            }
            catch (error) {
                console.log("error==>", error)
                handleNotification('failed')
            }
            finally {
                setLoading(false)
            }
        }
    }

    const handleMax = async () => {
        let maxAmount = vestAmount.totalVestBalanceWei;
        setMaxAmtSelected(true)
        let penalyFactor = await getEarlyVestingPenaltyFactor();
        let penalty = penalyFactor/10000 * 100;
        setPenalty(penalty);
        setWithdrawAmount(parseFloat(vestAmount.totalVestBalance));
        setReceiveAmount((parseFloat(vestAmount.totalVestBalance) - (parseFloat(vestAmount.totalVestBalance) * penalty / 100)));
        setDisplaySendAmount(maxAmount.toString());
    }

    const renderVests = () => {
        let vests = userPortfolio.vestingPositions && userPortfolio.vestingPositions.userLockArray? userPortfolio.vestingPositions.userLockArray : [];
        return vests.map((vest: any) => { 
            return (
                <>
                    <CardText className='overview-title'>{vest.lockedAmount.toFixed(6)} GAMMA</CardText>
                    <CardText className='overview-title'>{'$' + vest.lockedAmountUsd.toFixed(2)}</CardText>
                    <CardText className='overview-title-white'>{vest.unlockTime}</CardText>
                </>
            )
        })
    }



    return (
        <MainContainer className='vesting'>
            <BaseContainer>
                <BaseHeader>
                    <div>
                        <TitleText className='vesting'>Vesting</TitleText>
                        <TitleInfo className="sub-title">Manage your vesting tokens</TitleInfo>
                    </div>

                </BaseHeader>
                <CardText className='vestingText'>Already Vested</CardText>
                <FlexCont className="vestingGammaCont">
                    <img className="vesting-gamma-icon" src={GAMMAIcon} alt="" />
                    <CardText className='vestingGammaVal'>
                        <>
                            {(parseFloat(vestAmount.unlockedVestingBalance).toFixed(4)).split(".")[0]}.<span>{(parseFloat(vestAmount.unlockedVestingBalance).toFixed(4)).split(".")[1]}</span>
                        </>
                    </CardText>
                    <CardButton2 className="collectGammaVesting" disabled={parseFloat(vestAmount.unlockedVestingBalance) <= 0} onClick={() => handleGammaCollect()}>{ getCollectGammaText() }</CardButton2>
                </FlexCont>
                <CardText className='vestingText'>Currently Vesting</CardText>

                <FlexCont className="vestingGammaCont">
                    <img className="vesting-gamma-icon" src={GAMMAIcon} alt="" />
                    <CardText className='vestingGammaVal'>
                        <>
                            {(parseFloat(vestAmount.lockedVestingBalance).toFixed(4)).split(".")[0]}.<span>{(parseFloat(vestAmount.lockedVestingBalance).toFixed(4)).split(".")[1]}</span>
                        </>
                    </CardText>
                </FlexCont>
                {
                    userPortfolio.vestingPositions && userPortfolio.vestingPositions.userLockArray && userPortfolio.vestingPositions.userLockArray.length > 0 ?
                    <>
                        <FlexCont>
                            <OverViewInfo className="vestingTableHeader">
                                <>
                                    <CardText className='overview-title'>Amount</CardText>
                                    <CardText className='overview-title'>Dollar Value</CardText>
                                    <CardText className='overview-title'>Estimated Unlock Time</CardText>
                                </>
                            </OverViewInfo>
                            <OverViewInfo className="vestingTableHeaderMob">
                                <CardText className='overview-title-mob'>Amount</CardText>
                                <CardText></CardText>
                                <CardText className='overview-title-mob'>Dollar</CardText>
                                <CardText></CardText>
                                <CardText className='overview-title-mob'>Estimated Unlock Time</CardText>
                            </OverViewInfo>
                        </FlexCont>
                        <FlexCont>
                            <OverViewInfo className="vestingTableBody">
                                {renderVests()}
                            </OverViewInfo>
                            <OverViewInfo className="vestingTableBodyMob">
                                {renderVests()}
                            </OverViewInfo>
                        </FlexCont>
                        <FlexCont>
                            <FlexSBCont className="flex-column-cont">
                                <CardText className='exitEarlyTextDisabled'>Exit Early</CardText>
                                <FlexCont>
                                    <ModalInputCont className="vesting-input">
                                        <ModalInput 
                                            className="vesting-input" 
                                            placeholder="0" 
                                            onChange={handleVestingAmount} 
                                            value={withdrawAmount && withdrawAmount}
                                        />
                                        <MaxBox className="maxbox" onClick={() => handleMax()}>
                                            MAX
                                        </MaxBox>
                                        <img className='token-icon-vesting' src={GAMMAIcon} alt="" />
                                        <CardText className="vesting-input-text">GAMMA</CardText>
                                    </ModalInputCont>
                                    {
                                        isUnderstand  ? 
                                            <MaxBox className='exitEarlyBtn'  onClick={() => handleExitEarly()}>
                                                {getExitEarlyText()}
                                            </MaxBox>
                                        :
                                            <MaxBoxZero className='exitEarlyBtn' >
                                                Exit Early
                                            </MaxBoxZero>
                                    }
                                </FlexCont>
                                <FlexCont className="vesting-details-cont">
                                    <FlexSBCont className='vesting-details'>
                                        <DataInfoTxt light className="vesting-details-text">I understand the Penalty</DataInfoTxt>
                                        <UnderstandCheckBox className="vesting-checkbox" onClick={() => !!withdrawAmount && withdrawAmount > 0 && setIsUnderstand(!isUnderstand)}>{isUnderstand && <img src={grey_tick} alt="" />}</UnderstandCheckBox>
                                    </FlexSBCont>
                                    <FlexSBCont className='vesting-details'>
                                        <DataInfoTxt light className="vesting-details-text">Penalty:</DataInfoTxt>
                                        <DataInfoTxt className="vesting-details-text">{penalty? penalty: 0}</DataInfoTxt>
                                    </FlexSBCont>
                                    <FlexSBCont className='vesting-details'>
                                        <DataInfoTxt light className="vesting-details-text">Withdraw:</DataInfoTxt>
                                        <DataInfoTxt className="vesting-details-text">{withdrawAmount}</DataInfoTxt>
                                    </FlexSBCont>
                                    <FlexSBCont className='vesting-details'>
                                        <DataInfoTxt light className="vesting-details-text">Receive:</DataInfoTxt>
                                        <DataInfoTxt className="vesting-details-text">{receiveAmount}</DataInfoTxt>
                                    </FlexSBCont>
                                </FlexCont>
                            </FlexSBCont>
                        </FlexCont>
                    </>
                :
                    <>
                    </>
                }
            </BaseContainer>
            <CustomActionModal show={showCollectModal} toggleModal={toggleCollectModal}>
                {showCollectModal && <CollectRewards pendingGamma={parseFloat(vestAmount.unlockedVestingBalance)} gammaPrice={userPortfolio.gammaPrice} toggleCollectModal={toggleCollectModal}/>}
            </CustomActionModal>
        </MainContainer>
    );
};

const mapStateToProps = (state: any) => ({
    portfolioLoading: state.user.portfolioLoading,
    userPortfolio: state.user.userPortfolio,
    platformLoading: state.user.platformLoading,
    platformData: state.user.platformData,
})
export default connect(mapStateToProps)(Vesting)