import { instType, priceOracleAddress, priceOracleAddressOld, selectInstance } from './lendingAbi'



/************************************************Read Only Functions************************************************************/

export const assetPrices = async (assetAddress: string) => {
    try {
        // const inst: any = await selectInstance(instType.priceOracle, priceOracleAddress)
        const inst: any = assetAddress.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() || assetAddress.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase()? await selectInstance(instType.priceOracleOld, priceOracleAddressOld) : await selectInstance(instType.priceOracle, priceOracleAddress) 
        const data = await inst.methods.assetPrices(assetAddress).call()
        return data
    } catch (e) {
        console.log("gtokenAddress inside assetPrices",e)
        return 0
    }
}

var underlyingPriceGLOBAL: any = {};
var underlyingPriceTime_GLOBAL:any = '';

export const getUnderlyingPrice = async (gTokenAddress: string,blockNumber?:number) => {
    try {
        var nowtime = new Date().getTime();
        var timeDiff = nowtime - underlyingPriceTime_GLOBAL;
        if(underlyingPriceGLOBAL != undefined && timeDiff < 10000 ) {
            if( underlyingPriceGLOBAL[gTokenAddress.toLowerCase()] != undefined ) {
                return underlyingPriceGLOBAL[gTokenAddress.toLowerCase()];
            }
        }
        else {
            const inst: any = gTokenAddress.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() || gTokenAddress.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() ? await selectInstance(instType.priceOracleOld, priceOracleAddressOld) : await selectInstance(instType.priceOracle, priceOracleAddress) 
            const data = blockNumber === undefined ? await inst.methods.getUnderlyingPrice(gTokenAddress).call() :
            await inst.methods.getUnderlyingPrice(gTokenAddress).call(undefined,blockNumber)
            
            underlyingPriceGLOBAL[gTokenAddress.toLowerCase()] = data;
            underlyingPriceTime_GLOBAL = new Date().getTime();
            return data
        }
    } catch (e) {
        console.log("getUnderlyingPrice inside block chain green ",gTokenAddress, e)
        return 0
    }
}




/********************************************************************************************************************************/

