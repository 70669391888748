import axios from 'axios'
import { BigNumber } from 'bignumber.js'
import {
  aquaAddress,
  aquaFarmAbi,
  aquaFarmAddress,
  masterchefAbi,
  pancakeLPabi,
  pancakeFactoryAbi,
  pancakFactoryAddress,
  aquaAutoCompPoolAddress,
  aquaAutoCompPoolAbi,
  STRATEGY_ABI,
  gammaFarmAdddress,
  gammaFarmAbi,
  pancakeFarmAddress,
  priceOracleAbi,
  multicall_abi,
  multicall_address,
  busdAddress,
  usdcAddress,
  gamma_infinity_vault_abi,
  gamma_infinity_vault_address,
  aqua_infinity_vault_abi,
  aqua_infinity_vault_address,
  gamma_infinity_vault_abi_old,
  gamma_infinity_vault_address_old,
  aqua_infinity_vault_abi_old,
  aqua_infinity_vault_address_old,
  aqua_strategy_address,
  gamma_strategy_address,
  aqua_strategy_address_new,
  gamma_strategy_address_new,
  aqua_strategy_abi,
  gamma_strategy_abi,
  anchorApy,
  gamma_reservoir,
  reservoir_abi,
  gammaFarmAdddressOld,
  gammaFarmAbiOld,
  normal_strategy_abi_new,
  normal_strategy_address_new,
  ERC20_abi,
  stableswap_main_abi_nG,
  bnbxAddress,
  gammaFarmV3Abi,
  gammaFarmV3,
  new_gamma_reservoir,
  new_gamma_reservoir_abi,
  v3_strategy_abi,
  v3_strategy_address,
  gammaFarmV3 as v3FarmAddress,
  gammaFarmV3Abi as v3FarmAbi ,
  v3_strategy_abi as v3StrategyAbi,
  vestingStrategyAbi,
  vestingPoolStrategyAddress,
  vestingVaultPid,
  vesting_strat_abi,
  eligibilityDataProviderAbi,
  eligibilityDataProviderAddress
} from './abi'
import { formatUnits } from '@ethersproject/units'
import { zeroAddress, aquaZapInAddress, PLANETLPABI, stakeManagerABI, stakeManagerAddress, aquaConvertLpAddress } from 'modules/block-chain/SwapDexAbi'
import wallet from 'modules/wallet/wallet'
import {
  getGammaPrice,
  getSupplyBalance,
  getTotalSupplyBalance,
  singleVaultsDataForFarm,
  getPendingData,
  getSupplyApy,
  getGammaSupplyApy,
  getBurnApy,
  getTreasuryApy,
  getMarketLiquidityData,
  getDiscountLevel,
  getPendingRewardsUsingMulticall,
  getUnderlyingDecimal,
  getBurnApr,
} from './LendingBase'
import {
  gBnbAbi,
  gAqua,
  maxAmount,
  gammaAddress,
  gGamma,
  treasuryAddress,
  AQUA_GAMMALPAddress,
  oldgAqua,
  oldgGamma,
  GAMMA_BNBLPAddress,
  priceOracleAddress,
  gammatrollerAddress,
  gammatrollerAbi,
  gTokenAbi,
  AQUA_BNBLPAddress,
  gBnbAddress,
  approvalAmount,
  newBoostedVaultsAbi,
  claim_cake_abi,
  priceOracleAbiOld,
  gammatrollerAbiOld,
  priceOracleAddressOld,
  gammatrollerAddressOld,
  gGammaOld,
  gAquaOld,
  gammaAbi,
  MAX_INT,
  apiKey,
  gBNBxAddress
} from './lendingAbi'
import { balanceOfGtoken, decimals, exchangeRateStored, symbol, underlying } from './gBNB'
import { bluePfTokenList, pfTokenList, redPfTokenList, pfMarketTokenList, pfTokenMarketList, OldGTokenList, bluePfTokenListWithoutAquaGamma } from 'modules/block-chain/pfTokenList'
import { getUnderlyingPrice , getUnderlyingPriceInfinityVaults} from './priceOracle'
import { newPFApiBaseUrl, planetFinanceApiBaseUrl } from "service/global-constant";
import { profile } from 'console'
import {getBorrowBalanceMulticall, getBorrowApyMulticall, getGammaBorrowApyMulticall, getApyOnLevelSelectAtBorrowRevised } from '../block-chain-green/LendingBase'
import {gammaSpeedsMulticall, getAssetsInMulticall} from '../block-chain-green/gammatroller'
import { AddressText } from 'modules/app/components/wallet-modal/style'
import { send } from 'process'
import { returnTokenPriceMulticall } from './tokenList'
import { convertToEtherRevised } from './Swap'
import planetGlobalObject  from 'global/GlobalVar'
import moment from 'moment'
import momentTimeZone from 'moment-timezone'
import gammaInfinityIcon from 'assets/icons/gammaInfinity.svg'
// import { resolve } from 'dns'

export const tokenList: any = {
  '0': { '0': 'AQUA-BNB', '1': 'AQUAPAIR' },
  '1': { '0': 'AQUA', '1': 'AQUA' },
  '2': { '0': '4BELT', '1': '4BELT' },
  '3': { '0': 'BUSD-USDT', '1': 'AUTOPAIR' },
  '4': { '0': 'BUSD-VAI', '1': 'AUTOPAIR' },
  '5': { '0': 'BUSD-UST', '1': 'AUTOPAIR' },
  '6': { '0': 'beltBNB', '1': 'SINGLEBELT' },
  '7': { '0': 'beltBTCB', '1': 'SINGLEBELT' },
  '8': { '0': 'beltETH', '1': 'SINGLEBELT' },
  '10': { '0': 'CAKE-BNB', '1': 'BUNNYPAIR' },
  '11': { '0': 'BUSD-BNB', '1': 'BUNNYPAIR' },
  '12': { '0': 'CAKE', '1': 'PCSv2SINGLE' },
  '13': { '0': 'AQUA-BNB', '1': 'AQUAPAIR' },
  '14': { '0': 'AQUA-CAKE', '1': 'AQUAPAIR' },
  '15': { '0': 'CAKE-BNB', '1': 'AQUAPAIR' },
  '16': { '0': 'BNB-BUSD', '1': 'AQUAPAIR' },
  '17': { '0': 'BUSD-CAKE', '1': 'AQUAPAIR' },
  '18': { '0': 'MCOIN-UST', '1': 'AQUAPAIR' },
  '19': { '0': 'BNB-ETH', '1': 'AQUAPAIR' },
  '20': { '0': 'BNB-BTCB', '1': 'AQUAPAIR' },
  '21': { '0': 'BNB-USDT', '1': 'AQUAPAIR' },
  '22': { '0': 'BTCB-BUSD', '1': 'AQUAPAIR' },
  '23': { '0': 'BNB-LINK', '1': 'AQUAPAIR' },
  '24': { '0': 'BNB-DOT', '1': 'AQUAPAIR' },
  '25': { '0': 'BNB-XVS', '1': 'AQUAPAIR' },
  '26': { '0': 'BTCB-ETH', '1': 'AQUAPAIR' },
  '27': { '0': 'BUSD-USDC', '1': 'AQUAPAIR' },
  '28': { '0': 'ETH-USDC', '1': 'AQUAPAIR' },
  '29': { '0': 'BUSD-TUSD', '1': 'AQUAPAIR' },
  '30': { '0': 'BUSD-DAI', '1': 'AQUAPAIR' },
  '35': { '0': 'XRP-BNB', '1': 'AQUAPAIR' },
  '36': { '0': 'AQUA-BUSD', '1': 'AQUAPAIR' },
  '37': { '0': 'DOGE-BNB', '1': 'AQUAPAIR' },
  '38': { '0': 'ADA-BNB', '1': 'AQUAPAIR' },
  '39': { '0': 'TRX-BNB', '1': 'AQUAPAIR' },
  '40': { '0': 'BUSD-VAI', '1': 'AQUAPAIR' },
  '41': { '0': 'BUSD-UST', '1': 'AQUAPAIR' },
  '42': { '0': 'BUSD-USDT', '1': 'AQUAPAIR' },
  '43': { '0': 'beltBTCB', '1': 'SINGLEBELT' },
  '44': { '0': 'beltETH', '1': 'SINGLEBELT' },
  '46': { '0': 'beltBNB', '1': 'SINGLEBELT' },
  '47': { '0': '4BELT', '1': '4BELT' },
  '48': { '0': 'BNB-LTC', '1': 'AQUAPAIR' },
  '49': { '0': 'BUSD-ALPACA', '1': 'AQUAPAIR' },
  '50': { '0': 'BNB-UNI', '1': 'AQUAPAIR' },
  '51': { '0': 'BNB-BUNNY', '1': 'AQUAPAIR' },
  '52': { '0': 'BNB-XVS', '1': 'AQUAPAIR' },
  '53': { '0': 'UST-USDC', '1': 'AQUAPAIR' },
  '54': { '0': 'BTCB-BNB', '1': 'AQUAPAIR' },
  '55': { '0': 'ETH-BTCB', '1': 'AQUAPAIR' },
  '56': { '0': 'USDC-BUSD', '1': 'AQUAPAIR' },
}
const BUSD_BNBLPAddress = '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16'
const bnbAddress = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
const cakeTokenAddress = '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
const otherPlanetsPoolsId = [
  6, 7, 8, 12, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 26, 28, 35, 37, 38, 39, 43, 44, 46, 48, 49, 50, 51, 52, 54, 55,
]
const bluePlanetpoolsId = [0, 1, 2, 3, 4, 5, 13, 14, 27, 29, 30, 36, 40, 41, 42, 47, 53, 56]
export const oldPoolsId = [...Array(57).keys(), -1]
export const aquaPairPoolId = [0, 13, 14, 36, 53, 54, 55, 56]
export const greenOldPool = [1, 3]
const uranusPoolId = [19]
const newUranusPoolIds = [ 49, 62, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48]//37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48

let infinityVaultData: any = [];

let oldInfinityVaultData: any = [];
interface stakedDataArray {
  [key: string]: Number
}
var supplyBalanceArr: Record<string, number> = {}

var underLyingPriceDataArray: Record<any, any> = {}

var userSharesArray: stakedDataArray = {}

var userLPWalletArray: stakedDataArray = {}

var userLPWalletArrayNew: stakedDataArray = {}

var stakedTokenArr: stakedDataArray = {};

var userMarketWalletArray: stakedDataArray = {}

var userAllowanceArray: stakedDataArray = {}

var userRewardsArray: stakedDataArray = {}

var pendingUranusRewardsArray: stakedDataArray = {}

var balanceLPArray: stakedDataArray = {}

var balanceMarketArray: stakedDataArray = {}

var marketAllowanceArray: stakedDataArray = {}

var totalGammaStaked = 0

var totalAquaStaked = 0

interface pendingGammaArr {
  [key: number]: Number
}

var totalSupplyArray: stakedDataArray = {}

var pendingGammaArray: pendingGammaArr = {}

var pendingGammaProfits: pendingGammaArr = {}

export enum instType {
  'AQUAFARM' = 'AQUAFARM',
  'PANCAKELP' = 'PANCAKELP',
  'MASTERCHEF' = 'MASTERCHEF',
  'STRATEGY_ABI' = 'STRATEGY_ABI',
  'FACTORY' = 'FACTORY',
  'AQUA_AUTO_COMP' = 'AQUA_AUTO_COMP',
  'GAMMAFARM' = 'GAMMAFARM',
  'priceOracle' = 'priceOracle',
  'gammatroller' = 'gammatroller',
  'gToken' = 'gToken',
  'gBNB' = 'gBNB',
  'NEW_STRATEGY_ABI' = 'NEW_STRATEGY_ABI',
  'CLAIM_CAKE' = 'CLAIM_CAKE',
  'priceOracleOld' = 'priceOracleOld',
  'gammatrollerOld' = 'gammatrollerOld',
  'AQUAZAPINABI' = 'AQUAZAPINABI'
}

const getReserves = async (tokenAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.getReserves().call()
    return data
  } catch (e) {
    console.log(tokenAddress, e)
    return {
      0: 0,
      1: 0,
      2: 0,
      _reserve0: 0,
      _reserve1: 0,
      _blockTimestampLast: 0,
    }
  }
}

export const token1 = async (tokenAddress: string) => {
  try {
    if (tokenAddress.toLowerCase() === cakeTokenAddress.toLowerCase()) return zeroAddress
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.token0().call()
    return data
  } catch (e) {
    console.log(tokenAddress, e)
    return zeroAddress
  }
}

export const token2 = async (tokenAddress: string) => {
  try {
    if (tokenAddress.toLowerCase() === cakeTokenAddress.toLowerCase()) return zeroAddress
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.token1().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const totalSupply = async (tokenAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.totalSupply().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const getPair = async (factoryAddress: string, token1: string, token2: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.FACTORY, factoryAddress)
    const data = await tokenInstance.methods.getPair(token1, token2).call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const getBnbPrice = async () => {
  const reserves = await getReserves(BUSD_BNBLPAddress)
  const getBNBReserve = reserves._reserve0
  const getUSDReserve = reserves._reserve1
  const bnbPrice = getUSDReserve / getBNBReserve
  return bnbPrice
}

export const getTokenPriceOld = async (tokenAddress: any) => {
  if(tokenAddress == undefined) {
    return 0;
  }
  const bnbPrice = await getBnbPrice()

  if (tokenAddress === bnbAddress || tokenAddress === zeroAddress) {
    return await getBnbPrice()
  } else if (tokenAddress.toLowerCase() === gammaAddress.toLowerCase()) {
    return await getGammaPrice()
  } else if (tokenAddress.toLowerCase() === aquaAddress.toLowerCase()) {
    return await getAquaPrice()
  } else {
    const pairAddress: string = await getPair(pancakFactoryAddress, tokenAddress, bnbAddress)
    if (pairAddress !== zeroAddress) {
      const token0: string = await token1(pairAddress)
      const reserves = await getReserves(pairAddress)

      let getReserve0: any, getReserve1: any

      if (tokenAddress.toLowerCase() === token0.toLowerCase()) {
        getReserve0 = reserves['_reserve0']
        getReserve1 = reserves['_reserve1']
      } else {
        getReserve0 = reserves['_reserve1']
        getReserve1 = reserves['_reserve0']
      }
      const reservesRatio = getReserve0 / getReserve1
      const price: number = bnbPrice / reservesRatio
      return price
    }
    return 0
  }
}

var tokenPrices_GLOBAL: any = {};
var tokenPricesTime_GLOBAL:any = '';
export const getTokenPrice = async (tokenAddress: any) => {
  var nowtime = new Date().getTime();
  var timeDiff = nowtime - tokenPricesTime_GLOBAL;
  // console.log(tokenPrices_GLOBAL);
  // console.log('timeDiff', timeDiff);
  if(tokenPrices_GLOBAL != undefined && timeDiff < 5000 ) {
    if( tokenPrices_GLOBAL[tokenAddress.toLowerCase()] != undefined ) {
      // console.log('from cache');
      return tokenPrices_GLOBAL[tokenAddress.toLowerCase()]
    }
  }

  if (tokenAddress === bnbAddress || tokenAddress === zeroAddress) {
    const bnbPrice = await getBnbPrice()
    tokenPrices_GLOBAL[bnbAddress.toLowerCase()] = bnbPrice;
    tokenPricesTime_GLOBAL = new Date().getTime();
    return bnbPrice;
  } else if (tokenAddress.toLowerCase() === gammaAddress.toLowerCase()) {
    const gammaPrice = await getGammaPrice()
    tokenPrices_GLOBAL[gammaAddress.toLowerCase()] = gammaPrice;
    tokenPricesTime_GLOBAL = new Date().getTime();
    return gammaPrice
  } else if (tokenAddress.toLowerCase() === aquaAddress.toLowerCase()) {
    const aquaPrice = await getAquaPrice()
    tokenPrices_GLOBAL[aquaAddress.toLowerCase()] = aquaPrice;
    tokenPricesTime_GLOBAL = new Date().getTime();
    return aquaPrice
  } else {
    const bnbPrice = await getBnbPrice()
    let pairAddress: string = await getPair(pancakFactoryAddress, tokenAddress, bnbAddress)
    if(tokenAddress.toLowerCase() === bnbxAddress.toLowerCase()) {
      pairAddress = "0x6c83E45fE3Be4A9c12BB28cB5BA4cD210455fb55";
    }
    
    if (pairAddress !== zeroAddress) {
      const token0: string = await token1(pairAddress)
      const reserves = await getReserves(pairAddress)

      let getReserve0: any, getReserve1: any

      if (tokenAddress.toLowerCase() === token0.toLowerCase()) {
        getReserve0 = reserves['_reserve0']
        getReserve1 = reserves['_reserve1']
      } else {
        getReserve0 = reserves['_reserve1']
        getReserve1 = reserves['_reserve0']
      }
      const reservesRatio = getReserve0 / getReserve1
      const price: number = bnbPrice / reservesRatio

      // console.log('price from pancake'+tokenAddress, price);
      tokenPrices_GLOBAL[tokenAddress.toLowerCase()] = price;
      tokenPricesTime_GLOBAL = new Date().getTime();
      return price
    }
    return 0
  }
}

export const poolInfo = async (farmAddress: string, poolId: string) => {
  try {
    const farm_inst: any = await selectInstance(instType.AQUAFARM, farmAddress)
    const data = await farm_inst.methods.poolInfo(poolId).call()
    return data
  } catch (e) {
    console.log(e)
    return {
      0: zeroAddress,
      1: 0,
      2: 0,
      3: 0,
      4: zeroAddress,
      want: zeroAddress,
      allocPoint: 0,
      lastRewardBlock: 0,
      accAQUAPerShare: 0,
      strat: zeroAddress,
    }
  }
}

export const poolInfoNew = async (farmAddress: string, poolId: any) => {
  console.log("params", farmAddress, poolId)
  try {
    const farmInstance: any = await selectInstance(instType.GAMMAFARM, farmAddress)
    const data = await farmInstance.methods.poolInfo(poolId).call();
    // console.log("pool details", data)
    return data
  } catch (e) {
    console.log(e)
    return {
      0: zeroAddress,
      1: 0,
      2: 0,
      3: 0,
      4: zeroAddress,
      want: zeroAddress,
      allocPoint: 0,
      lastRewardBlock: 0,
      accAQUAPerShare: 0,
      strat: zeroAddress,
    }
  }
}

const poolLength = async (farmAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, farmAddress)
    const data = await tokenInstance.methods.poolLength().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const totalAllocPoint = async (farmAddress: string = gammaFarmAdddress) => {
  try {
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, farmAddress)
    const data = await tokenInstance.methods.totalAllocPoint().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const AQUAPerBlock = async () => {
  try {
    // const tokenInstance: any = await selectInstance(instType.AQUAFARM, aquaFarmAddress)
    // const data = await tokenInstance.methods.AQUAPerBlock().call()
    return 0
  } catch (e) {
    console.log(e)
    return 0
  }
}

const GAMMAPerBlock = async () => {
  try {
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbiOld, gammaFarmAdddressOld)
    // new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
    const data = await farm_inst.methods.GAMMAPerBlock().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const GAMMAPerBlockNew = async () => {
  try {
    let reservoir_inst = new wallet.web3.eth.Contract(reservoir_abi, gamma_reservoir);
    const data = await reservoir_inst.methods.farmV2DripRate().call()
    return data;
  } catch (e) {
    console.log(e)
    return 0
  }
}

const AQUAMaxSupply = async () => {
  try {
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, aquaFarmAddress)
    const data = await tokenInstance.methods.AQUAMaxSupply().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const pendingAQUA = async (poolId: string, userAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, aquaFarmAddress)
    const data = await tokenInstance.methods.pendingAQUA(poolId, userAddress).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const pendingGAMMA = async (poolId: string, userAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.GAMMAFARM, gammaFarmAdddress)
    const data = await tokenInstance.methods.pendingGAMMA(poolId, userAddress).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const stakedWantTokens = async (farmAddress: string, poolId: string, userAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, farmAddress)
    const data = await tokenInstance.methods.stakedWantTokens(poolId, userAddress).call()
    // console.log("stakedWantTokens", data);
    return data;
  } catch (e) {
    console.log(e)
    return 0
  }
}

const fetchOldPoolDetails = async (userAddress: any, pidArr: any, wantAddressArr: any, lpTokenPrice: any, gammaPrice: any, tradeFeeApy: any, farmApyArray: any, strategyAddressArr: any, tradeFeeAprArrayNew?: any, poolInterestPerYear?: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  
  let sharesArr: any = [];
  let allowanceArr: any = [];
  let lpBalanceArr: any = [];
  let totalSupplyArr: any = [];
  let balanceOfArr: any = [];
  let pendingRewardsArr: any = [];
  let pendingRewardsPoolLength: any = 0;
  let pendingUranusRewardsArr: any = [];
  let uranusRewardsPidArr: any = [];

  let targets: any = [];
  let callDatas: any = [];
  let results:any = [];
  let ouput_format : any = [];

  const farmInstance: any = new wallet.web3.eth.Contract(aquaFarmAbi, aquaFarmAddress);
  // const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, GAMMA_BNBLPAddress)
  const token_inst: any = new wallet.web3.eth.Contract(pancakeLPabi, aquaAddress)
  //old pool user shares
  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_")[1];
    let farmAddress = pid.split('_')[0];
    // console.log(poolId, farmAddress)
    try{
      targets.push(farmAddress);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(farmInstance.methods.stakedWantTokens(poolId, userAddress)._method,[poolId, userAddress]));
      callDatas.push(data);
      ouput_format.push(farmInstance.methods.stakedWantTokens(poolId, userAddress)._method.outputs)
    } catch(error){
      console.log(pid, error);
      return 0;
    }
  })

  //old pool allowance
  pidArr.forEach(async(pid: any, index: any) => {
    let farmAddress = pid.split('_')[0];
    try{
      let wantAddress = index > 1 ? wantAddressArr[pid] : wantAddressArr[pidArr[2]];
      const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
      targets.push(wantAddressArr[pid]);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.allowance(userAddress, farmAddress)._method,[userAddress, farmAddress]));
      callDatas.push(data);
      ouput_format.push(tokenInstance.methods.allowance(userAddress, farmAddress)._method.outputs)
    } catch(error){
      console.log(pid, error);
      return 0;
    }
  })

  //old pool lp balance
  pidArr.forEach(async(pid: any, index: any) => {
    try{
      let wantAddress = index > 1 ? wantAddressArr[pid] : wantAddressArr[pidArr[2]];
      const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
      // const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddressArr[pid])
      targets.push(wantAddressArr[pid]);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(userAddress)._method,[userAddress]));
      callDatas.push(data);
      ouput_format.push(tokenInstance.methods.balanceOf(userAddress)._method.outputs)
    } catch(error){
      console.log(pid, error);
      return 0;
    }
  })

  //total supply
  pidArr.forEach(async(pid: any, index: any) => {
    try{
      let wantAddress = index > 1 ? wantAddressArr[pid] : wantAddressArr[pidArr[2]];
      const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
      // const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddressArr[pid])
      targets.push(wantAddressArr[pid])
      const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.totalSupply()._method, [])
      callDatas.push(data)
      ouput_format.push(tokenInstance.methods.totalSupply()._method.outputs)
    } catch(error){
      console.log("error", error)
      return [];
    }
  })

  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_")[1];
    let farm_address = pid.split("_")[0];
    // let token_inst: any;
    let wantAddress: any;
    try{
      if(farm_address.toLowerCase() == aquaFarmAddress.toLowerCase() && +poolId == 13){
        
        targets.push(aquaAddress)
        wantAddress = wantAddressArr[pid];
      } else if(farm_address.toLowerCase() == gammaFarmAdddressOld.toLowerCase() && +poolId == 59){
        // token_inst = new wallet.web3.eth.Contract(pancakeLPabi, gammaAddress)
        targets.push(gammaAddress)
        wantAddress = userAddress;
      } else if(farm_address.toLowerCase() == gammaFarmAdddressOld.toLowerCase() && +poolId == 62){
        // token_inst = new wallet.web3.eth.Contract(pancakeLPabi, aquaAddress)
        targets.push(aquaAddress)
        wantAddress = userAddress;
      } else {
        // token_inst = new wallet.web3.eth.Contract(pancakeLPabi, gammaAddress)
        targets.push(gammaAddress)
        wantAddress = wantAddressArr[pid];
      }
      const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.balanceOf(wantAddress)._method, [wantAddress])
      callDatas.push(data)
      ouput_format.push(token_inst.methods.balanceOf(wantAddress)._method.outputs)
    } catch(error){
      console.log("error", error)
      return [];
    }
    /*
   
    */
  })

  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split('_')[1]
    let farmAddress = pid.split('_')[0]
    const farmInstance: any = new wallet.web3.eth.Contract(gammaFarmAbiOld, farmAddress)//new wallet.web3.eth.Contract
    poolId = farmAddress.toLowerCase() !== gammaFarmAdddressOld ? 1 : poolId;
    farmAddress = gammaFarmAdddressOld;
    
    if (farmAddress === gammaFarmAdddressOld) {
      // pendingRewardsPoolLength += 1;
      targets.push(farmAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(farmInstance.methods.pendingGAMMA(poolId, userAddress)._method, [
        poolId,
        userAddress,
      ])
      callDatas.push(data)
      ouput_format.push(farmInstance.methods.pendingGAMMA(poolId, userAddress)._method.outputs)
    }
  })

  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split('_')[1]
    let farmAdd = pid.split('_')[0];
    let strategyInstance = new wallet.web3.eth.Contract(STRATEGY_ABI, strategyAddressArr[pid])
    
    let strategyAddress = farmAdd.toLowerCase() !== gammaFarmAdddressOld.toLowerCase() || poolId < 37 || strategyAddressArr[pid].toLowerCase() == "0x96BD1E3B37551a50c1A89CcD920fB060f57aDC8b".toLowerCase() ? "0xb9a666F026d18e87A3321188d9b46D8CC87859a2" : strategyAddressArr[pid];
    poolId = farmAdd.toLowerCase() !== gammaFarmAdddressOld.toLowerCase() || poolId < 37 || poolId == 59 ? 37 : poolId;
    farmAdd = gammaFarmAdddressOld;
    // console.log("strat address", strategyAddress, poolId, farmAdd )
    if (newUranusPoolIds.includes(+poolId) && farmAdd.toLowerCase() === gammaFarmAdddressOld.toLowerCase()) {
      uranusRewardsPidArr.push(poolId)
      
      targets.push(strategyAddress)
      if(+poolId === 62){
        strategyInstance = new wallet.web3.eth.Contract(newBoostedVaultsAbi, strategyAddressArr[pid])
      }
      const data = wallet.web3.eth.abi.encodeFunctionCall(strategyInstance.methods.userPendingGammaProfit(userAddress)._method, [
        userAddress
      ])
      callDatas.push(data)
      ouput_format.push(strategyInstance.methods.userPendingGammaProfit(userAddress)._method.outputs)
    }
  })
  // console.log(targets,callDatas,targets.length,callDatas.length)
  const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());
  // console.log("aggregated_data", aggregated_data)

  const do_split = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }  

  for(let i = 0 ; i < aggregated_data[1].length ; i++){
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
  }
  const split_arr = (await do_split(results, pidArr.length));
  // console.log("old pools details",split_arr)
  sharesArr = split_arr[0];
  allowanceArr = split_arr[1];
  lpBalanceArr = split_arr[2];
  totalSupplyArr = split_arr[3];
  balanceOfArr = split_arr[4];
  pendingRewardsArr = split_arr[5];
  pendingUranusRewardsArr = split_arr[6];
  // console.log(pendingRewardsArr)
  // console.log(pendingUranusRewardsArr)
  // console.log(uranusRewardsPidArr, pendingUranusRewardsArray)
  // console.log("totalSupplyArr", totalSupplyArr)
  // console.log("lpBalanceArr", lpBalanceArr)
  // console.log("balanceOfArr", balanceOfArr)
  let totalBalanceBlueUSD: any = 0;
  let totalApy: any = 0;
  let totalShares: any = 0;
  let pendingEarningsUSD: any = 0;
  let blue_apy: any = 0;
  let balanceInBlue: any = 0;
  let uranusRewardsTotal: any = 0;
  let userLPArray: any = [];
  for (let i = 0; i < pidArr.length; i++) {
    let key = pidArr[i];
    let farmAddress = pidArr[i].split("_")[0];
    let pool_id = pidArr[i].split("_")[1];
    userSharesArray[pidArr[i]] = convertToEther(sharesArr[i][0]);
    // console.log("balance of", pool_id, farmAddress, lpBalanceArr[i][0], balanceOfArr[i][0])
    wantAddressArr[pidArr[i]] = wantAddressArr[pidArr[i]].toLowerCase();    
    totalSupplyArray[wantAddressArr[pidArr[i]].toLowerCase()] = gConvertToEther(totalSupplyArr[i][0], 18);
    
    let wantAddress = pool_id == 59 ? gammaAddress.toLowerCase() : pool_id == 62 ? aquaAddress.toLowerCase() : wantAddressArr[pidArr[i]] ;
    balanceLPArray[wantAddress] = gConvertToEther(balanceOfArr[i][0], 18)
    balanceMarketArray[wantAddress] = pool_id == 59 ? gConvertToEther(balanceOfArr[i][0], 18) : pool_id == 62 ? gConvertToEther(balanceOfArr[i][0], 18) : "0";
      // console.log("LP array for want address", wantAddress, balanceOfArr[i][0], gConvertToEther(balanceOfArr[i][0], 18));
    
    // balanceLPArray[tokenArray[i]] = gConvertToEther(split_arr[0][i][0], 18)
    
    userAllowanceArray[wantAddressArr[pidArr[i]] + '_' + farmAddress.toLowerCase()] = convertToEther(allowanceArr[i][0])
    if(pool_id == 62){
      userAllowanceArray['0xf18f4770ee76e6459b6b2cecc919ea5d3c58e8d9'] = convertToEther(allowanceArr[i][0]);
    }
    if(farmAddress.toLowerCase() === gammaFarmAdddressOld.toLowerCase()){
      // console.log("pending rewards data", i, pool_id, pendingRewardsArr[i])
      userRewardsArray[pidArr[i]] = convertToEther(pendingRewardsArr[i][0])
    } else {
      userRewardsArray[pidArr[i]] = 0;
    }
    
    if (farmAddress.toLowerCase() === gammaFarmAdddressOld.toLowerCase() && newUranusPoolIds.includes(+pool_id)) {
      // console.log("uranus rewards data", i, pool_id, pendingUranusRewardsArr[i][0])
      pendingUranusRewardsArray[pidArr[i]] = convertToEther(pendingUranusRewardsArr[i][0])
    } else {
      pendingUranusRewardsArray[pidArr[i]] = 0;
    }
    
    // userRewardsArray[newPoolIdArray[i]] = convertToEther(split_arr[0][i][0])
    let walletId = wantAddressArr[pidArr[i]] + "_" + farmAddress;
    walletId = walletId.toLowerCase();
    let poolId = key.split('_')[1];
    userLPWalletArray[walletId] = convertToEther(lpBalanceArr[i][0])
    userLPWalletArray[wantAddressArr[pidArr[i]].toLowerCase()] = convertToEther(lpBalanceArr[i][0])
    totalBalanceBlueUSD = poolId !== '62' ? +userSharesArray[pidArr[i]] * +lpTokenPrice[key]: 0;
    // console.log( "LP balance ", pool_id, totalBalanceBlueUSD);
    balanceInBlue += totalBalanceBlueUSD;
    userRewardsArray[key] = userRewardsArray[key] ? +userRewardsArray[key] : 0;
    pendingEarningsUSD += +userRewardsArray[key] * gammaPrice;
    
    if (newUranusPoolIds.includes(+poolId) && pendingUranusRewardsArray[key] !== undefined) {
      pendingEarningsUSD += +pendingUranusRewardsArray[key] * gammaPrice;
      uranusRewardsTotal += +pendingUranusRewardsArray[key] * gammaPrice;
    }
    farmApyArray[key] = farmApyArray[key] ? farmApyArray[key] : 0;
    if (wantAddressArr[key] !== undefined && tradeFeeAprArrayNew[key] !== undefined) {
      poolInterestPerYear += (totalBalanceBlueUSD * (farmApyArray[key] + tradeFeeAprArrayNew[key])) / 100;
    } else {
      poolInterestPerYear += (totalBalanceBlueUSD * farmApyArray[key]) / 100;
    }
    let lpAddressKey = wantAddressArr[key] + '_' + farmAddress;
    if (+userSharesArray[key] > 0) {
      // console.log("user wallet bal", userLPWalletArray[lpAddressKey.toLowerCase()], userSharesArray[key], poolId, farmAddress)
      userLPArray[lpAddressKey] = {};
      userLPArray[lpAddressKey].wantAddress = wantAddressArr[key];
      userLPArray[lpAddressKey].farmAddress = farmAddress;
      userLPArray[lpAddressKey].wantAddress_farmAddress = lpAddressKey;
      userLPArray[lpAddressKey].poolId = key;
      userLPArray[lpAddressKey].userRewards = userRewardsArray[key] ? +userRewardsArray[key] : 0;
      userLPArray[lpAddressKey].userRewardsUSD = userRewardsArray[key] && +userRewardsArray[key] * gammaPrice > 0.01 ? +userRewardsArray[key] * gammaPrice : 0;
      userLPArray[lpAddressKey].uranusRewardsUSD = pendingUranusRewardsArray[key] && +pendingUranusRewardsArray[key] * gammaPrice > 0.01 ? +pendingUranusRewardsArray[key] * gammaPrice : 0;
      userLPArray[lpAddressKey].uranusRewards = pendingUranusRewardsArray[key] ? +pendingUranusRewardsArray[key] : 0;
      userLPArray[lpAddressKey].LPTokens = poolId !== '62' ? +userSharesArray[key] : 0;
      userLPArray[lpAddressKey].LPTokenUSD = poolId !== '62' && +userSharesArray[key] * lpTokenPrice[key] > 0.01 ? +userSharesArray[key] * lpTokenPrice[key] : 0;
      userLPArray[lpAddressKey].allowance = userAllowanceArray[lpAddressKey.toLowerCase()] ? userAllowanceArray[lpAddressKey.toLowerCase()] : 0;
      userLPArray[lpAddressKey].isAllowanceApproved =
        userAllowanceArray[lpAddressKey.toLowerCase()] && +userAllowanceArray[lpAddressKey.toLowerCase()] > 0 ? true : false;
      userLPArray[lpAddressKey].walletBalance = userLPWalletArray[lpAddressKey.toLowerCase()];
      userLPArray[lpAddressKey].walletBalanceUSD = +userLPWalletArray[lpAddressKey.toLowerCase()] * lpTokenPrice[key] > 0.01 ? +userLPWalletArray[lpAddressKey.toLowerCase()] * lpTokenPrice[key] : 0;
      if(+pool_id === 49){
        // console.log(userLPArray[lpAddressKey])
        userLPArray['0x0709e1356F765a6F88632B539E209a5d6864B765'] = JSON.parse(JSON.stringify(userLPArray[lpAddressKey]));
      }
      if(+pool_id === 62){
        // console.log(userLPArray[lpAddressKey])
        // userLPArray['0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'] = JSON.parse(JSON.stringify(userLPArray[lpAddressKey]));
      }
    } else {
      // console.log("user wallet bal in else", userLPWalletArray[lpAddressKey.toLowerCase()], poolId, farmAddress)
      userLPArray[lpAddressKey] = {};
      userLPArray[lpAddressKey].wantAddress = wantAddressArr[key];
      userLPArray[lpAddressKey].farmAddress = farmAddress;
      userLPArray[lpAddressKey].wantAddress_farmAddress = lpAddressKey;
      userLPArray[lpAddressKey].poolId = poolId;
      userLPArray[lpAddressKey].userRewards = 0;
      userLPArray[lpAddressKey].userRewardsUSD = 0;
      userLPArray[lpAddressKey].uranusRewards = 0;
      userLPArray[lpAddressKey].uranusRewardsUSD = 0;
      userLPArray[lpAddressKey].LPTokens = 0;
      userLPArray[lpAddressKey].LPTokenUSD = 0;
      userLPArray[lpAddressKey].allowance = userAllowanceArray[lpAddressKey.toLowerCase()] ? userAllowanceArray[lpAddressKey.toLowerCase()]: 0;
      userLPArray[lpAddressKey].isAllowanceApproved =
        userAllowanceArray[lpAddressKey] && +userAllowanceArray[lpAddressKey] > 0 ? true : false;
      userLPArray[lpAddressKey].walletBalance = userLPWalletArray[lpAddressKey.toLowerCase()] ? userLPWalletArray[lpAddressKey.toLowerCase()] : 0;
      userLPArray[lpAddressKey].walletBalanceUSD = userLPWalletArray[lpAddressKey.toLowerCase()] && +userLPWalletArray[lpAddressKey.toLowerCase()] * lpTokenPrice[key] > 0.01 ? +userLPWalletArray[lpAddressKey.toLowerCase()] * lpTokenPrice[key] : 0;
    }
    if(+pool_id === 49){
      // console.log(userLPArray[lpAddressKey])
      userLPArray['0x0709e1356F765a6F88632B539E209a5d6864B765'] = JSON.parse(JSON.stringify(userLPArray[lpAddressKey]));
    }
    if(+pool_id === 62){
      // console.log(userLPArray[lpAddressKey])
      userLPArray['0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'] = JSON.parse(JSON.stringify(userLPArray[lpAddressKey]));
    }
  }
  
  return {userLPArray, balanceInBlue, blue_apy, poolInterestPerYear};
}

const fetchVaultDetails = async (userAddress: any, pidArr: any, wantAddressArr: any, lpTokenPrice: any, gammaPrice: any, tradeFeeApy: any, farmApyArray: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  
  let sharesArr: any = [];
  let allowanceArr: any = [];
  let lpBalanceArr: any = [];

  let targets: any = [];
  let callDatas: any = [];
  let results:any = [];
  let ouput_format : any = [];
  
  //old pool user shares
  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_")[1];
    let farmAddress = pid.split('_')[0];
    try{
      const tokenInstance: any = new wallet.web3.eth.Contract(aquaFarmAbi, farmAddress);
      targets.push(farmAddress);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.stakedWantTokens(poolId, userAddress)._method,[poolId, userAddress]));
      callDatas.push(data);
      ouput_format.push(tokenInstance.methods.stakedWantTokens(poolId, userAddress)._method.outputs)
    } catch(error){
      console.log(pid, error);
      return 0;
    }
  })

  //old pool allowance
  pidArr.forEach(async(pid: any) => {
    let farmAddress = pid.split('_')[0];
    try{
      targets.push(wantAddressArr[pid]);
      const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddressArr[pid])
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.allowance(userAddress, farmAddress)._method,[userAddress, farmAddress]));
      callDatas.push(data);
      ouput_format.push(tokenInstance.methods.allowance(userAddress, farmAddress)._method.outputs)
    } catch(error){
      console.log(pid, error);
      return 0;
    }
  })

  //old pool lp balance
  pidArr.forEach(async(pid: any) => {
    try{
      const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddressArr[pid])
      targets.push(wantAddressArr[pid]);
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(userAddress)._method,[userAddress]));
      callDatas.push(data);
      ouput_format.push(tokenInstance.methods.balanceOf(userAddress)._method.outputs)
    } catch(error){
      console.log(pid, error);
      return 0;
    }
  })

  const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());

  const do_split = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }  

  for(let i = 0 ; i < aggregated_data[1].length ; i++){
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
  }
  const split_arr = (await do_split(results, pidArr.length));
  console.log(split_arr)
  sharesArr = split_arr[0];
  allowanceArr = split_arr[1];
  lpBalanceArr = split_arr[2];
  
  let totalBalanceBlueUSD: any = 0;
  let totalApy: any = 0;
  let totalShares: any = 0;
  let pendingEarningsUSD: any = 0;
  let blue_apy: any = 0;
  let balanceInBlue: any = 0;
  let uranusRewardsTotal: any = 0;
  let userLPArray: any = [];
  for (let i = 0; i < pidArr.length; i++) {
    let key = pidArr[i];
    let farmAddress = pidArr[i].split("_")[0];
    let pool_id = pidArr[i].split("_")[1];
    userSharesArray[pidArr[i]] = convertToEther(sharesArr[i][0]);
    // console.log("user shares", pool_id, userSharesArray[pidArr[i]])
    wantAddressArr[pidArr[i]] = wantAddressArr[pidArr[i]].toLowerCase();
    userAllowanceArray[wantAddressArr[pidArr[i]] + '_' + farmAddress.toLowerCase()] = convertToEther(allowanceArr[i][0])
    if(pool_id == 62){
      userAllowanceArray['0xf18f4770ee76e6459b6b2cecc919ea5d3c58e8d9'] = convertToEther(allowanceArr[i][0]);
    }
    
    let walletId = wantAddressArr[pidArr[i]] + "_" + farmAddress;
    walletId = walletId.toLowerCase();
    userLPWalletArray[walletId] = convertToEther(lpBalanceArr[i][0])
    userLPWalletArray[wantAddressArr[pidArr[i]].toLowerCase()] = convertToEther(lpBalanceArr[i][0])
    totalBalanceBlueUSD = +userSharesArray[pidArr[i]] * +lpTokenPrice[key];
    // console.log( "LP balance ", pool_id, totalBalanceBlueUSD);
    balanceInBlue += totalBalanceBlueUSD;
    userRewardsArray[key] = userRewardsArray[key] ? +userRewardsArray[key] : 0;
    pendingEarningsUSD += +userRewardsArray[key] * gammaPrice;
    let poolId = key.split('_')[1];
    if (newUranusPoolIds.includes(+poolId) && pendingUranusRewardsArray[key] !== undefined) {
      pendingEarningsUSD += +pendingUranusRewardsArray[key] * gammaPrice;
      uranusRewardsTotal += +pendingUranusRewardsArray[key] * gammaPrice;
    }
    farmApyArray[key] = farmApyArray[key] ? farmApyArray[key] : 0;
    if (wantAddressArr[key] !== undefined && tradeFeeApy[key] !== undefined) {
      
      blue_apy += (totalBalanceBlueUSD * (farmApyArray[key] + tradeFeeApy[key])) / 100;
    } else {
      blue_apy += (totalBalanceBlueUSD * farmApyArray[key]) / 100;
    }
    let lpAddressKey = wantAddressArr[key] + '_' + farmAddress;
    if (+userSharesArray[key] > 0) {
      userLPArray[lpAddressKey] = {};
      userLPArray[lpAddressKey].wantAddress = wantAddressArr[key];
      userLPArray[lpAddressKey].farmAddress = farmAddress;
      userLPArray[lpAddressKey].wantAddress_farmAddress = lpAddressKey;
      userLPArray[lpAddressKey].poolId = key;
      userLPArray[lpAddressKey].userRewards = userRewardsArray[key] ? +userRewardsArray[key] : 0;
      userLPArray[lpAddressKey].userRewardsUSD = userRewardsArray[key] && +userRewardsArray[key] * gammaPrice > 0.01 ? +userRewardsArray[key] * gammaPrice : 0;
      userLPArray[lpAddressKey].uranusRewardsUSD = pendingUranusRewardsArray[key] && +pendingUranusRewardsArray[key] * gammaPrice > 0.01 ? +pendingUranusRewardsArray[key] * gammaPrice : 0;
      userLPArray[lpAddressKey].uranusRewards = pendingUranusRewardsArray[key] ? +pendingUranusRewardsArray[key] : 0;
      userLPArray[lpAddressKey].LPTokens = +userSharesArray[key];
      userLPArray[lpAddressKey].LPTokenUSD = +userSharesArray[key] * lpTokenPrice[key] > 0.01 ? +userSharesArray[key] * lpTokenPrice[key] : 0;
      userLPArray[lpAddressKey].allowance = userAllowanceArray[lpAddressKey.toLowerCase()] ? userAllowanceArray[lpAddressKey.toLowerCase()] : 0;
      userLPArray[lpAddressKey].isAllowanceApproved =
        userAllowanceArray[lpAddressKey.toLowerCase()] && +userAllowanceArray[lpAddressKey.toLowerCase()] > 0 ? true : false;
      userLPArray[lpAddressKey].walletBalance = userLPWalletArray[lpAddressKey.toLowerCase()];
      userLPArray[lpAddressKey].walletBalanceUSD = +userLPWalletArray[wantAddressArr[key]] * lpTokenPrice[key] > 0.01 ? +userLPWalletArray[wantAddressArr[key]] * lpTokenPrice[key] : 0;
      if(+pool_id === 49){
        console.log(userLPArray[lpAddressKey])
        userLPArray['0x0709e1356F765a6F88632B539E209a5d6864B765'] = JSON.parse(JSON.stringify(userLPArray[lpAddressKey]));
      }
      if(+pool_id === 62){
        console.log(userLPArray[lpAddressKey])
        userLPArray['0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'] = JSON.parse(JSON.stringify(userLPArray[lpAddressKey]));
      }
    } else {
      userLPArray[lpAddressKey] = {};
      userLPArray[lpAddressKey].wantAddress = wantAddressArr[poolId];
      userLPArray[lpAddressKey].farmAddress = farmAddress;
      userLPArray[lpAddressKey].wantAddress_farmAddress = lpAddressKey;
      userLPArray[lpAddressKey].poolId = poolId;
      userLPArray[lpAddressKey].userRewards = 0;
      userLPArray[lpAddressKey].userRewardsUSD = 0;
      userLPArray[lpAddressKey].uranusRewards = 0;
      userLPArray[lpAddressKey].uranusRewardsUSD = 0;
      userLPArray[lpAddressKey].LPTokens = 0;
      userLPArray[lpAddressKey].LPTokenUSD = 0;
      userLPArray[lpAddressKey].allowance = userAllowanceArray[lpAddressKey.toLowerCase()] ? userAllowanceArray[lpAddressKey.toLowerCase()]: 0;
      userLPArray[lpAddressKey].isAllowanceApproved =
        userAllowanceArray[lpAddressKey] && +userAllowanceArray[lpAddressKey] > 0 ? true : false;
      userLPArray[lpAddressKey].walletBalance = userLPWalletArray[wantAddressArr[poolId]] ? userLPWalletArray[wantAddressArr[poolId]] : 0;
      userLPArray[lpAddressKey].walletBalanceUSD = userLPWalletArray[wantAddressArr[poolId]] && +userLPWalletArray[wantAddressArr[poolId]] * lpTokenPrice[poolId] > 0.01 ? +userLPWalletArray[wantAddressArr[poolId]] * lpTokenPrice[poolId] : 0;
    }
    if(+pool_id === 49){
      console.log(userLPArray[lpAddressKey])
      userLPArray['0x0709e1356F765a6F88632B539E209a5d6864B765'] = JSON.parse(JSON.stringify(userLPArray[lpAddressKey]));
    }
    if(+pool_id === 62){
      console.log(userLPArray[lpAddressKey])
      userLPArray['0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'] = JSON.parse(JSON.stringify(userLPArray[lpAddressKey]));
    }
  }
  
  return {userLPArray, balanceInBlue, blue_apy};
}

export const newLPDataMulticall = async (userAddress: any, pidArr?: any, wantAddressArr?: any, gammaPrice?: any, lpTokenPriceArr?: any, totalBalanceBlueUSD?: any, blue_apy?: any, farmApyArray?: any, tradeFeeApy?: any, aquaPrice?: any, stratAddressArr?:any, poolNameArr?: any, poolInterestPerYear?: any, gammaAprArray?: any, tradeFeeApr?: any, parentFarmAprArray?: any) => {  
  let portfolio_response = true;
  // console.log(" lp token price array" , lpTokenPriceArr)
  if(wantAddressArr == undefined) {
    let poolIdArr: any = [];
    let farmApyArr: any = [];
    let lpTokenPriceArray: any = [];
    let wantAddressArray: any = [];
    let tradeFeeApyArray: any = [];
    let poolNameArray: any = [];
    let pool_interest_per_year: any = 0;
    let gamma_apr_array: any = [];
    let tradefee_apr_array: any = [];
    let parent_farm_apr_array: any = [];
    portfolio_response = false;
    let newPoolData: any = {};
    try {
      if(Object.keys(planetGlobalObject.poolsInfoV2).length > 0) {
        newPoolData = planetGlobalObject.poolsInfoV2;
      }
      else {
        newPoolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfov2')).data;
      }
    } catch (error) {
      try {
        newPoolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfov2')).data;
      } catch (error) {
        console.log('error in fetching new pool data', error);
      }
    }
    let stratAddressArray: any = [];
    let allocPointArray: any = [];
    for (var key in newPoolData) {
      let key_arr = key.split('_');
      let poolId = newPoolData[key].poolId;
      // console.log("poolId details",poolId, newPoolData[key].strategyAddress, newPoolData[key].farmApyPerYear)
      poolIdArr.push(key_arr[1] + '_' + poolId); //farmAddress_poolId
      stratAddressArray[key_arr[1] + '_' + poolId] = newPoolData[key].strategyAddress;
      farmApyArr[key_arr[1] + '_' + poolId] = newPoolData[key].farmApyPerYear ? newPoolData[key].farmApyPerYear : 0; // gammaApy = farmApyPerYear
      lpTokenPriceArray[key_arr[1] + '_' + poolId] = newPoolData[key].price ? newPoolData[key].price : 0;
      wantAddressArray[key_arr[1] + '_' + poolId] = newPoolData[key].wantAddress.toLowerCase();
      allocPointArray[key_arr[1] + '_' + poolId] = newPoolData[key].poolAllocPoint;
      tradeFeeApyArray[key_arr[1] + '_' + poolId] = newPoolData[key].tradeFeeApy ? newPoolData[key].tradeFeeApy : 0;
      poolNameArray[key_arr[1] + '_' + poolId] = newPoolData[key].name ? newPoolData[key].name : "";
      gamma_apr_array[key_arr[1] + '_' + poolId] = newPoolData[key].gammaApr ? newPoolData[key].gammaApr : 0;
      tradefee_apr_array[key_arr[1] + '_' + poolId] = newPoolData[key].tradeFeeApr ? newPoolData[key].tradeFeeApr : 0;
      parent_farm_apr_array[key_arr[1] + '_' + poolId] = newPoolData[key].parentFarmApr ? newPoolData[key].parentFarmApr : 0;
    }
    totalBalanceBlueUSD = 0;
    blue_apy = 0;
    let priceArray = await returnTokenPriceMulticall();
    aquaPrice = priceArray[aquaAddress.toLowerCase()] ? priceArray[aquaAddress.toLowerCase()] : await getAquaPrice();
    gammaPrice = priceArray[gammaAddress.toLowerCase()] ? priceArray[gammaAddress.toLowerCase()] : await getGammaPrice();
    pidArr = poolIdArr;
    wantAddressArr = wantAddressArray;
    tradeFeeApy = tradeFeeApyArray;
    farmApyArray = farmApyArr;
    lpTokenPriceArr = lpTokenPriceArray;
    stratAddressArr = stratAddressArray;
    poolNameArr = poolNameArray;
    gammaAprArray = gamma_apr_array;
    tradeFeeApr = tradefee_apr_array;
    poolInterestPerYear = pool_interest_per_year;
    parentFarmAprArray = parent_farm_apr_array
  }
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress);
  let sharesArr: any = [];
  let poolInfoArr: any = [];
  let infinityBalanceArr: any = [];
  let gTokenExchangeRateArr: any = [];
  let userInfoArr: any = [];
  let allowanceArr: any = [];
  let lpBalanceArr: any = [];
  let balanceInLPArr: any = [];
  let lpAllowance: any = [];
  let totalSupplyArr: any = [];

  let targets: any = [];
  let callDatas: any = [];
  let results:any = [];
  let ouput_format : any = [];

  let totalAllocPoint: any = await farm_inst.methods.totalAllocPoint().call();
  let isDrip: any = await farm_inst.methods.isDrip().call()
  let reservoir_inst = new wallet.web3.eth.Contract(reservoir_abi, gamma_reservoir);
  const farmV2DripRate = await reservoir_inst.methods.farmV2DripRate().call()
  
  //shares
  pidArr.forEach(async(pid: any, index: any) => {
    let poolId = pid.split("_");
    const strat_inst: any = poolId[1] == 0 ? new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new) : poolId[1] == 1 ? new wallet.web3.eth.Contract( aqua_strategy_abi, aqua_strategy_address_new) : new wallet.web3.eth.Contract(normal_strategy_abi_new, normal_strategy_address_new);
    const strategyAddress: any = poolId[1] == 0 ? gamma_strategy_address_new : poolId[1] == 1 ?aqua_strategy_address_new :  stratAddressArr[pid];
    // console.log(strategyAddress, poolId)
    targets.push(strategyAddress);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(strat_inst.methods.getShares()._method,[]));
      callDatas.push(data);
      ouput_format.push(strat_inst.methods.getShares()._method.outputs)
    } catch(error){
      console.log(error);
      return [0, 0];
    }
  })

  //pool info
  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_");
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, poolId[0]);
    targets.push(poolId[0]);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.poolInfo(poolId[1])._method,[poolId[1]]));
      callDatas.push(data);
      ouput_format.push(farm_inst.methods.poolInfo(poolId[1])._method.outputs)
    } catch(error){
      console.log(error);
      return 0;
    }
  })

  //balance of
  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_");
    const iToken_inst: any = poolId[1] == 0 ? new wallet.web3.eth.Contract(gamma_infinity_vault_abi, gamma_infinity_vault_address) : new wallet.web3.eth.Contract(aqua_infinity_vault_abi, aqua_infinity_vault_address);
    const infinityVault: any = poolId[1] == 0 ? gamma_infinity_vault_address : aqua_infinity_vault_address;
    targets.push(infinityVault);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(iToken_inst.methods.balanceOf(userAddress)._method,[userAddress]));
      callDatas.push(data);
      ouput_format.push(iToken_inst.methods.balanceOf(userAddress)._method.outputs)
    } catch(error){
      console.log(error);
      return 0;
    }
  })
  
  // exchange rate
  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_");
    const gToken_inst: any = poolId[1] == 0 ? new wallet.web3.eth.Contract(gTokenAbi, gGamma) : new wallet.web3.eth.Contract(gTokenAbi, gAqua);
    
    const gToken: any = poolId[1] == 0 ? gGamma : gAqua;
    targets.push(gToken);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.exchangeRateStored()._method,[]));
      callDatas.push(data);
      ouput_format.push(gToken_inst.methods.exchangeRateStored()._method.outputs)
    } catch(error){
      console.log(error);
      return 0;
    }
  })

  // user info
  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_");
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, poolId[0]);
    targets.push(poolId[0]);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.userInfo(poolId[1], userAddress)._method,[poolId[1], userAddress]));
      callDatas.push(data);
      ouput_format.push(farm_inst.methods.userInfo(poolId[1], userAddress)._method.outputs)
    } catch(error){
      console.log(error);
      return 0;
    }
  })

  //allowance
  pidArr.forEach(async(pid: any, index: any) => {
    let farmAddress = pid.split('_')[0];
    let wantAddress = index > 1 ? wantAddressArr[pid] : wantAddressArr[pidArr[2]];
    // console.log(wantAddress, index);
    targets.push(wantAddress)
    const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
    const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.allowance(userAddress, farmAddress)._method, [
      userAddress,
      farmAddress,
    ])
    callDatas.push(data);
    ouput_format.push(tokenInstance.methods.allowance(userAddress, farmAddress)._method.outputs)
  });

  //lp balance
  pidArr.forEach(async(pid:any, index: any) => {
    let wantAddress = index > 1 ? wantAddressArr[pid] : wantAddressArr[pidArr[2]];
    const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
    targets.push(wantAddress)
    const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(userAddress)._method, [userAddress])
    callDatas.push(data)
    ouput_format.push(tokenInstance.methods.balanceOf(userAddress)._method.outputs)
  })

  pidArr.forEach(async(pid:any, index: any) => {
    let tokenInstance: any;
    if (wantAddressArr[pid].toLowerCase() === AQUA_BNBLPAddress.toLowerCase()) {
      tokenInstance =new wallet.web3.eth.Contract(pancakeLPabi, aquaAddress)
      targets.push(aquaAddress)
    } else {
      tokenInstance = new wallet.web3.eth.Contract(pancakeLPabi, gammaAddress)
      targets.push(gammaAddress)
    }
    const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(wantAddressArr[pid])._method, [wantAddressArr[pid]])
    callDatas.push(data)
    ouput_format.push(tokenInstance.methods.balanceOf(wantAddressArr[pid])._method.outputs)
  })
  
  //zap allowance for lp
  pidArr.forEach(async(pid:any, index: any) => {
    const LP_INSTANCE = new wallet.web3.eth.Contract(PLANETLPABI, wantAddressArr[pid])
    targets.push(wantAddressArr[pid])
    
    const data = wallet.web3.eth.abi.encodeFunctionCall(LP_INSTANCE.methods.allowance(userAddress, aquaConvertLpAddress)._method, [userAddress, aquaConvertLpAddress])
    callDatas.push(data)
    ouput_format.push(LP_INSTANCE.methods.allowance(userAddress, aquaConvertLpAddress)._method.outputs)
  })

  pidArr.forEach(async(pid: any, index: any) => {
    try{
      let wantAddress = index > 1 ? wantAddressArr[pid] : wantAddressArr[pidArr[2]];
      const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
      // const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddressArr[pid])
      targets.push(wantAddressArr[pid])
      const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.totalSupply()._method, [])
      callDatas.push(data)
      ouput_format.push(tokenInstance.methods.totalSupply()._method.outputs)
    } catch(error){
      console.log("error", error)
      return [];
    }
  })

  // console.log(targets.length, callDatas.length, wantAddressArr)
  const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());

  const do_split = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }  

  for(let i = 0 ; i < aggregated_data[1].length ; i++){
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i],aggregated_data[1][i]))
  }
  const split_arr = (await do_split(results, pidArr.length));
  // console.log("new pool multicall split data", split_arr);
  sharesArr = split_arr[0];
  // console.log("shares array", sharesArr)
  poolInfoArr = split_arr[1];
  infinityBalanceArr = split_arr[2];
  gTokenExchangeRateArr = split_arr[3];
  userInfoArr = split_arr[4];
  allowanceArr = split_arr[5];
  lpBalanceArr = split_arr[6];
  balanceInLPArr = split_arr[7];
  lpAllowance = split_arr[8];
  totalSupplyArr = split_arr[9];
  // console.log("totalSupplyArr", totalSupplyArr)
  let boostArray: any = [];
  // let userLPWalletArray: any = [];
  let userSharesArray: any = []

  let userLPArray: any = [];
  let balanceInBlue: any = 0;
  let userAllowanceArr: any = userAllowanceArray ? userAllowanceArray : [];
  let getPoolsAndVaultsDataForPF: any = [];
  try {
    if(Object.keys(planetGlobalObject.getallpoolinfo).length > 0) {
      getPoolsAndVaultsDataForPF = planetGlobalObject.getallpoolinfo;
    }
    else {
      getPoolsAndVaultsDataForPF = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
    }
  } catch (error) {
    try {
      getPoolsAndVaultsDataForPF = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
    } catch (error) {
      console.log('error in fetching getTotalSupplyApy', error);
    }
  }
  let avg_boost_apr_array: any = [];
  // let getKeys = Object.keys(getPoolsAndVaultsDataForPF);
  getPoolsAndVaultsDataForPF.newPools.active.forEach((element: any, key: any) => {
    let new_key = element.farmContractAddress + "_" + element.poolId ;
    avg_boost_apr_array[new_key] = element.averageGammaBoostApr;
  });

  for (let i = 0; i < pidArr.length; i++) {
    let poolId = pidArr[i];
    let sharesTotal = sharesArr[i][1];
    let wantLockedTotal = sharesArr[i][0];
    let userBoostAPR = 0;
    let averageBoostAPR = 0
    if(!poolInfoArr[i].isBoosted){
      boostArray[pidArr[i]] = {averageBoostApy: 0, userBoostApy: 0};
    }
    let blocksPerYear = 10512000;
    if(!isDrip){
      blocksPerYear = 0;
    }
    let gammaBoostPercentage = poolInfoArr[i].gammaRewardBoostPercentage ? +poolInfoArr[i].gammaRewardBoostPercentage/10000 : 0;
    
    if(totalAllocPoint > 0){
      let gammaBoostReward = (gammaBoostPercentage * blocksPerYear * farmV2DripRate/ 1e18 * poolInfoArr[i].allocPoint)/totalAllocPoint;

      // console.log("gamma boost reward", poolId, gammaBoostReward, gammaBoostPercentage, blocksPerYear, farmV2DripRate, poolInfoArr[i].allocPoint, totalAllocPoint)

      averageBoostAPR = +wantLockedTotal > 0 ? (100 * gammaBoostReward * 1e18 * gammaPrice )/(wantLockedTotal * lpTokenPriceArr[pidArr[i]]) : 0;
      
      // console.log("avg boost apr", poolId, averageBoostAPR, gammaBoostReward, gammaPrice, wantLockedTotal, lpTokenPriceArr[pidArr[i]], userInfoArr[i].factor, poolInfoArr[i].totalFactor, userInfoArr[i].shares, sharesTotal )
      // console.log("lp balance", poolId, (+userInfoArr[i].shares * +sharesArr[i][0] * lpTokenPriceArr[pidArr[i]]) / (+sharesArr[i][1] * 1e18))
      if(userAddress !== undefined) {
        userBoostAPR = +sharesTotal > 0 && +wantLockedTotal > 0 && +userInfoArr[i].shares > 0 &&( (+userInfoArr[i].shares * +sharesArr[i][0] * lpTokenPriceArr[pidArr[i]]) / (+sharesArr[i][1] * 1e18) > 1 || userAddress == undefined) ? (100 * gammaBoostReward * +gammaPrice * 1e18 * (userInfoArr[i].factor/poolInfoArr[i].totalFactor))/(userInfoArr[i].shares * wantLockedTotal * lpTokenPriceArr[pidArr[i]] / sharesTotal) : 0;
      }
      
      let userBoostApy = userBoostAPR < 300 ? (Math.pow((1 + (userBoostAPR)/36500), 365) - 1) * 100 : userBoostAPR;
      let averageBoostApy = averageBoostAPR < 300 ? (Math.pow((1 + (averageBoostAPR)/36500), 365) - 1) * 100 : averageBoostAPR;
      // console.log("avg boost apy : ", averageBoostApy, averageBoostAPR, "user boost apy : ", userBoostApy, userBoostAPR, poolId)
      boostArray[pidArr[i]] = {userBoostApy, averageBoostApy, gammaBoostPercent: gammaBoostPercentage * 100};
    } else {
      boostArray[pidArr[i]] = {averageBoostApy: 0, userBoostApy: 0, gammaBoostPercent: 0};
    }
    if(sharesArr[i][1] == 0){
      stakedTokenArr[pidArr[i]] = 0;
    }
    if(poolInfoArr[i].isInfinity){
      
      stakedTokenArr[pidArr[i]] = (+infinityBalanceArr[i][0] * +sharesArr[i][0] * +gTokenExchangeRateArr[i][0]) / (+sharesArr[i][1] * 1e36)
    } else {
      stakedTokenArr[pidArr[i]] = (+userInfoArr[i].shares * +sharesArr[i][0]) / (+sharesArr[i][1] * 1e18)
    }

    totalSupplyArray[wantAddressArr[pidArr[i]].toLowerCase()] = gConvertToEther(totalSupplyArr[i][0], 18);
    userSharesArray[poolId] = stakedTokenArr[poolId]
    let farmAddress = poolId.split('_')[0].toLowerCase();
    let pool_id = poolId.split('_')[1];
    wantAddressArr[poolId] = wantAddressArr[poolId].toLowerCase();
    // balanceLPArray[tokenArray[i]] = gConvertToEther(split_arr[0][i][0], 18)
    balanceLPArray[wantAddressArr[poolId]] = gConvertToEther(balanceInLPArr[i][0], 18)
    userAllowanceArray[wantAddressArr[pidArr[i]] + '_' + farmAddress] = convertToEther(allowanceArr[i][0])
    let walletId = wantAddressArr[pidArr[i]] + "_" + farmAddress;
    walletId = walletId.toLowerCase();
    userLPWalletArray[walletId] = convertToEtherRevised(lpBalanceArr[i][0], 1e18);//convertToEther(lpBalanceArr[i][0])
    // balanceLPArray[wantAddressArr[poolId]] = convertToEther(lpBalanceArr[i][0]);
    userLPWalletArrayNew[wantAddressArr[pidArr[i]].toLowerCase()] = convertToEtherRevised(lpBalanceArr[i][0], 1e18);//convertToEther(lpBalanceArr[i][0])
    //new user pool array data
    totalBalanceBlueUSD = userSharesArray[poolId] == userSharesArray[poolId] ? +userSharesArray[poolId] * +lpTokenPriceArr[poolId] : 0;

    balanceInBlue += totalBalanceBlueUSD;
    let boostApy = userSharesArray[poolId] == userSharesArray[poolId] && +userSharesArray[poolId] * lpTokenPriceArr[poolId] > 0.01 && boostArray[poolId].userBoostApy ? boostArray[poolId].userBoostApy : boostArray[poolId].averageBoostApy ? boostArray[poolId].averageBoostApy : 0 ;
    // console.log("avg boost apy", boostArray, boostArray[poolId].averageBoostApy, "user boost apy", boostArray[poolId].userBoostApy)
    if(i< 2){
      continue;
    }
    if (wantAddressArr[poolId] !== undefined && tradeFeeApy[poolId] !== undefined) {
      blue_apy += (totalBalanceBlueUSD * (farmApyArray[poolId] + tradeFeeApy[poolId] + boostApy)) / 100;
      // console.log("apy in new  pools if", poolId, boostApy, farmApyArray[poolId], tradeFeeApy[poolId], blue_apy)
    } else {
      blue_apy += (totalBalanceBlueUSD * (farmApyArray[poolId] + boostApy)) / 100;
      // console.log("apy in new pools else", poolId, farmApyArray[poolId], boostApy, blue_apy)
    }
    let boostApr = userSharesArray[poolId] == userSharesArray[poolId] && +userSharesArray[poolId] * lpTokenPriceArr[poolId] > 0.01 && userBoostAPR ? userBoostAPR : avg_boost_apr_array[poolId] ? avg_boost_apr_array[poolId] : 0;
    
    let parentFarmApr = parentFarmAprArray[poolId] ? parentFarmAprArray[poolId] : 0;

    if (wantAddressArr[poolId] !== undefined && tradeFeeApr[poolId] !== undefined) {
      
      poolInterestPerYear += (totalBalanceBlueUSD * (gammaAprArray[poolId] + tradeFeeApr[poolId] + boostApr + parentFarmApr)) / 100;
      // console.log("poolInterestPerYear", poolInterestPerYear, poolId, totalBalanceBlueUSD, boostApr, gammaAprArray[poolId], tradeFeeApr[poolId])
    } else {
      poolInterestPerYear += (totalBalanceBlueUSD * (gammaAprArray[poolId] + boostApr + parentFarmApr)) / 100;
      // console.log("apy in new pools else", poolInterestPerYear, poolId, totalBalanceBlueUSD, gammaAprArray[poolId], boostApr)
    }
    if (wantAddressArr[poolId] !== undefined) {
      let farmAddress = poolId.split('_')[0];
      let pid = poolId.split('_')[1];
      lpTokenPriceArr[poolId] = pid == 0 ? gammaPrice : pid == 1 ? aquaPrice : lpTokenPriceArr[poolId];
      let lpAddressKey = wantAddressArr[poolId] + '_' + farmAddress;
      if (userLPArray[lpAddressKey] === undefined) {
        let key = poolId;
        userLPArray[lpAddressKey] = {};
        userLPArray[lpAddressKey].wantAddress = wantAddressArr[poolId];
        userLPArray[lpAddressKey].farmAddress = farmAddress;
        userLPArray[lpAddressKey].wantAddress_farmAddress = lpAddressKey;
        userLPArray[lpAddressKey].poolId = key;
        userLPArray[lpAddressKey].userRewards = 0;//userRewardsArray[key] ? +userRewardsArray[key] : 0;
        userLPArray[lpAddressKey].userRewardsUSD = 0;//userRewardsArray[key] && +userRewardsArray[key] * gammaPrice > 0.01 ? +userRewardsArray[key] * gammaPrice : 0;
        userLPArray[lpAddressKey].uranusRewardsUSD = 0;//pendingUranusRewardsArray[key] && +pendingUranusRewardsArray[key] * gammaPrice > 0.01 ? +pendingUranusRewardsArray[key] * gammaPrice : 0;
        userLPArray[lpAddressKey].uranusRewards = 0;//pendingUranusRewardsArray[key] ? +pendingUranusRewardsArray[key] : 0;
        userLPArray[lpAddressKey].LPTokens = userSharesArray[key] == userSharesArray[key] ? +userSharesArray[key] : 0;
        userLPArray[lpAddressKey].LPTokenUSD = +userLPArray[lpAddressKey].LPTokens * lpTokenPriceArr[key] > 0.01 ? +userSharesArray[key] * lpTokenPriceArr[key] : 0;
        userLPArray[lpAddressKey].allowance = userAllowanceArray[lpAddressKey.toLowerCase()] ? userAllowanceArray[lpAddressKey.toLowerCase()] : 0;
        userLPArray[lpAddressKey].isAllowanceApproved =
          userAllowanceArray[lpAddressKey.toLowerCase()] && +userAllowanceArray[lpAddressKey.toLowerCase()] > 0 ? true : false;
        userLPArray[lpAddressKey].walletBalance = userLPWalletArray[lpAddressKey.toLowerCase()];
        // console.log("userLPWalletArray[lpAddressKeys]", lpAddressKey, userLPWalletArray[lpAddressKey.toLowerCase()]);
        // console.log("wallet balance of LP", +userLPWalletArray[lpAddressKey.toLowerCase()] * +lpTokenPriceArr[key], userLPWalletArray[lpAddressKey.toLowerCase()] , +lpTokenPriceArr[key])
        userLPArray[lpAddressKey].walletBalanceUSD = +userLPWalletArray[lpAddressKey.toLowerCase()] * +lpTokenPriceArr[key] > 0.01 ? +userLPWalletArray[lpAddressKey.toLowerCase()] * +lpTokenPriceArr[key] : 0;
        userLPArray[lpAddressKey].userBoostApy = boostArray[poolId].userBoostApy ? boostArray[poolId].userBoostApy : 0;
        userLPArray[lpAddressKey].averageBoostApy = boostArray[poolId].averageBoostApy ? boostArray[poolId].averageBoostApy : 0;
        userLPArray[lpAddressKey].gammaBoostPercent = boostArray[poolId].gammaBoostPercent ? boostArray[poolId].gammaBoostPercent : 0;
        userLPArray[lpAddressKey].avgBoostAPR = averageBoostAPR;
        userLPArray[lpAddressKey].userBoostAPR = userBoostAPR;
        userLPArray[lpAddressKey].lpAllowance = convertToEther(lpAllowance[i][0]);
        userLPArray[lpAddressKey].poolName = poolNameArr[poolId];
        userLPArray[lpAddressKey].lp_price = lpTokenPriceArr[key];
      }
    }
  }
  if(portfolio_response){
    return {userLPArray, balanceInBlue, blue_apy, poolInterestPerYear};
  } else {
    let poolData: any = {};
    try {
      if(Object.keys(planetGlobalObject.poolsInfo).length > 0) {
        poolData = planetGlobalObject.poolsInfo;
      }
      else {
        poolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfo')).data;
      }
      
    } catch (error) {
      try {
        poolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfo')).data;
      } catch (error) {
        console.log('error in fetching poolData', error);
      }
    }
    let poolIdArray: any = [];
    let stratAddressArray: any = [];
    let farmApyArray: any = [];
    let lpTokenPrice: any = [];
    let wantAddressArray: any = [];
    // let userLPArray: any = [];
    let allocPointArray: any = [];
    let tradeFeeApy: any = [];
    let tradeFeeApr: any = [];
    let poolRewards: any = 0;
    for (var key in poolData) {
      // console.log(poolData[key])
      let key_arr = key.split('_');
      let poolId = poolData[key].poolId;
      // console.log("poolId details",poolId, poolData[key].strategyAddress, poolData[key].farmApyPerYear)
      
      poolIdArray.push(key_arr[1] + '_' + poolId); //farmAddress_poolId
      stratAddressArray[key_arr[1] + '_' + poolId] = poolData[key].strategyAddress;
      farmApyArray[key_arr[1] + '_' + poolId] = poolData[key].farmApyPerYear ? poolData[key].farmApyPerYear : 0; // gammaApy = farmApyPerYear
      lpTokenPrice[key_arr[1] + '_' + poolId] = poolData[key].price ? poolData[key].price : 0;
      wantAddressArray[key_arr[1] + '_' + poolId] = poolData[key].wantAddress.toLowerCase();
      allocPointArray[key_arr[1] + '_' + poolId] = poolData[key].poolAllocPoint;
      tradeFeeApy[key_arr[1] + '_' + poolId] = poolData[key].tradeFeeApy ? poolData[key].tradeFeeApy : 0;
      tradeFeeApr[key_arr[1] + '_' + poolId] = poolData[key].tradeFeeApr ? poolData[key].tradeFeeApr : 0;
    }
    const oldPoolData = await fetchOldPoolDetails(userAddress, poolIdArray, wantAddressArray, lpTokenPrice, gammaPrice, tradeFeeApy, farmApyArray, stratAddressArray, tradeFeeApr, poolRewards)
    let newPoolData = userLPArray;
    return {newPoolData, oldPoolData};
  }
  
}

export const v3_LPDataMulticall = async (userAddress: any, pidArr?: any, wantAddressArr?: any, gammaPrice?: any, lpTokenPriceArr?: any, totalBalanceBlueUSD?: any, blue_apy?: any, farmApyArray?: any, tradeFeeApy?: any, aquaPrice?: any, stratAddressArr?:any, poolNameArr?: any, poolInterestPerYear?: any, gammaAprArray?: any, tradeFeeApr?: any, parentFarmAprArray?: any) => {  
  let portfolio_response = true;
  // console.log(" lp token price array" , lpTokenPriceArr)
  if(wantAddressArr == undefined) {
    let poolIdArr: any = [];
    let farmApyArr: any = [];
    let lpTokenPriceArray: any = [];
    let wantAddressArray: any = [];
    let tradeFeeApyArray: any = [];
    let poolNameArray: any = [];
    let pool_interest_per_year: any = 0;
    let gamma_apr_array: any = [];
    let tradefee_apr_array: any = [];
    let parent_farm_apr_array: any = [];

    portfolio_response = false;
    let newPoolData: any = {};
    try {
      if(Object.keys(planetGlobalObject.poolsInfoV3).length > 0) {
        newPoolData = planetGlobalObject.poolsInfoV3;
      }
      else {
        newPoolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfov3')).data;
      }
    } catch (error) {
      try {
        newPoolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfov3')).data;
      } catch (error) {
        console.log('error in fetching new pool data', error);
      }
    }
    let stratAddressArray: any = [];
    let allocPointArray: any = [];
    for (var key in newPoolData) {
      let key_arr = key.split('_');
      let poolId = newPoolData[key].poolId;
      // console.log("poolId details",poolId, newPoolData[key].strategyAddress, newPoolData[key].farmApyPerYear)
      poolIdArr.push(key_arr[1] + '_' + poolId); //farmAddress_poolId
      stratAddressArray[key_arr[1] + '_' + poolId] = newPoolData[key].strategyAddress;
      farmApyArr[key_arr[1] + '_' + poolId] = newPoolData[key].farmApyPerYear ? newPoolData[key].farmApyPerYear : 0; // gammaApy = farmApyPerYear
      lpTokenPriceArray[key_arr[1] + '_' + poolId] = newPoolData[key].price ? newPoolData[key].price : 0;
      wantAddressArray[key_arr[1] + '_' + poolId] = newPoolData[key].wantAddress.toLowerCase();
      allocPointArray[key_arr[1] + '_' + poolId] = newPoolData[key].poolAllocPoint;
      tradeFeeApyArray[key_arr[1] + '_' + poolId] = newPoolData[key].tradeFeeApy ? newPoolData[key].tradeFeeApy : 0;
      poolNameArray[key_arr[1] + '_' + poolId] = newPoolData[key].name ? newPoolData[key].name : "";
      gamma_apr_array[key_arr[1] + '_' + poolId] = newPoolData[key].gammaApr ? newPoolData[key].gammaApr : 0;
      tradefee_apr_array[key_arr[1] + '_' + poolId] = newPoolData[key].tradeFeeApr ? newPoolData[key].tradeFeeApr : 0;
      parent_farm_apr_array[key_arr[1] + '_' + poolId] = newPoolData[key].parentFarmApr ? newPoolData[key].parentFarmApr : 0;
    }
    totalBalanceBlueUSD = 0;
    blue_apy = 0;
    let priceArray = await returnTokenPriceMulticall();
    aquaPrice = priceArray[aquaAddress.toLowerCase()] ? priceArray[aquaAddress.toLowerCase()] : await getAquaPrice();
    gammaPrice = priceArray[gammaAddress.toLowerCase()] ? priceArray[gammaAddress.toLowerCase()] : await getGammaPrice();
    pidArr = poolIdArr;
    wantAddressArr = wantAddressArray;
    tradeFeeApy = tradeFeeApyArray;
    farmApyArray = farmApyArr;
    lpTokenPriceArr = lpTokenPriceArray;
    stratAddressArr = stratAddressArray;
    poolNameArr = poolNameArray;
    gammaAprArray = gamma_apr_array;
    tradeFeeApr = tradefee_apr_array;
    poolInterestPerYear = pool_interest_per_year;
    parentFarmAprArray = parent_farm_apr_array
  }
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmV3Abi, gammaFarmV3);
  const user_eligible_inst: any = new wallet.web3.eth.Contract(eligibilityDataProviderAbi, eligibilityDataProviderAddress);
  let sharesArr: any = [];
  let poolInfoArr: any = [];
  let infinityBalanceArr: any = [];
  let gTokenExchangeRateArr: any = [];
  let userInfoArr: any = [];
  let allowanceArr: any = [];
  let lpBalanceArr: any = [];
  let balanceInLPArr: any = [];
  let lpAllowance: any = [];
  let totalSupplyArr: any = [];
  let number_of_locks: any = 0;
  let locked_tokens_array: any = [];
  let unlocked_pool_balance_array = [];
  let token0_array: any = [];
  let token1_array: any = [];
  let pendingGammaRewardsArr: any = [];
  let userEarningSharesArr: any = [];

  let targets: any = [];
  let callDatas: any = [];
  let results:any = [];
  let ouput_format : any = [];

  let totalAllocPoint: any = await farm_inst.methods.totalAllocPoint().call();
  let isDrip: any = await farm_inst.methods.isDrip().call()
  let reservoir_inst = new wallet.web3.eth.Contract(new_gamma_reservoir_abi, new_gamma_reservoir);
  const farmV3DripRate = await reservoir_inst.methods.farmDripRate().call()
  
  //shares
  pidArr.forEach(async(pid: any, index: any) => {
    let poolId = pid.split("_");
    const strat_inst: any = new wallet.web3.eth.Contract(v3_strategy_abi, v3_strategy_address);
    const strategyAddress: any = stratAddressArr[pid];
    // console.log(strategyAddress, poolId)
    targets.push(strategyAddress);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(strat_inst.methods.getShares()._method,[]));
      callDatas.push(data);
      ouput_format.push(strat_inst.methods.getShares()._method.outputs)
    } catch(error){
      console.log(error);
      return [0, 0];
    }
  })

  //pool info
  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_");
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmV3Abi, poolId[0]);
    targets.push(poolId[0]);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.poolInfo(poolId[1])._method,[poolId[1]]));
      callDatas.push(data);
      ouput_format.push(farm_inst.methods.poolInfo(poolId[1])._method.outputs)
    } catch(error){
      console.log(error);
      return 0;
    }
  })

  // user info
  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_");
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmV3Abi, poolId[0]);
    targets.push(poolId[0]);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.userInfo(poolId[1], userAddress)._method,[poolId[1], userAddress]));
      callDatas.push(data);
      ouput_format.push(farm_inst.methods.userInfo(poolId[1], userAddress)._method.outputs)
    } catch(error){
      console.log(error);
      return 0;
    }
  })

  //allowance
  pidArr.forEach(async(pid: any, index: any) => {
    let farmAddress = pid.split('_')[0];
    let wantAddress = wantAddressArr[pid];
    // console.log(wantAddress, index);
    targets.push(wantAddress)
    const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
    const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.allowance(userAddress, farmAddress)._method, [
      userAddress,
      farmAddress,
    ])
    callDatas.push(data);
    ouput_format.push(tokenInstance.methods.allowance(userAddress, farmAddress)._method.outputs)
  });

  //lp balance
  pidArr.forEach(async(pid:any, index: any) => {
    let wantAddress = wantAddressArr[pid];
    const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
    targets.push(wantAddress)
    const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(userAddress)._method, [userAddress])
    callDatas.push(data)
    ouput_format.push(tokenInstance.methods.balanceOf(userAddress)._method.outputs)
  })

  //balance
  pidArr.forEach(async(pid:any, index: any) => {
    let tokenInstance: any;
    if (wantAddressArr[pid].toLowerCase() === "0x43d427dF6957e180497248CaDc30cE7aa716FE52".toLowerCase()) {
      tokenInstance = new wallet.web3.eth.Contract(pancakeLPabi, gammaAddress)
      targets.push(gammaAddress)
    } else {
      tokenInstance = new wallet.web3.eth.Contract(pancakeLPabi, aquaAddress)
      targets.push(aquaAddress)
    }
    const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(wantAddressArr[pid])._method, [wantAddressArr[pid]])
    callDatas.push(data)
    ouput_format.push(tokenInstance.methods.balanceOf(wantAddressArr[pid])._method.outputs)
  })
  
  //zap allowance for lp
  pidArr.forEach(async(pid:any, index: any) => {
    const LP_INSTANCE = new wallet.web3.eth.Contract(PLANETLPABI, wantAddressArr[pid])
    targets.push(wantAddressArr[pid])
    
    const data = wallet.web3.eth.abi.encodeFunctionCall(LP_INSTANCE.methods.allowance(userAddress, aquaConvertLpAddress)._method, [userAddress, aquaConvertLpAddress])
    callDatas.push(data)
    ouput_format.push(LP_INSTANCE.methods.allowance(userAddress, aquaConvertLpAddress)._method.outputs)
  })

  //total supply
  pidArr.forEach(async(pid: any, index: any) => {
    try{
      let wantAddress = wantAddressArr[pid];
      const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
      // const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddressArr[pid])
      targets.push(wantAddressArr[pid])
      const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.totalSupply()._method, [])
      callDatas.push(data)
      ouput_format.push(tokenInstance.methods.totalSupply()._method.outputs)
    } catch(error){
      console.log("error", error)
      return [];
    }
  })
  
  //number of locks in pool
  pidArr.forEach(async(pid: any, index: any) => {
      let poolId = pid.split("_");
      // console.log(poolId)
      const strategyAddress: any = index > 2 ? stratAddressArr[v3FarmAddress+'_2'] : stratAddressArr[pid];
      const strat_inst: any = new wallet.web3.eth.Contract(v3_strategy_abi, strategyAddress);
      targets.push(strategyAddress);
      try{
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(strat_inst.methods.getNumberOfLocks(userAddress)._method,[userAddress]));
        callDatas.push(data);
        ouput_format.push(strat_inst.methods.getNumberOfLocks(userAddress)._method.outputs)
      } catch(error){
        console.log(error);
        return 0;
      }
  })

  //unlocked pool balance for withdrawal
  pidArr.forEach(async(pid: any, index: any) => {
      let poolId = pid.split("_");
      const strat_inst: any = new wallet.web3.eth.Contract(v3_strategy_abi, v3_strategy_address);
      const strategyAddress: any = index > 2 ? stratAddressArr[v3FarmAddress+'_2'] : stratAddressArr[pid];
      targets.push(strategyAddress);
      try{
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(strat_inst.methods.lockedBalances(userAddress)._method,[userAddress]));
        callDatas.push(data);
        ouput_format.push(strat_inst.methods.lockedBalances(userAddress)._method.outputs)
      } catch(error){
        console.log(error);
        return 0;
      }
  })

  //pool token 0
  pidArr.forEach(async(pid: any, index: any) => {
    try{
      let wantAddress = wantAddressArr[pid].toLowerCase() == "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991".toLowerCase() ? "0x43d427dF6957e180497248CaDc30cE7aa716FE52" : wantAddressArr[pid];
      const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
      // const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddressArr[pid])
      targets.push(wantAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.token0()._method, [])
      callDatas.push(data)
      ouput_format.push(tokenInstance.methods.token0()._method.outputs)
    } catch(error){
      console.log("error", error)
      return [];
    }
  })

  //pool token 1
  pidArr.forEach(async(pid: any, index: any) => {
    try{
      let wantAddress = wantAddressArr[pid].toLowerCase() == "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991".toLowerCase() ? "0x43d427dF6957e180497248CaDc30cE7aa716FE52" : wantAddressArr[pid];
      const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
      targets.push(wantAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.token1()._method, [])
      callDatas.push(data)
      ouput_format.push(tokenInstance.methods.token1()._method.outputs)
    } catch(error){
      console.log("error", error)
      return [];
    }
  })

  //pending Gamma reward in v3
  pidArr.forEach(async(pid: any, index: any) => {
    try{
      const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmV3Abi, gammaFarmV3);
      targets.push(gammaFarmV3)
      const data = wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.pendingGAMMAAllPools(userAddress)._method, [userAddress])
      callDatas.push(data)
      ouput_format.push(farm_inst.methods.pendingGAMMAAllPools(userAddress)._method.outputs)
    } catch(error){
      console.log("error", error)
      return [];
    }
  })

  //user earing shares
  pidArr.forEach(async(pid: any, index: any) => {
      try{
        let poolId = pid.split("_");
        const strat_inst: any = new wallet.web3.eth.Contract(v3_strategy_abi, v3_strategy_address);
        const strategyAddress: any = index > 2 ? stratAddressArr[v3FarmAddress+'_2'] : stratAddressArr[pid];
        targets.push(strategyAddress);
        const data = wallet.web3.eth.abi.encodeFunctionCall(strat_inst.methods.getEarningShares(userAddress)._method, [userAddress])
        callDatas.push(data)
        ouput_format.push(strat_inst.methods.getEarningShares(userAddress)._method.outputs)
      } catch(error){
        console.log("error", error)
        return [];
      }
  })

  pidArr.forEach(async(pid: any, index: any) => {
    try{
      targets.push(eligibilityDataProviderAddress);
      const data = wallet.web3.eth.abi.encodeFunctionCall(user_eligible_inst.methods.isEligibleForRewards(userAddress)._method, [userAddress])
      callDatas.push(data)
      ouput_format.push(user_eligible_inst.methods.isEligibleForRewards(userAddress)._method.outputs)
    } catch(error){
      console.log("error", error)
      return [];
    }
  })

  // console.log(targets.length, callDatas.length, wantAddressArr)
  const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());

  const do_split = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }  

  for(let i = 0 ; i < aggregated_data[1].length ; i++){
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i],aggregated_data[1][i]))
  }
  const split_arr = (await do_split(results, pidArr.length));
  // console.log("new pool multicall split data", split_arr);
  sharesArr = split_arr[0];
  // console.log("shares array", sharesArr)
  poolInfoArr = split_arr[1];
  // gTokenExchangeRateArr = split_arr[2];
  userInfoArr = split_arr[2];
  allowanceArr = split_arr[3];
  lpBalanceArr = split_arr[4];
  balanceInLPArr = split_arr[5];
  lpAllowance = split_arr[6];
  totalSupplyArr = split_arr[7];
  number_of_locks = split_arr[8];
  unlocked_pool_balance_array = split_arr[9];
  token0_array = split_arr[10];
  token1_array = split_arr[11];
  pendingGammaRewardsArr = split_arr[12] ? split_arr[12] : 0;
  userEarningSharesArr = split_arr[13];
  let userEligibilityData: any = split_arr[14];
  let pendingGammaRewards = convertToEther(pendingGammaRewardsArr[0][0]);
  let pool_locks_array: any = [];

  if(number_of_locks.length > 0) {
    let targets_ : any = [];
    let callDatas_ : any = [];
    let results_ :any = [];
    let ouput_format_ : any = [];
    //number of locks in pool
    pidArr.forEach(async(pid: any, index: any) => {
      if(index < 3) {
        let poolId = pid.split("_");
        const strat_inst: any = new wallet.web3.eth.Contract(v3_strategy_abi, v3_strategy_address);
        const strategyAddress: any = stratAddressArr[pid];
        for( let i = 0; i < number_of_locks[index][0]; i++) {
          if(number_of_locks[index][0] > 0 ){
            targets_.push(strategyAddress);
            const data: any = (wallet.web3.eth.abi.encodeFunctionCall(strat_inst.methods.userLocks(userAddress, i)._method,[userAddress, i]));
            callDatas_.push(data);
            ouput_format_.push(strat_inst.methods.userLocks(userAddress, i)._method.outputs)
          }
        }
      }
    })

    const aggregated_data_ = (await multicall_inst.methods.aggregate(targets_,callDatas_).call());

    const do_split_ = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split_(array, n)) : [];
    }  

    for(let i = 0 ; i < aggregated_data_[1].length ; i++){
      results_.push(wallet.web3.eth.abi.decodeParameters(ouput_format_[i],aggregated_data_[1][i]))
    }
    const split_arr_ = (await do_split_(results_, targets_.length));
    pool_locks_array = split_arr_[0];
  }
  let boostArray: any = [];
  let userSharesArray: any = []
  let userLPArray: any = [];
  let balanceInBlue: any = 0;
  let userAllowanceArr: any = userAllowanceArray ? userAllowanceArray : [];
  let getPoolsAndVaultsDataForPF: any = [];
  try {
    if(Object.keys(planetGlobalObject.getallpoolinfo).length > 0) {
      getPoolsAndVaultsDataForPF = planetGlobalObject.getallpoolinfo;
    }
    else {
      getPoolsAndVaultsDataForPF = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
    }
  } catch (error) {
    try {
      getPoolsAndVaultsDataForPF = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
    } catch (error) {
      console.log('error in fetching getTotalSupplyApy', error);
    }
  }
  let avg_boost_apr_array: any = [];
  // let getKeys = Object.keys(getPoolsAndVaultsDataForPF);
  getPoolsAndVaultsDataForPF.newPools.active.forEach((element: any, key: any) => {
    let new_key = element.farmContractAddress + "_" + element.poolId ;
    avg_boost_apr_array[new_key] = element.averageGammaBoostApr;
  });

  let user_gamma_btcb_balance_in_v3 : any = 0;
  let other_pool_balance_in_v3: any = 0
  let pool_lock_index = 0
  let aquaInAquaBtcbPool: any = 0;
  let gammaInGammaBtcbPool: any = 0;
  let aquaInAquaPool: any = 0;
  for (let i = 0; i < pidArr.length; i++) {
    let poolId = pidArr[i];
    let sharesTotal = sharesArr[i][1];
    let wantLockedTotal = sharesArr[i][0];
    let userBoostAPR = 0;
    let averageBoostAPR = 0;
    let userEarningShares = userEarningSharesArr[i][0];
    let userFactor = userInfoArr[i].earningFactor;
    let userShares = userInfoArr[i].shares;
    let totalFactor: any = poolInfoArr[i].totalEarningFactor;
    if(!poolInfoArr[i].isBoosted){
      boostArray[pidArr[i]] = {averageBoostApy: 0, userBoostApy: 0};
    }
    let blocksPerYear = 10512000;
    if(!isDrip){
      blocksPerYear = 0;
    }

    if(sharesArr[i][1] == 0){
      stakedTokenArr[pidArr[i]] = 0;
    }

    stakedTokenArr[pidArr[i]] = (+userInfoArr[i].shares * +sharesArr[i][0]) / (+sharesArr[i][1] * 1e18)

    //farmDripPerBlock*AllocPointForPool*blocksPerYear*gammaPrice/(total lp tokens in pool * price of LP Token). 
    let gammaAPR : any = 0;
    let gammaAPY : any = 0;
    let userEligibilityCheck = userEligibilityData[0][0];
    if(totalAllocPoint > 0){

      gammaAPR = (farmV3DripRate * blocksPerYear * poolInfoArr[i].allocPoint * userFactor * gammaPrice * +sharesArr[i][1] * 1e18 * 100) /( 1e18 * totalFactor * totalAllocPoint * +lpTokenPriceArr[poolId] * +userInfoArr[i].shares * +sharesArr[i][0]) ;
      
      let genericGammaApr = (farmV3DripRate * blocksPerYear * poolInfoArr[i].allocPoint * gammaPrice * 100 ) /( wantLockedTotal * totalAllocPoint * +lpTokenPriceArr[poolId]);

      if(stakedTokenArr[pidArr[i]] == 0){
        gammaAPR = genericGammaApr;
      } else if (userEligibilityCheck || wantAddressArr[poolId].toLowerCase() == "0x43d427dF6957e180497248CaDc30cE7aa716FE52".toLowerCase()){
        //proper apr
        gammaAPR = gammaAPR;
      } else {
        gammaAPR = 0;
      }

      gammaAPY =  (Math.pow((1 + (gammaAPR)/36500), 365) - 1) * 100;
      gammaAPY = gammaAPY > 10000 ? 10000 : gammaAPY;

      // console.log("farmV3DripRate ", farmV3DripRate, "blocksPerYear ", blocksPerYear, "allocPoint ", poolInfoArr[i].allocPoint, "gamma price ",  gammaPrice, "wantLockedTotal", wantLockedTotal,  "totalAllocPoint", totalAllocPoint, "lp price", +lpTokenPriceArr[poolId], "gammaAPR", gammaAPR)
    }

    totalSupplyArray[wantAddressArr[pidArr[i]].toLowerCase()] = gConvertToEther(totalSupplyArr[i][0], 18);

    userSharesArray[poolId] = stakedTokenArr[poolId]
    let farmAddress = poolId.split('_')[0].toLowerCase();
    let pool_id = poolId.split('_')[1];
    wantAddressArr[poolId] = wantAddressArr[poolId].toLowerCase();
    // balanceLPArray[tokenArray[i]] = gConvertToEther(split_arr[0][i][0], 18)
    balanceLPArray[wantAddressArr[poolId]] = gConvertToEther(balanceInLPArr[i][0], 18)
    userAllowanceArray[wantAddressArr[pidArr[i]] + '_' + farmAddress] = convertToEther(allowanceArr[i][0])
    let walletId = wantAddressArr[pidArr[i]] + "_" + farmAddress;
    walletId = walletId.toLowerCase();
    userLPWalletArray[walletId] = convertToEtherRevised(lpBalanceArr[i][0], 1e18);//convertToEther(lpBalanceArr[i][0])
    // balanceLPArray[wantAddressArr[poolId]] = convertToEther(lpBalanceArr[i][0]);
    userLPWalletArrayNew[wantAddressArr[pidArr[i]].toLowerCase()] = convertToEtherRevised(lpBalanceArr[i][0], 1e18);//convertToEther(lpBalanceArr[i][0])
    //new user pool array data
    totalBalanceBlueUSD = userSharesArray[poolId] == userSharesArray[poolId] ? +userSharesArray[poolId] * +lpTokenPriceArr[poolId] : 0;
    balanceInBlue += totalBalanceBlueUSD;
    let boostApy = userSharesArray[poolId] == userSharesArray[poolId] && +userSharesArray[poolId] * lpTokenPriceArr[poolId] > 0.01 && boostArray[poolId].userBoostApy ? boostArray[poolId].userBoostApy : boostArray[poolId].averageBoostApy ? boostArray[poolId].averageBoostApy : 0 ;
    // console.log("avg boost apy", boostArray, boostArray[poolId].averageBoostApy, "user boost apy", boostArray[poolId].userBoostApy)
    // if(i< 2){
    //   continue;
    // }
    if (wantAddressArr[poolId] !== undefined && tradeFeeApy[poolId] !== undefined) {
      blue_apy += (totalBalanceBlueUSD * (farmApyArray[poolId] + tradeFeeApy[poolId] + boostApy)) / 100;
      // console.log("apy in new  pools if", poolId, boostApy, farmApyArray[poolId], tradeFeeApy[poolId], blue_apy)
    } else {
      blue_apy += (totalBalanceBlueUSD * (farmApyArray[poolId] + boostApy)) / 100;
      // console.log("apy in new pools else", poolId, farmApyArray[poolId], boostApy, blue_apy)
    }
    let boostApr = userSharesArray[poolId] == userSharesArray[poolId] && +userSharesArray[poolId] * lpTokenPriceArr[poolId] > 0.01 && userBoostAPR ? userBoostAPR : avg_boost_apr_array[poolId] ? avg_boost_apr_array[poolId] : 0;
    
    let parentFarmApr = parentFarmAprArray[poolId] ? parentFarmAprArray[poolId] : 0;

    if (wantAddressArr[poolId] !== undefined && tradeFeeApr[poolId] !== undefined) {
      
      poolInterestPerYear += (totalBalanceBlueUSD * (gammaAprArray[poolId] + tradeFeeApr[poolId] + parentFarmApr)) / 100;
      // console.log("poolInterestPerYear", poolInterestPerYear, poolId, totalBalanceBlueUSD, boostApr, gammaAprArray[poolId], tradeFeeApr[poolId])
    } else {
      poolInterestPerYear += (totalBalanceBlueUSD * (gammaAprArray[poolId] + parentFarmApr)) / 100;
      // console.log("apy in new pools else", poolInterestPerYear, poolId, totalBalanceBlueUSD, gammaAprArray[poolId], boostApr)
    }
    if (wantAddressArr[poolId] !== undefined) {
      let farmAddress = poolId.split('_')[0];
      let pid = poolId.split('_')[1];
      lpTokenPriceArr[poolId] = lpTokenPriceArr[poolId] ? lpTokenPriceArr[poolId] : 0;
      let lpAddressKey = wantAddressArr[poolId] + '_' + farmAddress;
      let pools_lock_details_array : any = [];
      // console.log(number_of_locks, pool_locks_array);
      if(number_of_locks.length > 0 && i < 3 && number_of_locks[i][0] > 0) {
        for(let k = 0; k < number_of_locks[i][0]; k++){
          // pool_locks_array[k].amountInUSD = pool_locks_array[pool_lock_index].amount * lpTokenPriceArr[poolId];
          let currentDate = new Date();
          if(pool_locks_array[pool_lock_index] && pool_locks_array[pool_lock_index].unlockTime && pool_locks_array[pool_lock_index].unlockTime > ((currentDate.getTime())/1000))
          pools_lock_details_array.push(pool_locks_array[pool_lock_index]);
          pool_lock_index++;
        }
      }
      if (userLPArray[lpAddressKey] === undefined) {
        let key = poolId;
        userLPArray[lpAddressKey] = {};
        userLPArray[lpAddressKey].wantAddress = wantAddressArr[poolId];
        userLPArray[lpAddressKey].farmAddress = farmAddress;
        userLPArray[lpAddressKey].wantAddress_farmAddress = lpAddressKey;
        userLPArray[lpAddressKey].poolId = key;
        userLPArray[lpAddressKey].userRewards = 0;//userRewardsArray[key] ? +userRewardsArray[key] : 0;
        userLPArray[lpAddressKey].userRewardsUSD = 0;//userRewardsArray[key] && +userRewardsArray[key] * gammaPrice > 0.01 ? +userRewardsArray[key] * gammaPrice : 0;
        userLPArray[lpAddressKey].uranusRewardsUSD = 0;//pendingUranusRewardsArray[key] && +pendingUranusRewardsArray[key] * gammaPrice > 0.01 ? +pendingUranusRewardsArray[key] * gammaPrice : 0;
        userLPArray[lpAddressKey].uranusRewards = 0;//pendingUranusRewardsArray[key] ? +pendingUranusRewardsArray[key] : 0;
        userLPArray[lpAddressKey].LPTokens = userSharesArray[key] == userSharesArray[key] ? +userSharesArray[key] : 0;
        userLPArray[lpAddressKey].LPTokenUSD = +userLPArray[lpAddressKey].LPTokens * lpTokenPriceArr[key] > 0.01 ? +userSharesArray[key] * lpTokenPriceArr[key] : 0;
        userLPArray[lpAddressKey].allowance = userAllowanceArray[lpAddressKey.toLowerCase()] ? userAllowanceArray[lpAddressKey.toLowerCase()] : 0;
        userLPArray[lpAddressKey].isAllowanceApproved =
          userAllowanceArray[lpAddressKey.toLowerCase()] && +userAllowanceArray[lpAddressKey.toLowerCase()] > 0 ? true : false;
        userLPArray[lpAddressKey].walletBalance = userLPWalletArray[lpAddressKey.toLowerCase()] ? userLPWalletArray[lpAddressKey.toLowerCase()] : 0;
        // console.log("userLPWalletArray[lpAddressKeys]", lpAddressKey, userLPWalletArray[lpAddressKey.toLowerCase()]);
        // console.log("wallet balance of LP", +userLPWalletArray[lpAddressKey.toLowerCase()] * +lpTokenPriceArr[key], userLPWalletArray[lpAddressKey.toLowerCase()] , +lpTokenPriceArr[key])
        userLPArray[lpAddressKey].walletBalanceUSD = +userLPWalletArray[lpAddressKey.toLowerCase()] * +lpTokenPriceArr[key] > 0.01 ? +userLPWalletArray[lpAddressKey.toLowerCase()] * +lpTokenPriceArr[key] : 0;
        userLPArray[lpAddressKey].userBoostApy = boostArray[poolId].userBoostApy ? boostArray[poolId].userBoostApy : 0;
        userLPArray[lpAddressKey].averageBoostApy = boostArray[poolId].averageBoostApy ? boostArray[poolId].averageBoostApy : 0;
        userLPArray[lpAddressKey].gammaBoostPercent = boostArray[poolId].gammaBoostPercent ? boostArray[poolId].gammaBoostPercent : 0;
        userLPArray[lpAddressKey].avgBoostAPR = averageBoostAPR;
        userLPArray[lpAddressKey].userBoostAPR = userBoostAPR;
        userLPArray[lpAddressKey].lpAllowance = convertToEther(lpAllowance[i][0]);
        userLPArray[lpAddressKey].poolName = poolNameArr[poolId];
        userLPArray[lpAddressKey].lp_price = lpTokenPriceArr[key];
        userLPArray[lpAddressKey].withdrawalBalance = pid > 2 ? userLPArray[lpAddressKey].LPTokens : convertToEther(unlocked_pool_balance_array[i][0]); //(+userInfoArr[i].shares * +sharesArr[i][0]) / (+sharesArr[i][1] * 1e18)
        userLPArray[lpAddressKey].withdrawalBalanceUSD = +userLPArray[lpAddressKey].withdrawalBalance * lpTokenPriceArr[key] > 0.01 ? +userLPArray[lpAddressKey].withdrawalBalance * lpTokenPriceArr[key] : 0;
        userLPArray[lpAddressKey].withdrawalBalanceUSD = pid > 2 ? userLPArray[lpAddressKey].LPTokenUSD : userLPArray[lpAddressKey].withdrawalBalanceUSD;
        userLPArray[lpAddressKey].poolsLockArray = pools_lock_details_array;
        userLPArray[lpAddressKey].lockPoolInfo = pools_lock_details_array;
        userLPArray[lpAddressKey].token0 = poolNameArr[poolId].toLowerCase() == "aqua" ? aquaAddress : token0_array[i][0]
        userLPArray[lpAddressKey].token1 = poolNameArr[poolId].toLowerCase() == "aqua" ? aquaAddress : token1_array[i][0]
        userLPArray[lpAddressKey].gammaAPR = gammaAPR ? gammaAPR : 0;
        userLPArray[lpAddressKey].gammaAPY = gammaAPY ? gammaAPY : 0;
        userLPArray[lpAddressKey].tradeFeeAPR = tradeFeeApr[poolId] ? tradeFeeApr[poolId] : 0;
        userLPArray[lpAddressKey].tradeFeeAPY = tradeFeeApy[poolId] ? tradeFeeApy[poolId] : 0;
        userLPArray[lpAddressKey].totalAPR = userLPArray[lpAddressKey].gammaAPR + userLPArray[lpAddressKey].tradeFeeAPR;
        const totalAPY = (Math.pow((1 + (userLPArray[lpAddressKey].totalAPR)/36500), 365) - 1) * 100;
        userLPArray[lpAddressKey].totalAPY = totalAPY > 10000 ? 10000 : 0;
        userLPArray[lpAddressKey].farmV3DripRate = farmV3DripRate;
        userLPArray[lpAddressKey].allocPoint = poolInfoArr[i].allocPoint;
        userLPArray[lpAddressKey].totalAllocPoint = totalAllocPoint;
        userLPArray[lpAddressKey].gammaPrice = gammaPrice;
        userLPArray[lpAddressKey].wantLockedTotal = wantLockedTotal;
        userLPArray[lpAddressKey].sharesTotal = sharesTotal;
        userLPArray[lpAddressKey].totalFactor = totalFactor;
        userLPArray[lpAddressKey].userFactor = userFactor;
        userLPArray[lpAddressKey].userEarningShares = userEarningShares;
        userLPArray[lpAddressKey].userShares = userShares;

        if(pid == 1){
          // console.log(userLPArray[lpAddressKey], lpAddressKey)
          let lp_balance = userLPArray[lpAddressKey].LPTokens;
          let aquaInPool = +balanceLPArray[wantAddressArr[poolId]];
          let total_supply = gConvertToEther(totalSupplyArr[i][0], 18);
          
          if(total_supply > 0){
            aquaInAquaBtcbPool = (lp_balance * aquaInPool)/total_supply;
            // console.log("aquaInAquaBtcbPool", aquaInAquaBtcbPool, aquaInPool, lp_balance, total_supply, userInfoArr[i])
          } 

        }

        if(pid == 0){
          // console.log(userLPArray[lpAddressKey], lpAddressKey)
          let lp_balance = userLPArray[lpAddressKey].LPTokens;
          let gammaInPool = +balanceLPArray[wantAddressArr[poolId]];
          // let lpInPool = +wallet_balance + parseFloat(convertToEther(userInfoArr[i].shares));
          let total_supply = gConvertToEther(totalSupplyArr[i][0], 18);
          
          if(total_supply > 0){
            gammaInGammaBtcbPool = (lp_balance * gammaInPool)/total_supply;
            // console.log("gammaIngammaBtcbPool", gammaInGammaBtcbPool, lp_balance,  gammaInPool, lp_balance, total_supply, userInfoArr[i])
          } 
        }

        if(pid == 2) {
          aquaInAquaPool = userLPArray[lpAddressKey].LPTokens;
        }
      }
      user_gamma_btcb_balance_in_v3 += i == 0 ? userLPArray[lpAddressKey].LPTokenUSD : 0;
      other_pool_balance_in_v3 += i > 0 ? userLPArray[lpAddressKey].LPTokenUSD : 0;
    }
    
  }
  if(portfolio_response){
    return {userLPArray, balanceInBlue, blue_apy, poolInterestPerYear, user_gamma_btcb_balance_in_v3, other_pool_balance_in_v3, pendingGammaRewards, gammaInGammaBtcbPool, aquaInAquaBtcbPool, aquaInAquaPool};
  } else {
    let poolData: any = {};
    try {
      if(Object.keys(planetGlobalObject.poolsInfo).length > 0) {
        poolData = planetGlobalObject.poolsInfo;
      }
      else {
        poolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfo')).data;
      }
      
    } catch (error) {
      try {
        poolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfo')).data;
      } catch (error) {
        console.log('error in fetching poolData', error);
      }
    }
    let poolIdArray: any = [];
    let stratAddressArray: any = [];
    let farmApyArray: any = [];
    let lpTokenPrice: any = [];
    let wantAddressArray: any = [];
    // let userLPArray: any = [];
    let allocPointArray: any = [];
    let tradeFeeApy: any = [];
    let tradeFeeApr: any = [];
    let poolRewards: any = 0;
    for (var key in poolData) {
      let key_arr = key.split('_');
      let poolId = poolData[key].poolId;
      
      poolIdArray.push(key_arr[1] + '_' + poolId); //farmAddress_poolId
      stratAddressArray[key_arr[1] + '_' + poolId] = poolData[key].strategyAddress;
      farmApyArray[key_arr[1] + '_' + poolId] = poolData[key].farmApyPerYear ? poolData[key].farmApyPerYear : 0; // gammaApy = farmApyPerYear
      lpTokenPrice[key_arr[1] + '_' + poolId] = poolData[key].price ? poolData[key].price : 0;
      wantAddressArray[key_arr[1] + '_' + poolId] = poolData[key].wantAddress.toLowerCase();
      allocPointArray[key_arr[1] + '_' + poolId] = poolData[key].poolAllocPoint;
      tradeFeeApy[key_arr[1] + '_' + poolId] = poolData[key].tradeFeeApy ? poolData[key].tradeFeeApy : 0;
      tradeFeeApr[key_arr[1] + '_' + poolId] = poolData[key].tradeFeeApr ? poolData[key].tradeFeeApr : 0;
    }
    const oldPoolData = await fetchOldPoolDetails(userAddress, poolIdArray, wantAddressArray, lpTokenPrice, gammaPrice, tradeFeeApy, farmApyArray, stratAddressArray, tradeFeeApr, poolRewards)
    let newPoolData = userLPArray;

    


    return {newPoolData, oldPoolData};
  }
  
}

const stakedWantTokensNew = async (farmAddress: string, poolId: number, userAddress: string, strat_abi: any, strat_addr: any, pid: any) => {
  try {
    const strat_inst: any = await selectInstance(strat_abi, strat_addr);
    const data = await strat_inst.methods.getShares().call()//[wantLockTotal, sharesTotal]
    if(data[1] == 0){
      return 0;
    }
    let stakedTokens;
    const farm_inst: any = await selectInstance(gammaFarmAbi, farmAddress);
    let pool_data: any = await farm_inst.methods.poolInfo(pid).call()// isInfinity - true or false
    if(pool_data.isInfinity){
      const iToken_inst: any = poolId == 0 ? await selectInstance(gamma_infinity_vault_abi, pool_data.iToken) : await selectInstance(aqua_infinity_vault_abi, pool_data.iToken);
      const itoken_balance = await iToken_inst.methods.balanceOf(userAddress).call()// itoken balance
      const gToken_inst: any = await selectInstance(gTokenAbi, pool_data.gToken);
      const gtoken_exchangeRate = await gToken_inst.methods.exchangeRateStored().call()// itoken balance
      stakedTokens = (itoken_balance * data[0] * gtoken_exchangeRate) / (data[1] * 1e36)
    } else {
      const farm_inst: any = await selectInstance(gammaFarmAbi, farmAddress);
      let user_data: any = await farm_inst.methods.userInfo(pid, userAddress).call();
      stakedTokens = user_data.shares * data[0] / data[1];
    }


    // console.log("stakedWantTokens", stakedTokens);
    return stakedTokens;
  } catch (e) {
    console.log(e)
    return 0
  }
}



/*
function getUserAndAverageBoost(uint256 _pid, address _user) external view returns (uint256 userBoostAPR, uint256 averageBoostAPR) {
  PoolInfo memory pool = poolInfo[_pid];
  UserInfo memory user = userInfo[_pid][_user];
  
  (uint256 wantLockedTotal, uint256 sharesTotal) = IStrategy(poolInfo[_pid].strat).getShares();
  (uint256 userShares, uint256 userFactor) = (user.shares,  user.factor);

  if(!pool.isBoosted) return (0, 0);
  if(sharesTotal == 0   == 0) return (0, 0);

  uint256 blocksPerYear = 10512000;
  if(!isDrip){
      blocksPerYear = 0;
  }

  uint256 GAMMAReward = (pool.gammaRewardBoostPercentage * blocksPerYear * ReservoirAddress.farmV2DripRate() * pool.allocPoint)/totalAllocPoint;
  averageBoostAPR = gammaBoostPerYear*1e18/(wantLockedTotal);
  userBoostAPR = gammaBoostPerYear*1e18*(userFactor/totalFactor)/(user.shares*wantLockedTotal/sharesTotal);

}
*/

export const getUserAndAverageBoost = async (pid: any, userAddress: string,) => {
  try {
    const strat_inst: any = pid == 0 ? new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new) : pid == 1 ? new wallet.web3.eth.Contract(aqua_strategy_abi, aqua_strategy_address_new) : new wallet.web3.eth.Contract(normal_strategy_abi_new, normal_strategy_address_new);
    const data = await strat_inst.methods.getShares().call()//[wantLockTotal, sharesTotal]
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress);
    let user_data: any = await farm_inst.methods.userInfo(pid, userAddress).call();
    let pool_data: any = await farm_inst.methods.poolInfo(pid).call()
    let isDrip: any = await farm_inst.methods.isDrip().call()
    let totalAllocPoint: any = await farm_inst.methods.totalAllocPoint().call()
    let reservoir_inst = new wallet.web3.eth.Contract(reservoir_abi, gamma_reservoir);//change this
    const farmV2DripRate = await reservoir_inst.methods.farmV2DripRate().call()
    if(!pool_data.isBoosted){
      return 0;
    }
    let blocksPerYear = 10512000;
    if(!isDrip){
      blocksPerYear = 0;
    }
    let gammaReward = (pool_data.gammaRewardBoostPercentage * blocksPerYear * farmV2DripRate * pool_data.allocPoint)/totalAllocPoint;
    let averageBoostAPR = gammaReward*1e18/(data[0]);
    let userBoostAPR = gammaReward*1e18*(user_data.factor/pool_data.totalFactor)/(user_data.shares*data[0]/data[1]);
    return {averageBoostAPR, userBoostAPR };
  } catch (error){

  }
}

export const getUserAndAverageBoostMulticall = async (pidArr: any, userAddress: string, gammaPrice: any, lpPriceArray: any, stratArr: any) => {
  
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  let sharesWantArr: any = [];
  let poolInfoArr: any = [];
  let userInfoArr: any = [];
  let boostArray: any = [];
  let targets: any = [];
  let callDatas: any = [];
  let results:any = [];
  let ouput_format : any = [];
  const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress);
  let totalAllocPoint: any = await farm_inst.methods.totalAllocPoint().call();
  let isDrip: any = await farm_inst.methods.isDrip().call()
  let reservoir_inst = new wallet.web3.eth.Contract(reservoir_abi, gamma_reservoir);
  const farmV2DripRate = await reservoir_inst.methods.farmV2DripRate().call()

  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_");
    const strat_inst: any = poolId[1] == 0 ? new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new) : poolId[1] == 1 ? new wallet.web3.eth.Contract( aqua_strategy_abi, aqua_strategy_address_new) : new wallet.web3.eth.Contract(normal_strategy_abi_new, normal_strategy_address_new);
    const strategyAddress: any = poolId[1] == 0 ? gamma_strategy_address_new : poolId[1] == 1 ?aqua_strategy_address_new : stratArr[pid]//normal_strategy_address_new;
    targets.push(strategyAddress);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(strat_inst.methods.getShares()._method,[]));
      callDatas.push(data);
      ouput_format.push(strat_inst.methods.getShares()._method.outputs)
    } catch(error){
      console.log(error);
      return [0, 0];
    }
  })

  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_");
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, poolId[0]);
    targets.push(poolId[0]);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.poolInfo(poolId[1])._method,[poolId[1]]));
      callDatas.push(data);
      ouput_format.push(farm_inst.methods.poolInfo(poolId[1])._method.outputs)
    } catch(error){
      console.log(error);
      return 0;
    }
  })

  if(userAddress !== undefined) {
    pidArr.forEach(async(pid: any) => {
      let poolId = pid.split("_");
      const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, poolId[0]);
      targets.push(poolId[0]);
      try{
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.userInfo(poolId[1], userAddress)._method,[poolId[1], userAddress]));
        callDatas.push(data);
        ouput_format.push(farm_inst.methods.userInfo(poolId[1], userAddress)._method.outputs)
      } catch(error){
        console.log(error);
        return 0;
      }
    })
  }
  
  
  const agregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());

  const do_split = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }  

  for(let i = 0 ; i < agregated_data[1].length ; i++){
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i],agregated_data[1][i]))
  }
  const split_arr = (await do_split(results, pidArr.length));
  sharesWantArr = split_arr[0];
  poolInfoArr = split_arr[1];
  if(userAddress !== undefined){
    userInfoArr = split_arr[2];
  }
  
  // console.log("sharesWantArr", sharesWantArr, poolInfoArr, userInfoArr)
  for (let i = 0; i < pidArr.length; i++) {
    // console.log("lp price for index", i, pidArr[i], lpPriceArray[pidArr[i]], gammaPrice)
    let sharesTotal = sharesWantArr[i][1];
    let wantLockedTotal = sharesWantArr[i][0];
    let userBoostAPR = 0;
    if(!poolInfoArr[i].isBoosted){
      boostArray[pidArr[i]] = {averageBoostApy: 0, userBoostApy: 0};
    }
    let blocksPerYear = 10512000;
    if(!isDrip){
      blocksPerYear = 0;
    }
    let gammaBoostPercentage = poolInfoArr[i].gammaRewardBoostPercentage ? +poolInfoArr[i].gammaRewardBoostPercentage/10000 : 0;
    // let gammaAPRPercentage = poolInfoArr[i].gammaRewardBoostPercentage ? (10000 - +poolInfoArr[i].gammaRewardBoostPercentage)/10000 : 1;
    
    if(totalAllocPoint > 0){
      let gammaBoostReward = (gammaBoostPercentage * blocksPerYear * farmV2DripRate/ 1e18 * poolInfoArr[i].allocPoint)/totalAllocPoint;
      // let gammaReward = (gammaAPRPercentage * blocksPerYear * farmV2DripRate/ 1e18 * poolInfoArr[i].allocPoint)/totalAllocPoint;
      let averageBoostAPR = +wantLockedTotal > 0 ? (100 * gammaBoostReward * 1e18 * gammaPrice )/(wantLockedTotal * lpPriceArray[pidArr[i]]) : 0;
      // averageBoostAPR = (averageBoostAPR * gammaPrice * 100) / (1e18 * lpPriceArray[pidArr[i]])
      // let gammaAPR = +wantLockedTotal > 0 ? (gammaReward * +gammaPrice)/(wantLockedTotal) : 0;
      // gammaAPR = (gammaAPR * gammaPrice * 100) / (1e18 * lpPriceArray[pidArr[i]]);
      // console.log("averageBoostAPR", averageBoostAPR)
      if(userAddress !== undefined) {
        userBoostAPR = +sharesTotal > 0 && +wantLockedTotal > 0 && +userInfoArr[i].shares > 0 ? (100 * gammaBoostReward * +gammaPrice * 1e18 * (userInfoArr[i].factor/poolInfoArr[i].totalFactor))/(userInfoArr[i].shares * wantLockedTotal * lpPriceArray[pidArr[i]] / sharesTotal) : 0;
      }
      
      // userBoostAPR = (userBoostAPR * 1e18) / (lpPriceArray[pidArr[i]]);
      // console.log("averageBoostAPR in pools", averageBoostAPR, userBoostAPR, gammaBoostReward, wantLockedTotal, userInfoArr[i], poolInfoArr[i], sharesTotal, lpPriceArray[pidArr[i]], gammaPrice,  i)
      let userBoostApy = (Math.pow((1 + (userBoostAPR)/36500), 365) - 1) * 100;
      let averageBoostApy = (Math.pow((1 + (averageBoostAPR)/36500), 365) - 1) * 100;//(Math.pow((1 + (+averageBoostAPR)/36500), 365) - 1) * 100;
      
      boostArray[pidArr[i]] = {userBoostApy, averageBoostApy, gammaBoostPercent: gammaBoostPercentage * 100};
    } else {
      boostArray[pidArr[i]] = {averageBoostApy: 0, userBoostApy: 0, gammaBoostPercent: 0};
    }
    
  }
  // console.log("boost array data", boostArray)
  return boostArray;
}

export const getUserAndAverageBoostForMarkets = async(userAddress: string, pfTokenList: any, priceArray: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  if (pfTokenList.length === 0) {
    return 0
  }
  let userBoostAPRArray: any = [];
  let revisedArray = [];
  //const gtoken_inst = new wallet.web3.eth.Contract(pancakeLPabi, gToken)
  try {
    for (let i = 0; i < pfTokenList.length; i++) {
      const gToken: any = pfTokenList[i].address;
      if(gToken.toLowerCase() != gGamma.toLowerCase() && gToken.toLowerCase() != gAqua.toLowerCase() && gToken.toLowerCase() != "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442".toLowerCase() && gToken.toLowerCase() != "0xb7eD4A5AF620B52022fb26035C565277035d4FD7".toLowerCase()){
        const gToken_inst: any = new wallet.web3.eth.Contract(gTokenAbi, gToken);
        targets.push(gToken)
        // console.log("gToken value", gToken)
        const data = wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.getUserAndAverageBoost(userAddress)._method, [userAddress])
        callDatas.push(data)
        ouput_format.push(gToken_inst.methods.getUserAndAverageBoost(userAddress)._method.outputs)
        revisedArray.push(pfTokenList[i]);
      } else {
        continue;
      }
      
    }
    // console.log("targets, callDatas", targets, callDatas)
    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()
    // console.log("aggregated_data", aggregated_data)
    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = await do_split(results, revisedArray.length)
    console.log(split_arr)
    if (split_arr.length > 0 && split_arr[0].length > 0) {
      for (let i = 0; i < split_arr[0].length; i++) {
        const gToken: any = revisedArray[i].address;
        let userBoostApy: any = 0;
        let avgBoostApy: any = 0;
        
        let userBoostAPR = (+split_arr[0][i][0] * +priceArray[gGamma.toLowerCase()] * 100)/(+priceArray[gToken.toLowerCase()]);
        let avgBoostAPR = (+split_arr[0][i][1] * +priceArray[gGamma.toLowerCase()] * 100)/(+priceArray[gToken.toLowerCase()]);
        // console.log("price array", gToken, priceArray[gGamma.toLowerCase()], priceArray[gToken.toLowerCase()], userBoostAPR, avgBoostAPR);
        if(split_arr[0][i][0] > 0){
          userBoostApy = Math.pow((1 + (+userBoostAPR)/365), 365) - 1;
        }
        if(split_arr[0][i][1] > 0){
          avgBoostApy = Math.pow((1 + (+avgBoostAPR)/365), 365) - 1;
        }
        
        userBoostAPRArray[revisedArray[i].address.toLowerCase()] = {userBoostApy, avgBoostApy};
      }
    }
    return userBoostAPRArray;
  } catch (error) {
    console.log('error in getUserAndAverageBoostForMarkets multicall', error)
    return 0
  }

} // boost percentage - gamma boost percentage in gammatroller

export const getUserAndAverageBoostForMarketsAlt = async(userAddress: string, pfTokenList: any, priceArray: any) => {
    const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
   
    let userDataArr: any = [];
    let exchangeRateArr: any = [];
    let gammaSpeedsArr: any = [];
    let gammaBoostPercentageArr: any = [];  
    let totalSupplyArr: any = [];
    let totalFactorArr: any = [];
  
    let targets: any = [];
    let callDatas: any = [];
    let results: any = [];
    let ouput_format : any = [];
    
    pfTokenList.forEach(async(tokenObj: any) => {
      let gToken = tokenObj.address;
      const gToken_inst: any = new wallet.web3.eth.Contract(gTokenAbi, gToken);
      targets.push(gToken);
      try{
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.getUserData(userAddress)._method,[userAddress]));
        callDatas.push(data);
        ouput_format.push(gToken_inst.methods.getUserData(userAddress)._method.outputs)
      } catch(error){
        console.log(error);
        return [0, 0];
      }
    })

    pfTokenList.forEach(async(tokenObj: any) => {
      let gToken = tokenObj.address;
      const gToken_inst: any = new wallet.web3.eth.Contract(gTokenAbi, gToken);
      targets.push(gToken);
      try{
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.exchangeRateStored()._method,[]));
        callDatas.push(data);
        ouput_format.push(gToken_inst.methods.exchangeRateStored()._method.outputs)
      } catch(error){
        console.log(error);
        return [0, 0];
      }
    })

    pfTokenList.forEach(async(tokenObj: any) => {
      let gToken = tokenObj.address;
      const gammatroller_inst: any = new wallet.web3.eth.Contract(gammatrollerAbi, gammatrollerAddress)
      targets.push(gammatrollerAddress);
      try{
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSpeeds(gToken)._method,[gToken]));
        callDatas.push(data);
        ouput_format.push(gammatroller_inst.methods.gammaSpeeds(gToken)._method.outputs)
      } catch(error){
        console.log(error);
        return [0, 0];
      }
    })

    pfTokenList.forEach(async(tokenObj: any) => {
      let gToken = tokenObj.address;
      const gammatroller_inst: any = new wallet.web3.eth.Contract(gammatrollerAbi, gammatrollerAddress)
      targets.push(gammatrollerAddress);
      try{
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.getGammaBoostPercentage(gToken)._method,[gToken]));
        callDatas.push(data);
        ouput_format.push(gammatroller_inst.methods.getGammaBoostPercentage(gToken)._method.outputs)
      } catch(error){
        console.log(error);
        return [0, 0];
      }
    })

    pfTokenList.forEach(async(tokenObj: any) => {
      let gToken = tokenObj.address;
      const gToken_inst: any = new wallet.web3.eth.Contract(gTokenAbi, gToken);
      targets.push(gToken);
      try{
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.totalSupply()._method,[]));
        callDatas.push(data);
        ouput_format.push(gToken_inst.methods.totalSupply()._method.outputs)
      } catch(error){
        console.log(error);
        return [0, 0];
      }
    })

    pfTokenList.forEach(async(tokenObj: any) => {
      let gToken = tokenObj.address;
      const gToken_inst: any = new wallet.web3.eth.Contract(gTokenAbi, gToken);
      targets.push(gToken);
      try{
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.totalFactor()._method,[]));
        callDatas.push(data);
        ouput_format.push(gToken_inst.methods.totalFactor()._method.outputs)
      } catch(error){
        console.log(error);
        return [0, 0];
      }
    })

    const agregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());
    // console.log("agregated_data",agregated_data, targets, callDatas, ouput_format)
    const do_split = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }  

    for(let i = 0 ; i < agregated_data[1].length ; i++){
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i],agregated_data[1][i]))
    }
  
  const split_arr = (await do_split(results, pfTokenList.length));
  // console.log("multicall split data", split_arr, results);
  userDataArr = split_arr[0];
  exchangeRateArr = split_arr[1];
  gammaSpeedsArr = split_arr[2];
  gammaBoostPercentageArr = split_arr[3];
  totalSupplyArr = split_arr[4];
  totalFactorArr = split_arr[5];
  let boostApyArr: any = [];
  for (let i = 0; i < pfTokenList.length; i++) {
    let gToken = pfTokenList[i].address.toLowerCase();
    let accountTokens = userDataArr[i][0];
    let userFactor = userDataArr[i][1];
    let blocksPerYear = 10512000;
    let gammaBoostPerYear = (gammaSpeedsArr[i][0]/1e18 * blocksPerYear * gammaBoostPercentageArr[i][0])/10000;
    // console.log("gammaBoostPerYear for gToken -",pfTokenList[i].name, gToken, gammaBoostPerYear, gammaSpeedsArr[i][0], totalSupplyArr[i][0], exchangeRateArr[i][0], gammaBoostPercentageArr[i][0]);
    if(totalSupplyArr[i][0] == 0 || totalFactorArr[i][0] == 0) {
      boostApyArr[gToken] = {averageBoostApy: 0, userBoostApy: 0};
    } else {
      let averageBoostAPR = (gammaBoostPerYear * 1e36)/(totalSupplyArr[i][0] * exchangeRateArr[i][0]);
      // console.log("averageBoostAPR", gToken, averageBoostAPR);
      averageBoostAPR = ( 100 * averageBoostAPR * +priceArray[gGamma.toLowerCase()])/(+priceArray[gToken]);
      // console.log("averageBoostAPR after", gToken, averageBoostAPR);
      let avgBoostApy = +averageBoostAPR < 300 ? (Math.pow((1 + (+averageBoostAPR)/36500), 365) - 1) * 100 : averageBoostAPR;
      if(accountTokens == 0){
        boostApyArr[gToken] = {averageBoostApy: avgBoostApy, userBoostApy: 0, averageBoostAPR, userBoostAPR: 0 };
      } else {
        let userBoostAPR = (accountTokens*exchangeRateArr[i][0] * priceArray[gToken])/1e36 > 1 || userAddress == undefined ? gammaBoostPerYear*1e36*(userFactor/totalFactorArr[i][0])/(accountTokens*exchangeRateArr[i][0]) : 0;
        userBoostAPR = ( 100 * userBoostAPR * +priceArray[gGamma.toLowerCase()])/(+priceArray[gToken]);
        
        let userBoostApy = +userBoostAPR < 300 ? (Math.pow((1 + (+userBoostAPR)/36500), 365) - 1) * 100 : +userBoostAPR; //((1 + 8.61 / 100 / 365) ** 365 - 1) * 100
        // console.log("userBoostAPR",gToken , userBoostAPR, userBoostApy, userFactor, totalFactorArr[i][0], accountTokens, exchangeRateArr[i][0], priceArray[gGamma.toLowerCase()], priceArray[gToken] )        
        boostApyArr[gToken] = {userBoostApy, averageBoostApy: avgBoostApy, userBoostAPR, averageBoostAPR};
        // boostApyArr[gToken] = {averageBoostApy: avgBoostApy, userBoostApy: userBoostApy};
      }
    }
  }
  // console.log("boostApyArr alt", boostApyArr)
  return boostApyArr;
}

export const boostCalculatorForMarkets = async(userAddress?: string) => {
  // console.log("userAddress", userAddress)
  const pfTokenList: any = Object.values(bluePfTokenListWithoutAquaGamma);
  const market_array = Object.values(bluePfTokenList);
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  let getPoolsAndVaultsDataForPF: any;
  let priceArray: Record<any, any> = {}
  try {
    getPoolsAndVaultsDataForPF = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
  } catch (error) {
    try {
      getPoolsAndVaultsDataForPF = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
    } catch (error) {
      console.log('error in fetching getTotalSupplyApy', error);
    }
  }

  // let getKeys = Object.keys(getPoolsAndVaultsDataForPF);
  if(getPoolsAndVaultsDataForPF !== undefined && getPoolsAndVaultsDataForPF.newVaults !== undefined && getPoolsAndVaultsDataForPF.newVaults.active!== undefined) {
    getPoolsAndVaultsDataForPF.newVaults.active.forEach((element: any, key: any) => {
      let new_key = element.address.toLowerCase();
      priceArray[new_key] = element.price;
    });
  }
 
  let userDataArr: any = [];
  let exchangeRateArr: any = [];
  let gammaSpeedsArr: any = [];
  let gammaBoostPercentageArr: any = [];  
  let totalSupplyArr: any = [];
  let totalFactorArr: any = [];

  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format : any = [];
  
  

  pfTokenList.forEach(async(tokenObj: any) => {
    let gToken = tokenObj.address;
    const gToken_inst: any = new wallet.web3.eth.Contract(gTokenAbi, gToken);
    targets.push(gToken);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.exchangeRateStored()._method,[]));
      callDatas.push(data);
      ouput_format.push(gToken_inst.methods.exchangeRateStored()._method.outputs)
    } catch(error){
      console.log(error);
      return [0, 0];
    }
  })

  pfTokenList.forEach(async(tokenObj: any) => {
    let gToken = tokenObj.address;
    const gammatroller_inst: any = new wallet.web3.eth.Contract(gammatrollerAbi, gammatrollerAddress)
    targets.push(gammatrollerAddress);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSpeeds(gToken)._method,[gToken]));
      callDatas.push(data);
      ouput_format.push(gammatroller_inst.methods.gammaSpeeds(gToken)._method.outputs)
    } catch(error){
      console.log(error);
      return [0, 0];
    }
  })

  pfTokenList.forEach(async(tokenObj: any) => {
    let gToken = tokenObj.address;
    const gammatroller_inst: any = new wallet.web3.eth.Contract(gammatrollerAbi, gammatrollerAddress)
    targets.push(gammatrollerAddress);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.getGammaBoostPercentage(gToken)._method,[gToken]));
      callDatas.push(data);
      ouput_format.push(gammatroller_inst.methods.getGammaBoostPercentage(gToken)._method.outputs)
    } catch(error){
      console.log(error);
      return [0, 0];
    }
  })

  pfTokenList.forEach(async(tokenObj: any) => {
    let gToken = tokenObj.address;
    const gToken_inst: any = new wallet.web3.eth.Contract(gTokenAbi, gToken);
    targets.push(gToken);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.totalSupply()._method,[]));
      callDatas.push(data);
      ouput_format.push(gToken_inst.methods.totalSupply()._method.outputs)
    } catch(error){
      console.log(error);
      return [0, 0];
    }
  })

  pfTokenList.forEach(async(tokenObj: any) => {
    let gToken = tokenObj.address;
    const gToken_inst: any = new wallet.web3.eth.Contract(gTokenAbi, gToken);
    targets.push(gToken);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.totalFactor()._method,[]));
      callDatas.push(data);
      ouput_format.push(gToken_inst.methods.totalFactor()._method.outputs)
    } catch(error){
      console.log(error);
      return [0, 0];
    }
  })

  if(userAddress !== undefined && userAddress !== null){
    // console.log("user Address", userAddress)
    pfTokenList.forEach(async(tokenObj: any) => {
      let gToken = tokenObj.address;
      const gToken_inst: any = new wallet.web3.eth.Contract(gTokenAbi, gToken);
      targets.push(gToken);
      try{
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.getUserData(userAddress)._method,[userAddress]));
        callDatas.push(data);
        ouput_format.push(gToken_inst.methods.getUserData(userAddress)._method.outputs)
      } catch(error){
        console.log(error);
        return [0, 0];
      }
    })
  }

  const agregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());
  // console.log("agregated_data",agregated_data, targets, callDatas, ouput_format)
  const do_split = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }  

  for(let i = 0 ; i < agregated_data[1].length ; i++){
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i],agregated_data[1][i]))
  }

const split_arr = (await do_split(results, pfTokenList.length));
// console.log("multicall split data", split_arr, results);

exchangeRateArr = split_arr[0];
gammaSpeedsArr = split_arr[1];
gammaBoostPercentageArr = split_arr[2];
totalSupplyArr = split_arr[3];
totalFactorArr = split_arr[4];
userDataArr = userAddress ? split_arr[5]: [];
let boostApyArr: any = [];
let iTokenExchangeRate: any;
let gammaGTokenExchangeRate: any;
try{
  let strategy_inst = new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new);
  iTokenExchangeRate = await strategy_inst.methods.iTokenExchangeRate().call()
} catch(error){
  console.log(error);
  iTokenExchangeRate = 1e8;
}
//gamma Gtoken exchange rate
try{
  let gtoken_inst = new wallet.web3.eth.Contract(gBnbAbi, gGamma);
  gammaGTokenExchangeRate = await gtoken_inst.methods.exchangeRateStored().call()
} catch(error){
  console.log(error);
  gammaGTokenExchangeRate = 2e26;
}

for (let i = 0; i < pfTokenList.length; i++) {
  let gToken = pfTokenList[i].address.toLowerCase();
  let accountTokens = userAddress ? userDataArr[i][0] : 0;
  let userFactor = userAddress ? userDataArr[i][1] : 0;
  let blocksPerYear = 10512000;
  let gammaBoostPerYear = (gammaSpeedsArr[i][0]/1e18 * blocksPerYear * gammaBoostPercentageArr[i][0])/10000;
  // console.log("gammaBoostPerYear for gToken -",gToken, gammaBoostPerYear, gammaSpeedsArr[i][0], totalSupplyArr[i][0], exchangeRateArr[i][0], gammaBoostPercentageArr[i][0]);
  if(totalSupplyArr[i][0] == 0 || totalFactorArr[i][0] == 0) {
    boostApyArr[gToken] = {
      averageBoostApy: 0, 
      userBoostApy: 0,
      iTokenExchangeRate,
      gTokenExchangeRate: exchangeRateArr[i][0],
      tokenPrice: +priceArray[gToken],
      gammaPrice: +priceArray[gGamma.toLowerCase()]
    };
  } else {
    let averageBoostAPR = (gammaBoostPerYear * 1e36)/(totalSupplyArr[i][0] * exchangeRateArr[i][0]);
    // console.log("averageBoostAPR", gToken, averageBoostAPR);
    averageBoostAPR = ( 100 * averageBoostAPR * +priceArray[gGamma.toLowerCase()])/(+priceArray[gToken]);
    // console.log("averageBoostAPR after", gToken, averageBoostAPR);
    let avgBoostApy = averageBoostAPR//(Math.pow((1 + (+averageBoostAPR)/36500), 365) - 1) * 100;
    if(accountTokens == 0){
      boostApyArr[gToken] = {
        averageBoostApy: avgBoostApy, 
        userBoostApy: 0,
        iTokenExchangeRate,
        gTokenExchangeRate: exchangeRateArr[i][0],
        gammaSpeeds: (gammaSpeedsArr[i][0]/1e18), 
        gammaBoostPercentage: gammaBoostPercentageArr[i][0]/10000,
        gammaBoostPerYear,
        userFactor: userAddress ? userDataArr[i][1] : 0,
        totalFactor: totalFactorArr[i][0],
        accountTokens: userAddress ? userDataArr[i][0] : 0,
        gammaGTokenExchangeRate,
        tokenPrice: +priceArray[gToken],
        gammaPrice: +priceArray[gGamma.toLowerCase()]
      };
    } else {
      let userBoostAPR = gammaBoostPerYear*1e36*(userFactor/totalFactorArr[i][0])/(accountTokens*exchangeRateArr[i][0]);
      userBoostAPR = ( 100 * userBoostAPR * +priceArray[gGamma.toLowerCase()])/(+priceArray[gToken]);
      
      let userBoostApy = userBoostAPR//(Math.pow((1 + (+userBoostAPR)/36500), 365) - 1) * 100; //((1 + 8.61 / 100 / 365) ** 365 - 1) * 100
      // console.log("userBoostAPR",gToken , userBoostAPR, userBoostApy, userFactor, totalFactorArr[i][0], accountTokens, exchangeRateArr[i][0], priceArray[gGamma.toLowerCase()], priceArray[gToken] )        
      boostApyArr[gToken] = {
        userBoostApy, 
        averageBoostApy: avgBoostApy, 
        gammaSpeeds: (gammaSpeedsArr[i][0]/1e18), 
        gammaBoostPercentage: gammaBoostPercentageArr[i][0]/10000,
        userFactor: userAddress ? userDataArr[i][1] : 0,
        totalFactor: totalFactorArr[i][0],
        accountTokens: userAddress ? userDataArr[i][0] : 0,
        gTokenExchangeRate: exchangeRateArr[i][0],
        gammaGTokenExchangeRate,
        iTokenExchangeRate,
        gammaBoostPerYear,
        tokenPrice: +priceArray[gToken],
        gammaPrice: +priceArray[gGamma.toLowerCase()]
      };
      // boostApyArr[gToken] = {averageBoostApy: avgBoostApy, userBoostApy: userBoostApy};
    }
  }
}
// console.log("boostApyArr alt", boostApyArr)
return boostApyArr;
}

export const  boostCalculatorForLPs = async (userAddress?: string) => {

  let poolIdArr: any = [];
  let farmApyArr: any = [];
  let lpTokenPriceArray: any = [];
  let wantAddressArray: any = [];
  let tradeFeeApyArray: any = [];
  let newPoolData: any;
  try {
    if(Object.keys(planetGlobalObject.poolsInfoV2).length > 0) {
      newPoolData = planetGlobalObject.poolsInfoV2;
    }
    else {
      newPoolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfov2')).data;
    }
  } catch (error) {
    try {
      newPoolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfov2')).data;
    } catch (error) {
      console.log('error in fetching new pool data', error);
    }
  }
  let stratAddressArray: any = [];
  let allocPointArray: any = [];
  for (var key in newPoolData) {
    let key_arr = key.split('_');
    let poolId = newPoolData[key].poolId;
    // console.log("poolId details",poolId, newPoolData[key].strategyAddress, newPoolData[key].farmApyPerYear)
    poolIdArr.push(key_arr[1] + '_' + poolId); //farmAddress_poolId
    stratAddressArray[key_arr[1] + '_' + poolId] = newPoolData[key].strategyAddress;
    farmApyArr[key_arr[1] + '_' + poolId] = newPoolData[key].farmApyPerYear ? newPoolData[key].farmApyPerYear : 0; // gammaApy = farmApyPerYear
    lpTokenPriceArray[key_arr[1] + '_' + poolId] = newPoolData[key].price ? newPoolData[key].price : 0;
    wantAddressArray[key_arr[1] + '_' + poolId] = newPoolData[key].wantAddress.toLowerCase();
    allocPointArray[key_arr[1] + '_' + poolId] = newPoolData[key].poolAllocPoint;
    tradeFeeApyArray[key_arr[1] + '_' + poolId] = newPoolData[key].tradeFeeApy ? newPoolData[key].tradeFeeApy : 0;
  }
  let priceArray = await returnTokenPriceMulticall();
  let aquaPrice = priceArray[aquaAddress.toLowerCase()] ? priceArray[aquaAddress.toLowerCase()] : await getAquaPrice();
  let gammaPrice = priceArray[gammaAddress.toLowerCase()] ? priceArray[gammaAddress.toLowerCase()] : await getGammaPrice();
  let pidArr = poolIdArr;
  let wantAddressArr = wantAddressArray;
  let tradeFeeApy = tradeFeeApyArray;
  let farmApyArray = farmApyArr;
  let lpTokenPriceArr = lpTokenPriceArray;
  let stratAddressArr = stratAddressArray

  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress);
  let sharesArr: any = [];
  let poolInfoArr: any = [];
  let infinityBalanceArr: any = [];
  let gTokenExchangeRateArr: any = [];
  let userInfoArr: any = [];
  let lpBalanceArr: any = [];
  let balanceInLPArr: any = [];

  let targets: any = [];
  let callDatas: any = [];
  let results:any = [];
  let ouput_format : any = [];

  let totalAllocPoint: any = await farm_inst.methods.totalAllocPoint().call();
  let isDrip: any = await farm_inst.methods.isDrip().call()
  let reservoir_inst = new wallet.web3.eth.Contract(reservoir_abi, gamma_reservoir);
  const farmV2DripRate = await reservoir_inst.methods.farmV2DripRate().call()
    
  //shares
  pidArr.forEach(async(pid: any, index: any) => {
    let poolId = pid.split("_");
    const strat_inst: any = poolId[1] == 0 ? new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new) : poolId[1] == 1 ? new wallet.web3.eth.Contract( aqua_strategy_abi, aqua_strategy_address_new) : new wallet.web3.eth.Contract(normal_strategy_abi_new, normal_strategy_address_new);
    const strategyAddress: any = poolId[1] == 0 ? gamma_strategy_address_new : poolId[1] == 1 ?aqua_strategy_address_new :  stratAddressArr[pid];
    // console.log(strategyAddress, poolId)
    targets.push(strategyAddress);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(strat_inst.methods.getShares()._method,[]));
      callDatas.push(data);
      ouput_format.push(strat_inst.methods.getShares()._method.outputs)
    } catch(error){
      console.log(error);
      return [0, 0];
    }
  })

  //pool info
  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_");
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, poolId[0]);
    targets.push(poolId[0]);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.poolInfo(poolId[1])._method,[poolId[1]]));
      callDatas.push(data);
      ouput_format.push(farm_inst.methods.poolInfo(poolId[1])._method.outputs)
    } catch(error){
      console.log(error);
      return 0;
    }
  })
  
  // exchange rate
  pidArr.forEach(async(pid: any) => {
    let poolId = pid.split("_");
    const gToken_inst: any = poolId[1] == 0 ? new wallet.web3.eth.Contract(gTokenAbi, gGamma) : new wallet.web3.eth.Contract(gTokenAbi, gAqua);
    
    const gToken: any = poolId[1] == 0 ? gGamma : gAqua;
    targets.push(gToken);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.exchangeRateStored()._method,[]));
      callDatas.push(data);
      ouput_format.push(gToken_inst.methods.exchangeRateStored()._method.outputs)
    } catch(error){
      console.log(error);
      return 0;
    }
  })

  pidArr.forEach(async(pid:any, index: any) => {
    let tokenInstance: any;
    if (wantAddressArr[pid].toLowerCase() === AQUA_BNBLPAddress.toLowerCase()) {
      tokenInstance =new wallet.web3.eth.Contract(pancakeLPabi, aquaAddress)
      targets.push(aquaAddress)
    } else {
      tokenInstance = new wallet.web3.eth.Contract(pancakeLPabi, gammaAddress)
      targets.push(gammaAddress)
    }
    const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(wantAddressArr[pid])._method, [wantAddressArr[pid]])
    callDatas.push(data)
    ouput_format.push(tokenInstance.methods.balanceOf(wantAddressArr[pid])._method.outputs)
  })

  if(userAddress){
    //balance of
    pidArr.forEach(async(pid: any) => {
      let poolId = pid.split("_");
      const iToken_inst: any = poolId[1] == 0 ? new wallet.web3.eth.Contract(gamma_infinity_vault_abi, gamma_infinity_vault_address) : new wallet.web3.eth.Contract(aqua_infinity_vault_abi, aqua_infinity_vault_address);
      const infinityVault: any = poolId[1] == 0 ? gamma_infinity_vault_address : aqua_infinity_vault_address;
      targets.push(infinityVault);
      try{
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(iToken_inst.methods.balanceOf(userAddress)._method,[userAddress]));
        callDatas.push(data);
        ouput_format.push(iToken_inst.methods.balanceOf(userAddress)._method.outputs)
      } catch(error){
        console.log(error);
        return 0;
      }
    })

    // user info
    pidArr.forEach(async(pid: any) => {
      let poolId = pid.split("_");
      const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, poolId[0]);
      targets.push(poolId[0]);
      try{
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.userInfo(poolId[1], userAddress)._method,[poolId[1], userAddress]));
        callDatas.push(data);
        ouput_format.push(farm_inst.methods.userInfo(poolId[1], userAddress)._method.outputs)
      } catch(error){
        console.log(error);
        return 0;
      }
    })


    //lp balance
    pidArr.forEach(async(pid:any, index: any) => {
      let wantAddress = index > 1 ? wantAddressArr[pid] : wantAddressArr[pidArr[2]];
      const tokenInstance: any = new wallet.web3.eth.Contract(pancakeLPabi, wantAddress)
      targets.push(wantAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(userAddress)._method, [userAddress])
      callDatas.push(data)
      ouput_format.push(tokenInstance.methods.balanceOf(userAddress)._method.outputs)
    })
  }
  
  // console.log(targets.length, callDatas.length, wantAddressArr)
  const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());

  const do_split = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }  
  
  for(let i = 0 ; i < aggregated_data[1].length ; i++){
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i],aggregated_data[1][i]))
  }
  const split_arr = (await do_split(results, pidArr.length));
  // console.log("new pool multicall split data", split_arr);
  sharesArr = split_arr[0];
  // console.log("shares array", sharesArr)
  poolInfoArr = split_arr[1];
  gTokenExchangeRateArr = split_arr[2];
  balanceInLPArr = split_arr[3];

  if(userAddress){
    infinityBalanceArr = split_arr[4];
    userInfoArr = split_arr[5];
    lpBalanceArr = split_arr[6];
  }
  // console.log(lpBalanceArr, balanceInLPArr)
  let boostArray: any = [];
  // let userLPWalletArray: any = [];
  let userSharesArray: any = []

  let userLPArray: any = [];
  let balanceInBlue: any = 0;

  let iTokenExchangeRate: any;
  try{
    let strategy_inst = new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new);
    iTokenExchangeRate = await strategy_inst.methods.iTokenExchangeRate().call()
  } catch(error){
    console.log(error);
    iTokenExchangeRate = 1e8;
  }

  for (let i = 0; i < pidArr.length; i++) {
    let poolId = pidArr[i];
    let sharesTotal = sharesArr[i][1];
    let wantLockedTotal = sharesArr[i][0];
    let userBoostAPR = 0;
    let gTokenExchangeRateForGamma = gTokenExchangeRateArr[0][0];
    let gTokenExchangeRateForAqua = gTokenExchangeRateArr[1][0]
    if(!poolInfoArr[i].isBoosted){
      boostArray[pidArr[i]] = {
        averageBoostApy: 0, 
        userBoostApy: 0,
        price: lpTokenPriceArray[poolId],
        gammaBoostReward: 0,
        sharesTotal,
        wantLockedTotal,
        gammaPrice,
        userFactor: userAddress ? userInfoArr[i].factor: 0,
        totalFactor: poolInfoArr[i].totalFactor,
        gTokenExchangeRateForGamma,
        gTokenExchangeRateForAqua,
        iTokenExchangeRate
      };
    }
    let blocksPerYear = 10512000;
    if(!isDrip){
      blocksPerYear = 0;
    }
    let gammaBoostPercentage = poolInfoArr[i].gammaRewardBoostPercentage ? +poolInfoArr[i].gammaRewardBoostPercentage/10000 : 0;
    
    if(totalAllocPoint > 0){
      let gammaBoostReward = (gammaBoostPercentage * blocksPerYear * farmV2DripRate/ 1e18 * poolInfoArr[i].allocPoint)/totalAllocPoint;

      // console.log("gamma boost reward", poolId, gammaBoostReward, gammaBoostPercentage, blocksPerYear, farmV2DripRate, poolInfoArr[i].allocPoint, totalAllocPoint)

      let averageBoostAPR = +wantLockedTotal > 0 ? (100 * gammaBoostReward * 1e18 * gammaPrice )/(wantLockedTotal * lpTokenPriceArr[pidArr[i]]) : 0;
      
      // console.log("avg boost apr", poolId, averageBoostAPR, gammaBoostReward, gammaPrice, wantLockedTotal, lpTokenPriceArr[pidArr[i]] )
      if(userAddress) {
        userBoostAPR = +sharesTotal > 0 && +wantLockedTotal > 0 && +userInfoArr[i].shares > 0 ? (100 * gammaBoostReward * +gammaPrice * 1e18 * (userInfoArr[i].factor/poolInfoArr[i].totalFactor))/(userInfoArr[i].shares * wantLockedTotal * lpTokenPriceArr[pidArr[i]] / sharesTotal) : 0;
        
      }
      
      let userBoostApy = userBoostAPR//(Math.pow((1 + (userBoostAPR)/36500), 365) - 1) * 100;
      let averageBoostApy = averageBoostAPR//(Math.pow((1 + (averageBoostAPR)/36500), 365) - 1) * 100;
      // console.log("avg boost apy : ", averageBoostApy, "user boost apy : ", userBoostApy, poolId)
      boostArray[pidArr[i]] = {
        userBoostApy, 
        averageBoostApy, 
        gammaBoostPercent: gammaBoostPercentage * 100,
        price: lpTokenPriceArray[poolId],
        gammaBoostReward,
        sharesTotal,
        wantLockedTotal,
        gammaPrice,
        userFactor: userAddress ? userInfoArr[i].factor : 0,
        totalFactor: poolInfoArr[i].totalFactor,
        gTokenExchangeRateForGamma,
        gTokenExchangeRateForAqua,
        iTokenExchangeRate
      };
    } else {
      boostArray[pidArr[i]] = {
        averageBoostApy: 0, 
        userBoostApy: 0, 
        gammaBoostPercent: 0,
        price: lpTokenPriceArray[poolId],
        gammaBoostReward: 0,
        sharesTotal,
        wantLockedTotal,
        gammaPrice,
        userFactor: userAddress ? userInfoArr[i].factor : 0,
        totalFactor: poolInfoArr[i].totalFactor,
        gTokenExchangeRateForGamma,
        gTokenExchangeRateForAqua,
        iTokenExchangeRate
      };
    }
  }

  return boostArray;
      
}

export const gammaBoostPercentForMarkets = async(marketArray: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  if (marketArray.length === 0) {
    return 0
  }
  let gammaBoostArr:any = [];
  try {
    for (let i = 0; i < marketArray.length; i++) {
      const gammatroller_inst: any = await selectInstance(instType.gammatroller, gammatrollerAddress)
      targets.push(gammatrollerAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaBoostPercentage(marketArray[i].address)._method, [marketArray[i].address])
      callDatas.push(data)
      ouput_format.push(gammatroller_inst.methods.gammaBoostPercentage(marketArray[i].address)._method.outputs)
    }

    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }
    
    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = await do_split(results, marketArray.length)
    if (split_arr.length > 0 && split_arr[0].length > 0) {
     
      for (let i = 0; i < marketArray.length; i++){
        let marketAddress = marketArray[i].address.toLowerCase();
        gammaBoostArr[marketAddress] = (split_arr[0][i][0])/ 100;
      }
    }
    return gammaBoostArr;
  } catch (error) {
    console.log('error in gammaBoostPercentForMarkets multicall', error)
    return 0
  }
}

export const allowance = async (tokenAddress: string, owner: string, spender: string) => {
  try {
    // console.log("token address", tokenAddress, "farm address", spender)
    // userAddress, gTokenAddress, underlyingToken
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.allowance(owner, spender).call()
    // console.log(data, "token address", tokenAddress, "farm address", spender)
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const balanceOf = async (tokenAddress: string, userAddress: string) => {
  // let contractAquaBalance = await balanceOf(aquaAddress, poolLpAddress)
  try {
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.balanceOf(userAddress).call()
    // console.log("tokenAddress", tokenAddress, data);
    return data
  } catch (e) {
    console.log(e)
    return 0
  }

}

export const balanceOfUserLPMulticall = async (poolIdArray: string, userAddress: string, wantAddressArray: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  if (poolIdArray.length === 0) {
    return 0
  }

  try {
    const asset_inst = await selectInstance(instType.gToken, gGamma)
    for (let i = 0; i < poolIdArray.length; i++) {
      if (wantAddressArray[poolIdArray[i]] !== undefined) {
        const tokenInstance: any = await selectInstance(instType.PANCAKELP, wantAddressArray[poolIdArray[i]])
        targets.push(wantAddressArray[poolIdArray[i]])
        const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(userAddress)._method, [userAddress])
        callDatas.push(data)
        ouput_format.push(tokenInstance.methods.balanceOf(userAddress)._method.outputs)
      }
    }

    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = await do_split(results, poolIdArray.length)
    if (split_arr.length > 0 && split_arr[0].length > 0) {
      for (let i = 0; i < split_arr[0].length; i++) {
        //farmaddress_wantaddress
        let farmAddress = poolIdArray[i].split("_")[0];
        let walletId = wantAddressArray[poolIdArray[i]] + "_" + farmAddress;
        walletId = walletId.toLowerCase();
          [walletId] = convertToEther(split_arr[0][i][0])
        userLPWalletArray[wantAddressArray[poolIdArray[i]].toLowerCase()] = convertToEther(split_arr[0][i][0])
      }
    }
  } catch (error) {
    console.log('error in getUserSharesFromFarm multicall', error)
    return 0
  }
}

export const balanceOfUserNewLPMulticall = async (poolIdArray: string, userAddress: string, wantAddressArray: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  if (poolIdArray.length === 0) {
    return 0
  }

  try {
    const asset_inst = await selectInstance(instType.gToken, gGamma)
    for (let i = 0; i < poolIdArray.length; i++) {
      if (wantAddressArray[poolIdArray[i]] !== undefined) {
        const tokenInstance: any = await selectInstance(instType.PANCAKELP, wantAddressArray[poolIdArray[i]])
        targets.push(wantAddressArray[poolIdArray[i]])
        const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(userAddress)._method, [userAddress])
        callDatas.push(data)
        ouput_format.push(tokenInstance.methods.balanceOf(userAddress)._method.outputs)
      }
    }

    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = await do_split(results, poolIdArray.length)
    if (split_arr.length > 0 && split_arr[0].length > 0) {
      for (let i = 0; i < split_arr[0].length; i++) {
        //farmaddress_wantaddress
        let farmAddress = poolIdArray[i].split("_")[0];
        let walletId = wantAddressArray[poolIdArray[i]] + "_" + farmAddress;
        walletId = walletId.toLowerCase();
        userLPWalletArrayNew[walletId] = convertToEther(split_arr[0][i][0])
        userLPWalletArrayNew[wantAddressArray[poolIdArray[i]].toLowerCase()] = convertToEther(split_arr[0][i][0])
      }
    }
  } catch (error) {
    console.log('error in getUserSharesFromFarm multicall', error)
    return 0
  }
}

export const balanceOfLPMulticall = async (tokenArray: any, marketArray: any, userAddress: string) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  if (tokenArray.length === 0) {
    return 0
  }
  let tokenInstance: any
  try {
    for (let i = 0; i < tokenArray.length; i++) {
      if (tokenArray[i] === AQUA_BNBLPAddress.toLowerCase()) {
        tokenInstance = await selectInstance(instType.PANCAKELP, aquaAddress)
        targets.push(aquaAddress)
      } else {
        tokenInstance = await selectInstance(instType.PANCAKELP, gammaAddress)
        targets.push(gammaAddress)
      }
      const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(tokenArray[i])._method, [tokenArray[i]])
      callDatas.push(data)
      ouput_format.push(tokenInstance.methods.balanceOf(tokenArray[i])._method.outputs)
    }

    for (let i = 0; i < marketArray.length; i++) {
      tokenInstance = await selectInstance(instType.gBNB, marketArray[i])
      // console.log("market address check", marketArray[i])
      targets.push(marketArray[i])

      const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(userAddress)._method, [userAddress])
      callDatas.push(data)
      ouput_format.push(tokenInstance.methods.balanceOf(userAddress)._method.outputs)
    }

    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = await do_split(results, tokenArray.length)
    if (split_arr.length > 0 && split_arr[0].length > 0) {
      // console.log("userLPWalletArray before", split_arr)
      for (let i = 0; i < split_arr[0].length; i++) {
        balanceLPArray[tokenArray[i]] = gConvertToEther(split_arr[0][i][0], 18)
        // balanceLPArray[tokenArray[i].toLowerCase()] = gConvertToEther(split_arr[0][i][0], 18)
        // console.log("balanceLPArray", i, tokenArray[i], balanceLPArray[tokenArray[i]]);
      }
    }

    if (split_arr.length > 0 && split_arr[1].length > 0) {
      console.log("usermarket balance in gamma", split_arr[1])
      for (let i = 0; i < marketArray.length; i++) {
        balanceMarketArray[marketArray[i]] = parseFloat(gConvertToEther(split_arr[1][i][0], 18))
        // console.log("balanceLPArray", i, tokenArray[i], balanceLPArray[tokenArray[i]]);
      }
    }
  } catch (error) {
    console.log('error in getUserSharesFromFarm multicall', error)
    return 0
  }
}

export const balanceOfUserMarketsMulticall = async (marketArray: any, userAddress: string) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  if (marketArray.length === 0) {
    return 0
  }
  // console.log("marketArray", marketArray)
  try {
    for (let i = 0; i < marketArray.length; i++) {
      const tokenInstance: any = await selectInstance(instType.PANCAKELP, marketArray[i].token)
      targets.push(marketArray[i].token)
      const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(userAddress)._method, [userAddress])
      callDatas.push(data)
      ouput_format.push(tokenInstance.methods.balanceOf(userAddress)._method.outputs)
    }

    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }
    
    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    // console.log("result data for allowance", results);
    const split_arr = await do_split(results, marketArray.length)
    // console.log("split_arr data for userMarketWalletArray", split_arr);
    if (split_arr.length > 0 && split_arr[0].length > 0) {
      // split_arr[0].splice(3, 0, split_arr[0][0]);
      // console.log("userMarketWalletArray before", split_arr[0])
      for (let i = 0; i < marketArray.length; i++){
        let marketAddress = marketArray[i].address.toLowerCase();
        userMarketWalletArray[marketAddress] = convertToEther(split_arr[0][i][0])
      }
    }
    //balance in bnb
    userMarketWalletArray['0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29'.toLowerCase()] = convertToEther(await wallet.web3.eth.getBalance(userAddress));
  } catch (error) {
    console.log('error in getUserSharesFromFarm multicall', error)
    return 0
  }
}

const wantLockedTotal = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.wantLockedTotal().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const farmContractAddress = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.farmContractAddress().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const entranceFeeFactor = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.entranceFeeFactor().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const entranceFeeFactorMax = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.entranceFeeFactorMax().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const buyBackRate = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.buyBackRate().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const buyBackRateMax = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.buyBackRateMax().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const pid = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.pid().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getAquaPrice = async () => {
  const poolDetails = await poolInfo(aquaFarmAddress, '13')
  const lpAddress = poolDetails['want']
  const bnbPrice = await getBnbPrice()
  const reserves = await getReserves(lpAddress)
  const reserve0 = reserves['_reserve0']
  const reserve1 = reserves['_reserve1']
  const bnbPerAQUA = reserve1 / reserve0
  const aquafinalPrice = bnbPerAQUA * bnbPrice
  return aquafinalPrice
}

const getPreviousAquaPrice = async () => {
  const poolDetails = await poolInfo(aquaFarmAddress, '0')
  const lpAddress = poolDetails['want']
  const bnbPrice = await getBnbPrice()
  const reserves = await getReserves(lpAddress)
  const reserve0 = reserves['_reserve0']
  const reserve1 = reserves['_reserve1']
  const bnbPerAQUA = reserve1 / reserve0
  const aquafinalPrice = bnbPerAQUA * bnbPrice
  return aquafinalPrice
}

const getBeltTokenPrice = async (tokenAddress: string) => {
  const token = (await axios.get('https://s.belt.fi/info/all.json')).data
  let temp: any[] = token['tokenList']['BSC']
  temp = temp.filter((e) => e.address === tokenAddress)
  if (!!temp && temp.length > 0) {
    return parseFloat(temp[0].price)
  } else {
    return 0
  }
}

export const getTreasuryBalance = async () => {
  // let newFarmData: any = []
  // let balance: any = 0

  // const newFarmPoolLength = await poolLength(gammaFarmAdddress)

  // for (let i = 0; i < newFarmPoolLength; i++) {
  //   const amount = convertToEther(await stakedWantTokens(gammaFarmAdddress, i.toString(), treasuryAddress))
  //   const price = await getNewPoolsTokenAssetPrice(i);
  //   balance += price * parseFloat(amount)
  // }

  // const list = Object.values(pfTokenList)
  // list.forEach(async (element: any) => {
  //   const amount = noExponents(await getSupplyBalance(element.address, treasuryAddress))
  //   const price = convertToEther(await getUnderlyingPrice(element.address))
  //   balance += parseFloat(price) * parseFloat(amount)
  // });

  // const userAquaBal = parseFloat(convertToEther(await balanceOf(aquaAddress, treasuryAddress)));
  // const userGammaBal = parseFloat(convertToEther(await balanceOf(gammaAddress, treasuryAddress)));

  // const aquaPrice = await getAquaPrice();
  // const gammaPrice = await getGammaPrice();
  // const bnbPrice = await getBnbPrice();
  // const busdPrice = await getTokenPrice(busdAddress);
  // const usdcPrice = await getTokenPrice(usdcAddress);

  // const bnbBalance = convertToEther(await wallet.web3.eth.getBalance(treasuryAddress))
  // const busdBalance = convertToEther(await balanceOf(busdAddress,treasuryAddress));
  // const usdcBalance = convertToEther(await balanceOf(usdcAddress,treasuryAddress));

  // balance += (userAquaBal * aquaPrice)
  //         + (userGammaBal * gammaPrice)
  //         + parseFloat(bnbBalance) * bnbPrice
  //         + parseFloat(busdBalance) * busdPrice
  //         + parseFloat(usdcBalance) * usdcPrice

  // return balance;
  return 332360.94
}

const getPoolTokenAssetPrice = async (i: any): Promise<number> => {
  if (i === -1) {
    return getAquaPrice()
  } else if (otherPlanetsPoolsId.includes(i) || aquaPairPoolId.includes(i) || bluePlanetpoolsId.includes(i)) {
    const poolDetails = await poolInfo(aquaFarmAddress, i)
    const lpAddress = poolDetails['want']

    if (tokenList[i][1] === 'AUTOPAIR') {
      const token0Address = await token1(lpAddress)
      const token1Address = await token2(lpAddress)
      const reserves = await getReserves(lpAddress)
      const reserve0 = reserves['_reserve0']
      const reserve1 = reserves['_reserve1']
      const totalLpSupply = await totalSupply(lpAddress)
      const token0price = await getTokenPrice(token0Address)
      const token1price = await getTokenPrice(token1Address)
      const assetPrice = (reserve0 * token0price + reserve1 * token1price) / totalLpSupply
      return assetPrice
    } else if (tokenList[i][1] === '4BELT' || tokenList[i][1] === 'SINGLEBELT') {
      return await getBeltTokenPrice(lpAddress)
    } else if (tokenList[i][1] === 'PCSv2SINGLE') {
      let assetPrice = await getTokenPrice(cakeTokenAddress)
      return assetPrice
    } else if (tokenList[i][1] === 'AQUA') {
      return getAquaPrice()
    } else if (tokenList[i][1] === 'AQUAPAIR') {
      const token0Address = await token1(lpAddress)
      const token1Address = await token2(lpAddress)
      const reserves = await getReserves(lpAddress)
      const reserve0 = reserves['_reserve0']
      const reserve1 = reserves['_reserve1']
      const totalLpSupply = await totalSupply(lpAddress)
      let token0price
      let token1price
      if (i === 13) {
        token0price = await getAquaPrice()
        token1price = await getBnbPrice()
      } else if (i === 0) {
        token0price = await getPreviousAquaPrice()
        token1price = await getBnbPrice()
      } else if (i === 14) {
        token0price = await getTokenPrice(cakeTokenAddress)
        token1price = await getAquaPrice()
      } else if (i === 36) {
        token0price = await getAquaPrice()
        token1price = await getTokenPrice(token1Address)
      } else {
        token0price = await getTokenPrice(token0Address)
        token1price = await getTokenPrice(token1Address)
      }
      const assetPrice = (reserve0 * token0price + reserve1 * token1price) / totalLpSupply
      return assetPrice
    }
  }
  return 0
}

const getNewPoolsTokenAssetPrice = async (i: any): Promise<number> => {
  if (i === -1) {
    return getAquaPrice()
  } else {
    const poolDetails = await poolInfo(gammaFarmAdddress, i)
    const lpAddress = poolDetails['want']
    if (lpAddress.toLowerCase() === cakeTokenAddress.toLowerCase()) return await getTokenPrice(cakeTokenAddress)
    const token0Address = await token1(lpAddress)
    const token1Address = await token2(lpAddress)
    const reserves = await getReserves(lpAddress)
    const reserve0 = reserves['_reserve0']
    const reserve1 = reserves['_reserve1']
    const totalLpSupply = await totalSupply(lpAddress)
    let token0price
    let token1price
    if (i === 0) {
      token0price = await getAquaPrice()
      token1price = await getGammaPrice()
    } else if (i === 1) {
      token0price = await getAquaPrice()
      token1price = await getBnbPrice()
    } else {
      token0price = await getTokenPrice(token0Address)
      token1price = await getTokenPrice(token1Address)
    }
    const assetPrice = (reserve0 * token0price + reserve1 * token1price) / totalLpSupply
    return assetPrice
  }
}

export const getTokenOneAndTwo = async (i: any) => {
  const poolDetails = await poolInfo(aquaFarmAddress, i)
  const wantAddress = poolDetails['want']
  const token0Address = await token1(wantAddress)
  const token1Address = await token2(wantAddress)
  return [token0Address, token1Address]
}

export const userTokenDetails = async (poolId: number, userAddress: string) => {
  let price: any = 0
  let liquidBalance: any = 0
  let usdLiquidBalance: any = 0
  let currentLpDeposit: any = 0
  let currentBalance: any = 0

  if (userAddress !== null) {
    liquidBalance = await getLPbalance(userAddress, poolId)
    price = await getPoolTokenAssetPrice(poolId)
    price = !!price && parseFloat(price) > 0 ? price : 0
    currentLpDeposit = await getCurrentBalance(poolId, userAddress)
    usdLiquidBalance = !!liquidBalance && parseFloat(liquidBalance) > 0 ? price * parseFloat(liquidBalance) : '0'
    currentBalance = !!currentLpDeposit && parseFloat(currentLpDeposit) > 0 ? price * parseFloat(currentLpDeposit) : '0'
  }
  let lpType = ''
  let temp = [-1, 1, 6, 7, 8, 12]

  if (poolId === 4) lpType = 'BLP'
  else if (!temp.includes(poolId)) lpType = 'LP'

  return {
    price: price,
    lpType: lpType,
    liquidBalance: liquidBalance,
    usdLiquidBalance: usdLiquidBalance,
    currentLpDeposit: currentLpDeposit,
    currentBalance: currentBalance,
  }
}

export const newFarmUserTokenDetails = async (poolId: number, userAddress: string) => {
  let price: any = 0
  let liquidBalance: any = 0
  let usdLiquidBalance: any = 0
  let currentLpDeposit: any = 0
  let currentBalance: any = 0

  if (userAddress !== null) {
    liquidBalance = await getNewFarmLPbalance(userAddress, poolId)
    price = await getNewPoolsTokenAssetPrice(poolId)
    price = !!price && parseFloat(price) > 0 ? price : 0
    currentLpDeposit = await getCurrentBalance(poolId, userAddress, true)
    usdLiquidBalance = !!liquidBalance && parseFloat(liquidBalance) > 0 ? price * parseFloat(liquidBalance) : '0'
    currentBalance = !!currentLpDeposit && parseFloat(currentLpDeposit) > 0 ? price * parseFloat(currentLpDeposit) : '0'
  }
  let lpType = 'LP'

  return {
    price: price,
    lpType: lpType,
    liquidBalance: liquidBalance,
    usdLiquidBalance: usdLiquidBalance,
    currentLpDeposit: currentLpDeposit,
    currentBalance: currentBalance,
  }
}

export const convertToWei = (data: any) => {
  data = noExponents(data)
  let x = new BigNumber(data)
  x = x.multipliedBy(1e18)
  return x.toFixed(0)
}

export const convertToEther = (data: any): any => {
  data = noExponents(data)
  return noExponents(formatUnits(data.toString(), 18))
}

export const gConvertToEther = (data: any, decimals: number): any => {
  data = noExponents(data)
  return noExponents(formatUnits(data.toString(), decimals)) //decimals = 18,8
}

export const handleDepositNew = async (poolId: any, amount: any, userAddress: any, showNotification: any, poolData: any, maxAmtSelected:boolean = false) => {
  if (poolId === -1) {
    await handleAquaAutoCompDeposit(amount, userAddress, showNotification)
  } else {
    if (userAddress) {

      if(poolId == 1 || poolId == 0) {
        const res: any = await deposit_in_infinity_vault_new(userAddress, poolId, amount, maxAmtSelected)
        showNotification('success', res.transactionHash)
      }
      else {
        let farmAddress: any;
        if (poolData.isNewFarm) {
          farmAddress = poolData.farmContractAddress;
        } else {
          farmAddress = poolData.farmContractAddress;
        }
        try {
          const depositAmount = amount
          const aquafarmInstance: any = await selectInstance(instType.AQUAFARM, farmAddress, true)
          const promisify = (inner: any) =>
            new Promise((resolve, reject) =>
              inner((err: any, res: any) => {
                if (err) {
                  reject(err)
                }
                resolve(res)
              })
            )
  
          await promisify((cb: any) => {
            aquafarmInstance.methods
              .deposit(poolId, depositAmount)
              .send({ from: userAddress })
              .once('receipt', function (receipt: any) {
                const type = receipt.status ? 'success' : 'failed'
                showNotification(type, receipt.transactionHash)
                cb()
              })
              .on('error', function (error: any) {
                cb(error)
              })
          })
        } catch (err) {
          showNotification('failed')
          console.log(err)
        }
      }
    }
  }
}

export const handleDepositV3 = async (poolId: any, amount: any, userAddress: any, showNotification: any, poolData: any, maxAmtSelected:boolean = false, time_lock: any) => {
  if (userAddress) {    
    let farmAddress: any = poolData.farmContractAddress;
    try {
      const depositAmount = amount;
      const aquafarmInstance: any = new wallet.web3.eth.Contract(gammaFarmV3Abi, farmAddress)
      let time_lock_index = poolData.name.toLowerCase() == "gamma-btcb" || poolData.name.toLowerCase() == "aqua" ? 0 : time_lock;
      // console.log(userAddress, depositAmount, poolId, time_lock_index, farmAddress)
      const promisify = (inner: any) =>
        new Promise((resolve, reject) =>
          inner((err: any, res: any) => {
            if (err) {
              reject(err)
            }
            resolve(res)
          })
        )
      
      await promisify((cb: any) => {
        aquafarmInstance.methods
          .deposit(userAddress, depositAmount, poolId, time_lock_index)
          .send({ from: userAddress })
          .once('receipt', function (receipt: any) {
            const type = receipt.status ? 'success' : 'failed'
            showNotification(type, receipt.transactionHash)
            cb()
          })
          .on('error', function (error: any) {
            cb(error)
          })
      })
    } catch (err) {
      showNotification('failed')
      console.log(err)
    }
  }
}

export const handleDeposit = async (poolId: any, amount: any, userAddress: any, showNotification: any, poolData: any, maxAmtSelected:boolean = false) => {
  if (poolId === -1) {
    await handleAquaAutoCompDeposit(amount, userAddress, showNotification)
  } else {
    if (userAddress) {

      if(poolId == 1 || poolId == 0) {
        console.log("pool ID", poolId, userAddress, poolId, amount, maxAmtSelected)
        const res: any = await deposit_in_infinity_vault_new(userAddress, poolId, amount, maxAmtSelected)
        showNotification('success', res.transactionHash)
      }
      else {
        let farmAddress: any;
        if (poolData.isNewFarm) {
          farmAddress = poolData.farmContractAddress;
        } else {
          farmAddress = poolData.farmContractAddress;
        }
        try {
          const depositAmount = amount
          const aquafarmInstance: any = await selectInstance(instType.AQUAFARM, farmAddress, true)
          const promisify = (inner: any) =>
            new Promise((resolve, reject) =>
              inner((err: any, res: any) => {
                if (err) {
                  reject(err)
                }
                resolve(res)
              })
            )
  
          await promisify((cb: any) => {
            aquafarmInstance.methods
              .deposit(poolId, convertToWei(depositAmount))
              .send({ from: userAddress })
              .once('receipt', function (receipt: any) {
                const type = receipt.status ? 'success' : 'failed'
                showNotification(type, receipt.transactionHash)
                cb()
              })
              .on('error', function (error: any) {
                cb(error)
              })
          })
        } catch (err) {
          showNotification('failed')
          console.log(err)
        }
      }
    }
  }
}

export const handleWithdraw = async (poolId: any, amount: any, userAddress: any, showNotification: any, poolData: any, maxAmt?: boolean, instantUnstake?: boolean) => {
  // console.log("PoolData", poolData)
  if (poolId === -1) {
    await handleAquaAutoCompWithdraw(amount, userAddress, showNotification)
  }
  else if ((poolId == 0 || poolId == 1) && poolData.farmContractAddress.toLowerCase() == gammaFarmAdddress.toLowerCase()) {
    amount = convertToWei(amount)
    if(instantUnstake != undefined && instantUnstake) {
      try {
        const res: any = await unstake_instantly_new(userAddress, poolId, amount)
        showNotification('success', res.transactionHash)
      }
      catch(error) {
        console.log('instantunstake error', error)
      } 
    }
    else {
      try {
        const res: any = await normal_unstake_new(userAddress, poolId, amount)
        showNotification('success', res.transactionHash)
      }
      catch(error) {
        console.log('normal unstake error', error)
      }
    }
  }
  else {
    if (userAddress) {
      let farmAddress: any

      if (poolData.isNewFarm) {
        farmAddress = poolData.farmContractAddress
      } else {
        farmAddress = poolData.farmContractAddress
      }

      try {
        const withdrawAmount = amount
        const aquafarmInstance: any = poolData.address !== undefined && poolData.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase() ? await selectInstance(instType.CLAIM_CAKE, "0xD3A375Ea1a4224DF396c479839DA13C8C5f02De9") : await selectInstance(instType.AQUAFARM, farmAddress, true)
        const promisify = (inner: any) =>
          new Promise((resolve, reject) =>
            inner((err: any, res: any) => {
              if (err) {
                reject(err)
              }
              resolve(res)
            })
          )
        await promisify((cb: any) => {
          if( poolData.address !== undefined && poolData.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()){
            
            aquafarmInstance.methods
            .withdrawUsersCake()
            .send({ from: userAddress })
            .once('receipt', function (receipt: any) {
              const type = receipt.status ? 'success' : 'failed'
              showNotification(type, receipt.transactionHash)
              cb()
            })
            .on('error', function (error: any) {
              cb(error)
            })
          } else {
            // console.log("withdraw details", poolId, withdrawAmount)
            let withdrawAmt: any = maxAmt ? maxAmount : convertToWei(withdrawAmount);
            aquafarmInstance.methods
            .withdraw(poolId, withdrawAmt)
            .send({ from: userAddress })
            .once('receipt', function (receipt: any) {
              const type = receipt.status ? 'success' : 'failed'
              showNotification(type, receipt.transactionHash)
              cb()
            })
            .on('error', function (error: any) {
              cb(error)
            })
          }
          
        })
        // }
      } catch (err) {
        showNotification('failed')
        console.log(err)
      }
    }
  }
}

export const handleMigrateToV3 = async (poolId: any, amount: any, userAddress: any, showNotification: any, poolData: any, maxAmt?: boolean) => {
  //withdraw from v2 code 
  //const res: any = await normal_unstake_new(userAddress, data.pid, unstaking_amount_without_decimal[0])
  //Approve migrate contract
  //Call migrate function

}

export const handleWithdrawVesting = async ( amount: any, userAddress: any, showNotification: any, vestAmount:any, maxAmt?: boolean, isExitEarly?:boolean ) => {
  if (userAddress) {
    let farmAddress: any = v3FarmAddress;

    try {
      let withdrawAmount:any = 0;

      if(isExitEarly) {
        // exit early from vesting
        if(maxAmt) {
          // max amount selected
          withdrawAmount = vestAmount.totalVestBalanceWei;
          withdrawAmount = (typeof withdrawAmount == "string") ? withdrawAmount : withdrawAmount + '';
        } else {
          // custom input
          // need to be converted to wei
          withdrawAmount = convertToWei(amount);
        }
      }
      else {
        // collect unlocked vesting amount
        withdrawAmount = vestAmount.unlockedVestingBalanceWei;
        withdrawAmount = (typeof withdrawAmount == "string") ? withdrawAmount : withdrawAmount + '';
      }
      const v3farmInstance: any = new wallet.web3.eth.Contract(gammaFarmV3Abi, farmAddress)
      const promisify = (inner: any) =>
        new Promise((resolve, reject) =>
          inner((err: any, res: any) => {
            if (err) {
              reject(err)
            }
            resolve(res)
          })
        )
      await promisify((cb: any) => {
        // console.log("withdraw details", poolId, withdrawAmount)
        v3farmInstance.methods
        .withdrawVesting( withdrawAmount)
        .send({ from: userAddress })
        .once('receipt', function (receipt: any) {
          const type = receipt.status ? 'success' : 'failed'
          showNotification(type, receipt.transactionHash)
          cb()
        })
        .on('error', function (error: any) {
          cb(error)
        })
      })
      // }
    } catch (err) {
      showNotification('failed')
      console.log(err)
    }
  }
}

export const handleWithdrawV3 = async (poolId: any, amount: any, userAddress: any, showNotification: any, maxAmt?: boolean) => {

  if (userAddress) {
    let farmAddress: any = v3FarmAddress;

    try {
      console.log("withdraw amount", amount, maxAmt)
      const withdrawAmount = amount;
      const aquafarmInstance: any = new wallet.web3.eth.Contract(gammaFarmV3Abi, farmAddress)
      const promisify = (inner: any) =>
        new Promise((resolve, reject) =>
          inner((err: any, res: any) => {
            if (err) {
              reject(err)
            }
            resolve(res)
          })
        )
      await promisify((cb: any) => {
        
        let withdrawAmt: any = maxAmt ? maxAmount : convertToWei(withdrawAmount);
        console.log("withdraw details", poolId, withdrawAmt)
        aquafarmInstance.methods
        .withdraw(poolId, withdrawAmt)
        .send({ from: userAddress })
        .once('receipt', function (receipt: any) {
          const type = receipt.status ? 'success' : 'failed'
          showNotification(type, receipt.transactionHash)
          cb()
        })
        .on('error', function (error: any) {
          cb(error)
        })
      })
      // }
    } catch (err) {
      showNotification('failed')
      console.log(err)
    }
  }
}

export const handleWithdrawLp = async (poolId: any, amount: any, userAddress: any, showNotification: any, poolData: any, maxAmt?: boolean) => {
  // console.log("PoolData in handleWithdrawLp", poolData, amount)

  if (userAddress) {
    let farmAddress: any

    if (poolData.isNewFarm) {
      farmAddress = poolData.farmContractAddress
    } else {
      farmAddress = poolData.farmContractAddress
    }
    
    try {
      const withdrawAmount = amount
      const aquafarmInstance: any = await selectInstance(instType.AQUAFARM, farmAddress, true)
      let withdrawAmt: any = maxAmt ? maxAmount : convertToWei(withdrawAmount);
      
      return new Promise((resolve, reject) => {
        aquafarmInstance.methods
          .withdraw(poolId, withdrawAmt)
          .send({ from: userAddress })
          .on('confirmation', (confNumber: any, receipt: any) => {
            if (confNumber === 5) {
              resolve("success") 
            }
          })
          .on('error', (error:any) => {
            reject(error)
          })
      })
    } catch (err) {
      showNotification('failed')
      console.log(err)
      return 'failed'
    }
  }
}

export const handleWithdrawLpV3 = async (poolId: any, amount: any, userAddress: any, showNotification: any, poolData: any, maxAmt?: boolean) => {
  // console.log("PoolData in handleWithdrawLp", poolData, amount)

  if (userAddress) {
    let farmAddress: any

    if (poolData.isNewFarm) {
      farmAddress = poolData.farmContractAddress
    } else {
      farmAddress = poolData.farmContractAddress
    }
    
    try {
      const withdrawAmount = amount
      const aquafarmInstance: any = await selectInstance(instType.AQUAFARM, farmAddress, true)
      let withdrawAmt: any = maxAmt ? maxAmount : convertToWei(withdrawAmount);
      // console.log("withdraw amount", withdrawAmt, poolId, farmAddress)
      return new Promise((resolve, reject) => {
        aquafarmInstance.methods
          .withdraw(poolId, withdrawAmt)
          .send({ from: userAddress })
          .on('confirmation', (confNumber: any, receipt: any) => {
            if (confNumber === 5) {
              resolve("success") 
            }
          })
          .on('error', (error:any) => {
            reject(error)
          })
      })
    } catch (err) {
      showNotification('failed')
      console.log(err)
      return 'failed'
    }
  }
}

/*
 * EARN EXTRA AQUA PROFITS
 */
export const earnAquaProfits = async (poolId: any, userAddress: any, showNotification: any, poolData: any) => {
  if (!!userAddress) {
    try {
      let farmAddress: any

      if (poolData.isNewFarm) {
        farmAddress = gammaFarmAdddress
      } else {
        farmAddress = aquaFarmAddress
      }
      const aquafarmInstance: any = await selectInstance(instType.AQUAFARM, farmAddress, true)
      const poolDetails = await aquafarmInstance.methods.poolInfo(poolId).call()
      const strategyAddress = poolDetails['strat']
      const strategyInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress, true)
      const promisify = (inner: any) =>
        new Promise((resolve, reject) =>
          inner((err: any, res: any) => {
            if (err) {
              reject(err)
            }
            resolve(res)
          })
        )
      await promisify((cb: any) => {
        strategyInstance.methods
          .earnGammaProfits()
          .send({ from: userAddress })
          .once('receipt', function (receipt: any) {
            const type = receipt.status ? 'success' : 'failed'
            showNotification(type, receipt.transactionHash)
            cb()
          })
          .on('error', function (error: any) {
            cb(error)
          })
      })
      // }
    } catch (err) {
      showNotification('failed')
      console.log(err)
    }
  }
}

const getFinalBalance = (val: any) => {
  const newVal = new BigNumber(val).toString()
  return newVal
}

const getLPbalance = async (currentUserAddress: any, poolId: any) => {
  try {
    if (currentUserAddress) {
      if (poolId === -1) {
        const balanceVal = await balanceOf(aquaAddress, currentUserAddress)
        let x = new BigNumber(balanceVal)
        return getFinalBalance(x.div(1e18).toString())
      } else {
        const poolDetails = await poolInfo(aquaFarmAddress, poolId)
        const lpAddress = poolDetails['want']
        const balanceVal = await balanceOf(lpAddress, currentUserAddress)
        let x = new BigNumber(balanceVal)
        const balance = x.div(1e18).toString()
        const finalBalance = getFinalBalance(balance)
        return finalBalance
      }
    } else {
      return 0
    }
  } catch (err) {
    console.log(err)
  }
}

const getNewFarmLPbalance = async (currentUserAddress: any, poolId: any) => {
  try {
    if (currentUserAddress) {
      if (poolId === -1) {
        const balanceVal = await balanceOf(aquaAddress, currentUserAddress)
        let x = new BigNumber(balanceVal)
        return getFinalBalance(x.div(1e18).toString())
      } else {
        const poolDetails = await poolInfo(gammaFarmAdddress, poolId)
        const lpAddress = poolDetails['want']
        const balanceVal = await balanceOf(lpAddress, currentUserAddress)
        let x = new BigNumber(balanceVal)
        const balance = x.div(1e18).toString()
        const finalBalance = getFinalBalance(balance)
        return finalBalance
      }
    } else {
      return 0
    }
  } catch (err) {
    console.log(err)
  }
}

const getCurrentBalance = async (aquaPoolId: any, userAddress: any, newFarm?: boolean) => {
  if (userAddress) {
    let farmAddress
    if (newFarm === undefined || newFarm === false) {
      farmAddress = aquaFarmAddress
    } else farmAddress = gammaFarmAdddress
    if (aquaPoolId !== -1) {
      const result: any = convertToEther(await stakedWantTokens(farmAddress, aquaPoolId, userAddress));
      // console.log("stakedWantTokens inside getCurrentBalance ", result);
      return result
    } else {
      const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress)
      const aquaBalance = convertToEther(await poolInstance.methods.balanceOf().call())
      const totalShares = convertToEther(await poolInstance.methods.totalShares().call())
      const userDetails = await poolInstance.methods.userInfo(userAddress).call()
      const userShares = convertToEther(userDetails['shares'])
      return (userShares / totalShares) * aquaBalance
    }
  } else {
    return 0
  }
}

export const getTokenYield = async (tokenYield: any, n: number) => {
  const apy = (Math.pow(1 + ((tokenYield / 100) * n) / 365 / n, n) - 1) * 100
  return apy
}

const getAutoAquaTokenYield = async (tokenYield: any, n: number) => {
  const apy = ((1 + tokenYield / 100 / n) ** n - 1) * 100
  return apy
}

const getApyData = async () => {
  let apyData: any = {}
  try {
    const res: any = await axios.get(planetFinanceApiBaseUrl+'v1/markets/gettotalApr')
    if (!!res && typeof res !== 'undefined' && res.data) {
      apyData = res.data
    }
  } catch (error) {
    console.log('error=>', error)
  } finally {
    return apyData
  }
}

const getUserLpStatus = async (userAddress: any, poolId: any) => {
  try {
    var obj: any = {}

    let tokenName = null
    let poolDetails = await poolInfo(aquaFarmAddress, poolId)
    let tvl: number = 0
    let aquaPerBlock: any
    let tokenYield: any
    let tokenAPR: any
    let tokenYieldPerDay: any
    let tokenType: any
    let farmName: any
    let parentFarmApy: number = 0
    let getLpTokenLink: any
    let assetPrice: number = 0
    let tokenTypeBoolean: boolean = true
    let poolTokenInstance: any
    let currentBalance: any
    let LPbalance: any
    let depositFee: number = 0
    let autoCompoundFee: number = 0
    let platformFee: number = 0
    let buyBackFee: number = 0
    let orgTvl: any
    let farmApr: any = 0

    const wantAddress = poolDetails['want']
    const strategyAddress = poolDetails['strat']
    const poolAllocPoint = poolDetails['allocPoint']

    if (tokenList[poolId][1] === '4BELT' && poolId === 47) {
      let APR: any = 0

      tokenName = await getName(wantAddress, false)
      tokenType = 'BLP'
      farmName = 'BELT'

      tokenTypeBoolean = true
      getLpTokenLink = 'https://belt.fi/'

      const autoPoolId = await pid(strategyAddress)

      tvl = await wantLockedTotal(strategyAddress)
      const token = (await axios.get('https://s.belt.fi/info/all.json')).data

      assetPrice = await getPoolTokenAssetPrice(poolId)

      const temp1: any[] = token['info']['BSC']['vaultPools']
      temp1.filter((e) => {
        if (autoPoolId === e.pid) APR = e.totalAPR
      })

      parentFarmApy = (await getAutoAquaTokenYield(APR, 365)) / 100
      tvl = convertToEther(tvl) * assetPrice
      orgTvl = tvl
      aquaPerBlock = await AQUAPerBlock()
      aquaPerBlock = convertToEther(aquaPerBlock)

      tokenYield = 0
      tokenYieldPerDay = tokenYield / 365

      tokenAPR = tokenYield
      tokenYield = await getTokenYield(tokenYield, 365)
      let APY: any = tokenYield + parentFarmApy * 100
      let m: any = 365
      let a: any = APY / 100 + 1
      a = (Math.pow(a, 1 / m) - 1) * m

      farmApr = (a * 100) / 365
      if (userAddress) {
        currentBalance = await getCurrentBalance(poolId, userAddress)
        LPbalance = await balanceOf(wantAddress, userAddress)
      }
      depositFee =
        ((parseFloat(await entranceFeeFactorMax(strategyAddress)) - parseFloat(await entranceFeeFactor(strategyAddress))) /
          parseFloat(await entranceFeeFactorMax(strategyAddress))) *
        100
      autoCompoundFee = 0.5
      platformFee = 1
      buyBackFee = (parseFloat(await buyBackRate(strategyAddress)) / parseFloat(await buyBackRateMax(strategyAddress))) * 100
    } else if (tokenList[poolId][1] === 'AQUAPAIR') {
      tokenName = await getName(wantAddress, true)

      const token0Address = await token1(wantAddress)
      const token1Address = await token2(wantAddress)
      tokenType = 'LP'

      if (token0Address !== bnbAddress && token1Address !== bnbAddress)
        getLpTokenLink = `https://exchange.pancakeswap.finance/#/add/${token0Address}/${token1Address}`
      else if (token0Address === bnbAddress) getLpTokenLink = `https://exchange.pancakeswap.finance/#/add/BNB/${token1Address}`
      else getLpTokenLink = `https://exchange.pancakeswap.finance/#/add/${token0Address}/BNB`

      tvl = await wantLockedTotal(strategyAddress)
      aquaPerBlock = await AQUAPerBlock()
      aquaPerBlock = convertToEther(aquaPerBlock)

      let reserves = await getReserves(wantAddress)
      let reserve0 = parseFloat(reserves['_reserve0'])
      let reserve1 = parseFloat(reserves['_reserve1'])
      let totalLpSupply = await totalSupply(wantAddress)
      let token0price: any
      let token1price: any

      if (poolId === 13) {
        //new aqua-bnb
        farmName = 'AQUA'
        token0price = await getAquaPrice()
        token1price = await getBnbPrice()
      } else if (poolId === 0) {
        //previous aqua-bnb
        farmName = 'AQUA'
        token0price = await getPreviousAquaPrice()
        token1price = await getBnbPrice()
      } else if (poolId === 14) {
        //cake-aqua
        farmName = 'AQUA'
        token0price = await getTokenPrice(cakeTokenAddress)
        token1price = await getAquaPrice()
      } else if (poolId === 36) {
        farmName = 'AQUA'
        token0price = await getAquaPrice()
        token1price = await getTokenPrice(token1Address)
      } else if (aquaPairPoolId.includes(poolId)) {
        farmName = 'AQUA'
        token0price = await getTokenPrice(token0Address)
        token1price = await getTokenPrice(token1Address)
      } else {
        farmName = 'PANCAKE SWAP'
        token0price = await getTokenPrice(token0Address)
        token1price = await getTokenPrice(token1Address)
      }
      assetPrice = (reserve0 * token0price + reserve1 * token1price) / totalLpSupply

      tokenTypeBoolean = true

      tokenTypeBoolean = true
      tvl = convertToEther(tvl) * assetPrice
      orgTvl = tvl
      tokenYield = 0
      tokenYieldPerDay = tokenYield / 365
      farmApr = tokenYieldPerDay
      tokenAPR = tokenYield
      tokenYield = await getTokenYield(tokenYield, 365)

      if (userAddress) {
        currentBalance = await getCurrentBalance(poolId, userAddress)
        LPbalance = await getLPbalance(userAddress, poolId)
      }

      if (!aquaPairPoolId.includes(poolId)) {
        /*****************************************************************************/
        let APR: any
        const cakePrice = await getTokenPrice(cakeTokenAddress)
        const pId = await pid(strategyAddress)
        const instPcsFarm: any = await selectInstance(instType.MASTERCHEF, '0x73feaa1eE314F8c655E354234017bE2193C9E24E')
        const pcsPoolInfo = await instPcsFarm.methods.poolInfo(pId).call()
        const alloc = pcsPoolInfo['allocPoint']
        const totalAlloc = await instPcsFarm.methods.totalAllocPoint().call()
        let balance = await balanceOf(wantAddress, '0x73feaa1eE314F8c655E354234017bE2193C9E24E')
        balance = convertToEther(balance)
        APR = ((40 * 28800 * (alloc / totalAlloc) * cakePrice) / (balance * assetPrice)) * 365
        parentFarmApy = Math.pow(1 + APR / 730, 730) - 1
        let APY: any = tokenYield + parentFarmApy * 100
        let m: any = 365
        let a: any = APY / 100 + 1
        a = (Math.pow(a, 1 / m) - 1) * m
        farmApr = (a * 100) / 365

        /*****************************************************************************/
      }

      depositFee =
        ((parseFloat(await entranceFeeFactorMax(strategyAddress)) - parseFloat(await entranceFeeFactor(strategyAddress))) /
          parseFloat(await entranceFeeFactorMax(strategyAddress))) *
        100
      autoCompoundFee = 0
      platformFee = 0
      buyBackFee = (parseFloat(await buyBackRate(strategyAddress)) / parseFloat(await buyBackRateMax(strategyAddress))) * 100
    } else if (tokenList[poolId][1] === 'AQUA') {
      tokenName = await getName(wantAddress, false)
      tokenType = ''
      farmName = 'AQUA'

      getLpTokenLink = null

      tvl = await wantLockedTotal(strategyAddress)
      aquaPerBlock = await AQUAPerBlock()
      assetPrice = await getAquaPrice()
      tokenTypeBoolean = false
      tvl = convertToEther(tvl) * assetPrice
      orgTvl = tvl
      aquaPerBlock = convertToEther(aquaPerBlock)
      tokenYield = 0
      tokenYieldPerDay = tokenYield / 365
      farmApr = tokenYieldPerDay
      tokenAPR = tokenYield
      tokenYield = await getTokenYield(tokenYield, 365)
      if (userAddress) {
        currentBalance = await getCurrentBalance(poolId, userAddress)
        LPbalance = await getLPbalance(userAddress, poolId)
      }
      depositFee =
        ((parseFloat(await entranceFeeFactorMax(strategyAddress)) - parseFloat(await entranceFeeFactor(strategyAddress))) /
          parseFloat(await entranceFeeFactorMax(strategyAddress))) *
        100
      autoCompoundFee = 0
      platformFee = 0
      buyBackFee = (parseFloat(await buyBackRate(strategyAddress)) / parseFloat(await buyBackRateMax(strategyAddress))) * 100
    }
    // console.log(poolId,assetPrice)
    //console.log(await returnApy(12))
    if (userAddress != null) {
      obj['tokenNameGetLP'] = tokenName
      obj['id'] = poolId
      obj['liquidBalance'] = LPbalance
      obj['usdLiquidBAlance'] = parseFloat(LPbalance) * assetPrice
      obj['currentLpDeposit'] = currentBalance
      obj['pendingAquaEarnings'] = await getPendingAquaClaim(userAddress, poolId)
      obj['token'] = tokenList[poolId][0]
      obj['tokenTvl'] = tvl
      obj['tokenYield'] = tokenYield
      obj['dailyPercentage'] = farmName === 'PANCAKE SWAP' ? farmApr : '0'
      obj['totalEarned'] = `${parseFloat(currentBalance).toFixed(4)} ` + tokenType
      obj['lpType'] = tokenType
      obj['currentBalance'] = parseFloat(currentBalance) * assetPrice
      obj['rewardToken'] = await getPendingAquaClaim(userAddress, poolId)
      obj['reward'] = obj['rewardToken'] * (await getAquaPrice())
      obj['assetTokenPrice'] = assetPrice
      obj['farmName'] = farmName
      obj['farmContract'] = `https://bscscan.com/address/${aquaFarmAddress}#code`
      obj['vaultContractt'] = `https://bscscan.com/address/${poolDetails['strat']}#code`
      obj['farmApy'] = parentFarmApy
      obj['optimalCompoundsPerYear'] = '0'
      obj['aquaApr'] = tokenYield
      obj['totalAPY2'] = (tokenYield + parentFarmApy * 100).toFixed(2)
      obj['totalApy'] = farmName === 'PANCAKE SWAP' ? tokenYield + parentFarmApy * 100 : '0'
      obj['getLpToken'] = getLpTokenLink
      obj['tokenType'] = tokenTypeBoolean
      obj['currentPoolTokenApproval'] = await allowance(wantAddress, userAddress, aquaFarmAddress)
      obj['depositFee'] = depositFee
      obj['autoCompoundFee'] = autoCompoundFee
      obj['platformFee'] = platformFee
      obj['buyBackFe e'] = buyBackFee
      obj['tokenAPR'] = tokenAPR
      obj['orgTvl'] = orgTvl
      obj['vaultMultiplier'] = parseFloat(poolDetails['allocPoint']) / 100
    } else {
      obj['tokenNameGetLP'] = tokenName
      obj['id'] = poolId
      obj['usdLiquidBAlance'] = 0
      obj['liquidBalance'] = '0.0000'
      obj['currentLpDeposit'] = '0.0000'
      obj['pendingAquaEarnings'] = '0.0000'
      obj['token'] = tokenList[poolId][0]
      obj['tokenTvl'] = tvl
      obj['tokenYield'] = tokenYield
      obj['lpType'] = tokenType
      obj['dailyPercentage'] = farmName === 'PANCAKE SWAP' ? farmApr : '0'
      obj['currentBalance'] = '0.0000'
      obj['totalEarned'] = '0.0000 ' + tokenType
      obj['reward'] = '0'
      obj['rewardToken'] = '0.0000'
      obj['assetTokenPrice'] = assetPrice
      obj['farmName'] = farmName
      obj['farmContract'] = `https://bscscan.com/address/${aquaFarmAddress}#code`
      obj['vaultContractt'] = `https://bscscan.com/address/${poolDetails['strat']}#code`
      obj['farmApy'] = parentFarmApy
      obj['optimalCompoundsPerYear'] = '0'
      obj['aquaApr'] = tokenYield
      obj['totalAPY2'] = (tokenYield + parentFarmApy * 100).toFixed(2)
      obj['totalApy'] = farmName === 'PANCAKE SWAP' ? tokenYield + parentFarmApy * 100 : '0'
      obj['getLpToken'] = getLpTokenLink
      obj['tokenType'] = tokenTypeBoolean
      obj['currentPoolTokenApproval'] = '0'
      obj['depositFee'] = depositFee
      obj['autoCompoundFee'] = autoCompoundFee
      obj['platformFee'] = platformFee
      obj['buyBackFee'] = buyBackFee
      obj['tokenAPR'] = tokenAPR
      obj['orgTvl'] = orgTvl
      obj['vaultMultiplier'] = parseFloat(poolDetails['allocPoint']) / 100
    }
    return obj
  } catch (err) {
    console.log(err)
  }
}

const getAllSupplyBalance = async (userAddress: any) => {
  // try catch

  const markets_data: any = Object.values(pfTokenList)
  if (markets_data.length === 0) {
    return 0
  }
  // console.log('markets_data', markets_data);
  try {
    let batch1: any
    batch1 = new wallet.web3.BatchRequest()
    return new Promise(async function (resolve: any, reject) {
      for (let i = 0; i < markets_data.length; i++) {
        // console.log('markets_data[i].address', markets_data[i].address);
        const inst: any = new wallet.web3.eth.Contract(gBnbAbi, markets_data[i].address)
        batch1.add(
          inst.methods.getAccountSnapshot(userAddress).call.request({ from: zeroAddress }, (error: any, data: any) => {
            if (error) return reject(error)
            const result = convertToEther(data[1]) * convertToEther(data[3])
            if (result > 0) {
              supplyBalanceArr[markets_data[i].address] = result
            }

            //console.log(data)
            if (i === markets_data.length - 1) resolve()
          })
        )
      }
      batch1.execute()
    })
  } catch (error) {
    console.log('error in getAllSupplyBalance batch', error)
    return 0
  }
}

const getAllSupplyBalanceMulticall = async (userAddress: any) => {
  let multicallArr: any = []
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  const markets_data: any = Object.values(bluePfTokenList)
  if (markets_data.length === 0) {
    return 0
  }

  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  const asset_inst = await selectInstance(instType.gToken, gGamma)

  try {
    for (let i = 0; i < markets_data.length; i++) {
      targets.push(markets_data[i].address)
      const data = wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.getAccountSnapshot(userAddress)._method, [userAddress])
      callDatas.push(data)
      ouput_format.push(asset_inst.methods.getAccountSnapshot(userAddress)._method.outputs)
    }
    const agregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < agregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], agregated_data[1][i]))
    }
    const split_arr = await do_split(results, markets_data.length)
    if (split_arr.length > 0 && split_arr[0].length > 0) {
      // console.log("split_arr", split_arr[0])
      for (let i = 0; i < split_arr[0].length; i++) {
        if (+split_arr[0][i][1] !== 0 && +split_arr[0][i][3] !== 0) {
          markets_data[i].address = markets_data[i].address.toLowerCase();
          const decimal = markets_data[i].decimals//await getUnderlyingDecimal(markets_data[i].address);
          supplyBalanceArr[markets_data[i].address] = parseFloat(gConvertToEther(split_arr[0][i][1],decimal)) * parseFloat(convertToEther(split_arr[0][i][3]))
          // console.log("supplyBalanceArr", supplyBalanceArr[markets_data[i].address])
        }
      }
    }
    // console.log("supplyBalanceArr", supplyBalanceArr)
  } catch (error) {
    console.log(error)
  }
}

const getPendingUranusRewardsMulticall = async (userAddress: string, stratAddressArray: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  try {
    let poolIdArray = Object.keys(userSharesArray)
    if (poolIdArray.length === 0) {
      return 0
    }
    let targets: any = []
    let callDatas: any = []
    let results: any = []
    let ouput_format: any = []
    let userUranusPoolIds: any = []
    
    for (let i = 0; i < poolIdArray.length; i++) {
      let poolId = poolIdArray[i].split('_')[1]
      let farmAdd = poolIdArray[i].split('_')[0];
      if (newUranusPoolIds.includes(+poolId) && farmAdd !== '0x0ac58Fd25f334975b1B61732CF79564b6200A933') {
        userUranusPoolIds.push(poolIdArray[i])
        targets.push(stratAddressArray[poolIdArray[i]])
        let strategyInstance = await selectInstance(instType.STRATEGY_ABI, stratAddressArray[poolIdArray[i]])
        if(+poolId === 62){
          strategyInstance = await selectInstance(instType.NEW_STRATEGY_ABI, stratAddressArray[poolIdArray[i]])
        }
        
        const data = wallet.web3.eth.abi.encodeFunctionCall(strategyInstance.methods.userPendingGammaProfit(userAddress)._method, [
          userAddress,
        ])
        callDatas.push(data)
        ouput_format.push(strategyInstance.methods.userPendingGammaProfit(userAddress)._method.outputs)
      } else {
        continue
      }
    }
    
    const agregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call({from: zeroAddress})
    // console.log("agregated_data", agregated_data)
    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < agregated_data[1].length; i++) {
      // console.log("ouput_format", i, ouput_format[i], agregated_data[1][i]);
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], agregated_data[1][i]))
    }
    const split_arr = await do_split(results, poolIdArray.length)
    // console.log("split_arr in getPendingUranusRewardsMulticall", split_arr[0]);
    if (split_arr.length > 0) {
      for (let i = 0; i < userUranusPoolIds.length; i++) {
        if(split_arr[0][i][1] === undefined)
          pendingUranusRewardsArray[userUranusPoolIds[i]] = convertToEther(split_arr[0][i][0])
        else
          pendingUranusRewardsArray[userUranusPoolIds[i]] = convertToEther(split_arr[0][i][1])
      }
    }
    
  } catch (error) {
    console.log('error in getPendingUranusRewardsMulticall', error)
    return 0
  }
}

const newBoostedVaults = async (strategyAddress: string, userAddress: string) => {
  let profit = 0
  try {
    const strategyInstance = await selectInstance(instType.NEW_STRATEGY_ABI, strategyAddress)
    profit = (await strategyInstance.methods.userPendingGammaProfit(userAddress).call());
    // console.log("profit", profit);
    return profit;
  } catch (error) {
    console.log("error in newBoostedVaults", error);
    profit = 0
    return profit;
  }
}

const getPendingRewardsMulticall = async (userAddress: string) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  try {
    let targets: any = []
    let callDatas: any = []
    let results: any = []
    let ouput_format: any = []
    let poolIdArray = Object.keys(userSharesArray)
    if (poolIdArray.length === 0) {
      return 0
    }
    let newPoolIdArray: any = []
    // console.log(poolIdArray)
    const asset_inst = await selectInstance(instType.gToken, gGamma)
    
    for (let i = 0; i < poolIdArray.length; i++) {
      let poolId = poolIdArray[i].split('_')[1]
      let farmAddress = poolIdArray[i].split('_')[0]
      const farmInstance: any = new wallet.web3.eth.Contract(gammaFarmAbiOld, farmAddress)//new wallet.web3.eth.Contract
      if (farmAddress === gammaFarmAdddressOld) {
        newPoolIdArray.push(poolIdArray[i])
        targets.push(farmAddress)
        // console.log(farmAddress, poolId)
        const data = wallet.web3.eth.abi.encodeFunctionCall(farmInstance.methods.pendingGAMMA(poolId, userAddress)._method, [
          poolId,
          userAddress,
        ])
        callDatas.push(data)
        ouput_format.push(farmInstance.methods.pendingGAMMA(poolId, userAddress)._method.outputs)
      }
    }
    // console.log(targets)
    // console.log(callDatas)
    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    
    const split_arr = await do_split(results, newPoolIdArray.length)
    if (split_arr.length > 0 && split_arr[0].length > 0) {
      for (let i = 0; i < newPoolIdArray.length; i++) {
        userRewardsArray[newPoolIdArray[i]] = convertToEther(split_arr[0][i][0])
      }
    }
  } catch (error) {
    console.log('error in getPendingRewardsMulticall', error)
    return 0
  }
}

const getUserSharesFromFarmMulticall = async (userAddress: string, poolIdArray: any, wantAddressArray: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  if (poolIdArray.length === 0) {
    return 0
  }
  try {
    for (let i = 0; i < poolIdArray.length; i++) {
      let poolId = poolIdArray[i].split('_')[1]
      let farmAddress = poolIdArray[i].split('_')[0]
      // console.log("farmAddress", farmAddress)
      const tokenInstance: any = await selectInstance(instType.AQUAFARM, farmAddress)
      targets.push(farmAddress)
      const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.stakedWantTokens(poolId, userAddress)._method, [
        poolId,
        userAddress,
      ])
      callDatas.push(data)
      ouput_format.push(tokenInstance.methods.stakedWantTokens(poolId, userAddress)._method.outputs)
    }

    for (let i = 0; i < poolIdArray.length; i++) {
      if (wantAddressArray[poolIdArray[i]] !== undefined) {
        let farmAddress = poolIdArray[i].split('_')[0]
        targets.push(wantAddressArray[poolIdArray[i]])
        const tokenInstance: any = await selectInstance(instType.PANCAKELP, wantAddressArray[poolIdArray[i]])
        const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.allowance(userAddress, farmAddress)._method, [
          userAddress,
          farmAddress,
        ])
        callDatas.push(data)
        ouput_format.push(tokenInstance.methods.allowance(userAddress, farmAddress)._method.outputs)
      }
    }

    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()
    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }
    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = await do_split(results, poolIdArray.length)


    
    if (split_arr.length > 0 && split_arr[0].length > 0) {
      for (let i = 0; i < split_arr[0].length; i++) {
        if (split_arr[0][i][0] > 0) {
          
          userSharesArray[poolIdArray[i]] = convertToEther(split_arr[0][i][0])
          // console.log(poolIdArray[i])
        }
      }
    }
    // console.log("userAllowanceArray in LPs before", userAllowanceArray);
    if (split_arr[1] && split_arr[1].length > 0) {
      for (let i = 0; i < split_arr[1].length; i++) {
        let farmAddress = poolIdArray[i].split('_')[0].toLowerCase();
        let pool_id = poolIdArray[i].split('_')[1];
        if(pool_id == 62){
          // console.log("new vault allowance", split_arr[1][i][0])
          userAllowanceArray['0xf18f4770ee76e6459b6b2cecc919ea5d3c58e8d9'] = convertToEther(split_arr[1][i][0]);
        }
        wantAddressArray[poolIdArray[i]] = wantAddressArray[poolIdArray[i]].toLowerCase();
        userAllowanceArray[wantAddressArray[poolIdArray[i]] + '_' + farmAddress] = convertToEther(split_arr[1][i][0])
        //userAllowanceArray[wantAddressArray[poolIdArray[i].toLowerCase()] + '_' + farmAddress.toLowerCase()] = convertToEther(split_arr[1][i][0])
      }
    }
    // console.log("userSharesArray in LPs", userSharesArray);
  } catch (error) {
    console.log('error in getUserSharesFromFarm multicall', error)
    return 0
  }
}

const getAllowanceFromNewLpMulticall = async (userAddress: string, poolIdArray: any, wantAddressArray: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  if (poolIdArray.length === 0) {
    return 0
  }
  try {
    
    for (let i = 0; i < poolIdArray.length; i++) {
      if (wantAddressArray[poolIdArray[i]] !== undefined) {
        let farmAddress = poolIdArray[i].split('_')[0];
        targets.push(wantAddressArray[poolIdArray[i]])
        const tokenInstance: any = await selectInstance(instType.PANCAKELP, wantAddressArray[poolIdArray[i]])
        const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.allowance(userAddress, farmAddress)._method, [
          userAddress,
          farmAddress,
        ])
        callDatas.push(data)
        ouput_format.push(tokenInstance.methods.allowance(userAddress, farmAddress)._method.outputs)
      }
    }

    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()
    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }
    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = await do_split(results, poolIdArray.length)

    // console.log("userAllowanceArray in LPs before", userAllowanceArray);
    if (split_arr[0] && split_arr[0].length > 0) {
      for (let i = 0; i < split_arr[0].length; i++) {
        let farmAddress = poolIdArray[i].split('_')[0].toLowerCase();
        let pool_id = poolIdArray[i].split('_')[1];
        if(pool_id == 62){
          // console.log("new vault allowance", split_arr[1][i][0])
          userAllowanceArray['0xf18f4770ee76e6459b6b2cecc919ea5d3c58e8d9'] = convertToEther(split_arr[0][i][0]);
        }
        wantAddressArray[poolIdArray[i]] = wantAddressArray[poolIdArray[i]].toLowerCase();
        userAllowanceArray[wantAddressArray[poolIdArray[i]] + '_' + farmAddress] = convertToEther(split_arr[0][i][0])
        //userAllowanceArray[wantAddressArray[poolIdArray[i].toLowerCase()] + '_' + farmAddress.toLowerCase()] = convertToEther(split_arr[1][i][0])
      }
    }
    // console.log("userAllowanceArray in new LPs", userAllowanceArray);
  } catch (error) {
    console.log('error in userAllowanceArray multicall', error)
    return 0
  }
}

export const getAllowanceForStablePoolsMulticall = async (userAddress: string, tokenArray: any, poolAddress: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let allowanceArray: any = [];
  if (tokenArray.length === 0) {
    return 0
  }
  try {
    for (let i = 0; i < tokenArray.length; i++) {
      if (tokenArray[i] !== undefined) {
        targets.push(tokenArray[i].tokenAddress)
        const tokenInstance: any = new wallet.web3.eth.Contract(gBnbAbi, tokenArray[i].tokenAddress)
        
        const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.allowance(userAddress, poolAddress)._method, [
          userAddress,
          poolAddress,
        ])
        callDatas.push(data)
        ouput_format.push(tokenInstance.methods.allowance(userAddress, poolAddress)._method.outputs)
      }
    }

    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()
    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }
    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    const split_arr = await do_split(results, tokenArray.length)

    // console.log("array for getAllowanceFromFarmMulticall", split_arr[0]);
    if (split_arr[0] && split_arr[0].length > 0) {
      for (let i = 0; i < split_arr[0].length; i++) {
        // tokenArray[i].tokesn = tokenArray[i].token.toLowerCase();
        allowanceArray[tokenArray[i].tokenAddress.toLowerCase()] = convertToEther(split_arr[0][i][0])
      }
    }
    return allowanceArray;
    // console.log("userAllowanceArray", userAllowanceArray)
  } catch (error) {
    console.log('error in getAllowanceFromFarmMulticall', error)
    return 0
  }
}


const getTotalUnderlyingPriceMulticall = async (addressArr: any, blockNumber?: number) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []

  blockNumber = undefined
  // console.log("address array ",addressArr)
  if (addressArr.length === 0) {
    return 0
  }
  try {
    // const inst: any = await selectInstance(instType.priceOracle, priceOracleAddress)
    for (let i = 0; i < addressArr.length; i++) {
      // const inst: any = await selectInstance(instType.priceOracle, priceOracleAddress)
      const inst: any = addressArr[i].toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() ? await selectInstance(instType.priceOracleOld, priceOracleAddressOld) : await selectInstance(instType.priceOracle, priceOracleAddress)

      let priceOracleAddressVal = addressArr[i].toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() ? priceOracleAddressOld : priceOracleAddress;
      targets.push(priceOracleAddressVal)
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.getUnderlyingPrice(addressArr[i])._method, [addressArr[i]])
      callDatas.push(data)
      ouput_format.push(inst.methods.getUnderlyingPrice(addressArr[i])._method.outputs)
    }
    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    // const split_arr = await do_split(results, addressArr.length)
    // if (split_arr[0].length > 0) {
    //   for (let i = 0; i < split_arr[0].length; i++) {
    //     addressArr[i] = addressArr[i].toLowerCase();
        
    //     const decimal = 36 - (await getUnderlyingDecimal(addressArr[i]));
    //     underLyingPriceDataArray[addressArr[i]] = parseFloat(gConvertToEther(split_arr[0][i][0], decimal));
    //     //console.log(underLyingPriceDataArray[addressArr[i]])
    //   }
    // }
    
    let decimal = 18;
    const split_arr = await do_split(results, addressArr.length)
    if (split_arr.length > 0 && split_arr[0].length > 0) {
      // console.log("decimal for the address ", split_arr[0]);
      for (let i = 0; i < split_arr[0].length; i++) {
        
        addressArr[i] = addressArr[i].toLowerCase();
        if(addressArr[i] === "0x88FD42E447d39C3259b53623f2536bd855e47C48".toLowerCase() || addressArr[i] === "0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4".toLowerCase() || addressArr[i] === '0xAF7602Fb6A83f04886032467B487101B80ebccC0'.toLowerCase()){
          decimal = 30;
        }
        underLyingPriceDataArray[addressArr[i]] = parseFloat(gConvertToEther(split_arr[0][i][0], decimal));
        // console.log("decimal for the address ", addressArr[i], decimal);
        decimal = 18;
      }
    }
    // console.log("underLyingPriceDataArray",underLyingPriceDataArray);
  } catch (error) {
    console.log('error', error)
  }
}

const totalSupplyMulticall = async (tokenAddressArray: any) => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  // console.log(tokenAddressArray);
  try {
    for (let i = 0; i < tokenAddressArray.length; i++) {
      // console.log(tokenAddressArray[i]);
      const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddressArray[i])
      targets.push(tokenAddressArray[i])
      const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.totalSupply()._method, [])
      callDatas.push(data)
      ouput_format.push(tokenInstance.methods.totalSupply()._method.outputs)
    }
    const aggregated_data = await multicall_inst.methods.aggregate(targets, callDatas).call()

    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = await do_split(results, tokenAddressArray.length)
    // console.log("total supply split arr", split_arr)
    if (split_arr.length > 0 && split_arr[0].length > 0) {
      for (let i = 0; i < split_arr[0].length; i++) {
        // totalSupplyArray[tokenAddressArray[i]] = parseFloat(convertToEther(split_arr[0][i][0]));
        totalSupplyArray[tokenAddressArray[i]] = gConvertToEther(split_arr[0][i][0], 18)
        // console.log("totalSupplyArray",totalSupplyArray[tokenAddressArray[i]])
      }
    }
  } catch (error) {
    console.log(error)
    //return 0
  }
}

export const fetchNewVaultDetails = async (userAddress: any, market_array?: any, getTotalSupplyApy?: any, getTotalGammaSupplyApr?: any, gammaPrice?: any, userLevel?: any, balance_in_vaults?: any, supplyAprArray?: any, supplyApyArray?: any, infinity_balance_arr? : any) => {

  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress);
  const asset_inst: any = new wallet.web3.eth.Contract(gTokenAbi, gGamma);
  const token_inst: any = new wallet.web3.eth.Contract(gBnbAbi, gammaAddress);
  const new_markets_without_gammaAqua: any = Object.values(bluePfTokenListWithoutAquaGamma);
  const gammatroller_inst: any = new wallet.web3.eth.Contract(gammatrollerAbi, gammatrollerAddress);
  const gammatroller_inst_old: any = new wallet.web3.eth.Contract(gammatrollerAbiOld, gammatrollerAddressOld);
  const old_priceoracle_inst: any = new wallet.web3.eth.Contract(priceOracleAbiOld, priceOracleAddressOld);
  const priceoracle_inst: any = new wallet.web3.eth.Contract(priceOracleAbi, priceOracleAddress);
  
  let sharesArr: any = [];
  let tokenBalance: any = [];
  let allowanceArr: any = [];
  let tokenPriceArray: any = [];
  let gammaBoostArr: any = [];
  let gammaSpeedsArr: any = [];
  let totalBorrowsArr: any = [];
  let borrowRatePerBlockArr: any = [];
  let isDeprecatedArr: any = [];
  let borrowBalanceArray: any = [];

  let targets: any = [];
  let callDatas: any = [];
  let results:any = [];
  let ouput_format : any = [];

  if(market_array == undefined){
    balance_in_vaults = 0;
    market_array = Object.values(bluePfTokenList);
    userLevel = await getDiscountLevel(userAddress);

    // let getTotalSupplyApy: any = {};
    try {
      if(Object.keys(planetGlobalObject.poolTotalSupplyApy)) {
        getTotalSupplyApy = planetGlobalObject.poolTotalSupplyApy;
      }
      else {
        getTotalSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getsupplyapy')).data;
      }
      
    } catch (error) {
      try {
        getTotalSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getsupplyapy')).data;
      } catch (error) {
        console.log('error in fetching getTotalSupplyApr', error);
      }
    }

    let getTotalSupplyAprKeys = Object.keys(getTotalSupplyApy);
    getTotalSupplyAprKeys.forEach((element: any, key: any) => {
      let new_key = element.toLowerCase();
      getTotalSupplyApy[new_key] = getTotalSupplyApy[element];
    });

    let getTotalGammaSupplyApy: any = {};
    try {
      if(Object.keys(planetGlobalObject.poolGammaSupplyApr).length > 0) {
        getTotalGammaSupplyApy = planetGlobalObject.poolGammaSupplyApr;
      }
      else {
        getTotalGammaSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApr')).data;
      }
    } catch (error) {
      try {
        getTotalGammaSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApr')).data;
      } catch (error) {
        console.log('error in fetching getTotalSupplyApr', error);
      }
    }
    let getTotalGammaSupplyApyKeys = Object.keys(getTotalGammaSupplyApy);
    getTotalGammaSupplyApyKeys.forEach((element: any, key: any) => {
      let new_key = element.toLowerCase();
      getTotalGammaSupplyApy[new_key] = getTotalGammaSupplyApy[element]
    });
    // let getTotalGammaSupplyApy: any = {};
    let supplyApyForVaults : any = [];
    try {
      if(Object.keys(planetGlobalObject.getallpoolinfo).length > 0) {
        supplyApyForVaults = planetGlobalObject.getallpoolinfo;
      }
      else {
        supplyApyForVaults = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
      }
    } catch (error) {
      try {
        supplyApyForVaults = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
      } catch (error) {
        console.log('error in fetching getTotalSupplyApy', error);
      }
    }
    // let supplyAprArray: any = [];
    // let getKeys = Object.keys(getPoolsAndVaultsDataForPF);
    supplyApyForVaults.newVaults.active.forEach((element: any, key: any) => {
      let new_key = element.address.toLowerCase() ;
      supplyAprArray[new_key] = element.supplyApr;
    });

    supplyApyForVaults.newVaults.active.forEach((element: any, key: any) => {
      let new_key = element.address.toLowerCase() ;
      supplyApyArray[new_key] = element.supplyApy;
    });

    gammaPrice = getGammaPrice();
  }
  
  //supplied balance
  market_array.forEach(async(market: any) => {
    targets.push(market.address)
    const data = wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.getAccountSnapshot(userAddress)._method, [userAddress])
    callDatas.push(data)
    ouput_format.push(asset_inst.methods.getAccountSnapshot(userAddress)._method.outputs)
  })

  market_array.forEach(async(market: any) => {
    targets.push(market.token)
    const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.balanceOf(userAddress)._method, [userAddress])
    callDatas.push(data)
    ouput_format.push(token_inst.methods.balanceOf(userAddress)._method.outputs)
  })

  market_array.forEach(async(market: any) => {
    targets.push(market.token)
    const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.allowance(userAddress, market.address)._method, [
      userAddress,
      market.address,
    ])
    callDatas.push(data)
    ouput_format.push(token_inst.methods.allowance(userAddress, market.address)._method.outputs)
  })

  market_array.forEach( async (market: any) => {
    const inst: any = market.address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() ? old_priceoracle_inst : priceoracle_inst;
    let priceOracleAddressVal = market.address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() ? priceOracleAddressOld : priceOracleAddress;
    targets.push(priceOracleAddressVal)
    const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.getUnderlyingPrice(market.address)._method, [market.address])
    callDatas.push(data)
    ouput_format.push(inst.methods.getUnderlyingPrice(market.address)._method.outputs)
  })

  market_array.forEach(async (market: any) => {
    targets.push(gammatrollerAddress)
    const data = wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaBoostPercentage(market.address)._method, [market.address])
    callDatas.push(data)
    ouput_format.push(gammatroller_inst.methods.gammaBoostPercentage(market.address)._method.outputs)
  })

  market_array.forEach(async(market:any) => {
    const inst : any = market.address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || market.address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? gammatroller_inst_old : gammatroller_inst
    let gammatrollerAddressVal =  market.address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || market.address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? gammatrollerAddressOld : gammatrollerAddress
    targets.push(gammatrollerAddressVal)
    const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.gammaSpeeds(market.address)._method, [market.address])
    callDatas.push(data)
    ouput_format.push(inst.methods.gammaSpeeds(market.address)._method.outputs)
  })

  market_array.forEach(async(market: any) => {
    targets.push(market.address) 
    const data = wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.totalBorrows()._method, [])
    callDatas.push(data)
    ouput_format.push(asset_inst.methods.totalBorrows()._method.outputs)
  })

  market_array.forEach(async(market: any) => {
    targets.push(market.address)
    const data = wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.borrowRatePerBlock()._method, [])
    callDatas.push(data)
    ouput_format.push(asset_inst.methods.borrowRatePerBlock()._method.outputs)
  })
  
  // market_array.forEach(async(market: any) => {
  //   const inst : any = market.address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() ||  market.address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? gammatroller_inst_old : gammatroller_inst;
  //   let gammatrollerAddressVal = market.address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() || market.address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() ? gammatrollerAddressOld : gammatrollerAddress
  //   targets.push(gammatrollerAddressVal)
  //   const data = (wallet.web3.eth.abi.encodeFunctionCall(inst.methods.isDeprecated(market.address)._method, [market.address]));
  //   callDatas.push(data);
  //   ouput_format.push(inst.methods.isDeprecated(market.address)._method.outputs)
  // })

  market_array.forEach(async(market: any) => {
    targets.push(market.address);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.borrowBalanceStored(userAddress)._method, [userAddress]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.borrowBalanceStored(userAddress)._method.outputs)
  })

  const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());
  
  const do_split = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }  

  for(let i = 0 ; i < aggregated_data[1].length ; i++){
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i],aggregated_data[1][i]))
  }

  const split_arr = (await do_split(results, market_array.length));
  // console.log("vaults data array",aggregated_data, split_arr)

  sharesArr = split_arr[0];
  tokenBalance = split_arr[1];
  allowanceArr = split_arr[2];
  tokenPriceArray = split_arr[3];
  gammaBoostArr = split_arr[4];
  gammaSpeedsArr = split_arr[5];
  totalBorrowsArr = split_arr[6];
  borrowRatePerBlockArr = split_arr[7];
  // isDeprecatedArr = split_arr[8];
  borrowBalanceArray = split_arr[8];

  // let borrowGammaApyArray: any = [];
  let borrowGammaAprArray: any = [];
  // let borrowApyArray: any = [];
  let borrowAprArray: any = [];
  let blocksPerDay = 28800;
  let daysPerYear = 365;
  let level = userLevel.level;
  let userMarketsArray: any = [];
  let oldTokenBalance: any = 0;
  let balanceInGreen: any = 0;
  let green_apy: any = 0;
  let borrowAprArrayOnLevel: any = [];
  let supplied_list: any = [];
  let marketsInterestPerYear: any = 0;
  market_array.forEach((token: any, index: any) => {
    let address = token.address.toLowerCase();
    underLyingPriceDataArray[address] = parseFloat(gConvertToEther(tokenPriceArray[index][0], 18));
  })
  let userBoostAPRArray = await getUserAndAverageBoostForMarketsAlt(userAddress, new_markets_without_gammaAqua, underLyingPriceDataArray);  //userBoostAPR, averageBoostAPR
  // console.log("boost apy array", userBoostAPRArray);
  // const discount = level === 0 ? 0 : level === 1 ? 5 : level === 2 ? 20 : 50;
  // let gammaSpeeds: any = [];
  for(let i = 0; i < market_array.length; i++){
    let token = market_array[i].address.toLowerCase();
    let decimal = market_array[i].decimals;
    supplied_list.push(token);
    supplyBalanceArr[token] = parseFloat(gConvertToEther(sharesArr[i][1],decimal)) * parseFloat(convertToEther(sharesArr[i][3]));
    userMarketWalletArray[token] = token != '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29'.toLowerCase() ? convertToEther(tokenBalance[i][0]) : convertToEther(await wallet.web3.eth.getBalance(userAddress));//userMarketWalletArray['0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29'.toLowerCase()] = convertToEther(await wallet.web3.eth.getBalance(userAddress));
    userAllowanceArray[token] = convertToEther(allowanceArr[i][0]);
    // underLyingPriceDataArray[token] = parseFloat(gConvertToEther(tokenPriceArray[i][0], decimal));
    gammaBoostArr[token] = (gammaBoostArr[i][0])/ 100;
    gammaSpeedsArr[token] = parseFloat(convertToEther(gammaSpeedsArr[i][0] * blocksPerDay));
    totalBorrowsArr[token] = parseFloat(gConvertToEther(totalBorrowsArr[i][0], decimal));
    //borrowGammaApyArray[token] = totalBorrowsArr[token] !== 0 ? 100 * (Math.pow(1 + (gammaPrice * gammaSpeedsArr[token]) / (totalBorrowsArr[token] * underLyingPriceDataArray[token]), daysPerYear) - 1) : 0;
    const _borrowRatePerBlock = convertToEther(borrowRatePerBlockArr[i][0])
    borrowRatePerBlockArr[token] = _borrowRatePerBlock * blocksPerDay * daysPerYear * 100;
    let borrowApr = borrowRatePerBlockArr[token];
    let borrowApy = (Math.pow(borrowApr/36500 + 1, daysPerYear) - 1) * 100;
    if(totalBorrowsArr[token] > 0){
      const discount = userLevel.discount;
      const diff = borrowRatePerBlockArr[token] - supplyApyArray[token];
      borrowApy -= (discount * diff) / 100;
    }
    let gammaBorrowApy = totalBorrowsArr[token] !== 0 ? 100 * (Math.pow(1 +(gammaPrice * gammaSpeedsArr[token]) / (totalBorrowsArr[token] * underLyingPriceDataArray[token]),daysPerYear) - 1)  : 0;
    borrowGammaAprArray[token] = (Math.pow((gammaBorrowApy / 100 + 1), (1 / 365)) - 1) * 365 * 100;
    const finalBorrowApy = gammaBorrowApy - borrowApy ;
    borrowAprArray[token] = (Math.pow((borrowApy / 100 + 1), (1 / 365)) - 1) * 365 * 100;//finalBorrowApy > 0 ? (Math.pow((borrowApy / 100 + 1), (1 / 365)) - 1) * 365 * 100 : (Math.pow((borrowApy * -1 / 100 + 1), (1 / 365)) - 1) * 365 * 100;
    borrowAprArrayOnLevel[token] = borrowAprArray[token];
    // console.log(" borrow apy", market_array[i].name, finalBorrowApy, totalBorrowsArr[token], borrowGammaApyArray[token], borrowRatePerBlockArr[token], getTotalSupplyApy[token])
    // console.log(market_array[i].name, borrowGammaAprArray[token], borrowAprArray[token], borrowApy)
    borrowBalanceArray[token] = convertToEther(borrowBalanceArray[i][0]);
    userMarketsArray[token] = {};
    // userMarketsArray[token].marketAddress = token;
    balanceMarketArray[token] =  userMarketWalletArray[token] ? userMarketWalletArray[token] : 0;
    if (supplyBalanceArr[token] && underLyingPriceDataArray[token]) {
      
      const deposit_usd = token !== gAquaOld.toLowerCase() && token !== gGammaOld.toLowerCase() ? (supplyBalanceArr[token]) * underLyingPriceDataArray[token] : 0;
      oldTokenBalance += token == gAquaOld.toLowerCase() && token == gGammaOld.toLowerCase() ? (supplyBalanceArr[token]) * underLyingPriceDataArray[token] : 0;
      // console.log("deposit_usd",market, deposit_usd);
      balanceInGreen += deposit_usd;
      // let distributionApy: any = getTotalGammaSupplyApy[token] && getTotalGammaSupplyApy[token]!== null ? +getTotalGammaSupplyApy[token] : 0;
      let distributionApr: any = getTotalGammaSupplyApr[token] && getTotalGammaSupplyApr[token]!== null ? +getTotalGammaSupplyApr[token] : 0;
      // console.log("distributionApy",token, distributionApy);
      
      let supplyApy = getTotalSupplyApy[token] && getTotalSupplyApy[token] !== null ? +getTotalSupplyApy[token] : 0;
      let supplyApr = supplyAprArray[token] && supplyAprArray[token] !== null ? +supplyAprArray[token] : 0;
      // if (supplyApy && distributionApy) {
      let borrow_apr = borrowAprArrayOnLevel[token] ? +borrowAprArrayOnLevel[token] : 0;
      // console.log("borrow apr", borrow_apr, market_array[i].name)
      let gamma_borrow_apr = borrowGammaAprArray[token] ? +borrowGammaAprArray[token] : 0;
      // console.log("gamma borrow apr", gamma_borrow_apr)
      let borrowBalanceUSD = borrowBalanceArray[token] ? +borrowBalanceArray[token] * +underLyingPriceDataArray[token] : 0;
      // let weightedBorrowGammaApy = gamma_borrow_apy !== undefined && gamma_borrow_apy === gamma_borrow_apy ? (gamma_borrow_apy * borrowBalanceUSD)/100: 0;
      balanceInGreen -= borrowBalanceUSD;
      // let weightedBorrowApy = borrowBalanceUSD > 0.01 ? ( borrow_apr * borrowBalanceUSD)/100 : 0;
      let weightedBorrowApr = borrowBalanceUSD > 0.01 ? ( ( gamma_borrow_apr - borrow_apr) * borrowBalanceUSD)/100 : 0;
      // console.log("borrow balance", borrowBalanceUSD, weightedBorrowGammaApy, weightedBorrowApy, gTokenAddress)
      //userBoostAPRArray[token].averageBoostApy
      let avgBoostApy = userBoostAPRArray[token] && userBoostAPRArray[token].averageBoostApy ? userBoostAPRArray[token].averageBoostApy : 0;
      let userBoostApy = userBoostAPRArray[token] && userBoostAPRArray[token].userBoostApy ? userBoostAPRArray[token].userBoostApy : avgBoostApy;
      let avgBoostApr = userBoostAPRArray[token] && userBoostAPRArray[token].averageBoostApy ? userBoostAPRArray[token].averageBoostAPR : 0;//userBoostAPR, averageBoostAPR
      let userBoostApr = userBoostAPRArray[token] && userBoostAPRArray[token].userBoostApy ? userBoostAPRArray[token].userBoostAPR : avgBoostApr;
      // console.log("user boost apy", userBoostApy, token)
      let boostApy = supplyBalanceArr[token] * underLyingPriceDataArray[token] > 1 ? userBoostApy : avgBoostApy;
      let boostApr = supplyBalanceArr[token] * underLyingPriceDataArray[token] > 1 ? userBoostApr : avgBoostApr;
      let total_apy = supplyApy + distributionApr + boostApy;
      let stakeApy = token == gBNBxAddress.toLowerCase() ? await fetchBNBxApy() : 0;
      let stakeApr = stakeApy > 0 ? (Math.pow((stakeApy / 100 + 1), (1 / 365)) - 1) * 365 * 100 : 0;
      let total_apr = infinity_balance_arr && infinity_balance_arr.length > 0 && infinity_balance_arr[0] > 0 ? supplyApr + distributionApr + boostApr : supplyApr + distributionApr + stakeApr;
      // console.log("supply apr", market_array[i].name, total_apr, supplyApr, distributionApr, boostApr, userBoostApr, avgBoostApr)
      // console.log("weightedBorrowApy", weightedBorrowApy);
      green_apy += token !== gAquaOld.toLowerCase() && token !== gGammaOld.toLowerCase() ? ((deposit_usd * total_apr) / 100) : 0;
      // console.log("vault apr for ", market_array[i].name, deposit_usd * total_apr / 100, weightedBorrowApr)
      green_apy += weightedBorrowApr
      // console.log("total_apy",token, gTokenAddress, total_apy, green_apy);
      // console.log("green_apy", green_apy);
      // }
      // console.log("apy details for supply ", green_apy, deposit_usd, total_apy, supplyApy, distributionApy, boostApy, token )
      // console.log("apy details for borrow ", borrowBalanceUSD, gamma_borrow_apy, borrow_apy, token )
      // console.log("borrow apy", token, borrow_apy+gamma_borrow_apy)
      balance_in_vaults += deposit_usd;
      userMarketsArray[token] = {
        marketAddress : token,
        totalShares : supplyBalanceArr[token],
        vaultBalance : supplyBalanceArr[token],
        vaultBalanceUSD : supplyBalanceArr[token] * underLyingPriceDataArray[token] > 0.01 ? supplyBalanceArr[token] * underLyingPriceDataArray[token] : 0,
        sharePrice : underLyingPriceDataArray[token],
        walletBalance : userMarketWalletArray[token] ? userMarketWalletArray[token] : 0,
        walletBalanceUSD : userMarketWalletArray[token] && +userMarketWalletArray[token] * +underLyingPriceDataArray[token] > 0.01 ? +userMarketWalletArray[token] * +underLyingPriceDataArray[token] : 0,
        allowance :  userAllowanceArray[token] ? userAllowanceArray[token] : 0,
        isAllowanceApproved : userAllowanceArray[token] !== 0 && +userAllowanceArray[token] > 0 ? true : false,
        strategyAddress : "",
        userBoostApy : userBoostAPRArray[token] && userBoostAPRArray[token].userBoostApy ? userBoostAPRArray[token].userBoostApy : 0,
        averageBoostApy : userBoostAPRArray[token] && userBoostAPRArray[token].averageBoostApy ? userBoostAPRArray[token].averageBoostApy : 0,
        gammaBoostPercentage : gammaBoostArr[token] ? gammaBoostArr[token] : 0,
        averageBoostAPR : userBoostAPRArray[token] && userBoostAPRArray[token].averageBoostAPR ? userBoostAPRArray[token].averageBoostAPR : 0,
        userBoostAPR : userBoostAPRArray[token] && userBoostAPRArray[token].userBoostAPR ? userBoostAPRArray[token].userBoostAPR : 0,
        borrowBalanceUSD,
        gamma_borrow_apr,
        borrow_apr

      };
      // userMarketsArray[token].totalShares = supplyBalanceArr[token];
      // userMarketsArray[token].vaultBalance = supplyBalanceArr[token];
      // userMarketsArray[token].vaultBalanceUSD = supplyBalanceArr[token] * underLyingPriceDataArray[token] > 0.01 ? supplyBalanceArr[token] * underLyingPriceDataArray[token] : 0;
      // userMarketsArray[token].sharePrice = underLyingPriceDataArray[token];
      // userMarketsArray[token].walletBalance = userMarketWalletArray[token] ? userMarketWalletArray[token] : 0;
      // userMarketsArray[token].walletBalanceUSD = userMarketWalletArray[token] && +userMarketWalletArray[token] * +underLyingPriceDataArray[token] > 0.01 ? +userMarketWalletArray[token] * +underLyingPriceDataArray[token] : 0;
      // userMarketsArray[token].allowance =  userAllowanceArray[token] ? userAllowanceArray[token] : 0;
      // userMarketsArray[token].isAllowanceApproved =
      //   userAllowanceArray[token] !== 0 && +userAllowanceArray[token] > 0 ? true : false;
      // userMarketsArray[token].strategyAddress = "";
      // userMarketsArray[token].userBoostApy = userBoostAPRArray[token] && userBoostAPRArray[token].userBoostApy ? userBoostAPRArray[token].userBoostApy : 0;
      // userMarketsArray[token].averageBoostApy = userBoostAPRArray[token] && userBoostAPRArray[token].averageBoostApy ? userBoostAPRArray[token].averageBoostApy : 0;
      // userMarketsArray[token].gammaBoostPercentage = gammaBoostArr[token] ? gammaBoostArr[token] : 0;
      // userMarketsArray[token].averageBoostAPR = userBoostAPRArray[token] && userBoostAPRArray[token].averageBoostAPR ? userBoostAPRArray[token].averageBoostAPR : 0;
      // userMarketsArray[token].userBoostAPR = userBoostAPRArray[token] && userBoostAPRArray[token].userBoostAPR ? userBoostAPRArray[token].userBoostAPR : 0;
    } else {
      oldTokenBalance += token == gAquaOld.toLowerCase() && token == gGammaOld.toLowerCase() ? (supplyBalanceArr[token]) * underLyingPriceDataArray[token] : 0;
      let gamma_borrow_apr = borrowGammaAprArray[token] ? +borrowGammaAprArray[token] : 0;
      let borrow_apr = borrowAprArrayOnLevel[token] ? +borrowAprArrayOnLevel[token] : 0;
      // console.log("borrow apy", borrow_apy, market_array[i].name)
      let borrowBalanceUSD = borrowBalanceArray[token] ? +borrowBalanceArray[token] * +underLyingPriceDataArray[token] : 0;
      // let weightedBorrowGammaApy = gamma_borrow_apy !== undefined && gamma_borrow_apy === gamma_borrow_apy ? (gamma_borrow_apy * borrowBalanceUSD)/100: 0;
      // console.log("gamma borrow apr", gamma_borrow_apr)
      // console.log("borrow apy", token, borrow_apy+gamma_borrow_apy)
      let weightedBorrowApr = borrowBalanceUSD > 0.01 ? (( gamma_borrow_apr - borrow_apr) * borrowBalanceUSD)/100 : 0; 
      // console.log("apy details for borrow ", borrowBalanceUSD, gamma_borrow_apy, borrowApyArrayOnLevel[token], token )
      // console.log("borrow balance", borrowBalanceUSD, weightedBorrowGammaApy, weightedBorrowApy, gTokenAddress)
      balanceInGreen -= borrowBalanceUSD;
      // green_apy += weightedBorrowGammaApy;
      green_apy += weightedBorrowApr;
      // console.log("vault apr for ", market_array[i].name, 0, weightedBorrowApr)
      // userMarketsArray[token].walletBalance = userMarketWalletArray[token] ? userMarketWalletArray[token] : 0;
      // userMarketsArray[token].walletBalanceUSD = userMarketWalletArray[token] && underLyingPriceDataArray[token] && +userMarketWalletArray[token] * +underLyingPriceDataArray[token] > 0.01 ? +userMarketWalletArray[token] * +underLyingPriceDataArray[token] : 0;
      // userMarketsArray[token].sharePrice = underLyingPriceDataArray[token] ? underLyingPriceDataArray[token] : 0;
      // userMarketsArray[token].vaultBalance = 0;
      // userMarketsArray[token].vaultBalanceUSD = 0;
      // userMarketsArray[token].totalShares = 0;
      // userMarketsArray[token].allowance = userAllowanceArray[token] ? userAllowanceArray[token] : 0;
      // userMarketsArray[token].isAllowanceApproved =
      //   userAllowanceArray[token] !== 0 && +userAllowanceArray[token] > 0 ? true : false;
      // userMarketsArray[token].strategyAddress = "";
      // userMarketsArray[token].userBoostApy = userBoostAPRArray[token] && userBoostAPRArray[token].userBoostApy ? userBoostAPRArray[token].userBoostApy: 0;
      // userMarketsArray[token].avgBoostApy = userBoostAPRArray[token] && userBoostAPRArray[token].averageBoostApy ? userBoostAPRArray[token].averageBoostApy : 0;
      // userMarketsArray[token].gammaBoostPercentage = gammaBoostArr[token] ? gammaBoostArr[token] : 0;
      userMarketsArray[token] = {
        marketAddress : token,
        totalShares : 0,
        vaultBalance : 0,
        vaultBalanceUSD : 0,
        sharePrice : underLyingPriceDataArray[token],
        walletBalance : userMarketWalletArray[token] ? userMarketWalletArray[token] : 0,
        walletBalanceUSD : userMarketWalletArray[token] && +userMarketWalletArray[token] * +underLyingPriceDataArray[token] > 0.01 ? +userMarketWalletArray[token] * +underLyingPriceDataArray[token] : 0,
        allowance :  userAllowanceArray[token] ? userAllowanceArray[token] : 0,
        isAllowanceApproved : userAllowanceArray[token] !== 0 && +userAllowanceArray[token] > 0 ? true : false,
        strategyAddress : "",
        userBoostApy : userBoostAPRArray[token] && userBoostAPRArray[token].userBoostApy ? userBoostAPRArray[token].userBoostApy : 0,
        averageBoostApy : userBoostAPRArray[token] && userBoostAPRArray[token].averageBoostApy ? userBoostAPRArray[token].averageBoostApy : 0,
        gammaBoostPercentage : gammaBoostArr[token] ? gammaBoostArr[token] : 0,
        averageBoostAPR : userBoostAPRArray[token] && userBoostAPRArray[token].averageBoostAPR ? userBoostAPRArray[token].averageBoostAPR : 0,
        userBoostAPR : userBoostAPRArray[token] && userBoostAPRArray[token].userBoostAPR ? userBoostAPRArray[token].userBoostAPR : 0,
        borrowBalanceUSD,
        gamma_borrow_apr,
        borrow_apr
      };
    }
    if (token === gGamma) {
      totalGammaStaked += supplyBalanceArr[token];
    }
    
  }
  return {userMarketsArray, balance_in_vaults, green_apy, balanceInGreen, supplied_list}
} 

export const isAllowanceApproved = async (userAddress: string, farmAddress: string, wantAddress: string) => {
  // let approvalAmountValue = parseFloat(approvalAmount)
  // console.log("isAllowanceApproved",userAddress, farmAddress, wantAddress, userAllowanceArray)
  if(wantAddress){
    let key = farmAddress !== "" ? wantAddress.toLowerCase() + '_' + farmAddress.toLowerCase() : wantAddress.toLowerCase();
    if (userAddress !== undefined && +userAllowanceArray[key] > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false
  }
}

export const getTVL = async(gTokenAddress: any) => {
  const inst: any = new wallet.web3.eth.Contract(gBnbAbi, gTokenAddress)
  const data = await inst.methods.totalSupply().call();
  return data;

}

export const getVestingData = async(userAddress?: string) => {
  // 1. vestingVaultPid - 3
  // farm contract - 0xf5eD1cC15C2a4C0FfA9180ffdD6CD96E96bBd1b7
  const gammaPrice = await getGammaPrice();
  //const vestingVaultPid = 3;
  //const vestingPoolStrategyAddress = "0x60A895073AdC0e5F5a22C60bdfc584D79B5219a1";
  const decimal = 18;
  let userLockArray = [];
  let vestingBalance = 0;
  let unlockedVestingBalance = 0;
  let vestingBalanceUsd = 0;
  let unlockedVestingBalanceUsd = 0;
  let lockedVestingBalance = 0;
  let unlockedVestingBalanceWei = 0;

  let farm_inst: any = new wallet.web3.eth.Contract(v3FarmAbi, v3FarmAddress );
  const userAccountData = await farm_inst.methods.getUserAccountData(userAddress).call();

  let userPools = userAccountData[0];
  
  if(userPools.indexOf(vestingVaultPid)) {
    const inst = new wallet.web3.eth.Contract(vestingStrategyAbi, vestingPoolStrategyAddress );
    const numberOfLocks = await inst.methods.getNumberOfLocks(userAddress).call();
    vestingBalance = await inst.methods.balance(userAddress).call();
    unlockedVestingBalance = await inst.methods.unlockedBalance(userAddress).call();

    unlockedVestingBalanceWei = unlockedVestingBalance;
    vestingBalanceUsd = parseFloat(gConvertToEther(vestingBalance, decimal)) * gammaPrice;  
    unlockedVestingBalanceUsd = parseFloat(gConvertToEther(unlockedVestingBalance, decimal)) * gammaPrice;  
    unlockedVestingBalance = parseFloat(gConvertToEther(unlockedVestingBalance, decimal));
    lockedVestingBalance = parseFloat(gConvertToEther(vestingBalance, decimal)) - unlockedVestingBalance;
    

    var tz = momentTimeZone.tz.guess();
    tz = momentTimeZone.tz(tz).format('z');
    // console.log('timezone', tz, momentTimeZone().tz(momentTimeZone.tz.guess()).format('z'));
    var currentTimestamp = Math.floor(Date.now() /1000);

    for(let i = 0; i < numberOfLocks; i++) {
      let userLock: any;
      try{
        userLock = await inst.methods.userLocks(userAddress, i).call();
      } catch(error){
        console.log("vesting error",error)
      }
      
      let uL:any = {};
      if(userLock.unlockTime > currentTimestamp) {
        uL.lockedAmountWei = userLock.amount;
        uL.lockedAmount = parseFloat(gConvertToEther(userLock.amount, decimal));
        uL.unlockTime = moment.unix(userLock.unlockTime).format('MMM DD, YYYY hh:mm A') + ' (' + tz + ')';
        uL.unlockTimeStamp = userLock.unlockTime;
        uL.lockedAmountUsd = gammaPrice * uL.lockedAmount;
        userLockArray[i] = uL;
      }
      // else {
      //   let userLock = await inst.methods.userLocks(userAddress, i).call();
      //   let uL:any = {};
      //   uL.lockedAmountWei = userLock.amount;
      //   uL.lockedAmount = parseFloat(gConvertToEther(userLock.amount, decimal));
      //   uL.unlockTime = moment.unix(userLock.unlockTime).format('MMM DD, YYYY hh:mm A') + ' (' + tz + ')';
      //   uL.unlockTimeStamp = userLock.unlockTime;
      //   uL.lockedAmountUsd = gammaPrice * uL.lockedAmount;
      //   userLockArray[i] = uL;
      // }
    }
    // console.log('userLockArray', userLockArray);
  }

  let vestingData = {
    userLockArray,
    vestingBalance,
    unlockedVestingBalance,
    lockedVestingBalance,
    vestingBalanceUsd,
    unlockedVestingBalanceUsd,
    unlockedVestingBalanceWei
  }
  return vestingData;
}

export const getPortfolioDataNew = async (userAddress?: string) => {
  // console.log("userAddress", userAddress)
  // userAddress = "0xB7A9024C10eb2C2877fb69690b48b725F7cA8288"//"0x99fedea26f6cdf924dd34c4a0e17afdfe553947f";
  const initialTimestamp = new Date().getTime();
  let poolData: any = {};
  try {
    if(Object.keys(planetGlobalObject.poolsInfo).length > 0) {
      poolData = planetGlobalObject.poolsInfo;
    }
    else {
      poolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfo')).data;
    }
  } catch (error) {
    try {
      poolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfo')).data;
    } catch (error) {
      console.log('error in fetching poolData', error);
    }
  }

  let newPoolData: any = {};
  try {
    if(Object.keys(planetGlobalObject.poolsInfoV2).length > 0) {
      newPoolData = planetGlobalObject.poolsInfoV2;
    }
    else {
      newPoolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfov2')).data;
    }
  } catch (error) {
    try {
      newPoolData = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfov2')).data;
    } catch (error) {
      console.log('error in fetching new pool data', error);
    }
  }

  let v3_pools_data: any = {};

  try {
    if(Object.keys(planetGlobalObject.poolsInfoV3).length > 0) {
      v3_pools_data = planetGlobalObject.poolsInfoV3;
    }
    else {
      v3_pools_data = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfov3')).data;
    }
  } catch (error) {
    try {
      v3_pools_data = (await axios.get(newPFApiBaseUrl+'v1/getpoolsinfov3')).data;
    } catch (error) {
      console.log('error in fetching poolData', error);
    }
  }

  let poolIdArray: any = [];
  let stratAddressArray: any = [];
  let farmApyArray: any = [];
  let lpTokenPrice: any = [];
  let wantAddressArray: any = [];
  let userLPArray: any = [];
  let allocPointArray: any = [];
  let tradeFeeApy: any = [];

  let poolIdArrayNew: any = [];
  let stratAddressArrayNew: any = [];
  let farmApyArrayNew: any = [];
  let lpTokenPriceNew: any = [];
  let wantAddressArrayNew: any = [];
  let userLPArrayNew: any = [];
  let allocPointArrayNew: any = [];
  let tradeFeeApyNew: any = [];
  let parentFarmAprArray: any = [];
  let poolName: any = [];
  let totalBalanceBlueUSD: any = 0;
  let totalApy: any = 0;
  let totalShares: any = 0;
  let pendingEarningsUSD: any = 0;
  let blue_apy: any = 0;
  let poolInterestPerYear: any = 0;
  let vaultInterestPerYear: any = 0;
  let infVaultInterestPerYear: any = 0;
  let gammaAprArrayNew: any = [];
  let tradeFeeAprArrayNew: any = [];
  let userSharesKeysArray: any = [];
  let balanceInBlue: any = 0;
  let uranusRewardsTotal: any = 0;
  let priceArray = await returnTokenPriceMulticall();
  const aquaPrice: any = priceArray[aquaAddress.toLowerCase()] ? priceArray[aquaAddress.toLowerCase()] : await getAquaPrice();
  const gammaPrice = priceArray[gammaAddress.toLowerCase()] ? priceArray[gammaAddress.toLowerCase()] : await getGammaPrice();
  let userLevel = userAddress ? await getDiscountLevel(userAddress) : 0;
  
  for (var key in poolData) {
    // console.log(poolData[key])
    let key_arr = key.split('_');
    let poolId = poolData[key].poolId;
    // console.log("poolId details",poolId, poolData[key].strategyAddress, poolData[key].farmApyPerYear)
    
    poolIdArray.push(key_arr[1] + '_' + poolId); //farmAddress_poolId
    stratAddressArray[key_arr[1] + '_' + poolId] = poolData[key].strategyAddress;
    farmApyArray[key_arr[1] + '_' + poolId] = poolData[key].farmApyPerYear ? poolData[key].farmApyPerYear : 0; // gammaApy = farmApyPerYear
    lpTokenPrice[key_arr[1] + '_' + poolId] = poolData[key].price ? poolData[key].price : 0;
    wantAddressArray[key_arr[1] + '_' + poolId] = poolData[key].wantAddress.toLowerCase();
    allocPointArray[key_arr[1] + '_' + poolId] = poolData[key].poolAllocPoint;
    tradeFeeApy[key_arr[1] + '_' + poolId] = poolData[key].tradeFeeApy ? poolData[key].tradeFeeApy : 0;
    gammaAprArrayNew[key_arr[1] + '_' + poolId] = poolData[key].gammaApy ? poolData[key].gammaApy : 0;
    tradeFeeAprArrayNew[key_arr[1] + '_' + poolId] = poolData[key].tradeFeeApr ? poolData[key].tradeFeeApr : 0;
  }

  for (var key in newPoolData) {
    let key_arr = key.split('_');
    let poolId = newPoolData[key].poolId;
    // console.log("poolId details",poolId, newPoolData[key].strategyAddress, newPoolData[key].farmApyPerYear)
    poolIdArrayNew.push(key_arr[1] + '_' + poolId); //farmAddress_poolId
    stratAddressArrayNew[key_arr[1] + '_' + poolId] = newPoolData[key].strategyAddress;
    farmApyArrayNew[key_arr[1] + '_' + poolId] = newPoolData[key].farmApyPerYear ? newPoolData[key].farmApyPerYear : 0; // gammaApy = farmApyPerYear
    lpTokenPriceNew[key_arr[1] + '_' + poolId] = newPoolData[key].price ? newPoolData[key].price : 0;
    wantAddressArrayNew[key_arr[1] + '_' + poolId] = newPoolData[key].wantAddress.toLowerCase();
    allocPointArrayNew[key_arr[1] + '_' + poolId] = newPoolData[key].poolAllocPoint;
    tradeFeeApyNew[key_arr[1] + '_' + poolId] = newPoolData[key].tradeFeeApy ? newPoolData[key].tradeFeeApy : 0;
    poolName[key_arr[1] + '_' + poolId] = newPoolData[key].name ? newPoolData[key].name : "";
    gammaAprArrayNew[key_arr[1] + '_' + poolId] = newPoolData[key].gammaApr ? newPoolData[key].gammaApr : 0;
    tradeFeeAprArrayNew[key_arr[1] + '_' + poolId] = newPoolData[key].tradeFeeApr ? newPoolData[key].tradeFeeApr : 0;
    parentFarmAprArray[key_arr[1] + '_' + poolId] = newPoolData[key].parentFarmApr ? newPoolData[key].parentFarmApr : 0;
  }

  let poolIdArray_v3: any = [];
  let stratAddressArray_v3: any = [];
  let farmApyArray_v3: any = [];
  let lpTokenPrice_v3: any = [];
  let wantAddressArray_v3: any = [];
  let userLPArray_v3: any = [];
  let allocPointArray_v3: any = [];
  let tradeFeeApy_v3: any = [];
  let gammaAprArray_v3: any = [];
  let tradeFeeAprArray_v3: any = [];
  let parentFarmAprArray_v3: any = [];
  let poolName_v3: any = [];

  for (var key in v3_pools_data) {
    var index = Object.keys(v3_pools_data).indexOf(key);
    // if(index > 2){
    //   continue;
    // }
    let key_arr = key.split('_');
    let poolId = v3_pools_data[key].poolId;
    poolIdArray_v3.push(key_arr[1] + '_' + poolId); //farmAddress_poolId
    stratAddressArray_v3[key_arr[1] + '_' + poolId] = v3_pools_data[key].strategyAddress;
    farmApyArray_v3[key_arr[1] + '_' + poolId] = v3_pools_data[key].farmApyPerYear ? v3_pools_data[key].farmApyPerYear : 0; // gammaApy = farmApyPerYear
    lpTokenPrice_v3[key_arr[1] + '_' + poolId] = v3_pools_data[key].price ? v3_pools_data[key].price : 0;
    wantAddressArray_v3[key_arr[1] + '_' + poolId] = v3_pools_data[key].wantAddress.toLowerCase();
    allocPointArray_v3[key_arr[1] + '_' + poolId] = v3_pools_data[key].poolAllocPoint;
    tradeFeeApy_v3[key_arr[1] + '_' + poolId] = v3_pools_data[key].tradeFeeApy ? v3_pools_data[key].tradeFeeApy : 0;
    poolName_v3[key_arr[1] + '_' + poolId] = v3_pools_data[key].name ? v3_pools_data[key].name : "";
    gammaAprArray_v3[key_arr[1] + '_' + poolId] = v3_pools_data[key].gammaApr ? v3_pools_data[key].gammaApr : 0;
    tradeFeeAprArray_v3[key_arr[1] + '_' + poolId] = v3_pools_data[key].tradeFeeApr ? v3_pools_data[key].tradeFeeApr : 0;
    parentFarmAprArray_v3[key_arr[1] + '_' + poolId] = v3_pools_data[key].parentFarmApr ? v3_pools_data[key].parentFarmApr : 0;
  }

  // await getUserSharesFromFarmMulticall(userAddress, poolIdArray, wantAddressArray);
  // console.log("user LP shares Array",userSharesArray);

  const oldPoolTimestamp = new Date().getTime();
  // await getPendingRewardsMulticall(userAddress);
  // console.log("userRewardsArray",userRewardsArray);
  // await getPendingUranusRewardsMulticall(userAddress, stratAddressArray);
  // console.log("pendingUranusRewardsArray", pendingUranusRewardsArray);
  const oldPoolData = await fetchOldPoolDetails(userAddress, poolIdArray, wantAddressArray, lpTokenPrice, gammaPrice, tradeFeeApy, farmApyArray, stratAddressArray, tradeFeeAprArrayNew, poolInterestPerYear)
  const oldPoolTimestampFinal = new Date().getTime();
  // console.log("old pool time taken", oldPoolTimestampFinal - oldPoolTimestamp)
  // console.log("old pool data", oldPoolData)
  userLPArray = oldPoolData.userLPArray;
  totalBalanceBlueUSD = oldPoolData.balanceInBlue > 1 ? oldPoolData.balanceInBlue : 0;
  totalApy = 0;
  totalShares = 0;
  pendingEarningsUSD = 0;
  blue_apy = oldPoolData.blue_apy;
  userSharesKeysArray = Object.keys(userSharesArray);
  balanceInBlue = oldPoolData.balanceInBlue;
  uranusRewardsTotal  = 0;
  poolInterestPerYear = oldPoolData.poolInterestPerYear;
  // console.log("interest from old pools", poolInterestPerYear, "balance from old pools", balanceInBlue)
  // console.log("tradefee apr arr",tradeFeeApyNew, "farm apy arr ",farmApyArrayNew)
  // console.log("time taken to load old pools", oldPoolsTimestamp - initialTimestamp, totalBalanceBlueUSD, blue_apy)
  let newLpArrayData: any = await newLPDataMulticall(userAddress, poolIdArrayNew, wantAddressArrayNew, gammaPrice, lpTokenPriceNew, totalBalanceBlueUSD, blue_apy, farmApyArrayNew, tradeFeeApyNew, aquaPrice, stratAddressArrayNew, poolName, poolInterestPerYear, gammaAprArrayNew, tradeFeeAprArrayNew, parentFarmAprArray)
    // console.log("balance in new pools", newLpArrayData.balanceInBlue, "interest in new pools", newLpArrayData.poolInterestPerYear)
  balanceInBlue += newLpArrayData.balanceInBlue;
  blue_apy += newLpArrayData.blue_apy;
  // console.log('userLPArray', userLPArray);
  poolInterestPerYear += newLpArrayData.poolInterestPerYear;
  // console.log("new pools accrued interest", poolInterestPerYear)
  // console.log("pendingEarningsUSD", pendingEarningsUSD);
  // console.log("blue_apy before", blue_apy);
  // console.log("balance in Blue", balanceInBlue);

  let v3_LpArrayData: any = await v3_LPDataMulticall(userAddress, poolIdArray_v3, wantAddressArray_v3, gammaPrice, lpTokenPrice_v3, newLpArrayData.totalBalanceBlueUSD, newLpArrayData.blue_apy, farmApyArray_v3, tradeFeeAprArray_v3, aquaPrice, stratAddressArray_v3, poolName_v3, newLpArrayData.poolInterestPerYear, gammaAprArray_v3, tradeFeeAprArray_v3, parentFarmAprArray_v3);
  //{userLPArray, balanceInBlue, blue_apy, poolInterestPerYear, user_gamma_btcb_balance_in_v3, other_pool_balance_in_v3};
  balanceInBlue += v3_LpArrayData.balanceInBlue;
  blue_apy += v3_LpArrayData.blue_apy;
  // console.log('userLPArray', userLPArray);
  poolInterestPerYear += v3_LpArrayData.poolInterestPerYear;
  let v3_gammaRewards = v3_LpArrayData.pendingGammaRewards ? v3_LpArrayData.pendingGammaRewards : 0;
  // console.log("new pools accrued interest", poolInterestPerYear)
  // console.log("pendingEarningsUSD", pendingEarningsUSD);
  // console.log("blue_apy before", blue_apy);
  // console.log("balance in Blue", balanceInBlue);
  if (balanceInBlue !== 0) {
    blue_apy = (blue_apy / balanceInBlue) * 100;
  }

  let getTotalGammaSupplyApy: any = {};
  try {
    if(Object.keys(planetGlobalObject.poolGammaSupplyApy).length > 0) {
      getTotalGammaSupplyApy = planetGlobalObject.poolGammaSupplyApy;
    }
    else {
      getTotalGammaSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApy')).data;
    }
  } catch (error) {
    try {
      getTotalGammaSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApy')).data;
    } catch (error) {
      console.log('error in fetching getTotalSupplyApy', error);
    }
  }
  let getTotalGammaSupplyApyKeys = Object.keys(getTotalGammaSupplyApy);
  getTotalGammaSupplyApyKeys.forEach((element: any, key: any) => {
    let new_key = element.toLowerCase();
    getTotalGammaSupplyApy[new_key] = getTotalGammaSupplyApy[element]
  });

  //infinity vault details

  let aquaBurnApyData: any = await getBurnApy("all");
  // console.log("burnApy", aquaBurnApyData)
  let aquaBurnApy: any = aquaBurnApyData.apy;
  let aquaBurnApr: any = aquaBurnApyData.apr;
  let burnApyTime = new Date().getTime();
  const vaults = [0,1]//[gamma_infinity_vault_address_old, aqua_infinity_vault_address_old]
  // console.log("infinityVaultData", infinityVaultData);
  // let infinity_vault_details = "";
  let infinity_vault_details = infinityVaultData[0] && infinityVaultData[0].userAddress ? infinityVaultData : "";
  let gamma_inf_bal = infinityVaultData[0] && infinityVaultData[0].userAddress ? infinityVaultData[0].unstaking_bal + infinityVaultData[0].deposited_bal : 0;
  let aqua_inf_bal = infinityVaultData[1] && infinityVaultData[1].userAddress ? infinityVaultData[1].unstaking_bal + infinityVaultData[1].deposited_bal : 0;

  // console.log("aqua_inf_bal", aqua_inf_bal)
  let infinityVaultBalance: any = [];
  let inf_details: any = [];
  let inf_details_old: any = [];
  
  if(infinity_vault_details != "" ){
    infinityVaultBalance = [gamma_inf_bal, aqua_inf_bal]
  } else{
    // console.log("in else for inf balance")
    inf_details = userAddress ? await getInfinityVaultBalanceNew(userAddress, aquaBurnApy, getTotalGammaSupplyApy, underLyingPriceDataArray) : [{balance: 0, apy: 0, apr: 0},{balance: 0, apy: 0, apr: 0}];
    infinityVaultBalance = [inf_details[0].balance, inf_details[1].balance]
  } 
  // console.log("blue_apy after new LPs", blue_apy);
  let timestamp_before = new Date().getTime();
  const greenPending: any = userAddress ? await getPendingData(userAddress) : 0;
  let timestamp_after = new Date().getTime();
  // console.log("time taken for getPendingData", timestamp_after - timestamp_before);
  const greenPendingUsd = !isNaN(greenPending.pendingGammaUsd) ? greenPending.pendingGammaUsd : 0;
  const greenPendingGAMMA = !isNaN(greenPending.pendingGamma) ? greenPending.pendingGamma : 0;
  const vaultsTimestamp = new Date().getTime();
  let balance_in_vaults = balanceInBlue;
  let green_apy: any = 0;
  let total_apy: any = 0;
  let distributionApy: any = 0;
  let userMarketsArray: any = [];
  let oldTokenBalance: any = 0;
  const markets_data: any = Object.values(pfTokenMarketList);
  const new_markets_data: any = Object.values(bluePfTokenList);
  const new_markets_without_gammaAqua: any = Object.values(bluePfTokenListWithoutAquaGamma);
  let marketsInterestPerYear: any = 0
  // try catch the following two API calls
  let getTotalSupplyApy: any = {};
  try {
    if(Object.keys(planetGlobalObject.poolTotalSupplyApy)) {
      getTotalSupplyApy = planetGlobalObject.poolTotalSupplyApy;
    }
    else {
      getTotalSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getsupplyapy')).data;
    }
    
  } catch (error) {
    try {
      getTotalSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getsupplyapy')).data;
    } catch (error) {
      console.log('error in fetching getTotalSupplyApy', error);
    }
  }
  let getTotalSupplyApyKeys = Object.keys(getTotalSupplyApy);
  getTotalSupplyApyKeys.forEach((element: any, key: any) => {
    // console.log("getTotalSupplyApy element", element);
    let new_key = element.toLowerCase();
    getTotalSupplyApy[new_key] = getTotalSupplyApy[element];
  });
  // console.log("getTotalSupplyApy", getTotalSupplyApy);
  let supplyApyForVaults : any = [];
  try {
    if(Object.keys(planetGlobalObject.getallpoolinfo).length > 0) {
      supplyApyForVaults = planetGlobalObject.getallpoolinfo;
    }
    else {
      supplyApyForVaults = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
    }
  } catch (error) {
    try {
      supplyApyForVaults = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
    } catch (error) {
      console.log('error in fetching getTotalSupplyApy', error);
    }
  }
  let supplyAprArray: any = [];
  // let getKeys = Object.keys(getPoolsAndVaultsDataForPF);
  supplyApyForVaults.newVaults.active.forEach((element: any, key: any) => {
    let new_key = element.address.toLowerCase() ;
    supplyAprArray[new_key] = element.supplyApr;
  });

  let supplyApyArray: any = [];
  // let getKeys = Object.keys(getPoolsAndVaultsDataForPF);
  supplyApyForVaults.newVaults.active.forEach((element: any, key: any) => {
    let new_key = element.address.toLowerCase() ;
    supplyApyArray[new_key] = element.supplyApy;
  });

  

  const newVaultsDetails = await fetchNewVaultDetails(userAddress, new_markets_data, getTotalSupplyApy, getTotalGammaSupplyApy, gammaPrice, userLevel, balance_in_vaults, supplyAprArray, supplyApyArray, infinityVaultBalance);
  // console.log("borrowBalanceArr", borrowBalanceArr)
  // let borrowBalanceArrKeys = Object.keys(borrowBalanceArr);
  // borrowBalanceArrKeys.forEach((element: any, key: any) => {
  //   let new_key = element.toLowerCase();
  //   borrowBalanceArr[new_key] = borrowBalanceArr[element]
  // });
  // console.log("borrowBalanceArr", borrowBalanceArr)
  let balanceInGreen: any = 0;
  let approvalAmountValue = parseFloat(approvalAmount);
  let boostedCakeKey = '0x0709e1356F765a6F88632B539E209a5d6864B765';
  let boostedCakeObj = userLPArray[boostedCakeKey] ? userLPArray[boostedCakeKey] : {};
  boostedCakeKey = boostedCakeKey.toLowerCase();
  boostedCakeObj.sharePrice = boostedCakeObj.LPTokenUSD && boostedCakeObj.LPTokens ? boostedCakeObj.LPTokenUSD / boostedCakeObj.LPTokens : 0;
  boostedCakeObj.totalShares = 0;
  boostedCakeObj.vaultBalance = 0;
  boostedCakeObj.vaultBalanceUSD = 0;
  boostedCakeObj.marketAddress = "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82";
  boostedCakeObj.wantAddress = "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82";
  boostedCakeObj.strategyAddress = "0x0709e1356F765a6F88632B539E209a5d6864B765";
  boostedCakeObj.allowance = boostedCakeObj.allowance;
  boostedCakeObj.isAllowanceApproved = boostedCakeObj.isAllowanceApproved;
  userAllowanceArray['0x0709e1356F765a6F88632B539E209a5d6864B765'.toLowerCase()] = boostedCakeObj.allowance;
  userMarketsArray['0x0709e1356F765a6F88632B539E209a5d6864B765'] = boostedCakeObj;

  let newBoostedCakeKey = '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9';
  let newBoostedCakeObj = userLPArray[newBoostedCakeKey] ? userLPArray[newBoostedCakeKey] : {};
  newBoostedCakeKey = newBoostedCakeKey.toLowerCase();
  newBoostedCakeObj.sharePrice = newBoostedCakeObj.LPTokenUSD && newBoostedCakeObj.LPTokens ? newBoostedCakeObj.LPTokenUSD / newBoostedCakeObj.LPTokens : 0;
  newBoostedCakeObj.totalShares = 0;
  newBoostedCakeObj.vaultBalance = 0;
  newBoostedCakeObj.vaultBalanceUSD = 0;
  newBoostedCakeObj.marketAddress = "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82";
  newBoostedCakeObj.wantAddress = "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82";
  newBoostedCakeObj.strategyAddress = "0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9";
  newBoostedCakeObj.allowance = userAllowanceArray['0xf18f4770ee76e6459b6b2cecc919ea5d3c58e8d9'];
  newBoostedCakeObj.isAllowanceApproved = newBoostedCakeObj.allowance > 0 ? true : false;
  userMarketsArray['0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'] = newBoostedCakeObj;
  newBoostedCakeObj.withdrew = false;
  newBoostedCakeObj.amountWithdrawn = 0;
  let withDrawDetails: any = []
  //check vault balance
  if(newBoostedCakeObj.vaultBalanceUSD !== 0){
    withDrawDetails = userAddress ? await checkUserCakeWithdrawDetails(userAddress): {};
    if(withDrawDetails.withdrew){
      newBoostedCakeObj.vaultBalance = 0;
      newBoostedCakeObj.vaultBalanceUSD = 0;
      newBoostedCakeObj.withdrew = withDrawDetails.withdrew;
      newBoostedCakeObj.amountWithdrawn = withDrawDetails.amountWithdrawn;
    }
    if(newBoostedCakeObj.vaultBalance === 0) {
      newBoostedCakeObj.withdrew = true;
      newBoostedCakeObj.amountWithdrawn = 0;
    }
  }
  // console.log(boostedCakeObj, newBoostedCakeObj);
  // console.log("underLyingPriceDataArray", underLyingPriceDataArray);
  userAllowanceArray['0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()] = newBoostedCakeObj.allowance;

  balanceInGreen = newVaultsDetails.balanceInGreen;
  balance_in_vaults = newVaultsDetails.balance_in_vaults;
  green_apy = newVaultsDetails.green_apy;
  userMarketsArray = newVaultsDetails.userMarketsArray;
  let supplied_list: any = newVaultsDetails.supplied_list;
  // console.log("green apy before", green_apy);
  // if (balanceInGreen !== 0) {
  //   green_apy = (green_apy / balanceInGreen) * 100;
  // }
  // console.log("userMarketsArray", userMarketsArray);
  // console.log("total gamma balance after", totalGammaStaked);
  // console.log("green_apy", green_apy);
  // console.log("green_balance", balanceInGreen);
  
  // console.log(burnApyTime - finalVaultsTime)
  
  const newInfVaultTimestamp = new Date().getTime();
  // console.log("time taken to load new inf vaults data", newInfVaultTimestamp - burnApyTime)
  // console.log("infinityVaultBalance", infinityVaultBalance, gammaPrice)
  let infinity_vault_details_old = oldInfinityVaultData[gamma_infinity_vault_address_old] && oldInfinityVaultData[gamma_infinity_vault_address_old].userAddress ? oldInfinityVaultData : "";

  let infinityVaultBalanceOld: any = [];

  infinityVaultBalanceOld = [0,0];
  
  const oldInfVaultTimestamp = new Date().getTime();
  // console.log("time taken to load old inf vaults data", oldInfVaultTimestamp - newInfVaultTimestamp)
  const old_inf_vaults = [gamma_infinity_vault_address_old, aqua_infinity_vault_address_old];
  // const infinityVaultBalanceOld: any = [gamma_inf_bal_old, aqua_inf_bal_old]

  const infinity_balance = infinityVaultBalance[1] * aquaPrice + infinityVaultBalance[0] * gammaPrice;//infinityVaultBalance[aqua_infinity_vault_address_old] * aquaPrice + infinityVaultBalance[gamma_infinity_vault_address_old] * gammaPrice;

  const infinity_balance_old = infinityVaultBalanceOld[1] * aquaPrice + infinityVaultBalanceOld[0] * gammaPrice;
  // console.log("inf apy for gamma", inf_details[0].apy)
  let gammaInfApr = 0;
  if( infinity_vault_details != ""){
    gammaInfApr = infinityVaultData[0].apr;
  } else {
    gammaInfApr = inf_details[0].apr;
  } 
  //let gammaSupplyApy = getTotalGammaSupplyApy[gGamma] && infinity_vault_details == "" ? getTotalGammaSupplyApy[gGamma] : 0;
  //let gammaSupplyApr = (Math.pow((gammaSupplyApy / 100 + 1), (1 / 365)) - 1) * 365 * 100
  //gammaInfApr += gammaSupplyApr;
  // console.log("gammaInfApy", gammaInfApy)
  let gammaInfOldApr = 0;

  let aquaInfApr = 0; 
  if(infinity_vault_details != "") {
    aquaInfApr = infinityVaultData[1].apr
  } else {
    aquaInfApr = inf_details[1].apr;
  } 
  
  let aquaInfOldApr = 0; 

  let total_balance: any = balanceInBlue + balanceInGreen + infinity_balance_old;
  // console.log("total_balance", total_balance , balanceInBlue, balanceInGreen, infinity_balance, infinity_balance_old);
  // console.log("aquaInfApy", aquaInfApy);
  balance_in_vaults += infinity_balance ? infinity_balance : 0;
  balance_in_vaults += infinity_balance_old ? infinity_balance_old : 0;

  let weightedGammaApy: any = infinityVaultBalance[0] !== 0 ? (gammaInfApr * infinityVaultBalance[0] * gammaPrice )/ 100 : 0;//infinityVaultBalance[gamma_infinity_vault_address_old] !== 0 ? gammaInfApy * infinityVaultBalance[gamma_infinity_vault_address_old] * gammaPrice : 0;
  // console.log("weightedGammaApy before", weightedGammaApy);
  weightedGammaApy += infinityVaultBalanceOld[0] !== 0 ? gammaInfOldApr * infinityVaultBalanceOld[0] * gammaPrice : 0;
  // console.log("sweightedGammaApy after", weightedGammaApy);
  let weightedAquaApy: any = infinityVaultBalance[1] !== 0 ? (aquaInfApr * infinityVaultBalance[1] * aquaPrice) /100 : 0;//infinityVaultBalance[aqua_infinity_vault_address_old] !== 0 ? aquaInfApy * infinityVaultBalance[aqua_infinity_vault_address_old] * aquaPrice : 0
  // console.log("weightedAquaApy before", weightedAquaApy);
  weightedAquaApy += infinityVaultBalanceOld[1] !== 0 ? aquaInfOldApr * infinityVaultBalanceOld[1] * aquaPrice : 0
  // console.log("weightedAquaApy after", weightedAquaApy);

  if (total_balance !== 0) {
    // console.log("green_apy", green_apy);
    // console.log("blue_apy", blue_apy);
    totalApy = (balanceInBlue * blue_apy) + (balanceInGreen * green_apy) + (weightedGammaApy) + (weightedAquaApy);
    totalApy /= total_balance;
  }
  total_balance += oldTokenBalance
  // console.log("apy breakdown", balanceInBlue ,poolInterestPerYear , balanceInGreen , green_apy , infinityVaultBalance[0] * gammaPrice, weightedGammaApy , infinityVaultBalance[1] * aquaPrice, weightedAquaApy, gammaInfApr);
  // console.log("totalApy", totalApy);
  // totalApy /= balance_in_vaults;
  const currentApr = total_balance > 0 ? (poolInterestPerYear + green_apy + weightedGammaApy + weightedAquaApy) * 100/(balanceInBlue + balanceInGreen + infinityVaultBalance[0] * gammaPrice + infinityVaultBalance[1] * aquaPrice) : 0//(Math.pow(totalApy / 100 + 1, 1 / 365) - 1) * 365;
  totalApy = (Math.pow((1 + (currentApr)/36500), 365) - 1) * 100;
  // console.log("apr", currentApr, "apy", totalApy)
  const dailyEarning = (currentApr * total_balance)/ 365;
  let daily_earning = dailyEarning;
  // console.log("pendingAquaRewards", pendingEarningsUSD - uranusRewardsTotal);
  // console.log("pendingUranusRewards", uranusRewardsTotal);
  // console.log("pendingEarningsUSD", pendingEarningsUSD);
  // console.log("greenPendingUsd", greenPendingUsd);
  // console.log("greenPending", greenPendingGAMMA);
  
  const timebeforegammacall = new Date().getTime();
  // console.log("timestamp for portfolio without gamma calculation", timebeforegammacall - initialTimestamp)
  //get Gamma Balance
  let AQUA_GAMMA_LP_Address = AQUA_GAMMALPAddress.toLowerCase();
  let GAMMA_BNB_LP_Address = GAMMA_BNBLPAddress.toLowerCase();
  let AQUA_BNB_LP_Address = AQUA_BNBLPAddress.toLowerCase();
  let Gamma_Address = gammaAddress.toLowerCase();
  let Aqua_Address = aquaAddress.toLowerCase();
  let totalSupplyTokenArray = [AQUA_GAMMA_LP_Address, GAMMA_BNB_LP_Address, AQUA_BNB_LP_Address, Gamma_Address, Aqua_Address];
  let marketArray = [Gamma_Address, Aqua_Address];
  // await totalSupplyMulticall(totalSupplyTokenArray);
  // await balanceOfLPMulticall(totalSupplyTokenArray, marketArray, userAddress);
  // console.log("balanceLPArray", balanceLPArray)
  // console.log("totalSupplyArray", totalSupplyArray, totalSupplyArray[AQUA_GAMMA_LP_Address])
  // console.log("balanceMarketArray",balanceMarketArray)

  let gammaInBnbGammaVault: any = 0;
  let gammaInAquaGammaVault: any = 0;
  let aquaGammaKey = '0xB87F7016585510505478D1d160BDf76c1f41b53d_0'.toLowerCase();
  let gammaBNBKey = '0xB87F7016585510505478D1d160BDf76c1f41b53d_17'.toLowerCase();
  let gammaTokenAddress = gGamma.toLowerCase();

  let userSharesAquaGamma = userSharesArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_0']
    ? +userSharesArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_0']
    : 0;
  let userLPAquaGamma = userLPWalletArray[wantAddressArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_0']]
    ? +userLPWalletArray[wantAddressArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_0']]
    : 0;
  let lpInAquaGammaVault = userSharesAquaGamma + userLPAquaGamma;
  // console.log("lpInAquaGammaVault", lpInAquaGammaVault);
  //0xB87F7016585510505478D1d160BDf76c1f41b53d_17
  let userSharesGammaBnb = userSharesArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_17']
    ? +userSharesArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_17']
    : 0;
  userSharesGammaBnb += stakedTokenArr['0x9EBce8B8d535247b2a0dfC0494Bc8aeEd7640cF9_3']
  ? +stakedTokenArr['0x9EBce8B8d535247b2a0dfC0494Bc8aeEd7640cF9_3'] : 0;

  let userLPGammaBnb = userLPWalletArray[wantAddressArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_17']]
    ? +userLPWalletArray[wantAddressArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_17']]
    : 0;

  userLPGammaBnb += userLPWalletArrayNew[wantAddressArray['0x9EBce8B8d535247b2a0dfC0494Bc8aeEd7640cF9_3']]
    ? +userLPWalletArrayNew[wantAddressArray['0x9EBce8B8d535247b2a0dfC0494Bc8aeEd7640cF9_3']]
    : 0;

  let lpInBnbGammaVault = userSharesGammaBnb + userLPGammaBnb;

  // console.log("lpInBnbGammaVault ", lpInBnbGammaVault, "userSharesGammaBnb ", userSharesGammaBnb);

  if (lpInAquaGammaVault !== 0 && userAddress) {
    let lpTotalSupply: any = totalSupplyArray[AQUA_GAMMA_LP_Address] ? totalSupplyArray[AQUA_GAMMA_LP_Address] : 0;
    let contractGammaBalance: any = balanceLPArray[AQUA_GAMMA_LP_Address] ? balanceLPArray[AQUA_GAMMA_LP_Address] : 0;
    gammaInAquaGammaVault = lpTotalSupply > 0 ? noExponents((+lpInAquaGammaVault / +lpTotalSupply) * +contractGammaBalance) : 0;
    // console.log("gammaInAquaGammaVault in portfolio", gammaInAquaGammaVault, lpTotalSupply, contractGammaBalance, totalSupplyArray, AQUA_GAMMA_LP_Address, balanceLPArray );
  }

  if (lpInBnbGammaVault !== 0 && userAddress) {
    let lpTotalSupply: any = totalSupplyArray[GAMMA_BNB_LP_Address];
    let contractGammaBalance: any = balanceLPArray[GAMMA_BNB_LP_Address];
    gammaInBnbGammaVault = lpInBnbGammaVault && lpTotalSupply ?noExponents((+lpInBnbGammaVault / +lpTotalSupply) * +contractGammaBalance) : 0;
    // console.log("gammaInBnbGammaVault in portfolio", gammaInBnbGammaVault);
  }

  const gammaInNewGamma = supplyBalanceArr[gammaTokenAddress]
    ? +supplyBalanceArr[gammaTokenAddress]
    : 0; //await getSupplyBalance(gGamma,userAddress);
  // console.log("supplyBalanceArr", supplyBalanceArr, balanceMarketArray)
  // const gammaInOldGamma = await getSupplyBalance(oldgGamma,userAddress);
  // let gammaSupply = parseFloat(JSON.stringify(totalSupplyArray[gammaAddress]));
  balanceMarketArray[Gamma_Address] = balanceMarketArray[gammaTokenAddress] ? balanceMarketArray[gammaTokenAddress] : 0
  const userGammaBalance: any = +balanceMarketArray[Gamma_Address] + gammaInNewGamma + parseFloat(gammaInAquaGammaVault) + parseFloat(gammaInBnbGammaVault) + parseFloat(infinityVaultBalance[0]) + +parseFloat(infinityVaultBalanceOld[0]) + parseFloat(v3_LpArrayData.gammaInGammaBtcbPool);;
    //+balanceMarketArray[Gamma_Address] + gammaInNewGamma + parseFloat(gammaInAquaGammaVault) + parseFloat
    // console.log("gamma balance breakdown", +balanceMarketArray[Gamma_Address], gammaInNewGamma , parseFloat(gammaInAquaGammaVault) , parseFloat(gammaInBnbGammaVault) , parseFloat(infinityVaultBalance[0]) , +parseFloat(infinityVaultBalanceOld[0]))
  const userGammaBalanceUSD = userGammaBalance * gammaPrice
  //AQUA balance
  let aquaInBnbAquaVault: any = 0
  let aquaInAquaGammaVault: any = 0
  let AquaBnbAddress = '0xB87F7016585510505478D1d160BDf76c1f41b53d_1'.toLowerCase();
  let AquaTokenInNewGamma = '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase();
  // console.log("lpInAquaGammaVault for aqua", lpInAquaGammaVault);
  let userSharesAquaBnb = userSharesArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_1']
    ? +userSharesArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_1']
    : 0
    userSharesAquaBnb += stakedTokenArr['0x9EBce8B8d535247b2a0dfC0494Bc8aeEd7640cF9_2']
  ? +stakedTokenArr['0x9EBce8B8d535247b2a0dfC0494Bc8aeEd7640cF9_2'] : 0;
  let userLPAquaBnb = userLPWalletArray[wantAddressArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_1']]
    ? +userLPWalletArray[wantAddressArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_1']]
    : 0
  userLPAquaBnb += userLPWalletArrayNew[wantAddressArrayNew['0x9EBce8B8d535247b2a0dfC0494Bc8aeEd7640cF9_2']]
    ? +userLPWalletArrayNew[wantAddressArrayNew['0x9EBce8B8d535247b2a0dfC0494Bc8aeEd7640cF9_2']]
    : 0
  let lpInBnbAquaVault = userSharesAquaBnb + userLPAquaBnb
  // console.log("lpInBnbAquaVault", lpInBnbAquaVault, userLPWalletArrayNew, stakedTokenArr, userSharesArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_1'], stakedTokenArr['0x9EBce8B8d535247b2a0dfC0494Bc8aeEd7640cF9_2'], userLPWalletArray[wantAddressArray['0xB87F7016585510505478D1d160BDf76c1f41b53d_1']], userSharesAquaBnb, userLPAquaBnb);
  if (lpInAquaGammaVault !== 0 && userAddress) {
    /*
    if (tokenArray[i] === AQUA_BNBLPAddress.toLowerCase()) {
        tokenInstance = await selectInstance(instType.PANCAKELP, aquaAddress)
        targets.push(aquaAddress)
      } else {
        tokenInstance = await selectInstance(instType.PANCAKELP, gammaAddress)
        targets.push(gammaAddress)
      }
      const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.balanceOf(tokenArray[i])._method, [tokenArray[i]])
    */
    let aquaLPInstance = new wallet.web3.eth.Contract(pancakeLPabi, aquaAddress);
    const contractLPBalance = await aquaLPInstance.methods.balanceOf("0xcCaF3fcE9f2D7A7031e049EcC65c0C0Cc331EE0D").call();
    let contractLPBalanceNormalised = convertToEther(contractLPBalance);
    let lpTotalSupply: any = totalSupplyArray[AQUA_GAMMA_LP_Address] ? totalSupplyArray[AQUA_GAMMA_LP_Address] : 0
    let contractAquaBalance: any = contractLPBalanceNormalised;//balanceLPArray[AQUA_GAMMA_LP_Address]
    aquaInAquaGammaVault = +lpTotalSupply > 0 ?noExponents((+lpInAquaGammaVault / +lpTotalSupply) * +contractAquaBalance) : 0;
    // console.log("aquaInAquaGammaVault in portfolio", aquaInAquaGammaVault, lpInAquaGammaVault, lpTotalSupply, contractAquaBalance, contractLPBalance);
  }
  if (lpInBnbAquaVault !== 0 && userAddress) {
    let lpTotalSupply: any = totalSupplyArray[AQUA_BNB_LP_Address]
    let contractAquaBalance: any = balanceLPArray[AQUA_BNB_LP_Address]
    aquaInBnbAquaVault = lpTotalSupply && lpInBnbAquaVault ? noExponents((+lpInBnbAquaVault / +lpTotalSupply) * +contractAquaBalance) : 0;
    // console.log("aquaInBnbAquaVault in portfolio", aquaInBnbAquaVault, lpTotalSupply, contractAquaBalance, balanceLPArray,totalSupplyArray);
  }
  let aquaInNewGamma = supplyBalanceArr[AquaTokenInNewGamma]
    ? +supplyBalanceArr[AquaTokenInNewGamma]
    : 0 //await getSupplyBalance(gAqua,userAddress);
  // console.log("supplyBalanceArr", supplyBalanceArr, balanceMarketArray, supplyBalanceArr[gAqua.toLowerCase()], AquaTokenInNewGamma)
  //Aqua in infinity vaults
  balanceMarketArray[Aqua_Address] = balanceMarketArray[AquaTokenInNewGamma] ? balanceMarketArray[AquaTokenInNewGamma] : 0
  aquaInNewGamma += supplyBalanceArr[gAqua.toLowerCase()] ? supplyBalanceArr[gAqua.toLowerCase()] : 0;
  // gammaInGammaBtcbPool, aquaInAquaBtcbPool, aquaInAquaPool
  const userAquaBalance: any = +balanceMarketArray[Aqua_Address] + aquaInNewGamma + parseFloat(aquaInAquaGammaVault) + parseFloat(aquaInBnbAquaVault)+ +parseFloat(infinityVaultBalance[1]) + +parseFloat(infinityVaultBalanceOld[1]) + parseFloat(v3_LpArrayData.aquaInAquaBtcbPool) + parseFloat(v3_LpArrayData.aquaInAquaPool);
    //+balanceMarketArray[Aqua_Address] + aquaInNewGamma + parseFloat(aquaInAquaGammaVault) + parseFloat(aquaInBnbAquaVault)+ +parseFloat(infinityVaultBalance[aqua_infinity_vault_address_old]);
  // console.log("aquaInNewGamma in portfolio", userAquaBalance, infinityVaultBalance[1], infinityVaultBalanceOld[1], balanceMarketArray[Aqua_Address], aquaInNewGamma, aquaInAquaGammaVault, aquaInBnbAquaVault);
  const userAquaBalanceUSD = userAquaBalance * aquaPrice;
  const timeaftergammacall = new Date().getTime()
  // console.log("timestamp for portfolio WITH AQUA  calculation");
  let pendingGammaAllPools: any = 0;
  try{
    const farm_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
    pendingGammaAllPools = await farm_inst.methods.pendingGAMMAAllPools(userAddress).call();
    pendingGammaAllPools = pendingGammaAllPools/1e18;
  } catch (error){
    console.log(error);
  }

  let pendingPoolEarningsUSD = pendingGammaAllPools * gammaPrice;
  
  let portfolio_data = {
    balance_in_vaults : total_balance,
    current_apy: isFinite(totalApy) && totalApy < 10000 ? totalApy : (!isFinite(totalApy) || totalApy > 10000) ? 10000  : 0,
    pending_earnings: pendingEarningsUSD + parseFloat(greenPendingUsd) + +pendingPoolEarningsUSD + (+v3_gammaRewards * gammaPrice),
    daily_earning,
    userLPArray,
    userMarketsArray,
    userLevel,
    supplied_list,
    current_apr: currentApr,
    userGammaBalance,
    userGammaBalanceUSD,
    userAquaBalance,
    userAquaBalanceUSD,
    greenPendingGAMMA,
    greenPendingUsd,
    gammaPrice,
    aquaPrice,
    pendingGammaAllPools,
    newLpArrayData : newLpArrayData.userLPArray,
    V3_LPArray: v3_LpArrayData.userLPArray,
    user_gamma_btcb_balance_in_v3 : v3_LpArrayData.user_gamma_btcb_balance_in_v3, 
    other_pool_balance_in_v3: v3_LpArrayData.other_pool_balance_in_v3,
    currentApr,
    userAddress,
    v3_gammaRewards
  }

  return portfolio_data
}

export const getPortfolioData = async (userAddress?: any) => {
  // let timestamp = new Date().getTime()

  const portfolioData = await getPortfolioDataNew(userAddress);
  // let timestamp1 = new Date().getTime()
  // console.log("New portfolioData", portfolioData);
  // console.log(" New portfolioData time taken", timestamp1 - timestamp);
  return portfolioData;
}

const sendGammaToPoorCompUsers = async () => {
  const privateKey = "";
  let tokenAddress = "0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15";
  let toAddress = "0x19d4051f7740e6aa4494ebcc655a70f524878346";//"0xA719151aDDBf8EbAdD68AcBF13A93aED0B729fA6";
  let fromAddress = "0xc358615Fb090Db0284a73Ac690B264BFDCa141f0";
  let gammaPrice = await getGammaPrice()
  
  let bnbPrice = await getBnbPrice()
 
 let contract = new wallet.web3.eth.Contract(gammaAbi, tokenAddress, { from: fromAddress })
 
 let amount = convertToWei(1);//new wallet.web3.utils.toHex(new wallet.web3.utils.toWei("1")); //1 DEMO Token
 
 let data = contract.methods.transfer(toAddress, amount).encodeABI()
 console.log("send data",data, amount);
 sendErcToken()
 const nonce = await wallet.web3.eth.getTransactionCount(fromAddress, 'latest');
function sendErcToken() {

   let txObj = {

       gas: 100000,

       "to": toAddress,

       "value": gammaPrice/bnbPrice,//286141568355308,

       "data": data,

       "from": fromAddress,

       'maxFeePerGas': 1000000108,

       'nonce': nonce,

   }
   //console.log(txObj)
   wallet.web3.eth.accounts.signTransaction(txObj, privateKey, (err: any, signedTx: any) => {

       if (err) {
        console.log("err", err)
           return; //callback(err)

       } else {

           //console.log(signedTx)

           return wallet.web3.eth.sendSignedTransaction(signedTx.rawTransaction, (err: any, res:any) => {

               if (err) {

                   console.log(err)

               } else {

                   console.log(res)

               }

           })

       }

   })

}

  // var myContractInstance = new wallet.web3.eth.Contract(contractABI, tokenAddress, { from: fromAddress });
  // myContractInstance.transfer({"to": tokenAddress,"value": "0x00"})
  // .send({
  //   from: fromAddress, 
  //   gas: 0x00, 
  //   gasPrice: 0x00
  //   }).then((receipt: any)=> {console.log(receipt)});
  // return;
}

const sendTx = async (transaction: any, userAddress: any) => {
  const privateKey = "";
  let tokenAddress = "0xb3cb6d2f8f2fde203a022201c81a96c167607f15";
  // let toAddress = "0x19d4051f7740e6aa4494ebcc655a70f524878346";//"0xA719151aDDBf8EbAdD68AcBF13A93aED0B729fA6";
  // let fromAddress = "0xc358615Fb090Db0284a73Ac690B264BFDCa141f0";
  //console.log("transaction details", transaction)
  const options = {
    to      : tokenAddress,//transaction._parent._address,
    data    : transaction,
    gas     : 100000,//await transaction.estimateGas({from: fromAddress}),
    // gasPrice: 100000,
    // maxFeePerGas: 1000000108,
  };
  try {
    const signed  = await wallet.web3.eth.accounts.signTransaction(options, privateKey);
    const receipt = await wallet.web3.eth.sendSignedTransaction(signed.rawTransaction);
    return receipt;
  } catch(err){
    console.log("error in send tx", userAddress, err)
    return "error for userAddress" + userAddress
  }
  
}

const sendGammaToPoorCompUsersMulticall = async() => {
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  let tokenAddress = "0xb3cb6d2f8f2fde203a022201c81a96c167607f15";
  let contract = new wallet.web3.eth.Contract(gammaAbi, tokenAddress)
  let userArray: any = [""];
  let gamma = 0.055;
  let bnb = 285.24 
  let amount = convertToWei(1);

  let targets: any = [];
  let callDatas: any = [];
  let results:any = [];
  let ouput_format : any = [];

  userArray.forEach(async(userId: any, index: any) => {
    
    targets.push(tokenAddress);
    try{
      const data: any = (wallet.web3.eth.abi.encodeFunctionCall(contract.methods.transfer(userId, amount)._method,[userId, amount]));
      callDatas.push(data);
      ouput_format.push(contract.methods.transfer(userId, amount)._method.outputs)
    } catch(error){
      console.log(error);
      return [0, 0];
    }
  })

  //console.log(targets, callDatas)
  for(let i = 0 ; i < userArray.length ; i++){
    const receipt = await sendTx(callDatas[i], userArray[i]);
    //console.log("transation receipt", userArray[i], receipt)
  }
  return
  const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());
  console.log("aggregated data", aggregated_data)

  const do_split = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }  

  for(let i = 0 ; i < aggregated_data[1].length ; i++){
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i],aggregated_data[1][i]))
    const receipt = await sendTx(callDatas[i],i);
    console.log("transation receipt", receipt)
  }
  const split_arr = (await do_split(results, userArray.length));

  console.log("multicall data", split_arr)
  
}

const fetchNewLpDetails = async(userAddress: string, poolIdArray: any, wantAddressArray: any, stratAddressArray: any, userLPWalletArray: any, userSharesArray: any, gammaPrice: any, lpTokenPrice: any, totalBalanceBlueUSD: any, blue_apy: any, farmApyArray: any, tradeFeeApy: any, userAllowanceArray: any, aquaPrice: any, boostApyArr: any) => {
  let userLPArray: any = [];
  let balanceInBlue: any = 0;
  poolIdArray.forEach((poolId: any, index: any) => {
    totalBalanceBlueUSD = userSharesArray[poolId] == userSharesArray[poolId] ? +userSharesArray[poolId] * +lpTokenPrice[poolId] : 0;
    balanceInBlue += totalBalanceBlueUSD;
    // console.log("wantaddress data", poolId, wantAddressArray[poolId])
    let boostApy = userSharesArray[poolId] == userSharesArray[poolId] && +userSharesArray[poolId] > 0 && boostApyArr[poolId].userBoostApy ? boostApyArr[poolId].userBoostApy : boostApyArr[poolId].averageBoostApy ? boostApyArr[poolId].averageBoostApy : 0 ;
    if (wantAddressArray[poolId] !== undefined && tradeFeeApy[wantAddressArray[poolId]] !== undefined) {
      // console.log("trade apy", tradeFeeApy[wantAddressArray[poolId]].apy)
      // console.log("total apy", farmApyArray[poolId] + tradeFeeApy[wantAddressArray[poolId]].apy)
      
      blue_apy += (totalBalanceBlueUSD * (farmApyArray[poolId] + tradeFeeApy[wantAddressArray[poolId]].apy) + boostApy) / 100;
    } else {
      blue_apy += (totalBalanceBlueUSD * (farmApyArray[poolId] + boostApy)) / 100;
    }

    if (wantAddressArray[poolId] !== undefined) {
      let farmAddress = poolId.split('_')[0];
      let pid = poolId.split('_')[1];
      lpTokenPrice[poolId] = pid == 0 ? gammaPrice : pid == 1 ? aquaPrice : lpTokenPrice[poolId];
      let lpAddressKey = wantAddressArray[poolId] + '_' + farmAddress;

      if (userLPArray[lpAddressKey] === undefined) {
        
        let key = poolId;
        userLPArray[lpAddressKey] = {};
        userLPArray[lpAddressKey].wantAddress = wantAddressArray[poolId];
        userLPArray[lpAddressKey].farmAddress = farmAddress;
        userLPArray[lpAddressKey].wantAddress_farmAddress = lpAddressKey;
        userLPArray[lpAddressKey].poolId = key;
        userLPArray[lpAddressKey].userRewards = 0;//userRewardsArray[key] ? +userRewardsArray[key] : 0;
        userLPArray[lpAddressKey].userRewardsUSD = 0;//userRewardsArray[key] && +userRewardsArray[key] * gammaPrice > 0.01 ? +userRewardsArray[key] * gammaPrice : 0;
        userLPArray[lpAddressKey].uranusRewardsUSD = 0;//pendingUranusRewardsArray[key] && +pendingUranusRewardsArray[key] * gammaPrice > 0.01 ? +pendingUranusRewardsArray[key] * gammaPrice : 0;
        userLPArray[lpAddressKey].uranusRewards = 0;//pendingUranusRewardsArray[key] ? +pendingUranusRewardsArray[key] : 0;
        userLPArray[lpAddressKey].LPTokens = userSharesArray[key] == userSharesArray[key] ? +userSharesArray[key] : 0;
        userLPArray[lpAddressKey].LPTokenUSD = +userLPArray[lpAddressKey].LPTokens * lpTokenPrice[key] > 0.01 ? +userSharesArray[key] * lpTokenPrice[key] : 0;
        userLPArray[lpAddressKey].allowance = userAllowanceArray[lpAddressKey.toLowerCase()] ? userAllowanceArray[lpAddressKey.toLowerCase()] : 0;
        userLPArray[lpAddressKey].isAllowanceApproved =
          userAllowanceArray[lpAddressKey.toLowerCase()] && userAllowanceArray[lpAddressKey.toLowerCase()] > 0 ? true : false;
        userLPArray[lpAddressKey].walletBalance = userLPWalletArray[lpAddressKey.toLowerCase()];
        // console.log("userLPWalletArray[lpAddressKeys]", lpAddressKey, userLPWalletArray[lpAddressKey.toLowerCase()]);
        userLPArray[lpAddressKey].walletBalanceUSD = +userLPWalletArray[wantAddressArray[key]] * lpTokenPrice[key] > 0.01 ? +userLPWalletArray[wantAddressArray[key]] * lpTokenPrice[key] : 0;
        userLPArray[lpAddressKey].userBoostApy = boostApyArr[poolId].userBoostApy ? boostApyArr[poolId].userBoostApy : 0;
        userLPArray[lpAddressKey].averageBoostApy = boostApyArr[poolId].averageBoostApy ? boostApyArr[poolId].averageBoostApy : 0;
        userLPArray[lpAddressKey].gammaBoostPercent = boostApyArr[poolId].gammaBoostPercent ? boostApyArr[poolId].gammaBoostPercent : 0;
      }
    }
  })
  // console.log("balance in new pools", balanceInBlue)
  return {userLPArray, balanceInBlue, blue_apy};
}

const getInfinityVaultBalance = async (userAddress: string, burnApy: any, gammaSupplyApy: any) => {
  
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);

  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format : any = [];
  let inf_vault_arr: any = [gamma_infinity_vault_address_old, aqua_infinity_vault_address_old];
  let iTokenArr: any = [gamma_infinity_vault_address_old, aqua_infinity_vault_address_old];
  let iTokenABIArr: any = [gamma_infinity_vault_abi_old, aqua_infinity_vault_abi_old];
  let iStrategyAddressArr: any = [gamma_strategy_address, aqua_strategy_address];
  let iStrategyABIArr: any = [gamma_strategy_abi, aqua_strategy_abi];
  let tokenArr: any = [gammaAddress, aquaAddress]; 
  let gTokenArr: any = [gGammaOld, gAquaOld]; 

  let underlyingPriceArr: any = [];
  let gTokenExchangeRateArr: any = [];
  let iTokenExchangeRateArr: any = [];
  let totalSupplyArr: any = [];
  let poolInfoArr: any = [];
  let weeklyFeeRewardsArr: any = [];
  let userInfoArr: any = [];
  let underlyingTokenBalance: any = [];
  let gTokenBalanceArr: any = [];
  let gTokenStakingBalance: any = [];  
        
  try{
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbiOld, gammaFarmAdddressOld);
    let total_alloc_point: any = await farm_inst.methods.totalAllocPoint().call();
    const reservoirDripRate: any = await farm_inst.methods.GAMMAPerBlock().call()
    let gammaPrice: any = await getGammaPrice();
    // //underlying price
    gTokenArr.forEach(async(gTokenAddress: any) => {
      const oracle_inst: any =  new wallet.web3.eth.Contract(priceOracleAbiOld, priceOracleAddressOld)
      let gToken = gTokenAddress;
      targets.push(priceOracleAddressOld);
      const data = wallet.web3.eth.abi.encodeFunctionCall(oracle_inst.methods.getUnderlyingPrice(gToken)._method,[gToken]);
      callDatas.push(data); 
      ouput_format.push(oracle_inst.methods.getUnderlyingPrice(gToken)._method.outputs)
    })

    //gtoken exchange rate
    gTokenArr.forEach(async(gTokenAddress: any) => {
      let gToken = gTokenAddress;
      const gToken_inst: any =  new wallet.web3.eth.Contract(gBnbAbi, gToken)
      targets.push(gToken);
      const data = wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.exchangeRateStored()._method,[]);
      callDatas.push(data); 
      ouput_format.push(gToken_inst.methods.exchangeRateStored()._method.outputs)    
    })

    //itoken exchange rate
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      let iToken_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
      targets.push(iTokenArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(iToken_inst.methods.iTokenExchangeRate()._method,[]);
      callDatas.push(data); 
      ouput_format.push(iToken_inst.methods.iTokenExchangeRate()._method.outputs)    
    })

    //total supply
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      let infinity_vault_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
      targets.push(iTokenArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(infinity_vault_inst.methods.totalSupply()._method,[]);
      callDatas.push(data); 
      ouput_format.push(infinity_vault_inst.methods.totalSupply()._method.outputs)    
    })

    //pool info
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      targets.push(gammaFarmAdddressOld);
      let pool_id = index == 0 ? 59 : 58;
      const data = wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.poolInfo(pool_id)._method,[pool_id]);
      callDatas.push(data); 
      ouput_format.push(farm_inst.methods.poolInfo(pool_id)._method.outputs)    
    })
    
    //weekly fee rewards
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      let iToken_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
      targets.push(iTokenArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(iToken_inst.methods.feeRewardsAccruedPerWeek()._method,[]);
      callDatas.push(data); 
      ouput_format.push(iToken_inst.methods.feeRewardsAccruedPerWeek()._method.outputs)    
    })

    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      let token_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
      targets.push(iTokenArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.balanceOfGtoken()._method,[]);
      callDatas.push(data); 
      ouput_format.push(token_inst.methods.balanceOfGtoken()._method.outputs)
    })

    if(userAddress){
      //user info
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        let token_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
        targets.push(iTokenArr[index]);
        const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.userInfo(userAddress)._method,[userAddress]);
        callDatas.push(data); 
        ouput_format.push(token_inst.methods.userInfo(userAddress)._method.outputs)
      })

      //underlying token balance
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        let token_inst = new wallet.web3.eth.Contract(pancakeLPabi, tokenArr[index])
        targets.push(tokenArr[index]);
        const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.balanceOf(userAddress)._method,[userAddress]);
        callDatas.push(data); 
        ouput_format.push(token_inst.methods.balanceOf(userAddress)._method.outputs)
      })

      //gToken staking bal
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        let token_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
        targets.push(iTokenArr[index]);
        const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.getUserStakingGtokenBal(userAddress)._method,[userAddress]);
        callDatas.push(data); 
        ouput_format.push(token_inst.methods.getUserStakingGtokenBal(userAddress)._method.outputs)
      })

    }
    const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());
    const do_split = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }  

    for(let i = 0 ; i < aggregated_data[1].length ; i++){
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
  
    const split_arr = (await do_split(results, inf_vault_arr.length));
    underlyingPriceArr = split_arr[0];
    gTokenExchangeRateArr = split_arr[1];
    iTokenExchangeRateArr = split_arr[2];
    totalSupplyArr = split_arr[3];
    poolInfoArr = split_arr[4];
    weeklyFeeRewardsArr = split_arr[5];
    gTokenBalanceArr = split_arr[6];
    if(userAddress){
      userInfoArr = split_arr[7];
      underlyingTokenBalance = split_arr[8];
      gTokenStakingBalance = split_arr[9];
    }
        
    // console.log("multicall arr data", split_arr)
    let infinity_vault_data: any = [];
    let vault_data: any = {};
    for(let i = 0; i < inf_vault_arr.length; i ++){
      let gTokenExchangeRate = gTokenExchangeRateArr[i][0];
      const decimalRatio = 36;
      const noOfGtokenInOneToken = Math.pow(10, decimalRatio) / gTokenExchangeRate / Math.pow(10, 8);
      let priceData = {
        gToken: gTokenArr[i],
        exchangeRate: gTokenExchangeRate,
        decimalRatio,
        underlyingPrice : underlyingPriceArr[i][0],
        gTokenPrice: underlyingPriceArr[i][0] / noOfGtokenInOneToken
      };
      let tvl: any = (totalSupplyArr[i][0] * iTokenExchangeRateArr[i][0] * priceData.gTokenPrice) / (1e18 * 1e8 * 1e18);
      let gammaApy: any = gammaSupplyApy[gTokenArr[i].toLowerCase()];
      let gammaPerBlock = convertToEther(reservoirDripRate);

      let rewards = total_alloc_point > 0 ? (gammaPerBlock * 28800 * (poolInfoArr[i].allocPoint / total_alloc_point) * gammaPrice * 365 * 100) : 0;
      const yield_: any = tvl > 0 ? (+rewards/+tvl): 0;
      let apy = ((1 + yield_ / 36500) ** 365 - 1) * 100;
      apy += gammaApy ? gammaApy : 0;
      if(i === 1 && burnApy !== undefined){
        apy += burnApy;
      } 
      let accuredBalancePerWeek = weeklyFeeRewardsArr[i][0];
      
      let totalGTokenInVault = gTokenBalanceArr[i][0];
      // console.log("totalGTokenInVault", totalGTokenInVault, accuredBalancePerWeek)
      let widthdrawApr = ((accuredBalancePerWeek/totalGTokenInVault) * (365/7));
      let widthdrawApy = (Math.pow((1 + widthdrawApr/(365*3)), (365*3)) -1 ) * 100;
      apy += widthdrawApy == widthdrawApy ? widthdrawApy : 0;
      let exchangeRateDecimal: any = 28;
      let exchangeRate = gTokenExchangeRateArr[i][0] / Math.pow(10, exchangeRateDecimal);
      exchangeRate = 1 / exchangeRate;
      const infinity_want_bal = gTokenStakingBalance[i][0];
      const infinity_unstaking_bal = userInfoArr[i].gTokenToBeUnstaked;
      const infinity_underlying_deposited_bal = ((infinity_want_bal) * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio);

      const infinity_underlying_unstaking_bal = (infinity_unstaking_bal * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio);

      let inf_details = {
        balance: infinity_underlying_deposited_bal + infinity_underlying_unstaking_bal,
        apy
      }
      infinity_vault_data[inf_vault_arr[i]] = inf_details;
    }
    // console.log(" old inf valut balance object", infinity_vault_data)
    return infinity_vault_data;
    } catch(error){
      console.log(error);
      return [];
    }
  
}

export const getInfinityVaultBalanceNew = async (userAddress: string, burnApy: any, gammaSupplyApy: any, underlyingPriceArray: any) => {
  
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  
  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format : any = [];
  let inf_vault_arr: any = [0,1];
  let iTokenArr: any = [gamma_infinity_vault_address, aqua_infinity_vault_address];
  let iTokenABIArr: any = [gamma_infinity_vault_abi, aqua_infinity_vault_abi];
  let iStrategyAddressArr: any = [gamma_strategy_address_new, aqua_strategy_address_new];
  let iStrategyABIArr: any = [gamma_strategy_abi, aqua_strategy_abi];
  let tokenArr: any = [gammaAddress, aquaAddress]; 
  let gTokenArr: any = [gGamma, gAqua]; 

  let underlyingPriceArr: any = [];
  let gTokenExchangeRateArr: any = [];
  let iTokenExchangeRateArr: any = [];
  let totalSupplyArr: any = [];
  let poolInfoArr: any = [];
  let weeklyFeeRewardsArr: any = [];
  let iTokenbalanceOfArr: any = [];
  let userInfoArr: any = [];
  let wantLockedTotalArr: any = [];
  let vaultAllowanceArr: any = [];
  let underlyingTokenBalance: any = [];
  let initial_timestamp = new Date().getTime();
  try{
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress);
    let total_alloc_point: any = await farm_inst.methods.totalAllocPoint().call();
    let reservoir_inst = new wallet.web3.eth.Contract(reservoir_abi, gamma_reservoir);
    const reservoirDripRate: any = await reservoir_inst.methods.farmV2DripRate().call();
    const oracle_inst: any =  new wallet.web3.eth.Contract(priceOracleAbi, priceOracleAddress);
    const gToken_inst: any =  new wallet.web3.eth.Contract(gBnbAbi, gGamma)
    let strategy_inst = new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new);
    let infinity_vault_inst = new wallet.web3.eth.Contract(gamma_infinity_vault_abi, gamma_infinity_vault_address)
    let priceArray = await returnTokenPriceMulticall();
    let gammaPrice: any = priceArray[gammaAddress.toLowerCase()] ? priceArray[gammaAddress.toLowerCase()] : await getGammaPrice();
    let aquaPrice: any = priceArray[aquaAddress.toLowerCase()] ? priceArray[aquaAddress.toLowerCase()] : await getAquaPrice();
    //underlying price
    inf_vault_arr.forEach(async(vault_id: any) => {
      
      let gToken = vault_id == 0 ? gGamma : gAqua;
      targets.push(priceOracleAddress);
      const data = wallet.web3.eth.abi.encodeFunctionCall(oracle_inst.methods.getUnderlyingPrice(gToken)._method,[gToken]);
      callDatas.push(data); 
      ouput_format.push(oracle_inst.methods.getUnderlyingPrice(gToken)._method.outputs)
    })

    //gtoken exchange rate
    inf_vault_arr.forEach(async(vault_id: any) => {
      let gToken = vault_id == 0 ? gGamma : gAqua;
      targets.push(gToken);
      const data = wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.exchangeRateStored()._method,[]);
      callDatas.push(data); 
      ouput_format.push(gToken_inst.methods.exchangeRateStored()._method.outputs)    
    })

    //itoken exchange rate
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      targets.push(iStrategyAddressArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(strategy_inst.methods.iTokenExchangeRate()._method,[]);
      callDatas.push(data); 
      ouput_format.push(strategy_inst.methods.iTokenExchangeRate()._method.outputs)    
    })

    //total supply
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      
      targets.push(iTokenArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(infinity_vault_inst.methods.totalSupply()._method,[]);
      callDatas.push(data); 
      ouput_format.push(infinity_vault_inst.methods.totalSupply()._method.outputs)    
    })

    //pool info
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      targets.push(gammaFarmAdddress);
      const data = wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.poolInfo(vault_id)._method,[vault_id]);
      callDatas.push(data); 
      ouput_format.push(farm_inst.methods.poolInfo(vault_id)._method.outputs)    
    })
   
    //weekly fee rewards
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      
      targets.push(iStrategyAddressArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(strategy_inst.methods.feeRewardsAccruedPerWeek()._method,[]);
      callDatas.push(data); 
      ouput_format.push(strategy_inst.methods.feeRewardsAccruedPerWeek()._method.outputs)    
    })

    //gtoken in inf vaults
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      targets.push(iStrategyAddressArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(strategy_inst.methods.wantLockedTotal()._method,[]);
      callDatas.push(data); 
      ouput_format.push(strategy_inst.methods.wantLockedTotal()._method.outputs)    
    })

    if(userAddress){
      //balance of in itoken
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        targets.push(iTokenArr[index]);
        const data = wallet.web3.eth.abi.encodeFunctionCall(infinity_vault_inst.methods.balanceOf(userAddress)._method,[userAddress]);
        callDatas.push(data); 
        ouput_format.push(infinity_vault_inst.methods.balanceOf(userAddress)._method.outputs)
      })

      //user info
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        targets.push(gammaFarmAdddress);
        const data = wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.userInfo(vault_id, userAddress)._method,[vault_id, userAddress]);
        callDatas.push(data); 
        ouput_format.push(farm_inst.methods.userInfo(vault_id, userAddress)._method.outputs)
      })
    }
  
    const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());
    const do_split = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }  

    for(let i = 0 ; i < aggregated_data[1].length ; i++){
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
  
    const split_arr = (await do_split(results, inf_vault_arr.length));
    underlyingPriceArr = split_arr[0];
    gTokenExchangeRateArr = split_arr[1];
    iTokenExchangeRateArr = split_arr[2];
    totalSupplyArr = split_arr[3];
    poolInfoArr = split_arr[4];
    weeklyFeeRewardsArr = split_arr[5];
    wantLockedTotalArr = split_arr[6]
    if(userAddress){
      iTokenbalanceOfArr = split_arr[7];
      userInfoArr = split_arr[8];
    }
    // console.log(" price array", underlyingPriceArray)
    const vaults = [0,1];
    let totalInfinityVaultBalance: any = [];
    for(let i = 0; i < vaults.length; i++){
      let gTokenExchangeRate = gTokenExchangeRateArr[i][0];
      const decimalRatio = 36;
      const noOfGtokenInOneToken = Math.pow(10, decimalRatio) / gTokenExchangeRate / Math.pow(10, 8);
      // let underlyingPriceVal = i == 0 ? underlyingPriceArray[gGamma.toLowerCase()] : underlyingPriceArray[gAqua.toLowerCase()];
      let priceData = {
        gToken: gTokenArr[i],
        exchangeRate: gTokenExchangeRate,
        decimalRatio,
        underlyingPrice : underlyingPriceArr[i][0],
        gTokenPrice: underlyingPriceArr[i][0] / noOfGtokenInOneToken
      };
      let tvl: any = (totalSupplyArr[i][0] * iTokenExchangeRateArr[i][0] * priceData.gTokenPrice) / (1e18 * 1e8 * 1e18);
      let gammaApy: any = gammaSupplyApy[gTokenArr[i].toLowerCase()];
      let gammaPerBlock = convertToEther(reservoirDripRate);

      let rewards = total_alloc_point > 0 ? (gammaPerBlock * 28800 * (poolInfoArr[i].allocPoint / total_alloc_point) * gammaPrice * 365 * 100) : 0;
      const yield_: any = tvl > 0 ? (+rewards/+tvl): 0;
      let apy = ((1 + yield_ / 36500) ** 365 - 1) * 100;
      // let apr = yield_;
      // console.log("apy", apy, "yield", yield_, i)
      apy += gammaApy ? gammaApy : 0;
      // console.log("gamma apy", gammaApy)
      // apr += gammaApr ? gammaApr : 0;
      if(i === 1 && burnApy !== undefined){
        apy += burnApy;
        // apr += burnApr;
      } 
      
      let totalGTokenInVault = wantLockedTotalArr[i][0];
      // console.log("total gamma tokens in the vault", totalGTokenInVault, gTokenExchangeRate)
      let widthdrawApr = ((weeklyFeeRewardsArr[i][0]/totalGTokenInVault) * (365/7));
      let widthdrawApy = (Math.pow((1 + widthdrawApr/(365*3)), (365*3)) -1 ) * 100;
      // console.log("withdraw apy", i, widthdrawApy, weeklyFeeRewardsArr[i][0])
      apy += widthdrawApy == widthdrawApy ? widthdrawApy : 0;
      // console.log("withdraw apy", widthdrawApy)
      
      if(userAddress){
        let exchangeRateDecimal: any = 28;
        let exchangeRate = gTokenExchangeRateArr[i][0] / Math.pow(10, exchangeRateDecimal);
        exchangeRate = 1 / exchangeRate;
        const infinity_want_bal = (iTokenbalanceOfArr[i][0] * iTokenExchangeRateArr[i][0])/(1e18);
        const infinity_unstaking_bal = userInfoArr[i].gTokenToBeUnstaked;
        const infinity_underlying_deposited_bal = ((infinity_want_bal - infinity_unstaking_bal) * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio);
        const infinity_underlying_unstaking_bal = (infinity_unstaking_bal * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio);

        if(i == 0 && (infinity_underlying_deposited_bal + infinity_underlying_unstaking_bal) * gammaPrice < 0.01){
          apy = 0
        }

        if(i == 1 && (infinity_underlying_deposited_bal + infinity_underlying_unstaking_bal) * aquaPrice < 0.01){
          apy = 0
        }
        let inf_details = {
          balance: infinity_underlying_deposited_bal + infinity_underlying_unstaking_bal,
          apy,
          apr: (Math.pow((apy / 100 + 1), (1 / 365)) - 1) * 365 * 100
        }
        totalInfinityVaultBalance[i] = inf_details;
      }
    }
    // console.log("totalInfinityVaultBalance", totalInfinityVaultBalance);
    return totalInfinityVaultBalance;

  } catch(error){
    console.log(error);
    return [];
  }
  
}

const checkUserCakeWithdrawDetails = async (userAddress: string) => {
  try {
    const inst = await selectInstance(instType.CLAIM_CAKE, "0xD3A375Ea1a4224DF396c479839DA13C8C5f02De9")
    const withdraw_details = (await inst.methods.userWithdrawInfo(userAddress).call())
    return withdraw_details;
  }
  catch (err) {
    console.log(err)
    return {
      amountWithdrawn: 0,
      withdrew: false,
    }
  }
}

const getUserLpStatusOnNewFarm = async (userAddress: any, poolId: any, apyData: any) => {
  if (poolId === 49) {
    // console.log('apyData', apyData)
  }

  try {
    var obj: any = {}

    let tokenName = null
    let poolDetails = await poolInfo(gammaFarmAdddress, poolId)
    let tvl: number = 0
    let gammaPerBlock: any
    let tokenYield: any
    let tokenAPR: any
    let tokenYieldPerDay: any
    let tokenType: any
    let farmName: any
    let parentFarmApy: number = 0
    let getLpTokenLink: any
    let assetPrice: number = 0
    let tokenTypeBoolean: boolean = true
    let currentBalance: any
    let LPbalance: any
    let depositFee: number = 0
    let autoCompoundFee: number = 0
    let platformFee: number = 0
    let buyBackFee: number = 0
    let orgTvl: any
    let farmApr: any = 0
    let tradeFeeApy: any = '0'
    let tradeFeeApr: any = '0'
    let pendingExtraGammaProfits = 0
    let pendingExtraGammaProfitsInUsd = 0
    let isBoostPool = false

    const wantAddress = poolDetails['want']
    const strategyAddress = poolDetails['strat']
    const poolAllocPoint = poolDetails['allocPoint']

    tokenName = await getName(wantAddress, true)
    const token0Address = await token1(wantAddress)
    const token1Address = await token2(wantAddress)

    if (!!apyData && Object.values(apyData).length > 0 && !!apyData[wantAddress.toLowerCase()]) {
      tradeFeeApy = apyData[wantAddress.toLowerCase()].apy
      tradeFeeApr = apyData[wantAddress.toLowerCase()].apr
    }

    tokenType = 'LP'

    if (token0Address !== bnbAddress && token1Address !== bnbAddress)
      getLpTokenLink = `https://exchange.pancakeswap.finance/#/add/${token0Address}/${token1Address}`
    else if (token0Address === bnbAddress) getLpTokenLink = `https://exchange.pancakeswap.finance/#/add/BNB/${token1Address}`
    else getLpTokenLink = `https://exchange.pancakeswap.finance/#/add/${token0Address}/BNB`

    tvl = await wantLockedTotal(strategyAddress)

    gammaPerBlock = await GAMMAPerBlock()
    gammaPerBlock = convertToEther(gammaPerBlock)

    let reserves = await getReserves(wantAddress)
    let reserve0 = parseFloat(reserves['_reserve0'])
    let reserve1 = parseFloat(reserves['_reserve1'])
    let totalLpSupply = await totalSupply(wantAddress)
    let token0price: any
    let token1price: any

    const _farmContractAddress = (await farmContractAddress(strategyAddress)).toLowerCase()

    farmName = _farmContractAddress.toLowerCase() === pancakeFarmAddress.toLowerCase() ? 'Pancake Swap' : 'AQUA'

    if (poolId === 0) {
      //aqua-gamma
      token0price = await getAquaPrice()
      token1price = await getGammaPrice()
    } else if (poolId === 1) {
      //aqua-bnb
      token0price = await getAquaPrice()
      token1price = await getBnbPrice()
    } else {
      token0price = await getTokenPrice(token0Address)
      token1price = await getTokenPrice(token1Address)
    }

    assetPrice = (reserve0 * token0price + reserve1 * token1price) / totalLpSupply

    tokenTypeBoolean = true

    tvl = convertToEther(tvl) * assetPrice
    orgTvl = tvl
    let totalAlloc = await totalAllocPoint(gammaFarmAdddress)

    const gammaAprPerYear =
      tvl > 0
        ? ((gammaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getGammaPrice())) / tvl) * 365 * 100
        : gammaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getGammaPrice()) * 365 * 100

    let farmApyPerYear = await getTokenYield(gammaAprPerYear, 365)

    const tradeFeeApyPerYear = parseFloat(tradeFeeApy)

    if (_farmContractAddress.toLowerCase() === pancakeFarmAddress.toLowerCase() && tokenName !== 'CAKE') {
      //PARENT FARM APY CALCULATION
      let APR: any
      const cakePrice = await getTokenPrice(cakeTokenAddress)
      const pId = await pid(strategyAddress)
      const instPcsFarm: any = await selectInstance(instType.MASTERCHEF, pancakeFarmAddress)
      const pcsPoolInfo = await instPcsFarm.methods.poolInfo(pId).call()
      const alloc = pcsPoolInfo['allocPoint']
      const totalAlloc = await instPcsFarm.methods.totalAllocPoint().call()
      let balance = await balanceOf(wantAddress, pancakeFarmAddress)
      balance = convertToEther(balance)
      APR = ((40 * 28800 * (alloc / totalAlloc) * cakePrice) / (balance * assetPrice)) * 365
      parentFarmApy = (Math.pow(1 + APR / 730, 730) - 1) * 100
      farmApyPerYear += parentFarmApy
      isBoostPool = true

      const strategyInstance = await selectInstance(instType.STRATEGY_ABI, strategyAddress)

      let profit = 0
      try {
        profit = userAddress !== null ? (await strategyInstance.methods.userPendingGammaProfit(userAddress).call())[1] : 0
      } catch (e) {
        profit = 0
      }

      pendingExtraGammaProfits = convertToEther(profit)

      if (pendingExtraGammaProfits <= 0) pendingExtraGammaProfits = 0

      pendingExtraGammaProfitsInUsd = pendingExtraGammaProfits * (await getGammaPrice())
    }

    //per day apr
    // const tokenYieldPerYear = ((gammaAprPerYear) + parseFloat(tradeFeeAprPerYear))
    // tokenYieldPerDay = tokenYieldPerYear / 365

    // if(tokenName === "AQUA-GAMMA"){
    //   console.log({
    //     gammaAprPerYear,
    //     farmApyPerYear,
    //     tradeFeeAprPerYear,
    //     tradeFeeApyPerYear,
    //     tokenYieldPerDay
    //   })
    // }

    // farmApr = tokenYieldPerDay

    // tokenAPR = tokenYield

    //365 day apy
    // tokenYield = await getTokenYield(tokenYieldPerYear, 365)
    tokenYield = farmApyPerYear + tradeFeeApyPerYear + parentFarmApy
    tokenYieldPerDay = ((Math.pow(tokenYield / 100 + 1, 1 / 365) - 1) * 365 * 100) / 365
    farmApr = tokenYieldPerDay
    tokenAPR = tokenYield

    if (userAddress) {
      currentBalance = await getCurrentBalance(poolId, userAddress, true)
      LPbalance = convertToEther(await balanceOf(wantAddress, userAddress))
    }

    depositFee =
      ((parseFloat(await entranceFeeFactorMax(strategyAddress)) - parseFloat(await entranceFeeFactor(strategyAddress))) /
        parseFloat(await entranceFeeFactorMax(strategyAddress))) *
      100
    autoCompoundFee = 0
    platformFee = 0
    buyBackFee = (parseFloat(await buyBackRate(strategyAddress)) / parseFloat(await buyBackRateMax(strategyAddress))) * 100
    obj.newFarm = true
    // console.log(poolId,assetPrice)
    //console.log(await returnApy(12))
    if (userAddress != null) {
      obj['supplyApy'] = 0
      obj['gammaApy'] = farmApyPerYear
      obj['tradeFeeApy'] = tradeFeeApyPerYear
      obj['totalApy'] = tokenYield
      obj['tokenNameGetLP'] = tokenName
      obj['id'] = poolId
      obj['liquidBalance'] = LPbalance
      obj['usdLiquidBAlance'] = parseFloat(LPbalance) * assetPrice
      obj['currentLpDeposit'] = currentBalance
      obj['pendingAquaEarnings'] = await getPendingGammaClaim(userAddress, poolId)
      obj['token'] = tokenName
      obj['tokenTvl'] = tvl
      obj['tokenYield'] = tokenYield
      obj['dailyPercentage'] = farmApr
      obj['totalEarned'] = `${parseFloat(currentBalance).toFixed(4)} ` + tokenType
      obj['lpType'] = tokenType
      obj['currentBalance'] = parseFloat(currentBalance) * assetPrice
      obj['rewardToken'] = await getPendingGammaClaim(userAddress, poolId)
      obj['reward'] = obj['rewardToken'] * (await getGammaPrice())
      obj['assetTokenPrice'] = assetPrice
      obj['farmName'] = farmName
      obj['farmContract'] = `https://bscscan.com/address/${gammaFarmAdddress}#code`
      obj['vaultContractt'] = `https://bscscan.com/address/${poolDetails['strat']}#code`
      obj['farmApy'] = parentFarmApy
      obj['optimalCompoundsPerYear'] = '0'
      obj['aquaApr'] = tokenYield
      obj['totalAPY2'] = tokenYield.toFixed(2)
      obj['getLpToken'] = getLpTokenLink
      obj['tokenType'] = tokenTypeBoolean
      obj['currentPoolTokenApproval'] = await allowance(wantAddress, userAddress, gammaFarmAdddress)
      obj['depositFee'] = depositFee
      obj['autoCompoundFee'] = autoCompoundFee
      obj['platformFee'] = platformFee
      obj['buyBackFe e'] = buyBackFee
      obj['tokenAPR'] = tokenAPR
      obj['orgTvl'] = orgTvl
      obj['pendingExtraAquaProfits'] = pendingExtraGammaProfits
      obj['pendingExtraAquaProfitsInUsd'] = pendingExtraGammaProfitsInUsd
      obj['isBoostPool'] = isBoostPool
      obj['vaultMultiplier'] = parseFloat(poolDetails['allocPoint']) / 100
    } else {
      obj['supplyApy'] = 0
      obj['gammaApy'] = farmApyPerYear
      obj['tokenNameGetLP'] = tokenName
      obj['id'] = poolId
      obj['usdLiquidBAlance'] = 0
      obj['liquidBalance'] = '0.0000'
      obj['currentLpDeposit'] = '0.0000'
      obj['pendingAquaEarnings'] = '0.0000'
      obj['token'] = tokenName
      obj['tokenTvl'] = tvl
      obj['tokenYield'] = tokenYield
      obj['lpType'] = tokenType
      obj['dailyPercentage'] = farmApr
      obj['currentBalance'] = '0.0000'
      obj['totalEarned'] = '0.0000 ' + tokenType
      obj['reward'] = '0'
      obj['rewardToken'] = '0.0000'
      obj['assetTokenPrice'] = assetPrice
      obj['farmName'] = farmName
      obj['farmContract'] = `https://bscscan.com/address/${gammaFarmAdddress}#code`
      obj['vaultContractt'] = `https://bscscan.com/address/${poolDetails['strat']}#code`
      obj['farmApy'] = parentFarmApy
      obj['optimalCompoundsPerYear'] = '0'
      obj['aquaApr'] = tokenYield
      obj['totalAPY2'] = tokenYield.toFixed(2)
      obj['tradeFeeApy'] = tradeFeeApyPerYear
      obj['totalApy'] = tokenYield
      obj['getLpToken'] = getLpTokenLink
      obj['tokenType'] = tokenTypeBoolean
      obj['currentPoolTokenApproval'] = '0'
      obj['depositFee'] = depositFee
      obj['autoCompoundFee'] = autoCompoundFee
      obj['platformFee'] = platformFee
      obj['buyBackFee'] = buyBackFee
      obj['tokenAPR'] = tokenAPR
      obj['orgTvl'] = orgTvl
      obj['pendingExtraAquaProfits'] = pendingExtraGammaProfits
      obj['pendingExtraGammaProfitsInUsd'] = pendingExtraGammaProfitsInUsd
      obj['isBoostPool'] = isBoostPool
      obj['vaultMultiplier'] = parseFloat(poolDetails['allocPoint']) / 100
    }
    return obj
  } catch (err) {
    console.log(err)
  }
}

const getName = async (assetAddress: any, flag: boolean) => {
  /*
   * flag= true means lp
   * flag = false means normal
   */
  const lpInstance: any = await selectInstance(instType.PANCAKELP, assetAddress)
  if (!flag) {
    let name = await lpInstance.methods.symbol().call()
    name = name.toUpperCase()
    return `${name}`
  } else {
    const token0Address = await token1(assetAddress)
    const token1Address = await token2(assetAddress)
    if (token0Address === zeroAddress && token1Address === zeroAddress) {
      let name = await lpInstance.methods.symbol().call()
      name = name.toUpperCase()
      return `${name}`
    }
    let token0Instance: any
    let token1Instance: any
    token0Instance = await selectInstance(instType.PANCAKELP, token0Address)
    token1Instance = await selectInstance(instType.PANCAKELP, token1Address)
    let token0Name = await token0Instance.methods.symbol().call()
    let token1Name = await token1Instance.methods.symbol().call()
    token0Name = token0Name.toUpperCase()
    token1Name = token1Name.toUpperCase()
    if (token0Name === 'WBNB') token0Name = 'BNB'
    if (token1Name === 'WBNB') token1Name = 'BNB'
    return `${token0Name}-${token1Name}`
  }
}

export const getPendingAquaClaim = async (currentUserAddress: any, poolId: any) => {
  try {
    let pendingExtraAquaProfits: any = 0
    var currentPendingAqua = await pendingAQUA(poolId, currentUserAddress)
    currentPendingAqua = convertToEther(currentPendingAqua)
    if (uranusPoolId.includes(poolId)) {
      let poolDetails = await poolInfo(aquaFarmAddress, poolId)
      let strategyInstance: any
      strategyInstance = await selectInstance(instType.STRATEGY_ABI, poolDetails['strat'])
      let profit = 0
      try {
        profit = (await strategyInstance.methods.userPendingAquaProfit(currentUserAddress).call())[1]
      } catch (e) {
        profit = 0
      }
      pendingExtraAquaProfits = convertToEther(profit)
      if (pendingExtraAquaProfits <= 0) pendingExtraAquaProfits = 0
    }
    return currentPendingAqua + pendingExtraAquaProfits
  } catch (err) {
    //console.log(err)
  }
}

export const getPendingGammaClaim = async (currentUserAddress: any, poolId: any) => {
  try {
    let pendingExtraGammaProfits: any = 0
    var currentPendingGamma = await pendingGAMMA(poolId, currentUserAddress)
    currentPendingGamma = convertToEther(currentPendingGamma)
    if (uranusPoolId.includes(poolId)) {
      let poolDetails = await poolInfo(gammaFarmAdddress, poolId)
      let strategyInstance: any
      strategyInstance = await selectInstance(instType.STRATEGY_ABI, poolDetails['strat'])
      let profit = 0
      try {
        profit = (await strategyInstance.methods.userPendingGammaProfit(currentUserAddress).call())[1]
      } catch (e) {
        profit = 0
      }
      pendingExtraGammaProfits = convertToEther(profit)
      if (pendingExtraGammaProfits <= 0) pendingExtraGammaProfits = 0
    }
    return parseFloat(currentPendingGamma) + parseFloat(pendingExtraGammaProfits)
  } catch (err) {
    //console.log(err)
    return 0
  }
}

export const harvestLpTokens = async (id: any, userAddress: any, showNotification: any, poolData: any) => {
  try {
    if (id !== -1) await handleWithdraw(id, 0, userAddress, showNotification, poolData)
    else await handleAquaAutoCompHarvest(userAddress, showNotification)
  } catch (err) {
    console.log(err)
  }
}

// export const harvestAllLpTokens = async (userAddress: any) => {
//   try {
//     const poolLength: any = await getPoolLength()
//     const aquaFarmInstance: any = await selectInstance(instType.AQUAFARM, aquaFarmAddress, true)
//     const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress)
//     const userDetails = await poolInstance.methods.userInfo(userAddress).call()
//     const userShares = convertToEther(userDetails['shares'])
//     for (let i = 0; i < poolLength; i++) {
//       if (bluePlanetpoolsId.includes(i)) {
//         let currentLPdeposit: any = await aquaFarmInstance.methods.userInfo(i, userAddress).call()
//         if (currentLPdeposit['shares'] > 0) {
//           await handleWithdraw(i, 0, userAddress)
//         }
//       }
//     }
//     if (userShares > 0) await harvestLpTokens(-1, userAddress)
//   } catch (err) {
//     console.log(err)
//   }
// }

const getPoolLength = async () => {
  try {
    const length = await poolLength(aquaFarmAddress)
    return 57
  } catch (err) {
    console.log(err)
  }
}

export const returnPoolData = async (userAddress: any) => {
  try {
    const poolLength: any = await getPoolLength()
    let result = []
    let lpStatusFunction = []
    const apyData: any = await getApyData()

    for (let i = 0; i < poolLength; i++) {
      if (bluePlanetpoolsId.includes(i)) {
        const lpStatus: any = getUserLpStatus(userAddress, i)
        lpStatusFunction.push(lpStatus)
      }
    }

    const bluePlanetpoolsIdOnNewFarm = [0, 1, 2, 3, 4, 5, 7, 8, 17]

    for (let i = 0; i < 20; i++) {
      if (bluePlanetpoolsIdOnNewFarm.includes(i)) {
        const lpStatus: any = getUserLpStatusOnNewFarm(userAddress, i, apyData)
        lpStatusFunction.push(lpStatus)
      }
    }

    lpStatusFunction.push(returnUserAquaAutoCompundingPoolData(userAddress))

    const greenSingleVaults = await singleVaultsDataForFarm(userAddress, bluePfTokenList)

    result = await Promise.all(lpStatusFunction)
    for (let i = 0; i < greenSingleVaults.data.length; i++) {
      const vault = greenSingleVaults.data[i]
      result.push(vault)
    }

    result.push({ greenVaultsData: greenSingleVaults })
    // console.log("result", result)
    return result
  } catch (err) {
    console.log(err)
  }
}

export const getPendingAquaRewards = async (userAddress: any) => {
  const poolLength: any = await getPoolLength()
  const aquaPrice = await getAquaPrice()
  let pendingObj: any = {}
  if (userAddress) {
    for (let i = 0; i < poolLength; i++) {
      if (bluePlanetpoolsId.includes(i)) {
        const pending: any = await getPendingAquaClaim(userAddress, i)
        const pendingAquaInUsd = parseFloat(pending) * aquaPrice
        pendingObj[i] = {
          pendingAquaEarnings: pending,
          reward: pendingAquaInUsd,
        }
      }
    }
  } else {
    for (let i = 0; i < poolLength; i++) {
      if (bluePlanetpoolsId.includes(i)) {
        pendingObj[i] = {
          pendingAquaEarnings: 0,
          reward: 0,
        }
      }
    }
  }
  let pendingAqua = await pendingAQUA('1', aquaAutoCompPoolAddress)
  let aquaBal = await balanceOf(aquaAddress, aquaAutoCompPoolAddress)
  const pending = convertToEther(pendingAqua) + convertToEther(aquaBal)
  const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress)

  let callFee = await poolInstance.methods.callFee().call()
  callFee = parseFloat(callFee) / 10000
  pendingObj[-1] = {
    pendingAquaEarnings: parseFloat(pending) * callFee,
    reward: parseFloat(pending) * aquaPrice * callFee,
  }
  return pendingObj
}

export const returnUserAquaAutoCompundingPoolData = async (userAddress: any) => {
  const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress)

  let userAquaBalance: any = 0
  let currentBalance: any = 0
  let aquaBalance: any = 0
  let currentPoolTokenApproval: any = 0
  let pending: any = 0
  let callFee: any = 0
  aquaBalance = await poolInstance.methods.balanceOf().call()
  aquaBalance = convertToEther(aquaBalance)

  let totalShares = await poolInstance.methods.totalShares().call()
  totalShares = convertToEther(totalShares)
  callFee = await poolInstance.methods.callFee().call()
  callFee = parseFloat(callFee) / 10000
  let pendingAqua = await pendingAQUA('1', aquaAutoCompPoolAddress)
  let aquaBal = await balanceOf(aquaAddress, aquaAutoCompPoolAddress)
  pending = convertToEther(pendingAqua) + convertToEther(aquaBal)
  pending = parseFloat(pending)
  if (userAddress != null) {
    userAquaBalance = await balanceOf(aquaAddress, userAddress)
    let x = new BigNumber(userAquaBalance)
    let balanceVal: any = x.div(1e18).toString()
    userAquaBalance = getFinalBalance(balanceVal)
    const userDetails = await poolInstance.methods.userInfo(userAddress).call()
    const userShares = convertToEther(userDetails['shares'])
    currentBalance = (userShares / totalShares) * aquaBalance
    currentPoolTokenApproval = await allowance(aquaAddress, userAddress, aquaAutoCompPoolAddress)
  }
  const assetPrice = await getAquaPrice()

  let perBlock = await AQUAPerBlock()
  const aquaPerBlock = convertToEther(perBlock)
  const tokenTypeBoolean = false

  const poolDetails = await poolInfo(aquaFarmAddress, '1')
  const poolAllocPoint = poolDetails['allocPoint']
  let totalAlloc = await totalAllocPoint(aquaFarmAddress)
  const strategyInstance: any = await selectInstance(instType.STRATEGY_ABI, poolDetails['strat'])

  let tvl = await strategyInstance.methods.wantLockedTotal().call()
  tvl = convertToEther(tvl)
  const orgTvl = tvl
  tvl = tvl * assetPrice

  const tokenType = ''
  const poolType = 'AUTO-COMPOUNDING'
  const farmName = 'AQUA'
  const getLpTokenLink = null

  let tokenYield =
    tvl > 0
      ? (aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * 365 * 100) / orgTvl
      : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * assetPrice * 365 * 100

  const roiPerDay = tokenYield / 365 // rate of interest per day
  tokenYield = await getAutoAquaTokenYield(tokenYield, 36500)

  return {
    tokenNameGetLP: 'AQUA',
    totalPendingAqua: pending,
    pendingAquaEarnings: pending * callFee,
    rewardToken: pending * callFee,
    reward: pending * (await getAquaPrice()) * callFee,
    id: -1,
    liquidBalance: userAquaBalance,
    usdLiquidBAlance: userAquaBalance * assetPrice,
    currentLpDeposit: currentBalance,
    token: 'AQUA',
    tokenTvl: aquaBalance * assetPrice,
    tokenYield: tokenYield,
    dailyPercentage: '0' /* roiPerDay */,
    totalEarned: `${currentBalance.toFixed(4)} ` + tokenType,
    lpType: tokenType,
    currentBalance: currentBalance * assetPrice,
    assetTokenPrice: assetPrice,
    farmName: farmName,
    farmContract: `https://bscscan.com/address/${aquaFarmAddress}#code`,
    vaultContractt: `https://bscscan.com/address/${aquaAutoCompPoolAddress}#code`,
    farmApy: tokenYield,
    optimalCompoundsPerYear: 0,
    aquaApr: roiPerDay,
    totalAPY2: tokenYield,
    totalApy: '0' /* tokenYield */,
    getLpToken: getLpTokenLink,
    tokenType: tokenTypeBoolean,
    currentPoolTokenApproval: currentPoolTokenApproval,
    tokenAPR: roiPerDay * 365,
    orgTvl: aquaBalance,
    poolType: poolType,
    vaultMultiplier: parseFloat(poolAllocPoint) / 100,
  }
}

export const handleAquaAutoCompDeposit = async (amount: any, userAddress: any, showNotification: any) => {
  if (userAddress) {
    try {
      const depositAmount = amount
      const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress, true)

      const promisify = (inner: any) =>
        new Promise((resolve, reject) =>
          inner((err: any, res: any) => {
            if (err) {
              reject(err)
            }
            resolve(res)
          })
        )
      await promisify((cb: any) => {
        poolInstance.methods
          .deposit(convertToWei(depositAmount))
          .send({ from: userAddress })
          .once('receipt', function (receipt: any) {
            const type = receipt.status ? 'success' : 'failed'
            showNotification(type, receipt.transactionHash)
            cb()
          })
          .on('error', function (error: any) {
            cb(error)
          })
      })
    } catch (err) {
      showNotification('failed')
      console.log(err)
    }
  }
}

export const handleAquaAutoCompWithdraw = async (amount: any, userAddress: any, showNotification: any) => {
  if (userAddress) {
    try {
      const withdrawAmount = amount
      const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress, true)
      const totalBalance = parseFloat(convertToEther(await poolInstance.methods.balanceOf().call()))
      const totalShares = parseFloat(convertToEther(await poolInstance.methods.totalShares().call()))
      const depositedBal = await getCurrentBalance(-1, userAddress)
      const promisify = (inner: any) =>
        new Promise((resolve, reject) =>
          inner((err: any, res: any) => {
            if (err) {
              reject(err)
            }
            resolve(res)
          })
        )

      await promisify((cb: any) => {
        if (withdrawAmount <= depositedBal) {
          const amount = (parseFloat(withdrawAmount) * totalShares) / totalBalance
          poolInstance.methods
            .withdraw(convertToWei(amount))
            .send({ from: userAddress })
            .once('receipt', function (receipt: any) {
              const type = receipt.status ? 'success' : 'failed'
              showNotification(type, receipt.transactionHash)
              cb()
            })
            .on('error', function (error: any) {
              cb(error)
            })
        } else {
          poolInstance.methods
            .withdrawAll()
            .send({ from: userAddress })
            .once('receipt', function (receipt: any) {
              const type = receipt.status ? 'success' : 'failed'
              showNotification(type, receipt.transactionHash)
              cb()
            })
            .on('error', function (error: any) {
              cb(error)
            })
        }
      })
    } catch (err) {
      showNotification('failed')
      console.log(err)
    }
  }
}

export const handleAquaAutoCompHarvest = async (userAddress: any, showNotification: any) => {
  if (userAddress) {
    try {
      const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress, true)

      const promisify = (inner: any) =>
        new Promise((resolve, reject) =>
          inner((err: any, res: any) => {
            if (err) {
              reject(err)
            }
            resolve(res)
          })
        )

      await promisify((cb: any) => {
        poolInstance.methods
          .harvest()
          .send({ from: userAddress, gasLimit: 0x927c0 })
          .once('receipt', function (receipt: any) {
            const type = receipt.status ? 'success' : 'failed'
            showNotification(type, receipt.transactionHash)
            cb()
          })
          .on('error', function (error: any) {
            cb(error)
          })
      })
    } catch (err) {
      showNotification('failed')
      console.log(err)
    }
  }
}

export const returnApy = async (pId: any, newFarm?: boolean) => {
  const perBlock = await AQUAPerBlock()
  let aquaPerBlock = convertToEther(perBlock)
  if (pId === -1) {
    const poolDetails = await poolInfo(aquaFarmAddress, '1')
    const poolAllocPoint = poolDetails['allocPoint']
    let totalAlloc = await totalAllocPoint(aquaFarmAddress)
    const strategyInstance: any = await selectInstance(instType.STRATEGY_ABI, poolDetails['strat'])
    const assetPrice = await getAquaPrice()
    let tvl = await strategyInstance.methods.wantLockedTotal().call()
    tvl = convertToEther(tvl)
    const orgTvl = tvl
    tvl = tvl * assetPrice

    let tokenYield =
      tvl > 0
        ? (aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * 365 * 100) / orgTvl
        : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * assetPrice * 365 * 100

    tokenYield = await getAutoAquaTokenYield(tokenYield, 36500)
    return tokenYield
  }

  let poolDetails = await poolInfo(aquaFarmAddress, pId)
  const wantAddress = poolDetails['want']
  const strategyAddress = poolDetails['strat']
  const poolAllocPoint = poolDetails['allocPoint']
  let tvl = await wantLockedTotal(strategyAddress)
  const assetPrice = await getPoolTokenAssetPrice(pId)

  let totalAlloc = await totalAllocPoint(aquaFarmAddress)

  if (newFarm) {
    const token0Address = await token1(wantAddress)
    const token1Address = await token2(wantAddress)
    tvl = await wantLockedTotal(strategyAddress)

    let gammaPerBlock = await GAMMAPerBlock()
    gammaPerBlock = convertToEther(gammaPerBlock)

    let reserves = await getReserves(wantAddress)
    let reserve0 = parseFloat(reserves['_reserve0'])
    let reserve1 = parseFloat(reserves['_reserve1'])
    let totalLpSupply = await totalSupply(wantAddress)
    let token0price: any
    let token1price: any

    if (pId === 0) {
      //aqua-gamma
      token0price = await getAquaPrice()
      token1price = await getGammaPrice()
    } else if (pId === 1) {
      //aqua-bnb
      token0price = await getAquaPrice()
      token1price = await getBnbPrice()
    } else {
      token0price = await getTokenPrice(token0Address)
      token1price = await getTokenPrice(token1Address)
    }

    let assetPrice = (reserve0 * token0price + reserve1 * token1price) / totalLpSupply

    tvl = convertToEther(tvl) * assetPrice

    let totalAlloc = await totalAllocPoint(gammaFarmAdddress)
    let tokenYield =
      tvl > 0
        ? ((gammaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getGammaPrice())) / tvl) * 365 * 100
        : gammaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getGammaPrice()) * 365 * 100
    tokenYield = await getTokenYield(tokenYield, 365)
    return tokenYield
  } else if (tokenList[pId][1] === '4BELT' && pId === 47) {
    const autoPoolId = await pid(strategyAddress)
    tvl = await wantLockedTotal(strategyAddress)
    const token = (await axios.get('https://s.belt.fi/info/all.json')).data
    let APR: any = 0
    const temp1: any[] = token['info']['BSC']['vaultPools']
    temp1.filter((e) => {
      if (autoPoolId === e.pid) APR = e.totalAPR
    })
    const parentFarmApy = (await getAutoAquaTokenYield(APR, 365)) / 100
    let tokenYield =
      tvl > 0
        ? ((aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice())) / tvl) * 365 * 100
        : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice()) * 365 * 100
    tokenYield = await getTokenYield(tokenYield, 365)
    return tokenYield + parentFarmApy * 100
  } else if (tokenList[pId][1] === 'AQUAPAIR') {
    let parentFarmApy: any = 0
    const token0Address = await token1(wantAddress)
    const token1Address = await token2(wantAddress)
    tvl = await wantLockedTotal(strategyAddress)
    let reserves = await getReserves(wantAddress)
    let reserve0 = parseFloat(reserves['_reserve0'])
    let reserve1 = parseFloat(reserves['_reserve1'])
    let totalLpSupply = await totalSupply(wantAddress)
    let token0price: any
    let token1price: any

    if (pId === 13) {
      //new aqua-bnb
      token0price = await getAquaPrice()
      token1price = await getBnbPrice()
    } else if (pId === 0) {
      //previous aqua-bnb
      token0price = await getPreviousAquaPrice()
      token1price = await getBnbPrice()
    } else if (pId === 14) {
      //cake-aqua
      token0price = await getTokenPrice(cakeTokenAddress)
      token1price = await getAquaPrice()
    } else if (pId === 36) {
      token0price = await getAquaPrice()
      token1price = await getTokenPrice(token1Address)
    } else if (aquaPairPoolId.includes(pId)) {
      token0price = await getTokenPrice(token0Address)
      token1price = await getTokenPrice(token1Address)
    } else {
      token0price = await getTokenPrice(token0Address)
      token1price = await getTokenPrice(token1Address)
    }
    const assetPrice = (reserve0 * token0price + reserve1 * token1price) / totalLpSupply
    tvl = convertToEther(tvl) * assetPrice
    let tokenYield =
      tvl > 0
        ? ((aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice())) / tvl) * 365 * 100
        : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice()) * 365 * 100
    tokenYield = await getTokenYield(tokenYield, 365)
    if (!aquaPairPoolId.includes(pId)) {
      /*****************************************************************************/
      let APR: any
      const cakePrice = await getTokenPrice(cakeTokenAddress)
      const pId = await pid(strategyAddress)
      const instPcsFarm: any = await selectInstance(instType.MASTERCHEF, '0x73feaa1eE314F8c655E354234017bE2193C9E24E')
      const pcsPoolInfo = await instPcsFarm.methods.poolInfo(pId).call()
      const alloc = pcsPoolInfo['allocPoint']
      const totalAlloc = await instPcsFarm.methods.totalAllocPoint().call()
      let balance = await balanceOf(wantAddress, '0x73feaa1eE314F8c655E354234017bE2193C9E24E')
      balance = convertToEther(balance)
      APR = ((40 * 28800 * (alloc / totalAlloc) * cakePrice) / (balance * assetPrice)) * 365
      parentFarmApy = Math.pow(1 + APR / 730, 730) - 1
      /*****************************************************************************/
    }
    return tokenYield + parentFarmApy * 100
  } else if (tokenList[pId][1] === 'AQUA') {
    const assetPrice = await getAquaPrice()
    tvl = await wantLockedTotal(strategyAddress)
    tvl = convertToEther(tvl) * assetPrice
    let tokenYield =
      tvl > 0
        ? ((aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice())) / tvl) * 365 * 100
        : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice()) * 365 * 100
    tokenYield = await getTokenYield(tokenYield, 365)
    return tokenYield
  } else if (tokenList[pId][1] === 'PCSv2SINGLE') {
    let APR: any
    const cakePrice = await getTokenPrice(cakeTokenAddress)
    const pId = await pid(strategyAddress)
    const instPcsFarm: any = await selectInstance(instType.MASTERCHEF, '0x73feaa1eE314F8c655E354234017bE2193C9E24E')
    const pcsPoolInfo = await instPcsFarm.methods.poolInfo(pId).call()
    const alloc = pcsPoolInfo['allocPoint']
    const totalAlloc = await instPcsFarm.methods.totalAllocPoint().call()
    let balance = await balanceOf(wantAddress, '0x73feaa1eE314F8c655E354234017bE2193C9E24E')
    balance = convertToEther(balance)
    APR = ((40 * 28800 * (alloc / totalAlloc) * cakePrice) / (balance * cakePrice)) * 365
    let parentFarmApy = Math.pow(1 + APR / 365, 365) - 1

    let tvl = await wantLockedTotal(strategyAddress)
    const assetPrice = await getPoolTokenAssetPrice(pId)
    tvl = convertToEther(tvl) * assetPrice
    let tokenYield =
      tvl > 0
        ? ((aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice())) / tvl) * 365 * 100
        : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice()) * 365 * 100
    tokenYield = await getTokenYield(tokenYield, 365)
    return tokenYield + parentFarmApy * 100
  } else if ((tokenList[pId][1] === 'SINGLEBELT' && pId === 43) || pId === 44 || pId === 46) {
    let APR: any = 0
    const autoPoolId = await pid(strategyAddress)

    let tvl = await wantLockedTotal(strategyAddress)
    const token = (await axios.get('https://s.belt.fi/info/all.json')).data

    const assetPrice = await getPoolTokenAssetPrice(pId)

    const temp1: any[] = token['info']['BSC']['vaultPools']
    temp1.filter((e) => {
      if (autoPoolId === e.pid) APR = e.totalAPR
    })

    const parentFarmApy = (await getAutoAquaTokenYield(APR, 365)) / 100
    tvl = convertToEther(tvl) * assetPrice

    let tokenYield =
      tvl > 0
        ? ((aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice())) / tvl) * 365 * 100
        : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice()) * 365 * 100
    tokenYield = await getTokenYield(tokenYield, 365)
    return tokenYield + parentFarmApy * 100
  }
}

const returnLpPrice = async (i: any, lpAddress: string) => {
  if (i === -1 || i === 1) return getAquaPrice()

  const poolTokenInstance: any = await selectInstance(instType.PANCAKELP, lpAddress)
  const symbol: string = await poolTokenInstance.methods.symbol().call()
  if (symbol === 'Cake-LP' || symbol === 'Net-LP') {
    //lp token price
    let token0Address = poolTokenInstance.methods.token0().call()
    let token1Address = poolTokenInstance.methods.token1().call()
    const getReserves = await poolTokenInstance.methods.getReserves().call()
    const reserve0 = parseFloat(getReserves['_reserve0'])
    const reserve1 = parseFloat(getReserves['_reserve1'])
    let totalLpSupply = poolTokenInstance.methods.totalSupply().call()
    let token0price: any
    let token1price: any
    if (i === 13) {
      token0price = getAquaPrice()
      token1price = getBnbPrice()
    } else if (i === 14) {
      token0price = getTokenPrice(cakeTokenAddress)
      token1price = getAquaPrice()
    } else if (i === 36) {
      token1Address = await token1Address
      token0price = getAquaPrice()
      token1price = getTokenPrice(token1Address)
    } else {
      token0Address = await token0Address
      token1Address = await token1Address
      token0price = getTokenPrice(token0Address)
      token1price = getTokenPrice(token1Address)
    }
    const assetPrice = (reserve0 * (await token0price) + reserve1 * (await token1price)) / (await totalLpSupply)
    return assetPrice
  } else if (symbol.includes('belt') || symbol.includes('Belt')) {
    //belt token prices
    const price = await getBeltTokenPrice(lpAddress)
    return price
  } else {
    //single token price
    const price = await getTokenPrice(lpAddress)
    return price
  }
}

export const returnPlatformData = async () => {
  //userAddress = "0x3BDaDCbF17e24B2157d920E25407FF8cd4f5F54C"
  try {
    let getPlatformDetails: any = (await axios.get(planetFinanceApiBaseUrl+'v1/markets/getplatformdata')).data
    return getPlatformDetails
  } catch (error) {
    try {
      let getPlatformDetails: any = (await axios.get(planetFinanceApiBaseUrl+'v1/markets/getplatformdata')).data
      return getPlatformDetails
    } catch (error) { }
  }
  /*
  try {
    const aquaTotalSupply = convertToEther(await totalSupply(aquaAddress))
    const maxSupply = convertToEther(await AQUAMaxSupply())
    const deadAquaBal = await balanceOf(aquaAddress, '0x000000000000000000000000000000000000dEaD')
    
    const obj = {
      circulatingSupply: parseFloat(aquaTotalSupply),
      maxSupply: maxSupply - convertToEther(deadAquaBal),
      marketCap: (aquaTotalSupply - convertToEther(deadAquaBal)) * (await getAquaPrice()),
      totalProfitGenerated: aquaTotalSupply * (await getAquaPrice()),
      perDayProfitGenerated: convertToEther(deadAquaBal) * (await getAquaPrice()),
      aquaBurn: convertToEther(deadAquaBal),
    }

    const aquaPlatformData = { ...obj }
    
    return aquaPlatformData
  } catch (err) {
    console.log(err)
    return {}
  }
  */
}

export const getCurrentApproval = async (poolId: any, userAddress: any, poolData: any) => {
  try {
    let farmAddress
    if (poolData.isNewFarm) {
      farmAddress = gammaFarmAdddress
    } else {
      farmAddress = aquaFarmAddress
    }
    const aquafarmInstance: any = await selectInstance(instType.AQUAFARM, farmAddress, true)
    const poolDetails = await aquafarmInstance.methods.poolInfo(poolId).call()
    const lpAddress = poolDetails['want']
    var getAllowance = await allowance(lpAddress, userAddress, farmAddress)
    getAllowance = convertToEther(getAllowance)
    return getAllowance
  } catch (err) {
    console.log(err)
  }
}

export const retPlatProf = async (myAquaHolding: number, aquaSupply: number, monthlyProtProf: number) => {
  const monthlyIncome = (myAquaHolding / aquaSupply) * monthlyProtProf
  const data = {
    monthlyIncome: monthlyIncome,
    monthlyIncomePerAqua: ((myAquaHolding / aquaSupply) * monthlyProtProf) / myAquaHolding,
    apr: ((monthlyIncome * 12) / (myAquaHolding * (await getAquaPrice()))) * 100,
  }
  return data
}

export const getUserGammaBal = async (userAddress: any) => {
  const gammaPrice: any = await getGammaPrice()

  let lpInAquaGammaVault: any = 0
  let gammaInAquaGammaVault: any = 0

  let lpInBnbGammaVault: any = 0
  let gammaInBnbGammaVault: any = 0

  if (userAddress !== null) {
    let lpStaked =
      parseFloat(await stakedWantTokens(gammaFarmAdddress, '0', userAddress)) +
      parseFloat(await balanceOf(AQUA_GAMMALPAddress, userAddress))
    lpInAquaGammaVault = parseFloat(gConvertToEther(lpStaked, 18))
    // console.log("lpStaked in lpInAquaGammaVault", lpInAquaGammaVault);
    lpStaked =
      parseFloat(await stakedWantTokens(gammaFarmAdddress, '17', userAddress)) +
      parseFloat(await balanceOf(GAMMA_BNBLPAddress, userAddress))
    lpInBnbGammaVault = parseFloat(gConvertToEther(lpStaked, 18))
    // console.log("lpStaked in lpInBnbGammaVault", lpInBnbGammaVault);
  }
  if (lpInAquaGammaVault !== 0 && userAddress) {
    let lpTotalSupply = await totalSupply(AQUA_GAMMALPAddress)
    lpTotalSupply = gConvertToEther(lpTotalSupply, 18)
    let contractGammaBalance = await balanceOf(gammaAddress, AQUA_GAMMALPAddress)
    contractGammaBalance = gConvertToEther(contractGammaBalance, 18)
    gammaInAquaGammaVault = noExponents((lpInAquaGammaVault / lpTotalSupply) * contractGammaBalance)
    // console.log("gammaInAquaGammaVault", gammaInAquaGammaVault);
  }

  if (lpInBnbGammaVault !== 0 && userAddress) {
    let lpTotalSupply = await totalSupply(GAMMA_BNBLPAddress)
    lpTotalSupply = gConvertToEther(lpTotalSupply, 18)
    let contractGammaBalance = await balanceOf(gammaAddress, GAMMA_BNBLPAddress)
    contractGammaBalance = gConvertToEther(contractGammaBalance, 18)
    gammaInBnbGammaVault = noExponents((lpInBnbGammaVault / lpTotalSupply) * contractGammaBalance)
    // console.log("gammaInBnbGammaVault", gammaInBnbGammaVault);
  }

  const gammaInNewGamma = await getSupplyBalance(gGamma, userAddress)
  // console.log("gammaInNewGamma", gammaInNewGamma);
  const gammaInOldGamma = await getSupplyBalance(oldgGamma, userAddress)
  // console.log("gammaInOldGamma", gammaInOldGamma);
  const val = gammaInNewGamma + gammaInOldGamma

  const userGammaBalance: any =
    parseFloat(gConvertToEther(await balanceOfGtoken(userAddress, gammaAddress), 18)) +
    val +
    parseFloat(gammaInAquaGammaVault) +
    parseFloat(gammaInBnbGammaVault)
  const userGammaBalanceUsd: any = parseFloat(userGammaBalance) * parseFloat(gammaPrice)

  const finalData = {
    userGammaBalance: !isNaN(userGammaBalance) ? userGammaBalance : 0,
    userGammaBalanceUsd: !isNaN(userGammaBalanceUsd) ? userGammaBalanceUsd : 0,
  }
  // console.log("total Gamma for user ", finalData);
  return finalData
}

export const retTotSuppAndUserBal = async (userAddress: any) => {
  //userAddress = "0xD1Ec391627c9E2Fb0c570Da876Bc75dF23c42BEB"
  try {
    const deadAddress = '0x000000000000000000000000000000000000dead'
    let aquaTotalSupply = await totalSupply(aquaAddress)
    aquaTotalSupply = convertToEther(aquaTotalSupply)
    let burnedAqua = await balanceOf(aquaAddress, deadAddress)
    burnedAqua = convertToEther(burnedAqua)
    let aquaInVault: any = 0
    let aquaInAutoCompoundingVault: any = 0
    let aquaInAquaBnbVault: any = 0
    let aquaInAquaCakeVault: any = 0
    let aquaInAquaBusdVault: any = 0
    let aquaInAquaGammaVault: any = 0
    let aquaInNewFarmAquaBnbVault: any = 0
    let userBalInUsd: any = 0
    let userGammaBalance: any = 0
    let userGammaBalanceUsd: any = 0
    let priceArray = await returnTokenPriceMulticall();
    const aquaPrice: any = priceArray[aquaAddress.toLowerCase()] ? priceArray[aquaAddress.toLowerCase()] : await getAquaPrice()
    const gammaPrice = priceArray[gammaAddress.toLowerCase()] ? priceArray[gammaAddress.toLowerCase()] : await getGammaPrice()
    let userBal: any
    if (userAddress !== null) {
      const gammaData: any = await getUserGammaBal(userAddress)
      userGammaBalance = gammaData.userGammaBalance
      userGammaBalanceUsd = gammaData.userGammaBalanceUsd

      aquaInVault = await getCurrentBalance(1, userAddress)

      /*USER AQUA BALANCE IN AQUA-BNB VAULT*/
      let poolLpAddress: any = (await poolInfo(aquaFarmAddress, '13'))['want']
      // console.log("poolLpAddress for AQUA BALANCE IN AQUA-BNB ", poolLpAddress)
      let lpInAquaBnbVault: any = parseFloat(convertToEther(await balanceOf(poolLpAddress, userAddress)))
      if (userAddress !== null) {
        let staked = await stakedWantTokens(aquaFarmAddress, '13', userAddress)
        staked = convertToEther(staked)
        lpInAquaBnbVault += parseFloat(staked)
      }

      if (lpInAquaBnbVault !== 0 && userAddress) {
        let lpTotalSupply = await totalSupply(poolLpAddress)
        lpTotalSupply = convertToEther(lpTotalSupply)
        let contractAquaBalance = await balanceOf(aquaAddress, poolLpAddress)
        contractAquaBalance = convertToEther(contractAquaBalance)
        aquaInAquaBnbVault = noExponents((lpInAquaBnbVault / lpTotalSupply) * contractAquaBalance)
      }

      /*USER AQUA BALANCE IN AQUA-CAKE VAULT*/
      poolLpAddress = (await poolInfo(aquaFarmAddress, '14'))['want']

      let lpInAquaCakeVault: any = parseFloat(convertToEther(await balanceOf(poolLpAddress, userAddress)))
      if (userAddress !== null) {
        let staked = await stakedWantTokens(aquaFarmAddress, '14', userAddress)
        staked = convertToEther(staked)
        lpInAquaCakeVault += parseFloat(staked)
      }

      if (lpInAquaCakeVault !== 0) {
        let lpTotalSupply = await totalSupply(poolLpAddress)
        lpTotalSupply = convertToEther(lpTotalSupply)
        let contractAquaBalance = await balanceOf(aquaAddress, poolLpAddress)
        contractAquaBalance = convertToEther(contractAquaBalance)
        aquaInAquaCakeVault = noExponents((lpInAquaCakeVault / lpTotalSupply) * contractAquaBalance)
      }

      /*USER AQUA BALANCE IN AQUA-BUSD VAULT*/
      poolLpAddress = (await poolInfo(aquaFarmAddress, '36'))['want']

      let lpInAquaBusdVault: any = parseFloat(convertToEther(await balanceOf(poolLpAddress, userAddress)))
      if (userAddress !== null) {
        let staked = await stakedWantTokens(aquaFarmAddress, '36', userAddress)
        staked = convertToEther(staked)
        lpInAquaBusdVault += parseFloat(staked)
      }

      if (lpInAquaBusdVault !== 0 && userAddress) {
        let lpTotalSupply = await totalSupply(poolLpAddress)
        lpTotalSupply = convertToEther(lpTotalSupply)
        let contractAquaBalance = await balanceOf(aquaAddress, poolLpAddress)
        contractAquaBalance = convertToEther(contractAquaBalance)
        aquaInAquaBusdVault = noExponents((lpInAquaBusdVault / lpTotalSupply) * contractAquaBalance)
      }

      /*USER AQUA BALANCE IN AQUA-GAMMA VAULT*/
      poolLpAddress = (await poolInfo(gammaFarmAdddress, '0'))['want']

      let lpInAquaGammaVault: any = parseFloat(convertToEther(await balanceOf(poolLpAddress, userAddress)))
      if (userAddress !== null) {
        let staked = await stakedWantTokens(gammaFarmAdddress, '0', userAddress)
        staked = convertToEther(staked)
        lpInAquaGammaVault += parseFloat(staked)
      }

      if (lpInAquaGammaVault !== 0 && userAddress) {
        let lpTotalSupply = await totalSupply(poolLpAddress)
        lpTotalSupply = convertToEther(lpTotalSupply)
        let contractAquaBalance = await balanceOf(aquaAddress, poolLpAddress)
        contractAquaBalance = convertToEther(contractAquaBalance)
        aquaInAquaGammaVault = noExponents((lpInAquaGammaVault / lpTotalSupply) * contractAquaBalance)
      }

      /*USER AQUA BALANCE IN NEW FARM AQUA-BNB VAULT*/
      poolLpAddress = (await poolInfo(gammaFarmAdddress, '1'))['want']

      let lpInNewFarmAquaBnbVault: any = 0
      if (userAddress !== null) {
        let staked = await stakedWantTokens(gammaFarmAdddress, '1', userAddress)
        staked = convertToEther(staked)
        lpInNewFarmAquaBnbVault = staked
      }

      if (lpInNewFarmAquaBnbVault !== 0 && userAddress) {
        let lpTotalSupply = await totalSupply(poolLpAddress)
        lpTotalSupply = convertToEther(lpTotalSupply)
        //to get aqua
        let contractAquaBalance = await balanceOf(aquaAddress, poolLpAddress)
        contractAquaBalance = convertToEther(contractAquaBalance)
        aquaInNewFarmAquaBnbVault = noExponents((lpInNewFarmAquaBnbVault / lpTotalSupply) * contractAquaBalance)
      }

      //user's aqua bal in aqua auto-componding vault

      const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress)
      let userDetails

      if (userAddress) userDetails = await poolInstance.methods.userInfo(userAddress).call()
      let userShares = 0
      if (userAddress) userShares = convertToEther(userDetails['shares'])
      let totalShares = await poolInstance.methods.totalShares().call()
      totalShares = convertToEther(totalShares)
      let aquaBalance = await poolInstance.methods.balanceOf().call()
      aquaBalance = convertToEther(aquaBalance)
      aquaInAutoCompoundingVault = (userShares / totalShares) * aquaBalance

      //aqua in green-planet
      let aquaInGreen = await getSupplyBalance(gAqua, userAddress)

      aquaInGreen += await getSupplyBalance(oldgAqua, userAddress)

      const bal = await balanceOf(aquaAddress, userAddress)
      const val = convertToEther(bal)
      userBal =
        parseFloat(val) +
        parseFloat(aquaInVault) +
        parseFloat(aquaInAquaCakeVault) +
        parseFloat(aquaInAquaBnbVault) +
        parseFloat(aquaInAquaBusdVault) +
        parseFloat(aquaInAquaGammaVault) +
        parseFloat(aquaInNewFarmAquaBnbVault) +
        parseFloat(aquaInAutoCompoundingVault) +
        aquaInGreen

      userBalInUsd = parseFloat(userBal) * parseFloat(aquaPrice)
    } else userBal = 0
    return {
      circulatingSupply: aquaTotalSupply - burnedAqua,
      userBalance: noExponents(userBal),
      userBalInUsd: userBalInUsd,
      maxSupply: 100000 - burnedAqua,
      userGammaBalance: userGammaBalance,
      userGammaBalanceUsd: userGammaBalanceUsd,
      aquaPrice: !isNaN(aquaPrice) ? aquaPrice : 0,
      gammaPrice: !isNaN(gammaPrice) ? gammaPrice : 0,
    }
  } catch (error) {
    console.log('error==>', error)
  }
}

const noExponents = function (num: any) {
  var data = String(num).split(/[eE]/)
  if (data.length === 1) return data[0]

  var z = '',
    sign = num < 0 ? '-' : '',
    str = data[0].replace('.', ''),
    mag = Number(data[1]) + 1

  if (mag < 0) {
    z = sign + '0.'
    while (mag++) z += '0'
    return z + str.replace(/^\-/, '')
  }
  mag -= str.length
  while (mag--) z += '0'
  return str + z
}

export const selectInstance = async (type: any, contractAddress: any, write = false) => {
  switch (type) {
    case 'AQUAFARM':
      return new wallet.web3.eth.Contract(aquaFarmAbi, contractAddress)
    case 'GAMMAFARM':
      return new wallet.web3.eth.Contract(gammaFarmAbi, contractAddress)
    case 'PANCAKELP':
      return new wallet.web3.eth.Contract(pancakeLPabi, contractAddress)
    case 'MASTERCHEF':
      return new wallet.web3.eth.Contract(masterchefAbi, contractAddress)
    case 'STRATEGY_ABI':
      return new wallet.web3.eth.Contract(STRATEGY_ABI, contractAddress)
    case 'FACTORY':
      return new wallet.web3.eth.Contract(pancakeFactoryAbi, contractAddress)
    case 'AQUA_AUTO_COMP':
      return new wallet.web3.eth.Contract(aquaAutoCompPoolAbi, contractAddress)
    case 'priceOracle':
      return new wallet.web3.eth.Contract(priceOracleAbi, contractAddress)
    case 'priceOracleOld':
      return new wallet.web3.eth.Contract(priceOracleAbiOld, contractAddress)
    case 'gammatroller':
      return new wallet.web3.eth.Contract(gammatrollerAbi, contractAddress)
    case 'gammatrollerOld':
      return new wallet.web3.eth.Contract(gammatrollerAbiOld, contractAddress)
    case 'gToken':
      return new wallet.web3.eth.Contract(gTokenAbi, contractAddress)
    case 'gBNB':
      return new wallet.web3.eth.Contract(gBnbAbi, contractAddress)
    case 'NEW_STRATEGY_ABI':
      return new wallet.web3.eth.Contract(newBoostedVaultsAbi, contractAddress)
    case 'CLAIM_CAKE':
      return new wallet.web3.eth.Contract(claim_cake_abi, contractAddress)
    default:
      return null
  }
}

// export const returnPlatformData = async (userAddress: any) => {
//   //userAddress = "0x3BDaDCbF17e24B2157d920E25407FF8cd4f5F54C"
//   const portfolio_data: any = (await getPortfolioData(userAddress))
//   try {
//     const poolDetails: any = await returnPoolData(userAddress);
//     const aquaTotalSupply = convertToEther(await totalSupply(aquaAddress))
//     const maxSupply = convertToEther(await AQUAMaxSupply())
//     const deadAquaBal = await balanceOf(aquaAddress, '0x000000000000000000000000000000000000dEaD')

//     const obj = {
//       circulatingSupply: parseFloat(aquaTotalSupply),
//       maxSupply: maxSupply - convertToEther(deadAquaBal),
//       dailyEarning: portfolio_data.daily_earning,
//       myPortfolioCurrentApy: portfolio_data.current_apy,
//       myPortfolioRewards: portfolio_data.pending_earnings,
//       totalBalance: portfolio_data.balance_in_vaults,
//       marketCap: (convertToEther(aquaTotalSupply) - convertToEther(deadAquaBal)) * (await getAquaPrice()),
//       totalProfitGenerated: convertToEther(aquaTotalSupply) * (await getAquaPrice()),
//       perDayProfitGenerated: convertToEther(deadAquaBal) * (await getAquaPrice()),
//       aquaBurn: convertToEther(deadAquaBal),
//     }

//     const aquaPlatformData = {
//       poolData: [...poolDetails],
//       plateFormData: { ...obj },
//     }

//     return aquaPlatformData
//   } catch (err) {
//     console.log(err)
//     const emptyPlatformData = {
//       poolData: [],
//       plateFormData: {},
//     }
//     return emptyPlatformData
//   }
// }

export const getUserBalInGtokenInfinityVaultInWei = async (userAddress: string, infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const gtoken_balance = await infinity_vault_inst.methods.getUserStakingGtokenBal(userAddress).call()//
  return gtoken_balance;
}

export const getUserBalInGtokenInfinityVaultInWeiNew = async (pid: any, userAddress: string) => {
  const strategy_inst: any = pid == 0 ? new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new) : new wallet.web3.eth.Contract(aqua_strategy_abi, aqua_strategy_address_new);
  const iTokenExchangeRate = await strategy_inst.methods.iTokenExchangeRate().call();
  const iToken_inst: any = pid == 0 ? new wallet.web3.eth.Contract(gamma_infinity_vault_abi, gamma_infinity_vault_address): new wallet.web3.eth.Contract(aqua_infinity_vault_abi, aqua_infinity_vault_address);
  const iTokenBalance = await iToken_inst.methods.balanceOf(userAddress).call();
  const farm_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
  const userInfo = await farm_inst.methods.userInfo(pid, userAddress).call(); 
  // const farm_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
  // const userInfo = await farm_inst.methods.userInfo(pid, userAddress).call();

  let gTokenBalance = (iTokenBalance) * iTokenExchangeRate/1e18;
  // console.log("inside getUserBalInGtokenInfinityVaultInWeiNew",gTokenBalance, iTokenExchangeRate, iTokenBalance, userAddress)
  return gTokenBalance;//balance of in Igamma  - iTokenToBeUnstaked * itoken exchange rate
}//balance of itokens from itoken contract * exchange rate from infinity strategy / 1e18 //getUserGtokenbalance * gtoken exchange/ 1e18

export const getUserBalGivenForUnstakinginInfinityVaultInWei = async (userAddress: string, infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const gtoken_unstake_balance = await infinity_vault_inst.methods.getUserGtokenBalGivenForUnstaking(userAddress).call()
  return gtoken_unstake_balance;
}

export const getUserBalGivenForUnstakinginInfinityVaultInWeiNew = async (pid: any, userAddress: string) => {
  const farm_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
  const userInfo = await farm_inst.methods.userInfo(pid, userAddress).call();
  return userInfo.gTokenToBeUnstaked; //userinfo in farm - gTokenToBeUnstaked
}

export const getUserUnstakingTimeInInfinityVault = async (userAddress: string, infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)// user info in farm
  const user_info = await infinity_vault_inst.methods.userInfo(userAddress).call()

  const user_unstake_start_time = user_info.unstakeStartTime
  const user_min_time_to_withdraw = user_info.minTimeToWithdraw

  const countDownDateTimeStamp = user_unstake_start_time > 0 ? parseInt(user_unstake_start_time) + parseInt(user_min_time_to_withdraw) : 0

  if (countDownDateTimeStamp === 0) return new Date()

  const date = new Date(countDownDateTimeStamp * 1000)

  // const countDownDate = new Date(countDownDateTimeStamp * 1000).getTime();

  // const now = new Date().getTime();
  // const timeleft = countDownDate > now ? countDownDate - now : 0;

  // const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
  // const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  // const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
  // const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

  // return {
  //   days,
  //   hours,
  //   minutes,
  //   seconds
  // }
  return date
}

export const getUserUnstakingTimeInInfinityVaultNew = async (userAddress: string, pid: any) => {
  const farm_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
  const userInfo = await farm_inst.methods.userInfo(pid, userAddress).call();

  const user_unstake_start_time = userInfo.unstakeStartTime
  const user_min_time_to_withdraw = userInfo.minTimeToWithdraw

  const countDownDateTimeStamp = user_unstake_start_time > 0 ? parseInt(user_unstake_start_time) + parseInt(user_min_time_to_withdraw) : 0;

  if (countDownDateTimeStamp === 0) return new Date()

  const date = new Date(countDownDateTimeStamp * 1000)
  return date
}

export const normalWithdrawFeeOfThisInfinityVault = async (infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const fee = parseInt(await infinity_vault_inst.methods.normalWithdrawFee().call()) / 100
  return fee 
}

export const normalWithdrawFeeOfThisInfinityVaultNew = async (pid: any) => {
  const strategy_inst: any = pid == 0 ? new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new) : new wallet.web3.eth.Contract(aqua_strategy_abi, aqua_strategy_address_new);
  const fee = parseInt(await strategy_inst.methods.withdrawFeeFactor().call()) / 100
  return fee; // strategy gamma or aqua
}

export const instantWithdrawFeeOfThisInfinityVault = async (infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const fee = parseInt(await infinity_vault_inst.methods.instantWithdrawFee().call()) / 100
  return fee // strategy gamma or aqua
}

export const instantWithdrawFeeOfThisInfinityVaultNew = async (pid: any) => {
  const strategy_inst: any = pid == 0 ? new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new) : new wallet.web3.eth.Contract(aqua_strategy_abi, aqua_strategy_address_new);
  const fee = parseInt(await strategy_inst.methods.instantWithdrawFeeFactor().call()) / 100
  return fee; // strategy gamma or aqua
}

export const depositFeeOfThisInfinityVault = async (infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const fee = parseInt(await infinity_vault_inst.methods.depositFee().call()) / 100
  return fee // strategy gamma or aqua
}

export const depositFeeOfThisInfinityVaultNew = async (pid: any) => {
  const strategy_inst: any = pid == 0 ? new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new) : new wallet.web3.eth.Contract(aqua_strategy_abi, aqua_strategy_address_new);
  const fee = parseInt(await strategy_inst.methods.entranceFeeFactor().call()) / 100
  return fee; // strategy gamma or aqua
}

export const infinityVaultbalanceOfGtoken = async (infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  return await infinity_vault_inst.methods.balanceOfGtoken().call() 
}

export const infinityVaultbalanceOfGtokenNew = async (pid: any, userAddress: string) => {
  
  const infinity_vault_inst = pid == 0 ? new wallet.web3.eth.Contract(gamma_infinity_vault_abi, gamma_infinity_vault_address) : new wallet.web3.eth.Contract(aqua_infinity_vault_abi, aqua_infinity_vault_address);
  const iTokenBal: any = await infinity_vault_inst.methods.totalSupply().call();

  const strategy_inst: any = pid == 0 ? new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new) : new wallet.web3.eth.Contract(aqua_strategy_abi, aqua_strategy_address_new);
  const itoken_exchange_rate = await strategy_inst.methods.iTokenExchangeRate().call();

  const infinityVaultbalanceInGToken: any = (iTokenBal * itoken_exchange_rate) / 1e18;
  // console.log("inside infinityVaultbalanceOfGtokenNew",userAddress, pid, infinityVaultbalanceInGToken, iTokenBal,itoken_exchange_rate )
  return infinityVaultbalanceInGToken;
}//balance of itokens from itoken contract * exchange rate from infinity strategy / 1e18 //getUserGtokenbalance * gtoken exchange/ 1e18

export const getWeeklyFeeRewardsAccruedInfVault = async (infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  return await infinity_vault_inst.methods.feeRewardsAccruedPerWeek().call()//call from aqua and gamma strategy
}

export const getWeeklyFeeRewardsAccruedInfVaultNew = async (pid: any) => {
  const strategy_inst: any = pid == 0 ? new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new) : new wallet.web3.eth.Contract(aqua_strategy_abi, aqua_strategy_address_new);
  return await strategy_inst.methods.feeRewardsAccruedPerWeek().call()//call from aqua and gamma strategy
}

export const infinityVaultTvlInWei = async (infinity_vault: any, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const tvl = await infinity_vault_inst.methods.balanceOfGtoken().call()
  return tvl;
}

export const infinityVaultTvlInWeiNew = async (pid: any) => {
  let strategy_inst: any;
  let infinity_vault_inst: any;
  let gToken: any;
  if(pid == 0){
    strategy_inst = new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new)
    gToken = gGamma;
    infinity_vault_inst = new wallet.web3.eth.Contract(gamma_infinity_vault_abi, gamma_infinity_vault_address)
  } else {
    strategy_inst = new wallet.web3.eth.Contract(aqua_strategy_abi, aqua_strategy_address_new)
    gToken = gAqua;
    infinity_vault_inst = new wallet.web3.eth.Contract(aqua_infinity_vault_abi, aqua_infinity_vault_address);
  }
  const exchangeRate = await strategy_inst.methods.iTokenExchangeRate().call();
  // console.log("exchangeRate", exchangeRate)
  const totalSupply = await infinity_vault_inst.methods.totalSupply().call();
  let tvl: any = totalSupply * exchangeRate / 1e18;
  // console.log("tvl", tvl)
  return tvl; //GToken balance of strategy  * exchange rate (convert it into gamma )
}//total supply * itoken exchange rate * gtoken excahnge/1e36 // want lock total of gtoken in strategy * gtoken exchangerate/1e18

export const minTimeToWithdraw = async (infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const minTime = await infinity_vault_inst.methods.minTimeToWithdraw().call()
  return minTime
}

export const minTimeToWithdrawNew = async () => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
  const minTime = await infinity_vault_inst.methods.minTimeToWithdraw().call()
  // console.log("minTimeToWithdrawNew",minTime)
  return minTime
}

export const getUserWantBalOfThisInfinityVaultInWei = async (userAddress: string, infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const gToken = await infinity_vault_inst.methods.gToken().call()


  //   const pool_id = await infinity_vault_inst.methods.pid().call();
  //   const farm_inst =  new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress);
  //   const user_info = await farm_inst.methods.userInfo(pool_id,infinity_vault).call();
  //   const pool_info = await farm_inst.methods.poolInfo(pool_id).call();
  //   const strat = pool_info.strat;
  //   const strat_inst = new wallet.web3.eth.Contract(STRATEGY_ABI, strat);
  //   const wantLocked = await strat_inst.methods.wantLockedTotal().call();
  //   const sharesTotal = await strat_inst.methods.sharesTotal().call();

  // if(infinity_vault === aqua_infinity_vault_address) {
  //   console.log("\n")
  //   console.log("STRATEGY WANT LOCKED TOTAL",wantLocked);  
  //   console.log("STRATEGY SHARES TOTAL",sharesTotal);  
  //   console.log("USER_INFO_OF_INFINITY_VAULT_IN_FARM",user_info);
  //   console.log("AVAILAIBLE",await infinity_vault_inst.methods.available().call());
  //   console.log("TOTAL SUPPLY",await infinity_vault_inst.methods.totalSupply().call());
  //   console.log("BALANCE OF GTOKEN",await infinity_vault_inst.methods.balanceOfGtoken().call());
  //   console.log("ITOKEN EXCHANGE RATE:-",await infinity_vault_inst.methods.iTokenExchangeRate().call())
  //   console.log("\n")
  // }

  const gtoken_inst = new wallet.web3.eth.Contract(pancakeLPabi, gToken)
  const bal = await gtoken_inst.methods.balanceOf(userAddress).call() //bal  of user  in gToken contract
  return bal
}

export const getUserWantBalOfThisInfinityVaultInWeiNew = async (userAddress: string, pid: any) => {
  // const infinity_vault_inst = pid == 0 ? new wallet.web3.eth.Contract(gamma_infinity_vault_abi, gamma_infinity_vault_address) : new wallet.web3.eth.Contract(aqua_infinity_vault_abi, aqua_infinity_vault_address);
  // const iTokenBal: any = await infinity_vault_inst.methods.balanceOf(userAddress).call();
  // const strategy_inst: any = pid == 0 ? new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new) : new wallet.web3.eth.Contract(aqua_strategy_abi, aqua_strategy_address_new);
  // const iTokenExchangeRate = await strategy_inst.methods.iTokenExchangeRate().call();
  // const bal =  (iTokenBal * iTokenExchangeRate)/1e18;//bal  of user  in gToken contract
  const token_inst = pid == 0 ? new wallet.web3.eth.Contract(pancakeLPabi, gammaAddress) : new wallet.web3.eth.Contract(pancakeLPabi, aquaAddress)
  const bal: any = await token_inst.methods.balanceOf(userAddress).call();
  return bal;
}

export const getPriceDataOfGivenInfinityVaultInEth = async (infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  // console.log("gToken infinity vault before", infinity_vault)
  const gToken = infinity_vault == "0x6bD50dFb39699D2135D987734F4984cd59eD6b53" ? gGamma: gAqua //await infinity_vault_inst.methods.gToken().call()
  // console.log("gToken infinity vault", infinity_vault, gToken)
  const underlyingPrice = convertToEther(await getUnderlyingPrice(gToken))
  // console.log("underlyingPrice in infinity vaults old", underlyingPrice)
  const underlyingToken = await underlying(gToken)
  const decimal = await decimals(underlyingToken)
  const exchangeRate = await exchangeRateStored(gToken)//exchangerate from gtoken contract
  const decimalRatio = parseInt(decimal) + 18
  const noOfGtokenInOneToken = Math.pow(10, decimalRatio) / exchangeRate / Math.pow(10, 8)

  return {
    gToken,
    exchangeRate,
    decimalRatio,
    underlyingPrice,
    gTokenPrice: underlyingPrice / noOfGtokenInOneToken,
  }
}

export const getPriceDataOfGivenInfinityVaultInEthNew = async (pid: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
  const gToken = pid == 0 ? gGamma : gAqua;//await infinity_vault_inst.methods.gToken().call()
  // console.log("gToken infinity vault", infinity_vault, gToken)
  const underlyingPrice = convertToEther(await getUnderlyingPrice(gToken));
  // console.log("underlyingPrice in infinity vaults new", underlyingPrice)
  const underlyingToken = pid == 0 ? gammaAddress : aquaAddress;//await underlying(gToken)
  const decimal = "18";//await decimals(underlyingToken)
  const exchangeRate = await exchangeRateStored(gToken)
  const decimalRatio = parseInt(decimal) + 18
  const noOfGtokenInOneToken = Math.pow(10, decimalRatio) / exchangeRate / Math.pow(10, 8)
  return {
    gToken,
    exchangeRate,
    decimalRatio,
    underlyingPrice,
    gTokenPrice: underlyingPrice / noOfGtokenInOneToken,
  }
}

export const getInfinityVaultApy = async (infinity_vault: string, infinity_abi: any, priceData: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const pid = await infinity_vault_inst.methods.pid().call()

  const _poolInfo = await poolInfo(gammaFarmAdddressOld, pid)
  const totalAlloc = await totalAllocPoint(gammaFarmAdddressOld)
  let gammaPerBlock: any = await GAMMAPerBlock()
  gammaPerBlock = convertToEther(gammaPerBlock)
  let wantTotal: any = await wantLockedTotal(_poolInfo.strat)
  wantTotal = wantTotal /1e8;
  const gammaPrice = await getGammaPrice()
  let rewards: any = (gammaPerBlock * 28800 * (_poolInfo.allocPoint / totalAlloc) * gammaPrice * 365 * 100);
  let tvl : any =  (wantTotal * priceData.gTokenPrice);
  const yield_ = rewards/tvl
    //(gammaPerBlock * 28800 * (_poolInfo.allocPoint / totalAlloc) * gammaPrice * 365 * 100) / (wantTotal * priceData.underlyingPrice)
  // console.log("getInfinityVaultApy details", infinity_vault)
  // console.log("pid", pid)
  // console.log("totalAlloc", totalAlloc)
  // console.log("_poolInfo", _poolInfo)
  // console.log("gammaPerBlock", gammaPerBlock)
  // console.log("wantTotal", wantTotal)
  // console.log("gammaPrice", gammaPrice)
  // console.log("yield_", yield_)
  // console.log("rewards", rewards)
  // console.log("tvl", tvl)
  // console.log("priceData for infinity_vault", priceData)
  const apy = await getAutoAquaTokenYield(yield_, 36500);
  // console.log("infinity vault apy old", infinity_vault, apy)
  return apy
}

export const getInfinityVaultApyNew = async (infinity_vault_pid: number, priceData: any) => {
  //const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  //const pid = await infinity_vault_inst.methods.pid().call()

  const _poolInfo = await poolInfoNew(gammaFarmAdddress, infinity_vault_pid)
  const totalAlloc = await totalAllocPoint(gammaFarmAdddress)
  let gammaPerBlock: any = await GAMMAPerBlockNew() // *** reservoir - gammadrippedperblock - is it same as gammaperblock
  gammaPerBlock = convertToEther(gammaPerBlock)
  let wantTotal: any = await wantLockedTotal(_poolInfo.strat) // strategy contract
  wantTotal = wantTotal /1e8;
  const gammaPrice = await getGammaPrice()
  let rewards: any = totalAlloc > 0 ? (gammaPerBlock * 28800 * (_poolInfo.allocPoint / totalAlloc) * gammaPrice * 365 * 100) : 0;
  let tvl : any = (wantTotal * priceData.gTokenPrice);
  const yield_ = tvl > 0 ? (rewards/tvl) : 0;
    //(gammaPerBlock * 28800 * (_poolInfo.allocPoint / totalAlloc) * gammaPrice * 365 * 100) / (wantTotal * priceData.underlyingPrice)
  // console.log("getInfinityVaultApy details")
  // console.log("pid", infinity_vault_pid)
  // console.log("totalAlloc", totalAlloc)
  // console.log("_poolInfo", _poolInfo)
  // console.log("gammaPerBlock", gammaPerBlock)
  // console.log("wantTotal", wantTotal)
  // console.log("gammaPrice", gammaPrice)
  // console.log("yield_", yield_)
  // console.log("rewards", rewards)
  // console.log("tvl", tvl)
  // console.log("priceData for infinity_vault", priceData)
  const apy = await getAutoAquaTokenYield(yield_, 36500);
  // console.log("infinity vault apy new ", infinity_vault_pid, apy)
  return apy
}

export const getFeeRewards = async (infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const feeRewards = (await infinity_vault_inst.methods.feeRewards().call()) / 1e8
  return feeRewards // strategy - fee rewards accrued
}

export const getFeeRewardsNew = async (pid: any) => {
  const strategy_inst: any = pid == 0 ? new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new) : new wallet.web3.eth.Contract(aqua_strategy_abi, aqua_strategy_address_new);
  const feeRewards = (await strategy_inst.methods.feeRewardsAccrued().call()) / 1e8
  return feeRewards; // strategy - fee rewards accrued
}

export const getUserPendingGammaInAquaInfinityVault = async (userAddress: string, vault: string, abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(abi, vault);
  try {
    let pending1: any = await getPendingRewardsUsingMulticall(aqua_strategy_address); // ***verify with Raj
    pending1 = pending1 * 1e18;
    pending1 = pending1.toString();
    pending1 = pending1.split(".");
    let final_pending = await infinity_vault_inst.methods.pendingGAMMA(userAddress, pending1[0]).call(); // _pendingGAMMA is internal in gamma farm implementation - need to make it public to use
    final_pending = final_pending / 1e18;
    // console.log("final Pending", final_pending);
    return final_pending;
  } catch(error) {
    console.log(error);
    return 0;
  }
  
}

const getTotalGammaSupplyApyVal = async(tokenAddress: string) => {
  let totalGammaSupplyApy: any;
  
  try {
    if(Object.keys(planetGlobalObject.poolGammaSupplyApy).length > 0) {
      totalGammaSupplyApy = planetGlobalObject.poolGammaSupplyApy;
    }
    else {
      totalGammaSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApy')).data;
    }
  } catch (error) {
    try {
      totalGammaSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApy')).data;
    } catch (error) {
      console.log('error in fetching getTotalSupplyApy', error);
    }
  }
  
  for(var key in totalGammaSupplyApy){
    
    if(key.toLowerCase() === tokenAddress.toLowerCase()){
      // console.log("tokenAddress in` getTotalGammaSupplyApyVal", tokenAddress, totalGammaSupplyApy[key]);
      return +totalGammaSupplyApy[key];
    }
  }
  return 0;
}


export const getInfinityVaultData = async (userAddress?: string) => {
  // userAddress = "0x43B513c90cb8A32d34ee213ED94F9655F10550fe";
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  
  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format : any = [];
  let inf_vault_arr: any = [0,1];
  let iTokenArr: any = [gamma_infinity_vault_address, aqua_infinity_vault_address];
  let iTokenABIArr: any = [gamma_infinity_vault_abi, aqua_infinity_vault_abi];
  let iStrategyAddressArr: any = [gamma_strategy_address_new, aqua_strategy_address_new];
  let iStrategyABIArr: any = [gamma_strategy_abi, aqua_strategy_abi];
  let tokenArr: any = [gammaAddress, aquaAddress]; 
  let gTokenArr: any = [gGamma, gAqua]; 

  let underlyingPriceArr: any = [];
  let gTokenExchangeRateArr: any = [];
  let iTokenExchangeRateArr: any = [];
  let totalSupplyArr: any = [];
  let poolInfoArr: any = [];
  let wantLockedTotalArr: any = [];
  let weeklyFeeRewardsArr: any = [];
  let iTokenbalanceOfArr: any = [];
  let userInfoArr: any = [];
  let entranceFeeFactorArr: any = [];
  let withdrawFeeFactorArr: any = [];
  let instantWithdrawFeeFactorArr: any = [];
  let vaultAllowanceArr: any = [];
  let underlyingTokenBalance: any = [];
  let feeRewardsAccruedArr: any = [];
  let initial_timestamp = new Date().getTime();
  try{
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress);
    let total_alloc_point: any = await farm_inst.methods.totalAllocPoint().call();
    let reservoir_inst = new wallet.web3.eth.Contract(reservoir_abi, gamma_reservoir);
    const reservoirDripRate: any = await reservoir_inst.methods.farmV2DripRate().call();
    let priceArray = await returnTokenPriceMulticall();
    let gammaPrice: any = priceArray[gammaAddress.toLowerCase()] ? priceArray[gammaAddress.toLowerCase()] : await getGammaPrice();
    let aquaPrice: any = priceArray[aquaAddress.toLowerCase()] ? priceArray[aquaAddress.toLowerCase()] : await getAquaPrice();
    // console.log("Gamma price in inf vault", gammaPrice)
    let burnApy = parseFloat(await getBurnApy());
    // console.log("burn apy in infinity vaults data", burnApy)
    burnApy = burnApy ? burnApy : 0;
    const minTime = await farm_inst.methods.minTimeToWithdraw().call();
    const oracle_inst: any =  new wallet.web3.eth.Contract(priceOracleAbi, priceOracleAddress)
    const gToken_inst: any =  new wallet.web3.eth.Contract(gBnbAbi, gGamma)
    let strategy_inst = new wallet.web3.eth.Contract(gamma_strategy_abi, gamma_strategy_address_new)
    let infinity_vault_inst = new wallet.web3.eth.Contract(gamma_infinity_vault_abi, gamma_infinity_vault_address)
    let token_inst = new wallet.web3.eth.Contract(pancakeLPabi, gammaAddress)
    //underlying price
    inf_vault_arr.forEach(async(vault_id: any) => {
      let gToken = vault_id == 0 ? gGamma : gAqua;
      targets.push(priceOracleAddress);
      const data = wallet.web3.eth.abi.encodeFunctionCall(oracle_inst.methods.getUnderlyingPrice(gToken)._method,[gToken]);
      callDatas.push(data); 
      ouput_format.push(oracle_inst.methods.getUnderlyingPrice(gToken)._method.outputs)
    })

    //gtoken exchange rate
    inf_vault_arr.forEach(async(vault_id: any) => {
      let gToken = vault_id == 0 ? gGamma : gAqua;
      targets.push(gToken);
      const data = wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.exchangeRateStored()._method,[]);
      callDatas.push(data); 
      ouput_format.push(gToken_inst.methods.exchangeRateStored()._method.outputs)    
    })

    //itoken exchange rate
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      
      targets.push(iStrategyAddressArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(strategy_inst.methods.iTokenExchangeRate()._method,[]);
      callDatas.push(data); 
      ouput_format.push(strategy_inst.methods.iTokenExchangeRate()._method.outputs)    
    })

    //total supply
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      
      targets.push(iTokenArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(infinity_vault_inst.methods.totalSupply()._method,[]);
      callDatas.push(data); 
      ouput_format.push(infinity_vault_inst.methods.totalSupply()._method.outputs)    
    })

    //pool info
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      targets.push(gammaFarmAdddress);
      const data = wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.poolInfo(vault_id)._method,[vault_id]);
      callDatas.push(data); 
      ouput_format.push(farm_inst.methods.poolInfo(vault_id)._method.outputs)    
    })
   
    //weekly fee rewards
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      targets.push(iStrategyAddressArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(strategy_inst.methods.feeRewardsAccruedPerWeek()._method,[]);
      callDatas.push(data); 
      ouput_format.push(strategy_inst.methods.feeRewardsAccruedPerWeek()._method.outputs)    
    })

    //gtoken in inf vaults
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      targets.push(iStrategyAddressArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(strategy_inst.methods.wantLockedTotal()._method,[]);
      callDatas.push(data); 
      ouput_format.push(strategy_inst.methods.wantLockedTotal()._method.outputs)    
    })

    //entrance fee factor
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      targets.push(iStrategyAddressArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(strategy_inst.methods.entranceFeeFactor()._method,[]);
      callDatas.push(data); 
      ouput_format.push(strategy_inst.methods.entranceFeeFactor()._method.outputs)
    })

    //withdraw fee factor
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      targets.push(iStrategyAddressArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(strategy_inst.methods.withdrawFeeFactor()._method,[]);
      callDatas.push(data); 
      ouput_format.push(strategy_inst.methods.withdrawFeeFactor()._method.outputs)
    })

    //instant withdraw fee factor
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      targets.push(iStrategyAddressArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(strategy_inst.methods.instantWithdrawFeeFactor()._method,[]);
      callDatas.push(data); 
      ouput_format.push(strategy_inst.methods.instantWithdrawFeeFactor()._method.outputs)
    })

    //total fee rewards accrued
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      targets.push(iStrategyAddressArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(strategy_inst.methods.feeRewardsAccrued()._method,[]);
      callDatas.push(data); 
      ouput_format.push(strategy_inst.methods.feeRewardsAccrued()._method.outputs)
    })

    if(userAddress){
      //balance of in itoken
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        targets.push(iTokenArr[index]);
        const data = wallet.web3.eth.abi.encodeFunctionCall(infinity_vault_inst.methods.balanceOf(userAddress)._method,[userAddress]);
        callDatas.push(data); 
        ouput_format.push(infinity_vault_inst.methods.balanceOf(userAddress)._method.outputs)
      })

      //user info
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        targets.push(gammaFarmAdddress);
        const data = wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.userInfo(vault_id, userAddress)._method,[vault_id, userAddress]);
        callDatas.push(data); 
        ouput_format.push(farm_inst.methods.userInfo(vault_id, userAddress)._method.outputs)
      })

      //vault allowance
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        
        targets.push(tokenArr[index]);
        const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.allowance(userAddress, gammaFarmAdddress)._method,[userAddress, gammaFarmAdddress]);
        callDatas.push(data); 
        ouput_format.push(token_inst.methods.allowance(userAddress, gammaFarmAdddress)._method.outputs)
      })

      //underlying token balance
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        targets.push(tokenArr[index]);
        const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.balanceOf(userAddress)._method,[userAddress]);
        callDatas.push(data); 
        ouput_format.push(token_inst.methods.balanceOf(userAddress)._method.outputs)
      })
    }
  
    const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());
    const do_split = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }  

    for(let i = 0 ; i < aggregated_data[1].length ; i++){
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
    let multicall_timestamp = new Date().getTime();
    // console.log("infinity vault time taken", multicall_timestamp- initial_timestamp);
    const split_arr = (await do_split(results, inf_vault_arr.length));
    underlyingPriceArr = split_arr[0];
    gTokenExchangeRateArr = split_arr[1]; // hardcode
    iTokenExchangeRateArr = split_arr[2];
    totalSupplyArr = split_arr[3];
    poolInfoArr = split_arr[4];
    weeklyFeeRewardsArr = split_arr[5];
    wantLockedTotalArr = split_arr[6];
    // console.log("weekly fee rewards", weeklyFeeRewardsArr)
    entranceFeeFactorArr = split_arr[7]; // hardcode
    withdrawFeeFactorArr = split_arr[8]; //hardcode
    instantWithdrawFeeFactorArr = split_arr[9]; // hardcode
    feeRewardsAccruedArr = split_arr[10];//split_arr[9];
    if(userAddress){
      iTokenbalanceOfArr = split_arr[11];//split_arr[10];
      userInfoArr = split_arr[12];//split_arr[11];
      vaultAllowanceArr = split_arr[13];//split_arr[12];
      underlyingTokenBalance = split_arr[14];//split_arr[13];
      // console.log('underlyingTokenBalance underlyingTokenBalance', split_arr[11]);
    }

    //gamma supply apy
    let gammaSupplyApy: any = {};
    try {
      if(Object.keys(planetGlobalObject.poolGammaSupplyApy).length > 0) {
        gammaSupplyApy = planetGlobalObject.poolGammaSupplyApy;
      }
      else {
        gammaSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApy')).data;
      }
    } catch (gamma_supply_error) {
      try {
        console.log('error in fetching getTotalSupplyApy catch 1', gamma_supply_error);
        gammaSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApy')).data;
      } catch (gamma_supply_error1) {
        console.log('error in fetching getTotalSupplyApy catch 2', gamma_supply_error1);
      }
    }

    let gammaSupplyApr: any = {};
    try {
      if(Object.keys(planetGlobalObject.poolGammaSupplyApr).length > 0) {
        gammaSupplyApr = planetGlobalObject.poolGammaSupplyApr;
      } else {
        gammaSupplyApr = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApr')).data;
      }
      
    } catch (gamma_supply_error) {
      try {
        console.log('error in fetching getTotalSupplyApr catch 1', gamma_supply_error);
        gammaSupplyApr = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApr')).data;
      } catch (gamma_supply_error1) {
        console.log('error in fetching getTotalSupplyApr catch 2', gamma_supply_error1);
      }
    }

    let getTotalGammaSupplyApyKeys = Object.keys(gammaSupplyApy);
    getTotalGammaSupplyApyKeys.forEach((element: any, key: any) => {
      let new_key = element.toLowerCase();
      gammaSupplyApy[new_key] = gammaSupplyApy[element]
    });
  
    // console.log("multicall arr data", split_arr)
    let infinity_vault_data: any = [];
    let vault_data: any = {};
    for (let i = 0; i < inf_vault_arr.length; i++) {
      
      let gTokenExchangeRate = gTokenExchangeRateArr[i][0];
      const decimalRatio = 36;
      const noOfGtokenInOneToken = Math.pow(10, decimalRatio) / gTokenExchangeRate / Math.pow(10, 8);
      let priceData = {
        gToken: gTokenArr[i],
        exchangeRate: gTokenExchangeRate,
        decimalRatio,
        underlyingPrice : underlyingPriceArr[i][0],
        gTokenPrice: underlyingPriceArr[i][0] / noOfGtokenInOneToken
      };
      // console.log("price data in infinity vault", i, priceData)
      let tvl: any = (totalSupplyArr[i][0] * iTokenExchangeRateArr[i][0] * priceData.gTokenPrice) / (1e18 * 1e8 * 1e18);
      let getAddress = (arr:any, q:any) => arr.findIndex((item:any )=> q.toLowerCase() === item.toLowerCase());
      let a = getAddress(Object.keys(gammaSupplyApy), gTokenArr[i]) ;
      // console.log('gTokenArr[i]', gTokenArr[i], a);
      let gammaApy: any = gammaSupplyApy[gTokenArr[i]];
      let gammaApr: any = gammaSupplyApr[gTokenArr[i]];
      let gammaPerBlock = convertToEther(reservoirDripRate);

      let rewards = total_alloc_point > 0 ? (gammaPerBlock * 28800 * (poolInfoArr[i].allocPoint / total_alloc_point) * gammaPrice * 365 * 100) : 0;
      const yield_: any = tvl > 0 ? (+rewards/+tvl): 0;
      let apy = ((1 + yield_ / 36500) ** 365 - 1) * 100;
      let aprtoapy = apy;
      // console.log("yield apy in inf", apy)
      apy += gammaApy ? gammaApy : 0;
      // console.log("gamma apy in inf", apy)
      if(i === 1 && burnApy !== undefined){
        apy += burnApy;
      } 
      // console.log("apy check in infinity vaults after burn apy", i, apy)
      let accuredBalancePerWeek = weeklyFeeRewardsArr[i][0];
      const min_Time_To_Withdraw = minTime / (24 * 60 * 60);
      const pending_gamma = 0;
      const infinity_vault_deposit_fee = 0;//entranceFeeFactorArr[i][0]/100;
      const infinity_vault_normal_withdraw_fee = 1;//withdrawFeeFactorArr[i][0]/100;
      const infinity_vault_instant_withdraw_fee = 5;//instantWithdrawFeeFactorArr[i][0]/100;
      const feeRewards = feeRewardsAccruedArr[i][0]/1e8;
      const apr = 0;
      let totalGTokenInVault = wantLockedTotalArr[i][0];
      // console.log("total gamma tokens in the vault", totalGTokenInVault, gTokenExchangeRate)
      let widthdrawApr = ((accuredBalancePerWeek/totalGTokenInVault) * (365/7));
      let widthdrawApy = (Math.pow((1 + widthdrawApr/(365*3)), (365*3)) -1 ) * 100;
      // console.log("withdraw apy", i, widthdrawApy, accuredBalancePerWeek)
      apy += widthdrawApy == widthdrawApy ? widthdrawApy : 0;
      // console.log("apy check in infinity vaults after withdraw apy", i, apy)
      let vaultContractLink = "https://bscscan.com/address/"+aqua_strategy_address_new+"#code"
      if(i == 0) {
        vaultContractLink = "https://bscscan.com/address/"+gamma_strategy_address_new+"#code"
      }
      if(userAddress){
        // console.log("user Address in inf vault", i)
        let exchangeRateDecimal: any = 28;
        let exchangeRate = gTokenExchangeRateArr[i][0] / Math.pow(10, exchangeRateDecimal);
        exchangeRate = 1 / exchangeRate;
        const infinity_want_bal = (iTokenbalanceOfArr[i][0] * iTokenExchangeRateArr[i][0])/(1e18);
        const infinity_unstaking_bal = userInfoArr[i].gTokenToBeUnstaked;
        let _allowance = vaultAllowanceArr[i][0];
        const infinity_token_wallet_bal_with_precision: any = underlyingTokenBalance[i][0];
        const infinity_token_wallet_bal: any = (infinity_token_wallet_bal_with_precision / Math.pow(10, 18));
        const infinity_vault_token_price = priceData.gTokenPrice;

        const infinity_vault_underlying_price: any = priceData.underlyingPrice;

        const infinity_token_wallet_bal_in_usd = (infinity_token_wallet_bal * infinity_vault_underlying_price)/ 1e18;
        const infinity_underlying_unstaking_bal = (infinity_unstaking_bal * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio);

        const infinity_underlying_unstaking_bal_in_usd = (infinity_underlying_unstaking_bal * infinity_vault_underlying_price) / 1e18;

        const infinity_underlying_deposited_bal = ((infinity_want_bal - infinity_unstaking_bal) * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio);
        // console.log("infinity_underlying_deposited_bal", vault, infinity_underlying_deposited_bal)
        const infinity_underlying_deposited_bal_in_usd = i == 0 ? (infinity_underlying_deposited_bal * gammaPrice) :  (infinity_underlying_deposited_bal * aquaPrice);

        const walletBalanceUSDUnderlyingToken = i == 0 ? 
        (infinity_token_wallet_bal * gammaPrice) :  
        (infinity_token_wallet_bal * aquaPrice);
        // console.log(Math.pow(2, 95))
        const isApproved = _allowance > Math.pow(2, 95) ? true : false//Math.pow(2, 200)

        const gtoken_deposited = infinity_want_bal / Math.pow(10, 8)

        const gtoken_user_can_unstake = gtoken_deposited
        let rewardsInfo: any = userAddress ? await fetchGammaRewardsInfo(userAddress) : {gammaInfinityRewards: 0, lifeRewards: 0};
        vault_data = {
          pid: i,
          poolId: i,
          exchangeRate: exchangeRate,
          pending_gamma: pending_gamma,
          infinityAbi: iTokenABIArr[i],
          feeRewards: feeRewards,
          gtoken_staked: gtoken_deposited,
          gtoken_user_can_unstake: gtoken_user_can_unstake,
          infinity_vault_address: iTokenArr[i],
          infinity_token_address: iTokenArr[i],//priceData.gToken,
          deposited_bal: infinity_underlying_deposited_bal_in_usd > 0.01? infinity_underlying_deposited_bal : 0,
          deposited_bal_usd: infinity_underlying_deposited_bal_in_usd > 0.01? infinity_underlying_deposited_bal_in_usd: 0,
          unstaking_bal: infinity_underlying_unstaking_bal_in_usd > 0.01 ? infinity_underlying_unstaking_bal: 0,
          unstaking_bal_in_usd: infinity_underlying_unstaking_bal_in_usd > 0.01 ? infinity_underlying_unstaking_bal_in_usd : 0,
          wallet_bal: infinity_token_wallet_bal_in_usd > 0.01 ? infinity_token_wallet_bal : 0,
          wallet_bal_in_usd: infinity_token_wallet_bal_in_usd > 0.01 ? infinity_token_wallet_bal_in_usd : 0,
          walletBalance: infinity_token_wallet_bal_in_usd > 0.01 ? infinity_token_wallet_bal : 0,
          walletBalanceUSD: walletBalanceUSDUnderlyingToken,
          walletBal:infinity_token_wallet_bal_in_usd > 0.01 ? infinity_token_wallet_bal : 0,
          walletBalInUsd: infinity_token_wallet_bal_in_usd > 0.01 ? infinity_token_wallet_bal_in_usd : 0,
          vaultBalance: infinity_underlying_deposited_bal_in_usd > 0.01? infinity_underlying_deposited_bal : 0,
          vaultBalanceUSD: infinity_underlying_deposited_bal_in_usd > 0.01? infinity_underlying_deposited_bal_in_usd: 0,
          currentlySupplying: infinity_underlying_deposited_bal_in_usd > 0.01? infinity_underlying_deposited_bal : 0,
          currentlySupplyingUsd: infinity_underlying_deposited_bal_in_usd > 0.01? infinity_underlying_deposited_bal_in_usd: 0,
          tvl: tvl,
          allowanceAmount: convertToEther(_allowance),
          allowance: convertToEther(_allowance),
          isApproved,
          infinity_vault_instant_withdraw_fee,
          infinity_vault_normal_withdraw_fee,
          infinity_vault_deposit_fee,
          min_Time_To_Withdraw,
          totalApy: apy,
          apy: apy,
          apr: yield_,
          justApy: aprtoapy,
          burnApy: burnApy,
          widthdrawApr: widthdrawApr,
          widthdrawApy: widthdrawApy,
          gammaApr: gammaApr,
          gammaApy: gammaApy,
          gammaSupplyApy: gammaApy,
          gammaSupplyApr: gammaApr,
          underlyingToken: tokenArr[i],
          wantAddress: tokenArr[i],
          farmAddress : gammaFarmAdddress,
          farmContractAddress: gammaFarmAdddress,
          wantaddress_farmaddress: tokenArr[i] + '_' + gammaFarmAdddress,
          rewardsInfo: rewardsInfo,
          infinity_token_wallet_bal_with_precision,
          userAddress,
          priceData,
          totalApr: (Math.pow((apy / 100 + 1), (1 / 365)) - 1) * 365 * 100,
          iTokenBalance: iTokenbalanceOfArr[i][0],
          price: i == 0 ? gammaPrice : aquaPrice,
          strategyAddress: i == 0? gamma_strategy_address_new : aqua_strategy_address_new,
          infinity_unstaking_bal: infinity_unstaking_bal,
          vaultMultiplier: 1,
          farmName: 'Planet',
          farmContract: "https://bscscan.com/address/"+gammaFarmAdddress+"#code",
          vaultContract: vaultContractLink,
          poolStatus: "active",
          name: i == 0? "GAMMA Infinity" : "AQUA Infinity",
          protocol: "Planet",
          pooltype: "infinity",
          poolAllocPoint: poolInfoArr[i].allocPoint,
          instantWithdrawFee: instantWithdrawFeeFactorArr[i][0]/100,
          withdrawalFees: withdrawFeeFactorArr[i][0]/100,
          depositFees: entranceFeeFactorArr[i][0]/100,
          icon: gammaInfinityIcon,
          totalMarketSizeUsd: tvl,
          totalMarketSize: tvl/gammaPrice,
          boostApr: 0,
          address: i == 0 ? gammaAddress : aquaAddress
        }
      } else {
        vault_data = {
          pid: i,
          poolId: i,
          exchangeRate: 0,
          infinityAbi: iTokenABIArr[i],
          feeRewards: 0,
          gtoken_staked: 0,
          gtoken_user_can_unstake: 0,
          infinity_vault_address: iTokenArr[i],
          infinity_token_address: zeroAddress,
          deposited_bal: 0,
          deposited_bal_usd: 0,
          unstaking_bal: 0,
          unstaking_bal_in_usd: 0,
          wallet_bal: 0,
          wallet_bal_in_usd: 0,
          walletBalance:  0,
          walletBalanceUSD: 0,
          vaultBalance:  0,
          vaultBalanceUSD:  0,
          tvl: tvl,
          isApproved: false,
          infinity_vault_instant_withdraw_fee: 0,
          infinity_vault_normal_withdraw_fee: 0,
          infinity_vault_deposit_fee: 0,
          min_Time_To_Withdraw: 0,
          apy: apy,
          totalApy: apy,
          apr: yield_,
          justApy: aprtoapy,
          burnApy: burnApy,
          widthdrawApr: widthdrawApr,
          widthdrawApy: widthdrawApy,
          gammaApr: gammaApr,
          gammaApy: gammaApy,
          gammaSupplyApy: gammaApy,
          gammaSupplyApr: gammaApr,
          underlyingToken: i == 0 ? gammaAddress : aquaAddress,
          wantAddress: tokenArr[i],
          farmAddress : gammaFarmAdddress,
          farmContractAddress: gammaFarmAdddress,
          wantaddress_farmaddress: tokenArr[i]+'_'+gammaFarmAdddress,
          rewardsInfo :{
            gammaInfinityRewards: 0,
            lifeRewards: 0
          },
          priceData,
          totalApr: (Math.pow((apy / 100 + 1), (1 / 365)) - 1) * 365 * 100,
          iTokenBalance: 0,
          price: i == 0 ? gammaPrice : aquaPrice,
          strategyAddress: i == 0? gamma_strategy_address_new : aqua_strategy_address_new,
          infinity_unstaking_bal: 0,
          vaultMultiplier: 1,
          farmName: 'Planet',
          farmContract: "https://bscscan.com/address/"+gammaFarmAdddress+"#code",
          vaultContract: vaultContractLink,
          poolStatus: "active",
          name: i == 0? "GAMMA Infinity" : "AQUA Infinity",
          protocol: "Planet",
          pooltype: "infinity",
          poolAllocPoint: poolInfoArr[i].allocPoint,
          infinity_token_wallet_bal_with_precision: 0,
          instantWithdrawFee: instantWithdrawFeeFactorArr[i][0]/100,
          withdrawalFees: withdrawFeeFactorArr[i][0]/100,
          depositFees: entranceFeeFactorArr[i][0]/100,
          icon: gammaInfinityIcon,
          totalMarketSizeUsd: tvl,
          totalMarketSize: tvl/gammaPrice,
          currentlySupplying: 0,
          currentlySupplyingUsd: 0,
          walletBal: 0,
          walletBalInUsd: 0,
          boostApr: 0,
          address: i == 0 ? gammaAddress : aquaAddress
        };
      }
      infinity_vault_data.push(vault_data)
    }
    // console.log("infinity_vault_data", infinity_vault_data, gammaPrice)
    return infinity_vault_data;
  } catch(error){
    console.log(error)
    return [];
  }
}

export const fetchGammaRewardsInfo = async (userAddress: string) => {
  try{
    // console.log("userAddress", userAddress)
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress);
    let rewardsInfo = await farm_inst.methods.getRewardsInfo(userAddress).call();
    // console.log("rewardsInfo", rewardsInfo)
    rewardsInfo.gammaInfinityRewards = rewardsInfo.gammaInfinityRewards/1e18;
    rewardsInfo.lifeRewards = rewardsInfo.lifeRewards/1e18;
    return rewardsInfo;
  } catch (error){
    console.log(error);
    return {gammaInfinityRewards: 0, lifeRewards: 0};
  }
  
}

export const getInfinityVaultDetailsOld = async (userAddress?: string) => {
  // userAddress = "0x99fedea26f6cdf924dd34c4a0e17afdfe553947f";
  const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
  
  let targets: any = [];
  let callDatas: any = [];
  let results: any = [];
  let ouput_format : any = [];
  let inf_vault_arr: any = [gamma_infinity_vault_address_old, aqua_infinity_vault_address_old];
  let iTokenArr: any = [gamma_infinity_vault_address_old, aqua_infinity_vault_address_old];
  let iTokenABIArr: any = [gamma_infinity_vault_abi_old, aqua_infinity_vault_abi_old];
  let iStrategyAddressArr: any = [gamma_strategy_address, aqua_strategy_address];
  let iStrategyABIArr: any = [gamma_strategy_abi, aqua_strategy_abi];
  let tokenArr: any = [gammaAddress, aquaAddress]; 
  let gTokenArr: any = [gGammaOld, gAquaOld]; 

  let underlyingPriceArr: any = [];
  let gTokenExchangeRateArr: any = [];
  let iTokenExchangeRateArr: any = [];
  let totalSupplyArr: any = [];
  let poolInfoArr: any = [];
  let wantLockedTotalArr: any = [];
  let weeklyFeeRewardsArr: any = [];
  let iTokenbalanceOfArr: any = [];
  let userInfoArr: any = [];
  let entranceFeeFactorArr: any = [];
  let withdrawFeeFactorArr: any = [];
  let instantWithdrawFeeFactorArr: any = [];
  let vaultAllowanceArr: any = [];
  let underlyingTokenBalance: any = [];
  let feeRewardsAccruedArr: any = [];
  let gTokenBalanceArr: any = [];
  let gTokenStakingBalance: any = [];  
  
  try{
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmAbiOld, gammaFarmAdddressOld);
    let total_alloc_point: any = await farm_inst.methods.totalAllocPoint().call();
    const reservoirDripRate: any = await farm_inst.methods.GAMMAPerBlock().call()
    let gammaPrice: any = await getGammaPrice();
    let burnApy = parseFloat(await getBurnApy());
    const iToken_inst: any = new wallet.web3.eth.Contract(gamma_infinity_vault_abi_old, gamma_infinity_vault_address_old);
    const minTime = await iToken_inst.methods.minTimeToWithdraw().call();
    // //underlying price
    gTokenArr.forEach(async(gTokenAddress: any) => {
      const oracle_inst: any =  new wallet.web3.eth.Contract(priceOracleAbiOld, priceOracleAddressOld)
      let gToken = gTokenAddress;
      targets.push(priceOracleAddressOld);
      const data = wallet.web3.eth.abi.encodeFunctionCall(oracle_inst.methods.getUnderlyingPrice(gToken)._method,[gToken]);
      callDatas.push(data); 
      ouput_format.push(oracle_inst.methods.getUnderlyingPrice(gToken)._method.outputs)
    })

    //gtoken exchange rate
    gTokenArr.forEach(async(gTokenAddress: any) => {
      let gToken = gTokenAddress;
      const gToken_inst: any =  new wallet.web3.eth.Contract(gBnbAbi, gToken)
      targets.push(gToken);
      const data = wallet.web3.eth.abi.encodeFunctionCall(gToken_inst.methods.exchangeRateStored()._method,[]);
      callDatas.push(data); 
      ouput_format.push(gToken_inst.methods.exchangeRateStored()._method.outputs)    
    })

    //itoken exchange rate
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      let iToken_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
      targets.push(iTokenArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(iToken_inst.methods.iTokenExchangeRate()._method,[]);
      callDatas.push(data); 
      ouput_format.push(iToken_inst.methods.iTokenExchangeRate()._method.outputs)    
    })

    //total supply
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      let infinity_vault_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
      targets.push(iTokenArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(infinity_vault_inst.methods.totalSupply()._method,[]);
      callDatas.push(data); 
      ouput_format.push(infinity_vault_inst.methods.totalSupply()._method.outputs)    
    })

    //gamma supply apy
    let gammaSupplyApy: any = {};
    try {
      if(Object.keys(planetGlobalObject.poolGammaSupplyApy).length > 0) {
        gammaSupplyApy = planetGlobalObject.poolGammaSupplyApy;
      }
      else {
        gammaSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApy')).data;
      }
    } catch (gamma_supply_error) {
      try {
        console.log('error in fetching getTotalSupplyApy catch 1', gamma_supply_error);
        gammaSupplyApy = (await axios.get(newPFApiBaseUrl+'v1/getGammaSupplyApy')).data;
      } catch (gamma_supply_error1) {
        console.log('error in fetching getTotalSupplyApy catch 2', gamma_supply_error1);
      }
    }
    let getTotalGammaSupplyApyKeys = Object.keys(gammaSupplyApy);
    getTotalGammaSupplyApyKeys.forEach((element: any, key: any) => {
      let new_key = element.toLowerCase();
      gammaSupplyApy[new_key] = gammaSupplyApy[element]
    });

    //pool info
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      targets.push(gammaFarmAdddressOld);
      let pool_id = index == 0 ? 59 : 58;
      const data = wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.poolInfo(pool_id)._method,[pool_id]);
      callDatas.push(data); 
      ouput_format.push(farm_inst.methods.poolInfo(pool_id)._method.outputs)    
    })
   
    //weekly fee rewards
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      let iToken_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
      targets.push(iTokenArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(iToken_inst.methods.feeRewardsAccruedPerWeek()._method,[]);
      callDatas.push(data); 
      ouput_format.push(iToken_inst.methods.feeRewardsAccruedPerWeek()._method.outputs)    
    })

    // //entrance fee factor
    // inf_vault_arr.forEach(async(vault_id: any, index: any) => {
    //   let iToken_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
    //   targets.push(iTokenArr[index]);
    //   const data = wallet.web3.eth.abi.encodeFunctionCall(iToken_inst.methods.depositFee()._method,[]);
    //   callDatas.push(data); 
    //   ouput_format.push(iToken_inst.methods.depositFee()._method.outputs)
    // })

    // //withdraw fee factor
    // inf_vault_arr.forEach(async(vault_id: any, index: any) => {
    //   let iToken_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
    //   targets.push(iTokenArr[index]);
    //   const data = wallet.web3.eth.abi.encodeFunctionCall(iToken_inst.methods.normalWithdrawFee()._method,[]);
    //   callDatas.push(data); 
    //   ouput_format.push(iToken_inst.methods.normalWithdrawFee()._method.outputs)
    // })

    // //instant withdraw fee factor
    // inf_vault_arr.forEach(async(vault_id: any, index: any) => {
    //   let iToken_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
    //   targets.push(iTokenArr[index]);
    //   const data = wallet.web3.eth.abi.encodeFunctionCall(iToken_inst.methods.instantWithdrawFee()._method,[]);
    //   callDatas.push(data); 
    //   ouput_format.push(iToken_inst.methods.instantWithdrawFee()._method.outputs)
    // })

    //total fee rewards accrued
    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      let iToken_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
      targets.push(iTokenArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(iToken_inst.methods.feeRewards()._method,[]);
      callDatas.push(data); 
      ouput_format.push(iToken_inst.methods.feeRewards()._method.outputs)
    })

    inf_vault_arr.forEach(async(vault_id: any, index: any) => {
      let token_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
      targets.push(iTokenArr[index]);
      const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.balanceOfGtoken()._method,[]);
      callDatas.push(data); 
      ouput_format.push(token_inst.methods.balanceOfGtoken()._method.outputs)
    })

    if(userAddress){
      // balance of in itoken
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        let infinity_vault_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
        targets.push(iTokenArr[index]);
        const data = wallet.web3.eth.abi.encodeFunctionCall(infinity_vault_inst.methods.balanceOf(userAddress)._method,[userAddress]);
        callDatas.push(data); 
        ouput_format.push(infinity_vault_inst.methods.balanceOf(userAddress)._method.outputs)
      })

      //user info
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        let token_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
        targets.push(iTokenArr[index]);
        const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.userInfo(userAddress)._method,[userAddress]);
        callDatas.push(data); 
        ouput_format.push(token_inst.methods.userInfo(userAddress)._method.outputs)
      })

      //vault allowance
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        let token_inst = new wallet.web3.eth.Contract(pancakeLPabi, gTokenArr[index])
        targets.push(gTokenArr[index]);
        const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.allowance(userAddress, iTokenArr[index])._method,[userAddress, iTokenArr[index]]);
        callDatas.push(data); 
        ouput_format.push(token_inst.methods.allowance(userAddress, iTokenArr[index])._method.outputs)
      })

      //underlying token balance
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        let token_inst = new wallet.web3.eth.Contract(pancakeLPabi, tokenArr[index])
        targets.push(tokenArr[index]);
        const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.balanceOf(userAddress)._method,[userAddress]);
        callDatas.push(data); 
        ouput_format.push(token_inst.methods.balanceOf(userAddress)._method.outputs)
      })

      //gToken staking bal
      inf_vault_arr.forEach(async(vault_id: any, index: any) => {
        let token_inst = new wallet.web3.eth.Contract(iTokenABIArr[index], iTokenArr[index])
        targets.push(iTokenArr[index]);
        const data = wallet.web3.eth.abi.encodeFunctionCall(token_inst.methods.getUserStakingGtokenBal(userAddress)._method,[userAddress]);
        callDatas.push(data); 
        ouput_format.push(token_inst.methods.getUserStakingGtokenBal(userAddress)._method.outputs)
      })

    }
    const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());
    const do_split = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }  

    for(let i = 0 ; i < aggregated_data[1].length ; i++){
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }
  
    const split_arr = (await do_split(results, inf_vault_arr.length));
    underlyingPriceArr = split_arr[0];
    gTokenExchangeRateArr = split_arr[1];
    iTokenExchangeRateArr = split_arr[2];
    totalSupplyArr = split_arr[3];
    poolInfoArr = split_arr[4];
    // wantLockedTotalArr = split_arr[5];
    weeklyFeeRewardsArr = split_arr[5];
    // entranceFeeFactorArr = split_arr[6];
    // withdrawFeeFactorArr = split_arr[7];
    // instantWithdrawFeeFactorArr = split_arr[8];
    feeRewardsAccruedArr = split_arr[6]//split_arr[9];
    gTokenBalanceArr = split_arr[7]//split_arr[10];
    if(userAddress){
      iTokenbalanceOfArr = split_arr[8]//split_arr[11];
      userInfoArr = split_arr[9]//split_arr[12];
      vaultAllowanceArr = split_arr[10]//split_arr[13];
      underlyingTokenBalance = split_arr[11]//split_arr[14];
      gTokenStakingBalance = split_arr[12]//split_arr[15];
    }
  
    // console.log("multicall arr data", split_arr)
    let infinity_vault_data: any = [];
    let vault_data: any = {};
    for (let i = 0; i < inf_vault_arr.length; i++) {
      
      let gTokenExchangeRate = gTokenExchangeRateArr[i][0];
      const decimalRatio = 36;
      const noOfGtokenInOneToken = Math.pow(10, decimalRatio) / gTokenExchangeRate / Math.pow(10, 8);
      let priceData = {
        gToken: gTokenArr[i],
        exchangeRate: gTokenExchangeRate,
        decimalRatio,
        underlyingPrice : underlyingPriceArr[i][0],
        gTokenPrice: underlyingPriceArr[i][0] / noOfGtokenInOneToken
      };
      let tvl: any = (totalSupplyArr[i][0] * iTokenExchangeRateArr[i][0] * priceData.gTokenPrice) / (1e18 * 1e8 * 1e18);
      let gammaApy: any = gammaSupplyApy[gTokenArr[i].toLowerCase()];
      let gammaPerBlock = convertToEther(reservoirDripRate);

      let rewards = total_alloc_point > 0 ? (gammaPerBlock * 28800 * (poolInfoArr[i].allocPoint / total_alloc_point) * gammaPrice * 365 * 100) : 0;
      const yield_: any = tvl > 0 ? (+rewards/+tvl): 0;
      let apy = ((1 + yield_ / 36500) ** 365 - 1) * 100;
      apy += gammaApy ? gammaApy : 0;
      if(i === 1 && burnApy !== undefined){
        apy += burnApy;
      } 
      let accuredBalancePerWeek = weeklyFeeRewardsArr[i][0];
      const min_Time_To_Withdraw = minTime / (24 * 60 * 60);
      let pending_gamma: any = 0;
      const infinity_vault_deposit_fee = 0;//entranceFeeFactorArr[i][0];
      const infinity_vault_normal_withdraw_fee = 0;//withdrawFeeFactorArr[i][0];
      const infinity_vault_instant_withdraw_fee = 0;//instantWithdrawFeeFactorArr[i][0];
      const feeRewards = feeRewardsAccruedArr[i][0]/1e8;
      if(userAddress){
        let totalGTokenInVault = gTokenBalanceArr[i][0];
        pending_gamma = i === 1 ? await getUserPendingGammaInAquaInfinityVault(userAddress, iTokenArr[i], iTokenABIArr[i]) : 0;
        // console.log("totalGTokenInVault", totalGTokenInVault, accuredBalancePerWeek)
        let widthdrawApr = ((accuredBalancePerWeek/totalGTokenInVault) * (365/7));
        let widthdrawApy = (Math.pow((1 + widthdrawApr/(365*3)), (365*3)) -1 ) * 100;
        apy += widthdrawApy == widthdrawApy ? widthdrawApy : 0;
        let exchangeRateDecimal: any = 28;
        let exchangeRate = gTokenExchangeRateArr[i][0] / Math.pow(10, exchangeRateDecimal);
        exchangeRate = 1 / exchangeRate;
        const infinity_want_bal = gTokenStakingBalance[i][0];
        const infinity_unstaking_bal = userInfoArr[i].gTokenToBeUnstaked;
        let _allowance = vaultAllowanceArr[i][0];
        const infinity_token_wallet_bal_with_precision: any = underlyingTokenBalance[i][0];
        const infinity_token_wallet_bal: any = (infinity_token_wallet_bal_with_precision / Math.pow(10, 18));
        // console.log("infinity_token_wallet_bal", infinity_token_wallet_bal)
        const infinity_vault_token_price = priceData.gTokenPrice;

        const infinity_vault_underlying_price: any = priceData.underlyingPrice;

        const infinity_token_wallet_bal_in_usd = (+infinity_token_wallet_bal * infinity_vault_token_price);

        const infinity_underlying_deposited_bal = ((infinity_want_bal) * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio);
        // console.log("infinity_underlying_deposited_bal", infinity_underlying_deposited_bal, infinity_want_bal, priceData)
        const infinity_underlying_deposited_bal_in_usd = (infinity_underlying_deposited_bal * infinity_vault_underlying_price) / 1e18;

        const infinity_underlying_unstaking_bal = (infinity_unstaking_bal * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio);

        const infinity_underlying_unstaking_bal_in_usd = (infinity_underlying_unstaking_bal * infinity_vault_underlying_price) / 1e18;
        // console.log(Math.pow(2, 95))
        const isApproved = _allowance > Math.pow(2, 95) ? true : false//Math.pow(2, 200)

        const gtoken_deposited = infinity_want_bal / Math.pow(10, 8)

        const gtoken_user_can_unstake = gtoken_deposited
        vault_data = {
          pid: iTokenArr[i],
          exchangeRate: exchangeRate,
          pending_gamma: pending_gamma,
          infinityAbi: iTokenABIArr[i],
          feeRewards: feeRewards,
          gtoken_staked: gtoken_deposited,
          gtoken_user_can_unstake: gtoken_user_can_unstake,
          infinity_vault_address: iTokenArr[i],
          infinity_token_address: priceData.gToken,
          deposited_bal: infinity_underlying_deposited_bal_in_usd > 0.01? infinity_underlying_deposited_bal : 0,
          deposited_bal_usd: infinity_underlying_deposited_bal_in_usd > 0.01? infinity_underlying_deposited_bal_in_usd: 0,
          unstaking_bal: infinity_underlying_unstaking_bal_in_usd > 0.01 ? infinity_underlying_unstaking_bal: 0,
          unstaking_bal_in_usd: infinity_underlying_unstaking_bal_in_usd > 0.01 ? infinity_underlying_unstaking_bal_in_usd : 0,
          wallet_bal: infinity_token_wallet_bal_in_usd > 0.01 ? infinity_token_wallet_bal : 0,
          wallet_bal_in_usd: infinity_token_wallet_bal_in_usd > 0.01 ? infinity_token_wallet_bal_in_usd : 0,
          tvl: tvl,
          isApproved,
          infinity_vault_instant_withdraw_fee,
          infinity_vault_normal_withdraw_fee,
          infinity_vault_deposit_fee,
          min_Time_To_Withdraw,
          apy,
          infinity_token_wallet_bal_with_precision,
          userAddress,
          priceData,
          apr: (Math.pow((apy / 100 + 1), (1 / 365)) - 1) * 365 * 100,
          address: i == 0 ? gGamma : gAqua
        }
      } else {
        vault_data = {
          pid: iTokenArr[i],
          exchangeRate: 0,
          infinityAbi: iTokenABIArr[i],
          feeRewards: 0,
          gtoken_staked: 0,
          gtoken_user_can_unstake: 0,
          infinity_vault_address: iTokenArr[i],
          infinity_token_address: zeroAddress,
          deposited_bal: 0,
          deposited_bal_usd: 0,
          unstaking_bal: 0,
          unstaking_bal_in_usd: 0,
          wallet_bal: 0,
          wallet_bal_in_usd: 0,
          tvl: tvl,
          isApproved: false,
          infinity_vault_instant_withdraw_fee: 0,
          infinity_vault_normal_withdraw_fee: 0,
          infinity_vault_deposit_fee: 0,
          min_Time_To_Withdraw: 0,
          apy,
          underlyingToken: i == 0 ? gammaAddress : aquaAddress,
          farmAddress : gammaFarmAdddress,
          rewardsInfo :{
            gammaInfinityRewards: 0,
            lifeRewards: 0
          },
          priceData,
          apr: (Math.pow((apy / 100 + 1), (1 / 365)) - 1) * 365 * 100,
          address: i == 0 ? gGamma : gAqua
        };
      }
      
      infinity_vault_data[iTokenArr[i]] = vault_data
    }
    // console.log("old inf data with new multicall", infinity_vault_data)
    return infinity_vault_data;
  } catch(error){
    console.log(error)
    return [];
  }
  
  
}

export const getOldInfinityVaultData = async (userAddress?: any) => {
  // userAddress = "0x9b505dC2D09A74d8e02D993430D4ca92C263a1Db";
  const initialTimestampVaults = new Date().getTime();
  let infVaults = await getInfinityVaultDetailsOld(userAddress);
  oldInfinityVaultData = infVaults;
  const initialTimestamp = new Date().getTime();
  // console.log("old inf vault data", infVaults);
  return infVaults;
  
  const vaults = [gamma_infinity_vault_address_old, aqua_infinity_vault_address_old]
  let res: any = {}

  try {
    for (let i = 0; i < vaults.length; i++) {
      let abi;
      if (i === 0) abi = gamma_infinity_vault_abi_old
      else abi = aqua_infinity_vault_abi_old

      const vault = vaults[i];
      const priceData = await getPriceDataOfGivenInfinityVaultInEth(vault, abi);
      const infinity_tvl = ((await infinityVaultTvlInWei(vault, abi)) / Math.pow(10, 8)) * priceData.gTokenPrice;
      let gammaApy: any = vault === gamma_infinity_vault_address_old ? await getTotalGammaSupplyApyVal(gGammaOld) : await getTotalGammaSupplyApyVal(gAquaOld);
      console.log("gammaApy in old inf", vault, gammaApy)
      let apy: any = await getInfinityVaultApy(vault, abi, priceData);
      console.log("apy in old inf", vault, apy)
      apy += gammaApy;
      let burnApy: any = 0;
      burnApy = parseFloat(await getBurnApy())
      if(vault === aqua_infinity_vault_address_old && burnApy !== undefined){
        apy += burnApy;
      }

      let totalGTokenInVault: any = await infinityVaultbalanceOfGtoken(vault, abi);
      let accuredBalancePerWeek = await getWeeklyFeeRewardsAccruedInfVault(vault, abi);
      //APR = (feeRewardsAccruedPerWeek/gTokenBalance) * (365/7) * 100 
      //APY = [1 + (APR / Number of Periods)]^(Number of Periods) - 1
      let widthdrawApr = ((accuredBalancePerWeek/totalGTokenInVault) * (365/7)) 
      let widthdrawApy = (Math.pow((1 + widthdrawApr/(365*3)), (365*3)) -1 ) * 100
      apy += widthdrawApy;  
      console.log("withdraw apy in old inf", vault, widthdrawApy)
      if (vault.length !== 0 && userAddress !== undefined) {

        // if(i === 1){
        //   console.log("USER PENDING GAMMA",await getUserPendingGammaInAquaInfinityVault(userAddress));
        // }

        const pending_gamma = (i === 1) ? await getUserPendingGammaInAquaInfinityVault(userAddress, vault, abi) : 0;

        const decimal = parseFloat(await getUnderlyingDecimal(priceData.gToken)) + 10;

        let exchangeRate = (await exchangeRateStored(priceData.gToken)) / Math.pow(10, decimal);

        exchangeRate = 1 / exchangeRate;
          
        const infinity_want_bal = userAddress !== null ? await getUserBalInGtokenInfinityVaultInWei(userAddress, vault, abi) : 0
        
        const infinity_unstaking_bal =
          userAddress !== null ? await getUserBalGivenForUnstakinginInfinityVaultInWei(userAddress, vault, abi) : 0

        const infinity_vault_deposit_fee = await depositFeeOfThisInfinityVault(vault, abi)

        const infinity_vault_normal_withdraw_fee = await normalWithdrawFeeOfThisInfinityVault(vault, abi)

        const infinity_vault_instant_withdraw_fee = await instantWithdrawFeeOfThisInfinityVault(vault, abi)

        const min_Time_To_Withdraw = (await minTimeToWithdraw(vault, abi)) / (24 * 60 * 60)

        const _allowance = userAddress !== null ? await allowance(priceData.gToken, userAddress, vault) : 0
        // console.log("old inf allowance", _allowance)
        const infinity_token_wallet_bal =
          userAddress !== null ? (await getUserWantBalOfThisInfinityVaultInWei(userAddress, vault, abi)) / Math.pow(10, 8) : 0

        const infinity_vault_token_price = priceData.gTokenPrice

        const infinity_vault_underlying_price = priceData.underlyingPrice

        const infinity_token_wallet_bal_in_usd = infinity_token_wallet_bal * infinity_vault_token_price

        const infinity_underlying_deposited_bal = ((infinity_want_bal) * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio)

        const infinity_underlying_deposited_bal_in_usd = infinity_underlying_deposited_bal * infinity_vault_underlying_price

        const infinity_underlying_unstaking_bal = (infinity_unstaking_bal * priceData.exchangeRate) / Math.pow(10, priceData.decimalRatio)

        const infinity_underlying_unstaking_bal_in_usd = infinity_underlying_unstaking_bal * infinity_vault_underlying_price

        const isApproved = _allowance > Math.pow(2, 200) ? true : false

        const gtoken_deposited = infinity_want_bal / Math.pow(10, 8)

        const gtoken_user_can_unstake = gtoken_deposited

        const feeRewards = await getFeeRewards(vault, abi)

        res[vault] = {
          pid: vault,
          exchangeRate: exchangeRate,
          pending_gamma: pending_gamma,
          infinityAbi: abi,
          feeRewards: feeRewards,
          gtoken_staked: gtoken_deposited,
          gtoken_user_can_unstake: gtoken_user_can_unstake,
          infinity_vault_address: vault,
          infinity_token_address: priceData.gToken,
          deposited_bal: infinity_underlying_deposited_bal_in_usd > 0.01? infinity_underlying_deposited_bal : 0,
          deposited_bal_usd: infinity_underlying_deposited_bal_in_usd > 0.01? infinity_underlying_deposited_bal_in_usd: 0,
          unstaking_bal: infinity_underlying_unstaking_bal_in_usd > 0.01 ? infinity_underlying_unstaking_bal: 0,
          unstaking_bal_in_usd: infinity_underlying_unstaking_bal_in_usd > 0.01 ? infinity_underlying_unstaking_bal_in_usd : 0,
          wallet_bal: infinity_token_wallet_bal_in_usd > 0.01 ? infinity_token_wallet_bal : 0,
          wallet_bal_in_usd: infinity_token_wallet_bal_in_usd > 0.01 ? infinity_token_wallet_bal_in_usd : 0,
          tvl: infinity_tvl,
          isApproved,
          infinity_vault_instant_withdraw_fee,
          infinity_vault_normal_withdraw_fee,
          infinity_vault_deposit_fee,
          min_Time_To_Withdraw,
          apy,
        }
      }
      else {
        res[vault] = {
          pid: vault,
          exchangeRate: 0,
          infinityAbi: abi,
          feeRewards: 0,
          gtoken_staked: 0,
          gtoken_user_can_unstake: 0,
          infinity_vault_address: vault,
          infinity_token_address: zeroAddress,
          deposited_bal: 0,
          deposited_bal_usd: 0,
          unstaking_bal: 0,
          unstaking_bal_in_usd: 0,
          wallet_bal: 0,
          wallet_bal_in_usd: 0,
          tvl: infinity_tvl,
          isApproved: false,
          infinity_vault_instant_withdraw_fee: 0,
          infinity_vault_normal_withdraw_fee: 0,
          infinity_vault_deposit_fee: 0,
          min_Time_To_Withdraw: 0,
          apy,
        }
      }
    }
    const finalTimestamp = new Date().getTime();
    console.log("time taken to load old infinity vaults", finalTimestamp - initialTimestamp, res);
    return res
  } catch (err) {
    console.log(err)
  }
}

export const transfer_rewards_in_gamma_infinity_vault = async (userAddress: string) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(aqua_infinity_vault_abi, aqua_infinity_vault_address)
  const res = await infinity_vault_inst.methods.deposit(0).send({
    from: userAddress,
  }) /// claim all pool pending gamma in farm
  return res
}

export const transfer_rewards_in_gamma_infinity_vault_old = async (userAddress: string) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(aqua_infinity_vault_abi_old, aqua_infinity_vault_address_old)
  const res = await infinity_vault_inst.methods.deposit(0).send({
    from: userAddress,
  })
  return res
}

export const deposit_in_infinity_vault = async (userAddress: string, infinity_vault: string, infinity_abi: any, amountInEth: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const decimals = 8;
  let staking_amount: any = +amountInEth * Math.pow(10, 8);
  staking_amount = staking_amount.toString();
  let stakingAmountInString: any = staking_amount.split(".");
  // console.log("amount in deposit_in_infinity_vault", stakingAmountInString)
  // let staking_amount: any = BigNumber.from(amountInEth).mul(BigNumber.from(10).pow(decimals));
  const res = await infinity_vault_inst.methods.deposit(stakingAmountInString[0]).send({
    from: userAddress,
  })
  return res
  //await transfer_rewards_in_gamma_infinity_vault(userAddress);
}

export const deposit_in_infinity_vault_new = async (userAddress: string, pid:number, amountInEth: any, max: any) => {
  const farm_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
  let res: any;
  console.log(pid, amountInEth)
  if(max){
    res = await farm_inst.methods.deposit(pid, amountInEth).send({
      from: userAddress,
    })
  } else {
    let staking_amount: any = convertToWei(amountInEth)//BigInt(amountInEth * Math.pow(10, 18));
    staking_amount = staking_amount.toString();
    let stakingAmountInString: any = staking_amount.split(".");
    console.log(stakingAmountInString); 
    res = await farm_inst.methods.deposit(pid, stakingAmountInString[0]).send({
      from: userAddress,
    })
  }
  
  return res
  //await transfer_rewards_in_gamma_infinity_vault(userAddress);
}

export const normal_unstake_new = async (userAddress: string, pid: number, unstaking_amount: string) => {
  const farm_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
  // amountInEth = +amountInEth;
  // let unstaking_amount: any = convertToWei(amountInEth)//BigInt(amountInEth * Math.pow(10, 18));
  // unstaking_amount = unstaking_amount.toString();
  // let unstaking_amount_without_decimal: any = unstaking_amount.split(".");
  // console.log("unstaking amount", amount)
  const res = await farm_inst.methods.startUnstakeProcess(pid, unstaking_amount).send({
    from: userAddress,
  })
  return res
}

export const normal_unstake = async (userAddress: string, infinity_vault: string, infinity_abi: any, amountInEth: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  let unstaking_amount: any = amountInEth * Math.pow(10, 8);
  unstaking_amount = unstaking_amount.toString();
  let unstaking_amount_without_decimal: any = unstaking_amount.split(".");
  const res = await infinity_vault_inst.methods.startUnstakeProcess(unstaking_amount_without_decimal[0]).send({
    from: userAddress,
  })
  return res
}

export const unstake_after_min_time = async (userAddress: string, infinity_vault: string, infinity_abi: any) => {
  // console.log("unstake after min withdraw", infinity_vault)
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const res = await infinity_vault_inst.methods.unstakeAfterMinWithdrawTime().send({
    from: userAddress,
  })
  return res
}

export const unstake_after_min_time_new = async (userAddress: string, pid: number) => {
  const farm_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
  const res = await farm_inst.methods.unstakeAfterMinWithdrawTime(pid).send({
    from: userAddress,
  })
  return res
}

export const stopUnstakeProcess = async (userAddress: string, infinity_vault: string, infinity_abi: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  const res = await infinity_vault_inst.methods.stopUnstakeProcess().send({
    from: userAddress,
  })
  return res
}

export const stopUnstakeProcessNew = async (userAddress: string, pid: number) => {
  const farm_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
  const res = await farm_inst.methods.stopUnstakeProcess(pid).send({
    from: userAddress,
  })
  return res
}

export const unstake_instantly = async (userAddress: string, infinity_vault: string, infinity_abi: any, amountInEth: any) => {
  const infinity_vault_inst = new wallet.web3.eth.Contract(infinity_abi, infinity_vault)
  // console.log("amount in function unstake_instantly", typeof amountInEth, amountInEth, infinity_vault)
  let unstaking_instant_amount: any = (amountInEth * Math.pow(10, 8));
  unstaking_instant_amount = unstaking_instant_amount.toString();
  let unstaking_instant_amount_without_decimal: any = unstaking_instant_amount.split(".");
  // console.log("amount in unstaking_instant_amount", typeof unstaking_instant_amount_without_decimal, unstaking_instant_amount_without_decimal)

  const res = await infinity_vault_inst.methods.unstakeInstantly(unstaking_instant_amount_without_decimal[0]).send({
    from: userAddress,
  })
  return res
}

export const unstake_instantly_new = async (userAddress: string, pid: number, unstaking_instant_amount: any) => {
  const farm_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
  // console.log("amount in function unstake_instantly", typeof amountInEth, amountInEth)
  // amountInEth = +amountInEth
  // let unstaking_instant_amount: any = convertToWei(amountInEth)//BigInt(amountInEth * Math.pow(10, 18));
  //unstaking_instant_amount = unstaking_instant_amount.toString();
  //let unstaking_instant_amount_without_decimal: any = unstaking_instant_amount.split(".");
  // console.log("amount in unstaking_instant_amount", typeof unstaking_instant_amount_without_decimal, unstaking_instant_amount_without_decimal)

  const res = await farm_inst.methods.unstakeInstantly(pid, unstaking_instant_amount).send({
    from: userAddress,
  })
  return res
}

export const claimGammaRewardsForPools = async (userAddress: string, stake: boolean) => {
  try{
    // console.log(stake, userAddress, gammaFarmAdddress);
    const farm_inst = new wallet.web3.eth.Contract(gammaFarmAbi, gammaFarmAdddress)
    const res = await farm_inst.methods.claimAllPoolsPendingGamma(stake).send({
      from: userAddress,
    })
  return res;
  } catch(error){
    console.log(error)
    return {}
  }
  
}

/*
@param fromTokenAddress: Aqua address, incase we are converting Aqua tokens
@param amountIn: How much aqua we want to transform, with precision
@param pathToBusd: Aqua to busd conversion path
@param routerAddress: Router address
@param stablePoolAddress: StablePoolAddress
@param busdIndex: Index of busd in the stablePool
@param nCoins: number of coins in the pool, 3 if 3g, 4 if 4g
@param userAddress: user calling transform
*/
export const zapInto3GStables = async (fromTokenAddress: string, amountIn: string, pathToBusd: string[],
  routerAddress: string, stablePoolAddress: string, busdIndex: number, nCoins: number, userAddress: string) => {
  try {
    const zapContract: any = await selectInstance(instType.AQUAZAPINABI, aquaZapInAddress);
    await zapContract.methods.zapInto3GStables(fromTokenAddress, amountIn, pathToBusd, routerAddress, stablePoolAddress, busdIndex, nCoins).send({
      from: userAddress
    })
  }
  catch (error){
    console.log(error)
  }
}

export const getWithdrawFeeFactor =async (strategyAddress: string) => {

  try{
    let strategy_inst = new wallet.web3.eth.Contract(gamma_strategy_abi, strategyAddress);
    let fee = await strategy_inst.methods.withdrawFeeFactor().call()
    return fee
  } catch(error){
    console.log(error);
    let fee = 0;
    return fee;
  }
  
}

export const UserBNBDetails = async (userAddress: any) => {
  try{
    let user_bnb_balance = convertToEther(await wallet.web3.eth.getBalance(userAddress));
    return user_bnb_balance;
  } catch(error: any){
    console.log(error);
    return 0;
  }
}

export const fetchBNBxApy = async() => {
  try {
    if(planetGlobalObject.bnbxApy != 0) {
      return planetGlobalObject.bnbxApy;
    }
    else {
      let bnbxApy = (await axios.get('https://universe.staderlabs.com/bnb/apy')).data;
      // console.log("bnbx staking apy", bnbxApy)
      planetGlobalObject.bnbxApy = bnbxApy && bnbxApy.value && bnbxApy.value !== null ? bnbxApy.value : 3.5;
      return planetGlobalObject.bnbxApy;
    }
  } catch (error){
    console.log(error)
    return 3.5;
  }
}

export const fetchBNBxTVL = async() => {
  try {
    if(planetGlobalObject.bnbxTVL != 0) {
      return planetGlobalObject.bnbxTVL;
    }
    else {
      let bnbxTVL = (await axios.get('https://universe.staderlabs.com/bnb/tvl')).data;
      // console.log("bnbx staking tvl", bnbxTVL)
      planetGlobalObject.bnbxTVL = bnbxTVL;
      return bnbxTVL;
    }
  } catch (error){
    return 0;
  }
}

export const fetchTotalBNBxStakers = async () => { 
   try {
    let bnbxHoldersArray = (await axios.get('https://api.bscscan.com/api?module=token&action=tokenholderlist&contractaddress=0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275&page=1&offset=1000000&apikey='+apiKey)).data;
    // console.log("bnbx staking holders", bnbxHoldersArray.result.length)
    return bnbxHoldersArray.result.length;
  } catch (error){
    return 0;
  }
}

export const fetchStakingWithdrawRequest = async (userAddress: any) => {
  try {
    const stake_instance: any = new wallet.web3.eth.Contract(stakeManagerABI, stakeManagerAddress)
                
    let withdrawRequestArray = await stake_instance.methods.getUserWithdrawalRequests(userAddress).call();
    return withdrawRequestArray;
  } catch (error){
    return [];
  }
}

export const getAllPoolInfo = async () => {
  try {
    let newPoolData = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
    return newPoolData;
  } catch (error) {
    try {
      let newPoolData = (await axios.get(newPFApiBaseUrl+'v1/getallpoolinfo')).data;
      return newPoolData;
    } catch (error) {
      console.log('error in fetching new pool data', error);
    }
  }
}

export const getAquaBurnAPY = async () => {
  try {
    const resBurn = (await axios.get('https://pfapi.planet.finance/v1/aquaburnapy')).data
    return resBurn
  }
  catch (error) {
    console.log("error", error)
    return {}
  }
}

export const getPoolsInfo = async () => {
  try {
    const res = (await axios.get('https://pfapi.planet.finance/v1/getpoolsinfo')).data
    return res
  }
  catch (error) {
    console.log("error", error)
    return {}
  }
}

export const getPoolsInfoV2 = async () => {
  try {
    const res = (await axios.get('https://pfapi.planet.finance/v1/getpoolsinfov2')).data
    return res
  }
  catch (error) {
    console.log("error", error)
    return {}
  }
}

export const getGammaSupplyApyFromApi = async () => {
  try {
    const res = (await axios.get('https://pfapi.planet.finance/v1/getGammaSupplyApy')).data
    return res
  }
  catch (error) {
    console.log("error", error)
    return {}
  }
}

export const getGammaSupplyAprFromApi = async () => {
  try {
    const res = (await axios.get('https://pfapi.planet.finance/v1/getGammaSupplyApr')).data
    return res
  }
  catch (error) {
    console.log("error", error)
    return {}
  }
}

export const getTotalSupplyApyFromApi = async () => {
  try {
    const res = (await axios.get('https://pfapi.planet.finance/v1/getsupplyapy')).data
    return res
  }
  catch (error) {
    console.log("error", error)
    return {}
  }
}


export const fetchBountyGamma = async (userArray: any) => {
  try {
      
    const multicall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);
    const farm_inst: any = new wallet.web3.eth.Contract(gammaFarmV3Abi, gammaFarmV3);
    let bountyUsersArr: any = [];
    
    let targets: any = [];
    let callDatas: any = [];
    let results:any = [];
    let ouput_format : any = [];

    let userArrayKeys =  Object.keys(userArray);
    // checkEligibilty
    userArrayKeys.forEach(async(key: any, index: any) => {
      
      targets.push(gammaFarmV3);
      try{
        const userObj = userArray[key];
        const data: any = (wallet.web3.eth.abi.encodeFunctionCall(farm_inst.methods.checkEligibilty(userObj.id)._method,[userObj.id]));
        callDatas.push(data);
        ouput_format.push(farm_inst.methods.checkEligibilty(userObj.id)._method.outputs)
      } catch(error){
        console.log(error);
        return [0, 0];
      }
    })

    // console.log(targets.length, callDatas.length, wantAddressArr)
    const aggregated_data = (await multicall_inst.methods.aggregate(targets,callDatas).call());

    const do_split = async(array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
    }  

    for(let i = 0 ; i < aggregated_data[1].length ; i++){
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i],aggregated_data[1][i]))
    }
    const split_arr = (await do_split(results, Object.keys(userArray).length));

    for(let i = 0; i < userArrayKeys.length; i++){
      let user :any = {};
      if(split_arr[0][i].issueBounty) { 
        user.huntedGamma = split_arr[0][i].huntedGamma;
        user.issueBounty = split_arr[0][i].issueBounty;
        user.id = userArray[userArrayKeys[i]].id;
        user.time = userArray[userArrayKeys[i]].time;
        bountyUsersArr.push(user)
      }
    }
    console.log(bountyUsersArr);
    return bountyUsersArr;
  } catch (error)  {
      console.log(error);
      return []
  }
}

export const getEarlyVestingPenaltyFactor = async () => {
  try {
    const inst = new wallet.web3.eth.Contract(vestingStrategyAbi, vestingPoolStrategyAddress );
    const penatly = await inst.methods.penaltyFactor().call();

    return penatly;
  }
  catch(error) {
    console.log(error);
    return 0;
  }
}