import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { PrimaryBtn, LoadingText, PlanetCard } from 'shared/styles/styled'

export const LiquidationCont = styled.div`
  .info-gap {
      margin-top: 30px;
  }
  .wallet-info{
      margin: 31px 0 20px 0;
  }
  .seize-token-cont{
    cursor: initial;
  }
`
export const Title = styled.h1`
  font-size: 24px;
  line-height: 29px;
  color: ${colors.white};
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 30px;
    line-height: 37px;
  }
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 40px;
    line-height: 49px;
  }
`
export const CardCont = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  margin-top: 30px;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    grid-template-columns: 1.2fr 1fr;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    grid-template-columns: 1.5fr 1fr;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    grid-template-columns: 2fr 1fr;
  }
`

export const Card = styled(PlanetCard)`
  padding: 30px;
`
export const CardTitle = styled.h1`
  font-size: 30px;
  line-height: 37px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
`
export const CardText = styled.p<any>`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: ${(props: any) => props.light ? 0.4 : 1};
  @media (min-width: ${screenSizes.mediaXL}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
`

export const CardSmText = styled.p<any>`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: ${(props: any) => props.light ? 0.4 : 1};
`

export const AccInfo = styled.div`
  margin: 15px 0 35px 0;
`
export const TransLink = styled.a`
  text-decoration: none;
  img{
    width: 14px;
    height: 16px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 16px;
      height: 18px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 18px;
      height: 20px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      width: 21.52px;
      height: 24px;
    }
  }
`

export const ActionCard = styled.div`
  padding: 20px;
  border: 1px solid ${colors.borderColor};
  background: ${colors.themeBackground};
  border-radius: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 24px 30px;
  }
  .liquidate-amt{
      margin-top: 19px;
  }
`
export const ActionCardTxt = styled(CardText)`
  font-family: MontserratMedium;
`
export const TokenValInput = styled.input`
  width: 50%;
  background: ${colors.themeBackground};
  color: ${colors.white};
  font-size: 14px;
  line-height: 12px;
  font-family: MontserratBold;
  border: none;
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0;
  @media (min-width: ${screenSizes.mediaXL}px) {
    font-size: 16px;
    line-height: 14px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
    line-height: 16px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 24px;
    line-height: 22px;
  }
`
export const MaxTxt = styled(CardText)`
  color: ${colors.lightGreen};
  margin-right: 10px;
  cursor: pointer;
`
export const LiquidateBtn = styled(PrimaryBtn)<any>`
  height: 60px;
  display: ${(props: any) => (props.accountsArray !== undefined && props.accountsArray > 0) || (props.disabled !== undefined && props.disabled == false) ? 'block' : 'block'};
  opacity: ${(props: any) => (props.accountsArray !== undefined && props.accountsArray > 0) || (props.disabled !== undefined && props.disabled == false) ? '1' : '0.3'};
`
export const TableWrapper = styled(PlanetCard)`
  overflow-x: auto;
  margin-top: 30px;
  padding: 18px 0 30px 0;
`
export const TableCont = styled.div`
  min-width: 900px;
  .table-row{
    cursor:pointer;
  }
  img{
      width: 20px;
      height: 20px;
      margin-right: 10px;
      @media (min-width: ${screenSizes.mediaXL}px) {
        width: 30px;
        height: 30px;
        margin-right: 11px;
      }
      @media (min-width: ${screenSizes.mediaXLL}px) {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }
      @media (min-width: ${screenSizes.mediaXXL}px) {
        width: 50px;
        height: 50px;
        margin-right: 13px;
      }
  }
`
export const TableRow = styled.div<any>`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 1.5fr 1fr 1.2fr;
  align-items: center;
  padding: 12px 30px 13px 26px;
  border-left: 4px solid ${(props: any) => props.selected ? colors.lightGreen : 'transparent'};
  text-align: left;
  background: ${(props: any) => props.selected ? colors.hoverRowBg : 'transparent'};
  @media (min-width: ${screenSizes.mediaXXL}px) {
    grid-template-columns: repeat(6, 1fr);
  }
  &.liquidatedAccounts{
    grid-template-columns: repeat(3,1fr) 1.5fr 1fr 1.2fr;
  }
  &.liquidatedRows{
    grid-template-columns: repeat(3,1fr) 1.5fr 1fr 1.2fr; 
  }
`
/*
&.liquidatedAccounts{
    grid-template-columns: repeat(3,1fr) 1.5fr 1fr 1.2fr 1fr 1fr;
  }
  &.liquidatedRows{
    grid-template-columns: repeat(3,1fr) 1.5fr 1fr 1.2fr 1fr 1fr; 
  }
*/
export const TokenText = styled(CardText)`
  font-family: MontserratBold;
`
export const HealthText = styled(CardText) <any>`
  color: ${(props: any) => props.isOverOne ? colors.lightGreen : colors.red}
`
export const TokenSelectCont = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .token-name{
    margin: 0 10px;
  }
  .token-icon{
    width: 24px;
    height: 24px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 30px;
      height: 30px;
    }
  }
  .down-icon{
    width: 17.25px;
    height: 9px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      height: 10px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      height: 11px;
    }
  }
  .seize-token{
      margin-left: 10px;
  }
`

/* ============================= shimmer style ======================= */

export const LoadingText1 = styled(LoadingText)`
  width: 100px;
  max-width: 100px;
  height: 14px;
  @media (min-width: ${screenSizes.mediaXL}px) {
    height: 16px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height: 18px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    height: 20px;
    width: 120px;
    max-width: 120px;
  }
`