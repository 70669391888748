import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { FlexSBCont, FlexCont } from 'shared/styles/styled'
import { TransActionCont, TransInputCont, TransBalTxt, InputTitle, TransInputBox, TransInput, TransMaxBox, TransDataCont, TokenSelectCont, TokenName, TwoImageCont, TransTxt, TransInoTxt, PriceImpactBox, TransButton } from 'shared/styles/highestApyStyle'
import DownIcon from 'assets/icons/down.svg'
import { transformTokenList as tokenList, returnTransformTokenList } from 'modules/block-chain/tokenList'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import wallet from 'modules/wallet/wallet'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import grey_tick from 'assets/icons/grey_tick.png'
import green_tick from 'assets/icons/green_tick.png'

const TransactionConfirmationLoader = (props: any) => {
    const {setShowConfModal, flags, heading } = props
    const [_flags, setFlags ] = useState([])
    useEffect(() => {
        // console.log('flags', flags)
        setFlags(flags);
    }, [flags]);
    //flag.status = 1 // 0 for process not started yet, 1 for loading, 2 for success, 3 for failed
    return (
        <Fragment>
            <TransInputCont className='zapNStake'>
            <FlexSBCont className='titleCont'>
                <InputTitle className='confTitle'>{heading}</InputTitle>
                <img className='cross-icon' src={CrossIcon} alt="" onClick={() => setShowConfModal(false)} />
            </FlexSBCont>
            {
                _flags.map((flag:any, index:any) => {
                    return (
                        <FlexSBCont className='allowanceCheck'>
                            <InputTitle className='confText'>{flag.actionHeading}</InputTitle>
                            { flag.status == 1 ?
                                <Spinner style={"transactionBox"} />
                            :
                                <img className='trans-icon' src={flag.status == 2 ? green_tick : grey_tick} alt="" />
                            }
                        </FlexSBCont>
                    )
                })
            }
            </TransInputCont>
        </Fragment>
    )
}

export default TransactionConfirmationLoader