import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FarmPoolContext } from 'context/FarmPoolContext'
import Modal from 'react-modal';
import { updateUserBalance, updateLPData } from 'logic/action/user.actions'
import { fetchUserPortfolio } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont, FlexCont, CardButton2 } from 'shared/styles/styled'
import { HAModalCont, HAMDataCont, HAModalTitle, TokenInfoBox, HAModalImgCont, HAModalTokenImg, TokenValTxt, ModalAPYTxt, TvlTxt, SwitchCont, DepositSwitch, WithdrawSwitch, ModalCard, ModalCardTitle, ModalCardTxt, PoolEarningCont, EarnCont, DataInfoCard, InfoHeaderCont, DataInfoTitle, DataInfoCont, DataInfoTxt, LoadingText1, LoadingText2, LoadingText3, LoadingText4 } from 'shared/styles/highestApyStyle'
import { ModalInputCont, ModalInput, MaxBox, MaxBoxZero, UserBalTxt } from 'shared/styles/highestApyStyle'
import { CardBtnGroup, CardGreenBtn, CardBtn, CardBtnGreen, TransformBtn, CardRedBtn, ConvertLpBtn, DepositBtn, WithdrawBtn } from 'shared/styles/highestApyStyle'
import CustomModal from 'shared/custom-modal'
import CloseIcon from 'assets/icons/white-cross.svg'
import DownIcon from 'assets/icons/down.svg'
import CAKEIcon from 'assets/icons/CAKE.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import { getTokenIcon } from 'shared/tokenIconList'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { getTokenName, commaFy, convertToKAndM, checkZeroVal, getNumFormat } from 'service/globalFunctions'
import AquaSyncIcon from 'assets/icons/aqua-sync.svg'
import GammaSyncIcon from 'assets/icons/gamma-sync.svg'
import { transLinkUrl, dogeBnbTransLink } from 'service/global-constant'
import Spinner from 'shared/spinner'
import {TokenSelectCont, TokenName,TokenNameTokenSelect, SelectCurrTxt, TokenContainer, GTokenImgCont, TwoImgCont, WithdrawBtnGroup, StartUnstakeLpBtn} from './styles'
import { maxAmount } from 'modules/block-chain/lendingAbi'
import { selectInstance, instType, poolInfoNew, handleDeposit, handleWithdraw, harvestLpTokens, earnAquaProfits, isAllowanceApproved, convertToEther, allowance, getUserUnstakingTimeInInfinityVaultNew, stopUnstakeProcessNew, unstake_after_min_time_new, handleWithdrawV3, handleDepositV3, convertToWei } from 'modules/block-chain/BlockChainMethods'
import { aquaAddress, aquaAutoCompPoolAddress, aquaFarmAddress, gammaFarmAdddress, aqua_strategy_address_new, gamma_strategy_address_new, allFusionPools } from 'modules/block-chain/abi'
import InfoTooltip from 'shared/info-tool-tip'
import thenaIcon from 'assets/icons/ThenaLogo.svg'
import bswIcon from 'assets/icons/bsw.svg'
import useTokenDropDown from '../useTokenDropdown';
import { useCountdown } from '../useCountdown';
import TokenSelect from 'shared/token-select'
import { tokenList, returnTokenList, swapTokenList, returnSwapTokenList, tokenSwapList } from '../../../block-chain/tokenList'
import ArrowDown from 'assets/icons/arrow-down.png'
import { planetZapOneInchAbi, planetZapOneInchAddress } from 'modules/block-chain/PlanetZapOneInchAbi';
import { TimerBtn, CancelStakeBtn, TableInfoBox, HeadText } from 'shared/styles/planetTableStyle'
import UnStakingTimeAqua from '../UnStakingTimeAqua'
import inf_boost_icon from 'assets/icons/inf_boost_icon.png'
import gammaIcon from 'assets/icons/gamma-icon.png'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import momentTimeZone from 'moment-timezone'
import moment from 'moment'

const PoolDetailsModal = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('deposit')
    const [depositAmount, setDepositAmount] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [depositLoading, setDepositLoading] = useState(false)
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [claimLoading, setClaimLoading] = useState(false)
    const [claimBoostLoading, setClaimBoostLoading] = useState(false)
    const [isAllowance, setIsAllowance] = useState(false)
    const [showVaultInfo, setShowVaultInfo] = useState(false)
    const [showLockedLPInfo, setShowLockedLPInfo] = useState(false)
    const [showFeeInfo, setShowFeeInfo] = useState(false)
    const [showAPYCal, setShowAPyCal] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [maxWithdraw, setMaxWithdraw] = useState(false)
    const [maxDeposit, setMaxDeposit] = useState(false)
    const { portfolioLoading, poolData } = selector.user
    const { togglePoolModal, data, handleGetLpToken, setModalData, currentTab, setTokenSwapAmount, setSendTokenFromParent, showSwapPopup, setShowUnstakePopUp, confirmUnstake, showUnstakePopUp, setConfirmUnstake, setUnstakeData } = props;
    const farmPoolData:any = useContext(FarmPoolContext);
    const [existTokenAdd, setExistTokeAdd] = useState('');

    const [sendTokenNew, setSendToken] = useState<any>({})
    const [sendWithdrawToken, setSendWithdrawToken] = useState<any>({})
    const [currTokenList, setCurrTokenList] = useState<any>(tokenSwapList)
    const [currentTokenSelectAllowance,  setCurrentTokenSelectAllowance] = useState(0);
    const [instantUnstakeLoading, setInstantUnstakeLoading] = useState(false)
    const [countDownDate, setCountDownDate] = useState('')
    const [stopUnstakeLoader, setStopUnstakeLoader] = useState(false)
    const [finishUnstakeLoader, setFinishUnstakeLoader] = useState(false)
    const {userPortfolio } = selector.user


    const closeModal = () => {
        setShowModal(false)
        setCurrTokenList([])
    }

    const handleTokenModal = (poolData: any) => {
        if(data.poolStatus == 'active'){
            setShowModal(true)
        }
        
        // setSendAmountCheck(0)
        let tokList = farmPoolData.tokenSwapListWithUserBalances.slice();
        if(selector.ethData.address != undefined && data.poolStatus == 'active')
        {   
            if(activeTab == 'deposit') {
                let lpTokenObject = {
                    sno: 100,
                    address: poolData.strategyAddress,
                    name: poolData.name,
                    price: poolData.price,
                    value: poolData.walletBalance,
                    usdValue: poolData.walletBalanceUSD,
                    walletBalance: poolData.walletBalance,
                    walletBalanceUSD: poolData.walletBalanceUSD,
                    vaultBalance: poolData.vaultBalance,
                    vaultBalanceUSD: poolData.vaultBalanceUSD,
                    allowance: poolData.allowance
                }
                if(poolData.walletBalanceUSD > 1) {
                    tokList.push(lpTokenObject)
                    if(sendTokenNew.address == undefined) {
                        setSendToken(lpTokenObject)
                        setSendTokenFromParent(lpTokenObject)
                        // console.log(lpTokenObject)
                    }
                    
                }
            }
            else {
                let lpTokenObject = {
                    sno: 100,
                    address: poolData.strategyAddress,
                    name: poolData.name,
                    price: poolData.price,
                    value: poolData.vaultBalance,
                    usdValue: poolData.vaultBalanceUSD,
                    walletBalance: poolData.walletBalance,
                    walletBalanceUSD: poolData.walletBalanceUSD,
                    vaultBalance: poolData.vaultBalance,
                    vaultBalanceUSD: poolData.vaultBalanceUSD,
                    allowance: poolData.allowance
                }
                if(poolData.vaultBalanceUSD > 0) {
                    tokList.push(lpTokenObject)
                    setSendWithdrawToken(lpTokenObject)
                }
            }
            
            setCurrTokenList(tokList);
        }
        
    } 

    const handleTokenSelect = async (selectedTokenData: any) => {
        if(activeTab == 'deposit') {
            setSendToken(selectedTokenData);
            setSendTokenFromParent(selectedTokenData);
            setDepositAmount('0');
            
            setShowModal(false);
        } 
        else {
            setSendWithdrawToken(selectedTokenData);
            setSendTokenFromParent(selectedTokenData);            
            setShowModal(false);
        }
    }

    useEffect(() => {
        //console.log(userPortfolio)
        let gammaRewardsEligibiltiy : any = false;
        if(userPortfolio && userPortfolio.other_pool_balance_in_v3 !== undefined && userPortfolio.user_gamma_btcb_balance_in_v3 !== undefined) {
            gammaRewardsEligibiltiy = userPortfolio.other_pool_balance_in_v3 > 0 && userPortfolio.user_gamma_btcb_balance_in_v3/userPortfolio.other_pool_balance_in_v3 > 5.8 ? true : userPortfolio.other_pool_balance_in_v3 == 0 && userPortfolio.user_gamma_btcb_balance_in_v3 >= 0 ? true : userPortfolio.userAddress == undefined ? true: false;
            //console.log(data.poolId, gammaRewardsEligibiltiy)
        }

        if(gammaRewardsEligibiltiy == false) {
            data.gammaApr = 0;
        }
        // data.totalApr = data.gammaApr + data.tradeFeeApr;
        // data.totalApy = (Math.pow((1 + (data.totalApr)/36500), 365) - 1) * 100;
        let poolApy: any = data.totalApy;
        poolApy = poolApy && (!isFinite(poolApy) || poolApy > 10000) ? "> 10,000" : poolApy ? poolApy.toFixed(2) : "0.00";
        const pool_apy = poolApy;
    })

    useEffect(() => {
        let isMounted = true;
        const dataArr = poolData.v3Pools
        const userAddress = selector.ethData.address;
        if (!!dataArr && Object.values(dataArr).length > 0) {
            if (currentTab === 'finished') {
                const newApyArr: any = dataArr.finished.filter((e: any) => e.wantaddress_farmaddress === data.wantaddress_farmaddress)
                setModalData(newApyArr[0])
            }
            else {
                    const newApyArr: any = dataArr.active.filter((e: any) => e.wantaddress_farmaddress === data.wantaddress_farmaddress)
                    setModalData(newApyArr[0])

                    if(data.walletBalanceUSD > 0.1){

                        let lpTokenObject = {
                            sno: 100,
                            address: data.strategyAddress,
                            name: data.name,
                            price: data.price,
                            value: data.walletBalance,
                            usdValue: data.walletBalanceUSD,
                            walletBalance: data.walletBalance,
                            walletBalanceUSD: data.walletBalanceUSD,
                            vaultBalance: data.vaultBalance,
                            vaultBalanceUSD: data.vaultBalanceUSD,
                            allowance: data.allowance
                        }
                        setSendToken(lpTokenObject)
                        setSendTokenFromParent(lpTokenObject)
                    } else {
                        let tokenList = farmPoolData.tokenSwapListWithUserBalances;
                        setSendToken(tokenList[0])
                        setSendTokenFromParent(tokenList[0])
                    }
                
                
            }

        }
        
    }, [poolData, data, setModalData, currentTab])

    useEffect(() => {
        const userAddress = selector.ethData.address
        const getIsAllowance = async () => {
            const addGroup = data.wantaddress_farmaddress.split('_')
            const wantAddress = data.wantAddress
            let farmAddress = ""
            if (addGroup.length > 1) {
                farmAddress = addGroup[1]
            }
            try {
                const isAllow = await isAllowanceApproved(userAddress, farmAddress, wantAddress)
                if(parseFloat(data.allowance) > 0){
                    setIsAllowance(true)
                }
                
            }
            catch (error) {
                console.log("error=>", error)
            }
        }
        if (!!data) {
            getIsAllowance()
        }
    }, [selector.ethData.address, data])

    const handleDepositAmountChanges = (e: any) => {
        let { value } = e.target
        if(+value === 0 ){
            value = value;
        } else {
            value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.replace(",", '') : "";
        }
        setMaxDeposit(false)
        if (floatNumRegex.test(value.toString())) {
            setDepositAmount(value)
            setTokenSwapAmount(value)
            if(parseFloat(data.allowance) >= parseFloat(value)){
                setIsAllowance(true)
            } else {
                setIsAllowance(false)
            }
        }
        if (!value) {
            setDepositAmount('')
        }
    }
    const handleWithdrawAmountChanges = (e: any) => {
        setMaxWithdraw(false)
        let { value } = e.target
        if(+value === 0 ){
            value = value;
        } else {
            value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.replace(",", '') : "";
        }

        if (floatNumRegex.test(value.toString())) {
            setWithdrawAmount(value)
            setTokenSwapAmount(value)
        }
        if (!value) {
            setWithdrawAmount('')
        }
    }

    const approveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} approved` : `Failed to approve  ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleApprove = async () => {
        const userAddress = selector.ethData.address
        if (!!userAddress) {
            try {
                setApproveLoading(true)
                let farmAddress: any;
                let LPinstance: any
                if (data.poolId === -1) {
                    farmAddress = aquaAutoCompPoolAddress
                    LPinstance = await selectInstance(instType.PANCAKELP, aquaAddress, true)
                }
                else {
                    farmAddress = data.farmContractAddress;
                    // const poolDetails = await poolInfoNew(farmAddress, data.poolId)
                    // console.log("poolDetails", poolDetails, farmAddress, data)
                    const lpAddress = data.wantAddress;
                    LPinstance = await selectInstance(instType.PANCAKELP, lpAddress, true)
                }
                await LPinstance.methods
                    .approve(farmAddress, maxAmount)
                    .send({ from: userAddress })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        approveNotification(type, receipt.transactionHash)
                        dispatch(updateUserBalance(userAddress))
                    })
                    .on('error', function (error: any) {
                        approveNotification('failed')
                        setApproveLoading(false)
                    })
            }
            catch (error) {
                console.log("error==>", error)
                setApproveLoading(false)
            }
            finally {
                checkLPApproval()
            }
        }
    }

    const checkApprovalForToken = async(token:string) => {
        let userAddress = selector.ethData.address;
        const allowanceAmount = await allowance(data.wantAddress, userAddress, planetZapOneInchAddress);
        return allowanceAmount;
    }

    const checkLPApproval = async() => {
        setApproveLoading(true)
        try{
            let farmAddress: any;
            let LP_INSTANCE: any
            if (data.poolId === -1) {
                farmAddress = aquaAutoCompPoolAddress
                LP_INSTANCE = await selectInstance(instType.PANCAKELP, aquaAddress, true)
            }
            else {
                farmAddress = data.farmContractAddress;
                // const poolDetails = await poolInfoNew(farmAddress, data.poolId)
                const lpAddress = data.wantAddress;
                LP_INSTANCE = await selectInstance(instType.PANCAKELP, lpAddress, true)
            }
            let allowanceAmount: any = await LP_INSTANCE.methods.allowance(selector.ethData.address, farmAddress).call();
            // console.log("allowance amount for lp", allowanceAmount);
            let allowance: any = convertToEther(allowanceAmount[0]);
            if(parseFloat(allowance) >= parseFloat(depositAmount)){
                setIsAllowance(true)
                dispatch(updateUserBalance(selector.ethData.address))
            } else {
                setIsAllowance(false)
            }
            setApproveLoading(false)
        } catch(error){
            console.log(error)
            setApproveLoading(false)
        }
    }

    const showNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const data: any = {
            type: type,
            message: '',
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(data))
    }

    const handleDepositAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(depositAmount) > 0) {
            try {
                let depositAmt = convertToWei(depositAmount);
                setDepositLoading(true)
                if(maxDeposit) {
                    depositAmt = convertToWei(data.walletBalance);
                }
                setDepositLoading(true)
                ////poolId: any, amount: any, userAddress: any, showNotification: any, poolData: any, maxAmtSelected:boolean = false, time_lock: any
                await handleDepositV3(data.poolId, depositAmt, selector.ethData.address, showNotification, data, maxDeposit, 0)
                setDepositLoading(false)
                dispatch(updateLPData(selector.ethData.address))
                dispatch(fetchUserPortfolio(selector.ethData.address, true))
                setDepositAmount('')
                closeModal();
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setDepositLoading(false)
            }
        }
    }
    const handleWithdrawAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(withdrawAmount) > 0) {
            try {
                setWithdrawLoading(true)
                console.log(withdrawAmount,maxWithdraw)
                await handleWithdrawV3(data.poolId, withdrawAmount, selector.ethData.address, showNotification, maxWithdraw)
                setWithdrawLoading(false)
                dispatch(updateLPData(selector.ethData.address))
                dispatch(fetchUserPortfolio(selector.ethData.address, true))
                setMaxWithdraw(false)
                setWithdrawAmount('')
                closeModal();
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setWithdrawLoading(false)
            }
        }
    }

    const processUnstakeInstantLy = async () => {
        if(selector.ethData.ethWalletConnected && parseFloat(withdrawAmount) > 0) {
            try {
                setInstantUnstakeLoading(true)
                setShowUnstakePopUp(false)
                // console.log('unstakeins', data)
                await handleWithdraw(data.poolId, withdrawAmount, selector.ethData.address, showNotification, data, maxWithdraw, true)
                setInstantUnstakeLoading(false)
                dispatch(updateLPData(selector.ethData.address))
                dispatch(fetchUserPortfolio(selector.ethData.address, true))
                setMaxWithdraw(false)
                setWithdrawAmount('')
                setConfirmUnstake(false)
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setInstantUnstakeLoading(false)
                
            }
        }
    }

    const renderTokenIconsDropDown = (tokenIcons: string, isBoostPool: boolean, protocol?: string) => {
        if(tokenIcons != undefined) {
            const tokenName = tokenIcons.split('-')
            if (tokenName.length === 1) {
                if(tokenName[0] == "GAMMA Infinity") {
                    const icon = getTokenIcon("GAMMA");
                    return (
                        <GTokenImgCont>
                            <img className='tokenIcon' src={icon} alt="" />
                            <img className="sync-img" src={GammaSyncIcon} alt="" />
                        </GTokenImgCont>
                    )
                }
                else if (tokenName[0] == "AQUA Infinity")  {
                    const icon = getTokenIcon("AQUA");
                    return (
                        <GTokenImgCont>
                            <img className='tokenIcon' src={icon} alt="" />
                            <img className="sync-img" src={AquaSyncIcon} alt="" />
                        </GTokenImgCont>
                    )
                }
                else {
                    const icon = getTokenIcon(tokenIcons)
                    return <img src={icon} alt="" />
                }
            }
            else if (tokenName.length === 2) {
                const icon1 = getTokenIcon(tokenName[0])
                let secondTokenName = tokenName[1].split(" ");
                const icon2 = getTokenIcon(secondTokenName[0])
                return (
                    <TwoImgCont>
                        <img src={icon1} alt="" />
                        <img className="second-img" src={icon2} alt="" />
                        {protocol && protocol.toLowerCase() == 'cake' && <img className='third-img' src={CAKEIcon} alt="" />}
                        {protocol && protocol.toLowerCase() == 'thena' && <img className='third-img' src={thenaIcon} alt="" />}
                        {protocol && protocol.toLowerCase() == 'biswap' && <img className='third-img' src={bswIcon} alt="" />}
                    </TwoImgCont>
                )
            }
            else if (tokenName.length === 3) {
                const icon1 = getTokenIcon(tokenName[0])
                const icon2 = getTokenIcon(tokenName[1])
                const icon3 = getTokenIcon(tokenName[2])
                return (
                    <TwoImgCont>
                        <img src={icon1} alt="" />
                        <img className="second-img" src={icon2} alt="" />
                        <img className='third-icon' src={icon3} alt="" />
                    </TwoImgCont>
                )
            }
        }
    }

    const renderTokenIcons = (tokenIcons: string, isBoostPool: boolean, miniCakeIcon?: boolean, protocol?: string) => {
        if(tokenIcons != undefined) {
            const tokenName = tokenIcons.split('-')
            if (tokenName.length === 1) {
                const icon = getTokenIcon(tokenIcons)
                return <HAModalTokenImg src={icon} alt="" />
            }
            else if (tokenName.length === 2) {
                const icon1 = getTokenIcon(tokenName[0])
                let secondToken = tokenName[1].split(" ");
                const icon2 = getTokenIcon(secondToken[0])
                return (
                    <HAModalImgCont>
                        <img src={icon1} alt="" />
                        <img className="second-img" src={icon2} alt="" />
                        {protocol && protocol.toLowerCase() == 'cake' && miniCakeIcon === false && <img className='third-img' src={CAKEIcon} alt="" />}
                        {protocol && protocol.toLowerCase() == 'cake' &&  miniCakeIcon === true && <img className='minicake-img' src={CAKEIcon} alt="" />}
                        {protocol && protocol.toLowerCase() == 'thena' && <img className='third-img' src={thenaIcon} alt="" />}
                        {protocol && protocol.toLowerCase() == 'biswap' && <img className='third-img' src={bswIcon} alt="" />}
                    </HAModalImgCont>
                )
            }
        }
    }

    const handleMaxWithdrawAmount = (data: any) => {
        setWithdrawAmount(getNumFormat(data.withdrawalBalance))
        setTokenSwapAmount(data.withdrawalBalance)
        setMaxWithdraw(true)
    }

    const handleMaxDeposit = () => {
        let toBeSendToken = data;
        setMaxDeposit(true)
        if(Object.keys(sendTokenNew).length > 0 ) {
            toBeSendToken = sendTokenNew;
        }
        else {
            toBeSendToken = {...data, ...{
                value: data.walletBalance,
                usdValue: data.walletBalanceUSD,
                address: data.strategyAddress,
                allowance: data.allowance
            } }
            
        }
        setDepositAmount(getNumFormat(toBeSendToken.value))
        setTokenSwapAmount(toBeSendToken.value)
        // check if lp token is selected
        if(parseFloat(toBeSendToken.allowance) >= parseFloat(toBeSendToken.value)){
            setIsAllowance(true)
        } else {
            setIsAllowance(false)
        }
    }


    const checkWithdrawDisabled = () => {
        if(selector.ethData.address == undefined) {
            return true;
        }
        if(Object.keys(sendWithdrawToken).length > 0 ) {
            return (+withdrawAmount == 0 || withdrawLoading || +withdrawAmount > +data.vaultBalance || data.strategyAddress != sendWithdrawToken.address || data.vaultBalance == 0)
        }
        else {
            return (+withdrawAmount == 0 || withdrawLoading || +withdrawAmount > +data.vaultBalance || data.vaultBalance == 0)
        }
    }

    const checkStakeDisabled = () => {
        if(selector.ethData.address == undefined) {
            return true;
        }
        if(Object.keys(sendTokenNew).length > 0 ) {
            // console.log("disable check for stake button", sendTokenNew, data)
            return (+depositAmount == 0 || depositLoading || +depositAmount > +data.walletBalance) || ((data.strategyAddress.toLowerCase() !== sendTokenNew.address.toLowerCase()) || data.walletBalance == 0)
        }
        else {
            return (+depositAmount == 0 || depositLoading || +depositAmount > +data.walletBalance || data.walletBalance == 0)
        }
    }

    const checkIsAllowance = () => {
        if(Object.keys(sendTokenNew).length == 0) {
            return isAllowance;
        }
        else {
            if(Object.keys(sendTokenNew).length > 0  && sendTokenNew.address == data.strategyAddress) {
                return isAllowance;
            } 
            else {
                return true;
            }
        }
    }

    const checkZapInStakeDisabled = () => {
        if(selector.ethData.address == undefined) {
            return true;
        }
        if(data.poolStatus == 'active'){
            if(activeTab == 'deposit') {
                // console.log("disable check", sendTokenNew, data)
                return (sendTokenNew.address == undefined || parseFloat(depositAmount) > parseFloat(sendTokenNew.value) || (( sendTokenNew.address.toLowerCase() == data.strategyAddress.toLowerCase())))
            }
            else {
                return (sendWithdrawToken.address == undefined || parseFloat(withdrawAmount) > parseFloat(sendWithdrawToken.value) || (sendWithdrawToken.address.toLowerCase() == data.strategyAddress.toLowerCase()))
            }
        } else {
            return (parseFloat(depositAmount) > parseFloat(data.walletBalance))
        }
        
        
    }

    const checkWithdrawAndZapOutDisabled = () => {
        if(selector.ethData.address == undefined) {
            return true;
        }
        
        return (instantUnstakeLoading || parseFloat(withdrawAmount) == 0 || parseFloat(withdrawAmount) > parseFloat(data.value) )
        
    }

    const withDrawTokenForDisplay = () => {
        if(Object.keys(sendWithdrawToken).length > 0 && 0) {
            return sendWithdrawToken;
        }
        else {
            let selectedTokenD = {...data, ...{
                value: data.vaultBalance,
                usdValue: data.vaultBalanceUSD,
                address: data.strategyAddress,
                allowance: data.allowance
            } }
            return {...data, ...selectedTokenD };
        }
    }

    const tokenForDisplay = () => {
        // console.log("send token for display", sendTokenNew)
        let defaultData = {
            name: data.name,
            value: data.walletBalance,
            usdValue: data.walletBalanceUsd,
            address: data.strategyAddress,
            allowance: data.allowance,
            vaultBalance: data.vaultBalance
        }
        if(data.poolStatus == 'active'){
            if(Object.keys(sendTokenNew).length > 0 ) {
                return sendTokenNew;
            }
            else {
                let selectedTokenD = {...data, ...{
                    value: data.walletBalance,
                    usdValue: data.walletBalanceUSD,
                    address: data.strategyAddress,
                    allowance: data.allowance
                } }
                return {...data, ...selectedTokenD };
            }
        } else {
            // console.log("finished pool data", data)
            return defaultData
        }
        
    }

    const _renderLockedPoolInfo = () => {
        return data.poolsLockArray.map((lockPoolInfo: any) => {
            var tz: any = momentTimeZone.tz.guess();
            tz = momentTimeZone.tz(tz).format('z');
            return (
                <TableInfoBox className='lockedLPCont'>
                    <FlexSBCont>
                        <DataInfoTxt light>{commaFy(parseFloat((convertToEther(lockPoolInfo.amount))).toFixed(4))}</DataInfoTxt>
                    </FlexSBCont>
                    <FlexSBCont>
                        <DataInfoTxt light>{commaFy((parseFloat((convertToEther(lockPoolInfo.amount))) * data.price).toFixed(2))}</DataInfoTxt>
                    </FlexSBCont>
                    <FlexSBCont>
                        <DataInfoTxt>{moment.unix(lockPoolInfo.unlockTime).format('MMM DD, YYYY hh:mm A') + ' (' + tz + ')'}</DataInfoTxt>
                    </FlexSBCont>
                </TableInfoBox>
            )
        })
    }

    const getWithdrawText = () => {
        return  withdrawLoading ? 
            <Spinner /> 
            : 
            'Withdraw'
    }

    const getWithdrawAndZapOutText = () => {
        return  instantUnstakeLoading ? 
            <Spinner /> 
            : 
            'Withdraw & Zap Out';
    }

    const getIsTimerOn = (endTime: any) => {
        let isTimerOn: boolean = false
        if (!!endTime) {
            let now = new Date()
            const difference: any = endTime.getTime() - now.getTime()
            if (parseFloat(difference) > 0) {
                isTimerOn = true
            }
        }
        return isTimerOn
    }

    const handleStopUnStake = async () => { 
        try {
            setStopUnstakeLoader(true);
            let res = await stopUnstakeProcessNew(selector.ethData.address, data.poolId);
            setStopUnstakeLoader(false);
            showNotification('success', res.transactionHash)
            dispatch(updateLPData(selector.ethData.address))
            dispatch(fetchUserPortfolio(selector.ethData.address, true))
        }
        catch(error) {
            setStopUnstakeLoader(false);
            console.log('instantunstake error', error)
            showNotification('failed') 
        } 
        
    }

    const handleFinishUnStake = async () => {
        try {
            setFinishUnstakeLoader(true);
            let res = await unstake_after_min_time_new(selector.ethData.address, data.poolId);
            showNotification('success', res.transactionHash)
            setFinishUnstakeLoader(false);
            dispatch(updateLPData(selector.ethData.address))
            dispatch(fetchUserPortfolio(selector.ethData.address, true))
        } catch(error) {
            console.log('instantunstake error', error)
            setFinishUnstakeLoader(false);
            showNotification('failed') 
        } 
    }

    const getGammaPerDay = () => {
        let allocPoint = data.poolAllocPoint;
        let totalAllocPoint = farmPoolData.totalAllocPoint;
        let gammaPerBlock = (farmPoolData.gammaPerBlock) / 1e18;
        // console.log('allocPoint, totalAllocPoint, gammaPerBlock', allocPoint, totalAllocPoint, gammaPerBlock)
        let gammaPerDay = (gammaPerBlock * 28800 * (allocPoint / totalAllocPoint)).toFixed(0);
        return gammaPerDay;
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999999999,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          backdropFilter: 'blur(15px)',
          padding: '0px',
          border: '0px',
        },

        overlay: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 99999999,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        }
    };

    return (
        <HAModalCont>
            <FlexSBCont>
                <HAModalTitle>{getTokenName(data.name)}</HAModalTitle>
                <img className='close-icon' src={CloseIcon} alt="" onClick={() => togglePoolModal()} />
            </FlexSBCont>
            <HAMDataCont>
                <TokenInfoBox>
                    {renderTokenIcons(data.name, data.isBoostPool, true, data.protocol)}
                    {portfolioLoading ? <LoadingText4 /> :
                        <TokenValTxt isValZero={checkZeroVal(data.vaultBalanceUSD)}>{`$${!!data && data.vaultBalanceUSD ? commaFy(parseFloat(data.vaultBalanceUSD).toFixed(2)) : '0.00'}`}</TokenValTxt>
                    }
                    <ModalAPYTxt>{commaFy(convertToKAndM(data.totalApy))}% APY</ModalAPYTxt>
                    <TvlTxt>{`$${!!data.tvl ? commaFy(convertToKAndM(data.tvl)) : '0.00'} TVL`}</TvlTxt>
                </TokenInfoBox>
                <SwitchCont>
                    <DepositSwitch isActive={activeTab === 'deposit'} onClick={() => setActiveTab('deposit')}>Deposit</DepositSwitch>
                    <WithdrawSwitch isActive={activeTab === 'withdraw'} onClick={() => setActiveTab('withdraw')}>Withdraw</WithdrawSwitch>
                </SwitchCont>
                {activeTab === 'deposit' ?
                    <ModalCard>
                        <FlexSBCont>
                            <ModalCardTitle>In Wallet</ModalCardTitle>
                            {portfolioLoading ? <LoadingText3 className='gamma-reward' /> :
                                <UserBalTxt>
                                    {data.poolStatus == 'active' ? `${commaFy(parseFloat(tokenForDisplay().value).toFixed(4))} ($${commaFy(parseFloat(tokenForDisplay().usdValue).toFixed(2))})` : `${commaFy(parseFloat(data.walletBalance).toFixed(4))} ($${commaFy(parseFloat(data.walletBalanceUSD).toFixed(2))})`
                                    }
                                </UserBalTxt>
                            }
                        </FlexSBCont>
                        <ModalInputCont>
                            <ModalInput placeholder="0" onChange={handleDepositAmountChanges} value={depositAmount && depositAmount} />
                            {data.walletBalanceUSD > 0.01 ?
                                <MaxBox onClick={() => handleMaxDeposit()}>MAX</MaxBox>
                                :
                                <MaxBoxZero onClick={() => handleMaxDeposit()}>MAX</MaxBoxZero>
                            }
                            {data.poolStatus == 'active' ?
                            <>
                                {
                                    sendTokenNew.pid == 0 || sendTokenNew.pid == 1 ?
                                    <TokenSelectCont className='poolTxInputSelectPoolModal' onClick={()=>{handleTokenModal(data)}}>
                                        <TokenContainer>
                                            {renderTokenIconsDropDown(tokenForDisplay().name, false)}
                                            <TokenNameTokenSelect className='poolsTx'>{tokenForDisplay().name}</TokenNameTokenSelect>
                                        </TokenContainer>
                                    </TokenSelectCont>
                                    :
                                    Object.keys(tokenForDisplay()).length > 0? 
                                    <TokenSelectCont className='poolTxInputSelectPoolModal' onClick={()=>{handleTokenModal(data)}}>
                                        <TokenContainer>
                                            {renderTokenIconsDropDown(tokenForDisplay().name, false)}
                                        </TokenContainer>
                                        <TokenNameTokenSelect className='poolsTx'>{tokenForDisplay().name.split(" ")[0]}</TokenNameTokenSelect>
                                        <img className="arrow-down" src={ArrowDown} alt="" />
                                    </TokenSelectCont>
                                    : <></>
                                }
                            </>
                            :""
                            }
                        </ModalInputCont>
                        {data.poolStatus == 'active' ?
                        <CardBtnGroup>
                            {
                                checkIsAllowance() ?
                                    <DepositBtn onClick={() => handleDepositAmount()} disabled={checkStakeDisabled()}>
                                        {depositLoading ? 
                                        <Spinner /> 
                                        : 'Stake'}
                                    </DepositBtn> 
                                :
                                    <CardBtnGreen onClick={() => handleApprove()} disabled={approveLoading}>
                                        {approveLoading ? <Spinner /> : 'Approve'}
                                    </CardBtnGreen>
                                    
                            }
                            
                                <DepositBtn 
                                    onClick={() => {handleGetLpToken(data, 'transform', depositAmount)}}
                                    disabled={ checkZapInStakeDisabled()  }
                                >
                                    {data.poolId == 2? "Swap & Stake": "Zap In & Stake"}
                                </DepositBtn>
                                
                        </CardBtnGroup>
                        :
                        <CardBtnGroup className='finishedPool'>
                            <DepositBtn 
                                onClick={() => data.poolStatus == "finished" && data.protocol.toLowerCase() == 'thena' ? handleGetLpToken(data, 'convertLp', depositAmount) : handleGetLpToken(data, 'transform')}
                                disabled={ checkZapInStakeDisabled()  }
                                >
                                {data.poolStatus == "active" ? "Zap In & Stake" : data.poolStatus == "finished" && data.protocol.toLowerCase() == 'thena' ? "Withdraw & Zap Out" :"- Liquidity"}
                            </DepositBtn>
                        </CardBtnGroup>
                    }
                    </ModalCard> :
                    <ModalCard>
                        <FlexSBCont>
                            <ModalCardTitle>Unlockable In Vault</ModalCardTitle>
                            {portfolioLoading ? <LoadingText3 className='gamma-reward' /> :
                                <UserBalTxt>{`${commaFy(parseFloat(data.withdrawalBalance).toFixed(4))} ($${commaFy(parseFloat(data.withdrawalBalanceUSD).toFixed(2))})`}</UserBalTxt>
                            }
                        </FlexSBCont>
                        <ModalInputCont>
                            <ModalInput placeholder="0" onChange={handleWithdrawAmountChanges} value={withdrawAmount && withdrawAmount} />
                            {withDrawTokenForDisplay().withdrawalBalance > 0.01 ?
                                <MaxBox onClick={() => handleMaxWithdrawAmount(data)}>MAX</MaxBox>
                                :
                                <MaxBoxZero onClick={() => handleMaxWithdrawAmount(data)}>MAX</MaxBoxZero>
                            }
                            { data.poolStatus == 'active' ?
                                <>
                                {
                                    
                                    Object.keys(withDrawTokenForDisplay()).length > 0 ? 
                                    <TokenSelectCont className='poolTxInputSelectPoolModal' >
                                        <TokenContainer>
                                            {renderTokenIconsDropDown(withDrawTokenForDisplay().name, false)}
                                        </TokenContainer>
                                        <TokenNameTokenSelect className='poolsTx'>{withDrawTokenForDisplay().name.split(" ")[0]}</TokenNameTokenSelect>
                                        {/* <img className="arrow-down" src={ArrowDown} alt="" /> */}
                                    </TokenSelectCont>
                                    : <></>
                                }
                                </>
                                :""
                            }
                        </ModalInputCont>
                        {data.poolStatus == "active" ?
                            <CardBtnGroup>
                                {/* {checkTimerOrWithdrawButton() } */}
                                <WithdrawBtn onClick={() => handleWithdrawAmount()} disabled={checkWithdrawDisabled()}>
                                    {getWithdrawText()}
                                </WithdrawBtn>
                                <CardRedBtn 
                                disabled={checkWithdrawAndZapOutDisabled()}
                                onClick={() => handleGetLpToken(data, 'convertLp', withdrawAmount) }>
                                    {getWithdrawAndZapOutText()}
                                </CardRedBtn>
                            </CardBtnGroup>
                            :
                            <WithdrawBtn 
                                onClick={() => handleWithdrawAmount()} 
                                disabled={withdrawLoading}>
                                {getWithdrawAndZapOutText()}
                            </WithdrawBtn>
                        }
                        
                    </ModalCard>
                }

                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowAPyCal(!showAPYCal)} isActive={showAPYCal}>
                        <DataInfoTitle>APR Calculations</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showAPYCal &&
                        <DataInfoCont>
                            
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Trade Fee APR (Auto Compounding)</DataInfoTxt>
                                <DataInfoTxt>{parseFloat(data.tradeFeeAPR).toFixed(2)}%</DataInfoTxt>
                            </FlexSBCont>
                            
                            {/* {data.poolId < 2 &&
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Unstake Fee APR</DataInfoTxt>
                                    <DataInfoTxt>{(parseFloat(data.widthdrawApr) * 100 ).toFixed(2)}%</DataInfoTxt>
                                </FlexSBCont>
                            } */}
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Farm GAMMA APR</DataInfoTxt>
                                <DataInfoTxt>{data.gammaAPR && (!isFinite(data.gammaAPR) || data.gammaAPR > 10000) ? "> 10,000" : data.gammaAPR ? commaFy(data.gammaAPR.toFixed(2)) : "0.00"}%</DataInfoTxt>
                            </FlexSBCont>
                            {/* {(data.poolId == 1) &&
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>BURN APR</DataInfoTxt>
                                <DataInfoTxt>{data.burnApy && (!isFinite(data.burnApy) || data.burnApy > 10000) ? "> 10,000" : data.burnApy ? commaFy(data.burnApy.toFixed(2)) : "0.00"}%</DataInfoTxt>
                            </FlexSBCont>
                            } */}
                            {/* {data.poolId < 2 &&
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Green Planet APR</DataInfoTxt>
                                <DataInfoTxt>{data.gammaApr && (!isFinite(data.gammaApr) || data.gammaApr > 10000) ? "> 10,000" : data.gammaApr ? commaFy(data.gammaApr.toFixed(2)): "0.00"}%</DataInfoTxt>
                            </FlexSBCont>
                            }
                            {data.poolId > 1 &&
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Boost APR</DataInfoTxt>
                                <DataInfoTxt>{data.boostAPR && (!isFinite(data.boostAPR) || data.boostAPR > 10000) ? "> 10,000" : data.boostAPR ? commaFy(data.boostAPR.toFixed(2)): "0.00"}%</DataInfoTxt>
                            </FlexSBCont>
                            */}
                            {data.parentFarmApr > 0 &&
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Parent Farm APR</DataInfoTxt>
                                    <DataInfoTxt>{parseFloat(data.parentFarmApr).toFixed(2)}%</DataInfoTxt>
                                </FlexSBCont>
                            }
                            <FlexSBCont>
                                <DataInfoTxt light>Total APR</DataInfoTxt>
                                <DataInfoTxt>{data.totalApr && (!isFinite(data.totalApr) || data.totalApr > 10000) ? "> 10,000" : data.totalApr ? commaFy(data.totalApr.toFixed(2)): "0.00"}%</DataInfoTxt>
                            </FlexSBCont>
                        </DataInfoCont>
                    }
                </DataInfoCard>

                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowVaultInfo(!showVaultInfo)} isActive={showVaultInfo}>
                        <DataInfoTitle>Pool Details</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showVaultInfo &&
                        <DataInfoCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>{data.name} Price</DataInfoTxt>
                                <DataInfoTxt>${commaFy(parseFloat(data.price).toFixed(2))}</DataInfoTxt>
                            </FlexSBCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Farm Contract</DataInfoTxt>
                                <a href={data.farmContract} target="_blank" rel="noreferrer">
                                    <DataInfoTxt>View</DataInfoTxt>
                                </a>
                            </FlexSBCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Vault Contract</DataInfoTxt>
                                <a href={data.vaultContract} target="_blank" rel="noreferrer">
                                    <DataInfoTxt>View</DataInfoTxt>
                                </a>
                            </FlexSBCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Vault Multiplier</DataInfoTxt>
                                <DataInfoTxt>{data.vaultMultiplier}X</DataInfoTxt>
                            </FlexSBCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Vault Type</DataInfoTxt>
                                <DataInfoTxt>Locked</DataInfoTxt>
                            </FlexSBCont>
                            {/* {data.poolId > 1 &&
                            <>
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Boost Percentage</DataInfoTxt>
                                    <DataInfoTxt>{data.gammaBoostPercent}%</DataInfoTxt>
                                </FlexSBCont>

                                <FlexSBCont>
                                    <DataInfoTxt light>Average Boost APR</DataInfoTxt>
                                    <DataInfoTxt>{data.averageGammaBoostApr && (!isFinite(data.averageGammaBoostApr) || data.averageGammaBoostApr > 10000) ? "> 10,000" : data.averageGammaBoostApr ? commaFy(data.averageGammaBoostApr.toFixed(2)): "0.00"}%</DataInfoTxt>
                                </FlexSBCont>
                            </>
                            } */}
                        </DataInfoCont>
                    }
                </DataInfoCard>

                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowFeeInfo(!showFeeInfo)} isActive={showFeeInfo}>
                        <DataInfoTitle>Fees</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showFeeInfo &&
                        <DataInfoCont>
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Deposit Fee</DataInfoTxt>
                                <DataInfoTxt>{data.depositFees}%</DataInfoTxt>
                            </FlexSBCont>
                            {
                            <FlexSBCont className='bottom-space'>
                                <DataInfoTxt light>Withdraw Fee</DataInfoTxt>
                                <DataInfoTxt>{data.withdrawalFees}%</DataInfoTxt>
                            </FlexSBCont>
                            }
                            {/* {data.poolId < 2 &&
                            <>
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Unstake Fee</DataInfoTxt>
                                    <DataInfoTxt>{data.withdrawalFees}%</DataInfoTxt>
                                </FlexSBCont>
                                <FlexSBCont className='bottom-space'>
                                    <DataInfoTxt light>Instant Unstake Fee</DataInfoTxt>
                                    <DataInfoTxt>{data.instantWithdrawFee}%</DataInfoTxt>
                                </FlexSBCont>
                            </>
                            }   
                            {data.farmName === "PCS" ?
                                <FlexSBCont>
                                    <DataInfoTxt light>Performance Fee</DataInfoTxt>
                                    <DataInfoTxt>{data.burnFees}%</DataInfoTxt>
                                </FlexSBCont>
                                : ""
                            */}
                            {+data.performanceFee > 0 ?
                            <FlexSBCont>
                                <DataInfoTxt className="aggregationFee" light>Performance Fee <InfoTooltip infoId='aggregationFee' left={5} /></DataInfoTxt>
                                <DataInfoTxt>{data.performanceFee}%</DataInfoTxt>
                            </FlexSBCont>
                            :""
                            }
                            
                        </DataInfoCont>
                    }
                </DataInfoCard>
                <DataInfoCard>
                    <InfoHeaderCont onClick={() => setShowLockedLPInfo(!showLockedLPInfo)} isActive={showLockedLPInfo}>
                        <DataInfoTitle>Locked Tokens</DataInfoTitle>
                        <img src={DownIcon} alt="" />
                    </InfoHeaderCont>
                    {showLockedLPInfo &&
                    <FlexSBCont className='lockedLPCont'>
                        <DataInfoTxt>Amount</DataInfoTxt>
                        <DataInfoTxt>Dollar Value</DataInfoTxt>
                        <DataInfoTxt>Estimated Unlock Time</DataInfoTxt>
                    </FlexSBCont>
                    }
                    { data && data.poolsLockArray && data.poolsLockArray.length > 0 && showLockedLPInfo  && _renderLockedPoolInfo()}

                </DataInfoCard>
                {/* {data.poolStatus == 'active' && data.poolId == 0 &&
                <FlexSBCont className='gamma_inf_mob'>
                    <TableInfoBox className='gamma_inf_mob'>
                        <FlexSBCont className='boostContMob'>
                            <FlexCont className='boostTextCont textCont'>
                                <HeadText className='boostText margin'>
                                    <span>Not Sure How Much More You’ll Earn With Boost?</span>
                                </HeadText>
                                <HeadText className='boostText'>
                                    Check out our handy <span>Boost Calculator!</span>
                                </HeadText>
                            </FlexCont>
                            <FlexCont>
                                <img src={inf_boost_icon} className='inf_boost_icon' />
                                <CardButton2 onClick={() => history.push(`${Paths.boost}`)} className="calculateBoost gammaBuy">Calculate Boost</CardButton2>
                            </FlexCont>
                        </FlexSBCont>
                    </TableInfoBox>
                    <TableInfoBox>
                        <FlexSBCont className='boostContMob'>
                            <FlexCont className='boostTextCont gammaTextCont'>
                                <HeadText className='boostText margin'>
                                    <span>Don't Have Any GAMMA ?</span>
                                </HeadText>
                                <HeadText className='boostText'>
                                Buy some to stake and <span>increase your APY</span> ACROSS ALL Planets Pools and Markets
                                </HeadText>
                            </FlexCont>
                            <FlexCont>
                                <img src={gammaIcon} className='gamma_icon_mob' />
                                <CardButton2 className="calculateBoost gammaBuy" onClick={() => history.push(`${Paths.swap}`)}>Buy GAMMA</CardButton2>
                            </FlexCont>
                        </FlexSBCont>
                    </TableInfoBox>
                </FlexSBCont>
                } */}
            </HAMDataCont>
            <Modal 
                isOpen={showModal}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <TokenSelect
                    selectedAdd={tokenForDisplay().address}
                    setShowTokenModal={setShowModal}
                    tokenListVal={currTokenList}
                    handleTokenSelect={handleTokenSelect}
                    crossIconColor={'white'}
                />
            </Modal>
        </HAModalCont>
    );
};

export default PoolDetailsModal;