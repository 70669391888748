import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import { updateUserLendingData } from 'logic/action/lending.action'
import { MainContainer, FlexCont, CardButton, CardButton2, FlexSBCont } from 'shared/styles/styled'
import { TitleText, TitleInfo, CardText, DataTitle } from 'shared/styles/globalText'
import { DataCont, TableSearchCont, TableProtoCalCont, LoadingText4, TableCont } from 'shared/styles/planetTableStyle'
import { TextBox, InfoText, InfoTitle, ClaimBtn } from './style';

const CompExplain = () => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    // const {  } = selector.user

    
    return (
        <FlexSBCont className="overview right">
            <TextBox>Why does this page exist ?</TextBox>
            <div className='overview_info'>
                <InfoTitle>As the Terra protocol unraveled, it affected protocols across the entire space. Green Planet had markets for UST, aUST & LUNA. Liquidators were unable to liquidate positions correctly when the Terra chain was halted and Chainlink stopped price feeds abruptly.<br/><br/> The Planet community wanted to help. So a proposal was created to allocate a % of protocol fees generated on Green Planet towards affected users.</InfoTitle>
            </div>
            
        </FlexSBCont>
    );
};

export default CompExplain