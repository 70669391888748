import { PriceChartCont, ToolTipCont, ToolTipLabel, ToolTipLabelTime, ToolTipDataTxt } from './style'
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { colors } from 'shared/styles/theme';
import moment from 'moment'

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
        return (
            <ToolTipCont>
                <div>
                    <ToolTipLabel>{labelFormatter(label)}</ToolTipLabel>
                    <ToolTipLabelTime>{labelTimeFormatter(label)}</ToolTipLabelTime>
                </div>
                {payload && payload.length > 0 && (
                    payload.map((p: any) => (
                        <ToolTipDataTxt key={`${p.name}-${p.stroke}`} className="label medium">{`PRICE : $${p.value}`}</ToolTipDataTxt>
                    )))}
            </ToolTipCont>
        );
    }

    return null;
};

const labelFormatter = (tickItem: any) => {
    return moment(tickItem).format('MMM Do YYYY');
}
const labelTimeFormatter = (tickItem: any) => {
    return moment(tickItem).format('LT');
}

const MetricsChart = (props: any) => {
    const { lineColor, data } = props
    return (
        <PriceChartCont>
            <ResponsiveContainer width="99.5%" height={160}>
                <LineChart data={data} >
                    <defs>
                        <filter id="shadow" height="200%">
                            <feGaussianBlur in="SourceAlpha" stdDeviation="7" result="blur" />
                            <feOffset in="blur" dx="0" dy="7" result="offsetBlur" />
                            <feFlood floodColor={lineColor} floodOpacity="0.5" result="offsetColor" />
                            <feComposite
                                in="offsetColor"
                                in2="offsetBlur"
                                operator="in"
                                result="offsetBlur"
                            />
                            <feMerge>
                                <feMergeNode />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                    <XAxis hide={true} dataKey='date' />
                    <YAxis hide={true} />
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <Line type="monotone" dataKey="price" filter="url(#shadow)" fill={colors.lightGreen} stroke={lineColor} dot={false} strokeWidth={3} />
                </LineChart>
            </ResponsiveContainer>
        </PriceChartCont>
    )
}
export default MetricsChart
