import Web3 from "web3";
import { WalletTypes } from "./walletConfig";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import detectEthereumProvider from "@metamask/detect-provider";
import { Networks } from "./walletConfig";
import { walletTestConnectId, walletMainConnectId, networkTestChainId, networkMainChainId, } from "./walletConfig";
import {     } from 'wagmi';
import WalletConnectProvider from "@walletconnect/web3-provider";
import {ethers} from 'ethers'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { mainnet, polygon, optimism, bsc, arbitrum } from 'wagmi/chains'

const projectId = '8f395d21e8a7dce987dfe4a6110febc9';
const chains = [bsc, mainnet];

const getBinanceUrl = () => {
    let currBscArr = 'https://bsc-dataseed.binance.org/'
    let bscArr = ['https://bsc-dataseed.binance.org/']
    currBscArr = bscArr[Math.floor(Math.random() * bscArr.length)]
    return currBscArr
}

//? new Web3(new Web3.providers.HttpProvider("https://delicate-rough-sea.bsc.quiknode.pro/80dc27e919b81c1abbd395c860ebdb31a23d83b6/"))
class Wallet {
    web3: any;
    walletType = 0;
    constructor() {
        // this.setNetworkProvider()
        this.web3 = window.ethereum !== undefined && window.ethereum.networkVersion !== null && +window.ethereum.networkVersion === 56 ? new Web3(window.ethereum) : new Web3(new Web3.providers.HttpProvider(getBinanceUrl())); 
            // window.ethereum !== undefined && window.ethereum.chainId === '0x38'
            // ? new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/"))
            // : new Web3(new Web3.providers.HttpProvider(getBinanceUrl()))
        
        //this.web3 = new Web3(new Web3.providers.HttpProvider("http://127.0.0.1:8545/"))
    }

    getNetwork = (network: string): string => {
        switch (network) {
            case Networks.mainnet:
                return "Mainnet";

            case Networks.ropsten:
                return "Ropsten";

            case Networks.rinkeby:
                return "Rinkeby";

            case Networks.goerli:
                return "Goerli";

            case Networks.kovan:
                return "Kovan";

            case Networks.moonBaseAlpha:
                return "Moonbase Alpha";

            default:
                return "Unknown";
        }
    };

    async setProvider(type: number) {
        
        let provider;
        
        switch (type) {
            case WalletTypes.metamask:
                try {
                    const provider: any = await detectEthereumProvider()
                    const { ethereum } = window;
                    const data = [
                        {
                            chainId: '0x38',
                            chainName: 'Binance Smart Chain',
                            nativeCurrency: {
                                name: 'BNB',
                                symbol: 'BNB',
                                decimals: 18,
                            },
                            rpcUrls: [walletMainConnectId, "https://bsc-dataseed.binance.org/"],
                            blockExplorerUrls: ['https://bscscan.com/'],
                        },
                    ]
                    if(window.ethereum.networkVersion !== null && +window.ethereum.networkVersion !== 56){
                       await provider.request({ method: 'wallet_addEthereumChain', params: data }).catch()
                    }
                    // console.log("provider details", provider, ethereum)
                    if (provider === ethereum) {
                        this.web3.setProvider(provider);
                        this.walletType = type;
                    } else {
                        window.alert('Please activate MetaMask first.')
                    }
                } catch (error : any) {
                    console.log(error)
                    if(error.code !== -32002){
                        console.log("in here", typeof error.code)
                        window.alert('Please activate MetaMask first')
                    }
                    
                }
                break;

            case WalletTypes.walletConnect:
                
                const provider = await EthereumProvider.init({
                    projectId: projectId, // REQUIRED your projectId
                    chains: [56], // REQUIRED chain ids
                    optionalChains: [1], // OPTIONAL chains
                    showQrModal: false, // REQUIRED set to "true" to use @walletconnect/modal
                    methods: ["eth_sendTransaction", "personal_sign"], // REQUIRED ethereum methods
                    optionalMethods: ["eth_accounts",
                    "eth_requestAccounts",
                    "eth_sendRawTransaction",
                    "eth_sign",
                    "eth_signTransaction",
                    "eth_signTypedData",
                    "eth_signTypedData_v3",
                    "eth_signTypedData_v4",
                    "wallet_switchEthereumChain",
                    "wallet_addEthereumChain",
                    "wallet_getPermissions",
                    "wallet_requestPermissions",
                    "wallet_registerOnboarding",
                    "wallet_watchAsset",
                    "wallet_scanQRCode"], // OPTIONAL ethereum methods
                    events:["chainChanged", "accountsChanged"], // REQUIRED ethereum events
                    optionalEvents: ["message", "disconnect", "connect"], // OPTIONAL ethereum events
                    //rpcMap, // OPTIONAL rpc urls for each chain
                    metadata: {
                              name: "Planet Finance",
                              description: "My Dapp description",
                              url: window.location.href && window.location.href !== null ? window.location.href : "https://app.planet.finance.com",
                              icons: ["https://planet.finance/aqua.1ba00457.webp"],
                            }, // OPTIONAL metadata of your app
                    //qrModalOptions // OPTIONAL - `undefined` by default, see https://docs.walletconnect.com/2.0/web3modal/options
                  })
                  await provider.enable()
                //   await provider.connect()
                this.walletType = type;
                this.web3.setProvider(provider);
                break;

            case WalletTypes.binance:
                // console.log(window.BinanceChain);
                this.web3.setProvider(window.BinanceChain);
                this.walletType = type;

                break;
            default:
                throw new Error("Invalid wallet type");
        }
        
    }

    async login(type: number) {
        let accounts: Array<string>;
        let address: String;

        switch (type) {
            case WalletTypes.metamask:
                accounts = await this.web3.currentProvider.request({
                    method: "eth_requestAccounts",
                });

                const chainId = await this.web3.currentProvider.request({
                    method: "eth_chainId",
                });
                if (accounts.length) {
                    address = accounts[0];
                } else {
                    throw new Error("No account found");
                }

                this.web3.currentProvider.on(
                    "accountsChanged",
                    async (accounts: Array<string>) => {

                        const chainId = await this.web3.currentProvider.request({
                            method: "eth_chainId",
                        });

                        if (accounts.length) {
                            address = accounts[0];
                        }
                    }
                );

                this.web3.currentProvider.on("chainChanged", () => {
                    window.location.reload();
                });
                break;

            case WalletTypes.walletConnect:
                accounts = await this.web3.currentProvider.enable();
                const chainIdWalletConnect = await this.web3.eth.getChainId();

                if (accounts.length) {
                    address = accounts[0];
                    // window.location.reload();
                } else {
                    throw new Error("No account found");
                }

                this.web3.currentProvider.on("chainChanged", () => {
                    window.location.reload();
                });

                this.web3.currentProvider.on(
                    "accountsChanged",
                    async (accounts: string[]) => {
                        if (accounts.length) {
                            address = accounts[0];

                            const chainIdWalletConnect = await this.web3.eth.getChainId();
                        }
                    }
                );

                break;

            case WalletTypes.binance:
                accounts = await this.web3.currentProvider.request({
                    method: "eth_requestAccounts",
                });

                const binancechainId = await this.web3.currentProvider.request({
                    method: "eth_chainId",
                });
                if (accounts.length) {
                    address = accounts[0];
                } else {
                    throw new Error("No account found");
                }

                this.web3.currentProvider.on(
                    "accountsChanged",
                    async (accounts: Array<string>) => {

                        const chainId = await this.web3.currentProvider.request({
                            method: "eth_chainId",
                        });

                        if (accounts.length) {
                            address = accounts[0];
                        }
                    }
                );

                this.web3.currentProvider.on("chainChanged", () => {
                    window.location.reload();
                });
                break;
            default:
                throw new Error("Invalid wallet type");
        }
        return address;
    };

    async disconnect() {
        switch (this.walletType) {
            case WalletTypes.metamask:
                await this.web3.currentProvider._handleDisconnect();
                localStorage.clear();
                window.location.reload();
                break;

            case WalletTypes.walletConnect:
                localStorage.clear();
                window.location.reload();
                break;

            case WalletTypes.binance:
                localStorage.clear();
                window.location.reload();
                break;

            default:
                localStorage.clear();
                window.location.reload();
        }
    };

    async setMobileProvider (provider: any){
        this.web3.setProvider(provider);
    }
}

export default new Wallet();