// import { useDispatch } from 'react-redux'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentPlanet } from 'logic/action/planet.action'
import { SubLinkCont, SubLink, SubLinkRow, HeadText, HeadDescription } from './style'
import { analyticsLink, aquaVotingLink, docsLink } from 'service/global-constant'
import AnalyticsIcon from 'assets/icons/analytics.svg'
import governanceIcon from 'assets/icons/governance.svg'
import learnMoreIcon from 'assets/icons/learnMore.svg'
import marketsIcon from 'assets/icons/markets.svg'
import metricsIcon from 'assets/icons/metrics.svg'
import liquidationIcon from 'assets/icons/liquidations.png'
import history from 'modules/app/components/history'
// import compensation fom 'modules/'
import { Paths } from 'modules/app/components/routes/types'
import { fetchPoolData, fetchUserPortfolio, fetchPlatformData, fetchInfVaultData, fetchInfVaultDataNew, fetchPoolDataNew } from 'logic/action/user.actions'
import { fetchLendingPlatformData, fetchUserAssetData, fetchBorrowLimit, fetchLendingPlatformData_v1, fetchUserAssetData_v1 } from 'logic/action/lending.action'

const PoolSubLinks = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: any) => state)
    const { poolLoading, poolData, portfolioLoading, userPortfolio } = selector.user
    const { infVaultLoading, gammaInfData, aquaInfData } = selector.user
    const { gPlatformData, userAssetData, userOldAssetData, borrowLimit, gPlatformLoadingNew, borrowLimitLoadingNew, addNullLoading } = selector.lending

    const handlePathLink = (path: string) => {
        if (path === 'migration') {
            history.push(Paths.migration)
            dispatch(setCurrentPlanet('blue'))
        }
        if (path === 'vesting') {
            history.push(Paths.vesting)
            dispatch(setCurrentPlanet('blue'))
        }
        if (path === 'bounty') {
            history.push(Paths.bounty)
            dispatch(setCurrentPlanet('blue'))
        }
        if (path === 'pools') {
            history.push(Paths.pools)
            dispatch(setCurrentPlanet('blue'))
        }
    }

    return (
        <SubLinkCont className='pool-sublinks'>
            <SubLinkRow>

                <SubLink onClick={() => handlePathLink('pools')}>
                    {/* <div>
                        <img src={marketsIcon} alt="" />
                    </div> */}
                    <div>
                        <HeadText className='menu-link'>
                            Pools V2
                        </HeadText>
                        {/* <HeadDescription>
                            Insightful Lending Data
                        </HeadDescription> */}
                    </div>
                </SubLink>

            </SubLinkRow>
            <SubLinkRow>

                <SubLink onClick={() => handlePathLink('migration')}>
                    {/* <div>
                        <img src={marketsIcon} alt="" />
                    </div> */}
                    <div>
                        <HeadText className='menu-link'>
                            Migrate
                        </HeadText>
                        {/* <HeadDescription>
                            Insightful Lending Data
                        </HeadDescription> */}
                    </div>
                </SubLink>

            </SubLinkRow>
            <SubLinkRow>

                <SubLink onClick={() => handlePathLink('vesting')}>
                    {/* <div>
                        <img src={liquidationIcon} alt="" />
                    </div> */}
                    <div>
                        <HeadText>
                            Vesting
                        </HeadText>
                        {/* <HeadDescription>
                            Protect green planet & earn
                        </HeadDescription> */}
                    </div>
                </SubLink>
                
            </SubLinkRow>
            <SubLinkRow>
            <SubLink onClick={() => handlePathLink('bounty')}>
                    {/* <div>
                        <img src={liquidationIcon} alt="" />
                    </div> */}
                    <div>
                        <HeadText>
                            Bounty
                        </HeadText>
                        {/* <HeadDescription>
                            Protect green planet & earn
                        </HeadDescription> */}
                    </div>
                </SubLink>
                
            </SubLinkRow>
            
        </SubLinkCont>
    );
};

export default PoolSubLinks;