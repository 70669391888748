import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LoadingText4 } from 'shared/styles/planetTableStyle'
import { EarnValText } from '../style'
import { commaFy, checkZeroVal, convertToKAndM } from 'service/globalFunctions'

let timerForPendingGammaAllPools = setInterval(function () { }, 0)
const PoolEarningsClaimAndStake = (props: any) => {
    const selector = useSelector((state: any) => state)
    const { gPlatformData } = selector.lending
    const { userPortfolio, portfolioLoading, claim_type } = props
    const [ pendingGammaAllPools, setPendingGammaAllPools] = useState('0')
    useEffect(() => {
        if (timerForPendingGammaAllPools) {
            clearInterval(timerForPendingGammaAllPools); 
        }
        if(userPortfolio.v3_gammaRewards)
        {
            startTimerTripping();
        }
        return () => clearInterval(timerForPendingGammaAllPools);
    }, [userPortfolio])

    const startTimerTripping = () => {
        // console.log('userPortfolio details inside pools trickle rewards',userPortfolio)
        if(userPortfolio.v3_gammaRewards)
        {   
            userPortfolio.v3_gammaRewards = userPortfolio.v3_gammaRewards ? userPortfolio.v3_gammaRewards : 0;
            setPendingGammaAllPools(userPortfolio.v3_gammaRewards)
            clearInterval(timerForPendingGammaAllPools)
            let pendingGammaAllPools: any = userPortfolio.v3_gammaRewards;

            let apy = userPortfolio.currentApy && isFinite(userPortfolio.currentApy) ? userPortfolio.currentApy : userPortfolio.currentApy && !isFinite(userPortfolio.currentApy) ? 10000 : 0;
            let m: any = 365
            let a: any = apy / 100 + 1
            let apr = (Math.pow(a, 1 / m) - 1) * m
            // gPlatformData.userTotalSupplyBal = gPlatformData.userTotalSupplyBal ? gPlatformData.userTotalSupplyBal : 0;
            // need to change following code based tripping criteria
            // supply balance * (aprWithoutInfinityVaults) / (365 * 86400)
            let trippingPendingGammaAllPools: any = parseFloat(userPortfolio.v3_gammaRewards) * (apr) / (365 * 86400)

            let now = new Date();
            let timeWhenStarted = now.getTime();
            timerForPendingGammaAllPools = setInterval(function () {
                now = new Date();
                trippingPendingGammaAllPools = trippingPendingGammaAllPools > 0 ? trippingPendingGammaAllPools : 0;
                let newPendingGammaAllPools = parseFloat(userPortfolio.v3_gammaRewards) + (( now.getTime() - timeWhenStarted ) * trippingPendingGammaAllPools / 1000);
                setPendingGammaAllPools(newPendingGammaAllPools+"")
            }, 1000)
        }
    }

    if(portfolioLoading)
    {
        return (
            <LoadingText4 /> 
        )
    }
    else
    {
        return (
            <EarnValText className='inGamma' id="inGamma">
                {`${pendingGammaAllPools && +pendingGammaAllPools > 0 ? commaFy(parseFloat(pendingGammaAllPools).toFixed(4)) : '0.0000'} GAMMA`}
            </EarnValText>
        )
    }
};

export default PoolEarningsClaimAndStake;