import { useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import {
  CardCont,
  FlexSBCont,
  FlexCont,
  TitleText,
  InfoText,
  InfoSmallText,
  SelectCurrTxt,
  InfoLDText,
  TokenValInput,
  MaxTxt,
  TokenName,
  BackButton,
  PrimaryBtn,
} from '../style'
import {
  CreateLpCont,
  AddLDTokenCont,
  AddLDCard,
  PluIconCont,
  TokenSelectCont,
  PoolDetailsCont,
  PoolInfoText,
  ApproveBtn,
  CreateLpBtnCont,
  ApproveBtnGroup,
} from './style'
import DownIcon from 'assets/icons/down-arrow-icon.png'
import ArrowDown from 'assets/icons/arrow-down.png'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import ClockIcon from 'assets/icons/clock-icon.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import TokenSelect from 'shared/token-select'
import TransactionInfo from 'shared/transaction-info'
import ConfirmLiquidity from './ConfirmLiquidity'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions';
import { setTransLink, setConfirmTransLink } from '../swapMethods'
import {
  returnPairExistOrNot,
  returnPairAddress,
  tokenAllowance,
  DEADLINE,
  selectInstance,
  instanceType,
  convertToWei,
} from 'modules/block-chain/Swap'
import { PLANETROUTERADDRESS } from 'modules/block-chain/SwapDexAbi'
import { convertToEther } from 'modules/block-chain/BlockChainMethods'
import { maxAmount } from 'modules/block-chain/lendingAbi';

const CreateLpPair = (props: any) => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const { setLiquidityTab, tokenList, updateLiquidity, setShowHistory } = props
  const [firstAmount, setFirstAmount] = useState('')
  const [secondAmount, setSecondAmount] = useState('')
  const [firstToken, setFirstToken] = useState<any>(!!tokenList && tokenList[0])
  const [secondToken, setSecondToken] = useState<any>({})
  const [existTokenAdd, setExistTokeAdd] = useState('')
  const [tokenType, setTokenType] = useState('')
  const [showTokenModal, setShowTokenModal] = useState(false)
  const [showTransModal, setShowTransModal] = useState(false)
  const [transactionLink, setTransactionLink] = useState('')
  const [currTokenList, setCurrTokenList] = useState<any>(tokenList)
  const [isPairExist, setIsPairExist] = useState(false)
  const [pairAddress, setPairAddress] = useState('')
  const [poolDetails, setPoolDetails] = useState<any>({})
  const [firstAllowance, setFirstAllowance] = useState<any>('0.00')
  const [secondAllowance, setSecondAllowance] = useState<any>('0.00')
  const [showCnfModal, setShowCnfModal] = useState(false)
  const [confirmLdData, setConformLdData] = useState<any>({})
  const [isAmtInSuf, setIsAmtInSuf] = useState(false)
  const [inSufMsg, setInSufMsg] = useState('')
  const [approve1Loading, setApprove1Loading] = useState(false)
  const [approve2Loading, setApprove2Loading] = useState(false)
  const [createLPLoading, setCreateLpLoading] = useState(false)
  const bnbSno = 1

  const getFirstAllowance = async () => {
    try {
      if (firstToken.name !== 'BNB') {
        const firstAllowanceVal: any = await tokenAllowance(firstToken.address, selector.ethData.address)
        if (!!firstAllowanceVal && typeof firstAllowanceVal !== 'undefined') {
          setFirstAllowance(firstAllowanceVal)
        }
      }
    } catch (error) {
      setFirstAllowance('')
    }
  }
  const getSecondAllowance = async () => {
    try {
      if (secondToken.name !== 'BNB') {
        const secondAllowanceVal: any = await tokenAllowance(secondToken.address, selector.ethData.address)
        if (!!secondAllowanceVal && typeof secondAllowanceVal !== 'undefined') {
          setSecondAllowance(secondAllowanceVal)
        }
      }
    } catch (error) {
      setSecondAllowance('')
    }
  }
  const checkInSufficient = (value: any, type: any) => {
    const firstDAmt = firstToken.name === 'BNB' ? 0.01 : 0.0
    const secondDAmt = secondToken.name === 'BNB' ? 0.01 : 0.0
    if (type === 'first') {
      if (parseFloat(value) > parseFloat(firstToken.value) - firstDAmt) {
        setInSufMsg(`Insufficient ${firstToken.name} Balance`)
        setIsAmtInSuf(true)
      } else if (parseFloat(secondAmount) > parseFloat(secondToken.value) - secondDAmt) {
        setInSufMsg(`Insufficient ${secondToken.name} Balance`)
        setIsAmtInSuf(true)
      } else {
        setInSufMsg('')
        setIsAmtInSuf(false)
      }
    }
    if (type === 'second') {
      const decreaseAmt = secondToken.name === 'BNB' ? 0.01 : 0.0
      if (parseFloat(value) > parseFloat(secondToken.value) - decreaseAmt) {
        setInSufMsg(`Insufficient ${secondToken.name} Balance`)
        setIsAmtInSuf(true)
      } else if (parseFloat(firstAmount) > parseFloat(firstToken.value) - secondDAmt) {
        setInSufMsg(`Insufficient ${firstToken.name} Balance`)
        setIsAmtInSuf(true)
      } else {
        setInSufMsg('')
        setIsAmtInSuf(false)
      }
    }
  }

  const handleFirstAmount = async (e: any) => {
    const { value } = e.target
    if (floatNumRegex.test(value.toString())) {
      setFirstAmount(value)
      checkInSufficient(value, 'first')
      getFirstAllowance()
    }
    if (!value) {
      setFirstAmount('')
      checkInSufficient('0.00', 'first')
    }
  }
  const handleSecondAmount = async (e: any) => {
    const { value } = e.target
    if (floatNumRegex.test(value.toString())) {
      setSecondAmount(value)
      checkInSufficient(value, 'second')
      getSecondAllowance()
    }
    if (!value) {
      setSecondAmount('')
      checkInSufficient('0.00', 'second')
    }
  }

  const getExistTokenAdd = (type: string) => {
    if (type === 'first' && Object.values(secondToken).length > 0) {
      setExistTokeAdd(secondToken.address)
    }
    if (type === 'second' && Object.values(firstToken).length > 0) {
      setExistTokeAdd(firstToken.address)
    }
  }

  const handleTokenModal = (type: string) => {
    setTokenType(type)
    // getTokenList(type)
    getExistTokenAdd(type)
    setShowTokenModal(true)
  }


  const getTokenList = (type: string) => {
    let newTokenList: any = [...tokenList]
    if (type === 'first' && Object.values(secondToken).length > 0) {
      newTokenList = tokenList.filter((e: any) => e.sno !== secondToken.sno)
    }
    if (type === 'second' && Object.values(firstToken).length > 0) {
      newTokenList = tokenList.filter((e: any) => e.sno !== firstToken.sno)
    }
    setCurrTokenList([...newTokenList])
  }

  const getPairExistVal = async (token1: any, token2: any) => {
    try {
      const pairExist: any = await returnPairExistOrNot(token1.address, token2.address)
      setIsPairExist(pairExist)
      if (pairExist) {
        const pairAddressVal = await returnPairAddress(token1.address, token2.address)
        setPairAddress(pairAddressVal)
        // const pollDataVal: any = await userPoolDetails(token1.address, token2.address, pairAddressVal, selector.ethData.address)
        // setPoolDetails(pollDataVal)
      }
    } catch (error) {
      setIsPairExist(false)
      setPairAddress('')
    }
  }

  const handleTokenSelect = (selectedTokenData: any) => {
    if (selectedTokenData.address === existTokenAdd) {
      setFirstToken(secondToken)
      setSecondToken(firstToken)
      getPairExistVal(secondToken, firstToken)
    }
    else {
      if (tokenType === 'first') {
        setFirstToken(selectedTokenData)
        setFirstAmount('')
        if (Object.values(secondToken).length > 0) {
          getPairExistVal(selectedTokenData, secondToken)
        }
      }
      if (tokenType === 'second') {
        setSecondToken(selectedTokenData)
        setSecondAmount('')
        if (Object.values(firstToken).length > 0) {
          getPairExistVal(firstToken, selectedTokenData)
        }
      }
    }
    setExistTokeAdd('')
    setFirstAmount('')
    setSecondAmount('')
    handleToggleModal()
  }

  const handleToggleModal = () => {
    setTokenType('')
    setShowTokenModal(false)
  }

  const getApproveStatus = () => {
    const firstAmt = parseFloat(firstAmount)
    const secondAmt = parseFloat(secondAmount)
    const firstAl = parseFloat(firstAllowance)
    const secondAl = parseFloat(secondAllowance)
    if ((firstAmt > firstAl && firstToken.name !== 'BNB') || (secondAmt > secondAl && secondToken.name !== 'BNB')) {
      return true
    } else {
      return false
    }
  }

  const handleAddLdModal = async () => {
    try {
      //const res = await onAddLiquidityPopUp(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address)
      setCreateLpLoading(true)
      // if (!!res && typeof res !== 'undefined') {
      const data: any = {
        firstToken: firstToken,
        secondToken: secondToken,
        firstAmount: firstAmount,
        secondAmount: secondAmount,
        receivedTokens: Math.sqrt(parseFloat(firstAmount) * parseFloat(secondAmount)) - convertToEther(1000),
        poolShare: 100,
      }
      setConformLdData(data)
      setShowCnfModal(true)
    } catch (error) {
      console.log('error----', error)
      setConformLdData({})
      setShowCnfModal(false)
    } finally {
      setCreateLpLoading(false)
    }
  }

  const handleApprove = async (type: any) => {
    const address = type === 'first' ? firstToken.address : secondToken.address
    try {
      type === 'first' ? setApprove1Loading(true) : setApprove2Loading(true)
      const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, address)
      const approvalAmount = maxAmount
      await TOKEN_INSTANCE.methods
        .approve(PLANETROUTERADDRESS, approvalAmount)
        .send({ from: selector.ethData.address })
        .once('transactionHash', function (res: any) {
          if (!!res && typeof res) {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            setShowTransModal(true)
          }
        })
        .on('confirmation', function (confNumber: any, receipt: any) {
          if (receipt && typeof receipt !== 'undefined') {
            setApprove1Loading(false)
            setApprove2Loading(false)
            type === 'first' ? getFirstAllowance() : getSecondAllowance()
            updateLiquidity()
          }
        })
        .on('error', function (error: any) {
          setTransactionLink('')

          setApprove1Loading(false)
          setApprove2Loading(false)
        })
    } catch (error) {
    } finally {
      setApprove1Loading(false)
      setApprove2Loading(false)
    }
  }

  const handleTransactionLink = (newLink: any, hashVal: any) => {
    const data: any = {
      firstToken: firstToken.name,
      secondToken: secondToken.name,
      amount1: firstAmount,
      amount2: secondAmount,
      link: newLink,
      type: 'addedLiquidity',
      status: false,
      hashVal: hashVal,
      transStatus: 'pending',
    }
    setTransLink(data)
  }

  const handleNotification = (type: string, hash?: any) => {
    const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
    const data: any = {
      type: type,
      message: `Added liquidity for ${parseFloat(firstAmount).toFixed(4)} ${firstToken.name} and ${parseFloat(secondAmount).toFixed(4)} ${secondToken.name}`,
      link: linkUrl
    }
    dispatch(notificationClose())
    dispatch(notificationOpen(data))
  }


  const handleCreatePool = async () => {
    try {
      setCreateLpLoading(true)
      if (firstToken.sno === bnbSno)
        await _createPool(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 0)
      else await _createPool(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 1)
    } catch (error) {
      console.log('error==>', error)
    } finally {
      setCreateLpLoading(false)
      setConformLdData({})
      setFirstAmount('')
      setSecondAmount('')
      updateLiquidity()
    }
  }

  const _createPool = async (tokenA: any, tokenB: any, amountADesiredInEther: any, amountBDesiredInEther: any, to: any, flag: any) => {
    /*
     * type : true =>  addLiquidityEth
     * type : false =>  addLiquidity
     * flag = 0 for bnb
     * flag = 1 for others
     */
    let type: boolean
    let amountAMinInEther: any = 0.008 * amountADesiredInEther
    let amountBMinInEther: any = 0.008 * amountBDesiredInEther
    amountAMinInEther = amountAMinInEther.toString()
    amountBMinInEther = amountBMinInEther.toString()

    const pair = await returnPairExistOrNot(tokenA, tokenB)
    if (!pair) {
      amountAMinInEther = '0'
      amountBMinInEther = '0'
    }
    if (flag === 0) type = true
    else type = false

    let deadline = Math.floor(Date.now() / 1000) + DEADLINE
    let txArray: any

    const ROUTER_INSTANCE = await selectInstance(instanceType.PLANETROUTER)
    if (type) {
      if (firstToken.sno === bnbSno) {
        txArray = [
          tokenB,
          convertToWei(amountBDesiredInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountADesiredInEther),
        ]
      } else {
        txArray = [
          tokenA,
          convertToWei(amountADesiredInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountBDesiredInEther),
        ]
      }

      await ROUTER_INSTANCE.methods
        .addLiquidityETH(txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({
          from: to,
          value: txArray[4],
        })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setCreateLpLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setCreateLpLoading(false)
        })
    } else {
      txArray = [
        convertToWei(amountADesiredInEther),
        convertToWei(amountBDesiredInEther),
        convertToWei(amountAMinInEther),
        convertToWei(amountBMinInEther),
      ]

      await ROUTER_INSTANCE.methods
        .addLiquidity(tokenA, tokenB, txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({ from: to })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setCreateLpLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setCreateLpLoading(false)
        })
    }
  }

  const handleMaxAmount = async () => {
    let amount: any
    if (!isPairExist) {
      if (firstToken.sno === bnbSno) {
        amount = firstToken.value
        amount -= 0.01
      } else amount = firstToken.value
      amount = getNumFormat(amount)
      setFirstAmount(amount)
    }
  }
  const handleSecondMaxAmount = async () => {
    let amount: any
    if (!isPairExist) {
      if (secondToken.sno === bnbSno) {
        amount = secondToken.value
        amount -= 0.01
      } else amount = secondToken.value
      amount = getNumFormat(amount)
      setSecondAmount(amount)
    }
  }

  return (
    <Fragment>
      <CardCont>
        <CreateLpCont>
          <FlexSBCont>
            <BackButton onClick={() => setLiquidityTab('liquidity')}>
              <img src={DownIcon} alt="back-btn" />
            </BackButton>
            <TitleText>Create Liquidity Pool</TitleText>
            <img className=" clock-icon" src={ClockIcon} alt="" onClick={() => setShowHistory(true)} />
          </FlexSBCont>
          <InfoText className="create-lp" light>
            Add tokens to a liquidity pool
          </InfoText>
          <AddLDTokenCont>
            <AddLDCard>
              <FlexSBCont>
                <InfoLDText>Amount</InfoLDText>
                <InfoLDText>{`Balance: ${!!firstToken && Object.values(firstToken).length > 0 ? parseFloat(firstToken.value).toFixed(4) : '-'
                  }`}</InfoLDText>
              </FlexSBCont>
              <FlexSBCont className="token-input">
                <TokenValInput
                  placeholder="0"
                  disabled={!(Object.values(firstToken).length > 0 && Object.values(secondToken).length > 0) || isPairExist}
                  onChange={handleFirstAmount}
                  value={firstAmount && firstAmount}
                />
                <FlexCont>
                  {Object.values(firstToken).length > 0 && <MaxTxt onClick={() => handleMaxAmount()}>Max</MaxTxt>}
                  <TokenSelectCont onClick={() => handleTokenModal('first')}>
                    {Object.values(firstToken).length > 0 ? (
                      <>
                        <img className="token-icon" src={firstToken.icon} alt="" />
                        <TokenName>{firstToken.name}</TokenName>
                      </>
                    ) : (
                      <SelectCurrTxt>Select Currency</SelectCurrTxt>
                    )}
                    <img className="arrow-down" src={ArrowDown} alt="" />
                  </TokenSelectCont>
                </FlexCont>
              </FlexSBCont>
            </AddLDCard>
            <PluIconCont>
              <img src={CrossIcon} alt="" />
            </PluIconCont>
            <AddLDCard>
              <FlexSBCont>
                <InfoLDText>Amount</InfoLDText>
                <InfoLDText>{`Balance: ${!!secondToken && Object.values(secondToken).length > 0 ? parseFloat(secondToken.value).toFixed(4) : '-'
                  }`}</InfoLDText>
              </FlexSBCont>
              <FlexSBCont className="token-input">
                <TokenValInput
                  placeholder="0"
                  disabled={!(Object.values(firstToken).length > 0 && Object.values(secondToken).length > 0) || isPairExist}
                  onChange={handleSecondAmount}
                  value={secondAmount && secondAmount}
                />
                <FlexCont>
                  {Object.values(secondToken).length > 0 && <MaxTxt onClick={() => handleSecondMaxAmount()}>Max</MaxTxt>}
                  <TokenSelectCont onClick={() => handleTokenModal('second')}>
                    {Object.values(secondToken).length > 0 ? (
                      <>
                        <img className="token-icon" src={secondToken.icon} alt="" />
                        <TokenName>{secondToken.name}</TokenName>
                      </>
                    ) : (
                      <SelectCurrTxt>Select Currency</SelectCurrTxt>
                    )}
                    <img className="arrow-down" src={ArrowDown} alt="" />
                  </TokenSelectCont>
                </FlexCont>
              </FlexSBCont>
            </AddLDCard>
          </AddLDTokenCont>
          {isPairExist && (
            <InfoSmallText className="warning-text">
              This liquidity pool already exists. Choose tokens to create a pair that doesn’t exist or go back and add liquidity to an
              existing liquidity pool.
            </InfoSmallText>
          )}
          {!isPairExist && Object.values(secondToken).length > 0 && (
            <>
              {parseFloat(firstAmount) > 0 && parseFloat(secondAmount) > 0 && (
                <PoolDetailsCont>
                  <InfoSmallText>Pool Details</InfoSmallText>
                  <FlexSBCont className="pool-info">
                    <PoolInfoText>{`${firstToken.name} Per ${secondToken.name}`}</PoolInfoText>
                    <InfoSmallText>
                      {!!poolDetails && firstAmount && secondAmount
                        ? (parseFloat(firstAmount) / parseFloat(secondAmount)).toFixed(4)
                        : parseFloat(poolDetails.price1).toFixed(4)}
                    </InfoSmallText>
                  </FlexSBCont>
                  <FlexSBCont className="pool-info">
                    <PoolInfoText>{`${secondToken.name} Per ${firstToken.name}`}</PoolInfoText>
                    <InfoSmallText>
                      {!!poolDetails && firstAmount && secondAmount
                        ? (parseFloat(secondAmount) / parseFloat(firstAmount)).toFixed(4)
                        : parseFloat(poolDetails.price2).toFixed(4)}
                    </InfoSmallText>
                  </FlexSBCont>
                  <FlexSBCont className="pool-info">
                    <PoolInfoText>Share Of Pool</PoolInfoText>
                    <InfoSmallText>100%</InfoSmallText>
                  </FlexSBCont>
                </PoolDetailsCont>
              )}
            </>
          )}
          <CreateLpBtnCont marginStatus={parseFloat(firstAmount) > 0 && parseFloat(secondAmount) > 0}>
            {parseFloat(firstAmount) > 0 && parseFloat(secondAmount) > 0 ? (
              <>
                {isAmtInSuf ? (
                  <PrimaryBtn disabled>{inSufMsg}</PrimaryBtn>
                ) : (
                  <>
                    {getApproveStatus() && (
                      <ApproveBtnGroup>
                        {parseFloat(firstAllowance) < parseFloat(firstAmount) && firstToken.name !== 'BNB' && (
                          <ApproveBtn disabled={approve1Loading} onClick={() => handleApprove('first')}>
                            {approve1Loading ? <Spinner /> : `${`Approve ${firstToken.name}`}`}
                          </ApproveBtn>
                        )}
                        {parseFloat(secondAllowance) < parseFloat(secondAmount) && secondToken.name !== 'BNB' && (
                          <ApproveBtn disabled={approve2Loading} onClick={() => handleApprove('second')}>
                            {approve2Loading ? <Spinner /> : `${`Approve ${secondToken.name}`}`}
                          </ApproveBtn>
                        )}
                      </ApproveBtnGroup>
                    )}
                    <PrimaryBtn disabled={getApproveStatus() || createLPLoading} onClick={() => handleAddLdModal()}>
                      {createLPLoading ? <Spinner /> : 'Create Pool'}
                    </PrimaryBtn>
                  </>
                )}
              </>
            ) : (
              <PrimaryBtn disabled>{isAmtInSuf ? inSufMsg : 'Create Pool'}</PrimaryBtn>
            )}
          </CreateLpBtnCont>
        </CreateLpCont>
      </CardCont>
      <CustomModal show={showTokenModal} toggleModal={handleToggleModal}>
        {showTokenModal && (
          <TokenSelect
            setShowTokenModal={setShowTokenModal}
            tokenListVal={currTokenList}
            handleTokenSelect={handleTokenSelect}
            existTokenAdd={existTokenAdd}
          />
        )}
      </CustomModal>
      <CustomModal show={showCnfModal} toggleModal={setShowCnfModal}>
        {showCnfModal && (
          <ConfirmLiquidity setShowCnfModal={setShowCnfModal} confirmLdData={confirmLdData} handleAddLiquidity={handleCreatePool} />
        )}
      </CustomModal>
      <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
        {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
      </CustomModal>
    </Fragment>
  )
}

export default CreateLpPair
