import { useState } from 'react'
import { FlexSBCont, /* FlexCont */ } from 'shared/styles/styled'
import { ExpandedCell, ExpendCellTitle, TableInfoRows, TableInfoBox, TableInfoTxt, CellDownIcon } from 'shared/styles/planetTableStyle'
import DownIcon from 'assets/icons/down.svg'
// import InfoTooltip from 'shared/info-tool-tip'
import { commaFy } from 'service/globalFunctions'
import { anchor_apy } from 'modules/block-chain-green/lendingAbi'

const VaultInfo = (props: any) => {
    const [showVaultInfo, setShowVaultInfo] = useState(false)
    const [showFeeInfo, setShowFeeInfo] = useState(false)
    const [showAPYCal, setShowAPyCal] = useState(false)
    const { data } = props

    const _renderVaultInfo = () => {
        return (
            <TableInfoBox>
                <FlexSBCont>
                    <TableInfoTxt light>{`${data.name} Price`}</TableInfoTxt>
                    <TableInfoTxt>${commaFy(parseFloat(data.price).toFixed(2))}</TableInfoTxt>
                </FlexSBCont>
                {data.protocol === "Planet" ?
                    <FlexSBCont>
                        <TableInfoTxt light>Origin</TableInfoTxt>
                        <TableInfoTxt>Green Planet</TableInfoTxt>
                    </FlexSBCont>
                    :
                    <FlexSBCont>
                        <TableInfoTxt light>Origin</TableInfoTxt>
                        <TableInfoTxt>PCS</TableInfoTxt>
                    </FlexSBCont>
                }

                <FlexSBCont>
                    <TableInfoTxt light>Contract</TableInfoTxt>
                    <a href={data.farmContract} target="_blank" rel="noreferrer">
                        <TableInfoTxt>View</TableInfoTxt>
                    </a>
                </FlexSBCont>
                {data.protocol === "PCS" ?
                    <FlexSBCont>
                        <TableInfoTxt light>Vault Contract</TableInfoTxt>
                        <a href={data.vaultContract} target="_blank" rel="noreferrer">
                            <TableInfoTxt>View</TableInfoTxt>
                        </a>
                    </FlexSBCont>
                    :
                    ""
                }
                {data.protocol === "PCS" ?
                    <FlexSBCont>
                        <TableInfoTxt light>Vault Multiplier</TableInfoTxt>
                        <TableInfoTxt>{data.vaultMultiplier}X</TableInfoTxt>
                    </FlexSBCont>
                    :
                    ""
                }
            </TableInfoBox>
        )
    }

    const _renderFeeInfo = () => {
        return (
            <TableInfoBox>
                <FlexSBCont >
                    <TableInfoTxt light>Deposit Fee</TableInfoTxt>
                    <TableInfoTxt>{data.depositFee}%</TableInfoTxt>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Withdraw Fee</TableInfoTxt>
                    <TableInfoTxt>{data.withdrawalFees}%</TableInfoTxt>
                </FlexSBCont>

                {data.protocol === "PCS" ?
                    <FlexSBCont>
                        <TableInfoTxt light>Performance Fee</TableInfoTxt>
                        <TableInfoTxt>{data.burnFees}%</TableInfoTxt>
                    </FlexSBCont>
                    :
                    ""
                }
            </TableInfoBox>
        )
    }

    const _renderAPYCalculation = () => {
        return (
            <TableInfoBox>
                {data.name === 'aUST' &&
                <FlexSBCont>
                    <TableInfoTxt light>ANCHOR APY</TableInfoTxt>
                    <TableInfoTxt>{anchor_apy}%</TableInfoTxt>
                </FlexSBCont>
                }
                <FlexSBCont>
                    <TableInfoTxt light>GAMMA APY</TableInfoTxt>
                    <TableInfoTxt>{parseFloat(data.gammaApy).toFixed(2)}%</TableInfoTxt>
                </FlexSBCont>
                {data.isBoostPool ?
                    <FlexSBCont>
                        <TableInfoTxt light>Parent Farm APY</TableInfoTxt>
                        <TableInfoTxt>{parseFloat(data.parentFarmApy).toFixed(2)}%</TableInfoTxt>
                    </FlexSBCont> :
                    <>
                        {data.name === 'AQUA' ?
                            <FlexSBCont>
                                <TableInfoTxt light>Burn APY</TableInfoTxt>
                                <TableInfoTxt>{parseFloat(data.burnApy).toFixed(2)}%</TableInfoTxt>
                            </FlexSBCont> :
                            <>
                                {data.name !== 'GAMMA' &&
                                    <FlexSBCont>
                                        <TableInfoTxt light>Supply APY</TableInfoTxt>
                                        <TableInfoTxt>{parseFloat(data.supplyApy).toFixed(2)}%</TableInfoTxt>
                                    </FlexSBCont>}
                            </>
                        }
                    </>
                }
                <FlexSBCont>
                    <TableInfoTxt light>Total APY</TableInfoTxt>
                    <TableInfoTxt>{parseFloat(data.totalApy).toFixed(2)}%</TableInfoTxt>
                </FlexSBCont>
            </TableInfoBox>
        )
    }

    return (
        <TableInfoRows>
            <ExpandedCell className="item1">
                <div className="on-web">
                    <ExpendCellTitle>Vault Details</ExpendCellTitle>
                    {_renderVaultInfo()}
                </div>
                <div className="on-mob">
                    <FlexSBCont onClick={() => setShowVaultInfo(!showVaultInfo)}>
                        <ExpendCellTitle>Vault Details</ExpendCellTitle>
                        <CellDownIcon activeStatus={showVaultInfo}><img src={DownIcon} alt="" /></CellDownIcon>
                    </FlexSBCont>
                    {showVaultInfo && _renderVaultInfo()}
                </div>
            </ExpandedCell>
            <ExpandedCell className="item2">
                <div className="on-web">
                    <ExpendCellTitle>Fees</ExpendCellTitle>
                    {_renderFeeInfo()}
                </div>
                <div className="on-mob">
                    <FlexSBCont onClick={() => setShowFeeInfo(!showFeeInfo)}>
                        <ExpendCellTitle>Fees</ExpendCellTitle>
                        <CellDownIcon activeStatus={showFeeInfo}><img src={DownIcon} alt="" /></CellDownIcon>
                    </FlexSBCont>
                    {showFeeInfo && _renderFeeInfo()}
                </div>
            </ExpandedCell>
            <ExpandedCell className="item3">
                <div className="on-web">
                    <ExpendCellTitle>APY Calculations</ExpendCellTitle>
                    {_renderAPYCalculation()}
                </div>
                <div className="on-mob">
                    <FlexSBCont onClick={() => setShowAPyCal(!showAPYCal)}>
                        <ExpendCellTitle>APY Calculations</ExpendCellTitle>
                        <CellDownIcon activeStatus={showAPYCal}><img src={DownIcon} alt="" /></CellDownIcon>
                    </FlexSBCont>
                    {showAPYCal && _renderAPYCalculation()}
                </div>
            </ExpandedCell>
        </TableInfoRows>
    )
}
export default VaultInfo
