import { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'
import { SwapContainer, SwapHeaderCont, SwapStatusText } from './style'
import TokenSwap from './components/token-swap/TokenSwap'
import Liquidity from './components/liquidity/Liquidity'
import { tokenList, returnTokenList, returnTokenListForAddLiquidity,swapTokenList, returnSwapTokenList, tokenSwapList } from '../block-chain/tokenList'
import { returnUserLiquidity } from 'modules/block-chain/Swap'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'

const wormholeLink = "https://synapseprotocol.com/"//"https://portalbridge.com/#/transfer";
const SwapPlanetZap = (props: any) => {
  const selector = useSelector((state: any) => state)
  const [activeTab, setActiveTab] = useState('swap')
  const [tokenDataList, setTokenDataList] = useState<any>(tokenSwapList)
  const [tokenListForAddLiquidity, setTokenListForAddLiquidity] = useState<any>(tokenSwapList)
  const [ldLoading, setLdLoading] = useState(false)
  const [liquidityList, setLiquidityList] = useState<any>([])
  const [initialTokenAdd, setInitialTokenAdd] = useState('')
  const [swapTokenArray, setSwapTokenArray] = useState<any>(tokenSwapList)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const tabUrl: any = props.match.url
    if (!!tabUrl) {
      const tab = tabUrl.split('/')
      if (tab.length > 1) {
        setActiveTab(tab[1])
      }
    }
  }, [props.match.url])

  useEffect(() => {
    const pathData: any = props.location.state
    if (!!pathData) {
      if (!!pathData.tokenAdd) {
        setInitialTokenAdd(pathData.tokenAdd)
      }
    }
  }, [props.location.state])

  useEffect(() => {
    const userAddress = selector.ethData.address
    const getTokenList = async () => {
      try {
        const res = await returnTokenList(userAddress)
        if (!!res && typeof res !== 'undefined') {
          // console.log("token list", res, selector.ethData.address)
          setTokenDataList(res)
        }
      } catch (error) { }
    }
    getTokenList()

    const getTokenListForAddLiquidity = async () => {
      try {
        const res = await returnTokenListForAddLiquidity(userAddress)
        if (!!res && typeof res !== 'undefined') {
          // console.log("token list", res, selector.ethData.address)
          setTokenListForAddLiquidity(res)
        }
      } catch (error) { }
    }

    getTokenListForAddLiquidity()
  }, [selector.ethData.address])

  useEffect(() => {
    const getUserLiquidity = async () => {
      try {
        setLdLoading(true)
        if (!!selector.ethData.address) {
          const res = await returnUserLiquidity(selector.ethData.address)
          //console.log("returnUserLiquidity",res)
          if (!!res && typeof res !== 'undefined') {
            setLiquidityList(res)
          }
        }
      } catch (error) { }
      finally {
        setLdLoading(false)
      }
    }
    if (activeTab !== 'swap') {
      getUserLiquidity()
    }
    // return () => {
    //   setLiquidityList([]); 
    // };
  }, [selector.ethData.address, activeTab])

  const updateTokenList = async () => {
    const userAddress = selector.ethData.address
    try {
      const res = await returnTokenList(userAddress)
      if (!!res && typeof res !== 'undefined') {
        setTokenDataList(res)
      }
    } catch (error) {
      console.log('error===>', error)
    }
  }

  const updateTokenListForAddLiquidity = async () => {
    const userAddress = selector.ethData.address
    try {
      const res = await returnTokenListForAddLiquidity(userAddress)
      if (!!res && typeof res !== 'undefined') {
        setTokenListForAddLiquidity(res)
      }
    } catch (error) {
      console.log('error===>', error)
    }
  }

  const updateSwapTokenList = async () => {
    const userAddress = selector.ethData.address
    try {
      const res = await returnSwapTokenList(userAddress)
      if (!!res && typeof res !== 'undefined') {
        setSwapTokenArray(res)
      }
    } catch (error) {
      console.log('error===>', error)
    }
  }

  const updateUserLiquidity = async () => {
    try {
      if (!!selector.ethData.address) {
        const res = await returnUserLiquidity(selector.ethData.address)
        if (!!res && typeof res !== 'undefined') {
          setLiquidityList(res)
        }
      }
    } catch (error) { }
  }

  const handleTabClick = (tabName: string) => {
    history.push(`${Paths.root}${tabName}`)
  }

  return (
    <SwapContainer className=''>
      <SwapHeaderCont className='swap'>
        <SwapStatusText className='tabText' activeStatus={activeTab === 'swap'} onClick={() => handleTabClick('swap')} >
          Swap
        </SwapStatusText>
        <SwapStatusText className='tabText' activeStatus={activeTab === 'liquidity'} onClick={() => handleTabClick('liquidity')}>
          Liquidity
        </SwapStatusText>
        {/* <SwapStatusText className='tabText' onClick={()=> window.open("https://portalbridge.com/#/transfer", "_blank")}>
          Wormhole
        </SwapStatusText> */}
      </SwapHeaderCont>
      {activeTab === 'swapplanetzap' && <TokenSwap tokenList={tokenDataList} updateTokenList={updateTokenList} initialTokenAdd={initialTokenAdd} />}
      {activeTab === 'swap' && <TokenSwap tokenList={tokenDataList} updateTokenList={updateTokenList} initialTokenAdd={initialTokenAdd} />}
      {activeTab === 'liquidity' && <Liquidity tokenList={tokenDataList} ldLoading={ldLoading} liquidityList={liquidityList} updateTokenList={updateTokenList} tokenListForAddLiquidity={tokenListForAddLiquidity} updateTokenListForAddLiquidity={updateTokenListForAddLiquidity} updateUserLiquidity={updateUserLiquidity} />}
    </SwapContainer>
  )
}

export default withRouter(SwapPlanetZap)
