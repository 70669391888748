import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch, connect } from 'react-redux'
import { MainContainer, FlexCont, CardButton, CardButton2 } from 'shared/styles/styled'
import { TitleText, TitleInfo, CardTitle, CardText, CardSubText } from 'shared/styles/globalText'
import { BaseContainer, BaseHeader, TvlInfoBox, Card, UserInfoCont, OverViewInfo, CardNumText, BalanceWrapper, BalanceCont, BalanceText, LevelBox, MobBalWrapper, MobBalCard, MobBuyAssetCont, MobBalText, LoadingText1, LoadingText2, ConnectWalletBtn } from './style'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import TvlIcon from 'assets/icons/tvl-icon.svg'
import { commaFy, convertToKAndM } from 'service/globalFunctions'
import InfoTooltip from 'shared/info-tool-tip'
import Projection from './components/Projection'
import PlanetActions from './components/PlanetActions'
import PlanetToken from './components/PlanetToken'
import ValueLocked from './components/ValueLocked'
import CountUp from "react-countup"
import PlanetNews from './components/PlanetNews'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { aquaAddress } from 'modules/block-chain/abi'
import GammaBoost from './components/GammaBoost'
import HighestApySection from './components/HighestApySection'
import { NewsWrapper, NewsContainer, NewsDataCard, NewsContent, NewsTitle, NewsInfo } from './components/style'
import CustomModal from 'shared/custom-modal'
import WalletLogout from 'modules/app/components/wallet-modal/WalletLogout'
import WalletList from 'modules/app/components/wallet-modal/WalletList'
import { connectWallet } from 'modules/wallet-provider/WalletProvider'
import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsAvatar,
    useEnsName,
  } from 'wagmi'
import { useWeb3Modal } from "@web3modal/react";
import { connectEthWallet, disconnectEthWallet } from 'logic/action/wallet.actions'
import wallet from 'modules/wallet/wallet'
import RightIcon from 'assets/icons/right_arrow.svg'


const Base = (props: any) => {
    const { portfolioLoading, userPortfolio, platformLoading, platformData } = props
    const selector = useSelector((state: any) => state)
    const { poolLoading, poolData, gammaInfDataNew, aquaInfDataNew } = selector.user
    const [highestApyArr, setHighestApyArr] = useState<any>([])
    const [gammaBtcbPool, setGammaBtcbPool] = useState<any>({})
    const { ethWalletConnected } = selector.ethData
    const [openWalletList, setOpenWalletList] = useState(false)
    const [platform_data, setPlatformData] = useState({
        aqua_price: 0,
        gamma_price: 0
    })
    const { connector: activeConnector, address, isConnected } = useAccount()
    const { connect, connectors, error, isLoading, pendingConnector } = useConnect()
    const { disconnect } = useDisconnect()
    const [loading, setLoading] = useState(false);
    const { open } = useWeb3Modal();
    const dispatch = useDispatch()

    useEffect(() => {
        setPlatformData(platformData);
    },[platformData])

    useEffect(() => {
        // console.log('platformData', platformData, isConnected, connectors, address );
        setPlatformData(platformData);
        if(address && address !== null){
            // console.log("set wallet address to local storage", address)
            dispatch(connectEthWallet(address));
            localStorage.setItem("walletConnected", JSON.stringify(true));
            let walletType = localStorage.getItem("wagmi.wallet");
            if(walletType != null  ) {
                let type = JSON.parse(walletType) == "walletConnect"? 3 : 1;
                localStorage.setItem("walletType", JSON.stringify(type));
                setProviderInWallet(type)
            } else {
                setProviderInWallet(1)
            }
        }
    },[platformData, address])

    const setProviderInWallet = async (wallet_type?: any) => {
        if(wallet_type == 3){
          await wallet.setProvider(wallet_type);
          const address = await wallet.login(wallet_type);
        } else {
            await wallet.setProvider(1);
          }
      }
    
    const goToSwap = () => {
        history.push(`${Paths.swap}`)
    }
    const handleBuyAqua = () => {
        history.push(`${Paths.swap}`, { tokenAdd: aquaAddress })
    }

    const goToVesting = () => {
        history.push(`${Paths.vesting}`)
    }

    useEffect(() => {
        if(gammaInfDataNew != undefined && aquaInfDataNew != undefined && poolData != undefined && poolData.v3Pools != undefined && poolData.v3Pools.active && poolData.v3Pools.active.length > 0 && poolData.newVaults != undefined && poolData.newVaults.active && poolData.newVaults.active.length > 0) {
            if(Object.keys(gammaInfDataNew).length > 0 && Object.keys(aquaInfDataNew).length > 0) {
                let pools = JSON.parse(JSON.stringify(poolData.v3Pools));
                pools.active.unshift(gammaInfDataNew, aquaInfDataNew);
                let newPoolsNVaultsArray = [ ...pools.active, ...poolData.newVaults.active];
                let highestApy: any;
                if (!!newPoolsNVaultsArray && Object.values(newPoolsNVaultsArray).length > 0) {
                  highestApy = newPoolsNVaultsArray.sort((a: any, b: any) => (parseFloat(b.totalApy) > parseFloat(a.totalApy)) ? 1 : (parseFloat(a.totalApy) > parseFloat(b.totalApy) ? -1 : 0))
                  highestApy = highestApy.slice(0, 10)
                }
                setHighestApyArr(highestApy)
                let gammaBtcbObj: any = pools.active.find((poolObj: any) => poolObj.name.toLowerCase() == "gamma-btcb")
                setGammaBtcbPool(gammaBtcbObj)
            } else {
                let pools = JSON.parse(JSON.stringify(poolData.v3Pools));
                let newPoolsNVaultsArray = [ ...pools.active, ...poolData.newVaults.active];
                let highestApy: any;
                if (!!newPoolsNVaultsArray && Object.values(newPoolsNVaultsArray).length > 0) {
                  highestApy = newPoolsNVaultsArray.sort((a: any, b: any) => (parseFloat(b.totalApy) > parseFloat(a.totalApy)) ? 1 : (parseFloat(a.totalApy) > parseFloat(b.totalApy) ? -1 : 0))
                  highestApy = highestApy.slice(0, 10)
                }
                setHighestApyArr(highestApy)
                let gammaBtcbObj: any = pools.active.find((poolObj: any) => poolObj.name.toLowerCase() == "gamma-btcb")
                setGammaBtcbPool(gammaBtcbObj)
            }
        }
    }, [gammaInfDataNew, aquaInfDataNew, poolData])

    async function onOpen() {
        setLoading(true);
        await open();
        setLoading(false);
    }

    const renderOverView = () => {
        return (
            <Fragment>
                <CardTitle className='overview'>Overview</CardTitle>
                <OverViewInfo className='overview-info'>
                    <div>
                        <CardText className='overview-title'>Net Balance</CardText>
                        {portfolioLoading ?
                            <LoadingText1 /> :
                            <CardNumText className='overview-values'>
                                <CountUp
                                    start={0.0}
                                    end={userPortfolio.balanceInVaults}
                                    duration={0.5}
                                    decimals={2}
                                    formattingFn={(num) => `$ ${commaFy(num.toFixed(2))}`}
                                />
                            </CardNumText>
                        }
                    </div>
                    <div>
                        <CardText className='overview-title'>Current APY</CardText>
                        {portfolioLoading ?
                            <LoadingText1 /> :
                            <CardNumText className='overview-values'>
                                <CountUp
                                    start={0.0}
                                    end={userPortfolio.currentApy}
                                    duration={0.5}
                                    decimals={2}
                                    formattingFn={(num) => userPortfolio.currentApy > 9999 ? `>${convertToKAndM(num)}%` : `${convertToKAndM(num)}%`}
                                />
                            </CardNumText>
                        }
                    </div>
                </OverViewInfo>
                <OverViewInfo className='overview-info'>
                    <div>
                        <CardText className='overview-title'>Pending Earnings</CardText>
                        {portfolioLoading ?
                            <LoadingText1 /> :
                            <CardNumText className='green-text overview-values'>
                                <CountUp
                                    start={0.0}
                                    end={userPortfolio.pendingEarnings}
                                    duration={0.5}
                                    decimals={2}
                                    formattingFn={(num) => `$ ${commaFy(num.toFixed(2))}`}
                                />
                            </CardNumText>
                        }
                    </div>
                    <div>
                        <CardText className='overview-title vesting-info' onClick={() => goToVesting()}>Collect Gamma <span className="marketSymbol"><img className='RightIcon' src={RightIcon} alt="" /></span></CardText>
                        {portfolioLoading ?
                            <LoadingText1 /> :
                            <CardNumText className='green-text overview-values'>
                                <CountUp
                                    start={0.0}
                                    end={0.0}
                                    duration={0.5}
                                    decimals={2}
                                    formattingFn={(num) => `$ ${commaFy(num.toFixed(2))}`}
                                />
                            </CardNumText>
                        }
                    </div>
                    {/* <div>
                        <FlexCont>
                            <CardText>Current Level</CardText>
                            <InfoTooltip infoId='currentLevel' left={6} />
                        </FlexCont>
                        {portfolioLoading ?
                            <LoadingText1 /> :
                            <FlexCont>
                                <CardNumText>
                                    <CountUp
                                        start={0}
                                        end={userPortfolio.userLevel.level}
                                        duration={0.5}
                                        decimals={0}
                                        formattingFn={(num) => `${num}`}
                                    />
                                </CardNumText>
                                <LevelBox>
                                    <p>{`${userPortfolio.userLevel.discount}% Discount`}</p>
                                </LevelBox>
                            </FlexCont>
                        }
                    </div> */}
                </OverViewInfo>
            </Fragment>
        )
    }

    const renderWalletConnect = () => {
        return (
            <div>
                <NewsWrapper className='newsCont'>
                    <CardTitle className="cardTitle">Connect Your Wallet To See An Overview Of Your Portfolio</CardTitle>

                    <ConnectWalletBtn className="base-wallet-connect-web" onClick={() => onOpen()}>Connect Your Wallet</ConnectWalletBtn>
                    <ConnectWalletBtn className="base-wallet-connect-mob" onClick={() => onOpen()}>Connect Your Wallet</ConnectWalletBtn>
                </NewsWrapper>
            </div>
        );
    }

    const _renderBalFormat = (balance: any) => {
        let newBal: any = '0.0000'
        if (!!balance && parseFloat(balance) > 0) {
            newBal = parseFloat(balance)
        }
        const balArr = parseFloat(newBal).toFixed(4).toString().split('.')

        return <BalanceText className='user-token-value'>{commaFy(balArr[0])}.<span>{balArr[1]}</span></BalanceText>
    }

    const renderUserBalance = () => {
        return (
            <BalanceWrapper className='user-token-cont'>
                <BalanceCont className='user-token-balance'>
                    <img className='token-icon' src={AQUAIcon} alt="" />
                    <CardText className="token-bal-text" >AQUA Balance</CardText>
                    {portfolioLoading ? <LoadingText1 /> : _renderBalFormat(userPortfolio.userAquaBalance)}
                    <CardButton className="aqua-buy-btn" onClick={() => handleBuyAqua()}>Buy</CardButton>
                </BalanceCont>
                <BalanceCont className='user-token-balance'>
                    <img className='token-icon' src={GAMMAIcon} alt="" />
                    <CardText className="token-bal-text">GAMMA Balance</CardText>
                    {portfolioLoading ? <LoadingText1 /> : _renderBalFormat(userPortfolio.userGammaBalance)}
                    <CardButton2 className="gamma-buy-btn" onClick={() => goToSwap()}>Buy</CardButton2>
                </BalanceCont>
            </BalanceWrapper>
        )
    }
    return (
        <MainContainer className='base'>
            { isConnected ?
            <BaseContainer>
                <BaseHeader>
                    <div>
                        <TitleText className='baseTitle'>Welcome to Planet!</TitleText>
                        <TitleInfo className="title-info">Decentralize The Planet’s Finances</TitleInfo>
                    </div>
                    <div className="on-web">
                        <ValueLocked {...props} />
                    </div>

                </BaseHeader>
                <UserInfoCont className='overview-section'>
                    <Card className='overviewCard'>{renderOverView()}</Card>
                    <Card className="user-balance-card on-web">{renderUserBalance()}</Card>
                    <Card className='projection-card'>
                        <Projection portfolioLoading={portfolioLoading} userPortfolio={userPortfolio} />
                    </Card>
                </UserInfoCont>
                <HighestApySection apyArray={highestApyArr} gammaBtcbPool={gammaBtcbPool}  />
                <MobBalWrapper>
                    <MobBalCard>
                        <MobBuyAssetCont>
                            <img src={AQUAIcon} alt="" />
                            <CardButton onClick={() => handleBuyAqua()}>Buy</CardButton>
                        </MobBuyAssetCont>
                        {platformLoading ?
                            <LoadingText2 /> :
                            <MobBalText>${!!platform_data && platform_data.aqua_price ? commaFy(parseFloat(platformData.aqua_price).toFixed(2)) : '0.00'}</MobBalText>
                        }
                        {portfolioLoading ?
                            <LoadingText2 /> :
                            <CardText className='total-token'>{`${!!userPortfolio && userPortfolio.userAquaBalance ? commaFy(parseFloat(userPortfolio.userAquaBalance).toFixed(4)) : '0.000'} AQUA`}</CardText>
                        }
                        {portfolioLoading ?
                            <LoadingText2 /> :
                            <MobBalText tokenType='aqua'>${commaFy(parseFloat(userPortfolio.userAquaBalanceUSD).toFixed(2))}</MobBalText>
                        }
                    </MobBalCard>
                    <MobBalCard>
                        <MobBuyAssetCont>
                            <img src={GAMMAIcon} alt="" />
                            <CardButton2 onClick={() => goToSwap()}>Buy</CardButton2>
                        </MobBuyAssetCont>
                        {platformLoading ?
                            <LoadingText2 /> :
                            <MobBalText>${!!platform_data && platform_data.gamma_price ? commaFy(parseFloat(platformData.gamma_price).toFixed(3)) : '0.00'}</MobBalText>
                        }
                        {portfolioLoading ?
                            <LoadingText2 /> :
                            <CardText className='total-token'>{`${!!userPortfolio && userPortfolio.userGammaBalance ? commaFy(parseFloat(userPortfolio.userGammaBalance).toFixed(4)) : '0.000'} GAMMA`}</CardText>
                        }
                        {portfolioLoading ?
                            <LoadingText2 /> :
                            <MobBalText tokenType='gamma'>${commaFy(parseFloat(userPortfolio.userGammaBalanceUSD).toFixed(2))}</MobBalText>
                        }
                    </MobBalCard>
                    
                </MobBalWrapper>
                <div className="on-mob">
                    <ValueLocked {...props} />
                </div>
                <PlanetToken platformLoading={platformLoading} platformData={platformData} />
                
                <GammaBoost />
                {/* <PlanetActions /> */}
                <PlanetNews /> 
                
            </BaseContainer>
            :
            <BaseContainer>
                <BaseHeader>
                    <div>
                        <TitleText className='baseTitle'>Welcome to Planet!</TitleText>
                        <TitleInfo className="title-info">Decentralize The Planet’s Finances</TitleInfo>
                    </div>
                    <div className="on-web">
                        <ValueLocked {...props} />
                    </div>

                </BaseHeader>
                
                <div>{renderWalletConnect()}</div>
                <div className="on-mob">
                    <ValueLocked {...props} />
                </div>
                <HighestApySection apyArray={highestApyArr} gammaBtcbPool={gammaBtcbPool} />
                <GammaBoost />
                <PlanetToken platformLoading={platformLoading} platformData={platformData} />
                <PlanetNews /> 
                <CustomModal show={openWalletList} toggleModal={setOpenWalletList}>
                    <WalletList setOpenModal={setOpenWalletList} />
                </CustomModal>
            </BaseContainer>
            }
        </MainContainer>
    );
};

const mapStateToProps = (state: any) => ({
    portfolioLoading: state.user.portfolioLoading,
    userPortfolio: state.user.userPortfolio,
    platformLoading: state.user.platformLoading,
    platformData: state.user.platformData,
})
export default connect(mapStateToProps)(Base)