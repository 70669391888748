import { FlexCont, CardButton, CardButton2, CardButton3 } from 'shared/styles/styled'
import { CardTitle, CardSubText } from 'shared/styles/globalText'
import { LoadingText2 } from '../style'
import { PlanetTokenCont, TokenTypeText, TokenCard, TokenInfoCont, TokenValText, TokenBtnGroup, PlanetTokenWrapper } from './style'
import { commaFy } from 'service/globalFunctions'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import MarketIcon from 'assets/icons/market_cap.png'
import AquaMarketIcon from 'assets/icons/aqua_market_cap.png'
import BurnedIcon from 'assets/icons/aqua_burned_icon.png'
import TreasuryIcon from 'assets/icons/treasury.png'
import MintIcon from 'assets/icons/daily_mint.png'
import ReserveIcon from 'assets/icons/reserve.png'
import { aquaChainLink, gammaChainLink } from 'service/global-constant'
import { Paths } from 'modules/app/components/routes/types'
import history from 'modules/app/components/history'
import gamma_coin from 'assets/icons/gamma-coin.png'
import aqua_coin from 'assets/icons/aqua-coin.png'

const PlanetToken = (props: any) => {
    const { platformLoading, platformData } = props

    const handleSeeMetrics = (tokenName: string) => {
        history.push(Paths.metrics, tokenName)
    }

    return (
        <PlanetTokenWrapper>
            <CardTitle className="cardTitle">Planet's Native Tokens</CardTitle>
            <PlanetTokenCont>
                <TokenCard className='gamma-cont'>
                    <img className='coin-image gamma-coin-img' src={gamma_coin} alt="" />
                    <CardTitle className='token-title'>GAMMA</CardTitle>
                    <TokenTypeText>The Utility Token</TokenTypeText>
                    <TokenInfoCont>
                        <FlexCont>
                            <img className='info-icon' src={GAMMAIcon} alt="" />
                            <div>
                                {platformLoading ? <LoadingText2 /> :
                                    <TokenValText>
                                        {!!platformData.gamma_circulatingsupply ? commaFy(parseFloat(platformData.gamma_circulatingsupply).toFixed(2)) : '0.00'}
                                    </TokenValText>
                                }
                                <CardSubText className='subtext'>Circulating Supply</CardSubText>
                                
                            </div>
                        </FlexCont>
                        <FlexCont>
                            <img className='info-icon' src={MarketIcon} alt="" />
                            <div>
                                {platformLoading ? <LoadingText2 /> :
                                    <TokenValText>
                                        ${!!platformData.gamma_marketcap ? commaFy(parseFloat(platformData.gamma_marketcap).toFixed(2)) : '0.00'}
                                    </TokenValText>
                                }
                                <CardSubText className='subtext'>Market Cap</CardSubText>
                                
                            </div>
                        </FlexCont>
                        <FlexCont>
                            <img className='info-icon' src={MintIcon} alt="" />
                            <div>
                                <TokenValText>100,000</TokenValText>
                                <CardSubText className='subtext'>Daily Mint</CardSubText>
                                
                            </div>
                        </FlexCont>
                        <FlexCont>
                            <img className='info-icon' src={ReserveIcon} alt="" />
                            <div>
                                {platformLoading ? <LoadingText2 /> :
                                    <TokenValText>
                                        ${!!platformData.reserve ? commaFy(parseFloat(platformData.reserve).toFixed(2)) : '0.00'}
                                    </TokenValText>
                                }
                                <CardSubText className='subtext'>Reserve</CardSubText>
                            </div>
                        </FlexCont>
                    </TokenInfoCont>
                </TokenCard>
                <TokenCard className='aqua-cont'>
                    <img className='coin-image aqua-coin-img' src={aqua_coin} alt="" />
                    <CardTitle className='token-title'>AQUA</CardTitle>
                    <TokenTypeText type="aqua">The Governance Token</TokenTypeText>
                    <TokenInfoCont>
                        <FlexCont>
                            <img className='info-icon' src={AQUAIcon} alt="" />
                            <div>
                                {platformLoading ? <LoadingText2 /> :
                                    <TokenValText>
                                        {!!platformData.aqua_circulatingsupply ? commaFy(parseFloat(platformData.aqua_circulatingsupply).toFixed(2)) : '0.00'}
                                    </TokenValText>
                                }
                                <CardSubText className='subtext'>Circulating Supply</CardSubText>
                                
                            </div>
                        </FlexCont>
                        <FlexCont>
                            <img className='info-icon' src={AquaMarketIcon} alt="" />
                            <div>
                                {platformLoading ? <LoadingText2 /> :
                                    <TokenValText>
                                        ${!!platformData.aqua_marketcap ? commaFy(parseFloat(platformData.aqua_marketcap).toFixed(2)) : '0.00'}
                                    </TokenValText>
                                }
                                <CardSubText className='subtext'>Market Cap</CardSubText>
                                
                            </div>
                        </FlexCont>
                        <FlexCont>
                            <img className='info-icon' src={BurnedIcon} alt="" />
                            <div>
                                {platformLoading ? <LoadingText2 /> :
                                    <TokenValText>
                                        ${!!platformData.aqua_burned_usd ? commaFy(parseFloat(platformData.aqua_burned_usd).toFixed(2)) : '0.00'}
                                    </TokenValText>
                                }
                                <CardSubText className='subtext'>AQUA Burned (USD)</CardSubText>
                                
                            </div>
                        </FlexCont>
                        <FlexCont>
                            <img className='info-icon' src={TreasuryIcon} alt="" />
                            <div>
                                {platformLoading ? <LoadingText2 /> :
                                    <TokenValText>
                                        ${!!platformData.treasury ? commaFy(parseFloat(platformData.treasury).toFixed(2)) : '0.00'}
                                    </TokenValText>
                                }
                                <CardSubText className='subtext'>Treasury</CardSubText>
                                
                            </div>
                        </FlexCont>
                    </TokenInfoCont>
                </TokenCard>
                
            </PlanetTokenCont>
        </PlanetTokenWrapper>
    );
};

export default PlanetToken