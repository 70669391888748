import { useState, useEffect, Fragment, useRef, useContext } from 'react'
import { CardTitle, CardSubText } from 'shared/styles/globalText'
import { LoadingText2 } from '../style'
import { NewsWrapper, PlanetTokenCont, TokenTypeText, TokenCard, TokenInfoCont, TokenValText, TokenBtnGroup, PlanetTokenWrapper } from './style'
import { commaFy } from 'service/globalFunctions'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import MarketIcon from 'assets/icons/market_cap.png'
import AquaMarketIcon from 'assets/icons/aqua_market_cap.png'
import BurnedIcon from 'assets/icons/aqua_burned_icon.png'
import TreasuryIcon from 'assets/icons/treasury.png'
import MintIcon from 'assets/icons/daily_mint.png'
import ReserveIcon from 'assets/icons/reserve.png'
import { aquaChainLink, gammaChainLink } from 'service/global-constant'
import { Paths } from 'modules/app/components/routes/types'
import history from 'modules/app/components/history'
import gamma_coin from 'assets/icons/gamma-coin.png'
import aqua_coin from 'assets/icons/aqua-coin.png'
import { FlexSBCont, FlexCont, CardButton2 } from 'shared/styles/styled'
import { Table, TableHeader, TableBody, MobTableBody, TableRowCont, TableRow, CellCont, HeadTextCont, HeadTextData, HeadText, CellText, CellSmText, TwoImgCont, ArrowIconCont, MobArrowIconCont, LoadingText1, TableInfoBox } from 'shared/styles/planetTableStyle'
import inf_boost_icon from 'assets/icons/inf_boost_icon.png'
import gammaIcon from 'assets/icons/gamma-icon.png'

const GammaBoost = () => {

    return (
        <Fragment>
            <NewsWrapper className='newsCont'>
            <CardTitle className="cardTitle">Earn More With <span>Gamma Boost</span>!</CardTitle>
                <FlexSBCont className='boost-in-base'>
                    <TableInfoBox>
                            <FlexSBCont className='boostContBase boostContGamma'>
                                <FlexCont className='boostTextCont gammaTextCont'>
                                    <HeadText className='boostText margin'>
                                        <span>Simply Supply to the GAMMA-BTCB Pool</span>
                                    </HeadText>
                                    <HeadText className='boostText'>
                                    Your <span>APY will increase</span> ACROSS ALL Planets Pools!
                                    </HeadText>
                                </FlexCont>
                                <FlexCont className='boostTextCont boostImgTextCont gammaBuyCont'>
                                    <img src={gammaIcon} className='gamma_icon' />
                                    <CardButton2 className="calculateBoost gammaBuy" onClick={() => history.push(`${Paths.swap}`)}>Buy GAMMA</CardButton2>
                                </FlexCont>
                            </FlexSBCont>
                        </TableInfoBox>
                    {/* <TableInfoBox>
                        <FlexSBCont className='boostContBase'>
                            <FlexCont className='boostTextCont textCont'>
                                <HeadText className='boostText margin'>
                                    <span>Not sure how much more you’ll earn with Boost?</span>
                                </HeadText>
                                <HeadText className='boostText'>
                                    Check out our handy <span>Boost Calculator!</span>
                                </HeadText>
                            </FlexCont>
                            <FlexCont className='boostTextCont boostImgTextCont'>
                                <img src={inf_boost_icon} className='inf_boost_icon' />
                                <CardButton2 onClick={() => history.push(`${Paths.boost}`)} className="calculateBoost">Calculate Boost</CardButton2>
                            </FlexCont>
                        </FlexSBCont>
                    </TableInfoBox> */}
                    
                </FlexSBCont>
            </NewsWrapper>
        </Fragment>
    );
};

export default GammaBoost