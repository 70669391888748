import axios from 'axios'
import {
  bnbAddress,
  BUSD_BNBLPAddress,
  gammatrollerAddress,
  discountLevelAddress,
  enterMarketTopic0,
  exitMarketTopic0,
  gammaAddress,
  gBnbAddress,
  gGamma,
  instType,
  level1Discount,
  level2Discount,
  level3Discount,
  selectInstance,
  approvalAmount,
  MAX_INT,
  convertToEther,
  noExponents,
  convertToWei,
  liquidationChecker,
  AQUA_BNBLPAddress,
  GAMMA_BNBLPAddress,
  gTokenAbi,
  priceOracleAddress,
  gammatrollerAbi,
  firstTimeGammaDrippedBlock,
  deadAddress,
  oldgGamma,
  apiKey
} from './lendingAbi'
import { aquaAddress, multicall_abi, multicall_address, priceOracleAbi, gamma_reservoir } from './abi'
import { balanceOf, getTokenYield } from './BlockChainMethods'
import { zeroAddress } from './SwapDexAbi'
import {
  balanceOfGtoken,
  borrowBalanceStored,
  exchangeRateStored,
  getAccountSnapshot,
  totalBorrows,
  underlying,
  allowance,
  supplyRatePerBlock,
  borrowRatePerBlock,
  borrowIndex,
  totalSupply,
  getCash,
  symbol,
  findLargest3,
  interestRateModel,
  totalReserves,
  decimals,
} from './gBNB'
import {
  borrowCaps,
  closeFactorMantissa,
  gammaAccrued,
  gammaBorrowerIndex,
  gammaBorrowState,
  gammaInitialIndex,
  gammaSpeeds,
  gammaSupplierIndex,
  gammaSupplyState,
  getAccountLiquidity,
  getAllMarkets,
  getAssetsIn,
  getBlockNumber,
  getHypotheticalAccountLiquidity,
  liquidateCalculateSeizeTokens,
  liquidationIncentiveMantissa,
  markets,
} from './gammatroller'
import { bluePfTokenList, pfTokenList, redPfTokenList, marketTokenList } from './pfTokenList'
import { getUnderlyingPrice } from './priceOracle'
import wallet from 'modules/wallet/wallet'
import { getTokenIcon } from 'shared/tokenIconList'
import { utilizationRate } from './interestRateModel'
import { planetFinanceApiBaseUrl } from "service/global-constant";
import planetGlobalObject from 'global/GlobalVar'

export const getMarketLiquidity = async (gTokenAddress: string) => {
  try {
    if (gTokenAddress === gBnbAddress) {
      const bal = await wallet.web3.eth.getBalance(gTokenAddress)
      return bal
    } else {
      const underlyingToken = await underlying(gTokenAddress)
      const bal = await balanceOfGtoken(gTokenAddress, underlyingToken)
      return bal
    }
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getUnderlyingDecimal = async (gTokenAddress: string) => {
  const decimal = await decimals(await underlying(gTokenAddress));
  return decimal;
}

export const differenceApy = async (gTokenAddress: string) => {
  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365

    const _borrowRatePerBlock = convertToEther(await borrowRatePerBlock(gTokenAddress), 18)
    const _supplyRatePerBlock = convertToEther(await supplyRatePerBlock(gTokenAddress), 18)
    const x = (Math.pow(_borrowRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100
    const y = (Math.pow(_supplyRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100
    let Apy = x - y
    return Apy;
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getBorrowApy = async (gTokenAddress: string, userAddress: string, blockNumber?: number) => {
  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365

    const _borrowRatePerBlock = convertToEther(await borrowRatePerBlock(gTokenAddress, blockNumber), 18)
    let borrowApy = (Math.pow(_borrowRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100

    const totalBorrows_ = convertToEther(await totalBorrows(gTokenAddress, blockNumber), await getUnderlyingDecimal(gTokenAddress));

    if (userAddress !== null && userAddress !== "" && (gTokenAddress.toLowerCase() !== gGamma.toLowerCase()) &&
      totalBorrows_ > 0) {
      const levelData = await getDiscountLevel(userAddress);
      const discount = (levelData.discount) / 2;
      const diff = await differenceApy(gTokenAddress)
      borrowApy -= (discount * diff) / 100
    }
    return borrowApy * (-1)
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getSupplyApy = async (gTokenAddress: string, userAddress: string, blockNumber?: number) => {
  userAddress = ""
  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365

    const _supplyRatePerBlock = convertToEther(await supplyRatePerBlock(gTokenAddress, blockNumber), 18)
    let supplyApy = (Math.pow(_supplyRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100

    // const totalBorrows_ = convertToEther(await totalBorrows(gTokenAddress, blockNumber), 18);

    // if (userAddress !== null && userAddress !== "" && (gTokenAddress !== pfTokenList[1].address)
    //   && totalBorrows_ > 0) {

    //   const levelData = await getDiscountLevel(userAddress);
    //   const discount = (levelData.discount) / 2;
    //   const diff = await differenceApy(gTokenAddress)
    //   supplyApy += (discount * diff) / 100

    // }
    return supplyApy
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getGammaBorrowApy = async (gTokenAddress: string) => {
  try {
    const blocksPerDay = 28800 // 3 seconds per block
    const daysPerYear = 365
    const gammaPrice = await getTokenPrice(gGamma)
    const gammaPerDay = parseFloat(convertToEther(await gammaSpeeds(gTokenAddress, gammatrollerAddress), 18)) * blocksPerDay
    const _totalBorrows = parseFloat(convertToEther(await totalBorrows(gTokenAddress), await getUnderlyingDecimal(gTokenAddress)))
    const assetPrice = await getTokenPrice(gTokenAddress)
    const borrowApy: any = 100 * (Math.pow(1 + (gammaPrice * gammaPerDay) / (_totalBorrows * assetPrice), daysPerYear) - 1)
    //console.log("net borrow apy",gTokenAddress,borrowApy)
    return borrowApy
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getGammaSupplyApy = async (gTokenAddress: string) => {
  try {
    const blocksPerDay = 28800 // 3 seconds per block
    const daysPerYear = 365
    const gammaPrice = await getTokenPrice(gGamma)
    const gammaPerDay = parseFloat(convertToEther(await gammaSpeeds(gTokenAddress, gammatrollerAddress), 18)) * blocksPerDay
    const _totalSupply = parseFloat(convertToEther(await totalSupply(gTokenAddress), 8))
    const decimal = parseFloat(await getUnderlyingDecimal(gTokenAddress)) + 10;
    const exchangerate = parseFloat(convertToEther(await exchangeRateStored(gTokenAddress), decimal))
    const totalSupply_ = _totalSupply * exchangerate
    const assetPrice = await getTokenPrice(gTokenAddress)
    const supplyApy: any = totalSupply_ !== 0 ? 100 * (Math.pow(1 + (gammaPrice * gammaPerDay) / (totalSupply_ * assetPrice), daysPerYear) - 1) : 0
    //console.log("net supply apy",gTokenAddress,supplyApy)
    return supplyApy
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getNetApy = async (userAddress: string): Promise<any> => {
  try {
    const assetsLen = Object.keys(pfTokenList).length
    let apyWithoutGamma = 0
    let apyWithGamma = 0
    for (let i = 0; i < assetsLen; i++) {
      const asset = pfTokenList[i].address
      const price = await getTokenPrice(asset)
      const supplyBal = getSupplyBalance(asset, userAddress)
      const borrowBal = getBorrowBalance(asset, userAddress)
      const supplyApy = getSupplyApy(asset, userAddress)
      const borrowApy = getBorrowApy(asset, userAddress)
      const gammaSupplyApy = getGammaSupplyApy(asset)
      const gammaBorrowApy = getGammaBorrowApy(asset)
      const promArr: any = await Promise.all([supplyBal, borrowBal, supplyApy, borrowApy, gammaSupplyApy, gammaBorrowApy])
      apyWithoutGamma +=
        (parseFloat(promArr[0]) * price * parseFloat(promArr[2])) / 100 - (parseFloat(promArr[1]) * price * parseFloat(promArr[3])) / 100

      const netSupplyApy = (parseFloat(promArr[0]) * parseFloat(price) * parseFloat(promArr[2]) / 100 + (parseFloat(promArr[0]) * parseFloat(price) * parseFloat(promArr[4]) / 100))
      const netBorrowApy = (parseFloat(promArr[1]) * parseFloat(price) * parseFloat(promArr[3]) / 100 + (parseFloat(promArr[1]) * parseFloat(price) * parseFloat(promArr[5]) / 100))

      const val = (netSupplyApy - netBorrowApy)
      if (!isNaN(val)) {
        apyWithGamma += val
      }


      if (i === assetsLen - 1) {
        if (apyWithGamma > 0 || apyWithoutGamma > 0) {
          const bal: any = (await getTotalSupplyBalance(userAddress)).total
          return {
            apyWithoutGamma: bal > 0 ? 100 * (apyWithoutGamma / bal) : 0,
            apyWithGamma: bal > 0 ? 100 * (apyWithGamma / bal) : 0,
          }
        } else if (apyWithGamma < 0 || apyWithoutGamma < 0) {
          const bal: any = await getTotalBorrowBalance(userAddress)
          return {
            apyWithoutGamma: bal > 0 ? 100 * (apyWithoutGamma / bal) : 0,
            apyWithGamma: bal > 0 ? 100 * (apyWithGamma / bal) : 0,
          }
        } else {
          return {
            apyWithoutGamma: 0,
            apyWithGamma: 0,
          }
        }
      }
    }
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getSupplyBalance = async (gTokenAddress: string, userAddress: string) => {
  try {
    const data = await getAccountSnapshot(userAddress, gTokenAddress)
    const result = convertToEther(data[1], await getUnderlyingDecimal(gTokenAddress)) * convertToEther(data[3], 18)
    return result
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getBorrowBalance = async (gTokenAddress: string, userAddress: string) => {
  try {
    const data = await borrowBalanceStored(userAddress, gTokenAddress)
    const result = convertToEther(data, await getUnderlyingDecimal(gTokenAddress))
    return result
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getCurrentBalance = async (gTokenAddress: string, userAddress: string) => {
  try {
    const bal = convertToEther(await balanceOfGtoken(userAddress, gTokenAddress), await getUnderlyingDecimal(gTokenAddress))
    const exchangeRate = convertToEther(await exchangeRateStored(gTokenAddress), 18)
    const result = bal * exchangeRate
    return result
  } catch (e) {
    console.log(e)
    return 0
  }
}

const getReserves = async (tokenAddress: string, blockNumber?: number) => {
  try {
    // console.log("tokenAddress inside getReserves", tokenAddress);
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = blockNumber === undefined ? await tokenInstance.methods.getReserves().call() :
    await tokenInstance.methods.getReserves().call() // call(undefined, blockNumber)
    return data
    
  } catch (e) {
    console.log(tokenAddress, e)
    return {
      0: 0,
      1: 0,
      2: 0,
      _reserve0: 0,
      _reserve1: 0,
      _blockTimestampLast: 0,
    }
  }
}

export const getBnbPrice = async (blockNumber?: number) => {
  const reserves = await getReserves(BUSD_BNBLPAddress, blockNumber)
  const getBNBReserve = reserves._reserve0
  const getUSDReserve = reserves._reserve1
  const bnbPrice = getUSDReserve / getBNBReserve
  return bnbPrice
}

const getPair = async (factoryAddress: string, token1: string, token2: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.FACTORY, factoryAddress)
    const data = await tokenInstance.methods.getPair(token1, token2).call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}
export const token1 = async (tokenAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.token0().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const getTokenPrice = async (tokenAddress: string, blockNumber?: number) => {
  const decimal = 36 - (await getUnderlyingDecimal(tokenAddress));
  const priceInBnb = convertToEther(await getUnderlyingPrice(tokenAddress, blockNumber), decimal)
  return priceInBnb

}

export const getGTokenPrice = async (tokenAddress: string, blockNumber?: number) => {
  const underlyingPrice = await getTokenPrice(tokenAddress, blockNumber)
  const exchangeRate = await exchangeRateStored(tokenAddress, blockNumber)
  const decimal = parseFloat(await getUnderlyingDecimal(tokenAddress)) + 10;
  const oneGTokenInUnderlying = convertToEther(exchangeRate, decimal)
  return parseFloat(oneGTokenInUnderlying) * underlyingPrice
}

export const getTotalSupplyBalance = async (userAddress: string) => {
  try {
    const assetsLen = Object.keys(pfTokenList).length
    let res = 0
    let suppliedAmountWithCollateral = 0
    let suppliedAmountWithoutCollateral = 0
    let out: any = {}
    for (let i = 0; i < assetsLen; i++) {
      const element = pfTokenList[i].address
      const price = await getTokenPrice(element)
      const suppliedBal = (await getSupplyBalance(element, userAddress)) * price
      res += suppliedBal
      if (await isGivenAsCollateral(element, userAddress)) {
        suppliedAmountWithCollateral += suppliedBal
      }
      suppliedAmountWithoutCollateral += suppliedBal
      out[element] = suppliedBal
      if (i === assetsLen - 1) {
        out['suppliedAmountWithoutCollateral'] = suppliedAmountWithoutCollateral
        out['suppliedAmountWithCollateral'] = suppliedAmountWithCollateral
        out['total'] = res
        return out
      }
    }
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getTotalSupplyBalanceIncludingCollateral = async (userAddress: string) => {
  try {
    const assetsIn = await getAssetsIn(userAddress, gammatrollerAddress)
    let res = 0
    for (let i = 0; i < assetsIn.length; i++) {
      const element = assetsIn[i]
      const market = await markets(element, gammatrollerAddress)
      const collateralFactor = convertToEther(market.collateralFactorMantissa, 18)
      const price = await getTokenPrice(element)
      const suppliedBal = (await getSupplyBalance(element, userAddress)) * price
      res += (collateralFactor / 100) * suppliedBal
      if (i === assetsIn.length - 1) {
        return res * 100
      }
    }
    return 0
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getTotalBorrowBalance = async (userAddress: string) => {
  try {
    const assetsIn = await getAssetsIn(userAddress, gammatrollerAddress)
    let totalBorrowedAmount = 0
    for (let i = 0; i < assetsIn.length; i++) {
      const element = assetsIn[i]
      const underlyingToken = await underlying(element)
      const price = await getTokenPrice(element)
      totalBorrowedAmount += (await getBorrowBalance(element, userAddress)) * price
      if (i === assetsIn.length - 1) {
        return totalBorrowedAmount
      }
    }
    return 0
  } catch (e) {
    console.log(e)
    return 0
  }
}

enum Type {
  supply = 1,
  withdraw = 2,
  borrow = 3,
  repay = 4,
}

export const getBorrowLimitParamsOnChange = async (
  isUsingAsCollateral: boolean,
  gTokenAddress: string,
  type: number,
  amountGivenInEth: number,
  userCurrentBorrowLimitInUsd: number,
  userTotalBorrowBal: number,
) => {
  switch (type) {

    case Type.supply: {

      if (isUsingAsCollateral) {

        let newBorrowLimit = 0
        newBorrowLimit = (userCurrentBorrowLimitInUsd) +
          amountGivenInEth * (await getTokenPrice(gTokenAddress)) *
          parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))

        return {
          borrowLimit: newBorrowLimit,
          usedBorrowLimit: newBorrowLimit > 0 ? userTotalBorrowBal / newBorrowLimit : 0,
          usedBorrowLimitInPercentage: newBorrowLimit > 0 ? (userTotalBorrowBal / newBorrowLimit) * 100 : 0,
        }

      }
      else {

        return {
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: userTotalBorrowBal / userCurrentBorrowLimitInUsd,
          usedBorrowLimitInPercentage: (userTotalBorrowBal / userCurrentBorrowLimitInUsd) * 100,
        }

      }

    }

    case Type.withdraw: {

      if (isUsingAsCollateral) {

        let newBorrowLimit = 0

        let newDiff = amountGivenInEth * (await getTokenPrice(gTokenAddress)) *
          parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))

        newBorrowLimit = newDiff < userCurrentBorrowLimitInUsd ?
          (userCurrentBorrowLimitInUsd) - newDiff : 0


        return {
          borrowLimit: newBorrowLimit,
          usedBorrowLimit: newBorrowLimit > 0 ? userTotalBorrowBal / newBorrowLimit : 0,
          usedBorrowLimitInPercentage: newBorrowLimit > 0 ? (userTotalBorrowBal / newBorrowLimit) * 100 : 0,
        }

      }
      else {

        return {
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: userTotalBorrowBal / userCurrentBorrowLimitInUsd,
          usedBorrowLimitInPercentage: (userTotalBorrowBal / userCurrentBorrowLimitInUsd) * 100,
        }

      }

    }

    case Type.borrow: {

      if (userCurrentBorrowLimitInUsd > 0) {

        let newTotalBorrowBalance = userTotalBorrowBal + amountGivenInEth * (await getTokenPrice(gTokenAddress))

        newTotalBorrowBalance = newTotalBorrowBalance >= userCurrentBorrowLimitInUsd ? userCurrentBorrowLimitInUsd : newTotalBorrowBalance

        return {
          usedBorrowLimit: newTotalBorrowBalance > 0 ? (newTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 0.9 : 0,
          usedBorrowLimitInPercentage: newTotalBorrowBalance > 0 ? (newTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 0.9 * 100 : 0,
        }

      }
      else {

        return {
          usedBorrowLimit: 0,
          usedBorrowLimitInPercentage: 0,
        }

      }

    }

    case Type.repay: {

      if (userCurrentBorrowLimitInUsd > 0) {

        let newTotalBorrowBalance = userTotalBorrowBal - amountGivenInEth * (await getTokenPrice(gTokenAddress))

        newTotalBorrowBalance = newTotalBorrowBalance <= 0 ? 0 : newTotalBorrowBalance

        return {
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: newTotalBorrowBalance > 0 ? newTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: newTotalBorrowBalance > 0 ? (newTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }

      }
      else {

        return {
          usedBorrowLimit: 0,
          usedBorrowLimitInPercentage: 0,
        }

      }
    }

    default:
      return null
  }
}

export const getParamsWhenBorrowLimitIsChanged = async (
  isUsingAsCollateral: boolean,
  gTokenAddress: string,
  type: number,
  userCurrentBorrowLimitInUsd: number,
  userTotalBorrowBalance: number,
  usedBorrowLimit: number,
  userAddress: string
) => {

  switch (type) {

    case Type.supply: {

      usedBorrowLimit = usedBorrowLimit > 0 ? usedBorrowLimit : 0.0001

      if (isUsingAsCollateral) {

        let newBorrowLimit = 0

        newBorrowLimit = userTotalBorrowBalance / usedBorrowLimit

        let amountGivenInEth = (newBorrowLimit - userCurrentBorrowLimitInUsd) /
          (await getTokenPrice(gTokenAddress)) *
          parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))

        return {
          amountGivenInEth: amountGivenInEth,
          amountGivenInUsd: amountGivenInEth * (await getTokenPrice(gTokenAddress)),
          borrowLimit: newBorrowLimit,
          usedBorrowLimit: newBorrowLimit > 0 ? userTotalBorrowBalance / newBorrowLimit : 0,
          usedBorrowLimitInPercentage: newBorrowLimit > 0 ? (userTotalBorrowBalance / newBorrowLimit) * 100 : 0,
        }

      }
      else {

        return {
          amountGivenInEth: 0,
          amountGivenInUsd: 0,
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: userCurrentBorrowLimitInUsd > 0 ? userTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: userCurrentBorrowLimitInUsd > 0 ? (userTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }

      }

    }

    case Type.withdraw: {

      if (isUsingAsCollateral) {



        let newTotalBorrowLimit = 0

        usedBorrowLimit = usedBorrowLimit >= 1 ? 1 : usedBorrowLimit

        let newDiff = userCurrentBorrowLimitInUsd - (userTotalBorrowBalance / usedBorrowLimit)

        newTotalBorrowLimit = (userCurrentBorrowLimitInUsd) - newDiff

        let amountGivenInEth = newDiff /
          (await getTokenPrice(gTokenAddress)) *
          parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))

        return {
          amountGivenInEth: amountGivenInEth,
          amountGivenInUsd: amountGivenInEth * (await getTokenPrice(gTokenAddress)),
          borrowLimit: newTotalBorrowLimit,
          usedBorrowLimit: newTotalBorrowLimit > 0 ? userTotalBorrowBalance / newTotalBorrowLimit : 0,
          usedBorrowLimitInPercentage: newTotalBorrowLimit > 0 ? (userTotalBorrowBalance / newTotalBorrowLimit) * 100 : 0,
        }

      }
      else {

        return {
          amountGivenInEth: 0,
          amountGivenInUsd: 0,
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: userCurrentBorrowLimitInUsd > 0 ? userTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: userCurrentBorrowLimitInUsd > 0 ? (userTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }

      }

    }

    case Type.borrow: {



      let amountGivenInEth = usedBorrowLimit * userCurrentBorrowLimitInUsd - userTotalBorrowBalance
      amountGivenInEth = amountGivenInEth <= 0 ? 0 : amountGivenInEth
      amountGivenInEth = amountGivenInEth / (await getTokenPrice(gTokenAddress))

      if (userCurrentBorrowLimitInUsd > 0) {

        let newTotalBorrowBalance = userTotalBorrowBalance + amountGivenInEth

        newTotalBorrowBalance = newTotalBorrowBalance >= userCurrentBorrowLimitInUsd ? userCurrentBorrowLimitInUsd : newTotalBorrowBalance

        return {
          amountGivenInEth: amountGivenInEth,
          amountGivenInUsd: amountGivenInEth * (await getTokenPrice(gTokenAddress)),
          usedBorrowLimit: userCurrentBorrowLimitInUsd > 0 ? newTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: userCurrentBorrowLimitInUsd > 0 ? (newTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }

      }
      else {

        return {
          amountGivenInEth: 0,
          amountGivenInUsd: 0,
          usedBorrowLimit: userCurrentBorrowLimitInUsd > 0 ? userTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: userCurrentBorrowLimitInUsd > 0 ? (userTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }

      }

    }

    case Type.repay: {



      if (userCurrentBorrowLimitInUsd > 0) {

        let amountGivenInEth = userTotalBorrowBalance - usedBorrowLimit * userCurrentBorrowLimitInUsd
        amountGivenInEth = amountGivenInEth / (await getTokenPrice(gTokenAddress))

        let newTotalBorrowBalance = userTotalBorrowBalance - amountGivenInEth

        newTotalBorrowBalance = newTotalBorrowBalance <= 0 ? 0 : newTotalBorrowBalance

        return {
          amountGivenInEth: amountGivenInEth,
          amountGivenInUsd: amountGivenInEth * (await getTokenPrice(gTokenAddress)),
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: userCurrentBorrowLimitInUsd > 0 ? newTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: userCurrentBorrowLimitInUsd > 0 ? (newTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }

      }
      else {

        return {
          amountGivenInEth: 0,
          amountGivenInUsd: 0,
          borrowLimit: userCurrentBorrowLimitInUsd,
          usedBorrowLimit: userCurrentBorrowLimitInUsd > 0 ? userTotalBorrowBalance / userCurrentBorrowLimitInUsd : 0,
          usedBorrowLimitInPercentage: userCurrentBorrowLimitInUsd > 0 ? (userTotalBorrowBalance / userCurrentBorrowLimitInUsd) * 100 : 0,
        }

      }

    }

    default:
      return null
  }
}

export const getBorrowAndUsedLimit = async (userAddress: string) => {
  try {
    const suppliedWithCollateral: any = await getTotalSupplyBalanceIncludingCollateral(userAddress)
    const totalBorrowedAmount: any = await getTotalBorrowBalance(userAddress)
    return {
      totalBorrowed: totalBorrowedAmount,
      borrowLimit: suppliedWithCollateral,
      usedBorrowLimit: suppliedWithCollateral > 0 ? totalBorrowedAmount / suppliedWithCollateral : 0,
      usedBorrowLimitInPercentage: suppliedWithCollateral > 0 ? (totalBorrowedAmount / suppliedWithCollateral) * 100 : 0,
    }
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const isGivenAsCollateral = async (gTokenAddress: string, userAddress: string) => {
  const assetIn = await getAssetsIn(userAddress, gammatrollerAddress)
  const index: any = assetIn.includes(gTokenAddress)
  const market = await markets(gTokenAddress, gammatrollerAddress)
  const collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
  const isAvailForCollateral = collateralFactor > 0 ? true : false
  return isAvailForCollateral && index ? true : false
}

export const getPendingRewardsUsingMulticall = async(userAddress:any) => {
  
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address);

  const gammatroller_inst  = await selectInstance(instType.gammatroller,gammatrollerAddress);

  const asset_inst = await selectInstance(instType.gToken,gGamma);

  //Supply side arrays
  let gammaSupplyStateArr: any = []
  let gammaSpeedsArr:any = []
  let getBlockNumberArr: any = []
  let totalSupplyArr:any = []
  let gammaSupplierIndexArr:any = []
  let gammaInitialIndexArr:any = []
  let balanceOfGtokenArr:any = []
  let gammaSupplierBoostIndexArr: any = [];
  let userDataArr: any = [];//accountTokens[user], userFactors[user];

  //Borrow side arrays
  let gammaBorrowStateArr: any = []
  let borrowIndexArr:any = []
  let totalBorrowsArr:any = []
  let gammaBorrowerIndexArr:any = []
  let borrowBalanceStoredArr:any = []
  
  let targets: any = [];
  let callDatas: any = [];
  let results:any = [];
  let ouput_format : any = [];

  const assetsIn: any = await getAllMarkets(gammatrollerAddress)

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSupplyState(asset)._method,[asset]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaSupplyState(asset)._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSpeeds(asset)._method,[asset]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaSpeeds(asset)._method.outputs)
  })

  // assetsIn.forEach(async(asset: any) => {
  //   targets.push(gammatrollerAddress);
  //   const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.getBlockNumber()._method,[]));
  //   callDatas.push(data);
  //   ouput_format.push(gammatroller_inst.methods.getBlockNumber()._method.outputs)
  // })

  //  const currentBlock = await wallet.web3.eth.getBlockNumber()
  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.totalSupply()._method,[]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.totalSupply()._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.totalSupply()._method,[]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.totalSupply()._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSupplierIndex(asset,userAddress)._method,[asset,userAddress]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaSupplierIndex(asset,userAddress)._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaInitialIndex()._method,[]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaInitialIndex()._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.balanceOf(userAddress)._method,[userAddress]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.balanceOf(userAddress)._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaBorrowState(asset)._method,[asset]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaBorrowState(asset)._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.borrowIndex()._method,[]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.borrowIndex()._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.totalBorrows()._method,[]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.totalBorrows()._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaBorrowerIndex(asset,userAddress)._method,[asset,userAddress]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaBorrowerIndex(asset,userAddress)._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.borrowBalanceStored(userAddress)._method,[userAddress]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.borrowBalanceStored(userAddress)._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(gammatrollerAddress);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(gammatroller_inst.methods.gammaSupplierBoostIndex(asset,userAddress)._method,[asset, userAddress]));
    callDatas.push(data);
    ouput_format.push(gammatroller_inst.methods.gammaSupplierBoostIndex(asset, userAddress)._method.outputs)
  })

  assetsIn.forEach(async(asset: any) => {
    targets.push(asset);
    const data = (wallet.web3.eth.abi.encodeFunctionCall(asset_inst.methods.getUserData(userAddress)._method,[userAddress]));
    callDatas.push(data);
    ouput_format.push(asset_inst.methods.getUserData(userAddress)._method.outputs)
  })

  const agregated_data = (await mutilcall_inst.methods.aggregate(targets,callDatas).call());

  const do_split = async(array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : [];
  }  

  for(let i = 0 ; i < agregated_data[1].length ; i++){
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i],agregated_data[1][i]))
  }

  const split_arr = (await do_split(results,assetsIn.length));

  gammaSupplyStateArr = split_arr[0];  // index,block
  gammaSpeedsArr = split_arr[1];
  const blockNumber = await wallet.web3.eth.getBlockNumber()//split_arr[2];
  totalSupplyArr = split_arr[3]
  gammaSupplierIndexArr = split_arr[4]
  gammaInitialIndexArr = split_arr[5]
  balanceOfGtokenArr = split_arr[6]   
  gammaBorrowStateArr = split_arr[7] //index,block
  borrowIndexArr = split_arr[8]
  totalBorrowsArr = split_arr[9]
  gammaBorrowerIndexArr = split_arr[10]
  borrowBalanceStoredArr = split_arr[11]
  gammaSupplierBoostIndexArr = split_arr[12];
  userDataArr = split_arr[13];//accountTokens[user], userFactors[user];

  let _userGammaAccrued = parseFloat(await gammaAccrued(userAddress, gammatrollerAddress))
  try {
    const getCompAccrueBySupply = async (i: any, _userGammaAccrued: any): Promise<number> => {

      let currentSupplyState = gammaSupplyStateArr[i]
      if(gammaSpeedsArr[i][0] == 0){
        return _userGammaAccrued;
      }

      const supplySpeed = parseFloat(gammaSpeedsArr[i][0])
      const currentBlockNumberDiff = parseFloat(blockNumber) - parseFloat(currentSupplyState.block)
      let newGammaSupplyIndex = 0
      let newGammaBoostSupplyIndex = 0
      if (currentBlockNumberDiff > 0 && supplySpeed > 0) {
        const supplyAmount = parseFloat(totalSupplyArr[i][0])
        let _gammaAccrued = currentBlockNumberDiff * supplySpeed //borrowspeed = gamma per block
        newGammaSupplyIndex = parseFloat(currentSupplyState.index) + (supplyAmount > 0 ? (_gammaAccrued / supplyAmount) * 1e36 : 0)
      }

      if (currentBlockNumberDiff > 0 && supplySpeed > 0) {
        const supplyAmount = parseFloat(totalSupplyArr[i][0])
        let _gammaAccrued = currentBlockNumberDiff * supplySpeed //borrowspeed = gamma per block
        newGammaBoostSupplyIndex = parseFloat(currentSupplyState.boostIndex) //+ (supplyAmount > 0 ? (_gammaAccrued / supplyAmount) * 1e36 : 0)
      }

      let currentSupplierIndex = parseFloat(gammaSupplierIndexArr[i][0])

      let currentSupplierBoostIndex = parseFloat(gammaSupplierBoostIndexArr[i][0])
      if (currentSupplierBoostIndex === 0 && newGammaBoostSupplyIndex > 0) {
        currentSupplierBoostIndex = parseFloat(gammaInitialIndexArr[i][0]) // gamma supplier and boost sipplier index initial values are same
      }

      if (currentSupplierIndex === 0 && newGammaSupplyIndex > 0) {
        currentSupplierIndex = parseFloat(gammaInitialIndexArr[i][0])
      }

      let deltaBoostIndex = (parseFloat(convertToEther(newGammaBoostSupplyIndex, 36)) - parseFloat(convertToEther(currentSupplierBoostIndex, 36))) * 1e36

      let deltaIndex = (parseFloat(convertToEther(newGammaSupplyIndex, 36)) - parseFloat(convertToEther(currentSupplierIndex, 36))) * 1e36

      deltaIndex = deltaIndex < 0 ? 0 : deltaIndex
      const userSupplyBalance = parseFloat(balanceOfGtokenArr[i][0])
      const userFactor = parseFloat(userDataArr[i][1])
      // console.log(userSupplyBalance * deltaIndex, userSupplyBalance, deltaIndex)
      const supplierDelta = userSupplyBalance * deltaIndex + userFactor * deltaBoostIndex;
      return parseFloat(_userGammaAccrued) + parseFloat(convertToEther(supplierDelta, 36))
    }

    const getCompAccrueByBorrow = async (i: any, _userGammaAccrued: any): Promise<number> => {
  
      let currentBorrowState = gammaBorrowStateArr[i]
      const borrowSpeed = parseFloat(gammaSpeedsArr[i][0])
      const currentBlockNumberDiff = parseFloat(blockNumber) - parseFloat(currentBorrowState.block)

      if (currentBlockNumberDiff > 0 && borrowSpeed > 0) {
        const marketBorrowIndex = parseFloat(borrowIndexArr[i][0])
        const borrowAmount = parseFloat(totalBorrowsArr[i][0]) / marketBorrowIndex

        let _gammaAccrued: any = convertToEther(currentBlockNumberDiff * borrowSpeed, 18) //borrowspeed = gamma per block
        let newGammaBorrowIndex =
          parseFloat(currentBorrowState.index) + (borrowAmount > 0 ? (parseFloat(_gammaAccrued) / borrowAmount) * 1e36 : 0)

        const currentborrowerIndex = parseFloat(gammaBorrowerIndexArr[i][0])

        if (currentborrowerIndex > 0) {
          let deltaIndex =
            (parseFloat(convertToEther(newGammaBorrowIndex, 36)) - parseFloat(convertToEther(currentborrowerIndex, 36))) * 1e36
          deltaIndex = deltaIndex < 0 ? 0 : deltaIndex
          const userBorrowBalance = parseFloat(borrowBalanceStoredArr[i][0])
          const borrowerAmount = userBorrowBalance / marketBorrowIndex
          const borrowerDelta = borrowerAmount * deltaIndex

          return parseFloat(_userGammaAccrued) + parseFloat(convertToEther(borrowerDelta, 18))
        }
        return _userGammaAccrued
      }
      return _userGammaAccrued
    }

    for (let i = 0; i < assetsIn.length; i++) {
      //update gamma borrow index

      _userGammaAccrued = await getCompAccrueBySupply(i, _userGammaAccrued)
      // console.log("pending Gamma accrued from supply", assetsIn[i], _userGammaAccrued)
      _userGammaAccrued = await getCompAccrueByBorrow(i, _userGammaAccrued)
      // console.log("pending Gamma accrued from borrow", assetsIn[i], _userGammaAccrued)
    }

    return noExponents(convertToEther(_userGammaAccrued.toFixed(0), 18))
  } catch (e) {
    console.log(e)
  }

}

export const getPendingRewards = async (userAddress: string) => {

  const assetsIn: any = await getAllMarkets(gammatrollerAddress)

  let batch1 = new wallet.web3.BatchRequest();
  let batch2 = new wallet.web3.BatchRequest();
  let batch3 = new wallet.web3.BatchRequest();
  let batch4 = new wallet.web3.BatchRequest();
  let batch5 = new wallet.web3.BatchRequest();
  let batch6 = new wallet.web3.BatchRequest();
  let batch7 = new wallet.web3.BatchRequest();
  let batch8 = new wallet.web3.BatchRequest();
  let batch9 = new wallet.web3.BatchRequest();
  let batch10 = new wallet.web3.BatchRequest();
  let batch11 = new wallet.web3.BatchRequest();
  let batch12 = new wallet.web3.BatchRequest();
  const gammatroller_inst = await selectInstance(instType.gammatroller, gammatrollerAddress);

  //Supply side arrays
  let gammaSupplyStateArr: any = []
  let gammaSpeedsArr: any = []
  let getBlockNumberArr: any = []
  let totalSupplyArr: any = []
  let gammaSupplierIndexArr: any = []
  let gammaInitialIndexArr: any = []
  let balanceOfGtokenArr: any = []

  //Borrow side arrays
  let gammaBorrowStateArr: any = []
  let borrowIndexArr: any = []
  let totalBorrowsArr: any = []
  let gammaBorrowerIndexArr: any = []
  let borrowBalanceStoredArr: any = []

  let counter1 = 0;
  let counter2 = 0;
  let counter3 = 0;
  let counter4 = 0;
  let counter5 = 0;
  let counter6 = 0;
  let counter7 = 0;
  let counter8 = 0;
  let counter9 = 0;
  let counter10 = 0;
  let counter11 = 0;
  let counter12 = 0;

  let total = assetsIn.length

  await new Promise(function (resolve: any, reject) {
    assetsIn.forEach(async (asset: any) => {
      const asset_inst = new wallet.web3.eth.Contract(gTokenAbi, asset);
      batch1.add(
        gammatroller_inst.methods.gammaSupplyState(asset).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter1++;
          gammaSupplyStateArr.push(data);
          if (counter1 === total)
            resolve();
        })
      )
      batch2.add(
        gammatroller_inst.methods.gammaSpeeds(asset).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter2++;
          gammaSpeedsArr.push(data);
          if (counter2 === total)
            resolve();
        })
      )
      // batch3.add(
      //   gammatroller_inst.methods.getBlockNumber().call.request({ from: zeroAddress }, (error: any, data: any) => {
      //     if (error) return reject(error);
      //     counter3++;
      //     getBlockNumberArr.push(data);
      //     if (counter3 === total)
      //       resolve();
      //   })
      // )
      batch3.add(
        asset_inst.methods.totalSupply().call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter4++;
          totalSupplyArr.push(data);
          if (counter4 === total)
            resolve();
        })
      )
      batch4.add(
        asset_inst.methods.totalSupply().call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter4++;
          totalSupplyArr.push(data);
          if (counter4 === total)
            resolve();
        })
      )
      batch5.add(
        gammatroller_inst.methods.gammaSupplierIndex(asset, userAddress).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter5++;
          gammaSupplierIndexArr.push(data);
          if (counter5 === total)
            resolve();
        })
      )
      batch6.add(
        gammatroller_inst.methods.gammaInitialIndex().call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter6++;
          gammaInitialIndexArr.push(data);
          if (counter6 === total)
            resolve();
        })
      )
      batch7.add(
        asset_inst.methods.balanceOf(userAddress).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter7++;
          balanceOfGtokenArr.push(data);
          if (counter7 === total)
            resolve();
        })
      )
      batch8.add(
        gammatroller_inst.methods.gammaBorrowState(asset).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter8++;
          gammaBorrowStateArr.push(data);
          if (counter8 === total)
            resolve();
        })
      )
      batch9.add(
        asset_inst.methods.borrowIndex().call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter9++;
          borrowIndexArr.push(data);
          if (counter9 === total)
            resolve();
        })
      )
      batch10.add(
        asset_inst.methods.totalBorrows().call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter10++;
          totalBorrowsArr.push(data);
          if (counter10 === total)
            resolve();
        })
      )
      batch11.add(
        gammatroller_inst.methods.gammaBorrowerIndex(asset, userAddress).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter11++;
          gammaBorrowerIndexArr.push(data);
          if (counter11 === total)
            resolve();
        })
      )
      batch12.add(
        asset_inst.methods.borrowBalanceStored(userAddress).call.request({ from: zeroAddress }, (error: any, data: any) => {
          if (error) return reject(error);
          counter12++;
          borrowBalanceStoredArr.push(data);
          if (counter12 === total)
            resolve();
        })
      )
    });

    batch1.execute()
    batch2.execute()
    batch3.execute()
    batch4.execute()
    batch5.execute()
    batch6.execute()
    batch7.execute()
    batch8.execute()
    batch9.execute()
    batch10.execute()
    batch11.execute()
    batch12.execute()

  });

  // console.log("gammaSupplyStateArr-->",gammaSupplyStateArr,
  // "gammaSpeedsArr-->",gammaSpeedsArr,
  // "getBlockNumberArr-->",getBlockNumberArr,
  // "totalSupplyArr-->",totalSupplyArr,
  // "gammaSupplierIndexArr-->",gammaSupplierIndexArr,
  // "gammaInitialIndexArr-->",gammaInitialIndexArr,
  // "balanceOfGtokenArr-->",balanceOfGtokenArr,
  // "gammaBorrowStateArr-->",gammaBorrowStateArr,
  // "borrowIndexArr-->",borrowIndexArr,
  // "totalBorrowsArr-->",totalBorrowsArr,
  // "gammaBorrowerIndexArr-->",gammaBorrowerIndexArr,
  // "borrowBalanceStoredArr-->",borrowBalanceStoredArr)


  let _userGammaAccrued = parseFloat(await gammaAccrued(userAddress, gammatrollerAddress))
  try {
    const getCompAccrueBySupply = async (i: any, _userGammaAccrued: any): Promise<number> => {
      const gTokenAddress = assetsIn[i]

      let currentSupplyState = gammaSupplyStateArr[i]
      const supplySpeed = parseFloat(gammaSpeedsArr[i])
      const currentBlockNumberDiff = parseFloat(getBlockNumberArr[i]) - parseFloat(currentSupplyState.block)
      let newGammaSupplyIndex = 0

      if (currentBlockNumberDiff > 0 && supplySpeed > 0) {
        const supplyAmount = parseFloat(totalSupplyArr[i])
        let _gammaAccrued = currentBlockNumberDiff * supplySpeed //borrowspeed = gamma per block
        newGammaSupplyIndex = parseFloat(currentSupplyState.index) + (supplyAmount > 0 ? (_gammaAccrued / supplyAmount) * 1e36 : 0)
      }

      let currentSupplierIndex = parseFloat(gammaSupplierIndexArr[i])

      if (currentSupplierIndex === 0 && newGammaSupplyIndex > 0) {
        currentSupplierIndex = parseFloat(gammaInitialIndexArr[i])
      }

      let deltaIndex = (parseFloat(convertToEther(newGammaSupplyIndex, 36)) - parseFloat(convertToEther(currentSupplierIndex, 36))) * 1e36
      deltaIndex = deltaIndex < 0 ? 0 : deltaIndex
      const userSupplyBalance = parseFloat(balanceOfGtokenArr[i])
      const supplierDelta = userSupplyBalance * deltaIndex
      return parseFloat(_userGammaAccrued) + parseFloat(convertToEther(supplierDelta, 36))
    }

    const getCompAccrueByBorrow = async (i: any, _userGammaAccrued: any): Promise<number> => {
      const gTokenAddress = assetsIn[i]
      let currentBorrowState = gammaBorrowStateArr[i]
      const borrowSpeed = parseFloat(gammaSpeedsArr[i])
      const currentBlockNumberDiff = parseFloat(getBlockNumberArr[i]) - parseFloat(currentBorrowState.block)

      if (currentBlockNumberDiff > 0 && borrowSpeed > 0) {
        const marketBorrowIndex = parseFloat(borrowIndexArr[i])
        const borrowAmount = parseFloat(totalBorrowsArr[i]) / marketBorrowIndex

        let _gammaAccrued: any = convertToEther(currentBlockNumberDiff * borrowSpeed, 18) //borrowspeed = gamma per block
        let newGammaBorrowIndex =
          parseFloat(currentBorrowState.index) + (borrowAmount > 0 ? (parseFloat(_gammaAccrued) / borrowAmount) * 1e36 : 0)

        const currentborrowerIndex = parseFloat(gammaBorrowerIndexArr[i])

        if (currentborrowerIndex > 0) {
          let deltaIndex =
            (parseFloat(convertToEther(newGammaBorrowIndex, 36)) - parseFloat(convertToEther(currentborrowerIndex, 36))) * 1e36
          deltaIndex = deltaIndex < 0 ? 0 : deltaIndex
          const userBorrowBalance = parseFloat(borrowBalanceStoredArr[i])
          const borrowerAmount = userBorrowBalance / marketBorrowIndex
          const borrowerDelta = borrowerAmount * deltaIndex

          return parseFloat(_userGammaAccrued) + parseFloat(convertToEther(borrowerDelta, 18))
        }
        return _userGammaAccrued
      }
      return _userGammaAccrued
    }

    for (let i = 0; i < assetsIn.length; i++) {
      //update gamma borrow index

      _userGammaAccrued = await getCompAccrueBySupply(i, _userGammaAccrued)
      _userGammaAccrued = await getCompAccrueByBorrow(i, _userGammaAccrued)
    }

    return noExponents(convertToEther(_userGammaAccrued.toFixed(0), 18))
  } catch (e) {
    console.log(e)
  }
}

export const getMarketLiquidityData = async (blockNumber?: number) => {
  const list = pfTokenList
  const listLength = Object.keys(list).length

  try {

    let data: any = []
    for (let i = 0; i < listLength; i++) {
      const getData = async (i: any) => {
        const gTokenAddress = list[i].address
        const price = await getGTokenPrice(gTokenAddress, blockNumber)
        const underlyingBal = convertToEther(await totalSupply(gTokenAddress, blockNumber), 8)
        return price * underlyingBal
      }
      data.push(getData(i))
    }
    data = await Promise.all(data)
    return data
  }
  catch (e) {
    console.log(e);
    return [0];
  }
}


export const getTotalBorrowsOfAllMarkets = async (blockNumber?: number) => {
  const list = pfTokenList
  const listLength = Object.keys(list).length

  try {
    let data: any = []
    for (let i = 0; i < listLength; i++) {
      const getData = async (i: any) => {
        const gTokenAddress = list[i].address
        const price = await getTokenPrice(gTokenAddress, blockNumber)
        const underlyingBal = convertToEther(await totalBorrows(gTokenAddress, blockNumber), await getUnderlyingDecimal(gTokenAddress))
        return price * underlyingBal
      }
      data.push(getData(i))
    }
    data = await Promise.all(data)
    return data
  }
  catch (e) {
    console.log(e)
    return [0]
  }
}


export const getUserSuppliedAndBorrowedAssetData = async (userAddress: string) => {
  //userAddress = "0xD1Ec391627c9E2Fb0c570Da876Bc75dF23c42BEB"
  const list = pfTokenList
  const listLength = Object.keys(list).length

  let data: any = []
  const borrowData: any = userAddress ? await getBorrowAndUsedLimit(userAddress) : 0
  for (let i = 0; i < listLength; i++) {
    const getUserData = async (i: any) => {
      try {
        let obj = list[i]
        const gTokenAddress = obj.address
        const underlyingToken = await underlying(gTokenAddress)
        obj.underlyingPrice = await getTokenPrice(gTokenAddress)
        const market = await markets(gTokenAddress, gammatrollerAddress)

        if (userAddress != null && userAddress !== '') {
          obj.walletBal =
            i !== 0
              ? convertToEther(await balanceOfGtoken(userAddress, underlyingToken), await decimals(underlyingToken))
              : convertToEther(await wallet.web3.eth.getBalance(userAddress), 18)

          obj.walletBalInUsd = obj.walletBal * obj.underlyingPrice
          obj.isUsingAsCollateral = await isGivenAsCollateral(gTokenAddress, userAddress)
          obj.underlyingTokenAllowance =
            i !== 0
              ? parseFloat(await allowance(userAddress, gTokenAddress, underlyingToken)) >= parseFloat(approvalAmount)
                ? true
                : false
              : true
          obj.gTokenAllowance = convertToEther(await allowance(userAddress, gTokenAddress, gTokenAddress), 8)
          obj.borrowBalance = await getBorrowBalance(gTokenAddress, userAddress)
          obj.borrowLimitInUsd = borrowData.borrowLimit
          obj.borrowLimitUsedCurr = borrowData.usedBorrowLimitInPercentage
          obj.currentlySupplying = noExponents(await getSupplyBalance(gTokenAddress, userAddress))
          obj.currentlySupplyingUsd = parseFloat(obj.currentlySupplying) * parseFloat(obj.underlyingPrice)
          obj.currentlyBorrowing = noExponents(await getBorrowBalance(gTokenAddress, userAddress))
          obj.currentlyBorrowingUsd = parseFloat(obj.currentlyBorrowing) * parseFloat(obj.underlyingPrice)
          obj.isBorrowing = obj.currentlyBorrowingUsd >= 0.01 ? true : false
          obj.isSupplying = obj.currentlySupplyingUsd >= 0.01 ? true : false
          obj.supplyApy = await getSupplyApy(gTokenAddress, userAddress);
          obj.borrowApy = await getBorrowApy(gTokenAddress, userAddress)
          obj.supplyApy24hr = parseFloat(obj.supplyApy) / 365
          obj.borrowApy24hr = parseFloat(obj.borrowApy) / 365
          obj.supplyDistributionApy = await getGammaSupplyApy(gTokenAddress)
          obj.borrowDistributionApy = await getGammaBorrowApy(gTokenAddress)
          obj.totalSupplyApy = obj.supplyApy + obj.supplyDistributionApy
          obj.totalBorrowApy = obj.borrowApy + obj.borrowDistributionApy
          obj.collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
          obj.isAvailForCollateral = obj.collateralFactor > 0 ? true : false
          obj.underlyingPrice = await getTokenPrice(gTokenAddress)
          obj.underlyingAddress = underlyingToken
          obj.totalMarketSize =
            convertToEther(await totalSupply(gTokenAddress), 8) * ((await getGTokenPrice(gTokenAddress)) / parseFloat(obj.underlyingPrice))
          obj.totalMarketSizeUsd = (await getGTokenPrice(gTokenAddress)) * parseFloat(convertToEther(await totalSupply(gTokenAddress), 8))
          obj.available = parseFloat(convertToEther(await getCash(gTokenAddress), await getUnderlyingDecimal(gTokenAddress)))
          obj.availableUsd = obj.available * parseFloat(obj.underlyingPrice)


          /***********************************************************************************/

          const walletBal = i !== 0 ? obj.walletBal : obj.walletBal - 0.01

          const supplyBorrowLimitData = (await getBorrowLimitParamsOnChange(obj.isUsingAsCollateral, gTokenAddress,
            1, walletBal, obj.borrowLimitInUsd,
            await getTotalBorrowBalance(userAddress)))

          if (obj.isUsingAsCollateral) {

            const collateralGiven = obj.currentlySupplyingUsd *
              parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))

            if ((borrowData.borrowLimit - collateralGiven)
              >= borrowData.totalBorrowed) {
              obj.maxWithdraw = obj.currentlySupplying
            }
            else {
              obj.maxWithdraw = (borrowData.borrowLimit - borrowData.totalBorrowed) / obj.underlyingPrice /
                parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))
            }

          }
          else {
            obj.maxWithdraw = obj.currentlySupplying
          }

          obj.maxWithdraw = obj.maxWithdraw >= obj.available ? obj.available : obj.maxWithdraw

          obj.supplyLowestBorrowLimitUsed = obj.isUsingAsCollateral ?
            supplyBorrowLimitData?.usedBorrowLimitInPercentage
            : 0

          /***********************************************************************************/

          return obj
        } else {
          obj.walletBal = 0
          obj.walletBalInUsd = 0
          obj.isUsingAsCollateral = false
          obj.underlyingTokenAllowance = 0
          obj.gTokenAllowance = 0
          obj.borrowBalance = 0
          obj.borrowLimitInUsd = 0
          obj.borrowLimitUsedCurr = 0
          obj.currentlySupplying = 0
          obj.currentlySupplyingUsd = 0
          obj.currentlyBorrowing = 0
          obj.currentlyBorrowingUsd = 0
          obj.isBorrowing = obj.currentlyBorrowing > 0 ? true : false
          obj.isSupplying = obj.currentlySupplying > 0 ? true : false
          obj.supplyApy = await getSupplyApy(gTokenAddress, userAddress)
          obj.borrowApy = await getBorrowApy(gTokenAddress, userAddress)
          obj.supplyApy24hr = parseFloat(obj.supplyApy) / 365
          obj.borrowApy24hr = parseFloat(obj.borrowApy) / 365
          obj.supplyDistributionApy = await getGammaSupplyApy(gTokenAddress)
          obj.borrowDistributionApy = await getGammaBorrowApy(gTokenAddress)
          obj.totalSupplyApy = obj.supplyApy + obj.supplyDistributionApy
          obj.totalBorrowApy = obj.borrowApy + obj.borrowDistributionApy
          obj.collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
          obj.isAvailForCollateral = obj.collateralFactor > 0 ? true : false
          obj.underlyingPrice = await getTokenPrice(gTokenAddress)
          obj.underlyingAddress = underlyingToken
          obj.totalMarketSize = convertToEther(await totalSupply(gTokenAddress), 8)
          obj.totalMarketSizeUsd = (await getGTokenPrice(gTokenAddress)) * parseFloat(obj.totalMarketSize)
          obj.available = parseFloat(convertToEther(await getCash(gTokenAddress), await getUnderlyingDecimal(gTokenAddress)))
          obj.availableUsd = obj.available * parseFloat(obj.underlyingPrice)
          return obj
        }
      }
      catch (err) {
        console.log(err);
      }
    }
    data.push(getUserData(i))
  }
  data = await Promise.all(data)
  const suppliedAssets: any = data.filter((e: any) => e.isSupplying)
  const borrowedAssets: any = data.filter((e: any) => e.isBorrowing)
  return { data, suppliedAssets, borrowedAssets }
}

export const getMarketLiquidityDataforMarkets = async (priceArray?: any, blockNumber?: number) => {
  const list = pfTokenList
  const listLength = Object.keys(list).length

  try {
    let data: any = []
    for (let i = 0; i < listLength; i++) {
      const getData = async (i: any) => {
        const gTokenAddress = list[i].address
        const price = await getGTokenPrice(gTokenAddress, blockNumber)
        const underlyingBal = convertToEther(await totalSupply(gTokenAddress, blockNumber), await decimals(gTokenAddress))
        return price * underlyingBal
      }
      data.push(getData(i))
    }
    data = await Promise.all(data)
    return data
  } catch (e) {
    console.log(e)
    return [0]
  }
}

export const getMarketData = async (userAddress: string) => {
  const currentBlock = await wallet.web3.eth.getBlockNumber()
  const currentBlockYesterday = currentBlock
  const currentBlockDayBeforeYesterday = currentBlock

  const prevToPrevSuppData = await getMarketLiquidityDataforMarkets(currentBlockDayBeforeYesterday)
  const prevSuppData = await getMarketLiquidityDataforMarkets(currentBlockYesterday)
  const currSuppData = await getMarketLiquidityDataforMarkets(currentBlock)

  const prevToPrevTotalSuppMarketSize = prevToPrevSuppData.reduce((a: any, b: any) => a + b, 0)
  const prevTotalSuppMarketSize = prevSuppData.reduce((a: any, b: any) => a + b, 0)
  const currTotalSuppMarketSize = currSuppData.reduce((a: any, b: any) => a + b, 0)

  const prevToPrevBorrowData = await getTotalBorrowsOfAllMarkets(currentBlockDayBeforeYesterday)
  const prevBorrowData = await getTotalBorrowsOfAllMarkets(currentBlockYesterday)
  const currBorrowData = await getTotalBorrowsOfAllMarkets(currentBlock)

  const prevToPrevTotalBorrowMarketSize = prevToPrevBorrowData.reduce((a: any, b: any) => a + b, 0)
  const prevTotalBorrowMarketSize = prevBorrowData.reduce((a: any, b: any) => a + b, 0)
  const currTotalBorrowMarketSize = currBorrowData.reduce((a: any, b: any) => a + b, 0)

  const prevTwentyFourHrSuppVolume =
    prevTotalSuppMarketSize - prevToPrevTotalSuppMarketSize > 0 ? prevTotalSuppMarketSize - prevToPrevTotalSuppMarketSize : 0
  const currTwentyFourHrSuppVolume =
    currTotalSuppMarketSize - prevTotalSuppMarketSize > 0 ? currTotalSuppMarketSize - prevTotalSuppMarketSize : 0

  const prevTwentyFourHrBorrowVolume =
    prevTotalBorrowMarketSize - prevToPrevTotalBorrowMarketSize > 0 ? prevTotalBorrowMarketSize - prevToPrevTotalBorrowMarketSize : 0
  const currTwentyFourHrBorrowVolume =
    currTotalBorrowMarketSize - prevTotalBorrowMarketSize > 0 ? currTotalBorrowMarketSize - prevTotalBorrowMarketSize : 0

  const top3SuppMarket = async () => {
    let list = marketTokenList
    let res = []
    const indexes = findLargest3(currSuppData)

    for (let i = 0; i < indexes.length; i++) {
      const obj1 = {
        address: list[indexes[i]].address,
        name: list[indexes[i]].name,
        marketSymbol: list[indexes[i]].marketSymbol,
        icon: list[indexes[i]].icon,
        tokenOrigin: list[indexes[i]].tokenOrigin,
        percentage: (currSuppData[indexes[i]] / currTotalSuppMarketSize) * 100,
      }
      res.push(obj1)
    }

    return res
  }

  const top3BorrowMarket = async () => {
    let list = marketTokenList
    let res = []

    const indexes = findLargest3(currBorrowData)

    for (let i = 0; i < indexes.length; i++) {
      const obj1 = {
        address: list[indexes[i]].address,
        name: list[indexes[i]].name,
        marketSymbol: list[indexes[i]].marketSymbol,
        icon: list[indexes[i]].icon,
        tokenOrigin: list[indexes[i]].tokenOrigin,
        percentage: (currBorrowData[indexes[i]] / currTotalBorrowMarketSize) * 100,
      }
      res.push(obj1)
    }

    return res
  }

  const allMarketsData = async (userAddress: string) => {
    let list: any = marketTokenList
    const marketListData: any = []
    const eachMarketData = async (index: number) => {
      const market = list[index].address

      const prevSuppApy = userAddress
        ? await getSupplyApy(market, userAddress, currentBlockYesterday)
        : await getSupplyApy(market, '', currentBlockYesterday)

      const currSuppApy = userAddress ? await getSupplyApy(market, userAddress, currentBlock) : await getSupplyApy(market, '', currentBlock)

      const prevBorrowApy = userAddress
        ? await getBorrowApy(market, userAddress, currentBlockYesterday)
        : await getBorrowApy(market, '', currentBlockYesterday)

      const currBorrowApy = userAddress
        ? await getBorrowApy(market, userAddress, currentBlock)
        : await getBorrowApy(market, '', currentBlock)

      const marketObj = {
        address: market,
        totalSupply: currSuppData[index],
        totalSupplyChange: ((currSuppData[index] - prevSuppData[index]) / prevSuppData[index]) * 100,
        totalBorrow: currBorrowData[index],
        totalBorrowChange: ((currBorrowData[index] - prevBorrowData[index]) / prevBorrowData[index]) * 100,
        supplyApy: currSuppApy,
        supplyApyChange: ((currSuppApy - prevSuppApy) / prevSuppApy) * 100,
        borrowApy: currBorrowApy,
        borrowApyChange: ((currBorrowApy - prevBorrowApy) / prevBorrowApy) * 100,
        name: marketTokenList[index].name,
        icon: marketTokenList[index].icon,
        tokenOrigin: marketTokenList[index].tokenOrigin,
      }
      return marketObj
    }
    Object.values(list).forEach((element: any) => {
      const market = element.sno
      const data = eachMarketData(market)
      marketListData.push(data)
    })
    const result = await Promise.all(marketListData)
    return result
  }

  return {
    supplyMarketSize: currTotalSuppMarketSize,
    changeInSupplyMarketSize: ((currTotalSuppMarketSize - prevTotalSuppMarketSize) / prevTotalSuppMarketSize) * 100,

    twentyFourHrSuppVolume: currTwentyFourHrSuppVolume < prevTwentyFourHrSuppVolume ? 0 : currTwentyFourHrSuppVolume,
    twentyFourHrSuppVolumeChange:
      ((currTwentyFourHrSuppVolume < prevTwentyFourHrSuppVolume ? 0 : currTwentyFourHrSuppVolume - prevTwentyFourHrSuppVolume) /
        (prevTwentyFourHrSuppVolume > 0 ? prevTwentyFourHrSuppVolume : 1)) *
      100,

    borrowMarketSize: currTotalBorrowMarketSize,
    changeInBorrowMarketSize: ((currTotalBorrowMarketSize - prevTotalBorrowMarketSize) / prevTotalBorrowMarketSize) * 100,

    twentyFourHrBorrowVolume: currTwentyFourHrBorrowVolume < prevTwentyFourHrBorrowVolume ? 0 : currTwentyFourHrBorrowVolume,
    twentyFourHrBorrowVolumeChange:
      ((currTwentyFourHrBorrowVolume < prevTwentyFourHrBorrowVolume ? 0 : currTwentyFourHrBorrowVolume - prevTwentyFourHrBorrowVolume) /
        (prevTwentyFourHrBorrowVolume > 0 ? prevTwentyFourHrBorrowVolume : 1)) *
      100,

    top3SupplyMarkets: await top3SuppMarket(),
    top3BorrowMarkets: await top3BorrowMarket(),
    allMarkets: await allMarketsData(userAddress),
  }
}

export const getBurnApy = async (type?: any) => {
  
  let burnApy: any = type ? {apy: 6.24, apr: 6.24} : 6.24;
  try {
    
    return burnApy;
  }
  catch (error) {
    console.log("error", error)
    burnApy = "2";
   }
  finally {
    return burnApy
  }

  //return '14.229871152691054'
}

export const getBurnApr = async () => {
  return "6.24";
}

export const getTreasuryApy = async () => {
  let apyVal: any = 0
  try {
    const res = await axios.get(planetFinanceApiBaseUrl+'v1/markets/gettreasury')
    if (!!res && res.data && res.data.length > 0) {
      const lastIndex: number = res.data.length - 1
      const firstData = res.data[0]
      const lastData = res.data[lastIndex]
      const key1 = `${Object.keys(firstData)}`
      const key2 = `${Object.keys(lastData)}`
      const firstVal = firstData[key1]
      const lastVal = lastData[key2]
      const diffVal: any = parseFloat(firstVal) - parseFloat(lastVal)
      let increasedVal: any = 0
      if (parseFloat(diffVal) > 0) {
        increasedVal = parseFloat(diffVal)
        const aquaTotalSupply = parseFloat(await totalSupply(aquaAddress))
        const deadAquaBal = await balanceOf(aquaAddress, '0x000000000000000000000000000000000000dEaD')
        const marketCap: any = (convertToEther(aquaTotalSupply, 18) - convertToEther(deadAquaBal, 18)) * (await getAquaPrice())
        const aprVal = ((parseFloat(increasedVal) / parseFloat(marketCap)) * 52) * 100
        const apyData: any = await getTokenYield(aprVal, 365)
        apyVal = !isNaN(parseFloat(apyData)) ? apyData : 0
      }
    }
  }
  catch (error) {
    console.log("error===>", error)
  }
  finally {
    return apyVal
  }
}

const token_transfer_history = async (tokenContract: string, accountAddress: string, startBlock: number) => {
  const url =
    `https://api.bscscan.com/api?module=account&action=tokentx&contractaddress=${tokenContract}&address=${accountAddress}&page=-1&offset=5&startblock=${startBlock}&endblock=latest&sort=desc&apikey=${apiKey}`

  const data = (await axios.get(url)).data['result']

  return data
}

const coingecko_history_data = async (coinTicker: string, startTimeStamp: number, endTimeStamp: number) => {
  const url =
    `https://api.coingecko.com/api/v3/coins/${coinTicker}/market_chart/range?vs_currency=usd&from=${startTimeStamp}&to=${endTimeStamp}`

  const data = (await axios.get(url)).data

  return data.prices
}

export const getGammaBought = async () => {
  const userAddress = gamma_reservoir
  const gammaPrice = await getGammaPrice();

  const gammaInNewGamma = await getSupplyBalance(gGamma, userAddress);
  const gammaInOldGamma = await getSupplyBalance(oldgGamma, userAddress);
  
  const val = (gammaInNewGamma + gammaInOldGamma)
  const userGammaBalance: any = parseFloat(convertToEther(await balanceOfGtoken(userAddress, gammaAddress), 18)) + (val)
  const userGammaBalanceUsd = gammaPrice * userGammaBalance

  return userGammaBalanceUsd
}

export const getMetricsPageData = async (historicPriceInterval: number) => {

  const currentBlock = await wallet.web3.eth.getBlockNumber()
  let startBlock = currentBlock - 7 * 28800

  //let oracleBlock =  currentBlock - 1 * 28800

  // const aqua_symbol_hash = wallet.web3.utils.keccak256("AQUA");
  // const _gamma_symbol_hash = gamma_symbol_hash;

  // const oracle_logs = (await logs_using_topic0(oracleBlock,priceOracleAddress,price_updated_hash))

  // const aqua_price_update_log = oracle_logs.filter((e: any) => e.topics[1] === aqua_symbol_hash)
  // const gamma_price_update_log = oracle_logs.filter((e: any) => e.topics[1] === _gamma_symbol_hash)

  // aqua_price_update_log.forEach((element: any) => {
  //   const d = new Date(element.timeStamp * 1000)
  //   aqua_history_price.push({
  //     price: (convertToEther(Number(element.data), 6)),
  //     symbol: "AQUA",
  //     date: d,
  //   })
  // });

  // gamma_price_update_log.forEach((element: any) => {
  //   const d = new Date(element.timeStamp * 1000)
  //   gamma_history_price.push({
  //     price: (convertToEther(Number(element.data), 6)),
  //     symbol: "GAMMA",
  //     date: d,
  //   })
  // });

  //AQUA BURN HISTORY
  const transfer_history = await token_transfer_history(aquaAddress, deadAddress, startBlock)
  const gamma_transfer_history = await token_transfer_history(gammaAddress, gamma_reservoir, startBlock)

  const endTimeStamp = (+new Date() / 1000);
  const startTimeStamp = endTimeStamp - historicPriceInterval * 24 * 60 * 60

  const aqua_price_update_log: any = await coingecko_history_data("planet-finance", startTimeStamp, endTimeStamp);
  const gamma_price_update_log: any = await coingecko_history_data("green-planet", startTimeStamp, endTimeStamp);

  const aquaPrice = await getAquaPrice();
  const gammaPrice = await getGammaPrice();

  let aqua_history_price: any = []

  aqua_price_update_log.forEach((element: any) => {
    const d = new Date(element[0])
    aqua_history_price.push({
      price: element[1],
      symbol: "AQUA",
      date: d,
    })
  });

  let gamma_history_price: any = []

  gamma_price_update_log.forEach((element: any) => {
    const d = new Date(element[0])
    gamma_history_price.push({
      price: element[1],
      symbol: "GAMMA",
      date: d,
    })
  });

  let aqua_burn_history: any = [];

  transfer_history.forEach((element: any) => {
    const d = new Date(element.timeStamp * 1000)
    aqua_burn_history.push({
      amount: convertToEther(element.value, 18),
      amountInUsd: parseFloat(convertToEther(element.value, 18)) * aquaPrice,
      symbol: element.tokenSymbol,
      date: d,
      transactionHash: element.hash
    })
  });

  //GAMMA BUY HISTORY
  //startBlock = currentBlock - 1 * 28800

  let gamma_buy_history: any = []

  gamma_transfer_history.forEach((element: any) => {
    const d = new Date(element.timeStamp * 1000)
    gamma_buy_history.push({
      amount: convertToEther(element.value, 18),
      amountInUsd: parseFloat(convertToEther(element.value, 18)) * gammaPrice,
      symbol: element.tokenSymbol,
      date: d,
      transactionHash: element.hash
    })
  });

  const aqua_latest_price = (aqua_history_price[aqua_history_price.length - 1]).price
  const gamma_latest_price = (gamma_history_price[gamma_history_price.length - 1]).price

  const aqua_price_change = ((parseFloat((aqua_history_price[aqua_history_price.length - 1]).price) -
    parseFloat((aqua_history_price[0]).price)) / parseFloat((aqua_history_price[0]).price)) * 100

  const gamma_price_change = ((parseFloat((gamma_history_price[gamma_history_price.length - 1]).price) -
    parseFloat((gamma_history_price[0]).price)) / parseFloat((gamma_history_price[0]).price)) * 100

  const aqua_burned = parseFloat(convertToEther(await balanceOf(aquaAddress, deadAddress), 18))

  const aqua_burn_usd = aqua_burned * aquaPrice

  const aqua_market_cap = (parseFloat(convertToEther(await totalSupply(aquaAddress), 18)) - aqua_burned) * aquaPrice

  const block_diff = currentBlock - firstTimeGammaDrippedBlock
  const gammaDrippedPerBlock = parseFloat(convertToWei(100000, 1e18)) / 28800
  const gamma_dripped = gammaDrippedPerBlock * block_diff;
  const gamma_market_cap = parseFloat(convertToEther(gamma_dripped, 18)) * gammaPrice + 4000000 * gammaPrice

  return {
    aqua_burn_history,
    gamma_buy_history,
    aqua_history_price,
    gamma_history_price,
    aqua_latest_price,
    gamma_latest_price,
    aqua_price_change,
    gamma_price_change,
    aqua_market_cap,
    aqua_burned,
    aqua_burn_usd,
    gamma_market_cap
  }
}

export const singleVaultsDataForFarm = async (userAddress: string, vaultsList: any) => {
  //userAddress = "0xD1Ec391627c9E2Fb0c570Da876Bc75dF23c42BEB"
  const list = Object.values(vaultsList)
  const listLength = list.length
  const burnApyVal = await getBurnApy()
  const burnAprVal = await getBurnApr()
  // const treasuryApyVal = await getTreasuryApy()

  let data: any = []
  const borrowData: any = userAddress ? await getBorrowAndUsedLimit(userAddress) : 0
  for (let i = 0; i < listLength; i++) {

    //let rewards : any = await getPendingData(userAddress)

    const getUserData = async (i: any) => {
      try {
        let obj: any = list[i]
        const gTokenAddress = obj.address
        const underlyingToken = await underlying(gTokenAddress)
        const market = await markets(gTokenAddress, gammatrollerAddress)

        const burnApy = obj.name === 'AQUA' || obj.name === 'AQUA (OLD)' ? burnApyVal : '0'
        const burnApr = obj.name === 'AQUA' || obj.name === 'AQUA (OLD)' ? burnAprVal : '0'
        // const treasuryApy = obj.name === 'AQUA' ? treasuryApyVal : '0'

        if (userAddress != null && userAddress !== '') {
          obj.newFarm = true
          obj.assetTokenPrice = await getTokenPrice(gTokenAddress)
          obj.greenVault = true;
          obj.liquidBalance =
            obj.sno !== 0
              ? convertToEther(await balanceOfGtoken(userAddress, underlyingToken), await decimals(underlyingToken))
              : convertToEther(await wallet.web3.eth.getBalance(userAddress), 18)
          obj.usdLiquidBalance = obj.liquidBalance * obj.assetTokenPrice

          obj.currentLpDeposit = noExponents(await getSupplyBalance(gTokenAddress, userAddress))
          obj.currentBalance = parseFloat(obj.currentLpDeposit) * parseFloat(obj.assetTokenPrice)

          obj.token = obj.name

          obj.lpType = ''

          obj.farmName = 'Green Planet'

          obj.farmContract = `https://bscscan.com/address/${gTokenAddress}`

          obj.supplyApy = await getSupplyApy(gTokenAddress, userAddress);

          obj.gammaApy = await getGammaSupplyApy(gTokenAddress)

          obj.burnApy = burnApy

          const aprPerYear = (Math.pow(((parseFloat(obj.supplyApy) + parseFloat(obj.gammaApy)) / 100 + 1), (1 / 365)) - 1) * 365 * 100
          const totalApr = aprPerYear + parseFloat(burnApr)

          // if(obj.name === 'AQUA' || obj.name === 'AQUA (OLD)'){
          //   obj.totalApy = (Math.pow((1+(totalApr/100)/365),365)-1)*100
          // }
          // else{
          //   obj.totalApy = parseFloat(obj.supplyApy) + parseFloat(obj.gammaApy)
          // }
          // obj.treasuryApy = treasuryApy
          // obj.totalApy = parseFloat(obj.supplyApy) + parseFloat(obj.gammaApy) + parseFloat(obj.burnApy) + parseFloat(obj.treasuryApy)
          obj.totalApy = parseFloat(obj.supplyApy) + parseFloat(obj.gammaApy) + parseFloat(obj.burnApy)

          obj.dailyPercentage = (Math.pow((obj.totalApy / 100 + 1), (1 / 365)) - 1) * 365 * 100 / 365

          obj.tokenType = false
          
          obj.currentPoolTokenApproval = convertToEther(await allowance(userAddress, gTokenAddress, gTokenAddress), 8)
          obj.depositFee = 0

          obj.autoCompoundFee = 0

          obj.platformFee = 0

          obj.buyBackFee = 0


          obj.withdrawFee = 0


          obj.walletBal =
            i !== 0
              ? convertToEther(await balanceOfGtoken(userAddress, underlyingToken), await decimals(underlyingToken))
              : convertToEther(await wallet.web3.eth.getBalance(userAddress), 18)

          obj.walletBalInUsd = obj.walletBal * obj.underlyingPrice
          obj.isUsingAsCollateral = await isGivenAsCollateral(gTokenAddress, userAddress)
          obj.underlyingTokenAllowance =
            i !== 0
              ? parseFloat(await allowance(userAddress, gTokenAddress, underlyingToken)) >= parseFloat(approvalAmount)
                ? true
                : false
              : true
          obj.gTokenAllowance = convertToEther(await allowance(userAddress, gTokenAddress, gTokenAddress), 8)
          obj.borrowBalance = await getBorrowBalance(gTokenAddress, userAddress)
          obj.borrowLimitInUsd = borrowData.borrowLimit
          obj.borrowLimitUsedCurr = borrowData.usedBorrowLimitInPercentage
          obj.currentlySupplying = noExponents(await getSupplyBalance(gTokenAddress, userAddress))
          obj.currentlySupplyingUsd = parseFloat(obj.currentlySupplying) * parseFloat(obj.underlyingPrice)
          obj.currentlyBorrowing = noExponents(await getBorrowBalance(gTokenAddress, userAddress))
          obj.currentlyBorrowingUsd = parseFloat(obj.currentlyBorrowing) * parseFloat(obj.underlyingPrice)
          obj.isBorrowing = obj.currentlyBorrowingUsd >= 0.01 ? true : false
          obj.isSupplying = obj.currentlySupplyingUsd >= 0.01 ? true : false
          obj.supplyApy = await getSupplyApy(gTokenAddress, userAddress);
          obj.borrowApy = await getBorrowApy(gTokenAddress, userAddress)
          obj.borrowApy24hr = parseFloat(obj.borrowApy) / 365
          obj.supplyDistributionApy = await getGammaSupplyApy(gTokenAddress)
          obj.tokenYield = obj.totalApy
          obj.supplyApy24hr = obj.dailyPercentage
          obj.borrowDistributionApy = await getGammaBorrowApy(gTokenAddress)
          obj.totalSupplyApy = obj.supplyApy + obj.supplyDistributionApy
          obj.totalBorrowApy = obj.borrowApy + obj.borrowDistributionApy
          obj.collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
          obj.isAvailForCollateral = obj.collateralFactor > 0 ? true : false
          obj.underlyingPrice = await getTokenPrice(gTokenAddress)
          obj.underlyingAddress = underlyingToken
          obj.totalMarketSize =
            convertToEther(await totalSupply(gTokenAddress), 8) * ((await getGTokenPrice(gTokenAddress)) / parseFloat(obj.underlyingPrice))
          obj.totalMarketSizeUsd = (await getGTokenPrice(gTokenAddress)) * parseFloat(convertToEther(await totalSupply(gTokenAddress), 8))
          obj.available = parseFloat(convertToEther(await getCash(gTokenAddress), await getUnderlyingDecimal(gTokenAddress)))
          obj.availableUsd = obj.available * parseFloat(obj.underlyingPrice)
          obj.tokenTvl = obj.totalMarketSizeUsd


          /***********************************************************************************/

          const walletBal = i !== 0 ? obj.liquidBalance : obj.liquidBalance - 0.01

          const supplyBorrowLimitData = (await getBorrowLimitParamsOnChange(obj.isUsingAsCollateral, gTokenAddress,
            1, walletBal, obj.borrowLimitInUsd,
            await getTotalBorrowBalance(userAddress)))

          if (obj.isUsingAsCollateral) {

            const collateralGiven = obj.currentlySupplyingUsd *
              parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))

            if ((borrowData.borrowLimit - collateralGiven)
              >= borrowData.totalBorrowed) {
              obj.maxWithdraw = obj.currentlySupplying
            }
            else {
              obj.maxWithdraw = (borrowData.borrowLimit - borrowData.totalBorrowed) / obj.underlyingPrice /
                parseFloat(convertToEther((await markets(gTokenAddress, gammatrollerAddress))['collateralFactorMantissa'], 18))
            }

          }
          else {
            obj.maxWithdraw = obj.currentlySupplying
          }
          obj.maxWithdraw = obj.maxWithdraw >= obj.available ? obj.available : obj.maxWithdraw
          obj.supplyLowestBorrowLimitUsed = obj.isUsingAsCollateral ?
            supplyBorrowLimitData?.usedBorrowLimitInPercentage
            : 0

          /***********************************************************************************/

          return obj
        } else {
          obj.newFarm = true
          obj.assetTokenPrice = await getTokenPrice(gTokenAddress)

          obj.greenVault = true;

          obj.liquidBalance = 0

          obj.usdLiquidBalance = obj.liquidBalance * obj.assetTokenPrice

          obj.currentLpDeposit = 0

          obj.currentBalance = parseFloat(obj.currentLpDeposit) * parseFloat(obj.assetTokenPrice)

          obj.token = obj.name

          obj.lpType = ''

          obj.farmName = 'Green Planet'

          obj.farmContract = `https://bscscan.com/address/${gTokenAddress}`

          obj.supplyApy = await getSupplyApy(gTokenAddress, "");

          obj.gammaApy = await getGammaSupplyApy(gTokenAddress)

          obj.burnApy = burnApy

          const aprPerYear = (Math.pow(((parseFloat(obj.supplyApy) + parseFloat(obj.gammaApy)) / 100 + 1), (1 / 365)) - 1) * 365 * 100
          const totalApr = aprPerYear + parseFloat(burnApr)

          // if (obj.name === 'AQUA' || obj.name === 'AQUA (OLD)') {
          //   obj.totalApy = (Math.pow((1 + (totalApr / 100) / 365), 365) - 1) * 100
          // }
          // else {
          //   obj.totalApy = parseFloat(obj.supplyApy) + parseFloat(obj.gammaApy)
          // }
          // obj.treasuryApy = treasuryApy

          // obj.totalApy = parseFloat(obj.supplyApy) + parseFloat(obj.gammaApy) + parseFloat(obj.burnApy) + parseFloat(obj.treasuryApy)
          obj.totalApy = parseFloat(obj.supplyApy) + parseFloat(obj.gammaApy) + parseFloat(obj.burnApy)

          obj.tokenYield = obj.totalApy

          obj.dailyPercentage = (Math.pow((obj.totalApy / 100 + 1), (1 / 365)) - 1) * 365 * 100 / 365

          obj.tokenType = false

          obj.currentPoolTokenApproval = 0

          obj.depositFee = 0

          obj.autoCompoundFee = 0

          obj.platformFee = 0

          obj.buyBackFee = 0

          if (obj.name === "AQUA" || obj.name === "GAMMA")
            obj.withdrawFee = 0.1

          obj.walletBal = 0
          obj.walletBalInUsd = 0
          obj.isUsingAsCollateral = false
          obj.underlyingTokenAllowance = 0
          obj.gTokenAllowance = 0
          obj.borrowBalance = 0
          obj.borrowLimitInUsd = 0
          obj.borrowLimitUsedCurr = 0
          obj.currentlySupplying = 0
          obj.currentlySupplyingUsd = 0
          obj.currentlyBorrowing = 0
          obj.currentlyBorrowingUsd = 0
          obj.isBorrowing = obj.currentlyBorrowing > 0 ? true : false
          obj.isSupplying = obj.currentlySupplying > 0 ? true : false
          obj.supplyApy = await getSupplyApy(gTokenAddress, "")
          obj.borrowApy = await getBorrowApy(gTokenAddress, "")
          obj.supplyApy24hr = obj.dailyPercentage
          obj.borrowApy24hr = parseFloat(obj.borrowApy) / 365
          obj.supplyDistributionApy = await getGammaSupplyApy(gTokenAddress)
          obj.borrowDistributionApy = await getGammaBorrowApy(gTokenAddress)
          obj.totalSupplyApy = obj.supplyApy + obj.supplyDistributionApy
          obj.totalBorrowApy = obj.borrowApy + obj.borrowDistributionApy
          obj.collateralFactor = convertToEther(market.collateralFactorMantissa, 18) * 100
          obj.isAvailForCollateral = obj.collateralFactor > 0 ? true : false
          obj.underlyingPrice = await getTokenPrice(gTokenAddress)
          obj.underlyingAddress = underlyingToken
          obj.totalMarketSize =
            convertToEther(await totalSupply(gTokenAddress), 8) * ((await getGTokenPrice(gTokenAddress)) / parseFloat(obj.underlyingPrice))
          obj.totalMarketSizeUsd = (await getGTokenPrice(gTokenAddress)) * parseFloat(convertToEther(await totalSupply(gTokenAddress), 8))
          obj.available = parseFloat(convertToEther(await getCash(gTokenAddress), await getUnderlyingDecimal(gTokenAddress)))
          obj.availableUsd = obj.available * parseFloat(obj.underlyingPrice)
          obj.tokenTvl = obj.totalMarketSizeUsd
          return obj
        }
      }
      catch (err) {
        console.log(err);
      }
    }
    data.push(getUserData(i))
  }
  data = await Promise.all(data)
  const suppliedAssets: any = data.filter((e: any) => e.isSupplying)
  const borrowedAssets: any = data.filter((e: any) => e.isBorrowing)
  return { data, suppliedAssets, borrowedAssets }
}

export const getAquaPrice = async () => {
  const lpAddress = AQUA_BNBLPAddress
  const bnbPrice = await getBnbPrice()
  const reserves = await getReserves(lpAddress)
  const reserve0 = reserves['_reserve0']
  const reserve1 = reserves['_reserve1']
  const bnbPerAQUA = reserve1 / reserve0
  const aquafinalPrice = bnbPerAQUA * bnbPrice
  // console.log("aquafinalPrice", aquafinalPrice)
  return aquafinalPrice
}

export const getGammaPrice = async () => {
  const lpAddress = GAMMA_BNBLPAddress
  const bnbPrice = await getBnbPrice()
  const reserves = await getReserves(lpAddress)
  const reserve0 = reserves['_reserve0']
  const reserve1 = reserves['_reserve1']
  const bnbPerGAMMA = reserve1 / reserve0
  const gammafinalPrice = bnbPerGAMMA * bnbPrice
  //console.log("gammafinalPrice", gammafinalPrice)
  return gammafinalPrice
}

export const getBNBxPrice = async () => {
  const lpAddress = "0x6c83E45fE3Be4A9c12BB28cB5BA4cD210455fb55" //bnb-bnbx lp
  const bnbPrice = await getBnbPrice()
  const reserves = await getReserves(lpAddress)
  const reserve0 = reserves['_reserve0']
  const reserve1 = reserves['_reserve1']
  const bnbPerBNBx = reserve1 / reserve0
  const BNBxFinalPrice = bnbPerBNBx * bnbPrice
  // console.log("gammafinalPrice", gammafinalPrice)
  return BNBxFinalPrice;
}

export const getDiscountLevel = async (userAddress: string) => {
  try {
    const inst = await selectInstance(instType.DL, discountLevelAddress)
    const discount = parseFloat(await inst.methods.returnDiscountPercentage(userAddress).call())
    let level = 0

    if (discount === level3Discount) {
      level = 3
    } else if (discount === level2Discount) {
      level = 2
    } else if (discount === level1Discount) {
      level = 1
    }

    return {
      level: level,
      discount: discount / 100,
    }
  }
  catch (err) {
    console.log(err)
    return {
      level: 0,
      discount: 0,
    }
  }
}

export const getBorrowLimitData = async (userAddress: string) => {
  let borrowLimit: any = 0
  borrowLimit = userAddress !== null ? await getBorrowAndUsedLimit(userAddress) : 0
  let result: any = {}
  result.availableCredit = borrowLimit.borrowLimit - borrowLimit.totalBorrowed
  result.borrowLimitInUsd = borrowLimit.borrowLimit
  result.borrowLimitUsedCurr = borrowLimit.usedBorrowLimitInPercentage //in percentage
  result.userTotalBorrowBal = userAddress !== null ? await getTotalBorrowBalance(userAddress) : 0
  result.remainingBorrowBalUsd = parseFloat(result.borrowLimitInUsd) - parseFloat(result.userTotalBorrowBal)
  return result;
}

export const getPendingData = async (userAddress: string) => {
  let pendingGamma: any = 0
  let gammaPrice: any = 0
  let pendingGammaUsd: any = 0
  try {
    // pendingGamma = userAddress !== null ? await getPendingRewards(userAddress) : 0
    let timestamp_after_normal = new Date().getTime();
    
    pendingGamma = userAddress !== null ? await getPendingRewardsUsingMulticall(userAddress) : 0
    let timestamp_after_multical = new Date().getTime();
    // console.log("time taken for multical batch", timestamp_after_multical - timestamp_after_normal, pendingGamma);

    gammaPrice = await getTokenPrice(gGamma)
    pendingGammaUsd = parseFloat(pendingGamma) * parseFloat(gammaPrice)
  }
  catch (error) {
    console.log("error==>", error)
  }
  finally {
    const finalData = {
      pendingGamma: pendingGamma,
      gammaPrice: gammaPrice,
      pendingGammaUsd: pendingGammaUsd
    }
    return finalData
  }
}

export const getGammaAndAquaPrice = async() => {
  let res = {
    aquaPrice: 0,
    gammaPrice: 0
  };
  res.aquaPrice = await getAquaPrice();
  res.gammaPrice = await getGammaPrice();
  return res;
}

export const getTotalPlatformData = async (userAddress: string) => {
  try {
    //userAddress = "0xD1Ec391627c9E2Fb0c570Da876Bc75dF23c42BEB"
    const getMarketLiquidityBalData = await getMarketLiquidityData()
    let diffToUpperLevel: any = 0
    let upperLevel: any = 0
    const levelData = userAddress !== null ? await getDiscountLevel(userAddress) : {
      level: 0,
      discount: 0,
    }
    let result: any = {
      currentLevel: 0,
      discountRate: 0,
      availaibleCredit: 0,
      userGammaBalance: 0,
      userGammaBalanceInUsd: 0,
      totalMarketSize: 0,
      userTotalSupplyBal: 0,
      userTotalBorrowBal: 0,
      userNetApyWithoutGamma: 0,
      userNetApyWithGamma: 0,
      userStakeRatio: 0,
      gammaPrice: 0,
      aquaPrice: 0,
      pendingGamma: 0,
    }
    const total = userAddress !== null ? await getTotalSupplyBalance(userAddress) : 0
    let userGammaSupplied = total[Object.keys(total).filter((e) => e == gGamma)[0]]
    userGammaSupplied = userGammaSupplied === undefined ? 0 : userGammaSupplied
    const userTotalSuppliedWithoutCollateral = parseFloat(total.suppliedAmountWithoutCollateral)

    if (levelData.level === 3) {
      diffToUpperLevel = 0
      upperLevel = 3
    }
    else if (levelData.level === 2) {
      diffToUpperLevel = (10 / 100) * userTotalSuppliedWithoutCollateral - userGammaSupplied
      diffToUpperLevel = diffToUpperLevel / 0.9
      upperLevel = 3
    }
    else if (levelData.level === 1) {
      diffToUpperLevel = (5 / 100) * userTotalSuppliedWithoutCollateral - userGammaSupplied
      diffToUpperLevel = diffToUpperLevel / 0.95
      upperLevel = 2
    }
    else if (userTotalSuppliedWithoutCollateral !== 0) {
      diffToUpperLevel = (1 / 100) * userTotalSuppliedWithoutCollateral - userGammaSupplied
      diffToUpperLevel = diffToUpperLevel / 0.99
      upperLevel = 1
    }
    else {
      diffToUpperLevel = 0
      upperLevel = 1
    }

    let borrowLimit: any = 0
    borrowLimit = userAddress !== null ? await getBorrowAndUsedLimit(userAddress) : 0
    result.availableCredit = borrowLimit.borrowLimit - borrowLimit.totalBorrowed
    result.borrowLimitInUsd = borrowLimit.borrowLimit
    result.borrowLimitUsedCurr = borrowLimit.usedBorrowLimitInPercentage //in percentage
    result.userTotalBorrowBal = userAddress !== null ? await getTotalBorrowBalance(userAddress) : 0
    result.remainingBorrowBalUsd = parseFloat(result.borrowLimitInUsd) - parseFloat(result.userTotalBorrowBal)
    result.gammaPrice = await getGammaPrice()
    result.diffToUpperLevel = parseFloat(diffToUpperLevel).toFixed(5)
    result.currentLevel = levelData.level
    result.upperLevel = upperLevel
    result.discountRate = levelData.discount
    result.userStakeRatio =
      (userTotalSuppliedWithoutCollateral !== 0 ? userGammaSupplied / userTotalSuppliedWithoutCollateral : 0)
        * 100 >= 100 ? 100 : (userTotalSuppliedWithoutCollateral !== 0 ? userGammaSupplied / userTotalSuppliedWithoutCollateral : 0) * 100
    result.aquaPrice = await getAquaPrice()
    result.userGammaBalance = userAddress !== null ? parseFloat(convertToEther(await balanceOfGtoken(userAddress, gammaAddress), 18)) + (userGammaSupplied / result.gammaPrice) : 0
    result.userGammaBalanceInUsd = result.userGammaBalance * (result.gammaPrice)
    result.totalMarketSize = getMarketLiquidityBalData.reduce((a: any, b: any) => a + b, 0)
    result.userTotalSupplyBal = total.total
    result.userTotalBorrowBal = userAddress !== null ? await getTotalBorrowBalance(userAddress) : 0
    result.userNetApyWithGamma = userAddress !== null ? noExponents((await getNetApy(userAddress)).apyWithGamma) : 0
    result.userNetApyWithoutGamma = userAddress !== null ? noExponents((await getNetApy(userAddress)).apyWithoutGamma) : 0
    return result
  }
  catch (err) {
    console.log(err)
  }
}



export const tokenApprove = async (underlyingAddress: string, gTokenAddress: string, userAddress: string) => {

  try {
    const TOKEN_INSTANCE = await selectInstance(instType.PANCAKELP, underlyingAddress)
    await TOKEN_INSTANCE.methods
      .approve(gTokenAddress, MAX_INT)
      .send({ from: userAddress })
      .once('transactionHash', function (res: any) { })
      .once('confirmation', function (confNumber: any, receipt: any) { })
      .on('error', function (error: any) { })
  } catch (error) { }

}

const marketData = (topic0: string) => {
  return `https://api.bscscan.com/api?module=logs&action=getLogs&fromBlock=0&toBlock=latest&address=0x0F6Bc276b3b61AAEa65761c92cb01d87A5FCd026&topic0=${topic0}&apikey=W3F3Y9G8FCC76MNRM1WF9UD4JGW61V2CB6`
}

const aqua_burn = async () => {

  const url = "https://api.bscscan.com/api?module=logs&action=getLogs&fromBlock=12529642&toBlock=12558442&address=0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991&topic0=0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef&apikey=W3F3Y9G8FCC76MNRM1WF9UD4JGW61V2CB6"

  const data = (await axios.get(url)).data['result']

  // wallet.web3.eth.getTransaction("0x9dd056666bf6de9d8b7399e881e04c849c61f15d136e1ffe7b28f04f07be62ce", function (error:any, result:any){
  //   console.log(result);
  // });

  // data.forEach((element:any) => {
  //   console.log(element.topics[0] === "0x000000000000000000000000000000000000000000000000000000000000dead")
  // });

  // console.log(data)
}

const getFinalUsersInMarket = async () => {

  let enterMarketObj: any = {}
  let exitMarketObj: any = {}

  const accountsInEnterMarket: any = (await axios.get(marketData(enterMarketTopic0))).data['result']
  const accountsInExitMarket: any = (await axios.get(marketData(exitMarketTopic0))).data['result']

  accountsInEnterMarket.forEach(async (element: any) => {
    const data = element.data
    const timeStamp = parseInt(element.timeStamp)
    const gToken = `0x${data.slice(26, 66)}`.toLowerCase()
    const user = `0x${data.slice(90, 130)}`.toLowerCase()

    if (enterMarketObj[user] === undefined) {
      let obj: any = {}
      obj[gToken] = timeStamp
      enterMarketObj[user] = obj
    } else {
      enterMarketObj[user][gToken] = timeStamp
    }
  })

  accountsInExitMarket.forEach((element: any) => {
    const data = element.data
    const timeStamp = parseInt(element.timeStamp)
    const gToken = `0x${data.slice(26, 66)}`.toLowerCase()
    const user = `0x${data.slice(90, 130)}`.toLowerCase()

    if (exitMarketObj[user] === undefined) {
      let obj: any = {}
      obj[gToken] = timeStamp
      exitMarketObj[user] = obj
    } else {
      exitMarketObj[user][gToken] = timeStamp
    }
  })

  let finalUsersInMarkets: any = {}
  const usersInEnterMarket = Object.keys(enterMarketObj)

  if (usersInEnterMarket.length > 0) {
    usersInEnterMarket.forEach(async (user) => {
      const marketsOfUser = Object.keys(enterMarketObj[user])

      marketsOfUser.forEach(async (market) => {
        const enterTime = enterMarketObj[user][market]

        if (exitMarketObj[user] !== undefined && (exitMarketObj[user][market] === undefined || exitMarketObj[user][market] < enterTime)) {
          if (finalUsersInMarkets[user] === undefined) {
            let obj: any = {}
            obj[market] = enterMarketObj[user][market]
            finalUsersInMarkets[user] = obj
          } else {
            finalUsersInMarkets[user][market] = enterMarketObj[user][market]
          }
        } else if (exitMarketObj[user] === undefined) {
          finalUsersInMarkets[user] = enterMarketObj[user]
        }
      })
    })
  }

  return finalUsersInMarkets;
}

export const getLiquidityDataOfUser = async (liquidator: string, borrower: string, market: string, seizeMarket: string) => {

  const user = borrower;
  const health = await getHealth(user)
  const unhealthy = parseFloat((await getAccountLiquidity(user, gammatrollerAddress))['2']) > 0 ? true : false
  const borrowBal = await getBorrowBalance(market, user)

  const closeFactor = parseFloat(convertToEther(await closeFactorMantissa(gammatrollerAddress), 18))

  if (borrowBal > 0) {

    const underlyingToken = await underlying(market)
    const repayAssetSymbol = underlyingToken === bnbAddress ? 'BNB' : await symbol(underlyingToken)
    const repayAssetBal =
      liquidator !== null
        ? underlyingToken !== bnbAddress
          ? convertToEther(await balanceOfGtoken(liquidator, underlyingToken), await decimals(underlyingToken))
          : convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
        : 0
    const repayAssetPrice = (await getTokenPrice(market))

    let maxRepayAmount = closeFactor * parseFloat(borrowBal)
    const supplyBal = await getSupplyBalance(seizeMarket, user)
    const collateralMarket = await markets(seizeMarket, gammatrollerAddress)
    const collateralFactor = convertToEther(collateralMarket.collateralFactorMantissa, 18) * 100

    if (supplyBal > 0 && collateralFactor > 0) {

      const underlyingPrice = await getTokenPrice(seizeMarket)
      const seizeUnderlying = await underlying(seizeMarket)
      const seizeSymbol = seizeUnderlying === bnbAddress ? 'BNB' : await symbol(seizeUnderlying)

      let maxSeized = convertToEther(

        (await liquidateCalculateSeizeTokens(market, seizeMarket, convertToWei(maxRepayAmount, await getUnderlyingDecimal(market)), gammatrollerAddress))['1'], 8)

      const liquidIncentive = parseFloat(convertToEther(await liquidationIncentiveMantissa(gammatrollerAddress), 18))

      maxSeized = ((liquidIncentive) < 1) ? (parseFloat(maxSeized) * (1 + liquidIncentive)) : (parseFloat(maxSeized) * (liquidIncentive))
      maxSeized = (1 / (underlyingPrice)) * parseFloat(maxSeized)

      let maxSeizedInDollar = maxSeized * underlyingPrice
      let maxRepayInDollar = maxRepayAmount * repayAssetPrice

      if (maxSeizedInDollar < maxRepayInDollar) {

        maxRepayInDollar = ((liquidIncentive) < 1) ? ((maxSeizedInDollar) / (1 + liquidIncentive)) : ((maxRepayInDollar) / (liquidIncentive))
        maxRepayAmount = (1 / repayAssetPrice) * maxRepayInDollar

      } else {

        maxSeizedInDollar = ((liquidIncentive) < 1) ? ((maxRepayInDollar) * (1 + liquidIncentive)) : ((maxRepayInDollar) * (liquidIncentive))
        maxSeized = (1 / underlyingPrice) * maxSeizedInDollar

      }

      return ({
        address: user,
        health: health,
        unhealthy: unhealthy,
        seizeAsset: seizeMarket,
        maxAmountSeized: maxSeized,
        maxSeizedInDollar: maxSeizedInDollar,
        maxRepayAmount: maxRepayAmount,
        maxRepayInDollar: maxRepayInDollar,
        seizeAssetSymbol: seizeSymbol,
        seizeAssetIcon: getTokenIcon(seizeSymbol),
        market: market,
        borrowBalance: borrowBal,
        repayAssetSymbol: repayAssetSymbol,
        repayAssetBal: repayAssetBal,
        repayAssetIcon: getTokenIcon(repayAssetSymbol),
        seizeAssetBal:
          liquidator !== null
            ? (underlyingToken !== bnbAddress
              ? convertToEther(await balanceOfGtoken(liquidator, underlyingToken), await decimals(underlyingToken))
              : convertToEther(await wallet.web3.eth.getBalance(liquidator), 18))
            : 0,
      })
    }
  }
  return null
}

export const getLiquidatedAccounts = async (liquidator: string) => {


  const accountsInEnterMarket: any = (await axios.get(`https://api.bscscan.com/api?module=logs&action=getLogs&fromBlock=0&toBlock=latest&address=${gammatrollerAddress}&topic0=${enterMarketTopic0}&apikey=W3F3Y9G8FCC76MNRM1WF9UD4JGW61V2CB6`)).data['result']
  let allUsersInMarket: any = [];

  accountsInEnterMarket.forEach(async (element: any) => {
    const data = element.data
    const user = `0x${data.slice(90, 130)}`.toLowerCase()
    allUsersInMarket.push(user.toString());
  })

  let withoutDuplicates = [...new Set(allUsersInMarket)];

  let i = 0;
  let unhealthyAccounts: any = []
  try {

    const checkerInst = await selectInstance(instType.liquidationChecker, liquidationChecker);

    for (; i < withoutDuplicates.length; i++) {

      let data: any = withoutDuplicates[i];
      let input: any = [];
      input.push(data.toString());
      const res = await checkerInst.methods.checker(input).call();

      if (res.length > 0)
        unhealthyAccounts.push(data.toString())
    }
  }
  catch (e) {
    console.log(e);
    console.log("unhealthy accounts checker : ", withoutDuplicates[i]);
  }



  let liquidatedAccounts: any = []
  const users = unhealthyAccounts

  for (let i = 0; i < users.length; i++) {

    const user = users[i]
    const health = await getHealth(user)

    if (+health < 3) {
      const assetsIn = await getAssetsIn(user, gammatrollerAddress);

      let supplyAsset = null;
      let borrowAsset = null;
      let assetsToRepayList = [];
      let assetsToSeizeList = [];
      for (let i = 0; i < assetsIn.length; i++) {
        const asset = assetsIn[i];

        const collateralMarket = await markets(asset, gammatrollerAddress)
        const collateralFactor = convertToEther(collateralMarket.collateralFactorMantissa, 18) * 100
        const _underlying = await underlying(asset);
        const _symbol = _underlying === bnbAddress ? 'BNB' : await symbol(_underlying)

        if (collateralFactor > 0) {

          const supplyBal = await getSupplyBalance(asset, user);

          if (supplyAsset === null) {

            supplyAsset = (supplyBal !== 0) ? asset : null
          }

          if (supplyBal > 0) {

            assetsToSeizeList.push({

              name: _symbol,
              icon: getTokenIcon(_symbol),
              value: liquidator !== null
                ? _underlying !== bnbAddress
                  ? convertToEther(await balanceOfGtoken(liquidator, _underlying), await decimals(_underlying))
                  : convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
                : 0,
              address: asset,
              usdValue: (liquidator !== null
                ? _underlying !== bnbAddress
                  ? convertToEther(await balanceOfGtoken(liquidator, _underlying), await decimals(_underlying))
                  : convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
                : 0) * (await getTokenPrice(asset))
            })

          }

        }
        const borrowBal = parseFloat(await getBorrowBalance(asset, user));

        if (borrowAsset === null) {

          borrowAsset = (borrowBal !== 0) ? asset : null
        }

        if (borrowBal > 0) {
          assetsToRepayList.push({
            name: _symbol,
            icon: getTokenIcon(_symbol),
            value: liquidator !== null
              ? _underlying !== bnbAddress
                ? convertToEther(await balanceOfGtoken(liquidator, _underlying), await decimals(_underlying))
                : convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
              : 0,
            address: asset,
            usdValue: (liquidator !== null
              ? _underlying !== bnbAddress
                ? convertToEther(await balanceOfGtoken(liquidator, _underlying), await decimals(_underlying))
                : convertToEther(await wallet.web3.eth.getBalance(liquidator), 18)
              : 0) * (await getTokenPrice(asset))
          })
        }
      }
      const res = borrowAsset !== null ? await getLiquidityDataOfUser(liquidator, user, borrowAsset, supplyAsset) : null;

      if (res !== null) {
        liquidatedAccounts.push({ res, assetsToRepayList, assetsToSeizeList })
      }

    }
  }
  //console.log(liquidatedAccounts)
  return liquidatedAccounts
}



const getHealth = async (user: string) => {

  const collat = await getTotalSupplyBalanceIncludingCollateral(user);
  const borrow = await getTotalBorrowBalance(user);

  if(borrow != 0){
    return (collat/borrow);
  }

  return Math.max;

}


export const checkErrors = async (data: any, userAddress: any, type: number, inputAmount: string, platFormData?: any) => {
  switch (type) {
    case Type.supply: {
      if (parseFloat(inputAmount) > parseFloat(data.walletBal)) {
        return {
          reason: 'Input Amount Exceed Wallet Balance',
          proceed: false,
        }
      }
      return {
        reason: 'Input Amount Is Under Wallet Balance',
        proceed: true,
      }
    }
    case Type.withdraw: {
      const redeemTokens: any = (parseFloat(convertToWei(inputAmount, 1e36)) / (await exchangeRateStored(data.address))).toFixed(0)
      const hypoData = await getHypotheticalAccountLiquidity(userAddress, data.address, redeemTokens, '0', gammatrollerAddress)

      if (parseFloat(inputAmount) > parseFloat(data.maxWithdraw)) {
        if (parseFloat(hypoData['2']) > 0) {
          return {
            reason: 'Insufficient Collateral',
            proceed: false,
          }
        }
        else if (inputAmount > data.available) {
          return {
            reason: 'Cannot withdraw more than availaible in market',
            proceed: false,
          }
        } else
          return {
            reason: 'Cannot withdraw more than supplied',
            proceed: false,
          }
      }
      return {
        reason: 'Input Amount Is Under Collateral Value',
        proceed: true,
      }
    }
    case Type.borrow: {
      const borrowTokens: any = convertToWei(inputAmount, await decimals(await underlying(data.address)))
      const hypoData = await getHypotheticalAccountLiquidity(userAddress, data.address, '0', borrowTokens, gammatrollerAddress)
      const available: any = data.availableUsd / data.underlyingPrice
      if (parseFloat(inputAmount) > parseFloat(available)) {
        return {
          reason: 'Insufficient Market Liquidity',
          proceed: false,
        }
      }
      else if (parseFloat(inputAmount) >
        (parseFloat(platFormData.borrowLimitInUsd) / data.underlyingPrice - parseFloat(platFormData.userTotalBorrowBal) / data.underlyingPrice)) {
        return {
          reason: 'Borrow Limit Exceed',
          proceed: false,
        }
      }

      else if (parseFloat(hypoData['2']) > 0 || platFormData !== undefined &&
        ((parseFloat(platFormData.userTotalBorrowBal) / data.underlyingPrice) + parseFloat(inputAmount)) >
        (0.9 * parseFloat(platFormData.borrowLimitInUsd) / data.underlyingPrice)) {
        return {
          reason: 'Beware of Liquidation Risk',
          proceed: false,
        }
      }
      return {
        reason: 'Input Amount Is Under Collateral Value',
        proceed: true,
      }
    }
    case Type.repay: {
      if (parseFloat(inputAmount) > parseFloat(data.walletBal)) {
        return {
          reason: 'Input Amount Exceed Wallet Balance',
          proceed: false,
        }
      }
      return {
        reason: 'Input Amount Is Under Wallet Balance',
        proceed: true,
      }
    }

    default:
      return false
  }
}


export const getApyOnLevelSelectAtSupply = async (gTokenAddress: string, level: number) => {

  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365

    const _supplyRatePerBlock = convertToEther(await supplyRatePerBlock(gTokenAddress), 18)
    let supplyApy = (Math.pow(_supplyRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100

    const gammaSupplyApy = await getGammaSupplyApy(gTokenAddress);

    const totalBorrows_ = convertToEther(await totalBorrows(gTokenAddress), await getUnderlyingDecimal(gTokenAddress));

    const discount = level === 0 ? 0
      : (level === 1 ? 2.5 : (level === 2 ? 10 : 25))

    if ((gTokenAddress.toLowerCase() !== gGamma.toLowerCase()) &&
      totalBorrows_ > 0) {
      const diff = await differenceApy(gTokenAddress)
      supplyApy += (discount * diff) / 100
    }

    const finalSupplyApy = parseFloat(gammaSupplyApy) + supplyApy
    return {
      supplyApy: finalSupplyApy,
      supplyApy24hr: finalSupplyApy / 365,
      address: gTokenAddress
    }
  } catch (e) {
    console.log(e)
    return {
      supplyApy: 0,
      supplyApy24hr: 0,
      address: gTokenAddress
    }
  }

}

export const getApyOnLevelSelectAtBorrow = async (gTokenAddress: string, level: number) => {

  try {
    const blocksPerDay = 28800 // 13.15 seconds per block
    const daysPerYear = 365

    const _borrowRatePerBlock = convertToEther(await borrowRatePerBlock(gTokenAddress), 18)
    let borrowApy = (Math.pow(_borrowRatePerBlock * blocksPerDay + 1, daysPerYear) - 1) * 100

    const gammaBorrowApy = await getGammaBorrowApy(gTokenAddress);

    const totalBorrows_ = convertToEther(await totalBorrows(gTokenAddress), await getUnderlyingDecimal(gTokenAddress));

    const discount = level === 0 ? 0
      : (level === 1 ? 2.5 : (level === 2 ? 10 : 25))

    if ((gTokenAddress !== pfTokenList[1].address) &&
      totalBorrows_ > 0) {
      const diff = await differenceApy(gTokenAddress)
      borrowApy -= (discount * diff) / 100
    }

    const finalBorrowApy = parseFloat(gammaBorrowApy) - borrowApy
    return {
      borrowApy: finalBorrowApy,
      borrowApy24hr: finalBorrowApy / 365,
      address: gTokenAddress
    }
  } catch (e) {
    console.log(e)
    return {
      borrowApy: 0,
      borrowApy24hr: 0,
      address: gTokenAddress
    }
  }

}

export const getIndividualMarketData = async (gTokenAddress: string) => {

  const supplyApy = await getSupplyApy(gTokenAddress, "");

  const borrowApy = await getBorrowApy(gTokenAddress, "");

  const supplyDistApy = await getGammaSupplyApy(gTokenAddress);

  const borrowDistApy = await getGammaBorrowApy(gTokenAddress);

  const netSupplyRate: any = supplyApy + supplyDistApy;

  const netBorrowRate: any = borrowApy - borrowDistApy;

  const gTokenPrice = await getGTokenPrice(gTokenAddress)

  const underlyingPrice = await getTokenPrice(gTokenAddress)

  const underlyingToken = await underlying(gTokenAddress)

  const gTokenSymbol = await symbol(gTokenAddress)

  let underlyingSymbol = await symbol(underlyingToken)

  underlyingSymbol = underlyingSymbol === "WBNB" ? "BNB" : underlyingSymbol

  const totalSupply_ = convertToEther(await totalSupply(gTokenAddress), 8)

  const totalSupplyInUSd = totalSupply_ * gTokenPrice

  const borrows_ = await totalBorrows(gTokenAddress)

  const totalBorrows_ = convertToEther(borrows_, await getUnderlyingDecimal(gTokenAddress))

  const totalBorrowInUSd = totalBorrows_ * underlyingPrice

  const cash_ = await getCash(gTokenAddress)

  const marketLiquidity = convertToEther(cash_, await getUnderlyingDecimal(gTokenAddress));

  const borrowCaps_: any = convertToEther(await borrowCaps(gTokenAddress, gammatrollerAddress), await getUnderlyingDecimal(gTokenAddress));

  const availableToBorrow: any = borrowCaps_ > 0 ? borrowCaps_ - totalBorrows_ : marketLiquidity - totalBorrows_;

  const factor: any = await markets(gTokenAddress, gammatrollerAddress);

  const collateralFactor: any = await convertToEther(factor.collateralFactorMantissa, 18);

  const marketTokenMinted: any = totalSupply_

  const rate: any = await exchangeRateStored(gTokenAddress);

  const decimal = (Math.pow(10,parseFloat(await getUnderlyingDecimal(gTokenAddress)) + 10));

  const exchangeRate: any = convertToWei(1 / rate, decimal);

  const interestRateModelAddress: any = await interestRateModel(gTokenAddress);

  const util: any = noExponents(convertToEther(await utilizationRate(cash_, borrows_, await totalReserves(gTokenAddress),
    interestRateModelAddress), 18) * 100);

  const noOfSuppliers: number = 0;

  const noOfBorrowers: number = 0;

  const dailyInterestPerDay: number = 0;

  return {
    supplyApy,
    borrowApy,
    borrowDistApy,
    supplyDistApy,
    netSupplyRate,
    netBorrowRate,
    totalSupplyInUSd,
    totalBorrowInUSd,
    underlyingPrice,
    gTokenPrice,
    marketLiquidity,
    availableToBorrow,
    collateralFactor,
    marketTokenMinted,
    exchangeRate,
    gTokenSymbol,
    underlyingSymbol,
    util,
    noOfSuppliers,
    noOfBorrowers,
    dailyInterestPerDay,
  }

}

const getTotalSuppInUsd = async (gTokenAddress: string, blockNumber?: number) => {
  const price = await getGTokenPrice(gTokenAddress, blockNumber);
  const underlyingBal = convertToEther(
    await totalSupply(gTokenAddress, blockNumber),
    8
  );
  return price * parseFloat(underlyingBal);
}

const getTotalBorrowInUsd = async (gTokenAddress: string, blockNumber?: number) => {
  const price = await getTokenPrice(gTokenAddress, blockNumber);
  const underlyingBal = convertToEther(
    await totalBorrows(gTokenAddress, blockNumber),
    await getUnderlyingDecimal(gTokenAddress)
  );
  return price * parseFloat(underlyingBal);
}
