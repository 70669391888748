import { instType, priceOracleAddress, priceOracleAddressOld, selectInstance } from './lendingAbi'
import wallet from 'modules/wallet/wallet'
import { convertToEther } from './BlockChainMethods'
import { zeroAddress } from './SwapDexAbi'



/************************************************Read Only Functions************************************************************/

export const assetPrices = async (assetAddress: string) => {
    try {
        // const inst: any = await selectInstance(instType.priceOracle, priceOracleAddress)
        const inst: any = assetAddress.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() ? await selectInstance(instType.priceOracleOld, priceOracleAddressOld) : await selectInstance(instType.priceOracle, priceOracleAddress) 
        const data = await inst.methods.assetPrices(assetAddress).call()
        return data
    } catch (e) {
        console.log("error in assetPrices", assetAddress, e)
        return 0
    }
}

export const getUnderlyingPrice = async (gTokenAddress: string, blockNumber?:number) => {
    try {
        const inst: any =  gTokenAddress.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() ||gTokenAddress.toLowerCase() == "0x0c6dd143F4b86567d6c21E8ccfD0300f00896442".toLowerCase() ? await selectInstance(instType.priceOracleOld, priceOracleAddressOld) : await selectInstance(instType.priceOracle, priceOracleAddress)
        const data = blockNumber === undefined ? await inst.methods.getUnderlyingPrice(gTokenAddress).call() :
        await inst.methods.getUnderlyingPrice(gTokenAddress).call(undefined,blockNumber)
        return data
    } catch (e) {
        console.log("error in getUnderlyingPrice in blue planet",gTokenAddress , e)
        return 0
    }
}

export const getUnderlyingPriceInfinityVaults = async (gTokenAddress: string,blockNumber?:number) => {
  try {
      const inst: any = await selectInstance(instType.priceOracle, priceOracleAddress);
      const data = blockNumber === undefined ? await inst.methods.getUnderlyingPrice(gTokenAddress).call() :
      await inst.methods.getUnderlyingPrice(gTokenAddress).call(undefined,blockNumber)
      return data
  } catch (e) {
      console.log(e)
      return 0
  }
}


export const getTotalUnderlyingPrice = async(addressArr: any, blockNumber?:number) => {
    let batch:any;
    batch = new wallet.web3.BatchRequest();
    blockNumber = undefined;
    let totalUnderlyingPriceArray:Record<string,number> = {};
    try{
      const getUnderlyingPriceTotal = new Promise(async function(resolve: any, reject){
        // const inst: any = await selectInstance(instType.priceOracle, priceOracleAddress)
        for(let i = 0 ; i < addressArr.length ; i++){
          const inst: any = addressArr[i].address.toLowerCase() == '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442'.toLowerCase() || addressArr[i].address.toLowerCase() == '0xb7eD4A5AF620B52022fb26035C565277035d4FD7'.toLowerCase() ? await selectInstance(instType.priceOracleOld, priceOracleAddressOld) : await selectInstance(instType.priceOracle, priceOracleAddress) 
            batch.add(
              await inst.methods.getUnderlyingPrice(addressArr[i]).call.request({from: zeroAddress},
              (batch_error:any, data:any) => {
                if (batch_error) return reject(batch_error);
                // console.log("gamma price data",parseFloat(convertToEther(data)))
                totalUnderlyingPriceArray[addressArr[i]] = parseFloat(convertToEther(data));
                
                if(i === (addressArr.length - 1))
                resolve();
              })
            )
        }
        batch.execute();
      });
    } catch (error){
      console.log("error",error)
    }
    return totalUnderlyingPriceArray;
}


/********************************************************************************************************************************/

