
import { LoadingTableRow, TokenCont, ShimmerCont, ShimmerBox, ShimmerCircle, ShimmerLine, ShimmerSubLine, MHideBox } from './style'

const TableLoading = () => {
    const arr = [0, 1, 2, 3, 4]

    return (
        <ShimmerCont>
            {
                arr.map((item: any, index: number) => (
                    <LoadingTableRow key={index}>
                        <TokenCont>
                            <ShimmerCircle className="animate" />
                            <div className="token-simmer">
                                <ShimmerLine className="animate" />
                                <ShimmerSubLine className="animate hide-loading" />
                            </div>
                        </TokenCont>
                        <ShimmerBox>
                            <ShimmerLine className="animate" />
                            <ShimmerSubLine className="animate" />
                        </ShimmerBox>
                        <ShimmerBox>
                            <ShimmerLine className="animate" />
                            <ShimmerSubLine className="animate" />
                        </ShimmerBox>
                        <MHideBox>
                            <ShimmerLine className="animate" />
                            <ShimmerSubLine className="animate" />
                        </MHideBox>
                    </LoadingTableRow>
                ))
            }
        </ShimmerCont>)

};

export default TableLoading;