import BNBIcon from 'assets/icons/BNB.png'
import BUSDIcon from 'assets/icons/BUSD.png'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import CAKEIcon from 'assets/icons/CAKE.svg'
import BTCBIcon from 'assets/icons/BTCB.svg'
import USDTIcon from 'assets/icons/USDT.svg'
import USDCIcon from 'assets/icons/USDC.svg'
import DAIIcon from 'assets/icons/DAI.svg'
import USTIcon from 'assets/icons/UST.png'
import ETHIcon from 'assets/icons/ETH.svg'
import FRAXIcon from 'assets/icons/FRAX.svg'
import TUSDIcon from 'assets/icons/TUSD.svg'
import DOTIcon from 'assets/icons/DOT.svg'
import ADAIcon from 'assets/icons/ADA.svg'
import MATIC from 'assets/icons/MATIC.svg'
import SOL from 'assets/icons/SOL.svg'
import LUNA from 'assets/icons/LUNA.png'
import MATICIcon from 'assets/icons/MATIC.svg'
import SOLIcon from 'assets/icons/SOL.svg'
import LUNAIcon from 'assets/icons/LUNA.png'
import aUSTIcon from 'assets/icons/aUST.png'
import AVAXIcon from 'assets/icons/AVAX.svg'
import FTMIcon from 'assets/icons/FTM.svg'
import AtomIcon from 'assets/icons/atom_icon.png'
import XRPIcon from 'assets/icons/XRP.svg'
import LinkIcon from 'assets/icons/LINK.svg'
import whustIcon from 'assets/icons/whust_icon.svg'
import BNBxIcon from 'assets/icons/BNBx.png'
import HayIcon from 'assets/icons/hay_icon.png'

export const bluePfTokenList: any = {
    0: {
        sno: 0,
        address: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29',
        name: 'BNB',
        marketSymbol: 'BNB',
        icon: BNBIcon,
        tokenOrigin: 'Binance Coin',
        blockNumber: 12078744,
        token: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        decimals: 18
    },
    1: {
        sno: 1,
        address: '0xe983cF03314830d9b4d5e87594bB50215438e19a',
        name: 'BUSD',
        marketSymbol: 'BUSD',
        icon: BUSDIcon,
        tokenOrigin: 'Binance USD',
        blockNumber: 12127442,
        token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        decimals: 18
    },
    2: {
        sno: 2,
        address: '0xCD221e1504442671671D3330CB8E916a5EDc3FC7',
        name: 'GAMMA',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12160319,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18,
    },
    3: {
        sno: 3,
        address: '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5',
        name: 'AQUA',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12160319,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18,
    },
    4: {
        sno: 4,
        address: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46',
        name: 'USDC',
        marketSymbol: 'USDC',
        icon: USDCIcon,
        tokenOrigin: 'USD Coin',
        blockNumber: 12160319,
        token: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        decimals: 18
    },
    5:{
        sno: 5,
        address: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8',
        name: 'USDT',
        marketSymbol: 'USDT',
        icon: USDTIcon,
        tokenOrigin: 'Tether USD',
        blockNumber: 12160319,
        token: '0x55d398326f99059ff775485246999027b3197955',
        decimals: 18
    },
    6: {
        sno: 6,
        address: '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
        name: 'FRAX',
        marketSymbol: 'FRAX',
        icon: FRAXIcon,
        tokenOrigin: 'FRAX Finance',
        blockNumber: 12160319,
        token: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
        decimals: 18
    },
    7: {
        sno: 7,
        address: '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
        name: 'DAI',
        marketSymbol: 'DAI',
        icon: DAIIcon,
        tokenOrigin: 'Maker DAO',
        blockNumber: 12160319,
        token: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        decimals: 18
    },
    8:{
        sno: 8,
        address: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050',
        name: 'BTCB',
        marketSymbol: 'BTCB',
        icon: BTCBIcon,
        tokenOrigin: 'Bitcoin',
        blockNumber: 12159960,
        token: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        decimals: 18
    },
    9:{
        sno: 9,
        address: '0x666Fa13501ce90c6f8c078313bd72f1f95197c5d',
        name: 'ETH',
        marketSymbol: 'ETH',
        icon: ETHIcon,
        tokenOrigin: 'Ethereum',
        blockNumber: 12160319,
        token: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        decimals: 18
    },
    10:{
        sno: 10,
        address: '0xc019728cccdc45cdA6a051802878c00e563C1E78',
        name: 'AVAX',
        marketSymbol: 'AVAX',
        icon: AVAXIcon,
        tokenOrigin: 'Avalanche',
        blockNumber: 12160319,
        token: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
        decimals: 18
    },
    11: {
        sno: 11,
        address: '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
        name: 'CAKE',
        marketSymbol: 'CAKE',
        icon: CAKEIcon,
        tokenOrigin: 'PancakeSwap',
        blockNumber: 12160319,
        token: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        decimals: 18
    },
    12: {
        sno: 12,
        address: '0x407e49480643E338ae36612b220f0607e90429Dc',
        name: 'ADA',
        marketSymbol: 'ADA',
        icon: ADAIcon,
        tokenOrigin: 'Cardano',
        blockNumber: 12160319,
        token: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
        decimals: 18
    },
    13: {
        sno: 13,
        address: '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442',
        name: 'GAMMA',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12160319,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18,
    },
    14: {
        sno: 14,
        address: '0xb7eD4A5AF620B52022fb26035C565277035d4FD7',
        name: 'AQUA',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12160319,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18,
    },
    15: {
        sno: 15,
        address: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
        name: 'XRP',
        marketSymbol: 'XRP',
        icon: XRPIcon,
        tokenOrigin: 'XRP',
        blockNumber: 12160319,
        token: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
        decimals: 18,
    },
    16: {
        sno: 16,
        address: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428',
        name: 'BNBx',
        marketSymbol: 'BNBx',
        icon: BNBxIcon,
        tokenOrigin: 'Stader',
        blockNumber: 12160319,
        token: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
        decimals: 18,
    },
}

export const bluePfTokenListWithoutAquaGamma: any = {
    0: {
        sno: 0,
        address: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29',
        name: 'BNB',
        marketSymbol: 'BNB',
        icon: BNBIcon,
        tokenOrigin: 'Binance Coin',
        blockNumber: 12078744,
        token: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        decimals: 18
    },
    1: {
        sno: 1,
        address: '0xe983cF03314830d9b4d5e87594bB50215438e19a',
        name: 'BUSD',
        marketSymbol: 'BUSD',
        icon: BUSDIcon,
        tokenOrigin: 'Binance USD',
        blockNumber: 12127442,
        token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        decimals: 18
    },
    
    2: {
        sno: 2,
        address: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46',
        name: 'USDC',
        marketSymbol: 'USDC',
        icon: USDCIcon,
        tokenOrigin: 'USD Coin',
        blockNumber: 12160319,
        token: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        decimals: 18
    },
    3:{
        sno: 3,
        address: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8',
        name: 'USDT',
        marketSymbol: 'USDT',
        icon: USDTIcon,
        tokenOrigin: 'Tether USD',
        blockNumber: 12160319,
        token: '0x55d398326f99059ff775485246999027b3197955',
        decimals: 18
    },
    4: {
        sno: 4,
        address: '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
        name: 'FRAX',
        marketSymbol: 'FRAX',
        icon: FRAXIcon,
        tokenOrigin: 'FRAX Finance',
        blockNumber: 12160319,
        token: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
        decimals: 18
    },
    5: {
        sno: 5,
        address: '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
        name: 'DAI',
        marketSymbol: 'DAI',
        icon: DAIIcon,
        tokenOrigin: 'Maker DAO',
        blockNumber: 12160319,
        token: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        decimals: 18
    },
    6:{
        sno: 6,
        address: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050',
        name: 'BTCB',
        marketSymbol: 'BTCB',
        icon: BTCBIcon,
        tokenOrigin: 'Bitcoin',
        blockNumber: 12159960,
        token: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        decimals: 18
    },
    7:{
        sno: 7,
        address: '0x666Fa13501ce90c6f8c078313bd72f1f95197c5d',
        name: 'ETH',
        marketSymbol: 'ETH',
        icon: ETHIcon,
        tokenOrigin: 'Ethereum',
        blockNumber: 12160319,
        token: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        decimals: 18
    },
    8:{
        sno: 8,
        address: '0xc019728cccdc45cdA6a051802878c00e563C1E78',
        name: 'AVAX',
        marketSymbol: 'AVAX',
        icon: AVAXIcon,
        tokenOrigin: 'Avalanche',
        blockNumber: 12160319,
        token: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
        decimals: 18
    },
    9: {
        sno: 9,
        address: '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
        name: 'CAKE',
        marketSymbol: 'CAKE',
        icon: CAKEIcon,
        tokenOrigin: 'PancakeSwap',
        blockNumber: 12160319,
        token: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        decimals: 18
    },
    10: {
        sno: 10,
        address: '0x407e49480643E338ae36612b220f0607e90429Dc',
        name: 'ADA',
        marketSymbol: 'ADA',
        icon: ADAIcon,
        tokenOrigin: 'Cardano',
        blockNumber: 12160319,
        token: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
        decimals: 18
    },
    11: {
        sno: 11,
        address: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
        name: 'XRP',
        marketSymbol: 'XRP',
        icon: XRPIcon,
        tokenOrigin: 'XRP',
        blockNumber: 12160319,
        token: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
        decimals: 18,
    },
    12: {
        sno: 12,
        address: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428',
        name: 'BNBx',
        marketSymbol: 'BNBx',
        icon: BNBxIcon,
        tokenOrigin: 'Stader',
        blockNumber: 12160319,
        token: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
        decimals: 18,
    },
}

export const redPfTokenList: any = {
    0: {
        sno: 0,
        address: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29',
        name: 'BNB',
        marketSymbol: 'BNB',
        icon: BNBIcon,
        tokenOrigin: 'Binance Coin',
        blockNumber: 12078744,
        token: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        decimals: 18
    },
    1: {
        sno: 1,
        address: '0xe983cF03314830d9b4d5e87594bB50215438e19a',
        name: 'BUSD',
        marketSymbol: 'BUSD',
        icon: BUSDIcon,
        tokenOrigin: 'Binance USD',
        blockNumber: 12127442,
        token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        decimals: 18
    },
    2: {
        sno: 2,
        address: '0xCD221e1504442671671D3330CB8E916a5EDc3FC7',
        name: 'GAMMA',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12160319,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18,
    },
    3: {
        sno: 3,
        address: '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5',
        name: 'AQUA',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12160319,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18,
    },
    4: {
        sno: 4,
        address: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46',
        name: 'USDC',
        marketSymbol: 'USDC',
        icon: USDCIcon,
        tokenOrigin: 'USD Coin',
        blockNumber: 12160319,
        token: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        decimals: 18
    },
    5:{
        sno: 5,
        address: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8',
        name: 'USDT',
        marketSymbol: 'USDT',
        icon: USDTIcon,
        tokenOrigin: 'Tether USD',
        blockNumber: 12160319,
        token: '0x55d398326f99059ff775485246999027b3197955',
        decimals: 18
    },
    6: {
        sno: 6,
        address: '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
        name: 'FRAX',
        marketSymbol: 'FRAX',
        icon: FRAXIcon,
        tokenOrigin: 'FRAX Finance',
        blockNumber: 12160319,
        token: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
        decimals: 18
    },
    7: {
        sno: 7,
        address: '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
        name: 'DAI',
        marketSymbol: 'DAI',
        icon: DAIIcon,
        tokenOrigin: 'Maker DAO',
        blockNumber: 12160319,
        token: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        decimals: 18
    },
    8:{
        sno: 8,
        address: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050',
        name: 'BTCB',
        marketSymbol: 'BTCB',
        icon: BTCBIcon,
        tokenOrigin: 'Bitcoin',
        blockNumber: 12159960,
        token: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        decimals: 18
    },
    9:{
        sno: 9,
        address: '0x666Fa13501ce90c6f8c078313bd72f1f95197c5d',
        name: 'ETH',
        marketSymbol: 'ETH',
        icon: ETHIcon,
        tokenOrigin: 'Ethereum',
        blockNumber: 12160319,
        token: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        decimals: 18
    },
    10:{
        sno: 10,
        address: '0xc019728cccdc45cdA6a051802878c00e563C1E78',
        name: 'AVAX',
        marketSymbol: 'AVAX',
        icon: AVAXIcon,
        tokenOrigin: 'Avalanche',
        blockNumber: 12160319,
        token: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
        decimals: 18
    },
    11: {
        sno: 11,
        address: '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
        name: 'CAKE',
        marketSymbol: 'CAKE',
        icon: CAKEIcon,
        tokenOrigin: 'PancakeSwap',
        blockNumber: 12160319,
        token: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        decimals: 18
    },
    12: {
        sno: 12,
        address: '0x407e49480643E338ae36612b220f0607e90429Dc',
        name: 'ADA',
        marketSymbol: 'ADA',
        icon: ADAIcon,
        tokenOrigin: 'Cardano',
        blockNumber: 12160319,
        token: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
        decimals: 18
    },
    13: {
        sno: 13,
        address: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
        name: 'XRP',
        marketSymbol: 'XRP',
        icon: XRPIcon,
        tokenOrigin: 'XRP',
        blockNumber: 12160319,
        token: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
        decimals: 18,
    },
    14: {
        sno: 14,
        address: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428',
        name: 'BNBx',
        marketSymbol: 'BNBx',
        icon: BNBxIcon,
        tokenOrigin: 'Stader',
        blockNumber: 12160319,
        token: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
        decimals: 18,
    },
}

export const pfTokenList: any = {
    0: {
        sno: 0,
        address: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29',
        name: 'BNB',
        marketSymbol: 'BNB',
        icon: BNBIcon,
        tokenOrigin: 'Binance Coin',
        blockNumber: 12078744,
        token: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        decimals: 18
    },
    1: {
        sno: 1,
        address: '0xe983cF03314830d9b4d5e87594bB50215438e19a',
        name: 'BUSD',
        marketSymbol: 'BUSD',
        icon: BUSDIcon,
        tokenOrigin: 'Binance USD',
        blockNumber: 12127442,
        token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        decimals: 18
    },
    2: {
        sno: 2,
        address: '0xCD221e1504442671671D3330CB8E916a5EDc3FC7',
        name: 'GAMMA',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12160319,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18,
    },
    3: {
        sno: 3,
        address: '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5',
        name: 'AQUA',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12160319,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18,
    },
    4: {
        sno: 4,
        address: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46',
        name: 'USDC',
        marketSymbol: 'USDC',
        icon: USDCIcon,
        tokenOrigin: 'USD Coin',
        blockNumber: 12160319,
        token: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        decimals: 18
    },
    5:{
        sno: 5,
        address: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8',
        name: 'USDT',
        marketSymbol: 'USDT',
        icon: USDTIcon,
        tokenOrigin: 'Tether USD',
        blockNumber: 12160319,
        token: '0x55d398326f99059ff775485246999027b3197955',
        decimals: 18
    },
    6: {
        sno: 6,
        address: '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
        name: 'FRAX',
        marketSymbol: 'FRAX',
        icon: FRAXIcon,
        tokenOrigin: 'FRAX Finance',
        blockNumber: 12160319,
        token: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
        decimals: 18
    },
    7: {
        sno: 7,
        address: '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
        name: 'DAI',
        marketSymbol: 'DAI',
        icon: DAIIcon,
        tokenOrigin: 'Maker DAO',
        blockNumber: 12160319,
        token: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        decimals: 18
    },
    8:{
        sno: 8,
        address: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050',
        name: 'BTCB',
        marketSymbol: 'BTCB',
        icon: BTCBIcon,
        tokenOrigin: 'Bitcoin',
        blockNumber: 12159960,
        token: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        decimals: 18
    },
    9:{
        sno: 9,
        address: '0x666Fa13501ce90c6f8c078313bd72f1f95197c5d',
        name: 'ETH',
        marketSymbol: 'ETH',
        icon: ETHIcon,
        tokenOrigin: 'Ethereum',
        blockNumber: 12160319,
        token: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        decimals: 18
    },
    10:{
        sno: 10,
        address: '0xc019728cccdc45cdA6a051802878c00e563C1E78',
        name: 'AVAX',
        marketSymbol: 'AVAX',
        icon: AVAXIcon,
        tokenOrigin: 'Avalanche',
        blockNumber: 12160319,
        token: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
        decimals: 18
    },
    11: {
        sno: 11,
        address: '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
        name: 'CAKE',
        marketSymbol: 'CAKE',
        icon: CAKEIcon,
        tokenOrigin: 'PancakeSwap',
        blockNumber: 12160319,
        token: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        decimals: 18
    },
    12: {
        sno: 12,
        address: '0x407e49480643E338ae36612b220f0607e90429Dc',
        name: 'ADA',
        marketSymbol: 'ADA',
        icon: ADAIcon,
        tokenOrigin: 'Cardano',
        blockNumber: 12160319,
        token: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
        decimals: 18
    },
    13: {
        sno: 13,
        address: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
        name: 'XRP',
        marketSymbol: 'XRP',
        icon: XRPIcon,
        tokenOrigin: 'XRP',
        blockNumber: 12160319,
        token: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
        decimals: 18,
    },
    14: {
        sno: 14,
        address: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428',
        name: 'BNBx',
        marketSymbol: 'BNBx',
        icon: BNBxIcon,
        tokenOrigin: 'Stader',
        blockNumber: 12160319,
        token: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
        decimals: 18,
    },
}

export const pfTokenMarketList: any = {
    0: {
        sno: 0,
        address: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29',
        name: 'BNB',
        marketSymbol: 'BNB',
        icon: BNBIcon,
        tokenOrigin: 'Binance Coin',
        blockNumber: 12078744,
        token: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        decimals: 18
    },
    1: {
        sno: 1,
        address: '0xe983cF03314830d9b4d5e87594bB50215438e19a',
        name: 'BUSD',
        marketSymbol: 'BUSD',
        icon: BUSDIcon,
        tokenOrigin: 'Binance USD',
        blockNumber: 12127442,
        token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        decimals: 18
    },
    2: {
        sno: 2,
        address: '0xCD221e1504442671671D3330CB8E916a5EDc3FC7',
        name: 'GAMMA',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12160319,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18,
    },
    3: {
        sno: 3,
        address: '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5',
        name: 'AQUA',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12160319,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18,
    },
    4: {
        sno: 4,
        address: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46',
        name: 'USDC',
        marketSymbol: 'USDC',
        icon: USDCIcon,
        tokenOrigin: 'USD Coin',
        blockNumber: 12160319,
        token: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        decimals: 18
    },
    5:{
        sno: 5,
        address: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8',
        name: 'USDT',
        marketSymbol: 'USDT',
        icon: USDTIcon,
        tokenOrigin: 'Tether USD',
        blockNumber: 12160319,
        token: '0x55d398326f99059ff775485246999027b3197955',
        decimals: 18
    },
    6: {
        sno: 6,
        address: '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
        name: 'FRAX',
        marketSymbol: 'FRAX',
        icon: FRAXIcon,
        tokenOrigin: 'FRAX Finance',
        blockNumber: 12160319,
        token: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
        decimals: 18
    },
    7: {
        sno: 7,
        address: '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
        name: 'DAI',
        marketSymbol: 'DAI',
        icon: DAIIcon,
        tokenOrigin: 'Maker DAO',
        blockNumber: 12160319,
        token: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        decimals: 18
    },
    8:{
        sno: 8,
        address: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050',
        name: 'BTCB',
        marketSymbol: 'BTCB',
        icon: BTCBIcon,
        tokenOrigin: 'Bitcoin',
        blockNumber: 12159960,
        token: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        decimals: 18
    },
    9:{
        sno: 9,
        address: '0x666Fa13501ce90c6f8c078313bd72f1f95197c5d',
        name: 'ETH',
        marketSymbol: 'ETH',
        icon: ETHIcon,
        tokenOrigin: 'Ethereum',
        blockNumber: 12160319,
        token: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        decimals: 18
    },
    10:{
        sno: 10,
        address: '0xc019728cccdc45cdA6a051802878c00e563C1E78',
        name: 'AVAX',
        marketSymbol: 'AVAX',
        icon: AVAXIcon,
        tokenOrigin: 'Avalanche',
        blockNumber: 12160319,
        token: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
        decimals: 18
    },
    11: {
        sno: 11,
        address: '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
        name: 'CAKE',
        marketSymbol: 'CAKE',
        icon: CAKEIcon,
        tokenOrigin: 'PancakeSwap',
        blockNumber: 12160319,
        token: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        decimals: 18
    },
    12: {
        sno: 12,
        address: '0x407e49480643E338ae36612b220f0607e90429Dc',
        name: 'ADA',
        marketSymbol: 'ADA',
        icon: ADAIcon,
        tokenOrigin: 'Cardano',
        blockNumber: 12160319,
        token: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
        decimals: 18
    },
    13: {
        sno: 13,
        address: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
        name: 'XRP',
        marketSymbol: 'XRP',
        icon: XRPIcon,
        tokenOrigin: 'XRP',
        blockNumber: 12160319,
        token: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
        decimals: 18,
    },
    14: {
        sno: 14,
        address: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428',
        name: 'BNBx',
        marketSymbol: 'BNBx',
        icon: BNBxIcon,
        tokenOrigin: 'Stader',
        blockNumber: 12160319,
        token: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
        decimals: 18,
    },
}

export const marketTokenList: any = {
    0: {
        sno: 0,
        address: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29',
        name: 'BNB',
        marketSymbol: 'BNB',
        icon: BNBIcon,
        tokenOrigin: 'Binance Coin',
        blockNumber: 12078744,
        token: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        decimals: 18
    },
    1: {
        sno: 1,
        address: '0xe983cF03314830d9b4d5e87594bB50215438e19a',
        name: 'BUSD',
        marketSymbol: 'BUSD',
        icon: BUSDIcon,
        tokenOrigin: 'Binance USD',
        blockNumber: 12127442,
        token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        decimals: 18
    },
    2: {
        sno: 2,
        address: '0xCD221e1504442671671D3330CB8E916a5EDc3FC7',
        name: 'GAMMA',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12160319,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18,
    },
    3: {
        sno: 3,
        address: '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5',
        name: 'AQUA',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12160319,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18,
    },
    4: {
        sno: 4,
        address: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46',
        name: 'USDC',
        marketSymbol: 'USDC',
        icon: USDCIcon,
        tokenOrigin: 'USD Coin',
        blockNumber: 12160319,
        token: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        decimals: 18
    },
    5:{
        sno: 5,
        address: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8',
        name: 'USDT',
        marketSymbol: 'USDT',
        icon: USDTIcon,
        tokenOrigin: 'Tether USD',
        blockNumber: 12160319,
        token: '0x55d398326f99059ff775485246999027b3197955',
        decimals: 18
    },
    6: {
        sno: 6,
        address: '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
        name: 'FRAX',
        marketSymbol: 'FRAX',
        icon: FRAXIcon,
        tokenOrigin: 'FRAX Finance',
        blockNumber: 12160319,
        token: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
        decimals: 18
    },
    7: {
        sno: 7,
        address: '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
        name: 'DAI',
        marketSymbol: 'DAI',
        icon: DAIIcon,
        tokenOrigin: 'Maker DAO',
        blockNumber: 12160319,
        token: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        decimals: 18
    },
    8:{
        sno: 8,
        address: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050',
        name: 'BTCB',
        marketSymbol: 'BTCB',
        icon: BTCBIcon,
        tokenOrigin: 'Bitcoin',
        blockNumber: 12159960,
        token: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        decimals: 18
    },
    9:{
        sno: 9,
        address: '0x666Fa13501ce90c6f8c078313bd72f1f95197c5d',
        name: 'ETH',
        marketSymbol: 'ETH',
        icon: ETHIcon,
        tokenOrigin: 'Ethereum',
        blockNumber: 12160319,
        token: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        decimals: 18
    },
    10:{
        sno: 10,
        address: '0xc019728cccdc45cdA6a051802878c00e563C1E78',
        name: 'AVAX',
        marketSymbol: 'AVAX',
        icon: AVAXIcon,
        tokenOrigin: 'Avalanche',
        blockNumber: 12160319,
        token: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
        decimals: 18
    },
    11: {
        sno: 11,
        address: '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
        name: 'CAKE',
        marketSymbol: 'CAKE',
        icon: CAKEIcon,
        tokenOrigin: 'PancakeSwap',
        blockNumber: 12160319,
        token: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        decimals: 18
    },
    12: {
        sno: 12,
        address: '0x407e49480643E338ae36612b220f0607e90429Dc',
        name: 'ADA',
        marketSymbol: 'ADA',
        icon: ADAIcon,
        tokenOrigin: 'Cardano',
        blockNumber: 12160319,
        token: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
        decimals: 18
    },
    13: {
        sno: 13,
        address: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
        name: 'XRP',
        marketSymbol: 'XRP',
        icon: XRPIcon,
        tokenOrigin: 'XRP',
        blockNumber: 12160319,
        token: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
        decimals: 18,
    },
    14: {
        sno: 14,
        address: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428',
        name: 'BNBx',
        marketSymbol: 'BNBx',
        icon: BNBxIcon,
        tokenOrigin: 'Stader',
        blockNumber: 12160319,
        token: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
        decimals: 18,
    },
}

export const pfMarketTokenList: any = {
    0: '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29',
    1: '0xe983cF03314830d9b4d5e87594bB50215438e19a',
    2: '0xCD221e1504442671671D3330CB8E916a5EDc3FC7',
    3: '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5',
    4: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46',
    5: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8',
    6: '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
    7: '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
    8: '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050',
    9: '0x666Fa13501ce90c6f8c078313bd72f1f95197c5d',
    10: '0xc019728cccdc45cdA6a051802878c00e563C1E78',
    11: '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
    12: '0x407e49480643E338ae36612b220f0607e90429Dc',
    13: '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442',
    14: '0xb7eD4A5AF620B52022fb26035C565277035d4FD7',
    15: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
    16: '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428'
}

export const revisedPFMarketTokenList: any = [
    '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29',
    '0xe983cF03314830d9b4d5e87594bB50215438e19a',
    '0xCD221e1504442671671D3330CB8E916a5EDc3FC7',
    '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5',
    '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46',
    '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8',
    '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
    '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
    '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050',
    '0x666Fa13501ce90c6f8c078313bd72f1f95197c5d',
    '0xc019728cccdc45cdA6a051802878c00e563C1E78',
    '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
    '0x407e49480643E338ae36612b220f0607e90429Dc',
    '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
    '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428'
]

export const revisedPFNewMarketTokenList = [
    '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29',
    '0xe983cF03314830d9b4d5e87594bB50215438e19a',
    '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46',
    '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8',
    '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
    '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
    '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050',
    '0x666Fa13501ce90c6f8c078313bd72f1f95197c5d',
    '0xc019728cccdc45cdA6a051802878c00e563C1E78',
    '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
    '0x407e49480643E338ae36612b220f0607e90429Dc',
    '0xCD221e1504442671671D3330CB8E916a5EDc3FC7',
    '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5',
    '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
    '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428'
   
]

export const revisedPFNewMarketTokenListForUserBalance = [
    '0x190354707Ad8221bE30bF5f097fa51C9b1EbdB29',
    '0xe983cF03314830d9b4d5e87594bB50215438e19a',
    '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46',
    '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8',
    '0xC73009cEECf7A11DA93d1472211486bB883Beb40',
    '0x9C53267A58A54Df78daD8DB48a118222998c71B4',
    '0x2c120cA3e7cCDFf2d3680953a4C984519AcB8050',
    '0x666Fa13501ce90c6f8c078313bd72f1f95197c5d',
    '0xc019728cccdc45cdA6a051802878c00e563C1E78',
    '0x5D6C2a76fE1a117a2B0eb436A49923A01E497d79',
    '0x407e49480643E338ae36612b220f0607e90429Dc',
    '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
    '0xe39fa6154CcE2f859bC6976Cd19A0D49F4962428'
]


export const OldGTokenList: any = {
    
    0: {
        sno: 0,
        address: '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442',
        name: 'GAMMA',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12160319,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18,
    },
    1: {
        sno: 1,
        address: '0xb7eD4A5AF620B52022fb26035C565277035d4FD7',
        name: 'AQUA',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12160319,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18,
    },
}


export const platformMetrics = {
    totalMarketSize: 0,
    userTotalSupplyBal: 0,
    userTotalBorrowBal: 0,
    userNetApyWithoutGamma: 0,
    userNetApyWithGamma: 0,
    userStakeRatio: 0,
    gammaPrice: 0,
    pendingGamma: 0,
} 

export const stableSwapPoolInfoList = [{  

    poolAddress: "0x99a32D3993b068A87dec9503b231895236E605e8",
    poolName: "3G",
    lpTokenAddress: "0xC9440dEb2a607A6f6a744a9d142b16eD824A1A3b",
    lpTokenName: "3G LP",
    assetsInfo: [{
                gTokenAddress: '0xe983cF03314830d9b4d5e87594bB50215438e19a',
                name: 'BUSD',
                tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
                decimals: 18
            },
            {
                gTokenAddress: '0xb56aEa60744ed5AeA8F1639dD1f574b102618d46',
                name: 'USDC',
                tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
                decimals: 18
            },
            {
                gTokenAddress: '0x045e2Df638eBEc29130DD3bE61161cbA5F00a9c8',
                name: 'USDT',
                tokenAddress: '0x55d398326f99059ff775485246999027b3197955',
                decimals: 18
            }
        ]
    }
]

export const pfTokenListLowercase: any = {
    '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c': {
        sno: 0,
        address: '0x190354707ad8221be30bf5f097fa51c9b1ebdb29',
        name: 'BNB',
        marketSymbol: 'BNB',
        icon: BNBIcon,
        tokenOrigin: 'Binance Coin',
        blockNumber: 12078744,
        token: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        decimals: 18
    },
    '0xe9e7cea3dedca5984780bafc599bd69add087d56': {
        sno: 1,
        address: '0xe983cf03314830d9b4d5e87594bb50215438e19a',
        name: 'BUSD',
        marketSymbol: 'BUSD',
        icon: BUSDIcon,
        tokenOrigin: 'Binance USD',
        blockNumber: 12127442,
        token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        decimals: 18
    },
    '0xb3cb6d2f8f2fde203a022201c81a96c167607f15': {
        sno: 2,
        address: '0xCD221e1504442671671D3330CB8E916a5EDc3FC7',
        name: 'GAMMA',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12160319,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18,
    },
    '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991': {
        sno: 3,
        address: '0x2f5d7A9D8D32c16e41aF811744DB9f15d853E0A5',
        name: 'AQUA',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12160319,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18,
    },
    '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d': {
        sno: 4,
        address: '0xb56aea60744ed5aea8f1639dd1f574b102618d46',
        name: 'USDC',
        marketSymbol: 'USDC',
        icon: USDCIcon,
        tokenOrigin: 'USD Coin',
        blockNumber: 12160319,
        token: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        decimals: 18
    },
    '0x55d398326f99059ff775485246999027b3197955':{
        sno: 5,
        address: '0x045e2df638ebec29130dd3be61161cba5f00a9c8',
        name: 'USDT',
        marketSymbol: 'USDT',
        icon: USDTIcon,
        tokenOrigin: 'Tether USD',
        blockNumber: 12160319,
        token: '0x55d398326f99059ff775485246999027b3197955',
        decimals: 18
    },
    '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40': {
        sno: 6,
        address: '0xc73009ceecf7a11da93d1472211486bb883beb40',
        name: 'FRAX',
        marketSymbol: 'FRAX',
        icon: FRAXIcon,
        tokenOrigin: 'FRAX Finance',
        blockNumber: 12160319,
        token: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
        decimals: 18
    },
    '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3': {
        sno: 7,
        address: '0x9c53267a58a54df78dad8db48a118222998c71b4',
        name: 'DAI',
        marketSymbol: 'DAI',
        icon: DAIIcon,
        tokenOrigin: 'Maker DAO',
        blockNumber: 12160319,
        token: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        decimals: 18
    },
    '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c':{
        sno: 8,
        address: '0x2c120ca3e7ccdff2d3680953a4c984519acb8050',
        name: 'BTCB',
        marketSymbol: 'BTCB',
        icon: BTCBIcon,
        tokenOrigin: 'Bitcoin',
        blockNumber: 12159960,
        token: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        decimals: 18
    },
    '0x2170ed0880ac9a755fd29b2688956bd959f933f8':{
        sno: 9,
        address: '0x666fa13501ce90c6f8c078313bd72f1f95197c5d',
        name: 'ETH',
        marketSymbol: 'ETH',
        icon: ETHIcon,
        tokenOrigin: 'Ethereum',
        blockNumber: 12160319,
        token: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        decimals: 18
    },
    '0x1ce0c2827e2ef14d5c4f29a091d735a204794041':{
        sno: 10,
        address: '0xc019728cccdc45cda6a051802878c00e563c1e78',
        name: 'AVAX',
        marketSymbol: 'AVAX',
        icon: AVAXIcon,
        tokenOrigin: 'Avalanche',
        blockNumber: 12160319,
        token: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
        decimals: 18
    },
    '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82': {
        sno: 11,
        address: '0x5d6c2a76fe1a117a2b0eb436a49923a01e497d79',
        name: 'CAKE',
        marketSymbol: 'CAKE',
        icon: CAKEIcon,
        tokenOrigin: 'PancakeSwap',
        blockNumber: 12160319,
        token: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        decimals: 18
    },
    '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47': {
        sno: 12,
        address: '0x407e49480643E338ae36612b220f0607e90429Dc',
        name: 'ADA',
        marketSymbol: 'ADA',
        icon: ADAIcon,
        tokenOrigin: 'Cardano',
        blockNumber: 12160319,
        token: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
        decimals: 18
    },
    '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe': {
        sno: 13,
        address: '0x0be66da1D2E4482e95d7e4C5051Eb662711Aa667',
        name: 'XRP',
        marketSymbol: 'XRP',
        icon: XRPIcon,
        tokenOrigin: 'XRP',
        blockNumber: 12160319,
        token: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
        decimals: 18,
    },
    '0x14016e85a25aeb13065688cafb43044c2ef86784': {
        sno: 14,
        address: '0x939e98eeC2Aa37baB8c5d94BC90C6E00911d27B4',
        name: 'TUSD',
        marketSymbol: 'TUSD',
        icon: TUSDIcon,
        tokenOrigin: 'TrueUSD',
        blockNumber: 12160319,
        token: '0x14016e85a25aeb13065688cafb43044c2ef86784',
        decimals: 18
    },
    '0x82c41b3b276f4dbdc4fb8d067601b4c3a4b336ed': {
        sno: 15,
        address: '0x82c41b3b276f4dbdc4fb8d067601b4c3a4b336ed',
        name: 'ACAC',
        marketSymbol: 'ACAC',
        icon: AQUAIcon,
        tokenOrigin: 'ACAC',
        blockNumber: 12160319,
        token: '0x82c41b3b276f4dbdc4fb8d067601b4c3a4b336ed',
        decimals: 18
    },
    '0x156ab3346823b651294766e23e6cf87254d68962': {
        sno: 16,
        address: '0x88FD42E447d39C3259b53623f2536bd855e47C48',
        name: 'LUNA',
        marketSymbol: 'LUNA',
        icon: LUNAIcon,
        tokenOrigin: 'Terra',
        blockNumber: 12160319,
        token: '0x156ab3346823b651294766e23e6cf87254d68962',
        decimals: 6
    },
    '0x23396cf899ca06c4472205fc903bdb4de249d6fc': {
        sno: 17,
        address: '0xa5ae8459e710F95ca0C93d73F63a66d9996F1ACE',
        name: 'UST',
        marketSymbol: 'UST',
        icon: USTIcon,
        tokenOrigin: 'Terra USD',
        blockNumber: 12159650,
        token: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
        decimals: 18
    },
    '0x03028d2f8b275695a1c6afb69a4765e3666e36d9': {
        sno: 18,
        address: '0x03028d2f8b275695a1c6afb69a4765e3666e36d9',
        name: 'Net-LP',
        marketSymbol: 'Net-LP',
        icon: "",
        tokenOrigin: 'Net-LP',
        blockNumber: 12159650,
        token: '0x03028d2f8b275695a1c6afb69a4765e3666e36d9',
        decimals: 18
    },
    '0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275': {
        sno: 19,
        address: '0xe39fa6154cce2f859bc6976cd19a0d49f4962428',
        name: 'BNBx',
        marketSymbol: 'BNBx',
        icon: BNBxIcon,
        tokenOrigin: 'Stader',
        blockNumber: 12160319,
        token: '0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275',
        decimals: 18,
    },
    '0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5': {
        sno: 19,
        address: '0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5',
        name: 'HAY',
        marketSymbol: 'Helio',
        icon: HayIcon,
        tokenOrigin: 'Helio',
        blockNumber: 12160319,
        token: '0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5',
        decimals: 18,
    },
}
  