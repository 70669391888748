import { useState, useEffect } from 'react'
import { FlexSBCont } from 'shared/styles/styled'
import { ExpandedCell, ExpendCellTitle, TableInfoRows, TableInfoBox, TableInfoTxt, CellDownIcon } from 'shared/styles/planetTableStyle'
import DownIcon from 'assets/icons/down.svg'
import { commaFy } from 'service/globalFunctions'
import InfoTooltip from 'shared/info-tool-tip'
import { FlexCont } from './styles'
import whiteCrossIcon from 'assets/icons/white-cross.svg'
import {TitleText, InfoLDText} from './styles'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import { MainContainer, CardButton2 } from 'shared/styles/styled'
import Spinner from 'shared/spinner';
import { claimGammaRewardsForPools } from 'modules/block-chain/BlockChainMethods';
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions';
import { updateUserLendingData } from 'logic/action/lending.action';
import { transLinkUrl } from 'service/global-constant';
import { notificationOpen, notificationClose } from "logic/action/notification.action";

const CollectRewards = (props: any) => {
    const selector = useSelector((state: any) => state)
    const [gammaAmountForStake, setGammaAmountForStake] = useState<any>('0')
    const [gammaDecimalsForStake, setGammaDecimalsForStake] = useState<any>('0000')
    const [gammaAmountForClaim, setGammaAmountForClaim] = useState<any>('0')
    const [gammaDecimalsForClaim, setGammaDecimalsForClaim] = useState<any>('0000')
    const [showFeeInfo, setShowFeeInfo] = useState(false)
    const [showAPYCal, setShowAPyCal] = useState(false)
    const [claimAndStakeLoading, setClaimAndStakeLoading] = useState(false)
    const [claimLoading, setClaimLoading] = useState(false)
    const { data, pendingGamma, toggleCollectModal } = props
    const { poolLoading, poolData, portfolioLoading, userPortfolio, gammaInfDataNew, aquaInfDataNew } = selector.user
    const dispatch = useDispatch()

    useEffect(() => {
        let gammaValArrForStake: any = (pendingGamma.toFixed(4)).split(".");
        setGammaAmountForStake(gammaValArrForStake[0])
        setGammaDecimalsForStake(gammaValArrForStake[1])
        let gammaValArrForClaim: any = ((parseFloat(pendingGamma) * 0.95).toFixed(4)).split(".");
        setGammaAmountForClaim(gammaValArrForClaim[0])
        setGammaDecimalsForClaim(gammaValArrForClaim[1])
    })

    const handleGammaCollect = async (stake: any) => {
        if (!!selector.ethData.address && !portfolioLoading) {
            try {
                if(stake == true){
                    setClaimAndStakeLoading(true)
                } else {
                    setClaimLoading(true)
                }
                
                const res = await claimGammaRewardsForPools(selector.ethData.address, stake)
                if (!!res && typeof res !== 'undefined' && Object.values(res).length > 0) {
                    const type = res.status ? 'success' : 'failed'
                    if (res.status) {
                        handleNotification(type, res.transactionHash)
                        dispatch(updateUserBalance(selector.ethData.address))
                        dispatch(updateUserLendingData(selector.ethData.address))
                    }
                    else {
                        handleNotification('failed')
                    }
                }
                else {
                    handleNotification('failed')
                }
            }
            catch (error) {
                console.log("error=>", error)
                handleNotification('failed')
            }
            finally {
                if(stake == true){
                    setClaimAndStakeLoading(false)
                } else {
                    setClaimLoading(false)
                }
            }
        }
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Claim ${type}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    return (
        <FlexSBCont className='collectGammaCont'>
            <FlexSBCont className='titleBox'>
                <TitleText className="collectGammaTitle">Collect GAMMA</TitleText>
                <img className="close-collect-gamma" src={whiteCrossIcon} alt="" onClick={()=> toggleCollectModal()}/>
            </FlexSBCont>
            <FlexSBCont className='claimGammaCont'>
                <FlexSBCont className='claimNStakeCont'>
                    <InfoLDText className='collectGammaTitle'>Deposit Directly into the Infinity Vault.</InfoLDText>
                    <InfoLDText className='collectGammaSubText'>This increases your APY Across All Planet’s Pools and Markets</InfoLDText>
                    <FlexSBCont className='gammaIconCont'>
                        <img className="gammaIcon" src={GAMMAIcon} alt="" />
                        <InfoLDText className='gammaVal'>
                            {gammaAmountForStake}.<span>{gammaDecimalsForStake}</span>
                        </InfoLDText>
                        
                    </FlexSBCont>
                    <CardButton2 className="on-web claimNStake" disabled={claimAndStakeLoading || selector.ethData.address == undefined} onClick={() => handleGammaCollect(true)}>{claimAndStakeLoading ? <Spinner /> : 'Collect & Stake'}</CardButton2>
                    <CardButton2 className="on-mob claimNStake" disabled={claimAndStakeLoading || selector.ethData.address == undefined} onClick={() => handleGammaCollect(true)}>{claimAndStakeLoading ? <Spinner /> : 'Collect & Stake'}</CardButton2>
                </FlexSBCont>
                <FlexSBCont className='claimCont'>
                    <InfoLDText className='collectGammaTitle'>Collect Directly to Wallet</InfoLDText>
                    <InfoLDText className='collectGammaSubText'>A 5% ClaimToWallet Fee is charged to bypass the infinity Vault</InfoLDText>
                    <FlexSBCont className='gammaIconCont'>
                        <img className="gammaIcon" src={GAMMAIcon} alt="" />
                        <InfoLDText className='gammaVal'>
                        {gammaAmountForClaim}.<span>{gammaDecimalsForClaim}</span>
                        </InfoLDText>
                        
                    </FlexSBCont>
                    <CardButton2 className="on-web claimNStake" disabled={claimLoading || selector.ethData.address == undefined} onClick={() => handleGammaCollect(false)}>{claimLoading ? <Spinner /> : 'Collect'}</CardButton2>
                    <CardButton2 className="on-mob claimNStake" disabled={claimLoading || selector.ethData.address == undefined} onClick={() => handleGammaCollect(false)}>{claimLoading ? <Spinner /> : 'Collect'}</CardButton2>
                </FlexSBCont>
                
            </FlexSBCont>
            
        </FlexSBCont>
    )
}
export default CollectRewards