import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserLendingData } from 'logic/action/lending.action'
import { TableCont, TableCard, ActiveTableRow, TableText, TableSmText, ApyText, ActiveTokenCont, TokenName, TableSwitchCont, MarketBtn } from './style'
import ToggleSwitch from 'shared/toggle-switch'
import CustomModal from 'shared/custom-modal'
import AssetSupply from './AssetSupply'
import Collateral from './Collateral'
import { enterMarkets, exitMarkets } from 'modules/block-chain-green/gammatroller'
import { checkNegative } from 'service/globalFunctions'
import { commaFy, convertNumFormat, convertToKAndM } from 'service/globalFunctions'
import { getApyOnLevelSelectAtSupply } from 'modules/block-chain-green/LendingBase'
import MarketIcon from 'assets/icons/markets.svg'
import { Table, TableHeader, TableBody, MobTableBody, TableRowCont, TableRow, CellCont, HeadTextCont, HeadTextData, HeadText, CellText, CellSmText, TwoImgCont, ArrowIconCont, MobArrowIconCont, LoadingText1 } from 'shared/styles/planetTableStyle'
import RightIcon from 'assets/icons/right_arrow.svg'
import SortIcon from 'assets/icons/white-arrow-up.svg'
import DefaultSortIcon from 'assets/icons/up-down-icon.svg'
import TableLoading from './TableLoading'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import AssetSupplyRevised from './AssetSupplyRevised'
import ActivateAsset from './ActivateAsset'
import PoolDetailsModalForLending from 'modules/pools/components/pool-modal/PoolDetailsModalForLending'
import { tokenList, returnTokenList, swapTokenList, returnSwapTokenList, tokenSwapList } from './../../block-chain/tokenList'
import { FarmPoolContext } from 'context/FarmPoolContext'

const SuppliedAsset = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [showAssetSupply, setShowAssetSupply] = useState(false)
    const [showCollateral, setShowCollateral] = useState(false)
    const [modalData, setModalData] = useState<any>({})
    const [collateralData, setCollateralData] = useState<any>({})
    const [isAllowance, setIsAllowance] = useState(false)
    const [apyData, setApyData] = useState<any>([])
    const [sortByTh, setSortByTh] = useState('supplied')
    const [sortDescending, setSortDescending] = useState(true)
    const [showVaultDetails, setShowVaultDetails] = useState(false)
    const [showEnableAsset, setShowEnableAsset] = useState(false)
    const [showPoolDetails, setShowPoolDetails] = useState(false)
    const [tokenSwapAmount, setTokenSwapAmount] = useState('')
    const [sendToken, setSendToken] = useState<any>(tokenList[1])
    const [tokenDataList, setTokenDataList] = useState<any>(tokenSwapList)
    const [showSwapPopUp, setShowSwapPopUp] = useState(false)
    const [unstakeData, setUnstakeData] = useState({})
    const [showUnstakePopUp, setShowUnstakePopUp] = useState(false)
    const [processUnstake, setProcessUnstake] = useState(() => {})
    const [confirmUnstake, setConfirmUnstake] = useState({
        confirm: false,
        poolId: -1,
    })
    const [transData, setTransData] = useState<any>()
    const [receiveTokenNumber, setReceiveTokenNumber] = useState('')
    const [lpAction, setLPAction] = useState<any>('transform')
    const [showTCAModal, setShowTCAModal] = useState(false)
    const [farmPoolData, setFarmPoolData] =  useState({
        totalAllocPoint: 365,
        gammaPerBlock: 1614583333333333200, 
        tokenSwapList: tokenSwapList,
        tokenSwapListWithUserBalances: tokenSwapList
    })
    const { userAssetLoading, gPlatformData } = selector.lending
    const { ethWalletConnected, address } = selector.ethData
    const { data, currLevel } = props

    useEffect(() => {
        const updateTokenListWithBalances = async () => {
            const userAddress = selector.ethData.address
            try {
              const res = await returnTokenList(userAddress)
              if (!!res && typeof res !== 'undefined') {
                farmPoolData.tokenSwapListWithUserBalances = res;
                setSendToken(res[0])
              }
            } catch (error) {
              console.log('error===>', error)
            }
        }
        updateTokenListWithBalances()
    }, [selector.ethData.address])

    useEffect(() => {
        const getApyData = async () => {
            let apyDataArr: any = []
            for (let i = 0; i < data.length; i++) {
                const gTokenAddress = data[i].address;
                const supplyApy = data[i].totalSupplyApy;
                const res = {
                    supplyApy: supplyApy,
                    supplyApy24hr: (Math.pow((supplyApy / 100 + 1), (1 / 365)) - 1) * 100,
                    address: gTokenAddress,
                };
                //getApyOnLevelSelectAtSupply(gTokenAddress, currLevel)
                apyDataArr.push(res)
            }
            apyDataArr = await Promise.all(apyDataArr)
            if(apyDataArr && apyDataArr.length > 0){
                //setApyData([...apyDataArr])
                apyDataArr = [...apyDataArr];
                return apyDataArr;
            } else {
                return [];
            }
        }
        if (!!data && data.length > 0) {
            const apyData = getApyData();
            setApyData(apyData)
        }
        // console.log("gPlatformData",gPlatformData)
        if(ethWalletConnected === false || (gPlatformData !== undefined && gPlatformData.suppliedAmountWithoutCollateral !== undefined && gPlatformData.suppliedAmountWithoutCollateral == 0 )){
            setSortByTh('tvl')
        } else {
            setSortByTh('supplied')
        }
    }, [data, currLevel, ethWalletConnected, gPlatformData])

    const getApyValues = (addVal: any) => {
        let finalApyVal: any = {
            supplyApy: 0,
            supplyApy24hr: 0,
        }
        if(apyData && apyData.length > 0){
            const apyValData = apyData.filter((e: any) => e.address.toLowerCase() === addVal.toLowerCase())
            if (apyValData.length > 0) {
                finalApyVal = apyValData[0]
            }
        }
        
        return finalApyVal
    }

    const handleRowClick = (itemData: any) => {
        // console.log("handleRowClick details", itemData)
        if(itemData.name.toLowerCase() == "gamma infinity"){
            setModalData(itemData)
            setShowPoolDetails(true)
        } else {
            setModalData(itemData)
            setIsAllowance(itemData.underlyingTokenAllowance)
            // setShowAssetSupply(true)
            if (itemData.name == "BNB" || +itemData.allowance > 0) {
                setShowAssetSupply(true)
                setShowVaultDetails(true)
            }
            else {
                setShowEnableAsset(true)
            }
        }
        
        
    }


    const togglePoolDetailsModal = () => {
        setModalData({})
        setShowPoolDetails(false)
    }
    

    const handleCollateral = async (itemData: any) => {
        setCollateralData(itemData)
        setShowCollateral(true)

        let collRes: any = {}
        try {
            if (itemData.isUsingAsCollateral) {
                collRes = await exitMarkets(itemData.address, selector.ethData.address)
            }
            else {
                collRes = await enterMarkets(itemData.address, selector.ethData.address)
            }
            if (!!collRes && collRes.status) {
                dispatch(updateUserLendingData(selector.ethData.address))
            }
        }
        catch (error) { }
        finally {
            setShowCollateral(false)
            dispatch(updateUserLendingData(selector.ethData.address))
        }
    }

    const sortByMarketSize = (dataList: any) => {
        if (!!dataList && dataList.length > 0) {
            const finalArr = data.sort((a: any, b: any) => (parseFloat(b.totalMarketSizeUsd) > parseFloat(a.totalMarketSizeUsd)) ? 1 : (parseFloat(a.totalMarketSizeUsd) > parseFloat(b.totalMarketSizeUsd) ? -1 : 0))
            return finalArr
        }
        else {
            return dataList
        }
    }

    const sortBySupplyAmount = (dataList: any) => {
        if (!!dataList && dataList.length > 0) {
            const finalArr = data.sort((a: any, b: any) => (parseFloat(b.currentlySupplyingUsd) > parseFloat(a.currentlySupplyingUsd)) ? 1 : (parseFloat(a.currentlySupplyingUsd) > parseFloat(b.currentlySupplyingUsd) ? -1 : 0))
            return finalArr
        }
        else {
            return dataList
        }
    }

    const sortByApy = (dataList: any) => {
        if (!!dataList && dataList.length > 0) {
            const finalArr = data.sort((a: any, b: any) => (parseFloat(b.totalSupplyApy) > parseFloat(a.totalSupplyApy)) ? 1 : (parseFloat(a.totalSupplyApy) > parseFloat(b.totalSupplyApy) ? -1 : 0))
            return finalArr
        }
        else {
            return dataList
        }
    }

    const handleTabClick = (tabSelect: string) => {
        if (tabSelect === sortByTh) {
            setSortByTh(tabSelect)
            setSortDescending(!sortDescending)
        }
        else {
            setSortByTh(tabSelect)
            setSortDescending(true)
        }
    }

    const handlePathLink = (path: string) => {
        if (path === 'markets') {
            history.push(Paths.markets)
        }
        if (path === 'metrics') {
            history.push(Paths.metrics)
        }
        if (path === 'liquidations') {
            history.push(Paths.liquidations)
        }
    }

    const toggleVaultDetailsModal = () => {
        setModalData({})
        setShowVaultDetails(false)
    }

    const handleGetLpToken = (transData: any, type: any, inputAmount: any) => {
        // console.log("handleShowPoolDetails", transData)
        if(transData.poolStatus == "active" || transData.protocol.toLowerCase() == 'thena'){
            // console.log("pool action in pool table ", type, transData, inputAmount)
            setTransData(transData)
            setShowTCAModal(true)
            setLPAction(type)
            setTokenSwapAmount(inputAmount)
        } else {
            history.push(`${Paths.liquidity}`)
        }
       
    }

    const showSwapPopup = async (data: any, swapAmount: any) => {
        setShowSwapPopUp(true)
        // console.log("pool data", data)
        let tokenNumber : any = data.poolId == 0 ? 2 : 0;
        setReceiveTokenNumber(tokenNumber)
        setTokenSwapAmount(swapAmount)
    }

    const _renderDataRow = () => {
        let orderedData = sortBySupplyAmount(data)
        if(sortByTh === "supplied"){
            orderedData = sortBySupplyAmount(data)
        } else if(sortByTh === "apy"){
            const orderedData = sortByApy(data)
        } else if(sortByTh === "tvl"){
            const orderedData = sortByMarketSize(data)
        } else{
            orderedData = sortBySupplyAmount(data)
        }
        if (!sortDescending) {
            orderedData = orderedData.reverse()
        }
        
        return orderedData.map((item: any, index: number) => {
            const apyVal: any = getApyValues(item.address)
            return (
                <ActiveTableRow key={index} className={item.name.toLowerCase() == "gamma" ?"table-data supplyTable": "table-data supplyTable"} tokenName={item.name.toLowerCase()}>
                    <ActiveTokenCont className="" onClick={() => handleRowClick(item)}>
                        <img src={item.icon} alt="" />
                        <div className="align-left">
                            <TokenName>{item.name}</TokenName>
                            <TableSmText className="market-size">${commaFy(convertNumFormat(item.totalMarketSizeUsd))}</TableSmText>
                        </div>
                    </ActiveTokenCont>
                    <div onClick={() => handleRowClick(item)}>
                    {item.currentlySupplyingUsd > 0.0054 &&
                        <TableText className='suppliedToken'>{commaFy(parseFloat(item.currentlySupplying).toFixed(4))}</TableText>
                    }
                    {item.currentlySupplyingUsd <= 0.0054 &&
                        <TableText className='dataInGrey suppliedToken'>{commaFy(convertNumFormat(item.currentlySupplying, 4))}</TableText>
                    }
                        <TableSmText className='suppliedUsd'>${commaFy(parseFloat(item.currentlySupplyingUsd).toFixed(2))}</TableSmText>
                    </div>
                    <div onClick={() => handleRowClick(item)}>
                        <ApyText className='apyCapsule' isNegative={checkNegative(item.totalSupplyApy)}>{item.totalSupplyApy && (!isFinite(item.totalSupplyApy) || item.totalSupplyApy > 10000) ? "> 10k" : item.totalSupplyApy ? commaFy(convertToKAndM(item.totalSupplyApy.toFixed(2))) : "0.00"}%</ApyText>
                        {/* <TableSmText >{!!apyVal && apyVal.supplyApy24hr ? commaFy(convertNumFormat(apyVal.supplyApy24hr)) : '0.00'}%</TableSmText> */}
                    </div>
                    {item.isAvailForCollateral && item.currentlySupplyingUsd > 0.0054 &&
                        <TableSwitchCont onClick={() => handleCollateral(item)}>
                            <ToggleSwitch id={item.name} data={item} isChecked={item.isUsingAsCollateral} />
                        </TableSwitchCont>
                    }
                </ActiveTableRow >
            )
        })
    }
    const _renderDataRowMob = () => {
        let orderedData = sortBySupplyAmount(data)
        if(sortByTh === "supplied"){
            orderedData = sortBySupplyAmount(data)
        } else if(sortByTh === "apy"){
            const orderedData = sortByApy(data)
        } else if(sortByTh === "tvl"){
            const orderedData = sortByMarketSize(data)
        } else{
            orderedData = sortBySupplyAmount(data)
        }
        if (!sortDescending) {
            orderedData = orderedData.reverse()
        }
        
        return orderedData.map((item: any, index: number) => {
            const apyVal: any = getApyValues(item.address)
            return (
                <TableRowCont key={index}>
                    <TableRow className="supplyTableRowMob" onClick={() => handleRowClick(item)}>
                        <CellCont className='supplyTableRowMob'>
                            <img src={item.icon} alt="" className='supplyTableMarketIcon' />
                            <div className="align-left supplyTableMarketText">
                                <TokenName>{item.name}</TokenName>
                                
                            </div>
                        </CellCont>
                        <CellCont>
                            <div onClick={() => handleRowClick(item)}>
                                <CellText className='apyTextWhite' isNegative={checkNegative(item.totalSupplyApy)}>{item.totalSupplyApy && (!isFinite(item.totalSupplyApy) || item.totalSupplyApy > 10000) ? "> 10k" : item.totalSupplyApy ? commaFy(item.totalSupplyApy.toFixed(2)) : "0.00"}%</CellText>
                            </div>
                        </CellCont>
                        <CellCont>
                            <div onClick={() => handleRowClick(item)} className='supplyText'>
                                {item.currentlySupplyingUsd > 0.005 &&
                                    <TableText className='suppliedAmountText'>${commaFy(convertNumFormat(item.currentlySupplyingUsd, 2))}</TableText>
                                }
                                {item.currentlySupplyingUsd <= 0.005 &&
                                    <TableText className='suppliedAmountText dataInGrey'>${commaFy(convertNumFormat(item.currentlySupplyingUsd, 2))}</TableText>
                                }
                                
                            </div>
                        </CellCont>
                        <CellCont>
                            <img className='downGreyIcon' src={RightIcon} alt="" />
                        </CellCont>
                    </TableRow>

                    
                </TableRowCont >
            )
        })
    }

    return (
        <FarmPoolContext.Provider value={farmPoolData}>
        <TableCont>
            
            <TableCard className='supplyTable'>
                <div className='supplyMarketDiv'>
                    <h2 className="supplyMarket">Supply & Earn</h2>
                    <div className='marketBtn' onClick={() => handlePathLink('markets')}>
                        <img className='marketIcon' src={MarketIcon} alt="" />
                        <MarketBtn className="showMarketBtn" >
                            View Markets 
                        </MarketBtn>
                        <span className="marketSymbol"><img className='downGreyIcon' src={RightIcon} alt="" /></span>
                    </div>
                    
                </div>
                
                <ActiveTableRow className="header-row supplyTable">
                    {/* <TableText className="lendingSupply" light>Earn Assets</TableText> */}
                    <HeadTextCont className='supplyHeader earn_assets'>
                        <HeadTextData className='lendingSupply' onClick={() => handleTabClick('tvl')} isDown={sortByTh === 'tvl' && sortDescending}>
                            <HeadText className='lendingSupply'>Market Size</HeadText>
                            <img className='lendingSupply' src={sortByTh === 'tvl' ? SortIcon : DefaultSortIcon} alt=""/>
                        </HeadTextData>
                    </HeadTextCont>
                    <HeadTextCont className='supplyHeader'>
                        <HeadTextData className='lendingSupply' onClick={() => handleTabClick('supplied')} isDown={sortByTh === 'supplied' && sortDescending}>
                            <HeadText className='lendingSupply'>Supplied</HeadText>
                            <img className='lendingSupply' src={sortByTh === 'supplied' ? SortIcon : DefaultSortIcon} alt=""/>
                        </HeadTextData>
                    </HeadTextCont>
                    {/* <TableText light>Supplied</TableText> */}
                    <HeadTextCont className='supplyHeader'>
                        <HeadTextData className='lendingSupply' onClick={() => handleTabClick('apy')} isDown={sortByTh === 'apy' && sortDescending}>
                            <HeadText className='lendingSupply'>APY</HeadText>
                            <img className='lendingSupply' src={sortByTh === 'apy' ? SortIcon : DefaultSortIcon} alt="" />
                        </HeadTextData>
                    </HeadTextCont>
                    {/* <TableText light>APY</TableText> */}
                    <TableText className="lendingSupply collateral" light>Collateral</TableText>
                </ActiveTableRow>
                {
                    userAssetLoading ? <TableLoading /> :
                        !!data && data.length > 0 ? _renderDataRow() : <TableLoading />
                }
                {/* {!!data && data.length > 0 && _renderDataRow()} */}
            </TableCard>
            <Table className='show-mob'>
            <TableHeader>
                <HeadTextCont />
                <HeadTextCont>
                    <HeadTextData onClick={() => handleTabClick('tvl')} isDown={sortByTh === 'tvl' && sortDescending} >
                        <HeadText>TVL</HeadText>
                        <img src={sortByTh === 'tvl' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                <HeadTextCont>
                    <HeadTextData onClick={() => handleTabClick('apy')} isDown={sortByTh === 'apy' && sortDescending}>
                        <HeadText>APY</HeadText>
                        <img src={sortByTh === 'apy' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                
                
                <HeadTextCont className='show-mob'>
                    <HeadTextData onClick={() => handleTabClick('supplied')} isDown={sortByTh === 'supplied' && sortDescending}>
                        <HeadText>Supplied</HeadText>
                        <img src={sortByTh === 'supplied' ? SortIcon : DefaultSortIcon} alt="" />
                    </HeadTextData>
                </HeadTextCont>
                <HeadTextCont />
            </TableHeader>
            {userAssetLoading ? <TableLoading /> : _renderDataRowMob()}
            </Table>
            {/* {displayWebModal && <CustomModal show={showAssetSupply} toggleModal={setShowAssetSupply}>
                {showAssetSupply && (
                    <AssetSupply
                        data={modalData}
                        setShowAssetSupply={setShowAssetSupply}
                        isAllowance={isAllowance}
                    />
                )}
            </CustomModal>} */}
            {<CustomActionModal show={showVaultDetails} toggleModal={toggleVaultDetailsModal}>
                {showVaultDetails && (
                    <AssetSupplyRevised
                        data={modalData}
                        setShowAssetSupply={setShowAssetSupply}
                        toggleVaultModal={toggleVaultDetailsModal} setModalData={setModalData} currentTab={"all"}
                    />
                )}
            </CustomActionModal>}
            <CustomModal show={showCollateral} toggleModal={setShowCollateral}>
                {showCollateral && (
                    <Collateral
                        setShowCollateral={setShowCollateral}
                        data={collateralData}
                        enableCollateral={!collateralData.isUsingAsCollateral}
                    />
                )}
            </CustomModal>
            <CustomModal show={showEnableAsset} toggleModal={setShowEnableAsset}>
                {showEnableAsset && (
                    <ActivateAsset
                        data={modalData}
                        setShowEnableAsset={setShowEnableAsset}
                        setShowAssetSupply={setShowAssetSupply}
                        setIsAllowance={setIsAllowance}
                    />
                )}
            </CustomModal>

            <CustomActionModal show={showPoolDetails} toggleModal={togglePoolDetailsModal}>
                {showPoolDetails && 
                <PoolDetailsModalForLending 
                    togglePoolModal={togglePoolDetailsModal} 
                    data={modalData} 
                    handleGetLpToken={handleGetLpToken} 
                    setModalData={setModalData} 
                    setTokenSwapAmount={setTokenSwapAmount}
                    currentTab={"all"} 
                    sendToken={sendToken}
                    tokenDataList={tokenDataList}
                    setSendTokenFromParent={setSendToken}
                    showSwapPopup={showSwapPopup}
                    setUnstakeData={setUnstakeData}
                    setShowUnstakePopUp={setShowUnstakePopUp}
                    confirmUnstake={confirmUnstake}
                    setConfirmUnstake={setConfirmUnstake}
                    showUnstakePopUp={showUnstakePopUp}
                />}
            </CustomActionModal>
        </TableCont>
        </FarmPoolContext.Provider>
    );
};

export default SuppliedAsset;
