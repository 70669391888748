import { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { FlexCont, FlexSBCont, NormalBtn, ToolTipIcon, ToolTipText, ToolTipDataCont, LoadingText1, LoadingText2, LoadingText3 } from 'shared/styles/styled'
import {
    MobileHomeCont,
    MobileHeader,
    LevelBox,
    HeaderBtnGroup,
    ActionBtn1,
    AquaPriceBox,
    InfoDataCard,
    MobBalanceCont,
    CardText,
    CardNumText,
    CardBoldText,
    MarketSizeTxt,
    SliderContainer,
    SwitchCard,
    SwitchBox,
    InfoBoxMob,
    DiscountPie,
    SliderText,
    VerticalCardDivider,
    HeaderNumTxt,
    HeaderTxt
} from "./style";
import GammaIcon from 'assets/icons/gamma-icon.png'
import InfinityGammaIcon from 'assets/icons/infinityGamma.png'
// import QuestionIcon from 'assets/icons/question-icon.svg'
import QuestionIcon from 'assets/icons/question_icon.png'
import ReactTooltip from "react-tooltip";
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import CustomModal from 'shared/custom-modal'
import { commaFy, convertToKAndM } from 'service/globalFunctions'
import SuppliedAsset from './components/SuppliedAsset'
import SupplyMarket from './components/SupplyMarket'
import BorrowedAsset from './components/BorrowedAsset'
import BorrowMarket from './components/BorrowMarket'
import Collateral from './components/Collateral'
import Spinner from 'shared/spinner';
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import { textChangeRangeIsUnchanged } from "typescript";
import AssetSupply from './components/AssetSupply'
import { MarketBtn, MarketButton } from './components/style'
import MarketIcon from 'assets/icons/markets.svg'
import { userDiscountLevel } from 'service/dataConstant'
import Select from 'shared/select'
import RightIcon from 'assets/icons/right_arrow.svg'
import mobileMarketBtn from 'assets/icons/mob_market_btn.svg'
import ViewMarkets from 'assets/icons/view_markets.svg'
import ToolTipModal from 'shared/tooltip-modal'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import AssetSupplyRevised from './components/AssetSupplyRevised'
import ActivateAsset from './components/ActivateAsset'
import VaultEarnings from './components/VaultEarnings'
import PoolDetailsModalForLending from 'modules/pools_v3/components/pool-modal/PoolDetailsModalForLending'
import { tokenList, returnTokenList, swapTokenList, returnSwapTokenList, tokenSwapList } from './../block-chain/tokenList'
import { FarmPoolContext } from 'context/FarmPoolContext'
import SwapInPools from 'modules/pools_v3/components/pool-modal/SwapInPools'
import UnStakeInstantlyPopUp from '../pools_v3/components/pool-modal/UnStakeInstantlyPopUp'

let timerForGreenPendingGAMMA = setInterval(function () { }, 0)
const MobLending = (props: any) => {
    const selector = useSelector((state: any) => state)
    const [showEnableAsset, setShowEnableAsset] = useState(false)
    const [activeSwitch, setSwitchActive] = useState("supply")
    const [showMoreDetails, setShowMoreDetails] = useState(false)
    const [isAllowance, setIsAllowance] = useState(false)
    const [modalData, setModalData] = useState<any>({})
    const [showAssetSupply, setShowAssetSupply] = useState(false)
    const [showCollateral, setShowCollateral] = useState(false)
    const [collateralData, setCollateralData] = useState<any>({})
    const [discountLevel, setDiscountLevel] = useState(0)
    const [showToolTip, setShowTooltipModal] = useState(false)
    const [toolTipTitle, setToolTipTitle] = useState('')
    const [toolTipMessage, setToolTipMessage] = useState('')
    const [showVaultDetails, setShowVaultDetails] = useState(false)
    const [ greenPendingGAMMA, setGreenPendingGAMMA] = useState('')
    const [ greenPendingGAMMAUSD, setGreenPendingGAMMAUSD] = useState('0.00')
    const [receiveTokenNumber, setReceiveTokenNumber] = useState('')
    const [tokenSwapAmount, setTokenSwapAmount] = useState('')
    const [lpAction, setLPAction] = useState<any>('transform')
    const [tokenDataList, setTokenDataList] = useState<any>(tokenSwapList)
    const [showSwapPopUp, setShowSwapPopUp] = useState(false)
    const [unstakeData, setUnstakeData] = useState({})
    const [processUnstake, setProcessUnstake] = useState(() => {})
    const [transData, setTransData] = useState<any>()
    const [showUnstakePopUp, setShowUnstakePopUp] = useState(false)
    const [showPoolDetails, setShowPoolDetails] = useState(false)
    const [showTCAModal, setShowTCAModal] = useState(false)
    const [gammaInfinityVault, setGammaInfinityVault] = useState(false)
    const [sendToken, setSendToken] = useState<any>(tokenList[1])
    const [confirmUnstake, setConfirmUnstake] = useState({
        confirm: false,
        poolId: -1,
    })
    const [initialTokenAdd, setInitialTokenAdd] = useState('')
    const [farmPoolData, setFarmPoolData] =  useState({
        totalAllocPoint: 365,
        gammaPerBlock: 1614583333333333200, 
        tokenSwapList: tokenSwapList,
        tokenSwapListWithUserBalances: tokenSwapList
    })
    const { portfolioLoading, userPortfolio, gammaInfDataNew } = selector.user
    const { ethWalletConnected, address } = selector.ethData
    const { gPlatformDataNew, userAssetData, borrowLimitNew, gPlatformLoading, borrowLimitLoading, addNullLoading } = selector.lending
    const { claimLoading, handleClaim, suppLevel, borLevel } = props
    const gPlatformData: any = gPlatformDataNew;
    const borrowLimit: any = borrowLimitNew;
    useEffect(() => {
        ReactTooltip.rebuild();
        if(discountLevel === 0 || (+discountLevel === 0 && +borLevel !== +discountLevel)){
            setDiscountLevel(borLevel)
        }
    },[borLevel])

    useEffect(() => {
        if(gammaInfDataNew != undefined && Object.keys(gammaInfDataNew).length > 0 && userAssetData.marketData.find((obj: any) => obj.name.toLowerCase() == "gamma infinity") == undefined){
          // console.log(userAssetData)
          // userAssetData.marketData.push(gammaInfDataNew)
          // userAssetData.total_lending_tvl += gammaInfDataNew.tvl;
          setGammaInfinityVault(gammaInfDataNew)
        }
        
    })

    useEffect(() => {
        const updateTokenListWithBalances = async () => {
            const userAddress = selector.ethData.address
            try {
              const res = await returnTokenList(userAddress)
              if (!!res && typeof res !== 'undefined') {
                farmPoolData.tokenSwapListWithUserBalances = res;
              }
            } catch (error) {
              console.log('error===>', error)
            }
        }
        updateTokenListWithBalances()
    }, [selector.ethData.address])


    const handleLevelSelect = (discountLevelOption: number) => {
        setDiscountLevel(discountLevelOption)
    }

    const handleSupplyModal = (itemData: any) => {
        // console.log("gamma object", itemData)
        if(itemData.address){            
            if (itemData.name.toLowerCase() == "gamma infinity") {
                setModalData(itemData)
                setShowPoolDetails(true)
            } else if (itemData.underlyingTokenAllowance){
                setIsAllowance(itemData.underlyingTokenAllowance)
                setShowAssetSupply(true)
                setShowVaultDetails(true)
                setShowEnableAsset(false)
            } else {
                setIsAllowance(itemData.underlyingTokenAllowance)
                setShowEnableAsset(true)
            }
        }
    }

    const showSwapPopup = async (data: any, swapAmount: any) => {
        setShowSwapPopUp(true)
        // console.log("pool data", data)
        let tokenNumber : any = data.poolId == 0 ? 2 : 0;
        setReceiveTokenNumber(tokenNumber)
        setTokenSwapAmount(swapAmount)
    }

    const togglePoolDetailsModal = () => {
        setModalData({})
        setShowPoolDetails(false)
    }

    const handleGetLpToken = (transData: any, type: any, inputAmount: any) => {
        // console.log("handleShowPoolDetails", transData)
        if(transData.poolStatus == "active" || transData.protocol.toLowerCase() == 'thena'){
            // console.log("pool action in pool table ", type, transData, inputAmount)
            setTransData(transData)
            setShowTCAModal(true)
            setLPAction(type)
            setTokenSwapAmount(inputAmount)
        } else {
            history.push(`${Paths.liquidity}`)
        }
       
    }

    const startTimerTripping = () => {
        if(userPortfolio.greenPendingGAMMA)
        {
          userPortfolio.greenPendingGAMMA = userPortfolio.greenPendingGAMMA ? userPortfolio.greenPendingGAMMA : 0;
          setGreenPendingGAMMA(userPortfolio.greenPendingGAMMA)
          setGreenPendingGAMMAUSD((userPortfolio.greenPendingGAMMA * userPortfolio.gammaPrice).toFixed(2).toString())
          clearInterval(timerForGreenPendingGAMMA)
          gPlatformData.userTotalSupplyBal = gPlatformData.userTotalSupplyBal ? gPlatformData.userTotalSupplyBal : 0;
          let apy = gPlatformData.apyWithoutInfinityVaults ? gPlatformData.apyWithoutInfinityVaults : 0;
          let m: any = 365
          let a: any = apy / 100 + 1
          let apr = (Math.pow(a, 1 / m) - 1) * m
          // need to change following code based tripping criteria
          // supply balance * (aprWithoutInfinityVaults) / (365 * 86400)
          let trippingGreenPendingGAMMA:any = parseFloat(gPlatformData.userTotalSupplyBal) * (apr) / (365 * 86400)
          trippingGreenPendingGAMMA = trippingGreenPendingGAMMA > 0 ? trippingGreenPendingGAMMA : 0;
          let now = new Date();
          let timeWhenStarted = now.getTime();
          timerForGreenPendingGAMMA = setInterval(function () {
              now = new Date();
              let newGreenPendingGAMMA =  parseFloat(userPortfolio.greenPendingGAMMA) + (( now.getTime() - timeWhenStarted ) * trippingGreenPendingGAMMA / 1000);
              let pendingGammaUsd = newGreenPendingGAMMA * userPortfolio.gammaPrice;
              if(newGreenPendingGAMMA !== undefined){
                  setGreenPendingGAMMA(newGreenPendingGAMMA+"")
                  setGreenPendingGAMMAUSD(pendingGammaUsd+"")
              }
              
              
          }, 1000)
        }
      }

    useEffect(() => {
        if (timerForGreenPendingGAMMA) {
            clearInterval(timerForGreenPendingGAMMA); 
        }
        if(userPortfolio.greenPendingGAMMA)
        {
            startTimerTripping();
        }
        return () => clearInterval(timerForGreenPendingGAMMA);
      }, [userPortfolio])

    const toggleVaultDetailsModal = () => {
        setModalData({})
        setShowVaultDetails(false)
      }

    const getInfoNumFormat = (val: any, borrowLimit?: boolean) => {
        const newVal: any = parseFloat(val).toFixed(2).toString().split('.')
        if(borrowLimit === true)
            return <span>${commaFy(newVal[0])}.<span>{newVal[1]}</span></span>;
        else
            return <CardBoldText>${commaFy(newVal[0])}.<span>{newVal[1]}</span></CardBoldText>;
    }
    const getPendingGammaNum = (val: any) => {
        const newVal: any = parseFloat(val).toFixed(4).toString().split('.')
        return <CardBoldText>{commaFy(newVal[0])}.<span>{newVal[1]}</span></CardBoldText>
    }

    const getGammaValFormat = (val: any, gammaEarning?: boolean) => {
        const gammaVal: any = parseFloat(val).toFixed(4).toString().split('.')
        if(gammaEarning === true)
          return <HeaderNumTxt className="gammaEarningsMob">{commaFy(gammaVal[0])}.<span className="gammaEarningsMob">{gammaVal[1]} GAMMA</span></HeaderNumTxt>
        else
          return <HeaderNumTxt className="gammaEarningsMob">{commaFy(gammaVal[0])}.<span>{gammaVal[1]}</span></HeaderNumTxt>
      }

    const handleShowMore = () => {
        setShowMoreDetails(!showMoreDetails)
    }

    const handleBuyGamma = () => {
        history.push(`${Paths.swap}`)
    }

    const handlePathLink = (path: string) => {
        if (path === 'markets') {
            history.push(Paths.markets)
        }
        if (path === 'metrics') {
            history.push(Paths.metrics)
        }
        if (path === 'liquidations') {
            history.push(Paths.liquidations)
        }
    }

    const polarToCartesian = (centerX: any, centerY: any, radius:any, angleInDegrees: any) => {
        var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;
        return {
          x: centerX + (radius * Math.cos(angleInRadians)),
          y: centerY + (radius * Math.sin(angleInRadians))
        };
    }

    const describeArc = (x: any, y: any, radius: any, startAngle: any, platformData: any) => {
        let endAngle = (+platformData.userGammaSupplied * 360)/(+platformData.minGammaUpperLevel - +platformData.minGammaCurrentLevel);
        var start = polarToCartesian(x, y, radius, endAngle);
        var end = polarToCartesian(x, y, radius, startAngle);
        var start = polarToCartesian(x, y, radius, endAngle);
        var end = polarToCartesian(x, y, radius, startAngle);
    
        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    
        var d = [
            "M", start.x, start.y, 
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");
        return d;       
    }

    const toggleTooltipInfoModal = () => {
        setModalData({})
        setShowTooltipModal(false)
    }

    const showToolTipModal = async(title: any, message: any) =>{
        setShowTooltipModal(!showToolTip);
        setToolTipTitle(title);
        setToolTipMessage(message)
    }

    const toggleSwapPopupModal = () => {
        // setModalData({})
        setShowSwapPopUp(false)
    }
    
    const closeSwapModal = () => {
        setShowSwapPopUp(false)
    }

    const handleUnstakePopUpToggleModal = () => {
        setShowUnstakePopUp(false)
    } 
    
    const updateTokenList = async () => {
        const userAddress = selector.ethData.address
        try {
          const res = await returnTokenList(userAddress)
          if (!!res && typeof res !== 'undefined') {
            setTokenDataList(res)
          }
        } catch (error) {
          console.log('error===>', error)
        }
    }

    return (
        <FarmPoolContext.Provider value={farmPoolData}>
        <MobileHomeCont>

            <InfoDataCard className="marketTVLMob">
                <HeaderTxt className='totalMarketSize'>
                ${userAssetData.total_lending_tvl !== undefined ? commaFy(userAssetData.total_lending_tvl.toFixed(0)) : 0}
                </HeaderTxt>
                <FlexCont className="info-space" onClick={() => showToolTipModal("Collateral Value", "This is the total value of all assets you have supplied and have collateral turned on for.")}>
                    <CardText>TOTAL MARKET SIZE</CardText>
                    <ToolTipIcon className="tooltip-mob" src={QuestionIcon} alt="" left={5} />
                </FlexCont>
                
            </InfoDataCard>
            
            <InfoDataCard>
                <MobBalanceCont className="balance-space">
                    <div>
                        <FlexCont className="info-space" onClick={() => showToolTipModal("Collateral Value", "This is the total value of all assets you have supplied and have collateral turned on for.")}>
                            <CardText>Collateral</CardText>
                            <ToolTipIcon className="tooltip-mob" src={QuestionIcon} alt="" left={5} />
                        </FlexCont>
                        {gPlatformLoading ? <LoadingText2 /> :
                            getInfoNumFormat(!!gPlatformData && gPlatformData.suppliedAmountWithCollateral ? gPlatformData.suppliedAmountWithCollateral : 0)
                        }
                    </div>
                    <div>
                        <FlexCont className="info-space" onClick={() => showToolTipModal("Borrow Value", "This is the total value of assets you’ve borrowed from the market.")} >
                            <CardText >Borrow</CardText>
                            <ToolTipIcon className="tooltip-mob" src={QuestionIcon} alt=""  left={5} />
                        </FlexCont>
                        {gPlatformLoading ? <LoadingText2 /> :
                            getInfoNumFormat(!!gPlatformData && gPlatformData.userTotalBorrowBal ? gPlatformData.userTotalBorrowBal : 0)
                        }

                    </div>
                </MobBalanceCont>
                <MobBalanceCont className="">
                    
                    <div>
                        <FlexCont className="info-space">
                            <CardText>Daily Earnings</CardText>
                        </FlexCont>
                        {gPlatformLoading ? <LoadingText2 /> :
                            getInfoNumFormat(!!gPlatformData && gPlatformData.dailyEarnings ? gPlatformData.dailyEarnings : 0)
                        }
                    </div>
                    <div>
                        <FlexCont className="info-space">
                            <CardText >Net APY</CardText>
                        </FlexCont>
                        {gPlatformLoading ? <LoadingText1 width={10} /> :
                            <CardBoldText className={gPlatformData.userNetApyWithGamma == undefined || +gPlatformData.userNetApyWithGamma === 0.00 ? "netApyGrey": +gPlatformData.userNetApyWithGamma > 0 ? "netApyGreen": "netApyRed" } >{ gPlatformData.userNetApyWithGamma && parseFloat(gPlatformData.userNetApyWithGamma) > 0 ? "+"+parseFloat(gPlatformData.userNetApyWithGamma).toFixed(2) : gPlatformData.userNetApyWithGamma && parseFloat(gPlatformData.userNetApyWithGamma) < 0 ? parseFloat(gPlatformData.userNetApyWithGamma).toFixed(2) : "0.00"}%</CardBoldText>
                        }

                    </div>
                </MobBalanceCont>
                {/* <MobBalanceCont>
                    <div>
                        <FlexCont className="info-space">
                            <CardText>Daily Earnings</CardText>
                            <ToolTipIcon src={QuestionIcon} alt="" data-tip data-for="mDailyEarning" left={5} />
                        </FlexCont>
                        {gPlatformLoading ? <LoadingText2 /> :
                            getInfoNumFormat(!!gPlatformData && gPlatformData.dailyEarning ? gPlatformData.dailyEarning : 0)
                        }
                    </div>
                    <div>
                        <FlexCont className="info-space">
                            <CardText >Net APY</CardText>
                            <ToolTipIcon src={QuestionIcon} alt="" data-tip="hello" data-for="mNetApyInfo" left={5} />
                        </FlexCont>
                        {gPlatformLoading ? <LoadingText1 width={10} /> :
                            <CardBoldText className="netApy">{gPlatformData.userNetApyWithGamma ? parseFloat(gPlatformData.userNetApyWithGamma).toFixed(2) : '0.00'}%</CardBoldText>
                        }

                    </div>
                </MobBalanceCont> */}
                
            </InfoDataCard>
            <InfoDataCard>
                <FlexSBCont>
                    {/* {borrowLimitLoading ? <LoadingText2 width={10} /> : <CardBoldText className="borrowPercent">{borrowLimit.borrowLimitUsedCurr ? parseFloat(borrowLimit.borrowLimitUsedCurr).toFixed(2) : "0.00"}%</CardBoldText>}
                    {borrowLimitLoading ? <LoadingText2 width={10} /> :  <CardText>100%</CardText>} */}
                    {borrowLimitLoading ? <LoadingText2 width={10} /> : borrowLimit.borrowLimitUsedCurr > 0.01 ? <CardBoldText className="borrowPercent">{parseFloat(borrowLimit.borrowLimitUsedCurr).toFixed(2)}%</CardBoldText> : <CardText>0.00%</CardText>}
                    {<div><CardText className="finalLimit">100%</CardText>
                    {/* <ToolTipIcon className="tooltip-mob maxBorrowLimitIcon" src={QuestionIcon} alt="" data-tip data-for="mBorrowLimitInfo" left={5} /> */}
                    </div>} 
                </FlexSBCont>
                <SliderContainer borrowLimit={borrowLimit.borrowLimitUsedCurr} className={+borrowLimit.borrowLimitUsedCurr > 75 && +borrowLimit.borrowLimitUsedCurr < 90 ? 'SliderContainer OrangeLimit': +borrowLimit.borrowLimitUsedCurr > 90 ? 'SliderContainer RedLimit' : 'SliderContainer'}>
                    <VerticalCardDivider className="limiterGap"></VerticalCardDivider>
                    {/* <ToolTipIcon className="tooltip-mob borrowLimitIcon" src={QuestionIcon} alt="" data-tip data-for="mBorrowLimitInfo" left={5} /> */}
                    {/* <CardText className="borrowLimit">70%</CardText>
                    <VerticalCardDivider className="borrowLimit"></VerticalCardDivider> */}
                    <Slider min={0} max={100} value={borrowLimit.borrowLimitUsedCurr ? borrowLimit.borrowLimitUsedCurr : 0} tooltip={false} />
                </SliderContainer>
                <FlexSBCont>
                    <FlexCont>
                        <CardText>Borrow Usage</CardText>
                    </FlexCont>
                    <FlexCont onClick={() => showToolTipModal("Borrow Limit", "The maximum dollar amount you can borrow against your collateral. If this were to hit $0.00 you would be subject to liquidation with a penalty up to 10% of your collateral.")}>
                        <CardText>Limit: {gPlatformLoading ? <LoadingText2 width={10} /> : getInfoNumFormat(!!gPlatformData && gPlatformData.borrowLimitInUsd ? (parseFloat(gPlatformData.borrowLimitInUsd).toFixed(2)) : 0.00, true)}</CardText>
                        {/* parseFloat(gPlatformData.diffToUpperLevel).toFixed(4) */}
                        <ToolTipIcon className="tooltip-mob" src={QuestionIcon} alt="" left={5} />
                    </FlexCont>
                </FlexSBCont>
            </InfoDataCard>
            <InfoBoxMob className="gammaTxCards">
                {/* <InfoDataCard className='discountLevel'>
                    <FlexSBCont className='discountLevel'>
                        <div className="discountCard">
                            
                            <DiscountPie  className="stake-gamma-icon-cont">
                                <img className="stake-gamma-icon" src={InfinityGammaIcon} alt="" />
                            </DiscountPie>
                            <NormalBtn className="buyGammaMob" onClick={() => handleSupplyModal(gammaInfinityVault)} disabled={claimLoading || gPlatformLoading}>Stake</NormalBtn>
                        </div>
                        <div>
                                 
                        </div>
                        <CardText className="GammaText">
                            GAMMA
                        </CardText>
                        <CardText className="boostText">
                            Boost Generator
                        </CardText>
                        <CardText className="gammaTVL">
                        {gammaInfDataNew && gammaInfDataNew.tvl ? "$" + commaFy(convertToKAndM(gammaInfDataNew.tvl.toFixed(0)).toString()): ""} TVL
                        </CardText>
                        <NormalBtn className="gammaApy" disabled={true}>{gammaInfDataNew && gammaInfDataNew.apy ? commaFy(convertToKAndM(gammaInfDataNew.apy.toFixed(2)).toString()): "0.00"}% APY</NormalBtn>
                    </FlexSBCont>
                </InfoDataCard> */}
                <InfoDataCard className='gammaEarnings'>
                    <FlexSBCont className='discountLevel'>
                        <div className="discountCard">
                            <div>
                                <NormalBtn className="vaultEarnings" onClick={() => handleClaim()} disabled={claimLoading}>{claimLoading ? <Spinner /> : 'Collect'}</NormalBtn>
                                <CardText className="discountTextMob"> 
                                ${(!!userPortfolio && userPortfolio.greenPendingUsd && +greenPendingGAMMAUSD > 0 ? commaFy(parseFloat(greenPendingGAMMAUSD).toFixed(2)) : '0.00 ')}
                                </CardText>
                                 
                            </div>
                            <DiscountPie  className="discountPieMob">
                                <img className="gamma-icon" src={GammaIcon} alt="" />
                            </DiscountPie>
                            
                        </div>
                        <CardText className="gammaToLevelUp">
                           
                            {portfolioLoading ? <LoadingText1 /> :
                            <HeaderTxt className='gammaEarningsMobUSD'>
                                {portfolioLoading ? <LoadingText2 top={10} bottom={10} /> :
                                getGammaValFormat(!!userPortfolio && userPortfolio.greenPendingGAMMA ? greenPendingGAMMA : 0, true)
                                }
                                
                            </HeaderTxt>
                            }
                        </CardText>
                        <CardText className="discountText">Earnings</CardText>
                    </FlexSBCont>
                </InfoDataCard>
            </InfoBoxMob>
            <SwitchCard className='mobile-switch' activeSwitch={activeSwitch}>
                <SwitchBox className = {activeSwitch === "supply" ?"supp-switch mobile-switch supplyActive": "supp-switch mobile-switch"} activeSwitch={activeSwitch === "supply"} onClick={() => setSwitchActive('supply')}>
                    <p>Supply</p>
                </SwitchBox>
                <SwitchBox className={activeSwitch === "borrow" ?"bor-switch mobile-switch borrowActive":"bor-switch mobile-switch" }activeSwitch={activeSwitch === "borrow"} onClick={() => setSwitchActive('borrow')}>
                    <p>Borrow</p>
                </SwitchBox>
            </SwitchCard>
            {
                activeSwitch === "supply" &&
                <>
                    
                    <SuppliedAsset data={userAssetData.marketData} currLevel={suppLevel} />
                    
                    <FlexSBCont className='mobileDropdown'>
                        <MarketButton className="MarketButtonMob">
                            <div className='MarketButtonDiv' onClick={() => handlePathLink('markets')}>
                                <img className='mobileMarketBtnImg' src={MarketIcon} alt="" />
                                <MarketBtn className="showMarketBtnMobText" >
                                    View Markets
                                </MarketBtn>
                                <span className="marketSymbol"><img className='RightIcon' src={RightIcon} alt="" /></span>
                            </div>
                        </MarketButton>
                    </FlexSBCont>
                </>

            }
            {
                activeSwitch === "borrow" &&
                <>
                    {/* {userAssetData.borrowedAssets.length > 0 &&
                        <BorrowedAsset data={userAssetData.borrowedAssets} currLevel={borLevel} />
                    }
                    <BorrowMarket data={(userAssetData.marketData).filter((e: any) => e.borrowPaused === false)} currLevel={borLevel} /> */}
                    <BorrowedAsset data={userAssetData.marketData} currLevel={discountLevel} />
                    <FlexSBCont className='mobileDropdown'>
                        <Select  options={userDiscountLevel} handleSelect={handleLevelSelect} iconColor={"grey"} defaultValue={discountLevel} value={discountLevel} />
                    </FlexSBCont>
                </>
            }

            <CustomModal show={showEnableAsset} toggleModal={setShowEnableAsset}>
                {showEnableAsset && (
                    <ActivateAsset
                        data={modalData}
                        setShowEnableAsset={setShowEnableAsset}
                        setShowAssetSupply={setShowAssetSupply}
                        setIsAllowance={setIsAllowance}
                    />
                )}
            </CustomModal>
            
            {/* <CustomModal show={showAssetSupply} toggleModal={setShowAssetSupply}>
                {showAssetSupply && (
                    <AssetSupply
                        data={modalData}
                        setShowAssetSupply={setShowAssetSupply}
                        isAllowance={isAllowance}
                    />
                )}
            </CustomModal> */}
            {<CustomActionModal show={showVaultDetails} toggleModal={toggleVaultDetailsModal}>
            {showVaultDetails && (
                <AssetSupplyRevised
                    data={modalData}
                    setShowAssetSupply={setShowAssetSupply}
                    toggleVaultModal={toggleVaultDetailsModal} 
                    setModalData={setModalData} 
                    currentTab={"all"}
                />
            )}
          </CustomActionModal>}

          <CustomActionModal show={showPoolDetails} toggleModal={togglePoolDetailsModal}>
            {showPoolDetails && 
            <PoolDetailsModalForLending
              togglePoolModal={togglePoolDetailsModal} 
              data={modalData} 
              handleGetLpToken={handleGetLpToken} 
              setModalData={setModalData} 
              setTokenSwapAmount={setTokenSwapAmount}
              currentTab={"all"} 
              sendToken={sendToken}
              tokenDataList={tokenDataList}
              setSendTokenFromParent={setSendToken}
              showSwapPopup={showSwapPopup}
              setUnstakeData={setUnstakeData}
              setShowUnstakePopUp={setShowUnstakePopUp}
              confirmUnstake={confirmUnstake}
              setConfirmUnstake={setConfirmUnstake}
              showUnstakePopUp={showUnstakePopUp}
            />}
          </CustomActionModal>
          <CustomActionModal show={showSwapPopUp} toggleModal={toggleSwapPopupModal}>
              {showSwapPopUp && <SwapInPools tokenList={tokenDataList} updateTokenList={updateTokenList} initialTokenAdd={initialTokenAdd} receiveTokenNumber={receiveTokenNumber} closeSwapModal={closeSwapModal} tokenSwapAmount={tokenSwapAmount} toggleSwapPopupModal={toggleSwapPopupModal} sendTokenObject={sendToken} />}
          </CustomActionModal>
          <CustomModal show={showUnstakePopUp} toggleModal={handleUnstakePopUpToggleModal}>
              <UnStakeInstantlyPopUp 
                  setShowUnstakePopUp={setShowUnstakePopUp} 
                  showUnstakePopUp={showUnstakePopUp}
                  unstakeData={unstakeData}
                  setConfirmUnstake={setConfirmUnstake}
              />
          </CustomModal>
            {/* <CustomModal show={showCollateral} toggleModal={setShowCollateral}>
                {showCollateral && (
                    <Collateral
                        setShowCollateral={setShowCollateral}
                        data={collateralData}
                        enableCollateral={!collateralData.isUsingAsCollateral}
                    />
                )}
            </CustomModal> */}
            <ToolTipModal 
                showToolTip={showToolTip}
                toggleToolTipModal={setShowTooltipModal}
                title={toolTipTitle}
                message={toolTipMessage} 
            />
            {/* <CustomModal show={showTooltipModal} toggleModal={setShowTooltipModal} infoPopUpContainer={true}> */} 
            {/* </MobileHomeCont></CustomModal> */}
            <ReactTooltip className="planet-tool-tip" id="mGammaBalanceInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>The total GAMMA you have in your wallet, supplied to markets and in liquidity pools.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mGammaEarnedInfo" place="right" effect="solid" multiline={true}>
                <ToolTipText>This is your uncollected GAMMA earned from supplying & borrowing assets. You can press collect to claim your GAMMA.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mTotalMarketSizeInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>The total value of all assets supplied.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mSupplyBalanceInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>This is the total value of assets you’ve supplied to the market.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mBorrowBalanceInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>This is the total value of assets you’ve borrowed from the market.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mNetApyInfo" place="right" effect="solid" multiline={true}>
                <ToolTipText>Your total estimated annualized interest rate, of all assets supplied and borrowed at the current market rates.
                    (Estimated Annualized Earnings/Supply Balance) * 100</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mBorrowLimitInfo" place="right" effect="solid" multiline={true}>
                <ToolTipText>The maximum value as a % that you can borrow against your collateral. If this were to hit 100% you
                    would be subject to liquidation with a penalty up to 10% of your collateral.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mAvailableCreditInfo" place="top" effect="solid" multiline={true}>
                <ToolTipText>The maximum dollar amount you can borrow against your collateral. If this were to hit $0.00 you
                    would be subject to liquidation with a penalty up to 10% of your collateral.</ToolTipText>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mCurrentLevelInfo" place="right" effect="solid" multiline={true} >
                <ToolTipDataCont>
                    <ToolTipText>There are 3 discount levels that can be reached by supplying GAMMA.</ToolTipText>
                    <ul>
                        <li>Level 1: 5% off protocol fees</li>
                        <li>Level 2: 20% off protocol fees</li>
                        <li>Level 3: 50% off protocol fees</li>
                    </ul>
                </ToolTipDataCont>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mStackRatioInfo" place="right" effect="solid" multiline={true} >
                <ToolTipDataCont>
                    <ToolTipText>This refers to the USD value of your GAMMA supplied, as a percentage, relative to the USD value of
                        assets you’re supplying. This is how your Discount Level is calculated.</ToolTipText>
                    <ul>
                        <li>Level 1: (1-4.99%)</li>
                        <li>Level 2: (5-9.99%)</li>
                        <li>Level 3: (Greater than 10%)</li>
                    </ul>
                </ToolTipDataCont>
            </ReactTooltip>
            <ReactTooltip className="planet-tool-tip" id="mDailyEarning" place="top" effect="solid" multiline={true}>
                <ToolTipText>This is an estimated amount that you will earn over the next 24 hours at the current numbers.</ToolTipText>
            </ReactTooltip>

        </MobileHomeCont>
        </FarmPoolContext.Provider>
    );
};

export default MobLending;