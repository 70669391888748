import { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { FlexSBCont, FlexCont } from 'shared/styles/styled'
import {
  MobileNavWrapper,
  MobileNavCont,
  MobNavHeader,
  MobAddressBox,
  MobNavItemCont,
  MobNavItem,
  MobNavDivider,
  MobNavLink,
  MobNavCardCont,
  MobNavCard,
  MobNavGreenCard,
  MovNavPinkCard,
  MobCardTitle,
  MobCardInfo,
  MobNavItemSubText
} from './style'
import CrossIcon from 'assets/icons/white-cross.svg'
import AquaIcon from 'assets/icons/blue-icon.svg'
import GammaIcon from 'assets/icons/green_planet_no_shadow.svg'
import PinkIcon from 'assets/icons/Pink.png'
import AccountIcon from 'assets/icons/walletIcon.svg'
import GreenCheckIcon from 'assets/icons/green-check.png'
import { analyticsLink, aquaVotingLink, docsLink } from 'service/global-constant'
import { lendingPathArr } from 'service/dataConstant'
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'
import baseIcon from 'assets/icons/base.png'
import vaultIcon from 'assets/icons/vaults.png'
import poolIcon from 'assets/icons/pools.png'
import swapIcon from 'assets/icons/swap.png'
import lendingIcon from 'assets/icons/lending.png'
import perpetualsIcon from 'assets/icons/Perpetuals_icon.png'
import { MobCollapseIcon, ConnectBtn } from '../header/style'
import gammaInfinityIcon from 'assets/icons/gamma_inf.png'
import BNBxIcon from 'assets/icons/BNBx.png';

const MobileSideNav = (props: any) => {
  const [activeTab, setActiveTab] = useState('base')
  const [isCopied, setIsCopied] = useState(false)
  const { showSideNav, setShowSideNav, address } = props

  useEffect(() => {
    const pathUrl: any = props.location.pathname.split('/')
    if (pathUrl[1]) {
      setActiveTab(pathUrl[1])
    } else {
      setActiveTab('base')
    }
    window.scrollTo(0, 0)
  }, [props.location.pathname])

  useEffect(() => {
    if (isCopied) {
      const interval = setInterval(() => {
        setIsCopied(false)
      }, 2000)

      return () => {
        clearInterval(interval);
      }
    }
  }, [isCopied])


  const handlePathClick = (item: string) => {
    setActiveTab(item)
    setShowSideNav(false)
    if (item === 'base') {
      history.push(`${Paths.root}`)
    }
    // if (item === 'boost') {
    //   history.push(`${Paths.boost}`)
    // }
    if (item === 'vaults') {
      history.push(`${Paths.vaults}`)
    }
    if (item === 'pools') {
      history.push(`${Paths.pools_v3}`)
    }
    if (item === 'pools_v2') {
      history.push(`${Paths.pools}`)
    }
    if (item === 'migration') {
      history.push(`${Paths.migration}`)
    }
    if (item === 'vesting') {
      history.push(`${Paths.vesting}`)
    }
    if (item === 'bounty') {
      history.push(`${Paths.bounty}`)
    }
    if (item === 'swap') {
      history.push(`${Paths.swap}`)
    }
    if (item === 'lending') {
      history.push(`${Paths.lending}`)
    }
    if (item === 'markets') {
      history.push(`${Paths.markets}`)
    }
    if (item === 'metrics') {
      history.push(`${Paths.metrics}`)
    }
    if (item === 'lending_v1') {
      history.push(`${Paths.lending_v1}`)
    }
    if (item === 'markets_v1') {
      history.push(`${Paths.markets_v1}`)
    }
    if (item === 'liquidations') {
      history.push(`${Paths.liquidations}`)
    }
    if (item === 'vaults_v1') {
      history.push(`${Paths.vaults_v1}`)
    }
    if (item === 'pools_v1') {
      history.push(`${Paths.pools_v1}`)
    }
    if (item === 'compensation') {
      history.push(`${Paths.compensation}`)
    }
    if (item === 'liquidstaking') {
      history.push(`${Paths.liquidStaking}`)
    }
  }

  const renderAddress = (address: string) => {
    // console.log(address)
    if(address != null){
      const add1 = address.substring(0, 2)
      const add2 = address.substring(address.length - 4)
      const finalAdd = `${add1}....${add2}`
      return finalAdd
    } else {
      return "Connect"
    }
    
  }

  const copyAddressText = async () => {
    try {
      await navigator.clipboard.writeText(address)
      setIsCopied(true)
    }
    catch (err) {
      setIsCopied(false)
    }
  }

  const getAddress = (address: string) => {
    const add1 = address.substring(0, 2)
    const add2 = address.substring(address.length - 4)
    const finalAdd = `${add1}....${add2}`
    return finalAdd
  }

  return (
    <MobileNavWrapper open={showSideNav} >
      <MobileNavCont>
        <MobNavHeader>
          <FlexSBCont>
            <FlexCont>
              <img className="planet-icon" src={AquaIcon} alt="" />
              <p>Planet</p>
            </FlexCont>
            <FlexCont className='mob-sidebar-header'>
              <ConnectBtn onClick={() => copyAddressText()}>{renderAddress(address)}</ConnectBtn>
              <img className="mob-cross-icon" src={CrossIcon} alt="" onClick={() => setShowSideNav(false)} />
            </FlexCont>
            
          </FlexSBCont>
        </MobNavHeader>
        
        <MobNavItemCont className='mob-nav-cont'>
          {/* <div className='mob-nav-cont' onClick={() => handlePathClick("boost")}>
            <img className="mob-nav-icon" src={gammaInfinityIcon} alt="" />
            <div className='mob-nav-textCont'>
              <MobNavItem isActive={activeTab === 'boost'} onClick={() => handlePathClick("boost")}>Boost</MobNavItem>
              <MobNavItemSubText isActive={activeTab === 'boost'}>Estimate your boost earnings</MobNavItemSubText>
            </div>
          </div>
          <MobNavDivider /> */}
          <div className='mob-nav-cont' onClick={() => handlePathClick("base")}>
            <img className="mob-nav-icon" src={baseIcon} alt="" />
            <div className='mob-nav-textCont'>
              <MobNavItem isActive={activeTab === 'base'} onClick={() => handlePathClick("base")}>BASE</MobNavItem>
              <MobNavItemSubText isActive={activeTab === 'base'}>What’s Happening On Planet</MobNavItemSubText>
            </div>
          </div>
          <MobNavDivider />
          <div className='mob-nav-cont' onClick={() => handlePathClick("pools")}>
            <img className="mob-nav-icon" src={poolIcon} alt="" />
            <div className='mob-nav-textCont'>
              <MobNavItem isActive={activeTab === 'pools'} onClick={() => handlePathClick("pools")}>POOLS</MobNavItem>
              <MobNavItemSubText isActive={activeTab === 'pools'}>Earn Trade Fees & GAMMA</MobNavItemSubText>
            </div>
          </div>
          <MobNavDivider />
          <div className='mob-nav-cont' onClick={() => handlePathClick("pools_v2")}>
            <img className="mob-nav-icon" src={poolIcon} alt="" />
            <div className='mob-nav-textCont'>
              <MobNavItem isActive={activeTab === 'pools_v2'} onClick={() => handlePathClick("pools")}>OLD POOLS</MobNavItem>
              <MobNavItemSubText isActive={activeTab === 'pools_v2'}>Older pools & GAMMA</MobNavItemSubText>
            </div>
          </div>
          <MobNavDivider />
          <div className='mob-nav-cont' onClick={() => handlePathClick("migration")}>
            <img className="mob-nav-icon" src={poolIcon} alt="" />
            <div className='mob-nav-textCont'>
              <MobNavItem isActive={activeTab === 'migration'} onClick={() => handlePathClick("pools")}>MIGRATION</MobNavItem>
              {/* <MobNavItemSubText isActive={activeTab === 'pools'}>Earn Trade Fees & GAMMA</MobNavItemSubText> */}
            </div>
          </div>
          <MobNavDivider />
          <div className='mob-nav-cont' onClick={() => handlePathClick("vesting")}>
            <img className="mob-nav-icon" src={poolIcon} alt="" />
            <div className='mob-nav-textCont'>
              <MobNavItem isActive={activeTab === 'vesting'} onClick={() => handlePathClick("vesting")}>VESTING</MobNavItem>
              {/* <MobNavItemSubText isActive={activeTab === 'pools'}>Earn Trade Fees & GAMMA</MobNavItemSubText> */}
            </div>
          </div>
          <MobNavDivider />
          <div className='mob-nav-cont' onClick={() => handlePathClick("bounty")}>
            <img className="mob-nav-icon" src={poolIcon} alt="" />
            <div className='mob-nav-textCont'>
              <MobNavItem isActive={activeTab === 'bounty'} onClick={() => handlePathClick("bounty")}>BOUNTY</MobNavItem>
              {/* <MobNavItemSubText isActive={activeTab === 'pools'}>Earn Trade Fees & GAMMA</MobNavItemSubText> */}
            </div>
          </div>
          <MobNavDivider />
          <div className='mob-nav-cont' onClick={() => handlePathClick("lending")}>
            <img className="mob-nav-icon" src={lendingIcon} alt="" />
            <div className='mob-nav-textCont'>
              <MobNavItem isActive={activeTab === 'lending'} onClick={() => handlePathClick("lending")}>LEND</MobNavItem>
              <MobNavItemSubText>Earn Instantly & Borrow Assets</MobNavItemSubText>
            </div>
          </div>
          <MobNavDivider />
          <div className='mob-nav-cont' onClick={() => handlePathClick("liquidstaking")}>
            <img className="mob-nav-icon" src={BNBxIcon} alt="" />
            <div className='mob-nav-textCont'>
              <MobNavItem isActive={activeTab === 'liquidstaking'} onClick={() => handlePathClick("liquidstaking")}>Liquid Staking</MobNavItem>
              <MobNavItemSubText>Derivatives Trading</MobNavItemSubText>
            </div>
          </div>
          <MobNavDivider />
          <div className='mob-nav-cont' onClick={() => handlePathClick("swap")}>
            <img className="mob-nav-icon" src={swapIcon} alt="" />
            <div className='mob-nav-textCont'>
              <MobNavItem isActive={activeTab === 'swap'} onClick={() => handlePathClick("swap")}>SWAP</MobNavItem>
              <MobNavItemSubText isActive={activeTab === 'swap'}>Deep Autonomous Liquidity</MobNavItemSubText>
            </div>
          </div>
          <MobNavDivider />
          
          <div className='mob-nav-cont' onClick={()=> window.open("https://perp.planet.finance", "_blank")}>
            <img className="mob-nav-icon" src={perpetualsIcon} alt="" />
            <div className='mob-nav-textCont'>
              <MobNavItem isActive={activeTab === 'perpetuals'} onClick={()=> window.open("https://perp.planet.finance", "_blank")}>PERPETUALS</MobNavItem>
              <MobNavItemSubText>Derivatives Trading</MobNavItemSubText>
            </div>
          </div>
          <MobNavDivider />

        </MobNavItemCont>
        <div className='mob-sidebar-links'>
          <a href={analyticsLink} target='_blank' rel="noreferrer"><MobNavLink className="mob-links" >Analytics</MobNavLink></a>
          <MobNavLink className="mob-links" onClick={() => handlePathClick("markets")}>Markets</MobNavLink>
        </div>
        <div className='mob-sidebar-links'>
          <MobNavLink className="mob-links" onClick={() => handlePathClick("metrics")}>Metrics</MobNavLink>
          <a href={aquaVotingLink} target='_blank' rel="noreferrer"><MobNavLink className="mob-links" >Governance</MobNavLink></a>
        </div>
        <div className='mob-sidebar-links'>
          <a href={docsLink} target='_blank' rel="noreferrer"><MobNavLink className="mob-links">Learn More</MobNavLink></a>
          <MobNavLink className="mob-links" onClick={() => handlePathClick("liquidations")}>Liquidations</MobNavLink>
        </div>
        {/* <div className='mob-sidebar-links'>
          <MobNavLink className="mob-links" onClick={() => handlePathClick("lending_v1")}>Lending V1</MobNavLink>
          <MobNavLink className="mob-links" onClick={() => handlePathClick("markets_v1")}>Markets V1</MobNavLink>
        </div> */}
        <div className='mob-sidebar-links'>
          <MobNavLink className="mob-links" onClick={() => handlePathClick("vaults_v1")}>Vaults V1</MobNavLink>
          <MobNavLink className="mob-links" onClick={() => handlePathClick("compensation")}>Compensation</MobNavLink>
        </div>
      </MobileNavCont>
    </MobileNavWrapper>
  )
}

export default withRouter(MobileSideNav)
