import { useState } from 'react';
import { FlexSBCont } from 'shared/styles/styled'
import { HistoryCont, HistoryTitle, HistorySwitchCont, SwitchText, TableWrapper, HistoryTable, TableRow, HeadText, CellText, AmountText, TransLink, EmptyText } from './style'
import ComponentLoader from 'shared/component-loader'
import LinkIcon from 'assets/icons/transactionlink.png'
import { transLinkUrl } from 'service/global-constant'
import { commaFy } from 'service/globalFunctions';
import moment from 'moment';


const MetricsHistory = (props: any) => {
    const [activeHistory, setActiveHistory] = useState('usd')
    const { activeToken, loading, aquaBurnData, gammaBurnData } = props


    const getAddress = (address: string) => {
        const add1 = address.substring(0, 2)
        const add2 = address.substring(address.length - 3)
        const finalAdd = `${add1}....${add2}`
        return finalAdd
    }


    const _renderHistoryRow = () => {
        const historyData = activeToken === 'gamma' ? gammaBurnData : aquaBurnData
        if (historyData.length > 0) {
            return historyData.map((item: any, index: number) => {
                return (
                    <TableRow key={index}>
                        {activeHistory === 'usd' ?
                            <AmountText>${commaFy(parseFloat(item.amountInUsd).toFixed(4))}</AmountText> :
                            <AmountText>{`${commaFy(parseFloat(item.amount).toFixed(4))} ${item.symbol}`}</AmountText>
                        }
                        <CellText className="hash-web" light>{item.transactionHash}</CellText>
                        <CellText className="hash-mobile" light>{getAddress(item.transactionHash)}</CellText>
                        <CellText className="hash-web" light>{moment(item.date).format("MMM Do YYYY")}</CellText>
                        <CellText className="hash-mobile" light>{moment(item.date).format('MM/DD/YYYY')}</CellText>
                        <TransLink className="web-cell" href={`${transLinkUrl}/${item.transactionHash}`} target='_blank'>
                            <img src={LinkIcon} alt="" />
                        </TransLink>
                    </TableRow>
                )
            })
        }
        else {
            return <EmptyText>No data available </EmptyText>
        }

    }

    return (
        <HistoryCont>
            <FlexSBCont>
                <HistoryTitle className="web-cell">{activeToken === 'gamma' ? 'GAMMA Buy History' : 'AQUA Burn History'}</HistoryTitle>
                <HistoryTitle className="mobile-cell">{activeToken === 'gamma' ? 'Buy History' : 'Burn History'}</HistoryTitle>
                <HistorySwitchCont activeHistory={activeHistory}>
                    <SwitchText className="usd" isActive={activeHistory === "usd"} onClick={() => setActiveHistory('usd')}>
                        <p>USD</p>
                    </SwitchText>
                    <SwitchText className="asset" isActive={activeHistory === "asset"} onClick={() => setActiveHistory('asset')}>
                        <p>{activeToken === 'gamma' ? 'GAMMA' : 'AQUA'}</p>
                    </SwitchText>

                </HistorySwitchCont>
            </FlexSBCont>
            <TableWrapper>
                <HistoryTable>
                    <TableRow className="table-header">
                        <HeadText>Amount</HeadText>
                        <HeadText className="web-cell">Transaction Hash</HeadText>
                        <HeadText className="mobile-cell">Transaction</HeadText>
                        <HeadText>Date</HeadText>
                        <HeadText className="web-cell">View</HeadText>
                    </TableRow>
                    {loading ? <ComponentLoader /> : _renderHistoryRow()}

                </HistoryTable>
            </TableWrapper>
        </HistoryCont>
    );
};

export default MetricsHistory;
