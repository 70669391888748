import { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'
import { MainContainer, PlanetCard, FlexCont, LoadingText2 } from 'shared/styles/styled'
import { MetricsCont, MetricsHeader, InfoWrapper, Title, PriceBox, PriceTitle, PriceText, PriceGraph, TimeBox, TimeText, InfoCardWrapper, InfoCont, InfoCard, CardText, CardNumText, SwitchCont, SwitchToken, SwitchTokenTxt } from './style'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GammaIcon from 'assets/icons/gamma-icon.png'
import MarketCapIcon from 'assets/icons/market-cap.svg'
import ReserveFundIcon from 'assets/icons/treasury.png'
import GammaMoneyIcon from 'assets/icons/money-icon.png'
import AQUABurnedIcon from 'assets/icons/burned-icon.svg'
import ReserveIcon from 'assets/icons/reserve.png'
import MetricsChart from './components/MetricsChart'
import MetricsHistory from './components/MetricsHistory'
import { colors } from "shared/styles/theme";
import { commaFy } from 'service/globalFunctions';
import { getMetricsPageData, getGammaBought } from 'modules/block-chain-green/LendingBase';

const Metrics = (props: any) => {
    const selector = useSelector((state: any) => state)
    const [activeToken, setActiveToken] = useState("gamma")
    const [activeTime, setActiveTime] = useState("hours")
    const [loading, setLoading] = useState(false)
    const [boughtLoading, setBoughtLoading] = useState(false)
    const [gammaBought, setGammaBought] = useState('0')
    const [metricsData, setMetricsDate] = useState<any>({})
    const [aquaBurnData, setAquaBurnData] = useState<any>([])
    const [gammaBurnData, setGammaBurnData] = useState<any>([])
    const [aquaGraphData, setAquaGraphData] = useState<any>([])
    const [gammaGraphData, setGammaGraphData] = useState<any>([])
    const { platformLoading, platformData } = selector.user

    useEffect(() => {
        const pathData: any = props.location.state
        if (!!pathData) {
            setActiveToken(pathData)
        }
        else {
            setActiveToken('gamma')
        }
    }, [props.location.state])

    useEffect(() => {
        const fetchGammaBought = async () => {
            try {
                setBoughtLoading(true)
                const res: any = await getGammaBought()
                if (!!res && typeof res !== 'undefined') {
                    setGammaBought(res)
                }
            }
            catch (error) {
                console.log("error===>", error)
            }
            finally {
                setBoughtLoading(false)
            }
        }
        fetchGammaBought()
    }, [])

    useEffect(() => {
        const getHistoryData = async () => {
            try {
                setLoading(true)
                const res = await getMetricsPageData(1)
                if (!!res && typeof res !== 'undefined') {
                    setMetricsDate(res)
                    setAquaBurnData(res.aqua_burn_history)
                    setGammaBurnData(res.gamma_buy_history)
                    if(res.gamma_history_price.length > 0){
                        setGammaGraphData(res.gamma_history_price)
                    } else {
                        getDataWithTimeForGamma("hours",30)
                    }
                    
                    setAquaGraphData(res.aqua_history_price)
                    
                }
            }
            catch (error) { 
                console.log("error", error)
            }
            finally {
                setLoading(false)
            }
        }
        getHistoryData()
    }, [])

    const getDataWithTimeForGamma = async (interval: string, days: number) => {
        setActiveTime(interval)
        try {
            const res = await getMetricsPageData(days)
            if (!!res && typeof res !== 'undefined') {
                setGammaGraphData(res.gamma_history_price)
            }
        }
        catch (error) { 
            console.log("error", error)
        }
    }

    const getDataWithTime = async (interval: string, days: number) => {
        setActiveTime(interval)
        try {
            const res = await getMetricsPageData(days)
            if (!!res && typeof res !== 'undefined') {
                setMetricsDate(res)
                setAquaBurnData(res.aqua_burn_history)
                // setGammaBurnData(res.gamma_buy_history)
                setAquaGraphData(res.aqua_history_price)
                // setGammaGraphData(res.gamma_history_price)
                if(res.gamma_buy_history.length > 0){
                    setGammaBurnData(res.gamma_buy_history)
                    setGammaGraphData(res.gamma_history_price)
                } else {
                    getDataWithTimeForGamma(interval,30)
                }
            }
        }
        catch (error) { 
            console.log("error", error)
        }
    }

    return (
        <MainContainer>
            <MetricsCont>
                <MetricsHeader>
                    <Title>Metrics</Title>
                    <SwitchCont activeToken={activeToken}>
                        <SwitchToken className="aqua-switch" isActive={activeToken === "aqua"} onClick={() => setActiveToken('aqua')}>
                            <FlexCont>
                                <img src={AQUAIcon} alt="" />
                                <SwitchTokenTxt isActive={activeToken === "aqua"}>AQUA</SwitchTokenTxt>
                            </FlexCont>
                        </SwitchToken>
                        <SwitchToken className="gamma-switch" isActive={activeToken === "gamma"} onClick={() => setActiveToken('gamma')}>
                            <FlexCont>
                                <img src={GammaIcon} alt="" />
                                <SwitchTokenTxt isActive={activeToken === "gamma"}>GAMMA</SwitchTokenTxt>
                            </FlexCont>
                        </SwitchToken>
                    </SwitchCont>
                </MetricsHeader>
                <InfoWrapper>
                    <PlanetCard>
                        <PriceBox>
                            <div>
                                <PriceTitle>{activeToken === "gamma" ? 'GAMMA Price' : 'AQUA Price'}</PriceTitle>
                                {loading ? <LoadingText2 className="loading-space" width={100} /> :
                                    <>
                                        {activeToken === "gamma" ?
                                            <>
                                                <PriceText type={activeToken} className="on-web" isNegative={parseFloat(metricsData.gamma_price_change) < 0}>
                                                    ${!!metricsData && metricsData.gamma_latest_price ? commaFy(parseFloat(metricsData.gamma_latest_price).toFixed(2)) : '0.00'}
                                                    <span>{`(${parseFloat(metricsData.gamma_price_change) > 0 ? '+' : ''} ${!!metricsData && metricsData.gamma_price_change ? parseFloat(metricsData.gamma_price_change).toFixed(2) : '0.00'}%)`}</span>
                                                </PriceText>
                                                <PriceText type={activeToken} className="on-mobile">${!!metricsData && metricsData.gamma_latest_price ? commaFy(parseFloat(metricsData.gamma_latest_price).toFixed(2)) : '0.00'}</PriceText>
                                            </> :
                                            <>
                                                <PriceText type={activeToken} className="on-web" isNegative={parseFloat(metricsData.aqua_price_change) < 0}>
                                                    ${!!metricsData && metricsData.aqua_latest_price ? commaFy(parseFloat(metricsData.aqua_latest_price).toFixed(2)) : '0.00'}
                                                    <span>{`(${parseFloat(metricsData.aqua_price_change) > 0 ? '+' : ''} ${!!metricsData && metricsData.aqua_price_change ? parseFloat(metricsData.aqua_price_change).toFixed(2) : '0.00'}%)`}</span>
                                                </PriceText>
                                                <PriceText type={activeToken} className="on-mobile">${!!metricsData && metricsData.aqua_latest_price ? commaFy(parseFloat(metricsData.aqua_latest_price).toFixed(2)) : '0.00'}</PriceText>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                            {!loading &&
                                <>
                                    {
                                        activeToken === "gamma" ?
                                            <PriceText type={activeToken} className="on-mobile" isNegative={parseFloat(metricsData.gamma_price_change) < 0}><span>{`${parseFloat(metricsData.gamma_price_change) > 0 ? '+' : ''} ${!!metricsData && metricsData.gamma_price_change ? parseFloat(metricsData.gamma_price_change).toFixed(2) : '0.00'}%`}</span></PriceText> :
                                            <PriceText type={activeToken} className="on-mobile" isNegative={parseFloat(metricsData.aqua_price_change) < 0}><span>{`${parseFloat(metricsData.aqua_price_change) > 0 ? '+' : ''} ${!!metricsData && metricsData.aqua_price_change ? parseFloat(metricsData.aqua_price_change).toFixed(2) : '0.00'}%`}</span></PriceText>
                                    }
                                </>
                            }

                        </PriceBox>
                        <PriceGraph>
                            <MetricsChart
                                lineColor={activeToken === "gamma" ? colors.lightGreen : colors.lightBlue}
                                data={activeToken === "gamma" ? gammaGraphData : aquaGraphData}
                            />
                        </PriceGraph>
                        <TimeBox>
                            <FlexCont>
                                <TimeText activeTime={activeTime === 'hours'} onClick={() => getDataWithTime('hours', 1)}>24H</TimeText>
                                <TimeText activeTime={activeTime === 'week'} onClick={() => getDataWithTime('week', 7)}>7D</TimeText>
                                <TimeText activeTime={activeTime === 'month'} onClick={() => getDataWithTime('month', 30)}>1M</TimeText>
                            </FlexCont>
                        </TimeBox>
                    </PlanetCard>
                    <InfoCardWrapper>
                        <InfoCont>
                            <InfoCard>
                                <FlexCont>
                                    <img src={MarketCapIcon} alt="" />
                                    <div>
                                        <CardText>Market Cap</CardText>
                                        {platformLoading ?
                                            <LoadingText2 className="loading-space" width={100} /> :
                                            <>
                                                {activeToken === 'gamma' ?
                                                    <CardNumText>${!!platformData && platformData.gamma_marketcap ? commaFy(parseFloat(platformData.gamma_marketcap).toFixed(2)) : '0.00'}</CardNumText> :
                                                    <CardNumText>${!!platformData && platformData.aqua_marketcap ? commaFy(parseFloat(platformData.aqua_marketcap).toFixed(2)) : '0.00'}</CardNumText>
                                                }

                                            </>
                                        }
                                    </div>
                                </FlexCont>
                            </InfoCard>
                            {
                                activeToken === 'gamma' ?
                                    <InfoCard>
                                        <FlexCont>
                                            <img src={ReserveIcon} alt="" />
                                            <div>
                                                <CardText>Reserve</CardText>
                                                {platformLoading ?
                                                    <LoadingText2 className="loading-space" width={100} /> :
                                                    <CardNumText>${!!platformData && platformData.reserve ? commaFy(parseFloat(platformData.reserve).toFixed(2)) : '0.00'}</CardNumText>
                                                }
                                            </div>
                                        </FlexCont>
                                    </InfoCard> :
                                    <InfoCard>
                                        <FlexCont>
                                            <img src={ReserveFundIcon} alt="" />
                                            <div>
                                                <CardText>Treasury</CardText>
                                                {platformLoading ?
                                                    <LoadingText2 className="loading-space" width={100} /> :
                                                    <CardNumText>${!!platformData && platformData.treasury ? commaFy(parseFloat(platformData.treasury).toFixed(2)) : '0.00'}</CardNumText>
                                                }
                                            </div>
                                        </FlexCont>
                                    </InfoCard>
                            }
                            {activeToken === 'gamma' ?
                                <InfoCard>
                                    <FlexCont>
                                        <img src={GammaMoneyIcon} alt="" />
                                        <div>
                                            <CardText>GAMMA Bought</CardText>
                                            {boughtLoading ?
                                                <LoadingText2 className="loading-space" width={100} /> :
                                                <CardNumText>${commaFy(parseFloat(gammaBought).toFixed(2))}</CardNumText>
                                            }

                                        </div>
                                    </FlexCont>
                                </InfoCard> :
                                <InfoCard>
                                    <FlexCont>
                                        <img src={AQUABurnedIcon} alt="" />
                                        <div>
                                            <CardText>AQUA Burned</CardText>
                                            {platformLoading ?
                                                <LoadingText2 className="loading-space" width={100} /> :
                                                <CardNumText>${!!platformData && platformData.aqua_burned_usd ? commaFy(parseFloat(platformData.aqua_burned_usd).toFixed(2)) : '0'}</CardNumText>
                                            }

                                        </div>
                                    </FlexCont>
                                </InfoCard>
                            }

                        </InfoCont>
                    </InfoCardWrapper>
                </InfoWrapper>
                <MetricsHistory
                    activeToken={activeToken}
                    loading={loading}
                    aquaBurnData={aquaBurnData}
                    gammaBurnData={gammaBurnData}
                />
            </MetricsCont>
        </MainContainer>

    );
};

export default withRouter(Metrics)