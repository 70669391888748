import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { PlanetCard, } from 'shared/styles/styled'
import { Button } from 'shared/button'

export const TextBox = styled.div`
  font-size: 30px;
  line-height: 37px;
  font-family: MontserratBold;
  color: #FFFFFF;
  &.claimBtnText{
    font-size: 20px;
    line-height: 24px;
    color: #55FF76;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 24px;
    line-height: 29px;
    &.claimBtnText{
      font-size: 18px;
      line-height: 22px;
      color: #55FF76;
    }
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {

    &.claimBtnText{
      font-size: 20px;
      line-height: 24px;
      color: #55FF76;
    }
  }
  
  @media (min-width: ${screenSizes.mediaXL}px) {

    &.claimBtnText{
      font-size: 18px;
      line-height: 22px;
      color: #55FF76;
    }
  }

  @media (min-width: 800px and max-width: 1024px) {
    font-size: 22px;
    line-height: 25px;
  }

  @media (min-width: ${screenSizes.mediaM}px) and (max-width: ${screenSizes.mediaXLL}px) {
    font-size: 22px;
    line-height: 25px;
    &.claimBtnText{
      font-size: 20px;
      line-height: 22px;
      color: #55FF76;
    }
  }

  @media (max-width: 799px) {
    font-size: 20px;
    line-height: 24px;
    &.claimBtnText{
      font-size: 18px;
      line-height: 22px;
      color: #55FF76;
    }
  }
`

export const InfoTitle = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-family: MontserratSemiBold;
  color: #FFFFFF;
  opacity: 0.4;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 14px;
    line-height: 16px;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    font-size: 16px;
    line-height: 19px;
  }
  @media (max-width: 799px) {
    font-size: 12px;
    line-height: 15px;
  }
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (min-width: ${screenSizes.mediaM}px) and (max-width: ${screenSizes.mediaXLL}px) {
    font-size: 13px;
    line-height: 16px;
  }
`

export const InfoText = styled.div`
  font-size: 24px;
  line-height: 29px;
  font-family: MontserratBold;
  color: #FFFFFF;
  margin-top: 10px;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 799px) {
    font-size: 17px;
    line-height: 20px;
  }
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 15px;
    line-height: 19px;
  }
  @media (min-width: ${screenSizes.mediaM}px) and (max-width: ${screenSizes.mediaXLL}px) {
    font-size: 16px;
    line-height: 19px;
  }
`
export const ClaimBtn = styled(Button) <any>`
  margin-top: 30px;
  height: 49px;
  padding: 15px 0;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 19px;
  background: #55FF7631 0% 0% no-repeat padding-box;
  transition: 0.5s;
  text-align: center;
  cursor:pointer;
  &:disabled {
    background: #55FF7631 0% 0% no-repeat padding-box;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 60px;
    padding: 18px 0 17px 0;
    font-size: 20px;
    line-height: 25px;
  }
  &.add-stable-liquidity{
    margin-top: 20px;
  }
  &.confirmStableSwapBtn{
    margin-top: 30px;
    @media (max-width: 799px) {
    margin-top: 20px;
    }
  }
`

export const PriceChartCont = styled.div`
  zoom: 110%;
`

/* ======================================= Metrics History ================================ */

export const HistoryCont = styled.div`
  margin-top: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-top: 50px;
  }
  .hash-web{
    display: none;
    @media (min-width: ${screenSizes.mediaL}px) {
      display: block;
    }
  }
  .hash-mobile{
    display: block;
    @media (min-width: ${screenSizes.mediaL}px) {
      display: none;
    }
  }
  .web-cell{
    display: none;
    @media (min-width: ${screenSizes.mediaM}px) {
      display: block;
    }
  }
  .mobile-cell{
    display: block;
    @media (min-width: ${screenSizes.mediaM}px) {
      display: none;
    }
  }
`
export const HistoryTitle = styled.h1`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 24px;
    line-height: 29px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 30px;
    line-height: 37px;
  }

`
export const HistorySwitchCont = styled(PlanetCard) <any>`
  width: 177px;
  height: 30px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  border: 1px solid ${colors.borderColor};
  padding: 2px 3px;
`
export const SwitchText = styled.div<any>`
  background: ${(props: any) => props.isActive ? 'rgba(85,255,118, 0.2)' : 'transparent'};
  border-radius: 20px;
  border:0;
  cursor: ${(props: any) => props.isActive ? "initial" : "pointer"};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props: any) => props.isActive ? 1 : 0.4};
  p{
    font-size: 14px;
    font-family: MontserratSemiBold;
    color: ${(props: any) => props.isActive ? colors.lightGreen : colors.white};
    margin: 0;
    text-align: center;
    opacity: 1;
  }
`
export const TableWrapper = styled(PlanetCard)`
  overflow-x: auto;
  margin-top: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-top: 30px;
  }
`
export const HistoryTable = styled.div`
  box-sizing: border-box;
  padding: 18px 0 0 0;
  border-radius: 0;
  .table-header{
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 15px;
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-bottom: 0;
      
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 0;
  }
`

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1.2fr repeat(2,1fr);
  grid-gap: 10px;
  padding: 0 20px;
  border-bottom: none;
  text-align: left;
  align-items: center;
  margin-bottom: 21px;
  &:last-child{
    border-bottom: 0;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-template-columns: 1fr 1fr 1fr 50px;
    border-bottom: 1px solid ${colors.borderColor};
    padding: 30px;
    margin-bottom: 0;
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    grid-template-columns: 1fr 3fr 1fr 50px;
  }
`

export const TableText = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.white};
  font-family: MontserratMedium;
  margin: 0;
  opacity: 0.4;
  @media (min-width: ${screenSizes.mediaL}px) {
    font-size: 12px;
    line-height: 15px;
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    font-size: 20px;
    line-height: 25px;
  }

`
export const HeadText = styled(TableText)`
  opacity: 0.4;
`
export const CellText = styled(TableText) <any>`
  font-family: MontserratBold;
  opacity: ${(props: any) => props.light ? 0.4 : 1};
`
export const AmountText = styled.p <any>`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaL}px) {
    font-size: 12px;
    line-height: 15px;
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    font-size: 20px;
    line-height: 25px;
  }
`
export const TransLink = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 14px;
    height: 16px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 16px;
      height: 18px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 18px;
      height: 20px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      width: 21.52px;
      height: 24px;
    }
  }

`

export const EmptyText = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  text-align: center;
  margin: 50px 0;
`

/* ================================== rechart custom tooltip ======================= */

export const ToolTipCont = styled.div`
  padding: 10px;
  background: #1E2442;
  border-radius: 10px;
`

export const ToolTipLabel = styled.h1`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
`
export const ToolTipLabelTime = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.white};
  font-family: MontserratMedium;
  margin: 0;
`
export const ToolTipDataTxt = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 5px 0 0;
`

export const CustomTooltip = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    .tooltip-body {
        width: 100%;
        position: relative;
        background: transparent;
        border: 1px solid ${colors.borderColor};
        text-align: center;
        padding: 1px 3px;
        border-radius: 2px;

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            width: 0;
            height: 0;
            border: 10px solid transparent;
            margin-left: -10px;
            bottom: 0;
            border-top-color: ${colors.borderColor};
            border-bottom: 0;
            margin-bottom: -10px;
        }

        p {
            font-size: 0.75em;
            color: ${colors.white};
            margin: 0;
            align-self: center;
        }
    }
`


export const PriceGraph = styled.div`
  margin: 12px 0 20px 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 27.51px 0 42.16px 0;
  }
`


export const MetricsCont = styled.div`
  margin-top: -5px;
  .on-mobile{
    display: block;
    @media (min-width: ${screenSizes.mediaM}px) {
      display: none;
    }
  }
  .on-web{
    display: none;
    @media (min-width: ${screenSizes.mediaM}px) {
      display: block;
    }
  }
  .loading-space{
    margin-top: 10px;
  }
`
export const MetricsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-bottom: 28px;
  }
`
export const Title = styled.h1`
  font-size: 24px;
  line-height: 29px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 30px;
    line-height: 37px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 40px;
    line-height: 49px;
  }
`
export const SwitchCont = styled(PlanetCard) <any>`
  display: grid;
  grid-template-columns: auto auto;
  width: 170px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 15px;
  .aqua-switch{
    border-right:${(props: any) => props.activeToken === "aqua" ? `1px solid ${colors.borderColor}` : "none"};
  }
  .gamma-switch{
    border-left: ${(props: any) => props.activeToken === "gamma" ? `1px solid ${colors.borderColor}` : "none"};
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    width: 280px;
    height: 50px;
    grid-template-columns: 1fr;
    border-radius: 20px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    width: 320px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    width: 346px;
    height: 64px;
  }
`
export const SwitchToken = styled.div<any>`
  background: ${(props: any) => props.isActive ? colors.themeBackground : 'transparent'};
  border-radius: 15px;
  cursor: ${(props: any) => props.isActive ? "initial" : "pointer"};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props: any) => props.isActive ? 1 : 0.4};
  img{
    width: 24px;
    height: 24px;
    margin-right: ${(props: any) => props.isActive ? '7px' : '0'};
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-right: 10px;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 30px;
      height: 30px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 40px;
      height: 40px;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    border-radius: 20px;
  }
`
export const SwitchTokenTxt = styled.p<any>`
  font-size: 12px;
  color: ${colors.white};
  font-family: MontserratBold;
  opacity: 1;
  margin: 0;
  text-align: center;
  display: ${(props: any) => props.isActive ? "block" : "none"};
  @media (min-width: ${screenSizes.mediaM}px) {
    display: block;
    font-size: 16px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
  }

`

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1,1fr);
  grid-gap: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-gap: 30px;
    grid-template-columns: 1fr;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    grid-template-columns: 1fr ;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    grid-template-columns: 1fr;
  }
`
export const CardText = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  opacity: 0.4;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
`

export const CardNumText = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 6px 0 0 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 11px 0 0 0;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 24px;
    line-height: 29px;
  }
`

export const PriceBox = styled.div`
  padding: 20px 16px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 30px 30px 0 30px;
  }
`
export const PriceTitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratMedium;
   margin: 0 0 11px 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 0 0 20px 0;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
`
export const PriceText = styled(Title) <any>`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  span{
    font-size: 16px;
    line-height: 20px;
    font-family: MontserratSemiBold;
    margin-left: 10px;
    color: ${(props: any) => props.isNegative ? colors.red : props.type === 'gamma' ? colors.lightGreen : colors.lightBlue};
    @media (min-width: ${screenSizes.mediaXLL}px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    color: ${(props: any) => props.type === 'gamma' ? colors.lightGreen : colors.lightBlue};
    font-size: 24px;
    line-height: 29px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 30px;
    line-height: 37px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 40px;
    line-height: 49px;
  }
`
export const TimeBox = styled.div`
  padding: 0 16px 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 0 30px 30px 0;
  }
`
export const TimeText = styled(CardText) <any>`
  font-family: MontserratBold;
  opacity: ${(props: any) => props.activeTime ? 1 : 0.4};
  margin: 0 0 0 20px;
  cursor: pointer;
`
export const InfoCardWrapper = styled.div`
  overflow-x: auto;
  margin-right: -16px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-right: 0;
  }
`

export const InfoCont = styled.div`
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 10px;
  min-width: 700px;
  padding-right: 16px;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-template-columns: repeat(1,1fr);
    grid-gap: 20px;
    min-width: auto;
    padding-right:0;
  }
`
export const InfoCard = styled(PlanetCard)`
  padding: 17px 10px 17px 25px;
  display: flex;
  align-items: center;
  img{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 50px;
      height: 50px;
      margin-right: 15px;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 65px;
      height: 65px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 80px;
      height: 80px;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 20px 10px 20px 30px;
  }
`
