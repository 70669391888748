import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { PlanetCard } from 'shared/styles/styled'

/* =============================== Toke Swap ===================================== */

export const TokenSwapCont = styled.div`
  .clock-setting {
    img {
      width: 23px;
      height: 20px;
      cursor: pointer;
      margin-left: 16px;
      @media (min-width: ${screenSizes.mediaM}px) {
        width: 30px;
        height: 25px;
        margin-left: 20px;
      }
    }
  }
  .swap-input {
    margin-top: 18px;
  }
  .aqua-price {
    margin: 20px 0;
  }
  .swap-info {
    margin: 19px 0 30px 0;
    opacity: 0.4;
  }
  .token-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  .arrow-down {
    width: 14px;
    height: 9px;
    margin-left: 10px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 17px;
      height: 11px;
      margin-left: 15px;
    }
  }
  .swap-const-info {
    margin-top: 20px;
  }
  .swap-const-data {
    margin-bottom: 10px;
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    &.swap{
      width: 100%;
    }
  }
  
  @media (min-width: 1050px) {
    &.swap{
      zoom:0.8 !important;
      max-width: 550px;
    }
  }
  @media (min-width: 1350px) {
    &.swap{
      zoom:0.85 !important;
      max-width: 620px;
    }
  }
  @media (min-width: 1550px) {
    &.swap{
      zoom:0.9 !important;
      max-width: 620px;
    }
  }
  @media (min-width: 1750px) {
    &.swap{
      zoom:0.95 !important;
      max-width: 620px;
    }
  }

  @media (min-width: 2160px) {
    &.swap{
      zoom:1 !important;
      max-width: 620px;
    }
  }
  
`
export const SwapCard = styled.div<any>`
  padding: 20px;
  border: ${(props: any) => (+props.sendAmount > 0 ? '2px solid #55B7FF' : '1px solid `${colors.borderColor}`' )};
  background: ${colors.inputBg};
  border-radius: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 24px 30px;
  }
  &.swapBox{
    border: 1px solid #FFFFFF26;
    border: ${(props: any) => (+props.sendAmount > 0 ? '2px solid #55B7FF' : '1px solid `${colors.borderColor}`' )};
  }
`
export const SwapConstCard = styled(PlanetCard)`
  padding: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 24px 30px;
  }
  &.swapInfo{
    @media (max-width: 799px) {
      overflow-x: visible;
      width: 100vw;
    }
  }
  &.stable-swap-info{
    border: none;
    padding: 0;
    @media (max-width: 799px) {
      padding: 0 0px 20px f0px;
    }
  }
`
export const TokenSelectCont = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-left: 20px;
  }
`
export const SwapDownArrow = styled.div`
  width: 40px;
  height: 40px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(85, 183, 255, 0.2);
  border-radius: 100px;
  cursor: pointer;
  @media (min-width: ${screenSizes.mediaM}px) {
    width: 50px;
    height: 50px;
  }
  &.swapIconBg{
    border-radius: 20px;
    background: #000000;
    border: 1px solid #FFFFFF26;
    margin: -5px auto;
    z-index: 10000000;
    position: relative;
  }
  @media (max-width: 799px) {
    &.swapIconBg{
      border-radius: 15px;
      
    }
  }
`

export const SwapConstTxt = styled.p<any>`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: ${(props: any) => (props.light ? 0.4 : 1)};
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
`
export const PriceImpactTxt = styled.p<any>`
  font-size: 12px;
  line-height: 16px;
  color: ${(props: any) => (props.priceImpactVal > 5 ? colors.red : props.priceImpactVal > 3 ? colors.yellow : colors.lightGreen)};
  font-family: MontserratSemiBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 14px;
    line-height: 18px;
  }
  &.priceImpact{
    font-size: 16px;
    line-height: 18px;
  }
  @media (max-width: 799px) {
    &.priceImpact{
      font-size: 13px;
      line-height: 18px;
    }
  }
  &.swapTokenPrice{
    color: #FFF;
    font-size: 16px;
    line-height: 19px;
    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  &.highSlippageWarning{
    color: ${colors.red};
    font-size: 16px;
    line-height: 18px;
    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
`
export const PriceCont = styled.div`
  margin-top: 20px;
  .price-space{
    margin-bottom: 11px;
  }
`

/* ==================== Confirm Swap Modal ======================== */

export const ConfirmSwapCont = styled.div`
  width: 100%;
  max-width: 415px;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaM}px) {
    max-width: 382px;
  }
  .down-icon {
    width: 20px;
    height: 24px;
    margin: 20px 0 20px 10px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 20px;
      height: 24px;
      margin: 40px 0 40px 15px;
    }
  }
  .token-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
  }
  .receive-aqua {
    margin-bottom: 30px;
  }
  .confirm-info {
    margin-bottom: 10px;
  }
  .price-impact {
    color: #55fe83;
  }
  .close-confirm-swap {
    cursor: pointer;
  }
  button {
    margin-top: 20px;
  }
`

export const SwapTokenCont = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 20px 0 28px 0;
  }
`
export const SwapText = styled.p<any>`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  opacity: ${(props: any) => (props.light ? 0.4 : 1)};
  margin: 0;
  span {
    color: ${colors.lightBlue};
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
`
export const CnfTokenTxt = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 30px;
  }
`
