import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import { updateUserLendingData } from 'logic/action/lending.action'
import { MainContainer, FlexCont, CardButton, CardButton2 } from 'shared/styles/styled'
import { TitleText, TitleInfo, CardText, DataTitle } from 'shared/styles/globalText'
import { DataCont, TableSearchCont, TableProtoCalCont, LoadingText4, TableCont } from 'shared/styles/planetTableStyle'
import { VaultsContainer, VaultsHeader, GammaEarnedBox, EarnValText, VaultsTabCont, TabWrapper, TabContainer, TabBox, TabText, MobileDiv, WebDiv } from './style'
import { commaFy } from 'service/globalFunctions'
import Spinner from 'shared/spinner'
import { transLinkUrl } from 'service/global-constant'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import InfoTooltip from 'shared/info-tool-tip'
import { vaultTabOptions, protocolOptions } from 'service/dataConstant'
import Select from 'shared/select'
import PlanetGToken from './components/PlanetGToken'
import HighestApiTokens from './components/HighestApiTokens'
import VaultEarnings from './components/VaultEarnings'
import VaultsTable from './components/VaultsTable'
import { claimGamma } from 'modules/block-chain/gammatroller'
import CountUp from "react-countup"


const Vaults = () => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [claimLoading, setClaimLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('all')
    const [protocol, setProtocol] = useState('')
    const { poolLoading, poolData, portfolioLoading, userPortfolio } = selector.user

    // console.log('poolData in vaults v1',  poolData);

    const handleProtocolSelect = (protocolOption: string) => {
        setProtocol(protocolOption)
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Claim ${type}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleGammaCollect = async () => {
        if (!!selector.ethData.address && !portfolioLoading) {
            try {
                setClaimLoading(true)
                const res = await claimGamma(selector.ethData.address, userPortfolio.suppliedList)
                if (!!res && typeof res !== 'undefined' && Object.values(res).length > 0) {
                    const type = res.status ? 'success' : 'failed'
                    if (res.status) {
                        handleNotification(type, res.transactionHash)
                        dispatch(updateUserBalance(selector.ethData.address))
                        dispatch(updateUserLendingData(selector.ethData.address))
                    }
                    else {
                        handleNotification('failed')
                    }
                }
                else {
                    handleNotification('failed')
                }
            }
            catch (error) {
                console.log("error=>", error)
                handleNotification('failed')
            }
            finally {
                setClaimLoading(false)
            }
        }
    }

    const getUserVaultData = () => {
        let userVaultArr: any = []
        // console.log("Vaults ==>", poolData.newVaults);
        if (!poolLoading && !portfolioLoading) {
            const dataArr = poolData.vaults;
            if (!!dataArr && Object.values(dataArr).length > 0) {
                userVaultArr = dataArr.active.filter((e: any) => parseFloat(e.vaultBalanceUSD) > 0)
            }
        }
        return userVaultArr
    }

    const getAllVaultData = () => {
        let userVaultArr: any = {}
        // console.log("Vaults ==>", poolData.newVaults);
        if (!poolLoading && !portfolioLoading) {
            const dataArr = poolData.vaults;
            if (!!dataArr && Object.values(dataArr).length > 0) {
                let vaultArr = dataArr.active;
                let data: any = {};
                data.active = vaultArr
                userVaultArr = data
                // console.log(" in if userVaultArr", userVaultArr)
            }
        } else if(!poolLoading){
            userVaultArr = poolData.vaults;
            // console.log("userVaultArr in else", userVaultArr)
        }
        // console.log("getAllVaultData", userVaultArr)
        return userVaultArr
    }

    return (
        <MainContainer>
            <VaultsContainer>
                <MobileDiv>
                    <VaultsHeader>
                        <div>
                            <TitleText>Vaults</TitleText>
                            <TitleInfo className="title-info">Earn Interest On Your Crypto!</TitleInfo>
                        </div>
                        <PlanetGToken /> 
                        <GammaEarnedBox>
                            <FlexCont>
                                <img src={GAMMAIcon} alt="" />
                                <div>
                                    <div className='on-mob'>
                                        <FlexCont >
                                            <CardText >Vault Earnings</CardText>
                                            <InfoTooltip infoId='vaultEarnings' left={5} />
                                        </FlexCont>
                                    </div>
                                    <VaultEarnings portfolioLoading={portfolioLoading} userPortfolio={userPortfolio} />
                                    {/* {portfolioLoading ? <LoadingText4 /> :
                                        <EarnValText>{`${userPortfolio.greenPendingGAMMA ? commaFy(parseFloat(userPortfolio.greenPendingGAMMA).toFixed(4)) : '0.0000'} GAMMA`}</EarnValText>
                                    } */}
                                </div>
                            </FlexCont>
                            <CardButton className="on-web" disabled={claimLoading} onClick={() => handleGammaCollect()}>{claimLoading ? <Spinner /> : 'Claim'}</CardButton>
                            <CardButton2 className="on-mob" disabled={claimLoading} onClick={() => handleGammaCollect()}>{claimLoading ? <Spinner /> : 'Collect'}</CardButton2>
                        </GammaEarnedBox>
                    </VaultsHeader>
                </MobileDiv>
                <WebDiv>
                    <VaultsHeader>
                        <div>
                            <TitleText>Vaults</TitleText>
                            <TitleInfo className="title-info">Earn Interest On Your Crypto!</TitleInfo>
                        </div>
                        <GammaEarnedBox>
                            <FlexCont id='earnings'>
                                <img src={GAMMAIcon} alt="" />
                                <div>
                                    <div className='on-web'>
                                        <FlexCont >
                                            <CardText >Vault Earnings</CardText>
                                            <InfoTooltip infoId='vaultEarnings' left={5} />
                                        </FlexCont>
                                    </div>

                                    <VaultEarnings portfolioLoading={portfolioLoading} userPortfolio={userPortfolio} />
                                    {/* {portfolioLoading ? <LoadingText4 /> :
                                        <EarnValText className='inGamma' id="inGamma">{`${userPortfolio.greenPendingGAMMA ? commaFy(parseFloat(userPortfolio.greenPendingGAMMA).toFixed(4)) : '0.0000'} GAMMA`}</EarnValText>
                                        
                                    } */}
                                    {portfolioLoading ? <LoadingText4 /> :
                                        <EarnValText className="gammaInUSD" id='gammaInUSD'>${`${userPortfolio.greenPendingGAMMA ? commaFy(parseFloat(userPortfolio.greenPendingUsd).toFixed(2)) : '0.00'}`}</EarnValText>
                                        
                                    }
                                </div>
                            </FlexCont>
                            <CardButton2 className="on-web" disabled={claimLoading} onClick={() => handleGammaCollect()}>{claimLoading ? <Spinner /> : 'Claim'}</CardButton2>
                            <CardButton2 className="on-mob" disabled={claimLoading} onClick={() => handleGammaCollect()}>{claimLoading ? <Spinner /> : 'Collect'}</CardButton2>
                        </GammaEarnedBox>
                    </VaultsHeader>
                    <PlanetGToken /> 
                </WebDiv>
                {/* <HighestApiTokens /> */}
                {/* {
                    getUserVaultData().length > 0 &&
                    <DataCont>
                        <DataTitle className='my-asset'>My Vaults</DataTitle>
                        <VaultsTable
                            isBorderDisable={true}
                            isUserVault={true}
                            activeTab={activeTab}
                            protocol={protocol}
                            poolLoading={poolLoading}
                            data={getUserVaultData()}
                            portfolioLoading={portfolioLoading}
                            userPortfolio={userPortfolio}
                            vaults="my_vaults"
                        />
                    </DataCont>
                } */}
                <DataCont>
                    <DataTitle>All Vaults</DataTitle>
                    <VaultsTabCont>
                        {/* <TabWrapper>
                            <TabContainer>
                                {
                                    vaultTabOptions.map((item: any, index: number) => (
                                        <TabBox key={index} onClick={() => activeTab === 'all' || activeTab !== item.value ? setActiveTab(item.value) : setActiveTab('all')} isActive={activeTab === item.value}>
                                            <TabText isActive={activeTab === item.value}>{item.label}</TabText>
                                        </TabBox>
                                    ))
                                }
                            </TabContainer>
                        </TabWrapper>
                        <TableSearchCont>
                            <TableProtoCalCont>
                                <Select options={protocolOptions} handleSelect={handleProtocolSelect} />
                            </TableProtoCalCont>
                        </TableSearchCont> */}
                    </VaultsTabCont>
                    <TableCont>
                    {!poolLoading &&
                        <VaultsTable
                            activeTab={activeTab}
                            protocol={protocol}
                            poolLoading={poolLoading}
                            data={!poolLoading ? getAllVaultData() : poolData.vaults }
                            portfolioLoading={portfolioLoading}
                            userPortfolio={userPortfolio}
                            isUserVault={false}
                            vaults="all"
                        />
                    }
                    </TableCont>
                    
                </DataCont>

            </VaultsContainer>
        </MainContainer>
    );
};

export default Vaults
