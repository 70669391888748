import { POOL_LOADING, UPDATE_POOL_INFO, UPDATE_POOL_DATA, PORTFOLIO_LOADING, UPDATE_USER_PORTFOLIO, PLATFORM_LOADING, UPDATE_PLATFORM_DATA, INFINITY_VAULT_LOADING, UPDATE_GAMMA_INF_VAULT_DATA, UPDATE_AQUA_INF_VAULT_DATA, UPDATE_GAMMA_INF_VAULT_DATA_NEW, UPDATE_AQUA_INF_VAULT_DATA_NEW, POOL_LOADING_NEW, UPDATE_POOL_INFO_NEW, UPDATE_POOL_DATA_NEW, GAMMA_INFINITY_VAULT_LOADING, AQUA_INFINITY_VAULT_LOADING, INFINITY_VAULT_LOADING_NEW, UPDATE_ONLY_POOL_DATA, UPDATE_ONLY_VAULTS_DATA, UPDATE_POOL_INFO_V3, UPDATE_POOL_DATA_V3 } from './action.config'
import { getPortfolioData, getInfinityVaultData, getOldInfinityVaultData, newLPDataMulticall, fetchNewVaultDetails, v3_LPDataMulticall, getVestingData } from 'modules/block-chain/BlockChainMethods'
import { getGammaAndAquaPrice } from 'modules/block-chain/LendingBase'
import { updateUserAsset, updateUserAssetNew } from './lending.action'
import axios from 'axios'
import { aqua_infinity_vault_address, gamma_infinity_vault_address, gamma_infinity_vault_address_old, aqua_infinity_vault_address_old } from 'modules/block-chain/abi'
import { planetFinanceApiBaseUrl, newPFApiBaseUrl } from "service/global-constant";
import planetGlobalObject  from 'global/GlobalVar'

const poolInfoUrl = newPFApiBaseUrl + 'v1/getallpoolinfo';
const platformInfoUrl = newPFApiBaseUrl+'v1/getallplatformdata'


/*  ============================= POOL INFO ============================= */

export const setPoolInfoLoading = (status: boolean) => {
  return {
    type: POOL_LOADING,
    payload: status,
  }
}

export const updatePoolInfo = (data: any) => {
  return {
    type: UPDATE_POOL_INFO,
    payload: data,
  }
}

export const updatePoolData = (data: any) => {
  return {
    type: UPDATE_POOL_DATA,
    payload: data,
  }
}

export const setPoolInfoLoadingNew = (status: boolean) => {
  return {
    type: POOL_LOADING_NEW,
    payload: status,
  }
}

export const updatePoolInfoNew = (data: any) => {
  return {
    type: UPDATE_POOL_INFO_NEW,
    payload: data,
  }
}

export const updatePoolInfoV3 = (data: any) => {
  return {
    type: UPDATE_POOL_INFO_V3,
    payload: data,
  }
}

export const updateOnlyPoolInfo = (data: any) => {
  return {
    type: UPDATE_ONLY_POOL_DATA,
    payload: data,
  }
}

export const updateOnlyVaultsInfo = (data: any) => {
  return {
    type: UPDATE_ONLY_VAULTS_DATA,
    payload: data,
  }
}

export const updatePoolDataNew = (data: any) => {
  return {
    type: UPDATE_POOL_DATA_NEW,
    payload: data,
  }
}

export const updatePoolDataV3 = (data: any) => {
  return {
    type: UPDATE_POOL_DATA_V3,
    payload: data,
  }
}

export const fetchPoolData = () => async (dispatch: any) => {
  try {
    dispatch(setPoolInfoLoading(true))
    if(planetGlobalObject.getallpoolinfo.newPools) {
      let getallpoolinfo = planetGlobalObject.getallpoolinfo;
      dispatch(updatePoolInfo(getallpoolinfo))
    }
    else {
      const res: any = await axios.get(poolInfoUrl)
      if (!!res && typeof res !== 'undefined' && res.data) {
        dispatch(updatePoolInfo(res.data))
      }
    }

    const defaultData = {
      userLPArray: [],
      userMarketsArray: [],
      newLpArrayData: [],
      V3_LPArray: []
    }
    dispatch(updatePoolData(defaultData))
  }
  catch (error) {
    console.log(error)
  }
  finally {
    dispatch(setPoolInfoLoading(false))
  }
};

export const fetchPoolDataNew = () => async (dispatch: any) => {
  try {
    dispatch(setPoolInfoLoadingNew(true))
    const res: any = await axios.get(poolInfoUrl)
    if (!!res && typeof res !== 'undefined' && res.data) {
      dispatch(updatePoolInfoNew(res.data))
      const defaultData = {
        userLPArray: [],
        userMarketsArray: [],
        newLpArrayData: [],
        V3_LPArray: []
      }
      dispatch(updatePoolDataNew(defaultData))
    }
  }
  catch (error) {
    console.log(error)
  }
  finally {
    dispatch(setPoolInfoLoadingNew(false))
  }
};

export const fetchPoolV3Data = () => async (dispatch: any) => {
  try {
    dispatch(setPoolInfoLoadingNew(true))
    const res: any = await axios.get(poolInfoUrl)
    if (!!res && typeof res !== 'undefined' && res.data) {
      dispatch(updatePoolInfoV3(res.data))
      const defaultData = {
        userLPArray: [],
        userMarketsArray: [],
        newLpArrayData: [],
        V3_LPArray: []
      }
      dispatch(updatePoolDataNew(defaultData))
    }
  }
  catch (error) {
    console.log(error)
  }
  finally {
    dispatch(setPoolInfoLoadingNew(false))
  }
};

export const updateLPData = (address: any) => async (dispatch: any) => {
  try {
    dispatch(setPoolInfoLoadingNew(true))
    const res: any = await axios.get(poolInfoUrl)
    const lpData: any = await newLPDataMulticall(address);
    res.newPoolData = lpData.newPoolData;
    res.oldPoolData = lpData.oldPoolData;
    if (!!res && typeof res !== 'undefined' && res) {
      dispatch(updateOnlyPoolInfo(res))
    }
  }
  catch (error) {
    console.log(error)
  }
  finally {
    dispatch(setPoolInfoLoadingNew(false))
  }
};

export const updatePoolV3Data = (address: any) => async (dispatch: any) => {
  try {
    dispatch(setPoolInfoLoadingNew(true))
    const res: any = await axios.get(poolInfoUrl)
    const lpData: any = await v3_LPDataMulticall(address);
    res.poolV3 = lpData.userLPArray;
    if (!!res && typeof res !== 'undefined' && res) {
      dispatch(updateOnlyPoolInfo(res))
    }
  }
  catch (error) {
    console.log(error)
  }
  finally {
    dispatch(setPoolInfoLoadingNew(false))
  }
};

export const updateVaultsData = (address: any) => async(dispatch: any) => {
  try{
    dispatch(setPoolInfoLoadingNew(true))
    const res: any = await axios.get(poolInfoUrl)
    const vaults_data: any = await fetchNewVaultDetails(address);
    res.vaults_data = vaults_data.userMarketsArray;
    if (!!res && typeof res !== 'undefined' && res) {
      dispatch(updateOnlyVaultsInfo(res))
    }
  } catch(error) {
    console.log(error)
  }
  finally {
    dispatch(setPoolInfoLoadingNew(false))
  }
};

/* ================================== PORTFOLIO ACTIONS ==================================*/

export const setPortfolioLoading = (loadingStatus: boolean) => {
  return {
    type: PORTFOLIO_LOADING,
    payload: loadingStatus,
  }
}

export const updateUserPortfolio = (data: any) => {
  return {
    type: UPDATE_USER_PORTFOLIO,
    payload: data,
  }
}

export const fetchUserPortfolio = (address: any, isUpdate?: any) => async (dispatch: any) => {
  //address = "0xB19aaDcB545e54B4D40f1D09fEe981D456CFD969";
  try {
    if (!!address) {
      if (!isUpdate) {
        dispatch(setPortfolioLoading(true))
      }
      const vestingPositions: any = await getVestingData(address);
      const res: any = await getPortfolioData(address)

      if (!!res && typeof res != 'undefined') {
        const data = {
          balanceInVaults: !isNaN(parseFloat(res.balance_in_vaults)) ? res.balance_in_vaults : 0,
          currentApy: !isNaN(parseFloat(res.current_apy)) ? res.current_apy : 0,
          currentApr: !isNaN(parseFloat(res.current_apr)) ? res.current_apr : 0,
          dailyEarning: !isNaN(parseFloat(res.daily_earning)) ? res.daily_earning : 0,
          pendingEarnings: !isNaN(parseFloat(res.pending_earnings)) ? res.pending_earnings : 0,
          userAquaBalance: !isNaN(parseFloat(res.userAquaBalance)) ? res.userAquaBalance : 0,
          userAquaBalanceUSD: !isNaN(parseFloat(res.userAquaBalanceUSD)) ? res.userAquaBalanceUSD : 0,
          userGammaBalance: !isNaN(parseFloat(res.userGammaBalance)) ? res.userGammaBalance : 0,
          userGammaBalanceUSD: !isNaN(parseFloat(res.userGammaBalanceUSD)) ? res.userGammaBalanceUSD : 0,
          greenPendingGAMMA: !isNaN(parseFloat(res.greenPendingGAMMA)) ? res.greenPendingGAMMA : 0,
          greenPendingUsd: !isNaN(parseFloat(res.greenPendingUsd)) ? res.greenPendingUsd : 0,
          gammaPrice: !isNaN(parseFloat(res.gammaPrice)) ? res.gammaPrice : 0,
          aquaPrice: !isNaN(parseFloat(res.aquaPrice)) ? res.aquaPrice : 0,
          userLevel: {
            level: !isNaN(res.userLevel.level) ? res.userLevel.level : 0,
            discount: !isNaN(res.userLevel.discount) ? res.userLevel.discount : 0
          },
          suppliedList: res.supplied_list? res.supplied_list: [],
          userLPArray: Object.values(res.userLPArray),
          newLpArrayData: Object.values(res.newLpArrayData),
          userMarketsArray: res.userMarketsArray? Object.values(res.userMarketsArray): [],
          pendingGammaAllPools: !isNaN(parseFloat(res.pendingGammaAllPools)) ? res.pendingGammaAllPools : 0,
          userAddress: !isNaN(parseFloat(res.userAddress)) ? res.userAddress : "",
          V3_LPArray: res.V3_LPArray ? Object.values(res.V3_LPArray): [],
          user_gamma_btcb_balance_in_v3 : !isNaN(parseFloat(res.user_gamma_btcb_balance_in_v3)) ? res.user_gamma_btcb_balance_in_v3 : 0,
          other_pool_balance_in_v3: !isNaN(parseFloat(res.other_pool_balance_in_v3)) ? res.other_pool_balance_in_v3 : 0,
          vestingPositions: vestingPositions,
          v3_gammaRewards: res.v3_gammaRewards ? res.v3_gammaRewards : 0,
        }

        dispatch(updateUserPortfolio(data))
        dispatch(updatePoolData(data))
      }
      
    }
  }
  catch (error) {
    console.log(error)
  }
  finally {
    dispatch(setPortfolioLoading(false))
    dispatch(fetchInfVaultDataNew(address))
  }
}

/* =============================== PLATFORM ACTIONS =========================== */

export const setPlatformLoading = (loadingStatus: boolean) => {
  return {
    type: PLATFORM_LOADING,
    payload: loadingStatus,
  }
}

export const updatePlatformData = (data: any) => {
  return {
    type: UPDATE_PLATFORM_DATA,
    payload: data,
  }
}

export const fetchPlatformData = () => async (dispatch: any) => {
  try {
    dispatch(setPlatformLoading(true))
    let res: any = await axios.get(platformInfoUrl)
    
    if (!!res && typeof res !== 'undefined' && res.data) {
      if(parseFloat(res.data.gamma_price) == 0 )
      {
        let r = await getGammaAndAquaPrice();
        res.data.gamma_price = r.gammaPrice;
        res.data.aqua_price = r.aquaPrice;
        if(res.data.tvl == undefined || parseFloat(res.data.tvl) == 0 ) {
          const tvl: any = await axios.get('https://pfapi.planet.finance/v1/tvl')
          res.data.tvl = tvl.data.tvl;
        }
      }
      dispatch(updatePlatformData(res.data))
    }
  }
  catch (error) {
    console.log(error)
  }
  finally {
    dispatch(setPlatformLoading(false))
  }
}

/* ================================= INFINITY VAULT ACTION ============================= */

export const setInfVaultLoading = (loadingStatus: boolean) => {
  return {
    type: INFINITY_VAULT_LOADING,
    payload: loadingStatus,
  }
}

export const setInfVaultLoadingNew = (loadingStatus: boolean) => {
  return {
    type: INFINITY_VAULT_LOADING_NEW,
    payload: loadingStatus,
  }
}

export const setGammaVaultLoading = (loadingStatus: boolean) => {
  return {
    type: GAMMA_INFINITY_VAULT_LOADING,
    payload: loadingStatus,
  }
}

export const setAquaVaultLoading = (loadingStatus: boolean) => {
  return {
    type: AQUA_INFINITY_VAULT_LOADING,
    payload: loadingStatus,
  }
}

export const updateGammaInfVault = (data: any) => {
  return {
    type: UPDATE_GAMMA_INF_VAULT_DATA,
    payload: data,
  }
}
export const updateAquaInfVault = (data: any) => {
  return {
    type: UPDATE_AQUA_INF_VAULT_DATA,
    payload: data,
  }
}

export const updateGammaInfVaultNew = (data: any) => {
  return {
    type: UPDATE_GAMMA_INF_VAULT_DATA_NEW,
    payload: data,
  }
}
export const updateAquaInfVaultNew = (data: any) => {
  return {
    type: UPDATE_AQUA_INF_VAULT_DATA_NEW,
    payload: data,
  }
}

export const fetchInfVaultData = (address?: any, isUpdate?: any) => async (dispatch: any) => {
  try {
    if (!!address) {
      if (!isUpdate) {
        dispatch(setInfVaultLoading(true))
      }
      const res: any = await getOldInfinityVaultData(address)
      if (!!res && typeof res !== 'undefined') {
        if (res[gamma_infinity_vault_address_old] !== 'undefined') {
          const gGammaVaultData = res[gamma_infinity_vault_address_old];
          dispatch(updateGammaInfVault(gGammaVaultData))
        }
        if (res[aqua_infinity_vault_address_old] !== 'undefined') {
          const gAquaVaultData = res[aqua_infinity_vault_address_old];
          dispatch(updateAquaInfVault(gAquaVaultData))
        }

      }
    } else {
      const res: any = await getOldInfinityVaultData()
      if (res[gamma_infinity_vault_address_old] !== 'undefined') {
        const gGammaVaultData = res[gamma_infinity_vault_address_old];
        dispatch(updateGammaInfVault(gGammaVaultData))
      }
      if (res[aqua_infinity_vault_address_old] !== 'undefined') {
        const gAquaVaultData = res[aqua_infinity_vault_address_old];
        dispatch(updateAquaInfVault(gAquaVaultData))
      }
    }

  }
  catch (error) {
    console.log(error)
  }
  finally {
    dispatch(setInfVaultLoading(false))
  }
}

export const fetchInfVaultDataNew = (address?: any, isUpdate?: any, pid?: any, action?: any) => async (dispatch: any) => {
  try {
    if (!!address) {
      if (!isUpdate) {
        dispatch(setInfVaultLoadingNew(true))
      }
      if(pid == 0 && action !== undefined){
        dispatch(setGammaVaultLoading(true))
      }
      if(pid == 1 && action !== undefined){
        dispatch(setAquaVaultLoading(true))
      }
      const res: any = await getInfinityVaultData(address)
      if (!!res && typeof res !== 'undefined') {
        if (res[0] !== 'undefined') {
          const gGammaVaultData = res[0];
          dispatch(updateGammaInfVaultNew(gGammaVaultData))
        }
        if (res[1] !== 'undefined') {
          const gAquaVaultData = res[1];
          dispatch(updateAquaInfVaultNew(gAquaVaultData))
        }

      }
    } else {
      const res: any = await getInfinityVaultData()
      if (res[0] !== 'undefined') {
        const gGammaVaultData = res[0];
        dispatch(updateGammaInfVaultNew(gGammaVaultData))
      }
      if (res[1] !== 'undefined') {
        const gAquaVaultData = res[1];
        dispatch(updateAquaInfVaultNew(gAquaVaultData))
      }
    }

  }
  catch (error) {
    console.log(error)
  }
  finally {
    dispatch(setInfVaultLoadingNew(false))
    dispatch(setGammaVaultLoading(false))
    dispatch(setAquaVaultLoading(false))
  }
}

export const fetchInfVaultDataOnApprove = (address?: any, isUpdate?: any, pid?: any, action?: any) => async (dispatch: any) => {
  try {
    if (!!address) {
      if(pid == 0 && action !== undefined){
        dispatch(setGammaVaultLoading(true))
      }
      if(pid == 1 && action !== undefined){
        dispatch(setAquaVaultLoading(true))
      }
      const res: any = await getInfinityVaultData(address)
      if (!!res && typeof res !== 'undefined') {
        if (res[0] !== 'undefined' && pid == 0) {
          res[0].action = action;
          const gGammaVaultData = res[0];
          dispatch(updateGammaInfVaultNew(gGammaVaultData))
        }
        if (res[1] !== 'undefined' && pid == 1) {
          res[1].action = action;
          const gAquaVaultData = res[1];
          dispatch(updateAquaInfVaultNew(gAquaVaultData))
        }

      }
    } else {
      const res: any = await getInfinityVaultData()
      if (res[0] !== 'undefined') {
        const gGammaVaultData = res[0];
        dispatch(updateGammaInfVaultNew(gGammaVaultData))
      }
      if (res[1] !== 'undefined') {
        const gAquaVaultData = res[1];
        dispatch(updateAquaInfVaultNew(gAquaVaultData))
      }
    }

  }
  catch (error) {
    console.log(error)
  }
  finally {
    dispatch(setInfVaultLoadingNew(false))
    dispatch(setGammaVaultLoading(false))
    dispatch(setAquaVaultLoading(false))
  }
}


/* ===================================== Update User Balance  ==================================*/

export const updateUserBalance = (address: any) => async (dispatch: any) => {
  dispatch(fetchUserPortfolio(address, true))
  dispatch(fetchInfVaultDataNew(address, true))
  dispatch(updateUserAssetNew(address))
  dispatch(fetchInfVaultData(address))
}

export const updateInfDetailsOnApprove = (address: any) => async (dispatch: any) => {
  dispatch(fetchInfVaultDataNew(address, true))
}

export const silentUpdateOnInfStake = (address: any, pid?: any, action?: any) => async (dispatch: any) => {
  dispatch(fetchInfVaultDataNew(address, true, pid, action))
  dispatch(fetchUserPortfolio(address, true))
  dispatch(fetchInfVaultData(address))
}