import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { updateUserBalance, updateLPData, setPortfolioLoading, setPoolInfoLoading } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { FlexSBCont, FlexCont } from 'shared/styles/styled'
import { TransActionCont, TransInputCont, TransBalTxt, InputTitle, TransInputBox, TransInput, TransMaxBox, TokenSelectCont, TokenName, LpImgGroup, TransInoTxt, TransButton } from 'shared/styles/highestApyStyle'
import DownIcon from 'assets/icons/down.svg'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import { getTokenIcon } from 'shared/tokenIconList'
import TokenSelect from 'shared/token-select'
import TransactionInfo from 'shared/transaction-info'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions'
import { setTransHistory, setConfirmTransHistory } from 'shared/transaction-history/historyMethods'
import { getConvertList, getConvertLpData, getConvertLpDataV3 ,selectInstance, instanceType, convertLpNew, lpTokenApprove, convertLpV3, fetchConvertLpForAqua} from 'modules/block-chain/Swap'
import { getTokenPrice, handleWithdrawLp, handleWithdrawLpV3, balanceOf,allowance, getWithdrawFeeFactor  } from 'modules/block-chain/BlockChainMethods'
import { aquaConvertLpAddress } from 'modules/block-chain/SwapDexAbi'
import { convertToWei, maxAmount } from 'modules/block-chain/lendingAbi'
import { convertLPTokenList as tokenList, returnTransformTokenList, returnConvertLPTokenList, returnTokenList } from 'modules/block-chain/tokenList'
import { busdAddress, usdtAddress, usdcAddress, gammaFarmAdddress, aquaAddress } from 'modules/block-chain/abi';
import { convertToEther } from 'modules/block-chain-green/BlockChainMethods';
import { commaFy } from 'service/globalFunctions'
import wallet from 'modules/wallet/wallet'
import { planetZapOneInchAbi, planetZapOneInchAddress, protocols, referralAddress, referralFees } from 'modules/block-chain/PlanetZapOneInchAbi';
import thenaIcon from 'assets/icons/ThenaLogo.svg'
import bswIcon from 'assets/icons/bsw.svg'
import TransactionConfirmation from './TransactionConfirmation';
import { BigNumber } from 'bignumber.js'
import { updateUserLendingData } from 'logic/action/lending.action';

const WithdrawAndZapOut = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const { data, conLpInfo, setConLpInfo, slippageVal, zapTokenAmount, zapTokenObject, setShowPoolDetails, setShowTCAModal  } = props
    let _zapTokenAmount = zapTokenAmount;
    const [wantAddress, setWantAddress] = useState<any>("")
    const [sendAmount, setSendAmount] = useState<any>(parseFloat(_zapTokenAmount) > 0 ? parseFloat(_zapTokenAmount).toString() : '0')
    const [sendAmountWithComma, setSendAmountWithComma] = useState<any>(parseFloat(zapTokenAmount) > 0 ? commaFy(parseFloat(zapTokenAmount).toFixed(6)) : '0')
    const [receiveTokenList, setReceiveTokenList] = useState<any>(tokenList)
    const [receiveToken, setReceiveToken] = useState<any>({})
    const [receiveAmount, setReceiveAmount] = useState('')
    const [receiveAmountWithComma, setReceiveAmountWithComma] = useState('')
    const [isAmtLoading, setIsAmtLoading] = useState(false)
    const [maxAmount, setMaxAmount] = useState(false)
    const [showTokenModal, setShowTokenModal] = useState(false)
    const [showTransModal, setShowTransModal] = useState(false)
    const [transactionLink, setTransactionLink] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [allowanceAmt, setAllowanceAmt] = useState<any>('0.00')
    const [minReceiveAmount, setMinReceiveAmount] = useState<any>('0.00')
    const [convertLoading, setConvertLoading] = useState(false)
    const [tokenLoading, setTokenLoading] = useState(false)
    const [zapOutFlag, setZapOutFlag] = useState(false)
    const [convertLPFlag, setConvertLPFlag] = useState(false)
    const [lpAllowanceFlag, setLPAllowanceFlag] = useState(false)
    const [showConfModal, setShowConfModal] = useState(false)
    const [transactionLoader, setTransactionLoader] = useState(false)
    
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            
            inputRef.current.focus();
            inputRef.current.autofocus = true;
        }
    })
    
    useEffect(() => {
        // console.log("data in convert lp", data, receiveToken, zapTokenAmount)
        const userAddress = selector.ethData.address
        setAllowanceAmt(data.lpAllowance)
        const getTokenList = async () => {
            try {
                setTokenLoading(true)
                const token_list = await returnConvertLPTokenList(userAddress)
                const tList = token_list
                setReceiveTokenList(tList)
                let firstPoolToken = data.name.split("-");
                let firstLPToken = tList.filter((e: any) => e.name.toLowerCase() == firstPoolToken[0].toLowerCase())
                // console.log(firstPoolToken, firstLPToken, tList)
                if(firstLPToken.length == 1) {
                    setReceiveToken(firstLPToken[0])
                } else {
                    setReceiveToken(tList[0])
                }
                
            } catch (error) { }
            finally {
                setTokenLoading(false)
            }
        }
        getTokenList()
    }, [selector.ethData.address, data.withdrawalBalanceUSD, data.walletBalanceUSD])

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (floatNumRegex.test(sendAmount.toString()) && receiveToken.address !== undefined) {
                // console.log("convert lp params details", selector.ethData.address, sendAmount, data.poolId, receiveToken.address, data)
                //let receiveTokenAddress = receiveToken.address;
                let receiveTokenAddress = undefined;
                // if(receiveToken !== undefined && receiveToken.address !== undefined && (receiveToken.address.toLowerCase() == usdcAddress.toLowerCase() || receiveToken.address.toLowerCase() == usdtAddress.toLowerCase())){
                //     receiveTokenAddress = busdAddress
                // }
                if(sendAmount > parseFloat(allowanceAmt)) {

                }
                if(sendAmount > 0){
                    setIsAmtLoading(true)
                }
                let withdrawFee = await getWithdrawFeeFactor(data.strategyAddress);
                // console.log('withdrawFee', withdrawFee);
                let sendAmountInEth =  sendAmount * (1 - withdrawFee/ 10000); 
                const tList = tokenList
                // if(receiveToken == undefined || receiveToken.address == undefined) {
                //     receiveTokenAddress = tList[0].address;
                // }
                // receiveTokenAddress = tList[0].address;
                // console.log(receiveToken)
                const res: any = data.wantAddress.toLowerCase() == aquaAddress.toLowerCase() ? await fetchConvertLpForAqua(selector.ethData.address, sendAmountInEth+"", data.poolId, receiveToken.address, data, slippageVal) : await getConvertLpDataV3(selector.ethData.address, sendAmountInEth+"", data.poolId, receiveToken.address, data, slippageVal)
                
                // console.log("convert lp response", sendAmount, data, res, receiveToken)
                if (!!res && typeof res !== 'undefined') {
                    const finalReceiveAmt: any = getNumFormat(res.receivedAmount)
                    let sendAmountUsd = res.amount_before_quote;
                    const receiveTokenPrice = receiveToken.price;
                    let finalReceiveAmtUsd = receiveTokenPrice * finalReceiveAmt;
                    //console.log('sendAmountUsd', sendAmountUsd);
                    
                    let diff = sendAmountUsd - finalReceiveAmtUsd;
                    //console.log('diff', diff);
                    if(diff < 0) {
                        diff = 0;
                    }
                    let priceImpact = diff/ sendAmountUsd * 100;
                    res.priceImpact =  priceImpact;

                    if(parseFloat(sendAmount) == parseFloat(data.withdrawalBalance) || parseFloat(zapTokenAmount) == parseFloat(data.withdrawalBalance)) {
                        setMaxAmount(true)
                    }
                    const finalSendAmt: any = getNumFormat(sendAmount)
                    // setSendAmount(finalSendAmt)
                    setSendAmountWithComma(commaFy(+finalSendAmt))

                    setWantAddress(res.wantAddress)
                    setReceiveAmount(finalReceiveAmt)
                    setMinReceiveAmount(res.minReceived)
                    // console.log("res obj", res)
                    setConLpInfo(res);
                    setReceiveAmountWithComma(commaFy(+finalReceiveAmt))
                    // setSendAmount(+sendAmount)
                    // setSendAmountWithComma(commaFy(+sendAmount))
                }
                else {
                    setConLpInfo({})
                    setAllowanceAmt('0.00')
                    setWantAddress('')
                    setReceiveAmount('')
                    setReceiveAmountWithComma('')
                    setMinReceiveAmount('0.00')
                }
                setIsAmtLoading(false)
            }
            setIsAmtLoading(false)
        }, 2000)
        return () => clearTimeout(delayDebounceFn)
    },[selector.ethData.address, sendAmount, slippageVal, receiveToken])



    const handleSendAmount = async (e: any) => {
        try {
            setIsAmtLoading(true)
            let { value } = e.target
            if(+value === 0 ){
                value = value;
            } else {
                value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.replace(",", '') : "";
            }
            if(value == 10001000 || value == 10011001 ||value == 10021002 || value == 10031003 || value == 10041004 || value == 10051005 || value == 10061006 || value == 10071007 || value == 10081008 || value == 10091009){
                // console.log(JSON.stringify(e.target))
            }
            if (floatNumRegex.test(value.toString())) {
                setSendAmount(value)
                setSendAmountWithComma(commaFy(value))
                setMaxAmount(false)
                // setConvertLoading(true)
            }
            if (!value) {
                setSendAmount('')
                setSendAmountWithComma('')
                setConLpInfo({})
                setWantAddress('')
                setTimeout(() => {
                    setReceiveAmount('')
                    setReceiveAmountWithComma('')
                }, 1500)
                setIsAmtLoading(false)
            }
        }
        catch (error) {
            console.log('error==>', error)
            setIsAmtLoading(false)
        }
        finally {
            // setIsAmtLoading(false)
        }
    }
    const handleMax = async () => {
        try {
            setIsAmtLoading(true)
            // setConvertLoading(true)
            if (data && data.withdrawalBalance) {
                const finalSendAmt: any = getNumFormat(data.withdrawalBalance)
                setSendAmount(data.withdrawalBalance)
                setSendAmountWithComma(commaFy(+finalSendAmt))
                setMaxAmount(true);
            }
        }
        catch (error) {
            console.log('error==>', error)
            setIsAmtLoading(true)
        }
        finally {
            // setIsAmtLoading(false)
        }
    }

    const handleApproveLp = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                setApproveLoading(true)
                // console.log("approve details", wantAddress, aquaConvertLpAddress, maxAmount)
                const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, wantAddress)
                const approvalAmount = maxAmount
                await TOKEN_INSTANCE.methods
                    .approve(aquaConvertLpAddress, approvalAmount)
                    .send({ from: selector.ethData.address })
                    .once('transactionHash', function (res: any) { })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        // setAllowanceAmt(approvalAmount)
                    })
                    .on('error', function (error: any) {
                        // setApproveLoading(false)
                    })
            } catch (error) {
                console.log('error==>', error)
            } finally {
                // setApproveLoading(false)
                checkLPApproval();
            }
        }

    }

    const checkLPApproval = async() => {
        setApproveLoading(true)
        try{
            const LP_INSTANCE = await selectInstance(instanceType.PLANETLP, wantAddress)
            let allowanceAmount: any = await LP_INSTANCE.methods.allowance(selector.ethData.address, aquaConvertLpAddress).call();
            // console.log("allowance amount for lp", allowanceAmount);
            let allowance: any = convertToEther(allowanceAmount);
            setAllowanceAmt(allowance)
            setApproveLoading(false)
            dispatch(updateUserBalance(selector.ethData.address))
        } catch(error){
            console.log("err", error)
            setApproveLoading(false)
        }
    }

    const handleTokenSelect = (data: any) => {
        setReceiveToken(data)
        setSendAmount('')
        setSendAmountWithComma('')
        setReceiveAmount('')
        setReceiveAmountWithComma('')
        setShowTokenModal(false)
        setConLpInfo({})
    }

    const handleTransactionHistory = (newLink: any, hashVal: any) => {
        const historyData: any = {
            firstToken: data.name,
            secondToken: receiveToken.name,
            amount1: sendAmount,
            amount2: receiveAmount,
            link: newLink,
            type: 'convertLp',
            status: false,
            hashVal: hashVal,
            transStatus: 'pending',
        }
        setTransHistory(historyData)
    }

    const showTransLink = (transHash: any) => {
        const transLink = `${transLinkUrl}/${transHash}`
        setTransactionLink(transLink)
        handleTransactionHistory(transLink, transHash)
        setShowTransModal(true)
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Convert LP for ${parseFloat(sendAmount).toFixed(4)} ${data.name} to ${parseFloat(receiveAmount).toFixed(4)} ${receiveToken.name}`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const getProtocols = async() => {
        function nonPmm(protocol:any) {
          //console.log('protocol', protocol);
          return !protocol.includes('PMM');
        }
        let protocolsAr:any = protocols;
        // let protocols:any = await fetch(apiRequestUrl('/liquidity-sources'))
        //       .then(res => res.json())
        //       .then(res => res);
        //console.log('protocols', protocols);
        protocolsAr = protocolsAr.protocols.map(function(a:any) {return a.id;});
        return protocolsAr.filter(nonPmm) ;
    }

    const chainId = 56;
    const apiBaseUrl = 'https://pfapi.planet.finance/v1';
    const apiRequestUrl:any = (methodName:any,  dst: any, src: any, amount: any, fee?:any, fromAddress?: any) => {
        return fee ? apiBaseUrl + methodName + '/' + src + '/' + dst + '/' + amount + '/' + fee + '/' + fromAddress : apiBaseUrl + methodName + '/' + src + '/' + dst + '/' + amount;
    }

    const buildTxForSwap = async (swapAmount: any) => {
        try {
            let inputToken = aquaAddress;
            let protocols = await getProtocols();
            //console.log('protocols', protocols);
            protocols = protocols.toString();
            let sendAmount: any = swapAmount;
            // console.log(" send amount in swap tx", sendAmount)
        
            const swapParams = {
                fromTokenAddress: inputToken, // 1INCH
                toTokenAddress: receiveToken.address, // DAI
                amount: sendAmount,
                fromAddress: planetZapOneInchAddress,
                slippage: slippageVal,
                disableEstimate: true,
                allowPartialFill: false,
                referrerAddress: referralAddress,
                fee: referralFees,
                protocols: protocols
            };
        
            //console.log('swapParams', swapParams);
            const url = apiRequestUrl('/swapDataNew', swapParams.toTokenAddress, swapParams.fromTokenAddress, swapParams.amount, swapParams.slippage, swapParams.fromAddress);
            // console.log(url)
            return fetch(url).then(res => res.json()).then(res => res.tx);
        } catch (error) {
            //console.log(error);
            let inputToken = aquaAddress;
            let protocols = await getProtocols();
            //console.log('protocols', protocols);
            protocols = protocols.toString();
            let sendAmount: any = swapAmount;
            //console.log(" send amount in swap tx", sendAmount)
        
            const swapParams = {
            fromTokenAddress: inputToken, // 1INCH
            toTokenAddress: data.wantAddress, // DAI
            amount: sendAmount[0],
            fromAddress: planetZapOneInchAddress,
            slippage: slippageVal,
            disableEstimate: true,
            allowPartialFill: false,
            referrerAddress: referralAddress,
            fee: referralFees,
            protocols: protocols
            };
        
            //console.log('swapParams', swapParams);
            const url = apiRequestUrl('/swapDataNew', swapParams.toTokenAddress, swapParams.fromTokenAddress, swapParams.amount, swapParams.slippage, swapParams.fromAddress);
            //console.log(url)
            return fetch(url).then(res => res.json()).then(res => res.tx);
        }
        
    }

    const handleTransactionLink = (newLink: any, hashVal: any, amount: any) => {
        const tx_data: any = {
          firstToken: aquaAddress,
          secondToken: receiveToken.name,
          amount1: amount,
          link: newLink,
          type: 'swap',
          status: false,
          hashVal: hashVal,
          transStatus: 'pending',
        }
        setTransLink(tx_data)
    }

    const setTransLink = (data: any) => {
        const links: any = localStorage.getItem('transLinks')
        if (!!links) {
          const linksData = JSON.parse(links)
          const finalData = {
            ...data, time: new Date()
          }
          linksData.push(finalData)
          localStorage.setItem('transLinks', JSON.stringify(linksData))
        } else {
          const newLinkData = [{ ...data, time: new Date() }]
          localStorage.setItem('transLinks', JSON.stringify(newLinkData))
        }
    }

    const setConfirmTransLink = (transHash: any, status: any) => {
        const links: any = localStorage.getItem('transLinks')
        const linksData = JSON.parse(links)
        const indexNum = linksData.findIndex((e: any) => e.hashVal === transHash)
        if (indexNum >= 0) {
          const newLinksArr = [...linksData]
          newLinksArr[indexNum].transStatus = 'confirm'
          newLinksArr[indexNum].status = status
          localStorage.setItem('transLinks', JSON.stringify([...newLinksArr]))
        }
    }

    const handleConvertLp = async () => {
        console.log(receiveToken, conLpInfo.convertedToken)
        if (selector.ethData.ethWalletConnected) {
            const userAddress = selector.ethData.address;
            let receiveTokenAddress = receiveToken.address;
            if(data.wantAddress.toLowerCase() == aquaAddress.toLowerCase()){
                try {
                    setConvertLoading(true)
                    setTransactionLoader(true)
                    setShowConfModal(true)
                    let sendAmount_ = sendAmount;
                    if(maxAmount) {
                        sendAmount_ = data.vaultBalance;
                    }
                    let lpBalanceBeforeWithdraw = await balanceOf(data.wantAddress, userAddress);
                    let res = await handleWithdrawLpV3(data.poolId, sendAmount_, userAddress, handleNotification, data);
                    
                    if(res== "success") {
                        if(receiveToken.address.toLowerCase() != aquaAddress.toLowerCase()){
                    
                        
                            let lpBalanceAfterWithdraw = await balanceOf(data.wantAddress, selector.ethData.address);
                            let withDrawnAmount = BigNumber(lpBalanceAfterWithdraw).minus(BigNumber(lpBalanceBeforeWithdraw)).toFixed(0).toString();
                            setConvertLPFlag(true);
                            
                            const allowanceAmount = await allowance(data.wantAddress, selector.ethData.address, planetZapOneInchAddress);
                            // console.log("balance after withdraw", lpBalanceAfterWithdraw, lpBalanceBeforeWithdraw, withDrawnAmount)
                            if(parseInt(allowanceAmount) < parseFloat(lpBalanceAfterWithdraw)) {
                                // get allowance
                                let res:any = await lpTokenApprove(data.wantAddress, planetZapOneInchAddress, selector.ethData.address, lpBalanceAfterWithdraw )
                
                                if(res == "Fail") {
                                    setConvertLoading(false)
                                    setTransactionLoader(false)
                                    setShowConfModal(false)
                                    //handleNotification('failed')
                                    return "error";
                                }
                                setLPAllowanceFlag(true)
                            } else {
                                setLPAllowanceFlag(true)
                            }

                            let swapTransaction = await buildTxForSwap(withDrawnAmount);
                            const userAddress = selector.ethData.address.replace('0x', '');
                            const _planetZapOneInchAddress = planetZapOneInchAddress.replace('0x', '');
                            swapTransaction = swapTransaction.data.replace(userAddress.toLowerCase(), _planetZapOneInchAddress.toLowerCase())
                            const swapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
                            //console.log("handle 1 inch in else",aquaAddress, withDrawnAmount, swapTransaction, data.wantAddress)
                            await swapInstance.methods
                            .swap(aquaAddress, withDrawnAmount, swapTransaction, receiveToken.address)
                            .send({
                            from: selector.ethData.address,
                            value: 0
                            }).on('sending', async function (res: any) {
                            // setConfirmSwapModal(false);
                            }).once('transactionHash', async function (res: any) {
                            if (res && typeof res !== 'undefined') {
                                const transLink = `${transLinkUrl}/${res}`
                                setTransactionLink(transLink)
                                handleTransactionLink(transLink, res, convertToEther(withDrawnAmount))
                                setShowTransModal(true)
                            }
                            }).once('confirmation', async function (confNumber: any, receipt: any) {
                                setConfirmTransLink(receipt.transactionHash, receipt.status)
                                const type = receipt.status ? 'success' : 'failed'
                                handleNotification(type, receipt.transactionHash)
                                // setSwapTokenFlag(true)
                                setTimeout(async () => {
                                    dispatch(updateUserLendingData(selector.ethData.address))
                                    dispatch(updateUserBalance(selector.ethData.address))
                                    setTransactionLoader(false)
                                    setShowConfModal(false)
                                    setShowTCAModal(false)
                                }, 3000)
                                // setBtnLoading(false)
                                // dispatch(silentUpdateOnInfStake(selector.ethData.address))
                                // dispatch(updateUserLendingData(selector.ethData.address))
                            })
                            .on('error', function (error: any) {
                                handleNotification('failed')
                                setShowTransModal(false)
                                // setBtnLoading(false)
                                setTransactionLoader(false)
                                setShowConfModal(false)
                                dispatch(updateUserLendingData(selector.ethData.address))
                                dispatch(updateUserBalance(selector.ethData.address))
                                // setShowTxConfModal(false)
                            })
                        } else {
                            setTransactionLoader(false)
                            setShowConfModal(false)
                            setConvertLoading(false)
                        }
                        
                    }
                } catch(error){
                    console.log('error==>', error)
                    setTransactionLoader(false)
                    setShowConfModal(false)
                    setConvertLoading(false)
                    dispatch(updateUserLendingData(selector.ethData.address))
                    dispatch(updateUserBalance(selector.ethData.address))
                }
                finally {
                    // console.log("in finally")
                    // setConvertLoading(false)               
                }
            } else {
                let sendAmount_ = sendAmount;
                if(maxAmount) {
                    sendAmount_ = data.withdrawalBalance;
                }
                try {
                    setConvertLoading(true)
                    setTransactionLoader(true)
                    setShowConfModal(true)
                    let lpBalanceBeforeWithdraw = await balanceOf(data.wantAddress, userAddress);
                    let res = await handleWithdrawLpV3(data.poolId, sendAmount_, userAddress, handleNotification, data);
                    
                    if(res== "success") {
                        let lpBalanceAfterWithdraw = await balanceOf(data.wantAddress, userAddress);
                        let withDrawnAmount = BigNumber(lpBalanceAfterWithdraw).minus(BigNumber(lpBalanceBeforeWithdraw));
                        setConvertLPFlag(true);
                        
                        const allowanceAmount = await allowance(data.wantAddress, userAddress, planetZapOneInchAddress);
                        // console.log("balance after withdraw", lpBalanceAfterWithdraw, allowanceAmount)
                        if(parseInt(allowanceAmount) < parseFloat(lpBalanceAfterWithdraw)) {
                            // get allowance
                            let res:any = await lpTokenApprove(data.wantAddress, planetZapOneInchAddress, userAddress, lpBalanceAfterWithdraw )
            
                            if(res == "Fail") {
                                setConvertLoading(false)
                                setTransactionLoader(false)
                                setShowConfModal(false)
                            //handleNotification('failed')
                            return "error";
                            }
                            setLPAllowanceFlag(true)
                        } else {
                            setLPAllowanceFlag(true)
                        }

                        const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
                        let convertLPData:any = await convertLpV3(userAddress, conLpInfo.wantAddress, convertToEther(withDrawnAmount), conLpInfo.routerAddress, receiveToken.address, conLpInfo.path1, conLpInfo.path2, receiveTokenAddress, slippageVal, showTransLink, setShowTransModal, handleNotification, setConvertLoading, conLpInfo, setConfirmTransHistory, updateTokenList, data)
                        const {wantAddress, amountInWei, convertedToken, data0, data1, type, removeLiquidity} = convertLPData

                        let minWithdrawToken0 = BigNumber(removeLiquidity[0]).multipliedBy(0.99).decimalPlaces(0).toString()
                        let minWithdrawToken1 = BigNumber(removeLiquidity[1]).multipliedBy(0.99).decimalPlaces(0).toString()
                        // console.log('wantAddress, amountInWei, convertedToken, data0, data1, type', wantAddress, amountInWei, convertedToken, data0, data1, type, minWithdrawToken0, minWithdrawToken1);
                        planetZapInstance.methods
                        .zapOut(wantAddress, amountInWei, convertedToken, data0, data1, type, minWithdrawToken0, minWithdrawToken1)
                        .send({ from: userAddress, })
                        .once('transactionHash', function (hash: any) {
                            if (!!hash) {
                            showTransLink(hash)
                            setShowTransModal(true)
                            }
                        }) 
                        .once('confirmation', function (confNumber: any, receipt: any) {
                            setConfirmTransHistory(receipt.transactionHash, receipt.status)
                            const type = receipt.status ? 'success' : 'failed'
                            handleNotification(type, receipt.transactionHash)
                            setConvertLoading(false)
                            setShowTransModal(false)
                            if(type == "success"){
                                // dispatch(updateLPData(selector.ethData.address))
                                dispatch(setPortfolioLoading(true))
                                dispatch(updateUserBalance(selector.ethData.address))
                                updateTokenList()
                                setZapOutFlag(true)
                                setShowPoolDetails(false)
                            }
                            setMaxAmount(false)
                            setSendAmount('')
                            setSendAmountWithComma('')
                            setReceiveAmount('')
                            setReceiveAmountWithComma('')
                            setConLpInfo({})
                        
                            setTimeout(async () => {
                                dispatch(updateUserLendingData(selector.ethData.address))
                                dispatch(updateUserBalance(selector.ethData.address))
                                setTransactionLoader(false)
                                setShowConfModal(false)
                                setShowTCAModal(false)
                            }, 3000)
                        })
                        .on('error', function (error: any) {
                            console.log("error message", error)
                            setConvertLoading(false)
                            handleNotification('failed')
                            // updateTokenList()
                            setShowTransModal(false)
                            setTransactionLoader(false)
                            setShowConfModal(false)
                            dispatch(updateUserLendingData(selector.ethData.address))
                            dispatch(updateUserBalance(selector.ethData.address))
                            return "error";
                        })
                    }
                }
                catch (error) {
                    console.log('error==>', error)
                    setTransactionLoader(false)
                    setShowConfModal(false)
                    setConvertLoading(false)
                    dispatch(updateUserLendingData(selector.ethData.address))
                    dispatch(updateUserBalance(selector.ethData.address))
                }
                finally {
                    // console.log("in finally")
                    // setConvertLoading(false)               
                }
            }
        }
    }


    const updateTokenList = async () => {
        const userAddress = selector.ethData.address
        try {
            const receiveTokensList: any = await returnConvertLPTokenList(userAddress);
            if(!!receiveTokensList && typeof receiveTokensList !== 'undefined' && receiveTokensList.length > 0){
                const index = receiveTokensList.findIndex((e: any) => e.address === receiveToken.address)
                if (index !== -1) {
                    setReceiveTokenList(receiveTokensList)
                    setReceiveToken(receiveTokensList[index])
                }
            }


        } catch (error) { }
    }

    const getTokenName = (tokenName: string) => {
        let tokenN = tokenName
        tokenN = tokenN.toLowerCase().replace('wbnb', 'bnb')
        return tokenN.toUpperCase()
    }
    const getIcons = (tokenNum: any) => {
        if(data.name.toLowerCase() == '3g'){
            const icon: any = getTokenIcon(data.name)
            return icon
        }
        const tokenNameVal: any = data.name.split('-')
        let tokenN = ''
        if (tokenNum === 1) {
            tokenN = tokenNameVal[0]
        }
        else {
            let actualTokenName: any = tokenNameVal[1].split(" ");
            tokenN = actualTokenName[0]
        }
        const icon: any = getTokenIcon(tokenN)
        return icon
    }

    return (
        <Fragment>
            <TransActionCont>
                <TransInputCont>
                    <FlexSBCont>
                        {
                            !!data && data.withdrawalBalance > 0 && sendAmount > 0 ?
                            <InputTitle className="sendText">Send {`($${parseFloat((+sendAmount * +data.price).toString()).toFixed(2)})`}</InputTitle>
                            :
                            <InputTitle className="sendText">Send</InputTitle>
                        }
                        
                        <TransBalTxt className="sendAmt">
                            {!!data && data.withdrawalBalance > 0 ? `${parseFloat(data.withdrawalBalance).toFixed(4)} ($${(+data.withdrawalBalance * +data.price).toFixed(2)})` : '0.0000 ($0.00)'}
                        </TransBalTxt>
                    </FlexSBCont>
                    <TransInputBox>
                        <FlexSBCont>
                            <TransInput
                                className='convert-lp-input'
                                disabled={receiveTokenList.length === 0}
                                placeholder="0"
                                onChange={handleSendAmount}
                                value={sendAmountWithComma && sendAmountWithComma}
                                autoFocus={true}
                                ref={inputRef}
                            />
                            <FlexCont>
                                <TransMaxBox onClick={() => receiveToken.address !== undefined ? handleMax(): ""}>Max</TransMaxBox>
                                {data.name.toLowerCase() != "aqua" ?
                                    <LpImgGroup>
                                        <img src={getIcons(1)} alt="" />
                                        { data.name.toLowerCase() !== '3g' &&
                                        <img className="second-img" src={getIcons(2)} alt="" />}
                                        {data.protocol.toLowerCase() == 'thena' && <img className='third-img' src={thenaIcon} alt="" />}
                                        {data.protocol.toLowerCase() == 'biswap' && <img className='third-img' src={bswIcon} alt="" />}
                                    </LpImgGroup>
                                    :
                                    <LpImgGroup>
                                        <img src={getIcons(1)} alt="" />
                                    </LpImgGroup>
                                }
                                <TokenName className="lp-token">{getTokenName(data.name)}</TokenName>
                            </FlexCont>

                        </FlexSBCont>
                    </TransInputBox>
                </TransInputCont>
                <TransInputCont className='receive-token-card'>
                    <FlexSBCont>
                        {
                            Object.values(receiveToken).length > 0 && +receiveAmount > 0 ?
                            <InputTitle className="sendText">Receive {`($${parseFloat((+receiveAmount * +receiveToken.price).toString()).toFixed(2)})`}</InputTitle>
                            :
                            <InputTitle className="sendText">Receive</InputTitle>
                        }
                        
                        <TransBalTxt className="sendAmt">{Object.values(receiveToken).length > 0 ? `${parseFloat(receiveToken.value).toFixed(4)} ($${parseFloat(receiveToken.usdValue).toFixed(2)})` : '-'}</TransBalTxt>
                    </FlexSBCont>
                    <TransInputBox>
                        <FlexSBCont>
                            <TransInput
                                className='convert-lp-input'
                                disabled={true}
                                placeholder="0"
                                value={receiveAmountWithComma && receiveAmountWithComma}
                            />
                            <FlexCont>
                                {
                                    tokenLoading ? <Spinner /> : receiveTokenList.length > 0 &&
                                        <TokenSelectCont disabled={isAmtLoading} onClick={() => {isAmtLoading ? setShowTokenModal(false) : setShowTokenModal(true)}} >
                                            {Object.values(receiveToken).length > 0 && (
                                                <FlexCont>
                                                    <img className="token-icon" src={receiveToken.icon} alt="" />
                                                    <TokenName>{receiveToken.name}</TokenName>
                                                    {isAmtLoading ?
                                                    <Spinner />:
                                                    <img className="down-icon" src={DownIcon} alt="" />
                                                    }
                                                </FlexCont>
                                            )}

                                        </TokenSelectCont>
                                }
                            </FlexCont>
                        </FlexSBCont>
                    </TransInputBox>
                </TransInputCont>
                {
                    isAmtLoading && parseFloat(sendAmount) > 0 && <TransInoTxt className="loading-text">Amount Loading ...</TransInoTxt>
                }
                {
                    selector.ethData.address === null || (!!conLpInfo && Object.values(conLpInfo).length === 0) || (parseFloat(sendAmount) > parseFloat(data.withdrawalBalance)) ?
                        <TransButton disabled={true}>{parseFloat(sendAmount) > parseFloat(data.withdrawalBalance) ? 'InSufficient Balance' : 'Convert'}</TransButton> :
                        <>
                            <TransButton disabled={convertLoading || parseFloat(sendAmount) === 0 || parseFloat(sendAmount) > parseFloat(data.withdrawalBalance) || isAmtLoading || parseFloat(sendAmount) > parseFloat(data.withdrawalBalance)} onClick={() => handleConvertLp()}>{convertLoading || isAmtLoading ? <Spinner /> : 'Withdraw & Zap Out'}</TransButton>
                        </>

                }

            </TransActionCont>
            <CustomModal show={showTokenModal} toggleModal={setShowTokenModal}>
                {showTokenModal && (
                    <TokenSelect
                        selectedAdd={wantAddress}
                        setShowTokenModal={setShowTokenModal}
                        tokenListVal={receiveTokenList}
                        handleTokenSelect={handleTokenSelect}
                        hideInput={true}
                    />
                )}
            </CustomModal>
            <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
                {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
            </CustomModal>
            <CustomModal show={showConfModal} toggleModal={setShowConfModal}>
                { showConfModal && <TransactionConfirmation flag_1={convertLPFlag} flag_2={lpAllowanceFlag} flag_3={zapOutFlag} poolDetails={data} sendToken={receiveToken} transactionLoader={transactionLoader} action={'withdraw_zapout'} setShowConfModal={setShowConfModal} />}
            </CustomModal>
        </Fragment>

    )
}

export default WithdrawAndZapOut