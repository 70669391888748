import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ModalCont,
  FlexSBCont,
  FlexCont,
  TitleText,
  TokenSelectTxt,
  TokenSelectSubTxt,
  TokenValCont,
  TokenListCont,
  TokenHeader,
  TokenRow,
  TokenListInput,
  TokenListData,
  NoDataAvailable,
} from './style'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import whiteCrossIcon from 'assets/icons/white-cross.svg'
import { returnTokenNameAndPrice, returnPairExistOrNot } from 'modules/block-chain/Swap'
import { tokenList } from 'modules/block-chain/tokenList'
import { commaFy } from 'service/globalFunctions'
import { getTokenIcon } from 'shared/tokenIconList'
import { TwoImgCont } from 'shared/styles/planetTableStyle'

const TokenSelect = (props: any) => {
  const selector = useSelector((state: any) => state)
  const { setShowTokenModal, tokenListVal, handleTokenSelect, selectedAdd, hideInput, hideUsd, existTokenAdd, crossIconColor, sortTokens } = props
  const [tokenDataList, setTokenDataList] = useState<any>(tokenListVal)

  useEffect(() => {
    if(tokenListVal) {
      setTokenDataList(tokenDataList);
    }
  },[tokenListVal])
  const handleTokenChange = async (e: any) => {
    const value: any = e.target.value
    let newTokenList: any = []
    if (!!value && value.length > 0) {
      newTokenList = tokenDataList.filter( 
        (token: any) => { 
          return token.name.toLowerCase().includes(value.toLowerCase()) || (token.address && token.address.toLowerCase() === value.toLowerCase() )
        }
      )
      if (newTokenList.length === 0) {
        if (selectedAdd) {
          const isPairExist = await returnPairExistOrNot(selectedAdd, value)
          if (isPairExist) {
            const newToken: any = await returnTokenNameAndPrice(value, selector.ethData.address)
            if (!!newToken && typeof newToken !== 'undefined') {
              const filterData = tokenList.filter((e: any) => e.address === value)
              let data: any = ''
              if (filterData.length > 0) {
                data = { name: newToken.name, value: newToken.balance, address: newToken.address, icon: filterData[0].icon }
              } else {
                data = { name: newToken.name, value: newToken.balance, address: newToken.address, icon: null }
              }
              newTokenList.push(data)
            }
          }
        }
      }
      setTokenDataList([...newTokenList])
    } else {
      setTokenDataList([...tokenListVal])
    }
  }

  const renderTokenIcons = (tokenIcons: string) => {
    const tokenName = tokenIcons.split('-')
    if (tokenName.length === 1) {
 
        const icon = getTokenIcon(tokenIcons)
        return <img src={icon} alt="" />
    }
    else if (tokenName.length === 2) {
        const icon1 = getTokenIcon(tokenName[0])
        let secondTokenName = tokenName[1].split(" ");
        const icon2 = getTokenIcon(secondTokenName[0])
        return (
            <TwoImgCont>
                <img src={icon1} alt="" />
                <img className="second-img" src={icon2} alt="" />
            </TwoImgCont>
        )
    }
    else if (tokenName.length === 3) {
        const icon1 = getTokenIcon(tokenName[0])
        const icon2 = getTokenIcon(tokenName[1])
        const icon3 = getTokenIcon(tokenName[2])
        return (
            <TwoImgCont>
                <img src={icon1} alt="" />
                <img className="second-img" src={icon2} alt="" />
                <img className='third-icon' src={icon3} alt="" />
            </TwoImgCont>
        )
    }
  }

  const getOrderedTokenList = (list: any) => {
    if (!!list && list.length > 0) {
      const newTokenList = list.sort((a: any, b: any) => (parseFloat(b.usdValue) > parseFloat(a.usdValue)) ? 1 : (parseFloat(a.usdValue) > parseFloat(b.usdValue) ? -1 : 0))
      return newTokenList
    }
  }
  const getOrderedTokenListByApy = (list: any) => {
    if (!!list && list.length > 0) {
      const newTokenList = list.sort((a: any, b: any) => (parseFloat(b.totalApy) > parseFloat(a.totalApy)) ? 1 : (parseFloat(a.totalApy) > parseFloat(b.totalApy) ? -1 : 0))
      return newTokenList
    }
  }
  const renderTokenList = () => {
    const orderedTokenList: any = sortTokens !== undefined && sortTokens == 'apy' ? getOrderedTokenListByApy(tokenDataList) : getOrderedTokenList(tokenDataList)
    return orderedTokenList.map((item: any, index: any) => {
      if(item.icon == undefined) {
        return (
          <TokenRow key={index} isAddExist={existTokenAdd === item.address} onClick={() => handleTokenSelect(item)}>
            <FlexSBCont >
              <FlexCont className='poolIcons'>
              {renderTokenIcons(item.name)}
                <div>
                  <TokenSelectTxt>{item.name}</TokenSelectTxt>
                  <TokenSelectSubTxt>{item.tokenOrigin}</TokenSelectSubTxt>
                </div>
              </FlexCont>
              <TokenValCont>
                <TokenSelectTxt>{commaFy(parseFloat(item.value).toFixed(4))}</TokenSelectTxt>
                {!hideUsd && <TokenSelectSubTxt>${commaFy(parseFloat(item.usdValue).toFixed(2))}</TokenSelectSubTxt>}
              </TokenValCont>
            </FlexSBCont>
          </TokenRow>
        )
      } else {
        return (
          <TokenRow key={index} isAddExist={existTokenAdd === item.address} onClick={() => handleTokenSelect(item)}>
            <FlexSBCont >
              <FlexCont>
                <img className="select-token-icon" src={item.icon} alt="" />
                <div>
                  <TokenSelectTxt>{item.name}</TokenSelectTxt>
                  <TokenSelectSubTxt>{item.tokenOrigin}</TokenSelectSubTxt>
                </div>
              </FlexCont>
              <TokenValCont>
                <TokenSelectTxt>{commaFy(parseFloat(item.value).toFixed(4))}</TokenSelectTxt>
                {!hideUsd && <TokenSelectSubTxt>${commaFy(parseFloat(item.usdValue).toFixed(2))}</TokenSelectSubTxt>}
              </TokenValCont>
            </FlexSBCont>
          </TokenRow>
        )
      }
      
    })
  }
  return (
    <ModalCont>
      <TokenListCont>
        <TokenHeader>
          <FlexSBCont>
            <TitleText>Select Token</TitleText>
            <img className="cross-icon" src={crossIconColor !== undefined ? whiteCrossIcon : CrossIcon} alt="" onClick={() => setShowTokenModal(false)} />
          </FlexSBCont>
          {!hideInput && <TokenListInput className='searchText' placeholder="Search" onChange={handleTokenChange} />}
        </TokenHeader>
        {tokenDataList.length > 0 ? (
          <TokenListData>{renderTokenList()}</TokenListData>
        ) : (
          <NoDataAvailable>
            <p>No Token Available</p>
          </NoDataAvailable>
        )}
      </TokenListCont>
    </ModalCont>
  )
}

export default TokenSelect
