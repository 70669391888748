import { ModalContainer, ModalContent, ModalContainer2, ModalContentNew } from './style'
import { useState, useEffect } from 'react'
interface Props {
  show: boolean
  toggleModal: any
  children: any
  infoPopUpContainer?: any
  zapTrxModal?: any
}

const CustomModal = (props: Props) => {
  const { show, toggleModal, infoPopUpContainer = '', children, zapTrxModal } = props
  const handleClickOutside = (e: any) => {
    if (e.target === e.currentTarget) {
      toggleModal()
    }
  }
  return (
    <ModalContainer show={show} onMouseDown={handleClickOutside}>
      {zapTrxModal !== undefined ?
        <ModalContentNew zapTrxModalVal={zapTrxModal} className={infoPopUpContainer}>{children}</ModalContentNew>
        :
        <ModalContent className={infoPopUpContainer}>{children}</ModalContent>
      }
      
    </ModalContainer>
  )
}
export default CustomModal
