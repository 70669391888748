import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { LoadingText, PlanetCard } from 'shared/styles/styled'

export const MarketCont = styled.div`
  .info-gap {
      margin-top: 10px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 0;
  }
  .on-web{
    display: none;
    @media (min-width: ${screenSizes.mediaM}px) {
      display: block;
    }
  }
  .on-mobile{
    display: block;
    @media (min-width: ${screenSizes.mediaM}px) {
       display: none;
    }
  }
`
export const Title = styled.h1`
  font-size: 24px;
  line-height: 29px;
  color: ${colors.white};
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 30px;
    line-height: 37px;
  }
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 40px;
    line-height: 49px;
  }
`
export const CardCont = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin-top: 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    margin-top: 30px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    margin-top: 40px;
  }
`

export const Card = styled(PlanetCard)`
  padding: 30px;
`
export const CardText = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 12px;
    line-height: 15px;
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    font-size: 16px;
    line-height: 19px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
`
export const CardBText = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 24px;
    line-height: 30px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 30px;
    line-height: 37px;
    color: ${colors.white};
    font-family: MontserratBold;
    margin: 0;
  }
`
export const CardLText = styled(CardText)`
  font-family: MontserratMedium;
  opacity: 0.4;
`
export const PercentText = styled(CardText) <any>`
  color: ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
`
export const CardLine = styled(PlanetCard)`
  border: 0;
  height: 1px;
  background: ${colors.borderColor};
  margin: 20px 0;
`
export const TopTokenCont = styled.div`
  .top-token{
    margin-top: 15px;
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-top: 16px;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
      margin-top: 20px;
    }
  }
  img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 30px;
      height: 30px;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 40px;
      height: 40px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      width: 50px;
      height: 50px;
    }
  }
  .token-name{
    width: 65px;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      width: 80px;
    }
  }
`
export const TableWrapper = styled.div`
  overflow-x: auto;
  margin: 15px -16px 0 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 30px 0 0 0;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
export const TableTitle = styled(CardBText)`
  padding: 0 0 0 30px;
  margin-bottom: 8px;
`
export const TableCont = styled(PlanetCard)`
  min-width: 637px;
  padding: 30px 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    min-width: auto;
  }
  .table-row{
    cursor: pointer;
    &:hover{
      background: #55FE761A;
      border-left: 4px solid ${colors.lightGreen};
    }
    // &:hover{
    //   background: #050505;
    //   border-left: 4px solid ${colors.lightGreen};
    //   box-shadow: 0px 4px 31px rgb(48 140 218 / 30%);
    // }
  }
  img{
      width: 30px;
      height: 30px;
      margin-right: 10px;
      @media (min-width: ${screenSizes.mediaXLL}px) {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }
      @media (min-width: ${screenSizes.mediaXXL}px) {
        width: 50px;
        height: 50px;
      }
  }
  .first-item{
    text-align: left;
  }
`
export const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  padding: 12px 30px 13px 26px;
  border-left: 4px solid transparent;
  text-align: right;
  background: transparent;
`
export const TableText = styled.p<any>`
  font-size: 14px;
  line-height: 18px;
  vertical-align:middle;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: ${(props: any) => props.light ? 0.4 : 1};
  .gamma-icon{
    width: 30px;
    height: 30px;
    vertical-align:middle;
    margin-right: 0px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 19px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
    line-height: 22px;
    
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    .gamma-icon{
      width: 24px;
      height: 24px;
    }
  }
`
export const TokenText = styled(TableText)`
  font-family: MontserratBold;
`
export const TableSmText = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 3px 0 0 0;
  opacity: 0.4;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 5px 0 0 0;
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 16px;
    line-height: 19px;
  }
`
export const TPercentText = styled(TableSmText) <any>`
  color: ${(props: any) => props.isNegative ? colors.red : colors.lightGreen};
  opacity: 1;
`

/* ============================= shimmer style ======================= */

export const LoadingText1 = styled(LoadingText)`
  width: 100px;
  max-width: 100px;
  height: 14px;
  @media (min-width: ${screenSizes.mediaXL}px) {
    height: 16px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    height: 18px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    height: 20px;
    width: 120px;
    max-width: 120px;
  }
`

/* ========================== Slider Style ======================= */

export const SliderCont = styled.div`
  width: 100%;
  margin: 10px 0 0 0;
  .rangeslider{
    touch-action: true;
    margin: 0;
  }
  .rangeslider-horizontal {
    height: 8px;
    border-radius: 20px;
    background: rgba(85, 254, 202, 0.1);
    border: 0;
    box-sizing: border-box;
    width:100%;
    padding:0;
    cursor:pointer;
    ul{
      display:none;
    }
    .rangeslider__fill {
      border-radius: 20px;
      background: linear-gradient(90deg, ${colors.lightGreen} 0%, ${colors.green} 100%) 0% 0% no-repeat padding-box;
      box-shadow:none;
    }
    .rangeslider__handle:after {
      display:none;
    }
    .rangeslider__handle {
      display: none;
      outline: none;
    }
    @media (min-width: ${screenSizes.mediaM}px) {
      height: 10px;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    margin: 0 10px 0 12px;
  }
`