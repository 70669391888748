import BNBIcon from 'assets/icons/BNB.png'
import BUSDIcon from 'assets/icons/BUSD.png'
import AQUAIcon from 'assets/icons/blue-icon.svg'
import GAMMAIcon from 'assets/icons/gamma-icon.png'
import BTCBIcon from 'assets/icons/BTCB.svg'
import USDTIcon from 'assets/icons/USDT.svg'
import USDCIcon from 'assets/icons/USDC.svg'
import DAIIcon from 'assets/icons/DAI.svg'
import USTIcon from 'assets/icons/UST.png'
import ETHIcon from 'assets/icons/ETH.svg'
import TUSDIcon from 'assets/icons/TUSD.svg'
import CAKEIcon from 'assets/icons/CAKE.svg'
import FRAXIcon from 'assets/icons/FRAX.svg'
import DOTIcon from 'assets/icons/DOT.svg'
import ADAIcon from 'assets/icons/ADA.svg'
import MATICIcon from 'assets/icons/MATIC.svg'
import SOLIcon from 'assets/icons/SOL.svg'
import LUNAIcon from 'assets/icons/LUNA.png'
import AUSTIcon from 'assets/icons/aUST.png'
import AVAXIcon from 'assets/icons/AVAX.svg'
import FTMIcon from 'assets/icons/FTM.svg'
import AtomIcon from 'assets/icons/atom_icon.png'
import XRPIcon from 'assets/icons/XRP.svg'
import LinkIcon from 'assets/icons/LINK.svg'
import whustIcon from 'assets/icons/whust_icon.svg'
// export const pfTokenList: any = {

//     0: {
//         sno: 0,
//         address: '0x7C13b52B85bf95D1531F192e1c0C63a337F479FF',
//         name: 'BNB',
//         marketSymbol: 'BNB',
//         icon: BNBIcon,
//     },
//     1: {
//         sno: 1,
//         address: '0x471Bc4232eA272868F0fDEccf5272f6982863910',
//         name: 'BUSD',
//         marketSymbol: 'BUSD',
//         icon: BUSDIcon,
//     },
//     2: {
//         sno: 2,
//         address: '0x015bB997BD548A411359681FFEA2964e070f66b0',
//         name: 'GAMMA',
//         marketSymbol: 'GAMMA',
//         icon: GAMMAIcon,
//     },
//     3: {
//         sno: 3,
//         address: '0xdF99B7E04F8e7F3F4FeD3B02dc0D97Ee76Ef5890',
//         name: 'BTCB',
//         marketSymbol: 'BTCB',
//         icon: BTCBIcon,
//     },
//     4: {
//         sno: 4,
//         address: '0xbC2E21Ee8aD8Ad34185ED21eDF8484bd2f116c7b',
//         name: 'USDT',
//         marketSymbol: 'USDT',
//         icon: USDTIcon,
//     },
//     5: {
//         sno: 5,
//         address: '0xCEe27302Dcf35FFc5B907405E1F736BAa5D3171c',
//         name: 'AQUA',
//         marketSymbol: 'AQUA',
//         icon: AQUAIcon,
//     },
// }

// export const marketTokenList: any = {

//     0: {
//         sno: 0,
//         address: '0x7C13b52B85bf95D1531F192e1c0C63a337F479FF',
//         name: 'BNB',
//         marketSymbol: 'BNB',
//         icon: BNBIcon,
//         tokenOrigin: 'Binance Coin',
//     },
//     1: {
//         sno: 1,
//         address: '0x471Bc4232eA272868F0fDEccf5272f6982863910',
//         name: 'BUSD',
//         marketSymbol: 'BUSD',
//         icon: BUSDIcon,
//         tokenOrigin: 'Binance USD',
//     },
//     2: {
//         sno: 2,
//         address: '0x015bB997BD548A411359681FFEA2964e070f66b0',
//         name: 'GAMMA',
//         marketSymbol: 'GAMMA',
//         icon: GAMMAIcon,
//         tokenOrigin: 'GAMMA',
//     },
//     3: {
//         sno: 3,
//         address: '0xdF99B7E04F8e7F3F4FeD3B02dc0D97Ee76Ef5890',
//         name: 'BTCB',
//         marketSymbol: 'BTCB',
//         icon: BTCBIcon,
//         tokenOrigin: 'Bitcoin',
//     },
//     4: {
//         sno: 4,
//         address: '0xbC2E21Ee8aD8Ad34185ED21eDF8484bd2f116c7b',
//         name: 'USDT',
//         marketSymbol: 'USDT',
//         icon: USDTIcon,
//         tokenOrigin: 'USDT',
//     },
//     5: {
//         sno: 5,
//         address: '0xCEe27302Dcf35FFc5B907405E1F736BAa5D3171c',
//         name: 'AQUA',
//         marketSymbol: 'AQUA',
//         icon: AQUAIcon,
//         tokenOrigin: 'AQUA',
//     },
// }

export const pfTokenList: any = {

    0: {
        sno: 0,
        address: '0x24664791B015659fcb71aB2c9C0d56996462082F',
        name: 'BNB',
        marketSymbol: 'BNB',
        icon: BNBIcon,
        tokenOrigin: 'Binance Coin',
        blockNumber: 12078744,
        token: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        decimals: 18
    },
    1: {
        sno: 1,
        address: '0xF701A48e5C751A213b7c540F84B64b5A6109962E',
        name: 'GAMMA (OLD)',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12078110,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18
    },
    2: {
        sno: 2,
        address: '0x928fa017eBf781947102690c9b176996B2E00f22',
        name: 'BUSD',
        marketSymbol: 'BUSD',
        icon: BUSDIcon,
        tokenOrigin: 'Binance USD',
        blockNumber: 12127442,
        token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        decimals: 18
    },
    3: {
        sno: 3,
        address: '0xB3A4ce0654524dCF4B5165cee280EbE69a6E8133',
        name: 'AQUA (OLD)',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12135891,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18
    },
    4: {
        sno: 4,
        address: '0xa5ae8459e710F95ca0C93d73F63a66d9996F1ACE',
        name: 'UST',
        marketSymbol: 'UST',
        icon: USTIcon,
        tokenOrigin: 'Terra USD',
        blockNumber: 12159650,
        token: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
        decimals: 18
    },
    5: {
        sno: 5,
        address: '0xcfa5b884689dc09e4503e84f7877d3A583fcceef',
        name: 'BTCB',
        marketSymbol: 'BTCB',
        icon: BTCBIcon,
        tokenOrigin: 'Bitcoin',
        blockNumber: 12159960,
        token: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        decimals: 18
    },
    6: {
        sno: 6,
        address: '0x66fD9D390De6172691EC0ddF0ac7F231c1f9a434',
        name: 'ETH',
        marketSymbol: 'ETH',
        icon: ETHIcon,
        tokenOrigin: 'Ethereum',
        blockNumber: 12160319,
        token: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        decimals: 18
    },
    7: {
        sno: 7,
        address: '0x854a534cEFAf8fD20A70C9DC976C4f65324D7B42',
        name: 'USDC',
        marketSymbol: 'USDC',
        icon: USDCIcon,
        tokenOrigin: 'USD Coin',
        blockNumber: 12160319,
        token: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        decimals: 18
    },
    8: {
        sno: 8,
        address: '0x4c2bddc208B58534EdDC1fba7B2828CAb70797b5',
        name: 'USDT',
        marketSymbol: 'USDT',
        icon: USDTIcon,
        tokenOrigin: 'Tether',
        blockNumber: 12160319,
        token: '0x55d398326f99059ff775485246999027b3197955',
        decimals: 18
    },
    9: {
        sno: 9,
        address: '0x8B2f098411Ce4B32c9D2110FeF257Cf01D006bA5',
        name: 'DAI',
        marketSymbol: 'DAI',
        icon: DAIIcon,
        tokenOrigin: 'DAI',
        blockNumber: 12160319,
        token: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        decimals: 18
    },
    10: {
        sno: 10,
        address: '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442',
        name: 'GAMMA',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12160319,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18
    },
    11: {
        sno: 11,
        address: '0xb7eD4A5AF620B52022fb26035C565277035d4FD7',
        name: 'AQUA',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12160319,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18
    },
    12: {
        sno: 12,
        address: '0x939e98eeC2Aa37baB8c5d94BC90C6E00911d27B4',
        name: 'TUSD',
        marketSymbol: 'TUSD',
        icon: TUSDIcon,
        tokenOrigin: 'TrueUSD',
        blockNumber: 12160319,
        token: '0x14016e85a25aeb13065688cafb43044c2ef86784',
        decimals: 18
    },
    13: {
        sno: 13,
        address: '0x7a9cB040e4DdD5271C36A8C8DeAdD3BA1b5A5949',
        name: 'CAKE',
        marketSymbol: 'CAKE',
        icon: CAKEIcon,
        tokenOrigin: 'PancakeSwap',
        blockNumber: 12160319,
        token: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        decimals: 18
    },
    14: {
        sno: 14,
        address: '0x6022bA7e5A70E1bAA98d47a566F3495A26511b25',
        name: 'FRAX',
        marketSymbol: 'FRAX',
        icon: FRAXIcon,
        tokenOrigin: 'Frax',
        blockNumber: 12160319,
        token: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
        decimals: 18
    },
    15: {
        sno: 15,
        address: '0x84a785E400c43D341D5cbCDC7f0dceb49Cae7d00',
        name: 'DOT',
        marketSymbol: 'DOT',
        icon: DOTIcon,
        tokenOrigin: 'Polkadot',
        blockNumber: 12160319,
        token: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
        decimals: 18
    },
    16: {
        sno: 16,
        address: '0x9B81d1df15fD9C07007d9a9b8fF75D40A5a80D94',
        name: 'ADA',
        marketSymbol: 'ADA',
        icon: ADAIcon,
        tokenOrigin: 'Cardano',
        blockNumber: 12160319,
        token: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
        decimals: 18
    },
    17: {
        sno: 17,
        address: '0xeb23D803Ec8D598662441807456620cF96eb0470',
        name: 'MATIC',
        marketSymbol: 'MATIC',
        icon: MATICIcon,
        tokenOrigin: 'Polygon',
        blockNumber: 12160319,
        token: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
        decimals: 18
    },

    18: {
        sno: 18,
        address: '0x45646b30c3Bb8c02bCfE10314308a8055E705ebF',
        name: 'SOL',
        marketSymbol: 'SOL',
        icon: SOLIcon,
        tokenOrigin: 'SOLANA',
        blockNumber: 12160319,
        token: '0x570a5d26f7765ecb712c0924e4de545b89fd43df',
        decimals: 18
    },
    19: {
        sno: 19,
        address: '0x88FD42E447d39C3259b53623f2536bd855e47C48',
        name: 'LUNA',
        marketSymbol: 'LUNA',
        icon: LUNAIcon,
        tokenOrigin: 'Terra',
        blockNumber: 12160319,
        token: '0x156ab3346823B651294766e23e6Cf87254d68962',
        decimals: 6
    },
    20: {
        sno: 20,
        address: '0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4',
        name: 'aUST',
        marketSymbol: 'aUST',
        icon: AUSTIcon,
        tokenOrigin: 'AnchorUST',
        blockNumber: 12160319,
        token: "0x8b04e56a8cd5f4d465b784ccf564899f30aaf88c",
        decimals: 6,
    },
    21: {
        sno: 21,
        address: '0x2a29572b771a8D8057A046fe80C45a77DBB6E804',
        name: 'AVAX',
        marketSymbol: 'AVAX',
        icon: AVAXIcon,
        tokenOrigin: 'Avalanche',
        blockNumber: 12160319,
        token: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
        decimals: 18
    },
    22: {
        sno: 22,
        address: '0x820cDE110D5f17b9F985FBb80f10D4f7158F130f',
        name: 'ATOM',
        marketSymbol: 'ATOM',
        icon: AtomIcon,
        tokenOrigin: 'Cosmos',
        blockNumber: 12160319,
        token: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
        decimals: 18
    },
    23: {
        sno: 23,
        address: '0x2aCAf66E67876d18CC5a27EB90Aa32b06Ab4785B',
        name: 'XRP',
        marketSymbol: 'XRP',
        icon: XRPIcon,
        tokenOrigin: 'XRP',
        blockNumber: 12160319,
        token: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
        decimals: 18
    },
    24: {
        sno: 24,
        address: '0x5e11eC8Ec1a57A93E5ddc8AD950cea60EAd9014b',
        name: 'LINK',
        marketSymbol: 'LINK',
        icon: LinkIcon,
        tokenOrigin: 'Chainlink',
        blockNumber: 12160319,
        token: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
        decimals: 18
    },
     25: {
        sno: 25,
        address: '0xb3A350f549113c60d074587dC40cD25c83d4D3b0',
        name: 'FTM',
        marketSymbol: 'FTM',
        icon: FTMIcon,
        tokenOrigin: 'Fantom',
        blockNumber: 12160319,
        token: '0xad29abb318791d579433d831ed122afeaf29dcfe',
        decimals: 18
    },
    // 26: {
    //     sno: 26,
    //     address: '0xAF7602Fb6A83f04886032467B487101B80ebccC0',
    //     name: 'whUST',
    //     marketSymbol: 'UST',
    //     icon: whustIcon,
    //     tokenOrigin: 'Terra',
    //     blockNumber: 12160319,
    //     token: '0x3d4350cd54aef9f9b2c29435e0fa809957b3f30a',
    //     decimals: 6
    // },
}

export const marketTokenList: any = {

    0: {
        sno: 0,
        address: '0x24664791B015659fcb71aB2c9C0d56996462082F',
        name: 'BNB',
        marketSymbol: 'BNB',
        icon: BNBIcon,
        tokenOrigin: 'Binance Coin',
        blockNumber: 12078744,
        token: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        decimals: 18
    },
    1: {
        sno: 1,
        address: '0xF701A48e5C751A213b7c540F84B64b5A6109962E',
        name: 'GAMMA (OLD)',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12078110,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18
    },
    2: {
        sno: 2,
        address: '0x928fa017eBf781947102690c9b176996B2E00f22',
        name: 'BUSD',
        marketSymbol: 'BUSD',
        icon: BUSDIcon,
        tokenOrigin: 'Binance USD',
        blockNumber: 12127442,
        token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        decimals: 18
    },
    3: {
        sno: 3,
        address: '0xB3A4ce0654524dCF4B5165cee280EbE69a6E8133',
        name: 'AQUA (OLD)',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12135891,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18
    },
    4: {
        sno: 4,
        address: '0xa5ae8459e710F95ca0C93d73F63a66d9996F1ACE',
        name: 'UST',
        marketSymbol: 'UST',
        icon: USTIcon,
        tokenOrigin: 'Terra USD',
        blockNumber: 12159650,
        token: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
        decimals: 18
    },
    5: {
        sno: 5,
        address: '0xcfa5b884689dc09e4503e84f7877d3A583fcceef',
        name: 'BTCB',
        marketSymbol: 'BTCB',
        icon: BTCBIcon,
        tokenOrigin: 'Bitcoin',
        blockNumber: 12159960,
        token: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        decimals: 18
    },
    6: {
        sno: 6,
        address: '0x66fD9D390De6172691EC0ddF0ac7F231c1f9a434',
        name: 'ETH',
        marketSymbol: 'ETH',
        icon: ETHIcon,
        tokenOrigin: 'Ethereum',
        blockNumber: 12160319,
        token: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        decimals: 18
    },
    7: {
        sno: 7,
        address: '0x854a534cEFAf8fD20A70C9DC976C4f65324D7B42',
        name: 'USDC',
        marketSymbol: 'USDC',
        icon: USDCIcon,
        tokenOrigin: 'USD Coin',
        blockNumber: 12160319,
        token: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        decimals: 18
    },
    8: {
        sno: 8,
        address: '0x4c2bddc208B58534EdDC1fba7B2828CAb70797b5',
        name: 'USDT',
        marketSymbol: 'USDT',
        icon: USDTIcon,
        tokenOrigin: 'Tether',
        blockNumber: 12160319,
        token: '0x55d398326f99059ff775485246999027b3197955',
        decimals: 18
    },
    9: {
        sno: 9,
        address: '0x8B2f098411Ce4B32c9D2110FeF257Cf01D006bA5',
        name: 'DAI',
        marketSymbol: 'DAI',
        icon: DAIIcon,
        tokenOrigin: 'DAI',
        blockNumber: 12160319,
        token: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        decimals: 18
    },
    10: {
        sno: 10,
        address: '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442',
        name: 'GAMMA',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12160319,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18
    },
    11: {
        sno: 11,
        address: '0xb7eD4A5AF620B52022fb26035C565277035d4FD7',
        name: 'AQUA',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12160319,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18
    },
    12: {
        sno: 12,
        address: '0x939e98eeC2Aa37baB8c5d94BC90C6E00911d27B4',
        name: 'TUSD',
        marketSymbol: 'TUSD',
        icon: TUSDIcon,
        tokenOrigin: 'TrueUSD',
        blockNumber: 12160319,
        token: '0x14016e85a25aeb13065688cafb43044c2ef86784',
        decimals: 18
    },
    13: {
        sno: 13,
        address: '0x7a9cB040e4DdD5271C36A8C8DeAdD3BA1b5A5949',
        name: 'CAKE',
        marketSymbol: 'CAKE',
        icon: CAKEIcon,
        tokenOrigin: 'PancakeSwap',
        blockNumber: 12160319,
        token: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        decimals: 18
    },
    14: {
        sno: 14,
        address: '0x6022bA7e5A70E1bAA98d47a566F3495A26511b25',
        name: 'FRAX',
        marketSymbol: 'FRAX',
        icon: FRAXIcon,
        tokenOrigin: 'Frax',
        blockNumber: 12160319,
        token: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
        decimals: 18
   },  
    15: {
        sno: 15,
        address: '0x84a785E400c43D341D5cbCDC7f0dceb49Cae7d00',
        name: 'DOT',
        marketSymbol: 'DOT',
        icon: DOTIcon,
        tokenOrigin: 'Polkadot',
        blockNumber: 12160319,
        token: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
        decimals: 18
    },
    16: {
        sno: 16,
        address: '0x9B81d1df15fD9C07007d9a9b8fF75D40A5a80D94',
        name: 'ADA',
        marketSymbol: 'ADA',
        icon: ADAIcon,
        tokenOrigin: 'Cardano',
        blockNumber: 12160319,
        token: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
        decimals: 18
    },
    17: {
        sno: 17,
        address: '0xeb23D803Ec8D598662441807456620cF96eb0470',
        name: 'MATIC',
        marketSymbol: 'MATIC',
        icon: MATICIcon,
        tokenOrigin: 'Polygon',
        blockNumber: 12160319,
        token: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
        decimals: 18
    },

    18: {
        sno: 18,
        address: '0x45646b30c3Bb8c02bCfE10314308a8055E705ebF',
        name: 'SOL',
        marketSymbol: 'SOL',
        icon: SOLIcon,
        tokenOrigin: 'SOLANA',
        blockNumber: 12160319,
        token: '0x570a5d26f7765ecb712c0924e4de545b89fd43df',
        decimals: 18
    },
    19: {
        sno: 19,
        address: '0x88FD42E447d39C3259b53623f2536bd855e47C48',
        name: 'LUNA',
        marketSymbol: 'LUNA',
        icon: LUNAIcon,
        tokenOrigin: 'Terra',
        blockNumber: 12160319,
        token: '0x156ab3346823B651294766e23e6Cf87254d68962',
        decimals: 6
    },
    20: {
        sno: 20,
        address: '0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4',
        name: 'aUST',
        marketSymbol: 'aUST',
        icon: AUSTIcon,
        tokenOrigin: 'AnchorUST',
        blockNumber: 12160319,
        token: "0x8b04e56a8cd5f4d465b784ccf564899f30aaf88c",
        decimals: 6,
    },
    21: {
        sno: 21,
        address: '0x2a29572b771a8D8057A046fe80C45a77DBB6E804',
        name: 'AVAX',
        marketSymbol: 'AVAX',
        icon: AVAXIcon,
        tokenOrigin: 'Avalanche',
        blockNumber: 12160319,
        token: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
        decimals: 18
    },
    22: {
        sno: 22,
        address: '0x820cDE110D5f17b9F985FBb80f10D4f7158F130f',
        name: 'ATOM',
        marketSymbol: 'ATOM',
        icon: AtomIcon,
        tokenOrigin: 'Cosmos',
        blockNumber: 12160319,
        token: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
        decimals: 18
    },
    23: {
        sno: 23,
        address: '0x2aCAf66E67876d18CC5a27EB90Aa32b06Ab4785B',
        name: 'XRP',
        marketSymbol: 'XRP',
        icon: XRPIcon,
        tokenOrigin: 'XRP',
        blockNumber: 12160319,
        token: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
        decimals: 18
    },
    24: {
        sno: 24,
        address: '0x5e11eC8Ec1a57A93E5ddc8AD950cea60EAd9014b',
        name: 'LINK',
        marketSymbol: 'LINK',
        icon: LinkIcon,
        tokenOrigin: 'Chainlink',
        blockNumber: 12160319,
        token: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
        decimals: 18
    },
    25: {
        sno: 25,
        address: '0xb3A350f549113c60d074587dC40cD25c83d4D3b0',
        name: 'FTM',
        marketSymbol: 'FTM',
        icon: FTMIcon,
        tokenOrigin: 'Fantom',
        blockNumber: 12160319,
        token: '0xad29abb318791d579433d831ed122afeaf29dcfe',
        decimals: 18
    },
    // 26: {
    //     sno: 26,
    //     address: '0xAF7602Fb6A83f04886032467B487101B80ebccC0',
    //     name: 'whUST',
    //     marketSymbol: 'UST',
    //     icon: whustIcon,
    //     tokenOrigin: 'Terra',
    //     blockNumber: 12160319,
    //     token: '0x3d4350cd54aef9f9b2c29435e0fa809957b3f30a',
    //     decimals: 6
    // },
}

export const platformMetrics = {
    totalMarketSize: 0,
    userTotalSupplyBal: 0,
    userTotalBorrowBal: 0,
    userNetApyWithoutGamma: 0,
    userNetApyWithGamma: 0,
    userStakeRatio: 0,
    gammaPrice: 0,
    pendingGamma: 0,
}

export const pfTokenListRevised: any = {

    '0x24664791B015659fcb71aB2c9C0d56996462082F': {
        sno: 0,
        address: '0x24664791B015659fcb71aB2c9C0d56996462082F',
        name: 'BNB',
        marketSymbol: 'BNB',
        icon: BNBIcon,
        tokenOrigin: 'Binance Coin',
        blockNumber: 12078744,
        token: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0xF701A48e5C751A213b7c540F84B64b5A6109962E': {
        sno: 1,
        address: '0xF701A48e5C751A213b7c540F84B64b5A6109962E',
        name: 'GAMMA (OLD)',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12078110,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x928fa017eBf781947102690c9b176996B2E00f22': {
        sno: 2,
        address: '0x928fa017eBf781947102690c9b176996B2E00f22',
        name: 'BUSD',
        marketSymbol: 'BUSD',
        icon: BUSDIcon,
        tokenOrigin: 'Binance USD',
        blockNumber: 12127442,
        token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0xB3A4ce0654524dCF4B5165cee280EbE69a6E8133': {
        sno: 3,
        address: '0xB3A4ce0654524dCF4B5165cee280EbE69a6E8133',
        name: 'AQUA (OLD)',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12135891,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0xa5ae8459e710F95ca0C93d73F63a66d9996F1ACE': {
        sno: 4,
        address: '0xa5ae8459e710F95ca0C93d73F63a66d9996F1ACE',
        name: 'UST',
        marketSymbol: 'UST',
        icon: USTIcon,
        tokenOrigin: 'Terra USD',
        blockNumber: 12159650,
        token: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0xcfa5b884689dc09e4503e84f7877d3A583fcceef': {
        sno: 5,
        address: '0xcfa5b884689dc09e4503e84f7877d3A583fcceef',
        name: 'BTCB',
        marketSymbol: 'BTCB',
        icon: BTCBIcon,
        tokenOrigin: 'Bitcoin',
        blockNumber: 12159960,
        token: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x66fD9D390De6172691EC0ddF0ac7F231c1f9a434': {
        sno: 6,
        address: '0x66fD9D390De6172691EC0ddF0ac7F231c1f9a434',
        name: 'ETH',
        marketSymbol: 'ETH',
        icon: ETHIcon,
        tokenOrigin: 'Ethereum',
        blockNumber: 12160319,
        token: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x854a534cEFAf8fD20A70C9DC976C4f65324D7B42': {
        sno: 7,
        address: '0x854a534cEFAf8fD20A70C9DC976C4f65324D7B42',
        name: 'USDC',
        marketSymbol: 'USDC',
        icon: USDCIcon,
        tokenOrigin: 'USD Coin',
        blockNumber: 12160319,
        token: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x4c2bddc208B58534EdDC1fba7B2828CAb70797b5': {
        sno: 8,
        address: '0x4c2bddc208B58534EdDC1fba7B2828CAb70797b5',
        name: 'USDT',
        marketSymbol: 'USDT',
        icon: USDTIcon,
        tokenOrigin: 'Tether',
        blockNumber: 12160319,
        token: '0x55d398326f99059ff775485246999027b3197955',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x8B2f098411Ce4B32c9D2110FeF257Cf01D006bA5': {
        sno: 9,
        address: '0x8B2f098411Ce4B32c9D2110FeF257Cf01D006bA5',
        name: 'DAI',
        marketSymbol: 'DAI',
        icon: DAIIcon,
        tokenOrigin: 'DAI',
        blockNumber: 12160319,
        token: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442': {
        sno: 10,
        address: '0x0c6dd143F4b86567d6c21E8ccfD0300f00896442',
        name: 'GAMMA',
        marketSymbol: 'GAMMA',
        icon: GAMMAIcon,
        tokenOrigin: 'GAMMA',
        blockNumber: 12160319,
        token: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0xb7eD4A5AF620B52022fb26035C565277035d4FD7': {
        sno: 11,
        address: '0xb7eD4A5AF620B52022fb26035C565277035d4FD7',
        name: 'AQUA',
        marketSymbol: 'AQUA',
        icon: AQUAIcon,
        tokenOrigin: 'AQUA',
        blockNumber: 12160319,
        token: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x939e98eeC2Aa37baB8c5d94BC90C6E00911d27B4': {
        sno: 12,
        address: '0x939e98eeC2Aa37baB8c5d94BC90C6E00911d27B4',
        name: 'TUSD',
        marketSymbol: 'TUSD',
        icon: TUSDIcon,
        tokenOrigin: 'TrueUSD',
        blockNumber: 12160319,
        token: '0x14016e85a25aeb13065688cafb43044c2ef86784',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x7a9cB040e4DdD5271C36A8C8DeAdD3BA1b5A5949': {
        sno: 13,
        address: '0x7a9cB040e4DdD5271C36A8C8DeAdD3BA1b5A5949',
        name: 'CAKE',
        marketSymbol: 'CAKE',
        icon: CAKEIcon,
        tokenOrigin: 'PancakeSwap',
        blockNumber: 12160319,
        token: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x6022bA7e5A70E1bAA98d47a566F3495A26511b25': {
        sno: 14,
        address: '0x6022bA7e5A70E1bAA98d47a566F3495A26511b25',
        name: 'FRAX',
        marketSymbol: 'FRAX',
        icon: FRAXIcon,
        tokenOrigin: 'Frax',
        blockNumber: 12160319,
        token: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x84a785E400c43D341D5cbCDC7f0dceb49Cae7d00': {
        sno: 15,
        address: '0x84a785E400c43D341D5cbCDC7f0dceb49Cae7d00',
        name: 'DOT',
        marketSymbol: 'DOT',
        icon: DOTIcon,
        tokenOrigin: 'Polkadot',
        blockNumber: 12160319,
        token: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x9B81d1df15fD9C07007d9a9b8fF75D40A5a80D94': {
        sno: 16,
        address: '0x9B81d1df15fD9C07007d9a9b8fF75D40A5a80D94',
        name: 'ADA',
        marketSymbol: 'ADA',
        icon: ADAIcon,
        tokenOrigin: 'Cardano',
        blockNumber: 12160319,
        token: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0xeb23D803Ec8D598662441807456620cF96eb0470': {
        sno: 17,
        address: '0xeb23D803Ec8D598662441807456620cF96eb0470',
        name: 'MATIC',
        marketSymbol: 'MATIC',
        icon: MATICIcon,
        tokenOrigin: 'Polygon',
        blockNumber: 12160319,
        token: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x45646b30c3Bb8c02bCfE10314308a8055E705ebF': {
        sno: 18,
        address: '0x45646b30c3Bb8c02bCfE10314308a8055E705ebF',
        name: 'SOL',
        marketSymbol: 'SOL',
        icon: SOLIcon,
        tokenOrigin: 'SOLANA',
        blockNumber: 12160319,
        token: '0x570a5d26f7765ecb712c0924e4de545b89fd43df',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x88FD42E447d39C3259b53623f2536bd855e47C48': {
        sno: 19,
        address: '0x88FD42E447d39C3259b53623f2536bd855e47C48',
        name: 'LUNA',
        marketSymbol: 'LUNA',
        icon: LUNAIcon,
        tokenOrigin: 'Terra',
        blockNumber: 12160319,
        token: '0x156ab3346823B651294766e23e6Cf87254d68962',
        decimals: 6,
        underlyingDecimals: 6,
    },
    '0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4': {
        sno: 20,
        address: '0x4Bdde0904aBB1695775Cc79c69Dd0d61507232e4',
        name: 'aUST',
        marketSymbol: 'aUST',
        icon: AUSTIcon,
        tokenOrigin: 'AnchorUST',
        blockNumber: 12160319,
        token: "0x8b04e56a8cd5f4d465b784ccf564899f30aaf88c",
        decimals: 6,
        underlyingDecimals: 6,
    },
    '0x2a29572b771a8D8057A046fe80C45a77DBB6E804': {
        sno: 21,
        address: '0x2a29572b771a8D8057A046fe80C45a77DBB6E804',
        name: 'AVAX',
        marketSymbol: 'AVAX',
        icon: AVAXIcon,
        tokenOrigin: 'Avalanche',
        blockNumber: 12160319,
        token: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
        decimals: 18,
        underlyingDecimals: 18
     },
    '0x820cDE110D5f17b9F985FBb80f10D4f7158F130f': {
        sno: 22,
        address: '0x820cDE110D5f17b9F985FBb80f10D4f7158F130f',
        name: 'ATOM',
        marketSymbol: 'ATOM',
        icon: AtomIcon,
        tokenOrigin: 'Cosmos',
        blockNumber: 12160319,
        token: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x2aCAf66E67876d18CC5a27EB90Aa32b06Ab4785B': {
        sno: 23,
        address: '0x2aCAf66E67876d18CC5a27EB90Aa32b06Ab4785B',
        name: 'XRP',
        marketSymbol: 'XRP',
        icon: XRPIcon,
        tokenOrigin: 'XRP',
        blockNumber: 12160319,
        token: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0x5e11eC8Ec1a57A93E5ddc8AD950cea60EAd9014b': {
        sno: 24,
        address: '0x5e11eC8Ec1a57A93E5ddc8AD950cea60EAd9014b',
        name: 'LINK',
        marketSymbol: 'LINK',
        icon: LinkIcon,
        tokenOrigin: 'Chainlink',
        blockNumber: 12160319,
        token: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
        decimals: 18,
        underlyingDecimals: 18
    },
    '0xb3A350f549113c60d074587dC40cD25c83d4D3b0': {
        sno: 25,
        address: '0xb3A350f549113c60d074587dC40cD25c83d4D3b0',
        name: 'FTM',
        marketSymbol: 'FTM',
        icon: FTMIcon,
        tokenOrigin: 'Fantom',
        blockNumber: 12160319,
        token: '0xad29abb318791d579433d831ed122afeaf29dcfe',
        decimals: 18,
        underlyingDecimals: 18
    },
    // '0xAF7602Fb6A83f04886032467B487101B80ebccC0': {
    //     sno: 26,
    //     address: '0xAF7602Fb6A83f04886032467B487101B80ebccC0',
    //     name: 'whUST',
    //     marketSymbol: 'UST',
    //     icon: whustIcon,
    //     tokenOrigin: 'Terra',
    //     blockNumber: 12160319,
    //     token: '0x3d4350cd54aef9f9b2c29435e0fa809957b3f30a',
    //     decimals: 6
    // },
}
