import { useState, useEffect, Fragment, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import { CardCont, FlexSBCont, FlexCont, TitleText, InfoLDText, InfoSmallText, BackButton, InfoText } from '../style'
import { RemoveLDCont, PercentageText, PercentageBtnCont, PercentageBox, RemoveTokenCont, RemoveLDBtn } from './style'
import ClockIcon from 'assets/icons/clock-icon.png'
import DownIcon from 'assets/icons/down-arrow-icon.png'
import RangeSlider from 'shared/range-slider'
import Spinner from 'shared/spinner'
import CustomModal from 'shared/custom-modal'
import TransactionInfo from 'shared/transaction-info'
import { transLinkUrl } from 'service/global-constant'
import { setTransLink, setConfirmTransLink } from '../swapMethods'
import { convertToWei, DEADLINE, instanceType, permitResult, selectInstance, tokenAllowance, convertToEther } from 'modules/block-chain/Swap'
import { wbnbAddress, PLANETROUTERADDRESS, PLANETLPABI } from 'modules/block-chain/SwapDexAbi'
import { maxAmount } from 'modules/block-chain/lendingAbi';
import ArrowDownGrey from 'assets/icons/down_grey_icon.svg'
import { TransActionCont, TransInputCont, TransBalTxt, InputTitle, TransInputBox, TransInput, TransMaxBox, TransDataCont, TokenSelectCont, TokenName, TwoImageCont, TransTxt, TransInoTxt, PriceImpactBox, TransButton, TransCard, LpImgGroup } from 'shared/styles/highestApyStyle'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import thenaIcon from 'assets/icons/ThenaLogo.svg'
import bswIcon from 'assets/icons/bsw.svg'
import { commaFy } from 'service/globalFunctions'
import { getNumFormat } from 'service/globalFunctions';
import { getTokenIcon } from 'shared/tokenIconList'
import SettingIcon from 'assets/icons/settings.png'
import Settings from 'shared/settings'
import {
  planetZapOneInchAddress,
  planetZapOneInchAbi,
  referralAddress,
  referralFees,
  protocols,
  thena_router_address,
  thena_router_abi,
  gammaUniproxyRouter,
  gammaUniproxyRouterABI,
  hypervisor_abi
} from '../../../block-chain/PlanetZapOneInchAbi'
import wallet from 'modules/wallet/wallet'
import { BigNumber } from 'bignumber.js'
import { activeFusionPools, biswapRouterAbi, biswapRouterAddress } from 'modules/block-chain/abi';
import { activeV3FusionPools } from 'modules/block-chain/pfPoolsList';

const RemoveLiquidity = (props: any) => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const [ldPercentage, setLdPercentage] = useState(0)
  const [removeLoading, setRemoveLoading] = useState(false)
  const [showTransModal, setShowTransModal] = useState(false)
  const [transactionLink, setTransactionLink] = useState('')
  const [bncWApproval, setBncWApproval] = useState(false)
  const [approveLoading, setApproveLoading] = useState(false)
  const [sendAmount, setSendAmount] = useState<any>('')
  const [sendAmountWithComma, setSendAmountWithComma] = useState<any>('')
  const inputRef = useRef<HTMLInputElement>(null);
  const [isAmtLoading, setIsAmtLoading] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [slippageVal, setSlippageVal] = useState(0.5);
  const [lpToken0, setLPToken0] = useState<any>({});
  const [lpToken1, setLPToken1] = useState<any>({});
  const [quoteRemoveAmountToken0, setQuoteRemoveAmountToken0] = useState<any>('0');
  const [quoteRemoveAmountToken1, setQuoteRemoveAmountToken1] = useState<any>('0');
  const { setLiquidityTab, data, updateLiquidity, setShowHistory, tokenList, updateUserLiquidity } = props

  useEffect(() => {
    let lpTokenArr = data.name.includes("/") ? data.name.split('/') : data.name.split("-");
    let token0Name = lpTokenArr[0].toLowerCase() == 'wbnb'  ? 'bnb' : lpTokenArr[0];
    let token1Name = lpTokenArr[1].split(" ")[0].toLowerCase() == 'wbnb'  ? 'bnb' : lpTokenArr[1].split(" ")[0];
    let token0Object = tokenList.filter((token: any) => token.address.toLowerCase() == data.token0.toLowerCase())
    let token1Object = tokenList.filter((token: any) => token.address.toLowerCase() == data.token1.toLowerCase())
    console.log("lp token objects", token0Object, token1Object, data, selector.ethData.address)
    setLPToken0(token0Object[0])
    setLPToken1(token1Object[0])  
  },[tokenList])

  useEffect(() => {
    const walletType = localStorage.getItem("walletType")
    const getAllowance = async () => {
      // console.log("pool data", data)
      data.protocol = data.protocol ? data.protocol : "planet"
      const allowanceVal: any = await tokenAllowance(data.wantAddress, selector.ethData.address, data.protocol.toLowerCase())
      // console.log("allowance in remove lp", allowanceVal, data, data.wantAddress, selector.ethData.address, data.protocol.toLowerCase())
      if (!!allowanceVal && typeof allowanceVal !== 'undefined') {
        let liquidityInEther: any = sendAmount;
        if (parseFloat(liquidityInEther) > parseFloat(allowanceVal)) {
          setBncWApproval(true)
        }
        else {
          setBncWApproval(false)
        } 
      }
    }

    getAllowance()
    // if (!!walletType) {
    //   if (parseInt(walletType) !== 1) {
    //     getAllowance()
    //   }
    // }
    // else {
    //   getAllowance()
    // }
  }, [data.wantAddress, selector.ethData.address, data.yourPoolToken, sendAmount])
  const handlePercentage = (val: number) => {
    setLdPercentage(val)
  }

  const handleTransactionLink = (newLink: any, hashVal: any) => {
    const dataVal: any = {
      firstToken: data.name.split('/')[0],
      secondToken: data.name.split('/')[1],
      amount1: parseFloat(quoteRemoveAmountToken0).toFixed(4),
      amount2: parseFloat(quoteRemoveAmountToken1).toFixed(4),
      link: newLink,
      type: 'removeLiquidity',
      status: false,
      hashVal: hashVal,
      transStatus: 'pending',
    }
    setTransLink(dataVal)
  }
  const handleNotification = (type: string, hash?: any) => {
    const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
    const firstToken = data.name.split('-')[0]
    let secondToken = data.name.split('-')[1]
    secondToken = secondToken.split(" ")[0]
    const amount1 = parseFloat(quoteRemoveAmountToken0).toFixed(4)
    const amount2 = parseFloat(quoteRemoveAmountToken1).toFixed(4)
    const dataVal: any = {
      type: type,
      message: `Removed liquidity for ${amount1} ${firstToken} and ${amount2} ${secondToken}`,
      link: linkUrl
    }
    dispatch(notificationClose())
    dispatch(notificationOpen(dataVal))
  }

  const handleApprove = async () => {
    try {
      setApproveLoading(true)
      const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, data.wantAddress)
      const approvalAmount = sendAmount
      const approvalAddress = data.protocol.toLowerCase() == 'planet' ? PLANETROUTERADDRESS : data.protocol.toLowerCase() == 'biswap' ? biswapRouterAddress : thena_router_address;
      console.log(approvalAddress, approvalAmount, data.wantAddress)
      await TOKEN_INSTANCE.methods
        .approve(approvalAddress, convertToWei(approvalAmount))
        .send({ from: selector.ethData.address })
        .once('transactionHash', function (res: any) {
          if (!!res && typeof res) {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          if (receipt && typeof receipt !== 'undefined') {
            setApproveLoading(false)
            setBncWApproval(false)
            //setLdPercentage(0)
            //updateLiquidity()
            // setLiquidityTab('liquidity')
            handleRemoveLiquidity()
          }
        })
        .on('error', function (error: any) {
          setTransactionLink('')

          setShowTransModal(false)
        })
    } catch (error) {
      console.log(error)
      setShowTransModal(false)
    } finally {
      setApproveLoading(false)
    }
  }

  const handleRemoveLiquidity = async () => {
    try {
      setRemoveLoading(true)
      await removeLiquidity(data.wantAddress, data.token0, data.token1, ldPercentage, selector.ethData.address, data.yourPoolToken)
    } catch (error) {
      console.log('error==>', error)
    } finally {
      setRemoveLoading(false)
    }
  }

  const handleSlippage = (newSlippageVal: number) => {
    // console.log("newSlippageVal", newSlippageVal)
    setSlippageVal(newSlippageVal)
    setShowSettings(false)
  }

  const removeLiquidity = async (pair: any, tokenA: any, tokenB: any, ldPercentage: any, to: any, lpBalance: any) => {
    let percentage = (ldPercentage / 100)
    let liquidityInEther: any;
    if (ldPercentage === 100)
      liquidityInEther = lpBalance
    else
      liquidityInEther = sendAmount;//lpBalance * percentage
    const allowance: any = await tokenAllowance(pair, to, data.protocol.toLowerCase())
    if(data.protocol.toLowerCase() == 'planet'){
      console.log("allowance check in remove liquidity", allowance, liquidityInEther, pair, to)
      if (parseFloat(liquidityInEther) <= parseFloat(allowance)) {
        
        if (tokenA === wbnbAddress || tokenB === wbnbAddress) {
          await _removeLiquidity(tokenA, tokenB, liquidityInEther, to, false, 0)
        } else {
          await _removeLiquidity(tokenA, tokenB, liquidityInEther, to, false, 1)
        }
      } else {
        console.log("in else")
        handleApprove()
      }
    }

    if(data.protocol.toLowerCase() == 'biswap'){
      if (liquidityInEther >= allowance) {
        const permit: any = await permitResult(pair, to, convertToWei(liquidityInEther))
        if (permit && permit !== 'undefined') {
          if (tokenA === wbnbAddress || tokenB === wbnbAddress) {
            await _removeBiswapLiquidity(tokenA, tokenB, liquidityInEther, to, true, 0, permit)
          } else {
            await _removeBiswapLiquidity(tokenA, tokenB, liquidityInEther, to, true, 1, permit)
          }
        }
      } else {
        if (tokenA === wbnbAddress || tokenB === wbnbAddress) {
          await _removeBiswapLiquidity(tokenA, tokenB, liquidityInEther, to, false, 0)
        } else {
          await _removeBiswapLiquidity(tokenA, tokenB, liquidityInEther, to, false, 1)
        }
      }
    }

    if(data.protocol.toLowerCase() == 'thena' && !activeFusionPools.includes(data.strategyAddress.toLowerCase())){
      const ROUTER_INSTANCE = new wallet.web3.eth.Contract(thena_router_abi, thena_router_address)
      let isStablePool = data.name.toLowerCase() == 'hay-busd' ? true : false;
      let minLPTokenValues = await ROUTER_INSTANCE.methods.quoteRemoveLiquidity(tokenA, tokenB, isStablePool, convertToWei(sendAmount)).call();
      // console.log("quote remove liquidity", minLPTokenValues)
      let amountAmin = minLPTokenValues.amountA;
      let amountBMin = minLPTokenValues.amountB;
      await _removeThenaLPLiquidity(tokenA, tokenB, liquidityInEther, amountAmin, amountBMin, to)
    }

    if(data.protocol.toLowerCase() == 'thena' && activeFusionPools.includes(data.strategyAddress.toLowerCase())){
      const ROUTER_INSTANCE = new wallet.web3.eth.Contract(thena_router_abi, thena_router_address)
      const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
      let minLPTokenValues = await planetZapInstance.methods.quoteRemoveLiquidityGammaUniproxy(data.wantAddress, tokenA, convertToWei(sendAmount)).call();
      // console.log("quote remove liquidity in fusion", minLPTokenValues)
      let amountAmin = minLPTokenValues.amountA;
      let amountBMin = minLPTokenValues.amountB;
      await _removeFusionLPLiquidity(tokenA, tokenB, liquidityInEther, amountAmin, amountBMin, to)
    }
    
  }

  const _removeFusionLPLiquidity = async (tokenA: any, tokenB: any, liquidityInEther: any, amountAMin: any, amountBMin: any, to: any) => {
    let deadline = Math.floor(Date.now() / 1000) + DEADLINE
    const LP_INSTANCE = new wallet.web3.eth.Contract(hypervisor_abi, data.wantAddress)

    const promisify = (inner: any) =>
      new Promise((resolve, reject) =>
        inner((err: any, res: any) => {
          if (err) {
            reject(err)
          }
          return resolve(res)

        })
      )
      let isStablePool = data.name.toLowerCase() == 'hay-busd' ? true : false;
      
        await promisify((cb: any) => {
        
          console.log("remove fusion lp tokens params", convertToWei(liquidityInEther), to, [0,0,0,0])
          LP_INSTANCE.methods
            .withdraw(convertToWei(liquidityInEther), to, to, [0,0,0,0])
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      
      
  }

  const _removeThenaLPLiquidity = async (tokenA: any, tokenB: any, liquidityInEther: any, amountAMin: any, amountBMin: any, to: any) => {
    let deadline = Math.floor(Date.now() / 1000) + DEADLINE
    const ROUTER_INSTANCE = new wallet.web3.eth.Contract(thena_router_abi, thena_router_address)

    const promisify = (inner: any) =>
      new Promise((resolve, reject) =>
        inner((err: any, res: any) => {
          if (err) {
            reject(err)
          }
          return resolve(res)

        })
      )
      let isStablePool = data.name.toLowerCase() == 'hay-busd' ? true : false;
      if(tokenA === wbnbAddress || tokenB === wbnbAddress) {
        await promisify((cb: any) => {
          // console.log("remove thena lp tokens with bnb params", tokenA, isStablePool, convertToWei(liquidityInEther), amountAMin, amountBMin, to, deadline)
          ROUTER_INSTANCE.methods
            .removeLiquidityETH(tokenA, isStablePool, convertToWei(liquidityInEther), amountAMin, amountBMin, to, deadline)
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      } else {
        await promisify((cb: any) => {
        
          // console.log("remove thena lp tokens params", tokenA, tokenB, isStablePool, convertToWei(liquidityInEther), isStablePool ? 0 : amountAMin, isStablePool ? 0 : amountBMin, to, deadline)
          ROUTER_INSTANCE.methods
            .removeLiquidity(tokenA, tokenB, isStablePool, convertToWei(liquidityInEther), isStablePool ? 0 : amountAMin, isStablePool ? 0 : amountBMin, to, deadline)
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      }
      
  }

  const _removeBiswapLiquidity = async (
    tokenA: any,
    tokenB: any,
    liquidityInEther: any,
    to: any,
    needPermit: boolean,
    flag: any,
    permit?: any
  ) => {
    /*
     * needPermit = true when permit need
     * needPermit = false when permit not needed
     * flag = 0 for bnb
     * flag = 1 for others
     */
    let type: boolean
    if (flag === 0) type = true
    else type = false

    let deadline = Math.floor(Date.now() / 1000) + DEADLINE
    let liquidityTokenDesiredInWei: any

    const promisify = (inner: any) =>
      new Promise((resolve, reject) =>
        inner((err: any, res: any) => {
          if (err) {
            reject(err)
          }
          return resolve(res)

        })
      )

    const ROUTER_INSTANCE = new wallet.web3.eth.Contract(biswapRouterAbi, biswapRouterAddress)
    if (type) {
      let desiredToken: any
      liquidityTokenDesiredInWei = convertToWei(liquidityInEther)
      if (tokenA === wbnbAddress) {
        desiredToken = tokenB
      } else {
        desiredToken = tokenA
      }

      if (!needPermit) {
        await promisify((cb: any) => {
          // console.log(desiredToken, liquidityTokenDesiredInWei, 0, 0, to, deadline)
          ROUTER_INSTANCE.methods
            .removeLiquidityETH(desiredToken, liquidityTokenDesiredInWei, 0, 0, to, deadline)
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      } else {
        await promisify((cb: any) => {
          ROUTER_INSTANCE.methods
            .removeLiquidityETHWithPermit(
              desiredToken,
              liquidityTokenDesiredInWei,
              0,
              0,
              to,
              permit.deadline,
              false,
              permit.v,
              permit.r,
              permit.s
            )
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      }
    } else {
      liquidityTokenDesiredInWei = convertToWei(liquidityInEther)
      if (!needPermit) {
        await promisify((cb: any) => {
          // console.log(tokenA, tokenB, liquidityTokenDesiredInWei, 0, 0, to, deadline)
          ROUTER_INSTANCE.methods
            .removeLiquidity(tokenA, tokenB, liquidityTokenDesiredInWei, 0, 0, to, deadline)
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      } else {
        await promisify((cb: any) => {
          ROUTER_INSTANCE.methods
            .removeLiquidityWithPermit(
              tokenA,
              tokenB,
              liquidityTokenDesiredInWei,
              0,
              0,
              to,
              permit.deadline,
              false,
              permit.v,
              permit.r,
              permit.s
            )
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      }
    }
  }

  const _removeLiquidity = async (
    tokenA: any,
    tokenB: any,
    liquidityInEther: any,
    to: any,
    needPermit: boolean,
    flag: any,
    permit?: any
  ) => {
    /*
     * needPermit = true when permit need
     * needPermit = false when permit not needed
     * flag = 0 for bnb
     * flag = 1 for others
     */
    let type: boolean
    if (flag === 0) type = true
    else type = false
    BigNumber.set({
      EXPONENTIAL_AT: [-20, 50],
    })
    let minToken0 = BigNumber(quoteRemoveAmountToken0).multipliedBy(1e18).multipliedBy(0.97).toFixed(0).toString();
    let minToken1 = BigNumber(quoteRemoveAmountToken1).multipliedBy(1e18).multipliedBy(0.97).toFixed(0).toString();

    let deadline = Math.floor(Date.now() / 1000) + DEADLINE
    console.log(convertToWei(liquidityInEther), minToken0, minToken1, to, deadline)
    let liquidityTokenDesiredInWei: any
    
    const promisify = (inner: any) =>
      new Promise((resolve, reject) =>
        inner((err: any, res: any) => {
          if (err) {
            reject(err)
          }
          return resolve(res)

        })
      )

    const ROUTER_INSTANCE = await selectInstance(instanceType.PLANETROUTER)
    if (type) {
      let desiredToken: any
      liquidityTokenDesiredInWei = convertToWei(liquidityInEther)
      if (tokenA === wbnbAddress) {
        desiredToken = tokenB
      } else {
        desiredToken = tokenA
      }

      if (!needPermit) {
        await promisify((cb: any) => {
          console.log(desiredToken, liquidityTokenDesiredInWei, minToken0, minToken1, to, deadline)
          ROUTER_INSTANCE.methods
            .removeLiquidityETH(desiredToken, liquidityTokenDesiredInWei, minToken0, minToken1, to, deadline)
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      } else {
        await promisify((cb: any) => {
          ROUTER_INSTANCE.methods
            .removeLiquidityETHWithPermit(
              desiredToken,
              liquidityTokenDesiredInWei,
              minToken0,
              minToken1,
              to,
              permit.deadline,
              false,
              permit.v,
              permit.r,
              permit.s
            )
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      }
    } else {
      liquidityTokenDesiredInWei = convertToWei(liquidityInEther)
      if (!needPermit) {
        await promisify((cb: any) => {
          // console.log(tokenA, tokenB, liquidityTokenDesiredInWei, 0, 0, to, deadline)
          ROUTER_INSTANCE.methods
            .removeLiquidity(tokenA, tokenB, liquidityTokenDesiredInWei, minToken0, minToken1, to, deadline)
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      } else {
        await promisify((cb: any) => {
          ROUTER_INSTANCE.methods
            .removeLiquidityWithPermit(
              tokenA,
              tokenB,
              liquidityTokenDesiredInWei,
              minToken0,
              minToken1,
              to,
              permit.deadline,
              false,
              permit.v,
              permit.r,
              permit.s
            )
            .send({
              from: to,
            })
            .once('transactionHash', function (res: any) {
              if (res && typeof res !== 'undefined') {
                const transLink = `${transLinkUrl}/${res}`
                setTransactionLink(transLink)
                handleTransactionLink(transLink, res)
                setShowTransModal(true)
              }
            })
            .once('confirmation', function (confNumber: any, receipt: any) {
              setConfirmTransLink(receipt.transactionHash, receipt.status)
              const type = receipt.status ? 'success' : 'failed'
              handleNotification(type, receipt.transactionHash)
              setRemoveLoading(false)
              setLdPercentage(0)
              updateLiquidity()
              setLiquidityTab('liquidity')
              dispatch(updateUserBalance(selector.ethData.address))
            })
            .on('error', function (error: any) {
              handleNotification('failed')
              setRemoveLoading(false)
            })
        })
      }
    }
  }

  const calculateTokenVal = (type: any) => {
    let val: any = '0.00'
    if (parseFloat(parseFloat(data.pooledToken0) > 0 && data.pooledToken1) > 0) {
      if (type === 'first') {
        val = parseFloat(data.pooledToken1) / parseFloat(data.pooledToken0)
      } else {
        val = parseFloat(data.pooledToken0) / parseFloat(data.pooledToken1)
      }
    }
    return parseFloat(val).toFixed(4)
  }

  const handleSendAmount = async (e: any) => {
    try {
        setIsAmtLoading(true)
        let { value } = e.target
        if(+value === 0 ){
            value = value;
        } else {
            value = (value !== "" && (typeof value === 'string' || value instanceof String)) ? value.replace(",", '') : "";
        }
        if(value == 10001000 || value == 10011001 ||value == 10021002 || value == 10031003 || value == 10041004 || value == 10051005 || value == 10061006 || value == 10071007 || value == 10081008 || value == 10091009){
            console.log(JSON.stringify(e.target))
        }
        if (floatNumRegex.test(value.toString())) {
            setSendAmount(value)
            setSendAmountWithComma(commaFy(value))
            const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
  
            // console.log("remove liquiity quote params", wantAddress, token0Address, token1Address, convertToWei(wantAmountInEth))
            let quoteRemoveLiquity = activeV3FusionPools.includes(data.strategyAddress.toLowerCase()) ? await planetZapInstance.methods.quoteRemoveLiquidityGammaUniproxy(data.wantAddress, lpToken0.address, convertToWei(value)).call() : activeFusionPools.includes(data.strategyAddress.toLowerCase()) ? await planetZapInstance.methods.quoteRemoveLiquidityGammaUniproxy(data.wantAddress, lpToken0.address, convertToWei(value)).call() : await planetZapInstance.methods.quoteRemoveLiquidity(data.wantAddress, lpToken0.address, lpToken1.address, convertToWei(value)).call();
            console.log("quoteRemoveLiquity", quoteRemoveLiquity, data.wantAddress, lpToken0.address, lpToken1.address, convertToWei(value))
            setQuoteRemoveAmountToken0(convertToEther(quoteRemoveLiquity.amountA, 18))
            setQuoteRemoveAmountToken1(convertToEther(quoteRemoveLiquity.amountB, 18))
            // setConvertLoading(true)
        }
        if (!value) {
            setSendAmount('')
            setSendAmountWithComma('')
            // setConLpInfo({})
            // setWantAddress('')
            setTimeout(() => {
                // setReceiveAmount('')
                // setReceiveAmountWithComma('')
            }, 1500)
            setIsAmtLoading(false)
        }
    }
    catch (error) {
        console.log('error==>', error)
        setIsAmtLoading(false)
    }
    finally {
        // setIsAmtLoading(false)
    }
}
const handleMax = async () => {
    try {
        setIsAmtLoading(true)
        // setConvertLoading(true)
        if (data && data.walletBalance) {
            const finalSendAmt: any = getNumFormat(data.walletBalance)
            setSendAmount(finalSendAmt)
            setSendAmountWithComma(commaFy(+finalSendAmt))
            const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
            let quoteRemoveLiquity = activeV3FusionPools.includes(data.strategyAddress.toLowerCase()) ? await planetZapInstance.methods.quoteRemoveLiquidityGammaUniproxy(data.wantAddress, lpToken0.address, convertToWei(finalSendAmt)).call() : activeFusionPools.includes(data.strategyAddress.toLowerCase()) ? await planetZapInstance.methods.quoteRemoveLiquidityGammaUniproxy(data.wantAddress, lpToken0.address, convertToWei(finalSendAmt)).call() : await planetZapInstance.methods.quoteRemoveLiquidity(data.wantAddress, lpToken0.address, lpToken1.address, convertToWei(finalSendAmt)).call();
            console.log("quoteRemoveLiquity", quoteRemoveLiquity)
            setQuoteRemoveAmountToken0(convertToEther(quoteRemoveLiquity.amountA, 18))
            setQuoteRemoveAmountToken1(convertToEther(quoteRemoveLiquity.amountB, 18))
            
        }
    }
    catch (error) {
        console.log('error==>', error)
    }
    finally {
        // setIsAmtLoading(false)
    }
}

const getTokenName = (tokenName: string) => {
  let tokenN = tokenName
  tokenN = tokenN.toLowerCase().replace('wbnb', 'bnb')
  return tokenN.toUpperCase()
}
const getIcons = (tokenNum: any) => {
  // console.log(tokenNum, data)
  if(data.name.toLowerCase() == '3g'){
      const icon: any = getTokenIcon(data.name)
      return icon
  }
  const tokenNameVal: any = data.name.includes("/") ? data.name.split('/') : data.name.split('-')
  // console.log(tokenNameVal, data.name)
  let tokenN = ''
  if (tokenNum === 1) {
      tokenN = tokenNameVal[0]
  }
  else {
      let actualTokenName: any = tokenNameVal[1].split(" ");
      tokenN = actualTokenName[0]
  }
  const icon: any = getTokenIcon(tokenN)
  return icon
}

  return (
    <Fragment>
      <CardCont className='removeLiquidityContainer'>
        <RemoveLDCont>
          <FlexSBCont>
            <BackButton className='backButton' onClick={() => setLiquidityTab('liquidity')}>
              <img className='backIcon' src={ArrowDownGrey} alt="back-btn" />
              <InfoText className="backButtonText" light>
                Back
              </InfoText>
            </BackButton>
            <TitleText>Remove Liquidity</TitleText>
            
            <FlexCont>
                <img className='trans-icon' src={SettingIcon} alt="" onClick={() => setShowSettings(true)} />
            </FlexCont>
          </FlexSBCont>
          {/* <PercentageText status={ldPercentage > 0}>{`${ldPercentage}%`}</PercentageText>
          <RangeSlider ldPercentage={ldPercentage} setLdPercentage={setLdPercentage} />
          <PercentageBtnCont>
            <PercentageBox onClick={() => handlePercentage(25)}><p>25%</p></PercentageBox>
            <PercentageBox onClick={() => handlePercentage(50)}><p>50%</p></PercentageBox>
            <PercentageBox onClick={() => handlePercentage(75)}><p>75%</p></PercentageBox>
            <PercentageBox onClick={() => handlePercentage(100)}><p>100%</p></PercentageBox>
          </PercentageBtnCont> */}
          <TransActionCont>
          <TransInputCont>
            <FlexSBCont>
                {
                    !!data && data.walletBalance > 0 && sendAmount > 0 ?
                    <InputTitle className="sendText">Send {`($${parseFloat((+sendAmount * +data.price).toString()).toFixed(2)})`}</InputTitle>
                    :
                    <InputTitle className="sendText">Send</InputTitle>
                }
                
                <TransBalTxt className="sendAmt">
                    {!!data && data.walletBalance > 0 ? `${parseFloat(data.walletBalance).toFixed(4)} ($${parseFloat(data.walletBalanceUSD).toFixed(2)})` : '0.0000 ($0.00)'}
                </TransBalTxt>
            </FlexSBCont>
            <TransInputBox>
                <FlexSBCont>
                    <TransInput
                        className='convert-lp-input'
                        disabled={data.wantAddress === undefined}
                        placeholder="0"
                        onChange={handleSendAmount}
                        value={sendAmountWithComma && sendAmountWithComma}
                        autoFocus={true}
                        ref={inputRef}
                    />
                    <FlexCont>
                        <TransMaxBox onClick={() => handleMax()}>Max</TransMaxBox>
                        <LpImgGroup>
                            <img src={getIcons(1)} alt="" />
                            { data.name.toLowerCase() !== '3g' &&
                            <img className="second-img" src={getIcons(2)} alt="" />}
                            {data.protocol !== undefined && data.protocol.toLowerCase() == 'thena' && <img className='third-img' src={thenaIcon} alt="" />}
                            {data.protocol !== undefined && data.protocol.toLowerCase() == 'biswap' && <img className='third-img' src={bswIcon} alt="" />}
                        </LpImgGroup>
                        <TokenName className="lp-token">{getTokenName(data.name)}</TokenName>
                    </FlexCont>

                </FlexSBCont>
            </TransInputBox>
          </TransInputCont>
          <RemoveTokenCont>
            <FlexSBCont className="remove-token">
              <FlexCont>
                <img className="remove-token-icon" src={getIcons(1)} alt="" />
                <InfoLDText>{lpToken0.name}</InfoLDText>
              </FlexCont>
              <InfoLDText>{lpToken0 && lpToken0.price && sendAmount > 0? parseFloat(quoteRemoveAmountToken0).toFixed(6) : "0"}</InfoLDText>
            </FlexSBCont>
            <FlexSBCont>
              <FlexCont>
                <img className="remove-token-icon" src={getIcons(2)} alt="" />
                <InfoLDText>{lpToken1.name}</InfoLDText>
              </FlexCont>
              <InfoLDText>{lpToken1 && lpToken1.price && sendAmount > 0? parseFloat(quoteRemoveAmountToken1).toFixed(6) : "0"}</InfoLDText>
            </FlexSBCont>
          </RemoveTokenCont>
          </TransActionCont>
          {/* <InfoSmallText style={{ marginBottom: '10px' }}>{`1 ${data.name.split('-')[0]} = ${calculateTokenVal('first')} ${data.name.split('-')[1]
            }`}</InfoSmallText>
          <InfoSmallText>{`1 ${data.name.split('-')[1]} = ${calculateTokenVal('second')} ${data.name.split('-')[0]}`}</InfoSmallText> */}

          { <RemoveLDBtn style={{ marginTop: '30px' }} disabled={removeLoading} onClick={() => handleRemoveLiquidity()}>
            {removeLoading ? <Spinner /> : 'Remove'}
          </RemoveLDBtn>}

        </RemoveLDCont>
      </CardCont>
      <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
        {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
      </CustomModal>
      <CustomModal show={showSettings} toggleModal={setShowSettings}>
        {showSettings && (
          <Settings
            setShowSettings={setShowSettings}
            slippageVal={slippageVal}
            setSlippageVal={setSlippageVal}
            handleSlippage={handleSlippage}
            // deadLine={deadLine}
            // setDeadLine={setDeadLine}
          />
        )}
      </CustomModal>
    </Fragment>
  )
}

export default RemoveLiquidity
