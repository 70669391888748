import { useState, useEffect } from 'react'

import {
  FlexSBCont,
  FlexCont,
  TitleText,
  YellowConfirmBtn,
  UnStakeCont,
  SwapConstTxt, 
  IconWithLabel,
  IconLabel,
  TokenPriceLabel,
  IconRow,
  TokenBalanceContainer,
  CheckBoxRow,
  CheckBox,
  CheckBoxLabel,
  LeftSection,
  RightSection,
  UnStakeBottomCont,
  UnStakeCardCont
} from './styles'
import {
  Separator
} from 'shared/styles/styled';
import { commaFy } from 'service/globalFunctions'
import whiteCrossIcon from 'assets/icons/white-cross.svg'
import { getTokenIcon } from 'shared/tokenIconList'

const UnStakeInstantlyPop = (props: any) => {
  const {setShowUnstakePopUp, unstakeData, setConfirmUnstake, showUnstakePopUp} = props;
  const [ enabledConfirm, setEnabledConfirm ] = useState(false);
  const renderTokenIcons = (tokenIcons: string) => {
    const tokenName = tokenIcons.split(' ')
    if (tokenName.length === 1) {
        const icon = getTokenIcon(tokenName[0])
        // console.log('icon',icon );
        return <img className="gammaIcon" alt="" src={icon} />
    }
  }

  useEffect(() => {
    if(showUnstakePopUp != undefined && showUnstakePopUp == false) {
      // console.log("unstake data", unstakeData)
      setEnabledConfirm(false)
    }
  }, [showUnstakePopUp])

  useEffect(() => {
    return () => {};
  }, []);
  
  if( Object.keys(unstakeData).length > 0) {
    return (
    
      <UnStakeCont >
        <UnStakeCardCont>
          <FlexSBCont>
            <TitleText className="swapText">Instant Unstake</TitleText>
            <FlexCont className="clock-setting">
              <img className="close-confirm-swap" src={whiteCrossIcon} alt="" onClick={() => setShowUnstakePopUp(false)} />
            </FlexCont>
          </FlexSBCont>
          <IconRow>
            <IconWithLabel className='gammaIconCont'>
              {renderTokenIcons(unstakeData.tokenName.toLowerCase())}
              <IconLabel>
                {unstakeData.tokenName}
              </IconLabel>
                
            </IconWithLabel>
            <TokenBalanceContainer>
              <TokenPriceLabel>
                {unstakeData.withdrawAmt.toFixed(4)}
              </TokenPriceLabel>
              <TokenPriceLabel>
                (${unstakeData.withdrawAmtUsd.toFixed(2)})
              </TokenPriceLabel>
            </TokenBalanceContainer>
          </IconRow>
          <CheckBoxRow>
            <LeftSection onClick={() => setEnabledConfirm(!enabledConfirm)}>
              {
                (enabledConfirm)? 
                  <CheckBox className="checked">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.045" height="19" viewBox="0 0 11.045 19">
                      <path id="Path_897" data-name="Path 897" d="M1353.184-9894.11l-8.026,10.955h5.077l-2.243,8.045,8.211-10.992h-4.984Z" transform="translate(-1345.158 9894.11)"/>
                    </svg>
                  </CheckBox>
                :
                <CheckBox />
              }
              <CheckBoxLabel>
                I understand the fees
              </CheckBoxLabel>
            </LeftSection>
            <RightSection>
              <CheckBoxLabel>
                = {unstakeData.withdrawFee.toFixed(4)} {unstakeData.tokenName}
              </CheckBoxLabel>
            </RightSection>
            
          </CheckBoxRow>

          <YellowConfirmBtn disabled={!enabledConfirm} 
            onClick={() => setConfirmUnstake(
              {
                confirm: true, 
                poolId: unstakeData.tokenName.toLowerCase() == "gamma"? 0:1
              })}
            >
            { 'Confirm'}
          </YellowConfirmBtn>
          <Separator/>
          <UnStakeBottomCont>
              <FlexSBCont className="swap-const-data">
                <SwapConstTxt light>Instant Unstake Penalty</SwapConstTxt>
                <SwapConstTxt>{commaFy(parseFloat(unstakeData.withdrawFee).toFixed(4))}</SwapConstTxt>
              </FlexSBCont>
              <FlexSBCont className="swap-const-data">
                <SwapConstTxt light>Instant Unstake Penalty</SwapConstTxt>
                <SwapConstTxt>{parseFloat(unstakeData.instantWithdrawFeePercent)}%</SwapConstTxt>
              </FlexSBCont>
              <FlexSBCont className="swap-const-data">
                <SwapConstTxt light>Amount To Receive</SwapConstTxt>
                <SwapConstTxt>{commaFy(parseFloat(unstakeData.receiveAmt).toFixed(4))} (${unstakeData.receiveAmtUsd.toFixed(2)})</SwapConstTxt>
              </FlexSBCont>
              
          </UnStakeBottomCont>
        </UnStakeCardCont>
      </UnStakeCont>

    )
  }
  else {
    return <></>
  }
  
}

export default UnStakeInstantlyPop;
