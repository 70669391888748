import axios from 'axios'
import { BigNumber } from 'bignumber.js'
import {
  aquaAddress,
  aquaFarmAbi,
  aquaFarmAddress,
  masterchefAbi,
  pancakeLPabi,
  pancakeFactoryAbi,
  pancakFactoryAddress,
  aquaAutoCompPoolAddress,
  aquaAutoCompPoolAbi,
  STRATEGY_ABI,
  multicall_abi, 
  multicall_address,
  compensation_address_poor,
  compensation_address_rich,
  COMPENSATION_ABI
} from './abi'
import { formatUnits } from '@ethersproject/units'
import { zeroAddress } from './SwapDexAbi'
import wallet from 'modules/wallet/wallet'
import { gammaAddress, gammaFarmAdddress } from './lendingAbi'
import { getGammaPrice } from './LendingBase'


export const tokenList: any = {
  '0': { '0': 'AQUA-BNB', '1': 'AQUAPAIR' },
  '1': { '0': 'AQUA', '1': 'AQUA' },
  '2': { '0': '4BELT', '1': '4BELT' },
  '3': { '0': 'BUSD-USDT', '1': 'AUTOPAIR' },
  '4': { '0': 'BUSD-VAI', '1': 'AUTOPAIR' },
  '5': { '0': 'BUSD-UST', '1': 'AUTOPAIR' },
  '6': { '0': 'beltBNB', '1': 'SINGLEBELT' },
  '7': { '0': 'beltBTCB', '1': 'SINGLEBELT' },
  '8': { '0': 'beltETH', '1': 'SINGLEBELT' },
  '10': { '0': 'CAKE-BNB', '1': 'BUNNYPAIR' },
  '11': { '0': 'BUSD-BNB', '1': 'BUNNYPAIR' },
  '12': { '0': 'CAKE', '1': 'PCSv2SINGLE' },
  '13': { '0': 'AQUA-BNB', '1': 'AQUAPAIR' },
  '14': { '0': 'AQUA-CAKE', '1': 'AQUAPAIR' },
  '15': { '0': 'CAKE-BNB', '1': 'AQUAPAIR' },
  '16': { '0': 'BNB-BUSD', '1': 'AQUAPAIR' },
  '17': { '0': 'BUSD-CAKE', '1': 'AQUAPAIR' },
  '18': { '0': 'MCOIN-UST', '1': 'AQUAPAIR' },
  '19': { '0': 'BNB-ETH', '1': 'AQUAPAIR' },
  '20': { '0': 'BNB-BTCB', '1': 'AQUAPAIR' },
  '21': { '0': 'BNB-USDT', '1': 'AQUAPAIR' },
  '22': { '0': 'BTCB-BUSD', '1': 'AQUAPAIR' },
  '23': { '0': 'BNB-LINK', '1': 'AQUAPAIR' },
  '24': { '0': 'BNB-DOT', '1': 'AQUAPAIR' },
  '25': { '0': 'BNB-XVS', '1': 'AQUAPAIR' },
  '26': { '0': 'BTCB-ETH', '1': 'AQUAPAIR' },
  '27': { '0': 'BUSD-USDC', '1': 'AQUAPAIR' },
  '28': { '0': 'ETH-USDC', '1': 'AQUAPAIR' },
  '29': { '0': 'BUSD-TUSD', '1': 'AQUAPAIR' },
  '30': { '0': 'BUSD-DAI', '1': 'AQUAPAIR' },
  '35': { '0': 'XRP-BNB', '1': 'AQUAPAIR' },
  '36': { '0': 'AQUA-BUSD', '1': 'AQUAPAIR' },
  '37': { '0': 'DOGE-BNB', '1': 'AQUAPAIR' },
  '38': { '0': 'ADA-BNB', '1': 'AQUAPAIR' },
  '39': { '0': 'TRX-BNB', '1': 'AQUAPAIR' },
  '40': { '0': 'BUSD-VAI', '1': 'AQUAPAIR' },
  '41': { '0': 'BUSD-UST', '1': 'AQUAPAIR' },
  '42': { '0': 'BUSD-USDT', '1': 'AQUAPAIR' },
  '43': { '0': 'beltBTCB', '1': 'SINGLEBELT' },
  '44': { '0': 'beltETH', '1': 'SINGLEBELT' },
  '46': { '0': 'beltBNB', '1': 'SINGLEBELT' },
  '47': { '0': '4BELT', '1': '4BELT' },
  '48': { '0': 'BNB-LTC', '1': 'AQUAPAIR' },
  '49': { '0': 'BUSD-ALPACA', '1': 'AQUAPAIR' },
  '50': { '0': 'BNB-UNI', '1': 'AQUAPAIR' },
  '51': { '0': 'BNB-BUNNY', '1': 'AQUAPAIR' },
  '52': { '0': 'BNB-XVS', '1': 'AQUAPAIR' },
  '53': { '0': 'UST-USDC', '1': 'AQUAPAIR' },
  '54': { '0': 'BTCB-BNB', '1': 'AQUAPAIR' },
  '55': { '0': 'ETH-BTCB', '1': 'AQUAPAIR' },
  '56': { '0': 'USDC-BUSD', '1': 'AQUAPAIR' },
}
const BUSD_BNBLPAddress = '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16'
const bnbAddress = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
const cakeTokenAddress = '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
const otherPlanetsPoolsId = [
  6, 7, 8, 12, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 26, 28, 35, 37, 38, 39, 43, 44, 46, 48, 49, 50, 51, 52, 54, 55,
]
const bluePlanetpoolsId = [0, 1, 2, 3, 4, 5, 13, 14, 27, 29, 30, 36, 40, 41, 42, 47, 53, 56]
export const oldPoolsId = [0, 1, 3, 4, 5, 27, 2, 6, 7, 8, 20, 26, 40]
export const aquaPairPoolId = [0, 13, 14, 36, 53, 54, 55, 56]

enum instType {
  'AQUAFARM' = 'AQUAFARM',
  'PANCAKELP' = 'PANCAKELP',
  'MASTERCHEF' = 'MASTERCHEF',
  'STRATEGY_ABI' = 'STRATEGY_ABI',
  'FACTORY' = 'FACTORY',
  'AQUA_AUTO_COMP' = 'AQUA_AUTO_COMP',
}


const getReserves = async (tokenAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.getReserves().call()
    return data
  } catch (e) {
    console.log(e)
    return {
      0: 0,
      1: 0,
      2: 0,
      _reserve0: 0,
      _reserve1: 0,
      _blockTimestampLast: 0
    }
  }
}

export const token1 = async (tokenAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.token0().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const token2 = async (tokenAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.token1().call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

export const totalSupply = async (tokenAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.totalSupply().call()
    return data
  } catch (e) {
    console.log(e)
    return 0;
  }
}

const getPair = async (factoryAddress: string, token1: string, token2: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.FACTORY, factoryAddress)
    const data = await tokenInstance.methods.getPair(token1, token2).call()
    return data
  } catch (e) {
    console.log(e)
    return zeroAddress
  }
}

const getBnbPrice = async () => {
  const reserves = await getReserves(BUSD_BNBLPAddress)
  const getBNBReserve = reserves._reserve0
  const getUSDReserve = reserves._reserve1
  const bnbPrice = getUSDReserve / getBNBReserve
  return bnbPrice
}

export const getTokenPrice = async (tokenAddress: any) => {
  const bnbPrice = await getBnbPrice()

  if (tokenAddress === bnbAddress || tokenAddress === zeroAddress) {
    return await getBnbPrice()
  }
  else if (tokenAddress.toLowerCase() === gammaAddress.toLowerCase()) {
    return await getGammaPrice();
  }
  else if (tokenAddress.toLowerCase() === aquaAddress.toLowerCase()) {
    return await getAquaPrice();
  } else {
    const pairAddress: string = await getPair(pancakFactoryAddress, tokenAddress, bnbAddress)
    if (pairAddress !== zeroAddress) {
      const token0: string = await token1(pairAddress)
      const reserves = await getReserves(pairAddress)

      let getReserve0: any, getReserve1: any

      if (tokenAddress === token0) {
        getReserve0 = reserves['_reserve0']
        getReserve1 = reserves['_reserve1']
      } else {
        getReserve0 = reserves['_reserve1']
        getReserve1 = reserves['_reserve0']
      }
      const reservesRatio = getReserve0 / getReserve1
      const price: number = bnbPrice / reservesRatio
      return price
    }
    return 0
  }
}

export const poolInfo = async (farmAddress: string, poolId: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, farmAddress)
    const data = await tokenInstance.methods.poolInfo(poolId).call()
    return data
  } catch (e) {
    console.log(e)
    return {
      0: zeroAddress,
      1: 0,
      2: 0,
      3: 0,
      4: zeroAddress,
      want: zeroAddress,
      allocPoint: 0,
      lastRewardBlock: 0,
      accAQUAPerShare: 0,
      strat: zeroAddress
    }

  }
}

const poolLength = async (farmAddress: string) => {
  try {
    if (farmAddress.toLowerCase() === gammaFarmAdddress.toLowerCase()) {
      return 17
    }
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, farmAddress)
    const data = await tokenInstance.methods.poolLength().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const totalAllocPoint = async () => {
  try {
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, aquaFarmAddress)
    const data = await tokenInstance.methods.totalAllocPoint().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const AQUAPerBlock = async () => {
  try {
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, aquaFarmAddress)
    const data = await tokenInstance.methods.AQUAPerBlock().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const AQUAMaxSupply = async () => {
  try {
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, aquaFarmAddress)
    const data = await tokenInstance.methods.AQUAMaxSupply().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const pendingAQUA = async (poolId: string, userAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, aquaFarmAddress)
    const data = await tokenInstance.methods.pendingAQUA(poolId, userAddress).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const userInfo = async (farmAddress: string, poolId: string, userAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, farmAddress)
    const data = await tokenInstance.methods.userInfo(poolId, userAddress).call()
    return data
  } catch (e) {
    console.log(e)
    return {
      0: 0,
      1: 0,
      shares: 0,
      rewardDebt: 0
    }
  }
}

export const stakedWantTokens = async (farmAddress: string, poolId: string, userAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, farmAddress)
    const data = await tokenInstance.methods.stakedWantTokens(poolId, userAddress).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const stakedWantTokensMulticall = async (farmAddress: string, poolIdArray: any, userAddress: string) => {
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let stakedTokenArray: any = [];
  
  if (poolIdArray.length === 0 || userAddress === undefined) {
    return stakedTokenArray;
  }
  // console.log(" inside stakedWantTokensMulticall ", farmAddress, poolIdArray)
  try {
  //   const tokenInstance: any = await selectInstance(instType.AQUAFARM, farmAddress)
  //   const data = await tokenInstance.methods.stakedWantTokens(poolId, userAddress).call()
  //   return data
  // } catch (e) {
  //   console.log(e)
  //   return 0
  // }
  for (let i = 0; i < poolIdArray.length; i++) {
    // console.log("gTokenAddressArray", i ,  gTokenAddressArray[i].address)
    const tokenInstance: any = await selectInstance(instType.AQUAFARM, farmAddress)
    targets.push(farmAddress)
    const data = wallet.web3.eth.abi.encodeFunctionCall(tokenInstance.methods.stakedWantTokens(poolIdArray[i], userAddress)._method, [
      poolIdArray[i],
      userAddress,
    ])
    callDatas.push(data)
    ouput_format.push(tokenInstance.methods.stakedWantTokens(poolIdArray[i], userAddress)._method.outputs)
  }
  const aggregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()
  const do_split = async (array: any, n: any): Promise<any> => {
    return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
  }

  for (let i = 0; i < aggregated_data[1].length; i++) {
    results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
  }

  const split_arr = await do_split(results, poolIdArray.length)
  
  if (split_arr[0].length > 0) {
    for (let i = 0; i < poolIdArray.length; i++) {
      stakedTokenArray[poolIdArray[i]] = split_arr[0][i][0]
    }
  }
  // console.log("stakedTokenArray", stakedTokenArray)
  return stakedTokenArray;
} catch (error) {
  console.log('error', error)
}
}

export const allowance = async (tokenAddress: string, owner: string, spender: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.allowance(owner, spender).call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const balanceOf = async (tokenAddress: string, userAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.PANCAKELP, tokenAddress)
    const data = await tokenInstance.methods.balanceOf(userAddress).call()
    // console.log("balanceOf",tokenAddress, data);
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const balanceOfMulticall = async (tokenAddressArray: any, userAddress: string) => {
  const mutilcall_inst = new wallet.web3.eth.Contract(multicall_abi, multicall_address)
  let targets: any = []
  let callDatas: any = []
  let results: any = []
  let ouput_format: any = []
  let balanceOfArr: any = [];
  if (tokenAddressArray.length === 0) {
    return balanceOfArr;
  }
  try {
    for (let i = 0; i < tokenAddressArray.length; i++) {
      const inst: any = await selectInstance(instType.PANCAKELP, tokenAddressArray[i].address);
      targets.push(tokenAddressArray[i].address);
      const data = wallet.web3.eth.abi.encodeFunctionCall(inst.methods.balanceOf(tokenAddressArray[i].token)._method, [tokenAddressArray[i].token])
      callDatas.push(data)
      ouput_format.push(inst.methods.balanceOf(tokenAddressArray[i].token)._method.outputs)
    }
    const aggregated_data = await mutilcall_inst.methods.aggregate(targets, callDatas).call()
    const do_split = async (array: any, n: any): Promise<any> => {
      return array.length ? [array.splice(0, n)].concat(await do_split(array, n)) : []
    }

    for (let i = 0; i < aggregated_data[1].length; i++) {
      results.push(wallet.web3.eth.abi.decodeParameters(ouput_format[i], aggregated_data[1][i]))
    }

    const split_arr = await do_split(results, tokenAddressArray.length)
    if (split_arr[0].length > 0) {
      for (let i = 0; i < tokenAddressArray.length; i++) {
        balanceOfArr[tokenAddressArray[i].address+"_"+tokenAddressArray[i].token] = split_arr[0][i][0];
        
      }
    }
    return balanceOfArr;
  } catch (e) {
    console.log(e)
    return 0
  }
}

const wantLockedTotal = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.wantLockedTotal().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const entranceFeeFactor = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.entranceFeeFactor().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const entranceFeeFactorMax = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.entranceFeeFactorMax().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const buyBackRate = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.buyBackRate().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const buyBackRateMax = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.buyBackRateMax().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

const pid = async (strategyAddress: string) => {
  try {
    const tokenInstance: any = await selectInstance(instType.STRATEGY_ABI, strategyAddress)
    const data = await tokenInstance.methods.pid().call()
    return data
  } catch (e) {
    console.log(e)
    return 0
  }
}

export const getAquaPrice = async () => {
  const poolDetails = await poolInfo(aquaFarmAddress, '13')
  const lpAddress = poolDetails['want']
  const bnbPrice = await getBnbPrice()
  const reserves = await getReserves(lpAddress)
  const reserve0 = reserves['_reserve0']
  const reserve1 = reserves['_reserve1']
  const bnbPerAQUA = reserve1 / reserve0
  const aquafinalPrice = bnbPerAQUA * bnbPrice
  return aquafinalPrice
}

const getPreviousAquaPrice = async () => {
  const poolDetails = await poolInfo(aquaFarmAddress, '0')
  const lpAddress = poolDetails['want']
  const bnbPrice = await getBnbPrice()
  const reserves = await getReserves(lpAddress)
  const reserve0 = reserves['_reserve0']
  const reserve1 = reserves['_reserve1']
  const bnbPerAQUA = reserve1 / reserve0
  const aquafinalPrice = bnbPerAQUA * bnbPrice
  return aquafinalPrice
}

const getBeltTokenPrice = async (tokenAddress: string) => {
  const token = (await axios.get('https://s.belt.fi/info/all.json')).data
  let temp: any[] = token['tokenList']['BSC']
  temp = temp.filter((e) => e.address === tokenAddress)
  return parseFloat(temp[0].price)
}

const getPoolTokenAssetPrice = async (i: any): Promise<number> => {
  if (i === -1) {
    return getAquaPrice()
  } else if (otherPlanetsPoolsId.includes(i) || aquaPairPoolId.includes(i) || bluePlanetpoolsId.includes(i)) {
    const poolDetails = await poolInfo(aquaFarmAddress, i)
    const lpAddress = poolDetails['want']

    if (tokenList[i][1] === 'AUTOPAIR') {
      const token0Address = await token1(lpAddress)
      const token1Address = await token2(lpAddress)
      const reserves = await getReserves(lpAddress)
      const reserve0 = reserves['_reserve0']
      const reserve1 = reserves['_reserve1']
      const totalLpSupply = await totalSupply(lpAddress)
      const token0price = await getTokenPrice(token0Address)
      const token1price = await getTokenPrice(token1Address)
      const assetPrice = (reserve0 * token0price + reserve1 * token1price) / totalLpSupply
      return assetPrice
    } else if (tokenList[i][1] === '4BELT' || tokenList[i][1] === 'SINGLEBELT') {
      return await getBeltTokenPrice(lpAddress)
    } else if (tokenList[i][1] === 'PCSv2SINGLE') {
      let assetPrice = await getTokenPrice(cakeTokenAddress)
      return assetPrice
    } else if (tokenList[i][1] === 'AQUA') {
      return getAquaPrice()
    } else if (tokenList[i][1] === 'AQUAPAIR') {
      const token0Address = await token1(lpAddress)
      const token1Address = await token2(lpAddress)
      const reserves = await getReserves(lpAddress)
      const reserve0 = reserves['_reserve0']
      const reserve1 = reserves['_reserve1']
      const totalLpSupply = await totalSupply(lpAddress)
      let token0price
      let token1price
      if (i === 13) {
        token0price = await getAquaPrice()
        token1price = await getBnbPrice()
      } else if (i === 0) {
        token0price = await getPreviousAquaPrice()
        token1price = await getBnbPrice()
      } else if (i === 14) {
        token0price = await getTokenPrice(cakeTokenAddress)
        token1price = await getAquaPrice()
      } else if (i === 36) {
        token0price = await getAquaPrice()
        token1price = await getTokenPrice(token1Address)
      } else {
        token0price = await getTokenPrice(token0Address)
        token1price = await getTokenPrice(token1Address)
      }
      const assetPrice = (reserve0 * token0price + reserve1 * token1price) / totalLpSupply
      return assetPrice
    }
  }
  return 0
}

export const getTokenOneAndTwo = async (i: any) => {
  const poolDetails = await poolInfo(aquaFarmAddress, i)
  const wantAddress = poolDetails['want']
  const token0Address = await token1(wantAddress)
  const token1Address = await token2(wantAddress)
  return [token0Address, token1Address]
}

export const userTokenDetails = async (poolId: number, userAddress: string) => {
  let price: any = 0
  let liquidBalance: any = 0
  let usdLiquidBalance: any = 0
  let currentLpDeposit: any = 0
  let currentBalance: any = 0

  if (userAddress !== null) {
    liquidBalance = await getLPbalance(userAddress, poolId)
    price = await getPoolTokenAssetPrice(poolId)
    currentLpDeposit = await getCurrentBalance(poolId, userAddress)
    usdLiquidBalance = liquidBalance ? price * parseFloat(liquidBalance) : '0'
    currentBalance = currentLpDeposit ? price * parseFloat(currentLpDeposit) : '0'
  }
  let lpType = ''
  let temp = [-1, 1, 6, 7, 8, 12]

  if (poolId === 4) lpType = 'BLP'
  else if (!temp.includes(poolId)) lpType = 'LP'

  return {
    price: price,
    lpType: lpType,
    liquidBalance: liquidBalance,
    usdLiquidBalance: usdLiquidBalance,
    currentLpDeposit: currentLpDeposit,
    currentBalance: currentBalance,
  }
}

export const convertToWei = (data: any) => {
  data = noExponents(data)
  let x = new BigNumber(data)
  x = x.multipliedBy(1e18)
  return x.toFixed(0)
}

export const convertToEther = (data: any): any => {
  data = noExponents(data)
  return noExponents(formatUnits(data.toString(), 18))
}

export const handleDeposit = async (poolId: any, amount: any, userAddress: any) => {
  if (poolId === -1) {
    await handleAquaAutoCompDeposit(amount, userAddress)
  } else {
    const approvalAmount = '100000000000000000000000000000000000000000000000000000000000000000000000000000'
    if (userAddress) {
      try {
        const depositAmount = amount
        const aquafarmInstance: any = await selectInstance(instType.AQUAFARM, aquaFarmAddress, true)
        const poolDetails = await poolInfo(aquaFarmAddress, poolId)
        const lpAddress = poolDetails['want']
        const LPinstance: any = await selectInstance(instType.PANCAKELP, lpAddress, true)
        const getAllowance = await getCurrentApproval(poolId, userAddress)

        const promisify = (inner: any) =>
          new Promise((resolve, reject) =>
            inner((err: any, res: any) => {
              if (err) {
                reject(err)
              }
              resolve(res)
            })
          )

        if (depositAmount > getAllowance) {
          await promisify((cb: any) => {
            LPinstance.methods
              .approve(aquaFarmAddress, approvalAmount)
              .send({ from: userAddress })
              .once('receipt', function (receipt: any) {
                cb()
              })
              .on('error', function (error: any) {
                cb(error)
              })
          })
        }
        await promisify((cb: any) => {
          aquafarmInstance.methods
            .deposit(poolId, convertToWei(depositAmount))
            .send({ from: userAddress })
            .once('receipt', function (receipt: any) {
              cb()
            })
            .on('error', function (error: any) {
              cb(error)
            })
        })
      } catch (err) {
        console.log(err)
      }
    }
  }
}

export const handleWithdraw = async (poolId: any, amount: any, userAddress: any) => {
  if (poolId === -1) {
    await handleAquaAutoCompWithdraw(amount, userAddress)
  } else {
    if (userAddress) {
      try {
        const withdrawAmount = amount
        const aquafarmInstance: any = await selectInstance(instType.AQUAFARM, aquaFarmAddress, true)
        const promisify = (inner: any) =>
          new Promise((resolve, reject) =>
            inner((err: any, res: any) => {
              if (err) {
                reject(err)
              }
              resolve(res)
            })
          )
        await promisify((cb: any) => {
          aquafarmInstance.methods
            .withdraw(poolId, convertToWei(withdrawAmount))
            .send({ from: userAddress })
            .once('receipt', function (receipt: any) {
              cb()
            })
            .on('error', function (error: any) {
              cb(error)
            })
        })
        // }
      } catch (err) {
        console.log(err)
      }
    }
  }
}

const getFinalBalance = (val: any) => {
  const newVal = new BigNumber(val).toString()
  return newVal
}

const getLPbalance = async (currentUserAddress: any, poolId: any) => {
  try {
    if (currentUserAddress) {
      if (poolId === -1) {
        const balanceVal = await balanceOf(aquaAddress, currentUserAddress)
        let x = new BigNumber(balanceVal)
        return getFinalBalance(x.div(1e18).toString())
      } else {
        const poolDetails = await poolInfo(aquaFarmAddress, poolId)
        const lpAddress = poolDetails['want']
        const balanceVal = await balanceOf(lpAddress, currentUserAddress)
        let x = new BigNumber(balanceVal)
        const balance = x.div(1e18).toString()
        const finalBalance = getFinalBalance(balance)
        return finalBalance
      }
    } else {
      return 0
    }
  } catch (err) {
    console.log(err)
  }
}

const getCurrentBalance = async (aquaPoolId: any, userAddress: any) => {
  if (userAddress) {
    if (aquaPoolId !== -1) {
      const result: any = convertToEther(await stakedWantTokens(aquaFarmAddress, aquaPoolId, userAddress))
      return result
    } else {
      const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress)
      const aquaBalance = convertToEther(await poolInstance.methods.balanceOf().call())
      const totalShares = convertToEther(await poolInstance.methods.totalShares().call())
      const userDetails = await poolInstance.methods.userInfo(userAddress).call()
      const userShares = convertToEther(userDetails['shares'])
      return (userShares / totalShares) * aquaBalance
    }
  } else {
    return 0
  }
}

const getTotalTvlOfOtherPlanets = async () => {
  try {
    const poolLength: any = await getPoolLength()

    let res = 0

    const getOtherTvl = async (i: any) => {
      const poolDetails = await poolInfo(aquaFarmAddress, i)
      const strategyAddress = poolDetails['strat']
      const assetPrice = await getPoolTokenAssetPrice(i)
      const wantTotal = await wantLockedTotal(strategyAddress)
      const tvl = convertToEther(wantTotal) * assetPrice
      return tvl
    }
    let result: any = []
    for (let i = 0; i < poolLength; i++) {
      if (otherPlanetsPoolsId.includes(i)) {
        const data: any = getOtherTvl(i)
        result.push(data)
      }
    }
    result = await Promise.all(result)
    for (let i = 0; i < result.length; i++) {
      res += parseFloat(result[i])
    }
    return res
  } catch (err) {
    console.log(err)
  }
}

export const getTokenYield = async (tokenYield: any, n: number) => {
  const apy = (Math.pow(1 + ((tokenYield / 100) * n) / 365 / n, n) - 1) * 100
  return apy
}

const getAutoAquaTokenYield = async (tokenYield: any, n: number) => {
  const apy = ((1 + tokenYield / 100 / n) ** n - 1) * 100
  return apy
}

const getUserLpStatus = async (userAddress: any, poolId: any) => {
  try {
    var obj: any = {}
    const autoAPiData = (await axios.get('https://static.autofarm.network/bsc/farm_data.json')).data
    let pools = autoAPiData['pools']

    let tokenName = null
    let poolDetails = await poolInfo(aquaFarmAddress, poolId)
    let tvl: number = 0
    let aquaPerBlock: any
    let tokenYield: any
    let tokenAPR: any
    let tokenYieldPerDay: any
    let tokenType: any
    let farmName: any
    let parentFarmApy: number = 0
    let getLpTokenLink: any
    let assetPrice: number = 0
    let tokenTypeBoolean: boolean = true
    let poolTokenInstance: any
    let currentBalance: any
    let LPbalance: any
    let depositFee: number = 0
    let autoCompoundFee: number = 0
    let platformFee: number = 0
    let buyBackFee: number = 0
    let orgTvl: any
    let farmApr: any = 0

    const wantAddress = poolDetails['want']
    const strategyAddress = poolDetails['strat']
    const poolAllocPoint = poolDetails['allocPoint']

    if (tokenList[poolId][1] === 'AUTOPAIR') {
      tokenName = await getName(wantAddress, true)
      const token0Address = await token1(wantAddress)
      const token1Address = await token2(wantAddress)

      tokenType = 'LP'
      tokenTypeBoolean = true
      farmName = 'AUTO'

      const autoPoolId = await pid(strategyAddress)
      parentFarmApy = pools[autoPoolId]['APY_total']

      getLpTokenLink = `https://exchange.pancakeswap.finance/#/add/${token0Address}/${token1Address}`
      tvl = await wantLockedTotal(strategyAddress)

      assetPrice = await getPoolTokenAssetPrice(poolId)

      tvl = convertToEther(tvl) * assetPrice
      orgTvl = tvl

      const perBlock = await AQUAPerBlock()
      aquaPerBlock = convertToEther(perBlock)

      let totalAlloc = await totalAllocPoint()
      tokenYield =
        tvl > 0
          ? ((aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice())) / tvl) * 365 * 100
          : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice()) * 365 * 100
      tokenYieldPerDay = tokenYield / 365
      tokenAPR = tokenYield
      tokenYield = await getTokenYield(tokenYield, 365)

      let APY: any = tokenYield + parentFarmApy * 100
      let m: any = 365
      let a: any = APY / 100 + 1
      a = (Math.pow(a, 1 / m) - 1) * m

      farmApr = (a * 100) / 365
      // (APY + 1) = (1 + ( APR / m))^m
      if (userAddress) {
        currentBalance = await getCurrentBalance(poolId, userAddress)
        LPbalance = await getLPbalance(userAddress, poolId)
      }
      depositFee =
        ((parseFloat(await entranceFeeFactorMax(strategyAddress)) - parseFloat(await entranceFeeFactor(strategyAddress))) /
          parseFloat(await entranceFeeFactorMax(strategyAddress))) *
        100
      autoCompoundFee = 0.5
      platformFee = 1
      buyBackFee = (parseFloat(await buyBackRate(strategyAddress)) / parseFloat(await buyBackRateMax(strategyAddress))) * 100
    } else if (tokenList[poolId][1] === '4BELT' && poolId === 47) {
      let APR: any = 0

      tokenName = await getName(wantAddress, false)
      tokenType = 'BLP'
      farmName = 'BELT'

      tokenTypeBoolean = true
      getLpTokenLink = 'https://belt.fi/'

      const autoPoolId = await pid(strategyAddress)

      tvl = await wantLockedTotal(strategyAddress)
      const token = (await axios.get('https://s.belt.fi/info/all.json')).data

      assetPrice = await getPoolTokenAssetPrice(poolId)

      const temp1: any[] = token['info']['BSC']['vaultPools']
      temp1.filter((e) => {
        if (autoPoolId === e.pid) APR = e.totalAPR
      })

      parentFarmApy = (await getAutoAquaTokenYield(APR, 365)) / 100
      tvl = convertToEther(tvl) * assetPrice
      orgTvl = tvl
      aquaPerBlock = await AQUAPerBlock()
      aquaPerBlock = convertToEther(aquaPerBlock)

      let totalAlloc = await totalAllocPoint()
      tokenYield =
        tvl > 0
          ? ((aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice())) / tvl) * 365 * 100
          : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice()) * 365 * 100
      tokenYieldPerDay = tokenYield / 365

      tokenAPR = tokenYield
      tokenYield = await getTokenYield(tokenYield, 365)
      let APY: any = tokenYield + parentFarmApy * 100
      let m: any = 365
      let a: any = APY / 100 + 1
      a = (Math.pow(a, 1 / m) - 1) * m

      farmApr = (a * 100) / 365
      if (userAddress) {
        currentBalance = await getCurrentBalance(poolId, userAddress)
        LPbalance = await getLPbalance(userAddress, poolId)
      }
      depositFee =
        ((parseFloat(await entranceFeeFactorMax(strategyAddress)) - parseFloat(await entranceFeeFactor(strategyAddress))) /
          parseFloat(await entranceFeeFactorMax(strategyAddress))) *
        100
      autoCompoundFee = 0.5
      platformFee = 1
      buyBackFee = (parseFloat(await buyBackRate(strategyAddress)) / parseFloat(await buyBackRateMax(strategyAddress))) * 100
    } else if (tokenList[poolId][1] === '4BELT') {
      tokenName = await getName(wantAddress, false)
      tokenType = 'BLP'
      farmName = 'AUTO'

      tokenTypeBoolean = true
      getLpTokenLink = 'https://belt.fi/'

      const autoPoolId = await pid(strategyAddress)
      parentFarmApy = pools[autoPoolId]['APY_total']

      tvl = await wantLockedTotal(strategyAddress)
      const token = (await axios.get('https://s.belt.fi/info/all.json')).data
      const temp: any[] = token['tokenList']['BSC']
      temp.filter((e: any) => {
        if (wantAddress === e.address) assetPrice = e.price
      })
      tvl = convertToEther(tvl) * assetPrice
      orgTvl = tvl
      aquaPerBlock = await AQUAPerBlock()
      aquaPerBlock = convertToEther(aquaPerBlock)
      let totalAlloc = await totalAllocPoint()
      tokenYield =
        tvl > 0
          ? ((aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice())) / tvl) * 365 * 100
          : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice()) * 365 * 100
      tokenYieldPerDay = tokenYield / 365
      tokenAPR = tokenYield
      tokenYield = await getTokenYield(tokenYield, 365)
      let APY: any = tokenYield + parentFarmApy * 100
      let m: any = 365
      let a: any = APY / 100 + 1
      a = (Math.pow(a, 1 / m) - 1) * m

      farmApr = (a * 100) / 365
      if (userAddress) {
        currentBalance = await getCurrentBalance(poolId, userAddress)
        LPbalance = await getLPbalance(userAddress, poolId)
      }
      depositFee =
        ((parseFloat(await entranceFeeFactorMax(strategyAddress)) - parseFloat(await entranceFeeFactor(strategyAddress))) /
          parseFloat(await entranceFeeFactorMax(strategyAddress))) *
        100
      autoCompoundFee = 0.5
      platformFee = 1
      buyBackFee = (parseFloat(await buyBackRate(strategyAddress)) / parseFloat(await buyBackRateMax(strategyAddress))) * 100
    } else if (tokenList[poolId][1] === 'AQUAPAIR') {
      tokenName = await getName(wantAddress, true)

      const token0Address = await token1(wantAddress)
      const token1Address = await token2(wantAddress)
      tokenType = 'LP'

      if (token0Address !== bnbAddress && token1Address !== bnbAddress)
        getLpTokenLink = `https://exchange.pancakeswap.finance/#/add/${token0Address}/${token1Address}`
      else if (token0Address === bnbAddress) getLpTokenLink = `https://exchange.pancakeswap.finance/#/add/BNB/${token1Address}`
      else getLpTokenLink = `https://exchange.pancakeswap.finance/#/add/${token0Address}/BNB`

      tvl = await wantLockedTotal(strategyAddress)
      aquaPerBlock = await AQUAPerBlock()
      aquaPerBlock = convertToEther(aquaPerBlock)

      let reserves = await getReserves(wantAddress)
      let reserve0 = parseFloat(reserves['_reserve0'])
      let reserve1 = parseFloat(reserves['_reserve1'])
      let totalLpSupply = await totalSupply(wantAddress)
      let token0price: any
      let token1price: any

      if (poolId === 13) {
        //new aqua-bnb
        farmName = 'AQUA'
        token0price = await getAquaPrice()
        token1price = await getBnbPrice()
      } else if (poolId === 0) {
        //previous aqua-bnb
        farmName = 'AQUA'
        token0price = await getPreviousAquaPrice()
        token1price = await getBnbPrice()
      } else if (poolId === 14) {
        //cake-aqua
        farmName = 'AQUA'
        token0price = await getTokenPrice(cakeTokenAddress)
        token1price = await getAquaPrice()
      } else if (poolId === 36) {
        farmName = 'AQUA'
        token0price = await getAquaPrice()
        token1price = await getTokenPrice(token1Address)
      } else if (aquaPairPoolId.includes(poolId)) {
        farmName = 'AQUA'
        token0price = await getTokenPrice(token0Address)
        token1price = await getTokenPrice(token1Address)
      } else {
        farmName = 'PANCAKE SWAP'
        token0price = await getTokenPrice(token0Address)
        token1price = await getTokenPrice(token1Address)
      }
      assetPrice = (reserve0 * token0price + reserve1 * token1price) / totalLpSupply

      tokenTypeBoolean = true

      tokenTypeBoolean = true
      tvl = convertToEther(tvl) * assetPrice
      orgTvl = tvl
      let totalAlloc = await totalAllocPoint()
      tokenYield =
        tvl > 0
          ? ((aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice())) / tvl) * 365 * 100
          : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice()) * 365 * 100
      tokenYieldPerDay = tokenYield / 365
      farmApr = tokenYieldPerDay
      tokenAPR = tokenYield
      tokenYield = await getTokenYield(tokenYield, 365)

      if (userAddress) {
        currentBalance = await getCurrentBalance(poolId, userAddress)
        LPbalance = await getLPbalance(userAddress, poolId)
      }

      if (!aquaPairPoolId.includes(poolId)) {
        /*****************************************************************************/
        let APR: any
        const cakePrice = await getTokenPrice(cakeTokenAddress)
        const pId = await pid(strategyAddress)
        const instPcsFarm: any = await selectInstance(instType.MASTERCHEF, '0x73feaa1eE314F8c655E354234017bE2193C9E24E')
        const pcsPoolInfo = await instPcsFarm.methods.poolInfo(pId).call()
        const alloc = pcsPoolInfo['allocPoint']
        const totalAlloc = await instPcsFarm.methods.totalAllocPoint().call()
        let balance = await balanceOf(wantAddress, '0x73feaa1eE314F8c655E354234017bE2193C9E24E')
        balance = convertToEther(balance)
        APR = ((40 * 28800 * (alloc / totalAlloc) * cakePrice) / (balance * assetPrice)) * 365
        parentFarmApy = Math.pow(1 + APR / 365, 365) - 1
        let APY: any = tokenYield + parentFarmApy * 100
        let m: any = 365
        let a: any = APY / 100 + 1
        a = (Math.pow(a, 1 / m) - 1) * m
        farmApr = (a * 100) / 365

        /*****************************************************************************/
      }

      depositFee =
        ((parseFloat(await entranceFeeFactorMax(strategyAddress)) - parseFloat(await entranceFeeFactor(strategyAddress))) /
          parseFloat(await entranceFeeFactorMax(strategyAddress))) *
        100
      autoCompoundFee = 0
      platformFee = 0
      buyBackFee = (parseFloat(await buyBackRate(strategyAddress)) / parseFloat(await buyBackRateMax(strategyAddress))) * 100
    } else if (tokenList[poolId][1] === 'AQUA') {
      tokenName = await getName(wantAddress, false)
      tokenType = ''
      farmName = 'AQUA'

      getLpTokenLink = null

      tvl = await wantLockedTotal(strategyAddress)
      aquaPerBlock = await AQUAPerBlock()
      assetPrice = await getAquaPrice()
      tokenTypeBoolean = false
      tvl = convertToEther(tvl) * assetPrice
      orgTvl = tvl
      aquaPerBlock = convertToEther(aquaPerBlock)
      let totalAlloc = await totalAllocPoint()
      tokenYield =
        tvl > 0
          ? ((aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice())) / tvl) * 365 * 100
          : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * (await getAquaPrice()) * 365 * 100
      tokenYieldPerDay = tokenYield / 365
      farmApr = tokenYieldPerDay
      tokenAPR = tokenYield
      tokenYield = await getTokenYield(tokenYield, 365)
      if (userAddress) {
        currentBalance = await getCurrentBalance(poolId, userAddress)
        LPbalance = await getLPbalance(userAddress, poolId)
      }
      depositFee =
        ((parseFloat(await entranceFeeFactorMax(strategyAddress)) - parseFloat(await entranceFeeFactor(strategyAddress))) /
          parseFloat(await entranceFeeFactorMax(strategyAddress))) *
        100
      autoCompoundFee = 0
      platformFee = 0
      buyBackFee = (parseFloat(await buyBackRate(strategyAddress)) / parseFloat(await buyBackRateMax(strategyAddress))) * 100
    }
    // console.log(poolId,assetPrice)
    if (userAddress != null) {
      obj['tokenNameGetLP'] = tokenName
      obj['id'] = poolId
      obj['liquidBalance'] = LPbalance
      obj['usdLiquidBAlance'] = parseFloat(LPbalance) * assetPrice
      obj['currentLpDeposit'] = currentBalance
      obj['pendingAquaEarnings'] = await getPendingAquaClaim(userAddress, poolId)
      obj['token'] = tokenList[poolId][0]
      obj['tokenTvl'] = tvl
      obj['tokenYield'] = tokenYield
      obj['dailyPercentage'] = farmApr
      obj['totalEarned'] = `${parseFloat(currentBalance).toFixed(4)} ` + tokenType
      obj['lpType'] = tokenType
      obj['currentBalance'] = parseFloat(currentBalance) * assetPrice
      obj['rewardToken'] = await getPendingAquaClaim(userAddress, poolId)
      obj['reward'] = obj['rewardToken'] * (await getAquaPrice())
      obj['assetTokenPrice'] = assetPrice
      obj['farmName'] = farmName
      obj['farmContract'] = `https://bscscan.com/address/${aquaFarmAddress}#code`
      obj['vaultContractt'] = `https://bscscan.com/address/${poolDetails['strat']}#code`
      obj['farmApy'] = parentFarmApy * 100
      obj['optimalCompoundsPerYear'] = '0'
      obj['aquaApr'] = tokenYield
      obj['totalAPY2'] = (tokenYield + parentFarmApy * 100).toFixed(2)
      obj['totalApy'] = tokenYield + parentFarmApy * 100
      obj['getLpToken'] = getLpTokenLink
      obj['tokenType'] = tokenTypeBoolean
      obj['currentPoolTokenApproval'] = await allowance(wantAddress, userAddress, aquaFarmAddress)
      obj['depositFee'] = depositFee
      obj['autoCompoundFee'] = autoCompoundFee
      obj['platformFee'] = platformFee
      obj['buyBackFe e'] = buyBackFee
      obj['tokenAPR'] = tokenAPR
      obj['orgTvl'] = orgTvl
      obj['vaultMultiplier'] = parseFloat(poolDetails['allocPoint']) / 100
    } else {
      obj['tokenNameGetLP'] = tokenName
      obj['id'] = poolId
      obj['usdLiquidBAlance'] = 0
      obj['liquidBalance'] = '0.0000'
      obj['currentLpDeposit'] = '0.0000'
      obj['pendingAquaEarnings'] = '0.0000'
      obj['token'] = tokenList[poolId][0]
      obj['tokenTvl'] = tvl
      obj['tokenYield'] = tokenYield
      obj['lpType'] = tokenType
      obj['dailyPercentage'] = farmApr
      obj['currentBalance'] = '0.0000'
      obj['totalEarned'] = '0.0000 ' + tokenType
      obj['reward'] = '0'
      obj['rewardToken'] = '0.0000'
      obj['assetTokenPrice'] = assetPrice
      obj['farmName'] = farmName
      obj['farmContract'] = `https://bscscan.com/address/${aquaFarmAddress}#code`
      obj['vaultContractt'] = `https://bscscan.com/address/${poolDetails['strat']}#code`
      obj['farmApy'] = parentFarmApy * 100
      obj['optimalCompoundsPerYear'] = '0'
      obj['aquaApr'] = tokenYield
      obj['totalAPY2'] = (tokenYield + parentFarmApy * 100).toFixed(2)
      obj['totalApy'] = tokenYield + parentFarmApy * 100
      obj['getLpToken'] = getLpTokenLink
      obj['tokenType'] = tokenTypeBoolean
      obj['currentPoolTokenApproval'] = '0'
      obj['depositFee'] = depositFee
      obj['autoCompoundFee'] = autoCompoundFee
      obj['platformFee'] = platformFee
      obj['buyBackFee'] = buyBackFee
      obj['tokenAPR'] = tokenAPR
      obj['orgTvl'] = orgTvl
      obj['vaultMultiplier'] = parseFloat(poolDetails['allocPoint']) / 100
    }
    return obj
  } catch (err) {
    console.log(err)
  }
}

const getName = async (assetAddress: any, flag: boolean) => {
  /*
   * flag= true means lp
   * flag = false means normal
   */
  const lpInstance: any = await selectInstance(instType.PANCAKELP, assetAddress)
  if (!flag) {
    let name = await lpInstance.methods.symbol().call()
    name = name.toUpperCase()
    return `${name}`
  } else {
    const token0Address = await token1(assetAddress)
    const token1Address = await token2(assetAddress)
    if (token0Address === zeroAddress && token1Address === zeroAddress) {
      let name = await lpInstance.methods.symbol().call()
      name = name.toUpperCase()
      return `${name}`
    }
    let token0Instance: any
    let token1Instance: any
    token0Instance = await selectInstance(instType.PANCAKELP, token0Address)
    token1Instance = await selectInstance(instType.PANCAKELP, token1Address)
    let token0Name = await token0Instance.methods.symbol().call()
    let token1Name = await token1Instance.methods.symbol().call()
    token0Name = token0Name.toUpperCase()
    token1Name = token1Name.toUpperCase()
    if (token0Name === 'WBNB') token0Name = 'BNB'
    if (token1Name === 'WBNB') token1Name = 'BNB'
    return `${token0Name}-${token1Name}`
  }
}

export const getPendingAquaClaim = async (currentUserAddress: any, poolId: any) => {
  try {
    var currentPendingAqua = await pendingAQUA(poolId, currentUserAddress)
    currentPendingAqua = convertToEther(currentPendingAqua)
    return currentPendingAqua
  } catch (err) {
    //console.log(err)
  }
}

export const harvestLpTokens = async (id: any, userAddress: any) => {
  try {
    if (id !== -1) await handleWithdraw(id, 0, userAddress)
    else await handleAquaAutoCompHarvest(userAddress)
  } catch (err) {
    console.log(err)
  }
}

export const harvestAllLpTokens = async (userAddress: any) => {
  try {
    const poolLength: any = await getPoolLength()
    const aquaFarmInstance: any = await selectInstance(instType.AQUAFARM, aquaFarmAddress, true)
    const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress)
    const userDetails = await poolInstance.methods.userInfo(userAddress).call()
    const userShares = convertToEther(userDetails['shares'])
    for (let i = 0; i < poolLength; i++) {
      if (bluePlanetpoolsId.includes(i)) {
        let currentLPdeposit: any = await aquaFarmInstance.methods.userInfo(i, userAddress).call()
        if (currentLPdeposit['shares'] > 0) {
          await handleWithdraw(i, 0, userAddress)
        }
      }
    }
    if (userShares > 0) await harvestLpTokens(-1, userAddress)
  } catch (err) {
    console.log(err)
  }
}

const getPoolLength = async () => {
  try {
    const length = await poolLength(aquaFarmAddress)
    return 57
  } catch (err) {
    console.log(err)
  }
}

export const returnPoolData = async (userAddress: any) => {
  try {
    const poolLength: any = await getPoolLength()
    let result = []
    let lpStatusFunction = []
    for (let i = 0; i < poolLength; i++) {
      if (bluePlanetpoolsId.includes(i)) {
        const lpStatus: any = getUserLpStatus(userAddress, i)
        lpStatusFunction.push(lpStatus)
      }
    }
    lpStatusFunction.push(returnUserAquaAutoCompundingPoolData(userAddress))
    result = await Promise.all(lpStatusFunction)
    return result
  } catch (err) {
    console.log(err)
  }
}

export const returnUserAquaAutoCompundingPoolData = async (userAddress: any) => {
  const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress)

  let userAquaBalance: any = 0
  let currentBalance: any = 0
  let aquaBalance: any = 0
  let currentPoolTokenApproval: any = 0
  let pending: any = 0
  let callFee: any = 0
  aquaBalance = await poolInstance.methods.balanceOf().call()
  aquaBalance = convertToEther(aquaBalance)

  let totalShares = await poolInstance.methods.totalShares().call()
  totalShares = convertToEther(totalShares)
  callFee = await poolInstance.methods.callFee().call()
  callFee = parseFloat(callFee) / 10000
  let pendingAqua = await pendingAQUA('1', aquaAutoCompPoolAddress)
  let aquaBal = await balanceOf(aquaAddress, aquaAutoCompPoolAddress)
  pending = convertToEther(pendingAqua) + convertToEther(aquaBal)
  pending = parseFloat(pending)
  if (userAddress != null) {
    userAquaBalance = await balanceOf(aquaAddress, userAddress)
    let x = new BigNumber(userAquaBalance)
    let balanceVal: any = x.div(1e18).toString()
    userAquaBalance = getFinalBalance(balanceVal)
    const userDetails = await poolInstance.methods.userInfo(userAddress).call()
    const userShares = convertToEther(userDetails['shares'])
    currentBalance = (userShares / totalShares) * aquaBalance
    currentPoolTokenApproval = await allowance(aquaAddress, userAddress, aquaAutoCompPoolAddress)
  }
  const assetPrice = await getAquaPrice()

  let perBlock = await AQUAPerBlock()
  const aquaPerBlock = convertToEther(perBlock)
  const tokenTypeBoolean = false

  const poolDetails = await poolInfo(aquaFarmAddress, '1')
  const poolAllocPoint = poolDetails['allocPoint']
  let totalAlloc = await totalAllocPoint()
  const strategyInstance: any = await selectInstance(instType.STRATEGY_ABI, poolDetails['strat'])

  let tvl = await strategyInstance.methods.wantLockedTotal().call()
  tvl = convertToEther(tvl)
  const orgTvl = tvl
  tvl = tvl * assetPrice

  const tokenType = ''
  const poolType = 'AUTO-COMPOUNDING'
  const farmName = 'AQUA'
  const getLpTokenLink = null

  let tokenYield =
    tvl > 0
      ? (aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * 365 * 100) / orgTvl
      : aquaPerBlock * 28800 * (poolAllocPoint / totalAlloc) * assetPrice * 365 * 100

  const roiPerDay = tokenYield / 365 // rate of interest per day
  tokenYield = await getAutoAquaTokenYield(tokenYield, 36500)

  return {
    tokenNameGetLP: 'AQUA',
    totalPendingAqua: pending,
    pendingAquaEarnings: pending * callFee,
    rewardToken: pending * callFee,
    reward: pending * (await getAquaPrice()) * callFee,
    id: -1,
    liquidBalance: userAquaBalance,
    usdLiquidBAlance: userAquaBalance * assetPrice,
    currentLpDeposit: currentBalance,
    token: 'AQUA',
    tokenTvl: aquaBalance * assetPrice,
    tokenYield: tokenYield,
    dailyPercentage: roiPerDay,
    totalEarned: `${currentBalance.toFixed(4)} ` + tokenType,
    lpType: tokenType,
    currentBalance: currentBalance * assetPrice,
    assetTokenPrice: assetPrice,
    farmName: farmName,
    farmContract: `https://bscscan.com/address/${aquaFarmAddress}#code`,
    vaultContractt: `https://bscscan.com/address/${aquaAutoCompPoolAddress}#code`,
    farmApy: tokenYield,
    optimalCompoundsPerYear: 0,
    aquaApr: roiPerDay,
    totalAPY2: tokenYield,
    totalApy: tokenYield,
    getLpToken: getLpTokenLink,
    tokenType: tokenTypeBoolean,
    currentPoolTokenApproval: currentPoolTokenApproval,
    tokenAPR: roiPerDay * 365,
    orgTvl: aquaBalance,
    poolType: poolType,
  }
}

export const handleAquaAutoCompDeposit = async (amount: any, userAddress: any) => {
  const approvalAmount = '100000000000000000000000000000000000000000000000000000000000000000000000000000'

  if (userAddress) {
    try {
      const depositAmount = amount
      const LPinstance: any = await selectInstance(instType.PANCAKELP, aquaAddress, true)
      const getAllowance = await allowance(aquaAddress, userAddress, aquaAutoCompPoolAddress)
      const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress, true)

      const promisify = (inner: any) =>
        new Promise((resolve, reject) =>
          inner((err: any, res: any) => {
            if (err) {
              reject(err)
            }
            resolve(res)
          })
        )

      if (depositAmount > getAllowance) {
        await promisify((cb: any) => {
          LPinstance.methods
            .approve(aquaAutoCompPoolAddress, approvalAmount)
            .send({ from: userAddress })
            .once('receipt', function (receipt: any) {
              cb()
            })
            .on('error', function (error: any) {
              cb(error)
            })
        })
      }
      await promisify((cb: any) => {
        poolInstance.methods
          .deposit(convertToWei(depositAmount))
          .send({ from: userAddress })
          .once('receipt', function (receipt: any) {
            cb()
          })
          .on('error', function (error: any) {
            cb(error)
          })
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export const handleAquaAutoCompWithdraw = async (amount: any, userAddress: any) => {
  if (userAddress) {
    try {
      const withdrawAmount = amount
      const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress, true)
      const userDetails = await poolInstance.methods.userInfo(userAddress).call()
      const userShares = convertToEther(userDetails['shares'])
      const depositedBal = await getCurrentBalance(-1, userAddress)
      const promisify = (inner: any) =>
        new Promise((resolve, reject) =>
          inner((err: any, res: any) => {
            if (err) {
              reject(err)
            }
            resolve(res)
          })
        )

      await promisify((cb: any) => {
        if (withdrawAmount < depositedBal) {
          poolInstance.methods
            .withdraw(convertToWei(withdrawAmount))
            .send({ from: userAddress })
            .once('receipt', function (receipt: any) {
              cb()
            })
            .on('error', function (error: any) {
              cb(error)
            })
        } else {
          poolInstance.methods
            .withdrawAll()
            .send({ from: userAddress })
            .once('receipt', function (receipt: any) {
              cb()
            })
            .on('error', function (error: any) {
              cb(error)
            })
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export const handleAquaAutoCompHarvest = async (userAddress: any) => {
  if (userAddress) {
    try {
      const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress, true)

      const promisify = (inner: any) =>
        new Promise((resolve, reject) =>
          inner((err: any, res: any) => {
            if (err) {
              reject(err)
            }
            resolve(res)
          })
        )

      await promisify((cb: any) => {
        poolInstance.methods
          .harvest()
          .send({ from: userAddress })
          .once('receipt', function (receipt: any) {
            cb()
          })
          .on('error', function (error: any) {
            cb(error)
          })
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export const returnPlatformData = async (userAddress: any) => {
  try {
    //console.log("1--------")
    const otherPlanetsTvl: any = await getTotalTvlOfOtherPlanets()
    const poolDetails: any = await returnPoolData(userAddress)
    let totalTvl: number = 0
    let myPortfolioCurrentApy: any = 0
    let myPortfolioRewards: number = 0
    let maxSupply: number = 0
    let totalBalance: number = 0

    if (userAddress !== null) {
      let poolLength: any = await getPoolLength()
      let i = -1
      let promisePriceArr = []
      let promiseDepositBal = []
      while (i < poolLength) {
        if (otherPlanetsPoolsId.includes(i) || bluePlanetpoolsId.includes(i) || i === -1) {
          promisePriceArr.push(getPoolTokenAssetPrice(i))
          promiseDepositBal.push(getCurrentBalance(i, userAddress))
        }
        i++
      }
      promisePriceArr = await Promise.all(promisePriceArr)
      promiseDepositBal = await Promise.all(promiseDepositBal)

      for (i = 0; i < promisePriceArr.length; i++) {
        totalBalance += promisePriceArr[i] * parseFloat(promiseDepositBal[i])
      }
    }
    //console.log("2--------")
    poolDetails.forEach((element: { [x: string]: any }) => {
      if (element !== undefined && element['orgTvl'] !== undefined) {
        totalTvl += parseFloat(element['orgTvl'])
        if (userAddress != null) {
          const newApy = element['totalAPY2']
          myPortfolioCurrentApy = myPortfolioCurrentApy + parseFloat(newApy) * parseFloat(element['currentBalance'])
          myPortfolioRewards += parseFloat(element['reward'])
        }
      }
    })
    myPortfolioCurrentApy = userAddress != null && totalBalance !== 0 ? myPortfolioCurrentApy / totalBalance : myPortfolioCurrentApy
    let aquaTotalSupply = parseFloat(await totalSupply(aquaAddress))
    maxSupply = await AQUAMaxSupply()
    maxSupply = convertToEther(maxSupply)
    let deadAquaBal = await balanceOf(aquaAddress, '0x000000000000000000000000000000000000dEaD')
    let obj = {
      circulatingSupply: convertToEther(aquaTotalSupply),
      maxSupply: maxSupply - convertToEther(deadAquaBal),
      totalTvl: totalTvl + otherPlanetsTvl,
      myPortfolioCurrentApy: myPortfolioCurrentApy,
      myPortfolioRewards: myPortfolioRewards,
      totalBalance: totalBalance,
      marketCap: convertToEther(aquaTotalSupply) * (await getAquaPrice()),
      totalProfitGenerated: convertToEther(aquaTotalSupply) * (await getAquaPrice()),
      perDayProfitGenerated: convertToEther(deadAquaBal) * (await getAquaPrice()),
    }
    const aquaPlatformData = {
      poolData: [...poolDetails],
      plateFormData: { ...obj }
    }
    return aquaPlatformData
  } catch (err) {
    console.log(err)
    const emptyPlatformData = {
      poolData: [],
      plateFormData: {}
    }
    return emptyPlatformData
  }
}

export const getCurrentApproval = async (poolId: any, userAddress: any) => {
  try {
    const aquafarmInstance: any = await selectInstance(instType.AQUAFARM, aquaFarmAddress, true)
    const poolDetails = await aquafarmInstance.methods.poolInfo(poolId).call()
    const lpAddress = poolDetails['want']
    var getAllowance = await allowance(lpAddress, userAddress, aquaFarmAddress)
    getAllowance = convertToEther(getAllowance)
    return getAllowance
  } catch (err) {
    console.log(err)
  }
}

export const retPlatProf = async (myAquaHolding: number, aquaSupply: number, monthlyProtProf: number) => {
  const monthlyIncome = (myAquaHolding / aquaSupply) * monthlyProtProf
  const data = {
    monthlyIncome: monthlyIncome,
    monthlyIncomePerAqua: ((myAquaHolding / aquaSupply) * monthlyProtProf) / myAquaHolding,
    apr: ((monthlyIncome * 12) / (myAquaHolding * (await getAquaPrice()))) * 100,
  }
  return data
}

export const retTotSuppAndUserBal = async (userAddress: any) => {
  try {
    const deadAddress = '0x000000000000000000000000000000000000dead'
    let aquaTotalSupply = await totalSupply(aquaAddress)
    aquaTotalSupply = convertToEther(aquaTotalSupply)
    let burnedAqua = await balanceOf(aquaAddress, deadAddress)
    burnedAqua = convertToEther(burnedAqua)
    let aquaInVault: any = 0
    let aquaInAutoCompoundingVault: any = 0
    let aquaInAquaBnbVault: any = 0
    let aquaInAquaCakeVault: any = 0
    let aquaInAquaBusdVault: any = 0
    let withdrawFee = 0.999
    if (userAddress !== null) aquaInVault = await getCurrentBalance(1, userAddress)

    /*USER AQUA BALANCE IN AQUA-BNB VAULT*/
    let poolLpAddress: any = (await poolInfo(aquaFarmAddress, '13'))['want']

    let lpInAquaBnbVault: any = 0
    if (userAddress !== null) {
      let staked = await stakedWantTokens(aquaFarmAddress, '13', userAddress)
      staked = convertToEther(staked)
      lpInAquaBnbVault = (staked * withdrawFee).toFixed(0)
    }

    if (lpInAquaBnbVault !== 0 && userAddress) {
      let lpTotalSupply = await totalSupply(poolLpAddress)
      lpTotalSupply = convertToEther(lpTotalSupply)
      let contractAquaBalance = await balanceOf(aquaAddress, poolLpAddress)
      contractAquaBalance = convertToEther(contractAquaBalance)
      aquaInAquaBnbVault = noExponents((lpInAquaBnbVault / lpTotalSupply) * contractAquaBalance)
    }

    /*USER AQUA BALANCE IN AQUA-CAKE VAULT*/
    poolLpAddress = (await poolInfo(aquaFarmAddress, '14'))['want']

    let lpInAquaCakeVault: any = 0
    if (userAddress !== null) {
      let staked = await stakedWantTokens(aquaFarmAddress, '14', userAddress)
      staked = convertToEther(staked)
      lpInAquaCakeVault = (staked * withdrawFee).toFixed(0)
    }

    if (lpInAquaCakeVault !== 0) {
      let lpTotalSupply = await totalSupply(poolLpAddress)
      lpTotalSupply = convertToEther(lpTotalSupply)
      let contractAquaBalance = await balanceOf(aquaAddress, poolLpAddress)
      contractAquaBalance = convertToEther(contractAquaBalance)
      aquaInAquaCakeVault = noExponents((lpInAquaCakeVault / lpTotalSupply) * contractAquaBalance)
    }

    /*USER AQUA BALANCE IN AQUA-BUSD VAULT*/
    poolLpAddress = (await poolInfo(aquaFarmAddress, '36'))['want']

    let lpInAquaBusdVault: any = 0
    if (userAddress !== null) {
      let staked = await stakedWantTokens(aquaFarmAddress, '36', userAddress)
      staked = convertToEther(staked)
      lpInAquaBusdVault = (staked * withdrawFee).toFixed(0)
    }

    if (lpInAquaBusdVault !== 0 && userAddress) {
      let lpTotalSupply = await totalSupply(poolLpAddress)
      lpTotalSupply = convertToEther(lpTotalSupply)
      let contractAquaBalance = await balanceOf(aquaAddress, poolLpAddress)
      contractAquaBalance = convertToEther(contractAquaBalance)
      aquaInAquaBusdVault = noExponents((lpInAquaBusdVault / lpTotalSupply) * contractAquaBalance)
    }

    //user's aqua bal in aqua auto-componding vault

    const poolInstance: any = await selectInstance(instType.AQUA_AUTO_COMP, aquaAutoCompPoolAddress)
    let userDetails

    if (userAddress) userDetails = await poolInstance.methods.userInfo(userAddress).call()
    let userShares = 0
    if (userAddress) userShares = convertToEther(userDetails['shares'])
    let totalShares = await poolInstance.methods.totalShares().call()
    totalShares = convertToEther(totalShares)
    let aquaBalance = await poolInstance.methods.balanceOf().call()
    aquaBalance = convertToEther(aquaBalance)
    aquaInAutoCompoundingVault = (userShares / totalShares) * aquaBalance

    let userBal: any
    if (userAddress !== null) {
      const bal = await balanceOf(aquaAddress, userAddress)
      const val = convertToEther(bal)
      userBal =
        parseFloat(val) +
        +parseFloat(aquaInVault) +
        parseFloat(aquaInAquaCakeVault) +
        parseFloat(aquaInAquaBnbVault) +
        parseFloat(aquaInAquaBusdVault) +
        parseFloat(aquaInAutoCompoundingVault)
    } else userBal = 0
    return {
      circulatingSupply: aquaTotalSupply - burnedAqua,
      userBalance: noExponents(userBal),
      userBalInUsd: await getAquaPrice(),
      maxSupply: 100000 - burnedAqua,
    }
  } catch (error) {
    console.log('error==>', error)
  }
}

const noExponents = function (num: any) {
  var data = String(num).split(/[eE]/)
  if (data.length === 1) return data[0]

  var z = '',
    sign = num < 0 ? '-' : '',
    str = data[0].replace('.', ''),
    mag = Number(data[1]) + 1

  if (mag < 0) {
    z = sign + '0.'
    while (mag++) z += '0'
    return z + str.replace(/^\-/, '')
  }
  mag -= str.length
  while (mag--) z += '0'
  return str + z
}

export const getCompensationDataForUser = async (userAddress: string, user_type: string) => {
  // console.log("userAddress", userAddress, "user type", user_type);
  try{
    let compensation_address = user_type == "poor" ? compensation_address_poor : compensation_address_rich;
    const compensationInstance: any = new wallet.web3.eth.Contract(COMPENSATION_ABI, compensation_address);
    const data = await compensationInstance.methods.usersCompensationDetails(userAddress).call();
    const currentRound = await compensationInstance.methods.currentRound().call();
    let compAmountInRound: any = 0;
    if(currentRound > 0 && data.remainingAmount > 0){
      data.unclaimedAmount = 0;
      for(let i = +data.compensatedTillRound; i < currentRound; i++){
        // console.log("value of index", i)
        try{
          compAmountInRound = await compensationInstance.methods.fundsInRound(i).call();
        } catch (err){
          console.log(err)
        }
        
        // console.log("compAmountInRound", compAmountInRound, i)
        let unclaimedAmount = gConvertToEther((+compAmountInRound * +data.whatPercentage), 10)

        data.unclaimedAmount += +unclaimedAmount.split(".")[0];
      }
      // console.log("unclaimed amount", data.unclaimedAmount);
    }
    
    return data;
  } catch(error){
    console.log("error for userAddress", userAddress, error);
    return {};
  }
}

export const gConvertToEther = (data: any, decimals: number): any => {
  data = noExponents(data)
  return noExponents(formatUnits(data.toString(), decimals)) //decimals = 18,8
}

export const selectInstance = async (type: any, contractAddress: any, write = false) => {
  switch (type) {
    case 'AQUAFARM':
      return new wallet.web3.eth.Contract(aquaFarmAbi, contractAddress)
    // case 'GAMMAFARM':
    //   return new wallet.web3.eth.Contract(gammaFarmAbi, contractAddress)
    case 'PANCAKELP':
      return new wallet.web3.eth.Contract(pancakeLPabi, contractAddress)
    case 'MASTERCHEF':
      return new wallet.web3.eth.Contract(masterchefAbi, contractAddress)
    case 'STRATEGY_ABI':
      return new wallet.web3.eth.Contract(STRATEGY_ABI, contractAddress)
    case 'FACTORY':
      return new wallet.web3.eth.Contract(pancakeFactoryAbi, contractAddress)
    case 'AQUA_AUTO_COMP':
      return new wallet.web3.eth.Contract(aquaAutoCompPoolAbi, contractAddress)
    default:
      return null
  }
}