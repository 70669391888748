import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import { Button } from 'shared/button'
import { PrimaryBtn } from '../style'

/* ============================== Liquidity - Details ========================== */

export const LiquidityCont = styled.div`
  width: 100%;
  box-sizing: border-box;
  .liquidity-header {
    margin-bottom: 0px;
    @media (max-width: 799px) {
      margin-bottom: 5px;
    }
  }
  .added-ld {
    margin-top: 20px;
  }
  .clock-icon {
    cursor: pointer;
  }
  .two-img {
    margin-right: 20px;
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-right: 30px;
    }
  }
  .add-liquidity-btn {
    margin-top: 20px;
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-top: 30px;
    }
  }
  //liquidityContainer
  @media (min-width: 1050px) {
    &.liquidityContainer{
      zoom:0.8 !important;
      max-width: 550px;
    }
  }
  @media (min-width: 1350px) {
    &.liquidityContainer{
      zoom:0.85 !important;
      max-width: 620px;
    }
  }
  @media (min-width: 1550px) {
    &.liquidityContainer{
      zoom: 0.9 !important;
      max-width: 620px;
    }
  }
  @media (min-width: 1750px) {
    zoom:1 !important;
    max-width: 620px;
  }
  &.liquidityContainer{
    zoom: 0.95 !important;
    max-width: 620px;
  }
  @media (min-width: 2160px) {
    &.liquidityContainer{
      zoom:1 !important;
      max-width: 620px;
    }
  }
`
export const EmptyLDCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.themeBackground};
  width: 100%;
  height: 137px;
  border-radius: 20px;
  box-sizing: border-box;
  margin-top: 30px;
`
export const LDListCont = styled.div`
  margin-top: 40px;
  .ld-row {
    cursor: pointer;
  }
  &.lpList{
    margin-top:0px;
  }
`
export const UserLDList = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`
export const DownImg = styled.img<any>`
  cursor: pointer;
  transform: ${(props: any) => (props.iconStatus ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: 0.3s;
  &.down-icon-liquidity{
    width: 24px;
    height: 15px;
  }
  @media (max-width: 799px) {
    &.down-icon-liquidity{
      width: 20px;
      height: 10px;
    }
  }
`

export const LDInfoCont = styled.div`
  margin-top: 30px;
  .ld-info {
    margin-bottom: 10px;
  }
`
export const RemoveLDBtn = styled(Button)`
  padding: 15px 0;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 19px;
  opacity: 1;
  text-align: center;
  border: 1px solid transparent;
  background: transparent linear-gradient(270deg, #3037DA 0%, #55B7FF 100%) 0% 0% no-repeat padding-box;
  color: #FFFFFF;
  &:disabled {
    opacity: 0.2;
    background: transparent linear-gradient(270deg, #3037DA 0%, #55B7FF 100%) 0% 0% no-repeat padding-box;padding-box;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    //height: 60px;
    padding: 15px 0 15px 0;
    font-size: 19px;
    line-height: 24px;
  }
`
export const LDInfoText = styled.p`
  font-size: 14px;
  line-height:18px;
  margin:0;
  color:${colors.white};
  font-family: MontserratSemiBold;
  text-align: center;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height:20px;
  }
  span{
    color:${colors.lightBlue};
  }
  img {
    width:16px;
    height:16px;
    margin:0px 10px 0 0;
  }
`
/* ============================== Remove Liquidity ========================== */

export const RemoveLDCont = styled.div`
  .remove-ld-header {
    margin-bottom: 24px;
  }
  .clock-icon {
    cursor: pointer;
  }
`
export const PercentageText = styled.p<any>`
  font-size: 40px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  opacity: ${(props: any) => (props.status ? 1 : 0.4)};
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 60px;
  }
`
export const PercentageBtnCont = styled.div`
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-gap: 20px;
  align-items: center;
`
export const PercentageBox = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  background: #55B7FF33;
  border-radius: 10px;
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 0px 3px 10px #0000004D;
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 50px;
  }
  p{
    font-size: 14px;
    line-height: 18px;
    color: #55B7FF;
    font-family:MontserratBold;
    margin:0;
    opacity:1;
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
`
export const RemoveTokenCont = styled.div`
  margin: 30px 0 40px 0;
  .remove-token {
    margin-bottom: 10px;
  }
  .remove-token-icon {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 35px;
      height: 35px;
      margin-right: 20px;
    }
  }
`
/* ============================== create lp pair ========================= */

export const CreateLpCont = styled.div`
  .create-lp {
    margin-top: 9px;
    text-align: center;
  }
  .token-input {
    margin-top: 18px;
  }
  .token-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .arrow-down {
    width: 17px;
    height: 11px;
    margin-left: 15px;
  }
  .warning-text {
    color: ${colors.red};
    margin-top: 20px;
  }
`
export const CreateLpBtnCont = styled.div<any>`
  margin-top: ${(props: any) => (props.marginStatus ? '20px' : '30px')};
  width: 100%;
  &.add-stable-liquidity{
    margin-top: 20px;
  }
`
export const ApproveBtnGroup = styled.div<any>`
  display: grid;
  grid-template-columns: ${(props: any) => (props.approveBtnStatus ? '1fr 1fr' : '1fr')};
  grid-gap: 10px;
  margin-bottom: 20px;
`
/* ============================== AddLiquidity ========================== */

export const AddLDCont = styled.div`
  .add-token-ld {
    margin-top: 9px;
    text-align: center;
  }
  .token-input {
    margin-top: 18px;
  }
  .token-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .arrow-down {
    width: 17px;
    height: 11px;
    margin-left: 15px;
  }
  .warning-text {
    color: ${colors.red};
    margin-top: 20px;
    text-align: center;
  }
`
export const AddLDTokenCont = styled.div`
  margin-top: 35px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: ${screenSizes.mediaM}px) {
    &.tokensContainer {
      padding: 0px 0px;
      margin-top: 20px
    }
  }
`
export const AddLDCard = styled.div`
  padding: 24px 30px;
  border: 1px solid ${colors.borderColor};
  background: ${colors.inputBg};
  border-radius: 20px;
  &.tokenCard{
    padding: 20px 30px;
    margin-bottom: 20px;
    background: #000;
    @media (max-width: 799px) {
      padding: 10px 15px;
    }
  }
  &.removeStableLiquidity{
    background: #000000 !important;
    opacity: 1;
  }
  @media (max-width: 799px) {
    padding: 8px 10px;
    &.zapInReceive{
      margin-top: 20px;
    }
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    &.zapInReceive{
      margin-top: 30px;
    }
  }
`
export const PluIconCont = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(85, 183, 255, 0.2);
  border-radius: 100px;
  margin: 20px auto;
  img {
    //transform: rotate(45deg);
  }
  cursor: pointer;
  &.addSymbol{
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border: 1px solid #FFFFFF26;
    margin: -10px auto;
    position: relative;
  }
  @media (max-width: 799px) {
    &.addSymbol{
      border-radius: 15px;
      border: 1px solid #FFFFFF26;
      margin: -7px auto;
      position: relative;
    }
    width: 40px;
    height: 40px;
  }
`
export const TokenSelectCont = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-left: 20px;
  }
`
export const PoolDetailsCont = styled.div`
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  .pool-info {
    margin-top: 10px;
  }
`
export const PoolInfoText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.lightBlue};
  font-family: MontserratSemiBold;
  margin: 0;
  &.poolDetails{
    color: #FFFFFF64;
  }
  @media (max-width: 799px) {
    &.poolDetails{
      font-size: 12px;
    }
  }
  &.addLiquidity{
    opacity: 0.4;
    color: #FFFFFF;
  }
`
export const ApproveBtn = styled(PrimaryBtn)`
  margin-bottom: 10px;
`
export const NoPairBtnCont = styled.div<any>`
  width: 100%;
  margin-top: ${(props: any) => (props.statusVal ? '0px' : '20px')};
  @media (min-width: ${screenSizes.mediaM}px) {
    margin-top: ${(props: any) => (props.statusVal ? '0px' : '30px')};
  }
`
export const AddLDBtnCont = styled.div<any>`
  margin-top: ${(props: any) => (props.marginStatus ? '20px' : '30px')};
  width: 100%;
`
/* ======================= Confirm Liquidity ============================== */

export const ConfirmLDCont = styled.div`
  width: 100%;
  max-width: 382px;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaM}px) {
    max-width: 415px;
  }
  .two-img {
    margin-right: 10px;
  }
  .close-ld {
    cursor: pointer;
  }
  .cnf-img-cont {
    margin: 24px 0 20px 0;
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-top: 36px 0 30px 0;
    }
  }
  .output-info {
    margin: 24px 0 40px 0;
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-top: 30px 0 55px 0;
    }
  }
`
export const PoolTokenText = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 30px;
  }
`
export const CnfLdInfoText = styled.p<any>`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 0;
  opacity: ${(props: any) => (props.light ? 0.4 : 1)};
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
`
export const CnfLdInfoCont = styled.div`
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .cnf-info-icon {
    margin-bottom: 10px;
  }
  .cnf-info-icon-rx{
    margin-bottom: 40px;
  }
  .cnf-info {
    margin-bottom: 20px;
  }
  button {
    margin-top: 40px;
  }
  &.receivedTokens{
    margin-bottom: 40px;
  }
`
export const DonutChart = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 200px;
  height: 200px;
  position: relative;
  margin: auto;
  background: conic-gradient(
    rgb(96, 74, 4) 0deg 80deg,
    rgba(83, 174, 148, 0.4) 80deg 190deg,
    rgba(39, 117, 202, 0.4) 190deg 360deg
    );
  background: ${(props: any) => (props.tokenTVL ? 'conic-gradient(rgb(96, 74, 4) 0deg '+(props.tokenTVL[0].tvl/props.totalTVL*360)+'deg, rgba(83, 174, 148, 0.4) '+props.tokenTVL[0].tvl/props.totalTVL*360+'deg '+(props.tokenTVL[1].tvl/props.totalTVL*360 + props.tokenTVL[0].tvl/props.totalTVL*360) +'deg, rgba(39, 117, 202, 0.4) '+(props.tokenTVL[1].tvl/props.totalTVL*360 + props.tokenTVL[0].tvl/props.totalTVL*360)+'deg '+360+'deg)' : 'conic-gradient(rgb(96, 74, 4) 0deg 80deg, rgba(83, 174, 148, 0.4) 80deg 190deg, rgba(39, 117, 202, 0.4) 190deg 360deg)')};
`
export const DonutHole = styled.div<any>`
  width: 160px; 
  height: 160px;
  border-radius: 50%;
  background: #000;
`
export const ProtocolCont = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  align-items: center;
  margin: 20px 0 30px 0;
  box-sizing: border-box;
  place-items: center;
`
export const ProtocolBox = styled.div<any>`
  text-align: center;
  height: 38px;
  max-width: 90px;
  font-size: 14px;
  line-height: 18px;
  font-family: MontserratBold;
  padding: 10px 20px;
  color: ${colors.lightBlue};
  background: ${(props: any) => (props.data == true ? '#55b7ff33' : 'transparent')};
  border-radius: 15px;
  border-style: solid;
  border-color: '#55b7ff33';
  cursor: pointer;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaM}px) {
    height: 50px;
    font-size: 16px;
    line-height: 17px;
    padding: 17px 30px;
    max-width: 130px;
  }
`
export const ProtocolInput = styled.input`
  text-align: center;
  background: ${colors.themeBackground};
  border: 1px solid ${colors.borderColor};
  border-radius: 15px;
  color: ${colors.white};
  height: 38px;
  font-size: 14px;
  line-height: 18px;
  font-family: MontserratBold;
  padding: 12px 25px;
  outline: none;
  max-width: 100px;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
    padding: 17px 20px 13px 20px;
    max-width: 129px;
    height: 50px;
  }
`