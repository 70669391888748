import { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions'
import { updateUserLendingData } from 'logic/action/lending.action'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import { FlexSBCont } from 'shared/styles/styled'
import { ExpandedCell, ExpendCellTitle, CellBtnGroup, TableInputCont, TableInput, MaxTxt, MaxTxtZero, TableBtn, WithdrawBtn, TransformBtn, EarningCont, ClaimTxt, LoadingText3, ClaimLoading1, ClaimLoading2, DepositBtn } from 'shared/styles/planetTableStyle'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { commaFy, getNumFormat } from 'service/globalFunctions'
import { transLinkUrl } from 'service/global-constant'
import Spinner from 'shared/spinner'
import { maxAmount } from 'modules/block-chain/lendingAbi'
import { selectInstance, instType, poolInfo, handleDeposit, handleWithdraw, harvestLpTokens, earnAquaProfits, isAllowanceApproved } from 'modules/block-chain/BlockChainMethods'
import { aquaAddress, aquaAutoCompPoolAddress, aquaFarmAddress, gammaFarmAdddress } from 'modules/block-chain/abi';
import history from 'modules/app/components/history'
import { Paths } from 'modules/app/components/routes/types'

const BoostVaultTransaction = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [depositAmount, setDepositAmount] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [depositLoading, setDepositLoading] = useState(false)
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [claimLoading, setClaimLoading] = useState(false)
    const [claimBoostLoading, setClaimBoostLoading] = useState(false)
    const [isAllowance, setIsAllowance] = useState(false)
    const { portfolioLoading } = selector.user
    const { data } = props

    useEffect(() => {
        const userAddress = selector.ethData.address
        const getIsAllowance = async () => {
            const addGroup = data.wantaddress_farmaddress.split('_')
            const wantAddress = data.wantAddress
            let farmAddress = ""
            if (addGroup.length > 1) {
                farmAddress = addGroup[1]
            }
            try {
                const isAllow = await isAllowanceApproved(userAddress, farmAddress, wantAddress)
                setIsAllowance(isAllow)
            }
            catch (error) {
                console.log("error=>", error)
            }
        }
        if (!!data) {
            getIsAllowance()
        }
        if(data.address !== undefined && data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()){
            // setDepositAmount(getNumFormat(data.walletBalance))
            setWithdrawAmount(getNumFormat(data.vaultBalance))
        }
    }, [selector.ethData.address, data])

    const handleDepositAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setDepositAmount(value)
        }
        if (!value) {
            setDepositAmount('')
        }
    }
    const handleWithdrawAmountChanges = (e: any) => {
        const { value } = e.target
        if (floatNumRegex.test(value.toString())) {
            setWithdrawAmount(value)
        }
        if (!value) {
            setWithdrawAmount('')
        }
    }

    const approveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const msg = type === 'success' ? `${data.name} approved` : `Failed to approve  ${data.name}`
        const dataVal: any = {
            type: type,
            message: msg,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleApprove = async () => {
        const userAddress = selector.ethData.address
        if (!!userAddress) {
            try {
                setApproveLoading(true)
                let farmAddress: any;
                let LPinstance: any
                if (data.poolId === -1) {
                    farmAddress = aquaAutoCompPoolAddress
                    LPinstance = await selectInstance(instType.PANCAKELP, aquaAddress, true)
                }
                else {
                    if (data.isNewFarm) {
                        farmAddress = gammaFarmAdddress
                    }
                    else {
                        farmAddress = aquaFarmAddress
                    }
                    const poolDetails = await poolInfo(farmAddress, data.poolId)
                    const lpAddress = poolDetails['want']
                    LPinstance = await selectInstance(instType.PANCAKELP, lpAddress, true)
                }
                await LPinstance.methods
                    .approve(farmAddress, maxAmount)
                    .send({ from: userAddress })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        approveNotification(type, receipt.transactionHash)
                        setApproveLoading(false)
                        setIsAllowance(true)
                    })
                    .on('error', function (error: any) {
                        approveNotification('failed')
                        setApproveLoading(false)
                    })
            }
            catch (error) {
                console.log("error==>", error)
                setApproveLoading(false)
            }
            finally {
                setApproveLoading(false)
            }
        }
    }

    const showNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const data: any = {
            type: type,
            message: '',
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(data))
    }

    const handleDepositAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(depositAmount) > 0 && data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()) {
            try {
                setDepositLoading(true)
                await handleDeposit(data.poolId, depositAmount, selector.ethData.address, showNotification, data)
                setDepositLoading(false)
                dispatch(updateUserLendingData(selector.ethData.address))
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setDepositLoading(false)
            }
        }
    }
    const handleWithdrawAmount = async () => {
        if (selector.ethData.ethWalletConnected && parseFloat(withdrawAmount) > 0) {
            try {
                setWithdrawLoading(true)
                await handleWithdraw(data.poolId, withdrawAmount, selector.ethData.address, showNotification, data)
                setWithdrawLoading(false)
                dispatch(updateUserLendingData(selector.ethData.address))
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setWithdrawLoading(false)
            }
        }
    }
    const handleClaimEarning = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                setClaimLoading(true)
                await harvestLpTokens(data.poolId, selector.ethData.address, showNotification, data)
                setClaimLoading(false)
                dispatch(updateUserBalance(selector.ethData.address))
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setClaimLoading(false)
            }
        }
    }

    const handleClaimBoostEarning = async () => {
        if (selector.ethData.ethWalletConnected) {
            try {
                setClaimBoostLoading(true)
                await earnAquaProfits(data.poolId, selector.ethData.address, showNotification, data)
                setClaimBoostLoading(false)
                dispatch(updateUserBalance(selector.ethData.address))
            } catch (error) {
                console.log('error==>', error)
            } finally {
                setClaimBoostLoading(false)
            }
        }
    }


    const handleTransform = () => {
        if(data.address !== undefined && data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()){
            history.push(`${Paths.swap}`, { tokenAdd: data.wantAddress })
        }
        
    }

    return (
        <Fragment>
            <ExpandedCell className="item1">
                <FlexSBCont>
                    <ExpendCellTitle light>In Wallet</ExpendCellTitle>
                    {portfolioLoading ? <LoadingText3 /> :
                        <ExpendCellTitle>
                            {`${commaFy(parseFloat(data.walletBalance).toFixed(4))} ($${commaFy(parseFloat(data.walletBalanceUSD).toFixed(2))})`}
                        </ExpendCellTitle>
                    }
                </FlexSBCont>
                <TableInputCont>
                    <TableInput disabled={data.address !== undefined && data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()} placeholder="0" onChange={handleDepositAmountChanges} value={depositAmount && depositAmount} />
                    {data.walletBalanceUSD > 0.01 && data.address.toLowerCase() !== '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase() ?
                        <MaxTxt onClick={() => setDepositAmount(getNumFormat(data.walletBalance))}>Max</MaxTxt>
                        :
                        <MaxTxtZero className={data.address !== undefined && data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase() ? 'disabled':''} onClick={() => data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase() ?setDepositAmount(getNumFormat(data.walletBalance)): ""}>Max</MaxTxtZero>
                    }
                </TableInputCont>
                <CellBtnGroup>
                    <TransformBtn disabled={data.address !== undefined && data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()} onClick={() => handleTransform()}>Buy {data.name}</TransformBtn>
                    {portfolioLoading ?
                        <DepositBtn disabled={true} style={{ opacity: 1 }}>Deposit</DepositBtn> :
                        <>
                            {isAllowance ?
                                <DepositBtn onClick={() => handleDepositAmount()} disabled={depositLoading || (data.address !== undefined && data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase())
                            }>{depositLoading ? <Spinner /> : 'Deposit'}</DepositBtn> :
                                <TableBtn onClick={() => handleApprove()} disabled={approveLoading || (data.address !== undefined && data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase())}>{approveLoading ? <Spinner /> : 'Approve'}</TableBtn>
                            }
                        </>
                    }
                </CellBtnGroup>
            </ExpandedCell>
            <ExpandedCell className="item2">
                <FlexSBCont>
                    <ExpendCellTitle light>In Vault</ExpendCellTitle>
                    {portfolioLoading ? <LoadingText3 /> :
                        <ExpendCellTitle>
                            {`${commaFy(parseFloat(data.vaultBalance).toFixed(4))} ($${commaFy(parseFloat(data.vaultBalanceUSD).toFixed(2))})`}
                        </ExpendCellTitle>
                    }
                </FlexSBCont>
                <TableInputCont>
                    <TableInput disabled={data.address !== undefined && data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase()} placeholder="0" onChange={handleWithdrawAmountChanges} value={withdrawAmount && withdrawAmount} />
                    {data.vaultBalanceUSD > 0.01 && data.address !== undefined && data.address.toLowerCase() !== '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase() ?
                        <MaxTxt onClick={() => setWithdrawAmount(getNumFormat(data.vaultBalance))}>Max</MaxTxt>
                        :
                        <MaxTxtZero className={data.address !== undefined && data.address.toLowerCase() === '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase() ? 'disabled':''} onClick={() => data.address.toLowerCase() !== '0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9'.toLowerCase() ?setWithdrawAmount(getNumFormat(data.vaultBalance)): ""}>Max</MaxTxtZero>
                    }
                </TableInputCont>
                <WithdrawBtn onClick={() => handleWithdrawAmount()} disabled={withdrawLoading || data.withdrew === true}>
                    {withdrawLoading ? <Spinner /> : 'Withdraw'}
                </WithdrawBtn>
            </ExpandedCell>
            <ExpandedCell className="item3">
                <ExpendCellTitle>Earnings</ExpendCellTitle>
                <EarningCont isBoostPool={data.isBoostPool}>
                    <div>
                        <FlexSBCont>
                            {portfolioLoading ? <ClaimLoading1 /> :
                                <ClaimTxt tokenType >{`${parseFloat(data.userRewards).toFixed(4)} GAMMA`}</ClaimTxt>
                            }
                            <div className="on-mob">
                                {portfolioLoading ? <ClaimLoading2 /> :
                                    <ClaimTxt light={parseFloat(data.userRewardsUSD) === 0}>${parseFloat(data.userRewardsUSD).toFixed(4)}</ClaimTxt>
                                }
                            </div>
                        </FlexSBCont>
                        <div className="on-web">
                            {portfolioLoading ? <ClaimLoading2 /> :
                                <ClaimTxt style={{ marginTop: 0 }} light={parseFloat(data.userRewardsUSD) === 0}>${parseFloat(data.userRewardsUSD).toFixed(4)}</ClaimTxt>
                            }
                        </div>
                        <TableBtn className='claim-btn' onClick={() => handleClaimEarning()} disabled={claimLoading}>
                            {claimLoading ? <Spinner /> : 'Collect'}
                        </TableBtn>
                    </div>
                    <div>
                        <FlexSBCont>
                            {portfolioLoading ? <ClaimLoading1 /> :
                                <ClaimTxt tokenType>{`${parseFloat(data.uranusRewards).toFixed(4)} GAMMA`}</ClaimTxt>
                            }
                            <div className="on-mob">
                                {portfolioLoading ? <ClaimLoading2 /> :
                                    <ClaimTxt light={parseFloat(data.uranusRewardsUSD) === 0}>${parseFloat(data.uranusRewardsUSD).toFixed(4)}</ClaimTxt>
                                }
                            </div>
                        </FlexSBCont>
                        <div className="on-web">
                            {portfolioLoading ? <ClaimLoading2 /> :
                                <ClaimTxt style={{ marginTop: 0 }} light={parseFloat(data.uranusRewardsUSD) === 0}>${parseFloat(data.uranusRewardsUSD).toFixed(4)}</ClaimTxt>
                            }
                        </div>
                        <TableBtn className='claim-btn' onClick={() => handleClaimBoostEarning()} disabled={claimBoostLoading}>
                            {claimBoostLoading ? <Spinner /> : data.strategyAddress.toLowerCase() == "0xf18F4770Ee76E6459b6b2CECc919Ea5D3c58e8D9".toLowerCase() ?'Stake Boost': 'Claim Boost'}
                        </TableBtn>
                    </div>
                </EarningCont>
            </ExpandedCell>

        </Fragment>
    )
}
export default BoostVaultTransaction
