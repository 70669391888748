import { SpinnerContainer, SpinnerLogo } from './style'

const Spinner = (props:any) => {
  const {style} = props
  return (
    <SpinnerContainer data={style}>
      <SpinnerLogo></SpinnerLogo>
    </SpinnerContainer>
  )
}

export default Spinner
