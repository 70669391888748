import { useState, useContext } from 'react'
import { FarmPoolContext } from 'context/FarmPoolContext'
import { FlexSBCont } from 'shared/styles/styled'
import { ExpandedCell, ExpendCellTitle, TableInfoRows, TableInfoBox, TableInfoTxt, CellDownIcon } from 'shared/styles/planetTableStyle'
import DownIcon from 'assets/icons/down.svg'
import { commaFy } from 'service/globalFunctions'
import InfoTooltip from 'shared/info-tool-tip'
import { useSelector } from 'react-redux'
import { allFusionPools, gammaFarmAdddress, gammaFarmAdddressOld } from 'modules/block-chain/abi'
import { convertToEther } from 'modules/block-chain/lendingAbi'
import momentTimeZone from 'moment-timezone'
import moment from 'moment'


const LockedPoolInfo = (props: any) => {
    const [showVaultInfo, setShowVaultInfo] = useState(false)
    const [showFeeInfo, setShowFeeInfo] = useState(false)
    const [showAPYCal, setShowAPyCal] = useState(false)
    const farmPoolData:any = useContext(FarmPoolContext);
    const selector = useSelector((state: any) => state)
    const { data } = props

    //moment.unix(userLock.unlockTime).format('MMM DD, YYYY hh:mm A') + ' (' + tz + ')';
    const _renderVaultInfo = () => {
        
        // console.log('_renderVaultInfo data', data, data.lockPoolInfo);
        return data.poolsLockArray.map((lockPoolInfo: any) => {
            var tz: any = momentTimeZone.tz.guess();
            tz = momentTimeZone.tz(tz).format('z');
            return (
                <TableInfoBox className='lockedLPCont'>
                    <FlexSBCont>
                        <TableInfoTxt light>{commaFy(parseFloat((convertToEther(lockPoolInfo.amount, 18))).toFixed(4))} {data.name}</TableInfoTxt>
                    </FlexSBCont>
                    <FlexSBCont>
                        <TableInfoTxt light>{commaFy((parseFloat((convertToEther(lockPoolInfo.amount, 18))) * data.price).toFixed(2))}</TableInfoTxt>
                    </FlexSBCont>
                    <FlexSBCont>
                        <TableInfoTxt>{moment.unix(lockPoolInfo.unlockTime).format('MMM DD, YYYY hh:mm A') + ' (' + tz + ')'}</TableInfoTxt>
                    </FlexSBCont>
                </TableInfoBox>
            )
        })

        
    }

    const _renderAPYCalculation = () => {
        let oldFarm = gammaFarmAdddressOld; 
        // console.log('gammaFarmAdddressOld', oldFarm);
        if(data.farmContractAddress != undefined && data.farmContractAddress.toLowerCase() == oldFarm.toLowerCase()) {
            return <TableInfoBox>
                <FlexSBCont>
                    <TableInfoTxt light>Trade Fee APR</TableInfoTxt>
                    <TableInfoTxt>{parseFloat(data.tradeFeeApr).toFixed(2)}%</TableInfoTxt>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>GAMMA APR</TableInfoTxt>
                    <TableInfoTxt>{"0.00"}%</TableInfoTxt>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Boost APR</TableInfoTxt>
                    <TableInfoTxt>{"0.00"}%</TableInfoTxt>
                </FlexSBCont>
                <FlexSBCont>
                    <TableInfoTxt light>Total APR</TableInfoTxt>
                    <TableInfoTxt>{data.tradeFeeApr && (!isFinite(data.tradeFeeApr) || data.tradeFeeApr > 10000) ? "> 10,000" : data.tradeFeeApr ? commaFy(data.tradeFeeApr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                </FlexSBCont>
            </TableInfoBox>
        }
        else {
            if(data.poolId >= 2) {
                return (
                    <TableInfoBox>
                        {!allFusionPools.includes(data.strategyAddress.toLowerCase()) && 
                        <FlexSBCont>
                            <TableInfoTxt light>Trade Fee APR</TableInfoTxt>
                            <TableInfoTxt>{parseFloat(data.tradeFeeApr).toFixed(2)}%</TableInfoTxt>
                        </FlexSBCont>
                        }
                        <FlexSBCont>
                            <TableInfoTxt light>GAMMA APR</TableInfoTxt>
                            <TableInfoTxt>{data.gammaApr && (!isFinite(data.gammaApr) || data.gammaApr > 10000) ? "> 10,000" : data.gammaApr ? commaFy(data.gammaApr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                        </FlexSBCont>
                        <FlexSBCont>
                            <TableInfoTxt light>Boost APR</TableInfoTxt>
                            <TableInfoTxt>{data.boostAPR && (!isFinite(data.boostAPR) || data.boostAPR > 10000) ? "> 10,000" : data.boostAPR ? commaFy(data.boostAPR.toFixed(2)) : "0.00"}%</TableInfoTxt>
                        </FlexSBCont>
                        {(data.isBoostedInNewFarm && (data.protocol.toLowerCase() == "thena" || data.protocol.toLowerCase() == "biswap" || data.protocol.toLowerCase() == "cake")) &&
                            <FlexSBCont>
                                <TableInfoTxt light>Parent Farm APR</TableInfoTxt>
                                <TableInfoTxt>{(data.parentFarmApr).toFixed(2)}%</TableInfoTxt>
                            </FlexSBCont>
                        }
                        <FlexSBCont>
                            <TableInfoTxt light>Total APR</TableInfoTxt>
                            <TableInfoTxt>{data.totalApr && (!isFinite(data.totalApr) || data.totalApr > 10000) ? "> 10,000" : data.totalApr ? commaFy(data.totalApr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                        </FlexSBCont>
                    </TableInfoBox>
                )
            }
            else {
                let apr = parseFloat(data.apr) ;
                let gammaSupplyApr = parseFloat(data.gammaSupplyApr) ;
                let totalApr = parseFloat(data.totalApr) ;
                return (
                    <TableInfoBox>
                        <FlexSBCont>
                            <TableInfoTxt light>Unstake Fee APR</TableInfoTxt>
                            <TableInfoTxt>{(parseFloat(data.widthdrawApr) * 100 ).toFixed(2)}%</TableInfoTxt>
                        </FlexSBCont>
                        <FlexSBCont>
                            <TableInfoTxt light>Farm GAMMA APR</TableInfoTxt>
                            <TableInfoTxt>{apr && (!isFinite(apr) || apr > 10000) ? "> 10,000" : apr ? commaFy(apr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                        </FlexSBCont>
                        {data.poolId == 1 &&
                            <FlexSBCont>
                                <TableInfoTxt light>BURN APR</TableInfoTxt>
                                <TableInfoTxt>{data.burnApy && (!isFinite(data.burnApy) || data.burnApy > 10000) ? "> 10,000" : data.burnApy ? commaFy(data.burnApy.toFixed(2)) : "0.00"}%</TableInfoTxt>
                            </FlexSBCont>
                        }
                        <FlexSBCont>
                            <TableInfoTxt light>Green Planet APR</TableInfoTxt>
                            <TableInfoTxt>{gammaSupplyApr && (!isFinite(gammaSupplyApr) || gammaSupplyApr > 10000) ? "> 10,000" : gammaSupplyApr ? commaFy(gammaSupplyApr.toFixed(2)) : "0.00"}%</TableInfoTxt>
                        </FlexSBCont>
                        <FlexSBCont>
                            <TableInfoTxt light>Total APR</TableInfoTxt>
                            <TableInfoTxt>{(totalApr).toFixed(2)}%</TableInfoTxt>
                        </FlexSBCont>
                    </TableInfoBox>
                )
            }
        }
        
    }

    // const 

    return (
        <TableInfoRows className='lockedLpSection'>
            <ExpandedCell className="item1">
                <div className="on-web">
                    <ExpendCellTitle>Locked Tokens</ExpendCellTitle>
                    <FlexSBCont className='lockedLPCont'>
                        <TableInfoTxt>Amount</TableInfoTxt>
                        <TableInfoTxt>Dollar Value</TableInfoTxt>
                        <TableInfoTxt>Estimated Unlock Time</TableInfoTxt>
                    </FlexSBCont>
                    {data && data.poolsLockArray && data.poolsLockArray.length > 0 && _renderVaultInfo()}
                </div>
                <div className="on-mob">
                    <FlexSBCont onClick={() => setShowVaultInfo(!showVaultInfo)}>
                        <ExpendCellTitle>Locked Tokens</ExpendCellTitle>
                        <CellDownIcon activeStatus={showVaultInfo}><img src={DownIcon} alt="" /></CellDownIcon>
                    </FlexSBCont>
                    {data && data.poolsLockArray && data.poolsLockArray.length > 0 && showVaultInfo && _renderVaultInfo()}
                </div>
            </ExpandedCell>
            
        </TableInfoRows>
    )
}
export default LockedPoolInfo
