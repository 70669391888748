import { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserBalance } from 'logic/action/user.actions'
import { notificationOpen, notificationClose } from "logic/action/notification.action";
import {
  CardCont,
  FlexSBCont,
  FlexCont,
  TitleText,
  InfoText,
  InfoSmallText,
  SelectCurrTxt,
  InfoLDText,
  TokenValInput,
  MaxTxt,
  TokenName,
  BackButton,
  PrimaryBtn,
} from '../style'
import {
  AddLDCont,
  AddLDTokenCont,
  AddLDCard,
  PluIconCont,
  TokenSelectCont,
  PoolDetailsCont,
  PoolInfoText,
  ApproveBtn,
  AddLDBtnCont,
} from './style'
import DownIcon from 'assets/icons/down-arrow-icon.png'
import ArrowDown from 'assets/icons/arrow-down.png'
import CrossIcon from 'assets/icons/greyPlus.svg'
import ClockIcon from 'assets/icons/clock-icon.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import TokenSelect from 'shared/token-select'
import TransactionInfo from 'shared/transaction-info'
import ConfirmLiquidity from './ConfirmLiquidity'
import {
  returnPairExistOrNot,
  returnPairAddress,
  userPoolDetails,
  returnQuotePrice,
  tokenAllowance,
  DEADLINE,
  selectInstance,
  instanceType,
  convertToWei,
  onAddLiquidityPopUp,
  userPoolDetailsNew,
  onAddLiquidityPopUpNew,
  getQuote,
} from 'modules/block-chain/Swap'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions';
import { setTransLink, setConfirmTransLink } from '../swapMethods'
import { PLANETROUTERABI, PLANETROUTERADDRESS } from 'modules/block-chain/SwapDexAbi'
import { MAX_INT, convertToEther, maxAmount } from 'modules/block-chain/lendingAbi';
import ArrowDownGrey from 'assets/icons/down_grey_icon.svg'
import {
  planetZapOneInchAddress,
  planetZapOneInchAbi,
  referralAddress,
  referralFees,
  protocols,
  thena_router_address,
  thena_router_abi,
  gammaUniproxyRouter,
  gammaUniproxyRouterABI
} from '../../../block-chain/PlanetZapOneInchAbi'
import wallet from 'modules/wallet/wallet'
import { BigNumber } from 'bignumber.js'
import { biswapRouterAddress, biswapRouterAbi } from 'modules/block-chain/abi';
import { updateUserLendingData } from 'logic/action/lending.action';

const AddLiquidity = (props: any) => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const { setLiquidityTab, tokenList, updateLiquidity, setShowHistory, updateLiquidityForAddLiquidity, tokenListForAddLiquidity } = props
  const [firstAmount, setFirstAmount] = useState('')
  const [secondAmount, setSecondAmount] = useState('')
  const [isAmtLoading, setIsAmtLoading] = useState(false)
  const [firstToken, setFirstToken] = useState<any>(!!updateLiquidityForAddLiquidity && updateLiquidityForAddLiquidity.length > 0 ? updateLiquidityForAddLiquidity[0] : {})
  const [secondToken, setSecondToken] = useState<any>(!!updateLiquidityForAddLiquidity && updateLiquidityForAddLiquidity.length > 0 ? updateLiquidityForAddLiquidity[1] : {})
  const [existTokenAdd, setExistTokeAdd] = useState('')
  const [tokenType, setTokenType] = useState('')
  const [showTokenModal, setShowTokenModal] = useState(false)
  const [showTransModal, setShowTransModal] = useState(false)
  const [transactionLink, setTransactionLink] = useState('')
  const [isPairExist, setIsPairExist] = useState(false)
  const [pairAddress, setPairAddress] = useState('')
  const [poolDetails, setPoolDetails] = useState<any>({})
  const [firstAllowance, setFirstAllowance] = useState<any>('0')
  const [secondAllowance, setSecondAllowance] = useState<any>('0')
  const [addLdLoading, setAddLdLoading] = useState(false)
  const [showCnfModal, setShowCnfModal] = useState(false)
  const [confirmLdData, setConformLdData] = useState<any>({})
  const [approve1Loading, setApprove1Loading] = useState(false)
  const [approve2Loading, setApprove2Loading] = useState(false)
  const [poolType, setPoolType] = useState('planet')
  const [isStablePool, setIsStablePool] = useState(false)
  const bnbSno = 1

  const liquidityPairList: any = ['gamma-btcb', 'aqua-btcb', 'hay-usdt', 'usdt-bnb', 'btcb-bnb', 'eth-bnb', 'bnbx-bnb'];

  const LPWantAddressList: any = {
    
    "gamma-btcb":{
      wantAddress: "0x43d427dF6957e180497248CaDc30cE7aa716FE52",
      type: "planet",
      stable: false
    },
    "aqua-btcb":{
      wantAddress: "0x70B606c23D6E786BE7accAf31C8fEcEaf846AdF3",
      type: "planet",
      stable: false
    },
    // "aqua":{
    //   wantAddress: "0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991",
    //   type: "planet",
    //   stable: false
    // },
    "hay-usdt":{
      wantAddress: "0xDf0B9b59E92A2554dEdB6F6F4AF6918d79DD54c4",
      type: "fusion",
      stable: false
    },
    "usdt-bnb":{
      wantAddress: "0x3ec1FFd5dc29190588608Ae9Fd4f93750e84CDA2",
      type: "fusion",
      stable: false
    },
    "btcb-bnb":{
      wantAddress: "0xD3C480EC7a47596fF8D63396227d1F7dC728A7f0",
      type: "fusion",
      stable: false
    },
    "eth-bnb":{
      wantAddress: "0x10bf6e7B28b1cfFb1c047D7F815953931e5Ee947",
      type: "fusion",
      stable: false
    },
    "bnbx-bnb":{
      wantAddress: "0x2ecBD508c00Bbc8aA0cdc9100bf3956fCabE7677",
      type: "fusion",
      stable: false
    },
  }

  // useEffect(() => {
  //   // console.log(tokenList)
  //   const getPairExistVal = async () => {
  //     let pairExist: any = false;
  //     try {
  //       pairExist = await returnPairExistOrNot(tokenList[0].address, tokenList[1].address)
  //       setIsPairExist(pairExist)
  //     }
  //     catch (error) {
  //       setIsPairExist(false)
  //       setPairAddress('')
  //     }

  //     try {
  //       if (pairExist) {
  //         const pairAddressVal = await returnPairAddress(tokenList[0].address, tokenList[1].address)
  //         setPairAddress(pairAddressVal)
  //         if((selector.ethData && selector.ethData.address))
  //         {
  //           const pollDataVal: any = await userPoolDetails(tokenList[0].address, tokenList[1].address, pairAddressVal, selector.ethData.address)
  //           setPoolDetails(pollDataVal)
  //         }
          
  //       }
  //     } catch (error) {
  //       //console.log('error')
  //       console.log(error)
  //     }
  //   }
  //   if (!!tokenList && tokenList.length > 0) {
  //     getPairExistVal()
  //   }
  // }, [selector.ethData.address, tokenList])

  useEffect(()=> {
    // console.log("add liquidity pop up params", firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, pairAddress)
    const fetchLPDetails = async() => {
      const res = await onAddLiquidityPopUpNew(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, pairAddress, poolType)
      console.log("onAddLiquidityPopUpNew response", res)
      if (!!res && typeof res !== 'undefined') {
        const data: any = {
          firstToken: firstToken,
          secondToken: secondToken,
          firstAmount: firstAmount,
          secondAmount: secondAmount,
          receivedTokens: res.receivedTokens,
          poolShare: res.poolShare,
        }
        setConformLdData(data)
      }
    }
    if(parseFloat(firstAmount) > 0 && parseFloat(secondAmount) > 0 && firstToken.address !== undefined && secondToken.address !== undefined){
      fetchLPDetails()
    }
      
  },[firstToken, secondToken, firstAmount, secondAmount])

  useEffect(()=>{
    // console.log("in here", firstToken, secondToken);
    returnPairExistOrNotNew()
  },[firstToken, secondToken])

  const returnPairExistOrNotNew = async() => {
    if(firstToken !== undefined && firstToken.name !== undefined && secondToken !== undefined && secondToken.name !== undefined){
      let lpPairName0 = firstToken.name.toLowerCase() + "-" + secondToken.name.toLowerCase();
      let lpPairName1 = secondToken.name.toLowerCase() + "-" + firstToken.name.toLowerCase();
      console.log(lpPairName0, lpPairName1, liquidityPairList)
      if(liquidityPairList.includes(lpPairName0) || liquidityPairList.includes(lpPairName1)){
        
        setIsPairExist(true)
        const poolDetail = LPWantAddressList[lpPairName0] !== undefined ? LPWantAddressList[lpPairName0] :  LPWantAddressList[lpPairName1];
        setPoolType(poolDetail.type)
        setPairAddress(poolDetail.wantAddress)
        setIsStablePool(poolDetail.stable)
        // console.log("pair address detail", poolDetail)
        if((selector.ethData && selector.ethData.address))
        {
          const poolDataVal: any = await userPoolDetailsNew(firstToken.address, secondToken.address, poolDetail.wantAddress, selector.ethData.address)
          console.log("pool data val", poolDataVal, poolDetail)
          setPoolDetails(poolDataVal)
        }
      } else {
        setIsPairExist(false)
      }
    }
  }

  const getAllowance = async () => {
    try {
      if(firstToken.address && secondToken.address){
        if (firstToken.sno !== bnbSno || (firstToken.sno == bnbSno && poolType == 'fusion')) {
          const firstAllowanceVal: any = await tokenAllowance(firstToken.address, selector.ethData.address, poolType, pairAddress)
          console.log("first token", firstToken.name, firstAllowanceVal)
          if (!!firstAllowanceVal && typeof firstAllowanceVal !== 'undefined') {
            setFirstAllowance(firstAllowanceVal)
          }
        } else {
          setFirstAllowance(MAX_INT)
        }
        if (secondToken.sno !== bnbSno || (secondToken.sno == bnbSno && poolType == 'fusion')) {
          const secondAllowanceVal: any = await tokenAllowance(secondToken.address, selector.ethData.address, poolType, pairAddress)
          if (!!secondAllowanceVal && typeof secondAllowanceVal !== 'undefined') {
            console.log("second token", secondToken.name, secondAllowanceVal, poolType)
            setSecondAllowance(secondAllowanceVal)
          }
        } else {
          setSecondAllowance(MAX_INT)
        }
      }
    } catch (error) { }
  }

  const getAmountOfToken = async (amount: any, flag: boolean, pairAddress: string, firstTokenAdd: string) => {
    let finalAmt: any = ''
    try {
      setIsAmtLoading(true)
      // console.log("getAmountOfToken", amount, flag, pairAddress, firstTokenAdd)
      const tokenAmount: any = await returnQuotePrice(amount, flag, pairAddress, firstTokenAdd, poolType, isStablePool)
      console.log("token amount", tokenAmount)
      finalAmt = tokenAmount
      
    }
    catch (error) {
      console.log("error=>", error)
    }
    finally {
      setIsAmtLoading(false)
      finalAmt = poolType !== 'fusion' ? getNumFormat(finalAmt) : finalAmt;
      return finalAmt
    }
  }

  const handleFirstAmount = async (e: any) => {
    const { value } = e.target
    if (floatNumRegex.test(value.toString())) {
      setFirstAmount(value)
      // const secondAmtVal: any = await getAmountOfToken(value, true, pairAddress, firstToken.address)
      let secondAmtVal: any = await getAmountOfToken(value, true, pairAddress, firstToken.address);
      console.log("in handleFirstAmount", firstToken, secondToken, value, secondAmtVal)
      setSecondAmount(secondAmtVal)
      getAllowance()
    }
    if (!value) {
      setSecondAmount('')
      setFirstAmount('')
    }
  }
  const handleSecondAmount = async (e: any) => {
    const { value } = e.target
    if (floatNumRegex.test(value.toString())) {
      setSecondAmount(value)
      const firstAmtVal: any = await getAmountOfToken(value, false, pairAddress, isStablePool || poolType == 'fusion' ? secondToken.address: firstToken.address)
      console.log("in handleSecondAmount", firstToken, secondToken, firstAmtVal, value)
      setFirstAmount(firstAmtVal)
      getAllowance()
    }
    if (!value) {
      setFirstAmount('')
      setSecondAmount('')
    }
  }
  const getExistTokenAdd = (type: string) => {
    if (type === 'first' && Object.values(secondToken).length > 0) {
      setExistTokeAdd(secondToken.address)
    }
    if (type === 'second' && Object.values(firstToken).length > 0) {
      setExistTokeAdd(firstToken.address)
    }
  }
  const handleTokenModal = (type: string) => {
    setTokenType(type)
    // getTokenList(type)
    getExistTokenAdd(type)
    setShowTokenModal(true)
  }

  const getPairExistVal = async (token1: any, token2: any) => {
    let pairExist: any = false;
    try {
      pairExist = await returnPairExistOrNot(token1.address, token2.address)
      console.log("pair exist check", pairExist)
      setIsPairExist(pairExist)
    }
    catch (error) {
      setIsPairExist(false)
      setPairAddress('')
    }
    try {
      if (pairExist) {
        const pairAddressVal = await returnPairAddress(token1.address, token2.address)
        setPairAddress(pairAddressVal)
        if((selector.ethData && selector.ethData.address))
        {
          const pollDataVal: any = await userPoolDetails(token1.address, token2.address, pairAddressVal, selector.ethData.address)
          setPoolDetails(pollDataVal)
        }
      }
    } catch (error) {
      //console.log('error');
      console.log(error);
    }
  }

  const handleTokenSelect = (selectedTokenData: any) => {
    if (selectedTokenData.address === existTokenAdd) {
      setFirstToken(secondToken)
      setSecondToken(firstToken)
      //getPairExistVal(secondToken, firstToken)
    }
    else {
      if (tokenType === 'first') {
        setFirstToken(selectedTokenData)
        if (Object.values(secondToken).length > 0) {
          //getPairExistVal(selectedTokenData, secondToken)
        }
      }
      if (tokenType === 'second') {
        setSecondToken(selectedTokenData)
        if (Object.values(firstToken).length > 0) {
          //getPairExistVal(firstToken, selectedTokenData)
        }
      }
    }
    setExistTokeAdd('')
    setFirstAmount('')
    setSecondAmount('')
    setFirstAllowance('0')
    setSecondAllowance('0')
    handleToggleModal()
  }

  const handleToggleModal = () => {
    setTokenType('')
    setShowTokenModal(false)
  }

  const getAddLiquidityStatus = () => {
    const firstAmt = parseFloat(firstAmount)
    const secondAmt = parseFloat(secondAmount)
    const firstAl = parseFloat(firstAllowance)
    const secondAl = parseFloat(secondAllowance)

    // console.log(firstAmount);
    // console.log(secondAmount);
    // console.log(firstAllowance);
    // console.log(secondAllowance);

    // console.log('firstAmt', firstAmt);
    // console.log('secondAmt', secondAmt);
    // console.log('firstAl', firstAl);
    // console.log('secondAl', secondAl);

    // console.log(secondToken);
    // console.log(firstToken);

    if (
      (firstAmt <= firstAl && secondToken.name === 'BNB') ||
      (secondAmt <= secondAl && firstToken.name === 'BNB') ||
      (firstAmt <= firstAl && secondAmt <= secondAl)
    ) {
      return true
    } else {
      return false
    }
  }

  const handleAddLdModal = async () => {
    // console.log("in here")
    try {
      setAddLdLoading(true)
      // console.log("add liquidity pop up params", firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, pairAddress)
      //const res = await onAddLiquidityPopUpNew(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, pairAddress)
      // console.log("add liquidity pop up response", res)
      // if (!!res && typeof res !== 'undefined') {
      //   const data: any = {
      //     firstToken: firstToken,
      //     secondToken: secondToken,
      //     firstAmount: firstAmount,
      //     secondAmount: secondAmount,
      //     receivedTokens: res.receivedTokens,
      //     poolShare: res.poolShare,
      //   }
      //   setConformLdData(data)
      //   setShowCnfModal(true)
      // }

      if(confirmLdData.receivedTokens !== undefined){
        setShowCnfModal(true)
      }
    } catch (error) {
      //console.log('error----', error)
      setConformLdData({})
      setShowCnfModal(false)
    } finally {
      setAddLdLoading(false)
    }
  }

  const handleApprove = async (type: any) => {
    const address = type === 'first' ? firstToken.address : secondToken.address
    try {
      type === 'first' ? setApprove1Loading(true) : setApprove2Loading(true)
      const TOKEN_INSTANCE = await selectInstance(instanceType.PLANETLP, address) //new wallet.web3.eth.Contract(thena_router_abi, thena_router_address); // const ROUTER_INSTANCE = new wallet.web3.eth.Contract(thena_router_abi, thena_router_address)
      const approvalAddress = poolType == 'planet' ? PLANETROUTERADDRESS : poolType == 'biswap'? biswapRouterAddress : poolType == 'thena' ? thena_router_address : pairAddress;
      const approvalAmount = maxAmount
      await TOKEN_INSTANCE.methods
        .approve(approvalAddress, approvalAmount)
        .send({ from: selector.ethData.address })
        .once('transactionHash', function (res: any) {
          if (!!res && typeof res) {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            setShowTransModal(true)
          }
        })
        .on('confirmation', function (confNumber: any, receipt: any) {
          if (receipt && typeof receipt !== 'undefined') {
            setApprove1Loading(false)
            setApprove2Loading(false)
            updateLiquidity()
            getAllowance()
          }
        })
        .on('error', function (error: any) {
          setTransactionLink('')

          setApprove1Loading(false)
          setApprove2Loading(false)
          getAllowance()
        })
    } catch (error) {
    } finally {
      setApprove1Loading(false)
      setApprove2Loading(false)
      // getAllowance()
    }
  }

  const handleTransactionLink = (newLink: any, hashVal: any) => {
    const data: any = {
      firstToken: firstToken.name,
      secondToken: secondToken.name,
      amount1: firstAmount,
      amount2: secondAmount,
      link: newLink,
      type: 'addedLiquidity',
      status: false,
      hashVal: hashVal,
      transStatus: 'pending',
    }
    setTransLink(data)
  }

  const handleNotification = (type: string, hash?: any) => {
    const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
    const data: any = {
      type: type,
      message: `Added liquidity for ${parseFloat(firstAmount).toFixed(4)} ${firstToken.name} and ${parseFloat(secondAmount).toFixed(4)} ${secondToken.name}`,
      link: linkUrl
    }
    dispatch(notificationClose())
    dispatch(notificationOpen(data))
  }

  const handleAddLiquidity = async () => {
    try {
      setAddLdLoading(true);
      // console.log("pool type", poolType)
      // console.log("add liquidity params", firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address)
      if(poolType == "planet"){
        if (firstToken.sno === bnbSno || secondToken.sno === bnbSno) {

          await _addLiquidity(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 0)
        }
  
        else {
          await _addLiquidity(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 1)
        }
      } else if(poolType == 'thena'){
        if (firstToken.sno === bnbSno || secondToken.sno === bnbSno) {
          await _addLiquidityInThenaPools(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 0)
        } else {
          await _addLiquidityInThenaPools(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 1)
        }
      } else if(poolType== 'fusion'){
        if (firstToken.sno === bnbSno || secondToken.sno === bnbSno) {
          console.log("LP contains bnb token")
          await _addLiquidityInFusionPools(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 0)
        } else {
          console.log("LP does NOT contain bnb token")
          await _addLiquidityInFusionPools(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 1)
        }
      } else if(poolType == 'biswap'){
        await _addLiquidityBiswap(firstToken.address, secondToken.address, firstAmount, secondAmount, selector.ethData.address, 1)
      }
      
    } catch (error) {
      console.log('error==>', error)
    } finally {
      setAddLdLoading(false)
      setConformLdData({})
      setFirstAmount('')
      setSecondAmount('')
      updateLiquidity()
    }
  }

  const _addLiquidityBiswap = async (tokenA: any, tokenB: any, amountADesiredInEther: any, amountBDesiredInEther: any, to: any, flag: any) => {

    /*
     * type : true =>  addLiquidityEth
     * type : false =>  addLiquidity
     * flag = 0 for bnb
     * flag = 1 for others
     */
    let type: boolean
    let amountAMinInEther: any = 0.95 * amountADesiredInEther
    let amountBMinInEther: any = 0.95 * amountBDesiredInEther
    amountAMinInEther = amountAMinInEther.toString()
    amountBMinInEther = amountBMinInEther.toString()

    const pair = await returnPairExistOrNot(tokenA, tokenB)
    if (!pair) {
      amountAMinInEther = '0'
      amountBMinInEther = '0'
    }
    if (flag === 0) type = true
    else type = false

    let deadline = Math.floor(Date.now() / 1000) + DEADLINE
    let txArray: any

    const ROUTER_INSTANCE = new wallet.web3.eth.Contract(biswapRouterAbi, biswapRouterAddress);
    if (type) {
      if (firstToken.sno === bnbSno) {
        txArray = [
          tokenB,
          convertToWei(amountBDesiredInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountADesiredInEther),
        ]
      } else {
        txArray = [
          tokenA,
          convertToWei(amountADesiredInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountBDesiredInEther),
        ]
      }
      // console.log('in true')
      // console.log("txArray", txArray);
      // console.log(to)
      // console.log("addLiquidityETH",txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
      await ROUTER_INSTANCE.methods
        .addLiquidityETH(txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({
          from: to,
          value: txArray[4],
        })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setAddLdLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setAddLdLoading(false)
        })
    } else {
      // console.log('amountADesiredInEther');
      // console.log(amountADesiredInEther);
      // console.log(amountBDesiredInEther);
      // console.log(amountAMinInEther);
      // console.log(amountBMinInEther);
      txArray = [
        convertToWei(amountADesiredInEther),
        convertToWei(amountBDesiredInEther),
        convertToWei(amountAMinInEther),
        convertToWei(amountBMinInEther),
      ]

      // console.log('in false')
      // console.log("txArray", txArray);
      // console.log(to)
      // console.log("in else", tokenA, tokenB, txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
      await ROUTER_INSTANCE.methods
        .addLiquidity(tokenA, tokenB, txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({ from: to })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setAddLdLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setAddLdLoading(false)
        })
    }
  }

  const _addLiquidity = async (tokenA: any, tokenB: any, amountADesiredInEther: any, amountBDesiredInEther: any, to: any, flag: any) => {

    /*
     * type : true =>  addLiquidityEth
     * type : false =>  addLiquidity
     * flag = 0 for bnb
     * flag = 1 for others
     */
    let type: boolean
    let amountAMinInEther: any = 0.95 * amountADesiredInEther
    let amountBMinInEther: any = 0.95 * amountBDesiredInEther
    amountAMinInEther = amountAMinInEther.toString()
    amountBMinInEther = amountBMinInEther.toString()

    const pair = await returnPairExistOrNot(tokenA, tokenB)
    if (!pair) {
      amountAMinInEther = '0'
      amountBMinInEther = '0'
    }
    if (flag === 0) type = true
    else type = false

    let deadline = Math.floor(Date.now() / 1000) + DEADLINE
    let txArray: any
    const ROUTER_INSTANCE = new wallet.web3.eth.Contract(PLANETROUTERABI, PLANETROUTERADDRESS);//await selectInstance(instanceType.PLANETROUTER)
    if (type) {
      if (firstToken.sno === bnbSno) {
        txArray = [
          tokenB,
          convertToWei(amountBDesiredInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountADesiredInEther),
        ]
      } else {
        txArray = [
          tokenA,
          convertToWei(amountADesiredInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountBDesiredInEther),
        ]
      }
      // console.log('in true')
      // console.log("txArray", txArray);
      // console.log(to)
      // console.log("addLiquidityETH",txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
      await ROUTER_INSTANCE.methods
        .addLiquidityETH(txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({
          from: to,
          value: txArray[4],
        })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setAddLdLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setAddLdLoading(false)
        })
    } else {
      // console.log('amountADesiredInEther');
      // console.log(amountADesiredInEther);
      // console.log(amountBDesiredInEther);
      // console.log(amountAMinInEther);
      // console.log(amountBMinInEther);
      txArray = [
        convertToWei(amountADesiredInEther),
        convertToWei(amountBDesiredInEther),
        convertToWei(amountAMinInEther),
        convertToWei(amountBMinInEther),
      ]

      // console.log('in false')
      // console.log("txArray", txArray);
      // console.log(to)
      // console.log("in else", tokenA, tokenB, txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
      await ROUTER_INSTANCE.methods
        .addLiquidity(tokenA, tokenB, txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({ from: to })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setAddLdLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setAddLdLoading(false)
        })
    }
  }

  const _addLiquidityInThenaPools = async (tokenA: any, tokenB: any, amountADesiredInEther: any, amountBDesiredInEther: any, to: any, flag: any) => {
    console.log("thena params for add liquidity", tokenA, tokenB, amountADesiredInEther, amountBDesiredInEther, to, flag)
    /*
     * type : true =>  addLiquidityEth
     * type : false =>  addLiquidity
     * flag = 0 for bnb
     * flag = 1 for others
     */
    const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
    let tokenVal1: any = convertToWei(amountBDesiredInEther);
    let tokenVal0: any = convertToWei(amountADesiredInEther)
    // if(isStablePool){
    //   let stableRatio: any = await planetZapInstance.methods.quoteStableAddLiquidityRatio(pairAddress, thena_router_address).call();
    //   console.log("stable ratio", stableRatio)
    //   tokenVal1 = BigNumber(convertToEther(tokenVal1, 18)).multipliedBy(BigNumber(stableRatio)).toFixed(0,3).toString()
    //   console.log(tokenVal1)
    //   tokenVal0 = BigNumber(convertToEther(tokenVal0, 18)).multipliedBy(BigNumber("1e18").minus(BigNumber(stableRatio))).toFixed(0,3).toString()
    // }
    let fetchLiquidity = await planetZapInstance.methods.quoteAddLiquidity(pairAddress, tokenA, tokenB, tokenVal0, tokenVal1).call();
    // console.log("fetchLiquidity data", fetchLiquidity, pairAddress, tokenA, tokenB, tokenVal0, tokenVal1)
    let type: boolean
    let amountAMinInEther: any = fetchLiquidity.amountA;//0.95 * amountADesiredInEther
    let amountBMinInEther: any = fetchLiquidity.amountB;//0.95 * amountBDesiredInEther
    amountAMinInEther = amountAMinInEther.toString()
    amountBMinInEther = amountBMinInEther.toString()

    const pair = await returnPairExistOrNot(tokenA, tokenB)
    if (!pair) {
      amountAMinInEther = '0'
      amountBMinInEther = '0'
    }
    if (flag === 0) type = true
    else type = false

    let deadline = Math.floor(Date.now() / 1000) + DEADLINE
    let txArray: any

    const ROUTER_INSTANCE = new wallet.web3.eth.Contract(thena_router_abi, thena_router_address)
    if (type) {
      if (firstToken.sno === bnbSno) {
        txArray = [
          tokenB,
          convertToWei(amountBDesiredInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountADesiredInEther),
        ]
      } else {
        txArray = [
          tokenA,
          convertToWei(amountADesiredInEther),
          convertToWei(amountAMinInEther),
          convertToWei(amountBMinInEther),
          convertToWei(amountBDesiredInEther),
        ]
      }
      // console.log('in true')
      // console.log("txArray", txArray);
      // console.log(to)
      // console.log("addLiquidityETH",txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
      await ROUTER_INSTANCE.methods
        .addLiquidityETH(txArray[0], txArray[1], isStablePool, txArray[2], txArray[3], to, deadline)
        .send({
          from: to,
          value: txArray[4],
        })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setAddLdLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setAddLdLoading(false)
        })
    } else {
      // console.log('amountADesiredInEther');
      console.log(amountADesiredInEther);
      console.log(amountBDesiredInEther);
      console.log(fetchLiquidity.amountA);
      console.log(fetchLiquidity.amountB);
      
      if(isStablePool){
        txArray = [
          convertToWei(amountADesiredInEther),
          convertToWei(amountBDesiredInEther),
          fetchLiquidity.amountA,
          fetchLiquidity.amountB,
        ]
      } else {
        txArray = [
          fetchLiquidity.amountA,
          fetchLiquidity.amountB,
          1,
          1
        ]
      }
      

      // console.log('in false')
      // console.log("txArray", txArray);
      // console.log(to)
      // console.log("in else", tokenA, isStablePool, tokenB, txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
      await ROUTER_INSTANCE.methods
        .addLiquidity(tokenA, tokenB, isStablePool, txArray[0], txArray[1], txArray[2], txArray[3], to, deadline)
        .send({ from: to })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setAddLdLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setAddLdLoading(false)
        })
    }
  }

  const _addLiquidityInFusionPools = async (tokenA: any, tokenB: any, amountADesiredInEther: any, amountBDesiredInEther: any, to: any, flag: any) => {
    // console.log("fusion pool params for add liquidity", tokenA, tokenB, amountADesiredInEther, amountBDesiredInEther, to, flag)
    /*
     * type : true =>  addLiquidityEth
     * type : false =>  addLiquidity
     * flag = 0 for bnb
     * flag = 1 for others
     */
    try {

      console.log("amounts desired", pairAddress, tokenA, tokenB, amountADesiredInEther, amountBDesiredInEther, firstToken, secondToken)
      const planetZapInstance: any = new wallet.web3.eth.Contract(planetZapOneInchAbi, planetZapOneInchAddress);
      let tokenVal0: any = convertToWei(amountADesiredInEther);
      let tokenVal1: any = convertToWei(amountBDesiredInEther)
      console.log(tokenVal1, tokenVal0)
      // let fetchLiquidity = await planetZapInstance.methods.quoteAddLiquidity(pairAddress, tokenA, tokenB, tokenVal0, tokenVal1).call();
      // console.log("fetchLiquidity data", fetchLiquidity, pairAddress, tokenA, tokenB, tokenVal0, tokenVal1, amountBDesiredInEther, amountADesiredInEther)
      let type: boolean
      let amountAMinInEther: any =  tokenVal0;
      let amountBMinInEther: any =  tokenVal1;
      // amountAMinInEther = amountAMinInEther.toString().split(".")[0]
      // amountBMinInEther = amountBMinInEther.toString().split(".")[0]

      // const pair = await returnPairExistOrNot(tokenA, tokenB)
      // if (!pair) {
      //   amountAMinInEther = '0'
      //   amountBMinInEther = '0'
      // }
      // if (flag === 0) type = true
      // else type = false

      let deadline = Math.floor(Date.now() / 1000) + DEADLINE
      let txArray: any

      const ROUTER_INSTANCE = new wallet.web3.eth.Contract(gammaUniproxyRouterABI, "0xf75c017e3b023a593505e281b565ed35cc120efa")
        txArray = [
          amountAMinInEther,
          amountBMinInEther,
          to,
          pairAddress,
          [0,0,0,0]
        ]
        // console.log('in true')
        // console.log("txArray", txArray);
        // console.log(to)
        console.log("add Liquidity in fusion pool",txArray[0], txArray[1], txArray[2], txArray[3], txArray[4])
        await ROUTER_INSTANCE.methods
        .deposit(txArray[0], txArray[1], txArray[2], txArray[3], txArray[4])
        // .addLiquidityETH(txArray[0], txArray[1], isStablePool, txArray[2], txArray[3], to, deadline)
        .send({
          from: to,
        })
        .once('transactionHash', function (res: any) {
          if (res && typeof res !== 'undefined') {
            const transLink = `${transLinkUrl}/${res}`
            setTransactionLink(transLink)
            handleTransactionLink(transLink, res)
            setShowTransModal(true)
          }
        })
        .once('confirmation', function (confNumber: any, receipt: any) {
          setConfirmTransLink(receipt.transactionHash, receipt.status)
          const type = receipt.status ? 'success' : 'failed'
          handleNotification(type, receipt.transactionHash)
          setAddLdLoading(false)
          dispatch(updateUserBalance(selector.ethData.address))
          dispatch(updateUserLendingData(selector.ethData.address))
        })
        .on('error', function (error: any) {
          handleNotification('failed')
          setAddLdLoading(false)
        })
      } catch (error){
        console.log(error)
        setAddLdLoading(false)
      }
    
  }

  const handleMaxAmount = async () => {
    if (isPairExist) {
      let amount: any
      if (firstToken.sno === bnbSno && parseFloat(secondToken.value) !== 0) {
        amount = firstToken.value
        amount -= 0.01
      } else amount = firstToken.value
      amount = getNumFormat(amount)
      setFirstAmount(amount)
      let secondAmtVal: any = poolType == 'thena' ? await getQuote(secondToken.address, firstToken.address, convertToWei(amount)) : await getAmountOfToken(amount, true, pairAddress, firstToken.address);
      console.log("in handle max amount for first token", firstToken, secondToken, amount, secondAmtVal)
      secondAmtVal = poolType == 'thena' ? secondAmtVal.toTokenAmount : secondAmtVal;
      setSecondAmount(secondAmtVal)
      
      getAllowance()
    }
  }
  const handleSecondMaxAmount = async () => {
    if (isPairExist) {
      let amount: any
      if (secondToken.sno === bnbSno && parseFloat(firstToken.value) !== 0) {
        amount = secondToken.value
        amount -= 0.01
      } else amount = secondToken.value
      amount = getNumFormat(amount)
      setSecondAmount(amount)
      const firstAmtVal: any = poolType == 'thena' ? await getQuote(firstToken.address, secondToken.address, convertToWei(amount)) : await getAmountOfToken(amount, true, pairAddress, secondToken.address);
      console.log("in handle max amount for second token", firstToken, secondToken, amount, firstAmtVal)
      setFirstAmount(firstAmtVal)
      getAllowance()
    }
  }
  return (
    <Fragment>
      <CardCont>
        <AddLDCont>
          <FlexSBCont>
            {/* <BackButton onClick={() => setLiquidityTab('liquidity')}>
              <img src={DownIcon} alt="back-btn" />
            </BackButton> */}
            <BackButton className='backButton' onClick={() => setLiquidityTab('liquidity')}>
              <img className='backIcon' src={ArrowDownGrey} alt="back-btn" />
              <InfoText className="backButtonText" light>
                Back
              </InfoText>
            </BackButton>
            <TitleText>Add Liquidity</TitleText>
            <img className=" clock-icon" src={ClockIcon} alt="" onClick={() => setShowHistory(true)} />
          </FlexSBCont>
          {/* <InfoText className="add-token-ld" light>
            Add tokens to a liquidity pool
          </InfoText> */}
          <AddLDTokenCont>
            <AddLDCard>
              <FlexSBCont>
                <InfoLDText>Amount</InfoLDText>
                <InfoLDText>{`Balance: ${!!firstToken && Object.values(firstToken).length > 0 ? parseFloat(firstToken.value).toFixed(4) : '-'
                  }`}</InfoLDText>
              </FlexSBCont>
              <FlexSBCont className="token-input">
                <TokenValInput
                  placeholder="0"
                  disabled={!(Object.values(firstToken).length > 0 && Object.values(secondToken).length > 0) || !isPairExist}
                  onChange={handleFirstAmount}
                  value={firstAmount && firstAmount}
                />
                <FlexCont>
                  {Object.values(firstToken).length > 0 && <MaxTxt onClick={() => handleMaxAmount()}>Max</MaxTxt>}
                  <TokenSelectCont onClick={() => handleTokenModal('first')}>
                    {Object.values(firstToken).length > 0 ? (
                      <>
                        <img className="token-icon" src={firstToken.icon} alt="" />
                        <TokenName>{firstToken.name}</TokenName>
                      </>
                    ) : (
                      <SelectCurrTxt>Select Currency</SelectCurrTxt>
                    )}
                    <img className="arrow-down" src={ArrowDown} alt="" />
                  </TokenSelectCont>
                </FlexCont>
              </FlexSBCont>
            </AddLDCard>
            <PluIconCont className='addSymbol'>
              <img src={CrossIcon} alt="" />
            </PluIconCont>
            <AddLDCard>
              <FlexSBCont>
                <InfoLDText>Amount</InfoLDText>
                <InfoLDText>{`Balance: ${!!secondToken && Object.values(secondToken).length > 0 ? parseFloat(secondToken.value).toFixed(4) : '-'
                  }`}</InfoLDText>
              </FlexSBCont>
              <FlexSBCont className="token-input">
                <TokenValInput
                  placeholder="0"
                  disabled={!(Object.values(firstToken).length > 0 && Object.values(secondToken).length > 0) || !isPairExist}
                  onChange={handleSecondAmount}
                  value={secondAmount && secondAmount}
                />
                <FlexCont>
                  {Object.values(secondToken).length > 0 && <MaxTxt onClick={() => handleSecondMaxAmount()}>Max</MaxTxt>}
                  <TokenSelectCont onClick={() => handleTokenModal('second')}>
                    {Object.values(secondToken).length > 0 ? (
                      <>
                        <img className="token-icon" src={secondToken.icon} alt="" />
                        <TokenName>{secondToken.name}</TokenName>
                      </>
                    ) : (
                      <SelectCurrTxt>Select Currency</SelectCurrTxt>
                    )}
                    <img className="arrow-down" src={ArrowDown} alt="" />
                  </TokenSelectCont>
                </FlexCont>
              </FlexSBCont>
            </AddLDCard>
          </AddLDTokenCont>
          {!isPairExist && (Object.values(firstToken).length > 0 && Object.values(secondToken).length > 0) && (
            <InfoSmallText className="warning-text"> Liquidity pair does not exist</InfoSmallText>
          )}
          {isAmtLoading &&
            <PoolDetailsCont>
              <InfoSmallText style={{ textAlign: 'center' }}> Amount loading...</InfoSmallText>
            </PoolDetailsCont>
          }
          {isPairExist && (
            <PoolDetailsCont>
              {/* <InfoSmallText>Pool Details</InfoSmallText> */}
              <FlexSBCont className="pool-info">
                <PoolInfoText className='addLiquidity'>{`${firstToken.name} Per ${secondToken.name}`}</PoolInfoText>
                <InfoSmallText>
                  {!!poolDetails && firstAmount && secondAmount
                    ? (parseFloat(firstAmount) / parseFloat(secondAmount)).toFixed(4)
                    : parseFloat(poolDetails.price1).toFixed(4)}
                </InfoSmallText>
              </FlexSBCont>
              <FlexSBCont className="pool-info">
                <PoolInfoText className='addLiquidity'>{`${secondToken.name} Per ${firstToken.name}`}</PoolInfoText>
                <InfoSmallText>
                  {!!poolDetails && firstAmount && secondAmount
                    ? (parseFloat(secondAmount) / parseFloat(firstAmount)).toFixed(4)
                    : parseFloat(poolDetails.price2).toFixed(4)}
                </InfoSmallText>
              </FlexSBCont>
              <FlexSBCont className="pool-info">
                <PoolInfoText className='addLiquidity'>LP Tokens to be received</PoolInfoText>
                <InfoSmallText>
                {!!confirmLdData && confirmLdData.receivedTokens ? parseFloat(confirmLdData.receivedTokens).toFixed(5) : '0.00'} LP
                </InfoSmallText>
              </FlexSBCont>
              <FlexSBCont className="pool-info">
              <PoolInfoText className='addLiquidity'>Share Of Pool</PoolInfoText>
              <InfoSmallText>
                {!!confirmLdData && confirmLdData.poolShare ? parseFloat(confirmLdData.poolShare).toFixed(5) : '0.00'}%
              </InfoSmallText>
            </FlexSBCont>
            </PoolDetailsCont>
          )}
          <AddLDBtnCont marginStatus={isPairExist}>
            {parseFloat(firstAmount) > 0 && parseFloat(secondAmount) > 0 ? (
              <>
                {parseFloat(firstAllowance) < parseFloat(firstAmount) && firstToken.name !== 'BNB' && (
                  <ApproveBtn disabled={approve1Loading} onClick={() => handleApprove('first')}>
                    {approve1Loading ? <Spinner /> : `${`Approve ${firstToken.name}`}`}
                  </ApproveBtn>
                )}
                {parseFloat(secondAllowance) < parseFloat(secondAmount) && secondToken.name !== 'BNB' && (
                  <ApproveBtn disabled={approve2Loading} onClick={() => handleApprove('second')}>
                    {approve2Loading ? <Spinner /> : `${`Approve ${secondToken.name}`}`}
                  </ApproveBtn>
                )}
                {getAddLiquidityStatus() && (
                  <>
                    {parseFloat(firstAmount) <= parseFloat(firstToken.value) && parseFloat(secondAmount) <= parseFloat(secondToken.value) ? (
                      <PrimaryBtn disabled={addLdLoading} onClick={() => handleAddLdModal()}>
                        {addLdLoading ? <Spinner /> : 'Add Liquidity'}
                      </PrimaryBtn>
                    ) : (
                      <PrimaryBtn disabled>Insufficient Balance</PrimaryBtn>
                    )}
                  </>
                )}
              </>
            ) : (
              <PrimaryBtn disabled>Add Liquidity</PrimaryBtn>
            )}
          </AddLDBtnCont>
        </AddLDCont>
      </CardCont>
      <CustomModal show={showTokenModal} toggleModal={handleToggleModal}>
        {showTokenModal && (
          <TokenSelect
            selectedAdd={tokenType === 'first' ? secondToken.address : firstToken.address}
            setShowTokenModal={setShowTokenModal}
            tokenListVal={tokenListForAddLiquidity}
            handleTokenSelect={handleTokenSelect}
            existTokenAdd={existTokenAdd}
          />
        )}
      </CustomModal>
      <CustomModal show={showCnfModal} toggleModal={setShowCnfModal}>
        {showCnfModal && (
          <ConfirmLiquidity setShowCnfModal={setShowCnfModal} confirmLdData={confirmLdData} handleAddLiquidity={handleAddLiquidity} />
        )}
      </CustomModal>
      <CustomModal show={showTransModal} toggleModal={setShowTransModal}>
        {showTransModal && <TransactionInfo setShowTransModal={setShowTransModal} transactionLink={transactionLink} />}
      </CustomModal>
    </Fragment>
  )
}

export default AddLiquidity