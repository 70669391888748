export const newPoolList = [
    '0x7b2Be59Ed58431c0040f1a30B5438Bd11ed99CFC',
    '0x06D7e1f13Db3a7d7458D085a9BDE2f20933Db3B1',
    '0x595608b012BC0DfBE3B18cE1098f034f78DCc7b7',
    '0x80f55A08B02B43aB55Cc98465C32A5A4B8cc25B6',
    // '0xCf32907C7eE99dCcbD8FeeC2C26fAd077F799890',
    // '0x7B5A46D584477eb00Fc88a3265724E50E7cBCb32',
    // '0x5cdFB4CD95f733fD679343bBC39d12Dff907251B',
    // '0xB32aE069db02f614e303aCEAF5aD963d7De12194',
    '0x208AdaE61fD7587314c2f7f1566bd137E0dd0126',
    // '0x06f5F260bE2C541F134b5548E5e62952D7315363',
    '0x10a4A5e00769d0101bF6ba8ff10b4D68f4ba070B',
    '0x590540c90f42eec56Ec33009CE954C91Fd3079B0',
    '0x4027a5cdA69dA74674A09Af4AaCc255d0d7a6e6D',//btc-bnb -> narrow,
    '0xFfE80fBdFC324305C61794Ba38fD2f041CB98b19',
    '0x3496294F8aB14904Cb5427556ae217CCFd09FCB1',
    //'0xC6AA190957dc5d70199da6f2853Bb969Cb316cB0', // ankr bnb -fusion
    '0xD0121b07C279498ca4E7c03eBeA7508B5620A4C3',
    '0xAf2773D0eEfF2bCf67045bfE8F750Dc143633279', // hay-usdt - biswap
    '0xF9b1B6c989c066E7Ab3127827A52BA248038356E', //bnbx usdt,
    '0x31636E47EFE076B23dEe7Fd1D89C962DeCCd63e9'
]

export const activePoolList = [
    '0x7b2Be59Ed58431c0040f1a30B5438Bd11ed99CFC',
    '0x06D7e1f13Db3a7d7458D085a9BDE2f20933Db3B1',
    '0x5cdFB4CD95f733fD679343bBC39d12Dff907251B',
    '0xCf32907C7eE99dCcbD8FeeC2C26fAd077F799890',
    '0x7B5A46D584477eb00Fc88a3265724E50E7cBCb32',
    '0xB32aE069db02f614e303aCEAF5aD963d7De12194',
    '0x208AdaE61fD7587314c2f7f1566bd137E0dd0126',
    '0x06f5F260bE2C541F134b5548E5e62952D7315363',
    '0x10a4A5e00769d0101bF6ba8ff10b4D68f4ba070B',
    '0x590540c90f42eec56Ec33009CE954C91Fd3079B0',    
    '0x4027a5cdA69dA74674A09Af4AaCc255d0d7a6e6D', //btc-bnb -> narrow
    '0xFfE80fBdFC324305C61794Ba38fD2f041CB98b19',
    '0x3496294F8aB14904Cb5427556ae217CCFd09FCB1',
    '0x80f55A08B02B43aB55Cc98465C32A5A4B8cc25B6',//3g pool,
    //'0xC6AA190957dc5d70199da6f2853Bb969Cb316cB0',// ankr bnb -fusion
    '0xD0121b07C279498ca4E7c03eBeA7508B5620A4C3',
    '0xAf2773D0eEfF2bCf67045bfE8F750Dc143633279', // hay-usdt - biswap
    '0xF9b1B6c989c066E7Ab3127827A52BA248038356E', //bnbx-usdt,
    '0x31636E47EFE076B23dEe7Fd1D89C962DeCCd63e9' 
]

export const activeV3Pools = [
    "0xadb34c8d63848eaf5760d8d1de776bbae3d044a8",
    "0x2c7ea70259dd5153b7f8bab177126fce850bfb1d",
    "0x6867fa79098f505aa0dfbd8370d04e0ab2234f92",
    "0x7ab46a66ff2b0ea3b541f73377570619000a262a",
    "0xf6c105fffabcce5d9b67b1fae34660f95ab1efa0",
    "0xaa37532acd1f93ae1db334db5093f98545572fcc",
    "0x79d84ebb0fc78ee97ee95fc1da1fc4257f38fce2",
    "0xbea71859e22d106a5728737193ce98b6b9bf2916"
]

export const activeV3FusionPools = [
    "0x7ab46a66ff2b0ea3b541f73377570619000a262a",
    "0xf6c105fffabcce5d9b67b1fae34660f95ab1efa0",
    "0xaa37532acd1f93ae1db334db5093f98545572fcc",
    "0x79d84ebb0fc78ee97ee95fc1da1fc4257f38fce2",
    "0xbea71859e22d106a5728737193ce98b6b9bf2916"
]
