import React, { Fragment, useState, useEffect, useRef   } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { MainContainer, CardButton, CardButton2 } from 'shared/styles/styled'
import { BaseContainer, BaseHeader, TvlInfoBox, Card, UserInfoCont, OverViewInfo, CardNumText, BalanceWrapper, BalanceCont, BalanceText, LevelBox, MobBalWrapper, MobBalCard, MobBuyAssetCont, MobBalText, LoadingText1, LoadingText2, InputBox, FlexSBCont, InfoLDText, TokenValInput, TokenSelectCont, TokenName, SelectCurrTxt, BoostDetailsCard, UserLDList, CardText, FlexCont, TransMaxBox, TransButton, TransInputCont, InputTitle } from './style'
import { TitleText, TitleInfo, CardTitle, CardSubText } from 'shared/styles/globalText'
import stader from 'assets/icons/stader.png'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import { getBnbPrice, UserBNBDetails, fetchBNBxApy, fetchBNBxTVL, fetchTotalBNBxStakers, getTokenPrice, fetchStakingWithdrawRequest} from 'modules/block-chain/BlockChainMethods'
import BNBIcon from 'assets/icons/BNB.png';
import BNBxIcon from 'assets/icons/BNBx.png';
import Spinner from 'shared/spinner'
import {stakeManagerABI, stakeManagerAddress, planetStakingAbi, planetStakingAddress} from 'modules/block-chain/SwapDexAbi'
import wallet from 'modules/wallet/wallet'
import {convertToEther, convertToWei} from 'modules/block-chain/Swap'
import TransactionInfo from 'shared/transaction-info'
import { transLinkUrl } from 'service/global-constant'
import { getNumFormat } from 'service/globalFunctions'
import { setTransHistory, setConfirmTransHistory } from 'shared/transaction-history/historyMethods'
import { notificationOpen, notificationClose } from "logic/action/notification.action"
import { commaFy, convertToKAndM } from 'service/globalFunctions'
import {tokenBalance} from 'modules/block-chain/Swap'
import ArrowDown from 'assets/icons/arrow-down.png'
import { gBnbAbi } from 'modules/block-chain/lendingAbi'
import { pancakeLPabi } from 'modules/block-chain/abi'
import CustomModal from 'shared/custom-modal'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import grey_tick from 'assets/icons/grey_tick.png'
import green_tick from 'assets/icons/green_tick.png'

const bnbxAddress = '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275';

const LiquidStaking = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [protocol, setProtocol] = useState('stader')
    const [activeTab, setActiveTab] = useState('stake')
    const [activeToken, setActiveToken] = useState('bnb')
    const [inputAmount, setInputAmount] = useState(0);
    const [displayInputAmount, setDisplayInputAmount] = useState(0);
    const [userBNBBalance, setUserBNBBalance] = useState(0);
    const [userBNBxBalance, setUserBNBxBalance] = useState(0);
    const [bnbPrice, setBNBPrice] = useState(0);
    const [bnbxPrice, setBNBxPrice] = useState(0);
    const [transactionLoader, setTransactionLoader] = useState(false)
    const [inSufAmt, setInSufAmt] = useState(false)
    const [showTransModal, setShowTransModal] = useState(false)
    const [exchangeRate, setExchangeRate] = useState(0)
    const [transactionLink, setTransactionLink] = useState('')
    const [bnbxApy, setBNBxApy] = useState<any>(0);
    const [bnbxTVL, setBNBxTVL] = useState<any>(0);
    const [bnbxHoldersCount, setBNBxHoldersCount] = useState<any>(0);
    const [showAnswerArr, setShowFaqAnswerArr] = useState<any>([]);
    const [clickedQuestion, setClickedQuestion] = useState(0);
    const [withdrawRequestArr, setWithdrawRequestArr] = useState<any>([]);
    const [showStakingTxModal, setShowStakingTxModal] = useState<any>(false);
    const [tokenAllowance, setTokenAllowance] = useState<any>(false);
    const [unStakeBNBxTx, setunstakeBNBxTx] = useState<any>(false);
    const { portfolioLoading, userPortfolio, platformLoading, platformData } = props

    useEffect(() => {
        const fetchUserBNBPrice = async() => {
            let bnbPrice = await getBnbPrice()
            setBNBPrice(bnbPrice);
        }
        fetchUserBNBPrice()

        const fetchBNB_BNBx_rate = async() => {
            try{
                const stake_instance: any = new wallet.web3.eth.Contract(stakeManagerABI, stakeManagerAddress)
                let exch_rate = await stake_instance.methods.convertBnbXToBnb(convertToWei(1)).call();
                console.log("exchange rate", exch_rate);
                setExchangeRate(exch_rate);
            } catch(error){
                console.log(error)
            }
        }
        fetchBNB_BNBx_rate()

        const fetchUserBNBxApy = async() => {
            let bnbxApy = await fetchBNBxApy()
            setBNBxApy(bnbxApy);
        }
        fetchUserBNBxApy()

        const fetchTotalBNBx = async() => {
            let bnbxValueLocked = await fetchBNBxTVL()
            setBNBxTVL(bnbxValueLocked.value);
        }
        fetchTotalBNBx()

        const fetchBNBxHoldersCount = async() => {
            let bnbxHolders = await fetchTotalBNBxStakers()
            setBNBxHoldersCount(bnbxHolders);
        }
        fetchBNBxHoldersCount()

        const fetchBNBxPrice = async() => {
            let bnbxPrice = await getTokenPrice(bnbxAddress)
            console.log("bnbx price", bnbxPrice)
            setBNBxPrice(bnbxPrice);
        }
        fetchBNBxPrice()
        
    }, [])

    //fetch bnb wallet balance & price
    useEffect(() => {
        const fetchUserBNBDetails = async() => {
            if(selector.ethData.address){
                let userBalance = await UserBNBDetails(selector.ethData.address);
                console.log("user bnb Balance", userBalance)
                setUserBNBBalance(userBalance)
            }
        }
        fetchUserBNBDetails()

        const fetchuserBNBxBalance = async() => {
            if(selector.ethData.address){
                let bnbxBalance = await tokenBalance(bnbxAddress, selector.ethData.address, 1)
                console.log("user bnbx Balance", bnbxBalance)
                setUserBNBxBalance(bnbxBalance);
            }
        }
        fetchuserBNBxBalance()

        const fetchWithdrawalRequests = async () => {
            let withdrawRequestArray = await fetchStakingWithdrawRequest(selector.ethData.address)
            console.log("withdrawRequestArray", withdrawRequestArray)
            setWithdrawRequestArr(withdrawRequestArray)
        }
        fetchWithdrawalRequests()

    }, [selector.ethData.address])

    const renderOverView = () => {
        return (
            <Fragment>
                <OverViewInfo className='overviewCont'>
                    <div>
                        <CardText className='cardTitle'>APY</CardText>
                        {portfolioLoading ?
                            <LoadingText1 /> :
                            <CardNumText className='cardValue green-text'>
                                {parseFloat(bnbxApy).toFixed(2)}%
                            </CardNumText>
                        }
                    </div>
                    <div>
                        <CardText className='cardTitle'>Annual Earnings</CardText>
                        {portfolioLoading ?
                            <LoadingText1 /> :
                            <div>
                                <CardNumText className='cardValue'>
                                    {parseFloat((userBNBxBalance * bnbxApy/100).toString()).toFixed(2)}
                                </CardNumText>
                                <CardText className='cardSubtext'>
                                    ${(parseFloat((userBNBxBalance * bnbxApy/100).toString()) * parseFloat(convertToEther(exchangeRate, 18)) * bnbxPrice).toFixed(2)}
                                </CardText>
                            </div>
                        }
                    </div>
                    
                </OverViewInfo>
                <OverViewInfo className='cardRow'>
                    
                    <div>
                        <FlexCont>
                            <CardText className='cardTitle'>Staked with Stader</CardText>
                        </FlexCont>
                        {portfolioLoading ?
                            <LoadingText1 /> :
                                <div>
                                    <CardNumText className='cardValue'>
                                        {convertToKAndM(parseFloat(bnbxTVL).toFixed(2))} BNB
                                    </CardNumText>
                                    <CardText className='cardSubtext'>
                                        ${convertToKAndM((parseFloat(bnbxTVL)*(bnbPrice)).toFixed(2))}
                                    </CardText>
                            </div>
                        }
                    </div>
                    <div>
                        <CardText className='cardTitle'>Stakers</CardText>
                        {portfolioLoading ?
                            <LoadingText1 /> :
                            <CardNumText className='cardValue'>
                                {bnbxHoldersCount}
                            </CardNumText>
                        }
                    </div>  
                </OverViewInfo>
            </Fragment>
        )
    }

    const changeTab = async (tab: any) => {
        setActiveTab(tab)
        if(tab == 'stake'){
            setActiveToken('bnb')
        } else {
            setActiveToken('bnbx')
        }
        setInputAmount(0)
        if(tab == 'withdraw'){
            let withdrawRequestArray = await fetchStakingWithdrawRequest(selector.ethData.address)
            console.log("withdrawRequestArray", withdrawRequestArray)
            setWithdrawRequestArr(withdrawRequestArray)
        }
    }

    const handleInputAmount = async (e: any) => {
        let { value } = e.target
        value = value.replace(/\$|,|/g, "");
        if (floatNumRegex.test(value.toString())) {
            setInputAmount(value)
            setDisplayInputAmount(value)
        } else {
            setInputAmount(0)
        }
    }

    const handleMaxAmount = () => {
        //remove transaction amount
        if(activeTab == 'stake'){
            let maxBNBAmount = userBNBBalance > 0.01 ? userBNBBalance - 0.01 : 0
            setInputAmount(maxBNBAmount)
        } else {
            let maxBNBxAmount = userBNBxBalance > 0 ? userBNBxBalance : 0
            setInputAmount(maxBNBxAmount)
        }
        
    }

    const handleNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Staked ${parseFloat(inputAmount.toString()).toFixed(4)} BNB to Stader Liquid Staking`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleUnStakeNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Unstaked ${parseFloat(inputAmount.toString()).toFixed(4)} BNBx from Stader Liquid Staking`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleWithdrawNotification = (type: string, hash?: any, amount? : any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Withdrew ${parseFloat(amount.toString()).toFixed(4)} BNBx from Stader Liquid Staking`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const handleApproveNotification = (type: string, hash?: any) => {
        const linkUrl = !!hash ? `${transLinkUrl}/${hash}` : ''
        const dataVal: any = {
            type: type,
            message: `Approved BNBx for Stader Liquid Unstaking`,
            link: linkUrl
        }
        dispatch(notificationClose())
        dispatch(notificationOpen(dataVal))
    }

    const stakeLiquidity = async() => {
        if(selector.ethData.ethWalletConnected){
            setTransactionLoader(true)
            try{
                const stake_instance: any = new wallet.web3.eth.Contract(planetStakingAbi, planetStakingAddress)
                await stake_instance.methods
                .stakeToStader(selector.ethData.address)
                .send({ from: selector.ethData.address, value: convertToWei(inputAmount) })
                .once('transactionHash', function (res: any) {
                    //console.log('approve transactionHash');
                    if (res && typeof res !== 'undefined') {
                        const transLink = `${transLinkUrl}/${res}`
                        setTransactionLink(transLink)
                    }
                })
                .once('confirmation', function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    // handleNotification(type, receipt.transactionHash)
                    //console.log('approve confirmation');
                    handleNotification(type, receipt.transactionHash)
                    setTransactionLoader(false)
                    setInputAmount(0)
                })
                .on('error', function (error: any) {
                    setTransactionLink('')
                    console.log('handle stake bnb error' ,error)
                    setTransactionLoader(false)
                })
            } catch(error){
                console.log("error", error)
            } finally {
    
            }
        }
    }

    const unStakeLiquidity = async() => {
        if(selector.ethData.ethWalletConnected){
            setTransactionLoader(true)
            setShowStakingTxModal(true)
            try{
                const token_instance: any = new wallet.web3.eth.Contract(pancakeLPabi, bnbxAddress);
                let allowanceAmount = await token_instance.methods.allowance(selector.ethData.address, stakeManagerAddress).call();
                console.log("allowance amount", allowanceAmount)
                if(parseFloat(convertToEther(allowanceAmount, 18))  < inputAmount){
                    await token_instance.methods
                    .approve(stakeManagerAddress, convertToWei(inputAmount))
                    .send({ from: selector.ethData.address, })
                    .once('transactionHash', function (res: any) {
                        //console.log('approve transactionHash');
                        if (res && typeof res !== 'undefined') {
                            const transLink = `${transLinkUrl}/${res}`
                            setTransactionLink(transLink)
                        }
                    })
                    .once('confirmation', function (confNumber: any, receipt: any) {
                        const type = receipt.status ? 'success' : 'failed'
                        // handleNotification(type, receipt.transactionHash)
                        //console.log('approve confirmation');
                        handleApproveNotification(type, receipt.transactionHash)
                        // setTransactionLoader(false)
                        setInputAmount(0)
                        setTokenAllowance(true)
                        
                    })
                    .on('error', function (error: any) {
                        setTransactionLink('')
                        console.log('approve bnbx error' ,error)
                        setTransactionLoader(false)
                        setShowStakingTxModal(false)
                    })
                } else {
                    setTokenAllowance(true)
                }
                const stake_instance: any = new wallet.web3.eth.Contract(stakeManagerABI, stakeManagerAddress);   
                await stake_instance.methods
                .requestWithdraw(convertToWei(inputAmount))
                .send({ from: selector.ethData.address, })
                .once('transactionHash', function (res: any) {
                    //console.log('approve transactionHash');
                    if (res && typeof res !== 'undefined') {
                        const transLink = `${transLinkUrl}/${res}`
                        setTransactionLink(transLink)
                    }
                })
                .once('confirmation', async function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    // handleNotification(type, receipt.transactionHash)
                    //console.log('approve confirmation');
                    handleUnStakeNotification(type, receipt.transactionHash)
                    setTransactionLoader(false)
                    setInputAmount(0)
                    setShowStakingTxModal(false)
                    setunstakeBNBxTx(true)
                    let withdrawRequestArray = await fetchStakingWithdrawRequest(selector.ethData.address)
                    console.log("withdrawRequestArray", withdrawRequestArray)
                    setWithdrawRequestArr(withdrawRequestArray)
                    let bnbxBalance = await tokenBalance(bnbxAddress, selector.ethData.address, 1)
                    console.log("user bnbx Balance", bnbxBalance)
                    setUserBNBxBalance(bnbxBalance);
                })
                .on('error', function (error: any) {
                    setTransactionLink('')
                    console.log('handle un stake bnbx error' ,error)
                    setTransactionLoader(false)
                    setShowStakingTxModal(false)
                })  
            } catch(error){
                console.log("error", error)
                setTransactionLoader(false)
                setShowStakingTxModal(false)
            } finally {
    
            }
        }
    }

    const withdraw = async(index: any) => {
        if(selector.ethData.ethWalletConnected){
            setTransactionLoader(true)
            try{
                const stake_instance: any = new wallet.web3.eth.Contract(stakeManagerABI, stakeManagerAddress)
                await stake_instance.methods
                .claimWithdraw(index)
                .send({ from: selector.ethData.address, })
                .once('transactionHash', function (res: any) {
                    //console.log('approve transactionHash');
                    if (res && typeof res !== 'undefined') {
                        const transLink = `${transLinkUrl}/${res}`
                        setTransactionLink(transLink)
                    }
                })
                .once('confirmation', async function (confNumber: any, receipt: any) {
                    const type = receipt.status ? 'success' : 'failed'
                    // handleNotification(type, receipt.transactionHash)
                    //console.log('approve confirmation');
                    let amount : any = convertToEther(withdrawRequestArr[index].amountInBnbX, 18);
                    let withdrawRequestArray = await fetchStakingWithdrawRequest(selector.ethData.address)
                    console.log("updated withdrawRequestArray", withdrawRequestArray)
                    setWithdrawRequestArr(withdrawRequestArray)
                    handleWithdrawNotification(type, receipt.transactionHash, amount)
                    setTransactionLoader(false)
                    setInputAmount(0)
                })
                .on('error', function (error: any) {
                    setTransactionLink('')
                    console.log('handle stake bnb error' ,error)
                    setTransactionLoader(false)
                })
            } catch(error){
                console.log("error", error)
            } finally {
    
            }
        }
    }

    const toggleAnswer = (question: any) => {
        // setClickedQuestion(question);
        const isRowCurrentlyExpanded = showAnswerArr.includes(question);
        const newExpandedFAQs = isRowCurrentlyExpanded
        ? showAnswerArr.filter((id: any) => id !== question)
        : showAnswerArr.concat(question)
        console.log("answer display", newExpandedFAQs, question, showAnswerArr, isRowCurrentlyExpanded)
        setShowFaqAnswerArr(newExpandedFAQs)
    }

    return (
        <MainContainer className="liquidStakeCont">
            <BaseHeader className="liquidStakeHead">
                <div>
                    <TitleText className='staking-title'>BNB Liquid Staking</TitleText>
                    <TitleInfo className="staking-info">Stake BNB On Ankr, Stader Etc.</TitleInfo>
                </div>
            </BaseHeader>

            <FlexSBCont className='liquidStakeCont'>
                <FlexSBCont className='liquidStakeLeftCont'>
                    <InfoLDText className="protocolText">
                        Choose A Protocol You’d Like To Stake With
                    </InfoLDText>
                    <FlexCont className="protocolCont">
                        <FlexCont data={protocol == 'stader' ? true: false} className="protocolOption">
                            <img src={stader} alt='' className='protocolIcon' />
                            <InfoLDText data={protocol == 'stader' ? true: false} className="protocolName">Stader</InfoLDText>
                        </FlexCont>
                        {/* <FlexCont data={protocol == 'ankr' ? true: false} className="protocolOption">
                            <img src={stader} alt='' className='protocolIcon' />
                            <InfoLDText data={protocol == 'ankr' ? true: false} className="protocolName">Ankr</InfoLDText>
                        </FlexCont> */}
                    </FlexCont>
                    <FlexSBCont className='on-web'>
                        {/* <FlexSBCont className='protocolImgCont'>
                            <img src={stader} alt='' className='protocolImg' />
                        </FlexSBCont> */}
                        <FlexSBCont className='protocolCont'>
                            <InfoLDText className='protocolText'>BNBx</InfoLDText>
                            <InfoLDText className='prototcolInfo'><span>BNBx</span> is a reward-bearing token. It accumulates rewards not by growing in number, but by growing in value in relation to BNB. The redemption price of <span>1 BNBx</span> is now <span>{parseFloat(convertToEther(exchangeRate, 18)).toFixed(4)}BNB.</span></InfoLDText>
                            <Card className="tokenCard">{renderOverView()}</Card>
                            <FlexSBCont className='securityInfoCont'>
                                <InfoLDText className='protocolText'>Safety & Security</InfoLDText>
                                <InfoLDText className='prototcolInfo'>Audited by Halborn and Peckshield</InfoLDText> 
                                <InfoLDText className='prototcolInfo faq'>FAQs</InfoLDText>
                                <FlexSBCont onClick={() => toggleAnswer(1)} className='faqQuestion'>
                                    <InfoLDText className='faqQuestion faq'>Which wallets are compatible with BNBx tokens ?</InfoLDText>
                                    <img className="faq-icon" src={ArrowDown} alt="" />
                                </FlexSBCont>
                                {showAnswerArr.includes(1) &&<FlexSBCont className='faqAnswer' data={showAnswerArr.includes(1) ? true : false} onClick={() => toggleAnswer(1)}>
                                    <InfoLDText  className='faqQuestion'>BNBx is an ERC-20 token that is compatible with Ethereum based wallets like MetaMask</InfoLDText>
                                </FlexSBCont>
                                }
                                <FlexSBCont onClick={() => toggleAnswer(2)} className='faqQuestion'>
                                    <InfoLDText className='faqQuestion faq'>What is the minimum amount of BNB I can stake ?</InfoLDText>
                                    <img className="faq-icon" src={ArrowDown} alt="" />
                                </FlexSBCont>
                                <FlexSBCont className='faqAnswer' data={showAnswerArr.includes(2) ? true : false} onClick={() => toggleAnswer(2)}>
                                    <InfoLDText  className='faqQuestion'>You can stake from 0.1BNB. You must also count in the gas fees on top for sending the transaction.</InfoLDText>
                                </FlexSBCont>
                            </FlexSBCont>
                        </FlexSBCont>
                    </FlexSBCont>
                    <FlexSBCont className='on-mob'>
                        
                        <FlexCont className='on-mob'>
                        <FlexSBCont className='liquidStakeRightCont'>
                        <FlexSBCont className='stakingCont'>
                            <FlexCont className="stakingCont">
                                <CardText data={activeTab == 'stake' ? true: false} className='stakingText' onClick={() => changeTab('stake')}>Stake</CardText>
                                <CardText data={activeTab == 'unstake' ? true: false} className='stakingText' onClick={() => changeTab('unstake')}>Unstake</CardText>
                                <CardText data={activeTab == 'withdraw' ? true: false} className='stakingText' onClick={() => changeTab('withdraw')}>Withdraw</CardText>
                            </FlexCont>
                            {activeTab == 'withdraw' ?
                            <FlexCont className='stakingCard'>
                                <TitleText className='staking-header'>{'Withdraw'}</TitleText>
                                <FlexSBCont className='staking-info'>
                                    <InfoLDText className='to-stake'>Note: Un-Staking requires 7-15 days.</InfoLDText>
                                </FlexSBCont>
                                
                                <FlexSBCont className='staking-details'>
                                    <InfoLDText className='to-stake-white'>Amount</InfoLDText>
                                    <InfoLDText className='to-stake'>Estimated Time</InfoLDText>
                                </FlexSBCont>

                                { 
                                    withdrawRequestArr.length > 0 && withdrawRequestArr.map((request: any, index: any) => {
                                        let withdrawalDate: any = new Date(parseFloat(request.startTime) * 1000 + (7 * 24 * 60 * 60 * 1000)).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric", hour: "numeric", minute: "numeric"}) 
                                        withdrawalDate = withdrawalDate.toString();
                                        let withdrawalStartDateVal: any = withdrawalDate + ' IST'
                                        let currentDate = new Date().getTime();
                                        let sevenDaysAhead = parseFloat(request.startTime) * 1000 + (7 * 24 * 60 * 60 * 1000)
                                        // console.log(withdrawalStartDateVal, request.startTime, currentDate, sevenDaysAhead)
                                        
                                        return (
                                        <FlexSBCont className='staking-details'>
                                            <InfoLDText className='to-stake'>{convertToEther(request.amountInBnbX, 18)}</InfoLDText>
                                            {sevenDaysAhead >  currentDate ?
                                            <InfoLDText className='to-stake-white'>{withdrawalStartDateVal}</InfoLDText>
                                            :
                                            <TransButton className='withdrawButton' disabled={transactionLoader} onClick={() => withdraw(index)}> {transactionLoader ? <Spinner /> : inSufAmt ? 'Insufficient Balance' : 'Withdraw'}</TransButton>
                                            }
                                        </FlexSBCont>
                                        )
                                    })
                                }
                            </FlexCont>
                            : 
                            <FlexCont className='stakingCard'>
                                <TitleText className='staking-header'>{activeTab == 'stake' ? 'Stake' : activeTab == 'unstake' ? 'Unstake' : 'Withdraw'}</TitleText>
                                <FlexSBCont className="stake-input-card">
                                    {activeTab == 'stake' ?
                                    <FlexSBCont>
                                        <InfoLDText className='to-stake'>{userBNBBalance == undefined || bnbPrice == 0 ? <LoadingText2 className="boostLoading" /> : `Send: $${(inputAmount * bnbPrice).toFixed(2)}`}</InfoLDText>
                                        <InfoLDText className='to-stake'>{userBNBBalance == undefined || bnbPrice == 0 ? <LoadingText2 className="boostLoading" /> : `Balance: ${(parseFloat(userBNBBalance.toString()).toFixed(4))}`}</InfoLDText>
                                    </FlexSBCont>
                                    :
                                    <FlexSBCont>
                                        <InfoLDText className='to-stake'>{userBNBxBalance == undefined || bnbxPrice == 0 ? <LoadingText2 className="boostLoading" /> : `Send: $${(inputAmount * bnbxPrice).toFixed(2)}`}</InfoLDText>
                                        <InfoLDText className='to-stake'>{userBNBxBalance == undefined || bnbxPrice == 0 ? <LoadingText2 className="boostLoading" /> : `Balance: ${(parseFloat(userBNBxBalance.toString()).toFixed(4))}`}</InfoLDText>
                                    </FlexSBCont>
                                    }
                                    <FlexSBCont className='inputText'>
                                        <TokenValInput
                                        disabled={false}
                                        placeholder="$0"
                                        onChange={handleInputAmount}
                                        value={inputAmount && `${inputAmount}`}
                                        autoFocus={true}
                                        className="boost-input"
                                        />
                                        <FlexCont>
                                            {<TransMaxBox className='liquidStakingMax' onClick={() => handleMaxAmount()}>Max</TransMaxBox>}
                                            {activeTab == 'stake' ?
                                            <FlexCont>
                                                <img className="bnb-token-icon" src={BNBIcon} alt="" />
                                                <TokenName className='stakingTokenName'>BNB</TokenName>
                                            </FlexCont>
                                            :
                                            <FlexCont>
                                                <img className="bnb-token-icon" src={BNBxIcon} alt="" />
                                                <TokenName className='stakingTokenName'>BNBx</TokenName>
                                            </FlexCont>
                                            }
                                        </FlexCont>
                                    </FlexSBCont>
                                </FlexSBCont>
                                <FlexSBCont className='staking-info'>
                                    <InfoLDText className='to-stake'>Exchange rate</InfoLDText>
                                    <InfoLDText className='to-stake'>1BNBx={parseFloat(convertToEther(exchangeRate, 18)).toFixed(4)}BNB</InfoLDText>
                                </FlexSBCont>
                                {activeTab == 'unstake' &&
                                <FlexSBCont className='staking-info'>
                                    <InfoLDText className='to-stake'>Unstake Time</InfoLDText>
                                    <InfoLDText className='to-stake'>7-15 Days</InfoLDText>
                                </FlexSBCont>
                                }
                                {/* <FlexSBCont className='staking-info'>
                                    <InfoLDText className='to-stake'>You will get</InfoLDText>
                                    <InfoLDText className='to-stake'>{(inputAmount / parseFloat(convertToEther(exchangeRate, 18))).toFixed(4)} BNBx</InfoLDText>
                                </FlexSBCont> */}
                                <TransButton className='stakeButton' disabled={inputAmount == 0 || transactionLoader} onClick={() => {activeTab == 'stake' ? stakeLiquidity() : unStakeLiquidity()}}> {transactionLoader ? <Spinner /> : inSufAmt ? 'Insufficient Balance' : activeTab == 'stake' ? 'Stake' : 'Unstake'}</TransButton>
                            </FlexCont>
                            }
                            {activeTab !== 'withdraw' &&
                            <FlexCont className='stakingCard'>
                                <FlexSBCont className=''>
                                    <InfoLDText className='to-stake'>Minimum Received</InfoLDText>
                                    <InfoLDText className='to-stake'>{(inputAmount / parseFloat(convertToEther(exchangeRate, 18))).toFixed(4)} BNBx</InfoLDText>
                                </FlexSBCont>
                                <FlexSBCont className='staking-info'>
                                    <InfoLDText className='to-stake'>Stader Fee</InfoLDText>
                                    <InfoLDText className='to-stake'>0.0000 BNB</InfoLDText>
                                </FlexSBCont>
                            </FlexCont>
                            }
                        </FlexSBCont>
                        </FlexSBCont>
                            <FlexSBCont>
                                {/* <FlexSBCont className='protocolImgCont'>
                                    <img src={stader} alt='' className='protocolImg' />
                                </FlexSBCont> */}
                                <FlexSBCont className='stakingDetailsMob'>
                                    <InfoLDText className='protocolText'>BNBx</InfoLDText>
                                    <InfoLDText className='prototcolInfo'><span>BNBx</span> is a reward-bearing token. It accumulates rewards not by growing in number, but by growing in value in relation to BNB. The redemption price of <span>1 BNBx</span> is now <span>1.0411 BNB.</span></InfoLDText>
                                </FlexSBCont>
                            </FlexSBCont>
                            <FlexSBCont className='protocolCont'>
                                <Card className="tokenCard">{renderOverView()}</Card>
                                <FlexSBCont className='securityInfoCont'>
                                    <InfoLDText className='protocolText'>Safety & Security</InfoLDText>
                                    <InfoLDText className='prototcolInfo'>Audited by Halborn and Peckshield</InfoLDText> 
                                    <InfoLDText className='prototcolInfo faq faqMargin'>FAQ</InfoLDText>
                                    <FlexSBCont onClick={() => toggleAnswer(1)} className='faqQuestion'>
                                        <InfoLDText className='faqQuestion faq'>Which wallets are compatible with BNBx tokens ?</InfoLDText>
                                        <img className="faq-icon" src={ArrowDown} alt="" />
                                    </FlexSBCont>
                                    <FlexSBCont className='faqAnswer' data={showAnswerArr.includes(1) ? true : false} onClick={() => toggleAnswer(1)}>
                                        <InfoLDText  className='faqQuestion'>BNBx is an ERC-20 token that is compatible with Ethereum based wallets like MetaMask</InfoLDText>
                                    </FlexSBCont>
                                    <FlexSBCont onClick={() => toggleAnswer(2)} className='faqQuestion'>
                                        <InfoLDText className='faqQuestion faq'>What is the minimum amount of BNB I can stake ?</InfoLDText>
                                        <img className="faq-icon" src={ArrowDown} alt="" />
                                    </FlexSBCont>
                                    <FlexSBCont className='faqAnswer' data={showAnswerArr.includes(2) ? true: false} onClick={() => toggleAnswer(2)}>
                                        <InfoLDText  className='faqQuestion'>You can stake from 0.1BNB. You must also count in the gas fees on top for sending the transaction.</InfoLDText>
                                    </FlexSBCont>
                                </FlexSBCont>
                            </FlexSBCont>
                        </FlexCont>                        
                    </FlexSBCont>
                </FlexSBCont>
                <FlexSBCont className='liquidStakeRightContWeb'>
                    <FlexSBCont className='stakingCont'>
                        <FlexCont className="stakingCont">
                            <CardText data={activeTab == 'stake' ? true: false} className='stakingText' onClick={() => changeTab('stake')}>Stake</CardText>
                            <CardText data={activeTab == 'unstake' ? true: false} className='stakingText' onClick={() => changeTab('unstake')}>Unstake</CardText>
                            <CardText data={activeTab == 'withdraw' ? true: false} className='stakingText' onClick={() => changeTab('withdraw')}>Withdraw</CardText>
                        </FlexCont>
                        {activeTab == 'withdraw' ?
                        <FlexCont className='stakingCard'>
                            <TitleText className='staking-header'>{'Withdraw'}</TitleText>
                            <FlexSBCont className='staking-info'>
                                <InfoLDText className='to-stake'>Note: Un-Staking requires 7-15 days.</InfoLDText>
                            </FlexSBCont>
                            
                            <FlexSBCont className='staking-details'>
                                <InfoLDText className='to-stake-white'>Amount</InfoLDText>
                                <InfoLDText className='to-stake'>Estimated Time</InfoLDText>
                            </FlexSBCont>

                            { 
                                withdrawRequestArr.length > 0 && withdrawRequestArr.map((request: any, index: any) => {
                                    let withdrawalDate: any = new Date(parseFloat(request.startTime) * 1000 + (7 * 24 * 60 * 60 * 1000)).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric", hour: "numeric", minute: "numeric"}) 
                                    withdrawalDate = withdrawalDate.toString();
                                    let withdrawalStartDateVal: any = withdrawalDate + ' IST'
                                    let currentDate = new Date().getTime();
                                    let sevenDaysAhead = parseFloat(request.startTime) * 1000 + (7 * 24 * 60 * 60 * 1000)
                                    // console.log(withdrawalStartDateVal, request.startTime, currentDate, sevenDaysAhead)
                                    
                                    return (
                                    <FlexSBCont className='staking-details'>
                                        <InfoLDText className='to-stake'>{convertToEther(request.amountInBnbX, 18)}</InfoLDText>
                                        {sevenDaysAhead >  currentDate ?
                                        <InfoLDText className='to-stake-white'>{withdrawalStartDateVal}</InfoLDText>
                                        :
                                        <TransButton className='withdrawButton' disabled={transactionLoader} onClick={() => withdraw(index)}> {transactionLoader ? <Spinner /> : inSufAmt ? 'Insufficient Balance' : 'Withdraw'}</TransButton>
                                        }
                                    </FlexSBCont>
                                    )
                                })
                            }
                        </FlexCont>
                        : 
                        <FlexCont className='stakingCard'>
                            <TitleText className='staking-header'>{activeTab == 'stake' ? 'Stake' : activeTab == 'unstake' ? 'Unstake' : 'Withdraw'}</TitleText>
                            <FlexSBCont className="stake-input-card">
                                {activeTab == 'stake' ?
                                <FlexSBCont>
                                    <InfoLDText className='to-stake'>{userBNBBalance == undefined || bnbPrice == 0 ? <LoadingText2 className="boostLoading" /> : `Send: $${(inputAmount * bnbPrice).toFixed(2)}`}</InfoLDText>
                                    <InfoLDText className='to-stake'>{userBNBBalance == undefined || bnbPrice == 0 ? <LoadingText2 className="boostLoading" /> : `Balance: ${(parseFloat(userBNBBalance.toString()).toFixed(4))}`}</InfoLDText>
                                </FlexSBCont>
                                :
                                <FlexSBCont>
                                    <InfoLDText className='to-stake'>{userBNBxBalance == undefined || bnbxPrice == 0 ? <LoadingText2 className="boostLoading" /> : `Send: $${(inputAmount * bnbxPrice).toFixed(2)}`}</InfoLDText>
                                    <InfoLDText className='to-stake'>{userBNBxBalance == undefined || bnbxPrice == 0 ? <LoadingText2 className="boostLoading" /> : `Balance: ${(parseFloat(userBNBxBalance.toString()).toFixed(4))}`}</InfoLDText>
                                </FlexSBCont>
                                }
                                <FlexSBCont className='inputText'>
                                    <TokenValInput
                                    disabled={false}
                                    placeholder="$0"
                                    onChange={handleInputAmount}
                                    value={inputAmount && `${inputAmount}`}
                                    autoFocus={true}
                                    className="boost-input"
                                    />
                                    <FlexCont>
                                        {<TransMaxBox className='liquidStakingMax' onClick={() => handleMaxAmount()}>Max</TransMaxBox>}
                                        {activeTab == 'stake' ?
                                        <FlexCont>
                                            <img className="bnb-token-icon" src={BNBIcon} alt="" />
                                            <TokenName className='stakingTokenName'>BNB</TokenName>
                                        </FlexCont>
                                        :
                                        <FlexCont>
                                            <img className="bnb-token-icon" src={BNBxIcon} alt="" />
                                            <TokenName className='stakingTokenName'>BNBx</TokenName>
                                        </FlexCont>
                                        }
                                    </FlexCont>
                                </FlexSBCont>
                            </FlexSBCont>
                            <FlexSBCont className='staking-info'>
                                <InfoLDText className='to-stake'>Exchange rate</InfoLDText>
                                <InfoLDText className='to-stake'>1BNBx={parseFloat(convertToEther(exchangeRate, 18)).toFixed(4)}BNB</InfoLDText>
                            </FlexSBCont>
                            {activeTab == 'unstake' &&
                            <FlexSBCont className='staking-info'>
                                <InfoLDText className='to-stake'>Unstake Time</InfoLDText>
                                <InfoLDText className='to-stake'>7-15 Days</InfoLDText>
                            </FlexSBCont>
                            }
                            {/* <FlexSBCont className='staking-info'>
                                <InfoLDText className='to-stake'>You will get</InfoLDText>
                                <InfoLDText className='to-stake'>{(inputAmount / parseFloat(convertToEther(exchangeRate, 18))).toFixed(4)} BNBx</InfoLDText>
                            </FlexSBCont> */}
                            <TransButton className='stakeButton' disabled={inputAmount == 0 || transactionLoader} onClick={() => {activeTab == 'stake' ? stakeLiquidity() : unStakeLiquidity()}}> {transactionLoader ? <Spinner /> : inSufAmt ? 'Insufficient Balance' : activeTab == 'stake' ? 'Stake' : 'Unstake'}</TransButton>
                        </FlexCont>
                        }
                        {activeTab !== 'withdraw' &&
                        <FlexCont className='stakingCard'>
                            <FlexSBCont className=''>
                                <InfoLDText className='to-stake'>Minimum Received</InfoLDText>
                                <InfoLDText className='to-stake'>{(inputAmount / parseFloat(convertToEther(exchangeRate, 18))).toFixed(4)} BNBx</InfoLDText>
                            </FlexSBCont>
                            <FlexSBCont className='staking-info'>
                                <InfoLDText className='to-stake'>Deposit Fee</InfoLDText>
                                <InfoLDText className='to-stake'>{parseFloat((inputAmount * 0.001).toString()).toFixed(4)} BNB</InfoLDText>
                            </FlexSBCont>
                        </FlexCont>
                        }
                    </FlexSBCont>
                </FlexSBCont>
            </FlexSBCont>
            <CustomModal show={showStakingTxModal} toggleModal={setShowStakingTxModal}>
                {showStakingTxModal && <StakingTransactionModal setShowStakingTxModal={setShowStakingTxModal} transactionLink={transactionLink} unStakeBNBxTx={unStakeBNBxTx} tokenAllowance={tokenAllowance} tokenName={'BNBx'} transactionLoader={transactionLoader} />}
            </CustomModal>
        </MainContainer>
    )
}


const StakingTransactionModal = (props: any) => {
    const {setShowStakingTxModal, unStakeBNBxTx, tokenAllowance, tokenName, transactionLoader} = props
    return (
        <Fragment>
            <TransInputCont className='zapNStake'>
                <FlexSBCont className='titleCont'>
                    <InputTitle className='confTitle'>Approve & Unstake</InputTitle>
                    <img className='cross-icon' src={CrossIcon} alt="" onClick={() => setShowStakingTxModal(false)} />
                </FlexSBCont>
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Approve {tokenName}</InputTitle>
                    {transactionLoader == true && tokenAllowance == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={tokenAllowance ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Unstake {tokenName}</InputTitle>
                    {transactionLoader == true && tokenAllowance && unStakeBNBxTx == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={unStakeBNBxTx ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
            </TransInputCont>
        </Fragment>
    )
}

export default LiquidStaking; 