import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { FlexSBCont, FlexCont } from 'shared/styles/styled'
import { TransActionCont, TransInputCont, TransBalTxt, InputTitle, TransInputBox, TransInput, TransMaxBox, TransDataCont, TokenSelectCont, TokenName, TwoImageCont, TransTxt, TransInoTxt, PriceImpactBox, TransButton } from 'shared/styles/highestApyStyle'
import DownIcon from 'assets/icons/down.svg'
import { transformTokenList as tokenList, returnTransformTokenList } from 'modules/block-chain/tokenList'
import { floatNumRegex } from 'shared/helpers/regexConstants'
import CustomModal from 'shared/custom-modal'
import Spinner from 'shared/spinner'
import wallet from 'modules/wallet/wallet'
import CrossIcon from 'assets/icons/blue-cross-icon.png'
import grey_tick from 'assets/icons/grey_tick.png'
import green_tick from 'assets/icons/green_tick.png'

const TransactionConfirmation = (props: any) => {
    const {setShowConfModal, flag_1, flag_2, flag_3, poolDetails, sendToken, transactionLoader, action, receiveToken} = props
    return (
        <Fragment>
            {action == 'withdraw_zapout' &&
            <TransInputCont className='zapNStake'>
                <FlexSBCont className='titleCont'>
                    <InputTitle className='confTitle'>Withdraw & Zap Out</InputTitle>
                    <img className='cross-icon' src={CrossIcon} alt="" onClick={() => setShowConfModal(false)} />
                </FlexSBCont>
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Withdraw {poolDetails.name}</InputTitle>
                    {transactionLoader == true && flag_1 == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={flag_1 ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                {poolDetails.name.toLowerCase() != sendToken.name.toLowerCase() &&
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Approve {poolDetails.name}</InputTitle>
                    {transactionLoader == true && flag_1 && flag_2 == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={flag_2 ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                }
                {poolDetails.name.toLowerCase() != sendToken.name.toLowerCase() &&
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Zap Out to {sendToken.name}</InputTitle>
                    {transactionLoader == true && flag_1 && flag_2 && flag_3 == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={flag_3 ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                }
            </TransInputCont>
            }
            {action == 'swap_stake' &&
            <TransInputCont className='zapNStake'>
                <FlexSBCont className='titleCont'>
                    <InputTitle className='confTitle'>Swap & Stake</InputTitle>
                    <img className='cross-icon' src={CrossIcon} alt="" onClick={() => setShowConfModal(false)} />
                </FlexSBCont>
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Approve {sendToken.name}</InputTitle>
                    {transactionLoader == true && flag_1 == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={flag_1 ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Swap {sendToken.name} into {receiveToken.name}</InputTitle>
                    {transactionLoader == true && flag_1 && flag_2 == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={flag_2 ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
                <FlexSBCont className='allowanceCheck'>
                    <InputTitle className='confText'>Stake {receiveToken.name} into infinity vault</InputTitle>
                    {transactionLoader == true && flag_1 && flag_2 && flag_3 == false ?
                    <Spinner style={"transactionBox"} />
                    :
                    <img className='trans-icon' src={flag_3 ? green_tick : grey_tick} alt="" />
                    }
                </FlexSBCont>
            </TransInputCont>
            }
        </Fragment>
    )
}

export default TransactionConfirmation