import styled from 'styled-components'
import { colors, screenSizes } from './theme'

export const TitleText = styled.h1`
 font-size: 24px;
 line-height: 29px;
 color: ${colors.white};
 font-family: MontserratBold;
 margin: 0;
 @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 30px;
    line-height: 37px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 40px;
    line-height: 50px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 50px;
    line-height: 61px;
  }
  &.boost-title{
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-top: 4.6vw;
      font-size: 2.08vw;
      line-height: 2.55vw;
    }
  }
  &.baseTitle{
    font-size: 2.08vw;
    line-height: 2.552vw;
    @media (max-width: 799px){
      font-size: 24px;
      line-height: 29px;
    }
  }
  &.vesting{
    font-size: 2.604vw;
    line-height: 3.177vw;
    @media (max-width: 799px){
      font-size: 5.797vw;
      line-height: 7vw;
    }
  }
  &.staking-header{
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 1.5625vw;
      line-height: 1.927vw;
    }
    @media (max-width: 799px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &.staking-title{
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 2.08vw;
      line-height: 2.55vw;
    }
  }
`
export const TitleInfo = styled.p`
 font-size: 14px;
 line-height: 18px;
 color: ${colors.white};
 font-family: MontserratSemiBold;
 margin: 0;
 opacity: 0.4;
 @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 24px;
    line-height: 29px;
  }
  &.title-info{
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-top: 1.197vw;
      font-size: 1.25vw;
      line-height: 1.51vw;
    }
  }
  &.staking-info{
    @media (min-width: ${screenSizes.mediaM}px) {
      margin-top: 1.197vw;
      font-size: 1.25vw;
      line-height: 1.51vw;
    }
    @media (max-width: 799px) {
      margin-top: 10px;
      font-size: 14px;
      line-height: 18px;
    }
  }
  &.sub-title{
    margin-top: 1.04vw;
    font-size: 1.25vw;
    line-height: 1.51vw;
    @media (max-width: 799px) {
      margin-top: 2.415vw;
      font-size: 3.381vw;
      line-height: 4.347vw;
    }
  }
`
export const CardTitle = styled.h1`
 font-size: 20px;
 line-height: 24px;
 color: ${colors.white};
 font-family: MontserratBold;
 margin: 0;
 &.proj-on-web{
  display: none;
 }
 @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 22px;
    line-height: 26px;
    &.proj-on-web{
      font-size: 1.30vw;
      line-height: 1.5625vw;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 24px;
    line-height: 29px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 30px;
    line-height: 37px;
  }
  &.overview{
    font-size: 1.5625vw;
    line-height: 1.927vw;
    @media (max-width: 799px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  &.cardTitle{
    font-size: 1.25vw;
    line-height: 1.51vw;
    @media (max-width: 799px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  &.cardTitle > span{
    color: #55FF76;
  }
  &.token-title{
    margin-left: 12.34vw;
    font-size: 1.5625vw;
    line-height: 1.927vw;
    @media (max-width: 799px) {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
  }
`
export const DataTitle = styled.p<any>`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 24px;
    line-height: 29px;
  }
  &.pool-gamma-rewards-text{
    font-size: 1.04vw;
    line-height: 1.25vw;
    font-family: MontserratRegular;
    font-weight: 600;
    color: ${(props: any) => props.poolRatio > 5 ? '#FFFFFF' : '#FF5555'};
    @media (max-width: 799px) {
      font-size: 2.9vw;
      line-height: 3.62vw;
    }
  }
  &.pool-gamma-rewards-text > span {
    font-family: MontserratRegular;
    color: #FFFFFF67; 
  }
`

export const CardSubTitle = styled.p`
 font-size: 20px;
 line-height: 24px;
 color: ${colors.white};
 font-family: MontserratBold;
 margin: 0;
`
export const CardText = styled.p`
 font-size: 14px;
 line-height: 18px;
 color: ${colors.white};
 font-family: MontserratBold;
 opacity: 0.4;
 margin: 0;
 &.proj-title{
  font-size: 14px;
  line-height: 19px;
  font-family: MontserratBold;
  margin-bottom: 10px;
}
&.proj-apy{
  color: #55FF76;
}
&.proj-value{
  font-size: 18px;
  line-height: 22px;
  font-family: MontserratBold;
  opacity: 1;
  margin-bottom: 0px;
}
&.proj-highlight{
  font-size: 18px;
  line-height: 30px;
  font-family: MontserratBold;
  opacity: 1;
}
&.proj-highlight > span{
  color: #55FF76;
}
&.what-is-boost{
  color: #FFFFFF;
  font-size: 1.3vw;
  line-height: 1.562vw;
  opacity: 1;
  @media (max-width: 799px) {
    font-size: 18px;
    line-height: 30px;
  }
}
&.what-is-boost > span{
  color: #55FF76;
}
&.gamma-interest > span {
  color: #55FF76;
}
&.gamma-interest{
  color: #FFFFFF;
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 1.5625vw;
  opacity: 1;
  @media (max-width: 799px) {
    margin-bottom: 30px;
  }
}
&.gamma-title{
  margin-top: 5px;
  margin-left: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF64;
  font-family: MontserratBold
}
&.bal-text{
  margin-top: 1.5625vw;
  font-size: 1.04vw;
  line-height: 1.5625vw;
  color: #FFFFFF;
  font-family: MontserratMedium;
  @media (max-width: 799px) {
    margin-top: 20px;
    font-size: 14px;
    line-height: 21px;
  }
}
 @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 12px;
    line-height: 15px;
    &.proj-title{
      font-size: 1.04vw;
      line-height: 1.25vw;
      font-family: MontserratBold;
      margin-bottom: 1.04vw;
    }
    &.proj-value{
      font-size: 1.822vw;
      line-height: 2.239vw;
      font-family: MontserratBold;
      opacity: 1;
    }
    &.proj-highlight{
      font-size: 1.30vw;
      line-height: 1.5625vw;
      font-family: MontserratBold;
      opacity: 1;
    }
    &.gamma-title{
      font-size: 1.04vw;
      line-height: 1.25vw;
      margin: 0.52vw 3.75vw 0 0;
    }
    &.gamma-interest{
      color: #FFFFFF;
      font-size: 1.30vw;
      line-height: 1.5625vw;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 16px;
    line-height: 19px;
    
  }
  @media (max-width: 799px) {
    &.on-mob{
      color: #FFFFFF;
      opacity: 1;
      font-size: 18px;
      line-height: 22px;
    }

    &.proj-on-web{
      display: none;
    }
  }
  
  @media (min-width: ${screenSizes.mediaM}px) {
    &.proj-on-web{
      font-size: 1.30vw;
      line-height: 1.5625vw;
      color: #FFFFFF;
      opacity: 1
    }
    &.on-mob{
      display: none;
    }
  }
  &.overview-title{
    font-size: 0.833vw;
    line-height: 0.9895vw;
    font-family: MontserratRegular;
    font-weight: 600;
    @media (max-width: 799px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.overview-title > span{
    font-size: 0.833vw;
    line-height: 0.9895vw;
    font-family: MontserratRegular;
    font-weight: 600;
    color: #FFFFFF;
    opacity: 1;
    @media (max-width: 799px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.token-bal-text{
    font-size: 0.729vw;
    line-height: 0.9375vw;
    margin-top: 0.52vw;
    @media (max-width: 799px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.vesting-info{
    cursor: pointer;
  }
  &.vestingText{
    font-size: 1.25vw;
    line-height: 1.51vw;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 2.08vw;
    @media (max-width: 799px) {
      margin-top: 4.83vw;
      font-size: 3.381vw;
      line-height: 4.347vw;
    }
  }
  &.migrationText{
    font-size: 1.25vw;
    line-height: 1.51vw;
    color: #FFFFFF;
    opacity: 1;
    align-self: baseline;
    margin-left: 1.04vw;
    @media (max-width: 799px) {
      font-size: 3.381vw;
      line-height: 4.347vw;
    }
  }
  &.migrationApy{
    font-size: 1.04vw;
    line-height: 1.25vw;
    color: #FFFFFF;
    opacity: 1;
    align-self: baseline;
    @media (max-width: 799px) {
      font-size: 2.41vw;
      line-height: 2.657vw;
      width: max-content;
    }
  }

  &.migrationApyPool{
    font-size: 1.04vw;
    line-height: 1.25vw;
    color: #FFFFFF;
    opacity: 1;
    align-self: baseline;
    @media (max-width: 799px) {
      font-size: 3.14vw;
      line-height: 2.657vw;
      width: max-content;
    }
  }

  &.vestingGammaVal{
    margin: auto 1.25vw;
    @media (max-width: 799px) {
      margin: auto 5.797vw;
    }
  }
  &.exitEarlyText{
    font-size: 0.833vw;
    line-height: 0.989vw;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 2.08vw;
    font-weight: 600;
    align-self: baseline;
    font-family: MontserratRegular;
    @media (max-width: 799px) {
      font-size: 3.864vw;
      line-height: 4.56vw;
      margin-top: 10.62vw;
      font-weight: 600;
    }
  }
  &.vesting-input-text{
    font-size: 1.04vw;
    line-height: 1.25vw;
    font-family: MontserratSemiBold;
    color: #FFFFFF;
    @media (max-width: 799px) {
      font-size: 3.38vw;
      line-height: 4.34vw;
    }

  }
  &.overview-title-mob{
    font-size: 2.9vw;
    line-height: 3.62vw;
    font-family: MontserratRegular;
    font-weight: 600;
  }
  &.overview-title-white{
    color: #FFFFFF;
    opacity: 1;
    font-family: MontserratRegular;
    font-weight: 600;
    font-size: 0.833vw;
    line-height: 0.9895vw;
    @media (max-width: 799px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  &.overview-title-mob-white{
    font-size: 2.9vw;
    line-height: 3.62vw;
    color: #FFFFFF;
    opacity: 1;
    font-family: MontserratRegular;
    font-weight: 600;

  }
  &.migration-title{
    font-size: 1.04vw;
    line-height: 1.25vw;
  }

  &.migrationLockTime{
    font-size: 0.9375vw;
    line-height: 1.145vw;
    color: #FFFFFF;
    opacity: 1;
    @media (max-width: 799px) {
      font-size: 3.864vw;
      line-height: 4.83vw;
    }
  }

  &.green{
    color: #55FF76 !important;
  }
  &.exitEarlyTextDisabled {
    padding-top: 20px;
    opacity: initial;
  }
`
export const CardSubText = styled.p`
 font-size: 14px;
 line-height: 18px;
 color: ${colors.white};
 font-family: MontserratSemiBold;
 opacity: 0.4;
 margin: 0;
 @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  &.tvl-text{
    font-size: 0.833vw;
    line-height: 0.989vw;
    @media (max-width: 799px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  &.subtext{
    font-size: 1.04vw;
    line-height: 1.25vw;
    margin-top: 0.52vw;
    @media (max-width: 799px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.migration-pool-subtext{
    margin-top: 5px;
    font-size: 0.781vw;
    line-height: 0.989vw;
    align-self: baseline;
    @media (max-width: 799px) {
      font-size: 11px;
      line-height: 13px;
    }
  }
  &.aquaBlue {
    font-size: 12px;
    font-family: MontserratSemiBold;
    color: "#55B7FF" !important;
  }
`

export const AquaVotingPower = styled.span`
  font-size: 14px;
  line-height: 18px;
  &.shiny-blue {
    margin-top: 5px;
    font-size: 0.781vw;
    line-height: 0.989vw;
    color: #55B7FF !important;
    opacity: 1 !important;
    align-self: baseline;
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 0.781vw;
      line-height: 0.989vw;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
      font-size: 18px;
      line-height: 22px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      font-size: 20px;
      line-height: 24px;
    }
    @media (max-width: 799px) {
      font-size: 11px;
      line-height: 13px;
    }
  }
`;
export const CardBText = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (max-width: ${screenSizes.mediaS}px) {
    font-size: 4.8vw
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 24px;
    line-height: 29px;
  }
`


export const CardAquaText =  styled.span`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  opacity: 0.4;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 20px;
    line-height: 24px;
  }
  &.migration-pool-subtext{
    margin-top: 5px;
    font-size: 0.781vw;
    line-height: 0.989vw;
    align-self: baseline;
    @media (max-width: 799px) {
      font-size: 11px;
      line-height: 13px;
    }
  }
`
