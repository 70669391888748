import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DataTitle } from 'shared/styles/globalText'
import { HAListCont, HATokenWrapper, HATokenCont, HATokenBox, HAImgCont, HATokenTxt, HApyTxt } from 'shared/styles/highestApyStyle'
import CAKEIcon from 'assets/icons/CAKE.svg'
import { getTokenIcon } from 'shared/tokenIconList'
import { getTokenName, commaFy, convertToKAndM } from 'service/globalFunctions'
import TokenBoxLoading from 'shared/token-box-loading'
import CustomModal from 'shared/custom-modal'
import CustomActionModal from 'shared/custom-modal/CustomActionModal'
import PoolDetailsModal from './pool-modal/PoolDetailsModal'
import PoolTCAction from './pool-modal/PoolTCAction'
import thenaIcon from 'assets/icons/ThenaLogo.svg'
import bswIcon from 'assets/icons/bsw.svg'
import { Paths } from 'modules/app/components/routes/types'
import history from 'modules/app/components/history'
import UnStakeInstantlyPopUp from './pool-modal/UnStakeInstantlyPopUp'
import { newPoolList, activePoolList, activeV3Pools } from 'modules/block-chain/pfPoolsList'
import { selectInstance, instType, poolInfoNew, handleDeposit, handleWithdraw, harvestLpTokens, earnAquaProfits, isAllowanceApproved, convertToEther, deposit_in_infinity_vault_new, getUserUnstakingTimeInInfinityVaultNew, stopUnstakeProcessNew, unstake_after_min_time_new } from 'modules/block-chain/BlockChainMethods'
import { updateUserBalance, updateLPData, silentUpdateOnInfStake, } from 'logic/action/user.actions'
import { fetchUserPortfolio } from 'logic/action/user.actions'

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const HighestApyPools = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    const [showPoolDetails, setShowPoolDetails] = useState(false)
    const [modalData, setModalData] = useState<any>({})
    const [showTCAModal, setShowTCAModal] = useState(false)
    const [transData, setTransData] = useState<any>()
    const [lpAction, setLPAction] = useState<any>('transform')
    const [tokenSwapAmount, setTokenSwapAmount] = useState(0)
    const [sendToken, setSendToken] = useState({})
    const { poolLoading, poolData } = selector.user
    const { poolsNewData, showSwapPopup, gammabtcbPoolObject } = props;
    const [showUnstakePopUp, setShowUnstakePopUp] = useState(false)
    const [unstakeData, setUnstakeData] = useState({})
    const [processUnstake, setProcessUnstake] = useState(() => {})
    const [instantUnstakeLoading, setInstantUnstakeLoading] = useState(false)
    const [trxPopUpOverlay, setTrxPopUpOverlay] = useState(false);
    const [confirmUnstake, setConfirmUnstake] = useState({
        confirm: false,
        poolId: -1,
    })
    const { userPortfolio } = selector.user

    useEffect(() => { 
        if(showPoolDetails) {
            let strat_id = modalData.strategyAddress
            let pool = poolsNewData.active.filter((ele:any) => {
                return ele.strategyAddress == strat_id
            })
            
            setModalData(pool[0])
        }
    }, [poolsNewData])

    const renderHATokenLoading = () => {
        return arr.map((item: any, index: number) => {
            return <TokenBoxLoading key={index} />
        })
    }

    const handleShowApyModal = (modalDataVal: any) => {
        setModalData(modalDataVal)
        setShowPoolDetails(true)
    }

    const toggleApyModal = () => {
        setModalData({})
        setShowPoolDetails(false)
    }
    const handleGetLpToken = (transData: any, type: any, inputAmount: any) => {
        if(transData.poolStatus == "active"){
            setTransData(transData)
            setShowTCAModal(true)
            setLPAction(type)
            setTokenSwapAmount(inputAmount)
        } else {
            history.push(`${Paths.liquidity}`)
        }
    }

    const getTokenList = () => {
        let tokenList: any = []
        if (!poolLoading) {
            const dataArr = poolsNewData;
            if (!!dataArr && Object.values(dataArr).length > 0) {
                tokenList = dataArr.active.sort((a: any, b: any) => (parseFloat(b.totalApy) > parseFloat(a.totalApy)) ? 1 : (parseFloat(a.totalApy) > parseFloat(b.totalApy) ? -1 : 0))
                tokenList = tokenList.slice(0, 10)
            }
        }
        return tokenList
    }

    const renderTokenIcons = (tokenIcons: string, isBoostPool: boolean, protocol?: string) => {
        const tokenName = tokenIcons.split('-')
        if (tokenName.length === 1) {
            const icon = getTokenIcon(tokenIcons)
            return <HAImgCont className="single-img">
                        <img className="single-img" src={icon} alt="" />
                    </HAImgCont>
        }
        else if (tokenName.length === 2) {
            const icon1 = getTokenIcon(tokenName[0])
            let secondTokenName = tokenName[1].split(" ");
            const icon2 = getTokenIcon(secondTokenName[0])
            return (
                <HAImgCont>
                    <img src={icon1} alt="" />
                    <img className="second-img" src={icon2} alt="" />
                    {protocol && protocol.toLowerCase() == 'cake' && <img  className='third-img' src={CAKEIcon} alt="" />}
                    {protocol && protocol.toLowerCase() == "thena" && <img className='third-img' src={thenaIcon} alt="" />}
                    {protocol && protocol.toLowerCase() == "biswap" && <img className='third-img' src={bswIcon} alt="" />}
                </HAImgCont>
            )
        }
        else if (tokenName.length === 3) {
            const icon1 = getTokenIcon(tokenName[0])
            const icon2 = getTokenIcon(tokenName[1])
            const icon3 = getTokenIcon(tokenName[2])
            return (
                <HAImgCont className='stablePool'>
                    <img src={icon1} alt="" />
                    <img className="second-img" src={icon2} alt="" />
                    <img className='third-icon' src={icon3} alt="" />
                </HAImgCont>
            )
        }
    }

    const renderTokenList = () => {
        const tokenList = getTokenList()
        return tokenList.map((item: any, index: number) => {
            // poolApy = poolApy && (!isFinite(poolApy) || poolApy > 10000) ? "> 10,000" : poolApy ? poolApy.toFixed(2) : "0.00";
            // item.avgGammaBoostApyLower = item.avgGammaBoostApyLower ? (+item.avgGammaBoostApyLower).toFixed(2) : 0;
            // item.avgGammaBoostApyUpper = item.avgGammaBoostApyUpper ? (+item.avgGammaBoostApyUpper).toFixed(2) : 0;
            // let rangeApy = "";
            // if(item.avgGammaBoostApyUpper > 0){
            //     if(selector.ethData.address !== undefined && item.vaultBalanceUSD > 0){
            //         rangeApy =  "("+ item.avgGammaBoostApyLower +"% - " + item.avgGammaBoostApyUpper +"%)";
            //     } else {
            //         rangeApy = item.avgGammaBoostApyLower +" - " + item.avgGammaBoostApyUpper;
            //     }
            // }
            // item.gammaApr = item.gammaAPR ? item.gammaAPR : item.gammaApr ? item.gammaApr : 0;
            // item.totalApr = item.gammaApr + item.tradeFeeApr;
            // item.totalApy = 100 * (Math.pow(1 + (item.totalApr/36500), 365) - 1);
            let poolApy: any = item.totalApy;
            poolApy = poolApy && (!isFinite(poolApy) || poolApy > 10000) ? "> 10,000" : poolApy ? poolApy.toFixed(2) : "0.00";
            const pool_apy = poolApy;
            return (
                <HATokenBox key={index} onClick={() => handleShowApyModal(item)} >
                    {renderTokenIcons(item.name, item.isBoostPool, item.protocol)}
                    <HATokenTxt>{getTokenName(item.name)}</HATokenTxt>
                    <HApyTxt>{poolApy}%</HApyTxt>
                    
                </HATokenBox>
            )
        })
    }
    
    const handleUnstakePopUpToggleModal = () => {
        setShowUnstakePopUp(false)
    }

    return (
        <HAListCont>
            <DataTitle>Highest APY</DataTitle>
            <HATokenWrapper>
                <HATokenCont poolSize={poolsNewData && poolsNewData.active && (poolsNewData.active.length > 0 && poolsNewData.active.length < 10) ? poolsNewData.active.length : 10}>
                    {poolLoading ? renderHATokenLoading() : renderTokenList()}
                </HATokenCont>
            </HATokenWrapper>
            <CustomActionModal show={showPoolDetails} toggleModal={toggleApyModal}>
                {showPoolDetails && <PoolDetailsModal 
                    togglePoolModal={toggleApyModal} 
                    data={modalData} 
                    handleGetLpToken={handleGetLpToken} 
                    setModalData={setModalData} 
                    setTokenSwapAmount={setTokenSwapAmount} 
                    setSendTokenFromParent={setSendToken} 
                    showSwapPopup={showSwapPopup}
                    setShowUnstakePopUp={setShowUnstakePopUp} 
                    confirmUnstake={confirmUnstake}
                    showUnstakePopUp={showUnstakePopUp}
                    setConfirmUnstake={setConfirmUnstake}
                    setUnstakeData={setUnstakeData}
                />}
            </CustomActionModal>
            <CustomModal show={showTCAModal} toggleModal={setShowTCAModal} zapTrxModal={trxPopUpOverlay}>
                {showTCAModal && activeV3Pools.includes(transData.strategyAddress) &&<PoolTCAction setShowTCAModal={setShowTCAModal} transData={transData} type={lpAction} poolTable={true} zapTokenAmount={tokenSwapAmount} zapTokenObject={sendToken} setShowPoolDetails={setShowPoolDetails} gammaBtcbPool={poolsNewData.active.find((poolObj: any) => poolObj.name.toLowerCase() == "gamma-btcb")} setTrxPopUpOverlay={setTrxPopUpOverlay} trxPopUpOverlay={trxPopUpOverlay} />}
            </CustomModal>
            <CustomModal show={showUnstakePopUp} toggleModal={handleUnstakePopUpToggleModal}>
                <UnStakeInstantlyPopUp 
                    setShowUnstakePopUp={setShowUnstakePopUp} 
                    showUnstakePopUp={showUnstakePopUp}
                    unstakeData={unstakeData}
                    setConfirmUnstake={setConfirmUnstake}
                    processUnstake={processUnstake}
                />
            </CustomModal>
        </HAListCont>
    );
};

export default HighestApyPools