import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { connectEthWallet, disconnectEthWallet } from 'logic/action/wallet.actions'
import { fetchPoolData, fetchUserPortfolio, fetchPlatformData, fetchInfVaultData, fetchInfVaultDataNew, fetchPoolDataNew } from 'logic/action/user.actions'
import { fetchLendingPlatformData, fetchUserAssetData, fetchBorrowLimit, fetchLendingPlatformData_v1, fetchUserAssetData_v1, fetchBorrowLimitNew } from 'logic/action/lending.action'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from 'shared/styles/theme'
import Routes from './components/routes/Routes'
import Notification from 'shared/notification'
import HoverInfo from 'shared/hover-info'
import wallet from 'modules/wallet/wallet'
import IntractAttribution, {trackWalletConnect} from "@intract/attribution";
import { GlobalContext } from 'context/GlobalContext'
import planetGlobalObject  from 'global/GlobalVar'
import { getAllPoolInfo, getAquaBurnAPY, getPoolsInfo, getPoolsInfoV2, getGammaSupplyApyFromApi, getGammaSupplyAprFromApi, getTotalSupplyApyFromApi } from 'modules/block-chain/BlockChainMethods'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal, useWeb3Modal } from '@web3modal/react'
import { Chain, configureChains, createConfig, WagmiConfig, useAccount, useConnect, usePublicClient } from 'wagmi'
import { mainnet, polygon, optimism, bsc, arbitrum } from 'wagmi/chains'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import Web3 from 'web3'

let walletMainConnectIdArray = ["https://bsc-dataseed1.binance.org/", "https://bsc-dataseed2.binance.org", "https://bsc-dataseed3.binance.org" ,"https://bsc-dataseed4.binance.org"];
// import { providers } from 'ethers'
// import { Provider, createClient } from 'wagmi'
let bsc_network = {
  id: 56,
  name: "BNB Smart Chain",
  network: "bsc",
  nativeCurrency: {
      decimals: 18,
      name: "BNB",
      symbol: "BNB",
  },
  rpcUrls: {
      default: {
        http: ["https://bsc-dataseed.binance.org/"],
      },
      public: {
        http: ["https://bsc-dataseed.binance.org/"],
      },
  },
  blockExplorers: {
      etherscan: {
          name: "BscScan",
          url: "https://bscscan.com",
      },
      default: {
          name: "BscScan",
          url: "https://bscscan.com",
      },
  },
  contracts: {
      multicall3: {
          address: "0xca11bde05977b3631167028862be2a173976ca11",
          blockCreated: 15921452,
      },
  }
};


const chains: any = [bsc_network, mainnet]
const projectId = '8f395d21e8a7dce987dfe4a6110febc9';
let infuraId = 'b8caa3ff43f8432da5250e762dbcfebc';


const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

const { webSocketPublicClient } = configureChains(
 chains,
 [w3mProvider({ projectId })]
    // [
    //   infuraProvider({
    //     apiKey: infuraId,
    //   }),
    //   publicProvider(),
    //   jsonRpcProvider({
    //     rpc: (chain) => ({
    //       http: chain.rpcUrls.default.http[0],
    //     }),
    //   }),
    // ],
  
)


// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors:w3mConnectors({ chains, projectId}),
//   publicClient,
// })

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    ...w3mConnectors({ projectId, chains }),
    // new CoinbaseWalletConnector({ chains, options: { appName: 'Planet Finance' } })
  ],
  publicClient
})

const ethereumClient = new EthereumClient(wagmiConfig, chains)

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: projectId,
      },

    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
        getProvider: () =>
      typeof window !== 'undefined' ? window.ethereum : undefined,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
})

const App = () => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const { address, isConnected } = useAccount()
  const [ready, setReady] = useState(false);
  const {setDefaultChain} = useWeb3Modal();
  const [globalData, setGlobalData] =  useState({
    poolsInfo: {}
  })

  // console.log(window.ethereum.networkVersion, 'window.ethereum.networkVersion');
  if(window.ethereum !== undefined && window.ethereum.networkVersion !== undefined && window.ethereum.networkVersion !== null && +window.ethereum.networkVersion !== 56){
    // console.log(" in setting bsc network")
    setDefaultChain(bsc_network)
  } else{ 
    // console.log("already using bsc network!")
  }

  
  

  useEffect(() => {
    const getLoginStatus = async () => {
      //@ts-ignore
      const walletConnect = JSON.parse(localStorage.getItem("walletConnected"));
      //@ts-ignore
      const walletType = JSON.parse(localStorage.getItem("walletType"));
      try {
        if (isConnected) {
          // console.log("wallet type in app.js", walletType)
          await wallet.setProvider(walletType);
          const address = await wallet.login(walletType);
          if (address !== undefined) {
            // console.log("address check in wallet connect", address)
            // dispatch(fetchInfVaultDataNew(address))
            dispatch(connectEthWallet(address));
            callAttributionFunction()
          }
        }
      } catch (error) {
        dispatch(disconnectEthWallet())
      }
    }
    setTimeout(async () => {
      getLoginStatus()
    }, 500)
  }, [dispatch])

  function callAttributionFunction(){
    //after connect wallet code
    trackWalletConnect();
  }

  useEffect(() => {
    IntractAttribution("64621fa71f4f2cf6bfccd8cf", {
        configAllowCookie: false
    });

    getAllPoolInfo().then((data:any) => {
      if(data && data.newPools 
        && data.newPools.active 
        && data.newPools.active.length > 0) {
          planetGlobalObject.getallpoolinfo = data;
      }
    })

    // getAquaBurnAPY().then((data) => {
    //   if( data && data.apr ) {
    //       planetGlobalObject.aquaBurnAPY = data;
    //   }
    // })

    getPoolsInfo().then((data) => {
      if( data ) {
        planetGlobalObject.poolsInfo = data;
      }
    })

    getPoolsInfoV2().then((data) => {
      if( data ) {
        planetGlobalObject.poolsInfoV2 = data;
      }
    })

    getGammaSupplyApyFromApi().then((data) => {
      if( data ) {
        planetGlobalObject.poolGammaSupplyApy = data;
      }
    })

    getGammaSupplyAprFromApi().then((data) => {
      if( data ) {
        planetGlobalObject.poolGammaSupplyApr = data;
      }
    })

    getTotalSupplyApyFromApi().then((data) => {
      if( data ) {
        planetGlobalObject.poolTotalSupplyApy = data;
      }
    })
  }, []);

  useEffect(() => {
    dispatch(fetchPoolData())
    // dispatch(fetchPoolDataNew())
    dispatch(fetchPlatformData())
  }, [dispatch])

  useEffect(() => {
    // console.log("userAddress check on site load", selector.ethData.address)
    if (!!selector.ethData.address) {
      // user_bnb_balance(selector.ethData.address)
      // dispatch(fetchInfVaultDataNew(selector.ethData.address))
      dispatch(fetchUserPortfolio(selector.ethData.address))
      // dispatch(fetchInfVaultData(selector.ethData.address))
      
      //to enable infinity vaults
      
      
    } else {
      dispatch(fetchInfVaultDataNew())
      // dispatch(fetchInfVaultData())
    }
  }, [dispatch, selector.ethData.address])

  useEffect(() => {
    dispatch(fetchLendingPlatformData(selector.ethData.address))
    dispatch(fetchUserAssetData(selector.ethData.address))
    dispatch(fetchBorrowLimitNew(selector.ethData.address))
    // dispatch(fetchLendingPlatformData_v1(selector.ethData.address))
    // dispatch(fetchUserAssetData_v1(selector.ethData.address))
    
  }, [selector.ethData.address, dispatch])

  useEffect(() => {
    setReady(true);
  }, []);

  const user_bnb_balance: any = async (userAddress: any) => {
    try {
      await wallet.web3.eth.getBalance(userAddress);
    } catch (error) {
      console.log(error);
      bsc_network.rpcUrls.public.http[0] = walletMainConnectIdArray[0];
      bsc_network.rpcUrls.default.http[0] = walletMainConnectIdArray[0];
    }
    
  }

  return (
    <>
    {ready ? (
      <WagmiConfig config={wagmiConfig}>
        <GlobalContext.Provider value={{}}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Notification />
            <Routes />
            <HoverInfo />
          </ThemeProvider>
        </GlobalContext.Provider>
        
        <Web3Modal 
        projectId={projectId} 
        ethereumClient={ethereumClient} 
        themeVariables={{
          '--w3m-font-family': 'MontserratBold',
          '--w3m-background-color': 'transparent'
        }}
        explorerRecommendedWalletIds={["c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0", "0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150", "19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927", "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4", "cbc11415130d01316513f735eac34fd1ad7a5d40a993bbb6772d2c02eeef3df8",
        "18388be9ac2d02726dbac9777c96efaac06d744b2f6d580fccdd4127a6d01fd1"
      ]}
        explorerExcludedWalletIds="ALL"
      />
      </WagmiConfig>
    ): null}
      
    </>
  )
}

export default App
