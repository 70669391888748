import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'

export const ModalCont = styled.div`
  padding: 20px 0 10px 0;
  width: 100%;
  max-width: 382px;
  box-shadow: 0px 3px 10px #00000033;
  background: ${colors.themeBackground};
  border: 1px solid ${colors.borderColor};
  border-radius: 20px;
  box-sizing: border-box;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 30px 0 15px 0;
    width: 477px;
    max-width: 477px;
  }
`
export const FlexSBCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const FlexCont = styled.div`
  display: flex;
  align-items: center;
  &.poolIcons{
    img{
      width: 30px;
      height: 30px;
      @media (min-width: ${screenSizes.mediaM}px) {
        width: 40px;
        height: 40px;
      } 
      @media (min-width: ${screenSizes.mediaXLL}px) {
        width: 50px;
        height: 50px;
      } 
    }
  }
`
export const TitleText = styled.h1`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 30px;
    line-height: 38px;
  }
`
export const TokenSelectTxt = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratBold;
  margin: 0;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 25px;
  }
`
export const TokenSelectSubTxt = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: ${colors.white};
  font-family: MontserratSemiBold;
  margin: 3px 0 0 0;
  opacity:0.4;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
    line-height: 20px;
  }
`
export const TokenValCont = styled.div`
  text-align: right;
`
export const TokenListCont = styled.div`
  .select-token-icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 50px;
      height: 50px;
    }
  }

  .cross-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`
export const TokenHeader = styled.div`
  padding: 0 20px;
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 0 30px;
  }
`
export const TokenRow = styled.div<any>`
  padding: 10px 20px;
  cursor: pointer;
  opacity: ${(props: any) => props.isAddExist ? 0.4 : 1};
  @media (min-width: ${screenSizes.mediaM}px) {
    padding: 15px 30px;
  }
  &:hover{
     background: #121212;
  }
`
export const TokenListInput = styled.input`
  width: 100%;
  padding: 20px 30px;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  font-family: MontserratBold;
  background: ${colors.themeBackground};
  border: 1px solid ${colors.borderColor};
  border-radius: 20px;
  margin-top: 20px;
  box-sizing: border-box;
  outline: none;
  @media (min-width: ${screenSizes.mediaXS}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 20px;
    line-height: 25px;
  }
  &.searchText{
    font-family: MontserratSemiBold;
  }
`
export const TokenListData = styled.div`
  height: 100%;
  max-height: 450px;
  overflow: auto;
  box-sizing: border-box;
  margin-top: 30px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  
  @media (min-width: ${screenSizes.mediaM}px) {
    -ms-overflow-style: block;
    &::-webkit-scrollbar {
      width: 10px;
      display: block;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background:${colors.themeBackground};
      border:1px solid ${colors.borderColor};
      border-radius: 10px;
    }
     
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(to bottom, ${colors.lightBlue} 0%, ${colors.blue} 100%) no-repeat padding-box;
      border-radius: 10px;
    }
  }
`
export const NoDataAvailable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:40px;
  p {
    font-size: 16px;
    line-height: 20px;
    color: ${colors.white};
    font-family: MontserratBold;
    margin: 0;
  }
`
